// Styled
import { useTheme } from 'styled-components';
import { Fragment, useEffect, useState } from 'react';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Slider from 'react-slick';
import * as S from './styled';

// React

// Components
import Tag from '../Tag';
import Empty from '../Empty';
import Search from '../Search';
import SliderTag from '../SliderTag';
import ContentCard from '../ContentCards';

// Components MUI

// React Slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Timer (Controll page, is local, but need do this for see the diff beteewn tags and search)
let timer = null;

const ModalTemplates = ({
  open,
  onClose,
  tags,
  templates,
  setModalTitle,
  setFullModal,
  setModalResponse,
  setFormResponse,
}) => {
  const theme = useTheme();
  // Slider Config
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1130,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // General States
  const [query, setQuery] = useState({
    tag: 'Todos',
    search: '',
  });
  const [inSearch, setInSearch] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);
  const [filteredTemplates, setFilteredTemplates] = useState(templates || []);

  // -------------------------------------FILTER AREA-------------------------------------------//
  const handleQuery = object => {
    if (object?.card == query.card && query.card) {
      setQuery({ ...query, card: null });
    } else {
      setQuery({ ...query, ...object });
    }
  };

  useEffect(() => {
    let hasFilter = [];
    const temps = templates;
    if (query.search) {
      hasFilter = temps.filter(temp => {
        if (
          temp.titulo
            .toLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          temp.tags
            .toString()
            .toLocaleLowerCase()
            .includes(query.search.toLocaleLowerCase())
        ) {
          return temp;
        }
      });
    }

    if (query.tag !== 'Todos') {
      const hasSearch = query.search ? hasFilter : temps;
      hasFilter = hasSearch.filter(temp => temp.tags.includes(query.tag));
    }

    if (firstCharge) {
      setFilteredTemplates(null);
      setFilteredTemplates(templates);
      setFirstCharge(false);
      return;
    }

    setInSearch(true);
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (!query.search && query.tag === 'Todos') {
        setFilteredTemplates(templates);
      } else {
        setFilteredTemplates(hasFilter);
      }
      setInSearch(false);
    }, 400);
  }, [query, templates]);

  return (
    <S.ModalMui open={open} onClose={onClose}>
      <S.Container>
        <S.Header>
          <div className="left">
            <QueueOutlinedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <span className="modalTitle">Criar a partir de template</span>
          </div>
          <div className="right">
            <CloseOutlinedIcon
              htmlColor={theme.palette.brand.primary.light}
              onClick={onClose}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </S.Header>
        <S.SerchLine>
          <SliderTag width="70%">
            {tags?.map((item, key) => (
              <Tag
                children={item}
                key={key}
                onClick={() => handleQuery({ tag: item })}
                active={item === query.tag}
                disabled={item === query.tag}
              />
            ))}
          </SliderTag>
          <Search
            value={query.search}
            setValue={value => handleQuery({ search: value })}
          />
        </S.SerchLine>

        <S.Body inSearch={inSearch}>
          <p className="title">{query.tag}</p>
          {filteredTemplates?.length > 0 && (
            <div className="templates">
              <Slider {...settings}>
                {filteredTemplates?.map((temp, key) => (
                  <Fragment key={key}>
                    <ContentCard
                      key={key}
                      template={temp}
                      isTemplate
                      closeModalTemplates={onClose}
                      setModalTitle={setModalTitle}
                      setFullModal={setFullModal}
                      setModalResponse={setModalResponse}
                      setFormResponse={setFormResponse}
                      noTags
                    />
                  </Fragment>
                ))}
              </Slider>
            </div>
          )}
          {filteredTemplates?.length <= 0 && (
            <Empty query={query} inSearch={inSearch} width="200px" />
          )}
        </S.Body>
      </S.Container>
    </S.ModalMui>
  );
};

export default ModalTemplates;
