import { toast } from 'react-toastify';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Componentes Reutilizaveis
import Tabs from 'components/Tabs';
import GhostButton from 'components/Buttons/Ghost';
import CardIndicadores from 'components/Cards/Indicador';

// Componentes do material
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Serviços
import { changeTabRevisaoOperacao } from 'store/modules/provider/filterRevisaoOperacao/actions';

// Utils
import ExportToExcel from 'utils/exportToCvs';
import { formatNameDate } from 'utils/dates';
import { SaveAlt } from '@mui/icons-material';
import JustifyModal from 'components/JustifyModal';
import { useTheme } from 'styled-components';
import { columnsApproved2, columnsPending2, columnsRejected2 } from './columns';
import * as request from './services';
import { fields } from './constants';
import { useQuery } from 'react-query';
import { DefaultTable } from 'components/_Table/templates/default';

const RevisaoCliente = () => {
  const theme = useTheme();
  // Redux
  const dispatch = useDispatch();
  const filter = useSelector(state => state.filterProvider);
  const { responsavel, revisaoTab } = useSelector(
    state => state.filterRevisaoOperacao,
  );

  // General States
  const [col, setCol] = useState(null);
  const [query, setQuery] = useState(null);
  const [loadingTab, setLoadingTab] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);
  const [loadingReq, setLoadingReq] = useState(false);
  const [selectedEvaluations, setSelectedEvaluations] = useState(null);
  const [justify, setJustify] = useState('');

  // -------------------------TABS (TOP PAGE)---------------------------------------//
  const [oderTable, setOrderTable] = useState([]);

  const tabs = [
    {
      value: 0,
      label: 'Pendentes',
    },
    {
      value: 1,
      label: 'Aprovados',
    },
    {
      value: 2,
      label: 'Recusados',
    },
  ];

  useEffect(() => {
    switch (revisaoTab) {
      case 0:
        setCol(columnsPending2);
        setOrderTable({ id: 'data_revisao', order: 'DESC' });
        break;
      case 1:
        setCol(columnsApproved2);
        setOrderTable({ id: 'data_revisao_distribuidora', order: 'DESC' });
        break;
      case 2:
        setCol(columnsRejected2);
        setOrderTable({ id: 'data_revisao_distribuidora', order: 'DESC' });
        break;
    }
  }, [revisaoTab]);

  const handlePageChange = (e, tab) => {
    setSelectedCards(null);
    setLoadingTab(true);
    dispatch(changeTabRevisaoOperacao({ tab }));
  };

  // -------------------------CARDS (TOP PAGE)---------------------------------------//
  const [selectedCard, setSelectedCards] = useState(null);

  const handleClickCard = type => {
    if (type === selectedCard) setSelectedCards(null);
    else setSelectedCards(type);
  };

  const renderCards = () => {
    const cards = resCards?.data?.data || [{}, {}, {}];
    return (
      <Grid item>
        <Grid container spacing={2}>
          {cards &&
            cards.map((item, idx) => {
              let md = 4;
              if (12 / cards.length < 4) md = 12 / cards.length;
              if (revisaoTab !== 0 && idx > 1) {
                return <div key={idx} />;
              }
              return (
                <Grid item key={idx} md={md} xs={12} sm={6}>
                  <CardIndicadores
                    value={item.ids?.length}
                    icon="Grupo12741.svg"
                    text={item.text}
                    border={`1px solid ${theme.palette.brand.primary.light}4D`}
                    handleClick={
                      item.type !== 'APROVADAS' && item.type !== 'RECUSADAS'
                        ? () => handleClickCard(item.type)
                        : null
                    }
                    selected={selectedCard === item.type}
                    loading={loadingCards}
                    disabled={isFetching}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    );
  };

  // -------------------------- BULK MENU & ACTION COLUMN---------------------------//;
  const [openRejectChanges, setOpenRejectChanges] = useState(false);
  const [openApproveChanges, setOpenApproveChanges] = useState(false);

  const bulk = [
    {
      title: 'Aprovar',
      function: setOpenApproveChanges,
      visible: () => [0].includes(revisaoTab),
    },
    {
      title: 'Rejeitar',
      function: setOpenRejectChanges,
      visible: () => [0].includes(revisaoTab),
    },
  ];

  const handleApproveChanges = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];
    setLoadingLines(ids);
    setLoadingReq(true);
    const body = {
      event: 'approveToggle',
      data: {
        ids,
        justify,
      },
    };

    const res = await request.toggleOperation(body);
    if (res.data.success) {
      fetchCards();
      fetchData();
      toast.success(res.data.message);
      setJustify('');
    } else {
      toast.error(res.data.message);
    }

    setOpenApproveChanges(false);
    setLoadingReq(false);
  };

  const handleRejectChanges = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];
    setLoadingLines(ids);
    setLoadingReq(true);
    const body = {
      event: 'rejectToggle',
      data: {
        ids,
        justify,
      },
    };

    const res = await request.toggleOperation(body);
    if (res.data.success) {
      fetchCards();
      fetchData();
      toast.success(res.data.message);
      setJustify('');
    } else {
      toast.error(res.data.message);
    }

    setOpenRejectChanges(false);
    setLoadingReq(false);
  };

  const actions = [
    {
      title: 'Aprovar',
      function: setOpenApproveChanges,
      visible: () => [0].includes(revisaoTab),
    },
    {
      title: 'Rejeitar',
      function: setOpenRejectChanges,
      visible: () => [0].includes(revisaoTab),
    },
  ];

  // --------------------- REQUESTS -------------------//

  const initial = useRef(true);
  const [resetTable, setResetTable] = useState(false);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, responsavel, revisaoTab, selectedCard]);

  const {
    isLoading: loadingRevisao,
    isFetching,
    refetch: fetchData,
    data: resData,
  } = useQuery(
    ['revisao-provider-index', query],
    () => query && request.requestAvaliacoesList(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingTab(false);
        setLoadingLines([]);
        resetTable && setResetTable(false);
      },
    },
  );

  const {
    isLoading: loadingCards,
    data: resCards,
    refetch: fetchCards,
  } = useQuery(
    ['revisao-provider-cards', { ...filter, responsavel, revisaoTab }],
    () => request.requestCards({ ...filter, responsavel, status: revisaoTab }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // -------------------------- EXCEL ------------------------------------------//

  const [excelFields, setExcelFields] = useState(fields);

  const handleRequestExcel = async () => {
    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    let { initialDate } = query;
    let { finalDate } = query;
    initialDate = Date.parse(initialDate);
    finalDate = Date.parse(finalDate);

    if (finalDate - initialDate > 7889400000 && !selectedEvaluations.length) {
      toast.error('Não é possível exportar períodos maiores que 3 meses');
      return;
    }

    const newQuery = {
      ...query,
      responsavel,
      status: revisaoTab,
      excelFields: newFields,
      ids: selectedEvaluations,
    };

    const res = await request.requestExcel(newQuery);
    if (!res.data.success) {
      toast.error(res.data.message);
      return;
    }

    if (res.data && res.data.data.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `revisoes_de_operacao_${formatedDate}`,
      });
  };

  return (
    col && (
      <Grid container>
        <Grid container>
          <Grid
            marginBottom={3}
            item
            md={12}
            display="flex"
            justifyContent="space-between"
          >
            <h1>Revisão Operação</h1>
            <GhostButton
              children="Exportar"
              startIcon={<SaveAlt />}
              onClick={() => handleRequestExcel()}
            />
          </Grid>
          <Grid marginBottom={0} item md={12} display="flex">
            <Tabs
              value={revisaoTab}
              items={tabs}
              onChange={handlePageChange}
              disabled={isFetching}
            />
          </Grid>
        </Grid>
        <Grid marginBottom={3} item md={12}>
          <Divider />
        </Grid>
        <Grid marginBottom={3} item md={12}>
          {renderCards()}
        </Grid>
        <Grid item md={12}>
          <DefaultTable
            data={resData?.data?.rows || []}
            columns={col}
            setSelectedRows={setSelectedEvaluations}
            loading={loadingRevisao || loadingTab}
            pageCount={resData?.data?.count || 0}
            local={false}
            bulk={bulk}
            actions={revisaoTab === 0 ? actions : undefined}
            reset={resetTable}
            loadingSelection={loadingLines}
            setQuery={q =>
              setQuery({
                ...q,
                ...filter,
                responsavel,
                status: revisaoTab,
                card: selectedCard,
              })
            }
            sortBy={oderTable}
            placeholder="Buscar ID, placa"
            empty={{
              title:
                'Ops! Você não tem nenhuma solicitação de troca de cliente',
              description: 'Verifique os filtros selecionados.',
            }}
          />
        </Grid>

        <JustifyModal
          handleClose={() => setOpenApproveChanges(false)}
          loading={loadingReq}
          open={!!openApproveChanges}
          title="Tem certeza que deseja aprovar?"
          subtitle="As avaliações aprovadas terão seus clientes alterados"
          buttonText="Confirmar"
          onClick={() => handleApproveChanges(openApproveChanges)}
          value={justify}
          onChange={setJustify}
        />

        <JustifyModal
          handleClose={() => setOpenRejectChanges(false)}
          loading={loadingReq}
          open={!!openRejectChanges}
          title="Tem certeza que deseja rejeitar?"
          subtitle="As avaliações rejeitadas serão movidas para aba de recusados"
          buttonText="Confirmar"
          onClick={() => handleRejectChanges(openRejectChanges)}
          value={justify}
          onChange={setJustify}
        />
      </Grid>
    )
  );
};

export default RevisaoCliente;
