import { getTiposDescarga, getCriticidade } from 'constants/_SERVICES/provider';

// Pages
import DesviosDescargaProvider from 'pages/Provider/DesviosDescarga';
import DesvioDescargaProvider from 'pages/Provider/DesviosDescarga/Detalhe';
import LogsDesvioDescargaProvider from 'pages/Provider/DesviosDescarga/Historico';

export default {
  '/desvios-descarga': {
    date: true,
    defaults: ['empresas', 'motoristas', 'filial'],
    pageFilterName: 'filterDesvioDescargaProvider',
    filters: [
      {
        name: 'tipo',
        placeholder: 'Filtrar por Tipo',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getTiposDescarga(),
      },
      {
        name: 'carga',
        placeholder: 'Filtrar por Criticidade',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getCriticidade(),
      },
    ],
    page: <DesviosDescargaProvider />,
  },

  '/desvio-descarga/:id': {
    back: -1,
    title: 'Desvio Descarga',
    id: true,
    status: true,
    defaults: [],
    page: <DesvioDescargaProvider />,
  },

  '/logs/desvio-descarga/:id': {
    back: -1,
    title: 'Histórico do Desvio',
    id: true,
    defaults: [],
    page: <LogsDesvioDescargaProvider />,
  },
};
