// Styled
import { useTheme } from 'styled-components';

// React
import { useEffect, useState } from 'react';

// Components
import DropZoneArchives from 'components/Inputs/DropZoneCustom';

// Components MUI
import { Tooltip } from '@mui/material';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import ModalComments from '../../components/ModalComments';
import * as S from './styled';

// Actions
import {
  addImageQuestion,
  changeComments,
  deleteImageQuestion,
  editImageQuestion,
} from '../actions';

export const WithQuestion = ({
  field,
  setSecoes,
  secoes,
  sectionId,
  hasError,
  preview,
  approveView,
}) => {
  const theme = useTheme();
  const [openComments, setOpenComments] = useState(false);
  const [hasEvidence, setHasEvidence] = useState(false);

  const redirectFunction = (executable, idCampo, item) => {
    executable(idCampo, item, setSecoes, secoes, sectionId);
  };

  useEffect(() => {
    const opcoes = field?.opcoes;
    if (opcoes) {
      let evidence = false;
      for (const i in opcoes) {
        if (opcoes[i].evidencia) {
          evidence = true;
        }
      }
      setHasEvidence(evidence);
    }
  }, [field]);

  return (
    <S.Wrapper hasError={hasError} preview={preview}>
      <div className="headerArea">
        <span className="boxQuestion">
          <div className="question">
            {field.pergunta} {field.obrigatorio && <span>*</span>}
          </div>
        </span>
        {(!field.opcoes || !hasEvidence) && (
          <S.IconsArea hasComment={field.observacoes} preview={preview}>
            {field.id_tipo_campo !== 14 && (
              <span>
                <DropZoneArchives
                  data={field.arquivos}
                  multiple
                  fileDir="formularios/evidencias"
                  addFunction={item =>
                    redirectFunction(addImageQuestion, field.id, item)
                  }
                  editFunction={item =>
                    redirectFunction(editImageQuestion, field.id, item)
                  }
                  deleteFunction={item =>
                    redirectFunction(deleteImageQuestion, field.id, item)
                  }
                  automaticSave
                  minimal
                  haveLegend
                  tooltip="Adicionar anexo"
                  newImagesByModal="Adicionar imagens"
                  onlyRead={approveView}
                />
              </span>
            )}

            <Tooltip
              title={
                !approveView
                  ? 'Adicionar comentário'
                  : `${field.observacoes ? '1 comentário' : '0 comentário'}`
              }
              className="icons"
            >
              <div
                className="boxComment"
                style={{ cursor: 'pointer' }}
                onClick={
                  !approveView || (approveView && field.observacoes)
                    ? () => setOpenComments(true)
                    : undefined
                }
              >
                <CommentOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
                <div className="hasCommnet">{field.observacoes ? 1 : 0}</div>
              </div>
            </Tooltip>
          </S.IconsArea>
        )}
      </div>
      {field.descricao && <div className="description">{field.descricao}</div>}
      <ModalComments
        open={openComments}
        onClose={() => setOpenComments(false)}
        comentario={field.observacoes}
        onChange={value =>
          changeComments(value, field.id, setSecoes, secoes, sectionId)
        }
        onlyRead={approveView}
      />
    </S.Wrapper>
  );
};
