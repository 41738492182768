export const tipos_cnh = ['A', 'B', 'C', 'D', 'E', 'AB', 'AC', 'AD', 'AE'];

export const tipos_categoria = [
  'Motorista Auxiliar',
  'Motorista Carreteiro',
  'Motorista de Ambulância',
  'Motorista de Automóveis',
  'Motorista de Caminhão',
  'Motorista de Caminhão Leve',
  'Motorista de Caminhão-basculante',
  'Motorista de Caminhão-pipa',
  'Motorista de Caminhão-tanque',
  'Motorista de Rodocaçamba',
  'Motorista de Carga a Frete',
  'Motorista de Carro de Passeio',
  'Motorista de Empilhadeira',
  'Motorista de Ônibus',
  'Motorista Entregador',
  'Mototaxista',
  'Outros',
];
