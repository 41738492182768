import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: ${({ templateColumns }) => templateColumns};
  gap: 1rem;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  .style-container {
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 4px;
    border: 1px solid #95aac94d;
  }

  .item-grid {
    width: 100%;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media screen and (max-width: 100px) {
      grid-template-columns: 1fr;
    }
  }
`;
