import React, { useEffect } from 'react';
import api from 'services/api';
import { useSelector, useDispatch } from 'react-redux';
import { setData } from 'store/modules/selects/actions';
import { toast } from 'react-toastify';

const redux_names = [
  'modulos',
  'filials',
  'clients',
  'operations',
  'drivers',
  'users',
  'trucks',
  'publics',
  'responsibles',
  'cargas',
  'rotas',
  'empresas',
  'params',
];

// names: nome das variáveis em storage/modules/selects a serem atualizadas.
//      Se names = 'all' >>> atuliza todas
// forced: Força atualizar name sem lastDate. Utilizado onde há update, add ou delete de registros.
// emptyOnly: Só consulta dados de names que estao []. Utilizado no primeiro carregameto (Header)
// trigger / setTrigger: Força nova consulta mesmo após componente ser renderizado.
const ConstantsUpdater = ({
  names,
  forced,
  emptyOnly,
  trigger,
  setTrigger,
  setLoaded,
}) => {
  const dispatch = useDispatch();
  const constants = useSelector(state => state.selects);

  // names: ['filials', 'clients', 'operation', 'publics', 'trucks']
  // forced: não passa last_date para consultar todos os valores
  const getUpdateConstant = async (name, last_name) => {
    // Carrega dados do redux
    const oldData = constants?.[name] || [];

    // Se receber emptyOnly e já existe dados, não prossegue com consulta
    if (emptyOnly && oldData.length > 0) return null;

    // Passa a ultima data de atualizacao
    // Se receber forced, conltar tudo independente da data
    const lastDate = constants && constants[last_name];
    const query = forced ? undefined : { lastDate };

    const res = await api.get(`/select-${name}`, {
      params: { ...query, all: name === 'empresas' ? 0 : 1 },
    });

    if (res.data?.success) {
      const newData = res.data.data || res.data.rows;

      // Retorna resposta inteira do back quando forced
      if (forced) return res.data.data;

      // newData vem [] quando não há dados para atualizar
      if (newData.length > 0) {
        // filtra dados antigos - novos
        const newIds = newData.map(i => i.id);
        const filtered = oldData.filter(i => !newIds.includes(i.id));
        const newStorage = [...filtered, ...newData];
        return newStorage;
      }
      return null;
    }

    toast.error(res.data?.message);
    return null;
  };

  const update = async () => {
    const newConstants = {};

    let names_update = names;
    // Atualiza tudo quando passado "all"
    if (names?.length === 1 && names[0] === 'all') names_update = redux_names;

    const reqArray = names_update.map(async name => {
      const data_name = `last_${name}`;
      const newValues = await getUpdateConstant(name, data_name);
      if (newValues) {
        newConstants[name] = newValues;
        newConstants[data_name] = new Date();
      }
    });
    Promise.all(reqArray).then(() => {
      dispatch(setData(newConstants));
      setLoaded && setLoaded(true);
      setTrigger && setTrigger(false);
    });
  };

  // Atualiza na montagem não sendo necessário usar trigger
  useEffect(() => {
    update();
  }, []);

  // Atualiza ao receber trigger = true.
  // Definir valor inicial = false
  useEffect(() => {
    if (trigger) update();
  }, [trigger]);

  return <></>;
};

export default ConstantsUpdater;
