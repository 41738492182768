import colors from 'styles/colors';
import styled from 'styled-components';
import { Collapse } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
`;

export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  height: calc(100% - 70px);
  position: fixed;
  margin-top: -35px;
  margin-left: -30px;
  background-color: ${colors.white};
  box-shadow: 0px 0px 10px 0px ${colors.greyTiny};
  padding: 60px 0;
  gap: 10px;

  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 50px;
    cursor: pointer;
    font-weight: 600;
    color: ${colors.greySubtitle};
    font-size: 16px;
    border-right: 5px solid transparent;
    transition: all 0.3s;
    position: relative;

    label {
      padding-left: 35px;
    }

    &.active {
      border-right: 5px solid ${colors.greenMain};
      background-color: ${colors.greyBorder};
    }

    &:hover {
      background-color: ${colors.greyBorder};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 260px);
  height: calc(100% - 70px);
  margin-left: 260px;
  padding: 30px 80px;
`;

export const Header = styled.div`
  margin-bottom: 30px;

  .top {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title {
      position: relative;
      margin-right: 10px;
      height: 35px;
      width: 220px;
    }
  }

  .bottom {
    margin-top: 20px;

    .description {
      font-size: 16px;
      font-weight: 600;
      color: ${colors.greySubtitle};
      position: relative;
      height: 25px;
      width: 100%;
    }
  }
`;

export const Box = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.greyTiny};
  box-shadow: 0px 0px 10px 0px ${colors.greyBorder};
  margin-bottom: 40px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
`;

export const Loading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  opacity: ${props => (props.isLoading ? 1 : 0)};
  z-index: ${props => (props.isLoading ? 2 : -1)};
  background-image: linear-gradient(
    to right,
    #e2e2e8 0%,
    #edeef1 20%,
    #e2e2e8 40%,
    #e2e2e8 100%
  );
  background-size: 80rem 14rem;
  animation: placeholderShimmer 3s linear infinite forwards;

  @keyframes placeholderShimmer {
    0% {
      background-position: -40rem 0;
    }
    100% {
      background-position: 40rem 0;
    }
  }
`;

export const BoxHeader = styled.div`
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;

  .texts {
    margin-bottom: 20px;
    .title {
      color: ${colors.greyTitle};
    }

    .description {
      margin-top: 10px;
      font-weight: 500;
      color: ${colors.greySubtitle};
    }
  }

  .progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    .bar {
      width: 100%;
      height: 6px;
      background-color: ${colors.greyBorder};
      border-radius: 5px;
      position: relative;

      .filler {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 5px;
        width: ${props => props.percent}%;
        background-color: ${colors.greenSucces};
        transition: all ease-in-out 0.5s;
      }
    }
    .percentage {
      width: 180px;
      font-size: 14px;
      font-weight: 700;
      color: ${colors.greyTitle};
    }
  }

  .timer {
    margin-top: 20px;
    display: flex;

    .rest {
      font-weight: 600;
      color: ${colors.grey};
      margin-left: 10px;
    }
  }
`;

export const BoxContent = styled(Collapse)``;

export const Module = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  border-top: 1px solid ${colors.greyTiny};
  transition: all ease-in-out 0.4s;
  cursor: pointer;

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    transition: all ease-in-out 0.5s;

    .value {
      font-size: 11px;
      font-weight: 900;
      color: ${colors.greyTitle};
    }
  }

  .infos {
    flex: 8;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .texts {
      .title {
        font-size: 18px;
        color: ${colors.greyTitle};
      }

      .description {
        margin-top: 5px;
        font-size: 16px;
        font-weight: 500;
        color: ${colors.greySubtitle};
      }
    }

    .timer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      width: 160px;

      .time {
        font-size: 15px;
        font-weight: 700;
        color: ${colors.grey};
        margin-left: 5px;
      }
    }
  }

  &:hover {
    .status {
      transform: rotateY(180deg);
    }

    background-color: ${colors.greyBorder};
    transform: scale(1.02);
  }
`;

export const HandleLine = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${colors.greyTiny};
  transition: all ease-in-out 0.1s;
  cursor: pointer;

  .text {
    font-size: 16px;
    font-weight: 700;
    color: ${colors.greyTitle};
    width: 100px;
  }

  &:hover {
    background-color: ${colors.greyBorder};
    opacity: 0.8;
  }
`;
