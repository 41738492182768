import styled from 'styled-components';

const Container = styled.div`
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    display: flex;
    align-items: center;
  }
`;

const Label = styled.p`
  color: ${({ theme, color }) => color || theme.palette.words.label.natural};
  font: normal medium 14px/20px Texta;
  margin-bottom: 5px;
`;

const Required = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme, ...props }) =>
    props.active
      ? theme.palette.semantics.feedback.attention.dark
      : 'transparent'};
`;

const SearchEnterprise = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NameEnterprise = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-left: 22px;
  margin-right: 22px;
  padding: 10px;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.system.divider};
    border-radius: 4px;
  }
  .title {
    text-align: left;
    margin-left: 10px;
    font: normal normal bold 16px/24px Texta;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.palette.words.title.natural};
  }
  .subtitle {
    text-align: left;
    font: normal normal bold 14px/24px Texta;
    margin-left: 10px;
    line-height: 15px;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.palette.words.subtitle.light};
  }
`;

const ButtonAdd = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.brand.primary.light};
  border-radius: 4px;
  height: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  font: normal normal 900 14px/18px Texta;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }
`;

export {
  Label,
  Required,
  Container,
  ButtonAdd,
  SearchEnterprise,
  NameEnterprise,
};
