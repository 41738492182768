import api from 'services/api';

export const getCards = async (id, params) => {
  const res = await api.get(`/empresas/${id}/cards`, { params });
  return res.data;
};

export const getRadarDesvio = async (tab, params) => {
  const res = await api.get(
    tab === 'ptsH' ? `/desempenho-pts-hora` : `/desempenho-pts-km`,
    {
      params,
    },
  );
  return res.data;
};

export const getHistorico = async (id, params) => {
  const res = await api.get(`/empresas/${id}/historico`, {
    params,
  });
  return res.data;
};

export const getRadarDeRisco = async (id, params) => {
  const res = await api.get(`/empresas/${id}/radar`, {
    params,
  });
  return res.data;
};

export const getFatoresRiscoGraves = async (id, params) => {
  const res = await api.get(`/empresas/${id}/graves`, { params });
  return res.data;
};

export const getMotoristasSuspensos = async (id, params) => {
  const res = await api.get(`/empresas/${id}/motoristas-suspensos`, { params });
  return res.data;
};

export const getVariacao = async (id, params) => {
  const res = await api.get(`/empresas/${id}/aumento-mes`, { params });
  return res.data;
};
