import api from 'services/api';

export const GetContents = async () => {
  const res = await api.get('/training/contents');
  return res.data;
};

export const GetConteudo = async id => {
  const res = await api.get(`/training/content/${id}`);
  return res.data;
};

export const UseTemplate = async id => {
  const res = await api.get(`/training/template/${id}`);
  return res.data;
};
