import styled, { css } from 'styled-components';

const SpacedRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CenteredCell = styled.div`
  text-align: center;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableName = styled.h3`
  ${({ theme, cor }) => css`
    color: ${cor ?? theme.palette.words.title.natural};
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
  `}
`;

const TableSubname = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.words.subtitle.natural};
    font-weight: 600;
    font-size: 13px;
  `}
`;

const TableTwoInputs = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.div`
  width: 90px;
  padding: 4px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  background-color: ${props => `${props.color}33`};

  p {
    color: ${props => props.color};
    font: normal normal bold 14px/14px Texta;
  }
`;

export {
  Main,
  TableName,
  TableSubname,
  TableTwoInputs,
  Status,
  SpacedRow,
  CenteredCell,
};
