import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  status: '',
  responsavel: '',
  tipo: '',
  tab: 0,
};

export default function filterPad(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER: {
        draft.status = action.payload.query.status;
        draft.responsavel = action.payload.query.responsavel;
        draft.tipo = action.payload.query.tipo;
        draft.tab = action.payload.query.tab;
        break;
      }
      default:
    }
  });
}
