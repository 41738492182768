import styled, { css } from 'styled-components';

export const DesvioBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 5px;
    margin-top: 20px;
    display: flex;
    cursor: pointer;

    .imgArea {
      width: 348px;
      height: 182px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px 0px 0px 5px;
      }
    }

    .contentArea {
      color: ${theme.palette.words.text.natural};
      font-size: 12px;
      font-weight: 600;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .top {
        display: flex;
        justify-content: space-between;

        .date {
          margin-right: 20px;
        }

        .id {
        }

        .actionsSide {
          display: flex;
          align-items: center;

          span {
            margin-right: 20px;
            font-size: 15px;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .medium {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin: 20px 0px;

        .dsTitle {
          color: ${theme.palette.words.title.natural};
          font-size: 18px;
          font-weight: bold;
        }

        .name {
          font-size: 14px;
          margin-top: 5px;
          font-weight: 500;
          text-transform: capitalize;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;

        .status {
          display: flex;
          align-items: center;

          .ball {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: ${props => props.color};
          }

          .text {
            margin-left: 10px;
            font-size: 15px;
            font-weight: bold;
            text-transform: capitalize;
            color: ${props => props.color};
          }
        }

        .type {
          font-size: 15px;

          span {
            padding: 2px 15px;
            border-radius: 30px;
            margin-left: 5px;
          }
        }
      }
    }
  `}
`;

export const ItemPopover = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.words.title.natural};
    font-size: 16px;
    font-weight: 600;
  `}
`;
