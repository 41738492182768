// Styles
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import React, { useState, useEffect, useRef } from 'react';

// Components
import Loading from 'components/Loading';
import TableLocal from 'components/TableLocal';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { Divider, Modal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import Filters from '../Filters';
import * as S from './style.js';

// Utils
import { columnsTransportadoras } from './columns';
import {
  getTransportadoras,
  createTurmaTransportadora,
  inativateTransportadora,
} from './services';

const Transportadoras = ({
  openModalAluno,
  behaviorCloseModal,
  id_turma,
  fetchData,
  tipoUser,
}) => {
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  // tabelas
  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedRow, setSelectedRow] = useState('');

  const [dataTransportadora, setDataTransportadora] = useState([]);
  const [dataTransportadoraBk, setDataTransportadoraBk] = useState([]);

  const [totalFilters, setTotalFilters] = useState(0);
  const [actions, setActions] = useState([]);

  const fetchUsers = async () => {
    setLoading(true);
    const res = await getTransportadoras({ ...tipoUser, id_turma });
    if (res.data.success) {
      const { transportadoras } = res.data;
      setDataTransportadora(transportadoras);
      setDataTransportadoraBk(transportadoras);
    } else {
      toast.error(res.data.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchUsers();
  }, [openModalAluno]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAcron = str => {
    const matches = str.match(/\b(\w)/g);
    const acronym = matches.join('');
    return acronym.toUpperCase();
  };

  const formatTable = arr => {
    arr.forEach(i => {
      if (i.id === 'id') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.id}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'nome') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <div className="container-img-name">
                <Avatar
                  alt={getAcron(row.original.nome)}
                  src={row.original.logo_url}
                  sx={{ marginRight: 1 }}
                />
                <S.Paragraph fontweight="bold" color="#494f65">
                  {row.original.nome}
                </S.Paragraph>
              </div>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'atividades') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.atividades_relacionadas}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'ativo') {
        i.Cell = function ({ row }) {
          let colorText = '';
          let colorBg = '';

          switch (row.original.status) {
            case 'ATIVO':
              colorText = colors.greenSucces;
              colorBg = colors.greenBackground;
              break;
            default:
              colorText = colors.redDanger;
              colorBg = colors.redBackground;
              break;
          }

          return (
            <S.ColDriver>
              <S.Paragraph className="status" color={colorText} bg={colorBg}>
                {row.original.status}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      }
    });
    return arr;
  };
  const bulkActions = [
    {
      title: 'Adicionar Transportadoras',
      function: () => AdicionarTransportadora(),
    },
  ];

  const sortBy = [];

  const AdicionarTransportadora = async () => {
    const transportadoras = [];
    selectedRow.map(id => {
      transportadoras.push(+id);
    });
    const res = await createTurmaTransportadora({
      id_turma: Number(id_turma),
      transportadoras,
    });
    if (res.data.success) {
      toast.success(res.data.message);
      behaviorCloseModal();
      fetchData();
    } else {
      toast.error(res.data.message);
    }
  };
  const openFilter = Boolean(anchorEl);

  const id = openFilter ? 'simple-popover' : undefined;

  const handleSetExcluir = async id => {
    const data = dataTransportadora.find(
      item => Number(item.id) === Number(id),
    );
    const status = !data.status ? 'ATIVO' : 'INATIVO';

    const res = await inativateTransportadora({
      id_turma: +id_turma,
      transportadoras: [+id],
    });
    if (res.data.success) {
      toast.success(res.data.message);
      fetchUsers();
    } else {
      toast.error(res.data.message);
    }
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={openModalAluno}
      onClose={behaviorCloseModal}
      disableEscapeKeyDown={false}
    >
      <S.Container>
        {loading && <Loading />}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <div className="leftHeader">
            <LocalShippingOutlinedIcon htmlColor={colors.greenMain} />
            <h1>Selecionar transportadoras</h1>
          </div>
          <IconButton onClick={() => behaviorCloseModal()}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <Divider />
        <div className="table-container">
          <div
            style={{
              opacity: loading ? 0 : 1,
              pointerEvents: loading ? 'none' : 'all',
            }}
          >
            <TableLocal
              columns={formatTable(columnsTransportadoras)}
              pageSizes={4}
              data={dataTransportadora}
              heightEmpty="200px"
              sortBy={sortBy}
              permitIsSortedOccur
              tableType
              actions={actions}
              setSelectedData={id => {
                setSelectedRow(id);
                setActions([
                  {
                    name: 'inserir',
                    action: async id => {
                      const res = await createTurmaTransportadora({
                        id_turma: Number(id_turma),
                        transportadoras: [+id],
                      });
                      if (res.data.success) {
                        toast.success(res.data.message);
                        behaviorCloseModal();
                        fetchData();
                      } else {
                        toast.error(res.data.message);
                      }
                    },
                    text: 'Inserir',
                  },
                  {
                    name: 'excluir',
                    action: id => handleSetExcluir(id),
                    text: 'Excluir',
                  },
                ]);
              }}
              setSelectedRow={val => {
                setSelectedClass(val);
              }}
              bulkActions={bulkActions}
              empty={{
                image: '',
                title: 'Seus alunos aparecerão aqui!',
                subtitle: '',
              }}
              search={{
                inputLabel: 'Buscar Cliente',
                keys: ['nome', 'filial_name'],
              }}
            />
          </div>
        </div>
        <div className="button-container">
          <GhostButton onClick={() => behaviorCloseModal()}>
            CANCELAR
          </GhostButton>
          <div style={{ marginRight: 10 }} />
          <DefaultButton onClick={AdicionarTransportadora}>
            SALVAR
          </DefaultButton>
        </div>
      </S.Container>
    </Modal>
  );
};

export default Transportadoras;
