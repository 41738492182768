import React, { useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';

import { differenceInDays } from 'date-fns';
import { paletteColors } from '../../../styles/colors';

// components custom
import { Skeleton } from '@mui/material';

// styles
import * as S from './styled';
import Tabs from 'components/FiltersGlobal/Tabs';
import { useState } from 'react';

import { formatarNome, getGroup, getInterval } from './util';

const tabsDates = [
  { value: 'day', label: 'D' },
  { value: 'week', label: 'S' },
  { value: 'month', label: 'M' },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      >
        <p>{label}</p>
        <p>{payload?.[0]?.payload?.key}</p>
        {payload.map(item => (
          <>
            {/* ??? */}
            <p style={{ color: item.color || '#000' }}>
              {`${item.name} : ${item.value}`}
            </p>
          </>
        ))}
      </div>
    );
  }

  return null;
};

const CustomTick = props => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#95AAC9"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

const CustomLegend = ({ payload }) => (
  <div
    style={{
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
      marginBottom: '8px',
    }}
  >
    {payload.map((entry, index) => (
      <div
        key={`item-${index}`}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {/* Circulo com a cor da linha */}
        <div
          style={{
            width: 14,
            height: 14,
            backgroundColor: entry.color,
            borderRadius: '50%',
            marginRight: 5,
          }}
        />
        {/* Label */}
        <span
          style={{
            color: '#4B5166',
            fontWeight: 500,
            textTransform: 'capitalize',
          }}
        >
          {entry.value}
        </span>
      </div>
    ))}
  </div>
);

export const History = ({
  titulo = '',
  data = [],
  series = ['x'],
  series_divs = [],
  calcDistinct = false,
  initialDate,
  finalDate,
  loading,
  leftHeaderComponent,
  hidePeriods,
  initialPeriod = 'day',
}) => {
  const [renderData, setRenderData] = useState([]);
  const [interval, setInterval] = useState('auto');
  const [periodDate, setPeriodDate] = useState(initialPeriod);

  useEffect(() => {
    const formatRenderData = () => {
      // Retiorna vazio para data invalida ou vazia
      if (!data?.length || !series?.length) {
        setRenderData([]);
        return;
      }
      const difference = differenceInDays(finalDate, initialDate);
      const _data = data?.reduce((acumulador, item) => {
        const group = getGroup(periodDate, difference);

        const { meta } = item;
        const name = formatarNome(item, group);
        const key = formatarNome(item, periodDate);
        const existente = acumulador.find(r => r.key === key);

        if (existente) {
          series.forEach(serie => {
            // Divisor
            if (series_divs?.length) {
              const key_div = `${serie}_${series_divs[0]}`;
              const key_div2 = `${serie}_${series_divs[1]}`;
              if (item[key_div]) existente[key_div] += item[key_div];
              if (item[key_div2]) existente[key_div2] += item[key_div2];
            }
            // Valor acumulavel
            else {
              if (item[serie]) {
                // Add , para acumulo de stringArray:  '1,2,3' + ',' + 'item[serie]'
                if (calcDistinct) existente[serie] += ',';
                if (item[serie]) existente[serie] += item[serie];
              }
            }
          });
        } else {
          const newObj = { name, key, meta };
          series.forEach(serie => {
            // Divisor
            if (series_divs?.length) {
              const key_div = `${serie}_${series_divs[0]}`;
              const key_div2 = `${serie}_${series_divs[1]}`;
              if (key_div in item) newObj[key_div] = item[key_div];
              if (key_div2 in item) newObj[key_div2] = item[key_div2];
            }
            // Valor acumulavel
            else {
              newObj[serie] = item[serie];
            }
          });
          acumulador.push(newObj);
        }

        return acumulador;
      }, []);
      // Calcula seria composta de divisao

      if (series_divs?.length) {
        for (const item of _data) {
          for (const serie of series) {
            item[serie] = 0;
            const key_div = `${serie}_${series_divs[0]}`;
            const key_div2 = `${serie}_${series_divs[1]}`;
            if (item[key_div] && item[key_div2]) {
              item[serie] = Number((item[key_div] / item[key_div2]).toFixed(2));
            }
          }
        }
        // console.log(_data);
        // console.log(data);
      }
      // Quantidade de valor distinto da stringArray
      // Ex: '1,2,3,4,5,2' >> [1,2,3,4,5] >> 5
      else if (calcDistinct) {
        for (const item of _data) {
          for (const serie of series) {
            const stringArray = item[serie];
            const uniqueValues = Array.from(
              new Set(stringArray.split(',').map(Number)),
            );
            item[serie] = uniqueValues.length;
          }
        }
      }

      setRenderData(_data);

      setInterval(getInterval(periodDate, difference));
    };
    formatRenderData();
  }, [data, series, initialDate, finalDate, periodDate]);

  return (
    <S.Container height={leftHeaderComponent ? '380px' : '320px'}>
      <div className="container-filters">
        <div>
          <h1 className="graphtitle">{titulo}</h1>
          {leftHeaderComponent && leftHeaderComponent}
        </div>
        <div className="titletop">
          {!hidePeriods && (
            <Tabs
              tab={periodDate}
              tabs={tabsDates}
              handleApplay={setPeriodDate}
            />
          )}
        </div>
      </div>
      {loading ? (
        <Skeleton
          style={{ margin: '1rem' }}
          width="98%"
          height={174}
          animation="wave"
          variant="rounded"
        />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            height={300}
            data={renderData}
            margin={{ top: 10, right: 40, left: 20, bottom: 5 }}
          >
            {renderData.length === 0 && (
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={22}
                fill="#999"
              >
                Nenhum dado encontrado, tente usar outros filtros.
              </text>
            )}
            <CartesianGrid vertical={false} />
            <XAxis
              opacity={0.5}
              padding={{ left: 0, right: 0 }}
              dataKey="name"
              height={70}
              tick={<CustomTick />}
              tickLine={false}
              interval={interval}
            />

            {renderData.length !== 0 && (
              <YAxis
                opacity={0.5}
                padding={{ left: 0, right: 0 }}
                tickLine={false}
                minTickGap={-20}
              />
            )}
            <Tooltip content={<CustomTooltip />} />

            {series.map((serie, idx) => (
              <Line
                type="linear"
                dataKey={serie}
                name={serie}
                stroke={paletteColors.default[idx]}
                dot={{ r: 0 }}
              />
            ))}

            {data.some(item => item.meta) && (
              <Line
                type="linear"
                dataKey="meta"
                name="Meta"
                stroke="#F64E60"
                strokeDasharray={['10 10']}
                dot={{ r: 0 }}
                activeDot={{ r: 0 }}
              />
            )}

            <Legend content={<CustomLegend />} />
          </LineChart>
        </ResponsiveContainer>
      )}
    </S.Container>
  );
};
