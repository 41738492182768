import { useState } from 'react';
import { Divider, Grid, IconButton } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLess from '@mui/icons-material/ExpandLessOutlined';
import MuiCollapse from '@mui/material/Collapse';
import { Switch } from 'components/Inputs/Switch';
import * as S from './styled';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import * as api from '../services';

const frequencias = [
  { value: 'DIARIA', label: 'Diário', description: '(Todo dia às 00h00)' },
  { value: 'SEMANAL', label: 'Semanal', description: '(Toda segunda-feira)' },
  {
    value: 'MENSAL',
    label: 'Mensal',
    description: '(Todo primeiro dia do mês)',
  },
];

export const Item = ({ item, refetch }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ ...item });
  const [loading, setLading] = useState(false);

  const verifySave = data => {
    if (!data.frequencia) {
      toast.error('Selecione a frequência de envio!');
      return;
    }
    handleSave(data);
  };

  const handleSave = async data => {
    setLading(true);
    const res = await api.saveRelatorioPermission(data);
    setLading(false);

    if (res?.success) {
      if (refetch instanceof Function) refetch();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const handleToggleRelatorioPermission = data => {
    setData({ ...data, ativo: !data.ativo });
    if (data.id) {
      verifySave({ ...data, ativo: !data.ativo });
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <S.Section>
          <S.SectionTitle>
            <Switch
              check={!!data.ativo}
              setCheckbox={() => handleToggleRelatorioPermission(data)}
              textOn=""
              textOff=""
            />
            <div onClick={() => setOpen(state => !state)}>
              <h2>{data.nome}</h2>
              <span>
                {!data.ativo
                  ? 'desativado'
                  : frequencias.find(f => f.value === data.frequencia)?.label}
              </span>
            </div>
          </S.SectionTitle>
          <IconButton size="small" onClick={() => setOpen(state => !state)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </S.Section>
      </Grid>
      <MuiCollapse in={open} timeout="auto">
        <S.SectionDetails>
          <div>
            <span>Para:</span>

            <div className="tag-email">{data.usuario.email}</div>
          </div>
          <div style={{ width: '100%' }}>
            <span>Escolha a frequência:</span>

            <div className="flex">
              {frequencias.map(freq => (
                <S.FrequencyButton
                  active={freq.value === data.frequencia}
                  key={freq.value}
                  onClick={() =>
                    setData(state => ({ ...state, frequencia: freq.value }))
                  }
                >
                  <div>{freq.label}</div>
                  <div>{freq.description}</div>
                </S.FrequencyButton>
              ))}
            </div>
            {data.observacao && (
              <div className="info">
                <ErrorOutlineOutlinedIcon sx={{ fontSize: '18px' }} />
                {data.observacao}
              </div>
            )}
          </div>
        </S.SectionDetails>
        <S.SectionActions>
          <GhostButton
            onClick={() => {
              setData({ ...item });
              setOpen(false);
            }}
          >
            Cancelar
          </GhostButton>
          <DefaultButton
            disabled={isEqual(item, data)}
            onClick={() => verifySave(data)}
            loading={loading}
          >
            Salvar
          </DefaultButton>
        </S.SectionActions>
      </MuiCollapse>
      <Divider />
    </>
  );
};
