import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import * as S from './styled';

export const MediaView = ({ item }) => {
  const [typeTag, setTypeTag] = useState('');

  useEffect(() => {
    if (item?.tipo === 'VÍDEO_UPLOADING' || item?.tipo === 'PDF')
      setTypeTag('upload');
    if (item?.tipo === 'VÍDEO') setTypeTag('player');
    if (item?.tipo === 'IMAGEM') setTypeTag('img');
    if (item?.tipo === 'TEXTO') setTypeTag('text');
  }, [item]);

  return (
    <S.Cotainer>
      <S.Title>{item?.titulo}</S.Title>
      <S.Description>{item?.descricao}</S.Description>

      {typeTag === 'upload' && <S.MediaVideo src={item?.link} />}
      {typeTag === 'img' && <S.MediaImage src={item?.link} />}
      {typeTag === 'text' && (
        <S.MediaText dangerouslySetInnerHTML={{ __html: item?.link }} />
      )}
      {typeTag === 'player' && (
        <S.ContainerMedia>
          <ReactPlayer
            width="100%"
            height="500px"
            controls
            playing={false}
            url={item?.link}
          />
        </S.ContainerMedia>
      )}
    </S.Cotainer>
  );
};
