import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const CardContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;
    color: ${theme.palette.words.subtitle.natural};
  `}
`;

export const CardHeader = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: center;
`;

export const Contact = styled.div`
  ${({ theme }) => css`
    display: flex;
    max-width: 14rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
    cursor: pointer;
    background: ${theme.palette.brand.primary.background};
    border-radius: 4px;
    border: 1px solid ${theme.palette.system.border};
    padding: 1rem 2rem;

    &:hover {
      background: ${theme.palette.system.border};
    }

    p {
      font: normal normal 900 16px/19px Texta;
      color: ${theme.palette.words.title.natural};
    }
  `}
`;

export const Link = styled.a`
  ${({ theme }) => css`
    text-decoration: underline;
    font: normal normal medium 13px/19px Texta;
    color: ${theme.palette.words.subtitle.natural};
    &:hover {
      color: ${theme.palette.words.title.natural};
    }
  `}
`;

export const IconCircle = styled.div`
  ${({ theme }) => css`
    width: 2.5rem;
    height: 2.5rem;
    background: ${theme.palette.system.overlay};
    border-radius: 50%;
    border: 2px solid ${theme.palette.system.border};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
