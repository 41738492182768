import styled, { css } from 'styled-components';

export const Space = styled.div`
  margin-top: 20px;
`;

export const Square = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    font-size: 16px;
    display: flex;
    border-radius: 5px;
    border: 1px solid ${theme.palette.system.divider};
    background-color: #fff;
    padding: 8px;
  `}
`;

export const ContainerQuiz = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  .limit-chars {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Quiz = styled.div`
  ${({ theme }) => css`
    flex-wrap: wrap;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    width: 60vw;
    border-radius: 5px 0px 0px 5px;
    border: 1px ${theme.palette.words.text.light};
    border-style: solid none solid solid;
  `}
`;

export const Teste = styled.div`
  ${({ theme }) => css`
    flex-wrap: wrap;
    flex-direction: column;
    background-color: #fff;
    padding: 15px;
    width: 40vw;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid ${theme.palette.words.text.light};
  `}
`;

export const ListItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 14px;
    padding-right: 14px;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    height: 50px;
    background: ${p =>
      p.seleted ? theme.palette.system.border : theme.palette.system.overlay};
  `}
`;

export const containerQuiz = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 150px;
    overflow: hidden;
    .radio-container {
      width: 50%;
    }
    .opcoes {
      width: 50%;
      color: ${theme.palette.words.title.natural};
    }
  `}
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
