import * as types from './types';

export function setFilterProvider(query) {
  return {
    type: types.SET_FILTER_PROVIDER,
    payload: { ...query },
  };
}

export function setSortBy(query) {
  return {
    type: types.SET_SORT_BY,
    payload: query,
  };
}

export function ResetFilterProvider() {
  return {
    type: types.RESET_FILTER_PROVIDER,
  };
}
