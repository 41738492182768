import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Grid, TextField } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { formatNewHour, formatNewDate } from 'utils/dates';
import Loading from 'components/Loading';

// Service

// Styled Components

// Components
import Button from 'components/Buttons/Default';
import Icon from 'components/Icons';
import { TooltipCustom } from 'components/Tooltip';

// Images
import * as S from './styled';
import { showLayout } from '../services';

const LayoutDetail = () => {
  const theme = useTheme();
  const { id } = useParams();
  const {
    user: { user },
  } = useSelector(state => state.auth);

  const [posto, setPosto] = useState(null);

  const [evidencyData, setEvidencyData] = useState([]);

  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loading, setLoading] = useState(true);
  const [col1, setCol1] = useState([]);
  const [col2, setCol2] = useState([]);
  const componentRef = useRef();

  const navigate = useNavigate();

  const setValuesData = () => {
    const raizen = [
      {
        label: 'Nome do Executor',
        value: posto.executor?.nome,
      },
      {
        label: 'Função',
        value: posto.executor?.funcao,
      },
      {
        label: 'Transportadora',
        value: posto.executor?.filial?.empresa?.nome,
      },
      {
        label: 'Filial',
        value: posto.executor?.filial?.nome,
      },
      {
        label: 'Cliente',
        value: posto.nome,
      },
      {
        label: 'IBM. Cliente',
        value: posto.ibm,
      },
      {
        label: 'Data',
        value: formatNewHour(new Date(posto.createdAt)),
      },
    ];

    const ipiranga = [
      {
        label: 'Nome do Executor',
        value: posto.executor?.nome,
      },
      {
        label: 'Função',
        value: posto.executor?.funcao,
      },
      {
        label: 'Transportadora',
        value: posto.executor?.filial?.empresa?.nome,
      },
      {
        label: 'Filial',
        value: posto.executor?.filial?.nome,
      },
      {
        label: 'Cliente',
        value: posto.nome,
      },
      {
        label: 'CNPJ',
        value: posto.ibm,
      },
      {
        label: 'Data',
        value: formatNewHour(new Date(posto.createdAt)),
      },
      {
        label: 'Tipo de Cliente',
        value: posto.tipo_de_cliente,
      },
      {
        label: 'Tipo de Tancagem',
        value: posto.tipo_de_tancagem,
      },
    ];

    const columns2 = [
      {
        label: 'Pessoa de Contato',
        value: posto.contato,
      },
      {
        label: 'Telefone de Contato',
        value: posto.telefone_contato,
      },
      {
        label: 'Base/Terminal',
        value: posto.base?.nome,
      },
      {
        label: 'Endereço',
        value: posto.endereco,
      },
      {
        label: 'Janela Horário de Recebimento',
        value: posto.janela_horario,
      },
      {
        label: 'Tamanho Máximo do Caminhão',
        value: posto.tamanho_maximo,
      },
    ];

    setCol1(posto?.id_da_distribuidora === 1 ? raizen : ipiranga);
    setCol2(columns2);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => setLoadingPDF(true),
    onAfterPrint: () => setLoadingPDF(false),
    documentTitle: `Layout_${id}`,
    pageStyle: `@page
    { size: auto; margin: 0mm; }
    @media print { body { -webkit-print-color-adjust: exact; padding: 20px !important; } }`,
  });

  const fetchLayout = async () => {
    const res = await showLayout(id);
    setLoading(false);
    setPosto(res.posto);
    setEvidencyData(res.posto.imagens);
  };

  // Carregamento inicial da página
  useEffect(() => {
    fetchLayout();
  }, []);

  useEffect(() => {
    if (posto) {
      setValuesData();
    }
  }, [posto]);

  // Componentes da página
  const renderFirstColumn = () => {
    return (
      col1 && (
        <S.StyledCard>
          <S.StyledCardHeader>
            <h1>Informações do Layout</h1>
          </S.StyledCardHeader>
          <Grid xs={12}>
            <Grid container spacing={1} padding={1}>
              <Grid item xs={6}>
                {col1.map(({ label, value }) => (
                  <>
                    <div className="normalText">{label} </div>
                    <div className="inputBox">
                      <p>{value}</p>
                    </div>
                  </>
                ))}
              </Grid>
              <Grid item xs={6}>
                {col2.map(({ label, value }) => (
                  <>
                    <div className="normalText">{label} </div>
                    <div className="inputBox">
                      <p>{value}</p>
                    </div>
                  </>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </S.StyledCard>
      )
    );
  };

  const signatureCards = () => {
    return (
      <>
        <S.StyledCard>
          <S.StyledCardHeader>
            <h1>Avaliadores</h1>
          </S.StyledCardHeader>
          <Grid xs={12}>
            <Grid container spacing={1} padding={1}>
              <Grid item xs={6}>
                <>
                  <div className="normalText">Nome executor </div>
                  <div className="inputBox">
                    <p>
                      {posto?.executor?.nome?.toLowerCase() ||
                        'Aguardando Assinatura'}
                    </p>
                  </div>
                </>
              </Grid>
              <Grid item xs={6}>
                <>
                  <div className="normalText">Função do executor </div>
                  <div className="inputBox">
                    <p>{posto?.executor?.funcao?.toLowerCase() || ''}</p>
                  </div>
                </>
              </Grid>
              <Grid item xs={6}>
                <>
                  <div className="normalText">Nome do aprovador </div>
                  <div className="inputBox">
                    <p>
                      {posto?.aprovador?.nome?.toLowerCase() ||
                        'Aguardando Assinatura'}
                    </p>
                  </div>
                </>
              </Grid>
              <Grid item xs={6}>
                <>
                  <div className="normalText">Função do aprovador" </div>
                  <div className="inputBox">
                    <p>{posto?.aprovador?.funcao?.toLowerCase() || ''}</p>
                  </div>
                </>
              </Grid>
            </Grid>
          </Grid>
        </S.StyledCard>
      </>
    );
  };

  const renderCroquiCard = () => {
    const texts = {
      items: [
        '1 - Acesso e saída do CT;',
        '2 - Edificações;',
        '3 - Ilhas das bombas de abastecimento;',
        '4 - Percurso na instalação;',
        '5 - Localização dos tanques de recebimento/respiros;',
        '6 - Nome e nº das ruase respectivos sentidos;',
        '7 - Outras particularidades;',
      ],
    };

    return (
      posto?.arquivo_croqui && (
        <S.StyledCard>
          <S.StyledCardHeader>
            <h1>Local de Entrega / Croqui</h1>
          </S.StyledCardHeader>
          <Grid xs={12}>
            <Grid container spacing={2} padding={3}>
              <Grid item md={12}>
                <img src={posto?.arquivo_croqui} width="90%" />
              </Grid>
            </Grid>
            {/* <Grid item md={12}>
              <S.StyledTitle>Identificar no Croqui</S.StyledTitle>
              <Grid item>
                <S.StyledIdentificarCroquiCard>
                  <div>
                    {texts.items.map(txt => (
                      <p>{txt}</p>
                    ))}
                  </div>
                </S.StyledIdentificarCroquiCard>
              </Grid>
            </Grid> */}
          </Grid>
        </S.StyledCard>
      )
    );
  };

  const renderTankMappingCard = () => {
    const texts = {
      title: 'Mapeamento de Tanques',
      number: 'TANQUE Nº',
      product: 'PRODUTOS',
      capacity: 'CAPACIDADE (LITROS)',
      disk: 'DISCO',
    };

    return (
      <S.StyledCard>
        <S.StyledCardHeader>
          <h1>{texts.title}</h1>
        </S.StyledCardHeader>
        <Grid item xs={12} padding={1}>
          <S.StyledCard container>
            <Grid>
              <S.Header item xs={12} style={{ textAlign: 'left' }}>
                <Grid container>
                  <Grid item xs={1}>
                    <S.ColumnTitle>{texts.disk}</S.ColumnTitle>
                  </Grid>
                  <Grid item xs={2} paddingLeft="20px">
                    <S.ColumnTitle style={{ width: '100px' }}>
                      {texts.number}
                    </S.ColumnTitle>
                  </Grid>
                  <Grid item xs={3} paddingLeft="20px">
                    <S.ColumnTitle>{texts.product}</S.ColumnTitle>
                  </Grid>
                  <Grid item paddingLeft="30px">
                    <S.ColumnTitle>{texts.capacity}</S.ColumnTitle>
                  </Grid>
                </Grid>
              </S.Header>
              <Grid item xs={12} marginTop="10px">
                {posto?.tanques?.map(
                  (tanque, idx) =>
                    !tanque.isDeleted && (
                      <Grid>
                        <Grid container marginBottom="25px">
                          <Grid
                            item
                            paddingRight="32px"
                            paddingLeft="23px"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <S.TanquesDisco
                              style={{
                                backgroundColor: tanque.produto
                                  ? tanque.produto.disco
                                  : null,
                                borderColor:
                                  tanque.produto &&
                                  tanque.produto.disco ===
                                    theme.palette.system.white
                                    ? theme.palette.system.black
                                    : theme.palette.system.transparent,
                              }}
                            />
                          </Grid>
                          <Grid item xs={2} paddingRight="32px">
                            <TextField
                              style={{ height: '50px' }}
                              variant="filled"
                              value={idx + 1}
                              // onChange={(e) => handleOnChangeTanque(idx, 'numero', e.target.value)}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} paddingRight="32px">
                            <TextField
                              style={{ height: '50px' }}
                              variant="outlined"
                              value={
                                tanque.produto ? tanque.produto.produto : null
                              }
                              // onChange={(e) => handleOnChangeTanque(idx, 'numero', e.target.value)}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            paddingRight="18px"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <TextField
                              style={{ height: '50px' }}
                              defaultValue={tanque.capacidade}
                              fullWidth
                              variant="outlined"
                              value={
                                tanque.capacidade ? tanque.capacidade : null
                              }
                              // onChange={(e) => handleOnChangeTanque(idx, 'capacidade', e.target.value)}
                              onKeyPress={event => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ),
                )}
              </Grid>
            </Grid>
          </S.StyledCard>
        </Grid>
      </S.StyledCard>
    );
  };

  const renderChecklistCard = () => {
    return (
      <S.StyledCard>
        <S.StyledCardHeader>
          <h1>
            {' '}
            Check-List de Riscos do Local de Entrega - Postos B2B e Aviação
          </h1>
        </S.StyledCardHeader>
        <Grid item xs={12}>
          <S.StyledCard container>
            <Grid flex-direction="column" padding={1}>
              {posto?.respostas
                ?.sort((a, b) => a.id_da_questao - b.id_da_questao)
                .map((i, idx) => (
                  <S.QuestionRow>
                    <S.Item width="50px">
                      <S.Paragraph marginleft="5px" fontweight={900}>
                        {`${idx + 1})`}
                      </S.Paragraph>
                    </S.Item>
                    <S.Item width="100%">
                      <Grid container>
                        <Grid item>
                          <S.Paragraph fontweight={900}>
                            {i.questao.texto_da_pergunta}
                          </S.Paragraph>
                        </Grid>
                        <Grid item marginLeft="5px">
                          <S.IconContainer
                            id={i.id_da_questao}
                            key={i.id_da_questao}
                          >
                            <InfoOutlinedIcon
                              htmlColor={
                                i.questao.cae === 1
                                  ? theme.palette.system.black
                                  : theme.palette.system.white
                              }
                            />
                            {i.questao.cae === 1 && (
                              <TooltipCustom
                                text="Ao responder este item como não, será aberta uma CAE."
                                svgName="openCae"
                                parentEl={i.id_da_questao}
                                left="-37"
                                right="0"
                                bottom="0"
                                top="74"
                                arrow="top"
                              />
                            )}
                          </S.IconContainer>
                        </Grid>
                      </Grid>
                    </S.Item>
                    <S.Item width="150px" marginright="5px" marginleft="5px">
                      <S.Button
                        name="resposta"
                        value={i.resposta ? i.resposta : 'SIM'}
                      >
                        <div type="text">Sim</div>
                        <div type="text">Não</div>
                      </S.Button>
                    </S.Item>
                  </S.QuestionRow>
                ))}
            </Grid>
          </S.StyledCard>
        </Grid>
      </S.StyledCard>
    );
  };

  const renderEvidenceCard = () => {
    return (
      (posto?.observacoes || evidencyData.length > 0) && (
        <S.StyledCard>
          <S.StyledCardHeader>
            <h1>Imagens e Observações adicionais </h1>
          </S.StyledCardHeader>
          <Grid item xs={12}>
            <S.StyledCard container style={{ padding: '24px' }}>
              <Grid container spacing={3}>
                <Grid item>
                  <Grid container gap="25px">
                    {evidencyData.length > 0 &&
                      evidencyData.map(item => (
                        <Grid
                          container
                          key={item.imagem_url}
                          style={{
                            position: 'relative',
                            height: '220px',
                            width: '225px',
                          }}
                        >
                          <S.StorageImage
                            src={item.imagem_url}
                            alt={item.imagem_url}
                          />
                          <TextField
                            style={{
                              width: '225px',
                              marginTop: '155px',
                              position: 'absolute',
                            }}
                            value={item.legenda}
                            variant="outlined"
                            label="Legenda:"
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} paddingBottom="10px">
                  {posto.observacoes && (
                    <>
                      <S.StyledObs>Observações</S.StyledObs>
                      <TextField
                        style={{ width: '100%' }}
                        multiline
                        variant="outlined"
                        value={posto.observacoes}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </S.StyledCard>
          </Grid>
        </S.StyledCard>
      )
    );
  };

  const renderPage = () => {
    return (
      <>
        <Grid item md={12} lg={12}>
          {renderFirstColumn()}
        </Grid>
        <Grid item md={12} lg={12}>
          {signatureCards()}
        </Grid>

        <Grid item md={12} lg={12}>
          {renderCroquiCard()}
        </Grid>
        <Grid item md={12} lg={12} />
        <Grid item md={12} lg={12}>
          {renderTankMappingCard()}
        </Grid>
        <Grid item md={12} lg={12}>
          {renderChecklistCard()}
        </Grid>
        <Grid item md={12} lg={12}>
          {renderEvidenceCard()}
        </Grid>
      </>
    );
  };

  const renderTemplatePage = () => {
    return (
      <>
        <Grid container spacing={2}>
          <S.ButtonContainer>
            {!loadingPDF ? (
              <Button
                disable={loadingPDF}
                loading={loadingPDF}
                onClick={handlePrint}
                variant="contained"
                color="primary"
                startIcon={
                  <Icon
                    name="download-outline"
                    color={theme.palette.system.overlay}
                  />
                }
              >
                Download PDF
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  <Icon
                    name="download-outline"
                    color={theme.palette.system.overlay}
                  />
                }
              >
                Download PDF
              </Button>
            )}
            {loadingPDF ? (
              <h1
                style={{
                  color: theme.palette.brand.primary.natural,
                  fontSize: '20px',
                }}
              >
                Download do PDF sendo realizado...
              </h1>
            ) : (
              <div />
            )}

            <S.CancelButton
              startIcon={
                <Icon
                  name="corner-up-left-outline"
                  color={theme.palette.words.subtitle.natural}
                />
              }
              onClick={() => navigate(-1)}
              children="VOLTAR"
            />
          </S.ButtonContainer>

          <S.Page ref={componentRef}>
            <form style={{ width: '100%', alignContent: 'center' }}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <div style={{ marginBottom: '60px' }}>
                    <Grid style={{ marginLeft: '50px', marginRight: '50px' }}>
                      <S.HeaderRaizen>
                        <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f2048ad2f8218_logo-new.svg" />
                        <div>
                          <div style={{ position: 'absolute', right: 20 }}>
                            <p>Layout</p>
                            <span>{`ID ${id}`}</span>
                          </div>
                          <img
                            width="50%"
                            src={
                              posto?.distribuidora?.logo_url
                                ? posto.distribuidora.logo_url
                                : null
                            }
                          />
                        </div>
                      </S.HeaderRaizen>
                      <S.TitleDivMain>
                        <S.TitleMain>
                          <h1>
                            Check-List de Riscos do Local de Entrega - Postos
                            B2B e Aviação
                          </h1>
                        </S.TitleMain>
                      </S.TitleDivMain>
                      {posto && renderPage()}
                      {loading && <Loading />}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </form>
          </S.Page>
        </Grid>
      </>
    );
  };

  // render page
  return <>{renderTemplatePage()}</>;
};

export default LayoutDetail;
