import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { useTheme } from 'styled-components';
import * as S from './styled';

const NewItem = ({ text, onClick }) => {
  const theme = useTheme();
  return (
    <S.NewItem onClick={onClick}>
      <ControlPointOutlinedIcon htmlColor={theme.palette.brand.primary.light} />
      <span>{text}</span>
    </S.NewItem>
  );
};

export default NewItem;
