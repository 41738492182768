import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    p {
      margin-bottom: 5px;
      color: ${!props.error
        ? theme.palette.words.subtitle.natural
        : theme.palette.semantics.feedback.attention.natural};
      font: 'normal medium 14px/20px Texta';
    }
  `};
`;

export const Container = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    width: 100%;
    height: 54px;
    background: ${theme.palette.system.highlight};
    color: ${props.fillcolor
      ? props.fillcolor
      : theme.palette.words.text.light};
    background: ${props.fillcolor
      ? `${props.fillcolor}1A`
      : props.disabled
      ? theme.palette.semantics.feedback.unknown.light
      : 'none'};
    border: ${props.disabled
      ? 'none'
      : `2px solid ${
          props.error
            ? theme.palette.semantics.feedback.attention.natural
            : props.fillcolor
            ? `${props.fillcolor}1A`
            : theme.palette.brand.primary.light
        }`};
    border-radius: 4px;
    padding: 16.5px 14px;
  `}
`;
