import styled, { css } from 'styled-components';
import { Modal } from '@mui/material';

export const ModalMUI = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 50%;
    max-height: 100%;
    background-color: ${theme.palette.system.overlay};
    border-radius: 5px;
    overflow-y: scroll;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      .textArea {
        display: flex;
        justify-content: start;
        align-items: center;

        .title {
          font-weight: 900;
          color: ${theme.palette.words.title.natural};
          margin-left: 20px;
          font-size: 20px;
        }
      }
    }

    .body {
      padding: 20px;
    }

    .footer {
      padding: 20px;
      display: flex;
      justify-content: end;
    }
  `}
`;
