import api from 'services/api';

// TODO ALTERAR ENDPOINTS PARA ROTAS DE DESTRIBUIDORA
// Arquivo puxando infos de distribuidora apenas para
// exibição temporaria do front

const requestInfractions = async query => {
  const res = await api.get('/provider/desvio-descarga', { params: query });
  return res;
};

const fetchInfraction = async id => {
  const res = await api.get(`/provider/desvio-descarga/${id}`);
  return res;
};

const requestHistory = async id => {
  const res = await api.get(`/provider/desvio-descarga-logs/${id}`);
  return res;
};

const requestCards = async query => {
  const res = await api.get('/provider/desvio-descarga-cards', {
    params: query,
  });
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel-provider/unloadingInfractions', {
    params: query,
  });
  return res;
};

export {
  requestInfractions,
  fetchInfraction,
  requestCards,
  requestExcel,
  requestHistory,
};
