// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import React, { useEffect, useState } from 'react';

// Components
import { Modal, IconButton, Icon } from '@mui/material';
import { DefaultTable } from 'components/_Table/templates/default';

// Utils
import { columns } from './columns';

const InfoModal = ({ open, handleClose, data }) => {
  const theme = useTheme();

  const body = (
    <S.Paper>
      <S.Header>
        <S.Title>
          <h1>Informações detalhadas do envio</h1>
        </S.Title>

        <IconButton size="small" onClick={() => handleClose(false)}>
          <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
            close
          </Icon>
        </IconButton>
      </S.Header>

      <div>
        <DefaultTable
          data={data || []}
          columns={columns}
          searchKeys={['motorista.nome']}
          placeholder="Buscar por nome"
          sortBy={{ id: 'nome', order: 'ASC' }}
          fixedItems={5}
        />
      </div>
    </S.Paper>
  );

  return (
    <div>
      <Modal open={open} onClose={() => handleClose(false)}>
        {body}
      </Modal>
    </div>
  );
};

export default InfoModal;
