import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 2rem 0;
  font-weight: 900;
  color: ${({ color, theme }) =>
    color ?? theme.palette.brand.secondary.natural};
`;

export const BarContainer = styled.div`
  width: 100%;
  height: 0.5rem;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
  background: ${({ color, theme }) =>
    color ? `${color}20` : theme.palette.brand.secondary.background};
`;

export const Progress = styled.div`
  height: 0.5rem;
  border-radius: 0.25rem;
  overflow: hidden;
  ${({ width, color, theme }) => css`
    background-color: ${color ?? theme.palette.brand.secondary.natural};
    width: ${width ?? '0%'};
  `}
`;
