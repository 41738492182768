import { useState, useEffect } from 'react';
import * as S from './styled';

export const InfoCard = ({ card, loading = true }) => {
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    if (card?.title) {
      setTimeout(() => {
        setTransition(true);
      }, 500);
    }
  }, [card]);

  return (
    <>
      <S.Container loading={loading || !transition}>
        <div className="responseBox">
          <S.Value>{card?.value}</S.Value>
          <S.Title>{card?.title}</S.Title>
        </div>
        <S.PrevArea positive={card?.prevValue > 0}>
          <div className="text">Último mes</div>
          <div className="value">{card?.prevValue || 0}%</div>
        </S.PrevArea>
        <S.BoxLoading loading={loading || !transition} className="boxLoading" />
      </S.Container>
    </>
  );
};
