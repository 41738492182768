import * as types from './types';

const INITIAL_STATE = {
  desvio: '',
  tecnologia: '',
  status: 'ABERTO',
  responsavel: '',
  criticidade: '',
  sortBy: { id: 'data_desvio', order: 'DESC' },
};

export default function fiterDesvios(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_FILTER: {
      return { ...state, ...action.payload, sortBy: state.sortBy };
    }
    case types.SET_SORT_BY: {
      return { ...state, sortBy: action.payload };
    }
    case types.RESET_FILTER: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
