import React from 'react';
import Modal from '@mui/material/Modal';
import SvgIcon from '@mui/material/SvgIcon';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Icon from 'components/Icons';
import Loading from 'components/Loading';

import { ReactComponent as info } from 'images/icons/toast/info.svg';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import { useTheme } from 'styled-components';
import * as S from './styled';

const ExcelModal = ({
  handleClose,
  open,
  title,
  titleIcon,
  subtitle,
  onClick,
  data,
  setData,
  loading,
}) => {
  const theme = useTheme();
  const handleChange = field => {
    const newFields = data.map(i => {
      if (i.label === field.label && !i.default) i.selected = !i.selected;
      return i;
    });
    setData(newFields);
  };

  const body = (
    <S.Paper>
      <S.Header>
        <S.Title>
          {titleIcon && (
            <SvgIcon
              style={{ margin: '0 10px 0 0' }}
              htmlColor={theme.palette.brand.secondary.natural}
              component={titleIcon}
            />
          )}{' '}
          {title}
        </S.Title>
        <S.RightHeader>
          <S.CloseButton
            startIcon={
              <Icon name="close" color={theme.palette.brand.primary.natural} />
            }
            onClick={handleClose}
            variant="inherit"
            style={{ backgroundColor: theme.palette.system.transparent }}
          />
        </S.RightHeader>
      </S.Header>

      <S.Main>
        <div style={{ display: 'flex', alignSelf: 'start' }}>
          <p style={{ color: theme.palette.words.text.light }}>{subtitle}</p>
        </div>
        <S.CheckboxContainer
          container
          style={{
            width: '100%',
            marginBottom: '25px',
            overflowY: 'scroll',
            maxHeight: '350px',
          }}
        >
          {data.map((item, idx) => (
            <S.CheckboxWrapper
              item
              md={12}
              selected={item.selected}
              key={`check_${idx}`}
            >
              <FormControlLabel
                label={item.label}
                control={
                  <Checkbox
                    checked={item.selected}
                    onChange={() => handleChange(item)}
                  />
                }
              />
            </S.CheckboxWrapper>
          ))}
        </S.CheckboxContainer>
      </S.Main>

      <S.Footer>
        <GhostButton
          disabled={loading}
          size="medium"
          icon={null}
          onClick={handleClose}
        >
          Cancelar
        </GhostButton>

        <DefaultButton
          loading={loading}
          size="medium"
          sx={{ marginLeft: '10px' }}
          onClick={onClick}
        >
          Baixar
        </DefaultButton>
      </S.Footer>
    </S.Paper>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      {loading && <Loading />}
    </div>
  );
};

export default ExcelModal;
