/* eslint-disable import/order */

// Styled
import * as S from './styled';
import colors from 'styles/colors';

// React
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

// Components
import GhostButton from 'components/Buttons/Ghost';
import { EvaluationView } from '../../../Publicar/EvaluationView';

// Components MUI
import { SaveAlt } from '@mui/icons-material';
import { Divider, SvgIcon } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

// Icons
import { ReactComponent as IconQuiz } from 'images/icons/capacitacao/reports/quiz.svg';
import { ReactComponent as IconModulo } from 'images/icons/capacitacao/reports/modulos.svg';

// Utils
import { format } from 'date-fns';

export const ModalDetails = ({ open, handleClose, currentDetail }) => {
  // Redux
  const { id, geral } = useSelector(state => state.conteudoProvider);

  const printPDF = useRef();
  const [loadingExport, setLoadingExport] = useState(false);

  const prePrint = () => {
    setLoadingExport(true);
    setTimeout(() => {
      printPDFAluno();
    }, 500);
  };

  const printPDFAluno = useReactToPrint({
    content: () => printPDF.current,
    onBeforeGetContent: () => setLoadingExport(true),
    onAfterPrint: () => {
      setLoadingExport(false);
    },
    copyStyles: true,
    documentTitle: `historico_${currentDetail?.nome}_conteudo_${id}`,
  });

  return (
    <S.ModalMUI open={open}>
      <S.DetailArea id="detailCurrentContent">
        <div className="container">
          <S.DetailsHeader>
            <div className="leftArea" onClick={() => handleClose()}>
              <ArrowBackIosNewRoundedIcon />
              <h1 style={{ marginLeft: '10px' }}>Voltar</h1>
            </div>

            <div className="boxButton">
              <GhostButton
                children="Exportar"
                icon={<SaveAlt />}
                loading={loadingExport}
                onClick={() => prePrint()}
              />
            </div>
          </S.DetailsHeader>
          <S.ScrollBox>
            <S.DetailsBox ref={printPDF}>
              {loadingExport && (
                <div className="header">
                  <div>
                    ID - {id} / Conteúdo - {geral.titulo}
                  </div>
                  <div>Data {format(new Date(), 'dd/MM/yyyy')}</div>
                </div>
              )}
              <div className="userInfos">
                <div className="photo" foto>
                  {currentDetail?.foto && <img src={currentDetail?.foto} />}
                  {!currentDetail?.foto && (
                    <div className="name">{currentDetail?.nome[0]}</div>
                  )}
                </div>
                <div className="name">{currentDetail?.nome}</div>
                <div className="filial">Filial: {currentDetail?.filial}</div>
              </div>
              <Divider />
              <div className="contentInfos">
                <div className="info">
                  <div className="icon">
                    <CheckIcon style={{ fill: colors.grey }} />
                  </div>
                  <div className="texts">
                    <div className="title">
                      {
                        currentDetail?.perguntas.filter(
                          item => item?.resposta?.correta,
                        ).length
                      }{' '}
                      Resposta(s)
                    </div>
                    <div className="subTitle">Correta(s)</div>
                  </div>
                </div>
                <div className="info">
                  <div className="icon">
                    <SvgIcon fontSize="medium" component={IconQuiz} />
                  </div>
                  <div className="texts">
                    <div className="title">{currentDetail?.percentual}%</div>
                    <div className="subTitle">Conclusão</div>
                  </div>
                </div>
                <div className="info">
                  <div className="icon">
                    <SvgIcon fontSize="medium" component={IconModulo} />
                  </div>
                  <div className="texts">
                    <div className="title">
                      {currentDetail?.tentativas} Tentativa(s)
                    </div>
                    <div className="subTitle">Neste Conteúdo</div>
                  </div>
                </div>
              </div>
              <Divider />
              {currentDetail?.perguntas.length > 0 && (
                <S.EvaluationBox>
                  <div className="evalType">
                    <div className="icon">
                      {currentDetail?.perguntas[0]?.is_quiz ? (
                        <OfflineBoltOutlinedIcon htmlColor={colors.greenMain} />
                      ) : (
                        <CheckCircleOutlineOutlinedIcon
                          htmlColor={colors.greenMain}
                        />
                      )}
                    </div>
                    <div className="text">
                      {currentDetail?.perguntas[0]?.is_quiz
                        ? 'Quiz'
                        : 'Teste Online'}
                    </div>
                  </div>
                  <div className="questionsArea">
                    {currentDetail?.perguntas?.map((item, index) => (
                      <EvaluationView
                        key={index}
                        item={item}
                        number={index + 1}
                        viewStyle
                      />
                    ))}
                  </div>
                </S.EvaluationBox>
              )}
            </S.DetailsBox>
          </S.ScrollBox>
        </div>
      </S.DetailArea>
    </S.ModalMUI>
  );
};
