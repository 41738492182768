export const SET_SECTION = '@formularios/SET_SECTION';
export const SET_ITEMS = '@formularios/SET_ITEMS';
export const SET_ELEMENT = '@formularios/SET_ELEMENT';
export const SET_TITLE = '@formularios/SET_TITLE';
export const SET_ID = '@formularios/SET_ID';
export const SET_CAPA = '@formularios/SET_CAPA';
export const SET_STATUS = '@formularios/SET_STATUS';
export const SET_GERAIS = '@formularios/SET_GERAIS';
export const RESET_STATE = '@formularios/RESET_STATE';
export const IS_EDITABLE = '@formularios/IS_EDITABLE';
