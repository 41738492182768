import React from 'react';
import { usePlans } from 'hooks/usePlans';
import { EstatisticasTorre } from './Torre';
import { EstatisticasTorrePlus } from './TorrePlus';

export const Estatisticas = () => {
  const { hasTorrePlus } = usePlans();

  return hasTorrePlus ? <EstatisticasTorrePlus /> : <EstatisticasTorre />;
};
