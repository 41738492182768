export const filtersGraficoParams = [
  {
    name: 'filial',
    placeholder: 'Filtrar por Filial',
    data: [],
  },
  {
    name: 'client',
    placeholder: 'Filtrar por Cliente',
    data: [],
  },
  {
    name: 'desvio',
    placeholder: 'Filtrar por Tipo de desvio',
    data: [],
  },
];

export const filtersGraficoParamsProvider = [
  {
    name: 'empresa',
    placeholder: 'Filtrar por Empresa',
    data: [],
  },
  {
    name: 'filial',
    placeholder: 'Filtrar por Filial',
    dependency: 'empresa',
    data: [],
    getData: (value, data) => {
      const empresa = data.find(item => item.id === value);
      if (empresa) {
        return empresa.filiais.map(item => ({
          id: item.id,
          value: item.nome,
        }));
      }
      return [];
    },
  },
  {
    name: 'operacao',
    placeholder: 'Filtrar por Operação',
    data: [],
  },
  {
    name: 'desvio',
    placeholder: 'Filtrar por Tipo de desvio',
    data: [],
  },
  {
    name: 'criticidade',
    placeholder: 'Filtrar por Criticidade',
    data: [
      { id: 'PRIMÁRIA', value: 'PRIMÁRIA' },
      { id: 'MODERADA', value: 'MODERADA' },
      { id: 'GRAVE', value: 'GRAVE' },
      { id: 'GRAVÍSSIMA', value: 'GRAVÍSSIMA' },
    ],
  },
];

export const columns = [
  {
    header: 'Rodovia',
    id: 'nome',
    type: 'string',
    tooltip: true,
    sort: true,
    width: 180,
    group: 'RODOVIAS',
  },
  {
    header: 'Motorista',
    id: 'nome',
    tooltip: true,
    type: 'string',
    sort: true,
    width: 180,
    group: 'MOTORISTAS',
  },
  {
    header: 'Qtde de Desvios',
    id: 'total',
    sort: true,
    type: 'string',
    align: 'center',
    tab: 'DESVIOS',
  },
  {
    header: 'Total de Pontos',
    id: 'total',
    sort: true,
    type: 'string',
    align: 'center',
    tab: 'PONTOS',
  },
  {
    header: 'Percentual',
    id: 'porcentagem',
    sort: false,
    type: 'sting',
    align: 'center',
  },
  {
    header: 'Maior Fator de Risco',
    id: 'fator_risco',
    tooltip: true,
    sort: false,
    type: 'number',
    align: 'center',
  },
];

export const columnsProvider = [
  columns[0],
  {
    header: 'Empresa',
    id: 'nome',
    type: 'string',
    tooltip: true,
    sort: true,
    width: 180,
    group: 'EMPRESAS',
  },
  {
    header: 'Motorista',
    id: 'nome',
    tooltip: true,
    type: 'string',
    sort: true,
    width: 180,
    group: 'MOTORISTAS',
  },
  ...columns.slice(2),
];
