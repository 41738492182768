import React, { useState, useEffect } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { signInRequest } from 'store/modules/auth/action';

import TextInput from 'components/Inputs/TextField';
import Button from 'components/Buttons/Default';

import bg from 'images/loginbg.png';
import logo from 'images/logo.svg';
import benefit from 'images/loginBenefit.svg';
import { useNavigate } from 'react-router';
import { LockOpen } from '@mui/icons-material';
import { auth } from 'utils/firebase';
import { useLocation } from 'react-router-dom';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useTheme } from 'styled-components';
import * as S from './styled';
import { updatePass } from './services';

const LoginPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authUser = useSelector(state => state.auth);

  const paramsURL = new URLSearchParams(useLocation().search);
  const [inputs, setInputs] = useState({});

  const [email, SetEmail] = useState(null);
  const [emailToken, SetEmailToken] = useState(null);
  const [visibleN, setVisibleN] = useState(false);

  const handleChange = e => {
    const { value } = e.target;
    setInputs(values => ({ ...values, [e.target.name]: value }));
  };

  const renderForm = () => {
    const texts = {
      title: 'Entrar',
      subTitle: 'Digite suas credencias abaixo:',
    };
    return (
      <Grid
        style={{
          overflowY: 'initial',
          height: '100%',
          padding: '100px',
          marginTop: '10%',
        }}
        item
        sm={10}
      >
        <S.PageContainer onSubmit={handleSubmit}>
          <div>
            <h1 style={{ fontSize: '38px', margin: '12px 0' }}>
              {texts.title}
            </h1>
            <p style={{ margin: '12px 0 24px' }}>{texts.subTitle}</p>
          </div>
          <S.FormWrapper>
            <TextInput
              label="Senha"
              placeholder="Nova senha"
              value={inputs.password || ''}
              onChange={handleChange}
              type={visibleN ? 'text' : 'password'}
              onBlur={() => setVisibleN(false)}
              name="password"
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <LockOpen />
                  </InputAdornment>
                ),
              }}
            />
            <S.VisibleController onClick={() => setVisibleN(!visibleN)}>
              {visibleN && (
                <VisibilityOffOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
              )}
              {!visibleN && (
                <VisibilityOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
              )}
            </S.VisibleController>
          </S.FormWrapper>
          <S.FormWrapper>
            <TextInput
              label="Confirmação da senha"
              placeholder="Confirmar nova senha"
              value={inputs.passwordConfirm || ''}
              onChange={handleChange}
              name="passwordConfirm"
              id="passwordConfirm"
              type={visibleN ? 'text' : 'password'}
              onBlur={() => setVisibleN(false)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <LockOpen />
                  </InputAdornment>
                ),
              }}
            />
            <S.VisibleController onClick={() => setVisibleN(!visibleN)}>
              {visibleN && (
                <VisibilityOffOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
              )}
              {!visibleN && (
                <VisibilityOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
              )}
            </S.VisibleController>
          </S.FormWrapper>
          <S.FormWrapper>
            <Button
              type="submit"
              variant="contained"
              style={{
                marginTop: '35px',
                background: theme.palette.brand.secondary.natural,
                color: theme.palette.words.button.contrast,
                height: '50px',
                width: '100%',
                fontWeight: 900,
              }}
              children="LOGIN"
            />
          </S.FormWrapper>
        </S.PageContainer>
      </Grid>
    );
  };

  const loginEmailToken = async token => {
    try {
      await auth.signInWithCustomToken(token);
      const { currentUser } = auth;
      if (currentUser) {
        // Aqui será redirecionado para /home logado pelo auth
        const body = {
          firebase_id: currentUser.uid,
          password: inputs.password,
        };
        const res = await updatePass(body);
        if (res?.data?.success) {
          if (res.data.message) toast.success(res.data.message);
          dispatch(signInRequest({ email, password: inputs.password }));
          return;
        }
        if (res?.data?.message) {
          toast.error(res.data.message);
        }
      } else toast.error('Token inválido.');
    } catch (err) {
      toast.error('Token inválido.');
      console.log(err);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!inputs.password) toast.warning('Campo senha é obrigatório');
    else if (inputs.password.length < 6)
      toast.warning('A senha deve ter ao menos 6 dígitos');
    else if (!inputs.passwordConfirm)
      toast.warning('Campo Confirmação da senha é obrigatório');
    else if (inputs.password !== inputs.passwordConfirm)
      toast.warning('As senhas não correspondem');
    else {
      loginEmailToken(emailToken);
    }
  };

  useEffect(() => {
    const user = paramsURL.get('user');
    const token = paramsURL.get('token');
    if (user && token) {
      SetEmailToken(token);
      SetEmail(user);
    }
  }, []);

  useEffect(() => {
    if (authUser?.signed) {
      navigate('/');
    }
  }, [authUser]);

  return (
    <S.PageWrapper container>
      <Grid
        sm={5}
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundImage: `url(${bg})`,
          minHeight: '100vh',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={logo} />
            <p
              style={{
                font: 'normal normal 24px/31px Texta',
                color: theme.palette.words.text.contrast,
                textAlign: 'center',
                marginTop: '30px',
              }}
            >
              Tudo que você precisa para gerenciar riscos.
            </p>
            <div
              style={{
                display: 'flex',
                width: '80%',
                justifyContent: 'space-evenly',
                margin: '35px 0',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img style={{ width: '48px', height: '48px' }} src={benefit} />
                <p
                  style={{
                    font: 'normal normal 18px/25px Texta',
                    color: theme.palette.words.text.contrast,
                  }}
                >
                  Benefício A
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img style={{ width: '48px', height: '48px' }} src={benefit} />
                <p
                  style={{
                    font: 'normal normal 18px/25px Texta',
                    color: theme.palette.words.text.contrast,
                  }}
                >
                  Benefício B
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img style={{ width: '48px', height: '48px' }} src={benefit} />
                <p
                  style={{
                    font: 'normal normal 18px/25px Texta',
                    color: theme.palette.words.text.contrast,
                  }}
                >
                  Benefício C
                </p>
              </div>
            </div>
            <p
              style={{
                font: 'normal normal 18px/25px Texta',
                color: theme.palette.words.text.contrast,
                textAlign: 'center',
                maxWidth: '350px',
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse dictum massa purus, id auctor enim tempor et. Aliquam
              vel ligula nec
            </p>
            <Button
              children="SOLICITAR DEMONSTRAÇÃO"
              style={{
                borderRadius: '4px',
                width: '250px',
                height: '50px',
                background: 'transparent',
                border: `1px solid ${theme.palette.brand.secondary.natural}`,
                color: theme.palette.words.button.contrast,
                marginTop: '40px',
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        sm={7}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {renderForm()}
      </Grid>
    </S.PageWrapper>
  );
};

export default LoginPage;
