import { formatNewHourClean } from 'utils/dates';

export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'string',
    sort: false,
    width: 100,
  },
  {
    header: 'Desvio',
    id: 'desvio_tipo.titulo',
    type: 'string',
    sort: false,
    subRow: {
      value: (_, item) => item?.status || '',
    },
  },
  {
    header: 'Data Desvio',
    id: 'data_desvio',
    type: 'string',
    sort: false,
    value: (_, item) =>
      item?.status === 'FINALIZADO'
        ? `Conclusão: ${formatNewHourClean(item?.data_encerramento)}`
        : formatNewHourClean(item?.data_desvio),
  },
  {
    header: 'Vencimento',
    id: 'data_vencimento',
    type: 'string',
    sort: false,
    value: (_, item) => formatNewHourClean(item?.data_vencimento),
  },
  {
    header: 'Criticidade',
    id: 'criticidade',
    type: 'string',
    align: 'center',
    sort: false,
    style: {
      textTransform: 'uppercase',
    },
    conditional: [
      {
        condition: value => true,
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.information?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.information?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'MODERADA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.warning?.natural,
          backgroundColor: theme?.palette?.semantics?.feedback?.warning?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVÍSSIMA' || value === 'GRAVE',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.attention?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.attention?.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];
