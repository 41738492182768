export const columnsView = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: true,
  },
  {
    header: 'Tipo',
    id: 'tipo',
    type: 'string',
    sort: true,
  },
  {
    header: 'Função',
    id: 'funcao',
    type: 'string',
    sort: true,
  },
  {
    header: 'Filial',
    id: 'filial.nome',
    type: 'string',
    sort: true,
  },
];

export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
  },
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: true,
  },
  {
    header: 'Tipo',
    id: 'tipoUser',
    type: 'string',
    sort: true,
  },
  {
    header: 'Status',
    id: 'ativa',
    type: 'string',
    sort: true,
    value: value => (value ? 'Ativa' : 'Inativa'),
    conditional: [
      {
        condition: value => value,
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => !value,
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Alunos',
    id: 'n_alunos',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Atividades',
    id: 'atividades',
    type: 'number',
    sort: true,
    align: 'center',
    width: 120,
    attention: (_, item) => {
      if (item.atividades)
        return {
          text: `${item.atividades} atividade(s) relacionada(s)`,
        };
    },
  },
];
