import CircleIcon from '@mui/icons-material/Circle';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Tooltip } from '@mui/material';
import * as S from './styled';
import { useTheme } from 'styled-components';

export const columns = [
  {
    header: 'Pos',
    id: 'posicao',
    accessor: d => (d.posicao ? parseInt(d.posicao) : '-'),
    sort: true,
    width: 60,
    align: 'center',
    children: (value, item) => {
      const theme = useTheme();
      if (!item.posicao_anterior || item.posicao_anterior > item.posicao)
        return (
          <S.TableName center className="position">
            <Tooltip
              title={`Posição anterior: ${item.posicao_anterior}`}
              arrow
              placement="top"
            >
              <ArrowDropUpIcon
                htmlColor={theme.palette.semantics.feedback.success.natural}
              />
            </Tooltip>
            <span>{item.posicao}</span>
          </S.TableName>
        );
      if (item.posicao_anterior < item.posicao)
        return (
          <S.TableName center className="position">
            <Tooltip
              title={`Posição anterior: ${item.posicao_anterior}`}
              arrow
              placement="top"
            >
              <ArrowDropDownIcon
                htmlColor={theme.palette.semantics.feedback.attention.natural}
              />
            </Tooltip>
            <span>{item.posicao}</span>
          </S.TableName>
        );
      if (item.posicao_anterior == item.posicao)
        return (
          <S.TableName center className="position">
            <CircleIcon
              className="svg"
              htmlColor={theme.palette.semantics.feedback.information.natural}
            />
            <span>{item.posicao}</span>
          </S.TableName>
        );
    },
  },
  {
    header: 'Nome',
    id: 'nome_motorista',
    sort: true,
    tooltip: true,
    type: 'string',
    picture: 'foto',
    width: 300,
  },
  {
    header: 'Total de pontos',
    id: 'total_pontos',
    sort: true,
    type: 'number',
    align: 'center',
    value: (value, item) => item.total_pontos_formatado ?? '-',
    conditional: [
      {
        condition: (value, item) =>
          Number(item.total_pontos) > Number(item.media),
        style: theme => ({
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          color: theme.palette.semantics.feedback.attention.natural,
          textAlign: 'center',
        }),
      },
      {
        condition: (value, item) =>
          Number(item.total_pontos) <= Number(item.media),
        style: theme => ({
          backgroundColor: theme.palette.semantics.feedback.success.light,
          color: theme.palette.semantics.feedback.success.natural,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Ptos/Km',
    id: 'pontos_km',
    sort: true,
    align: 'center',
    plans: [100],
    value: (value, item) => item.pontos_km_formatado ?? '-',
    children: val => (
      <S.TableName style={{ textTransform: 'uppercase' }}>{val}</S.TableName>
    ),
  },
  {
    header: 'Ptos/Hr',
    id: 'pontos_hora',
    sort: true,
    align: 'center',
    plans: [140],
    value: (value, item) => item.pontos_hora_formatado ?? '-',
    children: val => (
      <S.TableName style={{ textTransform: 'uppercase' }}>{val}</S.TableName>
    ),
  },
  {
    header: 'Deslocamento',
    id: 'total_kms',
    sort: true,
    align: 'center',
    value: (value, item) => item.total_kms_formatado ?? '-',
    children: val => (
      <S.TableName style={{ textTransform: 'uppercase' }}>{val}</S.TableName>
    ),
  },
  {
    header: 'Horas',
    id: 'total_horas',
    sort: true,
    type: 'number',
    align: 'center',
    plans: [140],
  },
  {
    header: 'QTD Desvios',
    id: 'quantidade',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'Desvios Graves/Gravíssimos',
    id: 'total_desvios_graves',
    sort: true,
    type: 'number',
    align: 'center',
  },
];

export const fields = [
  {
    id: 'total_pontos',
    title: 'Total de pontos',
    type: 'number-compact',
  },
  {
    id: 'pontos_km',
    title: 'Ptos/Km',
    type: 'number-compact',
    plans: [100],
  },
  {
    id: 'pontos_hora',
    title: 'Ptos/Hr',
    type: 'number-compact',
    plans: [140],
  },
  {
    id: 'total_kms',
    title: 'Deslocamento',
    type: 'number-compact',
    plans: [100],
  },
  {
    id: 'total_horas',
    title: 'Horas',
    plans: [140],
    type: 'number-compact',
  },
  {
    id: 'quantidade',
    title: 'Qtd desvios',
    type: 'number-compact',
  },
  {
    id: 'total_desvios_graves',
    title: 'Desvios graves/gravíssimos',
    type: 'number-compact',
  },
];

export const excelFields = [
  {
    value: 'posicao',
    label: 'Posição',
  },
  {
    value: 'nome_motorista',
    label: 'Motorista',
  },
  {
    value: 'nome_filial',
    label: 'Filial',
  },
  ...fields.map(field => ({
    label: field.title,
    value: field.id,
  })),
];
