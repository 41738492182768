import styled, { css } from 'styled-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

const ContainerModifier = {
  hover: ({ theme }) => css`
    border: 2px solid ${theme.palette.primary.main};

    .icon {
      color: ${theme.palette.primary.main};
    }
  `,
  open: ({ theme }) => css`
    border: 2px solid ${theme.palette.secondary.main};

    .icon {
      color: ${theme.palette.secondary.main};
    }
  `,
};

export const InputModifier = {
  hover: ({ theme }) => css`
    border: 2px solid ${theme.palette.primary.main};
  `,
  focus: ({ theme }) => css`
    border: 2px solid ${theme.palette.secondary.main};
  `,
};

export const PlaceholderModifier = {
  selected: ({ theme }) => css`
    color: ${theme.palette.words.text.dark};
    font-weight: 600;
    transition: all 0.2s ease-in-out;
  `,
  empty: ({ theme }) => css`
    color: ${theme.palette.words.text.light};
  `,
};

export const Container = styled.div`
  ${({ theme, open }) => css`
    width: 350px;
    height: 50px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    border-radius: 4px;
    border: 2px solid ${theme.palette.system.border};
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    .icon {
      color: ${theme.palette.words.text.light};
      transition: all 0.25s ease-in-out;
      transform: ${open ? 'rotate(180deg)' : 'rotate(0deg)'};
    }

    &:hover {
      ${ContainerModifier.hover({ theme })}
    }

    ${open && ContainerModifier.open({ theme })}
  `}
`;

export const Placeholder = styled.div`
  ${({ theme, isSelected }) => css`
    font-size: 16px;
    font-weight: 500;

    ${isSelected && PlaceholderModifier.selected({ theme })}
    ${!isSelected && PlaceholderModifier.empty({ theme })}
  `}
`;

export const MenuBox = styled(Menu)``;

export const ScrollOptions = styled.div`
  max-height: 150px;
  overflow-y: scroll;
`;

export const Check = styled(Checkbox).attrs(({ theme }) => ({
  size: 'small',
  sx: {
    '&.Mui-checked': {
      color: theme.palette.brand.secondary.natural,
    },
  },
}))``;

export const Option = styled(MenuItem)`
  ${({ theme, checked }) => css`
    width: 340px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    color: ${checked
      ? theme.palette.words.text.dark
      : theme.palette.words.text.natural};
    background-color: ${checked
      ? theme.palette.system.disabled
      : 'transparent'};
  `}
`;

export const SearchBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    position: relative;

    .icon {
      color: ${theme.palette.words.text.light};
      position: absolute;
      right: 50px;
      font-size: 20px;
    }
  `}
`;

export const SearchInput = styled.input`
  ${({ theme }) => css`
    width: 296px;
    border-radius: 4px;
    border: 2px solid ${theme.palette.system.border};
    height: 35px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 600;
    color: ${theme.palette.words.text.natural};

    &:hover {
      ${InputModifier.hover({ theme })}
    }

    &:focus {
      ${InputModifier.focus({ theme })}
    }

    &::placeholder {
      color: ${theme.palette.words.text.light};
      font-weight: 600;
      font-size: 14px;
    }
  `}
`;

export const LineAction = styled.div`
  ${({ theme, isEmpty }) => css`
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: ${theme.palette.words.text.natural};
    background-color: ${theme.palette.system.divider};
    margin: 5px 0px;
    cursor: pointer;
    transition: all 0.2s ease;
    opacity: ${isEmpty ? 0 : 1};
    pointer-events: ${isEmpty ? 'none' : 'all'};

    &:hover {
      opacity: 0.7;
    }
  `}
`;

export const Empty = styled.div`
  width: 340px;
  display: flex;
  height: 120px;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const EmptyText = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: 600;
    color: ${theme.palette.words.text.light};
  `}
`;

export const EmptyImage = styled.img`
  width: 100px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 10px;
`;
