import * as types from './types';

export function setFilterProvider(query) {
  return {
    type: types.SET_FILTER_REQUEST_PROVIDER,
    payload: query,
  };
}

export function setInitialStateDateProvider() {
  return {
    type: types.SET_INITIAL_STATE_DATE_PROVIDER,
    payload: {},
  };
}
