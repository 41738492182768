import api from 'services/api';

const requestGraphDesvio = async (graph, query) => {
  const res = await api.get(`/provider/estatisticas-desvios/${graph}`, {
    params: query,
  });
  return res;
};

const requestLayoutProvider = async (graph, query) => {
  const res = await api.get(`/provider/estatisticas-layout/${graph}`, {
    params: query,
  });
  return res;
};

export { requestGraphDesvio, requestLayoutProvider };
