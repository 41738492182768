import styled, { css } from 'styled-components';

export const Paper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 540px;
    background-color: ${theme.palette.system.overlay};
    border: 0px 5px 20px ${theme.palette.system.border};
    border-radius: 8px;
    padding: 25px 25px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    h1 {
      color: ${theme.palette.words.title.natural};
      font: normal 900 20px Texta;
      margin: 0;
      padding: 0;
    }
  `}
`;

export const Main = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 15px;

    .footerText {
      display: block;
      text-decoration: underline;
      text-align: center;
      color: ${theme.palette.words.title.natural};
      font-weight: 700;
      opacity: 0.8;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  `}
`;

export const StyledToast = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    font-size: 16px;
    color: ${theme.palette.words.title.natural};
    display: flex;

    .box {
      background-color: ${theme.palette.brand.primary.background};
      border: 1px solid ${theme.palette.system.border};
      height: 170px;
      width: 220px;
      border-radius: 5px;
      margin: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover {
        background-color: ${theme.palette.system.border};
      }

      .svgArea {
        background-color: ${theme.palette.system.overlay};
        border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
      }

      .textArea {
        font-weight: 800;
        text-align: center;
        margin: 10px;
      }

      .clickArea {
        text-decoration: underline;
        opacity: 0.8;
        color: ${theme.palette.words.subtitle.natural};
      }
    }
  `}
`;
