import React, { useEffect, useMemo } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Divider, Grid, Tooltip } from '@mui/material';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextField';
import Switch from '../components/Switch';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useQuery } from 'react-query';
import * as services from '../../services';
import { DashedButton } from 'components/Buttons/Dashed';
import Condicoes from '../components/Condicoes';
import Criticidade from '../components/Criticidade';
import Collapse from '../components/Collapse';
import { useTheme } from 'styled-components';
import * as S from '../styled';

const Velocidades = ({
  control,
  watch,
  getValues,
  setValue,
  errors,
  disableForm,
  isProvider,
}) => {
  const theme = useTheme();
  const { data: _tiposVeiculos = [] } = useQuery(
    ['tipos-veiculos-telemetria'],
    () => services.getTiposVeiculos(),
    { staleTime: Infinity },
  );

  const tiposVeiculos = [
    { value: null, name: 'Todos' },
    ..._tiposVeiculos
      .filter(veiculo => veiculo.id >= 0)
      .map(tipo => ({
        name: tipo.tipo,
        value: tipo.id,
      })),
  ];

  const { fields, append, remove } = useFieldArray({
    name: 'desvios_tipos',
    control,
  });

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (name?.includes('limiar')) {
        const index = parseInt(name.split('[')[1]);
        if (index > 0) {
          const value = getValues(name);
          setValue(
            `desvios_tipos[${index - 1}].velocidade_final`,
            value ? value - 1 : '',
          );
        }
      }
      if (name === 'desvios_tipos' && values.desvios_tipos.length > 0) {
        setValue(
          `desvios_tipos[${values.desvios_tipos.length - 1}].velocidade_final`,
          230,
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <br />
      <Divider />
      {fields.map((field, index) => {
        const canRemove = !field.createdAt && fields.length > 1;
        const velocidadeMinima =
          index === 0 ? 0 : getValues(`desvios_tipos[${index - 1}].limiar`);
        return (
          <Collapse
            key={field.id}
            handleRemove={() => remove(index)}
            canRemove={!disableForm && canRemove}
            last={index + 1 === fields.length}
            title={`Faixa de Velocidade ${index > 0 ? `(${index + 1})` : ''}`}
            switchProps={{
              control,
              name: `desvios_tipos[${index}].ativo`,
              disabled: disableForm,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3} sm={1.5}>
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    paddingTop: '2.5rem',
                  }}
                >
                  <Switch
                    disabled={disableForm}
                    name={`desvios_tipos[${index}].ativo`}
                    control={control}
                  />
                </div>
              </Grid>

              <Grid item xs={9} sm={4.5}>
                <Controller
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].titulo`}
                  render={({ field }) => (
                    <TextInput
                      required
                      disabled={disableForm}
                      error={!!errors.desvios_tipos?.[index]?.titulo}
                      message={errors.desvios_tipos?.[index]?.titulo?.message}
                      label="Título da faixa de velocidade"
                      placeholder="Insira um título"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name={`desvios_tipos[${index}].id_tipo_veiculo`}
                  render={({ field: { onChange, name, ref, value } }) => (
                    <Select
                      ref={ref}
                      disabled={disableForm}
                      name={name}
                      value={value}
                      handleChange={onChange}
                      error={!!errors.desvios_tipos?.[index]?.id_tipo_veiculo}
                      data={tiposVeiculos}
                      label="Tipo de veículo"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                    min: {
                      value: velocidadeMinima,
                      message: 'O valor deve ser maior que a faixa anterior',
                    },
                    max: {
                      value: 230,
                      message: 'O valor deve ser inferior à 230',
                    },
                  }}
                  name={`desvios_tipos[${index}].limiar`}
                  render={({ field }) => (
                    <TextInput
                      required
                      disabled={disableForm}
                      type="number"
                      inputProps={{ min: 0 }}
                      error={!!errors.desvios_tipos?.[index]?.limiar}
                      message={errors.desvios_tipos?.[index]?.limiar?.message}
                      label="Velocidade inicial"
                      placeholder="Insira a velocidade"
                      {...field}
                      onChange={({ target }) =>
                        field.onChange(
                          target.value ? parseInt(target.value) : '',
                        )
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name={`desvios_tipos[${index}].velocidade_final`}
                  render={({ field }) => (
                    <Tooltip title="Valor baseado no inicial da próxima faixa.">
                      <TextInput
                        readOnly
                        disabled={disableForm}
                        label="Velocidade final"
                        placeholder=""
                        {...field}
                        onChange={() => {}}
                      />
                    </Tooltip>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: 'Campo obrigatório.',
                    },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].intervalo_tempo`}
                  render={({ field }) => (
                    <TextInput
                      required
                      disabled={disableForm}
                      type="number"
                      inputProps={{ min: 0 }}
                      error={!!errors.desvios_tipos?.[index]?.intervalo_tempo}
                      message={
                        errors.desvios_tipos?.[index]?.intervalo_tempo?.message
                      }
                      label={
                        <span>
                          Intervalo de tempo (min.){' '}
                          <Tooltip
                            title="Tempo de tolerância antes que o desvio comece a ser contabilizado."
                            arrow
                          >
                            <HelpIcon sx={{ fontSize: '1rem' }} />
                          </Tooltip>
                        </span>
                      }
                      placeholder="Insira o intervalo de tempo"
                      {...field}
                      value={field.value ?? ''}
                      onChange={({ target }) =>
                        field.onChange(
                          target.value ? parseInt(target.value) : null,
                        )
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].pontos`}
                  render={({ field }) => (
                    <TextInput
                      required
                      disabled={disableForm}
                      type="number"
                      inputProps={{ min: 0 }}
                      error={!!errors.desvios_tipos?.[index]?.pontos}
                      message={errors.desvios_tipos?.[index]?.pontos?.message}
                      label="Pontuação"
                      placeholder="Insira a pontuação"
                      {...field}
                      onChange={({ target }) =>
                        field.onChange(
                          target.value ? parseInt(target.value) : '',
                        )
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Criticidade
                  name={`desvios_tipos[${index}].criticidade`}
                  control={control}
                  errors={errors}
                  disableForm={disableForm}
                />
                {errors.desvios_tipos?.[index]?.criticidade?.message && (
                  <span
                    style={{
                      color: theme.palette.semantics.feedback.attention.dark,
                      margin: '10px 0',
                    }}
                  >
                    {errors.desvios_tipos?.[index]?.criticidade?.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Condicoes
                  path={`desvios_tipos[${index}]`}
                  control={control}
                  disableForm={disableForm}
                />
              </Grid>
            </Grid>
          </Collapse>
        );
      })}
      <DashedButton
        type="button"
        disabled={disableForm}
        onClick={() =>
          append({ ativo: true, plano_de_acao: '', id_tipo_veiculo: null })
        }
      >
        <AddIcon />
        ADICIONAR FAIXA DE VELOCIDADE
      </DashedButton>
    </>
  );
};

export default Velocidades;
