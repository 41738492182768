import api from 'services/api';

export const GetConteudo = async id => {
  const res = await api.get(`/training/content/${id}`);
  return res.data;
};

export const SaveGeneralInfos = async data => {
  const res = await api.post('/training/content', data);
  return res.data;
};

export const EditInfos = async (id, data) => {
  const res = await api.put(`training/content/${id}`, data);
  return res.data;
};

export const GetTags = async () => {
  const res = await api.get('/training/tags');
  return res.data;
};

export const GetPublic = async () => {
  const res = await api.get('/training/turmas');
  return res.data;
};

export const PublishContent = async id => {
  const res = await api.put(`/training/publish-content/${id}`);
  return res.data;
};

export const DeleteContent = async id => {
  const res = await api.delete(`/training/content/${id}`);
  return res.data;
};

export const getDesviosTiposPadrao = async () => {
  const res = await api.get('/desvios/configuracoes/padroes-ativos');
  return res.data;
};
