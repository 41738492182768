import api from 'services/api';

export const getRelatorios = async query => {
  const { data } = await api.get('/configuracoes/relatorios', {
    params: query,
  });
  return data?.success ? data.data : [];
};

export const saveRelatorioPermission = async data => {
  const res = data.id
    ? await api.put(`/configuracoes/relatorios/${data.id}`, data)
    : await api.post(`/configuracoes/relatorios`, data);
  return res.data;
};
