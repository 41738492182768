import React, { useState } from 'react';
import { Container, BarContainer, Item, PopContainer } from './styled';
import { Popover, Skeleton } from '@mui/material';

export const TotaisPorTipos = ({ titulo = '', data = [], loading }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorItem, setAnchorItem] = useState(null);

  let maior = 0;
  const total = data.reduce((acc, item) => {
    if (item.total > maior) maior = item.total;
    return (acc += item.total);
  }, 0);

  const itens = data.map(item => {
    const percentage = (item.total / total) * 100;
    return { ...item, percentage };
  });

  const handlePopoverOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
    setAnchorItem(item);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setAnchorItem(null);
  };

  itens.sort((a, b) => b.total - a.total);
  const open = Boolean(anchorEl);
  return (
    <Container>
      <h2>
        {titulo} <span>{total}</span>
      </h2>
      <BarContainer>
        {loading ? (
          <Skeleton
            width="100%"
            height={30}
            animation="wave"
            variant="rounded"
          />
        ) : itens.length ? (
          itens.map((item, index) => (
            <Item
              key={index}
              color={item.cor}
              percentage={item.percentage}
              onMouseEnter={e => handlePopoverOpen(e, item)}
              onMouseLeave={handlePopoverClose}
            />
          ))
        ) : (
          <Item color="#cccc" percentage={100} />
        )}
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <PopContainer>
            <div className="title">{anchorItem?.tipo_acidente}</div>
            <div>{anchorItem?.total} ocorrências</div>
            <div>{anchorItem?.percentage.toFixed(2)}% do total</div>
          </PopContainer>
        </Popover>
      </BarContainer>
    </Container>
  );
};
