export const mockData = [
  {
    mes: 9,
    semanas: [
      {
        inicio: '2022-10-02T03:00:00.000Z',
        final: '2022-10-08T03:00:00.000Z',
        tarefas: [
          {
            id: 1,
            evento: '1',
            title: 'Aplicação de PAD',
            data: '2022-10-02T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 2,
            evento: '2',
            title: 'Aplicação de PAD',
            data: '2022-10-03T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 3,
            evento: '4',
            title: 'Aplicação de PAD',
            data: '2022-10-04T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 4,
            evento: '5',
            title: 'Aplicação de PAD',
            data: '2022-10-04T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
        ],
      },
      {
        inicio: '2022-10-09T03:00:00.000Z',
        final: '2022-10-15T03:00:00.000Z',
        tarefas: [
          {
            id: 1,
            evento: '6',
            title: 'Aplicação de PAD',
            data: new Date(),
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 2,
            evento: '7',
            title: 'Aplicação de PAD',
            data: new Date(),
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 3,
            evento: '8',
            title: 'Aplicação de PAD',
            data: new Date(),
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 4,
            evento: '9',
            title: 'Aplicação de PAD',
            data: new Date(),
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
        ],
      },
      {
        inicio: '2022-10-16T03:00:00.000Z',
        final: '2022-10-22T03:00:00.000Z',
        tarefas: [
          {
            id: 1,
            evento: '10',
            title: 'Aplicação de PAD',
            data: '2022-10-16T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 2,
            evento: '11',
            title: 'Aplicação de PAD',
            data: '2022-10-16T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 3,
            evento: '12',
            title: 'Aplicação de PAD',
            data: '2022-10-16T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 4,
            evento: '13',
            title: 'Aplicação de PAD',
            data: '2022-10-17T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
        ],
      },
      {
        inicio: '2022-10-23T03:00:00.000Z',
        final: '2022-10-29T03:00:00.000Z',
        tarefas: [
          {
            id: 1,
            evento: '14',
            title: 'Aplicação de PAD',
            data: '2022-10-24T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 2,
            evento: '15',
            title: 'Aplicação de PAD',
            data: '2022-10-24T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 3,
            evento: '16',
            title: 'Aplicação de PAD',
            data: '2022-10-25T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 4,
            evento: '17',
            title: 'Aplicação de PAD',
            data: '2022-10-26T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
        ],
      },
      {
        inicio: '2022-10-30T03:00:00.000Z',
        final: '2022-11-05T03:00:00.000Z',
        tarefas: [
          {
            id: 1,
            evento: '18',
            title: 'Aplicação de PAD',
            data: '2022-10-30T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 2,
            evento: '19',
            title: 'Aplicação de PAD',
            data: '2022-10-30T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 3,
            evento: '20',
            title: 'Aplicação de PAD',
            data: '2022-10-30T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 4,
            evento: '21',
            title: 'Aplicação de PAD',
            data: '2022-10-30T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
        ],
      },
    ],
  },
  {
    mes: 10,
    semanas: [
      {
        inicio: '2022-11-06T03:00:00.000Z',
        final: '2022-11-12T03:00:00.000Z',
        tarefas: [
          {
            id: 1,
            evento: '22',
            title: 'Aplicação de PAD',
            data: '2022-11-07T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 2,
            evento: '23',
            title: 'Aplicação de PAD',
            data: '2022-11-08T03:00:00.000Z',
            status: 'VENCIDO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 3,
            evento: '24',
            title: 'Aplicação de PAD',
            data: '2022-11-09T03:00:00.000Z',
            status: 'CONCLUIDO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 4,
            evento: '25',
            title: 'Aplicação de PAD',
            data: '2022-11-09T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
        ],
      },
      {
        inicio: '2022-11-13T03:00:00.000Z',
        final: '2022-11-19T03:00:00.000Z',
        tarefas: [
          {
            id: 1,
            evento: '26',
            title: 'Aplicação de PAD',
            data: new Date(),
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 2,
            evento: '27',
            title: 'Aplicação de PAD',
            data: new Date(),
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 3,
            evento: '28',
            title: 'Aplicação de PAD',
            data: new Date(),
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 4,
            evento: '29',
            title: 'Aplicação de PAD',
            data: new Date(),
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
        ],
      },
      {
        inicio: '2022-11-20T03:00:00.000Z',
        final: '2022-11-26T03:00:00.000Z',
        tarefas: [
          // {
          //     id: 1,
          //     evento: "30",
          //     title: "Aplicação de PAD",
          //     data: new Date(),
          //     status: "ABERTO",
          //     icon: "edicao.svg",
          //     route: "/avaliacoes/",
          //     id_da_tarefa: 613346
          // },
          // {
          //     id: 2,
          //     evento: "31",
          //     title: "Aplicação de PAD",
          //     data: new Date(),
          //     status: "ABERTO",
          //     icon: "edicao.svg",
          //     route: "/avaliacoes/",
          //     id_da_tarefa: 613346
          // },
          // {
          //     id: 3,
          //     evento: "32",
          //     title: "Aplicação de PAD",
          //     data: new Date(),
          //     status: "ABERTO",
          //     icon: "edicao.svg",
          //     route: "/avaliacoes/",
          //     id_da_tarefa: 613346
          // },
          // {
          //     id: 4,
          //     evento: "33",
          //     title: "Aplicação de PAD",
          //     data: new Date(),
          //     status: "ABERTO",
          //     icon: "edicao.svg",
          //     route: "/avaliacoes/",
          //     id_da_tarefa: 613346
          // }
        ],
      },
      {
        inicio: '2022-11-27T03:00:00.000Z',
        final: '2022-12-03T03:00:00.000Z',
        tarefas: [
          // VENCIDOS
          {
            id: 2,
            evento: '36',
            title: 'Aplicação de PAD 1',
            data: '2022-11-28T03:00:00.000Z',
            status: 'VENCIDO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 9,
            evento: '38',
            title: 'Aprovar ocorrência 2',
            data: '2022-12-01T03:00:00.000Z',
            status: 'VENCIDO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 9,
            evento: '38',
            title: 'Aprovação de desvio 3',
            data: '2022-12-01T03:00:00.000Z',
            status: 'VENCIDO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 9,
            evento: '38',
            title: 'Aprovar documentos 4',
            data: '2022-12-02T03:00:00.000Z',
            status: 'VENCIDO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },

          // ABERTOS
          {
            id: 1,
            evento: '34',
            title: 'Aplicação de PAD 5',
            data: '2022-11-28T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 4,
            evento: '38',
            title: 'Aplicação de PAD 6',
            data: '2022-11-29T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 5,
            evento: '38',
            title: 'Aplicação de PAD 7',
            data: '2022-11-30T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 6,
            evento: '38',
            title: 'Aplicação de PAD 8',
            data: '2022-11-30T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 7,
            evento: '38',
            title: 'Aplicação de PAD 9',
            data: '2022-11-30T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 8,
            evento: '38',
            title: 'Aprovar CAE 10',
            data: '2022-12-01T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 8,
            evento: '38',
            title: 'Aprovar formulário 11',
            data: '2022-12-01T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 8,
            evento: '38',
            title: 'Finalizar desvio 12',
            data: '2022-12-01T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          // CONCLUIDOS
          {
            id: 3,
            evento: '37',
            title: 'Aplicação de PAD 13',
            data: '2022-11-28T03:00:00.000Z',
            status: 'CONCLUIDO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 8,
            evento: '38',
            title: 'Aprovar novo layout 14',
            data: '2022-12-01T03:00:00.000Z',
            status: 'CONCLUIDO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 8,
            evento: '38',
            title: 'Preenchimento 15',
            data: '2022-12-01T03:00:00.000Z',
            status: 'CONCLUIDO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 8,
            evento: '38',
            title: 'Testemunhar avaliação',
            data: '2022-12-01T03:00:00.000Z',
            status: 'CONCLUIDO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 8,
            evento: '38',
            title: 'Testemunhar avaliação',
            data: '2022-12-01T03:00:00.000Z',
            status: 'CONCLUIDO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 8,
            evento: '38',
            title: 'Testemunhar avaliação',
            data: '2022-12-01T03:00:00.000Z',
            status: 'CONCLUIDO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
        ],
      },
    ],
  },
  {
    mes: 11,
    semanas: [
      {
        inicio: '2022-12-04T03:00:00.000Z',
        final: '2022-12-11T03:00:00.000Z',
        tarefas: [
          {
            id: 1,
            evento: '39',
            title: 'Aplicação de PAD',
            data: '2022-12-04T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 2,
            evento: '40',
            title: 'Aplicação de PAD',
            data: '2022-12-04T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 3,
            evento: '41',
            title: 'Aplicação de PAD',
            data: '2022-12-04T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 4,
            evento: '42',
            title: 'Aplicação de PAD',
            data: '2022-12-04T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
        ],
      },
      {
        inicio: '2022-12-12T03:00:00.000Z',
        final: '2022-12-17T03:00:00.000Z',
        tarefas: [
          {
            id: 1,
            evento: '43',
            title: 'Aplicação de PAD',
            data: '2022-12-12T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 2,
            evento: '44',
            title: 'Aplicação de PAD',
            data: '2022-12-12T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 3,
            evento: '45',
            title: 'Aplicação de PAD',
            data: '2022-12-12T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 4,
            evento: '46',
            title: 'Aplicação de PAD',
            data: '2022-12-12T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
        ],
      },
      {
        inicio: '2022-12-18T03:00:00.000Z',
        final: '2022-12-24T03:00:00.000Z',
        tarefas: [
          {
            id: 1,
            evento: '47',
            title: 'Aplicação de PAD',
            data: '2022-12-18T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 2,
            evento: '48',
            title: 'Aplicação de PAD',
            data: '2022-12-18T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 3,
            evento: '49',
            title: 'Aplicação de PAD',
            data: '2022-12-18T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 4,
            evento: '50',
            title: 'Aplicação de PAD',
            data: '2022-12-18T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
        ],
      },
      {
        inicio: '2022-12-25T03:00:00.000Z',
        final: '2022-12-31T03:00:00.000Z',
        tarefas: [
          {
            id: 1,
            evento: '51',
            title: 'Aplicação de PAD',
            data: '2022-12-25T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 2,
            evento: '52',
            title: 'Aplicação de PAD',
            data: '2022-12-25T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 3,
            evento: '53',
            title: 'Aplicação de PAD',
            data: '2022-12-25T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
          {
            id: 4,
            evento: '54',
            title: 'Aplicação de PAD',
            data: '2022-12-25T03:00:00.000Z',
            status: 'ABERTO',
            icon: 'edicao.svg',
            route: '/avaliacoes/',
            id_da_tarefa: 613346,
          },
        ],
      },
    ],
  },
];

export const mockDay = new Date('2022-11-30T02:59:59.999Z');
