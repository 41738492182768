import styled, { css } from 'styled-components';
import { Button, Icon } from '@mui/material';

// Janela Modal
export const Main = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 95%;
    max-width: 95%;
    overflow-y: scroll;

    background-color: ${theme.palette.system.overlay};
    padding: 20px;
  `}
`;

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

// Corpo do curriculo
export const Wrapper = styled.div`
  width: 700px;
  height: 990px;
  margin: 10px;
  padding: 15px 20px 15px 15px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    width: 100%;
    height: 8%;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.palette.brand.primary.dark};

    img {
      height: 98%;
      position: absolute;
      right: 0px;
      top: 0px;
    }

    div {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 30px;
      top: 30px;
      background-color: ${theme.palette.semantics.feedback.warning.natural};
      width: 70px;
      height: 70px;
      border-radius: 4px;
    }

    h2 {
      color: ${theme.palette.semantics.feedback.unknown.light};
      font-size: 25px;
    }

    h3 {
      position: absolute;
      left: 140px;
      top: 30px;
      color: ${theme.palette.semantics.feedback.unknown.light};
      font-size: 25px;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 86%;
`;

export const ContentProfile = styled.div`
  ${({ theme }) => css`
    width: 40%;
    height: 100%;
    background-color: ${theme.palette.system.divider};
    padding: 20px;
  `}
`;

export const ContentCurriculo = styled.div`
  ${({ theme }) => css`
    width: 60%;
    height: 100%;
    background-color: ${theme.palette.system.overlay};
    padding: 20px;
  `}
`;

export const FooterCurriculo = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 6%;
    background-color: ${theme.palette.brand.primary.dark};
    position: relative;

    img {
      height: 50%;
      position: absolute;
      left: 50px;
      top: 50%;
      transform: translate(0, -50%);
    }

    div {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;

      img {
        height: 20px;
        width: 20px;
        position: relative;
        left: 0px;
      }
    }
  `}
`;

// Itens do conteudo
export const ItemTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 20px;
    justify-content: flex-begin;
    align-items: normal;
    border-bottom: 1px solid ${theme.palette.words.title.light};

    h2 {
      color: ${theme.palette.words.title.dark};
      font-size: 16px;
      margin-left: 10px;
    }
  `}
`;

export const StyledIcon = styled(Icon)(({ theme }) => ({
  opacity: 1,
  color: theme.palette.semantics.feedback.warning.natural,
  transition: '0.3s ease-out',
  fontSize: '25px',
}));

export const StyledCustomIcon = styled('img')(({ theme }) => ({
  width: '25px',
  height: '25px',
  color: theme.palette.semantics.feedback.warning.natural,
}));

export const ItemContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: baseline;
    min-height: 25px;

    h2 {
      color: ${theme.palette.words.title.dark};
      font-size: 11px;
      font-weight: bold;
      line-height: 20px;
    }

    h3 {
      color: ${theme.palette.words.title.dark};
      font-size: 11px;
      font-weight: normal;
      margin-left: 10px;
      display: flex;
      line-height: 20px;
    }
  `}
`;
