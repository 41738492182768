import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { subDays } from 'date-fns';
import { useTheme } from 'styled-components';

import { Divider, SvgIcon } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import GhostButton from 'components/Buttons/Ghost';
import { RoundedTabs } from 'components/RoundedTabs';

import { Header } from './views/Header';
import { Desvios } from './views/Desvios';
import { Frota } from './views/Frota';
import { AcidentesMultas } from './views/AcidentesMultas';
import { Estatisticas, dateMap } from './views/Estatisticas';
import { AcoesSuspensoes } from './views/AcoesSuspensoes';
import { CustomizedSelect } from 'components/SectionPeriod';
import { ReactComponent as filterIco } from 'images/icons/filter.svg';
import { StyledButton } from 'pages/Desvios/Estatisticas/DesempenhoGeral/styled';
import Filters from '../../../Motoristas/PerfilMotorista/components/Filters';

import { tabs } from './constants';
import * as S from './styled';
import { usePlans } from 'hooks/usePlans';
import ExportModal from 'components/ExportModal';
import { useReactToPrint } from 'react-to-print';
import { requestExcel } from '../services';
import { downloadFromLink } from 'utils/helpers';
import { toast } from 'react-toastify';
import { PDF } from './views/PDF';
import {
  requestDesvios,
  requestAcoesSuspensoes,
  requestFrotas,
} from './views/PDF/services';

export const PerfilEmpresa = () => {
  const theme = useTheme();
  const params = useParams();
  const { planosAtivos } = usePlans();
  const [tab, setTab] = useState(tabs[0]);
  const [printTabs, setPrintTabs] = useState(tabs.map(item => item.value));
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const promiseResolveRef = useRef(null);
  const componentRef = useRef();

  const [dataAcoes, setDataAcoes] = useState([]);
  const [dataDesvios, setDataDesvios] = useState([]);
  const [dataFrota, setDataFrota] = useState([]);

  const [openExport, setOpenExport] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [period, setPeriod] = useState('30DIAS');
  const [filtersPage, setFiltersPage] = useState({
    finalDate: new Date(),
    initialDate: subDays(new Date(), 30),
  });

  const handleExport = (exportType, selectedRadio, filters) => {
    if (exportType === 'EXCEL') {
      const _tabs = selectedRadio
        ? [tabs.find(item => item.value === selectedRadio)?.label]
        : tabs.filter(i => i.value != 'estatisticas').map(item => item.label);
      handleRequestExcel({ ...filters, tabs: _tabs });
    } else if (exportType === 'PDF') {
      const _printTabs = selectedRadio
        ? [selectedRadio]
        : tabs.map(item => item.value);
      setPrintTabs(_printTabs);

      const promises = [];

      // request desvios
      if (_printTabs.includes('desvios')) {
        const promiseDesvio = requestDesvios(params.id, {
          ...filters,
          isExport: true,
        })
          .then(response => {
            setDataDesvios(
              response?.data?.data?.map(item => ({
                ...item,
                periodo: `Últimos ${dateMap[period]}.`,
              })) ?? [],
            );
          })
          .catch(err => toast.error('Falha ao buscar desvios'));
        promises.push(promiseDesvio);
      }

      // request ações
      if (_printTabs.includes('acoes')) {
        const promiseDesvio = requestAcoesSuspensoes(params.id, {
          ...filters,
          isExport: true,
        })
          .then(response => {
            setDataAcoes(
              response?.data?.data?.map(item => ({
                ...item,
                periodo: `Últimos ${dateMap[period]}.`,
              })) ?? [],
            );
          })
          .catch(err => toast.error('Falha ao buscar ações e suspensões'));
        promises.push(promiseDesvio);
      }

      // request frota
      if (_printTabs.includes('frota')) {
        const promiseDesvio = requestFrotas(params.id, {
          ...filters,
          isExport: true,
        })
          .then(response => {
            setDataFrota({
              data:
                response?.data?.data?.map(item => ({
                  ...item,
                  periodo: `Últimos ${dateMap[period]}.`,
                })) ?? [],
              inativos: response?.data?.inativos ?? 0,
            });
          })
          .catch(err => toast.error('Falha ao buscar frota'));
        promises.push(promiseDesvio);
      }

      Promise.all(promises).then(promises => {
        handlePrint();
      });
    }
  };

  const handleRequestExcel = useCallback(
    async query => {
      setLoadingPrint(true);
      const newQuery = {
        ...query,
      };
      const res = await requestExcel(params.id, newQuery);
      if (res.link) {
        downloadFromLink(res.link);
        toast.success(res.message);
      } else if (res.message) toast.error(res.message);

      setLoadingPrint(false);
    },
    [params.id],
  );

  // Aguarda a atualizacao dos states do PDF
  useEffect(() => {
    if (loadingPrint && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [loadingPrint]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise(resolve => {
        promiseResolveRef.current = resolve;
        setLoadingPrint(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setLoadingPrint(false);
    },
  });

  return (
    <>
      <Header id={params.id} handleClickExport={() => setOpenExport(true)} />
      <S.TabContainer>
        <div className="flex-1">
          <RoundedTabs
            items={tabs.filter(
              tab =>
                !tab.plans ||
                planosAtivos.some(plan => tab.plans.includes(plan.id_do_plano)),
            )}
            value={tab.value}
            onChange={value => setTab(tabs.find(_tab => _tab.value === value))}
          />
          <Divider />
        </div>
        {tab.link ? (
          <GhostButton
            icon={<ExitToAppIcon />}
            onClick={() => window.open(tab.link.url.replace(':id', params.id))}
          >
            IR PARA {tab.link.label}
          </GhostButton>
        ) : tab.filters ? (
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            {tab.date && (
              <CustomizedSelect
                today={filtersPage.finalDate}
                selectedDate={filtersPage.initialDate}
                selectedPeriod={period}
                handleChangeDate={value =>
                  setFiltersPage(state => ({ ...state, initialDate: value }))
                }
                handleChangePeriod={value => setPeriod(value)}
              />
            )}
            <StyledButton
              textcolor={theme.palette.words.text.natural}
              backgroundcolor="transparent"
              startIcon={<SvgIcon component={filterIco} />}
              height="45px"
              style={{ marginTop: '3px' }}
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              Filtros
            </StyledButton>
          </div>
        ) : null}
      </S.TabContainer>
      <S.Content>
        {tab.value === 'estatisticas' && (
          <Estatisticas
            filters={{
              ...filtersPage,
              filial: filtersPage.filial ? filtersPage.filial.split(',') : null,
            }}
            period={period}
          />
        )}
        {tab.value === 'desvios' && <Desvios />}
        {tab.value === 'acoes' && <AcoesSuspensoes />}
        {tab.value === 'frota' && (
          <Frota filters={filtersPage} period={period} />
        )}
        {tab.value === 'acidentes_multas' && <AcidentesMultas />}
      </S.Content>
      <Filters
        id="simple-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        filter={filtersPage}
        setFilter={setFiltersPage}
        idEmpresa={params.id}
      />
      <ExportModal
        date
        open={openExport}
        handleClose={() => setOpenExport(false)}
        radioItems={tabs.filter(
          tab =>
            !tab.plans ||
            planosAtivos.some(plan => tab.plans.includes(plan.id_do_plano)),
        )}
        handleClick={handleExport}
        setFilterAnchorEl={setAnchorEl}
        filters={filtersPage}
        setFilters={setFiltersPage}
        period={period}
        setPeriod={setPeriod}
        loading={loadingPrint || loadingData}
      />
      <PDF
        id={params.id}
        ref={componentRef}
        filters={{
          ...filtersPage,
          filial: filtersPage.filial ? filtersPage.filial.split(',') : null,
        }}
        period={period}
        printTabs={printTabs}
        dataDesvios={dataDesvios}
        dataAcoes={dataAcoes}
        dataFrota={dataFrota?.data ?? []}
        totalInativos={dataFrota?.inativos ?? 0}
        setLoadingData={setLoadingData}
      />
    </>
  );
};
