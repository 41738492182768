import React from 'react';
import Input from '../../DataHour';
import { Controller } from 'react-hook-form';

const DateHour = ({ control, name, rules, errors, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <Input
          style={{ maxWidth: '100%' }}
          value={value}
          onChange={newDate => onChange(newDate)}
          error={!!errors?.[name]?.message}
          {...props}
        />
      )}
    />
  );
};

export default DateHour;
