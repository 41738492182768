import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 24px;
    color: ${theme.palette.brand.primary.natural};
    margin-top: -15px;
    margin-bottom: 5px;
    font-weight: 900;
    letter-spacing: 1.1px;
  `}
`;

export const Subtitle = styled.span`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal medium 14px/30px Texta;
    letter-spacing: 0px;
    color: ${theme.palette.words.subtitle.light};
    opacity: 1;
  `}
`;

export const LoadingBox = styled.div`
  position: relative;
  width: 100%;
  height: 210px;
`;
