import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  status: '',
  turma: '',
};

export default function filterCapacitacaoProvider(
  state = INITIAL_STATE,
  action,
) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_CAPACTACAO_PROVIDER: {
        draft.status = action.payload.query.status;
        draft.turma = action.payload.query.turma;
        break;
      }
    }
  });
}
