// Styles
import { useTheme } from 'styled-components';

// React
import { useState } from 'react';

// Components
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Material UI
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import PlayLessonOutlinedIcon from '@mui/icons-material/PlayLessonOutlined';

// Utils
import * as S from './styled';
import { formatDescription, formatStatus, formatTitle } from '../../actions';

const ContentCard = ({
  template,
  isTemplate = false,
  noTags,
  loadingHistoryLine,
  onClickNavigateEdit,
  onClickSketch,
  loadingScreen,
  onClickView,
  loadingCard,
  onClickTemplate,
}) => {
  const theme = useTheme();
  let timerReset = null;

  const [loading, setLoading] = useState(false);
  const [screenView, setScreenView] = useState('100%');
  const [inHover, setInHover] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const img =
    'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/capacitacao%2Finfo%2Fcapas%2F1673467756358%2F2023-01-11_17-09-19_Capa_Conteudo_Capacitacao.jpg?alt=media&token=3ca2cd3b-f1c8-438a-a277-88580f7cbdbd';

  // ---------------------------TEXT FORMATING --------------------------//
  const formatedFinsih = date => {
    if (!date) {
      return 'Nunca encerrar';
    }
    return `Até: ${new Date(date).toLocaleDateString()}`;
  };

  // -------------------------CALLED BY TEMPLATE -----------------------//
  const view = async id => {
    setCurrentId(id);
    await onClickView(id);
    setCurrentId(null);
  };

  // -------------------------CALLED BY LIST -----------------------//

  const edit = async (id, status) => {
    if (status === 'Rascunho') {
      await onClickSketch(template.id);
      setCurrentId(null);
    } else {
      await onClickNavigateEdit(template.id);
      setCurrentId(null);
    }
  };

  // -------------------------CALLED BY FORM ME -----------------------//
  const selectTemplate = async id => {
    setCurrentId(id);
    await onClickTemplate(id);
    setCurrentId(null);
  };

  const resetScreenHistory = () => {
    if (loadingHistoryLine || screenView === '100%') return;

    clearTimeout(timerReset);
    timerReset = setTimeout(() => {
      setScreenView('100%');
    }, 1000);
  };

  return (
    <>
      <S.Wrapper
        className="cardContent"
        charge={loadingCard}
        loadingScreen={loadingScreen}
        // noTags={noTags}
        onMouseLeave={() => {
          setInHover(false);
          resetScreenHistory();
        }}
        onMouseEnter={() => {
          setInHover(true);
          clearTimeout(timerReset);
        }}
      >
        {loadingCard && currentId === template.id && (
          <S.LoadingBox>
            <Loading />
          </S.LoadingBox>
        )}
        <>
          <S.Image
            src={template?.imagem_capa || img}
            finished={
              // !noTags &&
              template.form_status === 'Finalizado' ||
              template.form_status === 'Concluido' ||
              template.status === 'Finalizado' ||
              template.status === 'Concluido'
            }
          />
          <S.Content
            screenView={screenView}
            inHover={inHover /* && !isTemplate */}
            className="content"
            // noTags={noTags}
            finished={
              // !noTags &&
              template.form_status === 'Finalizado' ||
              template.form_status === 'Concluido' ||
              template.status === 'Finalizado' ||
              template.status === 'Concluido'
            }
            inLineLoading={loadingHistoryLine}
          >
            <div className="screenPrimary">
              {/* {!noTags && ( */}
              <S.TagsArea>
                {template?.tags.map((tag, key) => {
                  if (key > 2) {
                    return null;
                  }
                  if (tag.length > 14) {
                    return <S.Tag key={key}>{`${tag.slice(0, 10)}...`}</S.Tag>;
                  }
                  return <S.Tag key={key}>{tag}</S.Tag>;
                })}
              </S.TagsArea>
              {/* )} */}

              <h6 className="title">{formatTitle(template.titulo)}</h6>
              <p className="description">
                {formatDescription(
                  template.descricao,
                  /* isTemplate */ false,
                  inHover /* && !isTemplate */,
                )}
              </p>
              {/* {!noTags && ( */}
              <span className="statusBox">
                {formatStatus(template.status)}

                <span className="finished">
                  {formatedFinsih(template.data_fim)}
                </span>
              </span>
              {/* )} */}

              <S.MediaContainer
                // noTags={noTags}
                forMe={false}
                inHover={inHover /* && !isTemplate */}
              >
                {template?.midiaCount?.VÍDEO && (
                  <S.StyledTooltip
                    title={`Vídeo (${template?.midiaCount?.VÍDEO})`}
                    arrow
                  >
                    <SlideshowOutlinedIcon
                      sx={{
                        color: theme.palette.words.text.light,
                        marginRight: 1,
                      }}
                    />
                  </S.StyledTooltip>
                )}
                {template?.midiaCount?.VÍDEO_UPLOADING && (
                  <S.StyledTooltip
                    title={`Upload (${template?.midiaCount?.VÍDEO_UPLOADING})`}
                    arrow
                  >
                    <BackupOutlinedIcon
                      sx={{
                        color: theme.palette.words.text.light,
                        marginRight: 1,
                      }}
                    />
                  </S.StyledTooltip>
                )}
                {template?.midiaCount?.TEXTO && (
                  <S.StyledTooltip
                    title={`Texto (${template?.midiaCount?.TEXTO})`}
                    arrow
                  >
                    <ArticleOutlinedIcon
                      sx={{
                        color: theme.palette.words.text.light,
                        marginRight: 1,
                      }}
                    />
                  </S.StyledTooltip>
                )}
                {template?.midiaCount?.IMAGEM && (
                  <S.StyledTooltip
                    title={`Imagem (${template?.midiaCount?.IMAGEM})`}
                    arrow
                  >
                    <PhotoOutlinedIcon
                      sx={{
                        color: theme.palette.words.text.light,
                        marginRight: 1,
                      }}
                    />
                  </S.StyledTooltip>
                )}
                {template?.midiaCount?.PDF && (
                  <S.StyledTooltip
                    title={`PDF (${template?.midiaCount?.PDF})`}
                    arrow
                  >
                    <PlayLessonOutlinedIcon
                      sx={{
                        color: theme.palette.words.text.light,
                        marginRight: 1,
                      }}
                    />
                  </S.StyledTooltip>
                )}
              </S.MediaContainer>

              <S.Buttons
                // noTags={noTags}
                forMe={false}
                inHover={inHover /* && !isTemplate */}
              >
                <GhostButton
                  children="Visualizar"
                  style={{ width: '100%', marginBottom: '10px' }}
                  onClick={() => view(template?.id)}
                />

                {!isTemplate && (
                  <DefaultButton
                    children="Editar"
                    style={{ width: '100%' }}
                    onClick={() => edit(template?.id, template.status)}
                  />
                )}

                {isTemplate && (
                  <DefaultButton
                    children="Selecionar Template"
                    style={{ width: '100%' }}
                    onClick={() => selectTemplate(template?.id)}
                  />
                )}
              </S.Buttons>
            </div>
            <div className="screenSecondary" />
          </S.Content>
        </>
      </S.Wrapper>
    </>
  );
};

export default ContentCard;
