const fields = [
  {
    value: 15,
    label: 'Empresa',
  },
  {
    value: 16,
    label: 'Filial (Preenchido)',
  },
  {
    value: 17,
    label: 'Usuário',
  },
  {
    value: 18,
    label: 'Data & Hora (auto)',
  },
  {
    value: 19,
    label: 'Placa',
  },
  {
    value: 20,
    label: 'Gestor',
  },
  {
    value: 21,
    label: 'Motorista',
  },
  {
    value: 22,
    label: 'Cliente',
  },
  {
    value: 23,
    label: 'Operação',
  },
  {
    value: 24,
    label: 'Carregamento',
  },
  {
    value: 25,
    label: 'Modelo do VeÍculo',
  },
  {
    value: 26,
    label: 'Frota',
  },
  {
    value: 27,
    label: 'Departamento',
  },
  {
    value: 28,
    label: 'Cambio',
  },
  {
    value: 29,
    label: 'Propriedade',
  },
  {
    value: 30,
    label: 'Filial (Selecionar)',
  },
];

export const filters = [
  {
    label: 'Filtro por Campos',
    placeholder: 'Selecione os Campos',
    data: fields,
    mode: 'multiple',
    key: 'reportsFilters',
  },
];
