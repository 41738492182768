import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 5px;
`;

export const Square = styled.div`
  ${({ theme }) => css`
    display: flex;
    background-color: ${theme.palette.system.overlay};
    border-radius: 5px;
    border: 1px solid ${theme.palette.system.border};
    margin-top: 20px;
  `}
`;

export const Counter = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 22px;
    height: 22px;
    background: ${props =>
      props.counter > 0
        ? theme.palette.brand.secondary.natural
        : theme.palette.brand.primary.light};
    margin: 8px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    color: ${theme.palette.system.white};
  `}
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
