// Icons
import { ReactComponent as item1 } from 'images/capacitacao/configuracoes.svg';
import { ReactComponent as item2 } from 'images/capacitacao/publico.svg';
import { ReactComponent as item3 } from 'images/capacitacao/gerais.svg';
import { ReactComponent as item4 } from 'images/capacitacao/reports.svg';
import { ReactComponent as item5 } from 'images/capacitacao/curtidas.svg';
import { ReactComponent as item6 } from 'images/capacitacao/previa.svg';
import { ReactComponent as item7 } from 'images/capacitacao/midias.svg';
import { ReactComponent as item8 } from 'images/capacitacao/avaliacao.svg';

// Components
import { Gerais } from '../../Config/Gerais';
import { Midias } from '../../Config/Midias';
import { Avaliacao } from '../../Config/Avaliacao';
import { Publico } from '../../Config/Publico';
import { Configuracoes } from '../../Config/Configuracoes';
import { Publicar } from '../../Config/Publicar';
import { Reports } from '../../Config/Reports';
import { Reviews } from '../../Config/Reviews';

export const itensBar = [
  {
    id: 0,
    page: 'Informações Gerais',
    linkTo: '',
    icon: item3,
    Component: params => Gerais(params),
  },
  {
    id: 1,
    page: 'Mídias',
    linkTo: '',
    icon: item7,
    Component: params => Midias(params),
  },
  {
    id: 2,
    page: 'Avaliação',
    linkTo: '',
    icon: item8,
    Component: params => Avaliacao(params),
  },
  {
    id: 3,
    page: 'Público',
    linkTo: '',
    icon: item2,
    Component: params => Publico(params),
  },
  {
    id: 4,
    page: 'Configurações',
    linkTo: '',
    icon: item1,
    Component: params => Configuracoes(params),
  },
  {
    id: 5,
    page: 'Prévia do conteúdo',
    linkTo: '',
    icon: item6,
    Component: params => Publicar(params),
  },
  {
    id: 6,
    page: 'Reports',
    linkTo: '',
    icon: item4,
    Component: params => Reports(params),
  },
  {
    id: 7,
    page: 'Curtidas e Comentários',
    linkTo: '',
    icon: item5,
    Component: params => Reviews(params),
  },
];
