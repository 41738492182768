export const sortBy = [
  {
    id: 'data',
    desc: true,
  },
];

export const tabs = [
  {
    value: 0,
    label: 'Ativos',
  },
  {
    value: 1,
    label: 'Inativos',
  },
];
