import api from 'services/api';

const requestInfractions = async query => {
  const res = await api.get('/telematics_desvios', { params: query });
  return res;
};

const fetchInfraction = async (id, query) => {
  let res = await api.get(`/telematics_desvios/${id}`, { params: query });

  if (res.data.success) return res.data?.data;
  throw res.data.message;
};

const fetchSequence = async (id, query) => {
  let res = await api.get(`/telematics_desvios/paginator/${id}`, {
    params: query,
  });
  if (res.data.success) return res.data;
  throw res.data.message;
};

const requestHistory = async id => {
  const res = await api.get(`/telematics_desvios/${id}/logs`);
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel-provider/telematics_infractions_drivers', {
    params: query,
  });
  return res;
};

export {
  requestInfractions,
  fetchInfraction,
  fetchSequence,
  requestExcel,
  requestHistory,
};
