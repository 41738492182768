// COMPONENTS
import EmptyCard from 'components/Cards/EmptyDataCard';

// UTILS
import { formatNewDate } from 'utils/dates';
import { useTheme } from 'styled-components';
import * as S from './styled';

export const OcorrenciasCards = ({ ocorrencias, driver }) => {
  const theme = useTheme();
  // Logo caso não haja imagem na ocorrência
  const logo = require('images/Onisys_Simbolo.png');

  const setStatusColor = status => {
    switch (status) {
      case 'PENDENTE':
        return theme.palette.semantics.feedback.attention.natural;
      case 'ANALISE':
        return theme.palette.semantics.feedback.warning.natural;
      case 'APROVADA':
        return theme.palette.semantics.feedback.success.natural;
      case 'DELETADA':
        return theme.palette.semantics.feedback.unknown.light;
    }
  };

  return (
    <div>
      {ocorrencias &&
        ocorrencias.map((ocorrencias, idx) => (
          <S.DesvioBox key={idx} color={setStatusColor(ocorrencias.status)}>
            <div className="imgArea">
              <img src={ocorrencias.arquivo_evidencia || logo} />
            </div>
            <div className="contentArea">
              <div className="top">
                <span className="texts">
                  <span className="date">
                    {formatNewDate(ocorrencias.data_ocorrencia)}
                  </span>
                  <span className="id">#{ocorrencias.id}</span>
                </span>
              </div>
              <div className="medium">
                <span className="dsTitle">{`Ocorrência: ${ocorrencias.violacao?.descricao}`}</span>
                <span className="name">{driver.nome.toLowerCase()}</span>
              </div>
              <div className="bottom">
                <span className="status">
                  <span className="ball" />
                  <span className="text">
                    {ocorrencias.status.toLowerCase()}
                  </span>
                </span>
                <span className="type">
                  Pontuação <span>{ocorrencias?.pontuacao}</span>
                </span>
              </div>
            </div>
          </S.DesvioBox>
        ))}
    </div>
  );
};
