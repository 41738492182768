// React
import { useQuery } from 'react-query';
import React, { useState, useEffect } from 'react';

// components pagina

import { Deslocamentos } from './Deslocamentos';
import { InfractionsMap } from './InfractionsMap';
import { RodoviasPerigosas } from './RodoviasPerigosas';

// Styles
import * as S from './styled';

// services
import * as API from './services';
import { FatoresRisco } from './FatoresRisco';

const Rastreamento = () => {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedCriticity, setSelectedCriticity] = useState(null);
  const [infractions, setInfractions] = useState([]);
  const [updatedAt, setUpdatedAt] = useState(new Date());

  const { isLoading, data } = useQuery(
    ['home-rastreamento'],
    () => API.getRastreamento(),
    {
      refetchInterval: 60000,
      onSuccess: () => {
        setUpdatedAt(new Date());
      },
    },
  );

  useEffect(() => {
    if (data?.data?.length) {
      const _infractions = data.data
        .filter(
          item =>
            !selectedDriver ||
            item.id === selectedDriver.key ||
            item.id_empresa === selectedDriver.key,
        )
        .reduce((acc, item) => {
          return [...acc, ...item.desvios];
        }, []);
      setInfractions(_infractions);
    }
  }, [data, selectedDriver]);

  return (
    <S.Container templateColumns="1fr 2fr">
      <div className="style-container">
        <Deslocamentos
          drivers={data?.data || []}
          selectedDriver={selectedDriver}
          setSelectedDriver={setSelectedDriver}
          updatedAt={updatedAt}
          isLoading={isLoading}
        />
      </div>
      <div style={{ width: '100%' }}>
        <div className="style-container">
          <InfractionsMap
            infractions={infractions.filter(
              item =>
                (!selectedAddress || item.endereco === selectedAddress) &&
                (!selectedCriticity || item.criticidade === selectedCriticity),
            )}
            selectedDriver={selectedDriver}
            setSelectedDriver={setSelectedDriver}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            selectedCriticity={selectedCriticity}
            setSelectedCriticity={setSelectedCriticity}
            isLoading={isLoading}
          />
        </div>
        <div className="item-grid">
          <div className="style-container">
            <RodoviasPerigosas
              data={infractions}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              isLoading={isLoading}
            />
          </div>
          <div className="style-container">
            <FatoresRisco
              data={infractions}
              selectedCriticity={selectedCriticity}
              setSelectedCriticity={setSelectedCriticity}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </S.Container>
  );
};

export default Rastreamento;
