import React, { Fragment } from 'react';
import * as S from './styled';
import { Item } from './Item';
import GraficoBarraVertical from './Grafico';
import { formatNewTime } from 'utils/dates';
import { usePlans } from 'hooks/usePlans';
import Empty from 'components/Empty';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const Deslocamentos = ({
  drivers = [],
  selectedDriver,
  setSelectedDriver,
  updatedAt,
  isLoading = false,
}) => {
  const navigate = useNavigate();

  const { isProvider: hasProvider, hasTorrePlus, hasTorre } = usePlans();

  const isProvider = hasProvider && !hasTorre && !hasTorrePlus;

  if (isProvider)
    drivers = drivers.map(item => ({ ...item, id: item.id_empresa }));

  const graphData = drivers.reduce((acc, curr) => {
    const item = acc.find(i => i.id === curr.id_filial);
    if (item) item.value += 1;
    else acc.push({ id: curr.id_filial, value: 1, name: curr.nome_filial });
    return acc;
  }, []);

  graphData.sort((a, b) => b.value - a.value);

  const graphDataProvider = drivers.reduce((acc, curr) => {
    const item = acc.find(i => i.id === curr.id_empresa);
    if (item) item.value += curr.total_viagens;
    else
      acc.push({
        id: curr.id_empresa,
        value: curr.total_viagens,
        name: curr.nome,
      });
    return acc;
  }, []);

  graphDataProvider.sort((a, b) => b.value - a.value);

  const total = isProvider
    ? drivers.reduce((total, item) => {
        return (total += item.total_viagens);
      }, 0)
    : drivers.length;

  return (
    <S.Container>
      <S.Title>
        {isProvider
          ? 'Deslocamentos nas últimas 24h'
          : 'Deslocamentos acontecendo agora'}
        <span className="point" />
      </S.Title>
      <S.Subtitle>Atualizado às {formatNewTime(updatedAt)}</S.Subtitle>
      <S.GraphContainer direction={isProvider}>
        {isLoading ? (
          <Skeleton width="100%" animation="wave">
            <S.GraphTitle>0</S.GraphTitle>
          </Skeleton>
        ) : total ? (
          <>
            <S.GraphTitle>{total ?? 0}</S.GraphTitle>
            <GraficoBarraVertical
              data={isProvider ? graphDataProvider : graphData}
            />
          </>
        ) : (
          <Empty
            title="Nenhum deslocamento encontrado."
            subtitle=" "
            image="motorista.png"
            height="100%"
            vertical
          />
        )}
      </S.GraphContainer>
      <S.ContainerList>
        {isLoading
          ? [1, 2, 3, 4, 5].map(item => (
              <Fragment key={item}>
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height="60px"
                  animation="wave"
                />
                <br />
              </Fragment>
            ))
          : drivers.slice(0, 500).map(item => (
              <Item
                active={selectedDriver && item?.id === selectedDriver?.key}
                key={item?.id_viagem || item?.id}
                name={item?.nome || ''}
                nome_empresa={item?.nome_empresa || ''}
                address={item?.endereco || ''}
                infractions={item?.desvios?.length || null}
                risco={item?.risco || null}
                idDeslocamento={item?.id_viagem || null} // Passando idDeslocamento dinamicamente
                onClick={() =>
                  setSelectedDriver(selected =>
                    selected?.key !== item.id
                      ? {
                          key: item.id,
                          name: item.nome,
                          ultima_localizacao: item.ultima_localizacao,
                        }
                      : null,
                  )
                }
              />
            ))}

        {hasTorrePlus && drivers.length > 0 && (
          <div
            className="deslocamentos-link"
            onClick={() => navigate('/desvios/deslocamentos')}
          >
            Ver todos os deslocamentos
          </div>
        )}

        {!hasTorrePlus && (
          <div
            className="deslocamentos-link"
            onClick={() => navigate('/desvios')}
          >
            Ver todos os desvios
          </div>
        )}
      </S.ContainerList>
    </S.Container>
  );
};
