import React from 'react';
import Input from 'components/Inputs/TextField';
import { Controller } from 'react-hook-form';
import { objectGet } from 'utils/helpers';

const TextField = React.forwardRef(
  ({ control, name, rules, errors, ...props }, ref) => {
    const error = errors && objectGet(errors, name);
    return (
      <Controller
        ref={ref}
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <Input
            message={error?.message || null}
            error={!!error}
            {...props}
            {...field}
          />
        )}
      />
    );
  },
);

export default TextField;
