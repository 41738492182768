import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'components/Inputs/SelectCreatable';

const SelectCreatable = ({ control, name, rules, data, errors, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { name, value, onChange, ref } }) => (
        <Select
          ref={ref}
          name={name}
          data={data}
          value={value}
          handleChange={onChange}
          error={!!errors?.[name]}
          message={errors?.[name]?.message || null}
          {...props}
        />
      )}
    />
  );
};

export default SelectCreatable;
