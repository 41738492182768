import api from 'services/api';

export const getTrucks = async () => {
  const res = await api.get('/select-trucks-provider');
  return res;
};

export const requestExcel = async query => {
  const res = await api.get('/excel-provider/frota', { params: query });
  return res;
};

export const changeStatus = async id => {
  const res = await api.put(`/provider/frota-change-status/${id}`);
  return res;
};

export const changeStatusMany = async data => {
  const res = await api.put(`/provider/frota-change-status-many`, data);
  return res;
};

export const updateTruck = async data => {
  const res = await api.put(`/provider/frota/${data.id}`, data);
  return res;
};

export const requestLogs = async id => {
  const res = await api.get(`provider/frota-logs/${id}`);
  return res;
};
