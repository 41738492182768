import api from 'services/api';
import { iconsCards } from './constants';

export const getAcoesSuspensoes = async params => {
  const res = await api.get('/acoes-suspensoes', { params });
  return res;
};

export const getCards = async params => {
  const res = await api.get('/acoes-suspensoes/cards', { params });
  if (res?.data?.data)
    return res.data.data.map(card => ({
      ...card,
      icon: iconsCards[card.type],
    }));
  return [];
};

export const getTotal = async params => {
  const res = await api.get('/acoes-suspensoes/paginator', { params });
  if (res?.data?.total) return res.data.total;
  return 0;
};

export const requestExcelAcoes = async query => {
  const res = await api.get('/excel/acao_suspensao', { params: query });
  if (res?.data?.data?.link)
    return { link: res.data.data.link, message: res.data.message };
  return { message: res?.data?.message };
};

export const requestExcelMotoristas = async query => {
  const res = await api.get('/excel/acao_suspensao_motoristas', {
    params: query,
  });
  if (res?.data?.data?.link)
    return { link: res.data.data.link, message: res.data.message };
  return { message: res?.data?.message };
};

export const fetchClientsWithFaixas = async () => {
  const res = await api.get(`/acoes-suspensoes/clients`);
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.nome }));
};
