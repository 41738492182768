import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .icon {
    position: absolute;
    margin-left: 10px;
  }
`;

export const Input = styled.input`
  width: 280px;
  padding: 10px 10px 10px 36px;
  outline: none;
  background-color: transparent;
  border: 1px solid ${colors.greyTiny};
  border-radius: 6px;
  font-size: 16px;
  color: ${colors.grey};

  &::placeholder {
    color: ${colors.grey};
    font-weight: 500;
  }
`;
