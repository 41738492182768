// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

// Components
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';
import { DefaultTable } from 'components/_Table/templates/default';

// Components MUI
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddTurmas from './Modal/ListarTurmas';
import ViewTurma from './Modal/viewTurma';
import * as S from './style.js';

// Utils
import { steps } from '../constants';
import * as services from '../services';
import { columns } from './columns';
import banner_publico from '../../../../images/empty/publico.svg';

export const Publico = ({
  trySave,
  setChanged,
  setTrySave,
  setLoading,
  turmas,
  fetchTurma,
  setCurrentStep,
  refetch,
  openModalAddAluno,
  setOpenModalAddAluno,
}) => {
  const theme = useTheme();
  // Redux
  const formularios = useSelector(state => state.formularios);
  const turmasForm = formularios.gerais.turmas;

  const [confirm, setConfirm] = useState(false);
  const [firstAccess, setFirstAccess] = useState(true);

  // tabelas
  const [allClassSelected, setAllClassSelected] = useState([]);
  const [turmasSelected, setTurmasSelected] = useState(turmasForm || []);
  const [openModalTurma, setOpenModalTurma] = useState(false);
  const [idTurma, setIdTurma] = useState(null);
  const [turmasDisp, setTurmasDisp] = useState([]);
  const [resetTable, setResetTable] = useState(false);

  // Atualiza o objeto em background para manter os itens não salvos sempre atualizados
  useEffect(() => {
    const idsSelecteds = turmasSelected.map(item => item.id);
    const turmasSelecteds = turmas.filter(item =>
      idsSelecteds.includes(item.id),
    );
    setTurmasSelected(turmasSelecteds);
  }, [turmas]);

  useEffect(() => {
    if (!firstAccess) {
      setChanged(true);
    }
    setFirstAccess(false);

    // Filtra apenas as turmas que ainda não foram adicionadas
    const idsUsed = turmasSelected.map(item => {
      return item.id;
    });
    const disp = turmas.filter(item => !idsUsed.includes(item.id));
    setTurmasDisp(disp);
  }, [turmasSelected]);

  useEffect(() => {
    if (trySave) {
      if (turmasSelected?.length <= 0) {
        toast.error('Selecione ao menos uma turma para o formulário');
        setTrySave(false);
        return;
      }

      const data = {
        pagina: 'turmas',
        id: formularios.id,
        turmas: turmasSelected.map(item => ({ id_turma: item.id })),
      };

      saveInfo(data);
    }
  }, [trySave]);

  const saveInfo = async data => {
    const res = await services.SavePublic(data);
    setLoading(true);

    if (res.success) {
      refetch();
      toast.success(res.message);
      setChanged(false);
      setCurrentStep(steps[2]);
    } else {
      toast.error(res.message);
    }

    setTrySave(false);
    setLoading(false);
  };

  const handleDelete = () => {
    setResetTable(false);
    const classes = [];
    const result = turmasSelected.filter(
      item => !allClassSelected.includes(item.id),
    );
    setTurmasSelected(result);
    result.filter(item => classes.push(item.id));
    setResetTable(true);
  };

  return (
    <S.Container>
      {idTurma && (
        <ViewTurma
          openModal={openModalTurma}
          idturma={idTurma}
          behaviorCloseModal={() => {
            setOpenModalTurma(false);
            setIdTurma(null);
            fetchTurma();
          }}
        />
      )}

      <AddTurmas
        openModal={openModalAddAluno}
        behaviorCloseModal={() => {
          setOpenModalAddAluno(false);
        }}
        data={turmasDisp}
        setSelectedTurmas={data => {
          const classes = [];
          setTurmasSelected(turmasSelected.concat(data));
          turmasSelected.concat(data).map(item => {
            classes.push(item.id);
          });
        }}
        fetchTurma={fetchTurma}
      />

      <S.TableContainer>
        {turmasSelected?.length !== 0 ? (
          <DefaultTable
            data={turmasSelected || []}
            columns={columns}
            setSelectedRows={setAllClassSelected}
            reset={resetTable}
            transparent
            local
            bulk={[
              {
                title: 'Excluir',
                function: () => setConfirm(true),
              },
            ]}
            actions={[
              {
                title: 'Ver turma',
                function: id => {
                  setIdTurma(id);
                  setOpenModalTurma(true);
                },
              },
              {
                title: 'Excluir',
                function: id => {
                  if (!formularios.isEditable) return;
                  setAllClassSelected([Number(id)]);
                  setConfirm(true);
                },
              },
            ]}
            onClickRow={id => {
              setIdTurma(id);
              setOpenModalTurma(true);
            }}
            sortBy={{ id: 'updatedAt', order: 'DESC' }}
            searchKeys={['nome', 'tipoUser', 'filial']}
            empty={{
              title: 'Ops! Você não tem nenhuma selecionada.',
              description: 'Adicione novas turmas ao seu formulário.',
            }}
          />
        ) : (
          <div className="banner">
            <img src={banner_publico} alt="banner publico" />
            <h3>Adicione seu público.</h3>
            <DefaultButton
              children="Adicionar Turma"
              icon={<SettingsOutlinedIcon />}
              startIcon={<AddCircleOutlineIcon />}
              style={{ marginRight: '10px' }}
              onClick={() => setOpenModalAddAluno(true)}
            />
          </div>
        )}
      </S.TableContainer>
      <ConfirmModal
        open={confirm}
        handleClose={() => setConfirm(false)}
        title={`Exclusão de ${
          allClassSelected?.length !== 1 ? 'turmas' : 'turma'
        }`}
        titleIcon={
          <DeleteOutlineOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.attention.natural}
          />
        }
        subtitle={`Deseja realmente remover ${
          allClassSelected?.length !== 1 ? ' as turmas' : ' a turma'
        }`}
        buttonText="Excluir"
        onClick={() => {
          handleDelete();
          setConfirm(false);
        }}
      />
    </S.Container>
  );
};
