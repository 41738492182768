import * as S from './styled';

const getStatus = (status, isSuspensao = false) => {
  switch (status) {
    case 'BLOQUEADO':
      return 'SUSPENSO';
    case 'LIBERADO':
      return isSuspensao ? 'LIBERADO' : 'CONCLUÍDO';
    default:
      return status;
  }
};

const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista/Filial',
    id: 'motorista.nome',
    type: 'string',
    sort: true,
    subRow: {
      prefix: ' Filial:',
      key: 'item.motorista.filial.nome',
      value: (value, item) => item.motorista.filial.nome,
      type: 'string',
    },
  },
  {
    header: 'Faixa',
    id: 'acao.titulo',
    type: 'string',
    sort: true,
    children: (value, item) => (
      <S.TableName cor={item.acao?.cor}>{item.acao?.titulo}</S.TableName>
    ),
  },
  {
    header: 'Ação Disciplinar',
    id: 'acao.acao.nome',
    type: 'string',
    sort: true,
  },
  {
    header: 'Pontuação',
    id: 'pontos',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Data Alcance de Faixa',
    id: 'data_inicio',
    type: 'date',
    sort: true,
    subRow: {
      prefix: ' Liberação:',
      key: 'item.data_fim',
      value: (value, item) =>
        item.acao?.acao?.is_reincidencia &&
        getStatus(item.status, !!item.acao?.acao?.is_reincidencia) ===
          'SUSPENSO'
          ? item.data_fim
          : undefined,
      type: 'date',
    },
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    align: 'center',
    width: 150,
    value: (_, item) =>
      getStatus(item.status, !!item.acao?.acao?.is_reincidencia),
    conditional: [
      {
        condition: value =>
          ['LIBERADO', 'APROVADO', 'CONCLUÍDO'].includes(value),
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'PENDENTE',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'REPROVADO',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.dark,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => ['BLOQUEADO', 'SUSPENSO'].includes(value),
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.dark,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];

const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'motorista.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Matrícula',
    value: 'motorista.matricula',
    selected: true,
    default: true,
  },
  {
    label: 'Faixa',
    value: 'acao.titulo',
    selected: true,
    default: true,
  },
  {
    label: 'Ação Disciplinar',
    value: 'acao.acao.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'pontos',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Data alcance',
    value: 'data_inicio',
    selected: true,
    default: true,
  },
  {
    label: 'Responsável',
    value: 'responsavel.nome',
    selected: false,
    default: false,
  },
  {
    label: 'Disponibilizado Em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
];

const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};

export { columns, fields, resetExcelFields, getStatus };
