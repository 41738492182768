import styled, { css } from 'styled-components';

export const ControlsSession = styled.div`
  display: flex;
`;

export const Controls = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 40px;
    border-color: ${theme.palette.system.divider};
    border-style: solid;
    border-width: 1px;
    border-radius: 4px 4px 0px 0px;
    overflow: hidden;

    > :first-child {
      border-width: 0 !important;
    }
  `}
`;

export const ControlItem = styled.div`
  ${({ theme, selected }) => css`
    display: flex;
    width: 8.5rem;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background: ${selected
      ? theme.palette.system.disabled
      : theme.palette.system.overlay};
    font: normal normal medium 16px/18px Texta;
    letter-spacing: 0px;
    color: ${theme.palette.words.subtitle.natural};
    border-color: ${theme.palette.system.divider};
    border-style: solid;
    border-width: 0 0 0 1px;
    user-select: none;
    transition: all ease 0.1s;
  `}
`;

export const MapContainer = styled.div`
  ${({ theme, show }) => css`
    display: ${show ? 'block' : 'none'};
    background: ${theme.palette.system.overlay};
    box-shadow: 0px 5px 10px ${theme.palette.system.disabled};
    border-radius: 0 0 4px 4px;
    padding: 1rem;

    .map {
      height: 480px;
    }
  `}
`;

export const TableContainer = styled.div`
  ${({ show }) => css`
    display: ${show ? 'block' : 'none'};
  `}
`;

export const MapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const MapFilters = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    #searchForm input {
      padding: 10px;
      color: ${theme.palette.words.label.light};
      opacity: 1;
    }

    #searchForm fieldset {
      border: 1px solid ${theme.palette.system.divider};
      border-radius: 4px;
      padding: 10px;
    }
  `}
`;

export const MapActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;
