import api from 'services/api';

// ---------------------------INDEX REQUESTS-----------------------------//

export const requestAvaliacoesList = async query => {
  const res = await api.get('/provider/revisao-cliente', { params: query });
  return res;
};

export const requestCards = async query => {
  const res = await api.get('/provider/cards/revisao-cliente', {
    params: query,
  });
  return res;
};

export const toggleOperation = async data => {
  const res = await api.put('/provider/revisao-update', data);
  return res;
};

export const requestExcel = async query => {
  const res = await api.get('/excel-provider/reviewOperation', {
    params: query,
  });
  return res;
};
