import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  version: null,
  old_version: null,
};

export default function version(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.UPDATE_VERSION_SUCCESS: {
        draft.version = action.payload;
        draft.old_version = state.version;
        break;
      }
      default:
    }
  });
}
