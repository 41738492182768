import * as types from './types';

export function setTableParams({ tableName, data }) {
  return {
    type: types.SET_PARAMS,
    payload: { tableName, data },
  };
}

export function ResetTableParams(tableName) {
  return {
    type: types.RESET_PARAMS,
    payload: { tableName },
  };
}
