export const columnsSmall = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
  },
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: true,
    picture: 'foto',
  },
  {
    header: 'Conclusão',
    id: 'concluido',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Filial',
    id: 'filial',
    type: 'string',
    align: 'center',
  },
  {
    header: 'Tipo',
    id: 'tipo',
    type: 'string',
    align: 'center',
  },
  {
    header: 'Tentativas',
    id: 'tentativas',
    type: 'string',
    align: 'center',
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    align: 'center',
  },
];
