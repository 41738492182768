import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0px;
    right: 0px;
    width: 600px;
    height: 100%;
    background-color: ${theme.palette.system.overlay};
    padding: 0px 30px;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    border-bottom: 1px solid ${theme.palette.brand.primary.light}4d;

    div {
      display: flex;
      flex-direction: row;
      h2 {
        margin-left: 10px;
      }
    }
  `}
`;

export const Main = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    padding: 5px 15px;
    overflow-y: scroll;
    margin-bottom: 5px;

    .titleContainer {
      margin: 20px 0px;
    }
    .title {
      font-size: 18px;
      color: ${theme.palette.words.title.natural};
      font-weight: 600;
    }
    .ibm {
      font-size: 13px;
      color: ${theme.palette.words.title.light};
      font-weight: 600;
    }

    .titleEmails {
      font-size: 20px;
      color: ${theme.palette.words.title.natural};
      font-weight: 900;
      margin: 30px 0px;
    }

    .cabecalho {
      font-size: 14px;
      color: ${theme.palette.words.title.natural};
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  `}
`;

export const Seguir = styled.div`
  ${({ theme, seguindo }) => css`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 85px;
    padding: 15px;
    align-items: center;
    margin: 0px;
    border: 1px solid ${theme.palette.primary.light};
    border-radius: 4px;
    background-color: ${seguindo
      ? theme.palette.brand.primary.background
      : 'white'};

    .ico {
      width: 40px;
      height: 40px;
      margin: 0px 20px;
    }
    .titleContainer {
      margin: 20px 0px;
    }
    .title {
      font-size: 20px;
      color: ${theme.palette.words.title.natural};
      font-weight: 900;
    }
    .subtitle {
      font-size: 16px;
      color: ${theme.palette.brand.secondary.natural};
      text-decoration: underline;
    }
  `}
`;

export const Notificacoes = styled.div`
  ${({ theme }) => css`
    width: 100%;
    //height: 85px;
    padding: 15px;
    align-items: center;
    margin: 0px;

    border-radius: 4px;
    background-color: ${theme.palette.system.border};

    .item {
      font-size: 14px;
      color: ${theme.palette.words.title.natural};
      font-weight: 600;
    }
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 90px;
    border-top: 1px solid ${theme.palette.brand.primary.light}4d;
  `}
`;
