import colors from 'styles/colors';

import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';

export const tabs = [
  {
    value: 'monitoramento',
    label: 'Monitoramento',
    id: 1,
  },
  {
    value: 'capacitacao',
    label: 'Capacitação',
    id: 2,
  },
];

export const message =
  'Devido a uma necessidade técnica, os itens listados nos primeiros passos são limitados para edição.';

export const feedback = [
  {
    type: 'like',
    icon: <ThumbUpOutlinedIcon htmlColor={colors.greenSucces} />,
  },
  {
    type: 'deslike',
    icon: <ThumbDownOffAltOutlinedIcon htmlColor={colors.redDanger} />,
  },
  {
    type: 'comentarios',
    icon: <CommentOutlinedIcon />,
  },
];
