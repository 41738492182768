/* eslint-disable import/order */

// Styles
import * as S from './styled';
import * as C from '../styled';

// React
import axios from 'axios';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import React, { useState, Fragment, useEffect } from 'react';

// Components
import ResponseModal from '../../components/ModalResponse';

// Components MUI
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

// Services
import * as services from '../services';
import { DefaultTable } from 'components/_Table/templates/default';

import { columns } from './columns';

let timerRoute = null;
const chargeCards = [0, 1, 2];

const Certificados = () => {
  const [cards, setCards] = useState(chargeCards);
  const [idUser, setIdUser] = useState(null);
  const [tipoUser, setTipoUser] = useState(null);
  const [certificados, setCertificados] = useState([]);
  const [currentContent, setCurrentContent] = useState(null);
  const [openPreviewFull, setOpenPreviewFull] = useState(false);

  // --------------------------------------------REQUEST--------------------------------------------
  const { isFetching } = useQuery(
    ['certification-profile', 1],
    () => services.fetchAllCertificates(),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setCards(data.cards);
        setIdUser(data.aluno.id);
        setTipoUser(data.aluno.tipo);
        setCertificados(
          data?.data?.map(item => ({
            ...item,
            titulo: item?.conteudo?.titulo,
            coeficiente: item?.conteudo?.respostas[0]?.nota,
          })) || [],
        );
      },
      onError: data => toast.error(data?.message),
    },
  );

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    timerRoute = setTimeout(() => {
      if (!openPreviewFull) {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }
    }, 100);

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [openPreviewFull]);

  // --------------------------------------------DOWNLOAD--------------------------------------------
  const thirtyPartyRequest = async (url, idx = 0) => {
    try {
      await axios
        .get(url, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Certificado${idx > 0 ? `_${idx}` : ''}.pdf`,
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => toast.warn(error));
    } catch {
      toast.warn('Token de acesso expirado!');
    }
  };

  const downloadCertification = async id => {
    const contentId = certificados.find(i => Number(i.id) === Number(id))
      ?.conteudo?.id;
    const nota =
      certificados.find(i => Number(i.id) === Number(id))?.conteudo
        ?.respostas[0]?.nota || '-';
    const cert = await services.requestCertificate(id, { nota, contentId });
    if (cert.data.success) {
      const link = cert.data.data.link;
      if (link) {
        thirtyPartyRequest(link);
      }
    } else toast.error(cert.data.message);
  };

  // --------------------------------------------PREVIEW--------------------------------------------
  const handlePerformTest = async id => {
    const certificado = certificados.find(temp => +temp.id === +id);
    const data = certificado?.conteudo;
    const obj = {
      alunoId: idUser,
      tipo: tipoUser,
      percentual: data.percentual,
    };
    const res = await services.showContent(data.id, obj);
    const content = res.data.content || [];
    setCurrentContent(content);
    setOpenPreviewFull(true);
  };

  const actions = [
    {
      title: 'Ver conteúdo',
      function: id => {
        handlePerformTest(id);
      },
    },
    {
      title: 'Baixar certificado',
      function: id => downloadCertification(id),
    },
  ];
  // --------------------------------------------RENDER--------------------------------------------
  return (
    <S.Container>
      <S.Header>
        <h1>Meus Certificados</h1>
      </S.Header>

      <C.CardsContainer>
        {cards.map((card, k) => (
          <Fragment key={k}>
            {!!k && <div className="bar" />}
            <C.Card isLoading={!card?.value < 0 || !card?.text}>
              <div className="iconContainer">
                {k === 0 && <WorkspacePremiumIcon />}
                {k === 1 && <WorkspacePremiumIcon />}
                {k === 2 && <WorkspacePremiumIcon />}
              </div>
              <div className="texts">
                <h2 className="txt">
                  <span className="val">{card?.value}</span>
                  <span className="loading" />
                </h2>
                <p className="txt">
                  <span className="val">{card?.text}</span>
                  <span className="loading" />
                </p>
              </div>
            </C.Card>
          </Fragment>
        ))}
      </C.CardsContainer>

      <DefaultTable
        data={certificados || []}
        columns={columns}
        actions={[
          {
            title: 'Ver conteúdo',
            function: val => {
              handlePerformTest(val);
            },
          },
          {
            title: 'Baixar certificado',
            function: id => downloadCertification(id),
          },
        ]}
        searchKeys={['titulo']}
        placeholder="Buscar Certificado"
        onClickRow={val => handlePerformTest(val)}
        sortBy={{ id: 'id', order: 'DESC' }}
        empty={{
          title: 'Seus certificados aparecerão aqui!',
          subtitle:
            'Acesse o conteúdo e faça o teste para ganhar seu certificado',
        }}
      />
      {/* Modal Area */}
      {openPreviewFull && currentContent && (
        <ResponseModal
          open={openPreviewFull}
          onClose={() => {
            setCurrentContent(null);
            setOpenPreviewFull(false);
          }}
          data={currentContent}
          setCurentContent={setCurrentContent}
          preview
          backPath="/capacitacao/perfil?page=certificado"
        />
      )}
    </S.Container>
  );
};

export default Certificados;
