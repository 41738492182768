import api from 'services/api';

export const requestEstatisticasMapa = async query => {
  const res = await api.get(`/home/area-risco/mapa`, {
    params: query,
  });
  return res;
};

export const requestEstatisticasLista = async query => {
  const res = await api.get(`/home/area-risco/lista`, {
    params: query,
  });
  return res;
};

export const requestExcel = async query => {
  const res = await api.get('/excel/area_risco', {
    params: query,
  });
  if (res?.data?.data?.link)
    return { link: res.data.data.link, message: res.data.message };
  return { message: res?.data?.message };
};

export const getDesviosTipos = async () => {
  const res = await api.get(`/desvios/configuracoes/padroes`);
  return res.data?.data || [];
};
