// Pages
import { Upgrade } from 'pages/Upgrade';

export default {
  '/upgradePlano': {
    back: -1,
    title: 'Upgrade de Plano',
    defaults: [],
    page: <Upgrade />,
  },
};
