import styled, { css } from 'styled-components';

export const Paper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 540px;
    background-color: ${theme.palette.system.overlay};
    border: 0px 5px 20px ${theme.palette.system.border};
    border-radius: 8px;
    padding: 25px 25px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    h1 {
      color: ${theme.palette.words.title.natural};
      font: normal 900 20px Texta;
      margin: 0;
      padding: 0;
    }
  `}
`;

export const Main = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  gap: 0.5rem;
`;

export const DropContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    padding: 0.5rem;
    justify-content: center;
    border: 1px solid ${theme.palette.system.border};
    border-radius: 4px;
  `}
`;

export const ThemeSelector = styled.ul`
  display: flex;
  gap: 0.5rem;
`;

export const ThemeOption = styled.li`
  ${({ theme, color }) => css`
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    background: ${color};
    outline: solid 1px ${theme.palette.system.border};
    outline-offset: 2px;
    border-radius: 50%;

    &:hover {
      outline: solid 1px ${color};
    }
  `}
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  text-align: end;
`;
