import * as S from './styled';
import * as C from '../styled';

import { useEffect, useState } from 'react';

import DefaultButton from 'components/Buttons/Default';
import Loading from '../../../components/Loading';
import { AddCircleOutline } from '@mui/icons-material';
import GhostButton from 'components/Buttons/Ghost';

import { getRotas, createUser } from './services';
import { toast } from 'react-toastify';

export const Apis = () => {
  const [rotas, setRotas] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const res = await getRotas();

    if (res.success) {
      setRotas(res.data);
      setLoading(false);
    } else toast.error(res?.message ? res.message : 'Rotas não encontradas');
  };

  const requestUser = async () => {
    setLoading(true);
    const res = await createUser();

    if (res.success) {
      setUserData(res.data);
    } else toast.error(res?.message ? res.message : 'Erro ao criar usuário');
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <S.Container>
      <C.TitleArea>
        <span className="textArea">API's</span>
      </C.TitleArea>
      <DefaultButton
        startIcon={<AddCircleOutline />}
        size="medium"
        sx={{ margin: '1.5rem 0', padding: '0 1.25rem' }}
        onClick={() => requestUser()}
        disabled={loading}
      >
        GERAR NOVO USUÁRIO E TOKEN
      </DefaultButton>
      {userData && (
        <>
          <S.Label>
            Usuário
            <S.TextField
              type="text"
              disabled
              readOnly
              value={userData.usuario}
            />
          </S.Label>
          <S.Label>
            Token
            <S.TextField type="text" disabled readOnly value={userData.token} />
          </S.Label>
        </>
      )}

      {rotas.length ? (
        rotas.map((i, k) => (
          <S.CardContainer>
            <S.CardSubContainer>
              <S.CardTitle key={k}>{i.titulo}</S.CardTitle>
              <S.CardContent key={k}>{i.descricao}</S.CardContent>
            </S.CardSubContainer>
            <GhostButton
              size="medium"
              variant="outlined"
              width="30%"
              onClick={() => window.open(i.link_swagger)}
            >
              VER DOCUMENTAÇÃO DA API
            </GhostButton>
          </S.CardContainer>
        ))
      ) : (
        <Loading />
      )}
    </S.Container>
  );
};
