import styled, { css, keyframes } from 'styled-components';

const jump = keyframes`
    0% {
        padding-top: 0px;
    }
    50% {
        padding-top: 4px;
    }
    100% {
        padding-top: 0px;
    }
`;

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  position: relative;
`;

export const WeekBox = styled.div`
  display: flex;
  width: calc(100% * ${props => props.total});
  margin-left: calc(-100% * ${props => props.semana});
  transition: all ease 0.5s;
  height: 400px;
  overflow-y: scroll;

  @media (min-width: 2000px) {
    height: 650px;
  }
`;

export const TasksBox = styled.div`
  width: calc(100% / ${props => props.total});
  transition: opacity 0.7s ease-out;
  position: relative;
  padding: 5px;

  &.visible {
    opacity: 1;
  }

  &.invisible {
    opacity: 0.4;
  }
`;

export const Slide = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Task = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${theme.palette.system.divider};
    margin-top: 10px;
    height: 80px;
    border-radius: 5px;
    border-left: 6px solid ${props.colorBorder};
    background-color: ${theme.palette.system.highlight};
    padding: 0px 15px;
    opacity: ${props.finished === 'CONCLUIDO' ? 0.7 : 1};
    width: 100%;
    cursor: pointer;

    .leftArea {
      display: flex;

      .textArea {
        margin-left: 15px;

        .title {
          color: ${theme.palette.words.title.natural};
          font-weight: 500;
        }

        .date {
          color: ${theme.palette.words.subtitle.natural};
          font-size: 14px;
        }
      }
    }

    .rightArea {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
    }
  `}
`;

export const FinishButton = styled.button`
  ${({ theme }) => css`
    border: none;
    background-color: ${theme.palette.brand.primary.background};
    color: ${theme.palette.words.title.natural};
    font-weight: 500;
    padding: 2px 20px;
    border-radius: 20px;
  `}
`;

export const EmptyBox = styled.div`
  width: 100%;
  margin-top: -15px;
`;

export const SeeMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;

  .textSeeMore {
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.words.title.natural};
    cursor: pointer;
  }

  .divIcon {
    background-color: red;
    animation: ${jump} 1s ease infinite;
    position: relative;
    display: flex;
    justify-content: center;

    .icon {
      cursor: pointer;
      position: absolute;
    }
  }
`;
