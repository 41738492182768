import api from 'services/api';

export const requestEstatisticas = async query => {
  const res = await api.get(`/telematics_dashboard/area_de_risco`, {
    params: query,
  });
  return res;
};

export const requestExcel = async (isProvider, query) => {
  const res = await api.get(
    `/${isProvider ? 'excel-provider' : 'excel'}/telematics_area_de_risco`,
    {
      params: query,
    },
  );
  return res;
};

export const requestRodovias = async query => {
  const res = await api.get(`/rodovias`, {
    params: query,
  });
  return res?.data?.data ?? [];
};
