import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import * as S from './styled';

export const Card = ({ active, item, onClick, isLoading }) => {
  const defaultImage = require('images/empty/frota.png');

  const textFormat = (text, limit) => {
    if (text?.length > limit) {
      return `${text?.substring(0, limit)}...`;
    }
    return text;
  };

  return (
    <>
      <S.Container
        onClick={() => !isLoading && onClick(item)}
        mode={item?.id === active?.id}
        filled={item?.respondida}
      >
        <S.Loading isLoading={isLoading} />
        <div className="image">
          {!item?.imagem_capa && (
            <div className="content">
              <img src={defaultImage} alt="default_case" className="default" />
            </div>
          )}
          {item?.imagem_capa && (
            <div className="content">
              <img className="case" src={item?.imagem_capa} />
            </div>
          )}
          <div className="boxIcon">
            <PlayCircleFilledOutlinedIcon />
            <div className="timer">
              {item.tipo === 'IMAGEM' ? 'Imagem' : `${item?.tempo}:00`}
            </div>
          </div>
        </div>
        <div className="progress" />
        <div className="infos">
          <div className="section">
            <span>Conteúdo: </span> {textFormat(item?.conteudo, 25)}
          </div>
          <div className="title">{textFormat(item?.titulo, 35)} </div>
          <div className="description">{textFormat(item?.descricao, 85)}</div>
        </div>
      </S.Container>
    </>
  );
};
