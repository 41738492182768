export default data => {
  let message = null;

  if (!data?.rota || !data?.titulo || !data?.planos)
    message = 'Preencha todos os campos obrigatórios';
  else if (!data?.etapas?.length && !data.capa)
    message = 'Adicione pelo menos uma etapa ou defina uma capa';
  else if (
    !data?.etapas?.filter(step => step?.ativa).length &&
    !data?.capa?.ativa
  )
    message = 'Defina pelo menos uma etapa ou capa como ativa';

  return message;
};
