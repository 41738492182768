// Styles
import { useTheme } from 'styled-components';

// React
import { useState } from 'react';
import { toast } from 'react-toastify';

// Components
import TableLocal from 'components/TableLocal';

// Material UI
import { Tooltip } from '@mui/material';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import ModelPdf from './ModelPdf';
import * as S from './styled';

// Utils
import { columns } from './columns';
import * as services from '../services';

export const Curriculos = ({ candidatos }) => {
  const theme = useTheme();
  const [curriculo, setCurriculo] = useState(null);
  const [loadingCurriculo, setLoadingCurriculo] = useState(false);

  const handlePrintCurriculo = async item => {
    if (!loadingCurriculo) {
      setLoadingCurriculo(true);
      const res = await services.requestCurriculo(item.id);
      if (res.data.data) setCurriculo(res.data.data);
      else if (res.data.message) toast.error(res.data.message);
      setLoadingCurriculo(false);
    }
  };

  const formatTable = arr => {
    arr.forEach(i => {
      if (i.id === 'id') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                fontweight="bold"
                color={theme.palette.brand.primary.natural}
              >
                {row.original.id}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'nome') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <div className="container-img-name">
                <S.Paragraph color={theme.palette.brand.primary.natural}>
                  {row.original.nome}
                </S.Paragraph>
              </div>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'curriculo') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver className="center" style={{ cursor: 'pointer' }}>
              <Tooltip title="Ver currículo">
                <FilePresentOutlinedIcon />
              </Tooltip>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'celular') {
        i.Cell = function ({ row }) {
          return (
            <span style={{ cursor: 'pointer' }}>
              <span>{row.original.celular ? row.original.celular : '-'}</span>
            </span>
          );
        };
      } else if (i.id === 'cidade') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <div className="container-img-name">
                <S.Paragraph
                  fontweight="bold"
                  color={theme.palette.brand.primary.natural}
                >{`${row.original.cidade ? row.original.cidade : '-'}/${
                  row.original.estado ? row.original.estado : '-'
                }`}</S.Paragraph>
              </div>
            </S.ColDriver>
          );
        };
      }
    });
    return arr;
  };

  return (
    <S.Box>
      <TableLocal
        columns={formatTable(columns)}
        pageSizes={10}
        data={candidatos}
        sortBy={[]}
        permitIsSortedOccur
        onClickRow={item => handlePrintCurriculo(item)}
        tableType
        empty={{
          image: 'frota.png',
          title: 'Seus curriculos aparecerão aqui!',
          subtitle: 'Essa vaga ainda não possui nenhum currículo compartilhado',
        }}
        search={{
          inputLabel: 'Buscar Cliente',
          keys: ['nome'],
        }}
      />

      {curriculo && (
        <ModelPdf open={curriculo} handleClose={() => setCurriculo(null)} />
      )}
    </S.Box>
  );
};
