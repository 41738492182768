import api from 'services/api';

export const GetConteudo = async id => {
  const res = await api.get(`/provider/training/contents/${id}`);
  return res.data;
};

export const SaveGeneralInfos = async data => {
  const res = await api.post('/provider/training/content', data);
  return res.data;
};

export const EditInfos = async (id, data) => {
  const res = await api.put(`/provider/training/content/${id}`, data);
  return res.data;
};

export const GetTags = async () => {
  const res = await api.get('/provider/training/tags');
  return res.data;
};

export const GetPublic = async () => {
  const res = await api.get('/provider/training/turmas');
  return res.data;
};

export const PublishContent = async id => {
  const res = await api.put(`/provider/training/publish-content/${id}`);
  return res.data;
};

export const DeleteContent = async id => {
  const res = await api.put(`/provider/training/delete-content/${id}`);
  return res.data;
};

export const getDesviosTiposPadrao = async () => {
  const res = await api.get('/desvios/configuracoes/padroes-ativos');
  return res.data;
};
