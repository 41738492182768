import api from 'services/api';

export const getEscalation = async query => {
  const { data } = await api.get('/escalation-list', {
    params: query,
  });
  return data?.success ? data.data : [];
};

export const deleteEscalation = async idEscalation => {
  const { data } = await api.delete(`/escalation-list/${idEscalation}`);
  return data;
};

export const saveEscalation = async payload => {
  const { data } = payload.id
    ? await api.put(`/escalation-list/${payload.id}`, payload)
    : await api.post(`/escalation-list/default`, payload);
  return data;
};

export const enableEscalation = async id => {
  const { data } = await api.put(`/escalation-list/${id}/enable`);
  return data;
};

export const disableEscalation = async id => {
  const { data } = await api.put(`/escalation-list/${id}/disable`);
  return data;
};
