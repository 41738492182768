/* eslint-disable import/order */

// Styles
import * as S from './styled.js';
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Loading from 'components/Loading';
import AddTurmas from './Modal/ListTurmas.js';
import TableLocal from 'components/TableLocal';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

// Utils
import * as services from '../services';
import { columnsTurmas } from './columns';
import banner_publico from 'images/empty/publico.svg';
import { setConteudo } from 'store/modules/provider/capacitacaoConteudo/actions.js';
import { Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ViewTurma from './Modal/UnicTurma.js';

export const Publico = ({
  trySave,
  openModalAddAluno,
  setOpenModalAddAluno,
  setChanged,
  setTrySave,
  setCurrentStep,
  isEdit,
  allTurmas,
  setAllTurmas,
  steps,
  isEmbarcadora,
}) => {
  // Redux
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, publico } = useSelector(state => state.conteudoProvider);

  const [selectedTurmas, setSelectedTurmas] = useState([]);
  const [addTurmas, setAddTurmas] = useState([]);
  const [currentTurmas, setCurrentTurmas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idTurma, setIdTurma] = useState(null);
  const [openModalTurma, setOpenModalTurma] = useState(false);

  const navigateTO = id => {
    navigate(`/capacitacao/viewTurmas/${id}`, { id });
  };

  const getAcron = str => {
    const matches = str.match(/\b(\w)/g);
    const acronym = matches.join('');
    return acronym.toUpperCase();
  };

  const formatTable = arr => {
    arr.forEach(i => {
      if (i.id === 'id') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.id}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      }

      if (i.id === 'nome') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <div className="container-img-name">
                <Avatar
                  alt={getAcron(row.original.nome)}
                  src={row.original.foto}
                  sx={{ marginRight: 1 }}
                />
                <S.Paragraph fontweight="bold" color="#494f65">
                  {row.original.nome}
                </S.Paragraph>
              </div>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'tipo') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver onClick={() => navigateTO(row.original.id)}>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.tipoUser}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'ativa') {
        i.Cell = function ({ row }) {
          let colorText = '';
          let colorBg = '';

          switch (row.original.ativa) {
            case true:
              colorText = colors.greenSucces;
              colorBg = colors.greenBackground;
              break;
            case false:
              colorText = colors.redDanger;
              colorBg = colors.redBackground;
              break;
          }

          return (
            <S.ColDriver>
              <S.Paragraph className="status" color={colorText} bg={colorBg}>
                {row.original.ativa ? 'Ativo' : 'Inativo'}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'n_alunos') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.n_alunos}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      }
    });
    return arr;
  };

  const multipleDelete = () => {
    setAddTurmas([...addTurmas.filter(i => !selectedTurmas.includes(i))]);
    setChanged(true);
  };

  useEffect(() => {
    if (publico?.length > 0) {
      setAddTurmas([...publico.map(i => i.id)]);
      setChanged(false);
    } else {
      setChanged(true);
    }
  }, [publico]);

  useEffect(() => {
    setCurrentTurmas(allTurmas.filter(i => addTurmas.includes(i.id)));
  }, [addTurmas]);

  useEffect(() => {
    if (trySave) {
      if (addTurmas.length === 0) {
        toast.error('Adicione pelo menos uma turma ao conteúdo');
        setTrySave(false);
      } else {
        sendRequest();
      }
    }
  }, [trySave]);

  const sendRequest = async () => {
    setLoading(true);
    const res = await services.EditInfos(id, {
      pagina: 'turma',
      turmas: currentTurmas.map(i => {
        return {
          ...i,
          id_turma: i.id,
        };
      }),
    });

    if (res.success) {
      setTrySave(false);
      setLoading(false);
      setChanged(false);
      dispatch(setConteudo(res.data.turmas, 'publico'));
      !isEdit && !isEmbarcadora && setCurrentStep(steps[4]);
      !isEdit &&
        isEmbarcadora &&
        navigate(`/capacitacao/editar/${id}?embarcadora=true`);
      toast.success('Informações salvas com sucesso!');
    } else {
      toast.error(res.message);
      setTrySave(false);
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && (
        <>
          <S.TableContainer>
            {addTurmas?.length !== 0 ? (
              <TableLocal
                columns={formatTable(columnsTurmas)}
                data={currentTurmas}
                sortBy={[]}
                permitIsSortedOccur
                tableType
                bulkActions={[
                  {
                    title: 'Excluir',
                    function: () => multipleDelete(),
                  },
                ]}
                actions={[
                  {
                    name: 'ver',
                    text: 'Ver turma',
                    action: id => {
                      setIdTurma(id);
                      setOpenModalTurma(true);
                    },
                  },
                  {
                    name: 'excluir',
                    action: id => {
                      setChanged(true);
                      setAddTurmas([...addTurmas.filter(i => +i !== +id)]);
                    },
                    text: 'Excluir',
                  },
                ]}
                setSelectedData={setSelectedTurmas}
                empty={{
                  image: '',
                  title: 'Seus turmas aparecerão aqui!',
                  subtitle: '',
                }}
                search={{
                  inputLabel: 'Buscar Turma',
                  keys: ['nome'],
                }}
              />
            ) : (
              <div className="banner">
                <img src={banner_publico} alt="banner publico" />
                <h3>Adicione seu público.</h3>
                <DefaultButton
                  children="Adicionar Turma"
                  icon={<SettingsOutlinedIcon />}
                  startIcon={<AddCircleOutlineIcon />}
                  style={{ marginRight: '10px' }}
                  onClick={() => setOpenModalAddAluno(true)}
                />
              </div>
            )}
          </S.TableContainer>
          {openModalAddAluno && (
            <AddTurmas
              openModal={openModalAddAluno}
              behaviorCloseModal={() => setOpenModalAddAluno(false)}
              data={allTurmas.filter(i => !addTurmas?.includes(i.id))}
              setSelectedTurmas={data => {
                setAddTurmas([...addTurmas, ...data]);
                setChanged(true);
              }}
              setAllTurmas={setAllTurmas}
            />
          )}

          {idTurma && (
            <ViewTurma
              openModal={openModalTurma}
              idturma={idTurma}
              setAllTurmas={setAllTurmas}
              behaviorCloseModal={() => {
                setOpenModalTurma(false);
                setIdTurma(null);
              }}
            />
          )}
        </>
      )}
      {loading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}
    </>
  );
};
