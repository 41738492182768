import { useTheme } from 'styled-components';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const columns = [
  {
    header: 'Nome',
    id: 'nome_motorista',
    sort: true,
    tooltip: true,
    type: 'string',
    picture: 'foto',
    width: 230,
    children: (value, item) => {
      const theme = useTheme();
      if (!item.posicao_anterior || item.posicao_anterior > item.posicao)
        return (
          <div style={{ display: 'flex' }}>
            <ArrowDropUpIcon
              htmlColor={theme.palette.semantics.feedback.success.natural}
            />
            <span>{item.nome_motorista}</span>
          </div>
        );
      if (item.posicao_anterior < item.posicao)
        return (
          <div style={{ display: 'flex' }}>
            <ArrowDropDownIcon
              htmlColor={theme.palette.semantics.feedback.attention.natural}
            />
            <span>{item.nome_motorista}</span>
          </div>
        );
      if (item.posicao_anterior == item.posicao)
        return (
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                width: '24px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircleIcon
                style={{ width: 10 }}
                htmlColor={theme.palette.semantics.feedback.information.natural}
              />
            </div>
            <span>{item.nome_motorista}</span>
          </div>
        );
    },
  },
  {
    header: 'Pontos',
    id: 'total_pontos_formatado',
    sort: false,
    type: 'string',
    align: 'center',
  },
  {
    header: 'Desvios Graves/Gravíssimos',
    id: 'total_desvios_graves',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'Fator de Risco',
    id: 'fator_risco',
    sort: true,
    type: 'string',
    align: 'center',
  },
];
