/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { Fragment, useEffect, useState } from 'react';

// Components
import { Select } from './Select';
import { Button } from './Button';

// MUI
import { Popover, SvgIcon } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

// Context
import { useTable } from '../../../context';
import { _setFilters } from '../../../context/actions';

// Assets
import { ReactComponent as filterIco } from '../../../assets/filter.svg';

// Actions
import { lineBadge } from './actions';

export const Filter = ({ filters = [] }) => {
  const [anchor, setAnchor] = useState(null);
  const handleClose = () => setAnchor(null);
  const handleOpen = e => setAnchor(e.currentTarget);

  const { dispatch, state } = useTable();

  const [values, setValues] = useState([]);
  const [collapsed, setCollapsed] = useState(
    Array(state?.filters?.length || filters?.length)?.fill(true),
  );

  useEffect(() => {
    const currentFilter = filters.map(filter => {
      const ref = state?.filters?.find(({ key }) => key === filter?.key);
      if (ref?.value && filter.data.some(data => data.value === ref.value)) {
        return {
          ...filter,
          value: ref?.value,
        };
      }
      return filter;
    });

    dispatch(_setFilters(currentFilter));
    setValues(
      currentFilter.map(filter => ({
        value: filter?.value
          ? filter?.value
          : filter?.mode === 'multiple'
          ? []
          : null,
      })),
    );
  }, [filters]);

  // ---------------------------------------HANLDERS---------------------------------------//
  const handleCollapse = index => {
    const newCollapsed = collapsed.map((item, i) => +i !== +index || item);
    newCollapsed[index] = !newCollapsed[index];
    setCollapsed(newCollapsed);
  };

  const handleValues = (index, value) => {
    const newValues = [...values];
    newValues[index].value = value;
    setValues(newValues);
  };

  const handleApply = async () => {
    const newFilters = state?.filters?.map((filter, index) => ({
      ...filter,
      value: values[index]?.value,
    }));

    await dispatch(_setFilters(newFilters));
    setAnchor(null);
    setCollapsed(Array(state?.filters?.length).fill(true));
    window.dispatchEvent(new Event('storage'));
  };

  const handleCancel = () => {
    const newValues = state?.filters?.map(filter => {
      return {
        value: filter?.value
          ? filter?.value
          : filter?.mode === 'multiple'
          ? []
          : null,
      };
    });

    setValues(newValues);
    setAnchor(null);
    setCollapsed(Array(state?.filters?.length).fill(true));
  };

  const handleClean = () => {
    setValues(
      state?.filters?.map(filter => ({
        value: filter?.mode === 'multiple' ? [] : null,
      })),
    );
  };

  const handleBadge = () => {
    const filterValues =
      state?.filters
        ?.map(filter =>
          filter.mode === 'multiple'
            ? filter?.value && filter.value.length
            : filter?.value,
        )
        .filter(i => !!i) || [];

    const badge = filterValues?.length;
    return <S.Badge visible={badge}>{badge}</S.Badge>;
  };

  // ---------------------------------------RENDER---------------------------------------//
  return (
    <>
      <S.Container
        id="filter-component"
        data-testid="filter"
        onClick={handleOpen}
        open={!!anchor}
      >
        <SvgIcon component={filterIco} className="icon--filter" />
        <p>Filtros</p>
        {handleBadge()}
      </S.Container>
      <Popover
        data-testid="filter-popover"
        id="filter-popover"
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <S.Filters>
          <S.Header>
            <S.Title>Filtros</S.Title>
            <S.Clean onClick={handleClean}>Limpar filtros</S.Clean>
          </S.Header>
          <S.Body>
            {state?.filters?.map((filter, index) => (
              <Fragment key={index}>
                <S.Line
                  data-testid="filter-line"
                  onClick={() => handleCollapse(index)}
                  open={!collapsed[index]}
                >
                  <KeyboardArrowDownRoundedIcon className="arrow" />
                  <S.Label>{filter?.label}</S.Label>
                  <S.LineBadge
                    hasItem={lineBadge(filter?.mode, values[index]?.value)}
                  >
                    {lineBadge(filter?.mode, values[index]?.value)}
                  </S.LineBadge>
                </S.Line>
                <S.Collapsed
                  in={!collapsed[index]}
                  data-testid="filter-collapsed"
                  aria-hidden={collapsed[index]}
                >
                  <Select
                    data={filter?.data}
                    mode={filter?.mode}
                    placeholder={filter?.placeholder}
                    value={values[index]?.value}
                    setValue={value => handleValues(index, value)}
                  />
                </S.Collapsed>
              </Fragment>
            ))}
          </S.Body>
          <S.Footer>
            <Button
              variant="ghost"
              children="Cancelar"
              onClick={handleCancel}
            />
            <Button children="Aplicar" onClick={handleApply} />
          </S.Footer>
        </S.Filters>
      </Popover>
    </>
  );
};
