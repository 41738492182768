export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: false,
  },
  {
    header: 'Nome',
    id: 'nome',
    type: 'String',
    sort: false,
  },
  {
    header: 'Motoristas',
    id: 'motorista',
    type: 'number',
    centered: true,
  },
  {
    header: 'Frota',
    id: 'frota',
    type: 'number',
    sort: false,
    centered: true,
  },
  {
    header: 'Status',
    id: 'status',
    type: 'String',
    sort: true,
    centered: true,
    conditional: [
      {
        condition: value => value === 'ATIVA',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'INATIVA',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];
