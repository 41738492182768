import styled, { css } from 'styled-components';

const DotModifier = {
  active: ({ theme }) => css`
    background-color: ${theme.palette.words.subtitle.natural};
    color: ${theme.palette.words.subtitle.contrast};
  `,
  inactive: ({ theme }) => css`
    background-color: ${theme.palette.system.transparent};
    color: ${theme.palette.words.subtitle.natural};
  `,
  hover: ({ theme }) => css`
    background-color: ${theme.palette.words.subtitle.light};
    color: ${theme.palette.words.subtitle.contrast};
  `,
};

const buttonModifier = {
  disabled: ({ theme }) => css`
    color: ${theme.palette.words.subtitle.light};
    opacity: 0.7;
    cursor: not-allowed;
  `,

  hover: ({ theme }) => css`
    color: ${theme.palette.words.subtitle.dark};
  `,
};

const ContainerModifier = {
  disabled: () => css`
    opacity: 0.7;
    pointer-events: none;
  `,
};

export const Container = styled.div`
  ${({ disabled }) => css`
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.25s ease-in-out;
    ${disabled && ContainerModifier.disabled}
  `}
`;

export const Slider = styled.div`
  ${({ visibleItems }) => css`
    width: ${visibleItems * 33}px;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  `}
`;

export const Visualizer = styled.div`
  ${({ count, position }) => css`
    width: ${count * 33}px;
    gap: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.25s ease-in-out;
    margin-left: ${position * -33}px;
  `}
`;

export const DotContainer = styled.button`
  ${({ active }) => css`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: ${({ children }) =>
      String(children?.props?.children).length > 5 ? 'x-small' : 'small'};
    transition: all 0.25s ease-in-out;
    border: none;
    cursor: pointer;
    ${active ? DotModifier.active : DotModifier.inactive}

    &:hover {
      ${!active && DotModifier.hover}
    }
  `}
`;

export const ArrowContainer = styled.button`
  ${({ theme, disabled }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    height: 32px;
    width: 75px;
    padding: 0px 8px;
    color: ${theme.palette.words.subtitle.natural};
    font-weight: 600;
    font-size: 16px;
    transition: all 0.25s ease-in-out;
    ${disabled && buttonModifier.disabled}

    &:hover {
      ${!disabled && buttonModifier.hover}
    }

    @media (max-width: 875px) {
      &.doble {
        display: none;
      }
    }
  `}
`;

export const ArrowText = styled.span``;
