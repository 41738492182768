import styled, { css } from 'styled-components';
import { Collapse } from '@mui/material';

export const Box = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;

    .title {
      font-size: 17px;
      font-weight: 600;
      color: ${theme.palette.words.subtitle.natural};
    }

    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    .unicSelect {
      margin-top: 30px;
      display: flex;
      width: 100%;
      gap: 10px;

      .little {
        flex: 1;
      }

      .bigger {
        flex: 6;
      }
    }

    .request {
      margin-top: 30px;

      .idxRequest {
        font-weight: 600;
        color: ${theme.palette.words.subtitle.natural};
        margin-right: 20px;
      }

      .boxRequest {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
      }

      .buttonBox {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .empty {
          font-weight: 500;
          color: ${theme.palette.words.text.light};
          font-size: 16px;
          opacity: 0.8;
        }
      }
    }
  `}
`;

export const BenefitsBox = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;

    .itemBox {
      height: 70px;
      border: 1px solid ${theme.palette.system.border};
    }
  `}
`;

export const CollapseMu = styled(Collapse)`
  ${({ theme }) => css`
    input {
      margin-left: 38px;
      border: none;
      border-bottom: 1px solid ${theme.palette.brand.primary.light};
    }
  `}
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
