import { useTheme } from 'styled-components';

import GhostButton from 'components/Buttons/Ghost';
import SelectInput from 'components/Inputs/Select';
import DefaultButton from 'components/Buttons/Default';
import { DatePicker } from './Date';

import { Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import * as S from './styled';

export const ModalExcel = ({
  open,
  onClose,
  select,
  defaultOption,
  exportablePeriod,
  setExportablePeriod,
  typeExport,
  exportPDF,
  exportExcel,
  loading,
}) => {
  const theme = useTheme();
  const confirmExport = () => {
    typeExport === 'EXCEL' ? exportExcel() : exportPDF();
  };

  return (
    <S.ModalMUI open={open} onClose={onClose}>
      <S.Content>
        <S.Header>
          <div className="left">
            <FileDownloadOutlinedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <span className="title">Selecione o período de exportação</span>
          </div>
          <div className="right">
            <CloseIcon
              htmlColor={theme.palette.brand.primary.light}
              onClick={onClose}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </S.Header>
        <Divider />
        <S.Body>
          <DatePicker
            initialize={exportablePeriod || defaultOption}
            setCurrentSelect={setExportablePeriod}
            select={select}
          />
        </S.Body>
        <S.Footer>
          <GhostButton children="Voltar" onClick={onClose} />
          <DefaultButton
            children="Exportar"
            style={{ marginLeft: '10px' }}
            onClick={confirmExport}
            loading={loading}
          />
        </S.Footer>
      </S.Content>
    </S.ModalMUI>
  );
};
