import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Componentes
import BarGraph from 'components/Graphs/BarGraph';
import LineGraph from 'components/Graphs/LineGraph';
import Select from 'components/Inputs/Select';

// Service
import { toast } from 'react-toastify/dist';
import { Grid } from '@mui/material';
import { requestMotoristas } from './services';
import * as S from './styled';
import { trackEvent } from 'utils/mixpanel';

const valuesSelect = [
  { value: 'agregados', name: 'AGREGADOS' },
  { value: 'filial', name: 'FILIAL' },
  { value: 'genero', name: 'GÊNERO' },
  { value: 'faixa', name: 'FAIXA ETÁRIA' },
  { value: 'cnh', name: 'EXPERIÊNCIA CNH' },
  { value: 'tempo', name: 'TEMPO DE EMPRESA' },
  { value: 'estado', name: 'ESTADO CIVIL' },
  { value: 'cliente', name: 'CLIENTE' },
  { value: 'status', name: 'ATIVADOS E DESATIVADOS' },
];

const GraficosMotoristas = () => {
  const filter = useSelector(state => {
    return state.filter;
  });

  const [indicadores, setIndicadores] = useState(null);

  const [historico, setHistorico] = useState(null);
  const [ptsHourAxis, setPtsHourAxis] = useState(['month']);

  const [graphSelect, setGraphSelect] = useState('agregados');

  const [graphLoading, setgraphLoading] = useState(true);

  const planos = useSelector(state => state.auth.user.user.planos);
  const hasCamera = !!planos.find(
    plano => plano.id_do_plano == 140 && plano.ativo,
  );

  const user = useSelector(state => state.auth?.user?.user);

  const fetchGraficos = async query => {
    setgraphLoading(true);
    const res = await requestMotoristas(query);
    if (res.data?.data) {
      setIndicadores(res.data.data.dataAbs);
      setHistorico(res.data.data.dataHist);
      setPtsHourAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setgraphLoading(false);
  };

  useEffect(() => {
    const query = { ...filter, tip_select: graphSelect };
    fetchGraficos(query);
  }, [filter, graphSelect]);

  const renderIndicadorGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <BarGraph
        data={indicadores}
        colors="default"
        title="Indicadores Absolutos"
        loading={graphLoading}
        tooltip
      />
      <div style={{ position: 'absolute', top: '15px', right: '25px' }}>
        <Select
          data={valuesSelect}
          value={graphSelect}
          handleChange={e => {
            trackEvent(user, `GRÁFICO LAYOUT DESCARGA: ${e.target.value}`);
            setGraphSelect(e.target.value);
          }}
        />
      </div>
    </div>
  );

  const renderHistoricoGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <LineGraph
        data={historico}
        colors="critical"
        title="Histórico Pontos/Hora"
        series={ptsHourAxis}
        legend={ptsHourAxis}
        loading={graphLoading}
        tooltip
      />
    </div>
  );

  const renderMain = () => (
    <>
      <S.Container>
        <S.Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <S.Title>Dashboard Motoristas</S.Title>
          </div>
        </S.Header>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              {renderIndicadorGraph()}
            </Grid>

            {hasCamera && (
              <Grid item xs={12} md={6} xl={6}>
                {renderHistoricoGraph()}
              </Grid>
            )}
          </Grid>
        </S.Main>
      </S.Container>
    </>
  );

  return renderMain();
};

export default GraficosMotoristas;
