/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConteudo } from 'store/modules/provider/capacitacaoConteudo/actions';

// Components
import Loading from 'components/Loading';
import Radio from '../../components/Radio';
import { Switch } from 'components/Inputs/Switch';
import TextInput from 'components/Inputs/TextField';

// Material
import { Tooltip } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

// Utils
import * as services from '../services';

export const Configuracoes = ({
  trySave,
  setTrySave,
  setChanged,
  setCurrentStep,
  isEdit,
  steps,
}) => {
  const dispatch = useDispatch();

  // Redux
  const { id, geral, avaliacao, configuracoes } = useSelector(
    state => state.conteudoProvider,
  );
  const is_online_test = avaliacao?.length > 0 && !avaliacao[0]?.is_quiz;

  // Obrigatorio
  const [obrigatorio, setObrigatorio] = useState(false);

  // Certificado
  const [collTime, setCollTime] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const [prazo_certificado, setPrazoCertificado] = useState('');

  // Coeficiente Minimo
  const [collPerc, setCollPerc] = useState(false);
  const [coeficiente, setCoeficiente] = useState(false);
  const [perc_coeficiente, setPercCoeficiente] = useState('');

  // Tentativas
  const [collTry, setCollTry] = useState(false);
  const [tentativas, setTentativas] = useState(false);
  const [num_tentativas, setNumTentativas] = useState('');

  // Comentarios
  const [comentarios, setComentarios] = useState(false);

  // Mostrar ao Publico
  const [showToPublic, setShowToPublic] = useState(false);

  // Error
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkDaysMonitoring = (func, value) => {
    if ((Number(value) && Number(value) < 1000) || !value) {
      func(value);
      setChanged(true);
    }
  };

  const checkPercentMonitoring = (func, value) => {
    if ((Number(value) && Number(value) < 101) || !value) {
      func(value);
      setChanged(true);
    }
  };

  const checkTryMonitoring = (func, value) => {
    if ((Number(value) && Number(value) < 11) || !value) {
      func(value);
      setChanged(true);
    }
  };

  // ------------------------------------INITIALIZE DATA-----------------------------------------//
  useEffect(() => {
    if (configuracoes) {
      setObrigatorio(!!configuracoes?.obrigatorio);
      setCertificate(!!configuracoes?.gera_certificado);
      setPrazoCertificado(configuracoes?.vencimento_certificado);
      setCoeficiente(!!configuracoes.coeficiente_minimo);
      setPercCoeficiente(configuracoes.coeficiente_minimo);
      setTentativas(!!configuracoes.tentativas);
      setNumTentativas(configuracoes.tentativas);
      setComentarios(!!configuracoes.comentarios_habilitados);
      setShowToPublic(!!configuracoes.motrar_ao_publico);
    }
  }, [configuracoes]);

  // --------------------------------------SAVE CONFIGS-------------------------------------------//
  useEffect(() => {
    if (trySave) {
      setCollTime(false);
      setCollPerc(false);
      setCollTry(false);
      setError(false);

      if (is_online_test && coeficiente && !perc_coeficiente) {
        toast.error('Informe o percentual mínimo para aprovação');
        setCollPerc(true);
        setTrySave(false);
        setError('per');
        return;
      }

      if (is_online_test && tentativas && !num_tentativas) {
        toast.error('Informe o número de tentativas');
        setCollTry(true);
        setTrySave(false);
        setError('try');
        return;
      }

      const data = {
        pagina: 'configuracao',
        comentarios_habilitados: comentarios,
        coeficiente_minimo:
          (is_online_test && coeficiente ? perc_coeficiente : 0) || 0,
        tentativas: is_online_test && tentativas ? num_tentativas || 0 : 0,
        obrigatorio: obrigatorio ? 1 : 0,
        gera_certificado: certificate ? 1 : 0,
        vencimento_certificado: certificate ? prazo_certificado || 0 : 0,
        mostrar_ao_publico: showToPublic ? 1 : 0,
      };

      setTrySave(false);
      sendRequest(data);
    }
  }, [trySave]);

  const sendRequest = async data => {
    setLoading(true);
    const res = await services.EditInfos(id, data);

    if (res.success) {
      setTrySave(false);
      setLoading(false);
      setChanged(false);
      !isEdit && setCurrentStep(steps[5]);
      dispatch(setConteudo(res.data, 'configuracoes'));
      toast.success('Informações salvas com sucesso!');
    } else {
      toast.error(res.message);
      setTrySave(false);
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && (
        <S.Box>
          <S.ContentLimit>
            {/* OBRIGATORIO */}
            <S.BoxConfigure>
              <div className="textArea">Conteúdo Obrigatório</div>
              <Switch
                check={obrigatorio}
                setCheckbox={value => {
                  setObrigatorio(value);
                  setChanged(true);
                }}
              />
            </S.BoxConfigure>

            {/* CERTIFICADO */}
            <S.BoxCollapse hasTest>
              <div className="clickColl">
                <div
                  className="leftSide"
                  onClick={() => setCollTime(!collTime)}
                >
                  <div className="textArea">Gerar Certificado</div>
                  <ArrowForwardIosOutlinedIcon
                    fontSize="18px"
                    htmlColor={colors.greyTitle}
                    style={{
                      transform: collTime ? 'rotate(90deg)' : 'rotate(0deg)',
                      transition: 'all ease .5s',
                      marginLeft: '10px',
                      marginBottom: '-5px',
                      cursor: 'pointer',
                    }}
                  />
                </div>
                <div className="rigthSide">
                  {certificate && (
                    <div className="textArea number">
                      {prazo_certificado
                        ? `${prazo_certificado} dia(s) de validade`
                        : 'Vitalício'}
                    </div>
                  )}
                  <Switch
                    check={certificate}
                    setCheckbox={() => {
                      setCollTime(!certificate);
                      setCertificate(!certificate);
                      setChanged(true);
                    }}
                  />
                </div>
              </div>
              <S.CollapseMu in={collTime} unmountOnExit>
                <div className="boxTime">
                  <TextInput
                    label="Validade em dias"
                    style={{ width: '70px' }}
                    value={prazo_certificado}
                    onChange={e => {
                      checkDaysMonitoring(setPrazoCertificado, e.target.value);
                      setChanged(true);
                    }}
                  />
                  <span className="current" style={{ marginLeft: '-30px' }}>
                    dia(s)
                  </span>

                  <div className="sideBox">
                    <Radio
                      value={!prazo_certificado}
                      label="Vitalício"
                      onChange={
                        !prazo_certificado
                          ? null
                          : () => {
                              setChanged(true);
                              setPrazoCertificado('');
                            }
                      }
                    />
                  </div>
                </div>
              </S.CollapseMu>
            </S.BoxCollapse>

            {/* COEFICIENTE */}
            <S.BoxCollapse hasTest={is_online_test}>
              <div className="clickColl">
                <div
                  className="leftSide"
                  onClick={
                    is_online_test
                      ? () => setCollPerc(!collPerc)
                      : () =>
                          toast.warning(
                            'Insira uma avaliação de "Teste Online" para habilitar essa opção',
                          )
                  }
                >
                  <div className="textArea">Habilitar Coeficiente Mínimo</div>
                  <ArrowForwardIosOutlinedIcon
                    fontSize="18px"
                    htmlColor={colors.greyTitle}
                    style={{
                      transform: collPerc ? 'rotate(90deg)' : 'rotate(0deg)',
                      transition: 'all ease .5s',
                      marginLeft: '10px',
                      marginBottom: '-5px',
                      cursor: 'pointer',
                    }}
                  />
                  <Tooltip
                    className="tooltip"
                    title="Porcentagem mínima de acertos para que o usuário seja aprovado. Em caso de geração do certicado, so será efetuado se o usuário atender esse critério."
                    style={{ marginLeft: '10px' }}
                  >
                    <HelpOutlineOutlinedIcon htmlColor={colors.grey} />
                  </Tooltip>
                </div>
                <div className="rigthSide">
                  {!!perc_coeficiente && coeficiente && is_online_test && (
                    <div className="textArea number">
                      {perc_coeficiente}% de acerto
                    </div>
                  )}
                  <Switch
                    check={coeficiente && is_online_test}
                    setCheckbox={
                      is_online_test
                        ? () => {
                            if (!tentativas) {
                              setCollPerc(!coeficiente);
                              setCoeficiente(!coeficiente);
                              setChanged(true);
                            } else {
                              toast.warning(
                                'Não é possivel desabilitar essa opção com "Máximo de tentativas" habilitado!',
                              );
                            }
                          }
                        : () =>
                            toast.warning(
                              'Insira uma avaliação de "Teste Online" para habilitar essa opção',
                            )
                    }
                  />
                </div>
              </div>
              <S.CollapseMu in={collPerc} unmountOnExit>
                <div className="boxTime">
                  <TextInput
                    label="Minimo de acertos"
                    style={{ width: '70px' }}
                    error={error === 'per' && !perc_coeficiente && coeficiente}
                    value={perc_coeficiente}
                    onChange={e => {
                      checkPercentMonitoring(
                        setPercCoeficiente,
                        e.target.value,
                      );
                      setChanged(true);
                    }}
                  />
                  <span className="current" style={{ marginLeft: '-30px' }}>
                    %
                  </span>
                </div>
              </S.CollapseMu>
            </S.BoxCollapse>

            {/* TENTATIVAS */}
            <S.BoxCollapse hasTest={is_online_test}>
              <div className="clickColl">
                <div
                  className="leftSide"
                  onClick={
                    is_online_test
                      ? () => setCollTry(!collTry)
                      : () =>
                          toast.warning(
                            'Insira uma avaliação de "Teste Online" para habilitar essa opção',
                          )
                  }
                >
                  <div className="textArea">Habilitar Máximo de Tentativas</div>

                  <ArrowForwardIosOutlinedIcon
                    fontSize="18px"
                    htmlColor={colors.greyTitle}
                    style={{
                      transform: collTry ? 'rotate(90deg)' : 'rotate(0deg)',
                      transition: 'all ease .5s',
                      marginLeft: '10px',
                      marginBottom: '-5px',
                      cursor: 'pointer',
                    }}
                  />

                  <Tooltip
                    className="tooltip"
                    title="Caso esse item estiver desativado, o usuário podera refazer o contéudo sem limitação de tentativas."
                    style={{ marginLeft: '10px' }}
                  >
                    <HelpOutlineOutlinedIcon htmlColor={colors.grey} />
                  </Tooltip>
                </div>
                <div className="rigthSide">
                  <div className="textArea number">
                    {is_online_test && num_tentativas && tentativas
                      ? `${num_tentativas} tentativa(s)`
                      : !tentativas
                      ? 'ilimitado'
                      : null}
                  </div>

                  <Switch
                    check={is_online_test && tentativas}
                    setCheckbox={
                      is_online_test
                        ? () => {
                            setCollTry(!tentativas);
                            if (!tentativas && !coeficiente) {
                              setCoeficiente(true);
                              setCollPerc(true);
                              setChanged(true);
                            }
                            setTentativas(!tentativas);
                          }
                        : () =>
                            toast.warning(
                              'Insira uma avaliação de "Teste Online" para habilitar essa opção',
                            )
                    }
                  />
                </div>
              </div>
              <S.CollapseMu in={collTry} unmountOnExit>
                <div className="boxTime">
                  <TextInput
                    label="Insira um valor entre 1 e 10"
                    style={{ width: '70px' }}
                    value={num_tentativas}
                    error={error === 'try' && !num_tentativas && tentativas}
                    onChange={e => {
                      checkTryMonitoring(setNumTentativas, e.target.value);
                      setChanged(true);
                    }}
                  />
                  <span className="current" style={{ marginLeft: '-85px' }}>
                    tentativas
                  </span>
                </div>
              </S.CollapseMu>
            </S.BoxCollapse>

            {/* COMENTÁRIOS */}
            <S.BoxConfigure>
              <div className="textArea">Habilitar Curtidas e Comentários</div>
              <Switch
                check={comentarios}
                setCheckbox={value => {
                  setComentarios(value);
                  setChanged(true);
                }}
              />
            </S.BoxConfigure>

            {/* TEMPLATE */}
            {geral.id_empresa === 163 && (
              <S.BoxConfigure>
                <div className="textArea">
                  <span>Mostrar ao Público</span>
                </div>

                <Switch
                  check={showToPublic}
                  setCheckbox={value => {
                    setShowToPublic(value);
                    setChanged(true);
                  }}
                />
              </S.BoxConfigure>
            )}
          </S.ContentLimit>
        </S.Box>
      )}
      {loading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}
    </>
  );
};
