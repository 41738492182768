// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// Components
import Loading from 'components/Loading';
import Select from 'components/Inputs/Select';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';

// Components MUI
import { Checkbox, Divider } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Utils
import NumberFormat from 'react-number-format';
import { steps } from '../constants';
import * as services from '../services';
import { tipos_categoria, tipos_cnh } from './constants';

// Lib
import * as S from './styled';

export const Requisitos = ({
  beneficios,
  setBeneficios,
  cargas,
  segmentos,
  trySave,
  setTrySave,
  setChanged,
  vagaId,
  setCurrentStep,
  data,
  setData,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const changingMonitor = (key, value) => {
    const newData = { ...data };
    newData[key] = value;
    setData(newData);
    setChanged(true);
  };

  // ---------------------REQUESTS CONTROLL---------------------------------//
  const handleResquestText = (idx, value) => {
    setChanged(true);
    const newArr = data.requisitos;
    newArr.map((item, key) => {
      if (key === idx) {
        item.text = value;
      }
    });

    setData({
      ...data,
      requisitos: newArr,
    });
  };

  const addNewRequest = () => {
    setData({
      ...data,
      requisitos: [...data.requisitos, { text: '' }],
    });
  };

  const deleteRequest = idx => {
    setChanged(true);
    const newArr = [];
    data.requisitos.map((item, key) => {
      if (key !== idx) {
        newArr.push(item);
      }
    });

    setData({
      ...data,
      requisitos: newArr,
    });
  };

  // ----------------------------------COLLAPSE CONTROLLER--------------------------------//

  const [collapse, setCollapse] = useState([]);

  useEffect(() => {
    const opned = [];
    for (const i in beneficios) {
      if (beneficios[i].checked) {
        opned.push(true);
      } else {
        opned.push(false);
      }
    }
    setCollapse(opned);
  }, []);

  // ------------------------BENEFITS CONTROLL----------------------------//

  const handleBenefits = (benefit, idx) => {
    setChanged(true);
    const copyCollapse = [...collapse];

    const copyBenefits = beneficios;

    for (const i in copyBenefits) {
      if (copyBenefits[i].id === benefit.id) {
        if (copyBenefits[i]?.checked) {
          copyBenefits[i].checked = false;
          copyCollapse[idx] = false;
        } else {
          copyBenefits[i].checked = true;
          copyCollapse[idx] = true;
        }
      }
    }

    setBeneficios([...copyBenefits]);

    setCollapse(copyCollapse);
  };

  const handleValue = (benefit, value) => {
    setChanged(true);
    const copyBenefits = beneficios;
    benefit.value = value;

    for (const i in copyBenefits) {
      if (copyBenefits[i].id === benefit.id) {
        if (copyBenefits[i]?.checked) {
          benefit.value = value;
        }
      }
    }

    setBeneficios([...copyBenefits]);
  };

  // -------------------HANDLE SAVE-------------------------------------//
  useEffect(() => {
    if (trySave) {
      const dataSend = {
        secao: 'Requisitos',
        carga: data.id_carga,
        segmento: data.id_segmento,
        carteira: data.tipo_de_carteira,
        categoria: data.categoria,
        requisitos: data.requisitos,
        beneficios: beneficios.filter(item => item.checked),
      };

      if (!data.id_carga) {
        toast.error('Selecione uma carga');
        setTrySave(false);
        return;
      }

      if (!data.id_segmento) {
        toast.error('Selecione um segmento');
        setTrySave(false);
        return;
      }

      if (!data.tipo_de_carteira) {
        toast.error('Selecione uma carteira');
        setTrySave(false);
        return;
      }

      if (!data.categoria) {
        toast.error('Selecione uma categoria');
        setTrySave(false);
        return;
      }

      saveInfos(dataSend);
    }
  }, [trySave]);

  const saveInfos = async dataSend => {
    setLoading(true);

    const res = await services.updateVacancy(dataSend, vagaId);

    if (res.success) {
      toast.success(res.message);
      setTrySave(false);
      setChanged(false);
      setCurrentStep(steps[2]);
    } else {
      setTrySave(false);
      toast.error(res.message);
    }

    setLoading(false);
  };

  return (
    <>
      {!loading && (
        <S.Box>
          <div className="container">
            <Select
              label="Tipos de carga"
              data={cargas || []}
              value={data.id_carga || ''}
              handleChange={value =>
                changingMonitor('id_carga', value.target.value)
              }
              required
            />
            <Select
              label="Segmento"
              data={segmentos || []}
              value={data.id_segmento || ''}
              handleChange={value =>
                changingMonitor('id_segmento', value.target.value)
              }
              required
            />
          </div>
          <div className="unicSelect">
            <span className="little">
              <Select
                label="Carteira"
                data={tipos_cnh.map(item => ({ name: item, value: item }))}
                value={data.tipo_de_carteira || ''}
                handleChange={value =>
                  changingMonitor('tipo_de_carteira', value.target.value)
                }
                required
              />
            </span>

            <span className="bigger">
              <Select
                label="Categoria"
                data={tipos_categoria.map(item => ({
                  name: item,
                  value: item,
                }))}
                value={data.categoria || ''}
                handleChange={value =>
                  changingMonitor('categoria', value.target.value)
                }
                required
              />
            </span>
          </div>

          <Divider style={{ margin: '20px 0px 20px 0px' }} />

          <p className="title">Requisitos</p>
          <div className="request">
            {data?.requisitos &&
              data.requisitos.map((item, key) => (
                <div className="boxRequest" key={key}>
                  <span className="idxRequest">{key + 1}</span>
                  <TextInput
                    noAsterisk
                    value={item?.text}
                    onChange={e => handleResquestText(key, e.target.value)}
                    style={{
                      width: '90%',
                      maxWidth: '100%',
                      minWidth: '400px',
                    }}
                  />
                  <DeleteOutlineOutlinedIcon
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    htmlColor={
                      theme.palette.semantics.feedback.attention.natural
                    }
                    onClick={() => deleteRequest(key)}
                  />
                </div>
              ))}
            <span className="buttonBox">
              <span
                className="empty"
                style={{
                  opacity: !data.requisitos?.length ? '1' : '0',
                  pointerEvents: !data.requisitos?.length ? 'all' : 'none',
                }}
              >
                Adicione requisitos para sua vaga
              </span>
              <GhostButton
                icon={<AddCircleOutlineOutlinedIcon />}
                children="Adicionar Requisito"
                onClick={addNewRequest}
                style={{ alignSelf: 'end' }}
              />
            </span>
          </div>

          <Divider style={{ margin: '20px 0px 20px 0px' }} />

          <p className="title">Benefícios</p>
          <S.BenefitsBox>
            {beneficios?.map((benefit, key) => {
              return (
                <div className="itemBox" key={key}>
                  <label style={{ cursor: 'pointer' }}>
                    <Checkbox
                      checked={!!benefit.checked}
                      onChange={() => handleBenefits(benefit, key)}
                    />
                    <span>{benefit.item}</span>
                  </label>

                  <S.CollapseMu in={collapse[key]} unmountOnExit>
                    <NumberFormat
                      value={benefit?.value}
                      prefix="R$"
                      decimalSeparator=","
                      allowedDecimalSeparators={[',']}
                      decimalScale={2}
                      displayType="input"
                      type="text"
                      thousandSeparator="."
                      allowNegative={false}
                      onValueChange={e =>
                        handleValue(benefit, e.value.replace('.', ','))
                      }
                      placeholder="R$ Valor do benefício"
                      maxLength={13}
                    />
                  </S.CollapseMu>
                </div>
              );
            })}
          </S.BenefitsBox>
        </S.Box>
      )}
      {loading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}
    </>
  );
};
