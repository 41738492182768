import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ptBR } from 'date-fns/locale';
import { useTheme } from 'styled-components';
import * as S from './styled';

const DataHour = ({
  value = null,
  onChange,
  label,
  required,
  disabled,
  maxDate,
  minDate,
  error,
  defaultMonth,
  refs, // Para usar useRef => Ex criar de avaliação p/ alterar com do input
}) => {
  const theme = useTheme();
  const handleChange = newValue => {
    if (newValue && !isNaN(new Date(newValue))) onChange(newValue);
  };

  return (
    <S.InputWrapper
      ref={refs}
      onClick={e => {
        e.currentTarget.classList.remove('error');
      }}
    >
      <p
        style={{
          color: disabled
            ? theme.palette.words.disabled.dark
            : theme.palette.words.disabled.natural,
          font: 'normal medium 14px/20px Texta',
        }}
      >
        {label}
        {required ? (
          <span style={{ fontSize: '20px', fontWeight: 500, color: 'red' }}>
            *
          </span>
        ) : (
          ''
        )}
      </p>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={ptBR}
        adapterLocale={ptBR}
      >
        <DateTimePicker
          value={value}
          onChange={handleChange}
          maxDate={maxDate ? new Date(maxDate) : new Date()}
          minDate={minDate ? new Date(minDate) : null}
          defaultCalendarMonth={defaultMonth}
          renderInput={params => (
            <S.StyledTextField {...params} error={error} disabled={disabled} />
          )}
          InputProps={{
            style: { border: 'none' },
          }}
          disabled={disabled}
        />
      </LocalizationProvider>
    </S.InputWrapper>
  );
};

export default DataHour;
