import React from 'react';
import Grid from '@mui/material/Grid';
import * as S from './styled';

const Imagens = ({ data }) => {
  return (
    <S.ImagensWrapper>
      <S.ImagensHeader>Imagens Adicionais</S.ImagensHeader>
      <div>
        <Grid className="page-break-inside" container spacing={3}>
          {data.map((img, idx) => {
            return (
              <Grid item xs={6} key={idx}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ maxWidth: '100%', marginTop: '20px' }}
                    src={img.imagem_url}
                  />
                  <p style={{ fontSize: '15px', marginTop: '20px' }}>
                    {img.legenda}
                  </p>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </S.ImagensWrapper>
  );
};

export default Imagens;
