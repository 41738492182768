import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  overflow-x: hidden;
  width: 100%;
  min-height: calc(100vh - 140px);
  position: relative;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    .box {
      display: flex;

      .item {
        background-color: ${theme.palette.system.overlay};
        border: 1px solid ${theme.palette.system.border};
        border-left: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 35px;
        transition: all ease 0.3s;
        cursor: pointer;
      }

      .item:last-child {
        border-radius: 0px 5px 5px 0px;
      }

      .item:first-child {
        border-left: 1px solid ${theme.palette.system.border};
        border-radius: 5px 0px 0px 5px;
        border-right: 1px solid ${theme.palette.brand.secondary.natural};
      }

      .item.active {
        background-color: ${theme.palette.brand.secondary.background};
        border: 1px solid ${theme.palette.brand.secondary.natural};
      }

      .item.active:last-child {
        border-left: none;
      }

      .item:hover {
        background-color: ${theme.palette.brand.secondary.background};
      }
    }
  `}
`;

export const Line = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    .topBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: -15px;

      .month {
        font-weight: 800;
        color: ${theme.palette.words.title.natural};
        text-transform: uppercase;
        font-size: 16px;
      }

      .backWeek {
        font-weight: 500;
        color: ${theme.palette.words.title.natural};
        cursor: pointer;
        transition: all ease 0.5s;
        opacity: ${props => (props.restorable ? 1 : 0)};
        visibility: ${props => (props.restorable ? 'visible' : 'hidden')};
        z-index: 2;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .dateBox {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .date {
        background-color: ${theme.palette.system.overlay};
        padding: 5px 15px;
        border: 3px solid ${theme.palette.system.border};
        border-radius: 5px;
        color: ${theme.palette.words.subtitle.natural};
        width: 120px;
        text-align: center;
        margin: 0px 10px 0px 10px;
        font-weight: 600;
        color: ${theme.palette.words.subtitle.natural};
      }

      .outedBar {
        width: 100%;
        height: 2px;
        background-color: ${theme.palette.system.border};
        position: absolute;
        z-index: -1;
      }

      .arrow {
        cursor: pointer;
        font-size: 32px;
      }

      .arrow.null {
        cursor: not-allowed;
      }
    }
  `}
`;

export const ViewBox = styled.div`
  /* visibility: ${props => (props.visible ? 'visible' : 'hidden')}; */
  /* visibility: visible; */
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: all ease 0.5s;
`;

export const BoxLoading = styled.div`
  width: 100%;
  min-height: 400px;
  /* background-color: ${({ theme }) => theme.palette.system.overlay}; */
  margin-top: 10px;
  position: relative;
  border-radius: 5px;
`;
