import api from 'services/api';

export const getDriversList = async query => {
  let res = null;
  if (query.currentTab === 0) {
    res = await api.get(`/driver-schedule-direction`, { params: query });
  } else if (query.currentTab === 1) {
    res = await api.get(`/driver-schedule-unloading`, { params: query });
  }
  return res.data;
};
