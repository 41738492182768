export const _SET_SEARCH = '@table/SET_SEARCH';
export const _SET_PAGE = '@table/SET_PAGE';
export const _SET_PAGE_SIZE = '@table/SET_PAGE_SIZE';
export const _SET_SORT = '@table/SET_SORT';
export const _SET_SELECTED = '@table/SET_SELECTED';
export const _SET_COUNT = '@table/SET_COUNT';
export const _SET_DEFAULT = '@table/SET_DEFAULT';
export const _SET_CACHE_PAGE = '@table/SET_CACHE_PAGE';
export const _SET_FILTERS = '@table/SET_FILTERS';
export const _SET_TABLE = '@table/SET_TABLE';
export const _SET_STARTED = '@table/SET_STARTED';
