import styled, { css } from 'styled-components';

const Paper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 540px;
    background-color: ${theme.palette.system.overlay};
    border: 0px 5px 20px ${theme.palette.system.border};
    border-radius: 8px;
    padding: 25px 25px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 15px;
`;

const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    h1 {
      color: ${theme.palette.words.title.natural};
      font: normal 900 20px Texta;
      margin-left: 10px;
    }
  `}
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
`;

export { Paper, Header, Title, Footer };
