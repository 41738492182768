import { Modal } from '@mui/material';
import styled from 'styled-components';
import colors from 'styles/colors';

export const ModalMUI = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const Container = styled.div`
  width: 510px;
  height: 530px;
  background-color: ${colors.white};
  border-radius: 5px;
  padding: 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      width: 40px;
      margin-right: 10px;
    }
  }

  .right {
  }
`;

export const Body = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .description {
    font-size: 16px;
    color: ${colors.greySubtitle};
    font-weight: 500;
    margin-bottom: 20px;
  }
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;
