import { defaultStaticRanges } from 'components/Header/DateRangePicker/defined';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import * as types from './types';

const today = endOfDay(new Date());
const last30 = startOfDay(addDays(new Date(), -30));

const INITIAL_STATE = {
  empresas: '',
  filial: '',
  filial_veiculo: '',
  motoristas: '',
  operation: '',
  initialDate: last30,
  finalDate: today,
  dateLabel: 'Últimos 30 dias',
};

export default function filterProvider(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_FILTER_REQUEST_PROVIDER: {
      return { ...state, ...action.payload };
    }
    case types.SET_INITIAL_STATE_DATE_PROVIDER: {
      // Verifica se há um preset de data selecionado de defaultStaticRanges (dateLabel)
      // Se sim, atualiza as datas
      if (state.dateLabel) {
        const findedRange = defaultStaticRanges.find(
          item => item.label === state.dateLabel,
        );
        if (findedRange) {
          const range = findedRange.range();
          return {
            ...state,
            initialDate: range.startDate,
            finalDate: range.endDate,
          };
        }
      }
      // Quando não há um preset, não faz nada
      else return state;
    }
    default:
      return state;
  }
}
