// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Componentes
import ModalAdd from './ModalAdd';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import CardIndicadores from 'components/Cards/Indicador';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';
import { DoubleInputTable } from 'components/_Table/templates/doubleInput';

// Componentes MUI
import Grid from '@mui/material/Grid';
import { AddCircleOutline } from '@mui/icons-material';

// Utils
import { trackEvent } from 'utils/mixpanel';
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';

// Services
import * as request from './services';

// Constants
import { columns } from './columns';
import { fields, resetFields } from './constants';

const Rotograma = () => {
  // Theme
  const theme = useTheme();

  // Navigate
  const navigate = useNavigate();

  // Refs
  const initialize = useRef(true);

  // Location
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('criar')) {
      setEditModal(true);
      window.history.replaceState({}, {}, '/rotograma');
    }
  }, [location]);

  // Redux
  const filter = useSelector(state => state.filter);
  const { user } = useSelector(state => state.auth.user);
  const filterRotograma = useSelector(state => state.filterRotograma);

  // General States
  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [selectedCard, setSelectedCards] = useState(null);
  const [loadingSelection, setLoadingSelection] = useState([]);
  const [selectedEvaluations, setSelectedEvaluations] = useState(null);

  const [route, setRoute] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [dataEdit, setDataEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // --------------------- REQUESTS -------------------//

  useEffect(() => {
    if (initialize.current) {
      initialize.current = false;
    }
    setResetTable(true);
  }, [filter, filterRotograma, selectedCard]);

  const {
    refetch: fetchData,
    isLoading: loadingIndex,
    isFetching,
    data: resData,
  } = useQuery(
    ['rotograma-index', query],
    () => query && request.getRotogramas(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => resetTable && setResetTable(false),
    },
  );

  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    ['rotograma-cards', { ...filter, ...filterRotograma }],
    () => request.getCards({ ...filter, ...filterRotograma }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // --------------------------------- CARDS ---------------------------------------//

  const handleClickCard = type => {
    if (type === selectedCard) setSelectedCards(null);
    else setSelectedCards(type);
  };

  const renderCards = () => {
    const cards = resCards?.data || [{}, {}];
    return (
      <Grid item>
        <Grid container spacing={2}>
          {cards.map((item, idx) => {
            let md = 4;
            if (12 / cards.length < 4) md = 12 / cards.length;
            return (
              <Grid item key={idx} md={md} xs={12} sm={6}>
                <CardIndicadores
                  value={item.ids?.length}
                  icon="Grupo12741.svg"
                  text={item.text}
                  border={`1px solid ${theme.palette.brand.primary.light}4D`}
                  handleClick={
                    item.type !== 'VIDEOS_PROCESSADOS'
                      ? () => handleClickCard(item.type)
                      : null
                  }
                  selected={selectedCard === item.type}
                  loading={loadingCards}
                  disabled={isFetching}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  // -------------------------- BULK MENU & ACTION COLUMN---------------------------//

  const handleDelete = async id => {
    const body = {
      event: 'deleteRoute',
      data: { ids: [route] },
      id,
    };

    setLoadingSelection([id]);
    const res = await request.deleteRotograma(body);
    setLoadingSelection([]);

    if (res.data.success) {
      fetchData();
      fetchCards();
      toast.success(res.data.message);
      setDeleteModal(false);
    } else {
      toast.error(res.data.message);
    }
    trackEvent(user, `rotograma deletar`);
  };

  const downloadRoute = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];

    if (ids.length > 1) trackEvent(user, `rotograma bulk_baixar`);
    else trackEvent(user, `rotograma baixar`);

    for (const i in ids) {
      const id = ids[i];
      const data = resData?.data || [];
      for (const i in data) {
        if (data[i].arquivo && data[i].id === Number(id)) {
          const req = new XMLHttpRequest();
          req.open('GET', data[i].arquivo, true);
          req.responseType = 'blob';

          let typeArchive = null;
          let mimiType = null;

          if (data[i].arquivo.includes('.pdf')) {
            typeArchive = 'application/pdf';
            mimiType = '.pdf';
          } else if (data[i].arquivo.includes('.docx')) {
            typeArchive = 'application/docx';
            mimiType = '.docx';
          } else if (data[i].arquivo.includes('.doc')) {
            typeArchive = 'application/doc';
            mimiType = '.doc';
          } else if (data[i].arquivo.includes('.png')) {
            typeArchive = 'image/png';
            mimiType = '.png';
          } else if (data[i].arquivo.includes('.jpeg')) {
            typeArchive = 'image/jpeg';
            mimiType = '.jpeg';
          } else if (data[i].arquivo.includes('.jpg')) {
            typeArchive = 'image/jpg';
            mimiType = '.jpg';
          } else if (data[i].arquivo.includes('.ppt')) {
            typeArchive = 'application/ppt';
            mimiType = '.ppt';
          }

          req.onload = async function () {
            const blob = new Blob([req.response], { type: typeArchive });
            const url = window.URL || window.webkitURL;
            const link = url.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('download', `${data[i].origem}${mimiType}`);
            a.setAttribute('href', link);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          };

          req.send();
        }
      }
    }
  };

  const actions = [
    {
      title: 'Baixar',
      function: id => downloadRoute(id),
    },
    {
      title: 'Editar',
      function: id => {
        trackEvent(user, `rotograma botao_editar`);
        setRoute(id);
        setEditModal(true);
      },
      visible: item => item?.id_da_empresa === user.filial.id_da_empresa,
    },
    {
      title: 'Excluir',
      function: id => {
        setRoute(id);
        setDeleteModal(true);
      },
      visible: item => item?.id_da_empresa === user.filial.id_da_empresa,
    },
    {
      title: 'Histórico',
      function: id => navigate(`/rotograma/historico/${id}`),
    },
  ];

  const bulk = [
    {
      title: 'Baixar',
      function: () => downloadRoute(),
    },
  ];

  // -------------------------- EXCEL ------------------------------------------//

  const [excelFields, setExcelFields] = useState(fields);

  const handleRequestExcel = async () => {
    trackEvent(user, `rotograma exportar`);
    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = {
      ...query,
      excelFields: newFields,
      ids: selectedEvaluations,
    };

    const res = await request.requestExcel(newQuery);

    if (!res.data.success) {
      toast.error(res.data.message);
      return;
    }

    if (res.data && res.data.data.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `rotograma_${formatedDate}`,
      });
    setExcelFields(resetFields(fields));
  };

  // ------------------------------------MODAL ADD & EDIT-----------------------------------//
  const renderModal = () => {
    return (
      editModal && (
        <ModalAdd
          open={editModal}
          handleClose={() => {
            setEditModal(false);
            setDataEdit(null);
            setRoute(null);
          }}
          dataEdit={dataEdit}
          setUpdated={() => {
            fetchData();
            fetchCards();
          }}
        />
      )
    );
  };

  // -------------------------------------FORMAT DATA FOR EDIT-------------------------------//
  useEffect(() => {
    if (route) {
      const data = resData?.data || [];
      for (const i in data) {
        if (data[i].id === Number(route)) {
          setDataEdit(data[i]);
        }
      }
    }
  }, [route]);

  // -----------------------------------------RENDER-----------------------------------------//
  return (
    <Grid container>
      <Grid container>
        <Grid
          marginBottom={3}
          item
          md={12}
          display="flex"
          justifyContent="space-between"
        >
          <h1>Rotograma</h1>
          <span>
            <GhostButton
              children="Exportar"
              onClick={() => handleRequestExcel()}
            />
            <DefaultButtonPopover
              startIcon={<AddCircleOutline />}
              size="medium"
              sx={{ marginLeft: '10px', width: '250px' }}
              onClick={() => {
                setEditModal(true);
                trackEvent(user, `rotograma botao_adicionar`);
              }}
            >
              ADICIONAR ROTOGRAMA
            </DefaultButtonPopover>
          </span>
        </Grid>
      </Grid>
      <Grid marginBottom={3} item md={12}>
        {renderCards()}
      </Grid>
      <Grid item md={12}>
        <DoubleInputTable
          // persist="rotograma"
          data={resData?.data || []}
          columns={columns}
          setSelectedRows={setSelectedEvaluations}
          loading={loadingIndex}
          pageCount={resData?.count || 0}
          local={false}
          bulk={bulk}
          actions={actions}
          // keys={['origem', 'destino']}
          reset={resetTable}
          searchEvent={search =>
            trackEvent(user, 'Busca Rotograma', null, search)
          }
          loadingSelection={loadingSelection}
          setQuery={q =>
            setQuery({
              ...q,
              ...filter,
              ...filterRotograma,
              card: selectedCard,
            })
          }
          sortBy={{ id: 'updatedAt', order: 'DESC' }}
          empty={{
            title: 'Seus rotogramas aparecerão aqui!.',
            description:
              'Clique no botão acima e digitalize o seu primeiro Rotograma!',
          }}
        />
      </Grid>

      <ConfirmModal
        handleClose={() => setDeleteModal(false)}
        open={deleteModal}
        title="Tem certeza que deseja excluir?"
        subtitle="Rotogramas excluídos não podem mais ser recuperados"
        buttonText="Confirmar"
        onClick={() => handleDelete()}
      />

      {renderModal()}
    </Grid>
  );
};

export default Rotograma;
