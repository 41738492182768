import styled, { css } from 'styled-components';

export const Container = styled.div`
  .map {
    border-radius: 4px;
    height: 512px;
  }
`;

export const MapInfoWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 1.5rem;
    bottom: 1.5rem;
    right: 1rem;
    width: 13rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
    border-radius: 4px;
    background: ${theme.palette.system.overlay};
    display: flex;
    align-items: start;
    z-index: 999;
  `}
`;

export const MapInfoContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: left;
    font-size: 18px;
    font-weight: medium;
    color: ${theme.palette.words.subtitle.natural};

    .title {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      margin-right: 0.5rem;
      font-size: 16px;
      font-weight: 900;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 600;
    }
  `}
`;

export const ContainerList = styled.div`
  height: 100%;
  padding-right: 0.5rem;
  overflow-y: scroll;
`;
