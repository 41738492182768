import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.system.overlay};
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 70px;
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    .divider {
      width: 100%;
      height: 2px;
      background-color: ${theme.palette.brand.primary.light}4d;
      border-radius: 20%;
    }
  `}
`;

export const containerCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 132px;
    background: ${theme.palette.system.overlay};
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    .container-card {
      display: flex;
      width: 33%;
      height: 3px;
      justify-content: center;
      align-items: center;
    }
    .container-card-divider {
      height: 132px;
      height: 45.43px;
      border: 1px solid ${theme.palette.brand.primary.light}4d;
    }
    .icon-container {
      padding: 8px 8px 8px 8px;
      color: ${theme.palette.words.text.light};
      background: ${theme.palette.semantics.feedback.unknown.light};
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
    h1 {
      font-size: 20px;
      line-height: 1;
    }
    h3 {
      color: ${theme.palette.words.text.light};
      line-height: 1;
      font-size: 14px;
    }
  `}
`;
