import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const ContainerStatus = styled.div`
  span {
    font-size: 12px;
    color: ${colors.greyTitle};
  }
`;
export const InfoLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const BoxInfos = styled.div`
  background-color: #fff;
  border: 2px solid ${colors.greyBorder};
  border-radius: 4px;
  padding: 30px;
  height: 145px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bar {
    height: 80px;
    width: 1px;
    background-color: ${colors.greyTiny};
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  .text {
    font-weight: 500;
    font-size: 18px;
    color: ${colors.greyTitle};
  }

  .value {
    font-weight: 900;
    font-size: 16px;
    margin-top: 5px;
  }
`;

export const CircleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  .textFields {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      font-weight: 500;
      font-size: 18px;
      color: ${colors.greyTitle};
    }

    .value {
      font-weight: 900;
      font-size: 16px;
      margin-top: 5px;
    }
  }
`;

export const TableArea = styled.div`
  width: 100%;
  position: relative;
  position: relative;
  transition: all ease 0.2s;
`;

export const BoxLoading = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Paragraph = styled.p`
  font-weight: bold;
  color: ${colors.blueTitle};
  font-size: 14px;
  text-align: ${props => (props.center ? 'center' : 'start')};
  min-width: 100px;
  &.normal {
    font-size: 16px;
  }

  &.name {
    text-transform: capitalize;
  }

  &.small {
    font-weight: 500;
    font-size: 12px;
  }

  &.green {
    color: ${colors.greenSucces};
    background-color: ${colors.greenBackground};
    border-radius: 20px;
    text-align: center;
  }

  &.orange {
    color: ${colors.orangeAlert};
    background-color: ${colors.orangeBackground};
    border-radius: 20px;
    text-align: center;
  }

  &.red {
    color: ${colors.redDanger};
    background-color: ${colors.redBackground};
    border-radius: 20px;
    text-align: center;
  }
`;

export const ColDriver = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.blueTitle};
  }

  &.flex {
    flex-direction: row;
    align-items: center;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PTable = styled.p`
  color: #4b5166;
  font-size: '16px';
  font-weight: 600;
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;

  button {
    width: 100%;
    border: none;
    background-color: #fff;
    border-radius: none;
    text-align: start;
    padding: 8px 10px;
    font-weight: 600;
    color: ${colors.greyTitle};
    font-size: 15px;
    transition: all ease 0.2s;

    span {
      font-size: 10px;
      font-weight: 600;
      margin-left: 5px;
    }

    &:hover {
      background-color: ${colors.greyBorder};
    }
  }
`;

export const Photo = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
  padding: 5px;
  border: 1px solid ${colors.greenMain};
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    object-fit: cover;
  }

  div {
    font-weight: 900;
    font-size: 16px;
    color: ${colors.greyTitle};
  }
`;

export const ListUsers = styled.div`
  .title {
    font-weight: bold;
    color: ${colors.greyTitle};
  }

  ul {
    margin-top: 3px;
    margin-left: 17px;
  }

  ul li {
    list-style: disc;
  }
`;
