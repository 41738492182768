import api from 'services/api';

export const getAcoesDisciplinares = async () => {
  const { data } = await api.get('/telematics_acoes_disciplinares');
  return data.data;
};

export const getModulosCapacitacao = async () => {
  const { data } = await api.get('/capacitacao/conteudos');
  return data.data;
};

const fakeacoes = [
  {
    id: 1,
    ativo: false,
    titulo: 'N1',
    cor: '',
    limiar: 20,
    valor_maximo: 40,
    is_sugestao: true,
  },
  {
    id: 2,
    ativo: false,
    titulo: 'N2',
    cor: '',
    limiar: 41,
    valor_maximo: 60,
    is_sugestao: true,
  },
  {
    id: 3,
    ativo: false,
    titulo: 'N3',
    cor: '#',
    limiar: 61,
    valor_maximo: 80,
    is_sugestao: true,
  },
  {
    id: 4,
    ativo: false,
    titulo: 'N4',
    cor: '',
    limiar: 81,
    valor_maximo: 100,
    is_sugestao: true,
  },
];

export const getSugestoesAcoes = async () => {
  const { data } = await api.get('/telematics_acoes/sugestoes');
  return data.data;
};

export const getAcoes = async () => {
  const { data } = await api.get('/telematics_acoes');
  return data.data;
};

export const registerAcao = async data => {
  const res = data.id
    ? await api.put(`/telematics_acoes/${data.id}`, data)
    : await api.post('/telematics_acoes', data);

  return res.data;
};

export const activateAcao = async id => {
  const res = await api.put(`/telematics_acoes/${id}/activate`);
  return res.data;
};

export const desactivateAcao = async id => {
  const res = await api.put(`/telematics_acoes/${id}/desactivate`);
  return res.data;
};

export const requestExcel = async (query, isProvider) => {
  const res = await api.get(
    `/excel${isProvider ? '-provider' : ''}/telematics_actions`,
    { params: query },
  );
  return res;
};

export const createAcaoDisciplinar = async nome => {
  const res = await api.post(`/telematics_acoes_disciplinares`, { nome });
  return res.data;
};

export const desactivateAcaoDisciplinar = async id => {
  const res = await api.put(
    `/telematics_acoes_disciplinares/${id}/desactivate`,
  );
  return { ...res.data, status: res.status };
};

export const requestHistory = async id => {
  const res = await api.get(`/telematics_acoes/${id}/logs`);
  return res;
};
