import React from 'react';
import { Modal, IconButton } from '@mui/material';
import * as S from './styled';

const ImageModal = ({ handleClose, open, image }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <S.Paper>
        <S.ImageContainer>
          <img src={image} alt="imagem_modal" onClick={handleClose} />
        </S.ImageContainer>
      </S.Paper>
    </Modal>
  );
};

export default ImageModal;
