import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { PlayCircle, Close, OpenInFull } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import ImageModal from 'components/ImageModal';
import { firestorageVideos } from 'utils/firebase';
import * as S from './styled';

const ImageViolacao = ({ infraction, onlyImage }) => {
  const theme = useTheme();
  const [videoUrl, setVideoUrl] = useState('');
  const [videoActive, setVideoActive] = useState(false);
  const [expandedImage, setExpandedImage] = useState(false);

  useEffect(() => {
    // Monta o link do vídeo no momento do desvio
    // Se o período não for encontrado, o vídeo não será exibido!
    const getVideoData = () => {
      const caminho = infraction.caminho_cloud || '';
      if (caminho) {
        firestorageVideos
          .ref(caminho)
          .getDownloadURL()
          .then(fireBaseUrl => {
            if (fireBaseUrl) {
              setVideoUrl(fireBaseUrl);
            }
          })
          .catch(err => console.log(err));
      }
    };
    getVideoData();
  }, [infraction]);

  return (
    <>
      {(!videoActive || onlyImage) && (
        <S.ImageContainer>
          <img src={infraction.img_ocorrencia} alt="imagem_desvio" />

          {videoUrl && (
            <div className="playVideo" onClick={() => setVideoActive(true)}>
              <PlayCircle
                sx={{ color: theme.palette.brand.primary.light, fontSize: 50 }}
              />
            </div>
          )}
          {infraction.img_ocorrencia && (
            <div className="expandImage" onClick={() => setExpandedImage(true)}>
              <OpenInFull
                sx={{
                  color: theme.palette.brand.secondary.natural,
                  fontSize: 40,
                }}
              />
            </div>
          )}
        </S.ImageContainer>
      )}
      {videoActive && videoUrl && !onlyImage && (
        <S.VideoContainer>
          <ReactPlayer
            url={videoUrl}
            controls
            playing
            width="100%"
            height="100%"
          />
          <span onClick={() => setVideoActive(false)}>
            <Close
              sx={{
                color: theme.palette.brand.secondary.natural,
                fontSize: 40,
              }}
            />
          </span>
        </S.VideoContainer>
      )}

      <ImageModal
        open={expandedImage}
        handleClose={() => setExpandedImage(false)}
        image={infraction.img_ocorrencia}
      />
    </>
  );
};

export default ImageViolacao;
