import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
} from 'recharts';
import {
  Container,
  Header,
  Legenda,
  ContainerGraph,
  TooltipContainer,
} from './styled';
import TabsSlider from 'components/TabsSlider';
import GraphSkeleton from './GraphSkeleton';
import EmptyState from './EmptyState';

const CustomTick = ({ x, y, payload, width }) => {
  const maxCharsPerLine = 15; // Máximo de caracteres por linha.
  const lineHeight = 20; // Altura de cada linha de texto.
  const spacingFromTop = 20; // Espaço inicial do topo para a primeira linha.

  const splitText = text => {
    let lines = [];
    let startIndex = 0;

    while (startIndex < text.length) {
      let endIndex = Math.min(text.length, startIndex + maxCharsPerLine);
      // Verifica se o próximo caractere após o endIndex é um espaço, indicando o fim de uma palavra
      // Se não for espaço (ou seja, está no meio de uma palavra), ajusta o endIndex para o último espaço encontrado
      if (endIndex < text.length && text[endIndex] !== ' ') {
        let lastSpaceIndex = text.lastIndexOf(' ', endIndex);
        if (lastSpaceIndex > startIndex) {
          endIndex = lastSpaceIndex;
        }
      }

      let line = text.substring(startIndex, endIndex);
      lines.push(line);

      // Se for necessário adicionar '...' para indicar continuação
      if (lines.length === 2 && endIndex < text.length) {
        lines[1] = `${line.trim().slice(0, -3)}...`;
        break;
      }

      startIndex = endIndex + (text[endIndex] === ' ' ? 1 : 0); // Pula o espaço se o corte terminou nele
    }

    return lines;
  };

  const lines = splitText(payload.value);

  return (
    <g transform={`translate(${x},${y})`}>
      {lines.map((line, index) => (
        <text
          x={0}
          y={spacingFromTop + index * lineHeight} // Ajusta o espaçamento vertical para cada linha.
          textAnchor="middle"
          fill="#666"
          key={`tick-${index}`}
        >
          {line}
        </text>
      ))}
    </g>
  );
};

const CustomTooltip = ({ active, payload, currentSelection }) => {
  if (active && payload && payload.length) {
    const { payload: currentPayload } = payload[0];
    return (
      <TooltipContainer>
        <p className="title">{`${currentPayload.x}`}</p>
        <p>{`Pts/${currentSelection === 'ptsH' ? 'Hr' : 'Km'} Atual: ${
          currentPayload.ptsHr_atual || 0
        }`}</p>
        <p>{`Pts/${currentSelection === 'ptsH' ? 'Hr' : 'Km'} Anterior: ${
          currentPayload.ptsHr_anterior || 0
        }`}</p>
      </TooltipContainer>
    );
  }

  return null;
};

export const GraphRDesvio = ({
  data = [],
  metaValue = 0,
  tabsItems = [],
  handleTabClick,
  currentSelection,
  isLoading,
  print = false,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getBarGap = () => {
    return 2;
  };

  const getBarCategoryGap = () => {
    // Aumentando o espaço entre os conjuntos de barras
    return 30; // Separção para telas pequenas
  };

  return (
    <Container print={print}>
      <Header>
        <Legenda>
          <div className="title-radar">Radar de desvios</div>
        </Legenda>
        <div>
          <TabsSlider
            items={tabsItems}
            onChange={(_, value) => handleTabClick(value)}
            value={currentSelection}
          />
        </div>
      </Header>
      {!isLoading && (!data || data.length === 0) ? (
        <EmptyState
          print={print}
          title="Nenhum dado retornado"
          subtitle="Ainda não há dados suficientes para esta busca. Favor verificar seus filtros."
        />
      ) : (
        <ContainerGraph>
          {isLoading ? (
            <GraphSkeleton />
          ) : (
            <BarChart
              width={data.length <= 6 ? 900 : data.length * 150}
              height={print ? 200 : 300}
              data={data}
              barGap={getBarGap()}
              barCategoryGap={getBarCategoryGap()}
              margin={{
                top: 5,
                right: 30,
                left: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="x"
                height={50}
                width={200}
                tick={<CustomTick />}
                interval={data.length > 20 ? 'preserveStartEnd' : 0}
              />
              <YAxis />
              <Tooltip
                content={<CustomTooltip currentSelection={currentSelection} />}
              />
              <Bar
                dataKey={
                  currentSelection === 'ptsH'
                    ? 'ptsHr_anterior'
                    : 'ptsKm_anterior'
                }
                fill="#95AAC9"
                radius={4}
              />
              <Bar
                dataKey={
                  currentSelection === 'ptsH' ? 'ptsHr_atual' : 'ptsKm_atual'
                }
                fill="#4B5166"
                radius={4}
                name="Atual"
              />
              <ReferenceLine
                y={metaValue}
                label={{
                  value: `Meta ${metaValue}`,
                  position: 'insideBottomRight',
                  fill: 'red',
                  style: {
                    fontSize: 12,
                    fontWeight: 'bold',
                  },
                }}
                stroke="red"
                strokeDasharray="3 3"
              />
            </BarChart>
          )}
        </ContainerGraph>
      )}
      <Legenda>
        <span className="circle" />
        <span className="text">Período anterior</span>
        <span className="circle2" color="#4B5166" />
        <span className="text">Período atual</span>
      </Legenda>
    </Container>
  );
};
