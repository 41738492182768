import styled, { css } from 'styled-components';

export const QuestionBox = styled.div`
  ${({ theme, hasError }) => css`
    padding: 20px;
    background-color: ${hasError
      ? theme.palette.semantics.feedback.attention.light
      : theme.palette.system.highlight};
  `}
`;

export const ResponseArea = styled.div`
  ${({ theme, ...props }) => css`
    padding: 10px 5px;
    border-radius: 3px;
    display: flex;
    width: 100%;
    background-color: ${props.hasError
      ? theme.palette.semantics.feedback.attention.light
      : theme.palette.system.transparent};

    .iconsBox {
      margin-left: 50px;
      opacity: ${props.isChecked ? 1 : 0};
      transition: all ease-in 0.3s;
      pointer-events: ${props.isChecked ? 'all' : 'none'};
      display: ${props.preview ? 'none' : 'block'};
    }
  `}
`;
