/* eslint-disable import/order */

// Styles
import * as S from '../styled';
import colors from 'styles/colors';
import { useTheme } from 'styled-components';

// React
import React, { useState } from 'react';

// Components
import TableLocal from 'components/TableLocal';
import Button from 'components/Buttons/Default';
import ButtonGhost from 'components/Buttons/Ghost';

// Components MUI
import { Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';

// Utils
import { columnsList } from '../columns';

const ListarTurmas = ({
  behaviorCloseModal,
  openModal,
  data,
  setSelectedTurmas,
}) => {
  const theme = useTheme();
  const [selectedRow, setSelectedRow] = useState([]);

  const getAcron = str => {
    const matches = str.match(/\b(\w)/g);
    const acronym = matches.join('');
    return acronym.toUpperCase();
  };

  const formatTable = arr => {
    arr.forEach(i => {
      if (i.id === 'id') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                fontweight="bold"
                color={theme.palette.brand.primary.natural}
              >
                {row.original.id}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'nome') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <div className="container-img-name">
                <Avatar
                  alt={getAcron(row.original.nome)}
                  src={row.original.foto}
                  sx={{ marginRight: 1 }}
                />
                <S.Paragraph fontweight="bold" color="#494f65">
                  {row.original.nome}
                </S.Paragraph>
              </div>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'tipo') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver onClick={() => navigateTO(row.original.id)}>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.tipoUser ? row.original.tipoUser : '-'}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'ativa') {
        i.Cell = function ({ row }) {
          let colorText = '';
          let colorBg = '';

          switch (row.original.ativa) {
            case true:
              colorText = colors.greenSucces;
              colorBg = colors.greenBackground;
              break;
            case false:
              colorText = colors.redDanger;
              colorBg = colors.redBackground;
              break;
          }

          return (
            <S.ColDriver>
              <S.Paragraph className="status" color={colorText} bg={colorBg}>
                {row.original.ativa ? 'Ativo' : 'Inativo'}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'n_transportadoras') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.n_transportadoras}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'atividades') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <Tooltip
                title={
                  row.original.atividades
                    ? `${row.original.atividades} atividade(s) relacionada(s)`
                    : 'Nenhuma atividade relacionada'
                }
                onClick={
                  row.original.atividades
                    ? () => {
                        openModalActivities(row.original.id);
                      }
                    : undefined
                }
              >
                <S.Paragraph
                  className="activity"
                  hasActivity={!!row.original.atividades}
                >
                  <span>{row.original.atividades}</span>
                  <ErrorOutlineOutlinedIcon
                    htmlColor={
                      row.original.atividades
                        ? colors.blueInfo
                        : colors.greyTiny
                    }
                  />
                </S.Paragraph>
              </Tooltip>
            </S.ColDriver>
          );
        };
      }
    });
    return arr;
  };

  const handleAddTurma = () => {
    setSelectedTurmas(selectedRow);
    behaviorCloseModal();
  };

  return (
    <S.ModalTurmas
      open={openModal}
      onClose={() => {
        behaviorCloseModal();
        window.onfocus = () => null;
      }}
      disableEscapeKeyDown={false}
    >
      <S.ContainerModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 10,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PersonAddAltOutlinedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <h1 style={{ marginLeft: '10px' }}>Adicionar Turmas</h1>
          </div>
          <IconButton
            onClick={() => {
              behaviorCloseModal();
              window.onfocus = () => null;
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <Divider />
        <S.TableContainer>
          <TableLocal
            pageSizes={4}
            heightEmpty="285px"
            columns={formatTable(columnsList)}
            data={data}
            sortBy={[]}
            permitIsSortedOccur
            tableType
            setSelectedData={setSelectedRow}
            bulkActions={[]}
            empty={{
              image: '',
              title: 'Turmas não encontradas.',
              subtitle: '',
            }}
            search={{
              inputLabel: 'Buscar Turma',
              keys: ['id', 'nome'],
            }}
          />
        </S.TableContainer>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', padding: 15 }}
        >
          <ButtonGhost
            onClick={() => {
              behaviorCloseModal();
              window.onfocus = () => null;
            }}
          >
            Cancelar
          </ButtonGhost>
          <div style={{ marginLeft: 5 }} />
          <Button onClick={handleAddTurma}>Adicionar</Button>
        </div>
      </S.ContainerModal>
    </S.ModalTurmas>
  );
};

export default ListarTurmas;
