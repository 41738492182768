import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { Checkbox, FormControlLabel } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import * as S from './styled';

export const SelectCheck = ({
  control,
  rules,
  name = '',
  title = '',
  disabled,
  options = [],
  setValue,
  error = '',
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const handleSelectAll = () => {
    if (setValue instanceof Function)
      setValue(name, [...options.map(op => op.value)]);
  };

  return (
    <S.Container>
      <S.Title>
        {title}
        {!!error && (
          <div>
            <span style={{ color: 'red' }}>{error}</span>
          </div>
        )}
      </S.Title>

      <S.SearchBox>
        <SearchOutlinedIcon
          className="searchIcon"
          htmlColor={theme.palette.semantics.feedback.unknown.natural}
        />
        <S.SearchInput
          type="text"
          placeholder="Buscar..."
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </S.SearchBox>
      <S.StyledButton type="button" onClick={handleSelectAll}>
        Selecionar todos
      </S.StyledButton>
      <S.ListContainer>
        <Controller
          name={name}
          rules={rules}
          control={control}
          defaultValue={[]} // Valor padrão é um array vazio
          render={({ field }) => (
            <ul>
              {options
                .filter(
                  op =>
                    !search ||
                    op.label?.toLowerCase().includes(search.toLowerCase()),
                )
                .map(option => (
                  <S.ListItem key={option.value}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disabled || option.disabled}
                          checked={field.value.includes(option.value)}
                          onChange={e => {
                            const newOptions = [...field.value];
                            if (e.target.checked) {
                              newOptions.push(option.value);
                            } else {
                              const index = newOptions.indexOf(option.value);
                              if (index > -1) {
                                newOptions.splice(index, 1);
                              }
                            }
                            field.onChange(newOptions);
                          }}
                        />
                      }
                      label={option.label}
                    />
                  </S.ListItem>
                ))}
            </ul>
          )}
        />
      </S.ListContainer>
    </S.Container>
  );
};
