import styled from 'styled-components';
import colors from 'styles/colors';

export const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  margin-bottom: 5px;
  padding: 10px 35px;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    input {
      display: none;
    }

    .imgArea {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid ${colors.greenMain};
      padding: 3px;
      display: flex;
      align-items: center;
      justify-content: center;

      .borderImage {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 100px;
          object-fit: cover;
        }
      }

      .empty {
        background-color: ${colors.greyBorder};
        width: 100%;
        height: 100%;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        color: ${colors.greyTitle};
      }
    }

    .textArea {
      display: flex;
      align-items: center;
      color: ${colors.greySubtitle};
      margin-left: 10px;

      span {
        margin-left: 5px;
      }
    }
  }
`;

export const Section = styled.h3`
  font: normal normal 900 13px/50px Texta;
  justify-content: center;
  color: ${colors.greySubtitle};
`;
