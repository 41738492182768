/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useLayoutEffect, useState } from 'react';

// Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

let timer;

export const Tooltip = ({
  text,
  right = '0',
  top = '0',
  bottom = '0',
  iconOut = <InfoOutlinedIcon className="svg" />,
  iconIn = <WarningAmberRoundedIcon className="svg" />,
  disabled,
  placement = 'left',
}) => {
  const [visible, setVisible] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const enter = () => {
    clearTimeout(timer);
    setVisible(true);
    timer = setTimeout(() => {
      setOpacity(1);
    }, 50);
  };

  const out = () => {
    clearTimeout(timer);
    setOpacity(0);
    timer = setTimeout(() => {
      setVisible(false);
    }, 50);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        opacity: disabled ? 0 : 1,
        pointerEvents: disabled ? 'none' : 'all',
      }}
    >
      <S.Button
        onMouseEnter={enter}
        onMouseLeave={out}
        style={{ cursor: 'pointer' }}
        data-testid="tooltip"
        id="button-anchor"
      >
        {iconOut}
        <S.Container
          className="tooltip--reserved--class"
          visible={visible}
          opacity={opacity}
          left={
            placement === 'left'
              ? (iconIn ? -95 : -70) - text?.length * 6
              : (iconIn ? 95 : 70) + text?.length * 6
          }
          right={right}
          bottom={bottom}
          top={top}
          pos={placement}
        >
          <div className="box--controller">
            {iconIn}
            <p>{text}</p>
          </div>
        </S.Container>
      </S.Button>
    </div>
  );
};
