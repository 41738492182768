import styled, { css } from 'styled-components';

const ContainerModifier = {
  hover: ({ theme }) => css`
    background-color: ${theme.palette.system.disabled};
  `,
  loading: () => css`
    pointer-events: none;
    opacity: 0.7;
  `,
  disabled: ({ theme, isLoading }) => css`
    pointer-events: none;
    ${!isLoading && `background-color: ${theme.palette.system.disabled}`};
  `,

  selection: ({ theme }) => css`
    border: 1px solid ${theme.palette.system.border};
    border-bottom: none;
    border-top: none;
    padding: 0px;
  `,
};

export const Container = styled.tr`
  ${({
    theme,
    align,
    clickable,
    hover,
    isLoading,
    selection,
    disabled,
    compactRow = false,
  }) => css`
    height: ${compactRow ? '45px' : '60px'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${align};
    position: relative;
    padding: 0px 15px;
    transition: all 0.25s ease;
    cursor: ${clickable ? 'pointer' : 'default'};
    ${hover && ContainerModifier.hover({ theme })}
    ${isLoading && ContainerModifier.loading()}
    ${disabled && ContainerModifier.disabled({ theme, isLoading })}
    ${selection && ContainerModifier.selection({ theme })}
  `}
`;

export const Wrapper = styled.td`
  ${({ align }) => css`
    display: flex;
    align-items: center;
    justify-content: ${align};
    width: 100%;
  `}
`;

export const Infos = styled.span`
  ${({ picture }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: ${picture ? 'calc(100% - 55px)' : '100%'};
    gap: 3px;
  `}
`;

export const TD = styled.span`
  ${({ theme, align, isLoading, selection }) => css`
    text-align: ${align};
    color: ${isLoading ? 'transparent' : theme.palette.words.title.natural};
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    font-size: 16px;
    width: ${selection ? '100%' : 'calc(100% - 5px)'};
    opacity: ${isLoading && selection ? 0.3 : 1};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    ${selection &&
    css`
      display: flex;
      align-items: center;
      justify-content: ${align};
    `}
  `}
`;

export const SubRow = styled.span`
  ${({ theme, align }) => css`
    text-align: ${align};
    color: ${theme.palette.words.subtitle.natural};
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    font-size: 13px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `}
`;
