import styled, { css } from 'styled-components';

export const ModalMUI = styled.div`
  position: relative;
  display: none;
  overflow: hidden;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    padding: 10px 10px;
    overflow-y: scroll;
    overflow-x: hidden;

    .box {
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    .lineGraph {
      position: relative;
      background-color: red;
      overflow: hidden;
    }
  `}
`;

export const Infos = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    .formIdent {
      font-weight: bold;
      color: ${theme.palette.words.title.natural};
      display: flex;
      flex-direction: column;
      font-size: 8px;

      .titleForm {
        margin-top: 1rem;
        font-size: 16px;
      }
    }
    img {
      width: 150px;
    }
  `}
`;

export const InputToPDF = styled.div`
  ${({ theme }) => css`
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 18px;
    border-radius: 2px;
    font-size: 10px;
    color: ${theme.palette.words.title.natural};
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    padding-left: 4px;
    overflow: hidden;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.words.text.light};
    border-left: 1px solid ${theme.palette.words.text.light};
    border-right: 1px solid ${theme.palette.words.text.light};
    border-radius: 1px;
    padding: 5px;

    .lineApprover {
      width: 100%;
      margin-top: 2rem;
      border-bottom: 1px solid
        ${theme.palette.semantics.feedback.unknown.natural};
    }
    .containerSignatures {
      //margin-top: 15px;
      display: flex;
      flex-direction: column;

      .signaturesUser {
        font-size: 10px;
        color: ${theme.palette.words.title.natural};
      }
    }
    .containerSignaturesTipe {
      font-size: 8px;
      color: ${theme.palette.words.subtitle.natural};
    }
    .topInfos {
      display: flex;
      gap: 1rem;

      .titleForm {
        font-weight: 800;
        font-size: 25px;
        color: ${theme.palette.words.title.natural};
      }

      .normalText {
        font-weight: 600;
        font-size: 8px;
        color: #9a9ea8;
        margin-top: 2px;
      }

      .normalTexttwo {
        font-weight: 600;
        font-size: 8px;
      }
    }

    .bottomInfos {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      .card {
        flex: 1;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        .coverArea {
          // flex: 2;
          width: 100%;
          height: 200px;
          border-radius: 5px;

          .background {
            background-image: url(${props => props.bg});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100px;
            height: 80px;
            border-radius: 5px;
          }
        }

        .textsArea {
          //flex: 4;
          margin-left: 20px;

          .titleForm {
            font-weight: 800;
            font-size: 25px;
            color: ${theme.palette.words.title.natural};
          }

          .normalText {
            font-weight: 600;
            font-size: 10px;
            color: ${theme.palette.words.subtitle.natural};
          }

          .normalTexttwo {
            font-weight: 600;
            font-size: 8px;
          }

          .descriptionForm {
          }
        }
      }

      .bottomInfos {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        .card {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          .logoCompany {
            // margin-right: 30px;

            img {
              max-height: 80px;
            }
          }

          .cardInfos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title {
              font-size: 16px;
              font-weight: 500;
              color: ${theme.palette.words.subtitle.natural};
              text-align: center;
            }

            .numbers {
              font-weight: 600;
              font-size: 18px;
              color: ${theme.palette.words.title.natural};
            }
          }
        }

        .bar {
          height: 100%;
          min-height: 100px;
          width: 1px;
          background-color: ${theme.palette.semantics.feedback.unknown.natural};
        }
      }
    }
  `}
`;

export const CardHeader = styled.div`
  ${({ theme }) => css`
    background: #373f54;
    margin-top: 10px;
    border-radius: 5px 5px 0px 0px;

    h1 {
      color: ${theme.palette.words.title.contrast};
      font-size: 10px;
      font-weight: 900;
      text-align: center;
    }
  `}
`;

export const TableArea = styled.div`
  ${({ theme }) => css`
    position: relative;

    .coverInput {
      width: 100%;
      height: 100px;
      background-color: ${theme.palette.system.overlay};
      position: absolute;
      top: 0;
      z-index: 3;
    }

    .coverInputBottom {
      width: 100%;
      height: 100px;
      background-color: ${theme.palette.system.overlay};
      position: absolute;
      bottom: 0;
      z-index: 3;
    }
  `}
`;
