import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { startOfMonth, subHours, subMonths } from 'date-fns';

import { PeriodPicker } from 'pages/Estatisticas/Components/PeriodPicker';
import { LineGraph } from './LineGraph';
import { BarGraph } from './BarGraph';
import * as S from './styled';
import { getHistorico, getJustificativas } from '../../services';

export const Historico = ({ id }) => {
  const [dates, setDates] = useState({
    finalDate: new Date(),
    initialDate: startOfMonth(subMonths(new Date(), 11)),
    userId: id,
  });

  const { isLoading: isLoadingHistorico, data: historico = [] } = useQuery(
    ['equipe-historico', id, dates],
    () => getHistorico(id, dates),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { isLoading: isLoadingJustificativas, data: justificativas = [] } =
    useQuery(
      ['equipe-justificativas', id, dates],
      () => getJustificativas(id, dates),
      {
        refetchOnWindowFocus: false,
      },
    );

  return (
    <S.Card>
      <S.Container flex={2}>
        <S.Flex className="justify-between mb-05">
          <S.Title>
            {historico.unidade
              ? `Histórico do tempo médio de tratativas (${historico.unidade})`
              : `Histórico do tempo médio de tratativas`}
          </S.Title>
          <PeriodPicker
            finalDate={dates.finalDate}
            initialDate={dates.initialDate}
            period="lastYear"
            periodAdd="month"
            onChange={date => {
              setDates({
                initialDate: date.initialDate,
                finalDate: subHours(date.finalDate, 3),
              });
            }}
          />
        </S.Flex>
        <LineGraph
          height={240}
          data={historico?.values}
          loading={isLoadingHistorico}
        />
      </S.Container>
      <S.Container flex={1}>
        <S.Title className="mb-05">Justificativas de exclusão</S.Title>
        <BarGraph
          height={240}
          data={justificativas}
          loading={isLoadingJustificativas}
        />
      </S.Container>
    </S.Card>
  );
};
