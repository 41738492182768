import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const SliderBox = styled.div`
  display: flex;
  min-height: 850px;
  width: 100%;
`;

export const SlideComponent = styled.div`
  position: relative;
  width: 100%;
`;

export const PreviewDate = styled.div`
  ${({ theme }) => css`
    font-weight: 600;
    color: ${theme.palette.words.title.natural};
    font-size: 15px;

    .raw {
      font-weight: 500;
      font-size: 14px;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;
