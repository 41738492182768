// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';
import { changeTabEvaluation } from 'store/modules/filterAvaliacoes/actions';
import ListPage from 'pages/_templates/ListPage';

// Componentes
import SaveModal from './SaveModal';
import ModalCriacao from './ModalCriacao';
import ExcelModal from 'components/ExcelModal';
import ConfirmModal from 'components/ConfirmModal';
import FiltersGlobal from 'components/FiltersGlobal';

// Utils
import { trackEvent } from 'utils/mixpanel';
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import { getRouterQueryParams } from 'utils/router-query-params';

// Serviços
import * as request from './services';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

// Columns
import {
  columnsArchived2,
  columnsFilled2,
  columnsProcessing2,
  columnsPending2,
  columnsSigned2,
  columnsUnavailable2,
} from './columns';

// Columns
import { fields, resetFields, justificativas, tabs } from './constants';

const Avaliacoes = () => {
  // Navigate
  const navigate = useNavigate();

  // Redux
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth?.user?.user);
  const userLevel = user.nivel;
  const { evaluationTab } = useSelector(state => state.filterAvaliacoes);
  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.avaliacoes;
  });

  // General States
  const [query, setQuery] = useState({
    ...filtersPersist,
    status: evaluationTab,
    card: null,
  });
  const [loadingTab, setLoadingTab] = useState(false);
  const [responsibles, setResponsibles] = useState(null);
  const [warningModal, setWarningModal] = useState(false);
  const [selectedEvaluations, setSelectedEvaluations] = useState(null);

  // Selects dos filtros
  const { filiais, operacao, clients, responsaveis, cargas } =
    useFetchMultipleWithCache();

  // -------------------------TABS (TOP PAGE)---------------------------------------//

  const getCol = () => {
    switch (evaluationTab) {
      case 'PROCESSAMENTO':
        return columnsProcessing2;
      case 'PENDENTE':
        return columnsPending2;
      case 'PREENCHIDA':
        return columnsFilled2;
      case 'ASSINADA':
        return columnsSigned2;
      case 'ARQUIVADA':
        return columnsArchived2;
      default:
        return columnsUnavailable2;
    }
  };

  // -------------------------CARDS (TOP PAGE)---------------------------------------//
  const [selectedCard, setSelectedCards] = useState(null);

  const handleClickCard = type => {
    if (type === selectedCard) setSelectedCards(null);
    else setSelectedCards(type);
  };

  // -------------------------- BULK MENU & ACTION COLUMN---------------------------//
  const [folga, setFolga] = useState('');
  const [justify, setJustify] = useState('');
  const [witness, setWitness] = useState('');
  const [loadingLines, setLoadingLines] = useState([]);
  const [openWitnessSelection, setOpenWitnessSelection] = useState(false);
  const [openArchiveEvaluations, setOpenArchiveEvaluations] = useState(false);
  const [openUnarchiveEvaluations, setOpenUnarchiveEvaluations] =
    useState(false);

  const bulk = [
    {
      title: 'Arquivar avaliação',
      function: setOpenArchiveEvaluations,
      visible: () =>
        ['INDISPONIVEL', 'PENDENTE', 'PREENCHIDA'].includes(evaluationTab) &&
        userLevel < 4,
    },
    {
      title: 'Selecionar testemunha',
      function: setOpenWitnessSelection,
      visible: () =>
        ['PENDENTE', 'PREENCHIDA'].includes(evaluationTab) && userLevel < 4,
    },
    {
      title: 'Restaurar Avaliação',
      function: setOpenUnarchiveEvaluations,
      visible: () => ['ARQUIVADA'].includes(evaluationTab) && userLevel < 4,
    },
  ];

  const handleArchiveEvaluation = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];
    setLoadingLines(ids);

    const body = {
      event: 'archiveEvaluation',
      data: {
        status: 'ARQUIVADA',
        justificativa: justify,
        data_folga: folga,
        ids,
      },
      id,
    };

    const res = await request.archiveEvaluation(body);
    if (res.success) {
      toast.success('Sucesso ao arquivar avaliação!');
      setOpenArchiveEvaluations(null);
      fetchData();
      fetchCards();
      setJustify('');
      setFolga('');
    } else {
      setLoadingLines([]);
      toast.error(res.message);
    }
  };

  const handleUnarchiveEvaluation = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];
    setLoadingLines(ids);
    const body = {
      event: 'unarchiveEvaluation',
      data: {
        ids,
      },
      id,
    };

    try {
      await request.archiveEvaluation(body);
      toast.success('Sucesso ao resturar avaliação!');
      fetchCards();
      fetchData();
    } catch (error) {
      setLoadingLines([]);
      const { message } = error.response.data;
      toast.error(message);
    }
    setOpenUnarchiveEvaluations(false);
  };

  const handleSelectWitnessEvaluation = async id => {
    let ids = [];
    ids =
      selectedEvaluations && selectedEvaluations.length > 0
        ? selectedEvaluations
        : [id];

    if (!witness) {
      toast.error('Selecione uma testemunha');
      return;
    }

    setLoadingLines(ids);

    const body = {
      event: 'bulk',
      data: {
        id_da_testemunha: witness,
        avaliacoes: ids,
      },
    };

    const res = await request.selectWitnessEvaluation(body);
    if (res.data.success) {
      fetchCards();
      fetchData();
      toast.success('Sucesso ao selecionar testemunha das avaliações!');
    } else {
      setLoadingLines([]);
      toast.error(res.data.message);
    }
    setOpenWitnessSelection(null);
    setWitness('');
  };

  // ------------------   actions -------------------------------------
  const handleViewEvaluation = async id => {
    await request.viewEvaluation(id);
    setLoadingLines([id]);
    fetchData();
  };

  const actions = [
    {
      title: 'Arquivar avaliação',
      function: id => {
        setOpenArchiveEvaluations(id);
        trackEvent(user, 'Arquivar Avaliação');
      },
      visible: () =>
        ['INDISPONIVEL', 'PENDENTE', 'PREENCHIDA'].includes(evaluationTab) &&
        userLevel < 4,
    },
    {
      title: 'Criar Avaliação',
      function: id => {
        setWarningModal(id);
        trackEvent(user, 'Criar Avaliação');
      },
      visible: () =>
        ['INDISPONIVEL'].includes(evaluationTab) &&
        !selectedEvaluations?.length &&
        userLevel < 4,
    },
    {
      title: 'Imprimir',
      function: id => {
        navigate(`pdf/${id}`);
        trackEvent(user, 'Imprimir Avaliação');
      },
      visible: () =>
        ['PREENCHIDA', 'ASSINADA'].includes(evaluationTab) &&
        !selectedEvaluations?.length,
    },
    {
      title: 'Selecionar testemunha',
      function: id => {
        setOpenWitnessSelection(id);
        trackEvent(user, 'Testemunha Adicionada');
      },
      visible: () =>
        ['PENDENTE', 'PREENCHIDA', 'ASSINADA'].includes(evaluationTab) &&
        userLevel < 4,
    },
    {
      title: 'Marcar como visto',
      function: id => handleViewEvaluation(id),
      visible: () =>
        ['PENDENTE', 'PREENCHIDA', 'ASSINADA'].includes(evaluationTab) &&
        !selectedEvaluations?.length,
    },
    {
      title: 'Abrir em nova guia',
      function: id => window.open(`/avaliacoes/${id}`, id),
      visible: () =>
        ['PENDENTE', 'PREENCHIDA', 'ASSINADA'].includes(evaluationTab) &&
        !selectedEvaluations?.length,
    },
    {
      title: 'Restaurar Avaliação',
      function: id => setOpenUnarchiveEvaluations(id),
      visible: () => ['ARQUIVADA'].includes(evaluationTab) && userLevel < 4,
    },
  ];

  // -------------------------- REQUESTS ------------------------------------------//
  const [resetTable, setResetTable] = useState(false);

  const {
    refetch: fetchData,
    isFetching,
    isLoading: loadingEvaluations,
    data: resData,
  } = useQuery(
    ['evaluations-index', { ...query, evaluationTab, selectedCard }],
    () =>
      evaluationTab && // Garante que um satatus está selecionado
      query.pageSize && // Aqui espera a tabela atualizar o query
      query.initialDate &&
      request.requestAvaliacoesList({
        ...query,
        status: evaluationTab,
        card: selectedCard,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        setLoadingTab(false);
        resetTable && setResetTable(false);
      },
    },
  );

  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    ['evaluations-cards', { ...query, evaluationTab }],
    () =>
      evaluationTab && // Garante que um status está selecionado
      query.pageSize && // Aqui espera a tabela atualizar o query
      query.initialDate &&
      request.requestCards({
        ...query,
        status: evaluationTab,
        card: undefined,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (openWitnessSelection) {
      fetchResponsibles(openWitnessSelection);
    }
  }, [openWitnessSelection]);

  const fetchResponsibles = async id => {
    if (responsibles === null) {
      const res = await request.requestResponsibles(id);
      setResponsibles(res.data.data);
    }
  };

  // -------------------------- EXCEL ------------------------------------------//
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const handleRequestExcel = async () => {
    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    let { initialDate, finalDate } = query;
    initialDate = Date.parse(initialDate);
    finalDate = Date.parse(finalDate);

    if (finalDate - initialDate > 7889400000 && !selectedEvaluations.length) {
      toast.error('Não é possível exportar períodos maiores que 3 meses');
      return;
    }

    const newQuery = {
      ...query,
      status: evaluationTab,
      excelFields: newFields,
      ids: selectedEvaluations,
    };

    setLoadingExcel(true);
    const res = await request.requestExcel(newQuery);

    if (!res.data.success) {
      toast.error(res.data.message);
      setLoadingExcel(false);
      return;
    }

    if (res.data && res.data.data.excel) toast.success(res.data.message);
    ExportToExcel({
      excel: res.data.data.excel,
      name: `avaliacoes_${formatedDate}`,
    });
    setExcelFields(resetFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }
    setResetTable(true);
  }, [selectedCard]); // TODO: nao sei se devo colocar query aqui

  useEffect(() => {
    setJustify('');
    setWitness('');
  }, [openArchiveEvaluations, openWitnessSelection]);

  const mode = getRouterQueryParams({ location: useLocation }).get('mode');
  useEffect(() => {
    if (!mode) return;

    const tab = tabs.find(
      item => item.label.toLowerCase() === mode.toLowerCase(),
    )?.value;

    tab && dispatch(changeTabEvaluation({ tab }));
    if (tab === 'ARQUIVADA') trackEvent(user, 'Avaliações Arquivadas');
  }, [mode]);

  const filterList = [
    // filiais, operacao, clients, responsaveis, cargas
    {
      filterName: 'filial',
      label: 'Filiais',
      options: filiais || [],
    },
    // {
    //   filterName: 'filial_veiculo',
    //   label: 'Filial Veículo',
    //   options: filiais || [],
    // },
    {
      filterName: 'operation',
      label: 'Operação',
      options: operacao,
    },
    {
      filterName: 'client',
      label: 'Clientes',
      options: clients.data || [],
    },
    {
      filterName: 'responsavel',
      label: 'Responsáveis',
      options: responsaveis.data || [],
    },
    {
      filterName: 'carga',
      label: 'Cargas',
      options: cargas.data || [],
    },
  ];

  // Configura as tabs da tela
  const headerTabs = {
    value: evaluationTab,
    items: tabs,
    onChange: (e, tab) => {
      setSelectedCards(null);
      setLoadingTab(true);
      dispatch(changeTabEvaluation({ tab }));
      if (tab === 'ARQUIVADA') trackEvent(user, 'Avaliações Arquivadas');
    },
    disabled: isFetching,
    // last: false,
  };

  const renderFilters = () => {
    return (
      <FiltersGlobal
        // hideRefleshButton
        showHours
        persistDate
        refetch={fetchData}
        isFetching={isFetching}
        handleFilters={props =>
          setQuery(prev => {
            return {
              ...prev,
              ...props,
            };
          })
        }
        handleExport={() => {
          setOpenExcelModal(true);
          trackEvent(user, 'Exportar Planilha Avaliações');
        }}
        data={filterList}
      />
    );
  };

  let cards = [{}, {}, {}, {}, {}];
  if (resCards?.data?.data) {
    const nCards = resCards.data.data.length;
    cards = resCards.data.data.map(item => {
      item.disabled = item.type === 'VIDEOS_PROCESSADOS';
      item.value = item.ids?.length;
      item.smalltype = nCards > 3 ? 'true' : undefined;
      item.icon = 'Grupo12741.svg';
      // handleClick={
      //   item.type !== 'VIDEOS_PROCESSADOS'
      //     ? () => handleClickCard(item.type)
      //     : null
      // }
      return item;
    });
  }

  return (
    <>
      <ListPage
        title="Avaliação de direção "
        headerTabs={headerTabs}
        tableTemplate="default"
        cards={cards}
        selectedCard={selectedCard}
        handleClickCard={handleClickCard}
        // lastUpdated={hasTorre || hasTorrePlus ? lastUpdated : null}
        customComponent={renderFilters()}
        loadingCards={loadingCards}
        tableProps={{
          data: resData?.data?.rows || [],
          columns: getCol(),
          setSelectedRows: setSelectedEvaluations,
          loading: loadingEvaluations || loadingTab,
          pageCount: resData?.data?.count || 0,
          visualizedKey: 'visto',
          local: false,
          bulk,
          actions: evaluationTab === 'PROCESSAMENTO' ? undefined : actions,
          reset: resetTable,
          onClickRow: !['INDISPONIVEL', 'PROCESSAMENTO', 'ARQUIVADA'].includes(
            evaluationTab,
          )
            ? id => navigate(`/avaliacoes/${id}`)
            : false,
          loadingSelection: loadingLines,
          setQuery: props =>
            setQuery(prev => {
              return {
                ...prev,
                ...props,
              };
            }),
          searchEvent: search =>
            trackEvent(user, 'Busca Avaliação de Direção', null, search),
          sortBy: {
            id:
              evaluationTab !== 'INDISPONIVEL' ? 'data_avaliacao' : 'updatedAt',
            order: 'DESC',
          },
          placeholder: 'Buscar Motoristas, ID...',
          empty: {
            title: 'Ops! Você não tem nenhuma avaliação.',
            description: 'Verifique os filtros selecionados.',
          },
        }}
      />

      <SaveModal
        onClick={() => handleArchiveEvaluation(openArchiveEvaluations)}
        open={!!openArchiveEvaluations}
        handleClose={() => setOpenArchiveEvaluations(false)}
        title="Deseja arquivar esta Avaliação?"
        titleIcon={file}
        subtitle="Selecione uma justificativa para o arquivamento."
        disabledSave
        value={justify}
        handleChange={setJustify}
        valueFolga={folga}
        setFolga={setFolga}
        data={justificativas.map(item => ({ value: item, name: item }))}
        selectLabel="Justificativa"
      />

      <ConfirmModal
        handleClose={() => setOpenUnarchiveEvaluations(false)}
        open={!!openUnarchiveEvaluations}
        title="Tem certeza que deseja restaurar?"
        subtitle="As avaliações resturadas voltam ao estado anterior"
        buttonText="Confirmar"
        onClick={() => handleUnarchiveEvaluation(openUnarchiveEvaluations)}
      />

      {responsibles && (
        <SaveModal
          onClick={() => handleSelectWitnessEvaluation(openWitnessSelection)}
          open={!!openWitnessSelection}
          handleClose={() => setOpenWitnessSelection(false)}
          title="Selecione a testemunhas da avaliação"
          titleIcon={file}
          subtitle="Selecione um usuário para testemunha."
          disabledSave
          value={witness}
          handleChange={setWitness}
          selectLabel="Testemunha"
          data={responsibles.map(item => ({
            value: item.id,
            name: item.nome,
          }))}
        />
      )}

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixos:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      {warningModal && (
        <ModalCriacao
          open
          onClose={setWarningModal}
          onClick={() => navigate(`/avaliacoes/criar/${warningModal}`)}
        />
      )}
    </>
  );
};

export default Avaliacoes;
