import styled, { css, keyframes } from 'styled-components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { styled as stMuiUI } from '@mui/material/styles';
import colors from 'styles/colors';
import { Card } from '@mui/material';

const Space = styled.div`
  margin-top: ${props => props.h}px;
`;

const Container = styled.div`
    flex-wrap: wrap;
    flex-direction:column;
    height: 100px,
    overflow: scroll;
`;

const ButtonContianer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const TagContent = styled.div`
  display: flow-root;
  flex-direction: row;
  overflow-x: hidden;
`;

const Tag = styled.div`
  ${({ theme, tabSelected }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: ${theme.palette.semantics.feedback.unknown.light};
    padding-right: 10px;
    padding-left: 10px;
    height: 22px;
    margin-right: 5px;
    font-size: 14px;
    color: ${theme.palette.words.title.natural};
    font-weight: bold;
    border: ${tabSelected
      ? `1px solid ${theme.palette.brand.primary.light};`
      : `none`};
    white-space: nowrap;
  `}
`;

const CardAddContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 345px;
    height: 392px;
    border: 1px dashed ${theme.palette.brand.primary.light};
    border-radius: 5px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    background-color: ${theme.palette.system.overlay};
    &:hover {
      background-color: rgba(238, 238, 238, 0.1);
    }
  `}
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 3fr);
  grid-column-gap: 20px;
  grid-row-gap: 1em;
`;

const CardMedia = styled.div`
  ${({ theme }) => css`
    -webkit-filter: brightness(70%);
    width: 100%;
    .module {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: ${theme.palette.system.shadow};
      width: 100%;
      top: 0px;
      right: 0px;
      color: while;
      height: 40px;
      .title {
        color: white;
        font-weight: bold;
      }
    }
  `}
`;

const containerStatus = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  color: ${props => props.color};
  font-size: 13px;
`;

const MediaContainer = styled.div`
  display: none;
  margin-top: 100px;
`;
const TagsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 3px;
  height: 25%;
`;

const CardDescription = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.palette.system.overlay};
    transition: transform 0.2s;
    transform: translateY(0px);
    padding: 20px;
    height: 200px;
  `}
`;

const containerSubTitle = styled.div`
  ${({ theme }) => css`
    text-align: justify;
    text-justify: inter-word;
    text-align: left;
    height: 25%;
    font-size: 13px;
    letter-spacing: 0px;
    color: ${theme.palette.brand.primary.natural};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `}
`;

const containerTitle = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;
    height: 25%;
    color: ${theme.palette.brand.primary.natural};
    font-size: 16px;
    font: normal normal 900 16px/20px Texta;
    letter-spacing: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `}
`;

const ButtonConainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Card2 = styled(Card)`
  //max-width: 345px;
  height: 392px;
  &:hover ${CardDescription} {
    transform: translateY(-140px);
    transition: transform 0.2s;
  }

  &:hover ${MediaContainer} {
    display: flex;
    margin-top: 30px;
  }

  &:hover ${containerStatus} {
    display: none;
  }

  &:hover ${containerSubTitle} {
    -webkit-line-clamp: 5;
  }

  &:hover ${containerTitle} {
    -webkit-line-clamp: 5;
  }

  &:hover ${ButtonConainer} {
    display: flex;
  }
`;

const containerDate = styled.div`
  ${({ theme }) => css`
    white-space: nowrap;
    font-size: 13px;
    color: ${theme.palette.brand.primary.natural};
  `}
`;

export const ProgressBar = styled.div`
  ${({ theme }) => css`
    height: 5px;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #ccc;
    border-radius: 4px 4px 4px 4px;
    .progress {
      border-radius: 4px 4px 4px 4px;
      transform: translateY(-1px);
      background-color: ${theme.palette.semantics.feedback.success.naturals};
      height: 5px;
    }
  `}
`;

const ContainerEmptyState = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 455px;
    background: ${theme.palette.system.overlay};
    width: 100%;
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    opacity: 1;
    border-radius: 4px;
  `}
`;

const EmptyStateTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 60px;
`;

export const containerBanner = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.system.overlay};
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px ${theme.palette.system.shadow};
    .avatar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transform: translateY(-60px);
    }
    .tabs {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .title-tab {
        display: flex;
        flex-direction: column;
        font-size: 24x;
        letter-spacing: 0px;
        color: ${theme.palette.words.subtitle.natural};
        margin-right: 30px;
        margin-left: 30px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .indicator {
          margin-top: 15px;
          height: 4px;
          background: ${theme.palette.brand.secondary.natural};
          border-radius: 4px 4px 0px 0px;
          transition: background 1s;
        }
        .container-title {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
    }

    label {
      color: ${theme.palette.words.title.natural};
      text-align: left;
      font: normal normal normal 18px/18px Texta;
    }
    img {
      filter: brightness(40%);
    }
  `}
`;

export const containerCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    box-shadow: ${theme.palette.system.shadow} 0px 1px 4px;
    width: 100%;
    height: 132px;
    background: ${theme.palette.system.overlay};
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    .container-card {
      display: flex;
      width: 33%;
      height: 3px;
      justify-content: center;
      align-items: center;
    }
    .container-card-divider {
      height: 132px;
      height: 45.43px;
      border: 1px solid ${theme.palette.brand.primary.light}4d;
    }
    .icon-container {
      padding: 8px 8px 8px 8px;
      color: ${theme.palette.words.text.light};
      background: #eee;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
    h1 {
      font-size: 20px;
      line-height: 1;
    }
    h3 {
      color: ${theme.palette.words.text.light};
      line-height: 1;
      font-size: 14px;
    }
  `}
`;

// Meterial Ui style

const ButtonStyle = {
  '&:hover': {
    borderColor: '#494F65',
    color: '#3c52b2',
  },
  width: 235,
  borderColor: '#494F65',
  color: '#494F65',
};

const StyledTooltip = stMuiUI(({ className, ...props }) => (
  <Tooltip
    placement="bottom"
    arrow
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    border: '1px solid #dadde9',
  },
}));

export {
  Container,
  Space,
  ButtonContianer,
  Tag,
  TagContent,
  SearchContainer,
  CardAddContent,
  CardContainer,
  CardMedia,
  CardDescription,
  containerDate,
  containerTitle,
  containerSubTitle,
  containerStatus,
  ButtonStyle,
  StyledTooltip,
  ButtonConainer,
  ContainerEmptyState,
  EmptyStateTitle,
  MediaContainer,
  Card2,
  TagsContainer,
};
