import styled, { css } from 'styled-components';

export const Main = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      align-items: center;

      input {
        display: none;
      }

      .imgArea {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid ${theme.palette.brand.secondary.natural};
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        .borderImage {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
          }
        }

        .empty {
          background-color: ${theme.palette.system.border};
          width: 100%;
          height: 100%;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 900;
          color: ${theme.palette.words.title.natural};
        }
      }

      .textArea {
        display: flex;
        align-items: center;
        color: ${theme.palette.words.subtitle.natural};
        margin-left: 10px;

        span {
          margin-left: 5px;
        }
      }
    }
  `}
`;
