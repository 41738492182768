import { filters, filtersProvider } from './constants';

export const pageFilters = (defaults, selects, provider) => {
  const globals = Array.isArray(defaults)
    ? defaults
    : provider
    ? ['empresas', 'operations', 'motoristas', 'filials']
    : ['filials', 'operations', 'clients'];

  const clientFilters = (provider ? filtersProvider : filters)?.filter(i =>
    provider ? i.provider : i.user && globals?.includes(i.key),
  );

  const hasSection =
    globals?.includes('operations') && globals?.includes('clients');

  const actives = clientFilters?.filter(i => globals?.includes(i.key));

  const keys = [];
  const datas = actives?.map(i => {
    const select =
      selects?.[i?.datakey ?? i?.key]?.map(s => ({
        ...s,
        label: i.key === 'operations' ? s.operacao : s.nome,
        value: s.id,
      })) || [];
    keys.push(i.name);
    if ((i.key === 'operations' || i.key === 'clients') && !hasSection) {
      return { ...i, section: undefined, select };
    }
    return { ...i, select };
  });

  return {
    datas,
    defaults: clientFilters?.map(i => i.name) || [],
  };
};

export const counter = (global, pages, filter, page) => {
  let count = 0;
  for (const key in filter) {
    if (
      key !== 'initialDate' &&
      key !== 'finalDate' &&
      key !== 'dateLabel' &&
      global?.includes(key)
    ) {
      if (filter[key] !== null && filter[key] !== '') count += 1;
    }
  }
  for (const key in page) {
    if (pages?.includes(key)) {
      if (
        page[key] !== null &&
        page[key] !== '' &&
        page[key] !== false &&
        typeof page[key] !== 'object'
      )
        count += 1;
    }
  }
  return count;
};

export const load = (filter = {}, pageFilter = {}, glFilters, pgFilters) => {
  const val = {};
  const gKeys = Object?.keys(filter);
  const pKeys = Object?.keys(pageFilter);

  gKeys?.forEach(i => glFilters?.includes(i) && (val[i] = filter[i]));
  pKeys?.forEach(i => pgFilters?.includes(i) && (val[i] = pageFilter[i]));

  return val;
};

export const setterFilter = (provider, pageFilterName) => {
  const setPageFilter =
    pageFilterName &&
    !provider &&
    require(`store/modules/${pageFilterName}/actions`).setFilter;

  const setPageFilterProvider =
    pageFilterName &&
    provider &&
    require(`store/modules/provider/${pageFilterName}/actions`)
      .setFilterProvider;

  return provider ? setPageFilterProvider : setPageFilter;
};

export const checkDependency = (
  filter,
  filtersTemp,
  provider,
  highData,
  lowData,
  user,
) => {
  if (filter.visibility) {
    const id = user?.provider ? user?.distribuidora?.id : user?.empresa?.id;
    const visibility = filter.visibility[provider ? 'provider' : 'user'];
    if (visibility && !visibility.includes(id)) {
      return {
        visible: false,
        data: undefined,
      };
    }
  }

  const userKeys = filter?.dependency?.filter(i =>
    provider ? i.type === 'provider' : i.type === 'user',
  );

  if (!userKeys || !userKeys.length)
    return {
      visible: true,
      data: undefined,
    };

  const keysToVisible = userKeys?.map(i => i.name);

  let fData;
  const hasOne = keysToVisible?.findIndex(i => filtersTemp?.[i]);
  let visible = hasOne > -1 || filter?.dependency?.every(i => !i.visible);

  if (hasOne > -1) {
    const depItem = filter?.dependency?.find(
      i => i.name === keysToVisible[hasOne],
    );

    const depData = depItem?.data;

    const keyData = userKeys[hasOne]?.name;

    const currentItem = highData?.find(i => i.name === keyData);
    const currentData = currentItem?.select || lowData?.[keyData] || [];
    const currentValue =
      currentItem.mode === 'multiple'
        ? filtersTemp[keyData].split(',').filter(i => i)
        : filtersTemp[keyData];

    let max = false;
    if (depItem.maxSelect && currentValue.length > depItem.maxSelect) {
      max = true;
      visible = false;
    }

    if (
      !max &&
      depItem?.value &&
      (currentItem?.mode === 'multiple'
        ? !currentValue.includes(depItem?.value?.toString())
        : currentValue !== depItem?.value?.toString())
    ) {
      visible = false;
    } else {
      if (depData)
        fData = depData(
          currentItem?.mode === 'multiple' && depItem.maxSelect === 1
            ? currentValue?.[0]
            : currentValue,
          currentData,
          lowData?.[filter.name],
        );
    }
  }

  return {
    visible,
    data: fData,
  };
};

export const checkClear = (key, sections, provider) => {
  const deps = [];

  sections?.forEach(i => {
    i?.filters?.forEach(f => {
      if (
        f?.dependency?.find(
          d =>
            d.name === key &&
            (provider ? d.type === 'provider' : d.type === 'user') &&
            d.clear,
        )
      ) {
        deps.push(f.name);
      }
    });
  });

  const objClear = {};
  deps?.forEach(i => (objClear[i] = ''));
  return objClear;
};
