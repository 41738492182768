import React from 'react';
import OnisysLogo from 'images/Grupo43.svg';
import PadCongig from 'images/empty/padconfig.png';
import Button from 'components/Buttons/Default';
import { Content, Control, Logo, Wrapper } from './styled';

const Feedback = () => {
  return (
    <Wrapper>
      <Logo src={OnisysLogo} />
      <Content>
        <img src={PadCongig} />
        <Control>
          <h1>Agradecemos pelas informações!</h1>
          <p>
            Seu cadastro será analisado e você receberá um e-mail em até 1 dia
            útil.
          </p>
          <Button href="https://www.onisys.com.br">
            VOLTAR PARA O SITE ONISYS
          </Button>
        </Control>
      </Content>
    </Wrapper>
  );
};

export default Feedback;
