import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    flex-direction: column;
    align-items: start;
    p {
      margin-bottom: 5px;
    }
    .MuiFilledInput-root {
      color: ${props.fillcolor
        ? props.fillcolor
        : theme.palette.words.text.light};
      border: 1px solid
        ${props.fillcolor
          ? `${props.fillcolor}1A`
          : theme.palette.semantics.feedback.unknown.light};
      background: ${props.fillcolor
          ? `${props.fillcolor}1A`
          : theme.palette.semantics.feedback.unknown.light}
        0% 0% no-repeat padding-box !important;
      &:before {
        border-bottom: none !important;
      }
      &:after {
        border-bottom: none !important;
      }
      input {
        padding: 16.5px 14px !important;
      }
    }
  `}
`;
