import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useConstants } from 'constants/use';

// Actions
import { pageFilters } from './actions';

import { uuidV4 } from './constants';

export const useFilter = (page = '', selects, provider) => {
  const user = useSelector(state => state?.auth?.user?.user);
  const map = useSelector(state => state.pageMap.pageMap);
  const { _PRIVATE } = useConstants(map, user);
  const [pageData, setPageData] = useState({});

  const [keys, setKeys] = useState([]);
  const [sections, setSections] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [keysDefault, setKeysDefault] = useState([]);

  const checkRoute = (page, key) => {
    const excludes = ['?', ':'];

    const pure = route => {
      return route
        .split('/')
        .map(part =>
          excludes.some(item => part.includes(item)) ||
          +part ||
          uuidV4.test(part)
            ? ''
            : part,
        )
        .join('/');
    };

    const pagePure = pure(page);
    const keyPure = pure(key);
    return pagePure === keyPure;
  };

  useEffect(() => {
    const generateData = async () => {
      const screen =
        _PRIVATE?.[Object?.keys(_PRIVATE)?.find(key => checkRoute(page, key))];
      const filters = screen?.filters;

      const screenData = {};
      await filters?.forEach(async (filter, idx) => {
        const data = await filter?.data?.(selects);
        screenData[filter?.name] = data || [];

        if (idx === filters?.length - 1) setPageData(screenData);
      });
    };
    generateData();

    const current =
      _PRIVATE?.[Object.keys(_PRIVATE)?.find(key => checkRoute(page, key))];
    const sections = [];
    const exp = [];

    const { datas, defaults } = pageFilters(
      current?.defaults,
      selects,
      provider,
    );

    [...(datas || []), ...(current?.filters || [])]?.forEach(filter => {
      const section = sections?.find(sec => sec?.name === filter?.section);
      if (section) {
        section?.filters?.push(filter);
      } else {
        exp?.push(false);
        sections?.push({
          name: filter?.section,
          filters: [filter],
        });
      }
    });

    setExpanded(exp);
    setKeysDefault(defaults);
    setKeys(current?.filters?.map(filter => filter?.name));
    setSections(sections?.sort((a, b) => b?.name?.localeCompare(a?.name)));
  }, [page]);

  const handleExpand = idx => {
    const exp = expanded.map(_ => false);
    exp[idx] = !expanded[idx];
    setExpanded(exp);
  };

  return {
    page: _PRIVATE?.[
      Object?.keys(_PRIVATE)?.find(key => checkRoute(page, key))
    ],
    datas: pageData,
    sections,
    expanded,
    handleExpand,
    keys,
    keysDefault,
  };
};
