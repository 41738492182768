import React from 'react';

import * as S from './styled';

export const Info = ({ title, text, subtitle }) => {
  return (
    <S.Container>
      {subtitle && <S.Text>{subtitle}</S.Text>}
      {text && <S.Text>{text}</S.Text>}
      {title && <S.Title>{title}</S.Title>}
    </S.Container>
  );
};
