import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Rectangle,
} from 'recharts';

const CustomBarShape = props => {
  const { fill, x, y, width, height, opacity, stroke } = props;

  return (
    <Rectangle
      x={x}
      y={y}
      width={width}
      height={height}
      radius={[2, 2, 2, 2]}
      fill={fill}
      cursor="pointer"
      opacity={opacity}
      stroke={stroke}
    />
  );
};

const CustomLabel = props => {
  const { x, y, name, color } = props;

  return (
    <g>
      <text
        x={x}
        y={y - 10}
        fill={color}
        textAnchor="start"
        dominantBaseline="middle"
      >
        {name}
      </text>
    </g>
  );
};

const BarChartVertical = ({ data = [], onBarClick }) => {
  const theme = useTheme();
  const colors = {
    PRIMÁRIA: theme.palette.semantics.feedback.success.natural,
    MODERADA: theme.palette.semantics.feedback.information.dark,
    GRAVE: theme.palette.semantics.feedback.warning.natural,
    GRAVÍSSIMA: theme.palette.semantics.feedback.attention.natural,
  };
  const [hover, setHover] = useState(null);
  return (
    <ResponsiveContainer width="100%" height={180}>
      <BarChart data={data} layout="vertical" margin={{ top: 20 }}>
        <XAxis dataKey="value" type="number" hide />
        <YAxis
          dataKey="name"
          type="category"
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 12 }}
          hide
        />
        <Bar
          dataKey="value"
          barSize={18}
          onMouseEnter={(item, index) => setHover(index)}
          onMouseOut={() => setHover(null)}
          shape={({ index, ...props }) => (
            <CustomBarShape
              {...props}
              fill={colors[data[index].name]}
              opacity={
                hover === index
                  ? 0.8
                  : data.every(e => !e.selected) || data[index].selected
                  ? 1
                  : 0.4
              }
              stroke={hover === index ? colors[data[index].name] : ''}
            />
          )}
          onClick={onBarClick}
          label={({ x, y, width, height, index }) => {
            let label = data[index].name.toLowerCase();
            label = label[0].toUpperCase() + label.slice(1);
            return (
              <CustomLabel
                x={x}
                index={index}
                y={y}
                width={width}
                height={height}
                name={label}
                color={colors[data[index].name]}
              />
            );
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartVertical;
