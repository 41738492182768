import { useEffect, useState } from 'react';
import { Divider, Grid, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import ExpandMore from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLess from '@mui/icons-material/ExpandLessOutlined';
import MuiCollapse from '@mui/material/Collapse';
import Switch from '../Switch';
import * as S from '../../styled';

const Collapse = ({
  canRemove,
  handleRemove,
  last,
  title,
  switchProps,
  children,
}) => {
  const [open, setOpen] = useState(last);

  useEffect(() => {
    setOpen(last);
  }, [last]);

  return (
    <>
      <Grid item xs={12}>
        <S.Section>
          <S.SectionTitle>
            {switchProps && !open && <Switch {...switchProps} />}
            <h2>{title}</h2>
          </S.SectionTitle>
          {last && canRemove && (
            <Tooltip title="Excluir desvio">
              <IconButton size="small" onClick={() => handleRemove()}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}{' '}
          {!last && (
            <IconButton size="small" onClick={() => setOpen(state => !state)}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </S.Section>
      </Grid>
      <MuiCollapse in={open} timeout="auto">
        {children}
      </MuiCollapse>
      {!last && <Divider />}
    </>
  );
};
export default Collapse;
