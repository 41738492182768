import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;
    border: 1px solid ${theme.palette.brand.secondary.natural};
    border-radius: 50px;
    margin-right: 10px;
    position: relative;
  `}
`;

export const Circle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border-radius: 50px;
    background-color: ${theme.palette.system.border};
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50px;
    }

    p {
      font-weight: 800;
      color: ${theme.palette.words.title.natural};
      text-transform: uppercase;
    }
  `}
`;
