import styled from 'styled-components';
import colors from 'styles/colors';

export const Cotainer = styled.div`
  margin-bottom: 50px;
`;

export const Title = styled.div`
  font-size: 25px;
  color: ${colors.greyTitle};
  font-weight: 600;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Description = styled.div`
  font-size: 18px;
  color: ${colors.greySubtitle};
  font-weight: 500;
  margin: 15px 0px;
`;

export const MediaVideo = styled.embed`
  width: 100%;
  height: 500px;
`;

export const ContainerMedia = styled.div`
  width: 100%;
  height: 500px;
  background-color: #000;
`;

export const MediaImage = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
`;

export const MediaText = styled.div`
  padding: 0px 15px;
`;
