import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import GlobalStyle from 'styles/global';
import './App.css';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from 'routes';
import { getFirebaseToken, onForegroundMessage } from 'utils/firebase';
import { CustomThemeProvider as ThemeProvider } from './styles/CustomThemeProvider';
import { store, persistor } from './store';

const App = () => {
  // useEffect(() => {
  //   onForegroundMessage()
  //     .then(payload => {
  //       const {
  //         notification: { title, body },
  //       } = payload;
  //       const Display = () => {
  //         return (
  //           <div>
  //             <h4>{title}</h4>
  //             <p>{body}</p>
  //           </div>
  //         );
  //       };
  //       toast.info(<Display />, { autoClose: false });
  //     })
  //     .catch(err =>
  //       console.log(
  //         'An error occured while retrieving foreground message. ',
  //         err,
  //       ),
  //     );
  // }, []);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <React.StrictMode>
            <ThemeProvider>
              <Routes />
              <ToastContainer autoClose={3000} />
              <GlobalStyle />
            </ThemeProvider>
          </React.StrictMode>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
