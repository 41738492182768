import React from 'react';

import { ReactComponent as Bucejo } from 'images/icons/desvios/ic_bocejo.svg';
import { ReactComponent as Celular } from 'images/icons/desvios/ic_celular.svg';
import { ReactComponent as Cinto } from 'images/icons/desvios/ic_cinto.svg';
import { ReactComponent as ForcaG } from 'images/icons/desvios/ic_forca_g.svg';
import { ReactComponent as Freada } from 'images/icons/desvios/ic_freada.svg';
import { ReactComponent as OlhoFechado } from 'images/icons/desvios/ic_olho_fechado.svg';
import { ReactComponent as Velocidade } from 'images/icons/desvios/ic_velocidade.svg';
import { ReactComponent as Faixa } from 'images/icons/desvios/ic_faixa.svg';
import { ReactComponent as ProxVencimento } from 'images/icons/desvios/icone_prox_vencimento.svg';
import { ReactComponent as RiscoALto } from 'images/icons/desvios/laranja.svg';
import { ReactComponent as RiscoAltissimo } from 'images/icons/desvios/vermelho.svg';
import { ReactComponent as IconeHome } from 'images/icons/sidebar/new-icons/icone-home.svg';
import { ReactComponent as IconeDesvios } from 'images/icons/sidebar/new-icons/icone-desvios.svg';
import { ReactComponent as IconeAvaliacoes } from 'images/icons/sidebar/new-icons/icone-camera.svg';
import { ReactComponent as IconeAcoesSuspensoes } from 'images/icons/sidebar/new-icons/icone-acoes-suspensoes.svg';
import { ReactComponent as IconeFormulario } from 'images/icons/sidebar/new-icons/icone-formularios.svg';
import { ReactComponent as IconeAcidentes } from 'images/icons/sidebar/new-icons/icone-acidentes.svg';
import { ReactComponent as IconeMotoristas } from 'images/icons/sidebar/new-icons/icone-motoristas.svg';
import { ReactComponent as IconeEquipe } from 'images/icons/sidebar/new-icons/icone-equipe.svg';
import { ReactComponent as IconeFrota } from 'images/icons/sidebar/new-icons/icone-frota.svg';
import { ReactComponent as IconeLayouts } from 'images/icons/sidebar/new-icons/icone-layouts.svg';
import { ReactComponent as IconeRotograma } from 'images/icons/sidebar/new-icons/icone-rotograma.svg';
import { ReactComponent as IconeCapacitacao } from 'images/icons/sidebar/new-icons/icone-capacitacao.svg';
import { ReactComponent as IconeArrowRight } from 'images/icons/sidebar/new-icons/arrow-right.svg';
import { ReactComponent as IconeEmpresas } from 'images/icons/sidebar/new-icons/icone-empresas.svg';
import { ReactComponent as PinPrimaria } from 'images/icons/pins/pin-primario.svg';
import { ReactComponent as PinModerada } from 'images/icons/pins/pin-moderado.svg';
import { ReactComponent as PinGrave } from 'images/icons/pins/pin-grave.svg';
import { ReactComponent as PinGravíssima } from 'images/icons/pins/pin-gravissimo.svg';
import { ReactComponent as IconeAlerta } from 'images/icons/icone-alerta.svg';

const iconComponents = {
  ic_bocejo: Bucejo,
  ic_celular: Celular,
  ic_cinto: Cinto,
  ic_forca_g: ForcaG,
  ic_freada: Freada,
  ic_olho_fechado: OlhoFechado,
  ic_velocidade: Velocidade,
  ic_faixa: Faixa,
  icone_prox_vencimento: ProxVencimento,
  icone_risco_alto: RiscoALto,
  icone_risco_altissmo: RiscoAltissimo,
  icone_home: IconeHome,
  icone_desvios: IconeDesvios,
  icone_avaliacoes: IconeAvaliacoes,
  icone_acoes_suspensoes: IconeAcoesSuspensoes,
  icone_formulario: IconeFormulario,
  icone_acidentes: IconeAcidentes,
  icone_motoristas: IconeMotoristas,
  icone_equipe: IconeEquipe,
  icone_frota: IconeFrota,
  icone_layouts: IconeLayouts,
  icone_rotograma: IconeRotograma,
  icone_capacitacao: IconeCapacitacao,
  icone_arrow_right: IconeArrowRight,
  icone_empresas: IconeEmpresas,
  PIN_PRIMÁRIA: PinPrimaria,
  PIN_MODERADA: PinModerada,
  PIN_GRAVE: PinGrave,
  PIN_GRAVÍSSIMA: PinGravíssima,
  icone_alerta: IconeAlerta,
};

const Icon = ({ name, style }) => {
  const IconComponent = iconComponents[name];
  return IconComponent ? <IconComponent style={style} /> : null;
};

export { Icon };
