import * as React from 'react';
import { Box, FormControl } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore'; // Ícone de seta para baixo
import { styled } from '@mui/material/styles';
import { Item, Label, SelectCustom } from './styled';

const CustomArrowDropDownIcon = styled(ExpandMore)(({ theme, open }) => ({
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.short,
  }),
}));

export const CustomizedSelect = ({
  selected,
  handelSelect = () => {},
  items = [],
  styles = {},
  size = 'medium',
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState(selected);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = event => {
    setValue(event.target.value);
    handelSelect(event.target.value);
  };

  return (
    <Box sx={{ width: '100%', ...styles }}>
      <FormControl fullWidth>
        <SelectCustom
          value={selected ?? value}
          displayEmpty
          onChange={handleChange}
          onOpen={handleOpen}
          onClose={handleClose}
          IconComponent={props => (
            <CustomArrowDropDownIcon {...props} open={isOpen} />
          )}
          size={size}
        >
          <Label>Selecione uma opção</Label>
          {items.map((item, index) => (
            <Item key={index} value={item.value}>
              {item.label}
            </Item>
          ))}
        </SelectCustom>
      </FormControl>
    </Box>
  );
};
