/* eslint-disable import/order */

// Styles
import * as S from './styled';
import * as C from '../styled';
import colors from 'styles/colors';

// React
import { useState } from 'react';

// Components
import ModalUsuario from './ModaUsuarios';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { Tooltip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Utils
import emptyImage from 'images/empty/frota.png';

export const Usuarios = ({
  title,
  tooltip,
  button,
  data,
  updateData,
  activedFilials,
  preview,
}) => {
  const [dataEdit, setDataEdit] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  data = data?.map((item, k) => ({ ...item, id_local: data?.length - k }));

  const textFormater = (text, size = 20) => {
    text = text?.toLowerCase();
    if (text?.length > size) {
      return `${text?.slice(0, size)}...`;
    }
    return text;
  };

  // --------------------------------------TABLE---------------------------------------//
  const [ordenad, setOrdenad] = useState(0);

  const getOrdenad = () => {
    switch (ordenad) {
      case 0:
        return [...data.sort((a, b) => b.id_local - a.id_local)];
      case 1:
        return [...data.sort((a, b) => `${a.nome}`.localeCompare(b.nome))];
      case 2:
        return [...data.sort((a, b) => `${b.nome}`.localeCompare(a.nome))];
    }
  };

  const handleOrdenad = () => {
    if (ordenad == 0) {
      setOrdenad(1);
    } else if (ordenad == 1) {
      setOrdenad(2);
    } else {
      setOrdenad(0);
    }
  };

  const setNewData = newData => {
    updateData(newData);
    setOrdenad(0);
  };

  // --------------------------------------RENDER---------------------------------------//
  return (
    <S.Container>
      <C.HeaderStep>
        <div className="left">
          <div className="title">{title}</div>
          {tooltip && (
            <Tooltip title={tooltip}>
              <HelpOutlineOutlinedIcon className="iconTooltip" />
            </Tooltip>
          )}
        </div>
        {button && (
          <DefaultButton
            children={button}
            disabled={preview}
            icon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => setOpenModal(true)}
          />
        )}
      </C.HeaderStep>

      {data?.length > 0 ? (
        <S.SampleTable>
          <S.HeaderTable onClick={() => handleOrdenad()}>
            <div className="left space">
              <p>NOME</p>
              <div className="arrows">
                <ArrowDropUpOutlinedIcon
                  className={ordenad == 1 ? 'top active' : 'top'}
                />
                <ArrowDropDownOutlinedIcon
                  className={ordenad === 2 ? 'bottom active' : 'bottom'}
                />
              </div>
            </div>

            <div className="left">
              <p>FUNÇÃO</p>
            </div>

            <div className="left">
              <p>FILIAL</p>
            </div>
            <div className="right center">
              <p>EDITAR</p>
            </div>
          </S.HeaderTable>
          <S.BodyTable>
            {getOrdenad()?.map((item, key) => (
              <S.LineTable key={key}>
                <div className="left">
                  <div className="boxImage">
                    {item?.foto && <img src={item.foto} />}
                    {!item?.foto && (
                      <div className="letter">
                        {item?.nome && item?.nome[0]?.toUpperCase()}
                      </div>
                    )}
                  </div>
                  <p>{textFormater(item?.nome)}</p>
                </div>
                <p className="prh">{textFormater(item?.funcao, 10)}</p>
                <p className="prh">{textFormater(item?.filial?.nome, 15)}</p>
                <p
                  className="prh center pointer"
                  onClick={() => {
                    setOpenModal(true);
                    setDataEdit(item);
                  }}
                >
                  <EditOutlinedIcon htmlColor={colors.greenMain} />
                </p>
              </S.LineTable>
            ))}
          </S.BodyTable>
        </S.SampleTable>
      ) : (
        <C.Empty>
          <img className="image" src={emptyImage} />
          <div className="title">Adicione seus usuários</div>
          <div className="subtitle">Adicione os usuários da sua empresa</div>
        </C.Empty>
      )}

      {openModal && (
        <ModalUsuario
          open={openModal}
          handleClose={() => setOpenModal(false)}
          dataEdit={dataEdit}
          setNewData={setNewData}
          setDataEdit={setDataEdit}
          activedFilials={activedFilials || []}
        />
      )}
    </S.Container>
  );
};
