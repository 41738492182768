import api from 'services/api';

const requestInfractions = async query => {
  const res = await api.get('/telematics_desvios', { params: query });
  return res;
};

const fetchInfraction = async (id, query) => {
  const res = await api.get(`/telematics_desvios/${id}`, {
    params: query,
  });
  if (res.data.success) return res.data?.data;
  throw res.data.message;
};

const fetchSequence = async (id, query) => {
  let res = await api.get(`/telematics_desvios/paginator/${id}`, {
    params: query,
  });
  if (res.data.success) return res.data;
  throw res.data.message;
};

const requestHistory = async id => {
  const res = await api.get(`/telematics_desvios/${id}/logs`);
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel/telematics_infractions_drivers', {
    params: query,
  });
  return res;
};

const saveInfraction = async (id, data) => {
  const res = await api.put(`/telematics_desvios/${id}`, data);
  return res;
};

const deleteInfractions = async data => {
  const res = await api.put(`/telematics_desvios/remover`, data);
  return res;
};

const approveInfractions = async data => {
  const res = await api.put(`/telematics_desvios/aprovar`, data);
  return res;
};

const responsavelInfractions = async data => {
  const res = await api.put(`/telematics_desvios/responsavel`, data);
  return res;
};

const finishInfractions = async data => {
  const res = await api.put(`/telematics_desvios/finalizar`, data);
  return res;
};

const sendPadInfractions = async data => {
  const res = await api.post(`/telematics_desvios/pad`, data);
  return res;
};

export {
  requestInfractions,
  fetchInfraction,
  fetchSequence,
  requestExcel,
  requestHistory,
  saveInfraction,
  deleteInfractions,
  approveInfractions,
  responsavelInfractions,
  finishInfractions,
  sendPadInfractions,
};
