import React, { useState } from 'react';
import { Modal, Button, SvgIcon, IconButton, Icon } from '@mui/material';
import GhostButton from 'components/Buttons/Ghost';
import { useTheme } from 'styled-components';
import { ReactComponent as WarningIco } from 'images/icons/warning.svg';
import TextField from 'components/Inputs/TextField';
import * as S from './styled';

const JustifyModal = ({
  handleClose,
  open,
  title,
  titleIcon,
  subtitle,
  buttonText,
  onClick,
  loading,
  isNegative,
  value,
  onChange,
}) => {
  const theme = useTheme();
  const body = (
    <S.Paper>
      <S.Header>
        <S.Title>
          {titleIcon}
          <h1>{title}</h1>
        </S.Title>

        <IconButton size="small" onClick={handleClose}>
          <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
            close
          </Icon>
        </IconButton>
      </S.Header>

      <S.Main>
        {subtitle && (
          <S.StyledToast>
            <WarningIco
              strokeWidth="1.5"
              stroke={theme.palette.semantics.feedback.warning.natural}
              fill="none"
              fontSize="medium"
            />
            <h1>{subtitle}</h1>
          </S.StyledToast>
        )}
      </S.Main>

      <TextField
        label="Justificativa"
        value={value}
        onChange={e => onChange(e.target.value)}
        name="jusitfy"
        style={{ width: '100%' }}
      />

      <S.Footer>
        <GhostButton size="medium" variant="contained" onClick={handleClose}>
          Cancelar
        </GhostButton>

        <Button
          disabled={loading}
          onClick={onClick}
          size="medium"
          variant="contained"
          style={{
            color: isNegative
              ? theme.palette.semantics.feedback.attention.natural
              : theme.palette.words.button.contrast,
            background: isNegative
              ? theme.palette.system.highlight
              : theme.palette.brand.secondary.natural,
            marginLeft: '10px',
            border: isNegative
              ? `1px solid ${theme.palette.semantics.feedback.attention.natural}`
              : '',
            font: 'normal normal 900 14px/18px Texta',
            height: '40px',
          }}
        >
          {buttonText}
        </Button>
      </S.Footer>
    </S.Paper>
  );

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
};

export default JustifyModal;
