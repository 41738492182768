import React from 'react';
import { Container, BarContainer, Progress } from './styled';

export const ProgressBar = ({ value, color, label }) => {
  return (
    <Container>
      {label && <span>{label}</span>}
      <BarContainer color={color}>
        <Progress color={color} width={`${value ?? 0.5}%`} />
      </BarContainer>
    </Container>
  );
};
