import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { usePlans } from 'hooks/usePlans';
import { subDays } from 'date-fns';
import Tab from 'components/FiltersGlobal/Tabs';
import Graph from 'components/Graphs/ComposedChart';
import { getRadarDesvios } from '../../services';
import FiltersGlobal from 'components/FiltersGlobal';

// components @mui
import { SvgIcon } from '@mui/material';

// icons svg
import { ReactComponent as filterIco } from 'images/icons/filter.svg';

// ------------------------------- Constantes
const valuesRadar = [
  { value: 'porPontos', label: 'Pontos' },
  { value: 'porQuantidade', label: 'Quantidade' },
];

const RadarDesvios = ({ empresas = [], clientes = [], filiais = [] }) => {
  const filtersGraficoParams = [
    {
      filterName: 'filial',
      label: 'Filial',
      options: filiais || [],
    },
    {
      filterName: 'client',
      label: 'Cliente',
      options: clientes.data || [],
    },
  ];

  const filtersGraficoParamsProvider = [
    {
      filterName: 'empresa',
      label: 'Empresa',
      options: empresas || [],
    },
    {
      filterName: 'criticidade',
      label: 'Criticidade',
      options: [
        { id: 'PRIMÁRIA', value: 'PRIMÁRIA' },
        { id: 'MODERADA', value: 'MODERADA' },
        { id: 'GRAVE', value: 'GRAVE' },
        { id: 'GRAVÍSSIMA', value: 'GRAVÍSSIMA' },
      ],
    },
  ];
  const theme = useTheme();
  const { isProvider } = usePlans();
  const [tab, setTab] = useState('porPontos');

  const [filtersPageRadar, setFiltersPageRadar] = useState({
    initialDate: subDays(new Date(), 30),
    finalDate: new Date(),
  });

  filtersGraficoParams[0].data = filiais;
  filtersGraficoParams[1].data = clientes;
  filtersGraficoParamsProvider[0].data = empresas;

  const { data: resData, isFetching } = useQuery(
    ['radar-desvios', filtersPageRadar],
    () => getRadarDesvios(filtersPageRadar),
    {
      onError: error => toast.error(error),
      refetchOnWindowFocus: false,
    },
  );

  const rightYAxisMax = datamax => {
    return datamax > 100
      ? Math.ceil(datamax / 100) * 100
      : datamax > 10
      ? Math.ceil(datamax / 10) * 10
      : 10;
  };

  const tabComponent = (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Tab
          value={tab}
          tabs={valuesRadar}
          handleApplay={value => {
            setTab(value);
          }}
        />
      </div>
    </div>
  );

  const data =
    tab == 'porPontos'
      ? resData?.data?.porPontos
      : resData?.data?.porQuantidade;

  const renderRadarDesviosGraph = () => (
    <Graph
      data={data}
      barSeries={['value']}
      barLabel={tab == 'porPontos' ? ['Pontos'] : ['Quantidade']}
      barColors="default"
      rightYAxisMax={rightYAxisMax}
      lineYAxis
      leftYAxisMax={Math.ceil(parseFloat(100))}
      lineSeries={['percentage']}
      lineLabel={['Percentual do todo']}
      renderLineLabel={false}
      lineColors="status"
      linePostFix="%"
      title="Radar de Desvios"
      subtitle={
        tab == 'porPontos' ? 'Total de pontos' : 'Quantidade de desvios'
      }
      loading={isFetching}
      tooltip
      tabComponent={tabComponent}
      wrapped
      yWidth={90}
    />
  );

  return (
    <>
      <div>
        {/* <DateRangePicker
          onDate={setFilterIntervalRadar}
          dInt={filterIntervalRadar.initialDate}
          dfim={filterIntervalRadar.finalDate}
        />

        <div style={{ marginLeft: '1rem' }}>
          <StyledButton
            textcolor={theme.palette.words.text.natural}
            backgroundcolor="transparent"
            startIcon={<SvgIcon component={filterIco} />}
            onClick={event => setOpenFilterRadar(event.currentTarget)}
            disabled={isFetching}
          >
            Filtros
          </StyledButton>
        </div> */}
        <FiltersGlobal
          hideExportButton
          hideRefleshButton
          handleFilters={v =>
            setFiltersPageRadar({ ...filtersPageRadar, ...v })
          }
          data={
            isProvider ? filtersGraficoParamsProvider : filtersGraficoParams
          }
        />
      </div>

      {renderRadarDesviosGraph()}
    </>
  );
};

export default RadarDesvios;
