import styled, { css } from 'styled-components';

export const Footer = styled.div`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3.75rem;
    background-color: ${theme.palette.system.overlay};
    box-shadow: 0px -3px 6px ${theme.palette.system.divider};
    display: flex;
    align-items: center;
  `}
`;

export const FooterContainer = styled.div`
  width: 90%;
  margin: auto;
  padding-left: 15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FooterActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;
