import React from 'react';

import { Divider, Grid } from '@mui/material';

import * as S from './styled';
import { MaisSeguros } from './MaisSeguros';
import { MenosSeguros } from './MenosSeguros';
import { ListaMotoristas } from './ListaMotoristas';
import { Historico } from './Historico';

export const MotoristasEstatisticas = () => {
  return (
    <S.Container>
      <S.Main>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <MenosSeguros />
          </Grid>
          <Grid item xs={12} md={6}>
            <MaisSeguros />
          </Grid>
          <Grid item xs={12}>
            <Historico />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ListaMotoristas />
          </Grid>
        </Grid>
      </S.Main>
    </S.Container>
  );
};
