import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

import {
  DropdownContainer,
  DropdownContent,
  DropdownItem,
  StyledButton,
} from './styled';
import { Popover } from '@mui/material';

export const DropDown = ({ value, data, disabled, onChange }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleDropdown = event => {
    setAnchorEl(state => (!state ? event.currentTarget : null));
  };
  return (
    <DropdownContainer>
      <StyledButton
        textcolor={theme.palette.words.subtitle.natural}
        backgroundcolor="transparent"
        endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
        height="40px"
        variant="outlined"
        onClick={toggleDropdown}
        disabled={disabled}
      >
        {data.find(item => item.value === value).label ?? ''}
      </StyledButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <DropdownContent>
          {data.map(option => (
            <DropdownItem
              key={option.value}
              onClick={() => {
                onChange(option.value);
                toggleDropdown();
              }}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownContent>
      </Popover>
    </DropdownContainer>
  );
};
