import { Modal, FormControlLabel } from '@mui/material';
import styled, { css } from 'styled-components';
import TextField from 'components/Inputs/TextField';

export const ModalMUI = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.system.overlay};
    border-radius: 5px;
    overflow: hidden;
    padding: 15px;
    width: 600px;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        font-weight: 900;
        color: ${theme.palette.words.title.natural};
        margin-left: 20px;
        font-size: 25px;
      }
    }
  `}
`;

export const Body = styled.div`
  padding-left: 40px;
  padding-right: 40px;

  .justify {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

    h3 {
      text-align: left;
      font: normal normal medium 14px/20px Texta;
      letter-spacing: 0px;
      opacity: 1;
    }

    span {
      font-size: 20px;
      font-weight: 500;
      color: red;
    }
  }
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  width: 500px;

  .form {
    margin-bottom: 20px;
    width: 200px;
  }

  .text {
    margin-top: -40px;
    width: 100%;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;
