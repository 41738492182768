import api from 'services/api';

export const requestDriversRanking = async query => {
  const res = await api.get(`/desvios/ranking`, { params: query });
  return res;
};

export const requestDriversResumo = async query => {
  const res = await api.get(`/desvios/ranking/resumo`, { params: query });
  return res;
};

export const requestExcel = async (query, isProvider = false) => {
  const res = await api.get(
    `/excel${isProvider ? '-provider' : ''}/motoristas_ranking`,
    { params: query },
  );
  return res.data || res;
};
