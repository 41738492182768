import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  padding: 10px 0px 15px 5px;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.words.title.natural};
  letter-spacing: 0px;
  font-weight: 800;
`;

export const Paragraph = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: gray;
  letter-spacing: 0px;
  padding: 15px 0px 0 5px;
`;

export const Main = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
`;

export const RightHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: ${({ theme }) => theme.palette.brand.primary.background};
`;

export const RightFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled(Button)`
  min-width: 15px !important;
  max-width: 15px !important;
  padding: 0px !important;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  :hover {
    transform: scale(1.1);
  }

  .MuiButton-startIcon {
    padding: 0px !important;
    margin: 0px !important;
  }
`;

export const Paper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 540px;
    background-color: ${theme.palette.system.overlay};
    border: 0px 5px 20px ${theme.palette.system.divider};
    border-radius: 8px;
    padding: 25px 25px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const StyledToast = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid ${theme.palette.semantics.feedback.warning.natural};
    border-radius: 4px;
    background: ${theme.palette.semantics.feedback.warning.light} 0% 0%
      no-repeat padding-box;
    height: 50px;
    padding: 0 10px;
    margin: 15px 0 10px;
    h1 {
      width: 100%;
      color: ${theme.palette.brand.primary.natural};
      font-size: 14px;
      margin-left: 15px;
      font-weight: 500;
    }
  `}
`;
