import styled, { css } from 'styled-components';
import colors from 'styles/colors';

// -----------------------------------GENERAL------------------------------//
export const Wrapper = styled.div`
  margin: 20px 50px;
  padding-top: ${props => (props.margin ? '250px' : '0px')};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-weight: bold;
  }

  div {
    text-align: right;
  }

  img {
    width: 150px;
  }
`;

export const TitleDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Title = styled.div`
  ${({ theme }) => css`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${theme.palette.words.text.natural};
  margin-bottom: 30px;
  font-weight: 900;
  text-align: center;
  font-size: 34px
  margin-left: 5px;

  img{
    margin-right: 5px;
  }
`}
`;

export const Line = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    text-transform: uppercase;

    h2 {
      color: ${theme.palette.words.subtitle.natural};
      text-align: left;
      font-size: 12px;
    }

    h3 {
      font-weight: bold;
      text-align: left;
      font-size: 10px;
      margin-left: 10px;
    }
  `}
`;

export const Logo = styled.img`
  width: 100px;
  height: 100px;
`;

export const Page = styled.div`
  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: flex;
      page-break-before: always;
      /* align-items: center; */
    }

    .page-break-inside {
      page-break-inside: auto;
    }
  }

  @page {
    size: auto;
  }
`;

// -----------------------------------HEADER------------------------------//
export const InfoHeader = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.system.shadow};
  padding: 10px 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// -----------------------------------CARDS------------------------------//
export const Card = styled.div`
  margin-top: 15px;
  border: 1px solid
    ${({ theme }) => theme.palette.semantics.feedback.unknown.natural};
  padding-top: ${props => (props.margin ? '150px' : '0px')};
`;

export const CardHeader = styled.div`
  background: ${({ theme }) => theme.palette.brand.primary.dark};
  padding: 5px 10px;

  h1 {
    color: ${({ theme }) => theme.palette.words.title.contrast};
    font-size: 16px;
  }
`;

export const CardContent = styled.div`
  background: ${({ theme }) => theme.palette.semantics.feedback.unknown.light};
  padding: 5px 10px;

  img {
    width: 100%;
  }

  &.aws {
    padding: 0px;
  }
`;

export const CardCol = styled.div`
  display: flex;
`;

export const CardContentCol = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.system.divider};
  display: flex;
  min-height: 45px;
  padding: 5px;
  align-items: center;
  flex: 2 !important;

  h4 {
    font-weight: 300;
  }

  h5 {
  }
`;

export const CardItem = styled.div`
  display: flex;
  justify-content: space-around;
  height: 40px;
  align-items: center;
  p {
    font-size: 10px;
    width: 30%;
  }
`;
// -----------------------------------SIGNATURE------------------------------//
export const Assinatura = styled.div`
  hr {
    margin: 20px 0;
  }

  h4 {
    color: ${({ theme }) => theme.palette.words.caption.ligth};
  }
`;

export const AsignTitle = styled.span`
  font-size: 16px;
  text-transform: capitalize;
`;

export const AsignDate = styled.span`
  font-size: 14px;
  text-transform: capitalize;
  margin-left: 10px;
`;
export const NoContent = styled.span`
  height: 42px;
  font-size: 16px;
`;
// -----------------------------------FORM------------------------------//
export const AvaliacaoLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px;
  width: 100%;
  // margin: 5px 0;
  background-color: ${({ theme, ...props }) =>
    props.value
      ? theme.palette.system.overlay
      : theme.palette.semantics.feedback.unknown.light};
`;

export const AvaliacaoPergunta = styled.div`
  width: 100%;

  p {
    width: 100%;
  }
`;

export const Check = styled.div`
  display: flex;
`;

export const Sim = styled.span`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props =>
      props.value
        ? theme.palette.words.label.contrast
        : theme.palette.words.text.light};
    border: 2px solid
      ${props =>
        props.value
          ? theme.palette.semantics.feedback.success.natural
          : theme.palette.brand.primary.light};
    background-color: ${props =>
      props.value
        ? theme.palette.semantics.feedback.success.natural
        : theme.palette.system.transparent};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: 500;
    border-right: none;
    padding: 5px 8px;
  `}
`;

export const Nao = styled.span`
  ${({ theme, ...props }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: ${props.value
      ? theme.palette.words.label.contrast
      : theme.palette.words.text.light};
    border: 2px solid
      ${props.value
        ? theme.palette.semantics.feedback.attention.natural
        : theme.palette.brand.primary.light};
    background-color: ${props.value
      ? theme.palette.semantics.feedback.attention.natural
      : theme.palette.system.transparent};
    padding: 5px 8px;
  `}
`;

export const Null = styled.span`
  ${({ theme, ...props }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: ${props.value
      ? theme.palette.words.label.contrast
      : theme.palette.words.text.light};
    border: 2px solid
      ${props.value
        ? theme.palette.semantics.feedback.information.natural
        : theme.palette.brand.primary.light};
    background-color: ${props.value
      ? theme.palette.semantics.feedback.information.natural
      : theme.palette.system.transparent};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
    padding: 5px 8px;
  `}
`;

// -----------------------------------IMAGES------------------------------//

export const ImagensWrapper = styled.div`
  width: 100%;
`;

export const ImagensHeader = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 3px 10px;
    background: ${theme.palette.brand.primary.dark};
    color: ${theme.palette.system.overlay};
  `}
`;
