import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: left;
  height: 100%;
  max-height: 430px;
  background: ${({ theme }) => theme.palette.system.white};
  padding: 1rem;
  border: 1px solid ${({ theme }) => `${theme.palette.brand.primary.light}4d`};
  border-radius: 4px;
  cursor: pointer;
`;

export const Title = styled.div`
  font: normal normal 900 20px/24px Texta;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.palette.words.subtitle.natural};
  opacity: 1;
  margin-bottom: 1rem;

  span {
    font: normal normal 600 16px/24px Texta;
    color: rgb(246, 78, 96);
  }
`;

export const ListContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export const ListItem = styled.div`
  ${({ theme }) => css`
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.brand.primary.light}4d;
    font: normal normal medium 16px/30px Texta;
    color: ${theme.palette.words.title.natural};
  `}
`;
