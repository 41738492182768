export const formatTaskColor = (status, theme) => {
  switch (status) {
    case 'PENDENTE': {
      return theme?.palette?.brand?.secondary?.natural;
    }
    case 'VENCIDO': {
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    }
    case 'CONCLUIDO': {
      return theme?.palette?.semantics?.feedback?.unknown?.light;
    }
  }
};
