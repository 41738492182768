/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useState, useEffect } from 'react';

// Components
import Form from 'components/Form';
import { ModalCae } from './ModalCae';

// Services
import { answerOne } from '../services';
import { toast } from 'react-toastify';

export const Formulario = ({
  posto,
  setPosto,
  isEdit,
  steps,
  answers,
  setAnswers,
  setCurrentStep,
  setTrySave,
  setChanged,
  cliente,
}) => {
  const [open, setOpen] = useState(null);
  const [itemAwait, setItemAwait] = useState(null);

  useEffect(() => {
    setChanged(false);
  }, [posto]);

  // ----------------FORMATERS----------------//
  const questions = posto?.respostas?.map(item => ({
    ...item?.questao,
  }));

  const sections = [
    {
      id: 1,
      title: 'Perguntas',
      questoes: questions,
    },
  ];

  const desvios = posto?.cae
    ?.filter(item => item?.status !== 'FINALIZADO')
    .map(item => ({
      ...item,
      pergunta: posto?.respostas?.find(
        pergunta => pergunta?.questao?.id === item?.questao?.id,
      )?.questao?.pergunta,
    }));

  const infoLines = posto?.respostas
    ?.filter(item => item?.questao?.cae)
    .map(item => item?.questao?.id);

  // -----------------ACTIONS-----------------//
  const handleYes = async (item, res, old) => {
    if (res !== old?.resposta) {
      if (old.resposta === 'NAO' && old?.questao?.cae) {
        toast.error('É necessário aprovar a CAE para alterar a resposta');
      } else {
        const resp = await answerOne({
          id: old?.id,
          resposta: res,
        });
        return resp;
      }
    }
  };

  const handleNo = async (item, res, old) => {
    if (res !== old?.resposta) {
      if (old?.questao?.cae) {
        setOpen(old?.questao);
        setItemAwait(item);
      } else {
        const resp = await answerOne({
          id: old?.id,
          resposta: res,
        });
        if (resp.success) {
          setAnswers(
            answers?.map(a =>
              a.id_da_questao === item ? { ...a, resposta: res } : a,
            ),
          );
        }
        return resp;
      }
    }
  };

  // -----------------RENDER-----------------//
  return (
    <S.Container>
      <Form
        sections={sections || []}
        answers={answers || []}
        desvios={desvios || []}
        cae
        noNA
        info="Ao responder essa pergunta como não, será aberta uma CAE"
        infoLines={infoLines}
        clickOutlier={() => setCurrentStep(steps[5])}
        onClickRow={handleYes}
        ifNo={handleNo}
        setAnswers={setAnswers}
        isCreate={!isEdit}
      />
      {!!open && (
        <ModalCae
          open={!!open}
          posto={posto}
          questao={open}
          setOpen={setOpen}
          setAnswers={setAnswers}
          setPosto={setPosto}
          item={itemAwait}
          setItem={setItemAwait}
          answers={answers}
          cliente={cliente}
        />
      )}
    </S.Container>
  );
};
