/* eslint-disable import/order */

import * as S from './styled';
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { formatNewHour } from 'utils/dates';
import BarGraph from 'components/Graphs/BarGraph';
import PieGraph from 'components/Graphs/PieGraph';

export const ExportGeralPdf = ({
  cont,
  printRef,
  aderencia = [],
  reprovacoes = [],
}) => {
  const logo = require('images/onisys.png');
  const capa =
    'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/capacitacao%2Finfo%2Fcapas%2F1673467756358%2F2023-01-11_17-09-19_Capa_Conteudo_Capacitacao.jpg?alt=media&token=3ca2cd3b-f1c8-438a-a277-88580f7cbdbd';
  const { id } = useSelector(state => state.conteudoProvider);

  return (
    <S.ModalMUI open onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="logoArea">
            <img src={logo} />
          </div>
          <div className="formIdent">
            <span>
              Contéudo - ID {id} {formatNewHour(new Date())}
            </span>
          </div>
        </S.Infos>
        <S.Header bg={cont?.capa || capa}>
          <div className="topInfos">
            <div className="coverArea">
              <div className="background" />
            </div>
            <div className="textsArea">
              <div className="titleForm">{cont?.titulo}</div>
              <div className="descriptionForm">{cont?.descricao}</div>
              <div className="initial">
                <span>Data Início:</span>
                {formatNewHour(cont?.data_inicio)}
              </div>
              <div className="final">
                <span>Data Fim:</span>
                {cont?.data_fim
                  ? formatNewHour(cont?.data_fim)
                  : 'Nunca encerrar'}
              </div>
            </div>
          </div>
          <Divider />
        </S.Header>
        <S.MediumBox className="bar">
          <BarGraph
            data={aderencia}
            colors="default"
            title="Aderência do Conteúdo"
            tooltip
            maxWidth="700px"
          />
          <span className="bigGap">
            <PieGraph
              data={aderencia}
              colors="critical-first"
              title="Aderência do Conteúdo"
              tooltip
              legend
            />
            <PieGraph
              data={reprovacoes}
              colors="critical-first"
              title="Reprovacões"
              tooltip
              legend
            />
          </span>
        </S.MediumBox>
      </S.Container>
    </S.ModalMUI>
  );
};
