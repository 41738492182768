import Button from 'components/Buttons/Default';
import { Content, Container } from './styled';

const Help = props => {
  return (
    <Container>
      <Content>
        <h1>Ainda tem dúvidas? Fale com um consultor agora</h1>
        <br />
        <br />
        {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
          dictum justo et ipsum rutrum, ac
        </p> */}
        <Button href="#">FALAR COM UM CONSULTOR AGORA</Button>
      </Content>
    </Container>
  );
};

export default Help;
