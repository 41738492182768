import { SvgIcon } from '@mui/material';
import { useTheme } from 'styled-components';
import * as S from './styled';
import { itensBar } from './constants';

export const Sidebar = ({ page, setPage, setTitle, setChanged }) => {
  const theme = useTheme();
  const handlePage = i => {
    if (i.id !== page) {
      setPage(i.id);
      setTitle(i.page);
      setChanged(false);
    }
  };

  return (
    <S.Container>
      {itensBar.map((i, k) => (
        <span
          onClick={() => handlePage(i)}
          className={page === i.id ? 'navBarItem active' : 'navBarItem'}
          key={k}
        >
          <SvgIcon
            component={i.icon}
            stroke={
              page === i.id
                ? theme.palette.brand.secondary.natural
                : theme.palette.brand.primary.light
            }
          />
          <span>{i.page}</span>
        </span>
      ))}
    </S.Container>
  );
};
