import styled, { css } from 'styled-components';
import bg from 'images/logobg.svg';

export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding-top: 6rem;
  overflow-x: hidden;
  background: 'transparent radial-gradient(closest-side at 57% 26%, #606779 0%, #5B6275 12%, #373F54 100%) 0% 0% no-repeat padding-box;';
  background-image: url(${bg});
  background-size: cover;
`;

export const Content = styled.div`
  width: 100vw;
  max-width: 1200px;
  margin: 3rem auto;
  padding: 0 1rem;
  display: flex;
  gap: 5rem;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 900px) {
    flex-direction: row;
    align-items: start;
  }
`;

export const PlanDetails = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 80vw;

    h1 {
      font: normal normal 900 38px/45px Texta;
      color: ${theme.palette.words.title.contrast};
    }

    span {
      color: ${theme.palette.brand.secondary.natural};
    }

    p {
      padding: 1.5rem 0;
      font: normal normal 600 18px/25px Texta;
      letter-spacing: 0px;
      color: ${theme.palette.words.subtitle.contrast};
    }
  `}
`;

export const Features = styled.div`
  ${({ theme }) => css`
    margin-top: 1rem;
    padding-top: 1rem;
    h3 {
      font: normal normal 900 13px/21px Texta;
      letter-spacing: 0px;
      text-transform: uppercase;
      color: ${theme.palette.words.title.contrast};
    }
    ul {
      li {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font: normal normal 600 16px/28px Texta;
        color: ${theme.palette.words.subtitle.contrast};
      }
    }
  `}
`;

export const Thumbnail = styled.img`
  width: 100%;
  margin-top: 3rem;
  max-width: 80vw;
  border-radius: 10px;
`;

export const PartnerList = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 2rem 0;
    border-top: 1px solid ${theme.palette.system.divider};

    p {
      text-align: center;
      font: normal normal medium 18px/25px Texta;
      letter-spacing: 0px;
      color: ${theme.palette.words.subtitle.contrast};
      margin-bottom: 2rem;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem;

      li {
        margin: auto;

        img {
          max-width: 200px;
          filter: grayscale(100%) invert(100%) contrast(40%) brightness(150%);
          opacity: 60%;

          @media only screen and (min-width: 640px) {
            max-width: 160px;
          }
        }
      }
    }
  `}
`;
