// Styled
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DefaultTable } from 'components/_Table/templates/default';

// Components
import Search from 'pages/Fomularios/components/Search';

// Components MUI
import { SaveAlt } from '@mui/icons-material';
import { Divider, Popover } from '@mui/material';
import GhostButton from 'components/Buttons/Ghost';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TableLocal from 'components/TableLocal';

// Utils
import ExportToExcel from 'utils/exportToCvs';
import * as S from './styled';
import { trackEvent } from 'utils/mixpanel';

// Services
import * as services from '../../services';
import { ExportAlunosPdf } from './Export';

// Columns
import { columnsSmall } from './columns';

const howToColor = status => {
  switch (status) {
    case 'APROVADO':
      return colors.greenSucces;
    case 'REPROVADO':
      return colors.redDanger;
    case 'INCOMPLETO':
      return colors.orangeAlert;
  }
};

export const ModalAlunos = ({ data, open, onClose, currentPeriod, idForm }) => {
  const user = useSelector(state => state.auth?.user?.user);

  const alunos = data?.alunosTable;
  const printAlunosRef = useRef();

  // Redux
  const { id, geral } = useSelector(state => state.conteudoProvider);

  // General States
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredItems, setFilteresItems] = useState(data || []);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loading, setLoading] = useState(false);

  // Empty Image
  const img = require('images/empty/frota.png');

  // Set filtered data
  useEffect(() => {
    if (search) {
      let newArr = alunos.filter(item =>
        item.nome.toLowerCase().includes(search.toLowerCase()),
      );
      setFilteresItems(newArr);
    } else {
      setFilteresItems(alunos);
    }
  }, [search, data]);
  // -------------------------------------------- FORMAT TABLE -----------------------------------------------//
  const formatTable = () => {
    const col = columnsSmall.map(i => {
      if (i.id === 'status') {
        return {
          children: (value, item) => (
            <S.ColDriver>
              <S.Paragraph
                className="normal"
                center
                color={howToColor(item?.status)}
              >
                {item.status}
              </S.Paragraph>
              <S.TableSubname>{item?.data_conclusao || ''}</S.TableSubname>
            </S.ColDriver>
          ),
        };
      }
      return i;
    });
    return col;
  };

  // -------------------------------------------- EXPORTS -----------------------------------------------//
  const exportExcel = async () => {
    setLoadingExport(true);

    const exportArr = [];
    alunos.forEach(item => {
      exportArr.push({
        Nome: item.nome,
        Tipo: item.tipo,
        Filial: item.filial,
        Nota: item.nota,
        Tentativas: item.tentativas,
        Id_Certificado: item.certificado || '',
      });
    });

    ExportToExcel({
      excel: exportArr,
      name: `conteudo_id_${id}_${data?.nome} {new Date()}`,
    });
    setLoadingExport(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printAlunosRef.current,
    onBeforeGetContent: () => setLoadingExport(true),
    onAfterPrint: () => setLoadingExport(false),
    copyStyles: true,
    documentTitle: `conteudo_id_${id}_${data?.nome} {new Date()}`,
  });

  const getCertificate = async item => {
    const aluno = alunos.find(data => +data.id === +item);
    if (!aluno.certificado) return toast.error('Aluno não possui certificado');
    let res = await services.getCertificate(aluno.certificado, {
      nota: aluno.nota,
      contentId: id,
    });
    if (res.success) {
      const link = res.data.link;

      const req = new XMLHttpRequest();
      req.open('GET', link, true);
      req.responseType = 'blob';

      let typeArchive = 'application/pdf';
      let mimiType = '.pdf';
      req.onload = async function () {
        const blob = new Blob([req.response], { type: typeArchive });
        const url = window.URL || window.webkitURL;
        const link = url.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('download', `certificado_${aluno.nome}${mimiType}`);
        a.setAttribute('href', link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      req.send();
    } else {
      toast.error('Ocorreu um erro ao gerar o certificado!');
    }
  };

  const actions = [{ title: 'Baixar certificado', function: getCertificate }];

  return (
    <>
      <S.ModalMUI open={open}>
        <S.Content>
          <S.Header>
            <div className="left">
              <div className="imageBox">
                <img src={data?.logo_url} />
              </div>
              <span className="title">{data?.nome}</span>
            </div>
            <div className="right">
              <CloseOutlinedIcon
                htmlColor={colors.greyTiny}
                onClick={onClose}
              />
            </div>
          </S.Header>
          <Divider />
          <S.Body>
            <S.TableArea>
              <div className="converInput" />
              <div className="buttonArea">
                <GhostButton
                  children="Exportar"
                  icon={<SaveAlt />}
                  onClick={event => setAnchorEl(event.currentTarget)}
                  loading={loadingExport}
                />
                <Popover
                  sx={{ transform: 'translate(0px, 2px)' }}
                  id={anchorEl ? 'popover' : undefined}
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  onClick={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <S.Popup>
                    <button onClick={() => exportExcel()}>Excel</button>
                    <button onClick={() => handlePrint()}>PDF</button>
                  </S.Popup>
                </Popover>
              </div>
            </S.TableArea>
            <DefaultTable
              data={alunos || []}
              columns={formatTable()}
              searchKeys={['nome']}
              loading={loading}
              placeholder="Buscar Aluno"
              sortBy={{ id: 'nome', order: 'DESC' }}
              actions={actions}
              searchEvent={search =>
                trackEvent(user, 'Busca alunos reports', null, search)
              }
              empty={{
                title: 'Seus alunos aparecerão aqui!',
                description: ``,
              }}
            />
          </S.Body>
        </S.Content>
      </S.ModalMUI>

      <ExportAlunosPdf
        cont={geral}
        data={alunos}
        printRef={printAlunosRef}
        empresa={data?.nome}
      />
    </>
  );
};
