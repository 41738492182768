import { Modal } from '@mui/material';
import styled from 'styled-components';
import colors from 'styles/colors';

export const ModalMUI = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

export const ProgressArea = styled.div`
  position: absolute;
  width: 100%;
  background-color: ${colors.white};
  margin-top: ${props => (props.transition ? '-50px' : '0px')};
  transition: all 0.3s ease-in-out;

  .line {
    width: ${props => props.progress}%;
    height: 9px;
    background-color: ${colors.greenMain};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: all 0.5s ease-in-out;
  }
`;

export const MediaArea = styled.div`
  background-color: ${colors.white};
  height: 100%;
  flex: 1.5;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: ${props => (props.transition ? '-200%' : '0px')};
  transition: all 0.35s ease;
`;

export const TitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .left {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
    }

    .product {
      font-size: 20px;
      font-weight: 900;
      margin-left: 7px;
      color: ${colors.greyTitle};
      font-style: italic;
    }
  }

  .right {
    position: absolute;
    right: 0;
    margin-right: 20px;

    .MuiSvgIcon-root {
      font-size: 25px;
      cursor: pointer;
      color: ${colors.greyTitle};

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const MediaContainer = styled.div`
  width: 100%;
  max-width: 700px;
  height: calc(100% - 60px);
  padding: 50px 40px;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${props => (props.transition ? '-200%' : '0px')};
  transition: all 0.25s ease-in-out;
  /* background-color: red; */

  .headerLine {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    width: 100%;

    .title {
      font-size: 25px;
      color: ${colors.greyTitle};
      font-weight: 900;
      margin-right: 10px;
    }
  }

  .videoArea {
    width: 100%;
    padding: 2px;
    background-color: ${colors.white};
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .description {
    font-size: 16px;
    color: ${colors.grey};
    margin-top: 20px;
    font-weight: 600;
    line-height: 1.5;
    width: 100%;
    text-align: start;
  }
`;

export const ActionsArea = styled.div`
  background-color: ${colors.whiteBackground};
  height: 100%;
  flex: 2;
  max-width: 66.6%;
  padding: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  overflow-y: scroll;
  margin-left: ${props => (props.transition ? '300%' : '0px')};
  transition: all 0.4s ease;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  transition: all 0.25s ease-in-out;
  padding: 100px 40px;
  margin-left: ${props => (props.transition ? '250%' : '0px')};
`;

export const FooterArea = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: ${colors.white};
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  margin-bottom: ${props => (props.transition ? '-70px' : '0px')};
  transition: all 0.15s ease-in-out;

  .left {
  }

  .right {
    .lineButton {
      background-color: transparent;
      border: none;
      margin-right: 25px;
      font-size: 16px;
      font-weight: 600;
      color: ${colors.greySubtitle};
      text-decoration: underline;
    }
  }
`;

export const SupportArea = styled.div`
  position: absolute;
  bottom: 0;
  width: 50px;
  height: 50px;
  background-color: ${colors.white};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 30px;
  bottom: 80px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.1);

    .MuiSvgIcon-root {
      color: ${colors.greySubtitle};
    }
  }

  .MuiSvgIcon-root {
    color: ${colors.grey};
  }
`;

export const HeaderStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .title {
      font-size: 25px;
      color: ${colors.greyTitle};
      font-weight: 900;
      margin-right: 10px;
    }

    .iconTooltip {
      color: ${colors.greyTiny};
      cursor: pointer;
    }
  }
`;

export const BoxLoading = styled.div`
  width: 100%;
  height: calc(100vh - 260px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-height: 350px;
  background-color: ${colors.white};
  border: 1px solid ${colors.greyBorder};
  border-radius: 5px;
  padding: 20px;

  .image {
    width: 210px;
    height: 210px;
  }
  .title {
    font-size: 25px;
    color: ${colors.greyTitle};
    font-weight: 900;
  }
  .subtitle {
    font-size: 16px;
    color: ${colors.greySubtitle};
    font-weight: 600;
  }
`;
