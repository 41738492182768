import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';

// Componentes Reutilizaveis
import Table from 'components/Table';
import CardIndicadores from 'components/Cards/Indicador';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';

// Componentes do material
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AddCircleOutline } from '@mui/icons-material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Serviços
import { toast } from 'react-toastify';
import { formatNewDate } from 'utils/dates';
import { useTheme } from 'styled-components';
import { columns } from './columns';
import * as request from './services';
import * as S from './styled';
import { statusColor } from './actions';

const Vagas = () => {
  const theme = useTheme();
  // Navigate
  const navigate = useNavigate();

  // Redux
  const filter = useSelector(state => state.filter);
  const filterVagas = useSelector(state => state.filterVagas);

  // General States
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({});
  const [updated, setUpdated] = useState(false);
  const [countEvaluations, setCountEvaluations] = useState(null);
  const [loadingEvaluations, setLoadingEvaluations] = useState(false);

  // -------------------------TABS (TOP PAGE)---------------------------------------//

  const formatColumns = arr => {
    arr.forEach(i => {
      if (i.identifier === 'id') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="bold">{row.original.id}</S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'titulo') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="bold">{row.original.titulo}</S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'responsavel') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="bold">
                {row.original.responsavel?.nome}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'cidade') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="bold">
                {row.original.cidade ? row.original.cidade : '-'}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'candidatos') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="bold data">
                {row.original.n_curriculos}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'expira') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="bold data">
                {row.original.expira_em
                  ? formatNewDate(row.original.expira_em)
                  : '-'}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'status') {
        i.Cell = function ({ row }) {
          const formt = statusColor(row.original.status);
          return (
            <S.ColDriver>
              <S.Status color={formt.color} bg={formt.background}>
                {row.original.status?.toLowerCase()}
              </S.Status>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'acoes') {
        i.Cell = function ({ row }) {
          return (
            <S.IconContainer
              id={row.original.id}
              key={row.original.id}
              onClick={handleOpenDistributorMenu}
            >
              <MoreHorizIcon cursor="pointer" fontSize="large" />
            </S.IconContainer>
          );
        };
      }
    });
    return arr;
  };

  // -------------------------CARDS (TOP PAGE)---------------------------------------//

  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCards] = useState(null);
  const [loadingCards, setLoadingCards] = useState(true);

  const handleClickCard = type => {
    if (type === selectedCard) setSelectedCards(null);
    else setSelectedCards(type);
  };

  const renderCards = () => {
    return (
      <Grid item>
        <Grid container spacing={2}>
          {cards.map((item, idx) => {
            let md = 4;
            if (12 / cards.length < 4) md = 12 / cards.length;
            return (
              <Grid item key={idx} md={md} xs={12} sm={6}>
                <CardIndicadores
                  value={
                    item.type !== 'CURRICULOS' ? item.ids?.length : item.ids
                  }
                  icon="Grupo12741.svg"
                  text={item.text}
                  border={`1px solid ${theme.palette.brand.primary.light}4D`}
                  handleClick={
                    item.click ? () => handleClickCard(item.type) : null
                  }
                  selected={selectedCard === item.type}
                  loading={loadingCards}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  // --------------------------ACTION COLUMN---------------------------//

  const [anchorDistributor, setAnchorDistributor] = useState(false);

  const handleOpenDistributorMenu = e => {
    setAnchorDistributor(e.currentTarget);
  };

  const renderActionMenu = () => {
    let item = null;
    let currentOptions = [];
    for (const i in data) {
      if (data[i].id === Number(anchorDistributor?.id)) item = data[i];
    }

    const tabsFuncs = {
      tabValue: 0,
      options: ['editar', 'curriculos'],
    };

    currentOptions = tabsFuncs.options;

    return (
      <Menu
        open={!!anchorDistributor}
        anchorEl={anchorDistributor}
        onClose={() => setAnchorDistributor(null)}
        onClick={() => setAnchorDistributor(null)}
      >
        <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
          {currentOptions.includes('editar') && (
            <MenuItem
              key="editar"
              onClick={() =>
                navigate(`/contratacao/vagas/editar/${anchorDistributor.id}`)
              }
            >
              <Grid item md={9}>
                <S.PTable>
                  {item?.status !== 'RASCUNHO' ? 'Editar' : 'Publicar'}
                </S.PTable>
              </Grid>
            </MenuItem>
          )}

          {currentOptions.includes('curriculos') &&
            item?.status &&
            item.status !== 'RASCUNHO' && (
              <MenuItem
                key="curriculos"
                onClick={() =>
                  navigate(`/contratacao/vagas/editar2/${anchorDistributor.id}`)
                }
              >
                <Grid item md={9}>
                  <S.PTable>Ver CurrÍculos</S.PTable>
                </Grid>
              </MenuItem>
            )}
        </Grid>
      </Menu>
    );
  };

  // -------------------------- REQUESTS ------------------------------------------//

  useEffect(async () => {
    // Necessary this verification for dont´t do a duplicade request and reset card values
    if (query.initialDate) {
      await fetchCards({ ...query, card: selectedCard });
    }
  }, [query]);

  const fetchData = useCallback(async query => {
    setLoadingEvaluations(true);
    const res = await request.getAllWorks({ ...query });
    if (res.success) {
      setData(res.data);
      setCountEvaluations(res.count);
      setLoadingEvaluations(false);
      setUpdated(false);
      setLoadingCards(false);
    } else {
      toast.error('Erro ao buscar vagas');
    }
  }, []);

  const fetchCards = async query => {
    setLoadingCards(true);
    const { data } = await request.getCards({ ...query, ...filterVagas });
    setCards(data);
    setLoadingCards(false);
  };

  // Update Indicators
  useEffect(() => {
    setUpdated(true);
  }, [query, selectedCard]);

  useEffect(() => {
    setQuery({ ...filter, ...filterVagas });
  }, [filter, filterVagas]);

  // -----------------------------------------RENDER-----------------------------------------//
  return (
    data && (
      <Grid container>
        <Grid container>
          <Grid
            marginBottom={3}
            item
            md={12}
            display="flex"
            justifyContent="space-between"
          >
            <h1>Vagas</h1>
            <span>
              <DefaultButtonPopover
                startIcon={<AddCircleOutline />}
                size="medium"
                sx={{ marginLeft: '10px', width: '250px' }}
                onClick={() => navigate('/contratacao/vagas/criar')}
              >
                ADICIONAR VAGA
              </DefaultButtonPopover>
            </span>
          </Grid>
        </Grid>
        <Grid marginBottom={3} item md={12}>
          {renderCards()}
        </Grid>
        <Grid item md={12}>
          <Table
            inputLabel="Buscar por Título..."
            data={data}
            columns={formatColumns(columns)}
            pageCount={countEvaluations}
            loading={loadingEvaluations}
            fetchData={fetchData}
            updated={updated}
            query={{
              ...query,
              card: selectedCard,
              ...filterVagas,
            }}
            permitIsSortedOccur
            initialSortBy={[{ id: 'createdAt', desc: false }]}
            empty={{
              title: 'Suas vagas aparecerão aqui!.',
              subtitle:
                'Adicione uma nova vaga ou verifique os filtros aplicados!',
            }}
          />
          {renderActionMenu()}
        </Grid>
      </Grid>
    )
  );
};

export default Vagas;
