import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CancelButton = styled.button`
  ${({ theme }) => css`
    background-color: white;
    color: ${theme.palette.brand.primary.natural};
    border: 1px solid ${theme.palette.brand.primary.natural};
    border-radius: 4px;
    padding: 0px 14px;
    height: 32px;
    cursor: pointer;
    font-family: 'Texta';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: ${theme.palette.brand.primary.natural};

    &:hover {
      background-color: ${theme.palette.brand.primary.background};
    }
  `}
`;

const ApplyButton = styled.button`
  background-color: ${({ theme }) => theme.palette.brand.secondary.natural};
  color: white;
  border: none;
  border-radius: 4px;
  height: 32px;
  padding: 0px 14px;
  margin-left: 4px;
  cursor: pointer;
  font-family: 'Texta';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  &:hover {
    opacity: 0.7;
  }
`;

const DateDisplay = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 32px;
  width: ${props => (props.showHours ? '128px' : '280px')};
  padding: 5px;
  text-align: ${props => (props.showHours ? 'center' : 'left')};
  border: 1px solid rgba(149, 170, 201, 0.5);
  outline: none;
  background-color: #fafafa;
  font-family: 'Texta';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  gap: 10px;
  line-height: 24px;
  color: #4b5166;

  &:focus {
    border-color: ${({ theme }) => theme.palette.brand.primary.natural};
    box-shadow: 0 0 0 2px
      ${({ theme }) => theme.palette.brand.primary.natural}60; /* Modificação da cor do foco */
  }

  &::placeholder {
    color: rgba(75, 81, 102, 0.8); /* Ajuste da cor do placeholder */
  }
`;

const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  transform: translate(0px, -12px);
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .divider-hours {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Texta';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #4b5166;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const TimeInput = styled.input`
  width: 64px;
  height: 32px;
  text-align: center;
  border: 1px solid rgba(149, 170, 201, 0.5);
  border-radius: 4px;
  font-size: 16px;
  background-color: #fafafa;
  color: #4b5166;
  font-family: 'Texta';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  &:focus {
    border-color: ${({ theme }) => theme.palette.brand.primary.natural};
    box-shadow: 0 0 0 2px
      ${({ theme }) => theme.palette.brand.primary.natural}60;
  }
`;

const Label = styled.label`
  font-family: 'Texta';
  font-size: 14px;
  color: #4b5166;
  margin-bottom: 5px;
`;

const StyledButton = styled(Button)`
  && {
    color: ${props => props.textcolor};
    background: ${props => props.backgroundcolor};
    border: 1px solid ${props => props.theme.palette.brand.primary.light};
    font-family: 'Texta';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    height: 32px;
    transition: opacity 300ms;
    box-shadow: none;

    :hover {
      background: ${props => props.backgroundcolor};
      opacity: 0.7;
    }
  }
`;

const CustomDateRangePickerWrapper = styled.div`
  .rdrDayNumber span {
    width: 15px;
    height: 25px;
    font-family: 'Texta';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #4b5166 !important;
    background-color: transparent !important; /* Remove fundo dos dias */
  }
  .rdrWeekDays > .rdrWeekDay {
    font-family: 'Texta';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #4b51666d;
    margin-top: 15px;
  }

  /* Garantir que o fundo do hover seja removido e o texto continue visível */
  .rdrDayHovered,
  .rdrDayHovered .rdrDayNumber,
  .rdrDayHovered .rdrDayNumber span {
    background-color: transparent !important; /* Remove qualquer fundo no hover */
    color: #4b5166 !important; /* Garante que o número fique visível no hover */
    border-radius: 50% !important;
    box-shadow: none !important;
    opacity: 1 !important; /* Garante que o número não desapareça */
  }

  /* Para os dias fora do mês atual */
  .rdrDayPassive .rdrDayNumber span {
    opacity: 0.4 !important;
    color: #4b5166 !important;
    background-color: transparent !important;
  }

  /* Manter as cores do intervalo selecionado */
  .rdrInRange {
    background-color: ${({ theme }) =>
      theme.palette.brand.secondary.natural}40 !important;
  }

  /* Dia inicial do intervalo */
  .rdrDayStartPreview .rdrDayNumber span {
    background-color: ${({ theme }) =>
      theme.palette.brand.secondary.natural}1d !important;
    border-radius: 50%;
    color: #4b5166 !important;
  }

  /* Dia final do intervalo */
  .rdrDayEndPreview .rdrDayNumber span {
    background-color: ${({ theme }) =>
      theme.palette.brand.secondary.natural}1d !important;
    border-radius: 0;
    color: #4b5166 !important;
  }

  /* Para corrigir o estilo do último dia selecionado */
  .rdrDayEndPreview,
  .rdrDayEndPreview .rdrDayNumber span {
    border-radius: 0;
    color: #4b5166 !important;
  }

  /* Muda a color dos dias de destaque */
  .rdrDay:has(.rdrStartEdge) .rdrDayNumber span {
    color: white !important;
  }
  .rdrDay:has(.rdrEndEdge) .rdrDayNumber span {
    color: white !important;
  }
`;

export {
  Main,
  Footer,
  ItemsWrapper,
  DateDisplay,
  StyledButton,
  CancelButton,
  ApplyButton,
  Label,
  TimeInput,
  DateTimeWrapper,
  FieldWrapper,
  CustomDateRangePickerWrapper,
};
