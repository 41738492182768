export const plans = [
  {
    id: 1,
    name: 'Básico',
    description:
      'É o nosso plano de entrada para o transportador que ainda não possui tecnologia de telemetria ou vídeo monitoramento, mas que deseja iniciar sua jornada na busca pelo zero acidente.\nIdeal para o acompanhamento de capacitação digital dos motoristas, criando conteúdos exclusivos ou utilizando os conteúdos de curadoria Onisys.',
    descriptionProvider:
      'É o nosso plano de entrada para o embarcador que ainda não possui tecnologia de telemetria ou vídeo monitoramento, mas que deseja iniciar sua jornada na busca pelo zero acidente.\nIdeal para o acompanhamento de capacitação digital dos motoristas, criando conteúdos exclusivos ou utilizando os conteúdos de curadoria Onisys.',
    features: [
      'curadoria',
      'capacitacao',
      'turmas',
      'testes',
      'certificados',
      'gamificacao',
      'conteudos',
      'pad',
      'checklist',
      'app',
      'rotograma',
      'layout',
    ],
    featuresProvider: [
      'capacitacao',
      'turmas-provider',
      'testes',
      'certificados',
      'gamificacao',
      'conteudos',
      'app',
      'rotograma',
      'layout',
    ],
  },
  {
    id: 2,
    name: 'Telemetria',
    description:
      'É o nosso plano para o transportador que possui tecnologia de telemetria e que está atento aos desvios cometidos pelos seus motoristas.\nIdeal para o monitoramento em tempo real dos comportamentos inseguros, contendo a localização e fornecendo dados precisos para a análise de desempenho de segurança dos motoristas.',
    descriptionProvider:
      'É o nosso plano para o embarcador que possui tecnologia de telemetria e que está atento aos desvios cometidos pelas suas transportadoras.\nIdeal para o monitoramento em tempo real dos comportamentos inseguros dos motoristas, contendo a localização e fornecendo dados precisos para a análise de desempenho de segurança das transportadoras.',
    features: [
      'curadoria',
      'capacitacao',
      'turmas',
      'testes',
      'certificados',
      'gamificacao',
      'conteudos',
      'pad',
      'checklist',
      'app',
      'velocidade',
      'cerca',
      'desvios',
      'ranking-motoristas',
      'notificacoes',
      'estatisticas',
      'fadiga',
      'rotograma',
      'layout',
    ],
    featuresProvider: [
      'capacitacao',
      'turmas-provider',
      'testes',
      'certificados',
      'gamificacao',
      'conteudos',
      'app',
      'velocidade',
      'cerca',
      'ranking-empresas',
      'notificacoes',
      'estatisticas',
      'fadiga',
      'rotograma',
      'layout',
    ],
  },
  {
    id: 3,
    name: 'Vídeo',
    description:
      'É o nosso plano de monitoramento que utiliza inteligência artificial para identificar comportamentos inseguros dos motoristas, além de integrar todas as tecnologias em uma única plataforma.\nIdeal para o transportador que possui câmera de cabine e deseja maximizar a segurança em suas operações de transporte, com a tomada de decisões mais ágil e baseada em dados concretos.',
    descriptionProvider:
      'É o nosso plano de monitoramento que utiliza inteligência artificial para identificar comportamentos inseguros dos motoristas, além de integrar todas as tecnologias em uma única plataforma.\nIdeal para o embarcador que possui câmera de cabine e deseja maximizar a segurança em suas operações de transporte, com a tomada de decisões mais ágil e baseada em dados concretos.',
    features: [
      'curadoria',
      'capacitacao',
      'turmas',
      'testes',
      'certificados',
      'gamificacao',
      'conteudos',
      'pad',
      'checklist',
      'app',
      'ranking-motoristas',
      'notificacoes',
      'estatisticas',
      'camera',
      'rotograma',
      'layout',
    ],
    featuresProvider: [
      'capacitacao',
      'turmas-provider',
      'testes',
      'certificados',
      'gamificacao',
      'conteudos',
      'app',
      'ranking-empresas',
      'notificacoes',
      'estatisticas',
      'camera',
      'rotograma',
      'layout',
    ],
  },
  {
    id: 4,
    name: 'Avançado',
    description:
      'É o nosso plano mais completo para o transportador que possui tecnologia de telemetria e câmera de cabine, integrando todas as tecnologias em uma única plataforma.\nIdeal para o transportador que procura maximizar o controle de suas operações, aproveitando ao máximo as inovações tecnológicas para garantir a segurança e eficiência do transporte de cargas.',
    descriptionProvider:
      'É o nosso plano mais completo para o embarcador que possui tecnologia de telemetria e câmera de cabine, integrando todas as tecnologias em uma única plataforma.\nIdeal para o embarcador que procura maximizar o controle de suas operações, aproveitando ao máximo as inovações tecnológicas para garantir a segurança e eficiência do transporte de cargas.',
    features: [
      'curadoria',
      'capacitacao',
      'turmas',
      'testes',
      'certificados',
      'gamificacao',
      'conteudos',
      'pad',
      'checklist',
      'app',
      'velocidade',
      'cerca',
      'desvios',
      'ranking-motoristas',
      'notificacoes',
      'estatisticas',
      'camera',
      'fadiga',
      'rotograma',
      'layout',
    ],
    featuresProvider: [
      'capacitacao',
      'turmas-provider',
      'testes',
      'certificados',
      'gamificacao',
      'conteudos',
      'app',
      'velocidade',
      'cerca',
      'ranking-empresas',
      'notificacoes',
      'estatisticas',
      'camera',
      'fadiga',
      'rotograma',
      'layout',
    ],
  },
];

export const features = [
  {
    id: 'curadoria',
    name: 'Curadoria de conteúdos Onisys',
    plugin: false,
  },
  {
    id: 'capacitacao',
    name: 'Criação de conteúdo para capacitação',
    plugin: false,
  },
  {
    id: 'turmas-provider',
    name: 'Criação de turmas para motoristas e gestores',
    plugin: false,
  },
  {
    id: 'turmas',
    name: 'Criação de turmas segmentadas',
    plugin: false,
  },
  {
    id: 'testes',
    name: 'Criação de testes online',
    plugin: false,
  },
  {
    id: 'certificados',
    name: 'Emissão de certificados',
    plugin: false,
  },
  {
    id: 'gamificacao',
    name: 'Capacitação gamificada',
    plugin: false,
  },
  {
    id: 'conteudos',
    name: 'Conteúdo off-line',
    plugin: false,
  },
  {
    id: 'pad',
    name: 'Plano de ação personalizável',
    plugin: false,
  },
  {
    id: 'checklist',
    name: 'Checklist e formulários digitais',
    plugin: false,
  },
  {
    id: 'app',
    name: 'Aplicativo Motorista (modo offline)',
    plugin: false,
  },
  {
    id: 'velocidade',
    name: 'Parametrização de desvios e velocidade da via',
    plugin: false,
  },
  {
    id: 'cerca',
    name: 'Parametrização de cerca eletrônica',
    plugin: false,
  },
  {
    id: 'desvios',
    name: 'Gestão de desvios em tempo real',
    plugin: false,
  },
  {
    id: 'ranking-empresas',
    name: 'Ranking de transportadores',
    plugin: false,
  },
  {
    id: 'ranking-motoristas',
    name: 'Ranking de motoristas',
    plugin: false,
  },
  {
    id: 'notificacoes',
    name: 'Notificações e relatórios por e-mails',
    plugin: false,
  },
  {
    id: 'estatisticas',
    name: 'Estatísticas',
    plugin: false,
  },
  {
    id: 'camera',
    name: 'Inteligência artificial para avaliação das câmeras',
    plugin: false,
  },
  {
    id: 'fadiga',
    name: 'Parametrização de desvios de fadiga*',
    plugin: true,
  },
  {
    id: 'rotograma',
    name: 'Rotograma*',
    plugin: true,
  },
  {
    id: 'layout',
    name: 'Layout de descarga*',
    plugin: true,
  },
];
