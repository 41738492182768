import api from 'services/api';

export const getDrivers = async () => {
  const res = await api.get(`/provider/motoristas?all=true`);
  return res;
};

export const getDriversTorre = async query => {
  const res = await api.get(`/select-drivers-provider`, {
    params: query,
  });
  return res;
};

const requestDriverCard = async query => {
  const res = await api.get('/provider/motoristas-card', { params: query });
  return res;
};

const requestDriverCardTorre = async query => {
  const res = await api.get('/drivers-card-provider', { params: query });
  return res;
};

const changeDriverStatus = async id => {
  const res = await api.put(`/provider/motoristas-change-status/${id}`);
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel-provider/driver', { params: query });
  return res;
};

const createMany = async data => {
  const res = await api.post(`/provider/drivers/storeMany`, data);
  return res;
};

const updateDriver = async data => {
  const res = await api.put(`/provider/driver/${data.id}`, data);
  return res;
};

const storePhone = async data => {
  const res = await api.put(
    `/provider/motoristas-store-new-phone/${data.id}`,
    data,
  );
  return res;
};

const requestDriver = async (id, query) => {
  const res = await api.get(`/provider/motoristas/${id}`, { params: query });
  return res;
};

const requestDriverLastTrip = async id => {
  const res = await api.get(`/provider/motoristas/${id}/ultima-viagem`);
  return res;
};

const requestDriverForms = async (id, query) => {
  const res = await api.get(`/provider/motoristas-forms/${id}`, {
    params: query,
  });
  return res;
};

const requestDriverGraphs = async query => {
  const res = await api.get('/provider/motoristas-graphs', { params: query });
  return res;
};

const requestDriverRanking = async (id, query) => {
  const res = await api.get(`/provider/motoristas-ranking/${id}`, {
    params: query,
  });
  return res;
};

const requestLogs = async id => {
  const res = await api.get(`/provider/motoristas-logs/${id}`);
  return res;
};

const requestExcelEvaluation = async query => {
  const res = await api.get('/excel-provider/evaluationsdriver', {
    params: query,
  });
  return res;
};

const requestExcelInfractions = async query => {
  const res = await api.get('/excel-provider/infractionsdriver', {
    params: query,
  });
  return res;
};

const requestExcelUnloadingEvaluation = async query => {
  const res = await api.get('/excel-provider/unloadingevaluationsdriver', {
    params: query,
  });
  return res;
};

const requestExcelUnloadingInfraction = async query => {
  const res = await api.get('/excel-provider/unloadingInfractionsdriver', {
    params: query,
  });
  return res;
};

const requestExcelForms = async query => {
  const res = await api.get('/excel-provider/formsdriver', { params: query });
  return res;
};

const checkUpload = async data => {
  const res = await api.post(`/provider/drivers/checkUpload`, data);
  return res;
};

const storeDriver = async data => {
  const res = await api.post('/provider/driver', data);
  return res;
};

const updateDriverImage = async data => {
  // const res = await api.post('/provider/driver', data);
  // return res;
};

const requestFaixaContratada = async () => {
  const res = await api.get('/faixa-contratada');
  return res;
};

export {
  requestDriver,
  requestDriverLastTrip,
  requestDriverForms,
  requestDriverGraphs,
  requestDriverCard,
  changeDriverStatus,
  updateDriver,
  requestLogs,
  requestExcel,
  requestExcelEvaluation,
  requestExcelInfractions,
  requestExcelUnloadingEvaluation,
  requestExcelUnloadingInfraction,
  requestExcelForms,
  requestDriverRanking,
  storePhone,
  createMany,
  checkUpload,
  storeDriver,
  updateDriverImage,
  requestFaixaContratada,
  requestDriverCardTorre,
};
