import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  border: 2px solid ${props => props.coloBox};
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  .type {
    font-size: 25px;
    color: ${colors.greyTitle};
    font-weight: 600;
    margin-left: 10px;

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;

  .seeFeedback {
    font-weight: 600;
    color: ${colors.greySubtitle};
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      text-decoration: underline;
      color: ${colors.greenMain};
    }
  }
`;

export const Counter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 35px;
  margin-bottom: 15px;
  font-weight: 700;
  color: ${colors.grey};
  font-size: 14px;
`;
