import styled, { css } from 'styled-components';

export const DashedButton = styled.button`
  ${({ theme }) => css`
    width: 100%;
    height: 42px;
    background: ${theme.palette.system.overlay};
    border: 2px dashed ${theme.palette.semantics.feedback.unknown.natural};
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: ${theme.palette.words.subtitle.natural};
    font: normal normal 500 16px/18px Texta;

    :hover {
      background: ${theme.palette.system.disabled};
    }
  `}
`;
