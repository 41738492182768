import Button from 'components/Buttons/Default';
import { useNavigate } from 'react-router-dom';
import { Card, Title } from './styled';

const PlanCard = ({ data, provider }) => {
  const navigate = useNavigate();
  const { id, name, description, descriptionProvider } = data;

  return (
    <Card>
      <Title>
        <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f209c262f8214_Grupo%2016181.svg" />
        <h2>{name}</h2>
      </Title>
      <p
        dangerouslySetInnerHTML={{
          __html: provider
            ? descriptionProvider.replace('\n', '<br /><br />')
            : description.replace('\n', '<br /><br />'),
        }}
      />

      <Button onClick={() => navigate(`/cadastro/${id}?provider=${provider}`)}>
        Conheça este plano
      </Button>
    </Card>
  );
};

export default PlanCard;
