import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import { Modal, IconButton, Icon, Grid } from '@mui/material';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import TextInput from 'components/Inputs/TextField';
import * as S from './styled';

const RetornoModal = ({ open, handleClose, handleConfirm, loading }) => {
  const theme = useTheme();

  const title = `Deseja retornar esse desvio para análise?`;
  const subtitle =
    'O desvio permanecerá na aba "PENDENTES" mas ficará marcado para análise.';

  // Icone do header variável com titleIconName
  const titleIcon = (
    <Icon
      sx={{ color: theme.palette.brand.secondary.natural }}
      fontSize="medium"
    >
      check
    </Icon>
  );

  // Objeto de dados a serem preenchidos
  const [data, setData] = useState({
    observacao_analise: '',
  });

  const handleChange = (name, value) => {
    setData(prev => {
      return { ...prev, [name]: value };
    });
  };

  // Bloqueia botão de confirmar quando há item sem preencher
  const checkReady = () => {
    let ready = true;
    if (!data.observacao_analise) ready = false;
    return ready;
  };

  return (
    <Modal open={open}>
      <S.Paper>
        <S.Header>
          <S.Title>
            {titleIcon}
            <h1>{title}</h1>
          </S.Title>

          <IconButton size="small" onClick={handleClose}>
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <InfoCard message={subtitle} />

        <Grid container rowSpacing={2} columnSpacing={2} marginY={1}>
          <Grid item xs={12} sm={12}>
            <TextInput
              label="Observação de retorno da análise"
              value={data.observacao_analise}
              onChange={e => handleChange('observacao_analise', e.target.value)}
              name="observacao_analise"
              maxRows={2}
              multiline
              inputProps={{ width: '100%' }}
            />
          </Grid>
        </Grid>

        <S.Footer>
          <GhostButton size="medium" onClick={handleClose}>
            Cancelar
          </GhostButton>

          <DefaultButton
            onClick={() => handleConfirm(data)}
            size="medium"
            style={{ marginLeft: '10px' }}
            disabled={!checkReady()}
            loading={loading}
          >
            Confirmar
          </DefaultButton>
        </S.Footer>
      </S.Paper>
    </Modal>
  );
};

export default RetornoModal;
