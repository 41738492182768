// Pages
import HomeProvider from 'pages/Home';
import { useSelector } from 'react-redux';

const Empresa = () => {
  const { user } = useSelector(state => {
    return state.auth.user;
  });

  return <div>Olá, {user?.nome || ''}!</div>;
};

export default {
  '/home': {
    date: false,
    title: <Empresa />,
    page: <HomeProvider />,
  },

  '/': {
    date: false,
    title: <Empresa />,
    page: <HomeProvider />,
  },
};
