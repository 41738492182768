import { Modal } from '@mui/material';
import styled, { css } from 'styled-components';

export const ModalMui = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    height: 580px;
    width: 580px;
    background-color: ${theme.palette.system.overlay};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;

export const Header = styled.div`
  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 30px;

    div {
      display: flex;
      align-items: center;

      h2 {
        margin-left: 10px;
      }
    }
  }
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.brand.primary.light};
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  padding: 25px 30px;
  pointer-events: ${props => (props.noEvents ? 'none' : 'all')};
  opacity: ${props => (props.noEvents ? 0.4 : 1)};
  transition: all ease 0.3s;
`;

export const MiniTitle = styled.h4``;

/// /////////////////////////////////////
export const FrequencyBox = styled.div`
  margin-bottom: 10px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 50%;
  gap: 10px;
  margin-top: 10px;
`;

/// ////////////////////////////////
export const DaysBox = styled.div`
  margin-top: 25px;
`;

export const WeekBox = styled.div`
  ${({ theme, noEvents }) => css`
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    pointer-events: ${noEvents ? 'none' : 'all'};
    opacity: ${noEvents ? 0.4 : 1};
    transition: all ease 0.3s;

    .dayBall {
      background-color: ${theme.palette.brand.primary.background};
      width: 32px;
      height: 32px;
      border-radius: 32px;
      margin-right: 10px;
      font-size: 16px;
      color: ${theme.palette.words.title.natural};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .dayBall.active {
      background-color: ${theme.palette.brand.secondary.natural};
      color: ${theme.palette.words.text.contrast};
    }
  `}
`;

/// ///////////////////////////////
export const TimeBox = styled.div`
  margin-top: 25px;
`;

export const FinishedBox = styled.div`
  .line {
    margin-top: 20px;
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
      margin-right: 50px;
    }
  }
`;
