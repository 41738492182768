export const tabs = [
  {
    value: 'estatisticas',
    label: 'Estatísticas',
    filters: true,
    date: true,
    export: ['PDF'],
  },
  {
    value: 'desvios',
    label: 'Desvios',
    link: { label: 'DESVIOS', url: '/desvios' },
    plans: [100, 110, 140],
  },
  {
    value: 'acoes',
    label: 'Ações e Suspensões',
    link: {
      label: 'AÇÕES E SUSPENSÕES',
      url: '/acoes-suspensoes/empresas/:id',
    },
    plans: [100, 110, 140],
  },
  {
    value: 'frota',
    label: 'Frota',
    date: false,
    // plans: [20],
  },
  {
    value: 'acidentes_multas',
    label: 'Acidentes e Multas',
    plans: [10],
  },
];
