import * as types from './types';

export function setFilter(query) {
  return {
    type: types.SET_FILTER,
    payload: { ...query },
  };
}

export function setSortBy(query) {
  return {
    type: types.SET_SORT_BY,
    payload: query,
  };
}

export function ResetFilter() {
  return {
    type: types.RESET_FILTER,
  };
}
