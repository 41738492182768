import AcoesSuspensoes from 'pages/AcoesSuspensoes/AcoesSuspensoes/ListAcoes';
import DetalheAcoesSuspensoes from 'pages/AcoesSuspensoes/AcoesSuspensoes/Detalhe';

// pages
import ConfiguracoesAcaoSuspensoes from 'pages/AcoesSuspensoes/Configuracoes';
import ConfigAcaoSuspensoesClientes from 'pages/AcoesSuspensoes/Configuracoes/Clientes';
import Historico from 'pages/Historico';

export default {
  '/acoes-suspensoes/configuracoes': {
    defaults: [],
    back: -1,
    title: '',
    page: <ConfiguracoesAcaoSuspensoes />,
  },
  '/acoes-suspensoes/configuracoes/clientes': {
    defaults: [],
    back: -1,
    title: '',
    page: <ConfigAcaoSuspensoesClientes />,
  },
  '/logs/acoes-suspensoes/configuracoes/:id': {
    back: -1,
    title: 'Histórico da Configuração da Faixa de Ação e Suspenção',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/acoes-suspensoes/faixas/:id/logs"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },
  '/acoes-suspensoes': {
    date: false,
    title: 'Ações e Suspensões',
    pageFilterName: 'filterAcoesSuspensoes',
    defaults: [],

    page: <AcoesSuspensoes />,
  },
  '/acoes-suspensoes/:id': {
    back: '/acoes-suspensoes',
    title: 'Ações e Suspensões',
    defaults: [],
    page: <DetalheAcoesSuspensoes />,
  },
  '/logs/acoes-suspensoes/:id': {
    back: -1,
    title: 'Histórico da Ação e Suspenção',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/acoes-suspensoes/:id/logs"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },
  '/logs/acoes-suspensoes/configuracoes/desvios/:id': {
    back: -1,
    title: 'Histórico da Configuração da Pontuação de Ação e Suspensão',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/acoes-suspensoes/desvios/logs/:id"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },
};
