import styled from 'styled-components';

const ImageContainer = styled.div`
  width: 100%;
  min-height: 100px;

  align-items: center;
  justify-content: center;
  position: relative;

  :hover {
    width: 99%;
    opacity: 0.9;
    transition-duration: 300ms;
  }

  img {
    width: 100%;
    border-radius: 5px;
    max-height: 500px;
  }

  .playVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
    cursor: pointer;

    :hover {
      opacity: 1;
      transition-duration: 300ms;
    }
  }

  .expandImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.4;
    cursor: pointer;

    :hover {
      opacity: 1;
      transition-duration: 300ms;
    }
  }
`;

const VideoContainer = styled.div`
  display: block;
  width: 100%;
  position: relative;
  border-radius: 10px;

  video {
    border-radius: 10px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.8;
    cursor: pointer;

    :hover {
      opacity: 1;
      transition-duration: 300ms;
    }
  }
`;

export { ImageContainer, VideoContainer };
