import styled, { css } from 'styled-components';

const ContainerModifier = {
  hover: ({ theme }) => css`
    &:hover {
      cursor: pointer;
      opacity: 0.8;
      color: ${theme.palette.words.title.natural};
    }
  `,
  loading: () => css`
    pointer-events: none;
    opacity: 0.7;
  `,
};

export const Container = styled.tr`
  ${({ theme, hover, align, isLoading }) => css`
    padding: 10px 15px;
    flex: 1;
    height: 50px;
    background-color: ${theme.palette.system.border};
    color: ${theme.palette.words.text.light};
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: ${align};
    transition: all 0.2s ease-in-out;
    user-select: none;
    ${isLoading && ContainerModifier.loading()}
    ${hover && ContainerModifier.hover({ theme })}

    .descriptor {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `}
`;

export const TH = styled.th`
  ${({ selection }) => css`
    ${!selection &&
    css`
      pointer-events: none;
    `};
  `}
`;

export const IconsContainer = styled.th`
  ${({ theme, visible }) => css`
    display: flex;
    flex-direction: column;
    opacity: ${visible ? 1 : 0};
    justify-content: center;
    color: ${theme.palette.words.text.light};

    .MuiSvgIcon-root {
      opacity: 0.5;
      font-size: 21px;
      transition: all 0.2s ease-in-out;

      &.up {
        margin-bottom: -6px;
      }

      &.down {
        margin-top: -6px;
      }

      &.active {
        opacity: 0.8;
        color: ${theme.palette.words.text.natural};
      }
    }
  `}
`;
