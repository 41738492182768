import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  pageCount: 0,
  pageSize: 10,
  pageIndex: 0,
  agregado: '',
  orderBy: 'ASC',
  currentTab: 0,
};

export default function filterMotoristaCronograma(
  state = INITIAL_STATE,
  action,
) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_MOTORISTA_CRONOGRAMA: {
        draft.pageCount = action.payload.query.pageCount;
        draft.pageSize = action.payload.query.pageSize;
        draft.pageIndex = action.payload.query.pageIndex;
        draft.orderBy = action.payload.query.orderBy;
        draft.currentTab = action.payload.query.currentTab;
        draft.agregado = action.payload.query.agregado;
        break;
      }
      default:
    }
  });
}
