import React from 'react';
import Loading from 'components/Loading';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Partners from './components/Partners';
import Footer from './components/Footer';
import Plans from './components/Plans';
import Help from './components/Help';
import { Wrapper } from './styled';

const Cadastro = () => {
  const navigate = useNavigate();

  const signed = useSelector(state => !!state?.auth?.signed);
  if (signed) navigate('/');

  return (
    <Wrapper>
      <Header />
      <Plans />
      <Partners />
      <Help />
      <Footer />
    </Wrapper>
  );
};

export default Cadastro;
