import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  background-color: ${colors.white};
  height: 160px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid ${colors.greyBorder};
  box-shadow: 0px 2px 2px ${colors.grey};
  border-radius: 5px;
  position: relative;
  padding: 10px;

  @media (max-width: 915px) {
    height: 100px;
  }
`;

export const BoxLoading = styled.div`
  opacity: ${props => (props.loading ? 1 : 0)};
  visibility: ${props => (props.loading ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-size: 80rem 14rem;
  animation: placeholderShimmer 2s linear infinite forwards;

  @keyframes placeholderShimmer {
    0% {
      background-position: -40rem 0;
    }
    100% {
      background-position: 40rem 0;
    }
  }
`;

export const ImageArea = styled.div`
  img {
    width: 100px;
    height: 100%;

    @media (max-width: 915px) {
      width: 50px;
    }
  }
`;

export const ActionsArea = styled.div`
  margin-left: 15px;

  .text {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;

    @media (max-width: 915px) {
      font-size: 14px;
    }
  }
`;
