import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  AddCircleOutline,
  SaveAlt,
  SettingsOutlined,
} from '@mui/icons-material';
import { Divider } from '@mui/material';
import Tabs from 'components/Tabs';
import { trackEvent } from 'utils/mixpanel';

import Loading from 'components/Loading';
import DefaultButton from 'components/Buttons/Default';
import EmptyDataCard from 'components/Cards/EmptyDataCard';
import Header from 'pages/_templates/ListPage/components/Header';
import ModalDecaimento from './Components/ModalDecaimento';
import ListaFaixas from './Components/ListaFaixas';
import ListaPontuacoes from './Components/ListaPontuacoes';
import { usePlans } from 'hooks/usePlans';
import { fields, getMin, statusTabs, steps } from './constants';
import {
  getFaixas,
  getFirstConfig,
  getPontuacoes,
  requestExcelFaixas,
} from './services';
import { ControllerArea } from './styles';
import { toast } from 'react-toastify';
import { downloadFromLink } from 'utils/helpers';

const queryInitial = {
  search: '',
  pageIndex: 0,
  pageSize: 10,
  sortBy: {
    id: 'ativo_acao_suspensao',
    order: 'DESC',
    desc: true,
  },
};

const ConfiguracoesAcoesSuspensoes = () => {
  const navigate = useNavigate();
  const { isProvider, isOpLogistico } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);

  const [queryPontuacoes, setQueryPontuacoes] = useState({ ...queryInitial });
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [tab, setTab] = useState(isProvider ? 'faixas' : 'pontuacoes');
  const [openDecaimentoModal, setOpenDecaimentoModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [faixaModal, setFaixaModal] = useState({
    open: false,
    data: null,
  });

  const {
    data: resFaixas,
    isFetching: fetchingFaixas,
    refetch: refetchFaixas,
  } = useQuery(['acoes-suspensoes-faxas'], () => getFaixas(), {
    refetchOnWindowFocus: false,
  });

  const {
    data: resPontuacoes,
    refetch: refetchPontuacoes,
    isFetching: fetchingPontuacoes,
  } = useQuery(
    ['acoes-suspensoes-pontuacoes', queryPontuacoes],
    () => getPontuacoes(queryPontuacoes),
    { refetchOnWindowFocus: false },
  );

  const {
    data: resFirstConfig,
    isFetching,
    refetch,
  } = useQuery(['first-config'], () => getFirstConfig(), {
    refetchOnWindowFocus: false,
  });

  const headerTabs = {
    value: tab,
    items: statusTabs,
    onChange: (_, newValue) => setTab(newValue),
    disabled: fetchingFaixas || fetchingPontuacoes,
  };

  // request de exportação
  const handleRequestExcel = async () => {
    setLoadingExcel(true);
    const res = await requestExcelFaixas({
      excelFields: fields,
    });
    if (res.link) {
      downloadFromLink(res.link);
      toast.success(res.message);
    } else if (res.message) toast.error(res.message);

    setLoadingExcel(false);
  };

  const headerActions = [
    {
      title: 'Exportar',
      variant: 'ghost',
      icon: <SaveAlt />,
      function: () => {
        trackEvent(user, 'EXPORTAR PLANILHA');
        handleRequestExcel();
      },
      loading: loadingExcel,
    },
    {
      title: 'Configurações',
      variant: 'ghost',
      icon: <SettingsOutlined />,
      function: () => setOpenDecaimentoModal(true),
    },
    {
      title: 'Adicionar Faixa',
      variant: 'default',
      icon: <AddCircleOutline />,
      function: () =>
        setFaixaModal({
          open: true,
          data: { min: getMin(resFaixas?.data?.data || []) },
        }),
    },
  ];

  const handleNext = () => {
    if (currentStep.step === 1) {
      setTab('faixas');
      setCurrentStep(steps[currentStep.value + 1]);
    } else {
      refetchFaixas();
      refetchPontuacoes();
    }
  };

  if (isFetching) {
    return <Loading />;
  }

  if (!isProvider && !isFetching && !!resFirstConfig?.allDisable) {
    return (
      <>
        <Header
          title="Configurações"
          steps={steps}
          currentStep={currentStep.step}
        />
        <EmptyDataCard
          image="padconfig.png"
          title="1. Comece a configurar as ações e suspensões"
          subtitle="Configure os desvios que contabilizam para Ações e Suspensões"
          defaultButtonContent="CONFIGURAR DESVIOS"
          handleDefaultButton={() => navigate('/desvios/configuracoes')}
        />
      </>
    );
  }

  return (
    <>
      <Header
        title="Configurações"
        steps={!isProvider && resFirstConfig?.allDisable ? steps : null}
        currentStep={
          !isProvider && resFirstConfig?.allDisable ? currentStep.step : null
        }
        actions={isProvider ? headerActions : []}
      />

      {!resFirstConfig?.allDisable ? (
        <>
          <Tabs {...headerTabs} />
          <Divider />
        </>
      ) : (
        isProvider && (
          <>
            <Tabs {...headerTabs} />
            <Divider />
          </>
        )
      )}

      {tab === 'pontuacoes' && (
        <ListaPontuacoes
          data={resPontuacoes?.data?.data || []}
          pageCount={resPontuacoes?.data?.total || 13}
          loading={fetchingPontuacoes}
          query={queryPontuacoes}
          setQuery={setQueryPontuacoes}
          refetch={refetchPontuacoes}
        />
      )}

      {tab === 'faixas' && (
        <ListaFaixas
          data={resFaixas?.data?.data || []}
          pageCount={resFaixas?.data?.total || 0}
          loading={fetchingFaixas}
          headerActions={!isProvider ? headerActions : null}
          formModal={faixaModal}
          setFormModal={setFaixaModal}
          refetch={refetchFaixas}
        />
      )}

      {!isProvider && resFirstConfig?.allDisable && (
        <ControllerArea>
          <div className="statusArea">{currentStep.label}</div>

          <div className="buttonsArea">
            <DefaultButton
              onClick={() => handleNext()}
              disabled={
                (currentStep.step === 1 &&
                  !resPontuacoes?.data?.data?.some(
                    item => item.ativo_acao_suspensao,
                  )) ||
                (currentStep.step === 2 && !resFaixas?.data?.data?.length)
              }
            >
              {currentStep.step === 1 ? 'Prosseguir' : 'Finalizar'}
            </DefaultButton>
          </div>
        </ControllerArea>
      )}

      <ModalDecaimento
        open={openDecaimentoModal}
        handleClose={() => setOpenDecaimentoModal(false)}
      />
    </>
  );
};

export default ConfiguracoesAcoesSuspensoes;
