import React from 'react';
import { useTheme } from 'styled-components';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Divider, InputAdornment, TextField } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColorOutlined';
import GhostButton from 'components/Buttons/Ghost';
import Switch from 'components/Inputs/_withController/Switch';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { Avatar } from '../Avatar';

import { objectGet } from 'utils/helpers';
import * as S from './styled';

export const Header = ({
  isLoading,
  control,
  errors,
  foto = '',
  setValue,
  id,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <S.Container>
      <div>
        <Avatar
          link={foto}
          loading={isLoading}
          handleChange={url => setValue('foto', url)}
        />
      </div>

      <S.Content>
        <S.Flex className="full-width gap-1 align-center">
          <Controller
            name="nome"
            control={control}
            rules={{
              required: { value: true, message: 'Campo obrigatório.' },
            }}
            render={({ field }) => {
              const error = errors && objectGet(errors, 'nome');
              return (
                <TextField
                  hiddenLabel
                  {...field}
                  error={!!error}
                  helperText={error?.message || null}
                  variant="standard"
                  size="medium"
                  margin="none"
                  style={{ width: '100%' }}
                  autoComplete={false}
                  placeholder="Nome completo"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <BorderColorIcon />
                      </InputAdornment>
                    ),
                    style: {
                      fontSize: '2rem',
                      fontWeight: '700',
                      color: theme.palette.words.title.natural,
                    },
                  }}
                />
              );
            }}
          />

          {!!id && (
            <GhostButton
              startIcon={<HistoricoIcon />}
              size="medium"
              onClick={() => navigate(`/logs/usuario/${id}`)}
            >
              HISTÓRICO DE ALTERAÇÕES
            </GhostButton>
          )}
        </S.Flex>
        <Divider />
        <S.Flex className="mt-05">
          <Switch
            small
            name="ativo"
            control={control}
            textOn="Usuário ativo"
            textOff="Usuário inativo"
          />
        </S.Flex>
      </S.Content>
    </S.Container>
  );
};
