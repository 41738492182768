import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Popover } from '@mui/material';
import { useTheme } from 'styled-components';
import * as S from './styled';

// Style igual DefaultButton
// Recebe uma div como popoverComponent
const DefaultButtonPopover = ({
  loading,
  children,
  icon,
  disabled,
  width,
  size,
  popoverComponent,
  ...props
}) => {
  // Controle do modal
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <S.StyledButton
        disabled={disabled}
        width={width}
        size={size}
        loading={loading ? 'true' : 'false'}
        startIcon={icon && icon}
        onClick={handleClick}
        {...props}
      >
        {loading ? (
          <CircularProgress
            size={24}
            sx={{ color: theme.palette.words.button.contrast }}
          />
        ) : (
          children
        )}
      </S.StyledButton>

      <Popover
        sx={{ transform: 'translate(0px, 20px)' }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)} // Fechar ao clicar dentro tmb
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {popoverComponent}
      </Popover>
    </>
  );
};

export default DefaultButtonPopover;
