import styled, { css } from 'styled-components';
import { Collapse } from '@mui/material';

export const StepOneBox = styled.div`
  ${({ theme }) => css`
    .boxText {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .counter {
      align-self: flex-end;
      font-size: 12px;
      color: ${theme.palette.words.text.light};
    }
  `}
`;

export const BoxAnswers = styled.div`
  margin-bottom: 20px;

  p {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const Content = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 250px;

  @media (min-height: 800px) {
    max-height: 350px;
  }

  @media (min-height: 900px) {
    max-height: 400px;
  }

  @media (min-height: 1000px) {
    max-height: 500px;
  }
`;

export const Answers = styled.div`
  display: flex;
  flex-direction: column;

  .boxTotalContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .left {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
  }
`;

export const ConifgAnw = styled.div`
  ${({ theme }) => css`
    .boxAll {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .textOp {
        font-weight: 600;
        color: ${theme.palette.words.subtitle.natural};
        font-size: 15px;
      }

      .occurrenceName {
        width: 50%;
        outline: none;
        border: none;
        border: 1px solid ${theme.palette.brand.primary.light};
        border-radius: 3px;
        padding: 3px 4px;
        font-size: 15px;
      }
    }
  `}
`;

export const CollapseMu = styled(Collapse)`
  margin-top: 5px;
`;
