import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { Tooltip } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { TotalContentBox, TotalTitle } from './styled';

const Resumo = ({ data = [] }) => {
  const theme = useTheme();
  const [isDragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const handleMouseDown = e => {
    setDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };

  const handleMouseMove = e => {
    if (!isDragging) return;
    const x = e.pageX - startX;
    e.currentTarget.scrollLeft = scrollLeft - x;
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const formatValue = (type, value) => {
    switch (type) {
      case 'string':
        return String(value).toLocaleLowerCase();
      case 'number':
        return Number(value);
      case 'number-compact':
        return Number(value).toLocaleString('en-US', {
          maximumFractionDigits: 2,
          notation: 'compact',
          compactDisplay: 'short',
        });
      case 'boolean':
        return Boolean(value);
      case 'date':
        return new Date(value || null);
      case 'date-hour':
        return new Date(value || null);
      default:
        return value;
    }
  };

  return (
    <>
      <TotalTitle>
        <span>Resumo</span>
        <Tooltip title="Resumo de todos os valores disponíveis na tabela">
          <HelpOutlineOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.unknown.natural}
            style={{ marginLeft: '10px', cursor: 'pointer' }}
          />
        </Tooltip>
      </TotalTitle>{' '}
      <TotalContentBox
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        {data?.map((item, key) => (
          <div className="boxItem" key={key}>
            <div className="title">{item.title}</div>
            <div className="value">{formatValue(item.type, item.value)}</div>
          </div>
        ))}
      </TotalContentBox>
    </>
  );
};

export default Resumo;
