export const getMin = (data = []) => {
  const sortData = data
    .filter(a => a.ativo)
    .sort((a, b) => a.limiar - b.limiar);
  return sortData.length > 0 ? sortData[sortData.length - 1].limiar : 0;
};

export const statusTabs = [
  { value: 'pontuacoes', label: 'Pontuações' },
  { value: 'faixas', label: 'Faixas' },
];

export const steps = [
  {
    title: 'Pontuações',
    label: 'Passo 1: Configuração de pontuações Ações e Suspensões',
    step: 1,
    value: 0,
  },
  {
    title: 'Faixas',
    label: 'Passo 2: Configuração de faixas de pontuação Ações e Suspensões',
    step: 2,
    value: 1,
  },
];

export const fields = [
  {
    label: 'Ativo',
    value: 'ativo',
    selected: true,
    default: true,
  },
  {
    label: 'Título',
    value: 'titulo',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação inicial',
    value: 'limiar',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação final',
    value: 'limiar_final',
    selected: true,
    default: true,
  },
  {
    label: 'Ação Disciplinar',
    value: 'acao_disciplinar.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Capacitação',
    value: 'conteudo.titulo',
    selected: true,
    default: false,
  },
];
