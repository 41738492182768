import colors from 'styles/colors';
import * as S from './styled';

const Radio = ({
  value,
  onChange,
  disabled,
  label,
  error,
  preview,
  ...props
}) => {
  return (
    <S.Label hasError={error} preview={preview} disabled={disabled}>
      <S.Box
        isCheck={value}
        color={value ? colors.white : '#0000'}
        preview={preview}
        disabled={disabled}
        {...props}
      >
        <input
          type="checkbox"
          onChange={onChange}
          checked={value}
          readOnly={disabled || preview}
        />
        <div className="pointer" />
      </S.Box>
      {label && <span>{label}</span>}
    </S.Label>
  );
};

export default Radio;
