import React, { useRef } from 'react';
import { Modal, Button, Divider, Link, Grid } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { formatNewDate } from 'utils/dates';
import { useTheme } from 'styled-components';
import HeaderPoint from 'images/curriculo/header_point.png';
import Logo from 'images/curriculo/logo_naestrada.png';
import SocialFace from 'images/curriculo/social_facebook.png';
import SocialInsta from 'images/curriculo/social_instagram.png';
import SocialLinkedin from 'images/curriculo/social_linkedin.png';
import SocialWhats from 'images/curriculo/social_whatsapp.png';
import IconId from 'images/curriculo/icon_id.png';
import IconEndereco from 'images/curriculo/icon_endereco.png';
import IconCertificados from 'images/curriculo/icon_certificados.png';
import IconTruck from 'images/curriculo/icon_truck.png';
import IconXp from 'images/curriculo/icon_xp.png';
import IconCursos from 'images/curriculo/icon_cursos.png';
import * as S from './styled';

// Iniciais do nome em CAIXA ALTA'
const getInitials = name => {
  const correcao = name.trim().replace('  ', ' ');
  const initials = correcao.split(' ').map(i => i[0].toUpperCase());
  return initials;
};

// Transforma [{attribute: value}, ...] >> 'value, ...'
const getListCheckedString = (list, attribute) => {
  const checked = list.filter(i => i.checked);
  const names = checked
    .map(i => i[attribute])
    .toString()
    .replaceAll(',', ', ');
  return names;
};

const ModelPdf = ({ open, handleClose }) => {
  const theme = useTheme();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Informações do perfil do candidato
  // lateral esquerda
  const contentProfile = (
    <S.ContentProfile>
      {/* ----------------- PERFIL ----------------------*/}
      <S.ItemTitle>
        <S.StyledCustomIcon src={IconId} />
        <h2>Perfil</h2>
      </S.ItemTitle>

      <S.ItemContent>
        <h2>Email:</h2>
        <h3>{open.email}</h3>
      </S.ItemContent>

      <S.ItemContent>
        <h2>CPF:</h2>
        <h3>{open.cpf}</h3>
      </S.ItemContent>

      <S.ItemContent>
        <h2>Data de Nascimento:</h2>
        <h3>{open.data_nascimento && formatNewDate(open.data_nascimento)}</h3>
      </S.ItemContent>

      <S.ItemContent>
        <h2>Documento de Identidade:</h2>
        <h3>{open.rg}</h3>
      </S.ItemContent>

      <S.ItemContent>
        <h2>Telefone:</h2>
        <h3>{open.celular}</h3>
      </S.ItemContent>

      <S.ItemContent>
        <h2>Documento CNH:</h2>
        <h3>{open.cnh}</h3>
      </S.ItemContent>

      <S.ItemContent>
        <h2>Validade CNH:</h2>
        <h3>{open.validade_cnh && formatNewDate(open.validade_cnh)}</h3>
      </S.ItemContent>

      <S.ItemContent>
        <h2>Categoria CNH:</h2>
        <h3>{open.categoria_cnh}</h3>
      </S.ItemContent>

      <S.ItemContent>
        <h2>Estado Civil:</h2>
        <h3>{open.estado_civil}</h3>
      </S.ItemContent>

      {/* ----------------- ENDERECO ----------------------*/}
      <S.ItemTitle>
        <S.StyledCustomIcon src={IconEndereco} />
        <h2>Endereço</h2>
      </S.ItemTitle>

      <S.ItemContent>
        <h2>CEP:</h2>
        <h3>{open.cep}</h3>
      </S.ItemContent>

      <S.ItemContent>
        <h2>Endereço:</h2>
        <h3>{`${open.endereco}, ${open.bairro}, ${open.cidade}, ${open.estado}`}</h3>
      </S.ItemContent>

      {/* ----------------- QUALIFICACOES ----------------------*/}
      <S.ItemTitle>
        <S.StyledCustomIcon src={IconCertificados} />
        <h2>Qualificações</h2>
      </S.ItemTitle>

      <S.ItemContent>
        <h2>Escolaridade:</h2>
        <h3>{open.escolaridade}</h3>
      </S.ItemContent>

      <S.ItemContent>
        <h2>Certificações:</h2>

        <h3>{getListCheckedString(open.certificacoes, 'nome')}</h3>
      </S.ItemContent>
    </S.ContentProfile>
  );

  // Informações de experiência do candidato
  // lateral direita
  const contentCurriculo = (
    <S.ContentCurriculo>
      {/* ----------------- CARGA ----------------------*/}
      <S.ItemTitle>
        <S.StyledCustomIcon src={IconTruck} />
        <h2>Direção e Carga</h2>
      </S.ItemTitle>

      <S.ItemContent>
        <h2>Oque você dirige:</h2>
        <h3>{getListCheckedString(open.direcao, 'nome')}</h3>
      </S.ItemContent>

      <S.ItemContent>
        <h2>Tipos de carga:</h2>
        <h3>{getListCheckedString(open.carga, 'nome')}</h3>
      </S.ItemContent>

      {/* ----------------- EXPERIENCIAS ----------------------*/}
      <S.ItemTitle>
        <S.StyledCustomIcon src={IconXp} />
        <h2>Últimas Experiências Profissionais</h2>
      </S.ItemTitle>

      <Grid container spacing={2} marginTop="0px">
        {open.experiencia.map(xp => (
          <Grid item xs={6} md={6} xl={6}>
            <S.ItemContent>
              <h2>Empresa:</h2>
              <h3>{xp.nome_empresa}</h3>
            </S.ItemContent>

            <S.ItemContent>
              <h2>Cargo:</h2>
              <h3>{xp.cargo}</h3>
            </S.ItemContent>

            <S.ItemContent>
              <h2>Função:</h2>
              <h3>{xp.funcao}</h3>
            </S.ItemContent>

            <S.ItemContent>
              <h2>Data Início:</h2>
              <h3>{xp.data_inicio && formatNewDate(xp.data_inicio)}</h3>
            </S.ItemContent>

            <S.ItemContent>
              <h2>Data Saída:</h2>
              <h3>{xp.data_fim && formatNewDate(xp.data_inicio)}</h3>
            </S.ItemContent>

            <Divider />
          </Grid>
        ))}
      </Grid>

      {/* ----------------- CURSOS ----------------------*/}
      <S.ItemTitle>
        <S.StyledCustomIcon src={IconCursos} />
        <h2>Cursos e Treinamentos</h2>
      </S.ItemTitle>

      <Grid container spacing={2} marginTop="0px">
        {open.cursos.map(curso => (
          <Grid item xs={6} md={6} xl={6}>
            <S.ItemContent>
              <h2>Nome do Curso:</h2>
              <h3>{curso.nome}</h3>
            </S.ItemContent>

            <S.ItemContent>
              <h2>Instituicao:</h2>
              <h3>{curso.instituicao}</h3>
            </S.ItemContent>

            <S.ItemContent>
              <h2>Tipo de Treinamento:</h2>
              <h3>{curso.tipo_treinamento}</h3>
            </S.ItemContent>

            <S.ItemContent>
              <h2>Carga Horária:</h2>
              <h3>{curso.carga_horaria}</h3>
            </S.ItemContent>

            <Divider />
          </Grid>
        ))}
      </Grid>
    </S.ContentCurriculo>
  );

  return (
    <Modal open={open}>
      <S.Main>
        {/* <S.RightHeader>
          <S.CloseButton
            startIcon={<Icon>close</Icon>}
            onClick={() => handleClose()}
          />
        </S.RightHeader> */}

        <S.Wrapper id="mainPdf" ref={componentRef}>
          <S.Header>
            <img src={HeaderPoint} />
            <div>
              <h2>{open.nome && getInitials(open.nome)}</h2>
            </div>
            <h3>{open.nome}</h3>
          </S.Header>

          <S.Content>
            {contentProfile}

            {contentCurriculo}
          </S.Content>

          <S.FooterCurriculo>
            <img src={Logo} />

            <div>
              <Link href="#" marginLeft="30px">
                <img src={SocialInsta} />
              </Link>
              <Link href="#" marginLeft="30px">
                <img src={SocialFace} />
              </Link>
              <Link href="#" marginLeft="30px">
                <img src={SocialLinkedin} />
              </Link>
              <Link href="#" marginLeft="30px">
                <img src={SocialWhats} />
              </Link>
            </div>
          </S.FooterCurriculo>
        </S.Wrapper>

        <S.Footer>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: theme.palette.semantics.feedback.warning.natural,
              color: theme.palette.words.text.contrast,
              marginLeft: '10px',
            }}
            onClick={handlePrint}
          >
            Imprimir
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.semantics.feedback.unknown.light,
              color: theme.palette.words.title.natural,
              marginLeft: '10px',
            }}
            onClick={() => handleClose()}
          >
            Cancelar
          </Button>
        </S.Footer>
      </S.Main>
    </Modal>
  );
};

export default ModelPdf;
