import styled from 'styled-components';
import colors from 'styles/colors';

export const Paper = styled.div`
  position: absolute;
  width: 540px;
  background-color: #ffffff;
  border: 0px 5px 20px #b0c3d329;
  border-radius: 8px;
  padding: 25px 25px;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  h1 {
    color: ${colors.greyTitle};
    font: normal 900 20px Texta;
    margin: 0;
    padding: 0;
  }
`;

export const Main = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  .counter {
    width: 100%;
    display: flex;
    justify-content: end;
    font-size: 11px;
    font-weight: 600;
    padding-right: 3px;
    color: ${colors.greyTitle};
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledToast = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: ${colors.greyTitle};
  display: flex;

  .box {
    background-color: #fff;
    border: 1px solid ${colors.greyBorder};
    height: 170px;
    width: 220px;
    border-radius: 5px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease 0.3s;

    &:hover {
      border: 1px solid ${colors.greenLighter};
    }

    .svgArea {
      background-color: #fff;
      border: 1px solid #b9b9b9;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
    }

    .textArea {
      font-weight: 800;
      text-align: center;
      margin: 10px;
    }

    .clickArea {
      text-decoration: underline;
      opacity: 0.8;
      color: ${colors.greySubtitle};
    }
  }
`;
