import React, { useState, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Modal, IconButton, Icon, Grid } from '@mui/material';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import Select from 'components/Inputs/Select';
import Calendar from 'components/Inputs/Calendar';
import * as S from './styled';

const MessageModal = ({
  id,
  handleClose,
  handleConfirm,
  titleIconName = 'info',
  canais,
  loading,
}) => {
  const theme = useTheme();
  const titleIcon = (
    <Icon
      sx={{ color: theme.palette.brand.secondary.natural }}
      fontSize="medium"
    >
      {titleIconName}
    </Icon>
  );

  const title = 'Informe o motorista sobre o Onisys';

  const subtitle =
    'Escolha o canal pelo qual deseja informar seu motorista sobre o Onisys. Será enviada uma mensagem com links para nosso aplicativo.';

  const [data, setData] = useState({
    canal: '',
  });

  const handleChange = (name, value) => {
    setData(prev => {
      return { ...prev, [name]: value };
    });
  };

  const checkConfirmButton = () => {
    let ready = true;
    if (!data.canal) ready = false;
    return ready;
  };

  const handleClickConfirm = () => {
    const req = {
      ...data,
      id,
    };
    handleConfirm(req);
  };

  const open = Boolean(id);

  return (
    <Modal open={open}>
      <S.Paper>
        <S.Header>
          <S.Title>
            {titleIcon}
            <h1>{title}</h1>
          </S.Title>

          <IconButton size="small" onClick={handleClose}>
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <InfoCard message={subtitle} />

        <Grid container rowSpacing={0} columnSpacing={2} marginY={3}>
          <Grid item xs={12} sm={12}>
            <Select
              required
              data={canais}
              value={data.canal}
              onChange={e => handleChange('canal', e.target.value)}
              name="canal"
              label="Canal de envio"
            />
          </Grid>
        </Grid>

        <S.Footer>
          <GhostButton size="medium" onClick={handleClose}>
            Cancelar
          </GhostButton>

          <DefaultButton
            onClick={handleClickConfirm}
            size="medium"
            style={{ marginLeft: '10px' }}
            disabled={!checkConfirmButton()}
            loading={loading}
          >
            Confirmar
          </DefaultButton>
        </S.Footer>
      </S.Paper>
    </Modal>
  );
};

export default MessageModal;
