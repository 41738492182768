import styled, { css } from 'styled-components';

export const Container = styled.div`
  .boxLoading {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  @media (max-width: 1336px) {
    transform: translateX(-10%);
  }
`;

export const Paragraph = styled.p`
  ${({ theme, ...props }) => css`
    font-weight: bold;
    color: ${theme.palette.brand.primary.natural};
    font-size: 14px;
    text-align: ${props.center ? 'center' : 'start'};

    &.normal {
      font-size: 16px;
    }
  `}
`;

export const ColDriver = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    h1 {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.brand.primary.natural};
    }
  `}
`;
