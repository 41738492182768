// Styleds

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { DefaultTable } from 'components/_Table/templates/default';

// Material UI
import { Grid } from '@mui/material';
import {
  AddCircleOutline,
  EditOutlined,
  DeleteOutline,
} from '@mui/icons-material';

// Components
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';

// icons
import { ReactComponent as IconAlert } from 'images/icons/configuracoes/icone-alerta.svg';

// Utils
import { useTheme } from 'styled-components';
import ModalFilial from './ModaClientes';
import * as C from '../styled';
import * as S from './styled';
import { columns } from './columns';
import * as services from './services';
import { trackEvent } from 'utils/mixpanel';
import { useSelector } from 'react-redux';
import { render } from 'react-dom';
import { set } from 'lodash';

export const Clientes = () => {
  const user = useSelector(state => state.auth?.user?.user);
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [loadingTable, setLoadingTable] = useState(true);
  const [reenviar, setReenviar] = useState(null);
  const [excluirConvite, setExcluirConvite] = useState(null);

  // Modal Add/Edit Filial
  const [openModal, setOpenModal] = useState(false);
  const renderModal = () => {
    return (
      openModal && (
        <ModalFilial
          open={openModal}
          updateData={fetchData}
          data={currentItem}
          handleClose={() => {
            setOpenModal(false);
          }}
        />
      )
    );
  };

  // Actions column
  const [idsAtivar, setIdsAtivar] = useState([]);
  const [idsDesativar, setIdsDesativar] = useState([]);
  const [modalText, setModalText] = useState('');
  const [companiesList, setCompaniesList] = useState([]);

  const handleSetDesativar = id => {
    setLoadingTable(true);
    const desativar = [];
    const ids = [];
    if (selectedData.length > 0) {
      for (const i in data) {
        const item = data[i].id;
        if (selectedData.includes(item)) {
          desativar.push(data[i]);
          ids.push(data[i].id);
        }
      }
    } else {
      for (const i in data) {
        if (data[i].id === Number(id)) {
          desativar.push(data[i]);
          ids.push(data[i].id);
        }
      }
    }
    setIdsDesativar(ids);
    setModalText(`Tem certeza que deseja desativar?`);
    setCompaniesList(formatText('desativados', desativar));
    setLoadingTable(false);
  };

  const confirmDesativar = async () => {
    setLoadingTable(true);

    const res = await services.updateClientes({ ids: idsDesativar, ativo: 0 });
    if (res.success) {
      toast.success(res.message);
      setIdsDesativar(null);
      fetchData();
    } else {
      toast.error(res.message);
    }
    setLoadingTable(false);
  };

  const handleSetAtivar = id => {
    setLoadingTable(true);

    const ativar = [];
    const ids = [];

    if (selectedData.length > 0) {
      for (const i in data) {
        const item = data[i].id;
        if (selectedData.includes(item)) {
          ativar.push(data[i]);
          ids.push(data[i].id);
        }
      }
    } else {
      for (const i in data) {
        if (data[i].id === Number(id)) {
          ativar.push(data[i]);
          ids.push(data[i].id);
        }
      }
    }
    setIdsAtivar(ids);
    setModalText(`Tem em certeza que deseja ativar?`);
    setCompaniesList(formatText('ativados', ativar));
    setLoadingTable(false);
  };

  const confirmAtivar = async () => {
    setLoadingTable(true);

    const res = await services.updateClientes({ ids: idsAtivar, ativo: 1 });
    if (res.success) {
      toast.success(res.message);
      setIdsAtivar(null);
      fetchData();
    } else {
      toast.error(res.message);
    }
    setLoadingTable(false);
  };

  const formatText = (text, filials) => {
    return (
      <>
        <span>
          Os seguintes clientes serão
          <span style={{ fontWeight: '900' }}> {text.toUpperCase()}</span>:
        </span>
        <ul style={{ listStyle: 'disc', marginLeft: '15px' }}>
          {filials.map((i, k) => (
            <li key={k}>{i.nome}</li>
          ))}
        </ul>
      </>
    );
  };

  const handleAtivarInativar = id => {
    const status = data.some(item => item.id === id && item.ativo === 'ATIVO');
    if (status) {
      setIdsDesativar([id]);
      setModalText(`Tem certeza que deseja desativar?`);
    } else {
      setIdsAtivar([id]);
      setModalText(`Tem em certeza que deseja ativar?`);
    }
  };

  const handleDeleteConvite = async () => {
    const item = data.find(i => i.id === excluirConvite);

    const res = await services.deleteConvite(item?.distId);
    if (res.success) {
      toast.success(res.message);
      fetchData();
    } else {
      toast.error(res.message);
    }
    setExcluirConvite(null);
  };

  const handleReenviar = async () => {
    const item = data.find(i => i.id === reenviar);
    const res = await services.reenviarConvite(item?.distId);
    if (res.success) {
      toast.success(res.message);
      fetchData();
    } else {
      toast.error(res.message);
    }
    setReenviar(null);
  };

  // BulkMenu
  const bulkActions = [
    {
      title: 'Desativar',
      function: handleSetDesativar,
    },
    {
      title: 'Ativar',
      function: handleSetAtivar,
    },
  ];

  // ações por linha
  const actions = [
    {
      title: 'Ativar/Desativar',
      function: handleAtivarInativar,
    },
    {
      title: 'Enviar novamente',
      function: setReenviar,
      visible: item => item.convite === 'REJEITADO',
    },
    {
      title: 'Excluir convite',
      function: setExcluirConvite,
      visible: item =>
        item.convite === 'PENDENTE' || item.convite === 'REJEITADO',
    },
  ];

  // ------------------------------REQUESTS-----------------------------------//
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await services.requireInfos();
    const data = res.data.map(item => {
      return {
        id: item.id,
        ativo: item.ativo ? 'ATIVO' : 'INATIVO',
        nome: item.nome,
        distId: item.distribuidora?.id,
        convite: item?.convite,
        logo: item?.distribuidora?.logo_url
          ? item?.distribuidora?.logo_url
          : '',
      };
    });
    setData(data);
    setLoadingTable(false);
  };

  const getColors = (status, ativo = false) => {
    switch (status) {
      case 'PENDENTE':
        return {
          backgroundColor: 'rgba(189, 204, 48, .1)',
          color: 'rgba(189, 204, 48, 1)',
        };
      case 'REJEITADO':
        return {
          backgroundColor: 'rgba(246, 78, 96, .1)',
          color: 'rgba(246, 78, 96, 1)',
        };
      case 'APROVADO':
        return ativo === 'ATIVO'
          ? {
              backgroundColor: 'rgba(54, 153, 255, .1)',
              color: 'rgba(54, 153, 255, 1)',
            }
          : {
              backgroundColor: 'rgba(246, 78, 96, .1)',
              color: 'rgba(246, 78, 96, 1)',
            };
      case null:
        return ativo === 'ATIVO'
          ? {
              backgroundColor: 'rgba(54, 153, 255, .1)',
              color: 'rgba(54, 153, 255, 1)',
            }
          : {
              backgroundColor: 'rgba(246, 78, 96, .1)',
              color: 'rgba(246, 78, 96, 1)',
            };
    }
  };

  const renderStatus = dataStatus => {
    return (
      <div
        style={{
          ...getColors(dataStatus?.convite, dataStatus?.ativo),
          paddingLeft: 30,
          paddingRight: 30,
          borderRadius: 100,
        }}
      >
        {dataStatus?.convite === 'PENDENTE' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconAlert />{' '}
            <span style={{ marginLeft: 5 }}>Aguardando confirmação</span>
          </div>
        )}
        {dataStatus?.convite === 'REJEITADO' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {' '}
            <IconAlert />
            <span style={{ marginLeft: 5 }}> Convite rejeitado</span>
          </div>
        )}
        {(dataStatus?.convite === 'APROVADO' ||
          dataStatus?.convite === null) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {dataStatus?.ativo}
          </div>
        )}
      </div>
    );
  };

  const tableFormat = () => {
    const col = columns.map(item => {
      if (item.id === 'ativo') {
        return {
          header: 'Status',
          align: 'center',
          children: (_, item) => renderStatus(item),
        };
      }
      return item;
    });
    return col;
  };

  return (
    <S.Container>
      <>
        <C.TitleArea className="space">
          <span className="textArea">Clientes</span>
          <DefaultButton
            startIcon={<AddCircleOutline />}
            size="medium"
            sx={{ marginLeft: '10px' }}
            onClick={() => setOpenModal(true)}
          >
            ADICIONAR CLIENTE
          </DefaultButton>
        </C.TitleArea>
        <Grid item md={12} paddingTop={2}>
          <DefaultTable
            data={data || []}
            columns={tableFormat(columns)}
            setSelectedRows={setSelectedData}
            actions={actions}
            bulk={bulkActions}
            searchKeys={['nome']}
            loading={loadingTable}
            placeholder="Buscar por nome"
            sortBy={{ id: 'ativo', order: 'ASC' }}
            searchEvent={search =>
              trackEvent(user, 'Busca clientes configuração', null, search)
            }
            empty={{
              title: 'Nenhum cliente encontrado',
              description: 'Você ainda não cadastrou nenhum cliente.',
              image: 'frota.png',
            }}
          />
        </Grid>

        <ConfirmModal
          open={excluirConvite}
          handleClose={() => setExcluirConvite(null)}
          title="Deseja exluir o convite?"
          buttonText="Confirmar"
          onClick={() => handleDeleteConvite()}
          loading={false}
        />

        <ConfirmModal
          open={reenviar}
          handleClose={() => setReenviar(null)}
          title="Deseja reenviar o convite?"
          buttonText="Confirmar"
          onClick={() => handleReenviar()}
          loading={false}
        />
        {
          /* Confirmação de ativacao */
          idsAtivar && (
            <ConfirmModal
              open={idsAtivar.length > 0}
              handleClose={() => setIdsAtivar(null)}
              title={modalText}
              titleIcon={
                <EditOutlined
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
              }
              subtitle={companiesList}
              buttonText="Confirmar"
              onClick={() => confirmAtivar()}
              loading={false}
            />
          )
        }

        {
          /* Confirmação de desativacao */
          idsDesativar && (
            <ConfirmModal
              open={idsDesativar.length > 0}
              handleClose={() => setIdsDesativar(null)}
              title={modalText}
              titleIcon={
                <DeleteOutline
                  sx={{
                    color: theme.palette.semantics.feedback.attention.natural,
                  }}
                  fontSize="medium"
                />
              }
              subtitle={companiesList}
              buttonText="Confirmar"
              onClick={() => confirmDesativar()}
              loading={false}
            />
          )
        }
        {renderModal()}
      </>
    </S.Container>
  );
};
