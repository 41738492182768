// Styleds
import colors from 'styles/colors';

// React
import React, { useState, useEffect } from 'react';

// Material UI
import { Modal } from '@mui/material';

// Components

import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import CloseIcon from '@mui/icons-material/Close';
import * as S from './styled';

const RightModal = ({
  title,
  open,
  handleClose,
  children,
  icon,
  textButton,
  onConfirm,
  noClose,
}) => {
  // GENERAL STATES

  const [margin, setMargin] = useState('-550px');
  const [close, setClose] = useState(true);

  // ------------------------CONTROLE DE MODAL--------------------//
  useEffect(() => {
    if (open) {
      setClose(false);
      setTimeout(() => {
        setMargin('0px');
      }, 10);
    } else {
      behaviorCloseModal();
    }
  }, [open]);

  const behaviorCloseModal = async () => {
    setMargin('-550px');
    setTimeout(() => {
      handleClose();
      setClose(true);
    }, 380);
  };

  return (
    <>
      <Modal
        open={!close}
        onClose={behaviorCloseModal}
        disableEscapeKeyDown={false}
      >
        <S.Container margin={margin}>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              {icon && icon()}
              <h2>{title}</h2>
            </div>

            <CloseIcon
              onClick={behaviorCloseModal}
              htmlColor={colors.grey}
              style={{ cursor: 'pointer' }}
            />
          </S.Header>

          <S.Main>{children}</S.Main>

          <S.Footer>
            <div>
              <GhostButton onClick={behaviorCloseModal} size="medium">
                CANCELAR
              </GhostButton>

              <DefaultButton
                onClick={() => {
                  !noClose && behaviorCloseModal();
                  onConfirm();
                }}
                size="medium"
                sx={{ marginLeft: '20px' }}
              >
                {textButton || 'Salvar'}
              </DefaultButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>
    </>
  );
};

export default RightModal;
