import styled, { css } from 'styled-components';
import { Modal } from '@mui/material';

export const ModalMui = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 85vw;
  max-width: 1300px;
  max-height: 88vh;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px 30px;
  display: ${props => (props.inPrev ? 'none' : 'block')};
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      justify-content: start;

      .modalTitle {
        font-size: 25px;
        font-weight: 900;
        color: ${theme.palette.words.title.natural};
        margin-left: 10px;
      }
    }
  `}
`;

export const SerchLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Body = styled.div`
  ${({ theme, ...props }) => css`
    margin-top: 20px;

    .title-section {
      font-size: 25px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
      margin-bottom: 20px;
    }

    .templates {
      margin-bottom: 10px;
      opacity: ${props.inSearch ? 0.5 : 1};
      pointer-events: ${props.inSearch ? 'none' : 'all'};
    }

    .cardContent {
      width: 300px;

      @media (max-height: 700px) {
        height: 320px;
      }
    }

    .content {
      @media (max-height: 700px) {
        margin-bottom: -160px;
      }
    }
  `}
`;
