import * as types from './types';

export function updateVersion() {
  return {
    type: types.UPDATE_VERSION_REQUEST,
  };
}

export function updateVersionSuccess(payload) {
  return {
    type: types.UPDATE_VERSION_SUCCESS,
    payload,
  };
}
