export const getStatusColor = (status, theme) => {
  switch (status) {
    case 'CONCLUÍDO':
      return theme?.palette?.semantics?.feedback?.information?.natural;
    case 'PENDENTE':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'REPROVADO':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    case 'VENCIDO':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
  }
};

export const getCriticidadeColor = (status, theme) => {
  switch (status) {
    case 'PRIMÁRIA':
      return theme?.palette?.semantics?.feedback?.information?.natural;
    case 'MODERADA':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'GRAVE':
    case 'GRAVÍSSIMA':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    default:
      theme?.palette?.semantics?.feedback?.unknown?.natural;
  }
};

export const statusTabs = [
  { value: 'ABERTO', label: 'Abertos' },
  { value: 'PENDENTE', label: 'Pendentes' },
  { value: 'FINALIZADO', label: 'Finalizados' },
  { value: 'DELETADO', label: 'Excluídos' },
];
