import React, { useEffect, useState, forwardRef } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FormControl from '@mui/material/FormControl';
import { FormHelperText, IconButton, MenuItem } from '@mui/material';
import { useTheme } from 'styled-components';
import * as S from './styled';
import AlertModal from 'components/AlertModal';
import ConfirmModal from 'components/ConfirmModal';
import { AddCircleOutlineOutlined, DeleteOutline } from '@mui/icons-material';

// TODO Não fecha ao selecionar opção

const SelectCreatable = forwardRef(
  (
    {
      value,
      handleChange,
      data, // Itens a que são exibidos no select
      label,
      clear, // Hablita a função que limpa a seleção do campo
      type, // Tipagem para criar nova formatação de dados se necessario
      disabled,
      error,
      message,
      required,
      tam, // Opcional -> Aumentar o tamanho do componente caso esteja muito pequeno na aplicação
      handleNewOption, // Hablita o botao que adiciona items baseado no texto do searchbox
      handleRemoveOption, // Função executada ao reber um item que possua a prop 'removable'
      onFocus,
      confirm = false,
      deleteErrorMessage = '',
      setDeleteErrorMessage,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();
    const [search, setSearch] = useState('');
    const [finalResult, setFinalResult] = useState([]);
    const [controllerSelect, setControllerSelect] = useState(false);

    const [addModal, setAddModal] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);

    const formatData = () => {
      if (data && search) {
        const letters = [];
        for (const i in data) {
          const name = data[i].name.toLowerCase();
          if (name.includes(search.toLowerCase())) {
            letters.push(data[i]);
          }
        }
        setFinalResult(letters);
      } else if (data) {
        setFinalResult(data);
      }
    };

    useEffect(() => {
      formatData();
    }, [search, data]);

    const ITEM_HEIGHT = 20;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          // TODO altura fixa pra evitar sobreposição do input
          // minHeight: "250px",
          // maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
          height: '200px',
          width: 'inherit',
        },
      },
    };

    // Controller Handle and Close Select
    useEffect(() => {
      if (controllerSelect) {
        setTimeout(() => {
          window.onclick = event => {
            const __DomTokenList = Array.from(event.target.classList);
            if (!__DomTokenList.includes('searchBox')) {
              setControllerSelect(false);
            }
          };
        }, 100);
      } else {
        window.onclick = () => {};
      }

      return function cleanup() {
        window.onclick = () => {};
      };
    }, [controllerSelect]);

    return (
      <>
        <S.InputWrapper>
          <p
            style={{
              color: disabled
                ? theme.palette.words.subtitle.dark
                : theme.palette.words.subtitle.natural,
              font: 'normal medium 14px/20px Texta',
            }}
          >
            {label}
            <span
              style={{
                fontSize: '20px',
                fontWeight: 500,
                color: required
                  ? theme.palette.semantics.feedback.attention.dark
                  : theme.palette.system.transparent,
              }}
            >
              *
            </span>
          </p>
          <FormControl sx={{ width: tam || '100%', border: 'none' }}>
            <S.StyledTextField
              value={value ?? ''}
              renderValue={selected =>
                data.find(item => item.value === selected)?.name ?? ''
              }
              MenuProps={MenuProps}
              ref={ref}
              disabled={disabled}
              error={error}
              open={controllerSelect}
              onClick={
                !controllerSelect ? () => setControllerSelect(true) : undefined
              }
              onFocus={onFocus}
            >
              <S.SearchContainer>
                <S.SearchBox className="searchBox">
                  <S.SearchInput
                    type="text"
                    placeholder="Buscar..."
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className="searchBox"
                  />
                  <SearchOutlinedIcon
                    className="searchIcon"
                    htmlColor={theme.palette.semantics.feedback.unknown.natural}
                  />
                </S.SearchBox>
              </S.SearchContainer>

              {clear && (
                <S.ClearSelection onClick={() => handleChange('')}>
                  Limpar Seleção
                </S.ClearSelection>
              )}
              <S.OptionsArea className="ClickedArea">
                {finalResult.length > 0 &&
                  finalResult.map(item => (
                    <MenuItem
                      className="ClickedArea"
                      key={item.value}
                      label={item.name}
                      value={item.value}
                      onClick={() => {
                        handleChange(item.value);
                        setControllerSelect(false);
                      }}
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                      disabled={disabled || !!item.disabled}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                {finalResult.length === 0 && (
                  <S.EmptyOptions>Nenhuma opção disponível</S.EmptyOptions>
                )}
              </S.OptionsArea>
            </S.StyledTextField>
            {message && (
              <span
                style={{
                  color: theme.palette.semantics.feedback.attention.dark,
                  margin: '10px 0',
                }}
              >
                {message}
              </span>
            )}
          </FormControl>
        </S.InputWrapper>
      </>
    );
  },
);

export default SelectCreatable;
