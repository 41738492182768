import api from 'services/api';

export const getAllWorks = async query => {
  const res = await api.get('/vagas', { params: query });
  return res.data;
};

export const getCards = async query => {
  const res = await api.get('/cards-vagas', { params: query });
  return res.data;
};
