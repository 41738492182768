import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import { Divider, Grid } from '@mui/material';

// components
import Tabs from 'components/Tabs';
import EmptyCard from 'components/Cards/EmptyDataCard';

// Utils
import { formatNewHour } from 'utils/dates';

// styles
import * as S from './style';

const img = require('images/empty/desvio.png');

export const Reviews = ({ review }) => {
  const { configuracoes } = useSelector(state => state.conteudoProvider);
  // tabs
  const [status, setStatus] = useState(0);
  const [items, setItems] = useState([]);

  // reviews
  const [curtidas, setCurtidas] = useState([]);
  const [comentarios, setComentarios] = useState([]);

  useEffect(() => {
    setCurtidas(review?.curtidas);
    setComentarios(review?.comentarios);

    setItems([
      { value: 0, label: 'Comentários' },
      { value: 1, label: 'Curtidas' },
    ]);
  }, []);

  const handleStatusTab = async (event, newValue) => {
    setStatus(newValue);
  };

  const getAcron = str => {
    let matches = str.match(/\b(\w)/g);
    let acronym = matches.join('');
    return acronym.toUpperCase();
  };

  return (
    <>
      <Grid marginBottom={0} item md={12} display="flex">
        <Tabs value={status} items={items} onChange={handleStatusTab} />
      </Grid>
      <Divider />
      <div style={{ height: '20px' }} />
      {configuracoes?.comentarios_habilitados && (
        <S.Container>
          {status === 0 ? (
            <div>
              <S.Total>{`Total de Comentarios (${
                comentarios?.length || 0
              })`}</S.Total>
              {comentarios?.map((comentario, index) => {
                return (
                  <S.Comentario key={index}>
                    <div>
                      <Avatar
                        alt={getAcron(comentario.aluno)}
                        src={comentario.foto}
                        sx={{ width: 40, height: 40 }}
                      />
                      <div className="content">
                        <p className="nome">{comentario.aluno}</p>
                        <p className="data">{formatNewHour(comentario.data)}</p>
                      </div>
                    </div>
                    <p className="comentario">{comentario.comentario}</p>

                    {index !== comentarios.length - 1 && (
                      <Divider style={{ marginBottom: '15px' }} />
                    )}
                  </S.Comentario>
                );
              })}
              {comentarios?.length === 0 && (
                <S.EmptyView>
                  <div className="imageArea">
                    <img src={img} />
                  </div>
                  <div className="text">
                    Nenhum comentário para esse conteúdo
                  </div>
                </S.EmptyView>
              )}
            </div>
          ) : (
            <div>
              <S.Total>{`Total de Curtidas (${
                curtidas?.length || 0
              })`}</S.Total>
              {curtidas?.map((curtida, index) => {
                return (
                  <S.Curtida key={index}>
                    <div>
                      <Avatar
                        alt={getAcron(curtida.aluno)}
                        src={curtida.foto}
                        sx={{ width: 32, height: 32 }}
                      />
                      <div className="content">
                        <div>
                          <p className="nome">{`${curtida.aluno} `}</p>
                          <p className="curtida">{` curtiu este conteúdo.`}</p>
                        </div>
                        <p className="data">{formatNewHour(curtida.data)}</p>
                      </div>
                    </div>

                    {index !== curtidas.length - 1 && (
                      <Divider
                        style={{ marginTop: '15px', marginBottom: '15px' }}
                      />
                    )}
                  </S.Curtida>
                );
              })}
              {curtidas?.length === 0 && (
                <S.EmptyView>
                  <div className="imageArea">
                    <img src={img} />
                  </div>
                  <div className="text">Nenhuma curtida para esse conteúdo</div>
                </S.EmptyView>
              )}
            </div>
          )}
        </S.Container>
      )}

      {!configuracoes?.comentarios_habilitados && (
        <EmptyCard
          image="frota.png"
          title="Curtidas e Comentários não estão habilitados para esse conteudo"
          subtitle="Para habilitar Curtidas e Comentários, vá em Configurações e ative a opção."
        />
      )}
    </>
  );
};
