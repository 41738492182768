import { toast } from 'react-toastify';

export const moreMedia = ({
  key,
  type,
  block,
  counters,
  setCounters,
  setMedia,
  media,
  errors,
  setErrors,
}) => {
  if (block) return;
  // if (counters[key] >= 3) {
  //   toast.warning('Não é possível adicionar mais de 3 mídias do mesmo tipo');
  //   return;
  // }
  setCounters({
    ...counters,
    [key]: counters[key] + 1,
  });

  media.unshift({
    draggableId: `id_${media.length}`,
    tipo: type,
    ordem: `${media.length}`,
    link: '',
    descricao: '',
    titulo: '',
  });

  if (errors) setErrors(null);

  setMedia([...media]);
};

export const minusMedia = ({
  key,
  type,
  block,
  counters,
  setCounters,
  setMedia,
  media,
  skip,
  setCurrentItem,
  setOpenConfirmDelete,
  blockAction,
  errors,
  setErrors,
}) => {
  if (block) return;
  if (counters[key] <= 0) return;
  const del = media.findIndex(item => item.tipo === type);
  if ((media[del].titulo || media[del].descricao || media[del].link) && !skip) {
    blockAction();
    return;
  }

  setCounters({
    ...counters,
    [key]: counters[key] - 1,
  });

  setCurrentItem(null);
  setOpenConfirmDelete(false);
  const newMedia = media.filter((item, index) => index !== del);
  if (errors) setErrors(null);
  setMedia(newMedia);
};

export const validation = media => {
  const itemErrors = [];

  media.forEach((item, index) => {
    if (!item.link || !item.titulo || !item.descricao) {
      itemErrors.push({
        index,
        link: !item.link,
        titulo: !item.titulo,
        descricao: !item.descricao,
      });
    }
  });

  return itemErrors.length > 0 ? itemErrors : false;
};
