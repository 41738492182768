/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// Components
import TextInput from 'components/Inputs/TextField';
import DropZoneArchives from 'components/Inputs/DropZoneCustom';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';

// Components MUI
import { Grid } from '@mui/material';

// aCTIONS
import { fields } from './constants';
import { saveUpdate } from '../services';
import { verifyErrors } from './actions';

export const Evidencias = ({
  setChanged,
  disabled,
  trySave,
  setTrySave,
  setCurrentStep,
  saveImg,
  setPosto,
  setSaveImg,
  posto,
  steps,
  cliente,
  setLoading,
  id,
  isEdit,
}) => {
  const compose = fields[cliente];

  const [obs, setObs] = useState(posto.observacoes ? posto.observacoes : '');
  const [archive, setArchive] = useState(
    posto?.avaliacao_risco ? posto?.avaliacao_risco : null,
  );
  const [addImages, setAddImages] = useState(null);
  const [editImages, setEditImages] = useState(null);
  const [deleteImages, setDeleteImages] = useState(null);

  useEffect(() => {
    const data = {
      avaliacao_risco: archive,
    };
    setChanged(verifyErrors(compose, data, true));
  }, []);

  const changeMonitoring = (value, func) => {
    if (!func) {
      setChanged(true);
      return;
    }

    if (value?.length >= 1024) return;
    func(value);
    setChanged(true);
  };

  const imageProcess = () => {
    const idsDeleted = deleteImages?.map(item => item.id);
    const idsEdited = editImages?.map(item => item.id);

    const currentImages = posto?.imagens?.filter(
      item => !idsDeleted?.includes(item.id) && !idsEdited?.includes(item.id),
    );

    return [
      ...(currentImages || []),
      ...(editImages || []),
      ...(addImages || []),
    ];
  };

  // ------------------------SAVE INFORMATIONS----------------//
  useEffect(() => {
    if (trySave) {
      const data = {
        observacoes: obs,
        avaliacao_risco: archive,
        imagens: imageProcess(),
      };

      const hasError = verifyErrors(compose, data);

      if (hasError) {
        setTrySave(false);
      } else {
        save(data);
      }
    }
  }, [trySave]);

  const save = async data => {
    setLoading(true);
    const res = await saveUpdate({ ...posto, ...data, etapa: 4 }, id);
    setTrySave(false);
    setLoading(false);

    if (res?.success) {
      setChanged(false);
      toast.success(res?.message);
      setPosto(res?.posto);
      !isEdit && setCurrentStep(steps[4]);
    } else {
      toast.error(res?.message);
    }
  };

  // -------------------------IMAGES CONTROLL-------------------------------//
  const addImage = item => {
    setAddImages(item);
  };

  const deleteImagem = item => {
    setDeleteImages(item);
  };

  const editImagem = item => {
    setEditImages(item);
  };

  return (
    <S.Container>
      <Grid item xs={12} sm={12}>
        <InputAttachedFile
          idInput="input_file_layout"
          label="Avaliação de Risco"
          fileUrl={archive}
          setFileUrl={value => changeMonitoring(value, setArchive)}
          fileDir="layout"
          required={compose[0]?.required}
        />
      </Grid>
      <Grid item xs={12} paddingBottom="10px" marginTop="20px">
        <DropZoneArchives
          data={posto?.imagens}
          multiple
          fileDir="layout"
          addFunction={addImage}
          deleteFunction={deleteImagem}
          editFunction={editImagem}
          saveNow={saveImg}
          setSaveNow={setSaveImg}
          changeMonitoring={changeMonitoring}
          haveLegend
          label="Adicionar Evidências"
          titleButton="Adicionar Imagens"
          typesString="jpeg, jpg, png, bmp, pdf"
          types={['image/*', 'application/pdf']}
          unicCenter
        />
      </Grid>
      <TextInput
        label="Observações"
        placeholder="Insira sua observação"
        value={obs}
        onChange={e => changeMonitoring(e.target.value, setObs)}
        InputProps={{ multiline: true }}
        multiline
        rows={6}
        disabled={disabled}
        variant={disabled ? 'filled' : 'outlined'}
      />
      <div className="counter">{`${obs ? obs.length : 0}/1023`}</div>
    </S.Container>
  );
};
