import { Table } from '../../components';

export const FilterTable = ({
  data,
  bulk,
  empty,
  reset,
  sizes,
  sortBy,
  filters,
  persist,
  columns,
  actions,
  loading,
  setQuery,
  pageCount,
  emptyFill,
  hideLines,
  compactRow,
  onClickRow,
  fixedItems,
  searchKeys,
  transparent,
  searchEvent,
  placeholder,
  visibleBulk,
  notClickable,
  actionsTitle,
  cachePerPage,
  local = true,
  headerActions,
  visualizedKey,
  setSelectedRows,
  loadingSelection,
  selectedByDefault,
}) => {
  return (
    <Table.Provider persist={persist}>
      <Table.Container transparent={transparent}>
        {!local && <Table.Dynamic setQuery={setQuery} sortBy={sortBy} />}
        <Table.Header transparent={transparent}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '15px',
            }}
          >
            <Table.Input
              local={local}
              loading={loading}
              placeholder={placeholder}
              searchEvent={searchEvent}
            />
            {filters && <Table.Filter filters={filters} />}
          </div>
          {headerActions || (
            <Table.Bulk
              options={bulk}
              disabled={loading}
              visible={visibleBulk}
            />
          )}
        </Table.Header>
        <Table.Body
          data={data}
          local={local}
          empty={empty}
          reset={reset}
          sortBy={sortBy}
          columns={columns}
          actions={actions}
          loading={loading}
          pageCount={pageCount}
          emptyFill={emptyFill}
          hideLines={hideLines}
          onClickRow={onClickRow}
          compactRow={compactRow}
          fixedItems={fixedItems}
          searchKeys={searchKeys}
          transparent={transparent}
          cachePerPage={cachePerPage}
          actionsTitle={actionsTitle}
          notClickable={notClickable}
          visualizedKey={visualizedKey}
          setSelectedRows={setSelectedRows}
          loadingSelection={loadingSelection}
          selectedByDefault={selectedByDefault}
        />
      </Table.Container>
      <Table.Footer>
        <Table.Counter
          sizes={sizes}
          fixedValue={fixedItems}
          disabled={loading}
        />
        <Table.Pagination disabled={loading} />
      </Table.Footer>
    </Table.Provider>
  );
};
