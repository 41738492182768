export const tabs = [
  { value: 'estatisticas', label: 'Estatísticas', filters: true },
  {
    value: 'avaliacoes',
    label: 'Avaliações de direção',
    link: { label: 'AVALIAÇÕES', url: '/avaliacoes' },
    plans: [140],
  },
  {
    value: 'avaliacoes_descarga',
    label: 'Avaliações de descarga',
    link: { label: 'AVALIAÇÕES', url: '/avaliacoes-descarga' },
    plans: [140],
  },
  {
    value: 'desvios',
    label: 'Desvios',
    link: { label: 'DESVIOS', url: '/desvios' },
    plans: [100, 110, 140],
  },
  {
    value: 'formularios',
    label: 'Formulários',
    link: { label: 'FORMULÁRIOS', url: '/formularios' },
    plans: [50],
  },
  {
    value: 'acoes',
    label: 'Ações e Suspensões',
    link: { label: 'AÇÕES E SUSPENSÕES', url: '/acoes-suspensoes' },
    plans: [100, 110, 140],
  },
  {
    value: 'acidentes_multas',
    label: 'Acidentes e Multas',
    plans: [10],
  },
];
