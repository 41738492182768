import styled from 'styled-components';
import Button from '@mui/material/Button';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  padding: 0px 0px 0px 5px;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.brand.primary.natural};
  letter-spacing: 0px;
  text-transform: capitalize;
  font-weight: 900;
`;

export const Paragraph = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: gray;
  letter-spacing: 0px;
  padding: 0px 0px 0px 5px;
`;

export const Main = styled.div`
  display: flex;
  height: 70%;
  width: 100%;
`;

export const RightHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  background: ${({ theme }) => theme.palette.brand.primary.background};
  position: absolute;
  right: 25px;
  bottom: 15px;
`;

export const RightFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled(Button)`
  min-width: 15px !important;
  max-width: 15px !important;
  padding: 0px !important;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  :hover {
    transform: scale(1.1);
  }

  .MuiButton-startIcon {
    padding: 0px !important;
    margin: 0px !important;
  }
`;
