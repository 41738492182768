// React

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';

// Componentes do material
import Grid from '@mui/material/Grid';
import { SaveAlt } from '@mui/icons-material';
import { toast } from 'react-toastify';

// Components
import { ReactComponent as SeguindoIco } from 'images/icons/seguindo.svg';
import { ReactComponent as SeguirIco } from 'images/icons/seguir.svg';
import { ReactComponent as EmailIco } from 'images/icons/configuracoes/email.svg';
import GhostButton from 'components/Buttons/Ghost';
import CardIndicadores from 'components/Cards/Indicador';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EmailSeguidor from './EmailSeguidor';

// Requests
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import { useTheme } from 'styled-components';
import * as request from './services';

// Utils
import { columns } from './columns';
import ListaCaes from './ListaCaes';
import { fields, caeFields } from './constants';
import { DefaultTable } from 'components/_Table/templates/default';
import { useQuery } from 'react-query';
import { Tooltip } from '@mui/material';
import { usePlans } from 'hooks/usePlans';

const initialCards = [{}, {}, {}, {}, {}];

// ---------------------------------------LAYOUT PAGE--------------------------------------------//

const LayoutDescargaProvider = () => {
  const theme = useTheme();
  const { isProvider } = usePlans();
  // GENERAL STATES
  const initial = useRef(true);
  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [selectedLayouts, setSelectedLayouts] = useState(null);
  const [caes, setCaes] = useState(null);
  const [clickedGasStation, setClickedGasSation] = useState('');
  const [selectedCard, setSelectedCard] = useState(null);
  const [openEmailSeguidor, setOpenEmailSeguidor] = useState(null);

  const [selectOp, setselectOp] = useState(null);

  const navigate = useNavigate();

  const iconsCard = [
    'Grupo12752.svg',
    'Grupo12754.svg',
    'Grupo12753.svg',
    'symbol.svg',
  ];

  // REDUX
  const filter = useSelector(state => {
    return state.filterProvider;
  });
  const filterLayout = useSelector(state => {
    return state.filterLayoutProvider;
  });

  // --------------------- REQUESTS -------------------//

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }
    setResetTable(true);
  }, [filter, filterLayout, selectedCard]);

  const {
    isLoading: loadingLayouts,
    isFetching,
    data: resData,
    refetch: fetchData,
  } = useQuery(
    ['layout-provider-index', query],
    () => query && request.requestLayouts(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => resetTable && setResetTable(false),
    },
  );

  const { isLoading: loadingCards, data: resCards } = useQuery(
    ['layout-provider-cards', { ...filter, ...filterLayout }],
    () => request.requestCards({ ...filter, ...filterLayout }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // ---------------------CARDS -----------------------------//

  const handleClickCard = type => {
    if (type === selectedCard) setSelectedCard(null);
    else setSelectedCard(type);
  };

  const renderCards = () => {
    const cards = resCards?.data?.data || initialCards;
    return (
      cards && (
        <Grid item>
          <Grid container spacing={2}>
            {cards.map((item, k) => {
              let md = 4;
              if (12 / cards.length < 4) md = 12 / cards.length;
              return (
                <Grid
                  item
                  md={md}
                  key={k}
                  style={{ fontSize: '13px', fontWeight: 600 }}
                >
                  <CardIndicadores
                    value={item.ids?.length}
                    icon={iconsCard[k]}
                    text={item.text}
                    border={`1px solid ${theme.palette.brand.primary.light}4D`}
                    handleClick={() => handleClickCard(item.type)}
                    selected={selectedCard === item.type}
                    loading={loadingCards}
                    disabled={isFetching}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )
    );
  };

  // ---------------------TABLE-----------------------------//

  // Excel Fields
  const handleRequestExcelLayout = async () => {
    const formatedDate = formatNameDate(new Date());

    const res = await request.requestExcel({
      ...query,
      excelFields: fields,
      ids: selectedLayouts,
    });
    if (res.data && res.data.data.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `layouts_${formatedDate}`,
      });
  };

  const handleRequestExcelCae = async () => {
    const formatedDate = formatNameDate(new Date());

    const resCae = await request.requestCaeExcel({
      ...query,
      excelFields: caeFields,
      ids: selectedLayouts,
    });
    if (resCae.data && resCae.data.data.excel.length > 0)
      ExportToExcel({
        excel: resCae.data.data.excel,
        name: `caes_${formatedDate}`,
      });
  };

  const handleOpenNewTab = ids => {
    let newTab = null;
    if (typeof ids === 'object' && ids.length > 0) {
      for (const i in ids) {
        newTab = window.open(`/layouts/${ids[i]}`, ids[i]);
      }
      return newTab;
    }
    return window.open(`/layout/${ids}`);
  };

  const handleOpenCae = id => {
    if (id) navigate(`/cae/${id}`);
    else toast.error('Erro ao encontrar id da CAE');
  };

  const handleOpenEmailSeguidor = async id => {
    const resPosto = await request.getSeguidores(id);
    if (!resPosto?.data?.data) {
      toast.error(resPosto?.data?.message);
      return;
    }
    setOpenEmailSeguidor(resPosto.data.data);
  };

  const handleSeguir = async posto => {
    const idPosto = posto.id;
    const seguindo = posto.seguindo;
    let res;
    if (!seguindo) res = await request.seguirPosto(idPosto);
    else res = await request.desseguirPosto(idPosto);

    if (res?.status == 200) {
      if (res.data?.message) toast.success(res.data.message);
      await handleOpenEmailSeguidor(posto.id);
      await fetchData();
      return;
    }
    // Erro req
    if (res.data?.message) toast.error(res.data.message);
  };

  const handleSalvarEmails = async (id_posto, emails) => {
    const res = await request.updateEmails(id_posto, emails);
    if (res?.status == 200) {
      if (res.data?.message) toast.success(res.data.message);
      await handleOpenEmailSeguidor(id_posto);
      await fetchData();
      return;
    }
    if (res.data?.message) toast.error(res.data.message);
  };

  // Bulk Menu
  const bulkOptions = [
    {
      title: 'Exportar Layout(Excel)',
      function: handleRequestExcelLayout,
    },
    {
      title: 'Exportar CAE (Excel)',
      function: handleRequestExcelCae,
    },
  ];

  const actions = [
    {
      title: 'Ver Layout',
      function: id => navigate(`/layout/${id}`),
    },
    {
      title: 'Imprimir',
      function: id => navigate(`/layout/pdf/${id}`),
    },
    {
      title: 'Abrir em nova guia',
      function: id => handleSelectCae(id),
    },
    {
      title: 'Ver CAES',
      function: id => openCaeModal(id),
      visible: item => item.cae.length > 0,
    },
  ];

  // ---------------------COLUMNS-----------------------------//
  const formatCol = () => {
    const cols = columns.map(i => {
      if (i.id === 'status') {
        return { ...i, onClick: (_, item) => openCaeModal(item.id) };
      }
      if (i.id === 'nome') {
        return { ...i, onClick: (_, item) => handleSelectCae(item.id) };
      }
      if (i.id === 'seguindo') {
        return {
          ...i,
          children: (value, item) => (
            <div style={{ whiteSpace: 'nowrap' }}>
              {item.seguindo ? (
                <SeguindoIco
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleOpenEmailSeguidor(item.id);
                  }}
                />
              ) : (
                <SeguirIco
                  onClick={() => {
                    handleOpenEmailSeguidor(item.id);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              )}{' '}
              {isProvider && (
                <>
                  {item.emails_posto?.length ? (
                    <Tooltip title="Notificações por email configuradas.">
                      <EmailIco color="#BDCC30" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Notificações por email não configuradas.">
                      <EmailIco color="#95AAC966" />
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          ),
        };
      }
      return i;
    });
    return cols;
  };

  // ------------------------RENDER CAE MODAL----------------------------------//
  const openCaeModal = id => {
    const data = resData?.data?.rows || [];
    for (const i in data) {
      if (data[i].id === id && data[i].cae.length > 0) {
        setCaes(data[i].cae);
        setClickedGasSation(data[i].nome);
        return;
      }
    }
  };

  const handleSelectCae = id => {
    handleOpenNewTab(id);
  };

  const handleOpenOptionsExport = event => {
    setselectOp(event.currentTarget);
  };

  const handleClose = () => {
    setselectOp(null);
  };

  const open = Boolean(selectOp);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Grid
        marginBottom={1}
        item
        md={12}
        display="flex"
        justifyContent="space-between"
      >
        <h1>Layout de Descarga</h1>

        <span>
          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={handleOpenOptionsExport}
            aria-describedby={id}
          >
            EXPORTAR
          </GhostButton>
          <Popover
            id={id}
            open={open}
            anchorEl={selectOp}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <List
              sx={{ width: '100%', minWidth: 120, bgcolor: 'background.paper' }}
              aria-label="contacts"
            >
              <ListItem disablePadding>
                <ListItemButton onClick={handleRequestExcelLayout}>
                  <ListItemText primary="Layout" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={handleRequestExcelCae}>
                  <ListItemText primary="Cae" />
                </ListItemButton>
              </ListItem>
            </List>{' '}
          </Popover>
        </span>
      </Grid>
      <Grid marginBottom={3} item md={12}>
        {renderCards()}
      </Grid>
      <Grid item md={12}>
        <DefaultTable
          data={resData?.data?.rows || []}
          columns={formatCol()}
          setSelectedRows={setSelectedLayouts}
          loading={loadingLayouts}
          pageCount={resData?.data?.total || 0}
          local={false}
          bulk={bulkOptions}
          actions={actions}
          reset={resetTable}
          // onClickRow={handleSelectCae}
          setQuery={q =>
            setQuery({ ...q, ...filter, ...filterLayout, card: selectedCard })
          }
          sortBy={{ id: 'updatedAt', order: 'DESC' }}
          placeholder="Buscar por Cliente ou IBM"
          empty={{
            title: 'Ops! Você não tem nenhum layout de descarga.',
            description: 'Verifique os filtros selecionados.',
          }}
        />
      </Grid>
      <ListaCaes
        caes={caes}
        handleCaes={setCaes}
        posto={clickedGasStation}
        handleOpenCae={handleOpenCae}
      />

      {openEmailSeguidor && (
        <EmailSeguidor
          posto={openEmailSeguidor}
          handleClose={() => setOpenEmailSeguidor(null)}
          handleSeguir={handleSeguir}
          handleSalvar={handleSalvarEmails}
        />
      )}
    </div>
  );
};

export default LayoutDescargaProvider;
