// Styles

// React
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import Tabs from 'components/Tabs';
import { Divider } from '@mui/material';
import { Users } from './Users';
import { General } from './General';

// Components MUI
import * as S from './styled';

// Utils
import { tabs } from './constants';

export const Reports = ({
  selectsUsers,
  selectsGeneral,

  currentGeneral,
  currentUser,

  setCurrentGeneral,
  setCurrentUser,
}) => {
  // Redux
  const formularios = useSelector(state => state.formularios);

  // Tab States
  const [currentTab, setCurrentTab] = useState(0);

  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const myTab = searchParams.get('tab');
    if (myTab) {
      setCurrentTab(+myTab);
      searchParams.delete('tab');
      searchParams.delete('step');
      searchParams.set('reset', 1);
      location.search = '';
      window.history.replaceState(
        {},
        {},
        `/formularios/configuracao/editar/${formularios.id}`,
      );
    }
  }, [location]);

  return (
    <S.Container>
      <Tabs
        value={currentTab}
        items={tabs}
        onChange={(e, tab) => setCurrentTab(tab)}
      />
      <Divider style={{ marginBottom: '30px' }} />

      <S.SliderBox>
        {currentTab === 0 && (
          <S.SlideComponent className="general">
            <General
              periods={selectsGeneral}
              currentTime={currentGeneral}
              setCurrentTime={setCurrentGeneral}
            />
          </S.SlideComponent>
        )}
        {currentTab === 1 && (
          <S.SlideComponent className="users">
            <Users
              select={selectsUsers}
              currentSelect={currentUser}
              setCurrentSelect={setCurrentUser}
            />
          </S.SlideComponent>
        )}
      </S.SliderBox>
    </S.Container>
  );
};
