import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4b5166;
  border-top: 1px solid #95aac94d;
  padding: 0.5rem 0;

  .text {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
  }

  .name {
    width: 100%;
    max-width: 10vw;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: normal normal medium 16px/20px Texta;
  }

  .desvios {
    font-size: 16px;
    font-style: medium;
    letter-spacing: 0px;
    color: #bdcc30;
    white-space: nowrap;
  }
`;
