/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import React, { useEffect, useState } from 'react';

// Components
import TextInput from 'components/Inputs/TextField';

// Material UI
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// CKEditor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const Texto = ({
  item,
  setChanged,
  media,
  index,
  setOpenConfirmDelete,
  deleteMedia,
  error,
  isPublished,
}) => {
  const theme = useTheme();
  const [text, setText] = useState(item.link);
  const [textTitle, setTextTitle] = useState(item.titulo);
  const [textDescription, setTextDescription] = useState(item.descricao);

  useEffect(() => {
    Object.assign(media[index], {
      link: text,
      tipo: 'TEXTO',
      descricao: textDescription,
      titulo: textTitle,
    });
    setChanged(true);
  }, [text, textTitle, textDescription]);

  const hanldeDelete = () => {
    if (text || textTitle || textDescription) {
      setOpenConfirmDelete(true);
    } else {
      setChanged(true);
      deleteMedia();
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={() => hanldeDelete()}>
          <DeleteOutlineOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.attention.natural}
          />
        </IconButton>
      </div>

      <S.Container>
        <TextInput
          label="Titulo"
          placeholder="Titulo"
          value={textTitle}
          onChange={e => setTextTitle(e.target.value.slice(0, 200))}
          error={error?.titulo && !textTitle}
          disabled={isPublished && !!item.id}
        />
        <TextInput
          label="Descrição"
          placeholder="Descrição"
          value={textDescription}
          onChange={e => setTextDescription(e.target.value.slice(0, 200))}
          error={error?.descricao && !textDescription}
          disabled={isPublished && !!item.id}
        />
        <S.MediaArchive isError={error?.link && !text}>
          <S.Label>Texto</S.Label>
          <CKEditor
            editor={ClassicEditor}
            config={{
              language: { ui: 'pt', content: 'pt' },
              toolbar: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'indent',
                'outdent',
                '|',
                'blockQuote',
                'insertTable',
                'mediaEmbed',
                'undo',
                'redo',
              ],
            }}
            data={text}
            height="25em"
            onChange={(e, editor) => setText(editor.getData())}
            disabled={isPublished && !!item.id}
          />
        </S.MediaArchive>
      </S.Container>
    </div>
  );
};
