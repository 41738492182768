import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

export const options = [
  {
    id: 0,
    title: 'Quiz',
    type: 'quiz',
    Icon: params => <OfflineBoltOutlinedIcon {...params} />,
  },
  {
    id: 1,
    title: 'Teste Online',
    type: 'test',
    Icon: params => <CheckCircleOutlineOutlinedIcon {...params} />,
  },
];

export const validation = questions => {
  let errors = [];

  questions.forEach((item, index) => {
    if (!item.pergunta) {
      errors.push({
        index,
        pergunta: true,
      });
    }
    item.alternativas.forEach((alt, altIndex) => {
      if (!alt.alternativa) {
        errors.push({
          index,
          altIndex,
          altIndex,
          alternativa: true,
        });
      }
    });
  });

  return errors.length > 0 ? errors : null;
};
