import { useTheme } from 'styled-components';

import EventNoteIcon from '@mui/icons-material/EventNote';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import * as S from './styled';

// Descrição do formulário no card
export const formatDescription = (text, small, ignore) => {
  let description = null;
  if (text && text.length >= 80 && !small && !ignore) {
    description = `${text.slice(0, 77)}...`;
  } else if (text && text.length >= 44 && small && !ignore) {
    description = `${text.slice(0, 41)}...`;
  } else if (text && text.length >= 290 && !small && ignore) {
    description = `${text.slice(0, 287)}...`;
  } else {
    description = text || 'Sem descrição';
  }
  return description;
};

// Titulo do formulário no card
export const formatTitle = text => {
  let description = null;
  if (text && text.length >= 29) {
    description = `${text.slice(0, 29)}...`;
  } else {
    description = text || 'Sem Título';
  }
  return description;
};

// Todos os status do formulario para o card, incluindo from_status e status do usuario
export const formatStatus = status => {
  const theme = useTheme();
  switch (status?.toUpperCase()) {
    case 'ATIVO':
      return (
        <S.StatusArea>
          <VisibilityOutlinedIcon
            htmlColor={theme.palette.brand.secondary.natural}
          />
          <span style={{ color: theme.palette.brand.secondary.natural }}>
            Ativo
          </span>
        </S.StatusArea>
      );
    case 'FINALIZADO':
      return (
        <S.StatusArea>
          <VisibilityOffOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.unknown.natural}
          />
          <span
            style={{ color: theme.palette.semantics.feedback.unknown.natural }}
          >
            Finalizado
          </span>
        </S.StatusArea>
      );
    case 'CONCLUIDO':
      return (
        <S.StatusArea>
          <VisibilityOffOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.unknown.natural}
          />
          <span
            style={{ color: theme.palette.semantics.feedback.unknown.natural }}
          >
            Concluido
          </span>
        </S.StatusArea>
      );
    case 'RASCUNHO':
      return (
        <S.StatusArea>
          <ModeEditOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.warning.natural}
          />
          <span
            style={{ color: theme.palette.semantics.feedback.warning.natural }}
          >
            Rascunho
          </span>
        </S.StatusArea>
      );
    case 'AGENDADO':
      return (
        <S.StatusArea>
          <EventNoteIcon
            htmlColor={theme.palette.semantics.feedback.information.natural}
          />
          <span
            style={{
              color: theme.palette.semantics.feedback.information.natural,
            }}
          >
            Agendado
          </span>
        </S.StatusArea>
      );
    case 'PENDENTE':
      return (
        <S.StatusArea>
          <ModeEditOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.warning.natural}
          />
          <span
            style={{ color: theme.palette.semantics.feedback.warning.natural }}
          >
            Pendente
          </span>
        </S.StatusArea>
      );
    case 'INICIADO':
      return (
        <S.StatusArea>
          <PlayCircleOutlineOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.warning.natural}
          />
          <span
            style={{ color: theme.palette.semantics.feedback.warning.natural }}
          >
            Iniciado
          </span>
        </S.StatusArea>
      );
    case 'ATRASADO':
      return (
        <S.StatusArea>
          <QueryBuilderOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.attention.natural}
          />
          <span
            style={{
              color: theme.palette.semantics.feedback.attention.natural,
            }}
          >
            Atrasado
          </span>
        </S.StatusArea>
      );
    default:
      return (
        <S.StatusArea>
          <span>Indisponivel</span>
        </S.StatusArea>
      );
  }
};

// Formatação de status para o header na pagina de detalhe/criação
export const formatStyleHeader = status => {
  const theme = useTheme();
  switch (status.toUpperCase()) {
    case 'ATIVO':
      return {
        status,
        color: theme.palette.brand.secondary.natural,
        background: theme.palette.brand.secondary.background,
      };
    case 'FINALIZADO':
      return {
        status,
        color: theme.palette.semantics.feedback.unknown.natural,
        background: theme.palette.semantics.feedback.unknown.light,
      };
    case 'RASCUNHO':
      return {
        status,
        color: theme.palette.semantics.feedback.warning.natural,
        background: theme.palette.semantics.feedback.warning.light,
      };
    case 'AGENDADO':
      return {
        status,
        color: theme.palette.semantics.feedback.information.natural,
        background: theme.palette.semantics.feedback.information.light,
      };
    default:
      return {};
  }
};

// Formatação do status do formulario no header do modal de respostas
export const formatStatusResponse = (status, fim) => {
  const theme = useTheme();
  switch (status?.toUpperCase()) {
    case 'ATIVO':
      return (
        <S.StatusArea className="header">
          <VisibilityOutlinedIcon
            htmlColor={theme.palette.brand.secondary.natural}
          />
          <span
            style={{ color: theme.palette.brand.secondary.natural }}
          >{`Ativo ${
            fim ? `até dia ${new Date(fim).toLocaleDateString()}` : ''
          }`}</span>
        </S.StatusArea>
      );
    case 'FINALIZADO':
      return (
        <S.StatusArea className="header">
          <VisibilityOffOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.attention.natural}
          />
          <span
            style={{
              color: theme.palette.semantics.feedback.attention.natural,
            }}
          >{`Finalizado ${
            fim ? `em ${new Date(fim).toLocaleDateString()}` : ''
          }`}</span>
        </S.StatusArea>
      );
    case 'AGENDADO':
      return (
        <S.StatusArea className="header">
          <VisibilityOffOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.unknown.natural}
          />
          <span
            style={{ color: theme.palette.semantics.feedback.unknown.natural }}
          >
            Agendado
          </span>
        </S.StatusArea>
      );
    default:
      return (
        <S.StatusArea>
          <span>Indisponivel</span>
        </S.StatusArea>
      );
  }
};

// Formataçao do status do usuario na linha acima do header do modal de resposta
export const formatStatusUser = status => {
  const theme = useTheme();
  switch (status?.toUpperCase()) {
    case 'INICIADO':
      return (
        <S.StatusArea
          className="user"
          bg={theme.palette.semantics.feedback.information.light}
        >
          <span
            style={{
              color: theme.palette.semantics.feedback.information.natural,
            }}
          >
            Iniciado
          </span>
        </S.StatusArea>
      );
    case 'PENDENTE':
      return (
        <S.StatusArea
          className="user"
          bg={theme.palette.semantics.feedback.warning.light}
        >
          <span
            style={{ color: theme.palette.semantics.feedback.warning.natural }}
          >
            Pendente
          </span>
        </S.StatusArea>
      );
    case 'CONCLUIDO':
      return (
        <S.StatusArea
          className="user"
          bg={theme.palette.semantics.feedback.success.light}
        >
          <span
            style={{ color: theme.palette.semantics.feedback.success.natural }}
          >
            Concluído
          </span>
        </S.StatusArea>
      );
    case 'ATRASADO':
      return (
        <S.StatusArea
          className="user"
          bg={theme.palette.semantics.feedback.attention.light}
        >
          <span
            style={{
              color: theme.palette.semantics.feedback.attention.natural,
            }}
          >
            Atrasado
          </span>
        </S.StatusArea>
      );
    case 'FINALIZADO':
      return (
        <S.StatusArea
          className="user"
          bg={theme.palette.semantics.feedback.success.light}
        >
          <span
            style={{ color: theme.palette.semantics.feedback.success.natural }}
          >
            Concluído
          </span>
        </S.StatusArea>
      );
    case 'ASSINADO':
      return (
        <S.StatusArea
          className="user"
          bg={theme.palette.semantics.feedback.success.light}
        >
          <span
            style={{ color: theme.palette.semantics.feedback.success.natural }}
          >
            Assinado
          </span>
        </S.StatusArea>
      );
    case 'NAO CONCLUIDO':
      return (
        <S.StatusArea
          className="user"
          bg={theme.palette.semantics.feedback.attention.light}
        >
          <span
            style={{
              color: theme.palette.semantics.feedback.attention.natural,
            }}
          >
            Não concluído
          </span>
        </S.StatusArea>
      );
    default:
      return (
        <S.StatusArea>
          <span>Indisponivel</span>
        </S.StatusArea>
      );
  }
};

// Calcula o percentual de atendimento para fazer a barra de progresso do card
export const calcPercente = percent => {
  const theme = useTheme();
  if (!percent) percent = 0;

  if (percent === 0) {
    return (
      <S.PecentResponse
        percent={percent}
        color={theme.palette.semantics.feedback.unknown.natural}
      >
        <div className="barArea">
          <div className="out" />
          <div className="in" />
        </div>
        <div className="percent">{`${percent} %`}</div>
      </S.PecentResponse>
    );
  }
  if (percent > 0 && percent < 50) {
    return (
      <S.PecentResponse
        percent={percent}
        color={theme.palette.semantics.feedback.attention.natural}
      >
        <div className="barArea">
          <div className="out" />
          <div className="in" />
        </div>
        <div className="percent">{`${percent} %`}</div>
      </S.PecentResponse>
    );
  }
  if (percent >= 50 && percent < 70) {
    return (
      <S.PecentResponse
        percent={percent}
        color={theme.palette.semantics.feedback.warning.natural}
      >
        <div className="barArea">
          <div className="out" />
          <div className="in" />
        </div>
        <div className="percent">{`${percent} %`}</div>
      </S.PecentResponse>
    );
  }
  if (percent >= 70 && percent <= 99) {
    return (
      <S.PecentResponse
        percent={percent}
        color={theme.palette.semantics.feedback.information.natural}
      >
        <div className="barArea">
          <div className="out" />
          <div className="in" />
        </div>
        <div className="percent">{`${percent} %`}</div>
      </S.PecentResponse>
    );
  }
  return (
    <S.PecentResponse
      percent={percent}
      color={theme.palette.semantics.feedback.success.natural}
    >
      <div className="barArea">
        <div className="out" />
        <div className="in" />
      </div>
      <div className="percent">{`${percent} %`}</div>
    </S.PecentResponse>
  );
};

export const formatRibbon = (
  status,
  form_status,
  final,
  inicio,
  inicio_atual,
) => {
  const theme = useTheme();
  let config = {
    children: '',
    colorText: '',
    colorBg: '',
    hasRibbon: false,
  };

  if (
    (status === 'Pendente' || status === 'Iniciado') &&
    (form_status === 'Concluido' || form_status === 'Finalizado')
  ) {
    // nao fez
    config = {
      children: 'Não respondido',
      colorText: theme.palette.semantics.feedback.attention.natural,
      colorBg: theme.palette.semantics.feedback.unknown.natural,
      hasRibbon: true,
    };
  } else if (status === 'Finalizado') {
    // fez
    config = {
      children: 'Concluído',
      colorText: theme.palette.words.text.contrast,
      colorBg: theme.palette.semantics.feedback.success.natural,
      hasRibbon: true,
    };
  } else if (status === 'Atrasado') {
    // nao fez, mas ainda pode responder, com atraso
    const today = new Date().toDateString();
    final = new Date(final).toDateString();
    const diffInMs = new Date(final) - new Date(today);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    config = {
      children: diffInDays
        ? `Encerra em ${diffInDays} ${diffInDays > 1 ? 'dias' : 'dia'}`
        : 'Encerra hoje',
      colorText: theme.palette.words.text.contrast,
      colorBg: theme.palette.semantics.feedback.attention.natural,
      hasRibbon: true,
    };
  } else if (!final && !inicio_atual) {
    // nao iniciou
    config = {
      children: `Não iniciado`,
      colorText: theme.palette.semantics.feedback.unknown.dark,
      colorBg: theme.palette.semantics.feedback.unknown.light,
      hasRibbon: true,
    };
  } else if (status === 'Agendado') {
    // ainda nao abriu
    if (!inicio) return config;

    const biggerThan =
      new Date() > new Date(inicio_atual) ? inicio : inicio_atual;

    const today = new Date().toDateString();
    final = new Date(biggerThan).toDateString();
    const diffInMs = new Date(biggerThan) - new Date(today);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    config = {
      children: `Começa em ${diffInDays} ${diffInDays > 1 ? 'dias' : 'dia'}`,
      colorText: theme.palette.words.text.contrast,
      colorBg: theme.palette.semantics.feedback.success.natural,
      hasRibbon: true,
    };
  }

  return config;
};
