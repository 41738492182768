import { Controller } from 'react-hook-form';
import { Switch } from 'components/Inputs/Switch';

const ControlledSwitch = ({ name, control, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Switch
          disabled={disabled}
          check={value}
          setCheckbox={() => {
            onChange(!value);
          }}
        />
      )}
    />
  );
};

export default ControlledSwitch;
