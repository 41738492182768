import api from 'services/api';

export const getRotas = async () => {
  const res = await api.get('/get-rotas-onisys');
  return res.data;
};

export const createUser = async () => {
  const res = await api.post('/create-user-api');
  return res.data;
};
