import React, { useEffect, useState } from 'react';
import * as S from './styled';
import Item from './item';
import * as API from '../services';
import { useNavigate } from 'react-router-dom';
import Empty from 'components/Empty';
import { usePlans } from 'hooks/usePlans';
import { Skeleton } from '@mui/material';

const GraficoMotoristaOfensores = ({ title, filters }) => {
  const { isProvider } = usePlans();
  const navigate = useNavigate();

  const [allDrivers, setAllDrivers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigateTo = () => {
    navigate(
      isProvider
        ? `/empresas/ranking?order=DESC`
        : `/motoristas/rankings?order=DESC`,
    );
  };

  const fetchMotoristas = async filters => {
    setIsLoading(true);
    const res = await API.getMotoristaMaisOfensores({ ...filters });
    setAllDrivers(res?.data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchMotoristas(filters);
  }, [filters]);

  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.ContainerList>
        {isLoading ? (
          <Skeleton
            style={{ marginBottom: '1rem' }}
            height={70}
            animation="wave"
            variant="rounded"
          />
        ) : allDrivers?.length ? (
          allDrivers.map((item, k) => (
            <Item
              key={k}
              id={item?.id_motorista || item?.id_empresa || 0}
              name={
                !isProvider
                  ? item?.nome_motorista
                    ? item?.nome_motorista
                    : ''
                  : item?.nome_empresa
                  ? item?.nome_empresa
                  : ''
              }
              points={item?.total_pontos || 0}
              deviations={item?.desvios || ''}
              primarios={item?.total_primarios || ''}
              moderados={item?.total_moderados || ''}
              graves={item?.total_graves || ''}
              gravissimos={item?.total_gravissimos || ''}
              nome_empresa={item?.nome_empresa || ''}
            />
          ))
        ) : (
          <Empty />
        )}
        <div className="see-more" onClick={navigateTo}>
          Ver todos
        </div>
      </S.ContainerList>
    </S.Container>
  );
};

export default GraficoMotoristaOfensores;
