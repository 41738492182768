import { format } from 'date-fns';
import { formatNewHour } from 'utils/dates/';

const defaults = [
  {
    header: 'Nome',
    id: 'aluno.nome',
    type: 'string',
    sort: true,
    subRow: {
      prefix: 'Filial:',
      value: (_, item) => item?.aluno?.filial?.nome,
    },
  },
  {
    header: 'Status',
    id: 'concluido',
    type: 'string',
    sort: true,
    value: (_, item) =>
      item?.concluido
        ? 'Concluído'
        : item.justificado
        ? 'Justificado'
        : item.conluido === null
        ? 'Pendente'
        : 'Iniciado',
    align: 'center',
    conditional: [
      {
        condition: value => !!value, // Concluído
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => !value, // Iniciado
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === null, // Pendente
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
      {
        condition: (_, item) => item.justificado, // Justificado
        style: theme => ({
          color: theme.palette.words.title.natural,
          backgroundColor: '#edf2f9',
          textAlign: 'center',
        }),
      },
    ],
    subRow: {
      prefix: 'Data:',
      value: (_, item) =>
        item.justificado
          ? format(new Date(item?.data_historico), 'dd/MM/yyyy')
          : formatNewHour(item?.data_historico),
    },
    attention: (_, item) => {
      if (item.justificado)
        return {
          text: item.justificativa,
          iconIn: '',
        };
    },
  },
  {
    header: 'Ocorrências',
    id: 'ocorrencias.total',
    type: 'number',
    sort: false,
    align: 'center',
  },
  {
    header: 'Repetições Preenchidas',
    id: 'contador',
    type: 'number',
    sort: true,
    align: 'center',
  },
];

export const columnsTotal2 = defaults;

export const columnsApprove2 = [
  ...defaults,
  {
    header: 'Aprovado em',
    id: 'data_assinatura',
    type: 'date',
    sort: true,
    align: 'center',
    value: (_, item) => item?.data_assinatura || 'Não aprovado',
    subRow: {
      prefix: 'Aprovador:',
      value: (_, item) =>
        item.aprovador?.nome || item.formulario?.responsavel_aprovador?.nome,
      type: 'string',
    },
    conditional: [
      {
        condition: value => !!value, // Aprovado
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => !value, // Não Aprovado
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];

export const columnsOcurrence2 = defaults;

export const columnsSmall2 = defaults.filter(c => c.header !== 'Ocorrências');
