import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;

  &.justify-end {
    justify-content: end;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.mb-05 {
    margin-bottom: 0.5rem;
  }
  &.mt-05 {
    margin-top: 0.5rem;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  color: #4b5166;
`;

export const Legend = styled.div`
  display: flex;
  gap: 0.25rem;
  font-weight: medium;
  font-size: 14px;
  line-height: 12px;
  color: #4b5166;

  .point {
    width: 12px;
    height: 12px;
    background-color: #4b5166;
    border-radius: 100%;
  }
  .primary {
    background-color: #bdcc30;
  }
`;
