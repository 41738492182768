import React from 'react';

import * as S from './styled';
import { Switch } from 'components/Inputs/Switch';
import { Controller } from 'react-hook-form';

export const SelectSwitch = ({
  control,
  rules,
  name = '',
  title = '',
  disabled = false,
  options = [],
  error = '',
}) => {
  return (
    <S.Container>
      <S.Title>
        {title}
        {!!error && (
          <div>
            <span style={{ color: 'red' }}>{error}</span>
          </div>
        )}
      </S.Title>
      <S.ListContainer disabled={disabled}>
        <Controller
          name={name}
          rules={rules}
          control={control}
          defaultValue={[]} // Valor padrão é um array vazio
          render={({ field }) => (
            <ul>
              {options.map(option => (
                <S.ListItem key={option.value}>
                  <span>{option.label}</span>
                  <Switch
                    small
                    disabled={disabled}
                    check={field.value.includes(option.value)}
                    setCheckbox={checked => {
                      const newOptions = [...field.value];
                      if (checked) {
                        newOptions.push(option.value);
                      } else {
                        const index = newOptions.indexOf(option.value);
                        if (index > -1) {
                          newOptions.splice(index, 1);
                        }
                      }
                      field.onChange(newOptions);
                    }}
                    textOn=""
                    textOff=""
                  />
                </S.ListItem>
              ))}
            </ul>
          )}
        />
      </S.ListContainer>
    </S.Container>
  );
};
