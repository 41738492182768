import styled, { css } from 'styled-components';

export const Section = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SectionTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;

    h2: {
      font: normal normal 900 20px/30px Texta;
      color: ${theme.palette.words.subtitle.natural};
    }
    span {
      font: normal normal medium 14px/30px Texta;
      color: ${theme.palette.words.subtitle.natural};
      text-transform: capitalize;
    }
  `}
`;

export const SectionDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    gap: 1rem;
    font: normal normal medium 14px/30px Texta;
    color: ${theme.palette.words.subtitle.natural};

    .tag-email {
      margin-top: 1.5rem;
      padding: 2px 8px;
      background: #2e3240;
      border-radius: 16px;
      font: normal normal medium 14px/20px Texta;
      letter-spacing: 0px;
      color: #ffffff;
    }

    .flex {
      padding: 0.5rem 0;
      display: flex;
      gap: 1rem;
      width: 100%;
    }

    .info {
      display: flex;
      gap: 0.25rem;
      align-items: center;
    }
  `}
`;

export const SectionActions = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  gap: 1rem;
  justify-content: right;
`;

export const FrequencyButton = styled.div`
  ${({ active }) => css`
    background: #4b5166 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 0.5rem 0;
    width: 100%;
    opacity: ${active ? 1 : 0.2};
    font: normal normal medium 16px/14px Texta;
    color: #ffffff;
    text-align: center;
    transition: 0.5s;
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
  `}
`;
