export const identify = [
  'Acesso e saída do CT;',
  'Edificações;',
  'Ilhas das bombas de abastecimento;',
  'Percurso na instalação;',
  'Localização dos tanques de recebimento/respiros;',
  'Nome e nº das ruas e respectivos sentidos;',
  'Outras particularidades;',
];

export const fields = {
  1: [
    {
      name: 'arquivo_croqui',
      required: true,
    },
    {
      name: 'tanques',
      required: true,
      instance: 'array',
    },
  ],
  4: [
    {
      name: 'arquivo_croqui',
      required: false,
    },
    {
      name: 'tanques',
      required: true,
      instance: 'array',
    },
  ],
};
