import styled, { css } from 'styled-components';

export const QuestionBox = styled.div`
  ${({ theme, hasError }) => css`
    padding: 20px;
    background-color: ${hasError
      ? theme.palette.semantics.feedback.attention.light
      : theme.palette.system.highlight};
  `}
`;

export const ResponseArea = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
