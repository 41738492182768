import styled, { css } from 'styled-components';

const Container = styled.div`
  ${({ theme }) => css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    min-height: 300px;
    max-height: 90%;
    background-color: ${theme.palette.system.overlay};
    padding: 0px 30px;
  `}
`;

const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid ${theme.palette.brand.primary.light}4d;

    div {
      display: flex;
      flex-direction: row;
      h2 {
        margin-left: 10px;
      }
    }
  `}
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  overflow-y: scroll;
  margin-bottom: 5px;
`;

const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 80px;
    border-top: 1px solid ${theme.palette.brand.primary.light}4d;
  `}
`;

const StyledToast = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid ${theme.palette.semantics.feedback.warning.natural};
    border-radius: 4px;
    background: ${theme.palette.semantics.feedback.warning.light} 0% 0%
      no-repeat padding-box;
    height: 50px;
    padding: 0 10px;
    margin: 15px 0 10px;
    h1 {
      width: 100%;
      color: ${theme.palette.brand.primary.natural};
      font-size: 14px;
      margin-left: 15px;
      font-weight: 500;
    }
  `}
`;

export { Container, Header, Main, Footer, StyledToast };
