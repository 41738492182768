import imgCard from 'images/trilhas.svg';

import { useNavigate } from 'react-router-dom';
import DefaultButton from 'components/Buttons/Default';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import * as S from './styled';

export const ViewCard = ({ loading = true }) => {
  const navigate = useNavigate();
  return (
    <>
      <S.Container loading={loading}>
        <S.ImageArea>
          <img src={imgCard} />
        </S.ImageArea>
        <S.ActionsArea>
          <h1 className="text">Visualizar meus conteúdos</h1>
          <DefaultButton
            icon={<VisibilityOutlinedIcon />}
            onClick={() => navigate('/capacitacao/perfil?page=conteudos')}
            children="Visualizar"
            width="100%"
          />
        </S.ActionsArea>
        <S.BoxLoading loading={loading} className="boxLoading" />
      </S.Container>
    </>
  );
};
