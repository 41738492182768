import { Modal } from '@mui/material';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const ModalMUI = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: 90%;
  max-height: 700px;
  width: 90%;
  max-width: 500px;
  background-color: ${colors.white};
  border-radius: 5px;
`;

export const Header = styled.div`
  padding: 20px;

  .left {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  height: 100%;
`;

export const LineFeed = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;

  h3 {
    margin-left: 10px;
  }

  .info {
    display: flex;
    align-items: center;
  }

  .segment {
    font-weight: 900;
    color: ${colors.greyTitle};
  }

  &.comentarios {
    flex-direction: column;

    p {
      margin-left: 35px;
      font-weight: 600;
      color: ${colors.greyTitle};
    }
  }
`;

export const TabArea = styled.div``;

export const SearchArea = styled.div`
  padding: 20px;

  input {
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid ${colors.grey};
    padding: 5px;
    font-size: 16px;
    font-weight: 600;

    &:focus {
      border: 2px solid ${colors.greenMain};
    }
  }
`;

export const Body = styled.div`
  height: 65%;
  overflow-y: scroll;
`;
