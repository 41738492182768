import {
  getResponsaveis,
  getCargas,
  getCriticidade,
  getTiposDescarga,
} from 'constants/_SERVICES/user';

// Pages
import DesviosDescarga from 'pages/DesviosDescarga';
import DesvioDescarga from 'pages/DesviosDescarga/Detalhe';
import LogDesvioDescarga from 'pages/DesviosDescarga/Historico';

export default {
  '/desvios-descarga': {
    date: true,
    defaults: ['filials', 'clients'],
    pageFilterName: 'filterDesvioDescarga',
    filters: [
      {
        name: 'tipo',
        placeholder: 'Filtrar por Tipo',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getTiposDescarga(),
      },
      {
        name: 'responsavel',
        placeholder: 'Filtrar por Responsável',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getResponsaveis(),
      },
      {
        name: 'criticidade',
        placeholder: 'Filtrar por Criticidade',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getCriticidade(),
      },
      {
        name: 'carga',
        placeholder: 'Filtrar por Carga',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getCargas(),
      },
    ],
    page: <DesviosDescarga />,
  },

  '/desvio-descarga/:id': {
    back: -1,
    title: 'Desvio de Descarga',
    id: true,
    defaults: [],
    page: <DesvioDescarga />,
  },

  '/logs/desvio-descarga/:id': {
    back: -1,
    title: 'Histórico do Desvio',
    id: true,
    defaults: [],
    page: <LogDesvioDescarga />,
  },
};
