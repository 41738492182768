import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100vw;
    padding-top: 4rem;
    background: ${theme.palette.system.overlay};
  `}
`;

export const Content = styled.div`
  width: 100vw;
  max-width: 1200px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 1rem;

  h1 {
    font: normal normal 900 32px/45px Texta;
  }
`;

export const PartnersList = styled.div`
  padding: 2rem 0;
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;

    li {
      margin: auto;

      img {
        max-width: 200px;

        @media only screen and (min-width: 640px) {
          max-width: 160px;
        }
      }
    }
  }
`;
