import React from 'react';
import {
  Modal,
  Button,
  SvgIcon,
  IconButton,
  Icon,
  CircularProgress,
} from '@mui/material';
import GhostButton from 'components/Buttons/Ghost';
import { ReactComponent as WarningIco } from 'images/icons/warning.svg';
import { useTheme } from 'styled-components';
import * as S from './styled';

const ConfirmModal = ({
  handleClose,
  open,
  title,
  titleIcon,
  subtitle,
  buttonText,
  onClick,
  loading,
  isNegative,
  hideButtonCancel = false,
  content = null,
  customButton = null,
}) => {
  const theme = useTheme();
  const body = (
    <S.Paper>
      <S.Header>
        <S.Title>
          {titleIcon}
          <h1>{title}</h1>
        </S.Title>

        <IconButton size="small" onClick={handleClose}>
          <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
            close
          </Icon>
        </IconButton>
      </S.Header>

      <S.Main>
        {subtitle && (
          <S.StyledToast>
            <WarningIco
              strokeWidth="1.5"
              stroke={theme.palette.semantics.feedback.warning.natural}
              fill="none"
              fontSize="medium"
            />
            <h1>{subtitle}</h1>
          </S.StyledToast>
        )}
        {content && content}
      </S.Main>

      <S.Footer>
        {!hideButtonCancel && (
          <GhostButton size="medium" variant="contained" onClick={handleClose}>
            Cancelar
          </GhostButton>
        )}
        {customButton && customButton}

        <Button
          disabled={loading}
          onClick={onClick}
          size="medium"
          variant="contained"
          style={{
            color: isNegative
              ? theme.palette.semantics.feedback.attention.natural
              : theme.palette.words.text.contrast,
            background: isNegative
              ? theme.palette.system.highlight
              : theme.palette.brand.secondary.natural,
            marginLeft: '10px',
            border: isNegative
              ? `1px solid ${theme.palette.semantics.feedback.attention.natural}`
              : '',
            font: 'normal normal 900 14px/18px Texta',
            height: '40px',
          }}
        >
          {loading ? (
            <CircularProgress
              size={24}
              sx={{ color: theme.palette.words.text.contrast }}
            />
          ) : (
            buttonText
          )}
        </Button>
      </S.Footer>
    </S.Paper>
  );

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
};

export default ConfirmModal;
