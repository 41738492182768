import styled, { css } from 'styled-components';
import { Pagination } from '@mui/material';

// -----------------------GENERAL COMPONENTS-----------------------------------//

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 4px;
`;

export const Table = styled.table`
  ${({ theme }) => css`
    width: 100%;
    text-align: left;
    border-spacing: 0px;
    box-shadow: ${props =>
      props.tableType
        ? `0px 5px 10px ${theme.palette.system.disabled}`
        : `0px 7px 10px ${theme.palette.system.disabled}`};
  `}
`;

export const Tr = styled.tr`
  height: 50px;
  position: relative;
  margin: 0;
  &:last-child {
    padding: ${props => props.lastChildWidth && '10px'};
    width: ${props => props.lastChildWidth && '10px'};
  }
  &:nth-last-child(2) {
    padding: ${props => props.lastChildWidth && '10px'};
    width: ${props => props.lastChildWidth && '10px'};
  }
`;

export const TableCheckbox = styled.label`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border: 1px solid
      ${props =>
        props.check || props.indeterminate
          ? theme.palette.brand.secondary.natural
          : theme.palette.words.button.light};
    border-radius: 4px;
    cursor: pointer;

    .checkIcon {
      display: ${props => (props.check ? 'block' : 'none')};
      width: 18px;
    }

    input {
      display: none;
    }
  `}
`;

// -----------------------TOP COMPONENTS--------------------------------------//

export const BulkOptions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-right: 15px;

  .button {
    display: flex;
  }
`;

export const BulkItem = styled.div`
  ${({ theme }) => css`
    font-size: 15px;
    color: ${theme.palette.words.button.natural};
    font-weight: 700;
    opacity: 0.9;
    margin: 0px 10px;
    cursor: default;

    .option&:hover {
      color: ${theme.palette.brand.secondary.dark};
      cursor: pointer;
    }
  `}
`;

export const TopWrapper = styled.div`
  ${({ theme, tableType }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${tableType ? '0px' : '1rem'};
    background-color: ${tableType
      ? theme.palette.system.transparent
      : theme.palette.system.white};
    box-shadow: ${tableType
      ? 'none'
      : `0px 0px 2px ${theme.palette.system.divider}`};
    padding-bottom: 20px;
  `}
`;

export const Filters = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    #searchForm input {
      padding: 10px;
      color: ${theme.palette.words.label.light};
      opacity: 1;
    }

    #searchForm fieldset {
      border: 1px solid ${theme.palette.system.divider};
      border-radius: 4px;
      padding: 10px;
    }
  `}
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

// -----------------------HEADER COMPONENTS-----------------------------------//

export const Head = styled.thead`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.palette.semantics.feedback.unknown.light};
    color: ${theme.palette.words.text.light};
    font-size: 14px;
    text-transform: uppercase;
  `}
`;

export const Th = styled.th`
  ${props => css`
    padding: 10px 18px;
    &:nth-child(6) {
      text-align: ${props.center};
    }
    &:nth-child(7) {
      text-align: ${props.center};
    }

    &:last-child {
      padding: ${props.lastChildWidth && '10px'};
      width: ${props.lastChildWidth && '10px'};
    }
    &:nth-last-child(2) {
      padding: ${props.lastChildWidth && '10px'};
      width: ${props.lastChildWidth && '10px'};
    }
  `}
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  transition: 0.9s ease-in-out;
`;

export const ExpandIconContainer = styled.span`
  display: flex;
  align-items: center;
`;

// -----------------------BODY COMPONENTS------------------------------------//

export const Body = styled.tbody`
  ${({ theme }) => css`
    color: ${theme.palette.brand.primary.natural};
    background-color: ${theme.palette.system.overlay};
  `}
`;

export const Td = styled.td`
  padding: 10px 18px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.system.divider};
  &:last-child {
    padding: ${props => props.lastChildWidth && '10px'};
    width: ${props => props.lastChildWidth && '10px'};
  }
  &:nth-last-child(2) {
    padding: ${props => props.lastChildWidth && '10px'};
    width: ${props => props.lastChildWidth && '10px'};
  }
`;

export const ItemPopover = styled.p`
  color: ${({ theme }) => theme.palette.words.text.natural};
  font-size: 16px;
  font-weight: 600;
`;

// -----------------------FOOTER COMPONENTS----------------------------------//

export const Footer = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.words.text.light};
    display: flex;
    width: 100%;
    justify-content: ${props =>
      props.blockSizePage ? 'end' : 'space-between'};
    padding: 0 22px;
    align-items: center;
    height: 100px;

    select {
      margin: 0 25px;
    }
  `}
`;

export const FooterCol = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 5px;
  }
`;

export const Control = styled.button`
  ${({ theme }) => css`
    margin-left: 15px;
    display: flex;
    flex-direction: justify-content;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    color: ${theme.palette.words.button.natural};
  `}
`;

export const Pages = styled(Pagination)`
  ${({ theme }) => css`
    &.MuiPagination-root {
      color: ${theme.palette.words.button.natural};

      .Mui-selected {
        color: ${theme.palette.words.button.contrast};
        background-color: ${theme.palette.words.button.natural};
      }
    }
  `}
`;
