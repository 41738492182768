import styled, { css } from 'styled-components';
import Grid from '@mui/material/Grid';

const PageWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  overflow: hidden;
`;

const PageContainer = styled.form`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    flex-direction: column;
    div > &:first-child {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      h1 {
        color: ${theme.palette.brand.primary.natural};
        font-weight: 600;
      }
      p {
        color: ${theme.palette.brand.primary.natural};
        font-weight: 400;
        font-size: 1.2em;
      }
    }
  `}
`;

const FormWrapper = styled.div`
  margin: 5px 0;
  position: relative;
`;

const VisibleController = styled.div`
  position: absolute;
  right: 15px;
  top: 50px;
  cursor: pointer;
`;

export { PageWrapper, PageContainer, FormWrapper, VisibleController };
