import styled, { css } from 'styled-components';

const Container = styled.div`
  ${({ theme, margin }) => css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0px;
    right: 0px;
    width: 550px;
    height: 100%;
    background-color: ${theme.palette.system.overlay};
    padding: 0px 30px;
    margin-right: ${margin};
    transition: all ease 0.5s;
  `}
`;

const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid ${theme.palette.brand.primary.light}4d;

    div {
      display: flex;
      flex-direction: row;
      h2 {
        margin-left: 10px;
      }
    }
  `}
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  overflow-y: scroll;
  margin-bottom: 5px;
`;

const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-content: end;
    width: 100%;
    height: 80px;
    border-top: 1px solid ${theme.palette.brand.primary.light}4d;
  `}
`;

const Section = styled.h3`
  ${({ theme }) => css`
    font: normal normal 900 13px/50px Texta;
    justify-content: center;
    color: ${theme.palette.words.subtitle.natural};
  `}
`;

export { Container, Header, Main, Footer, Section };
