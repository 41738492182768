import { useSelector } from 'react-redux';

export function usePlans() {
  const {
    provider: isProvider,
    planos,
    has_config_acao_suspensao,
  } = useSelector(state => state.auth?.user?.user);
  const planosAtivos = planos?.filter(p => p.ativo) ?? [];
  const isOpLogistico = planosAtivos.some(plano => plano.id_do_plano === 180);

  const hasTelemetria = planosAtivos.some(plano => plano.id_do_plano === 100);
  const hasFadiga = planosAtivos.some(plano => plano.id_do_plano === 110);
  const hasMonitoramento = planosAtivos.some(
    plano => plano.id_do_plano === 140,
  );
  const hasTorre = planosAtivos.some(plano => plano.id_do_plano === 190);
  const hasTorrePlus = planosAtivos.some(plano => plano.id_do_plano === 200);

  return {
    isProvider,
    isOpLogistico,
    hasTelemetria,
    hasFadiga,
    hasTorre,
    hasTorrePlus,
    planosAtivos,
    hasMonitoramento,
    hasConfigAcaoSuspensao: has_config_acao_suspensao,
  };
}
