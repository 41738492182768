import { format } from 'date-fns';
import { SvgIcon } from '@mui/material';
import { ReactComponent as meu } from '../../images/icons/acidentes/icone_meu.svg';
import { ReactComponent as fora } from '../../images/icons/acidentes/icone_fora.svg';

export const colorsGravidade = {
  LEVE: '#1BC5BD',
  MÉDIA: '#0C12F2',
  GRAVE: '#FFA801',
  GRAVÍSSIMA: '#F64E60',
};

export const columns = [
  {
    header: 'Motorista',
    id: 'motorista.nome',
    type: 'string',
    tooltip: true,
    sort: true,
    subRow: {
      prefix: 'Filial: ',
      value: (_, item) => item?.motorista?.filial?.nome,
    },
    attention: (_, item) => {
      if (item.provider)
        return {
          left: true,
          text: `Editado por ${item.distribuidora?.nome}.`,
          iconIn: '',
          iconOut: <SvgIcon component={fora} />,
        };

      return {
        left: true,
        text: `Editado por ${item.empresa?.nome}.`,
        iconIn: '',
        iconOut: <SvgIcon component={meu} />,
      };
    },
  },
  {
    header: 'Data da infracao',
    id: 'data_multa',
    type: 'date-hour',
    sort: true,
    value: value => new Date(value),
  },
  {
    header: 'Tipo de infração',
    id: 'multa_tipo.titulo',
    type: 'string',
    tooltip: true,
    sort: true,
  },
  {
    header: 'Local',
    id: 'endereco',
    type: 'string',
    tooltip: true,
    sort: true,
  },
  {
    header: 'Gravidade',
    id: 'multa_tipo.gravidade',
    type: 'string',
    sort: true,
    align: 'center',
    conditional: [
      {
        condition: value => value === 'LEVE',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'MÉDIA',
        style: theme => ({
          color: theme.palette.semantics.feedback.information.natural,
          backgroundColor: theme.palette.semantics.feedback.information.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVE',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVÍSSIMA',
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];

export const columnsProvider = [
  {
    header: 'Motorista',
    id: 'motorista.nome',
    type: 'string',
    tooltip: true,
    sort: true,
    subRow: {
      prefix: '',
      value: (_, item) => item?.empresa?.nome,
    },
    attention: (_, item) => {
      if (item.provider)
        return {
          left: true,
          text: `Editado por ${item.distribuidora?.nome}.`,
          iconIn: '',
          iconOut: <SvgIcon component={meu} />,
        };

      return {
        left: true,
        text: `Editado por ${item.empresa?.nome}.`,
        iconIn: '',
        iconOut: <SvgIcon component={fora} />,
      };
    },
  },
  ...columns.slice(1),
];

const defaultfields = [
  {
    label: 'Motorista',
    value: 'motorista',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'filial',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo multa',
    value: 'multa_tipo',
    selected: true,
    default: true,
  },
  {
    label: 'Gravidade',
    value: 'gravidade',
    selected: true,
    default: true,
  },
  {
    label: 'Pontos',
    value: 'pontos',
    selected: true,
    default: true,
  },
  {
    label: 'Valor',
    value: 'valor',
    selected: true,
    default: true,
  },
  {
    label: 'Data da multa',
    value: 'data_multa',
    selected: true,
    default: true,
  },
  {
    label: 'Agregado',
    value: 'agregado',
    selected: true,
    default: true,
  },
  {
    label: 'Placa',
    value: 'placa',
    selected: true,
    default: true,
  },
  {
    label: 'Local',
    value: 'localizacao',
    selected: true,
    default: true,
  },
  {
    label: 'Km',
    value: 'km',
    selected: true,
    default: true,
  },
  {
    label: 'Latitude',
    value: 'latitude',
    selected: true,
    default: true,
  },
  {
    label: 'Longitude',
    value: 'longitude',
    selected: true,
    default: true,
  },
  {
    label: 'Observação',
    value: 'observacao',
    selected: true,
    default: true,
  },
];

export const fields = [
  ...defaultfields,
  {
    label: 'Cliente',
    value: 'distribuidora',
    selected: true,
    default: true,
  },
];

export const fieldsProvider = [
  ...defaultfields,
  {
    label: 'Empresa',
    value: 'empresa',
    selected: true,
    default: true,
  },
];

export const columnsCreateMany = [
  {
    Header: 'Motorista',
    id: 'nome_motorista',
    accessor: d => (d.nome_motorista ? String(d.nome_motorista) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original?.nome_motorista}</h3>,
  },
  {
    Header: 'Filial',
    id: 'nome_filial',
    accessor: d => (d.nome_filial ? String(d.nome_filial) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original?.nome_filial}</h3>,
  },
  {
    Header: 'Tipo de multa',
    id: 'multa_tipo',
    accessor: d => (d.multa_tipo ? String(d.multa_tipo) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.multa_tipo}</h3>,
  },
  {
    Header: 'Data da infração',
    id: 'data_multa',
    accessor: d => (d.data_multa ? String(d.data_multa) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => (
      <h3>{format(new Date(row.original.data_multa), 'dd/MM/yyyy hh:mm')}</h3>
    ),
  },
  {
    Header: 'Placa',
    id: 'placa',
    accessor: d => (d.placa ? String(d.placa) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.placa}</h3>,
  },
  {
    Header: 'CEP',
    id: 'cep',
    accessor: d => (d.cep ? String(d.cep) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.cep}</h3>,
  },
  {
    Header: 'Rodovia',
    id: 'endereco',
    accessor: d => (d.endereco ? String(d.endereco) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.endereco}</h3>,
  },
  {
    Header: 'Km da rodovia',
    id: 'km',
    accessor: d => (d.km ? String(d.km) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.km}</h3>,
  },
  {
    Header: 'Cliente',
    id: 'nome_distribuidora',
    accessor: d => (d.nome_distribuidora ? String(d.nome_distribuidora) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original?.nome_distribuidora}</h3>,
  },
];
export const columnsCreateManyProvider = columnsCreateMany.slice(0, -1).concat({
  Header: 'Empresa',
  id: 'nome_empresa',
  accessor: d => (d.nome_empresa ? String(d.nome_empresa) : '-'),
  sortType: 'basic',
  show: true,
  Cell: ({ row }) => <h3>{row.original?.nome_empresa}</h3>,
});
