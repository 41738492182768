import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tabs from 'pages/Desvios/Estatisticas/DesempenhoGeral/components/Tabs';
import { Grid, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Loading from 'components/Loading';
import TextInput from 'components/Inputs/TextField';
import Select from 'components/Inputs/Select';
import { Switch } from 'components/Inputs/Switch';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';
import GhostButton from 'components/Buttons/Ghost';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import * as C from '../styled';
import * as S from './styled';
import * as api from './services';

// Components
export const Configuracoes = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentCliente, setCurrentCliente] = useState(1);
  const [cardSize, setCardSize] = useState(0);
  const [headers_clientes, setHeaders_clientes] = useState([]);
  const [itemsclientes, setItemsclientes] = useState([]);

  // Parametrizacoes
  const [allParametrizacoes, setAllParametrizacoes] = useState([]);

  useEffect(() => {
    requireData();
  }, []);

  useEffect(() => {
    const tabs = allParametrizacoes[currentTab]?.parametrizacoes_clientes;
    setHeaders_clientes(tabs);
    setCurrentCliente(tabs?.[0]?.id ?? null);
  }, [currentSection, currentTab]);

  useEffect(() => {
    if (headers_clientes) {
      const itens = headers_clientes.find(item => item.id === currentCliente);
      setItemsclientes(itens?.indicadores || []);
    }
  }, [headers_clientes, currentCliente, currentTab]);

  const requireData = async () => {
    const { parametrizacoes } = await api.getParametrizacoes();

    const parametrizacoesData = parametrizacoes.map((item, index) => {
      const categoria = {
        value: index,
        label: item.nome,
        editavel: item.transportadora_configura,
        provider: item.provider,
        page: item.nome,
        subSections: [],
        itens: [],
        changed: false,
        parametrizacoes_clientes: [],
      };

      categoria.itens = item.indicadores;
      categoria.parametrizacoes_clientes = item.clientes_indicadores;

      let totalCard = 0;

      categoria?.itens.forEach(item => {
        if (item.tipo_valor === 'card') {
          totalCard += 1;
        }
      });

      setCardSize(totalCard);

      return categoria;
    });

    setAllParametrizacoes(parametrizacoesData);
    setLoading(false);
  };

  const handleSave = async (headers, itens) => {
    const updatedItens = itens.filter(item => item.changed);

    if (updatedItens.filter(item => item.value === '').length > 0)
      return toast.warning('É necessário preencher todos os campos!');

    const subItems = [];
    const data = updatedItens.map(item => {
      item?.cards &&
        item?.cards?.forEach(
          (el, index) =>
            el.value &&
            subItems.push({
              parametrizacao_indicador: el.id,
              valor: el.value,
            }),
        );
      return {
        parametrizacao_indicador: item.id,
        valor: item.value,
      };
    });

    if (subItems.length > 0) {
      const res = await api.updateParametrizacao(subItems);
      if (res.success) {
        toast.success(res.message);
        if (headers) {
          setAllParametrizacoes(previous => {
            const newParametrizacoes = [...previous];
            newParametrizacoes[currentTab].subSections[
              currentSection
            ].changed = false;
            return newParametrizacoes;
          });
        } else {
          setAllParametrizacoes(previous => {
            const newParametrizacoes = [...previous];
            newParametrizacoes[currentTab].changed = false;
            return newParametrizacoes;
          });
        }
      }
    }

    const existUndefined = data.find(
      item =>
        item.parametrizacao_indicador === undefined && item.valor === undefined,
    );

    if (data.length > 0 && !existUndefined) {
      const res = await api.updateParametrizacao(data);
      if (res.success) {
        toast.success(res.message);

        if (headers) {
          setAllParametrizacoes(previous => {
            const newParametrizacoes = [...previous];
            newParametrizacoes[currentTab].subSections[
              currentSection
            ].changed = false;
            return newParametrizacoes;
          });
        } else {
          setAllParametrizacoes(previous => {
            const newParametrizacoes = [...previous];
            newParametrizacoes[currentTab].changed = false;
            return newParametrizacoes;
          });
        }
      } else {
        toast.error(res.message);
      }
    }
  };

  const handlePageTab = (event, newValue) => {
    setCurrentTab(newValue);
    setCurrentSection(0);
    setCurrentCliente(1);
  };

  const handleChange = (i, k, headers, value, subIndex = null) => {
    if (headers) {
      setAllParametrizacoes(previous => {
        const newParametrizacoes = [...previous];

        newParametrizacoes[currentTab].subSections[
          currentSection
        ].parametrizacoes[k].value = value;

        newParametrizacoes[currentTab].subSections[
          currentSection
        ].parametrizacoes[k].changed = true;

        newParametrizacoes[currentTab].subSections[
          currentSection
        ].changed = true;

        return newParametrizacoes;
      });
    } else {
      setAllParametrizacoes(previous => {
        const newParametrizacoes = [...previous];

        if (subIndex !== null) {
          newParametrizacoes[currentTab].itens[k].cards[subIndex].valor_padrao =
            value;
          newParametrizacoes[currentTab].itens[k].cards[subIndex].value = value;
          newParametrizacoes[currentTab].itens[k].changed = true;
        } else {
          console.log(newParametrizacoes[currentTab].itens[k].value, value);

          // Atualizando um item principal fora de headers
          newParametrizacoes[currentTab].itens[k].value = value;
          newParametrizacoes[currentTab].itens[k].changed = true;
          newParametrizacoes[currentTab].itens[k].valor_padrao = value;
        }
        newParametrizacoes[currentTab].changed = true;
        return newParametrizacoes;
      });
    }
    renderSquare();
  };

  const renderSquare = () => {
    let editavel = allParametrizacoes[currentTab].editavel;
    let provider = allParametrizacoes[currentTab].provider;
    let headers;
    let itens = allParametrizacoes[currentTab].itens;
    let disabledSave = !allParametrizacoes[currentTab].changed;

    if (allParametrizacoes[currentTab].subSections.length > 0) {
      headers = allParametrizacoes[currentTab].subSections;
      itens = headers[currentSection].parametrizacoes;
      disabledSave = !headers[currentSection].changed;
    }

    const getTitle = data => {
      switch (data) {
        case 'AVALIACOES':
          return 'Avaliações';
        case 'DESVIOS':
          return 'Desvios';
        case 'EVENTOS':
          return 'Eventos';
        case 'CERCA':
          return 'Cerca Eletrônica';
        case 'VELOCIDADE':
          return 'Velocidade';
        case 'ACOES':
          return 'Ações/Suspensões';
        default:
          return data;
      }
    };

    const getSubTitle = (item, k) => {
      if (item.agrupador === 'DESVIOS' && (k === 0 || k === 2)) {
        return k === 0 ? 'DESVIOS DE DIREÇÃO' : 'DESVIOS DE DESCARGA';
      }
      if (item.agrupador === 'ACOES' && (k === 0 || k === 1))
        return k === 0 ? 'AÇÕES DISCIPLINARES' : 'SUSPENSÕES';
      if (
        item.agrupador === null &&
        item.categoria === 10 &&
        (k === 0 || k === 4)
      )
        return k === 0 ? (
          <span>
            PRAZO DE VENCIMENTO{' '}
            <Tooltip
              title="Após este prazo o desvio será movido para a próxima aba."
              arrow
            >
              <HelpIcon sx={{ fontSize: '1rem' }} />
            </Tooltip>
          </span>
        ) : (
          'META DE TRATATIVA'
        );
      if (item.tipo_valor === 'card' && k === 0) return true;
    };

    const getGridValue = (item, k) => {
      let gridValue =
        itens.length % 2 === 0 ? 6 : itens.length - 1 === k ? 12 : 6;

      // Agrupador Ações da Telemetria ou Categoria PAD
      if (item.agrupador === 'ACOES' || item.categoria === 4) gridValue = 12;

      return gridValue;
    };

    return (
      <>
        {editavel && (
          <S.Box>
            {headers && (
              <div className="boxHeader">
                {headers.map((header, keyHeader) => (
                  <span
                    key={keyHeader}
                    className={
                      currentSection === header.subPage
                        ? 'headerItem active'
                        : 'headerItem'
                    }
                    onClick={() => {
                      setCurrentSection(header.subPage);
                      setCurrentCliente(1);
                    }}
                  >
                    {getTitle(header.title)}
                  </span>
                ))}
              </div>
            )}

            <div className="boxBody">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                columnSpacing={2}
                rowSpacing={2}
                marginBottom={2}
              >
                {itens.map((i, k) => (
                  <>
                    {getSubTitle(i, k) && (
                      <Grid
                        item
                        md={12}
                        style={{ marginTop: k !== 0 ? '20px' : '-20px' }}
                      >
                        <p className="subtitle">{getSubTitle(i, k)}</p>
                      </Grid>
                    )}

                    <Grid item md={getGridValue(i, k)}>
                      {i.tipo_valor === 'select' && (
                        <div>
                          <Select
                            color={i?.cor || 'secondary'}
                            label={i?.nome || ''}
                            value={i.value ? i.value : i.valor_padrao}
                            placeholder={i?.descricao || ''}
                            onChange={e =>
                              handleChange(i, k, headers, e.target.value)
                            }
                            data={i?.list || []}
                          />
                          {i.sub_indicadores &&
                            i.sub_indicadores.map((itemCh, n) => (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }}
                              >
                                <Switch
                                  check={
                                    itemCh.value
                                      ? itemCh.value === 'SIM'
                                      : itemCh.valor_padrao === 'SIM'
                                  }
                                  setCheckbox={e => {
                                    handleChange(
                                      itemCh,
                                      k,
                                      headers,
                                      itemCh.value
                                        ? itemCh.value === 'SIM'
                                        : itemCh.valor_padrao === 'SIM',
                                      n,
                                    );
                                  }}
                                  textOn=""
                                  textOff=""
                                />
                                <p className="switchTitle">{itemCh.nome}</p>
                              </div>
                            ))}
                        </div>
                      )}

                      <label>
                        {i.tipo_valor === 'check' && (
                          <div>
                            <p className="switchTitle">{i.nome}</p>
                            <Switch
                              check={
                                i.value
                                  ? i.value === 'SIM'
                                  : i.valor_padrao === 'SIM'
                              }
                              setCheckbox={e => {
                                handleChange(i, k, headers, e ? 'SIM' : 'NAO');
                              }}
                              textOn=""
                              textOff=""
                            />
                          </div>
                        )}
                        {i.tipo_valor === 'number' &&
                          i.tipo_valor !== 'card' && (
                            <TextInput
                              label={i.nome}
                              value={i.value}
                              placeholder={i.descricao}
                              onChange={e =>
                                handleChange(i, k, headers, e.target.value)
                              }
                            />
                          )}
                      </label>
                    </Grid>
                  </>
                ))}
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                columnSpacing={2}
                rowSpacing={2}
                marginBottom={2}
              >
                {itens
                  .filter(f => f.tipo_valor === 'card')
                  .map((i, k) => (
                    <>
                      {getSubTitle(i, k) && (
                        <Grid
                          item
                          md={12}
                          marginTop={20}
                          style={{ marginTop: k !== 0 ? '20px' : '-20px' }}
                        >
                          <p className="subtitle" style={{ marginTop: 20 }}>
                            PRAZO DE VENCIMENTO PARA DESVIOS
                          </p>
                        </Grid>
                      )}
                      {i.tipo_valor === 'card' && (
                        <Grid
                          item
                          md={
                            cardSize % 2 === 0 ? 6 : cardSize - 1 === k ? 12 : 6
                          }
                        >
                          <S.GridCustom
                            item
                            background="transparent"
                            border="transparent"
                          >
                            <S.Criticidade color={i.cor}>
                              <p>{i.nome}</p>
                            </S.Criticidade>

                            {i.cards
                              .sort((a, b) => a.card_posicao - b.card_posicao)
                              .map((subI, indexPa) => (
                                <>
                                  {subI.tipo_valor === 'select' && (
                                    <Grid container columnSpacing={1}>
                                      {subI.tamanho_horizontal === 12 && (
                                        <Grid
                                          item
                                          md={subI.tamanho_horizontal}
                                          key={indexPa}
                                        >
                                          <Select
                                            // color={subI?.cor || 'secondary'}
                                            label={subI?.nome || ''}
                                            value={
                                              subI.value
                                                ? subI.value
                                                : subI.valor_padrao
                                            }
                                            placeholder=""
                                            onChange={e =>
                                              handleChange(
                                                i,
                                                k,
                                                headers,
                                                e.target.value,
                                                indexPa,
                                              )
                                            }
                                            data={subI?.list || []}
                                          />
                                        </Grid>
                                      )}
                                      {subI.tamanho_horizontal === 6 && (
                                        <>
                                          {i.cards[indexPa - 1]
                                            ?.tamanho_horizontal !== 6 && (
                                            <Grid
                                              item
                                              md={subI.tamanho_horizontal}
                                              key={indexPa}
                                            >
                                              <Select
                                                label={subI?.nome || ''}
                                                value={
                                                  subI.value
                                                    ? subI.value
                                                    : subI.valor_padrao
                                                }
                                                placeholder=""
                                                onChange={e =>
                                                  handleChange(
                                                    i,
                                                    k,
                                                    headers,
                                                    e.target.value,
                                                    indexPa,
                                                  )
                                                }
                                                data={subI?.list || []}
                                                style={{
                                                  marginTop: '-5px',
                                                  height: 40,
                                                }}
                                              />
                                            </Grid>
                                          )}
                                          {i.cards[indexPa + 1]
                                            ?.tamanho_horizontal === 6 && (
                                            <Grid item md={6} key={indexPa + 1}>
                                              <Select
                                                label={
                                                  i.cards[indexPa + 1]?.nome ||
                                                  ''
                                                }
                                                value={
                                                  i.cards[indexPa + 1].value
                                                    ? i.cards[indexPa + 1].value
                                                    : i.cards[indexPa + 1]
                                                        .valor_padrao
                                                }
                                                placeholder=""
                                                onChange={e =>
                                                  handleChange(
                                                    i,
                                                    k,
                                                    headers,
                                                    e.target.value,
                                                    indexPa + 1,
                                                  )
                                                }
                                                data={
                                                  i.cards[indexPa + 1]?.list ||
                                                  []
                                                }
                                                style={{
                                                  marginTop: '-5px',
                                                  height: 40,
                                                }}
                                              />
                                            </Grid>
                                          )}
                                        </>
                                      )}
                                    </Grid>
                                  )}

                                  {subI.tipo_valor === 'check' && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        marginTop: '10px',
                                      }}
                                    >
                                      <Switch
                                        check={
                                          subI.value
                                            ? subI.value === 'SIM'
                                            : subI.valor_padrao === 'SIM'
                                        }
                                        setCheckbox={e => {
                                          handleChange(
                                            subI,
                                            k,
                                            headers,
                                            e ? 'SIM' : 'NAO',
                                            indexPa,
                                          );
                                        }}
                                        textOn=""
                                        textOff=""
                                      />
                                      <p className="switchTitle">
                                        {subI?.nome || ''}
                                      </p>
                                    </div>
                                  )}
                                </>
                              ))}
                          </S.GridCustom>
                        </Grid>
                      )}
                    </>
                  ))}
              </Grid>
              <Grid
                container
                justifyContent="end"
                alignItems="center"
                columnSpacing={2}
                rowSpacing={2}
              >
                <Grid item>
                  <DefaultButtonPopover
                    disabled={disabledSave}
                    size="medium"
                    onClick={() => handleSave(headers, itens)}
                  >
                    Salvar
                  </DefaultButtonPopover>
                </Grid>
              </Grid>
            </div>
          </S.Box>
        )}
        {provider && (
          <>
            <S.ClienteTitle>
              <p>Configurações do Cliente</p>
              <Tooltip title="Apenas para consulta" placement="right">
                <InfoOutlinedIcon
                  data-testid="description"
                  fontSize="20px"
                  sx={{ color: '#656e8c' }}
                />
              </Tooltip>
            </S.ClienteTitle>
            <S.Box>
              {headers_clientes && (
                <div className="boxHeader">
                  {headers_clientes.map((header, keyHeader) => (
                    <span
                      key={keyHeader}
                      className={
                        currentCliente === header.id
                          ? 'headerItem active'
                          : 'headerItem'
                      }
                      onClick={() => setCurrentCliente(header.id)}
                    >
                      {header.nome}
                    </span>
                  ))}
                </div>
              )}
              <div className="boxBody cliente">
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  columnSpacing={2}
                  rowSpacing={2}
                  marginTop={1}
                  style={{ padding: '30px' }}
                >
                  {itemsclientes.map((i, k) => (
                    <>
                      <Grid item md={getGridValue(i, k)}>
                        {i.tipo_valor === 'card' && (
                          <S.GridCustom
                            item
                            md={getGridValue(i, k)}
                            padding={27}
                          >
                            <S.Criticidade color={i.cor}>
                              <p>{i.nome}</p>
                            </S.Criticidade>

                            {i.cards
                              .sort((a, b) => a.card_posicao - b.card_posicao)
                              .map((subI, indexPa) => (
                                <>
                                  {subI.tipo_valor === 'select' && (
                                    <Grid container columnSpacing={1}>
                                      {subI.tamanho_horizontal === 12 && (
                                        <Grid
                                          item
                                          md={subI.tamanho_horizontal}
                                          key={indexPa}
                                        >
                                          <Select
                                            // color={subI?.cor || 'secondary'}
                                            disabled
                                            label={subI?.nome || ''}
                                            value={
                                              subI.value
                                                ? subI.value
                                                : subI.valor_padrao
                                            }
                                            placeholder=""
                                            style={{
                                              border: '1px solid #95AAC9',
                                              borderRadius: '4px',
                                            }}
                                            colorDisabled="#EDF2F9"
                                            onChange={e =>
                                              handleChange(
                                                i,
                                                k,
                                                headers,
                                                e.target.value,
                                                indexPa,
                                              )
                                            }
                                            data={subI?.list || []}
                                          />
                                        </Grid>
                                      )}
                                      {subI.tamanho_horizontal === 6 && (
                                        <>
                                          {i.cards[indexPa - 1]
                                            ?.tamanho_horizontal !== 6 && (
                                            <Grid
                                              item
                                              md={subI.tamanho_horizontal}
                                              key={indexPa}
                                            >
                                              <Select
                                                disabled
                                                label={subI?.nome || ''}
                                                value={
                                                  subI.value
                                                    ? subI.value
                                                    : subI.valor_padrao
                                                }
                                                colorDisabled="#EDF2F9"
                                                placeholder=""
                                                onChange={e =>
                                                  handleChange(
                                                    i,
                                                    k,
                                                    headers,
                                                    e.target.value,
                                                    indexPa,
                                                  )
                                                }
                                                data={subI?.list || []}
                                                style={{
                                                  border: '1px solid #95AAC9',
                                                  borderRadius: '4px',
                                                }}
                                              />
                                            </Grid>
                                          )}
                                          {i.cards[indexPa + 1]
                                            ?.tamanho_horizontal === 6 && (
                                            <Grid item md={6} key={indexPa + 1}>
                                              <Select
                                                disabled
                                                label={
                                                  i.cards[indexPa + 1]?.nome ||
                                                  ''
                                                }
                                                value={
                                                  i.cards[indexPa + 1].value
                                                    ? i.cards[indexPa + 1].value
                                                    : i.cards[indexPa + 1]
                                                        .valor_padrao
                                                }
                                                placeholder=""
                                                onChange={e =>
                                                  handleChange(
                                                    i,
                                                    k,
                                                    headers,
                                                    e.target.value,
                                                    indexPa + 1,
                                                  )
                                                }
                                                data={
                                                  i.cards[indexPa + 1]?.list ||
                                                  []
                                                }
                                                colorDisabled="#EDF2F9"
                                                style={{
                                                  border: '1px solid #95AAC9',
                                                  borderRadius: '4px',
                                                }}
                                              />
                                            </Grid>
                                          )}
                                        </>
                                      )}
                                    </Grid>
                                  )}

                                  {subI.tipo_valor === 'check' && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        marginTop: '10px',
                                      }}
                                    >
                                      <Switch
                                        check={
                                          subI.value
                                            ? subI.value === 'SIM'
                                            : subI.valor_padrao === 'SIM'
                                        }
                                        setCheckbox={e => true}
                                        textOn=""
                                        disabled
                                        textOff=""
                                      />
                                      <p className="switchTitle">
                                        {subI?.nome || ''}
                                      </p>
                                    </div>
                                  )}
                                </>
                              ))}
                          </S.GridCustom>
                        )}
                        {i.tipo_valor === 'select' && (
                          <div>
                            <Select
                              color={i?.cor || 'secondary'}
                              label={i?.nome || ''}
                              value={i.value ? i.value : i.valor_padrao}
                              placeholder={i?.descricao || ''}
                              disabled
                              data={i?.list || []}
                            />
                          </div>
                        )}

                        {i.tipo_valor !== 'card' && (
                          <label>
                            <TextInput
                              label={i.nome}
                              value={i.value}
                              placeholder={i.descricao}
                              disabled
                            />
                          </label>
                        )}
                      </Grid>
                    </>
                  ))}
                </Grid>
              </div>
            </S.Box>
          </>
        )}
      </>
    );
  };

  return (
    <S.Container>
      {loading ? (
        <div className="boxLoading">
          <Loading />
        </div>
      ) : (
        <>
          <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GhostButton
              startIcon={<HistoricoIcon />}
              size="medium"
              onClick={() => navigate(`/logs/parametrizacoes`)}
              sx={{ marginRight: '1rem' }}
            >
              HISTÓRICO DE ALTERAÇÕES
            </GhostButton>
          </Grid>
          <C.TitleArea
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <span className="textArea">Configurações</span>
            <span className="subTitle">Configure seus prazos e metas</span>
          </C.TitleArea>

          <Grid item marginBottom={3} md={12}>
            <Tabs
              value={currentTab}
              items={allParametrizacoes}
              onChange={handlePageTab}
              // width="750px"
            />
          </Grid>
          {renderSquare()}
        </>
      )}
    </S.Container>
  );
};
