import api from 'services/api';

// -------------------GENERAL REQUEST--------------------//
export const requestEvaluation = async id => {
  const { data } = await api.get(`/evaluation-descarga/${id}`);
  return data;
};

// -------------------INFO GERAIS-----------------------//
export const selectDistributors = async () => {
  const res = await api.get('/select-clients');
  return res.data;
};

export const selectDrivers = async () => {
  const res = await api.get('/select-drivers');
  return res.data;
};

export const selectTrucks = async () => {
  const res = await api.get('/select-trucks');
  return res.data;
};

export const createEvaluantion = async data => {
  const res = await api.post('/evaluation-descarga-criar', data);
  return res.data;
};

export const updateHeaderEvaluation = async (id, data) => {
  const res = await api.put(`/evaluations-descarga/${id}`, data);
  return res;
};

// ------------------------FORMULÁRIO REQUESTS---------------------------//
export const requestAnswersEvaluation = async id => {
  const res = await api.get(`/answers-evaluation-descarga/${id}`);
  return res;
};

export const requestTypesQuestions = async () => {
  const res = await api.get('/types-questions-descarga');
  return res;
};

export const answerOneQuestion = async (id, data) => {
  try {
    const res = await api.put(`/evaluations-descarga/${id}`, data);
    return res;
  } catch (error) {
    return false;
  }
};

export const createNewInfraction = async data => {
  const res = await api.post('/infractions-create', data);
  return res.data;
};

// ------------------------EVIDENCIAS REQUESTS---------------------------//
export const updateEvidences = async (id, data) => {
  const res = await api.put(`/evaluations-descarga/${id}`, data);
  return res;
};

// ------------------------ASSINATURAS REQUESTS---------------------------//
export const requestResponsibles = async id => {
  const res = await api.get(`/select-responsibles-descarga/${id}`);
  return res.data;
};

export const signatureEvaluation = async data => {
  try {
    const res = await api.put(`/evaluations-descarga/${data.id}`, data);
    return res.data;
  } catch (error) {
    return false;
  }
};

export const selectWitnessEvaluation = async data => {
  try {
    const res = await api.put(`/select-witness-evaluations-descarga`, data);
    return res;
  } catch (error) {
    return false;
  }
};
