import { toast } from 'react-toastify';
import qs from 'qs';
import api from 'services/api';

const requestContents = async () => {
  const res = await api.get('/training/aluno');
  if (res.data?.success) {
    return res.data;
  }
  if (res.data?.message) {
    toast.error(res.data.message);
    return [];
  }
};

const saveEvaluation = async (id, data) => {
  const res = await api.put(`training/content/evaluate/${id}`, { data });
  if (res.data?.success) {
    toast.success(res.data.message);
    return res.data;
  }
  if (res.data?.message) {
    toast.error(res.data.message);
    return [];
  }
};

// Ler midia de Conteudo. Passar como parametro (:id) o id da mídia (conteudo).
const readingContent = async id => {
  const res = await api.put(`/training/content/read-midia/${id}`);
  if (res.data?.success) {
    return res.data;
  }
  if (res.data?.message) {
    toast.error(res.data.message);
    return [];
  }
};

const answerTest = async id => {
  const res = await api.put(`/training/content/answer-question/${id}`);
  if (res.data?.success) {
    return res.data;
  }
  if (res.data?.message) {
    toast.error(res.data.message);
    return [];
  }
};

const retakeTest = async id => {
  const res = await api.post(`/training/content/new-try/${id}`);
  return res;
};

const answerQuestion = async (data, id) => {
  const res = await api.post(`/training/content/answer-question`, {
    respostas: data,
    id,
  });
  return res;
};

// Novo perfil __________________________________________________________

export const fetchAllContents = async () => {
  const res = await api.get(`/training/aluno-contents`);
  return res;
};

export const fetchAllReproved = async () => {
  const res = await api.get(`/training/aluno-reproved`);
  return res;
};

export const fetchAllCertificates = async () => {
  const res = await api.get(`/training/aluno-certificates`);
  return res;
};

export const fetchAllReports = async () => {
  const res = await api.get(`/training/aluno-reports`);
  return res;
};

export const showContent = async (id, params) => {
  const res = await api.get(`/training/show-content/${id}}`, {
    params,
  });
  if (res.data.success) {
    return res.data;
  }
  toast.error(res.data.message);
  return [];
};

export const tryNewTest = async id => {
  const res = await api.post(`/training/content/new-try/${id}`);
  return res.data;
};

export const requestCertificate = async (id, query) => {
  const res = await api.get(
    `/training/request-acertificate/${id}?${qs.stringify(query)}`,
  );
  return res;
};

export {
  requestContents,
  readingContent,
  answerTest,
  retakeTest,
  saveEvaluation,
  answerQuestion,
};
