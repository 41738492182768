import styled, { css, keyframes } from 'styled-components';
import colors from 'styles/colors';

const swipe = keyframes`
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const swipeBack = keyframes`
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const rocket = keyframes`
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-25%);
  }
`;

const guide = keyframes`
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(180deg);
  }

`;

const ContainerModifiers = {
  full: () => css`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    max-height: 650px;
  `,
  small: () => css`
    right: 0px;
    bottom: 0px;
    transform: translate(-5%, -5%);
    width: 375px;
    /* max-height: 410px; */
  `,
  final: () => css`
    right: 0px;
    bottom: 0px;
    transform: translate(-5%, -5%);
    width: 330px;
  `,
};

export const Overlay = styled.div`
  ${({ isFull }) => css`
    background-color: rgba(0, 0, 0, 0.65);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9998;
    transition: all 0.65s ease-in-out;
    opacity: ${isFull ? 1 : 0};
    visibility: ${isFull ? 'visible' : 'hidden'};
  `}
`;

export const ContainerFull = styled.div`
  ${({ inTransition }) => css`
    position: fixed;
    background-color: ${colors.white};
    z-index: 9999;
    border-radius: 5px;
    padding: 30px;
    margin-top: ${inTransition ? '100%' : '0%'};
    transition: all 0.25s ease-in-out;

    ${ContainerModifiers.full()}
  `}
`;

export const ContainerSmall = styled.div`
  ${({ inTransition, screen }) => css`
    position: fixed;
    background-color: ${colors.white};
    z-index: 9999;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: ${inTransition ? '-100%' : '0%'};
    transition: all 0.2s 0.4s ease-in-out;
    border: 1px solid ${colors.greyBorder};
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.4);
    overflow: hidden;

    ${ContainerModifiers.small()}

    &.final {
      ${ContainerModifiers.final()}
      height: ${screen === 0 ? '300px' : '130px;'};
      overflow: hidden;
      padding: 0px;
      width: 380px;

      .sliderContainer {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: ${screen === 0 ? '0px' : '-100%'};
        transition: all 0.25s ease-in-out;
        width: 200%;
        overflow: hidden;

        .screen {
          width: 100%;
          padding: 30px;
        }
      }
    }
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  .texts {
    display: flex;
    align-items: center;

    .icon {
      width: auto;
      height: 35px;

      img {
        width: 100%;
        height: 100%;
      }

      .MuiSvgIcon-root {
        color: ${colors.greenMain};
      }
    }
    .title {
      margin-left: 10px;
      font-size: 20px;

      &.up {
        animation: ${swipe} 0.3s infinite alternate;
      }

      &.down {
        animation: ${swipeBack} 0.3s infinite alternate;
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 15px;

    .MuiSvgIcon-root {
      font-size: 22px;
      cursor: pointer;
      color: ${colors.greySubtitle};
      transition: all ease 0.3s;

      &:hover {
        color: ${colors.grey};
        opacity: 0.8;
        transform: scale(1.1);
      }
    }
  }

  &.small {
    .texts {
      .icon {
        width: auto;
        height: 26px;
      }
      .title {
        font-size: 16px;

        &.slide {
          animation: ${swipe} 1s infinite alternate;
        }
      }
    }

    .actions {
      gap: 8px;

      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }
  }
`;

export const BodyEvaluation = styled.div`
  .evaluation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    gap: 50px;
    margin-top: 10px;

    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 75px;
      height: 75px;
      border-radius: 90px;
      transition: all ease 0.3s;

      &.active {
        background-color: ${colors.greyBorder};
      }

      cursor: pointer;

      span {
        font-size: 14px;
        font-weight: 600;
        color: ${colors.greySubtitle};
      }

      &:hover {
        opacity: 0.8;
        background-color: ${colors.greyBorder};
      }
    }
  }

  .comment {
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: ${colors.greySubtitle};

    input {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      border: 1px solid ${colors.greyTiny};
      padding: 10px 10px;
    }
  }
`;

export const Fixed = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const ContainerMidia = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  height: ${props => props.setWIdth}px;
  width: calc(${props => props.totalWidth} * 100%);
  margin-left: calc(${props => props.current} * -100%);
  margin-top: 25px;
  transition: all ease 0.3s;

  &.small {
    height: ${props => props.setWIdth}px;
    margin-top: 10px;
  }
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const Midia = styled.div`
  width: 100%;
  height: 215px;

  &.small {
    height: 158px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.greySubtitle};
  height: 160px;
  width: 100%;
  text-align: start;
  margin: 30px 0;

  &::first-letter {
    text-transform: uppercase;
  }

  &.small {
    height: 95px;
    font-size: 13px;
  }
`;

export const Footer = styled.div`
  height: 40px;
  margin-top: 20px;

  &.small {
    margin-top: 0px;
    height: 30px;
    margin-top: 10px;

    &.right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 0px;
    }
  }

  .MuiSvgIcon-root {
    animation: ${rocket} 0.8s infinite alternate;
  }

  .final {
    .MuiSvgIcon-root {
      animation: ${guide} 1s infinite alternate;
    }
  }
`;

export const CustomFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .counter {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .number {
      margin-left: 10px;
      font-size: 12px;
      font-weight: 500;
      color: ${colors.greySubtitle};
    }

    .bar {
      width: 100%;
      height: 7px;
      background-color: ${colors.greyBorder};
      border-radius: 5px;
      position: relative;

      .filled {
        position: absolute;
        left: 0;
        height: 100%;
        background-color: ${colors.greenMain};
        border-radius: 5px;
        width: ${props => props.percentage}%;
        transition: all ease 0.3s;
      }
    }
  }

  .area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    flex: 1;

    button {
      background-color: transparent;
      border: none;

      font-size: 16px;
      font-weight: 600;
      color: ${colors.greyTitle};
      cursor: pointer;
      text-decoration: underline;
      transition: all ease 0.1s;

      &:hover {
        color: ${colors.grey};
        opacity: 0.8;
      }
    }
  }

  &.small {
    .counter {
      .bar {
        height: 4px;
      }
    }

    .area {
      gap: 10px;

      button {
        font-size: 12px;
      }
    }
  }
`;
