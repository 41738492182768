import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const InputArea = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .inputBox {
    display: flex;
    width: 380px;
  }
`;

export const Label = styled.label`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 380px;
    width: 100%;
    color: ${theme.palette.words.subtitle.natural};
    font-size: 14px;
    font-weight: 600;
    opacity: 0.9;
    position: relative;
    margin-top: 20px;

    .error {
      border: 2px solid ${theme.palette.semantics.feedback.attention.natural};
    }

    .error::placeholder {
      color: ${theme.palette.semantics.feedback.attention.natural};
    }
  `}
`;

export const PasswordField = styled.input`
  ${({ theme }) => css`
    height: 50px;
    border-radius: 5px;
    border: 2px solid ${theme.palette.brand.primary.light};
    padding: 5px 15px;
    margin-top: 8px;
    font-size: 16px;

    &:focus {
      border: 2px solid ${theme.palette.brand.secondary.natural};
    }

    &::placeholder {
      color: ${theme.palette.words.text.light};
    }
  `}
`;

export const VisibleController = styled.div`
  position: absolute;
  right: 10px;
  top: 42px;
  cursor: pointer;
`;
