import styled, { css } from 'styled-components';

export const ModalMUI = styled.div`
  position: absolute;
  top: 0;
  overflow: hidden;
  visibility: hidden;
  z-index: -999;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    padding: 10px 10px;
    overflow-y: scroll;
    overflow-x: hidden;
  `}
`;

export const Infos = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    .formIdent {
      font-weight: bold;
      color: ${theme.palette.words.title.natural};
      display: flex;
      flex-direction: column;
      font-size: 8px;
      margin-top: 1rem;

      .titleForm {
        text-align: end;
        font-size: 16px;
      }

      .logoArea {
        text-align: end;
        img {
          width: 80px;
          max-height: 80px;
        }
      }
    }
    img {
      width: 150px;
    }
  `}
`;

export const InputToPDF = styled.div`
  ${({ theme, lines = 1 }) => css`
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${lines * 18}px;
    border-radius: 2px;
    font-size: 10px;
    color: ${theme.palette.words.title.natural};
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    padding-left: 4px;
    overflow: hidden;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.words.text.light};
    border-left: 1px solid ${theme.palette.words.text.light};
    border-right: 1px solid ${theme.palette.words.text.light};
    border-radius: 1px;
    padding: 5px;

    .topInfos {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      gap: 1rem;

      .coverArea {
        flex: 1;
        border-radius: 5px;
      }

      .textsArea {
        flex: 1;
        width: 100%;

        .normalText {
          font-weight: 600;
          font-size: 8px;
          color: ${theme.palette.words.subtitle.natural};
          margin-top: 2px;
        }
      }
    }

    .bottomInfos {
      display: flex;
      margin-top: 8px;
      gap: 1rem;

      .normalText {
        font-weight: 600;
        font-size: 8px;
        color: ${theme.palette.words.subtitle.natural};
      }
    }
  `}
`;

export const CardHeader = styled.div`
  ${({ theme }) => css`
    background: #373f54;
    margin-top: 10px;
    border-radius: 5px 5px 0px 0px;

    h1 {
      color: ${theme.palette.words.title.contrast};
      font-size: 10px;
      font-weight: 900;
      text-align: center;
      text-transform: uppercase;
    }
  `}
`;
