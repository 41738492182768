import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from 'styled-components';
import * as S from './styled';

export const Switch = ({
  check,
  setCheckbox,
  textOn,
  textOff,
  disabled,
  small = false,
  ...props
}) => {
  const theme = useTheme();
  return (
    <S.Label check={check} {...props} small={small} disabled={disabled}>
      <input
        type="checkbox"
        onChange={() => setCheckbox(!check)}
        checked={check}
        disabled={disabled}
      />
      <div className="background">
        <div className="circle">
          {check && (
            <CheckIcon
              htmlColor={theme.palette.semantics.feedback.success.natural}
            />
          )}
          {!check && (
            <CloseIcon
              htmlColor={theme.palette.semantics.feedback.unknown.natural}
            />
          )}
        </div>
      </div>
      <span className="textSwitch">{check ? textOn : textOff}</span>
    </S.Label>
  );
};
