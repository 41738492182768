import React from 'react';
import * as S from './styled';
import { useTheme } from 'styled-components';
import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const Link = ({
  justify = 'left',
  component = null,
  children,
  ...props
}) => {
  const theme = useTheme();
  return (
    <S.Flex justify={justify}>
      <MuiLink
        color={theme.palette.brand.secondary.natural}
        component={component ?? RouterLink}
        underline="hover"
        whiteSpace="nowrap"
        {...props}
      >
        {children}
      </MuiLink>
    </S.Flex>
  );
};
