import React from 'react';

import * as S from './styled';
import { CircularProgress, Divider } from '@mui/material';
import Loading from 'components/Loading';
import DefaultButton from 'components/Buttons/Default';
import { ArrowForwardIos, SaveAlt } from '@mui/icons-material';
import { Info } from './components/Info';
import { Selo } from 'components/Selo';
import { Link } from 'pages/Estatisticas/Components/SingleTable/Link';
import { ReactComponent as RankingIcon } from 'images/icons/motoristas/perfil/header/ranking.svg';
import { useQuery } from 'react-query';
import { requestBasic, requestPosRanking, requestSelo } from './services';

export const Header = ({
  id,
  handleClickExport,
  print,
  motoristaBloqueado,
}) => {
  const { isLoading: loadingBasic, data: resBasic } = useQuery(
    ['motorista-basic', id],
    () => id && requestBasic(id),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { isLoading: loadingRanking, data: resRanking } = useQuery(
    ['pos-motorista-ranking', id],
    () => id && requestPosRanking(id),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: resSelo } = useQuery(
    ['motorista-selo', id],
    () => id && requestSelo(id),
    {
      refetchOnWindowFocus: false,
    },
  );

  const text = resBasic?.nome || '';
  return (
    <S.Container>
      <div>
        <S.Avatar>
          {!loadingBasic ? (
            resBasic?.foto ? (
              <img src={resBasic.foto} alt="Avatar" />
            ) : (
              <span>{text ? text.charAt(0) : ''}</span>
            )
          ) : (
            <Loading />
          )}
        </S.Avatar>
      </div>

      <S.Content>
        <S.Flex>
          <S.Status
            color={
              resBasic?.status === 'ATIVO' && !motoristaBloqueado
                ? 'success'
                : 'attention'
            }
          >
            {motoristaBloqueado
              ? 'Bloqueado'
              : String(resBasic?.status).toLowerCase()}
          </S.Status>
        </S.Flex>
        <S.Flex>
          <S.Flex className="full-width gap-1">
            <S.Title>{String(text).toLowerCase()}</S.Title>
            <Selo data={resSelo} />
          </S.Flex>
          {!print && (
            <DefaultButton
              onClick={() =>
                handleClickExport instanceof Function && handleClickExport()
              }
              icon={<SaveAlt />}
            >
              Exportar
            </DefaultButton>
          )}
        </S.Flex>
        <Divider />

        <div style={{ marginTop: 15 }}>
          <S.Flex className="gap-05">
            {print && (
              <>
                <S.Icon>
                  {loadingRanking ? (
                    <S.LoadingBox>
                      <CircularProgress color="inherit" size={24} />
                    </S.LoadingBox>
                  ) : (
                    <RankingIcon />
                  )}
                </S.Icon>

                <div style={{ paddingLeft: 10 }}>
                  <S.Ranking>
                    <span>{resRanking ?? '-'}</span> Posição no ranking
                  </S.Ranking>
                  <Link
                    component="button"
                    onClick={() => window.open(`/motoristas/rankings`)}
                  >
                    Ver ranking unificado{' '}
                    <ArrowForwardIos style={{ fontSize: '10px' }} />
                  </Link>
                </div>
              </>
            )}
          </S.Flex>
        </div>
        <S.Flex className="full-width">
          <S.Flex className="full-width mt-1 gap-1">
            <Info title="Função" text={resBasic?.funcao ?? '-'} />
            <Info title="Filial" text={resBasic?.filial ?? '-'} />
            <Info title="Gênero" text={resBasic?.genero ?? '-'} />
            <Info title="Idade" text={resBasic?.idade ?? '-'} />
            <Info
              title="Tempo de empresa"
              text={resBasic?.tempo_empresa ?? '-'}
            />
          </S.Flex>

          <S.Flex className="mt-1">
            <S.Flex className="gap-05">
              {!print && (
                <>
                  <S.Icon>
                    {loadingRanking ? (
                      <S.LoadingBox>
                        <CircularProgress color="inherit" size={24} />
                      </S.LoadingBox>
                    ) : (
                      <RankingIcon />
                    )}
                  </S.Icon>

                  <div>
                    <S.Ranking>
                      <span>{resRanking ?? '-'}</span> Posição no ranking
                    </S.Ranking>
                    <Link
                      component="button"
                      onClick={() => window.open(`/motoristas/rankings`)}
                    >
                      Ver ranking unificado{' '}
                      <ArrowForwardIos style={{ fontSize: '10px' }} />
                    </Link>
                  </div>
                </>
              )}
            </S.Flex>
          </S.Flex>
        </S.Flex>
      </S.Content>
    </S.Container>
  );
};
