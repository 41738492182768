export const homes = [
  {
    url: '/',
    envs: 1,
  },
  {
    url: '/home',
    envs: 1,
  },
  {
    url: '/capacitacao/home',
    envs: 2,
  },
  {
    url: '/contratacao/vagas',
    envs: 3,
  },
];
