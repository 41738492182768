import React, { useState, useEffect, useRef } from 'react';
import { SidebarInter } from 'components/SidebarInter';
import { pages } from './constants';

const MeuPerfil = () => {
  return (
    <div>
      <SidebarInter pages={pages} />
    </div>
  );
};

export default MeuPerfil;
