import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: ${({ width }) => width ?? '100%'};
  max-width: 50rem;
  gap: 1rem;
  justify-content: ${({ justify }) => justify ?? 'end'};
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: #4b5166;
  white-space: nowrap;
`;

export const ListContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
`;

export const Chip = styled.div`
  ${({ active, theme }) => css`
    height: fit-content;
    padding: 0 8px;
    font-size: 14px;
    font-weight: 600px;
    border-radius: 100px;
    white-space: nowrap;
    background: ${active ? '#2E3240' : '#00000044'};
    color: ${theme.palette.words.subtitle.contrast};
    cursor: ${active ? 'default' : 'pointer'};

    :hover {
      background: ${active ? '#2E3240' : '#00000066'};
    }
  `}
`;
