import { takeLatest, put, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from './types';
import api from '../../../services/api';

import { updatePageMapSuccess, updatePageMapFailure } from './actions';

export function* updatePageMap({ lastDate }) {
  const res = yield call(api.get, '/page-map', { params: { lastDate } });
  if (res.data?.data) {
    const query = {
      pageMap: res.data.data,
      last_pageMap: new Date(),
    };
    yield put(updatePageMapSuccess(query));
  } else if (res.data?.message) {
    toast.error(res.data?.message);
    yield put(updatePageMapFailure());
  }
}

export default all([takeLatest(types.SET_PAGEMAP_REQUEST, updatePageMap)]);
