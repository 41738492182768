import api from 'services/api';
import qs from 'qs';

export const GetForms = async () => {
  const res = await api.get('/my-forms');
  return res.data;
};

export const getFormResponse = async id => {
  const res = await api.get(
    `/my-form/${id}?${qs.stringify({ selects: true })}`,
  );
  return res.data;
};

export const getFormPreview = async id => {
  const res = await api.get(`/form/${id}`);
  return res.data;
};

export const responseSection = async (id, secoes) => {
  const res = await api.post(`/my-form/answer/${id}`, { secoes });
  return res.data;
};

export const getFormResponseHistory = async (
  id,
  histId,
  alunoId,
  alunoTipo,
) => {
  const res = await api.get(
    `/my-form/${id}?${qs.stringify({
      selects: true,
      histId,
      alunoId,
      alunoTipo,
    })}`,
  );
  return res.data;
};
