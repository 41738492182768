/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import { useState } from 'react';
import { toast } from 'react-toastify';

// Components
import ConfirmModal from 'components/ConfirmModal';
import TextInput from 'components/Inputs/TextField';
import DefaultButton from 'components/Buttons/Default';

// Components Material UI
import Radio from '@mui/material/Radio';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Utils
import { options } from '../constants';

let selectToDelete = null;

export const EvaluationModules = ({
  questions = [],
  quizOp,
  setQuestions,
  selectedIndex,
  setSelectedIndex,
  setQuizOp,
  errors = [],
  id,
  setChanged,
  isPublished,
}) => {
  const theme = useTheme();
  const { Icon, title } = options[quizOp];
  const [openConfirm, setOpenConfirm] = useState(false);
  const indexErrors = errors.map(item => item?.index);

  const questionDefinition = {
    pergunta: '',
    feedback: '',
    ordem: 0,
    alternativas: [],
    is_quiz: true,
  };

  const handleNewQuestion = () => {
    const prevQuestions = questions;
    const newQuestion = questionDefinition;
    if (quizOp === 0) {
      newQuestion.is_quiz = true;
      newQuestion.ordem = prevQuestions.length;
      newQuestion.alternativas = [
        {
          alternativa: 'verdadeiro',
          correta: true,
          ordem: 0,
        },
        {
          alternativa: 'falso',
          correta: false,
          ordem: 1,
        },
      ];
    } else if (quizOp === 1) {
      newQuestion.is_quiz = false;
      newQuestion.ordem = prevQuestions.length;
      newQuestion.alternativas = [
        {
          alternativa: '',
          correta: true,
          ordem: 0,
        },
        {
          alternativa: '',
          correta: false,
          ordem: 1,
        },
      ];
    }
    setQuestions([...prevQuestions, newQuestion]);
    setSelectedIndex(prevQuestions.length);
    setChanged(true);
    let element = document.getElementById('input-question-eval');
    element.focus();
  };

  const handleText = (value, key) => {
    const newText = questions;
    newText[selectedIndex] = {
      ...newText[selectedIndex],
      [key]: value,
    };
    setQuestions([...newText]);
    setChanged(true);
  };

  const handleRemoveQuestion = (qst, index, skip) => {
    if (questions.length === 1) {
      toast.info('Nenhum tipo de avaliação está associado ao conteúdo');
      setQuizOp(-1);
      setQuestions([]);
      setChanged(true);
      return;
    }

    if (index !== null) selectToDelete = index;

    if (quizOp === 0 && !skip) {
      if (qst.pergunta || qst.feedback) {
        setOpenConfirm(true);
        return;
      }
    }

    let oldQuestion = questions;
    oldQuestion = oldQuestion.filter((_, k) => k !== selectToDelete);
    setQuestions([...oldQuestion]);
    setOpenConfirm(false);
    setChanged(true);

    if (
      (selectedIndex !== 0 && selectedIndex === selectToDelete) ||
      selectedIndex > selectToDelete
    ) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const handleCorrectAnswer = index => {
    const newQuestions = questions;
    newQuestions[selectedIndex].alternativas = newQuestions[
      selectedIndex
    ].alternativas.map((qst, i) => {
      if (i === index) {
        return {
          ...qst,
          correta: true,
        };
      }
      return {
        ...qst,
        correta: false,
      };
    });
    setQuestions([...newQuestions]);
    setChanged(true);
  };

  const handleSelectedQuestion = index => {
    let element = document.getElementById('input-question-eval');
    element.focus();
    setSelectedIndex(index);
  };

  // ----------------------------------Online Test ---------------------------------- //

  const addNewOption = () => {
    const newQuestions = questions;
    const newOption = {
      alternativa: '',
      correta: false,
      ordem: newQuestions[selectedIndex].alternativas.length,
    };
    newQuestions[selectedIndex].alternativas.push(newOption);
    setQuestions([...newQuestions]);
    setChanged(true);
  };

  const removeOption = (option, index) => {
    if (questions[selectedIndex].alternativas.length === 2) {
      toast.warning(
        'É necessário ao menos duas opções de resposta para cada pergunta.',
      );
      return;
    }
    const newQuestions = questions;

    if (option?.correta) {
      if (index === 0) {
        newQuestions[selectedIndex].alternativas[1].correta = true;
      } else if (
        index ===
        newQuestions[selectedIndex].alternativas.length - 1
      ) {
        newQuestions[selectedIndex].alternativas[index - 1].correta = true;
      } else {
        newQuestions[selectedIndex].alternativas[index + 1].correta = true;
      }
    }

    newQuestions[selectedIndex].alternativas = newQuestions[
      selectedIndex
    ].alternativas.filter((_, i) => i !== index);
    setQuestions([...newQuestions]);
    setChanged(true);
  };

  const changeOptionText = (value, index) => {
    const newQuestions = questions;
    newQuestions[selectedIndex].alternativas[index].alternativa = value;
    setQuestions([...newQuestions]);
    setChanged(true);
  };

  // ----------------------------------Formaters ---------------------------------- //
  const formatQuestionWidth = qst => {
    if (qst?.length > 35) {
      return `${qst.substring(0, 32)}...`;
    }
    return qst;
  };

  return (
    <>
      <S.Container id={id}>
        <S.Left>
          <S.TitleArea>
            <Icon htmlColor={theme.palette.brand.secondary.natural} />
            <h3>{`${title} #${(selectedIndex + 1)
              .toString()
              .padStart(2, '0')}`}</h3>
          </S.TitleArea>
          <S.QuestionsArea>
            <p className="title">Texto da Pergunta</p>
            <TextInput
              id="input-question-eval"
              placeholder="Digite sua pergunta aqui."
              value={questions[selectedIndex]?.pergunta}
              onChange={e =>
                handleText(e.target.value.slice(0, 300), 'pergunta')
              }
              multiline
              rows={6}
              noAsterisk
              error={
                errors.some(
                  item => item.index === selectedIndex && item.pergunta,
                ) && !questions[selectedIndex]?.pergunta
              }
              disabled={isPublished && !!questions[selectedIndex]?.id}
            />
            <S.OptionsArea>
              <div className="headerLine">
                <span className="cr">Correta</span>
                <span className="op">Opções</span>
              </div>
              <div className="options">
                {questions[selectedIndex]?.alternativas?.map((alt, index) => (
                  <div className="option" key={index}>
                    <Radio
                      checked={alt.correta}
                      onChange={() => handleCorrectAnswer(index)}
                      sx={{ color: theme.palette.words.title.natural }}
                      size="small"
                      disabled={isPublished && !!questions[selectedIndex]?.id}
                    />
                    {quizOp === 0 && (
                      <span className="text">{alt?.alternativa}</span>
                    )}

                    {quizOp === 1 && (
                      <>
                        <S.InputOption
                          type="text"
                          placeholder={`Resposta ${index + 1}`}
                          onChange={e =>
                            changeOptionText(
                              e.target.value.slice(0, 250),
                              index,
                            )
                          }
                          disabled={
                            isPublished && !!questions[selectedIndex]?.id
                          }
                          value={alt?.alternativa}
                          isError={
                            errors.some(
                              item =>
                                item.altIndex === index &&
                                item.alternativa &&
                                item?.index === selectedIndex,
                            ) && !alt?.alternativa
                          }
                        />
                        {(!isPublished || !questions[selectedIndex]?.id) && (
                          <DeleteOutlineOutlinedIcon
                            htmlColor={
                              questions[selectedIndex]?.alternativas.length > 2
                                ? theme.palette.semantics.feedback.attention
                                    .natural
                                : theme.palette.words.text.light
                            }
                            style={{ cursor: 'pointer' }}
                            onClick={() => removeOption(alt, index)}
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
                {quizOp === 1 &&
                  (!isPublished || !questions[selectedIndex]?.id) && (
                    <div className="option add" onClick={addNewOption}>
                      <AddCircleOutlineOutlinedIcon
                        className="addIcon"
                        sx={{ color: theme.palette.words.title.natural }}
                        fontSize="small"
                      />
                      <span className="text addText">Adicionar resposta</span>
                    </div>
                  )}
              </div>
            </S.OptionsArea>
          </S.QuestionsArea>

          {quizOp === 0 && (
            <S.FeedbackArea>
              <p className="title">Feedback</p>
              <TextInput
                placeholder="Mensagem que será exibida quando o aluno errar"
                noAsterisk
                value={questions[selectedIndex]?.feedback}
                onChange={e =>
                  handleText(e.target.value.slice(0, 150), 'feedback')
                }
                disabled={isPublished && !!questions[selectedIndex]?.id}
              />
            </S.FeedbackArea>
          )}
        </S.Left>
        <S.Right>
          <S.HeaderRight>
            <DefaultButton
              onClick={handleNewQuestion}
              children={
                <>
                  <AddCircleOutlineOutlinedIcon />
                  <span style={{ marginLeft: '10px' }}>Nova Pergunta</span>
                </>
              }
            />
          </S.HeaderRight>
          <S.BodyRight>
            <p className="titleBody">Perguntas</p>

            <S.QuestionsLine>
              {questions.map((question, index) => {
                let ok = true;
                if (!question.pergunta) {
                  ok = false;
                }

                question.alternativas.forEach(item => {
                  if (!item.alternativa) {
                    ok = false;
                  }
                });

                return (
                  <S.Question
                    key={index}
                    isSelected={index === selectedIndex}
                    isEmpty={!question?.pergunta}
                    isError={
                      indexErrors.includes(index) && !ok && errors.length
                    }
                  >
                    <div
                      className="left"
                      onClick={() => handleSelectedQuestion(index)}
                    >
                      <p className="index">{index + 1}</p>
                      <div className="text">
                        {formatQuestionWidth(question?.pergunta) ||
                          'Digite sua pergunta'}
                      </div>
                    </div>
                    <div className="right">
                      <DeleteOutlineOutlinedIcon
                        htmlColor={
                          questions.length > 1
                            ? theme.palette.semantics.feedback.attention.natural
                            : theme.palette.words.text.light
                        }
                        onClick={() => handleRemoveQuestion(question, index)}
                      />
                      <div className="bar" />
                    </div>
                  </S.Question>
                );
              })}
            </S.QuestionsLine>
          </S.BodyRight>
        </S.Right>
      </S.Container>

      {/* Modal Area */}
      <ConfirmModal
        handleClose={() => setOpenConfirm(false)}
        open={openConfirm}
        title="Tem certeza que deseja excluir essa pergunta?"
        subtitle="O item será removido do conteúdo e não poderá mais ser visualizado."
        titleIcon={
          <DeleteOutlineOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.attention.natural}
          />
        }
        buttonText="Confirmar"
        onClick={() => handleRemoveQuestion(null, null, true)}
      />
    </>
  );
};
