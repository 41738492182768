import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: calc(100vw - 140px);
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;

    p {
      margin-top: 10px;
      font-weight: 500;
      color: ${theme.palette.words.text.natural};
    }
  `}
`;

export const Infos = styled.div``;

export const Percents = styled.div`
  ${({ theme, percent = 0 }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .bar {
      width: 180px;
      height: 8px;
      border-radius: 5px;
      background-color: ${theme.palette.system.border};
      position: relative;

      .fill {
        position: absolute;
        height: 100%;
        width: ${percent}%;
        border-radius: 5px;
        transition: all 0.4s ease-in-out;
        background-color: ${theme.palette.brand.secondary.light};
      }
    }

    .infos {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
    }
  `}
`;

export const TabArea = styled.div`
  margin-top: 15px;
  position: relative;
`;

export const SearchArea = styled.div`
  ${({ theme }) => css`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    gap: 5px;

    .grid {
      &.left {
        width: 40%;
      }

      &.right {
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .MuiSvgIcon-root {
          position: absolute;
          left: 10px;
          color: ${theme.palette.brand.primary.light};
        }

        .search {
          height: 52px;
          width: 100%;
          border: none;
          background-color: transparent;
          border-radius: 4px;
          border: 1px solid ${theme.palette.brand.primary.light};
          padding-left: 40px;
          font-weight: 600;
          color: ${theme.palette.brand.primary.light};
          font-size: 16px;

          &::placeholder {
            color: ${theme.palette.brand.primary.light};
          }
        }
      }
    }
  `}
`;

export const ContentArea = styled.div``;

export const Loading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  opacity: ${props => (props.isLoading ? 1 : 0)};
  z-index: ${props => (props.isLoading ? 2 : -1)};
  background-image: linear-gradient(
    to right,
    #e2e2e8 0%,
    #edeef1 20%,
    #e2e2e8 40%,
    #e2e2e8 100%
  );
  background-size: 80rem 14rem;
  animation: placeholderShimmer 3s linear infinite forwards;

  @keyframes placeholderShimmer {
    0% {
      background-position: -40rem 0;
    }
    100% {
      background-position: 40rem 0;
    }
  }
`;
