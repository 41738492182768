// Pages
import Cadastro from 'pages/Cadastro';
import CadastroEmpresa from 'pages/CadastroEmpresa';
import CadastroDetalhe from 'pages/Cadastro/Detalhe';
import CadastroDemo from 'pages/CadastroDemonstracao';
import CadastroFeedback from 'pages/Cadastro/Feedback';

export default {
  '/cadastro': {
    page: <Cadastro />,
  },

  '/cadastro/success': {
    page: <CadastroFeedback />,
  },

  '/cadastro/:id': {
    page: <CadastroDetalhe />,
  },

  '/cadastro-empresa': {
    page: <CadastroEmpresa />,
  },

  '/demonstracao': {
    page: <CadastroDemo />,
  },
};
