import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  desvio: '',
  proximoVencimento: false,
  categoria: 'FADIGA',
  motoristas: '',
  criticidade: '',
  status: 'PENDENTE',
  sortBy: { id: 'data_violacao', desc: true },
};

export default function filterFadigaDesviosProvider(
  state = INITIAL_STATE,
  action,
) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_PROVIDER: {
        return { ...state, ...action.payload, sortBy: state.sortBy };
      }
      case types.SET_SORT_BY: {
        return { ...state, sortBy: action.payload };
      }
      case types.RESET_FILTER_PROVIDER: {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
