import {
  getTiposDesvios,
  getObjetos,
  getCriticidade,
  getResponsaveis,
} from 'constants/_SERVICES/provider';

// Pages
import RevisaoCliente from 'pages/Provider/RevisaoCliente';
import ExclusaoDesvio from 'pages/Provider/ExclusaoDesvio';
import CaronasAutorizadasProvider from 'pages/Provider/CaronasAutorizadas';

export default {
  '/caronas-autorizadas': {
    date: true,
    defaults: ['empresas', 'operation', 'filial', 'motoristas'],
    page: <CaronasAutorizadasProvider />,
  },

  '/revisao-cliente': {
    date: true,
    defaults: ['empresas', 'operations', 'filials', 'motoristas'],
    pageFilterName: 'filterRevisaoOperacao',
    pageFilters: ['responsavel'],
    filters: [
      {
        name: 'responsavel',
        placeholder: 'Filtrar por Responsável',
        section: 'Revisão Operação',
        mode: 'multiple',
        data: async () => getResponsaveis(),
      },
    ],
    page: <RevisaoCliente />,
  },

  '/exclusao-desvio': {
    date: true,
    pageFilterName: 'filterExclusaoDesvio',
    defaults: ['empresas', 'operations', 'filials', 'motoristas'],
    filters: [
      {
        name: 'tipo',
        placeholder: 'Filtrar por Tipo',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getTiposDesvios(),
      },
      {
        name: 'objeto',
        placeholder: 'Filtrar por Objeto',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getObjetos(),
        dependency: [
          {
            type: 'provider',
            name: 'tipo',
            clear: true,
            visible: true,
            maxSelect: 1,
            value: 6,
          },
        ],
      },
      {
        name: 'criticidade',
        placeholder: 'Filtrar por Criticidade',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getCriticidade(),
      },
      {
        name: 'responsavel',
        placeholder: 'Filtrar por Responsável',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getResponsaveis(),
      },
    ],
    page: <ExclusaoDesvio />,
  },
};
