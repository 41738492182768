import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    width: 100%;
    height: ${props.height || '400px'};
    padding: 10px;
    margin-top: ${props.marginTop};
    color: ${theme.palette.words.text.natural};

    p {
      font-size: 16px;
      font-weight: bold;
      height: 23px;
    }
  `}
`;

export const Image = styled.img`
  margin-top: 20px;
  height: 100%;
`;
