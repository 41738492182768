import * as types from './types';

export const _setSearch = (search, persistPage) => ({
  type: types._SET_SEARCH,
  payload: { search, persistPage },
});

export const _setPage = page => ({
  type: types._SET_PAGE,
  payload: page,
});

export const _setPageSize = pageSize => ({
  type: types._SET_PAGE_SIZE,
  payload: pageSize,
});

export const _setSort = sort => ({
  type: types._SET_SORT,
  payload: sort,
});

export const _setSelected = selected => ({
  type: types._SET_SELECTED,
  payload: selected,
});

export const _setCount = count => ({
  type: types._SET_COUNT,
  payload: count,
});

export const _setSelectedDefault = selected => ({
  type: types._SET_DEFAULT,
  payload: selected,
});

export const _setCachePage = cachePerPage => ({
  type: types._SET_CACHE_PAGE,
  payload: cachePerPage,
});

export const _setFilters = filters => ({
  type: types._SET_FILTERS,
  payload: filters,
});

export const _setStarted = hasSearch => ({
  type: types._SET_STARTED,
  payload: hasSearch,
});

export const _setTable = table => ({
  type: types._SET_TABLE,
  payload: table,
});
