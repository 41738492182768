// Styled

// React
import { useEffect, useState } from 'react';

// Components
import TextInput from 'components/Inputs/TextField';

// Components MUI
import { Divider } from '@mui/material';
import { WithQuestion } from '..';
import * as S from './styled';

// Utils
import { changeText } from '../../actions';

export const ResponseGps = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosObrigatorios,
  preview,
  approveView,
}) => {
  const redirectFunction = (executable, idCampo, texto) => {
    setCleanField(true);
    executable(texto, idCampo, setSecoes, secoes, sectionId);
  };

  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [firstAccess, setFirstAccess] = useState(true);

  useEffect(() => {
    if (!firstAccess) {
      const stringfyGeo = [];
      stringfyGeo.push(lat || '');
      stringfyGeo.push(long || '');
      redirectFunction(changeText, field.id, stringfyGeo.toString());
    }
  }, [lat, long]);

  useEffect(() => {
    const geos = field?.resposta?.split(',') || [];
    setLat(geos[0] || '');
    setLong(geos[1] || '');
    setFirstAccess(false);
  }, [field]);

  const verifyText = (text, toogleValue, current) => {
    if (!text.includes(',')) {
      toogleValue(text);
    } else {
      toogleValue(current);
    }
  };

  // ------------------------------------ERROS-------------------------------------------//
  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [cleanField, setCleanField] = useState(false);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setCleanField(false);
    if (approveView) return null;

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }
  }, [errosObrigatorios]);

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanField}
        className={`fieldClass-${field.id}`}
      >
        <WithQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanField}
          preview={preview}
          approveView={approveView}
        />
        <S.ResponseArea preview={preview}>
          <TextInput
            label="Latitude"
            noAsterisk
            value={lat}
            onChange={e => verifyText(e.target.value.slice(0, 30), setLat, lat)}
            error={hasErrorObrigatorio && !cleanField && lat === ''}
            disabled={approveView}
          />
          <TextInput
            label="Longitude"
            noAsterisk
            value={long}
            onChange={e =>
              verifyText(e.target.value.slice(0, 30), setLong, long)
            }
            error={hasErrorObrigatorio && !cleanField && long === ''}
            disabled={approveView}
          />
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
