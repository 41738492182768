import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100vw;
    padding: 3rem 0;
    text-align: center;
    z-index: -2;

    h1 {
      font: normal normal 900 42px/58px Texta;
    }
    p {
      color: ${theme.palette.words.subtitle.natural};
      font: normal normal medium 18px/25px Texta;
      padding-top: 1rem;
    }
    ::before {
      content: '';
      position: absolute;
      top: -112px;
      left: -112px;
      width: 232px;
      height: 234px;
      background: ${theme.palette.brand.secondary.light}a2;
      border-radius: 50%;
      z-index: -1;
    }
    ::after {
      content: '';
      position: absolute;
      top: 163px;
      right: -213px;
      width: 336px;
      height: 236px;
      border-radius: 50%;
      transform: rotate(40deg);
      background: ${theme.palette.semantics.feedback.unknown.natural}a2;
      z-index: -1;
    }
  `}
`;

export const FlexWrapper = styled.div`
  width: 100vw;
  max-width: 1200px;
  margin: 3rem auto;
  padding: 0 1rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  z-index: 0;
`;

export const GridWrapper = styled.div`
  width: 100vw;
  max-width: 1200px;
  margin: 3rem auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  justify-content: ${({ justify }) => justify ?? 'center'};
  z-index: 0;

  @media only screen and (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Filter = styled.div`
  ${({ theme, active }) => css`
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font: normal normal 900 28px/39px Texta;
    color: ${theme.palette.words.title.natural};
    border-bottom: 4px solid
      ${active
        ? theme.palette.brand.secondary.natural
        : theme.palette.system.border};

    img {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }
  `}
`;
