import { formatNewDate } from 'utils/dates';
import * as S from './styled';

export const fequencyModule = form => {
  const { periodo } = form;
  const dia_repeticao = form.dias_semana;
  const inicio = form.data_inicio;
  const final = form.data_fim;
  const repete = form.repetir;
  const { frequencia } = form;

  let finalModule = null;
  let repetableModule = null;

  if (final) {
    finalModule = (
      <S.PreviewDate>
        Previsão de encerramento:{' '}
        <span className="raw">{formatNewDate(final)}</span>
      </S.PreviewDate>
    );
  } else {
    finalModule = (
      <S.PreviewDate>
        Previsão de encerramento: <span className="raw">Nunca encerrar</span>
      </S.PreviewDate>
    );
  }

  if (!repete) {
    repetableModule = (
      <S.PreviewDate>
        Modelo de frequência: <span className="raw">Sem repetição</span>
      </S.PreviewDate>
    );
  } else {
    switch (periodo) {
      case 'DIA':
        repetableModule = (
          <S.PreviewDate>
            Modelo de frequência:{' '}
            <span className="raw">{`Repetir a cada ${frequencia} ${
              +frequencia > 0 ? 'dias' : 'dia'
            }`}</span>
          </S.PreviewDate>
        );
        break;

      case 'SEMANA':
        repetableModule = (
          <S.PreviewDate>
            Modelo de frequência:{' '}
            <span className="raw">{`Repetir ${getDay(
              dia_repeticao?.toUpperCase(),
            )}, a cada ${frequencia} ${
              frequencia > 0 ? 'semana' : 'semanas'
            }`}</span>
          </S.PreviewDate>
        );
        break;

      case 'MES':
        repetableModule = (
          <S.PreviewDate>
            Modelo de frequência:{' '}
            <span className="raw">{`Repetir a cada ${frequencia} ${
              frequencia > 0 ? 'mês' : 'meses'
            }`}</span>
          </S.PreviewDate>
        );
        break;
    }
  }

  const initialDate = (
    <S.PreviewDate>
      Data de início: <span className="raw">{formatNewDate(inicio)}</span>
    </S.PreviewDate>
  );

  return (
    <>
      {initialDate}
      {finalModule}
      {repetableModule}
    </>
  );
};

const getDay = day => {
  const objDays = [
    { text: 'SEGUNDA', item: 'segunda-feira', preFix: 'toda' },
    { text: 'TERCA', item: 'terça-feira', preFix: 'toda' },
    { text: 'QUARTA', item: 'quarta-feira', preFix: 'toda' },
    { text: 'QUINTA', item: 'quinta-feira', preFix: 'toda' },
    { text: 'SEXTA', item: 'sexta-feira', preFix: 'toda' },
    { text: 'SABADO', item: 'sábado', preFix: 'todo' },
    { text: 'DOMINGO', item: 'domingo', preFix: 'todo' },
  ];

  for (const i in objDays) {
    if (objDays[i].text === day) {
      return `${objDays[i].preFix} ${objDays[i].item}`;
    }
  }
};
