import React from 'react';

// components
import Divider from '@mui/material/Divider';
import { IconButton } from '@mui/material';
import { Switch } from 'components/Inputs/Switch';

// icons
import { ReactComponent as EditarIcon } from 'images/icons/configuracoes/icone-editar.svg';
import { ReactComponent as BtnAdd } from 'images/icons/configuracoes/icone-adicionar.svg';
import { DesvioItem, Item } from './styled';

import { getColor, formatArrayString } from '../services';
import { enableEscalation, disableEscalation } from '../../services';
import { toast } from 'react-toastify';
import { usePlans } from 'hooks/usePlans';

export const Default = ({
  item,
  handleNavigate,
  refetch,
  desviosTiposPadroes,
  desviosTipos,
}) => {
  const { isProvider } = usePlans();
  const handleSwitchChange = async (id, value) => {
    const res = value
      ? await enableEscalation(id)
      : await disableEscalation(id);
    if (res?.success) {
      toast.success(res.message);
    } else {
      toast.error('Falha ao atualizar lista.');
    }
    refetch();
  };

  return (
    <>
      <Item>
        <Item>
          {item?.id ? (
            <Switch
              check={item.status === 'ATIVO'}
              setCheckbox={value => handleSwitchChange(item.id, value)}
              disabled={!isProvider && item.distribuidora}
              textOn=""
              textOff=""
            />
          ) : (
            <IconButton
              sx={{ marginRight: 1 }}
              onClick={() => handleNavigate(item)}
            >
              <BtnAdd />
            </IconButton>
          )}
          <div>
            {!isProvider &&
              (item.distribuidora || item.id_escalation_distribuidora) && (
                <span className="description-client">
                  Configurado por {item.distribuidora.nome}
                </span>
              )}
            <div className="description">
              {item?.is_default && 'Onisys - '}
              <span style={{ color: getColor(item?.risco) }}>
                {`${item?.titulo.slice(0, 50)}` || ''}
              </span>
            </div>
          </div>
          <DesvioItem>
            {formatArrayString(
              !isProvider &&
                (item.id_distribuidora || item.id_escalation_distribuidora)
                ? item.desvios
                : item.is_default
                ? desviosTiposPadroes
                : desviosTipos,
              item?.ids_desvios,
            )}
          </DesvioItem>
        </Item>
        <div style={{ marginTop: 10 }}>
          {!isProvider && item.distribuidora && !item.id_empresa ? (
            <>
              <span className="alertas">Configurar alertas!</span>
              <IconButton onClick={() => handleNavigate(item)}>
                <EditarIcon color="#F64E60" />
              </IconButton>
            </>
          ) : (
            <IconButton onClick={() => handleNavigate(item)}>
              <EditarIcon color="#95aac9" />
            </IconButton>
          )}
        </div>
      </Item>
      <Divider style={{ marginTop: 15 }} />
    </>
  );
};
