import React from 'react';
import Home from 'pages/Home';
import { useSelector } from 'react-redux';

const Empresa = () => {
  const { user } = useSelector(state => {
    return state.auth.user;
  });

  return <div>Olá, {user?.nome || ''}!</div>;
};

const routes = {
  '/home': {
    date: false,
    title: <Empresa />,
    page: <Home />,
  },
  '/': {
    date: false,
    title: <Empresa />,
    page: <Home />,
  },
};

export default routes;
