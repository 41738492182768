import * as React from 'react';
import { subDays, subMonths } from 'date-fns';
import { Box, FormControl } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore'; // Ícone de seta para baixo
import { styled } from '@mui/material/styles';
import { Item, SelectCustom } from './styled';

const CustomArrowDropDownIcon = styled(ExpandMore)(({ theme, open }) => ({
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.short,
  }),
}));

export const CustomizedSelect = ({ selectedPeriod, onChange, width = 200 }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState(selectedPeriod ?? 'TRATADOSNOPRAZO');

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = event => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <Box sx={{ minWidth: width }}>
      <FormControl fullWidth>
        <SelectCustom
          value={selectedPeriod ?? value}
          displayEmpty
          onChange={handleChange}
          onOpen={handleOpen}
          onClose={handleClose}
          IconComponent={props => (
            <CustomArrowDropDownIcon {...props} open={isOpen} />
          )}
        >
          <Item value="TEMPOMEDIO">Tempo médio de tratativas</Item>
          <Item value="TRATADOSNOPRAZO">Desvios tratados no prazo</Item>
        </SelectCustom>
      </FormControl>
    </Box>
  );
};
