import React from 'react';
import { Info } from '@mui/icons-material';
import Tabs from '@mui/material/Tabs';
import * as S from './styled';
import { useTheme } from 'styled-components';

const ColorTabs = ({ handleInfo, value, onChange, items, width, ...props }) => {
  const theme = useTheme();
  return (
    <S.Wrapper>
      <Tabs
        value={value}
        onChange={onChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.brand.secondary.natural,
            height: 4,
            borderRadius: '10px 10px 0px 0px',
          },
        }}
      >
        {items &&
          items.map(i => (
            <S.StyledTab
              key={i.value}
              value={i.value}
              label={
                !handleInfo ? (
                  <S.Span>
                    <h3>{i.label}</h3>
                  </S.Span>
                ) : (
                  <S.Span>
                    <h3>{i.label}</h3>
                    <S.Div />
                    <Info onClick={() => handleInfo(i)} />
                  </S.Span>
                )
              }
            />
          ))}
      </Tabs>
    </S.Wrapper>
  );
};

export default ColorTabs;
