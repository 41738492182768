// Icons
import { ReactComponent as gerais } from 'images/vagas/gerais.svg';
import { ReactComponent as requisitos } from 'images/vagas/propriedade.svg';
import { ReactComponent as detalhes } from 'images/vagas/departamento.svg';
import { ReactComponent as curriculo } from 'images/vagas/funcionario.svg';

// Components
import { InformacoesGerais } from '../InfoGerais';
import { Requisitos } from '../Requisitos';
import { Detalhes } from '../Detalhes';
import { Curriculos } from '../Curriculos';

export const itensBar = [
  {
    id: 0,
    page: 'Informações Gerais',
    linkTo: '',
    icon: gerais,
    Component: params => InformacoesGerais(params),
  },
  {
    id: 1,
    page: 'Requisitos e Benefícios',
    linkTo: '',
    icon: requisitos,
    Component: params => Requisitos(params),
  },
  {
    id: 2,
    page: 'Detalhamento',
    linkTo: '',
    icon: detalhes,
    Component: params => Detalhes(params),
  },
  {
    id: 3,
    page: 'Currículos',
    linkTo: '',
    icon: curriculo,
    Component: params => Curriculos(params),
  },
];
