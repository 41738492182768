import styled, { css } from 'styled-components';
import { TextField } from '@mui/material';

export const StyledTextField = styled(TextField)`
  ${({ theme }) => css`
    width: 100%;

    .MuiOutlinedInput-root {
      fieldset {
        border: 2px solid ${theme.palette.brand.primary.light};
      }
      &:hover fieldset {
        border: 2px solid ${theme.palette.brand.primary.dark};
      }
    }
    .MuiOutlinedInput-root.Mui-focused fieldset {
      border: 2px solid ${theme.palette.brand.secondary.natural};
    }
    .MuiFormLabel-root {
      background-color: ${theme.palette.system.highlight};
      padding-right: 3px;
    }
  `}
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;

    p {
      margin-bottom: 5px;
    }

    .MuiFilledInput-root {
      color: ${theme.palette.words.label.natural};
      border: 1px solid ${theme.palette.semantics.feedback.unknown.light};
      background: ${theme.palette.semantics.feedback.unknown.light} 0% 0%
        no-repeat padding-box !important;
      &:before {
        border-bottom: none !important;
      }
      &:after {
        border-bottom: none !important;
      }
      input {
        padding: 16.5px 14px !important;
      }
    }

    &.error {
      .MuiOutlinedInput-root {
        fieldset {
          border: 2px solid
            ${theme.palette.semantics.feedback.attention.natural};
        }
        &:hover fieldset {
          border: 2px solid
            ${theme.palette.semantics.feedback.attention.natural};
        }
      }
    }
  `}
`;
