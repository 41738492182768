// Styled

// React
import { useEffect, useState } from 'react';

// Components

// Components MUI
import { Divider } from '@mui/material';
import SelectPhoto from 'components/Inputs/SelectCliente';
import { OutQuestion } from '..';
import * as S from './styled';

// Utils
import { changeId } from '../../actions';

export const SelectClient = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosObrigatorios,
  preview,
  previewWeb,
  selects,
}) => {
  const redirectFunction = (executable, idCampo, texto) => {
    setCleanField(true);
    const resp = selects[field.id_tipo_campo].find(item => item.id == texto);
    executable(texto, idCampo, setSecoes, secoes, sectionId, resp.nome);
  };

  // ------------------------------------ERROS-------------------------------------------//
  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [cleanField, setCleanField] = useState(false);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setCleanField(false);

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }
  }, [errosObrigatorios]);

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanField}
        className={`fieldClass-${field.id}`}
      >
        <OutQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanField}
          preview={preview}
        />
        <S.ResponseArea preview={preview}>
          <SelectPhoto
            distributors={
              !preview && !previewWeb
                ? selects[field.id_tipo_campo]?.map(item => ({
                    ...item,
                    logo_url: item.imagem,
                  }))
                : []
            }
            value={field.id_resposta}
            setValue={value => redirectFunction(changeId, field.id, value)}
            label="Selecione um Cliente"
            required={field.obrigatorio}
            hasError={hasErrorObrigatorio && !cleanField}
          />
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
