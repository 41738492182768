import { formatNewDate } from 'utils/dates';
import { ReactComponent as Bloqueado } from 'images/icons/acoes-suspensoes/bloqueado.svg';

export const SUSPENSAO_ID = 4;

export const iconsCards = {
  suspensos: 'motorista_suspenso.svg',
  proximos_liberacao: 'motorista_proximo_liberacao.svg',
  proximos_suspensao: 'motorista_proximo_suspensao.svg',
};

export const getStatus = (status, isSuspensao = false) => {
  switch (status) {
    case 'BLOQUEADO':
      return 'SUSPENSO';
    case 'LIBERADO':
      return isSuspensao ? 'LIBERADO' : 'CONCLUÍDO';
    default:
      return status;
  }
};

export const columns = [
  {
    header: '',
    width: 40,
    value: () => ' ',
    attention: (_, item) => {
      if (item.is_bloqueado)
        return {
          left: true,
          text: 'Motorista Suspenso',
          iconIn: '',
          iconOut: <Bloqueado />,
        };
    },
  },
  {
    header: 'Motorista',
    id: 'nome_motorista',
    type: 'string',
    sort: true,
    subRow: {
      prefix: ' Filial:',
      key: 'nome_filial',
      type: 'string',
    },
  },
  {
    header: 'Pontos',
    id: 'pontos',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Faixa atual',
    id: 'faixa_atual',
    type: 'string',
    sort: true,
    conditional: [
      {
        condition: value => value === 'Nenhuma',
        style: (theme, item) => ({
          color: theme.palette.words.text.natural,
        }),
      },
      {
        condition: value => value !== 'Nenhuma',
        style: (theme, item) => ({
          color: item.faixa_cor,
          textTransform: 'uppercase',
        }),
      },
    ],
  },
  {
    header: 'Total de Ações',
    id: 'total_acoes',
    type: 'number',
    sort: false,
    align: 'center',
  },
  {
    header: 'Última Ação gerada',
    id: 'ultima_acao_disciplinar',
    type: 'string',
    conditional: [
      {
        condition: (_, item) => item.is_bloqueado,
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
        }),
      },
    ],
  },
  {
    header: 'Data Últ. Ação',
    id: 'data_ultima_acao',
    type: 'date',
    sort: true,
  },
  {
    header: 'Status Últ. Ação',
    id: 'ultimo_status',
    type: 'string',
    sort: true,
    width: 200,
    align: 'center',
    conditional: [
      {
        condition: value => value === 'APLICADO',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'PENDENTE',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];

export const columnsProvider = [
  columns[0],
  {
    header: 'Matricula',
    id: 'matricula',
    type: 'string',
    sort: true,
  },
  ...columns.slice(2),
];

export const fields = [
  {
    label: 'Motorista',
    value: 'motorista',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'filial',
    selected: true,
    default: true,
  },
  {
    label: 'Faixa',
    value: 'faixa',
    selected: true,
    default: true,
  },
  {
    label: 'Ação Disciplinar',
    value: 'acao_disciplinar',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'pontos',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Data Alcance de Faixa',
    value: 'data_inicio',
    selected: true,
    default: true,
  },
  {
    label: 'Data Liberação',
    value: 'data_fim',
    selected: true,
    default: true,
  },
];

export const fieldsProvider = fields.map(item =>
  item.value === 'motorista' ? { ...item, label: 'Matricula' } : item,
);
