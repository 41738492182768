import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import Avatar from '@mui/material/Avatar';
import { Tooltip } from '@mui/material';
import { Icon } from 'components/IconsOnisys';
import { HeaderlessTable } from 'components/_Table/templates/headerless';
import { columns, listOptionsSelect } from './constants';
import * as S from './styled';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Bar from './components/bar';
import { CustomizedSelect } from 'components/SelectCustom';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import Skeleton from '@mui/material/Skeleton';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { usePlans } from 'hooks/usePlans';

export function formatDate(dateString) {
  if (dateString === null) return '-';
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} - ${hours}:${minutes}`;
}

const Deslocamento = ({ data = [], onSave = () => {}, loading = false }) => {
  const { isProvider, hasTorrePlus } = usePlans();
  const fildsInitial = data.map(() => ({
    planoAcao: '',
    selectedAction: null,
  }));
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [fields, setFields] = useState(fildsInitial);

  const handlePlanoAcaoChange = (index, value) => {
    const newFields = [...fields];
    newFields[index].planoAcao = value;
    setFields(newFields);
  };

  const handleSelectedActionChange = (index, value) => {
    const newFields = [...fields];
    newFields[index].selectedAction = value;
    setFields(newFields);
  };

  const handleSave = item => {
    const data = {
      ids: item.desvios.map(item => item.id_desvio),
      plano_acao: fields[currentIndex]?.planoAcao,
      tratativa: fields[currentIndex]?.selectedAction || '',
      id_viagem: item?.id_viagem,
      risco: item?.risco,
    };
    onSave(data);
  };

  useEffect(() => {
    setFields(
      data.map(() => ({
        planoAcao: '',
        selectedAction: null,
      })),
    );
  }, [data]);

  const getCriticidade = criticidade => {
    switch (criticidade) {
      case 'ALTISSIMO':
        return '#f64e60';
      case 'ALTO':
        return '#FFA801';
      case 'GRAVÍSSIMA':
        return `#F64E60`;
      case 'GRAVE':
      case 'ALTO':
        return `#FFA801`;
      case 'MODERADA':
      case 'PERIGO':
        return `#0C12F2`;
      case 'BAIXO':
        return `#1BC5BD`;
      default:
        return `#95AAC9`;
    }
  };

  const handleOpenNewTab = (id, item) => {
    return window.open(`/desvios/${item.id_desvio}`);
  };

  return (
    <div>
      {!loading ? (
        data.length ? (
          data.map((item, index) => (
            <S.Main color={getCriticidade(item?.risco)} onClick={() => {}}>
              <>
                <div className="sub-container">
                  <div className="space">
                    <div className="risk">Risco da viagem</div>
                    <div className="criticidade">{item?.risco}</div>
                    {!isProvider && item?.configurado_por && (
                      <>
                        <div className="risk">Configurado por</div>
                        <div className="info">{item.configurado_por}</div>
                      </>
                    )}
                  </div>
                  <div className="space fullwidth">
                    {isProvider && !hasTorrePlus ? (
                      <div className="vehicle">
                        Placa:{' '}
                        <span className="placa">{item?.placa || ''}</span>
                        <span className="empresa">{` (${
                          item?.nome_empresa ?? ''
                        })`}</span>
                      </div>
                    ) : (
                      <div className="driver-name">
                        {item?.nome_motorista || ''}
                      </div>
                    )}
                    <div className="container-user">
                      {(!isProvider || item?.visto_por) && (
                        <div style={{ marginRight: 10 }}>
                          <Avatar alt={item?.visto_por} src={item?.foto} />
                        </div>
                      )}
                      {!isProvider || item?.visto_por ? (
                        <div style={{ color: '#BDCC30', fontSize: 14 }}>
                          <div>Esse deslocamento está sendo visto por</div>
                          <div style={{ fontWeight: '900' }}>
                            {item?.visto_por || ''}
                          </div>
                        </div>
                      ) : (
                        <div className="points">
                          <div className="driver-descrition">
                            Nesse deslocamento, o motorista já somou{' '}
                            <span>{item?.pontos || 0} pontos</span>
                          </div>
                          {item?.tratativa === null && (
                            <div className="driver-footer">
                              {item?.endereco_fim || 'Endereço não informado '}(
                              {item?.latitude_fim}, {item?.longitude_fim})
                            </div>
                          )}
                          {item?.tratativa && (
                            <div>
                              <div className="tratado">
                                <CheckBoxOutlinedIcon />
                                <span>Tratado</span>
                                <div className="tag-tratado">
                                  {item?.tratativa || ' teste '}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="space">
                    {!item?.tempoVencido ? (
                      <>
                        <div className="last-location">Última localização</div>
                        <div
                          style={{
                            color: '#4B5166',
                            fontSize: 15,
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {formatDate(item?.data_fim) || '-'}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="last-location"
                          style={{ color: '#F64E60' }}
                        >
                          Vencido há
                        </div>
                        <div
                          style={{
                            color: '#F64E60',
                            fontSize: 15,
                            fontWeight: 900,
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item?.tempoVencido || '-'}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="space">
                    <div className="last-location">Ptos/Km</div>
                    <div style={{ color: '#4B5166', fontSize: 15 }}>
                      {item?.pontos_km || '-'}
                    </div>
                  </div>
                  <div className="space">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 10,
                      }}
                    >
                      <div className="total-desvio">
                        <span>{item?.quantidade || '-'}</span> Desvios cometidos
                      </div>
                      <div style={{ marginTop: 10, marginLeft: 10 }}>
                        <Bar
                          quantities={{
                            qtde_GRAVISSIMA:
                              item?.qtde_GRAVÍSSIMA ||
                              item?.qtde_GRAVISSIMA ||
                              0,
                            qtde_GRAVE: item?.qtde_GRAVE || 0,
                            qtde_MODERADA: item?.qtde_MODERADA || 0,
                            qtde_PRIMARIA:
                              item?.qtde_PRIMÁRIA || item?.qtde_PRIMARIA || 0,
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      {[
                        ...new Map(
                          item?.desvios?.map(desvio => [
                            desvio.titulo,
                            { icone: desvio.icone, titulo: desvio.titulo },
                          ]),
                        ).values(),
                      ].map(desvio => (
                        <Tooltip title={desvio.titulo}>
                          <span>
                            <Icon
                              name={desvio.icone}
                              style={{ marginRight: 5 }}
                            />
                          </span>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                  <div style={{ cursor: 'pointer' }}>
                    {showDetails && index === currentIndex ? (
                      <KeyboardArrowUpIcon
                        onClick={() => {
                          setShowDetails(!showDetails);
                          setCurrentIndex(null);
                        }}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        onClick={() => {
                          setShowDetails(true);
                          setCurrentIndex(index);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div
                  className="details"
                  style={{
                    height: showDetails && index === currentIndex ? 499 : 0,
                  }}
                >
                  {showDetails && index === currentIndex && (
                    <>
                      {(!isProvider || hasTorrePlus) && (
                        <>
                          <div style={{ width: '100%' }}>
                            <input
                              type="text"
                              value={fields[index]?.planoAcao}
                              onChange={e =>
                                handlePlanoAcaoChange(index, e.target.value)
                              }
                              className="input-text"
                              placeholder="Escreva um plano de ação..."
                            />
                          </div>
                          <div
                            style={{
                              marginBottom: 25,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <CustomizedSelect
                              items={listOptionsSelect}
                              selected={fields[index]?.selectedAction}
                              handelSelect={value =>
                                handleSelectedActionChange(index, value)
                              }
                              styles={{ marginRight: 1 }}
                            />
                            <GhostButton
                              children="CANCELAR"
                              onClick={() => onClose()}
                            />
                            <DefaultButton
                              children="SALVAR"
                              style={{ marginLeft: '10px' }}
                              onClick={() => handleSave(item)}
                            />
                          </div>
                        </>
                      )}
                      <HeaderlessTable
                        data={item?.desvios}
                        columns={columns}
                        loading={loading}
                        local
                        actions={[
                          {
                            title: 'Ver detalhes',
                            function: handleOpenNewTab,
                          },
                        ]}
                        sortBy={{ id: 'id', order: 'DESC' }}
                      />
                    </>
                  )}
                </div>
              </>
            </S.Main>
          ))
        ) : (
          <S.Main color={getCriticidade(null)}>
            Nenhum deslocamento encontrado.
          </S.Main>
        )
      ) : (
        [1, 2, 3].map((item, index) => (
          <Skeleton
            variant="rounded"
            width="100%"
            height={120}
            style={{ marginTop: 20 }}
          />
        ))
      )}
    </div>
  );
};

export default Deslocamento;
