import { addHours } from 'date-fns';
import { SvgIcon } from '@mui/material';
import { ReactComponent as meu } from 'images/icons/acidentes/icone_meu.svg';
import { ReactComponent as fora } from 'images/icons/acidentes/icone_fora.svg';

export const columns = [
  {
    header: 'Data do acidente',
    id: 'data_acidente',
    type: 'date',
    sort: true,
    value: value => addHours(new Date(value), 3),
    attention: (_, item) => {
      if (item.provider)
        return {
          left: true,
          text: `Editado por ${item.distribuidora}.`,
          iconIn: '',
          iconOut: <SvgIcon component={fora} />,
        };

      return {
        left: true,
        text: `Editado por ${item.empresa}.`,
        iconIn: '',
        iconOut: <SvgIcon component={meu} />,
      };
    },
  },
  {
    header: 'Tipo de acidente',
    id: 'tipo_acidente',
    type: 'string',
    tooltip: true,
    sort: true,
  },
  {
    header: 'Local',
    id: 'endereco',
    type: 'string',
    tooltip: true,
    sort: true,
  },
  {
    header: 'Fatalidade',
    id: 'fatalidade',
    type: 'string',
    sort: true,
    align: 'center',
    conditional: [
      {
        condition: value => value === 'NÃO',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'SIM',
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];

export const columnsProvider = [
  {
    header: 'Data do acidente',
    id: 'data_acidente',
    type: 'date',
    sort: true,
    value: value => addHours(new Date(value), 3),
    attention: (_, item) => {
      if (item.provider)
        return {
          left: true,
          text: `Editado por ${item.distribuidora}.`,
          iconIn: '',
          iconOut: <SvgIcon component={meu} />,
        };

      return {
        left: true,
        text: `Editado por ${item.empresa}.`,
        iconIn: '',
        iconOut: <SvgIcon component={fora} />,
      };
    },
  },
  ...columns.slice(1),
];
