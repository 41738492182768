import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.system.overlay};
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 70px;
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    .button-costum {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 52px;
      border: 1px dashed ${theme.palette.words.subtitle.natural};
      border-radius: 4px;
      font-size: 14px;
      color: ${theme.palette.words.subtitle.natural};
    }
    .title-button {
      padding: 5px;
    }
  `}
`;

export const ListItems = styled.div`
  ${({ theme }) => css`
    display: flex;
    border-top: 1px solid ${theme.palette.brand.primary.light}4d;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 40px;
    .container-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${theme.palette.brand.secondary.natural};
      width: 40px;
      height: 40px;
      padding: 5px;
      border-radius: 100%;
    }
    .item-right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 40%;
    }
    .title {
      margin-left: 10px;
    }
    .status {
      border-radius: 10px;
      color: ${theme.palette.system.overlay};
      padding: 3px 10px 3px 10px;
    }
    .active {
      background-color: ${theme.palette.brand.secondary.light};
    }
    .inative {
      background-color: ${theme.palette.semantics.feedback.attention.natural};
    }
    .items-end {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  `}
`;
