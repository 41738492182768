import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div``;

export const BoxInformation = styled.div``;

export const CompanyInfo = styled.div`
  width: 100%;
  background: ${colors.white};
  border: 1px solid #95aac94d;
  border-radius: 4px;
  padding: 25px 40px;

  label {
    font-size: 14px;
    color: ${colors.greySubtitle};
    font-weight: bold;
    opacity: 0.8;
  }
`;
