import {
  getStatus,
  getTurmas,
  getDesviosTipos,
} from 'constants/_SERVICES/user';

// Pages
import Aluno from 'pages/CapacitacaoV2/Alunos';
import Turmas from 'pages/CapacitacaoV2/Turmas';
import MeuPerfil2 from 'pages/CapacitacaoV2/MeuPerfil';
import Capacitacao from 'pages/CapacitacaoV2/Conteudos';
import PerfilAluno from 'pages/CapacitacaoV2/PerfilAluno';
import CapacitacaoHomeV2 from 'pages/CapacitacaoV2/Home';
import ViewTurma from 'pages/CapacitacaoV2/Turmas/viewTurma';
import UploadAluno from 'pages/CapacitacaoV2/Alunos/UploadPage';
import DetalhesConteudoCapacitacao from 'pages/CapacitacaoV2/Config';
import AutomacaoCapacitacao from 'pages/AutomacaoCapacitacao';
import ConfiguracaoAutomacaoCapacitacao from 'pages/AutomacaoCapacitacao/Configuracao';
import DetalhesAutomacaoCapacitacao from 'pages/AutomacaoCapacitacao/Detalhes';
import Historico from 'pages/Historico';

export default {
  '/capacitacao': {
    title: 'Home',
    defaults: [],
    page: <CapacitacaoHomeV2 />,
  },

  '/capacitacao/': {
    title: 'Home',
    defaults: [],
    page: <CapacitacaoHomeV2 />,
  },

  '/capacitacao/home': {
    title: 'Home',
    defaults: [],
    page: <CapacitacaoHomeV2 />,
  },

  '/capacitacao/criar': {
    back: -1,
    title: 'Novo Conteúdo',
    subTitle: 'Conteúdo',
    id: true,
    defaults: [],
    page: <DetalhesConteudoCapacitacao />,
  },

  '/capacitacao/editar/:id': {
    back: -1,
    title: 'Conteúdo',
    id: true,
    status: true,
    defaults: [],
    page: <DetalhesConteudoCapacitacao />,
  },

  '/capacitacao/conteudos': {
    defaults: [],
    pageFilterName: 'filterConteudo',
    filters: [
      {
        name: 'status',
        placeholder: 'Filtrar por Status',
        mode: 'multiple',
        data: async () => getStatus(),
      },
      {
        name: 'turma',
        placeholder: 'Filtrar por Turma',
        mode: 'single',
        data: async () => getTurmas(),
      },
    ],

    page: <Capacitacao />,
  },

  '/capacitacao/alunos': {
    back: -1,
    title: 'Alunos e Turmas',
    id: true,
    defaults: [],
    page: <Aluno />,
  },

  '/capacitacao/turmas': {
    back: -1,
    title: 'Alunos e Turmas',
    id: true,
    defaults: [],
    page: <Turmas />,
  },

  '/capacitacao/viewTurmas/:id': {
    back: -1,
    title: 'Adicionar alunos',
    subTitle: 'Turmas',
    id: true,
    defaults: [],
    page: <ViewTurma />,
  },

  '/capacitacao/uploadaluno': {
    back: -1,
    title: 'Adicionar em Massa',
    subTitle: 'Alunos',
    id: true,
    defaults: [],
    page: <UploadAluno />,
  },

  '/capacitacao/perfil-do-aluno/info-aluno': {
    defaults: [],
    subTitle: 'Perfil do Aluno',
    title: 'Informações Gerais',
    page: <PerfilAluno />,
  },

  '/capacitacao/perfil-do-aluno/turmas-aluno': {
    defaults: [],
    subTitle: 'Perfil do Aluno',
    title: 'Turmas',
    page: <PerfilAluno />,
  },

  '/capacitacao/perfil-do-aluno/certificados-aluno': {
    defaults: [],
    subTitle: 'Perfil do Aluno',
    title: 'Certificados',
    page: <PerfilAluno />,
  },

  '/capacitacao/perfil-do-aluno/aprendizado-aluno': {
    defaults: [],
    subTitle: 'Perfil do Aluno',
    title: 'Aprendizados',
    page: <PerfilAluno />,
  },

  '/capacitacao/perfil': {
    back: -1,
    title: 'Minha Capacitação',
    id: true,
    defaults: [],
    page: <MeuPerfil2 />,
  },

  '/capacitacao/perfil?page=conteudos': {
    back: -1,
    title: 'Minha Capacitação',
    id: true,
    defaults: [],
    defaults: [],
    page: <MeuPerfil2 />,
  },

  '/capacitacao/automacao': {
    title: '',
    defaults: ['filials', 'clients'],
    pageFilterName: 'filterCapacitacaoAutomacao',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Tipo',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getDesviosTipos(),
      },
    ],
    date: true,
    page: <AutomacaoCapacitacao />,
  },

  '/automacao/configuracoes': {
    title: 'Configurar automações',
    defaults: [],
    page: <ConfiguracaoAutomacaoCapacitacao />,
  },

  '/automacao/capacitacao/detalhes/:id': {
    title: 'Envio automático',
    defaults: [],
    page: <DetalhesAutomacaoCapacitacao />,
    back: -1,
  },

  '/logs/automacao/capacitacao/:id': {
    back: -1,
    title: 'Histórico da Automação de Conteúdo',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/capacitacao/usuario-conteudo/:id/logs"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },
};
