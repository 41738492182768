import { createGlobalStyle, css } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/fonts/stylesheet.css';

export default createGlobalStyle`
  @font-face {
    font-family: 'Texta';
    src: url('../assets/fonts/Texta-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Texta';
    src: url('../assets/fonts/Texta-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  ${({ theme }) => css`
    * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
      font-family: 'Texta', sans-serif;
    }

    *:focus {
      outline: 0;
    }

    html,
    body,
    #root {
      height: 100%;
    }

    body {
      -webkit-font-smoothing: antialiased;
      -webkit-appearance: none;
      background-color: ${theme.palette.brand.primary.background};
    }

    a {
      text-decoration: none;
    }

    ul {
      list-style: none;
    }

    button {
      cursor: pointer;
    }

    .MuiFormLabel-root.Mui-error {
      color: ${theme.palette.words.text.dark} !important;
    }

    h1 {
      color: ${theme.palette.words.title.natural};
      font: normal 900 24px 'Texta', sans-serif;
    }

    h2 {
      color: ${theme.palette.words.subtitle.natural};
      font: normal 900 20px 'Texta', sans-serif;
    }

    h3 {
      color: ${theme.palette.words.caption.natural};
      font: normal normal 16px 'Texta', sans-serif;
    }

    ::-webkit-scrollbar {
      width: 8px;
      background: white;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px white;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.palette.system.scrollbar};
      border-radius: 10px;
    }
  `}
`;
