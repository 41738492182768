import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  width: 100%;
  overflow: hidden;

  .boxLoading {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  &.space {
    display: flex;
    justify-content: space-between;
  }

  &.flex {
    display: flex;
  }

  .textArea {
    color: ${colors.blueTitle};
    font-size: 24px;
    font-weight: 900;
  }

  .subTitle {
    margin-top: 10px;
    font-size: 16px;
    color: ${colors.greySubtitle};
    font-weight: 600;
  }

  .tooltipArea {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 5px;
  }
`;

export const ContainerButton = styled.div`
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'all' : 'none')};
  transition: all 0.3s ease-in-out;
`;

export const Content = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  width: 200%;
  overflow: hidden;
  margin-left: ${props => props.screen * -100}%;
  transition: margin-left 0.3s ease-in-out;
`;

export const Box = styled.div`
  width: 50%;
  border-radius: 5px;
  margin-top: 20px;
`;

export const SectionBox = styled.div`
  width: 100%;
  background-color: ${colors.white};
  border: 1px solid ${colors.greyTiny};
  border-radius: 5px;
  margin-top: 20px;

  .header {
    display: flex;
    justify-content: space-between;

    .left {
      .titleArea {
        margin-top: 20px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .icon {
          color: ${props =>
            props.active ? colors.greenSucces : colors.redDanger};
          margin-right: 5px;
          transition: all 0.3s ease-in-out;
        }

        .status {
          margin-left: 20px;
          color: ${props =>
            props.active ? colors.greenSucces : colors.redDanger};
          background-color: ${props =>
            props.active ? colors.greenBackground : colors.redBackground};
          border-radius: 50px;
          padding: 0px 25px;
          font-weight: 900;
          transition: all 0.3s ease-in-out;
        }

        .visibility {
          margin-left: 15px;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${colors.greySubtitle};

          .MuiSvgIcon-root {
            font-size: 16px;
            margin-right: 5px;
            color: ${colors.greenSubtitle};
          }
        }
      }

      .descriptionS {
        margin-top: 10px;
        margin-left: 20px;
        margin-bottom: 20px;
        color: ${colors.greySubtitle};
        font-weight: 600;
        word-wrap: break-word;
        max-width: 650px;
      }
    }

    .actions {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-right: 20px;
      margin-top: 20px;
      gap: 20px;

      .MuiSvgIcon-root {
        font-size: 16px;
        margin-right: 5px;
        color: ${colors.greenMain};

        &.bad {
          color: ${colors.redDanger};
        }
      }

      button {
        color: ${colors.greySubtitle};
        background-color: transparent;
        border: none;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;

export const BoxLine = styled.div`
  width: 100%;
  height: 70px;
  border-bottom: 1px solid ${colors.grey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  opacity: ${props => (props.active ? 1 : 0.5)};
  transition: opacity 0.3s ease-in-out;

  .actions {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 30px;

    .edit {
      font-size: 25px;
      margin-left: 20px;

      cursor: pointer;
    }
  }

  .page {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;

    .title {
      font-size: 20px;
      font-weight: bold;
      color: ${colors.greyTitle};
      width: 200px;
    }

    .public {
      font-size: 14px;
      font-weight: bold;
      color: ${colors.greyTitle};
      width: 100px;
    }

    .infos {
      margin-left: 5px;
      width: 200px;
      transform: scale(0.8);
      .in {
        span {
          font-weight: bold;
        }
      }
    }

    .feedbacks {
      display: flex;
      margin-left: 5px;
      gap: 5px;

      .box {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: ${colors.greySubtitle};
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        transition: all 0.2s ease-in-out;

        &:hover {
          opacity: 0.8;
          background-color: ${colors.greyBorder};
        }

        .MuiSvgIcon-root {
          font-size: 16px;
          margin-right: -5px;
          margin-left: 2px;
        }
      }
    }

    .MuiSvgIcon-root {
      font-size: 40px;
      margin-right: 20px;
      transition: all 0.3s ease-in-out;
    }
  }
`;

export const NewGuide = styled.div`
  width: 100%;
  height: 60px;
  border-bottom: 1px solid ${colors.greyTiny};
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  color: ${colors.greySubtitle};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 5px;
    color: ${colors.greySubtitle};
  }
`;

export const EmptySection = styled.div`
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.greyTiny};

  .text {
    font-weight: 600;
    color: ${colors.orangeAlert};
    font-size: 17px;
  }

  .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 5px;
    color: ${colors.orangeAlert};
  }
`;

export const SeeAll = styled.div`
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: ${props => (props.active ? 1 : 0.5)};
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  .text {
    font-weight: 600;
    color: ${colors.greyTitle};
    font-size: 18px;
  }

  .MuiSvgIcon-root {
    font-size: 25px;
    margin-left: 5px;
    color: ${colors.greyTitle};
  }
`;

export const SearchArea = styled.div`
  margin-top: 30px;
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    border: none;
    height: 50px;
    border: 2px solid ${colors.greyTiny};
    border-radius: 5px;
    width: 300px;
    padding-left: 10px;
    color: ${colors.greyTitle};
    font-size: 16px;
    font-weight: 500;

    &::placeholder {
      color: ${colors.grey};
      font-weight: 600;
      font-size: 16px;
    }

    &:focus {
      border: 2px solid ${colors.greenMain};
    }

    &:hover {
      border: 2px solid ${colors.greenMain};
    }
  }

  button {
    color: ${colors.greySubtitle};
    background-color: transparent;
    border: none;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: 0.8;
    }

    .MuiSvgIcon-root {
      font-size: 20px;
      margin-right: 5px;
      color: ${colors.greenMain};
    }
  }
`;
