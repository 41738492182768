// Styles
import { useTheme } from 'styled-components';

// React
import { useEffect, useState } from 'react';

// Components
import Loading from 'components/Loading';

// Components MI
import { SvgIcon, Tooltip } from '@mui/material';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

// Utils
import { ptMonths, formatNewDate } from 'utils/dates';

import { useSelector, useDispatch } from 'react-redux';
import {
  changeTaskStatus,
  changeTaskComponent,
} from 'store/modules/myTasks/actions';
import { sections } from './constants';
import * as S from './styled';

const Tasks = ({
  data,
  loading,
  reset,
  setReset,
  currentDay = new Date().toString(),
}) => {
  const theme = useTheme();
  // Redux
  const dispatch = useDispatch();
  const tasksContent = useSelector(state => state.myTasks);
  const compareDay = new Date(currentDay).toLocaleString().split(' ')[0];

  // General States
  const [month, setMonth] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [semanas, setSemanas] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [sectionPage, setSectionPage] = useState(null);

  // Components States
  const [semana, setSemana] = useState(null);
  const [dispatcher, setDispatcher] = useState(null);
  const [isNull, setIsNull] = useState(false);
  const [items, setItems] = useState(5);
  const resetForced = tasksContent.lastView !== compareDay;

  // Verify route (In case to be necessary in another pages)
  useEffect(() => {
    const path = window.location.pathname.substring(1);
    const keys = Object.keys(sections);
    for (const i in keys) {
      if (keys[i] === path) {
        setSectionPage(sections[keys[i]]);
      }
    }

    if (resetForced) {
      dispatch(
        changeTaskComponent({
          savedComponent: 1,
        }),
      );
    }
  }, []);

  // Get current month to open by default
  useEffect(() => {
    if (!visible) {
      const today = new Date(currentDay);
      for (const i in data) {
        const { mes } = data[i];
        const { semanas } = data[i];
        for (const j in semanas) {
          const inicio = new Date(semanas[j].inicio);
          const final = new Date(semanas[j].final);
          if (today > inicio && today < final) {
            setCurrentMonth(mes);
            setMonth(resetForced ? mes : tasksContent.savedMonth);
            setVisible(true);
          }
        }
      }
    }
  }, [data]);

  // Select week into month
  useEffect(() => {
    if (data) {
      for (const i in data) {
        if (data[i].mes === month) {
          setSemanas(data[i].semanas);
          setCurrentData(i);
        }
      }
    }
  }, [month, data]);

  // Controll component viewer
  const changePage = () => {
    const getActive = !resetForced ? tasksContent.savedComponent : 1;
    const { Component } = sectionPage.find(item => item.value === getActive);

    return (
      <Component
        semanas={semanas}
        semana={semana}
        setSemana={setSemana}
        items={items}
        setItems={setItems}
        setCurrentWeek={setCurrentWeek}
        currentDay={currentDay}
        tasksContent={tasksContent}
        resetForced={resetForced}
      />
    );
  };

  const toggleDispatch = item => {
    dispatch(
      changeTaskComponent({
        savedComponent: item,
      }),
    );
  };

  // --------------------------------HEADER CONTROLL--------------------------------------//
  const nextWeek = () => {
    setItems(5);
    const totalSem = semanas.length - 1;

    // Controll click with the week are last option in back
    if (Number(semana) >= totalSem - 1 && currentData >= data.length - 1) {
      setIsNull(true);
    } else {
      setIsNull(false);
    }

    // Controll with is necessary toggle month or week
    if (Number(semana) >= totalSem) {
      if (currentData < data.length - 1) {
        if (Number(month) === 11) {
          setMonth(0);
        } else {
          setMonth(Number(month) + 1);
        }
      }
    } else {
      setSemana(Number(semana) + 1);
    }
    setDispatcher('NEXT');
  };

  const backWeek = () => {
    setItems(5);

    // Controll click with the week are last option in next
    if (semana - 1 <= 0 && currentData <= 0) {
      setIsNull(true);
    } else {
      setIsNull(false);
    }

    // Controll with is necessary toggle month or week
    if (semana > 0) {
      setSemana(semana - 1);
    } else if (currentData > 0) {
      if (month > 0) {
        setMonth(month - 1);
      } else {
        setMonth(11);
      }
    }
    setDispatcher('BACK');
  };

  // Reset week and Month
  const resetTasks = () => {
    setDispatcher(null);
    setMonth(Number(currentMonth));
    setSemana(currentWeek);
  };

  useEffect(() => {
    if (dispatcher && reset) {
      if (dispatcher === 'NEXT') {
        setSemana(0);
      } else if (dispatcher === 'BACK') {
        setSemana(semanas.length - 1);
      }
    }
    setReset(true);
  }, [semanas]);

  // ------------------------------------DISPATCHER REDUX--------------------------------------//
  useEffect(() => {
    if (reset) {
      dispatch(
        changeTaskStatus({
          savedWeek: Number(semana),
          savedMonth: Number(month),
          lastView: new Date(currentDay).toLocaleString().split(' ')[0],
        }),
      );
    }
  }, [semana]);

  // ---------------------------------------RENDER----------------------------------------------//
  return (
    <S.Wrapper>
      <S.Header>
        <div className="box">
          {sectionPage &&
            sectionPage.map((i, idx) => (
              <Tooltip title={i.title} key={idx}>
                <span
                  className={
                    tasksContent.savedComponent === i.value
                      ? 'item active'
                      : 'item'
                  }
                  onClick={() => toggleDispatch(i.value)}
                >
                  <SvgIcon
                    component={i.icon}
                    stroke={
                      tasksContent.savedComponent === i.value
                        ? theme.palette.brand.secondary.natural
                        : theme.palette.brand.primary.light
                    }
                    fontSize="28px"
                  />
                </span>
              </Tooltip>
            ))}
        </div>
      </S.Header>
      <S.Line restorable={currentWeek != semana || currentMonth != month}>
        <span className="topBox">
          <p className="month">{ptMonths[month]}</p>
          <span className="backWeek" onClick={resetTasks}>
            Ver semana atual
          </span>
        </span>

        <span className="dateBox">
          <KeyboardDoubleArrowLeftOutlinedIcon
            htmlColor={
              isNull && dispatcher === 'BACK'
                ? theme.palette.words.title.light
                : theme.palette.words.title.natural
            }
            onClick={
              isNull && dispatcher === 'BACK' ? undefined : () => backWeek()
            }
            className={isNull && dispatcher === 'BACK' ? 'arrow null' : 'arrow'}
            title="arrow-back-tasks"
          />

          <span className="date">
            {semanas[semana]?.inicio
              ? formatNewDate(new Date(semanas[semana].inicio))
              : '-'}
          </span>

          <span className="date">
            {semanas[semana]?.final
              ? formatNewDate(new Date(semanas[semana].final))
              : '-'}
          </span>

          <KeyboardDoubleArrowRightOutlinedIcon
            htmlColor={
              isNull && dispatcher === 'NEXT'
                ? theme.palette.words.title.light
                : theme.palette.words.title.natural
            }
            onClick={
              isNull && dispatcher === 'NEXT' ? undefined : () => nextWeek()
            }
            className={isNull && dispatcher === 'NEXT' ? 'arrow null' : 'arrow'}
            title="arrow-next-tasks"
          />

          <div className="outedBar" />
        </span>
      </S.Line>

      <S.ViewBox visible={visible}>
        {!loading && sectionPage !== null && data && changePage()}
      </S.ViewBox>

      {loading && !visible && (
        <S.BoxLoading>
          <Loading />
        </S.BoxLoading>
      )}
    </S.Wrapper>
  );
};

export default Tasks;
