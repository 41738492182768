import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Main = styled.div`
  display: flex;
`;

export const Header = styled.div`
  padding: 22px 0px 0px 0px;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 24px;
    color: ${theme.palette.brand.primary.natural};
    margin-top: -15px;
    margin-bottom: 5px;
    font-weight: 900;
    letter-spacing: 1.1px;
  `}
`;
