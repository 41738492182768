import React, { useEffect, useState, useRef } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { MenuItem } from '@mui/material';
import { useTheme } from 'styled-components';
import * as S from './styled';

// TODO Não fecha ao selecionar opção

const SelectOne = ({
  value,
  handleChange,
  data, // Itens a que são exibidos no select
  label,
  clear, // Hablita a função que limpa a seleção do campo
  type, // Tipagem para criar nova formatação de dados se necessario
  disabled,
  error,
  required,
  refs, // Para referenciar com "useRef" Ex: pagina de criação de avaliação
  tam, // Opcional -> Aumentar o tamanho do componente caso esteja muito pequeno na aplicação
  noAsterisk, // Opcional -> Retirar o asterisco do label
  ...props
}) => {
  const theme = useTheme();
  const [items, setItems] = useState(null);
  const [search, setSearch] = useState('');
  const [finalResult, setFinalResult] = useState([]);
  const [name, setName] = useState([]);
  const [controllerSelect, setControllerSelect] = useState(false);

  useEffect(() => {
    // Se necessario uma formatação diferente
    // Criar ua condicional nesse useEffect
    // Para tranformar as props em value & text
    let newFormated = [];
    for (const i in data) {
      switch (type) {
        case 'driver':
          newFormated.push({
            value: data[i].id,
            text: data[i].nome,
          });
          break;
        case 'truck':
          newFormated.push({
            value: data[i].id,
            text: data[i].placa,
          });
          break;
        default:
          newFormated = data;
      }
    }
    setItems(newFormated);

    // Type === env é apenas por garantia de um comportamento indesejado em outras paginas
    if ((type === 'env', value)) {
      const item = newFormated.find(item => item.value == value);
      setName([item?.text]);
    }
  }, [data]);

  const formatData = () => {
    if (items && search) {
      const letters = [];
      for (const i in items) {
        const name = items[i].text.toLowerCase();
        if (name.includes(search)) {
          letters.push(items[i]);
        }
      }
      setFinalResult(letters);
    } else if (items) {
      setFinalResult(items);
    }
  };

  const formatName = (value, text) => {
    setName([text]);
    handleChange(value);
  };

  // Usado no modal de edição para pagina de rotograma
  // Sem isso o valor ja existente nao é identificado pelo select
  // **type=="routes" é apenas por garantia de que nao ira quebrar nada
  // em outras paginas
  useEffect(() => {
    if (type == 'routes') {
      setName([value]);
      handleChange(value);
    }
  }, [value]);

  useEffect(() => {
    formatData();
  }, [search, items]);

  const ITEM_HEIGHT = 20;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        // TODO altura fixa pra evitar sobreposição do input
        // minHeight: "250px",
        // maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        height: '200px',
        width: 'inherit',
      },
    },
  };

  // Controller Handle and Close Select
  useEffect(() => {
    if (controllerSelect) {
      setTimeout(() => {
        window.onclick = event => {
          const __DomTokenList = Array.from(event.target.classList);
          if (!__DomTokenList.includes('searchBox')) {
            setControllerSelect(false);
          }
        };
      }, 100);
    } else {
      window.onclick = () => {};
    }

    return function cleanup() {
      window.onclick = () => {};
    };
  }, [controllerSelect]);

  return (
    <S.InputWrapper>
      <p
        style={{
          color: disabled
            ? theme.palette.words.subtitle.dark
            : theme.palette.words.subtitle.natural,
          font: 'normal medium 14px/20px Texta',
        }}
      >
        {label}
        {!noAsterisk && (
          <span
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: required
                ? theme.palette.semantics.feedback.attention.dark
                : theme.palette.system.transparent,
            }}
          >
            *
          </span>
        )}
      </p>
      <FormControl sx={{ width: tam || '100%', border: 'none' }}>
        <S.StyledTextField
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={name}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}
          ref={refs}
          disabled={disabled}
          error={error}
          open={controllerSelect}
          onClick={
            !controllerSelect && !disabled
              ? () => setControllerSelect(true)
              : undefined
          }
        >
          <S.SearchBox className="searchBox">
            <S.SearchInput
              type="text"
              placeholder="Buscar..."
              value={search}
              onChange={e => setSearch(e.target.value.toLowerCase())}
              className="searchBox"
            />
            <SearchOutlinedIcon
              className="searchIcon"
              htmlColor={theme.palette.semantics.feedback.unknown.natural}
            />
          </S.SearchBox>

          {clear && (
            <S.ClearSelection onClick={() => handleChange('')}>
              Limpar Seleção
            </S.ClearSelection>
          )}
          <S.OptionsArea className="ClickedArea">
            {finalResult.length > 0 &&
              finalResult.slice(0, 150).map(item => (
                <MenuItem
                  className="ClickedArea"
                  key={item.value}
                  label={item.text}
                  value={item.value}
                  onClick={() => {
                    formatName(item.value, item.text);
                    setControllerSelect(false);
                  }}
                >
                  {item.text}
                </MenuItem>
              ))}
            {finalResult.length === 0 && (
              <S.EmptyOptions>Nenhuma opção disponível</S.EmptyOptions>
            )}
          </S.OptionsArea>
        </S.StyledTextField>
      </FormControl>
    </S.InputWrapper>
  );
};

export default SelectOne;
