import { Modal } from '@mui/material';
import styled from 'styled-components';
import colors from 'styles/colors';

export const ModalMUI = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 500px;
  min-height: 340px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${colors.greyTiny};
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleArea {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .title {
      margin-left: 15px;
      font-weight: 900;
      font-size: 20px;
      color: ${colors.greyTitle};
    }
  }
`;

export const Infos = styled.div`
  margin-top: 30px;
  font-size: 16px;
  color: ${colors.greyTitle};
  font-weight: 600;
  word-break: break-all;
  text-align: center;
  width: 100%;
`;

export const TagsArea = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -32px;
  transform: scale(0.8);
`;

export const Details = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${colors.greyTiny};
  padding: 20px 15px;
  border-radius: 5px;
  background-color: ${colors.greyBackgroud};

  .imageArea {
    width: 150px;
    height: 100px;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .title {
    margin-left: 15px;
    font-weight: 900;
    font-size: 20px;
    color: ${colors.greyTitle};
  }
`;

export const Buttons = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
