import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SaveAlt, AddCircleOutline } from '@mui/icons-material';

import ExcelModal from 'components/ExcelModal';
import Button from 'components/Buttons/Default';
import ButtonGhost from 'components/Buttons/Ghost';
import EmptyCard from 'components/Cards/EmptyDataCard';
import { FilterTable } from 'components/_Table/templates/filter';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import * as services from '../services';
import { fields, resetExcelFields } from './constants';

const ConfigVelocidades = () => {
  const navigate = useNavigate();

  // EXCEL STATES
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [openExcelModal, setOpenExcelModal] = useState(false);

  const selects = useSelector(state => state.selects);
  const { user } = useSelector(state => state.auth);
  const isProvider = user?.user?.provider;

  // FILTER OPTIONS
  const { data: _tiposVeiculos = [] } = useQuery(
    ['tipos-veiculos-telemetria'],
    () => services.getTiposVeiculos(),
    { staleTime: Infinity },
  );

  const tiposVeiculos = _tiposVeiculos.map(tipo => ({
    label: tipo.tipo,
    value: tipo.id,
  }));

  // FEFTCH DATA
  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery(
    ['velocidades-telemetria'],
    () => services.getDesvios({ categoria: ['VELOCIDADE'] }),
    { refetchOnWindowFocus: false },
  );

  // Excel Fields
  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(
      item =>
        item.selected === true && (!isProvider || item.label !== 'Cliente'),
    );
    const formatedDate = formatNameDate(new Date());
    const { filters } = JSON.parse(
      localStorage.getItem('table-telemetria-velocidade'),
    );

    const newQuery = {
      id_distribuidora:
        filters.find(filter => filter.key === 'cliente')?.value ?? null,
      id_tipo_veiculo:
        filters.find(filter => filter.key === 'veiculo')?.value ?? null,
      id_tipo_padrao: 1,
      excelFields: newFields,
      categoria: 'VELOCIDADE',
    };

    const res = await services.requestExcel(newQuery, isProvider);
    if (res.data && res.data?.data?.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `telemetria_velocidades${formatedDate}`,
      });
    else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // HANDLERS
  const handleAtivar = async (id, row) => {
    let velocidade = null;
    if (row) {
      velocidade = row;
    } else {
      velocidade = data.find(item => String(item.id) === String(id));
    }

    if (!velocidade.id_empresa && !isProvider)
      return toast.warning('Esse desvio foi criado pelo cliente');
    if (velocidade.ativo) return toast.success('Essa velocidade já está ativa');

    const clientesAtivos = selects.clients
      .filter(i => i.status == 'ATIVO')
      .map(i => i.id);
    if (clientesAtivos.includes(velocidade.id_distribuidora))
      return toast.warning(
        'Este cliente está ATIVO! Sua parametrização deve ser definida por ele.',
      );

    velocidade.ativo = true;
    const res = await services.activateDesvio(velocidade.id);

    if (res.success) {
      refetch();
      toast.success(res.message);
    } else toast.error(res.message);
  };

  const handleDesativar = async (id, row) => {
    let velocidade = null;
    if (row) {
      velocidade = row;
    } else {
      velocidade = data.find(item => String(item.id) === String(id));
    }

    if (!velocidade.id_empresa && !isProvider)
      return toast.warning('Esse desvio foi criado pelo cliente');
    if (!velocidade.ativo)
      return toast.success('Essa velocidade já está inativa');

    velocidade.ativo = false;

    const res = await services.desactivateDesvio(velocidade.id);
    if (res.success) {
      refetch();
      toast.success(res.message);
    } else toast.error(res.message);
  };

  const handleEditar = id => {
    navigate(`/telemetria/configuracoes/velocidades/${id}`);
  };

  // TABLE CONFIGS
  const actions = [
    {
      title: 'Ativar',
      function: handleAtivar,
    },
    {
      title: 'Desativar',
      function: handleDesativar,
    },
    {
      title: 'Editar',
      function: handleEditar,
    },
    {
      title: 'Ver Histórico',
      function: id =>
        navigate(`/logs/telemetria/configuracoes/velocidades/${id}`),
    },
  ];

  const columns = [
    {
      header: 'Status',
      id: 'ativo',
      type: 'boolean',
      sort: true,
      switch: {
        value: (_, item) => item.ativo,
        onChange: (value, item) =>
          value ? handleAtivar(null, item) : handleDesativar(null, item),
      },
      width: 105,
    },
    {
      header: 'Titulo',
      id: 'titulo',
      type: 'string',
      sort: true,
    },
    {
      header: 'Cliente',
      id: 'cliente',
      type: 'string',
      sort: true,
      attention: (_, item) => {
        if (!item.id_empresa && !isProvider)
          return {
            text: 'Desvio configurado pelo cliente',
          };
      },
      width: 150,
    },
    {
      header: 'Tipo de veículo',
      id: 'tipoVeiculo',
      type: 'string',
      sort: true,
    },
    {
      header: 'Condições',
      id: 'condicoes',
      type: 'string',
      value: (_, item) => {
        if (
          !item.carregado &&
          item.carregado !== false &&
          !item.pista_molhada &&
          item.pista_molhada !== false
        )
          return 'Todos';
        let complemento = '';
        if (item.carregado === true) complemento += 'Veículo Carregado\n';
        if (item.carregado === false) complemento += 'Veículo Vazio\n';
        if (item.pista_molhada === true) complemento += 'Pista Molhada\n';
        if (item.pista_molhada === false) complemento += 'Pista Seca\n';
        return complemento;
      },
    },
    {
      header: 'Total Desvios',
      id: 'violacoes.length',
      type: 'number',
      sort: true,
      align: 'center',
    },
  ];

  let filters = [
    {
      label: 'Filtro por Veículo',
      placeholder: 'Selecione o tipo de veículo',
      data: tiposVeiculos,
      mode: 'single',
      key: 'veiculo',
      keysToSearch: ['id_tipo_veiculo'],
    },
  ];

  if (!isProvider)
    filters.unshift({
      label: 'Filtro por Cliente',
      placeholder: 'Selecione o cliente',
      data: selects.clients.map(i => ({ label: i.nome, value: i.id })),
      mode: 'single',
      key: 'cliente',
      keysToSearch: ['id_distribuidora'],
    });

  const headerActions = (
    <div style={{ display: 'flex', gap: 10 }}>
      <ButtonGhost
        startIcon={<SaveAlt />}
        onClick={() => setOpenExcelModal(true)}
      >
        EXPORTAR
      </ButtonGhost>
      <Button
        startIcon={<AddCircleOutline />}
        onClick={() => navigate('/telemetria/configuracoes/velocidades/criar')}
      >
        ADICIONAR VELOCIDADE
      </Button>
    </div>
  );

  return (
    <>
      {!isLoading && !data.length && (
        <EmptyCard
          image="padconfig.png"
          title="Comece a configurar as faixas de velocidade por aqui."
          subtitle=" "
          handleDefaultButton={() =>
            navigate('/telemetria/configuracoes/velocidades/criar')
          }
          defaultButtonContent={
            <>
              <AddCircleOutline /> Adicionar velocidade
            </>
          }
          margintop="0"
        />
      )}
      {!isLoading && !!data.length && (
        <FilterTable
          local
          data={data.map(item => ({
            ...item,
            cliente: item.distribuidora?.nome ?? 'Todos',
            tipoVeiculo: item.tipo_veiculo?.tipo ?? 'Todos',
          }))}
          columns={columns}
          filters={filters}
          persist="telemetria-velocidade"
          searchKeys={['titulo', 'distribuidora.nome', 'tipo_veiculo.tipo']}
          placeholder="Buscar Velocidade"
          permitIsSortedOccur
          resetPaginationOnFilter
          actions={actions}
          empty={{
            title: 'Ainda não há faixas de velocidade cadastradas.',
            description: 'Cadastre as faixas de velocidade.',
            image: 'motorista.png',
          }}
          headerActions={headerActions}
        />
      )}
      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields.filter(
            item => !isProvider || item.label !== 'Cliente',
          )}
          loading={loadingExcel}
        />
      )}
    </>
  );
};

export default ConfigVelocidades;
