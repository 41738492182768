import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  filter: {},
};

export default function filterTelemetriaTratativaDesvios(
  state = INITIAL_STATE,
  action,
) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER: {
        return { ...state, filter: { ...action.payload } };
      }
      case types.RESET_FILTER: {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
