export const getColumns = ({
  handleAtivar,
  handleDesativar,
  isProvider = false,
}) => {
  return !isProvider
    ? [
        {
          header: 'Status',
          id: 'ativo_acao_suspensao',
          type: 'boolean',
          switch: {
            value: (_, item) => item.ativo_acao_suspensao,
            onChange: (value, item) =>
              value ? handleAtivar(null, item) : handleDesativar(null, item),
          },
          sort: true,
          width: 105,
        },
        {
          header: 'Desvio',
          id: 'titulo',
          type: 'string',
          sort: true,
        },
        {
          header: 'Pontuação do desvio',
          id: 'pontos',
          type: 'number',
          sort: true,
          align: 'center',
        },
        {
          header: 'Pontuação ações/suspensões',
          id: 'pontos_acao_suspensao',
          type: 'number',
          sort: true,
          align: 'center',
          value: (_, item) =>
            item.pontos_acao_suspensao
              ? item.pontos_acao_suspensao
              : item.ativo_acao_suspensao
              ? item.pontos
              : '-',
        },
      ]
    : [
        {
          header: 'Status',
          id: 'ativo_acao_suspensao',
          type: 'boolean',
          switch: {
            value: (_, item) => item.ativo_acao_suspensao,
            onChange: (value, item) =>
              value ? handleAtivar(null, item) : handleDesativar(null, item),
          },
          sort: true,
          width: 105,
        },
        {
          header: 'Desvio',
          id: 'titulo',
          type: 'string',
          sort: true,
        },
        {
          header: 'Pontuação do desvio',
          id: 'pontos',
          type: 'number',
          sort: true,
          align: 'center',
        },
      ];
};
