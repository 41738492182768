import api from 'services/api';

// ------------------------------------ENDPOINTS DE LAYOUT-----------------------------------------//

export const requestLayouts = async query => {
  const res = await api.get(`/layout`, { params: query });
  return res;
};

export const requestCards = async query => {
  const res = await api.get('/layout-cards', { params: query });
  return res;
};

export const requestExcel = async query => {
  const res = await api.get('/excel/layout', { params: query });
  return res;
};

export const requestCaeExcel = async query => {
  const res = await api.get('/excel/cae', { params: query });
  return res;
};

export const approveLayouts = async data => {
  const res = await api.put('/layout-aprovador', { ids: data });
  return res.data;
};

export const deleteLayouts = async data => {
  const res = await api.put('/layout-delete', { ids: data });
  return res.data;
};

export const reativarLayouts = async data => {
  const res = await api.put('/layout-reativar', { ids: data });
  return res.data;
};

export const seguirPosto = async id => {
  const res = await api.put(`/layout/seguir/${id}`);
  return res;
};

export const desseguirPosto = async id => {
  const res = await api.put(`/layout/desseguir/${id}`);
  return res;
};
