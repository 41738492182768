import { useRef } from 'react';
import { Close } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { validateBr } from 'js-brasil';
import * as S from './styled';

const Tags = ({ data, tagKey, handleDelete }) => {
  return (
    <S.Tag>
      <h3 className="name">{tagKey ? data[tagKey] : data}</h3>
      {!data?.blocked && (
        <Close
          className="ico"
          onClick={() => {
            handleDelete(data);
          }}
        />
      )}
    </S.Tag>
  );
};

export const InputTags = ({
  tags,
  setTags,
  tagKey,
  placeHolder,
  validationKey,
}) => {
  const tagRef = useRef();

  const handleDelete = value => {
    const newtags = tagKey
      ? tags.filter(val => val[tagKey] !== value[tagKey])
      : tags.filter(val => val !== value);
    setTags(newtags);
  };

  const validateInput = newValue => {
    // Valor vazio
    if (!newValue) {
      return false;
    }
    // Valor existente
    const currentValues = tagKey ? tags.map(item => item[tagKey]) : tags;
    if (currentValues.includes(newValue)) {
      toast.error(`${tagKey} já inserido`);
      return false;
    }

    // é uma key a ser validada
    if (validationKey && validateBr[validationKey]) {
      const isValid = validateBr[validationKey](newValue);
      if (!isValid) {
        toast.error(`${validationKey} inválido.`);
        return false;
      }
    }
    return true;
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    const newValue = tagRef?.current?.value
      .replaceAll('\n', '')
      .trim()
      .toLowerCase();

    if (validateInput(newValue)) {
      if (tagKey) setTags([...tags, { [tagKey]: newValue, isNew: true }]);
      else setTags([...tags, newValue]);
    }
    tagRef.current.value = '';
  };

  return (
    <S.Container>
      {tags.map((data, index) => {
        return (
          <Tags
            data={data}
            tagKey={tagKey}
            handleDelete={handleDelete}
            key={index}
          />
        );
      })}

      <S.Input>
        <form onSubmit={handleOnSubmit} className="form">
          <TextField
            inputRef={tagRef}
            fullWidth
            variant="standard"
            size="small"
            placeholder={
              !tagRef?.current?.value && placeHolder ? placeHolder : ''
            }
          />
        </form>
      </S.Input>
    </S.Container>
  );
};
