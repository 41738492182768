// Styled
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { Fragment, useEffect, useRef, useState } from 'react';

// Components
import Search from 'pages/Fomularios/components/Search';

// Components MUI
import { SaveAlt } from '@mui/icons-material';
import { Divider, Popover } from '@mui/material';
import GhostButton from 'components/Buttons/Ghost';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Utils
import ExportToExcel from 'utils/exportToCvs';
import * as S from './styled';
// import { ExportFormPdf } from '../../../Export';

// Services
import * as services from '../../services';
import { ExportAlunosPdf } from './Export';

export const ModalAlunos = ({ data, open, onClose, currentPeriod, idForm }) => {
  const alunos = data?.alunos;
  const printAlunosRef = useRef();

  // Redux
  const { id, geral } = useSelector(state => state.conteudoProvider);

  // General States
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElLine, setAnchorElLine] = useState(null);
  const [filteredItems, setFilteresItems] = useState(data || []);
  const [loadingExport, setLoadingExport] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  // Empty Image
  const img = require('images/empty/frota.png');

  // Set filtered data
  useEffect(() => {
    if (search) {
      let newArr = alunos.filter(item =>
        item.nome.toLowerCase().includes(search.toLowerCase()),
      );
      setFilteresItems(newArr);
    } else {
      setFilteresItems(alunos);
    }
  }, [search, data]);

  // -------------------------------------------- EXPORTS -----------------------------------------------//
  const exportExcel = async () => {
    setLoadingExport(true);

    const exportArr = [];
    alunos.forEach(item => {
      exportArr.push({
        Nome: item.nome,
        Tipo: item.tipo,
        Filial: item.filial,
        Nota: item.nota,
        Tentativas: item.tentativas,
        Id_Certificado: item.certificado || '',
      });
    });

    ExportToExcel({
      excel: exportArr,
      name: `conteudo_id_${id}_${data?.nome} {new Date()}`,
    });
    setLoadingExport(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printAlunosRef.current,
    onBeforeGetContent: () => setLoadingExport(true),
    onAfterPrint: () => setLoadingExport(false),
    copyStyles: true,
    documentTitle: `conteudo_id_${id}_${data?.nome} {new Date()}`,
  });

  const getCertificate = async item => {
    if (!item.certificado) return toast.error('Aluno não possui certificado');
    let res = await services.getCertificate(item.certificado, {
      nota: item.nota,
      contentId: id,
    });
    if (res.success) {
      const link = res.data.link;

      const req = new XMLHttpRequest();
      req.open('GET', link, true);
      req.responseType = 'blob';

      let typeArchive = 'application/pdf';
      let mimiType = '.pdf';
      req.onload = async function () {
        const blob = new Blob([req.response], { type: typeArchive });
        const url = window.URL || window.webkitURL;
        const link = url.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('download', `certificado_${item.nome}${mimiType}`);
        a.setAttribute('href', link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      req.send();
    } else {
      toast.error('Ocorreu um erro ao gerar o certificado!');
    }
  };

  return (
    <>
      <S.ModalMUI open={open}>
        <S.Content>
          <S.Header>
            <div className="left">
              <div className="imageBox">
                <img src={data?.logo} />
              </div>
              <span className="title">{data?.nome}</span>
            </div>
            <div className="right">
              <CloseOutlinedIcon
                htmlColor={colors.greyTiny}
                onClick={onClose}
              />
            </div>
          </S.Header>
          <Divider />
          <S.Body>
            <S.Table>
              <S.SearchArea>
                <Search
                  value={search}
                  setValue={e => setSearch(e)}
                  placeholder="Buscar Aluno"
                />
                <GhostButton
                  children="Exportar"
                  icon={<SaveAlt />}
                  onClick={event => setAnchorEl(event.currentTarget)}
                  loading={loadingExport}
                />
                <Popover
                  sx={{ transform: 'translate(0px, 2px)' }}
                  id={anchorEl ? 'popover' : undefined}
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  onClick={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <S.Popup>
                    <button onClick={() => exportExcel()}>Excel</button>
                    <button onClick={() => handlePrint()}>PDF</button>
                  </S.Popup>
                </Popover>
              </S.SearchArea>
              <S.HeaderTable>
                <div className="columnHeader flex3">
                  <div className="lineName">NOME</div>
                </div>
                <div className="columnHeader flex1">
                  <div className="lineItem">TIPO</div>
                </div>
                <div className="columnHeader flex1">
                  <div className="lineItem">FILIAL</div>
                </div>
                <div className="columnHeader flex1 center">
                  <div className="lineItem">NOTA</div>
                </div>
                <div className="columnHeader flex1">
                  <div className="lineItem">TENTATIVAS</div>
                </div>
                <div className="columnHeader flex1 center">
                  <div className="lineItem">AÇÕES</div>
                </div>
              </S.HeaderTable>
              <S.BodyTable>
                {filteredItems?.length > 0 && (
                  <>
                    <div className="column flex3">
                      {filteredItems?.map((item, key) => (
                        <div className="lineItem" key={key}>
                          {item?.nome.toLowerCase()}
                        </div>
                      ))}
                    </div>
                    <div className="column flex1">
                      {filteredItems?.map((item, key) => (
                        <div className="lineItem" key={key}>
                          {item?.tipo}
                        </div>
                      ))}
                    </div>
                    <div className="column flex1">
                      {filteredItems?.map((item, key) => (
                        <div className="lineItem" key={key}>
                          {item?.filial}
                        </div>
                      ))}
                    </div>
                    <div className="column flex1">
                      {filteredItems?.map((item, key) => (
                        <div className="lineItem center" key={key}>
                          {item?.nota}
                        </div>
                      ))}
                    </div>
                    <div className="column flex1 ">
                      {filteredItems?.map((item, key) => (
                        <div className="lineItem center" key={key}>
                          {item?.tentativas}
                        </div>
                      ))}
                    </div>
                    <div className="column flex1 ">
                      {filteredItems?.map((item, key) => (
                        <Fragment key={key}>
                          <div
                            className="lineItem center"
                            key={key}
                            onClick={event => {
                              setAnchorElLine(event.currentTarget);
                              setCurrentId(item?.id);
                            }}
                          >
                            <MoreHorizIcon cursor="pointer" fontSize="large" />
                          </div>
                          {currentId === item?.id && (
                            <Popover
                              sx={{
                                transform: 'translate(0px, 2px)',
                                marginTop: '-20px',
                              }}
                              id={anchorElLine ? 'popover' : undefined}
                              open={!!anchorElLine}
                              anchorEl={anchorElLine}
                              onClose={() => {
                                setCurrentId(null);
                                setAnchorElLine(null);
                              }}
                              onClick={() => {
                                setCurrentId(null);
                                setAnchorElLine(null);
                              }}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                            >
                              <S.Popup>
                                <button onClick={() => getCertificate(item)}>
                                  Baixar certificado
                                </button>
                              </S.Popup>
                            </Popover>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </>
                )}
                {filteredItems?.length <= 0 && (
                  <S.EmptyArea>
                    <img src={img} />
                    <div className="emeptyText">
                      <span className="title">Nenhum resultado encontrado</span>
                    </div>
                  </S.EmptyArea>
                )}
              </S.BodyTable>
            </S.Table>
          </S.Body>
        </S.Content>
      </S.ModalMUI>

      <ExportAlunosPdf
        cont={geral}
        data={alunos}
        printRef={printAlunosRef}
        empresa={data?.nome}
      />
    </>
  );
};
