export const fields = [
  {
    label: 'Ativo',
    value: 'ativo',
    selected: true,
    default: false,
  },
  {
    label: 'Titulo',
    value: 'grupo_violacao.titulo',
    selected: true,
    default: false,
  },
  {
    label: 'Velocidade',
    value: 'limiar',
    selected: true,
    default: false,
  },
  {
    label: 'Intervalo de Tempo',
    value: 'intervalo_tempo',
    selected: true,
    default: false,
  },
  {
    label: 'Pontuação',
    value: 'pontos',
    selected: true,
    default: false,
  },
  {
    label: 'Tipo de veículo',
    value: 'grupo_violacao.tipo_veiculo.tipo',
    selected: true,
    default: false,
  },
  {
    label: 'Cliente',
    value: 'grupo_violacao.distribuidora.nome',
    selected: true,
    default: false,
  },
  {
    label: 'Pista Molhada',
    value: 'grupo_violacao.pista_molhada',
    selected: true,
    default: false,
  },
  {
    label: 'Carregado',
    value: 'grupo_violacao.carregado',
    selected: true,
    default: false,
  },
];

export const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = true;
    return i;
  });
};
