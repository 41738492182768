import styled, { css } from 'styled-components';

export const MapInfoWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    max-width: 16rem;
    height: 4.5rem;
    border-radius: 2px;
    background: ${theme.palette.system.overlay};
    display: flex;
    align-items: start;
    padding: 0.5rem;
    z-index: 999;
  `}
`;

export const MapInfoContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin: 0 0.5rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: left;
    font: normal normal medium 14px/21px Texta;
    color: ${theme.palette.words.subtitle.natural};
  `}
`;

export const MapInfoButton = styled.button`
  ${({ theme }) => css`
    background: ${theme.palette.system.overlay};
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  `}
`;

export const RadiusControlWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 1.5rem;
  right: 4rem;
  width: 10rem;
  height: 5rem;
  border-radius: 2px;
  flex-direction: column;
  overflow: hidden;
`;

export const RadiusControlTitle = styled.div`
  ${({ theme }) => css`
    flex: 1;
    width: 100%;
    display: flex;
    background: ${theme.palette.brand.primary.natural};
    color: ${theme.palette.brand.primary.background};
    font: normal normal bold 16px/18px Texta;
    letter-spacing: 0px;
    align-items: center;
    justify-content: center;
  `}
`;

export const RadiusControlActions = styled.div`
  ${({ theme }) => css`
    flex: 1;
    background: ${theme.palette.system.overlay};
    display: flex;
    justify-content: space-between;
    align-items: center;

    p: {
      font: normal normal bold 16px/18px Texta;
    }
  `}
`;

export const RadiusControlButton = styled.button`
  ${({ theme }) => css`
    flex: 1;
    background: ${theme.palette.system.overlay};
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  `}
`;
