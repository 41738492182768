export const validatorPerfil = form => {
  const errors = {
    endereco: {},
  };

  if (!form.nome_do_contato) errors.nome_do_contato = true;
  if (!form.email_do_contato) errors.email_do_contato = true;
  if (!form.nome) errors.nome = true;
  if (!form.razao_social) errors.razao_social = true;
  if (!form.cnpj) errors.cnpj = true;
  if (!form.endereco.estado) errors.endereco.estado = true;
  if (!form.endereco.rua) errors.endereco.rua = true;
  if (!form.endereco.cidade) errors.endereco.cidade = true;
  if (!form.endereco.numero) errors.endereco.numero = true;
  if (!form.endereco.bairro) errors.endereco.bairro = true;
  if (!form.endereco.cep) errors.endereco.cep = true;

  return Object.keys(errors).length > 1 ||
    Object.keys(errors.endereco).length > 0
    ? errors
    : null;
};
