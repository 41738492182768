export const tabs = [
  {
    value: 0,
    label: 'Geral',
  },
  {
    value: 1,
    label: 'Usuários',
  },
];
