// Styles
import colors from 'styles/colors';

// React
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Material UI
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import PlayLessonOutlinedIcon from '@mui/icons-material/PlayLessonOutlined';

import { initializeData } from 'store/modules/provider/capacitacaoConteudo/actions';
import * as services from '../../Conteudos/services';

// Utils
import * as S from './styled';
import {
  formatDescription,
  formatStatus,
  formatTitle,
  formatRibbonEmbarcadora,
  formatRibbonOnisys,
  formatRibbonUser,
  calcPercente,
  formatTextButton,
} from '../../actions';

const ContentCard = ({
  template,
  isTemplate = false,
  loadingHistoryLine,
  onClickNavigateEdit,
  onClickSketch,
  loadingScreen,
  onClickView,
  loadingCard,
  onClickTemplate,
  forMe,
  tryed,
}) => {
  const [screenView, setScreenView] = useState('100%');
  const [inHover, setInHover] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const img =
    'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/capacitacao%2Finfo%2Fcapas%2F1673467756358%2F2023-01-11_17-09-19_Capa_Conteudo_Capacitacao.jpg?alt=media&token=3ca2cd3b-f1c8-438a-a277-88580f7cbdbd';

  // ---------------------------TEXT FORMATING --------------------------//
  const formatedFinsih = date => {
    if (!date) {
      return 'Nunca encerrar';
    }
    return `Até: ${new Date(date).toLocaleDateString()}`;
  };

  const calcTryed = (t, ta) => {
    let formated = [];

    for (let i = 1; i <= t; i++) {
      if (i <= ta) {
        formated.push({ id: i, status: true });
      } else {
        formated.push({ id: i, status: false });
      }
    }
    return formated;
  };

  // -------------------------CALLED BY TEMPLATE -----------------------//
  const selectTemplate = async id => {
    setCurrentId(id);
    let res = await services.UseTemplate(id);
    if (res.success) {
      const cont = res?.data;
      const template = cont[0].id_do_template;
      const geral = {
        titulo: cont[0].titulo,
        descricao: cont[0].descricao,
        capa: cont[0].imagem_capa,
        tags: cont[0].tags.map(item => item.tag),
        desvios_tipos_padrao: cont[0].desvios_tipos_padrao,
        status: 'Rascunho',
      };
      const midias = cont[1].midias.map(m => {
        return {
          id: m.id,
          titulo: m.titulo,
          descricao: m.descricao,
          link: m.link,
          draggableId: m.ordem,
          ordem: m.ordem,
          tipo: m.tipo,
        };
      });
      const avaliacao = cont[2].perguntas;
      const publico = cont[3].turmas.map(item => {
        return {
          id: item.id_empresa,
        };
      });
      dispatch(
        initializeData({
          id: null,
          geral,
          midias,
          avaliacao,
          publico,
          template,
        }),
      );
      navigate('/capacitacao/criar?mode=template');
    } else {
      toast.error(res.message);
    }
    setCurrentId(null);
  };

  // -------------------------CALLED BY LIST -----------------------//
  const edit = async (id, status) => {
    setCurrentId(id);
    if (status === 'Rascunho') {
      await onClickSketch(id);
      setCurrentId(null);
    } else {
      await onClickNavigateEdit(id);
      setCurrentId(null);
    }
  };

  // -------------------------CALLED BY FORM ME -----------------------//
  const view = async id => {
    setCurrentId(id);
    await onClickView(id);
    setCurrentId(null);
  };

  return (
    <>
      <S.Wrapper
        className="cardContent"
        charge={loadingCard}
        loadingScreen={loadingScreen}
        inHover={inHover}
        onMouseLeave={() => {
          setInHover(false);
        }}
        onMouseEnter={() => {
          setInHover(true);
        }}
      >
        {loadingCard && currentId === template.id && (
          <S.LoadingBox>
            <Loading />
          </S.LoadingBox>
        )}
        <>
          <S.Image
            loadingScreen={loadingScreen || !template?.titulo}
            src={template?.imagem_capa || img}
            finished={
              template.form_status === 'Finalizado' ||
              template.form_status === 'Concluido' ||
              template.status === 'Finalizado' ||
              template.status === 'Concluido'
            }
          >
            <div className="preLoad" />
          </S.Image>
          {template.isEmbarcadora && (
            <S.Ribbon
              {...formatRibbonEmbarcadora(template?.distribuidora_nome)}
              isLoading={loadingScreen || !template?.titulo}
            />
          )}
          {template.isOnisys && (
            <S.Ribbon
              {...formatRibbonOnisys()}
              isLoading={loadingScreen || !template?.titulo}
            />
          )}
          {forMe && (
            <S.Ribbon
              {...formatRibbonUser(template?.status_usuario)}
              isLoading={loadingScreen || !template?.titulo}
            />
          )}
          <S.Content
            loadingScreen={loadingScreen || !template?.titulo}
            screenView={screenView}
            inHover={inHover}
            className="content"
            forMe={forMe}
            finished={
              template.form_status === 'Finalizado' ||
              template.form_status === 'Concluido' ||
              template.status === 'Finalizado' ||
              template.status === 'Concluido'
            }
            inLineLoading={loadingHistoryLine}
          >
            <div className="screenPrimary">
              <S.TagsArea>
                {template?.tags?.map((tag, key) => {
                  if (key > 2) {
                    return null;
                  }
                  if (tag.length > 14) {
                    return <S.Tag key={key}>{`${tag.slice(0, 10)}...`}</S.Tag>;
                  }
                  return <S.Tag key={key}>{tag}</S.Tag>;
                })}
              </S.TagsArea>

              <h6 className="title">{formatTitle(template.titulo)}</h6>
              <p className="description">
                {formatDescription(template.descricao, false, inHover)}
              </p>

              <span className="statusBox">
                {formatStatus(template.status, forMe)}
                {forMe && !tryed && (
                  <S.ProgressBar>
                    {calcPercente(
                      template.status !== 'Agendado' &&
                        template.status !== 'Pendente'
                        ? template?.percentual
                        : 0,
                    )}
                  </S.ProgressBar>
                )}

                {!forMe && !tryed && (
                  <span className="finished">
                    {formatedFinsih(template.data_fim)}
                  </span>
                )}

                {tryed && (
                  <S.TryedArea>
                    <div className="area">
                      {template?.tentativas > 0 &&
                        calcTryed(
                          template?.tentativas,
                          template?.tentativa_atual,
                        ).map((t, key) => {
                          if (t.status) {
                            return (
                              <HeartBrokenIcon
                                key={key}
                                htmlColor={colors.greenMain}
                              />
                            );
                          }
                          return (
                            <FavoriteIcon
                              key={key}
                              htmlColor={colors.greyTiny}
                            />
                          );
                        })}
                      {!template.tentativas && (
                        <div className="unlimited">Tentativas Ilimitadas</div>
                      )}
                    </div>
                  </S.TryedArea>
                )}
              </span>

              <S.MediaContainer forMe={false} inHover={inHover}>
                {template?.midiaCount?.VÍDEO && (
                  <S.StyledTooltip
                    title={`Vídeo (${template?.midiaCount?.VÍDEO})`}
                    arrow
                  >
                    <SlideshowOutlinedIcon
                      sx={{ color: colors.grey, marginRight: 1 }}
                    />
                  </S.StyledTooltip>
                )}
                {template?.midiaCount?.VÍDEO_UPLOADING && (
                  <S.StyledTooltip
                    title={`Upload (${template?.midiaCount?.VÍDEO_UPLOADING})`}
                    arrow
                  >
                    <BackupOutlinedIcon
                      sx={{ color: colors.grey, marginRight: 1 }}
                    />
                  </S.StyledTooltip>
                )}
                {template?.midiaCount?.TEXTO && (
                  <S.StyledTooltip
                    title={`Texto (${template?.midiaCount?.TEXTO})`}
                    arrow
                  >
                    <ArticleOutlinedIcon
                      sx={{ color: colors.grey, marginRight: 1 }}
                    />
                  </S.StyledTooltip>
                )}
                {template?.midiaCount?.IMAGEM && (
                  <S.StyledTooltip
                    title={`Imagem (${template?.midiaCount?.IMAGEM})`}
                    arrow
                  >
                    <PhotoOutlinedIcon
                      sx={{ color: colors.grey, marginRight: 1 }}
                    />
                  </S.StyledTooltip>
                )}
                {template?.midiaCount?.PDF && (
                  <S.StyledTooltip
                    title={`PDF (${template?.midiaCount?.PDF})`}
                    arrow
                  >
                    <PlayLessonOutlinedIcon
                      sx={{ color: colors.grey, marginRight: 1 }}
                    />
                  </S.StyledTooltip>
                )}
                {forMe && (
                  <span className="finished">
                    {formatedFinsih(template.data_fim)}
                  </span>
                )}
              </S.MediaContainer>

              <S.Buttons forMe={false} inHover={inHover}>
                <GhostButton
                  children="Visualizar"
                  style={{ width: '100%', marginBottom: '10px' }}
                  onClick={() => view(template?.id)}
                />

                {!forMe && !isTemplate && !template.onlyTemplate && (
                  <DefaultButton
                    children={
                      template.status === 'Rascunho'
                        ? 'Editar'
                        : template.needPublic
                        ? 'Definir Público'
                        : 'Resumo'
                    }
                    style={{ width: '100%' }}
                    onClick={() => edit(template?.id, template.status)}
                  />
                )}

                {!forMe && (isTemplate || template.onlyTemplate) && (
                  <DefaultButton
                    children="Selecionar Template"
                    style={{ width: '100%' }}
                    onClick={() => selectTemplate(template?.id)}
                  />
                )}

                {forMe && (
                  <DefaultButton
                    children={formatTextButton(template.status_usuario)}
                    style={{ width: '100%' }}
                    onClick={() => edit(template?.id)}
                    disabled={
                      (template.status === 'Finalizado' &&
                        template.status_usuario !== 'APROVADO') ||
                      template.status === 'Agendado' ||
                      (template.status_usuario === 'REPROVADO' &&
                        template?.tentativas <= template?.tentativa_atual &&
                        template?.tentativas)
                    }
                  />
                )}
              </S.Buttons>
            </div>
            <div className="preLoad" />
          </S.Content>
        </>
      </S.Wrapper>
    </>
  );
};

export default ContentCard;
