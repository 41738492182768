/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { colors } from 'styles/colors';

// React
import { useEffect, useState } from 'react';

// Components
import Tabs from 'components/Tabs';

// Components MUI
import { Divider } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TryOutlinedIcon from '@mui/icons-material/TryOutlined';

// Constants
import { tabs, text, icons } from './constants';

export const ModalStatistics = ({ open, handleClose, data }) => {
  const [tab, setTab] = useState('like');
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState(null);

  useEffect(() => {
    open && setTab(open);
  }, [open]);

  const handleTab = (e, newTab) => {
    setTab(newTab);
  };

  useEffect(() => {
    const filter = data[tab]?.filter(i =>
      i.nome.toLowerCase().includes(search.toLowerCase()),
    );

    search ? setFiltered(filter) : setFiltered(null);
  }, [search, tab]);

  return (
    <S.ModalMUI open={open}>
      <S.Container>
        <S.Header>
          <div className="left">
            <TryOutlinedIcon htmlColor={colors.greenSucces} />
            <h1>Estatísticas do guia</h1>
          </div>
          <div className="right">
            <CloseRoundedIcon
              htmlColor={colors.greySubtitle}
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </S.Header>
        <Divider />
        <S.Content>
          <Tabs
            value={tab}
            items={tabs.map(i => ({
              ...i,
              label: `${i?.label} (${data[i?.value]?.length || 0})`,
            }))}
            onChange={handleTab}
          />
          <S.SearchArea>
            <input
              placeholder="Buscar usuário"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </S.SearchArea>
          <Divider />
          <S.Body>
            {(filtered || data[tab])?.map((i, k) => (
              <>
                <S.LineFeed key={k} className={text[tab]}>
                  <div className="info">
                    {icons[tab]}
                    <h3>
                      {i.nome}{' '}
                      <span className="segment">
                        {i.comentario ? ':' : text[tab]}
                      </span>
                    </h3>
                  </div>

                  {i.comentario && <p>{i.comentario}</p>}
                </S.LineFeed>
                <Divider />
              </>
            ))}
          </S.Body>
        </S.Content>
      </S.Container>
    </S.ModalMUI>
  );
};
