export const fields = {
  1: {
    name: 'arquivo_evidencia',
    required: false,
  },
  4: {
    name: 'arquivo_evidencia',
    required: true,
  },
};
