import * as S from './styled';

export const Button = ({
  children,
  onClick,
  variant = 'default',
  ...props
}) => {
  return (
    <S.Button onClick={onClick} variant={variant} {...props}>
      {children}
    </S.Button>
  );
};
