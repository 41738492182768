/*
Ordenação:
  Tabela está habilitada para ordenar apenas 1 coluna;
  initialSortBy recebe a ordenacao padrão da tabela. EX:  [{ id: "id", desc: false }];
  sortBy é o estado que controla a ordenação;
  Toda vez que sortBy é alterado, uma nova busca é realizada com a ordenação selecionada;
  Para funcionar:
    1 - Definir id de cada coluna como o id do objeto do backend.
      EX: "motorista.nome" em desvio vai ordenar pelo nome do obj motorista dentro de desvio;
    2 - Ainda em columns, atribuir disableSortBy: true para colunas que não dever ser ordenadas;
    3 - Implementar recepcao de sortBy no backend como em desvioController.index
*/

import React, { forwardRef, useRef, useEffect, useState } from 'react';
import { useRowSelect, useTable, usePagination, useSortBy } from 'react-table';
import { useLocation } from 'react-router-dom';
import { setTableParams } from 'store/modules/tables/actions';

// Components
import Input from 'components/Inputs/TextField';
import Select from 'components/Inputs/Select';
import Icon from 'components/Icons';
import Loading from 'components/Loading';
import EmptyCard from 'components/Cards/EmptyDataCard';

// Material
import {
  InputAdornment,
  SvgIcon,
  Stack,
  Popover,
  MenuItem,
  IconButton,
} from '@mui/material';
import { ExpandMore, ExpandLess, MoreHoriz } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import { ReactComponent as searchIcon } from '../../images/icons/inputs/Search.svg';
import * as S from './styled';

// Timer para pesquisa (fora do compoenente para evitar reinicialização)
let inputTimer;

// Converte o path da página em um nome de tabela do tipo: "configuracoes/envios"
// #TODO Tabela esta reiniciando pageIndex e offset após update

const Table = ({
  columns, // Colunas a serem exibidas -- Required
  data, // Itens a serem exibidos -- Required
  pageCount: controlledPageCount, // TOTAL (exibidos + ñ exibidos) de itens existentes na tabela
  inputLabel, // Label do input de pesquisa
  fetchData, // Função que executa a requisição do conteudo da tabela
  setSelectedData, // Armazena os Ids de itens marcados no checkbox -- Optional
  loading, // Monitora o estado da rquisição
  updated, // Monitora a necessidade de atualização da requisição
  query, // Envia TODOS os parametros de pesquisa (cards, search, filter)
  permitIsSortedOccur, // Permite a ordenação da tabela -- Optional
  initialSortBy, // Define a ordenação padrão da tabela
  actions, // Ações referentes ao coluna "Ações" -- Optional
  bulkOptions, // Ações referentes ao topo da tabela -- Optional
  onClickRow, // Função executada ao clicar sobre a linha(exeto colunas de checkbox, acoes e status) -- Optional
  tableType, // True ==  Altera estilização da tabela (cabeçalho, input, e sombra) -- Optional,
  empty, // Objeto que recebe image, title, subtitle,
  blockSizePage, // Bloqueia a troca do tamanho da pagina
  doubleInput, // Exibi um input duplo no lugar do search para fazer origem e destino (ROTOGRAMA)
  noFooter,
  getPaginationData, // Função que permite à tela (parent) acessar os dados de paginação.
  tablePersist,
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: tablePersist?.pageIndex || 0,
        pageSize: tablePersist?.pageSize || 10,
        sortBy: tablePersist?.sortBy ||
          initialSortBy || [{ id: 'id', desc: false }],
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
      autoResetSortBy: false,
      disableSortBy: !permitIsSortedOccur,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      if (setSelectedData) {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      } else {
        hooks.visibleColumns.push(columns => [...columns]);
      }

      if (actions && columns[columns.length - 1]?.id === 'acoes') {
        // Evento de clique em Ações
        const clickAction = event => {
          setAnchorAction(event.currentTarget);
        };

        // Define coluna de ações
        columns[columns.length - 1].Cell = function ({ row }) {
          return (
            <IconButton
              size="medium"
              id={row.original.id}
              key={row.original.id}
              onClick={clickAction}
            >
              <MoreHoriz fontSize="large" />
            </IconButton>
          );
        };
      }
    },
  );

  const [starter, setStarter] = useState(true);
  const [anchorAction, setAnchorAction] = useState(false);
  const [needUpdate, setNeedUpdate] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [copySearch, setCopySearch] = useState('');
  const [sortApplied, setSortApplied] = useState(
    tablePersist?.sortBy || initialSortBy || [{ id: 'id', desc: false }],
  );
  const [updating, setUpdating] = useState(false);
  const [lastPageIndex, setLastpageIndex] = useState(false);

  const dispatch = useDispatch();

  // States for Rotograma page
  const [origem, setOrigem] = useState('');
  const [copyOrigem, setCopyOrigem] = useState('');
  const [destino, setDestino] = useState('');
  const [copyDestino, setCopyDestino] = useState('');
  const tableName = useLocation().pathname.split('/')[1];
  const handleTablePersist = data => {
    dispatch(
      setTableParams({
        tableName,
        data,
      }),
    );
  };

  // updating : estado que indica que já está atualizando
  // needUpdate: atualizacao que precisa resetar o pageIndex
  // pageIndex: chama fetch só se o lastPageIndex for diferente
  useEffect(() => {
    if (!updating && (needUpdate || pageIndex !== lastPageIndex)) {
      const fetch = async () => {
        setUpdating(true);

        let newIndex = pageIndex;
        if (needUpdate)
          // Commented for dont reset page after action in column "Ações"
          newIndex = 0;
        gotoPage(newIndex);
        setLastpageIndex(newIndex);

        const search = searchInput;
        setCopySearch(search);
        setCopyOrigem(origem);
        setCopyDestino(destino);

        await fetchData({
          pageIndex: newIndex,
          pageSize,
          search,
          sortBy,
          ...query,
          origem,
          destino,
        });

        setSortApplied(sortBy);
        setNeedUpdate(false);

        setUpdating(false);

        !starter && resetScroll();
        setStarter(false);
        handleTablePersist({ pageIndex: newIndex, sortBy, pageSize });
      };
      fetch();
    }
  }, [updating, needUpdate, pageIndex]);

  useEffect(() => {
    setNeedUpdate(true);
  }, [pageSize]);

  useEffect(() => {
    if (getPaginationData instanceof Function)
      getPaginationData({ pageSize, pageIndex, sortBy });
  }, [pageSize, pageIndex, sortBy]);

  // Busca atualizacao APENAS quando sortBy atual é diferente
  // do ultimo aplicado (sortApplied).
  useEffect(() => {
    if (
      !(
        (sortBy.length === 0 && sortApplied.length === 0) ||
        (sortBy[0] &&
          sortApplied[0] &&
          sortBy[0].id === sortApplied[0].id &&
          sortBy[0].desc === sortApplied[0].desc)
      )
    ) {
      setNeedUpdate(true);
    }
  }, [sortBy]);

  // #TODO Listen for changes in Query (TESTING)
  useEffect(() => {
    if (searchInput !== copySearch) {
      clearTimeout(inputTimer);
      inputTimer = setTimeout(() => {
        setNeedUpdate(true);
      }, 2000);
    }
  }, [searchInput]);

  // Used unic by Rotograma page
  const toggleItems = () => {
    setDestino(origem);
    setOrigem(destino);
  };

  useEffect(() => {
    if (origem !== copyOrigem) {
      clearTimeout(inputTimer);
      inputTimer = setTimeout(() => {
        setNeedUpdate(true);
      }, 2000);
    }
  }, [origem]);

  useEffect(() => {
    if (destino !== copyDestino) {
      clearTimeout(inputTimer);
      inputTimer = setTimeout(() => {
        setNeedUpdate(true);
      }, 2000);
    }
  }, [destino]);

  //------------------------------------------------------------------------

  useEffect(() => {
    if (updated) {
      setNeedUpdate(true);
    }
  }, [updated]);

  const resetScroll = () => {
    let table = document?.getElementById('local-table-offset');
    table?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const [anchorMore, setAnchorMore] = useState(false);

  const renderBulkMenu = options => {
    const evs = selectedFlatRows.map(i => i.original.id);
    return (
      <S.BulkOptions>
        <S.BulkItem>
          {selectedFlatRows.length}{' '}
          {selectedFlatRows.length === 1 ? 'Selecionado' : 'Selecionados'}
        </S.BulkItem>
        {options.map((i, idx) => {
          if (idx < 3) {
            return (
              <S.BulkItem
                key={idx}
                className="option"
                onClick={() => i.function(evs)}
              >
                {i.title}
              </S.BulkItem>
            );
          }
        })}

        {options.length > 3 && (
          <>
            <S.BulkItem
              className="button"
              onClick={e => setAnchorMore(e.currentTarget)}
            >
              <span className="btnText">Mais</span>
              <ArrowDropDownIcon />
            </S.BulkItem>
            <Popover
              id={anchorMore ? 'popover' : undefined}
              open={Boolean(anchorMore)}
              anchorEl={anchorMore}
              onClose={() => setAnchorMore(null)}
              onClick={() => setAnchorMore(null)} // Fechar ao clicar dentro tmb
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {options?.map((i, idx) => {
                if (idx >= 3) {
                  return (
                    <MenuItem key={idx}>
                      <S.ItemPopover onClick={() => i.function(evs)}>
                        {i.title}
                      </S.ItemPopover>
                    </MenuItem>
                  );
                }
              })}
            </Popover>
          </>
        )}
      </S.BulkOptions>
    );
  };

  const renderAcao = () => {
    return (
      <Popover
        id={anchorAction ? 'popover' : undefined}
        open={Boolean(anchorAction)}
        anchorEl={anchorAction}
        onClose={() => setAnchorAction(null)}
        onClick={() => setAnchorAction(null)} // Fechar ao clicar dentro tmb
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {actions?.map(action => {
          const hide =
            action.hide && anchorAction?.id && action.hide(anchorAction.id);
          if (!hide)
            return (
              <MenuItem
                key={action.name}
                onClick={() => action.action && action.action(anchorAction.id)}
              >
                <S.ItemPopover>{action.text}</S.ItemPopover>
              </MenuItem>
            );
        })}
      </Popover>
    );
  };

  const renderTable = () => {
    return (
      <div style={{ position: 'relative' }}>
        <S.Table {...getTableProps()}>
          <S.TableHead>
            {headerGroups.map((headerGroup, idx) => (
              <S.TableRow {...headerGroup.getHeaderGroupProps()} key={idx}>
                {headerGroup.headers.map((column, idx) => {
                  const center = column.centered;
                  return (
                    <S.TableHeader
                      key={idx}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={center ? { textAlign: 'center' } : {}}
                    >
                      {column.render('Header')}

                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ExpandMore fontSize="small" />
                          ) : (
                            <ExpandLess fontSize="small" />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </S.TableHeader>
                  );
                })}
              </S.TableRow>
            ))}
          </S.TableHead>

          {!loading && rows.length > 0 && (
            <S.TableBody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <S.TableRow {...row.getRowProps()}>
                    {row.cells.map((cell, idx) => {
                      return (
                        <S.TableData
                          key={idx}
                          {...cell.getCellProps()}
                          viewed={cell.row.original.visto}
                          onClick={
                            onClickRow &&
                            cell.column.id !== 'acoes' &&
                            cell.column.id !== 'selection' &&
                            cell.column.id !== 'status'
                              ? () => onClickRow(cell.row.original.id)
                              : null
                          }
                          className={
                            onClickRow &&
                            cell.column.id !== 'acoes' &&
                            cell.column.id !== 'selection' &&
                            cell.column.id !== 'status'
                              ? 'clicked--row'
                              : null
                          }
                        >
                          {cell.render('Cell')}
                        </S.TableData>
                      );
                    })}
                  </S.TableRow>
                );
              })}
            </S.TableBody>
          )}
        </S.Table>
        {!loading && rows.length <= 0 && (
          <div style={{ marginTop: '-30px' }}>
            <EmptyCard
              image={empty?.image ? empty.image : 'frota.png'}
              title={
                copySearch
                  ? `Nenhum resultado encontrado para "${copySearch}"`
                  : empty?.title
                  ? empty.title
                  : 'Ops! Nada para exibir'
              }
              subtitle={
                empty?.subtitle
                  ? empty.subtitle
                  : 'Verifique os filtros selecionados'
              }
            />
          </div>
        )}

        {loading && (
          <div
            style={{
              width: '100%',
              position: 'relative',
              background:
                '0% 0% no-repeat padding-box padding-box rgba(75, 81, 102, 0.2)',
              minHeight: '300px',
              borderRadius: '4px',
            }}
          >
            <Loading />
          </div>
        )}
        {!noFooter && (
          <S.Footer blockSizePage={blockSizePage}>
            {!blockSizePage && (
              <S.FooterCol>
                <p>Exibindo</p>
                <Select
                  value={pageSize}
                  variant="outlined"
                  margin="dense"
                  onChange={e => {
                    setPageSize(Number(e.target.value));
                  }}
                  data={[
                    { name: '10', value: 10 },
                    { name: '20', value: 20 },
                    { name: '30', value: 30 },
                  ]}
                  style={{ height: '35px', width: '70px', margin: ' 0px 5px' }}
                />
                <p>{`de ${pageCount}`}</p>
              </S.FooterCol>
            )}
            <Stack spacing={2}>
              <S.FooterCol>
                <S.Control onClick={() => gotoPage(0)}>
                  <Icon name="chevron-left-outline" />
                  <p>Primeiro</p>
                </S.Control>
                <S.Control onClick={() => previousPage()}>
                  <Icon name="chevron-left-outline" />
                  <p>Anterior</p>
                </S.Control>
                <S.Pages
                  count={Math.ceil(pageCount / pageSize)}
                  page={pageIndex + 1}
                  onChange={(e, value) => gotoPage(value - 1)}
                  hidePrevButton
                  hideNextButton
                />
                <S.Control
                  onClick={
                    pageIndex + 1 < Math.ceil(pageCount / pageSize)
                      ? () => nextPage()
                      : undefined
                  }
                >
                  <p>Próximo</p>
                  <Icon name="chevron-right-outline" />
                </S.Control>
                <S.Control
                  onClick={() => gotoPage(Math.ceil(pageCount / pageSize) - 1)}
                >
                  <p>Último</p>
                  <Icon name="chevron-right-outline" />
                </S.Control>
              </S.FooterCol>
            </Stack>
          </S.Footer>
        )}
      </div>
    );
  };

  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const theme = useTheme();
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <S.TableCheckbox
            indeterminate={indeterminate}
            check={rest.checked}
            style={{
              backgroundColor:
                rest.checked || indeterminate
                  ? theme.palette.brand.secondary.natural
                  : theme.palette.system.transparent,
            }}
          >
            {indeterminate && (
              <RemoveIcon htmlColor={theme.palette.words.text.contrast} />
            )}
            {!indeterminate && (
              <CheckIcon
                className="checkIcon"
                htmlColor={theme.palette.words.text.contrast}
              />
            )}
            <input type="checkbox" ref={resolvedRef} {...rest} />
          </S.TableCheckbox>
        </div>
      );
    },
  );

  useEffect(() => {
    setSelectedData &&
      setSelectedData(selectedFlatRows.map(item => item.original.id));
  }, [selectedFlatRows]);

  const handleSubmitSearch = e => {
    e.preventDefault();
    setNeedUpdate(true);
    clearInterval(inputTimer);
  };
  const theme = useTheme();

  return (
    <Grid container>
      <S.Wrapper
        item
        md={12}
        custom={tableType ? tableType.toString() : null}
        id="local-table-offset"
      >
        <Grid
          item
          md={12}
          paddingLeft={tableType ? 0 : 2}
          className="topPageContainer"
        >
          {!doubleInput && (
            <form id="searchForm" action="" onSubmit={handleSubmitSearch}>
              <Input
                placeholder={inputLabel}
                name="observacoes"
                id="observacoes"
                value={searchInput}
                onChange={e => setSearchInput(e.target.value)}
                style={{
                  padding: '10px!important',
                  width: '300px',
                  background: tableType
                    ? theme.palette.system.overlay
                    : theme.palette.brand.primary.background,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        component={searchIcon}
                        color={theme.palette.semantics.feedback.unknown.natural}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          )}

          {doubleInput && (
            <S.DoubleInput>
              <form id="searchForm" action="" onSubmit={handleSubmitSearch}>
                <Input
                  placeholder="Buscar por Origem"
                  name="origem"
                  id="origem"
                  value={origem}
                  onChange={e => setOrigem(e.target.value)}
                  style={{
                    width: '300px',
                    background: tableType
                      ? theme.palette.system.overlay
                      : theme.palette.brand.primary.background,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          component={searchIcon}
                          color={
                            theme.palette.semantics.feedback.unknown.natural
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </form>

              <SyncAltIcon
                style={{
                  margin: '30px 10px 0px 10px',
                  cursor: 'pointer',
                }}
                onClick={toggleItems}
              />

              <form id="searchForm" action="" onSubmit={handleSubmitSearch}>
                <Input
                  placeholder="Buscar por Destino"
                  name="destino"
                  id="destino"
                  value={destino}
                  onChange={e => setDestino(e.target.value)}
                  style={{
                    width: '300px',
                    background: tableType
                      ? theme.palette.system.overlay
                      : theme.palette.brand.primary.background,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          component={searchIcon}
                          color={
                            theme.palette.semantics.feedback.unknown.natural
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </S.DoubleInput>
          )}

          {bulkOptions &&
            selectedFlatRows.length >= 1 &&
            renderBulkMenu(bulkOptions)}
        </Grid>
        {/* {!loading && renderTable()} */}
        {renderTable()}
      </S.Wrapper>
      {renderAcao()}
    </Grid>
  );
};

export default Table;
