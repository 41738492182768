/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';
import icone_table from 'images/icons/capacitacao/certificados/Trilhas_Icon_On.svg';

// React
import { Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

// Components
import TableLocal from 'components/TableLocal';
import ResponseModal from '../../components/ModalResponse';
import { DefaultTable } from 'components/_Table/templates/default';

// Components MUI
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

// Services
import * as services from '../services';
import { columns } from './columns';

let timerRoute = null;
const charge = [0, 1, 2, 3, 4, 5];

const Reports = () => {
  const [cards, setCards] = useState(charge);
  const [reports, setReports] = useState([]);
  const [idUser, setIdUser] = useState(null);
  const [tipoUser, setTipoUser] = useState(null);
  const [currentContent, setCurrentContent] = useState(null);
  const [openPreviewFull, setOpenPreviewFull] = useState(false);

  // --------------------------------------------REQUEST--------------------------------------------
  const { isFetching: loadingContent } = useQuery(
    ['reports-profile', 1],
    () => services.fetchAllReports(),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setCards(data.cards);
        setReports(data.data);
        setIdUser(data.aluno.id);
        setTipoUser(data.aluno.tipo);
      },
      onError: data => toast.error(data?.message),
    },
  );

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    timerRoute = setTimeout(() => {
      if (!openPreviewFull) {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }
    }, 100);

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [openPreviewFull]);

  // --------------------------------------------TABLE FORMAT--------------------------------------------
  const sortBy = [
    {
      id: 'id',
      desc: true,
    },
  ];

  const formatTable = () => {
    const col = columns.map(i => {
      if (i.id === 'conclusao') {
        return {
          header: 'Conclusão',
          align: 'center',
          sort: true,
          children: (value, item) => (
            <S.ColDriver>
              <S.Paragraph fontweight="bold" color="#494f65">
                {item.conclusao &&
                  new Date(item.conclusao).toLocaleDateString()}
                {!item.conclusao && '-'}
              </S.Paragraph>
            </S.ColDriver>
          ),
        };
      }
      if (i.id === 'prazo') {
        return {
          header: 'Prazo',
          align: 'center',
          sort: true,
          children: (value, item) => (
            <S.ColDriver>
              <S.Paragraph fontweight="bold" color="#494f65">
                {item?.data_fim && new Date(item.data_fim).toLocaleDateString()}
                {!item.data_fim && '-'}
              </S.Paragraph>
            </S.ColDriver>
          ),
        };
      }
      if (i.id === 'status') {
        return {
          header: 'Status',
          align: 'center',
          sort: true,
          children: (value, item) => {
            let colorText = '';
            let colorBg = '';
            const op = item.status
              ? item.status.replaceAll(/\s/g, '').toLowerCase()
              : '';
            switch (op) {
              case 'emandamento':
                colorText = colors.orangeAlert;
                colorBg = colors.orangeLighter;
                break;
              case 'finalizado':
                colorText = colors.grey;
                colorBg = colors.greyBackgroud;
                break;
              case 'reprovado':
                colorText = colors.redDanger;
                colorBg = colors.redBackground;
                break;
              case 'aprovado':
                colorText = colors.greenSucces;
                colorBg = colors.greenBackground;
                break;
            }
            return (
              <S.ColDriver>
                <S.Paragraph className="status" color={colorText} bg={colorBg}>
                  {item.status}
                </S.Paragraph>
              </S.ColDriver>
            );
          },
        };
      }
      return i;
    });
    return col;
    // arr.forEach(i => {
    //   if (i.id === 'titulo') {
    //     i.Cell = function ({ row }) {
    //       return (
    //         <div
    //           style={{
    //             display: 'flex',
    //             flexDirection: 'row',
    //             justifyContent: 'flex-start',
    //           }}
    //         >
    //           <img
    //             src={icone_table}
    //             alt="icone table"
    //             style={{ marginRight: 10 }}
    //           />
    //           <S.Paragraph fontweight="bold" color="#494f65">
    //             {row.original.titulo}
    //           </S.Paragraph>
    //         </div>
    //       );
    //     };
    //   } else if (i.id === 'id') {
    //     i.Cell = function ({ row }) {
    //       return (
    //         <S.ColDriver>
    //           <S.Paragraph fontweight="bold" color="#494f65">
    //             {row.original.conteudo_id}
    //           </S.Paragraph>
    //         </S.ColDriver>
    //       );
    //     };
    //   } else if (i.id === 'conclusao') {
    //     i.Cell = function ({ row }) {
    //       return (

    //       );
    //     };
    //   } else if (i.id === 'avaliacoes') {
    //     i.Cell = function ({ row }) {
    //       return (
    //         <S.ColDriver>
    //           <S.Paragraph fontweight="bold" color="#494f65">
    //             {row.original.avaliacao || '-'}
    //           </S.Paragraph>
    //         </S.ColDriver>
    //       );
    //     };
    //   } else if (i.id === 'tentativas') {
    //     i.Cell = function ({ row }) {
    //       return (
    //         <S.ColDriver>
    //           <S.Paragraph fontweight="bold" color="#494f65">
    //             {row.original.tentativas || '-'}
    //           </S.Paragraph>
    //         </S.ColDriver>
    //       );
    //     };
    //   } else if (i.id === 'prazo') {
    //     i.Cell = function ({ row }) {
    //       return (
    //         <S.ColDriver>
    //           <S.Paragraph fontweight="bold" color="#494f65">
    //             {row?.original?.data_fim &&
    //               new Date(row.original.data_fim).toLocaleDateString()}
    //             {!row?.original?.data_fim && '-'}
    //           </S.Paragraph>
    //         </S.ColDriver>
    //       );
    //     };
    //   } else if (i.id === 'status') {
    //     i.Cell = function ({ row }) {
    //       let colorText = '';
    //       let colorBg = '';
    //       const op = row.original.status.replaceAll(/\s/g, '').toLowerCase();
    //       switch (op) {
    //         case 'emandamento':
    //           colorText = colors.orangeAlert;
    //           colorBg = colors.orangeLighter;
    //           break;
    //         case 'finalizado':
    //           colorText = colors.grey;
    //           colorBg = colors.greyBackgroud;
    //           break;
    //         case 'reprovado':
    //           colorText = colors.redDanger;
    //           colorBg = colors.redBackground;
    //           break;
    //         case 'aprovado':
    //           colorText = colors.greenSucces;
    //           colorBg = colors.greenBackground;
    //           break;
    //       }

    //       return (

    //       );
    //     };
    //   }
    // });
    // return arr;
  };

  // --------------------------------------------PREVIEW--------------------------------------------
  const handlePerformTest = async id => {
    const data = reports.find(temp => +temp.id === +id);
    const obj = {
      alunoId: idUser,
      tipo: tipoUser,
      percentual: data.percentual,
    };
    const res = await services.showContent(data.id, obj);
    const content = res.data.content || [];
    setCurrentContent(content);
    setOpenPreviewFull(true);
  };
  const actions = [
    {
      title: 'ver conteúdo',
      function: val => {
        handlePerformTest(val);
      },
    },
  ];
  // --------------------------------------------RENDER--------------------------------------------
  return (
    <S.Container>
      <S.Header>
        <h1>Reports</h1>
      </S.Header>

      <S.BoxInfos>
        <>
          <S.CircleBar isLoading={loadingContent}>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-flex',
                transform: 'scale(2)',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress
                variant="determinate"
                value={100}
                sx={{
                  color: colors.greyTiny,
                }}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={+cards[0]?.value || 0}
                />
              </Box>
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SchoolOutlinedIcon
                  htmlColor={
                    !+cards[0]?.value ? colors.greyTiny : colors.greenMain
                  }
                  style={{ transform: 'scale(0.7)' }}
                />
              </Box>
            </Box>
            <div className="textFields">
              <div className="text">
                <div className="val">{cards[0]?.text}</div>
                <span className="loading" />
              </div>
              <div className="value">
                <div className="val">{+cards[0]?.value || 0}%</div>
                <span className="loading" />
              </div>
            </div>
          </S.CircleBar>
          {cards.map((card, k) => {
            if (k && k < 3) {
              return (
                <Fragment key={k}>
                  <div className="bar" />
                  <S.Card isLoading={loadingContent}>
                    <div className="text">
                      <div className="val">{card?.text}</div>
                      <span className="loading" />
                    </div>
                    <div className="value">
                      <div className="val">{+card?.value || 0}</div>
                      <span className="loading" />
                    </div>
                  </S.Card>
                </Fragment>
              );
            }
          })}
        </>
      </S.BoxInfos>
      <S.BoxInfos style={{ marginTop: '-6px' }}>
        {cards.map((card, k) => {
          if (k > 2) {
            return (
              <Fragment key={k}>
                {k > 3 && <div className="bar" />}
                <S.Card isLoading={loadingContent}>
                  <div className="text">
                    <div className="val">{card?.text}</div>
                    <span className="loading" />
                  </div>
                  <div className="value">
                    <div className="val">{+card?.value || 0}</div>
                    <span className="loading" />
                  </div>
                </S.Card>
              </Fragment>
            );
          }
        })}
      </S.BoxInfos>

      {/* <TableLocal
        columns={formatTable(columns)}
        data={reports}
        sortBy={sortBy}
        loading={loadingContent}
        permitIsSortedOccur
        tableType
        onClickRow={val => handlePerformTest(val?.id)}
        actions={[
          {
            name: 'ver conteúdo',
            action: val => {
              handlePerformTest(val);
            },
            text: true ? 'Ver conteúdo' : 'fazer conteúdo',
          },
        ]}
        empty={{
          image: '',
          title: 'Seus reports aparecerão aqui!',
          subtitle: 'Acompanhe seus reports e veja seu progresso.',
        }}
        search={{
          inputLabel: 'Buscar',
          keys: ['titulo', 'status'],
        }}
      /> */}
      <DefaultTable
        data={reports || []}
        columns={formatTable(columns)}
        actions={actions}
        searchKeys={['titulo', 'status']}
        loading={loadingContent}
        placeholder="Buscar"
        searchEvent={search => trackEvent(user, 'Buscar', null, search)}
        empty={{
          title: 'Nenhum resultado encontrada',
          description: '',
        }}
      />
      {/* Modal Area */}
      {openPreviewFull && currentContent && (
        <ResponseModal
          open={openPreviewFull}
          onClose={() => {
            setCurrentContent(null);
            setOpenPreviewFull(false);
          }}
          data={currentContent}
          setCurentContent={setCurrentContent}
          preview
          backPath="/capacitacao/perfil?page=reports"
        />
      )}
    </S.Container>
  );
};

export default Reports;
