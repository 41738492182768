import colors from 'styles/colors';
import icon_certificado from 'images/icons/certificados/certificado.svg';

export const columns = [
  {
    header: 'Título',
    id: 'titulo',
    sort: true,
    type: 'string',
    width: 300,
    children: (value, item) => (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <img src={icon_certificado} alt="icone certificado" width={30} />
        <span
          style={{
            color: colors.primary,
            fontWeight: 'bold',
            fontSize: '16px',
            textTransform: 'capitalize',
          }}
        >
          {value}
        </span>
      </div>
    ),
  },
  {
    header: 'Autor',
    id: 'conteudo.responsavel.nome',
    sort: true,
    type: 'string',
  },
  {
    header: 'Data da conclusão',
    id: 'createdAt',
    sort: true,
    type: 'date',
  },
  {
    header: 'Coeficiente atingido',
    id: 'coeficiente',
    sort: true,
    align: 'center',
    type: 'number',
  },
  {
    header: 'Validade',
    id: 'validade_fim',
    sort: true,
    type: 'date',
    align: 'center',
    conditional: [
      {
        condition: value => {
          let date = new Date(value);
          let today = new Date();
          let check = Math.ceil((date - today) / (1000 * 60 * 60 * 24));
          if (check > 30) return true;
        },
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          textAlign: 'center',
        }),
      },

      {
        condition: value => {
          let date = new Date(value);
          let today = new Date();
          let check = Math.ceil((date - today) / (1000 * 60 * 60 * 24));
          if (check >= 0 && check <= 30) return true;
        },
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          textAlign: 'center',
        }),
      },

      {
        condition: value => {
          let date = new Date(value);
          let today = new Date();
          let check = Math.ceil((date - today) / (1000 * 60 * 60 * 24));
          if (check < 0) return true;
        },
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          textAlign: 'center',
        }),
      },
    ],
  },
];
