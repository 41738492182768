import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  color: #4b5166;
  font-size: 24px;
  font-weight: 900;

  @media (max-width: 1400px) {
    font-size: 16px;
  }
`;
