import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { SubdirectoryArrowRight } from '@mui/icons-material';
import { Logs } from 'components/Logs';
import GhostButton from 'components/Buttons/Ghost';
import * as S from '../../styled';
import { requestHistory } from '../services';

const LogTelemetriaDesvio = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLogs = async () => {
      const res = await requestHistory(id);
      if (res.data?.data)
        setLogs(
          res.data.data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          })),
        );
      setLoading(false);
    };
    getLogs();
  }, []);

  return (
    <S.Main>
      <S.SpacedRow>
        <div />
        <GhostButton
          startIcon={
            <SubdirectoryArrowRight sx={{ transform: 'rotate(180deg)' }} />
          }
          size="medium"
          onClick={() => navigate('/telemetria/configuracoes/acoes-suspensao')}
        >
          Voltar para configurações
        </GhostButton>
      </S.SpacedRow>

      <Logs logs={logs} loading={loading} />
    </S.Main>
  );
};

export default LogTelemetriaDesvio;
