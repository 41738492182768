export const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Tranportadora',
    value: 'filial.empresa.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'distribuidora.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Veículo',
    value: 'caminhao.placa',
    selected: true,
    default: true,
  },
  {
    label: 'Disponibilizado',
    value: 'data_revisao',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'revisao_da_operacao',
    selected: true,
    default: true,
  },
  {
    label: 'Data da Revisão',
    value: 'data_revisao_distribuidora',
    selected: true,
    default: true,
  },
  {
    label: 'Data da Viagem',
    value: 'data_avaliacao',
    selected: true,
    default: true,
  },
  {
    label: 'Enviado para Revisão',
    value: 'data_revisao',
    selected: true,
    default: true,
  },
  {
    label: 'Vencimento Revisão',
    value: 'data_revisao',
    selected: true,
    default: true,
  },
  {
    label: 'Responsável',
    value: 'revisor.nome',
    selected: true,
    default: true,
  },
];

export const resetFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};
