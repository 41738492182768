import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ColDriver = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    h1 {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.brand.primary.natural};
    }
  `}
`;

export const PTable = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.words.title.natural};
    font-size: '16px';
    font-weight: 600;
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Paragraph = styled.p`
  ${({ theme, ...props }) => css`
    font-size: ${props.fontsize ? props.fontsize : '16px'};
    color: ${props.color ? props.color : theme.palette.brand.primary.light};
    font-weight: ${props.fontweight ? props.fontweight : 400};
    min-width: ${props.minwidth};

    &.data {
      font-weight: bold;
      color: ${theme.palette.brand.primary.natural};
      text-align: center;
    }

    &.rounded {
      border-radius: 50px;
      padding: 0px 5px;
    }

    &.bold {
      font-weight: bold;
      color: ${theme.palette.brand.primary.natural};
    }
  `}
`;

export const Status = styled.div`
  color: ${props => props.color};
  text-transform: capitalize;
  background-color: ${props => props.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-weight: 600;
`;
