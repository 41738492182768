import React, { useState } from 'react';
import { Container, Grid } from '@mui/material';

import TextInput from 'components/Inputs/TextField';
import Button from 'components/Buttons/Default';

import { validateBr } from 'js-brasil';
import { toast } from 'react-toastify';
import logo from 'images/Grupo43.svg';
import bg from 'images/logobg.svg';
import demo from 'images/demo.png';
import logoEnterprise from 'images/logo.svg';
import { useTheme } from 'styled-components';
import { requestDemo } from './services';
import * as S from './styled';

const CadastroDemonstracao = () => {
  const theme = useTheme();
  const [inputs, setInputs] = useState({});

  const handleChange = async e => {
    const { value } = e.target;
    setInputs(values => ({ ...values, [e.target.name]: value }));
  };

  const validateInputs = () => {
    let validated = true;
    Object.keys(inputs).forEach(item => {
      if (item === 'email') {
        validated = validateBr.email(inputs[item]);
      }
    });
    return validated;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isValidated = validateInputs();
    if (isValidated) {
      const res = await requestDemo(inputs);
      if (res && res.success) {
        toast.success(
          'Obrigado! Estamos analisando sua solicitação de cadastro. Você receberá um e-mail assim que for aprovado, para verificar sua conta e logar.',
        );
      } else {
        toast.error(res.message || 'Erro ao cadastrar empresa.');
      }
    } else {
      toast.error('Digite um e-mail válido');
    }
  };

  const renderForm = () => {
    const texts = {
      title: 'Solicitar demonstração',
      subTitle: 'Digite suas credenciais abaixo:',
    };
    return (
      <Grid
        style={{
          padding: '100px',
        }}
        item
        sm={6}
      >
        <S.PageContainer>
          <div>
            <h1>{texts.title}</h1>
            <p>{texts.subTitle}</p>
          </div>
          <S.FormWrapper>
            <TextInput
              label="Nome"
              placeholder="Nome"
              value={inputs.nome || ''}
              onChange={handleChange}
              name="nome"
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <TextInput
              label="Email"
              placeholder="E-mail"
              value={inputs.email || ''}
              onChange={handleChange}
              name="email"
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <TextInput
              label="Empresa"
              placeholder="Empresa"
              value={inputs.empresa || ''}
              onChange={handleChange}
              name="empresa"
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <TextInput
              label="Telefone"
              placeholder="(DDD) X-XXXX-XXXX"
              value={inputs.telefone || ''}
              onChange={handleChange}
              name="telefone"
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <TextInput
              label="O que você transporta"
              placeholder="Ex: grãos, materias..."
              value={inputs.carga || ''}
              onChange={handleChange}
              name="carga"
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <Button
              onClick={handleSubmit}
              size="large"
              width="100%"
              sx={{
                marginTop: '35px',
              }}
              children="SOLICITAR DEMONSTRAÇÃO"
            />
          </S.FormWrapper>
          <p style={{ marginTop: '20px' }}>
            Já tem uma conta?{' '}
            <span>
              <a
                style={{
                  color: theme.palette.brand.secondary.dark,
                  textDecoration: 'underline',
                }}
                href="/login"
              >
                Faça o login
              </a>
            </span>
          </p>
        </S.PageContainer>
      </Grid>
    );
  };

  const renderImage = () => {
    return (
      <Grid item sm={6}>
        <div
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <img width="30%" src={logoEnterprise} />
          <div
            style={{
              overflow: 'hidden',
            }}
          >
            <img
              style={{
                alignSelf: 'flex-end',
                marginLeft: '25%',
                overflow: 'hidden',
              }}
              width="95%"
              src={demo}
            />
          </div>
          <p
            style={{
              font: 'normal normal 24px/31px Texta',
              color: theme.palette.words.text.contrast,
              textAlign: 'center',
              marginTop: '30px',
            }}
          >
            Gerencie o{' '}
            <span style={{ color: theme.palette.brand.secondary.natural }}>
              desempenho{' '}
            </span>
            de seus motoristas
          </p>
        </div>
      </Grid>
    );
  };

  return (
    <Grid container>
      <S.PageWrapper>
        <img height={100} style={{ padding: '50px 50px 0 50px' }} src={logo} />
        {renderForm()}
        {renderImage()}
      </S.PageWrapper>
    </Grid>
  );
};

export default CadastroDemonstracao;
