// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// Coponents
import Tabs from 'components/Tabs';
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';
import { DefaultTable } from 'components/_Table/templates/default';

// Components Material UI
import { AddCircleOutline } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EditOutlined, DeleteOutline } from '@mui/icons-material';
import { Divider, Grid, MenuItem, Popover } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ModalAddMotorista from './ModalAddMotorista';
import * as C from '../styled';
import * as S from './styled';

// Utils
import { columns } from './columns';
import { sortBy, tabs } from './constants';
import * as services from './services';
import { trackEvent } from 'utils/mixpanel';
import { useSelector } from 'react-redux';

export const Motoristas = () => {
  const user = useSelector(state => state.auth?.user?.user);

  const theme = useTheme();
  // GENERAL STATES
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [dataEdit, setDataEdit] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  // ---------------------------TABLE FORMATED--------------------------------//

  const handlePageTab = (event, newValue) => {
    setPageTab(newValue);
  };

  // ---------------------------ACTIONS COLUNM--------------------------------//
  const [idAtivar, setIdAtivar] = useState(null);
  const [idDesativar, setIdDesativar] = useState(null);
  const [modalText, setModalText] = useState('');
  const [companiesList, setCompaniesList] = useState([]);
  const [pageTab, setPageTab] = useState(0);

  const handleEditar = id => {
    let itemEdit;
    if (pageTab == 0) {
      itemEdit = ativos.find(ativo => String(ativo.id) === String(id));
    } else {
      itemEdit = inativos.find(inativo => String(inativo.id) === String(id));
    }

    if (itemEdit) {
      setDataEdit(itemEdit);
      setOpenModal(true);
    }
  };

  const handleSetDesativar = id => {
    let ativo = null;
    for (const i in ativos) {
      if (ativos[i].id === Number(id)) {
        ativo = ativos[i];
        setIdDesativar(ativos[i]);
      }
    }

    setModalText(`Tem certeza que deseja desativar?`);
    setCompaniesList(formatText('desativo', ativo));
  };

  const handleSetAtivar = id => {
    let inativo = null;
    for (const i in inativos) {
      if (inativos[i].id === Number(id)) {
        inativo = inativos[i];
        setIdAtivar(inativos[i]);
      }
    }

    setModalText(`Tem em certeza que deseja ativar?`);
    setCompaniesList(formatText('ativado', inativo));
  };

  const formatText = (text, ativar) => {
    return (
      <>
        <span>
          O seguinte cliente sera
          <span style={{ fontWeight: '900' }}> {text.toUpperCase()}</span>:
        </span>
        <ul style={{ listStyle: 'disc', marginLeft: '15px' }}>
          <li>{ativar?.nome || ''}</li>
        </ul>
      </>
    );
  };

  // ações por linha
  const handleAtivarInativar = id => {
    if (pageTab == 0) {
      handleSetDesativar(id);
    } else {
      handleSetAtivar(id);
    }
  };

  const actions = [
    {
      title: 'Ativar/Desativar',
      function: handleAtivarInativar,
    },
    {
      title: 'Editar',
      function: handleEditar,
    },
  ];

  //     const id = Number(selectedRow);

  //     if (pageTab == 0) {
  //       if (selectedData.length > 1) {
  //         setActions([
  //           {
  //             name: 'desativar',
  //             text: 'Desativar',
  //             action: handleSetDesativar,
  //           },
  //         ]);
  //       } else {
  //         setActions([
  //           { name: 'editar', text: 'Editar', action: handleEditar },
  //           {
  //             name: 'desativar',
  //             text: 'Desativar',
  //             action: handleSetDesativar,
  //           },
  //         ]);
  //       }
  //     }

  //     if (pageTab == 1) {
  //       if (selectedData.length > 1) {
  //         setActions([
  //           { name: 'ativar', text: 'Ativar', action: handleSetAtivar },
  //         ]);
  //       } else {
  //         setActions([
  //           { name: 'editar', text: 'Editar', action: handleEditar },
  //           { name: 'ativar', text: 'Ativar', action: handleSetAtivar },
  //         ]);
  //       }
  //     }
  //   }
  // }, [selectedRow, selectedData]);

  // -------------------------REQUESTS--------------------------//
  const fetchData = async () => {
    setLoadingTable(true);
    const res = await services.requestDrivers();
    if (res.success) {
      const data = res.data;
      const actives = [];
      const disabled = [];
      for (const i in data) {
        if (data[i].status === 'ATIVO') {
          actives.push(data[i]);
        } else if (data[i].status === 'INATIVO') {
          disabled.push(data[i]);
        }
      }

      setAtivos(actives);
      setInativos(disabled);
    } else {
      toast.error(res.message);
    }
    setLoadingTable(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const confirmActive = async () => {
    setLoadingTable(true);

    const res = await services.changeDriverStatus(idAtivar.id);

    if (res.data.success) {
      toast.success(res.data.message);
      setIdAtivar(null);
      fetchData();
    } else {
      toast.error(res.data.message);
    }
    setLoadingTable(false);
  };

  const confirmDisable = async () => {
    setLoadingTable(true);

    const res = await services.changeDriverStatus(idDesativar.id);

    if (res.data.success) {
      toast.success(res.data.message);
      setIdDesativar(null);
      fetchData();
    } else {
      toast.error(res.data.message);
      setIdDesativar(null);
    }
    setLoadingTable(false);
  };

  // MODAL FOR ADD AND EDIT DRIVER
  const renderModal = () => {
    return (
      openModal && (
        <ModalAddMotorista
          open={openModal}
          handleClose={() => {
            setOpenModal(false);
            setDataEdit(0);
            fetchData();
          }}
          dataEdit={dataEdit}
        />
      )
    );
  };
  return (
    <S.Container>
      <>
        <C.TitleArea className="space">
          <span className="textArea">Motoristas</span>
          <DefaultButton
            startIcon={<AddCircleOutline />}
            size="medium"
            sx={{ marginLeft: '10px' }}
            onClick={() => setOpenModal(true)}
          >
            ADICIONAR MOTORISTA
          </DefaultButton>
        </C.TitleArea>
        <Grid marginBottom={1} item md={12}>
          <Tabs value={pageTab} items={tabs} onChange={handlePageTab} />
          <Divider />
        </Grid>
        <Grid item md={12}>
          {pageTab === 0 && (
            <DefaultTable
              data={ativos || []}
              columns={columns}
              actions={actions}
              searchKeys={['nome']}
              loading={loadingTable}
              placeholder="Buscar por nome"
              sortBy={{ id: 'ativo', order: 'ASC' }}
              searchEvent={search =>
                trackEvent(user, 'Buscar Gestão de Motoristas', null, search)
              }
              empty={{
                title: 'Nenhum cliente encontrado',
                description: 'Você ainda não cadastrou nenhum cliente.',
                image: 'frota.png',
              }}
            />
          )}
          {pageTab === 1 && (
            <DefaultTable
              data={inativos || []}
              columns={columns}
              actions={actions}
              searchKeys={['nome']}
              loading={loadingTable}
              placeholder="Buscar por nome"
              sortBy={{ id: 'ativo', order: 'ASC' }}
              searchEvent={search =>
                trackEvent(user, 'Busca Gestão de Motoristas', null, search)
              }
              empty={{
                title: 'Nenhum cliente encontrado',
                description: 'Você ainda não cadastrou nenhum cliente.',
                image: 'frota.png',
              }}
            />
          )}
        </Grid>

        {
          /* Confirmação de ativacao */
          idAtivar && (
            <ConfirmModal
              open={!!idAtivar}
              handleClose={() => setIdAtivar(null)}
              title={modalText}
              titleIcon={
                <EditOutlined
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
              }
              subtitle={companiesList}
              buttonText="Confirmar"
              onClick={() => confirmActive()}
              loading={false}
            />
          )
        }

        {
          /* Confirmação de desativacao */
          idDesativar && (
            <ConfirmModal
              open={!!idDesativar}
              handleClose={() => setIdDesativar(null)}
              title={modalText}
              titleIcon={
                <DeleteOutline
                  sx={{
                    color: theme.palette.semantics.feedback.attention.natural,
                  }}
                  fontSize="medium"
                />
              }
              subtitle={companiesList}
              buttonText="Confirmar"
              onClick={() => confirmDisable()}
              loading={false}
            />
          )
        }
        {renderModal()}
      </>
    </S.Container>
  );
};
