/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { useEffect, useState } from 'react';

// Components
import DefaultButton from 'components/Buttons/Default';
import GhostButton from 'components/Buttons/Ghost';
import { EvaluationResponse as Card } from '../../EvaluationResponse';

// Material UI
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { toast } from 'react-toastify';
import { FinishModal } from './Finish';
import { FeedbackModal } from './Feedback';
import * as services from '../services';

export const EvaluationView = ({
  is_quiz,
  conteudo,
  setBlocked,
  unlockNext,
  questions = [],
  setQuestions,
  currentQuestion,
  setCurrentQuestion,
  setCurrentStatus,
  setCurentContent,
  falseBack,
  backModal,
  isFinished,
}) => {
  const [finishedModal, setFinishedModal] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbackInline, setFeedbackInline] = useState(false);
  const [statusUser, setStatusUser] = useState(null);
  const [coloBox, setColoBox] = useState(colors.greyTiny);
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    if (currentQuestion < questions?.length - 1) {
      handleTransition();
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrev = () => {
    if (currentQuestion > 0) {
      handleTransition();
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const [checkedId, setCheckedId] = useState(
    questions?.length ? questions[0]?.resposta?.id_alternativa : null,
  );

  useEffect(() => {
    setCheckedId(questions[currentQuestion]?.resposta?.id_alternativa);
  }, [currentQuestion]);

  const handleRequest = async () => {
    if (!checkedId) {
      toast.warn('Selecione uma resposta para prosseguir!');
      setColoBox(colors.orangeAlert);
      return;
    }

    let currentAlt = questions[currentQuestion]?.alternativas?.find(
      alt => alt.id === checkedId,
    );

    let noNext = false;
    if (
      !currentAlt?.correta &&
      questions[currentQuestion]?.feedback &&
      questions[currentQuestion]?.feedback !== ''
    ) {
      noNext = true;
      setFeedbackModal(true);
    }

    let newQuestions = questions.map(item => {
      if (item?.id === questions[currentQuestion]?.id) {
        return {
          ...item,
          resposta: {
            id_questao: item?.id,
            id_alternativa: checkedId,
            correta: currentAlt?.correta,
          },
        };
      }
      return item;
    });

    setQuestions(newQuestions);

    const totalQuestions = questions?.length;
    const totalResp = newQuestions?.filter(
      item => item?.resposta?.id_alternativa,
    ).length;

    setLoading(true);
    !noNext && handleNext();

    let res = await services.responseQuestion({
      id: conteudo?.id,
      respostas: [
        {
          perguntaId: questions[currentQuestion]?.id,
          alternativaId: checkedId,
        },
      ],
    });

    if (!res.success) {
      toast.error('Erro ao enviar resposta!');
      backModal();
      return;
    }

    setLoading(false);

    if (totalQuestions === totalResp) {
      setBlocked(false);
      unlockNext(true);

      const isQuiz = questions[0]?.is_quiz;
      if (!isQuiz) {
        setStatusUser(res?.data?.status);
        setCurrentStatus(
          res?.data?.status !== 'APROVADO' ? 'REPROVADO' : 'APROVADO',
        );
        setFinishedModal(true);
      } else {
        setCurrentStatus('APROVADO');
      }
    }
  };

  // ---------------------------TRANSITION QUESTIONS---------------------------//
  const [margin, setMargin] = useState(0);
  const [opacity, setOpacity] = useState(1);

  const handleTransition = () => {
    setMargin(-200);
    setOpacity(0);
    setTimeout(() => {
      setMargin(0);
    }, 400);
    setTimeout(() => {
      setOpacity(1);
    }, 500);
  };

  return (
    <S.Container coloBox={coloBox}>
      {questions && (
        <>
          <S.Title>
            {is_quiz ? (
              <OfflineBoltOutlinedIcon htmlColor={colors.greenMain} />
            ) : (
              <CheckCircleOutlineOutlinedIcon htmlColor={colors.greenMain} />
            )}
            <div className="type">
              {is_quiz ? 'Quiz' : 'Teste Online'} #
              {(currentQuestion + 1).toString().padStart(2, '0')}
            </div>
          </S.Title>
          <Card
            item={questions[currentQuestion]}
            noBorder
            checkedId={checkedId}
            setCheckedId={setCheckedId}
            margin={margin}
            opacity={opacity}
            setColoBox={setColoBox}
            isFinished={isFinished}
          />

          <S.Footer>
            {questions[currentQuestion]?.resposta?.id_alternativa &&
              !questions[currentQuestion]?.resposta?.correta &&
              questions[currentQuestion]?.feedback && (
                <div
                  className="seeFeedback"
                  onClick={() => setFeedbackInline(true)}
                >
                  {' '}
                  Ver Feedback
                </div>
              )}

            {!!currentQuestion && (
              <GhostButton
                children="Anterior"
                onClick={() => handlePrev()}
                disabled={currentQuestion === 0}
              />
            )}
            {currentQuestion !== questions?.length - 1 &&
              questions[currentQuestion]?.resposta?.id_alternativa && (
                <DefaultButton
                  children="Avançar"
                  onClick={() => handleNext()}
                  disabled={currentQuestion === questions?.length - 1}
                />
              )}

            {!questions[currentQuestion]?.resposta?.id_alternativa &&
              !isFinished && (
                <DefaultButton
                  children="Enviar resposta"
                  onClick={!isFinished ? () => handleRequest() : undefined}
                  loading={loading}
                  disabled={isFinished}
                />
              )}
          </S.Footer>

          <S.Counter>
            {currentQuestion + 1}/{questions.length}
          </S.Counter>
        </>
      )}

      {!feedbackModal && statusUser && finishedModal && (
        <FinishModal
          open={finishedModal}
          handleClose={() => setFinishedModal(false)}
          status={statusUser}
          conteudo={conteudo}
          setCurentContent={setCurentContent}
          setCurrentStatus={setCurrentStatus}
          falseBack={falseBack}
        />
      )}

      <FeedbackModal
        open={feedbackModal}
        handleClose={() => setFeedbackModal(false)}
        feedback={
          questions[currentQuestion] && questions[currentQuestion]?.feedback
        }
        handleNext={() => handleNext()}
      />

      <FeedbackModal
        open={feedbackInline}
        handleClose={() => setFeedbackInline(false)}
        feedback={
          questions[currentQuestion] && questions[currentQuestion]?.feedback
        }
        handleNext={() => {}}
      />
    </S.Container>
  );
};
