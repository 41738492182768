import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { PlayCircle, Close, OpenInFull } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import ImageModal from 'components/ImageModal';
import { firestorageVideos } from 'utils/firebase';
import * as S from './styled';

const ImageDesvio = ({ imagem, video, onlyImage, frame_pos }) => {
  const theme = useTheme();
  const [videoUrl, setVideoUrl] = useState('');
  const [videoActive, setVideoActive] = useState(false);
  const [expandedImage, setExpandedImage] = useState(false);

  useEffect(() => {
    // Monta o link do vídeo no momento do desvio
    // Se o período não for encontrado, o vídeo não será exibido!
    const getVideoData = () => {
      // Obs: homolog não tem vídeos. valor mocado para REACT_APP_ENV = 'development'
      const caminho =
        process.env.REACT_APP_ENV !== 'production'
          ? 'Transpedrosa/Moveit Transpedrosa/10207#ADRIANO DOUGLAS LOPES/2022-39/10207#ADRIANO DOUGLAS LOPES_2022-39.mp4'
          : video || '';
      if (caminho) {
        const caminhoIsAbsolute = caminho.includes('http');
        caminhoIsAbsolute
          ? firestorageVideos
              .refFromURL(caminho)
              .getDownloadURL()
              .then(fireBaseUrl => {
                if (fireBaseUrl) {
                  let video_url = fireBaseUrl;
                  if (frame_pos) {
                    video_url = `${fireBaseUrl}#t=${frame_pos - 5},${
                      frame_pos + 20
                    }`;
                  }
                  setVideoUrl(video_url);
                }
              })
              .catch(err => console.log(err))
          : firestorageVideos
              .ref(caminho)
              .getDownloadURL()
              .then(fireBaseUrl => {
                if (fireBaseUrl) {
                  let video_url = fireBaseUrl;
                  if (frame_pos) {
                    video_url = `${fireBaseUrl}#t=${frame_pos - 5},${
                      frame_pos + 20
                    }`;
                  }
                  setVideoUrl(video_url);
                }
              })
              .catch(err => console.log(err));
      }
    };

    getVideoData();
  }, [video]);

  return (
    <>
      {imagem && (!videoActive || onlyImage) && (
        <S.ImageContainer>
          <img src={imagem} alt="imagem_desvio" />

          {video && videoUrl && (
            <div className="playVideo" onClick={() => setVideoActive(true)}>
              <PlayCircle
                sx={{ color: theme.palette.brand.primary.light, fontSize: 50 }}
              />
            </div>
          )}
          {imagem && (
            <div className="expandImage" onClick={() => setExpandedImage(true)}>
              <OpenInFull
                sx={{
                  color: theme.palette.brand.secondary.natural,
                  fontSize: 40,
                }}
              />
            </div>
          )}
        </S.ImageContainer>
      )}
      {(!imagem || videoActive) && videoUrl && !onlyImage && (
        <S.VideoContainer>
          <ReactPlayer
            url={videoUrl}
            controls
            playing
            width="100%"
            height="100%"
          />
          {imagem && (
            <span onClick={() => setVideoActive(false)}>
              <Close
                sx={{
                  color: theme.palette.brand.secondary.natural,
                  fontSize: 40,
                }}
              />
            </span>
          )}
        </S.VideoContainer>
      )}

      <ImageModal
        open={expandedImage}
        handleClose={() => setExpandedImage(false)}
        image={imagem}
      />
    </>
  );
};

export default ImageDesvio;
