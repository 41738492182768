import api from 'services/api';

export const desactivateConfig = async id => {
  const res = await api.put(`/desvios/configuracoes/${id}/desativar`);
  return res.data;
};

export const requestConfigurations = async query => {
  const res = await api.get('/desvios/configuracoes', { params: query });
  return res;
};

export const requestConfiguration = async id => {
  const { data } = await api.get(`/desvios/configuracoes/${id}`);
  return data?.data;
};

export const saveConfiguration = async data => {
  const res = data.id
    ? await api.put(`/desvios/configuracoes/${data.id}`, data)
    : await api.post('/desvios/configuracoes', data);
  return res.data;
};

export const requestExcel = async (query, isProvider) => {
  const res = await api.get(
    `/excel${isProvider ? '-provider' : ''}/desvios_tipos`,
    { params: query },
  );
  return res;
};

export const getTiposPadrao = async grupo => {
  const { data } = await api.get(
    `/desvios/configuracoes/padroes-auto/${grupo}`,
  );
  return data.data;
};

export const getTiposVeiculos = async () => {
  const { data } = await api.get('/telematics_tipos_veiculos');
  return data.data;
};

export const getTiposDesviosManuais = async () => {
  const { data } = await api.get('/desvios/configuracoes/padroes-manuais');
  return data.data;
};

export const createTipoDesvioManual = async titulo => {
  const res = await api.post(`/desvios/configuracoes/padroes-manuais`, {
    titulo,
  });
  return res.data;
};

export const desactivateTipoDesvioManual = async id => {
  const res = await api.put(
    `/desvios/configuracoes/padroes-manuais/${id}/desativar`,
  );
  return { ...res.data, status: res.status };
};
