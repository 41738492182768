import styled, { css } from 'styled-components';

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.div`
  padding: 4px 10px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  background-color: ${props => `${props.color}30`};

  p {
    color: ${props => props.color};
    font: normal normal bold 18px/18px Texta;
  }
`;

const SpacedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ColumnWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.system.overlay};
    border-radius: 4px;
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 25px;
  `}
`;

const ImageContainer = styled.div`
  width: 100%;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  position: relative;

  :hover {
    width: 99%;
    opacity: 0.9;
    transition-duration: 300ms;
  }

  img {
    width: 100%;
    border-radius: 5px;
  }

  .playVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
    cursor: pointer;

    :hover {
      opacity: 1;
      transition-duration: 300ms;
    }
  }

  .expandImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.1;
    cursor: pointer;

    :hover {
      opacity: 1;
      transition-duration: 300ms;
    }
  }
`;

const VideoContainer = styled.div`
  display: block;
  width: 100%;
  position: relative;
  border-radius: 10px;

  video {
    border-radius: 10px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.1;
    cursor: pointer;

    :hover {
      opacity: 1;
      transition-duration: 300ms;
    }
  }
`;

const StyledLogoWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid ${theme.palette.brand.secondary.natural};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const StyledLogo = styled.div`
  ${({ theme, ...props }) => css`
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: ${props.border};
    background-image: ${props.backgroundImage};
    background-color: ${theme.palette.system.border};
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 20px;
      color: ${theme.palette.brand.primary.natural};
      text-align: center;
    }
  `}
`;

const StyledTextWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 16px;
      color: ${theme.palette.words.title.natural};
      font-weight: 600;
      text-transform: capitalize;
      text-align: left;
    }
    p {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }
  `}
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Card = styled.div`
  ${({ theme, margin }) => css`
    margin-top: 15px;
    margin-bottom: 20px;
    border: 1px solid ${theme.palette.words.text.light};

    ${margin &&
    `
    padding-top: 150px;
    `}
  `}
`;

export const CardHeader = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.brand.primary.natural};
    padding: 5px 10px;

    h1 {
      color: ${theme.palette.words.title.contrast};
      font-size: 16px;
    }
  `}
`;

export const CardContent = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.semantics.feedback.unknown.light};
    padding: 5px 10px;

    img {
      width: 100%;
    }
  `}
`;

export const Assinatura = styled.div`
  ${({ theme }) => css`
    hr {
      margin: 20px 0;
    }

    h4 {
      color: ${theme.palette.words.text.light};
    }
  `}
`;

export const AsignTitle = styled.p`
  font-size: 16px;
  text-transform: capitalize;
`;

export const AsignDate = styled.p`
  font-size: 14px;
  text-transform: capitalize;
`;
export const NoContent = styled.p`
  height: 42px;
  font-size: 16px;
`;

export {
  Main,
  Status,
  SpacedRow,
  ImageContainer,
  VideoContainer,
  ColumnWrapper,
  StyledLogoWrapper,
  StyledLogo,
  StyledTextWrapper,
  IconContainer,
};
