// Styled

// React
import { useEffect, useState } from 'react';

// Components
import DataHour from 'components/Inputs/DataHour';

// Components MUI
import { Divider } from '@mui/material';
import { WithQuestion } from '..';
import * as S from './styled';

// Actions
import { changeText } from '../../actions';

export const ResponseDataHora = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosObrigatorios,
  preview,
  approveView,
}) => {
  const redirectFunction = (texto, idCampo) => {
    setCleanField(true);
    changeText(texto, idCampo, setSecoes, secoes, sectionId);
  };

  // ------------------------------------ERROS-------------------------------------------//
  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [cleanField, setCleanField] = useState(false);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setCleanField(false);
    if (approveView) return null;

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }
  }, [errosObrigatorios]);

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanField}
        className={`fieldClass-${field.id}`}
        preview={preview}
      >
        <WithQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanField}
          preview={preview}
          approveView={approveView}
        />
        <S.ResponseArea>
          <S.BlockInput preview={preview}>
            <DataHour
              value={field.resposta ? field.resposta : null}
              onChange={newValue => redirectFunction(newValue, field.id)}
              maxDate="infinite"
              error={hasErrorObrigatorio && !cleanField}
              disabled={approveView}
            />
          </S.BlockInput>
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
