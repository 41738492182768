import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  queue: [],
  concluded: 0,
};

export default function guiaQueue(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_QUEUE: {
        draft.queue = action.payload.queue;
        break;
      }
      case types.SET_CONCLUDED: {
        draft.concluded = action.payload.concluded;
        break;
      }
      default:
        return state;
    }
  });
}
