import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

// components
import { IconButton } from '@mui/material';
import Loading from 'components/Loading';
import InfoCard from 'components/Cards/InfoCard';
import { Default } from './Rows/Default';
import { ConfigTorre } from './Rows/ConfigTorre';
import { DefaultTorre } from './Rows/DefaultTorre';

// styles
import * as S from './styled';

// services
import { usePlans } from 'hooks/usePlans';
import * as services from './services';

// icons
import { ReactComponent as BtnAdd } from 'images/icons/configuracoes/icone-adicionar.svg';

export const EscalationList = () => {
  const navigate = useNavigate();
  const { isProvider, hasTorre, hasTorrePlus } = usePlans();

  const {
    data: getEscalationList,
    isLoading,
    refetch,
  } = useQuery(['acao_motorista'], () => services.getEscalation(), {
    refetchOnWindowFocus: false,
  });

  const handleNavigate = params => {
    navigate('/configuracoes/escalation-list/nova-list', {
      state: {
        item: params,
        desviosTiposPadroes: getEscalationList.desviosTiposPadroes,
        desviosTipos: getEscalationList.desviosTipos,
      },
    });
  };

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isProvider && (
            <InfoCard message="As empresas que prestam serviço para você receberão alertas relacionados aos seus deslocamentos de risco." />
          )}
          {getEscalationList?.rows?.map((item, index) => {
            if (hasTorre && !hasTorrePlus && item.is_default) {
              return (
                <DefaultTorre
                  key={item?.id ?? index}
                  item={item}
                  refetch={refetch}
                  desviosTipos={getEscalationList.desviosTipos}
                  desviosTiposPadroes={getEscalationList.desviosTiposPadroes}
                />
              );
            }
            if (hasTorre && !hasTorrePlus && !item.is_default) {
              return (
                <ConfigTorre
                  key={item?.id ?? index}
                  item={item}
                  refetch={refetch}
                  handleNavigate={handleNavigate}
                  desviosTipos={getEscalationList.desviosTipos}
                  desviosTiposPadroes={getEscalationList.desviosTiposPadroes}
                />
              );
            }
            return (
              <Default
                key={item?.id ?? index}
                item={item}
                refetch={refetch}
                handleNavigate={handleNavigate}
                desviosTipos={getEscalationList.desviosTipos}
                desviosTiposPadroes={getEscalationList.desviosTiposPadroes}
              />
            );
          })}
          <div className="container-actions">
            <IconButton onClick={() => handleNavigate()}>
              <BtnAdd />
            </IconButton>
            <div onClick={() => handleNavigate()} className="btn-add">
              Adicionar deslocamento perigoso
            </div>
          </div>
        </>
      )}
    </S.Container>
  );
};
