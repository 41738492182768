import { useQueries } from 'react-query';
import {
  getResponsaveis,
  getTiposDesvio,
  getCriticidade,
  getTecnologias,
  getClients,
  getVeiculos,
  getCargas,
} from 'constants/_SERVICES/user';
import {
  getDesviosTiposPadrao,
  getResponsaveis as getResponsaveisProvider,
} from 'constants/_SERVICES/provider';
import { usePlans } from 'hooks/usePlans';
import { useSelector } from 'react-redux';

export const useFetchMultipleWithCache = () => {
  const { isProvider } = usePlans();

  const { filials } = useSelector(state => {
    return state.selects;
  });

  const queries = useQueries([
    {
      queryKey: 'responsaveis',
      queryFn: isProvider ? getResponsaveisProvider : getResponsaveis,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'clients',
      queryFn: getClients,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'tecnologias',
      queryFn: getTecnologias,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'criticidade',
      queryFn: getCriticidade,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'tiposDesvios',
      queryFn: getTiposDesvio,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'veiculos',
      queryFn: getVeiculos,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'desviosTiposPadrao',
      queryFn: getDesviosTiposPadrao,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
    {
      queryKey: 'cargas',
      queryFn: getCargas,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  ]);

  return {
    responsaveis: queries[0],
    clients: queries[1],
    tecnologias: queries[2],
    criticidade: queries[3],
    tiposDesvios: queries[4],
    veiculos: queries[5],
    desviosTiposPadrao: queries[6],
    cargas: queries[7],
    filiais: filials.map(f => ({ value: f.id, label: f.nome })),
    motoristasTipos: [
      { label: 'Agregados', value: 'AGREGADOS' },
      { label: 'Próprios', value: 'PROPRIOS' },
      { label: 'Não Informado', value: '-1' },
    ],
    operacao: [
      { label: 'Primária', value: 1 },
      { label: 'Secundária', value: 2 },
      { label: 'Açucar', value: 3 },
    ],
    origin: [
      { label: 'Manual', value: 'MANUAL' },
      { label: 'Velocidade', value: 'VELOCIDADE' },
      { label: 'Cerca', value: 'CERCA' },
      { label: 'Telemetria', value: 'TELEMETRIA' },
      { label: 'Fadiga', value: 'FADIGA' },
      { label: 'Cabine', value: 'CABINE' },
      { label: 'Externa', value: 'EXTERNA' },
      { label: 'Jornada', value: 'JORNADA' },
    ],
  };
};
