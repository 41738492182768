import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  display: flex;
  gap: 30px;

  .image {
    .circle {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      border: 2px solid ${colors.greenMain};
      background-color: ${colors.greyBackgroud};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .letter {
      font-size: 20px;
      font-weight: 900;
      color: ${colors.greyTitle};
    }
  }

  .infos {
    .name {
      font-size: 20px;
      font-weight: bold;
      color: ${colors.greyTitle};
    }
    .company {
      font-size: 16px;
      font-weight: 600;
      color: ${colors.greySubtitle};
    }
  }
`;

export const Bottom = styled.div`
  margin-top: 30px;
`;

export const Grid = styled.div`
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const Item = styled.div``;
