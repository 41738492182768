import { Modal } from '@mui/material';
import styled from 'styled-components';
import colors from 'styles/colors';

export const ModalMUI = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 500px;
  height: 600px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;

  .left {
    display: flex;
    align-items: center;

    .MuiSvgIcon-root {
      color: ${colors.greenMain};
      margin-right: 10px;
    }
  }

  .MuiSvgIcon-root {
    cursor: pointer;
    color: ${colors.greySubtitle};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.greyBackgroud};
  height: calc(100% - 100px);
  overflow: auto;
  border-radius: 5px;
  padding: 15px 5px;
`;

export const LineSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${colors.white};
  border-radius: 5px;
  height: 50px;
  padding: 0px 15px;
  border: 1px solid ${colors.greyTiny};
  position: relative;

  p {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiSvgIcon-root {
      color: ${colors.greySubtitle};
    }
  }

  .title {
    font-weight: 600;
    color: ${colors.greyTitle};
    margin-left: 10px;
  }

  .sts {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;

    .ball {
      cursor: pointer;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
      background-color: ${props =>
        props.active ? colors.greenSucces : colors.redDanger};
    }

    .MuiSvgIcon-root {
      color: ${props => (props.visible ? colors.greySubtitle : colors.grey)};
      font-size: 18px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${colors.white};
  gap: 20px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-bottom: 20px;
  margin-right: 20px;
`;
