import { getStatusTarefas } from 'constants/_SERVICES/user';

// Pages
import MinhasTarefas from 'pages/MinhasTarefas';

export default {
  '/minhas-tarefas': {
    defaults: [],
    pageFilterName: 'filterMinhasTarefas',
    back: -1,
    title: 'Minhas Tarefas',
    id: true,
    filters: [
      {
        name: 'status',
        placeholder: 'Filtrar por Status',
        mode: 'multiple',
        data: async () => getStatusTarefas(),
      },
    ],
    page: <MinhasTarefas />,
  },
};
