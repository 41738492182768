import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { endOfMonth, startOfMonth, subHours, subMonths } from 'date-fns';

import { SvgIcon } from '@mui/material';
import Graph from 'components/Graphs/ComposedChart';
import { PeriodPicker } from '../../Components/PeriodPicker';
import { ReactComponent as filterIco } from 'images/icons/filter.svg';

import * as S from './styled';
import SelectInput from 'components/Inputs/Select';
import { requestDriversHistorico } from './services';
import { ptMonths } from 'utils/dates';
import { usePlans } from 'hooks/usePlans';
import Filters from './Filters';
import { useTheme } from 'styled-components';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { payload: currentPayload } = payload[0];
    console.log(currentPayload);
    console.log(payload);
    return (
      <S.TooltipContainer>
        <p>{`${currentPayload.name}`}</p>
        <p className="title">{`Quantidade: ${currentPayload.value}`}</p>
        {currentPayload.meta && (
          <>
            <p>{`Meta: ${currentPayload.meta} hrs`}</p>
            <p
              style={{ color: '#F64E60' }}
            >{`${currentPayload.percentual_meta}`}</p>
          </>
        )}
      </S.TooltipContainer>
    );
  }

  return null;
};

export const Historico = () => {
  const theme = useTheme();
  const { hasMonitoramento } = usePlans();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState({
    finalDate: new Date(),
    initialDate: startOfMonth(subMonths(new Date(), 11)),
    label: 'motoristas_avaliados',
    client: '',
    filial: '',
    empresas: '',
  });

  const { isFetching, data } = useQuery(
    ['motoristas-historico', filter],
    () =>
      filter &&
      requestDriversHistorico({
        ...filter,
        initialDate: subHours(filter.initialDate, 3),
        finalDate: subHours(filter.finalDate, 3),
        client: filter.client ? filter.client.split(',') : '',
        filial: filter.filial ? filter.filial.split(',') : '',
        empresas: filter.empresas ? filter.empresas.split(',') : '',
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const titleComponent = (
    <S.Header>
      <S.Title>Estatisticas</S.Title>
      <PeriodPicker
        period="lastYear"
        periodAdd="month"
        initialDate={filter.initialDate}
        finalDate={filter.finalDate}
        onChange={value =>
          setFilter(state => ({
            ...state,
            initialDate: value.initialDate,
            finalDate: value.finalDate,
          }))
        }
        maxDate={endOfMonth(new Date())}
      />
    </S.Header>
  );

  const selectItens = useMemo(() => {
    const itens = [
      { value: 'motoristas_avaliados', name: 'Motoristas avaliados' },
      { value: 'motoristas_sem_desvios', name: 'Motorista sem desvios' },
    ];
    if (hasMonitoramento)
      itens.splice(1, 0, {
        value: 'horas_inspecionadas',
        name: 'Horas inspecionadas',
      });

    return itens;
  }, [hasMonitoramento]);

  const selectComponent = (
    <S.HeaderActions>
      <SelectInput
        style={{ height: '45px' }}
        value={filter.label}
        data={selectItens}
        onChange={({ target }) => {
          setFilter(state => ({
            ...state,
            label: target?.value,
          }));
        }}
      />
      <S.StyledButton
        textcolor={theme.palette.words.text.natural}
        backgroundcolor="transparent"
        startIcon={<SvgIcon component={filterIco} />}
        height="45px"
        onClick={event => setAnchorEl(event.currentTarget)}
        disabled={isFetching}
      >
        Filtros
      </S.StyledButton>
    </S.HeaderActions>
  );

  const getData = _data => {
    return data?.map(item => ({
      ...item,
      value: Number(item.x),
      name: `${ptMonths[item.mes - 1].substring(0, 3)} ${item.ano}`,
    }));
  };

  const rightYAxisMax = datamax => {
    return datamax > 100
      ? Math.ceil(datamax / 100) * 100
      : datamax > 10
      ? Math.ceil(datamax / 10) * 10
      : 10;
  };

  return (
    <>
      <Graph
        data={getData(data)}
        barSeries={[]}
        barLabel={[]}
        barColors="default"
        rightYAxisMax={rightYAxisMax}
        lineSeries={['value', 'meta']}
        lineLabel={[
          filter.label === 'horas_inspecionadas' ? 'Horas' : 'Quantidade',
          'Meta',
        ]}
        lineStrokeDash={['', '5 5']}
        lineColors="meta"
        linePostFix=""
        titleComponent={titleComponent}
        tabComponent={selectComponent}
        loading={isFetching}
        tooltip
        tooltipContent={CustomTooltip}
        wrapped
      />
      <Filters
        id="simple-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        filter={filter}
        setFilter={setFilter}
      />
    </>
  );
};
