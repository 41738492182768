import styled, { css } from 'styled-components';
import { Modal } from '@mui/material';

export const BigModal = styled(Modal)`
  transition: all ease 0.3s;
  margin-top: ${props => props.visible};
`;

export const Cover = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
  overflow: hidden;
`;

export const Header = styled.header`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    width: 100%;
    height: 70px;
    display: flex;

    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px #999;
    padding: 0px 20px;
    z-index: 9999;
    background-color: #fff;

    .left {
      position: absolute;
      left: 0;
      margin-left: 30px;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .right {
      position: absolute;
      right: 0;
      margin-right: 30px;
      display: flex;
      align-items: center;

      .stArea {
        margin-right: 35px;
        display: flex;
        align-items: center;
        justify-content: center;

        .menu {
          margin-left: 15px;
          color: ${theme.palette.brand.secondary.natural};
          font-size: 35px;
          transform: rotateY(${props => (props.visible ? '180deg' : '0deg')});
          transition: all ease 0.3s;
          cursor: pointer;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }

    .medium {
      img {
        width: 40px;
      }

      @media (max-width: 990px) {
        img {
          display: none;
        }
      }
    }
  `}
`;

export const Body = styled.div`
  padding-top: 70px;
  height: 100%;
  display: flex;
  z-index: 1;
  background-color: red;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    flex: 2;
    background-color: ${theme.palette.brand.primary.background};
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    ::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px white;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #9090904d;
      border-radius: 10px;
    }
  `}
`;

export const Bar = styled.div`
  ${({ theme }) => css`
    flex: 1;
    background-color: #fff;
    border-left: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    margin-right: ${props => (props.visible ? '0px' : '-50%')};
    transition: all ease 0.3s;
    height: 100%;
    overflow: auto;
    position: relative;

    ::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px white;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.palette.words.text.light};
      border-radius: 10px;
    }

    .iconAbs {
      position: fixed;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0px 0px 10px #999;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      margin-left: ${props => (props.visible ? '-15px' : '-25px')};
      cursor: pointer;
      transform: rotateY(${props => (props.visible ? '0deg' : '180deg')});
      transition: all ease 0.3s;

      &:hover {
        opacity: 0.8;

        .MuiSvgIcon-root {
          color: ${theme.palette.brand.secondary.natural};
        }
      }

      .MuiSvgIcon-root {
        color: ${theme.palette.words.title.natural};
        font-size: 12px;

        &:hover {
          color: ${theme.palette.brand.secondary.natural};
        }
      }
    }
  `}
`;

export const LineModule = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    padding: 0px 20px;
    cursor: pointer;
    transition: all ease 0.1s;

    background-color: ${props =>
      props.active ? theme.palette.system.border : '#fff'};

    .MuiSvgIcon-root {
      color: ${props =>
        !props.active
          ? theme.palette.words.text.light
          : theme.palette.brand.secondary.natural};
    }

    &:hover {
      background-color: ${theme.palette.system.border};

      .MuiSvgIcon-root {
        color: ${theme.palette.brand.secondary.natural};
      }
    }

    .left {
      .type {
        font-size: 18px;
        color: ${theme.palette.words.title.natural};
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
          margin-left: 5px;
        }
      }

      .status {
        font-size: 14px;
        color: ${theme.palette.words.subtitle.natural};
        font-weight: 600;
        margin-top: 5px;
      }
    }

    .right {
    }
  `}
`;

export const ZeroSelect = styled.div`
  ${({ theme }) => css`
    padding: ${props => (props.view ? '0' : '30px 80px')};
    margin-top: ${props => (props.view ? '-72px' : '0px')};
    transition: all ease 0.3s;
    position: relative;
    width: 100%;

    .titleArea {
      z-index: 2;
      margin-bottom: ${props => (props.view ? '-180px' : '20px')};
      margin-top: ${props => (props.view ? '180px' : '0px')};
      margin-left: ${props => (props.view ? '80px' : '0px')};

      .title {
        opacity: ${props => (props.view ? 0 : 1)};
        transition: all ease 0.3s;
      }

      .title::first-letter {
        text-transform: uppercase;
      }

      .tags {
        margin-top: 10px;
        opacity: ${props => (props.view ? 0 : 1)};
      }
    }

    .background {
      height: ${props => (props.view ? '300px' : '350px')};
      background-image: url(${props => props.background});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      z-index: -1;
      width: 100%;

      .gradient {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0px 120px;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.95) 0%,
          rgba(0, 0, 0, 0.5) 100%
        );
        opacity: ${props => (props.view ? 1 : 0)};
        transition: all ease 0.3s;

        .title {
          font-size: 30px;
          color: #fff;
          transition: all ease 0.3s;

          &::first-letter {
            text-transform: uppercase;
          }
        }

        .description {
          font-size: 18px;
          color: #fff;
          font-weight: 500;
          margin-top: 15px;
          transition: all ease 0.3s;
        }
      }
    }

    .descriptionArea {
      margin-top: 25px;

      .descriptionTab {
        display: inline-block;
        border-bottom: 5px solid ${theme.palette.brand.secondary.natural};
        color: ${theme.palette.words.title.natural};
        padding-bottom: 3px;
        font-weight: 600;
        font-size: 17px;
      }

      .descriptionText {
        margin-top: 15px;
        color: ${theme.palette.words.subtitle.natural};
        font-weight: 500;
      }
    }
  `}
`;

export const BoxView = styled.div`
  padding: 20px 70px;
  padding-bottom: 0px;
  margin-left: -${props => props.slide}%;
  max-width: 1300px;
  width: 100%;
  transition: all ease 0.5s;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
`;
