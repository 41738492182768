import styled from 'styled-components';

export const SpacedRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  flex: 1;
  margin: 1.5rem 0;
`;

export const CenteredCell = styled.div`
  display: flex;
  justify-content: center;
`;
