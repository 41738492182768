import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { isValid, parseISO } from 'date-fns';
import {
  setFilter as setFilterFadigaDesvios,
  setSortBy,
} from 'store/modules/filterFadigaDesvios/actions';
import { setFilter } from 'store/modules/filter/actions';
import { toast } from 'react-toastify';

import { SaveAlt, Check } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';

import ExportToExcel from 'utils/exportToCvs';
import { useTheme } from 'styled-components';

import Tabs from 'components/Tabs';
import Card from 'components/Cards/Indicador';
import ExcelModal from 'components/ExcelModal';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import { DefaultTable } from 'components/_Table/templates/default';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';
import ApproveModal from './ApproveModal';
import ResponsavelModal from './ResponsavelModal';

import {
  statusTabs,
  columns,
  fields,
  resetExcelFields,
  emptyState,
} from './constants';

import {
  requestInfractions,
  requestExcel,
  approveInfractions,
  responsavelInfractions,
  finishInfractions,
} from './services';

import * as S from './styled';

const FadigaDesvios = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const initial = useRef(true);

  // Redux e hooks
  const filter = useSelector(state => {
    return state.filter;
  });

  const filterFadigaDesvios = useSelector(state => {
    return state.filterFadigaDesvios;
  });

  const [query, setQuery] = useState(null);
  const [cards, setCards] = useState([]);
  const [selectedInfractions, setSelectedInfractions] = useState([]);

  const [filialSelected, setFilialSelected] = useState(null);
  const [idsApproveModal, setIdsApproveModal] = useState(null);
  const [idsFinishModal, setIdsFinishModal] = useState(null);
  const [idsResponsibleModal, setIdsResponsibleModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);

  // Excel Fields
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const navigate = useNavigate();

  // -------------------------- REQUESTS ------------------------------------------//
  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  const {
    isFetching,
    isLoading: loadingInfractions,
    data: resData,
    refetch,
  } = useQuery(['infractions-fadiga', query], () => query && fetchData(query), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setLoadingLines([]);
      resetTable && setResetTable(false);
    },
    onError: () => {},
    enabled: !initial.current,
  });

  // -------------------------- Requisições e controles --------------------------
  // Requisição da lista de desvios. Chamada com alteracao de updated:
  // filter, filterFadigaDesvios
  const fetchData = async fetchQuery => {
    const isReport = params.get('isReport');

    const res = await requestInfractions({
      ...fetchQuery,
      isReport: isReport == '1' || isReport == 'true',
    });
    if (res.data?.success) {
      setCards({
        grave: res.data.totalGraves,
        proximoVencimento: res.data.totalProximosVencimento,
      });
    }
    return res;
  };

  // ajusta os nomes dos filtros com os nomes na api
  const filters = useMemo(
    () => ({
      initialDate: filter.initialDate,
      finalDate: filter.finalDate,
      idCliente: filter.client ? [filter.client] : '',
      idFilial: filter.filial ? [filter.filial] : '',
      status: filterFadigaDesvios.status,
      categoria: filterFadigaDesvios.categoria?.split(',').filter(i => !!i),
      criticidade: filterFadigaDesvios.criticidade?.split(',').filter(i => !!i),
      proximoVencimento: filterFadigaDesvios.proximoVencimento,
      idDesvio: filterFadigaDesvios.desvio,
      idResponsavel: filterFadigaDesvios.responsavel,
    }),
    [filter, filterFadigaDesvios],
  );

  // Adiciona coluna de posicao 5 com o STATUS
  const handleColumns = () => {
    const col = [...columns];

    if (filterFadigaDesvios.status === 'FINALIZADO')
      col[5] = {
        header: 'Data Conclusão',
        id: 'data_encerramento',
        type: 'date',
        sort: true,
      };
    else if (filterFadigaDesvios.status === 'DELETADO')
      col[5] = {
        header: 'Data Exclusão',
        id: 'data_exclusao',
        type: 'date',
        sort: true,
      };

    return col;
  };

  // Set filter by queryparams
  useEffect(() => {
    const newfilter = {};
    const initialDate = params.get('initialDate');
    const finalDate = params.get('finalDate');
    const client = params.get('idCliente');
    if (initialDate && isValid(parseISO(initialDate)))
      newfilter.initialDate = parseISO(initialDate);
    if (finalDate && isValid(parseISO(finalDate)))
      newfilter.finalDate = parseISO(finalDate);
    if (client && !isNaN(client)) newfilter.client = [Number(client)];

    const newfilterDesvios = {};
    const status = params.get('status');
    const categoria = params.get('categoria');
    const proximoVencimento = params.get('proximoVencimento');
    const desvio = params.get('idDesvio');
    const tipo = params.get('tipo');

    if (
      status &&
      ['ABERTO', 'PENDENTE', 'FINALIZADO', 'DELETADO'].includes(status)
    )
      newfilterDesvios.status = status;
    if (categoria && categoria === 'fadiga')
      newfilterDesvios.categoria = categoria;
    if (proximoVencimento == 1 || proximoVencimento === 'true')
      newfilterDesvios.proximoVencimento = true;
    if (desvio && !isNaN(desvio)) newfilterDesvios.desvio = Number(desvio);
    if (tipo) newfilterDesvios.tipo = tipo;

    dispatch(setFilter(newfilter));
    dispatch(setFilterFadigaDesvios(newfilterDesvios));
  }, []);

  // Atualiza tabela após filtros
  // Atrasa a primeira consulta para aplicar o filtro de query params

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filters]);

  // Troca do tab (filterFadigaDesvios.status)
  const handleStatusTab = (event, newValue) => {
    dispatch(
      setFilterFadigaDesvios({
        status: newValue,
        categoria: 'FADIGA',
        proximoVencimento: false,
      }),
    );
  };

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor válido: nao nulo e maior que 0.
  const handleClickCard = useCallback(
    type => {
      if (type === 'proximoVencimento') {
        if (cards[type] || filterFadigaDesvios.proximoVencimento) {
          dispatch(
            setFilterFadigaDesvios({
              proximoVencimento: !filterFadigaDesvios.proximoVencimento,
            }),
          );
        }
      } else if (type === 'grave') {
        if (filterFadigaDesvios.criticidade === 'GRAVE,GRAVISSIMA') {
          dispatch(
            setFilterFadigaDesvios({
              criticidade: '',
            }),
          );
        } else if (cards[type]) {
          dispatch(
            setFilterFadigaDesvios({
              criticidade: 'GRAVE,GRAVISSIMA',
            }),
          );
        }
      }
    },
    [cards],
  );

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = {
      ...filters,
      excelFields: newFields,
    };

    const res = await requestExcel(newQuery);
    if (res.data && res.data?.data?.excel) {
      ExportToExcel({
        excel: res.data.data.excel,
        name: `desvios_fadiga_${formatedDate}`,
      });
      toast.success(res.data.message);
    } else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // ********************  ACTIONS / BULKACTIONS *****************************
  // Funções das ações

  const handleAtribuirResponsavel = async data => {
    setLoadingModal(true);

    const res = await responsavelInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
    } else if (res.data?.message) toast.error(res.data.message);

    refetch();
    setLoadingModal(false);
    setIdsResponsibleModal(null);
  };

  const handleAprovar = async data => {
    setLoadingModal(true);

    const res = await approveInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
    } else if (res.data?.message) toast.error(res.data.message);

    refetch();
    setLoadingModal(false);
    setIdsApproveModal(null);
  };

  const handleFinalizar = async () => {
    setLoadingModal(true);

    const data = { ids: idsFinishModal, data_encerramento: new Date() };
    const res = await finishInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
    } else if (res.data?.message) toast.error(res.data.message);

    refetch();
    setLoadingModal(false);
    setIdsFinishModal(null);
  };

  const getIndex = id => {
    const _data = [...(resData?.data?.rows ?? [])];
    dispatch(setSortBy(query.sortBy));

    const dataIndex = _data.findIndex(item => item.id == id);
    if (dataIndex !== -1) {
      return query.pageSize * query.pageIndex + dataIndex;
    }
    return '';
  };

  const handleOpenNewTab = id => {
    return window.open(`/fadiga/desvios/${id}?index=${getIndex(id)}`);
  };

  const handleOpenDetails = id => {
    const url = `/fadiga/desvios/${id}?index=${getIndex(id)}`;
    navigate(url);
  };

  // Atualiza bulkActions de acordo com o status selecionado
  // Os itens selecionados podem alterar o a lista de ações
  const getBulkActions = () => {
    let actions = [];

    switch (filterFadigaDesvios.status) {
      case 'ABERTO':
        // Confere se seleção tem a mesma filial
        const filialIds = [];
        for (const desvio of resData?.data?.rows ?? []) {
          const filialId = desvio.motorista?.filial?.id;
          if (
            selectedInfractions.includes(desvio.id) &&
            !filialIds.includes(filialId)
          )
            filialIds.push(filialId);
        }
        const mesmaFilial = filialIds.length < 2;

        // nao mostrar 'aprovar' quando há filiais diferentes
        actions = mesmaFilial
          ? [
              {
                title: 'Atribuir Responsável',
                function: ids => {
                  setIdsResponsibleModal(ids);
                  setFilialSelected(filialIds[0]);
                },
              },
              {
                title: 'Aprovar',
                function: ids => {
                  setIdsApproveModal(ids);
                  setFilialSelected(filialIds[0]);
                },
              },
            ]
          : [];
        break;

      case 'PENDENTE':
        actions = [
          {
            title: 'Finalizar',
            function: ids => setIdsFinishModal(ids),
          },
        ];
        break;

      // case 'FINALIZADO':
      //   actions = [
      //     {
      //       title: 'Enviar para PAD',
      //       function: ids => setIdsPadModal(ids),
      //     },
      //   ];
      //   break;

      case 'DELETADO':
        break;

      default:
        break;
    }
    return actions.filter(Boolean);
  };

  // // Atualiza ações de acordo com o status selecionado
  const getActions = () => {
    let actions = [{ name: 'null', text: 'Sem ações' }];

    switch (filterFadigaDesvios.status) {
      case 'ABERTO':
        actions = [
          {
            title: 'Aprovar',
            function: id => setIdsApproveModal([parseInt(id)]),
          },
          {
            title: 'Ver detalhes',
            function: handleOpenNewTab,
          },
        ];
        break;

      case 'PENDENTE':
        actions = [
          {
            title: 'Finalizar',
            function: id => setIdsFinishModal([parseInt(id)]),
          },
          {
            title: 'Ver detalhes',
            function: handleOpenNewTab,
          },
        ];
        break;

      case 'FINALIZADO':
        actions = [
          // {
          //   title: 'Enviar para o PAD',
          //   function: id => setIdsPadModal([parseInt(id)]),
          // },
          {
            title: 'Ver detalhes',
            function: handleOpenNewTab,
          },
        ];
        break;

      case 'DELETADO':
        actions = [
          {
            title: 'Ver detalhes',
            function: handleOpenNewTab,
          },
        ];
        break;

      default:
        break;
    }
    return actions.filter(Boolean);
  };

  // //  ------------------   RENDERIZACAO --------------------------------
  // // Renderiza cards por status: ABERTO, PENDENTE, FINALIZADO, DELETADO
  const renderCards = () => {
    return (
      <Grid container spacing={2} marginBottom="25px" marginTop="15px">
        {['PENDENTE', 'ABERTO'].includes(filterFadigaDesvios.status) && (
          <Grid item xs={12} md={4} xl={4}>
            <Card
              value={cards?.proximoVencimento ?? 0}
              icon="desvio_prox_vencimento.svg"
              text="Desvios próximos ao vencimento"
              handleClick={() => handleClickCard('proximoVencimento')}
              selected={filterFadigaDesvios.proximoVencimento}
              disabled={isFetching}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4} xl={4}>
          <Card
            value={cards?.grave ?? 0}
            icon="Grupo12754.svg"
            text="Com criticidade grave ou gravíssima"
            handleClick={() => handleClickCard('grave')}
            selected={filterFadigaDesvios.criticidade === 'GRAVE,GRAVISSIMA'}
            disabled={isFetching}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <S.Main>
        <Grid
          marginBottom={1}
          item
          md={12}
          display="flex"
          justifyContent="space-between"
        >
          <h1>Desvios</h1>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={() => setOpenExcelModal(true)}
            style={{ marginLeft: '10px' }}
          >
            EXPORTAR
          </GhostButton>
        </Grid>

        <Grid marginBottom={0} item md={12} display="flex">
          <Tabs
            value={filterFadigaDesvios.status}
            items={statusTabs}
            onChange={handleStatusTab}
            disabled={loadingInfractions || isFetching}
            last
          />
        </Grid>

        <Divider />

        {renderCards()}

        <DefaultTable
          data={resData?.data?.rows || []}
          columns={handleColumns()}
          setSelectedRows={setSelectedInfractions}
          loading={loadingInfractions}
          pageCount={resData?.data?.total || 0}
          visualizedKey="visto"
          local={false}
          bulk={getBulkActions()}
          actions={getActions()}
          reset={resetTable}
          onClickRow={handleOpenDetails}
          loadingSelection={loadingLines}
          setQuery={q =>
            setQuery({
              ...q,
              ...filters,
            })
          }
          sortBy={{ id: 'data_violacao', order: 'DESC' }}
          placeholder="Buscar por ID, Placa ou Motorista"
          empty={emptyState}
        />
      </S.Main>

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      {idsApproveModal && (
        <ApproveModal
          handleConfirm={handleAprovar}
          ids={idsApproveModal}
          handleClose={() => setIdsApproveModal(null)}
          loading={loadingModal}
          idFilialDesvios={filialSelected}
        />
      )}

      {idsResponsibleModal && (
        <ResponsavelModal
          handleConfirm={handleAtribuirResponsavel}
          ids={idsResponsibleModal}
          handleClose={() => setIdsResponsibleModal(null)}
          loading={loadingModal}
          idFilialDesvios={filialSelected}
        />
      )}

      {idsFinishModal && (
        <ConfirmModal
          handleClose={() => setIdsFinishModal(null)}
          open={Boolean(idsFinishModal)}
          title={
            idsFinishModal.length > 1
              ? `Deseja finalizar os desvios selecionados? (${idsFinishModal.length})`
              : 'Deseja finalizar o desvio selecionado?'
          }
          titleIcon={
            <Check
              sx={{ color: theme.palette.brand.secondary.natural }}
              fontSize="medium"
            />
          }
          subtitle={
            idsFinishModal.length > 1
              ? `Os desvios serão movidos para o status Finalizados. As alterações serão aplicadas em TODOS os desvios selecionados.`
              : 'O desvio será movido para o status Finalizados.'
          }
          buttonText="Confirmar"
          onClick={handleFinalizar}
          loading={loadingModal}
        />
      )}
    </>
  );
};

export default FadigaDesvios;
