import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  id: null,
  geral: null,
  midias: null,
  avaliacao: null,
  publico: null,
  configuracoes: null,
  template: null,
};

export default function conteudoProvider(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_DATA_ID: {
        draft.id = action.payload.data;
        break;
      }
      case types.SET_DATA_GERAL: {
        draft.geral = action.payload.data;
        break;
      }
      case types.SET_DATA_MIDIAS: {
        draft.midias = action.payload.data;
        break;
      }
      case types.SET_DATA_AVALIACAO: {
        draft.avaliacao = action.payload.data;
        break;
      }
      case types.SET_DATA_PUBLICO: {
        draft.publico = action.payload.data;
        break;
      }
      case types.SET_DATA_CONFIGURACOES: {
        draft.configuracoes = action.payload.data;
        break;
      }
      case types.SET_DATA_PUBLICAR: {
        draft.publicar = action.payload.data;
        break;
      }
      case types.INITIALIZE_DATA: {
        draft.id = action.payload.id;
        draft.geral = action.payload.geral;
        draft.midias = action.payload.midias;
        draft.avaliacao = action.payload.avaliacao;
        draft.publico = action.payload.publico;
        draft.configuracoes = action.payload.configuracoes;
        draft.template = action.payload.template;

        break;
      }
      case types.RESET_DATA: {
        draft.id = null;
        draft.geral = null;
        draft.midias = null;
        draft.avaliacao = null;
        draft.publico = null;
        draft.configuracoes = null;
        draft.template = null;
        break;
      }
      default:
    }
  });
}
