/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import { Progress } from 'components/CircularProgress';

// Components MUI
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

let timer = null;

export const HeaderGuide = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hoverGuide, setHoverGuide] = useState(false);
  const { concluded } = useSelector(state => state.guiaQueue);

  return (
    <S.Wrapper
      onClick={() =>
        location.pathname !== '/guia-usuario' && navigate('/guia-usuario')
      }
      onMouseEnter={() => (timer = setTimeout(() => setHoverGuide(true), 250))}
      onMouseLeave={() => {
        clearTimeout(timer);
        setTimeout(() => setHoverGuide(false), 250);
      }}
    >
      <div className="status">
        <Progress value={hoverGuide ? 100 : concluded}>
          {!hoverGuide && <S.Value>{concluded}%</S.Value>}
          {hoverGuide && (
            <PlayArrowRoundedIcon
              htmlColor={colors.greenSucces}
              style={{
                transform: 'rotateY(180deg)',
              }}
            />
          )}
        </Progress>
      </div>

      <S.Text className="text">Guia do Usuário</S.Text>
    </S.Wrapper>
  );
};
