import React from 'react';
import {
  Tooltip,
  Legend,
  ResponsiveContainer,
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Cell,
} from 'recharts';
import { IconButton, SvgIcon, Grid } from '@mui/material';
import CheckFiltersCard from 'components/Cards/CheckFiltersCard';
import { useTheme } from 'styled-components';
import { paletteColors } from '../../../styles/colors';
import Loading from '../../Loading';
import * as S from './styled';

const RadarGraph = ({ data, color, title, loading, tooltip, tabComponent }) => {
  const theme = useTheme();
  const chartColors = paletteColors[color] || paletteColors.default;

  return (
    <S.StyledCard>
      <S.Header>
        <h2>{title}</h2>
        {tabComponent}
      </S.Header>

      {data?.length > 0 ? (
        <>
          {loading ? (
            <S.BoxLoading>
              <Loading />
            </S.BoxLoading>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart
                data={data}
                margin={{
                  top: 20,
                  right: 20,
                  left: 20,
                  bottom: 10,
                }}
                width={730}
                height={250}
                layout="centric"
                startAngle={90}
                endAngle={-270}
                outerRadius={90}
                innerRadius={0}
              >
                {tooltip && <Tooltip />}
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <PolarRadiusAxis />
                <Radar
                  dataKey="value" // default
                  stroke={theme.palette.brand.secondary.natural}
                  fill={theme.palette.brand.secondary.natural}
                  fillOpacity={0.6}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={chartColors[index % chartColors.length]}
                    />
                  ))}
                </Radar>
                <Legend />
              </RadarChart>
            </ResponsiveContainer>
          )}
        </>
      ) : (
        <CheckFiltersCard height="200px" marginTop="45px" />
      )}
    </S.StyledCard>
  );
};

export default RadarGraph;
