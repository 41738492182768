import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { EditOutlined } from '@mui/icons-material';
import InfoCard from 'components/Cards/InfoCard';
import ConfirmModal from 'components/ConfirmModal';
import { FilterTable } from 'components/_Table/templates/filter';
import Actions from 'pages/_templates/ListPage/components/Header/Actions';
import { trackEvent } from 'utils/mixpanel';
import ModalPontuacao from '../ModalPontuacao';
import { getColumns } from './constants';
import { desactivatePontuacao, enableAll } from '../../services';

import { usePlans } from 'hooks/usePlans';

const ListaPontuacoes = ({
  data = [],
  pageCount = 0,
  loading = false,
  refetch,
  setQuery,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth?.user?.user);
  const [confirmAll, setConfirmAll] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);
  const [resetTable, setResetTable] = useState(false);
  const [formModal, setFormModal] = useState({
    open: false,
    data: null,
  });
  const { isProvider } = usePlans();
  // HANDLERS
  const handleAtivar = async (id, row) => {
    const registro = row ?? data.find(item => String(item.id) === String(id));

    if (registro.ativo_acao_suspensao)
      return toast.success('Esse registro já está ativo');

    registro.ativo_acao_suspensao = true;

    // Se ativar sugestao, deve editar imediatamente!
    handleEditar(registro.id, registro);
  };

  const handleDesativar = async (id, row) => {
    const registro = row ?? data.find(item => String(item.id) === String(id));

    if (!registro.ativo_acao_suspensao)
      return toast.success('Esse registro já está inativo');

    const res = await desactivatePontuacao(registro.id);
    if (res.success) {
      refetch();
      toast.success(res.message);
    } else toast.error(res.message);
  };

  const handleEditar = (id, row) => {
    const registro = row ?? data.find(item => String(item.id) === String(id));
    if (registro) {
      setFormModal({
        open: true,
        data: {
          ...registro,
        },
      });
    } else toast.error('Não foi possível encontrar esse registro');
  };

  const handleActivateAll = async () => {
    setLoadingAll(true);
    const res = await enableAll();
    if (res.success) {
      refetch();
      toast.success(res.message);
    } else toast.error(res.message);
    setLoadingAll(false);
    setConfirmAll(false);
  };

  const actions = !isProvider
    ? [
        {
          title: 'Ativar',
          function: handleAtivar,
        },
        {
          title: 'Desativar',
          function: handleDesativar,
        },
        {
          title: 'Editar',
          function: handleEditar,
        },
        {
          title: 'Ver histórico',
          function: id =>
            navigate(`/logs/acoes-suspensoes/configuracoes/desvios/${id}`),
        },
      ]
    : [
        {
          title: 'Ativar',
          function: handleAtivar,
        },
        {
          title: 'Desativar',
          function: handleDesativar,
        },
        {
          title: 'Ver histórico',
          function: id =>
            navigate(`/logs/acoes-suspensoes/configuracoes/desvios/${id}`),
        },
      ];

  const headerActions = (
    <Actions
      actions={[
        {
          title: 'Habilitar todos os desvios',
          variant: 'ghost',
          function: () => setConfirmAll(true),
        },
      ]}
    />
  );

  return (
    <>
      <InfoCard
        message="Habilite os desvios que serão contabilizados para Ações e Suspensões e configure a pontuação que será aplicada."
        key="info"
      />

      <FilterTable
        data={data}
        columns={getColumns({
          handleAtivar,
          handleDesativar,
        })}
        loading={loading}
        pageCount={pageCount}
        local={false}
        actions={actions}
        reset={resetTable}
        setQuery={q => {
          setQuery({ ...q });
        }}
        searchEvent={search =>
          trackEvent(
            user,
            'Busca Desvio em Configurações de Ações e Suspensões',
            null,
            search,
          )
        }
        sortBy={{ id: 'ativo_acao_suspensao', order: 'DESC' }}
        placeholder="Buscar Desvio"
        empty={{
          title: 'Ops! Você não tem nenhum desvio disponível.',
          description: 'Verifique os filtros aplicados!',
        }}
        headerActions={headerActions}
      />

      <ModalPontuacao
        registro={formModal.data}
        handleClose={() => setFormModal({ open: false, data: null })}
        open={formModal.open}
        refetch={refetch}
      />

      <ConfirmModal
        open={confirmAll}
        handleClose={() => setConfirmAll(false)}
        title="Deseja ativar todos os tipos de desvios para ação e suspenção?"
        titleIcon={
          <EditOutlined
            sx={{ color: theme.palette.brand.primary.natural }}
            fontSize="medium"
          />
        }
        subtitle="Atenção! Será considerada como pontuação de ação e suspensão, a mesma pontuação configurada no tipo de desvio."
        buttonText="Confirmar"
        onClick={() => handleActivateAll()}
        loading={loadingAll}
      />
    </>
  );
};

export default ListaPontuacoes;
