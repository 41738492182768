import styled, { css } from 'styled-components';

export const Card = styled.a`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px 40px 15px;
    background-color: #fff;
    border-radius: 5px;
    border: 2px solid
      ${props =>
        props.isError
          ? theme.palette.semantics.feedback.attention.natural
          : theme.palette.system.border};
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

    h3 {
      color: ${theme.palette.words.subtitle.natural};
      text-align: left;
      font: normal medium 16px Texta;
      text-transform: capitalize;
    }
  `}
`;
