import styled, { css } from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div``;

export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 25px 50px;
    gap: 10px;
    margin-top: 10px;
    background: ${colors.orangeLighter};
    border: 1px solid ${colors.orangeAlert};
    border-radius: 4px;
    opacity: 1;

    p {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
    }
  `}
`;

export const Square = styled.div`
  ${({ theme }) => css`
    border: 2px solid ${colors.greyTiny};
    border-radius: 4px;
    margin-top: 10px;
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: ${colors.greyBackgroud};
`;

export const Title = styled.div`
  ${({ theme, space }) => css`
    font-size: 20px;
    flex: ${space};
    font-weight: 600;
    color: ${colors.greySubtitle};
    /* border: 1px solid black; */

    &.index {
      text-align: center;
    }
  `}
`;

export const Line = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid ${colors.greyTiny};
  `}
`;

export const Column = styled.div`
  ${({ theme, space }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: ${space};
    width: 100%;
    height: 100%;

    &.index {
      font-size: 20px;
      font-weight: 600;
      color: ${colors.greySubtitle};
      justify-content: center;
    }

    .trash {
      color: ${colors.redDanger};
      cursor: pointer;
    }

    /*
    &.tank {
      justify-content: center;
    } */
  `}
`;

export const Additional = styled.div`
  padding: 20px;
`;

export const Button = styled.div`
  ${({ theme }) => css`
    height: 60px;
    width: 100%;
    border: 2px dashed ${colors.greyTiny};
    font-size: 20px;
    font-weight: 600;
    color: ${colors.greySubtitle};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    gap: 10px;

    &:hover {
      background-color: ${colors.greyBackgroud};
    }
  `}
`;

export const Tank = styled.div`
  ${({ theme, color }) => css`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 2px solid ${colors.greyTiny};
    background-color: ${color};
  `}
`;
