// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import Button from 'components/Buttons/Default';
import ConfirmModal from 'components/ConfirmModal';

// Components MUI
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import EditTurma from '../../../Turmas/Modal/EditTurma';
import ModalAlunos from '../../../Turmas/Alunos';
import { ModalTurmas } from '../style';
import * as S from '../style';

// Utils
import { view } from '../columns';
import { getTurma, deleteAlunoTurma } from '../../../Turmas/services';
import { DefaultTable } from 'components/_Table/templates/default';

const ViewTurma = ({ behaviorCloseModal, openModal, idturma }) => {
  const theme = useTheme();
  const formularios = useSelector(state => state.formularios);

  const [loading, setLoading] = useState(false);
  const [openModalAddAluno, setOpenModalAddAluno] = useState(false);
  const [openModalEditAluno, setOpenModalEditAluno] = useState(false);
  const [turmaName, setTurmaName] = useState('');
  const [foto, setFoto] = useState('');
  const [infoGroup, setinfoGroup] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [currentID, setCurrentID] = useState(null);
  const [resetTable, setResetTable] = useState(false);

  // tabelas
  const [selectedRow, setSelectedRow] = useState('');
  const [data, setData] = useState([]);

  const fetchTurma = () =>
    getTurma(idturma).then(res => {
      setTurmaName(res.data.data.nome);
      setFoto(res.data.data.foto);
      setinfoGroup({
        id: res.data.data.id,
        nome: res.data.data.nome,
        foto: res.data.data.foto,
        status: res.data.data.ativa,
        para_motoristas: res.data.data.para_motoristas,
        para_usuarios: res.data.data.para_usuarios,
      });
      setData(res.data.data.alunos);
      setLoading(false);
      setResetTable(false);
    });

  useEffect(() => {
    fetchTurma();
    setLoading(true);
  }, [openModal]);

  const handleSetExcluir = async (id = null) => {
    const usersToDelete = [];
    data.map(item => {
      if (id === null && selectedRow.includes(item.id)) {
        usersToDelete.push(item.id_relacao_turma);
      } else if (Number(id) === Number(item.id)) {
        usersToDelete.push(item.id_relacao_turma);
      }
    });

    const dataToDelte = {
      id_turma: infoGroup.id,
      ids_alunos: usersToDelete,
    };
    const res = await deleteAlunoTurma(dataToDelte);
    if (res.data.success) {
      setResetTable(true);
      toast.success(res.data.message);
      fetchTurma();
    } else {
      toast.error(res.data.message);
    }
  };

  return (
    <ModalTurmas
      open={openModal}
      onClose={behaviorCloseModal}
      disableEscapeKeyDown={false}
    >
      <S.ContainerModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PeopleAltOutlinedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <h1 style={{ marginLeft: '10px' }}>Turma #{idturma}</h1>
          </div>
          <IconButton onClick={() => behaviorCloseModal()}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>

        <Divider light sx={{ marginTop: 1, marginBottom: 3 }} />

        <S.ButtonContianer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Avatar src={foto} sx={{ marginRight: 1 }}>
              {turmaName.length > 0 && turmaName[0].toUpperCase()}
            </Avatar>
            <h1 className="titleTurma">{turmaName}</h1>
          </div>

          <div>
            <Button
              disabled={!formularios.isEditable}
              style={{ marginLeft: 10 }}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => setOpenModalAddAluno(true)}
            >
              <span style={{ fontSize: 14 }}>NOVOS ALUNOS</span>
            </Button>
          </div>
        </S.ButtonContianer>

        <S.TableContainer>
          <DefaultTable
            data={data || []}
            columns={view}
            setSelectedRows={setSelectedRow}
            transparent
            bulk={[
              {
                title: 'Excluir',
                function: () => setConfirm(true),
              },
            ]}
            actions={[
              {
                title: 'Excluir',
                function: id => {
                  setCurrentID(id);
                  setConfirm(true);
                },
              },
            ]}
            loading={loading}
            fixedItems={4}
            reset={resetTable}
            sortBy={{ id: 'nome', order: 'DESC' }}
            searchKeys={['nome', 'tipoUser']}
            empty={{
              title: 'Ops! Você não tem nenhuma turma.',
              description: 'Verifique os filtros selecionados.',
            }}
          />
        </S.TableContainer>

        {/* Modal Area */}
        <ConfirmModal
          open={confirm}
          handleClose={() => setConfirm(false)}
          title={`Exclusão de ${selectedRow.length === 0 ? 'aluno' : 'alunos'}`}
          subtitle={`Deseja excluir definitivamente ${
            selectedRow.length === 0
              ? 'o aluno selecionado'
              : ' os alunos selecionados'
          }?`}
          buttonText="Excluir"
          onClick={() => {
            selectedRow.length === 0
              ? handleSetExcluir(currentID)
              : handleSetExcluir();
            setConfirm(false);
          }}
        />

        {infoGroup && openModalAddAluno && (
          <ModalAlunos
            openModalAluno={openModalAddAluno}
            id_turma={idturma}
            tipoUser={{
              para_motoristas: infoGroup.para_motoristas ? 1 : 0,
              para_usuarios: infoGroup.para_usuarios ? 1 : 0,
              existentIds: data.map(item => item.id),
            }}
            behaviorCloseModal={() => setOpenModalAddAluno(false)}
            fetchData={fetchTurma}
          />
        )}

        <EditTurma
          open={openModalEditAluno}
          dataEdit={infoGroup}
          handleClose={() => setOpenModalEditAluno(false)}
          fetchData={fetchTurma}
        />
      </S.ContainerModal>
    </ModalTurmas>
  );
};

export default ViewTurma;
