import colors from 'styles/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s;

  .status {
    transition: all ease-in-out 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    .text {
      opacity: 0.6;
    }

    .status {
      transform: rotateY(180deg);
    }
  }
`;

export const Text = styled.div`
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
  color: ${colors.greyTitle};
`;

export const Value = styled.div`
  font-size: 12px;
  font-weight: 900;
  color: ${colors.greyTitle};
`;
