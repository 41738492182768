import api from 'services/api';

// ---------------------------INDEX REQUESTS-----------------------------//

export const requestDesviosList = async query => {
  const res = await api.get('/provider/exclusao-desvio', { params: query });
  return res;
};

export const requestCards = async query => {
  const res = await api.get('/provider/cards/exclusao-desvio', {
    params: query,
  });
  return res;
};

export const toggleOperation = async data => {
  const res = await api.put('/provider/exclusao-update', data);
  return res;
};

export const requestExcel = async query => {
  const res = await api.get('/excel-provider/infractionExclude', {
    params: query,
  });
  return res;
};
