import styled, { css } from 'styled-components';
import { Tab } from '@mui/material';

const minWidth = '70px';

export const StyledTab = styled(Tab)`
  ${({ theme }) => css`
    && {
      text-transform: none;
      min-width: ${minWidth};
      transition: 0s;
      padding: 1px 15px;
      font-size: 14px;
      color: ${theme.palette.brand.primary.natural};
      border-bottom: 1px solid ${theme.palette.system.divider};

      :hover {
        color: ${theme.palette.brand.secondary.natural};
        opacity: 1;
        min-width: ${minWidth};
      }

      :selected {
        color: ${theme.palette.brand.secondary.natural};
        min-width: ${minWidth};
      }

      :focus {
        color: ${theme.palette.brand.secondary.natural};
        minwidth: ${minWidth};
      }
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme, width }) => css`
    width: ${width || '100%'};
    position: relative;
    .MuiTab-textColorPrimary {
      color: ${theme.palette.brand.primary.natural} !important;
      font-weight: 900;
      opacity: 0.5;
    }

    .MuiTab-textColorPrimary.Mui-selected {
      opacity: 1;
    }
  `}
`;

export const InfoIcon = styled.button`
  display: none;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.palette.brand.primary.background};
  border-radius: 50%;
  margin-left: 3px;
`;

export const Div = styled.div`
  width: 18px;
`;

export const Span = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;

  &:hover ${InfoIcon} {
    display: flex;
  }

  &:hover ${Div} {
    display: none;
  }

  h3 {
    color: ${({ theme }) => theme.palette.words.subtitle.natural};
    font: normal normal 600 16px/18px Texta;
  }
`;
