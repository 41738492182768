import styled from 'styled-components';

const StyledCard = styled.div`
  padding: 16px;
  width: 100%;
  overflow: hidden;
  height: 280px;
  .graph {
    transform: translate(20%, -50px);
  }
`;

const Header = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0px;
    color: #4b5166;
  }
`;

const BoxLoading = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: right;
`;

const Legend = styled.div`
  position: relative;
  left: 10px;
  top: 50%;
  color: #4b5166;
  .container-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const CicleLegend = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: ${p => p.color};
  margin-right: 5px;
`;
export { StyledCard, Header, BoxLoading, Legend, CicleLegend };
