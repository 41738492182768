export const defaultForm = {
  titulo: '',
  descricao: '',
  link: '',
  ativa: true,
  niveis: [1, 2, 3],
  is_etapa_obrigatoria: true,
  tempo: 0,
  mode: null,
  tipo: null,
  mostrar_guia: 1,
  mostrar_rota: 1,
  mostrar_catalogo: 1,
  imagem_capa: null,
};
