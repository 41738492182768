import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { paletteColors } from 'styles/colors';
import { IconButton, SvgIcon } from '@mui/material';
import { useTheme } from 'styled-components';
import * as S from './styled';
import { ReactComponent as settingsIcon } from '../../../images/icons/sidebar/inicio.svg';
import Loading from '../../Loading';

const LineGraph = ({
  data,
  colors,
  series,
  title,
  legend,
  loading,
  tooltip,
  isAnimationActive,
  label,
  newYAxisMax,
  doubleYAxis,
  series2,
  tabComponent,
}) => {
  const theme = useTheme();
  const chartColors = paletteColors[colors] || paletteColors.default;

  const CustomizedAxisTick = props => {
    const { x, y, payload } = props;

    let label = payload.value;
    if (label.length > 12) label = `${label.slice(0, 10)}...`;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={10}
          y={10}
          dy={5}
          textAnchor="end"
          fill={theme.palette.words.text.natural}
          transform="rotate(-30)"
        >
          {label}
        </text>
      </g>
    );
  };

  return (
    <S.StyledCard>
      <S.Header>
        <h2>{title}</h2>
        {tabComponent}
      </S.Header>
      {loading ? (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{
              top: 40,
              right: 20,
              left: 10,
              bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray="1 0" horizontal={false} vertical />
            <XAxis
              dataKey="x"
              axisLine={false}
              interval={0}
              tick={<CustomizedAxisTick />}
              height={40}
            />
            <YAxis
              type="number"
              domain={[
                0,
                newYAxisMax || (dataMax => Math.round(dataMax * 1.1)),
              ]}
              tickCount={8}
              allowDecimals
              axisLine={false}
            />
            {doubleYAxis && (
              <YAxis
                type="number"
                domain={[0, dataMax => 100]}
                tickCount={8}
                allowDecimals
                axisLine={false}
                yAxisId="right"
                orientation="right"
                tickFormatter={tick => `${tick}%`}
              />
            )}
            {tooltip && <Tooltip />}
            {legend && <Legend />}
            {series.map((item, idx) => (
              <Line
                key={item}
                type="linear" // monotone step
                dataKey={item}
                legendType="circle" // line tringle rect
                dot={{ r: 6 }} // mostrar pontos sem hover. Pode receber obj personalizado
                activeDot={{ r: 8 }} // mostrar pontos com hover. Pode receber obj personalizado
                stroke={chartColors[idx % chartColors.length]}
                strokeWidth={3}
                isAnimationActive={isAnimationActive}
                label={label}
              />
            ))}
            {series2 &&
              series2.map((item, idx) => (
                <Line
                  key={item}
                  type="linear" // monotone step
                  dataKey={item}
                  yAxisId="right"
                  legendType="star" // line tringle rect
                  dot={{ r: 6 }} // mostrar pontos sem hover. Pode receber obj personalizado
                  activeDot={{ r: 8 }} // mostrar pontos com hover. Pode receber obj personalizado
                  stroke={chartColors[idx + (1 % chartColors.length)]}
                  strokeWidth={3}
                  isAnimationActive={isAnimationActive}
                  label={label}
                />
              ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </S.StyledCard>
  );
};

export default LineGraph;
