import React, { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import cep from 'cep-promise';
import { useQuery } from 'react-query';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
} from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import TextInput from 'components/Inputs/TextField';
import Select from 'components/Inputs/Select';
import Button from 'components/Buttons/Default';
import RadioGroup from 'components/Inputs/RadioGroup';
import { useNavigate } from 'react-router-dom';
import { FormCard, FormTab, Header, Title } from './styled';
import {
  getFaixas,
  getTelemetrias,
  getTecnologias,
  saveEmpresa,
  saveDistribuidora,
} from '../../services';
import { Switch } from 'components/Inputs/Switch';
import SelectMultiple from 'components/Inputs/SelectMultiple';

const ID_TELEMETRIA = 2;
const ID_VIDEO = 3;
const ID_AVANCADO = 4;

const ID_FADIGA = 110;
const ID_LAYOUT = 120;
const ID_ROTOGRAMA = 130;

const Form = ({ plan, provider }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(false);

  const { data: telemetrias = [] } = useQuery(
    ['telemetrias'],
    () => getTelemetrias(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: faixas = [] } = useQuery(['faixas'], () => getFaixas(), {
    refetchOnWindowFocus: false,
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
  } = useForm({
    defaultValues: {
      contrato: {
        isWhitelabel: false,
        id_do_plano: plan?.id ?? 0,
      },
      monitoramento: {
        possui_telemetria: false,
        nome_telemetria: '',
        possui_sistema_monitoramento: false,
        sistema_monitoramento: '',
        monitoramento_em_tempo_real: false,
        franquia_pacote_de_dados_por_veiculo: '',
        monitoramento_download_remoto: false,
        armazenamento_por_servidor_remoto: false,
        upload_por_wifi_garagem: false,
        upload_por_descarga_manual: false,
      },
    },
  });

  const proximaEtapa =
    plan.id === ID_AVANCADO || (plan.id === ID_VIDEO && !provider);

  const onSubmit = async data => {
    setLoading(true);
    try {
      const res = await saveEmpresa({
        ...data,
        // monitoramento: proximaEtapa ? { ...data.monitoramento } : null,
      });
      setLoading(false);
      if (res.success) {
        navigate('/cadastro/success');
      } else {
        if (res.message) {
          if (res.message.includes('Usuário CS'))
            setError('empresa.nome', {
              type: 'custom',
              message: 'Empresa já cadastrada.',
            });
          if (res.message.includes('Usuário'))
            setError('usuario.email', {
              type: 'custom',
              message: 'Email já cadastrado.',
            });
          if (res.message.includes('CNPJ'))
            setError('empresa.cnpj', {
              type: 'custom',
              message: 'CNPJ já cadastrado.',
            });
          setTab(1);
        }
      }
    } catch (error) {
      setLoading(false);
      console.warn('Erro ao salvar.', error);
    }
  };

  const onSubmitProvider = async data => {
    setLoading(true);
    try {
      const _data = {
        ...data,
        distribuidora: {
          ...data.empresa,
        },
      };
      delete _data.empresa;
      const res = await saveDistribuidora(_data);
      setLoading(false);
      if (res.success) {
        navigate('/cadastro/success');
      } else {
        if (res.message) {
          if (res.message.includes('Usuário CS'))
            setError('empresa.nome', {
              type: 'custom',
              message: 'Empresa já cadastrada.',
            });
          if (res.message.includes('Usuário'))
            setError('usuario.email', {
              type: 'custom',
              message: 'Email já cadastrado.',
            });
          if (res.message.includes('CNPJ'))
            setError('empresa.cnpj', {
              type: 'custom',
              message: 'CNPJ já cadastrado.',
            });
          setTab(1);
        }
      }
    } catch (error) {
      setLoading(false);
      console.warn('Erro ao salvar.', error);
    }
  };

  const onBlurCep = useCallback(({ target: { value } }) => {
    if (value) {
      cep(value, { timeout: 5000 })
        .then(res => {
          setValue('endereco.estado', res.state);
          setValue('endereco.cidade', res.city);
          clearErrors('endereco');
        })
        .catch(() => {
          setValue('endereco.estado', '');
          setValue('endereco.cidade', '');
          setError('endereco.cep', {
            type: 'custom',
            message: 'Cep não encontrado.',
          });
        });
    }
  }, []);

  const renderSelectTelemetria = () => (
    <>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="monitoramento.id_da_telemetria"
          render={({ field: { onChange, name, ref, value = [] } }) => (
            <SelectMultiple
              ref={ref}
              fieldName={name}
              value={value}
              handleChange={onChange}
              error={!!errors.monitoramento?.id_da_telemetria}
              message={errors.monitoramento?.id_da_telemetria}
              data={telemetrias}
              label="Tecnologias de telemetria"
              placeholder="Selecione as tecnologias"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="monitoramento.nome_telemetria"
          render={({ field }) => (
            <TextInput
              noAsterisk
              label="Outras tecnologias"
              placeholder="Insira outras tecnologias"
              {...field}
            />
          )}
        />
      </Grid>
    </>
  );

  const renderSelectMonitoramento = () => (
    <>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="monitoramento.id_do_monitoramento"
          render={({ field: { onChange, name, ref, value = [] } }) => (
            <SelectMultiple
              ref={ref}
              fieldName={name}
              value={value}
              handleChange={onChange}
              error={!!errors.monitoramento?.id_do_monitoramento}
              message={errors.monitoramento?.id_do_monitoramento}
              data={telemetrias}
              label="Tecnologias de video monitoramento"
              placeholder="Selecione as tecnologias"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="monitoramento.sistema_monitoramento"
          render={({ field }) => (
            <TextInput
              noAsterisk
              label="Outras tecnologias"
              placeholder="Insira outras tecnologias"
              {...field}
            />
          )}
        />
      </Grid>
    </>
  );

  return (
    <FormCard>
      <Header>
        <Title>
          <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f209c262f8214_Grupo%2016181.svg" />
          <h2>{plan.name}</h2>
        </Title>
        {proximaEtapa &&
          (tab == 1 ? (
            <IconButton onClick={() => setTab(2)}>
              <ArrowForward />
            </IconButton>
          ) : (
            <IconButton onClick={() => setTab(1)}>
              <ArrowBack />
            </IconButton>
          ))}
      </Header>

      <form
        onSubmit={handleSubmit(provider ? onSubmitProvider : onSubmit, () =>
          setTab(1),
        )}
      >
        <FormTab show={tab === 1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
                control={control}
                name="usuario.nome"
                render={({ field }) => (
                  <TextInput
                    required
                    error={!!errors.usuario?.nome}
                    message={errors.usuario?.nome?.message}
                    label="Nome"
                    placeholder="Seu nome"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
                control={control}
                name="usuario.email"
                render={({ field }) => (
                  <TextInput
                    required
                    error={!!errors.usuario?.email}
                    message={errors.usuario?.email?.message}
                    label="E-mail"
                    placeholder="E-mail"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
                control={control}
                name="empresa.nome"
                render={({ field }) => (
                  <TextInput
                    required
                    label="Nome da Empresa"
                    placeholder="Nome da Empresa"
                    error={!!errors.empresa?.nome}
                    message={errors.empresa?.nome?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
                control={control}
                name="empresa.cnpj"
                render={({ field }) => (
                  <InputMask mask="99.999.999/9999-99" {...field}>
                    {() => (
                      <TextInput
                        required
                        label="CNPJ"
                        placeholder="CNPJ"
                        error={!!errors.empresa?.cnpj}
                        message={errors.empresa?.cnpj?.message}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
                control={control}
                name="empresa.razao_social"
                render={({ field }) => (
                  <TextInput
                    required
                    label="Razão Social"
                    placeholder="Razão Social"
                    error={!!errors.empresa?.razao_social}
                    message={errors.empresa?.razao_social?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
                control={control}
                name="empresa.telefone"
                render={({ field }) => (
                  <InputMask
                    mask="(99) 9999tt999?"
                    formatChars={{ 9: '[0-9]', t: '[0-9-]', '?': '[0-9 ]' }}
                    maskChar={null}
                    {...field}
                  >
                    {() => (
                      <TextInput
                        required
                        label="Telefone"
                        placeholder="Telefone"
                        error={!!errors.empresa?.telefone}
                        message={errors.empresa?.telefone?.message}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
                control={control}
                name="endereco.cep"
                render={({ field }) => (
                  <InputMask mask="99.999-999" {...field} onBlur={onBlurCep}>
                    {() => (
                      <TextInput
                        required
                        error={!!errors.endereco?.cep?.message}
                        message={errors.endereco?.cep?.message}
                        label="CEP"
                        placeholder="CEP"
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
                control={control}
                name="endereco.rua"
                render={({ field }) => (
                  <TextInput
                    required
                    error={!!errors.endereco?.rua}
                    message={errors.endereco?.rua?.message}
                    label="Endereço"
                    placeholder="Endereço"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="endereco.complemento"
                render={({ field }) => (
                  <TextInput
                    label="Complemento"
                    placeholder="Complemento"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
                control={control}
                name="endereco.cidade"
                render={({ field: { name, value } }) => (
                  <TextInput
                    label="Cidade"
                    placeholder="Cidade"
                    name={name}
                    value={value}
                    error={!!errors.endereco?.cidade}
                    message={errors.endereco?.cidade?.message}
                    InputProps={{ readOnly: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                name="endereco.estado"
                render={({ field: { name, value } }) => (
                  <TextInput
                    label="Estado"
                    placeholder="UF"
                    name={name}
                    value={value}
                    InputProps={{ readOnly: true }}
                  />
                )}
              />
            </Grid>
            {provider ? (
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  name="contrato.quantidade_motoristas"
                  render={({ field }) => (
                    <TextInput
                      label="Quantidade de motoristas"
                      placeholder="Quantidade de motoristas"
                      {...field}
                    />
                  )}
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  name="contrato.id_da_faixa"
                  render={({ field: { onChange, name, ref, value } }) => (
                    <Select
                      required
                      ref={ref}
                      name={name}
                      value={value}
                      handleChange={e => {
                        const faixa = faixas.find(
                          faixa => faixa.value === e.target.value,
                        );
                        setValue(
                          'contrato.quantidade_motoristas',
                          `${faixa.min}-${faixa.max}`,
                        );
                        onChange(e);
                      }}
                      error={!!errors.contrato?.id_da_faixa}
                      data={faixas}
                      label="Selecione a quantidade de motoristas"
                    />
                  )}
                />
                <p
                  style={{
                    color: theme.palette.semantics.feedback.attention.dark,
                  }}
                >
                  {errors.contrato?.id_da_faixa_do_usuario &&
                    errors.contrato?.id_da_faixa_do_usuario?.message}
                </p>
              </Grid>
            )}

            {plan.id === ID_TELEMETRIA && renderSelectTelemetria()}

            {plan.id === ID_VIDEO && provider && renderSelectMonitoramento()}

            {plan.features.includes('fadiga') && (
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  sx={{
                    marginLeft: '0rem',
                  }}
                  control={
                    <Controller
                      name="contrato.plugins"
                      control={control}
                      render={({ field: { value = [], onChange } }) => (
                        <Switch
                          check={value.includes(ID_FADIGA)}
                          setCheckbox={() =>
                            onChange(
                              value.includes(ID_FADIGA)
                                ? value.filter(item => item !== ID_FADIGA)
                                : [...value, ID_FADIGA],
                            )
                          }
                        />
                      )}
                    />
                  }
                  label={
                    <p>
                      Tenho interesse em adicionar Parametrização de desvios de
                      fadiga.
                    </p>
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <FormControlLabel
                sx={{
                  marginLeft: '0rem',
                }}
                control={
                  <Controller
                    name="contrato.plugins"
                    control={control}
                    render={({ field: { value = [], onChange } }) => (
                      <Switch
                        check={value.includes(ID_ROTOGRAMA)}
                        setCheckbox={() =>
                          onChange(
                            value.includes(ID_ROTOGRAMA)
                              ? value.filter(item => item !== ID_ROTOGRAMA)
                              : [...value, ID_ROTOGRAMA],
                          )
                        }
                      />
                    )}
                  />
                }
                label={<p>Tenho interesse em adicionar Rotograma.</p>}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                sx={{
                  marginLeft: '0rem',
                  marginBottom: '1rem',
                }}
                control={
                  <Controller
                    name="contrato.plugins"
                    control={control}
                    render={({ field: { value = [], onChange } }) => (
                      <Switch
                        check={value.includes(ID_LAYOUT)}
                        setCheckbox={() =>
                          onChange(
                            value.includes(ID_LAYOUT)
                              ? value.filter(item => item !== ID_LAYOUT)
                              : [...value, ID_LAYOUT],
                          )
                        }
                      />
                    )}
                  />
                }
                label={<p>Tenho interesse em adicionar Layout de descarga.</p>}
              />
            </Grid>
          </Grid>
          {proximaEtapa ? (
            <Button width="100%" onClick={() => setTab(2)}>
              PRÓXIMA ETAPA
            </Button>
          ) : (
            <>
              <FormControlLabel
                sx={{
                  marginBottom: '1rem',
                }}
                control={
                  <Controller
                    name="empresa.aceite_termos"
                    control={control}
                    render={({ field: { value = false, onChange } }) => (
                      <Checkbox
                        checked={value}
                        onChange={e => {
                          onChange(e.target.checked);
                          setAcceptTerms(e.target.checked);
                        }}
                        style={{
                          color: value
                            ? theme.palette.brand.secondary.natural
                            : theme.palette.semantics.feedback.unknown.natural,
                        }}
                        disableRipple
                      />
                    )}
                  />
                }
                label={
                  <p>
                    Eu estou de acordo com os{' '}
                    <a href="#">Termos e Condições da Onisys</a>.
                  </p>
                }
              />
              <Button
                width="100%"
                type="submit"
                disabled={!acceptTerms}
                loading={loading}
              >
                Solicitar demonstração
              </Button>
            </>
          )}
        </FormTab>

        <FormTab show={tab === 2}>
          <Grid container spacing={2}>
            {(plan.id === ID_AVANCADO || provider) && (
              <>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel>
                      <p>Possui sistema de telemetria?*</p>
                    </FormLabel>
                    <Controller
                      control={control}
                      name="monitoramento.possui_telemetria"
                      render={({ field }) => (
                        <RadioGroup
                          row
                          options={[
                            { value: true, label: 'Sim' },
                            { value: false, label: 'Não' },
                          ]}
                          {...field}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                {renderSelectTelemetria()}
              </>
            )}
            <Grid item xs={12} md={12}>
              <FormControl>
                <FormLabel>
                  <p>Possui sistema de monitoramento?*</p>
                </FormLabel>
                <Controller
                  control={control}
                  name="monitoramento.possui_sistema_monitoramento"
                  render={({ field }) => (
                    <RadioGroup
                      row
                      options={[
                        { value: true, label: 'Sim' },
                        { value: false, label: 'Não' },
                      ]}
                      {...field}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {renderSelectMonitoramento()}

            {!provider && (
              <>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel>
                      <p>
                        O sistema de monitoramento possui visualização em tempo
                        real?*
                      </p>
                    </FormLabel>
                    <Controller
                      control={control}
                      name="monitoramento.monitoramento_em_tempo_real"
                      render={({ field }) => (
                        <RadioGroup
                          row
                          options={[
                            { value: true, label: 'Sim' },
                            { value: false, label: 'Não' },
                          ]}
                          {...field}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel>
                      <p>O sistema de monitoramento possui download remoto?*</p>
                    </FormLabel>
                    <Controller
                      control={control}
                      name="monitoramento.monitoramento_download_remoto"
                      render={({ field }) => (
                        <RadioGroup
                          row
                          options={[
                            { value: true, label: 'Sim' },
                            { value: false, label: 'Não' },
                          ]}
                          {...field}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    control={control}
                    name="monitoramento.franquia_pacote_de_dados_por_veiculo"
                    render={({ field }) => (
                      <TextInput
                        noAsterisk
                        size="small"
                        label="De quanto é a franquia de dados contratada por veículo?*"
                        placeholder="Valor da franquia por veículo"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel>
                      <p>
                        Possui um servidor para armazenar as imagens baixadas
                        remotamente?*
                      </p>
                    </FormLabel>
                    <Controller
                      control={control}
                      name="monitoramento.armazenamento_por_servidor_remoto"
                      render={({ field }) => (
                        <RadioGroup
                          row
                          options={[
                            { value: true, label: 'Sim' },
                            { value: false, label: 'Não' },
                          ]}
                          {...field}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel>
                      <p>
                        O sistema de monitoramento possui descarga automática
                        via Wi-fi na garagem?*
                      </p>
                    </FormLabel>
                    <Controller
                      control={control}
                      name="monitoramento.upload_por_wifi_garagem"
                      render={({ field }) => (
                        <RadioGroup
                          row
                          options={[
                            { value: true, label: 'Sim' },
                            { value: false, label: 'Não' },
                          ]}
                          {...field}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel>
                      <p>
                        É feita a descarga dos cartões de memórias/HD para um
                        servidor/HD externo?
                      </p>
                    </FormLabel>
                    <Controller
                      control={control}
                      name="monitoramento.upload_por_descarga_manual"
                      render={({ field }) => (
                        <RadioGroup
                          row
                          options={[
                            { value: true, label: 'Sim' },
                            { value: false, label: 'Não' },
                          ]}
                          {...field}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
          <FormControlLabel
            sx={{
              marginBottom: '1rem',
            }}
            control={
              <Controller
                name="empresa.aceite_termos"
                control={control}
                render={({ field: { value = false, onChange } }) => (
                  <Checkbox
                    checked={value}
                    onChange={e => {
                      onChange(e.target.checked);
                      setAcceptTerms(e.target.checked);
                    }}
                    style={{
                      color: value
                        ? theme.palette.brand.secondary.natural
                        : theme.palette.semantics.feedback.unknown.natural,
                    }}
                    disableRipple
                  />
                )}
              />
            }
            label={
              <p>
                Eu estou de acordo com os{' '}
                <a href="#">Termos e Condições da Onisys</a>.
              </p>
            }
          />
          {proximaEtapa && (
            <Button
              width="100%"
              type="submit"
              disabled={!acceptTerms}
              loading={loading}
            >
              Solicitar demonstração
            </Button>
          )}
        </FormTab>
      </form>
    </FormCard>
  );
};

export default Form;
