/* eslint-disable prettier/prettier */
// Styles

// React
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import Button from 'components/Buttons/Default';
import { useNavigate } from 'react-router-dom';
import { DefaultTable } from 'components/_Table/templates/default';

// Components MUI
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { trackEvent } from 'utils/mixpanel';
import { useTheme } from 'styled-components';
import EditTurma from './Modal/EditTurma';
import ModalAlunos from './Alunos';
import * as S from './style.js';

// Utils
import { columnsView } from './columns';
import { getTurma, deleteAlunoTurma } from './services';

const ViewTurma = () => {
  const theme = useTheme();
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [openModalAddAluno, setOpenModalAddAluno] = useState(false);
  const [openModalEditAluno, setOpenModalEditAluno] = useState(false);

  const [foto, setFoto] = useState('');
  const [turmaName, setTurmaName] = useState('');
  const [infoGroup, setinfoGroup] = useState({});

  const [selectedRow, setSelectedRow] = useState('');
  const [data, setData] = useState([]);

  let isConvidado = false;
  if (+user.nivel === 4) isConvidado = true;

  const handleConvidado = () => {
    toast.error('Você não tem permissão para executar essa ação!');
  };

  const fetchTurma = () =>
    getTurma(id).then(res => {
      setTurmaName(res.data.data.nome);
      setFoto(res.data.data.foto);
      setinfoGroup({
        id: res.data.data.id,
        nome: res.data.data.nome,
        foto: res.data.data.foto,
        id_relacao_turma: res.data.data.id_relacao_turma,
        status: res.data.data.ativa,
        para_motoristas: res.data.data.para_motoristas,
        para_usuarios: res.data.data.para_usuarios,
      });
      setData(res.data.data.alunos);
      setLoading(false);
    });

  useEffect(() => {
    setLoading(true);
    fetchTurma();
    trackEvent(user, 'Visualização Turma Capacitação');
  }, []);

  const getAcron = str => {
    const matches = str.match(/\b(\w)/g);
    const acronym = matches.join('');
    return acronym.toUpperCase();
  };

  const formatTable = arr => {
    arr.forEach(i => {
      if (i.id === 'id') {
        i.Cell = function ({ row }) {
          return <S.ColDriver>{row.original.id}</S.ColDriver>;
        };
      } else if (i.id === 'nome') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <div className="container-img-name">
                <Avatar
                  alt={getAcron(row.original.nome)}
                  src={row.original.foto}
                  sx={{ marginRight: 1 }}
                />
                <S.Paragraph
                  fontweight="bold"
                  color={theme.palette.brand.primary.natural}
                >
                  {row.original.nome}
                </S.Paragraph>
              </div>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'tipo') {
        i.Cell = function ({ row }) {
          return <S.ColDriver>{row.original.tipo}</S.ColDriver>;
        };
      } else if (i.id === 'funcao') {
        i.Cell = function ({ row }) {
          return <S.ColDriver>{row.original.funcao}</S.ColDriver>;
        };
      } else if (i.id === 'filial') {
        i.Cell = function ({ row }) {
          return <S.ColDriver>{row.original?.filial?.nome}</S.ColDriver>;
        };
      }
    });
    return arr;
  };
  const bulkActions = [
    {
      title: 'Excluir',
      function: () => handleExcluir(),
    },
  ];

  const actions = [
    {
      function: id => {
        const tipo = data.find(
          item => item.tipo === 'tipo' || item.tipo === 'motorista',
        );
        navigate(
          `/capacitacao/perfil-do-aluno/info-aluno?id=${id}&tipo=${
            tipo?.tipo === 'motorista' ? 'motorista' : 'gestor'
          }`,
        );
      },
      title: 'Ver perfil',
    },
    {
      function: id => handleExcluir(id),
      title: 'Excluir',
    },
  ];

  const handleExcluir = async (id = null) => {
    const usersToDelete = [];
    data.map(item => {
      if (id === null && selectedRow.includes(item.id)) {
        usersToDelete.push(item.id_relacao_turma);
      } else if (Number(id) === Number(item.id)) {
        usersToDelete.push(item.id_relacao_turma);
      }
    });

    const dataToDelte = {
      id_turma: infoGroup.id,
      ids_alunos: usersToDelete,
    };
    const res = await deleteAlunoTurma(dataToDelte);
    if (res.data.success) {
      toast.success(res.data.message);
      fetchTurma();
    } else {
      toast.error(res.data.message);
    }
  };

  return (
    <S.Container>
      <EditTurma
        open={openModalEditAluno}
        dataEdit={infoGroup}
        handleClose={() => setOpenModalEditAluno(false)}
        fetchData={fetchTurma}
      />
      <ModalAlunos
        openModalAluno={openModalAddAluno}
        id_turma={id}
        tipoUser={
          infoGroup.para_motoristas === false &&
          infoGroup.para_usuarios === false
            ? {
                para_motoristas: 1,
                para_usuarios: 1,
                existentIds: data.map(item => item.id),
              }
            : {
                para_motoristas: infoGroup.para_motoristas ? 1 : 0,
                para_usuarios: infoGroup.para_usuarios ? 1 : 0,
                existentIds: data.map(item => item.id),
              }
        }
        behaviorCloseModal={() => setOpenModalAddAluno(false)}
        fetchData={fetchTurma}
      />
      <S.ButtonContianer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <Avatar src={foto} sx={{ marginRight: 1 }}>
            {turmaName.length > 0 && turmaName[0].toUpperCase()}
          </Avatar>
          <h1 className="titleTurma">{turmaName}</h1>
        </div>
        <div>
          <IconButton
            onClick={() =>
              !isConvidado ? setOpenModalEditAluno(true) : handleConvidado()
            }
          >
            <SettingsOutlinedIcon />
          </IconButton>
          <Button
            style={{ marginLeft: 10 }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() =>
              !isConvidado ? setOpenModalAddAluno(true) : handleConvidado()
            }
          >
            <span style={{ fontSize: 14 }}>Adicionar alunos</span>
          </Button>
        </div>
      </S.ButtonContianer>

      <S.TableContainer>
        <DefaultTable
          data={data || []}
          columns={columnsView}
          actions={!isConvidado ? actions : ''}
          searchKeys={['nome', 'tipoUser']}
          setSelectedRows={!isConvidado ? setSelectedRow : false}
          loading={loading}
          bulk={bulkActions}
          placeholder="Buscar aluno"
          searchEvent={search => trackEvent(user, 'Buscar aluno', null, search)}
          empty={{
            title: 'Nenhum aluno encontrada',
            description: '',
          }}
        />
      </S.TableContainer>
    </S.Container>
  );
};

export default ViewTurma;
