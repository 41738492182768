import api from 'services/api';

export const requestOperations = async () => {
  const { data } = await api.get('/select-operations');
  return data.data;
};

export const getCompanies = async () => {
  const res = await api.get('/companies');
  return res;
};
