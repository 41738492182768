import { Divider, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

// Utils
import { formatNewDate, formatNewHour } from 'utils/dates';

import * as S from './styled';

export const ExportOcorrenciaPdf = ({ ocorrencia, aluno, printRef }) => {
  const logo = require('images/onisys.png');
  const capa = require(`images/defaultFormulario.jpg`);
  const { user } = useSelector(state => state.auth.user);
  const companyLogo = user?.filial?.empresa?.logo_url;

  // -------------------------------------------------Retorno de cada gráfico--------------------------------------------------//

  return (
    <S.ModalMUI open={false} onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="formtIdent">
            <div className="logoArea">
              <img src={logo} />
            </div>
          </div>
          <div className="formIdent">
            <span>
              {aluno.tipo === 'motorista' ? 'Motorista: ' : 'Gestor: '}
              {aluno.nome}
            </span>
            <div className="titleForm">Ocorrência ID: {ocorrencia.id}</div>
            <div className="logoCompany">
              <img src={companyLogo} />
            </div>
          </div>
        </S.Infos>
        <S.CardHeader>
          <h1>Informações da Ocorrência</h1>
          <S.Header bg={ocorrencia?.arquivo_evidencia}>
            <div className="topInfos">
              <div className="coverArea">
                <div className="background" />
              </div>
              <div className="textsArea">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className="normalText">Nome </div>
                    <div className="inputBox">
                      <p>{aluno?.nome}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="normalText">Filial</div>
                    <div className="inputBox">
                      <p>{aluno?.filial?.nome}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="normalText">Ocorrência</div>
                    <div className="inputBox">
                      <p>{ocorrencia?.ocorrencia}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="normalText">Criada em</div>
                    <div className="inputBox">
                      <p>{formatNewHour(ocorrencia?.data_evento)}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="normalText">Descrição da ocorrência</div>
                    <div className="inputBox">
                      <p>{ocorrencia?.legenda_evidencia || '-'}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="normalText">Status</div>
                    <div className="inputBox">
                      <p>{ocorrencia?.status}</p>
                    </div>
                  </Grid>
                  {ocorrencia.data_encerramento && (
                    <Grid item xs={12}>
                      <div className="normalText">Finalizada em</div>
                      <div className="inputBox">
                        <p>{formatNewHour(ocorrencia?.data_encerramento)}</p>
                      </div>
                    </Grid>
                  )}
                  {ocorrencia.data_exclusao && (
                    <Grid item xs={12}>
                      <div className="normalText">Excluída em</div>
                      <div className="inputBox">
                        <p>{formatNewHour(ocorrencia?.data_exclusao)} </p>
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <div className="normalText">Plano de ação</div>
                    <div className="inputBox">
                      <p>{ocorrencia?.plano_de_acao || '-'}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="normalText">Observações</div>
                    <div className="inputBox">
                      <p>{ocorrencia?.observacao_responsavel || '-'} </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </S.Header>
        </S.CardHeader>

        <S.CardHeader style={{ marginTop: '30px' }}>
          <h1>Assinaturas</h1>
          <S.Header>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <S.Assinatura>
                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <S.AsignTitle>
                      {ocorrencia.responsavel ? (
                        ocorrencia.responsavel.nome.toLowerCase()
                      ) : (
                        <S.NoContent>Aguardando Assinatura</S.NoContent>
                      )}
                    </S.AsignTitle>
                    <S.AsignDate>
                      {ocorrencia?.data_evento &&
                        ` -  Criada em ${formatNewDate(
                          ocorrencia?.data_evento,
                        )}`}
                    </S.AsignDate>
                  </div>
                  <hr />
                  <h4>Responsavel</h4>
                </S.Assinatura>
              </Grid>
              <Grid item xs={12}>
                <S.Assinatura>
                  <div style={{ display: 'flex' }}>
                    <S.AsignTitle>{aluno.nome.toLowerCase()}</S.AsignTitle>
                    <S.AsignDate>
                      {ocorrencia?.data_encerramento &&
                        ` - Finalizada em ${formatNewDate(
                          ocorrencia?.data_encerramento,
                        )}`}
                    </S.AsignDate>
                  </div>
                  <hr />
                  <h4>{aluno.tipo === 'motorista' ? 'Motorista' : 'Gestor'}</h4>
                </S.Assinatura>
              </Grid>
            </Grid>
          </S.Header>
        </S.CardHeader>
      </S.Container>
    </S.ModalMUI>
  );
};
