import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as S from './styled';
import * as api from './services';
import { useTheme } from 'styled-components';
import { DefaultTable } from 'components/_Table/templates/default';
import { trackEvent } from 'utils/mixpanel';
import { columns } from './columns';
import { useQuery } from 'react-query';
import ModalEditar from './ModalEditar';

export const Empresas = () => {
  const theme = useTheme();
  const user = useSelector(state => state.auth?.user?.user);

  const [openModal, setOpenModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  const { isLoading: loading, data } = useQuery(
    ['empresas-provider'],
    () => api.getEmpresas(),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {},
    },
  );

  // açoes
  const actions = [
    {
      title: 'Editar',
      function: id => {
        const tmp = data?.rows.find(item => item.id === id);
        setCurrentData(tmp);
        setOpenModal(true);
      },
    },
  ];

  const formatCol = () => {
    return data?.rows?.map(item => ({
      ...item,
      status: item?.empresa_distribuidoras[0]?.ativo,
    }));
  };

  return (
    <S.Container>
      <DefaultTable
        data={formatCol() || []}
        columns={columns}
        actions={actions}
        searchKeys={['nome']}
        loading={loading}
        placeholder="Buscar Nome"
        sortBy={{ order: 'ASC' }}
        searchEvent={search =>
          trackEvent(user, 'Empresa embarcador', null, search)
        }
        empty={{
          title: 'Nenhuma empresa encontrada',
          description: '',
        }}
      />
      <ModalEditar
        open={openModal}
        handleClose={() => setOpenModal(false)}
        dataEdit={currentData}
      />
    </S.Container>
  );
};
