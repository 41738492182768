import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SaveAlt, AddCircleOutline } from '@mui/icons-material';

import ExcelModal from 'components/ExcelModal';
import Button from 'components/Buttons/Default';
import ButtonGhost from 'components/Buttons/Ghost';
import { FilterTable } from 'components/_Table/templates/filter';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import * as S from './styled';
import * as services from './services';
import { fields, resetExcelFields } from './constants';
import AcoesSuspensaoModal from './AcoesSuspensaoModal';

const ConfigAcoesSuspensao = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  // EXCEL STATES
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [openExcelModal, setOpenExcelModal] = useState(false);

  const { user } = useSelector(state => state.auth);
  const isProvider = user?.user?.provider;

  const [acoesSuspensaoModal, setAcoesSuspensaoModal] = useState({
    open: false,
    data: null,
  });

  // FEFTCH DATA
  const {
    data: acoes = [],
    isFetched,
    refetch,
  } = useQuery(['acoes-suspensao'], () => services.getAcoes(), {
    refetchOnWindowFocus: false,
  });

  const { data: sugestoes = [], refetch: getSugestoes } = useQuery(
    ['acoes-sugestoes'],
    () => services.getSugestoesAcoes(),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (isFetched && !acoes?.length) getSugestoes();
    if (acoes?.length) setData(acoes);
  }, [isFetched, acoes]);

  useEffect(() => {
    if (sugestoes?.length) setData(sugestoes);
  }, [sugestoes]);

  // Excel Fields
  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = {
      excelFields: newFields,
    };

    const res = await services.requestExcel(newQuery, isProvider);
    if (res.data && res.data?.data?.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `acoes_suspensao${formatedDate}`,
      });
    else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // HANDLERS
  const handleAtivar = async (id, row) => {
    let registro = null;
    if (row) {
      registro = row;
    } else {
      registro = data.find(item => String(item.id) === String(id));
    }

    if (registro.ativo) return toast.success('Esse registro já está ativo');

    registro.ativo = true;

    // Se ativar sugestao, deve editar imediatamente!
    if (registro.is_sugestao) {
      handleEditar(registro.id, true);
    } else {
      const res = await services.activateAcao(registro.id);
      if (res.success) {
        refetch();
        toast.success(res.message);
      } else toast.error(res.message);
    }
  };

  const handleDesativar = async (id, row) => {
    let registro = null;
    if (row) {
      registro = row;
    } else {
      registro = data.find(item => String(item.id) === String(id));
    }

    if (!registro.ativo) return toast.success('Esse registro já está inativo');

    registro.ativo = false;

    const res = await services.desactivateAcao(registro.id);
    if (res.success) {
      refetch();
      toast.success(res.message);
    } else toast.error(res.message);
  };

  const handleEditar = (id, is_sugestao) => {
    const sortData = data.sort((a, b) => b.limiar - a.limiar);
    const registro = sortData.find(item => String(item.id) === String(id));
    if (registro) {
      const anterorAtivo = sortData.find(
        item => item.ativo && item.limiar < registro.limiar,
      );
      const min = anterorAtivo?.limiar ?? 0;

      setAcoesSuspensaoModal({
        open: true,
        data: {
          ...registro,
          ativo: is_sugestao || registro.ativo,
          min,
        },
      });
    } else toast.error('Não foi possível encontrar esse registro');
  };

  const handleAdicionar = () => {
    const sortData = data
      .filter(a => a.ativo)
      .sort((a, b) => a.limiar - b.limiar);
    let min = 0;
    if (sortData.length > 0) min = sortData[sortData.length - 1].limiar;

    setAcoesSuspensaoModal({ open: true, data: { min } });
  };

  // TABLE CONFIGS
  const actions = [
    {
      title: 'Ativar',
      function: handleAtivar,
    },
    {
      title: 'Desativar',
      function: handleDesativar,
    },
    {
      title: 'Editar',
      function: handleEditar,
    },
    {
      title: 'Ver Histórico',
      function: id =>
        navigate(`/logs/telemetria/configuracoes/acoes-suspensao/${id}`),
    },
  ];

  const columns = [
    {
      header: 'Status',
      id: 'ativo',
      type: 'boolean',
      sort: true,
      switch: {
        value: (_, item) => item.ativo,
        onChange: (value, item) =>
          value ? handleAtivar(null, item) : handleDesativar(null, item),
      },
      width: 105,
    },
    {
      header: 'Título',
      id: 'titulo',
      type: 'string',
      sort: true,
      width: 250,
      tooltip: true,
      conditional: [
        {
          condition: _ => true,
          style: (theme, item) => ({
            color: item.cor,
            textTransform: 'uppercase',
          }),
        },
      ],
    },
    {
      header: 'Pontuação inicial',
      id: 'limiar',
      type: 'number',
      sort: true,
      align: 'center',
    },
    {
      header: 'Pontuação final',
      id: 'valor_maximo',
      type: 'number',
      sort: true,
      align: 'center',
    },
    {
      header: 'Ação disciplinar',
      id: 'acao.nome',
      type: 'string',
    },
    {
      header: 'Capacitação',
      id: 'capacitacao',
      type: 'string',
      tooltip: true,
      value: (_, item) =>
        String(
          item.acao?.is_reincidencia && item.reincidencias?.length
            ? item.reincidencias?.[item.reincidencias.length - 1].capacitacao
                ?.titulo ?? ''
            : item.capacitacao?.titulo ?? '',
        ),
    },
  ];

  const sortBy = {
    id: 'limiar',
    order: 'DESC',
  };
  const headerActions = (
    <div style={{ display: 'flex', gap: 10 }}>
      <ButtonGhost
        startIcon={<SaveAlt />}
        onClick={() => setOpenExcelModal(true)}
      >
        EXPORTAR
      </ButtonGhost>
      <Button startIcon={<AddCircleOutline />} onClick={handleAdicionar}>
        ADICIONAR AÇÃO
      </Button>
    </div>
  );

  return (
    <>
      <FilterTable
        local
        data={data}
        columns={columns}
        sortBy={sortBy}
        searchKeys={['titulo', 'limiar']}
        placeholder="Buscar Ação"
        actions={actions}
        empty={{
          title: 'Ainda não há ações ou suspensões cadastrados.',
          description: 'Cadastre os registros.',
          image: 'motorista.png',
        }}
        headerActions={headerActions}
      />
      <AcoesSuspensaoModal
        acoes={data}
        open={acoesSuspensaoModal.open}
        registro={acoesSuspensaoModal.data}
        handleClose={() => setAcoesSuspensaoModal({ open: false, data: null })}
        refetch={refetch}
      />
      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}
    </>
  );
};

export default ConfigAcoesSuspensao;
