import React from 'react';

import { Grid } from '@mui/material';
import TextInput from 'components/Inputs/TextField';
import MapDesvio from 'pages/Desvios/components/MapDesvio';

import { formatNewHour } from 'utils/dates';
import { usePlans } from 'hooks/usePlans';
import * as S from '../styled';
import { colorsGravidade } from 'pages/Multas/constants';

const Info = ({ multa }) => {
  const { isOpLogistico, isProvider } = usePlans();
  const hasImage = multa?.foto_motorista ?? false;

  return (
    <S.ColumnWrapper>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <MapDesvio
            position={
              multa?.latitude
                ? {
                    lat: parseFloat(multa.latitude),
                    lng: parseFloat(multa.longitude),
                  }
                : null
            }
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <S.DriverContainer>
            <div className="info">
              <S.StyledLogoWrapper>
                <S.StyledLogo backgroundImage={hasImage}>
                  {!hasImage && (
                    <h2>
                      {!isProvider || isOpLogistico
                        ? multa.motorista?.nome[0]
                        : multa.empresa?.nome[0]}
                    </h2>
                  )}
                </S.StyledLogo>
              </S.StyledLogoWrapper>

              <S.StyledTextWrapper>
                <h1>
                  {!isProvider || isOpLogistico
                    ? multa.motorista?.nome.toLowerCase()
                    : multa.empresa?.nome.toLowerCase()}
                </h1>
                {!isProvider && <p>Filial: {multa.motorista?.filial?.nome}</p>}
              </S.StyledTextWrapper>
            </div>
            <div className="card">
              Gravidade:{' '}
              <div
                style={{
                  padding: '8px',
                  borderRadius: '4px',
                  fontWeight: 900,
                  color: colorsGravidade[multa?.multa_tipo?.gravidade],
                  background: `${
                    colorsGravidade[multa?.multa_tipo?.gravidade]
                  }20`,
                }}
              >
                {multa?.multa_tipo?.gravidade}
              </div>
            </div>
          </S.DriverContainer>
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextInput
            id="multa_tipo"
            label="Tipo de infração"
            value={multa.multa_tipo?.titulo ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={5}>
          <TextInput
            id="valor"
            label="Valor da multa"
            value={`R$ ${multa.multa_tipo?.valor.toFixed(2)}`}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextInput
            id="pontos"
            label="Pontos"
            value={multa.multa_tipo?.pontos}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={5}>
          <TextInput
            id="data_multa"
            label="Data da infração"
            value={formatNewHour(multa.data_multa)}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            id="agregado"
            label="Agregado"
            value={multa.motorista?.agregado ? 'Sim' : 'Não'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            id="placa"
            label="Placa"
            value={multa.caminhao?.placa ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextInput
            id="localizacao"
            label="Localização"
            value={
              multa.endereco
                ? `${multa.endereco}${multa.km ? `, Km ${multa.km}` : ''}${
                    multa.cep ? `, CEP: ${multa.cep}` : ''
                  }`
                : '-'
            }
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            id="latitude"
            label="Latitude"
            value={multa.latitude ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            id="longitude"
            label="Longitude"
            value={multa.longitude ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>
      </Grid>
    </S.ColumnWrapper>
  );
};

export default Info;
