import React, { useEffect, useState, useMemo } from 'react';

import { Container } from './styled';

// icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// componentes
import { GraphBar } from './components/GraphBar';
import PieGraph from './components/PieGraph';
import Tabs from 'components/Tabs';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import GraficoBarraVertical from './components/GraficoBarraVertical';
import { GraficoHitorico } from './components/GraficoHistorico';
import {
  getHistoricoTratativas,
  getJustified,
  getEquipe,
  getMovimentacoes,
} from './services';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import DateRangePicker from 'components/DateRangePicker';
import { useNavigate } from 'react-router-dom';
import { usePlans } from 'hooks/usePlans';
import Divider from '@mui/material/Divider';
import TextField from 'components/Inputs/TextField';
import SearchIcon from '@mui/icons-material/Search';

const StyledBadge = styled(Badge)(
  ({ theme, badgeColor, badgeBackgroundColor }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: badgeBackgroundColor,
      color: badgeColor,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
);

const DesempenhoEquipe = () => {
  const { isProvider, hasTorre } = usePlans();

  const checkPlanTorre = isProvider ? true : hasTorre;

  const navigate = useNavigate();
  const [filterDate, setFilterDate] = useState({
    initialDate: new Date(),
    finalDate: new Date(),
  });

  const [currentTab, setCurrentTab] = useState('CRITICIDADE');
  const [historyGraph, setHistoryGraph] = useState([]);
  const [dateHistory, setDateHistory] = useState(null);
  const [historyFilterType, setHistoryFiterType] = useState('EXCLUSAO');
  const [graphJustied, setGraphJustied] = useState(['TRATADOSNOPRAZO']);
  const [graphMovimentacao, setGraphMovimentacao] = useState([]);
  const [graphMoviPie, setGraphMovPie] = useState([]);

  const [loadingCard, setLoadingCard] = useState(false);
  const [loadingMovimentacoes, setLoadingMovimentacoes] = useState(false);
  const [loadingjustify, setLoadingJustify] = useState(false);
  const [cardOne, setCardOne] = useState([]);
  const [cardTwo, setCardTwo] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [allCards, setAllCards] = useState([]);

  function splitArray(array) {
    const len = array.length;

    const mid = Math.ceil(len / 2);

    const firstHalf = array.slice(0, mid);
    const secondHalf = array.slice(mid);

    return [firstHalf, secondHalf];
  }
  function transformData(rawData) {
    return rawData.map(companyData => {
      const companyName = Object.keys(companyData)[0];
      const companyValues = companyData[companyName];

      return {
        name: companyName,
        'Novas solicitações de exclusão':
          companyValues['Novas solicitações de exclusão'],
        Aprovadas: companyValues.Aprovadas,
        Rejeitadas: companyValues.Rejeitadas,
        'Em reavaliação': companyValues['Em reavaliação'],
        Vencidas: companyValues.Vencidas,
      };
    });
  }
  function transformDataAndCombine(rawData) {
    const combinedData = {};

    rawData.forEach(companyData => {
      const companyName = Object.keys(companyData)[0];
      const companyValues = companyData[companyName];

      const categories = [
        {
          name: 'Novas solicitações de exclusão',
          value: companyValues['Novas solicitações de exclusão'],
          absoluteValue: companyValues['Novas solicitações de exclusão'],
          color: '#4B5166',
        },
        {
          name: 'Aprovadas',
          value: companyValues.Aprovadas,
          absoluteValue: companyValues.Aprovadas,
          color: '#BDCC30',
        },
        {
          name: 'Rejeitadas',
          value: companyValues.Rejeitadas,
          absoluteValue: companyValues.Rejeitadas,
          color: '#FFA801',
        },
        {
          name: 'Em reavaliação',
          value: companyValues['Em reavaliação'],
          absoluteValue: companyValues['Em reavaliação'],
          color: '#0C12F2',
        },
        {
          name: 'Vencidas',
          value: companyValues.Vencidas,
          absoluteValue: companyValues.Vencidas,
          color: '#F64E60',
        },
      ];

      categories.forEach(category => {
        if (!combinedData[category.name]) {
          combinedData[category.name] = {
            name: category.name,
            value: 0,
            absoluteValue: 0,
            color: category.color,
          };
        }
        combinedData[category.name].value += category.value;
        combinedData[category.name].absoluteValue += category.absoluteValue;
      });
    });

    return Object.values(combinedData);
  }

  const formatDate = isoDate => {
    if (isoDate) {
      const timeZone = 'America/Sao_Paulo';
      const zonedDate = utcToZonedTime(isoDate, timeZone);
      const formattedDate = format(zonedDate, 'HH:mm');
      return `Ontem às ${formattedDate}`;
    }
    return ' - ';
  };

  const fetchHistory = async () => {
    const res = await getHistoricoTratativas({
      ...dateHistory,
      type: historyFilterType,
    });
    setHistoryGraph(res?.data?.values || []);
  };

  const fetchJustified = async () => {
    setLoadingJustify(true);
    const res = await getJustified();
    setGraphJustied(res?.data || []);
    setLoadingJustify(false);
  };

  const fetchEquipe = async () => {
    setLoadingCard(true);
    const res = await getEquipe();
    const [firstHalf, secondHalf] = splitArray(res?.data || []);
    setCardOne(firstHalf);
    setCardTwo(secondHalf);
    setAllCards(res?.data || []);
    setLoadingCard(false);
  };

  const filterCards = term => {
    const filteredData = allCards.filter(card =>
      card.nome.toLowerCase().includes(term.toLowerCase()),
    );

    const [firstHalf, secondHalf] = splitArray(filteredData);
    setCardOne(firstHalf);
    setCardTwo(secondHalf);
  };

  const fetchMovimentacoes = async () => {
    setLoadingMovimentacoes(true);
    const res = await getMovimentacoes({ groupBy: currentTab, ...filterDate });

    setGraphMovimentacao(transformData(res?.data || []));

    setGraphMovPie(transformDataAndCombine(res?.data || []));
    setLoadingMovimentacoes(false);
  };

  useEffect(() => {
    fetchHistory();
  }, [dateHistory, historyFilterType]);

  useEffect(() => {
    fetchMovimentacoes();
  }, [currentTab, filterDate]);

  useEffect(() => {
    fetchHistory();
    fetchJustified();
    fetchEquipe();
    fetchMovimentacoes();
  }, []);

  useEffect(() => {
    filterCards(searchTerm);
  }, [searchTerm, allCards]);

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleNavigate = url => navigate(url);

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="title-main">Estatísticas da equipe</div>
        <div>
          <DateRangePicker
            onDate={date => setFilterDate(date)}
            dInt={filterDate.initialDate}
            dfim={filterDate.finalDate}
          />
        </div>
      </div>
      <Divider sx={{ marginBottom: 3, marginRight: 23 }} />
      <div className="container-header ">
        <div className="first-graph">
          <GraficoBarraVertical
            data={graphJustied}
            dataKey1="motivo_exclusao"
            title="Justificativas de exclusão"
            isLoading={loadingjustify}
          />
        </div>
        <div className="second-graph">
          <div className="top">
            <div>Movimentações no período - Revisões de exclusão</div>
          </div>
          <div className="middle">
            <PieGraph data={graphMoviPie} loading={loadingMovimentacoes} />
            <div
              style={{
                width: '80%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 60,
                  paddingBottom: 5,
                }}
              >
                <Tabs
                  onChange={(e, val) => setCurrentTab(val)}
                  value={currentTab}
                  items={[
                    {
                      label: 'CRITICIDADE',
                      value: 'CRITICIDADE',
                    },
                    {
                      label: 'DESVIOS',
                      value: 'DESVIOS',
                    },
                    {
                      label: 'EMPRESAS',
                      value: 'EMPRESAS',
                    },
                  ]}
                />
              </div>
              {graphMovimentacao.length ? (
                <GraphBar
                  data={graphMovimentacao}
                  loading={loadingMovimentacoes}
                />
              ) : (
                <div
                  style={{
                    color: 'rgb(101, 110, 140)',
                    fontSize: 18,
                  }}
                >
                  Não existe dados para serem exibidos, tente usar os filtros
                </div>
              )}
            </div>
          </div>
          <div className="footer">
            {[
              {
                text: 'Novas solicitações de exclusão',
                color: '#4B5166',
              },
              {
                text: 'Movidas para reavaliação',
                color: '#0C12F2',
              },
              {
                text: 'Aprovadas',
                color: '#BDCC30',
              },
              {
                text: 'Rejeitadas',
                color: '#FFA801',
              },
              {
                text: 'Vencidas',
                color: '#F64E60',
              },
            ].map(item => (
              <>
                <div
                  className="point"
                  style={{ backgroundColor: item.color }}
                />
                <div className="text">{item?.text || ''}</div>
              </>
            ))}
          </div>
        </div>
      </div>

      <div className="card-bottom">
        <GraficoHitorico
          data={historyGraph}
          title="Tempo médio de revisões"
          handleDate={date => setDateHistory(date)}
          changeFilter={e => setHistoryFiterType(e)}
          filter={historyFilterType}
        />
      </div>

      <div className="title-equipe">
        <div>Minha equipe</div>
        <div>
          <TextField
            startIcon={<SearchIcon sx={{ color: '#95AAC9' }} />}
            placeholder="Buscar"
            value={searchTerm}
            style={{ width: 350, background: 'transparent' }}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      {cardOne.length > 0 ? (
        <>
          {checkPlanTorre && (
            <div className="content">
              {!loadingCard
                ? cardOne.map((card, index) => (
                    <div>
                      <div
                        className="card"
                        key={`${index}-one`}
                        onClick={() => handleNavigate(`/equipe/${card?.id}`)}
                      >
                        <div className="card-content">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                              flexDirection: 'row',
                            }}
                          >
                            <Stack direction="row" spacing={2}>
                              <StyledBadge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                badgeColor={
                                  card?.status === 'ATIVO'
                                    ? '#1BC5BD'
                                    : '#F64E60'
                                }
                                badgeBackgroundColor={
                                  card?.status === 'ATIVO'
                                    ? '#1BC5BD'
                                    : '#F64E60'
                                }
                                variant="dot"
                              >
                                <Avatar
                                  alt={cardTwo[index]?.nome}
                                  src={cardTwo[index]?.foto}
                                  sx={{
                                    width: 47,
                                    height: 47,
                                    boxShadow: '0px 3px 6px #00000029',
                                    backgroundColor: '#95AAC9',
                                  }}
                                />
                              </StyledBadge>
                            </Stack>
                            <div className="name">{card.nome}</div>
                          </div>
                          <div>
                            <ArrowForwardIosIcon sx={{ color: '#4B5166' }} />
                          </div>
                        </div>
                        <div className="footer">
                          <div className="first-title">
                            Tempo médio de revisão:{' '}
                            <span style={{ color: '#4B5166', fontWeight: 900 }}>
                              {card?.tempo_medio
                                ? `${card?.tempo_medio} minutos`
                                : ' - '}
                            </span>
                          </div>
                          <div className="secound-title">
                            Última revisão:{' '}
                            <span style={{ color: '#95AAC9', fontWeight: 900 }}>
                              {formatDate(card?.ultima_tratativa)}
                            </span>
                          </div>
                        </div>
                      </div>
                      {cardTwo[index]?.nome && (
                        <div
                          className="card"
                          key={`${index}-two`}
                          onClick={() =>
                            handleNavigate(`/equipe/${cardTwo[index]?.id}`)
                          }
                        >
                          <div className="card-content">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                              }}
                            >
                              <Stack direction="row" spacing={2}>
                                <StyledBadge
                                  badgeColor={
                                    cardTwo[index]?.status === 'ATIVO'
                                      ? '#1BC5BD'
                                      : '#F64E60'
                                  }
                                  badgeBackgroundColor={
                                    cardTwo[index]?.status === 'ATIVO'
                                      ? '#1BC5BD'
                                      : '#F64E60'
                                  }
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  variant="dot"
                                >
                                  <Avatar
                                    alt={cardTwo[index]?.nome}
                                    src={cardTwo[index]?.foto}
                                    sx={{
                                      width: 47,
                                      height: 47,
                                      boxShadow: '0px 3px 6px #00000029',
                                      backgroundColor: '#95AAC9',
                                    }}
                                  />
                                </StyledBadge>
                              </Stack>
                              <div className="name">
                                {cardTwo[index]?.nome || ' - '}
                              </div>
                            </div>
                            <div>
                              <ArrowForwardIosIcon sx={{ color: '#4B5166' }} />
                            </div>
                          </div>
                          <div className="footer">
                            <div className="first-title">
                              Tempo médio de revisão:{' '}
                              <span
                                style={{ color: '#4B5166', fontWeight: 900 }}
                              >
                                {cardTwo[index]?.tempo_medio
                                  ? `${cardTwo[index]?.tempo_medio} minutos`
                                  : ' - '}
                              </span>
                            </div>
                            <div className="secound-title">
                              Última revisão:{' '}
                              <span
                                style={{ color: '#95AAC9', fontWeight: 900 }}
                              >
                                {formatDate(cardTwo[index]?.ultima_tratativa)}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                : Array.from({ length: 10 }, () =>
                    Math.floor(Math.random() * 100),
                  ).map((_, index) => (
                    <div key={index}>
                      <Skeleton
                        variant="rounded"
                        width={302}
                        height={139}
                        style={{ marginLeft: 20 }}
                      />
                      <Skeleton
                        variant="rounded"
                        width={302}
                        height={139}
                        style={{ marginTop: 20, marginLeft: 20 }}
                      />
                    </div>
                  ))}
            </div>
          )}
        </>
      ) : (
        <div className="card-empty">
          Não existe dados para serem exibidos, tente usar os filtros
        </div>
      )}
      <div className="see-more" onClick={() => handleNavigate(`/equipe`)}>
        Ver todos
      </div>
    </Container>
  );
};

export default DesempenhoEquipe;
