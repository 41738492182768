import styled, { css } from 'styled-components';

export const QuestionBox = styled.div`
  ${({ theme, hasError }) => css`
    padding: 20px;
    background-color: ${hasError
      ? theme.palette.semantics.feedback.attention.light
      : theme.palette.system.highlight};
  `}
`;

export const ResponseArea = styled.div`
  ${({ preview }) => css`
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    width: 50%;
    width: ${preview ? '100%' : 'auto'};
    max-width: ${preview ? 'auto' : '300px'};
  `}
`;
