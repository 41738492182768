import React from 'react';

import * as S from './styled';

export const Info = ({ title, text }) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Text>{text}</S.Text>
    </S.Container>
  );
};
