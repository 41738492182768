import React from 'react';
import {
  ComposedChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  Text,
} from 'recharts';
import CheckFiltersCard from 'components/Cards/CheckFiltersCard';
import { useTheme } from 'styled-components';
import * as S from './styled';
import { paletteColors } from '../../../styles/colors';
import Loading from '../../Loading';

const ComposedGraph = ({
  data,
  barSeries = ['value'],
  lineSeries = [],
  barLabel,
  lineLabel,
  barColors,
  lineColors,
  linePostFix,
  lineStrokeDash,
  title,
  subtitle,
  legend,
  loading,
  tooltip,
  titleComponent,
  tabComponent,
  lineYAxis,
  leftYAxisMax,
  rightYAxisMax,
  width,
  maxWidth,
  isAnimationActive,
  onBarClick,
  wrapped = false,
  yWidth = 40,
  tooltipContent = null,
  renderLineLabel = true,
}) => {
  const theme = useTheme();
  const chartColors = paletteColors[barColors] || paletteColors.default;
  const lineChartColors = paletteColors[lineColors] || paletteColors.default;

  const AngledAxisTick = props => {
    const { x, y, payload } = props;

    let label = payload.value;
    if (label.length > 12) label = `${label.slice(0, 10)}...`;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={10}
          y={10}
          dy={5}
          textAnchor="end"
          fill="#666"
          transform="rotate(-30)"
        >
          {label}
        </text>
      </g>
    );
  };

  const WrappedAxisTick = props => {
    const { x, y, payload, width } = props;

    let label = payload.value;
    if (label?.length > 12) label = `${label.slice(0, 10)}...`;

    if (label) {
      return (
        <Text
          width={width}
          x={x}
          y={y}
          textAnchor="middle"
          verticalAnchor="start"
        >
          {payload.value}
        </Text>
      );
    }
    return null;
  };

  const renderCustomizedLabel = props => {
    const { x, y, value, postFix } = props;

    return value && renderLineLabel ? (
      <g>
        <rect x={x - 18} y={y + 8} width={36} height={16} fill="#fff" />
        <text
          x={x}
          y={y + 16}
          fontSize={12}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {`${value}${postFix ?? ''}`}
        </text>
      </g>
    ) : null;
  };

  return (
    <S.StyledCard maxWidth={maxWidth}>
      <S.Header>
        {titleComponent ?? (
          <div>
            <h2>{title}</h2>
            {subtitle && <p>{subtitle}</p>}
          </div>
        )}
        {tabComponent}
      </S.Header>

      {data?.length > 0 || loading ? (
        <S.GraphContainer>
          <ResponsiveContainer
            minWidth={64 * ((data?.length || 1) + 2)}
            width={width || '100%'}
            height="100%"
          >
            {loading ? (
              <S.BoxLoading>
                <Loading />
              </S.BoxLoading>
            ) : (
              <ComposedChart
                data={data}
                margin={{
                  top: 20,
                  right: 20,
                  left: 10,
                  bottom: 15,
                }}
              >
                <CartesianGrid
                  strokeDasharray="1 0"
                  horizontal
                  vertical={false}
                />
                <XAxis
                  dataKey="name" // default
                  axisLine={false}
                  tick={
                    wrapped ? (
                      <WrappedAxisTick width={yWidth} />
                    ) : (
                      <AngledAxisTick />
                    )
                  }
                  height={50}
                  interval={0}
                />
                <YAxis
                  type="number"
                  yAxisId="left"
                  domain={[
                    0,
                    rightYAxisMax ||
                      (dataMax => {
                        Math.ceil(dataMax);
                      }),
                  ]}
                  tickCount={8}
                  allowDecimals
                  axisLine={false}
                  tickFormatter={value => {
                    if (typeof value === 'number') {
                      if (value >= 10000) {
                        return `${(value / 1000).toFixed(0)}K`;
                      }
                      if (value >= 1000) {
                        return `${(value / 1000).toFixed(1)}K`;
                      }
                    }
                    return value;
                  }}
                />
                {lineYAxis && (
                  <YAxis
                    domain={[
                      0,
                      leftYAxisMax || (dataMax => Math.ceil(dataMax)),
                    ]}
                    yAxisId="right"
                    orientation="right"
                    tickCount={6}
                    tickFormatter={value => `${value}${linePostFix}`}
                  />
                )}
                {tooltip && (
                  <Tooltip
                    formatter={value => {
                      if (typeof value === 'string')
                        return `${value}${linePostFix ?? ''}`;
                      return parseFloat(Number(value).toFixed(5));
                    }}
                    cursor={{
                      fill: theme.palette.semantics.feedback.unknown.light,
                      opacity: '0.4',
                    }}
                    content={tooltipContent}
                  />
                )}
                {legend && (
                  <Legend
                    wrapperStyle={{ bottom: 0 }}
                    payload={lineSeries.map((item, idx) => ({
                      id: item,
                      color: lineChartColors[idx % lineChartColors.length],
                      type: 'line',
                      value: lineLabel[idx],
                    }))}
                  />
                )}
                {barSeries.map((item, idx) => (
                  <Bar
                    key={item}
                    yAxisId="left"
                    dataKey={item} // default
                    name={barLabel[idx] ?? item}
                    radius={4}
                    maxBarSize={40}
                    onMouseOver={false}
                    onClick={onBarClick}
                    fill={chartColors[idx % chartColors.length]}
                    label={{
                      position: 'top',
                      enableBackground: 'white',
                      formatter: value => parseFloat(Number(value).toFixed(5)),
                    }}
                  >
                    {barSeries.length === 1 &&
                      data.map((entry, index) => (
                        <Cell
                          key={`${entry[item]}`}
                          opacity={
                            data.every(e => !e.selected) || entry.selected
                              ? 1
                              : 0.4
                          }
                          fill={chartColors[index % chartColors.length]}
                        />
                      ))}
                  </Bar>
                ))}
                {lineSeries.map((item, idx) => (
                  <Line
                    key={item}
                    yAxisId={lineYAxis ? 'right' : 'left'}
                    type="linear" // monotone step
                    name={lineLabel[idx] ?? item}
                    dataKey={item}
                    dot={{ r: 6 }} // mostrar pontos sem hover. Pode receber obj personalizado
                    activeDot={{ r: 8 }} // mostrar pontos com hover. Pode receber obj personalizado
                    stroke={lineChartColors[idx % lineChartColors.length]}
                    strokeWidth={3}
                    strokeDasharray={lineStrokeDash?.[idx] ?? ''}
                    isAnimationActive={isAnimationActive}
                    label={{
                      fill: lineChartColors[idx % lineChartColors.length],
                      formatter: value => parseFloat(Number(value).toFixed(5)),
                      content: props =>
                        renderCustomizedLabel({
                          ...props,
                          postFix: linePostFix,
                        }),
                    }}
                  />
                ))}
              </ComposedChart>
            )}
          </ResponsiveContainer>
        </S.GraphContainer>
      ) : (
        <CheckFiltersCard height="200px" marginTop="45px" />
      )}
    </S.StyledCard>
  );
};

export default ComposedGraph;
