import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)`
  && {
    color: ${props => props.textcolor};
    background: ${props => props.backgroundcolor};
    border: 1px solid ${props => props.textcolor};
    font: normal normal 900 14px/18px Texta;
    height: ${props => props.height || '38px'};
    padding: 5px 15px;
    transition: opacity 300ms;

    :hover {
      background: ${props => props.backgroundcolor};
      opacity: 0.7;
    }
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownContent = styled.div`
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

// Styled component for the dropdown items
export const DropdownItem = styled.div`
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;
