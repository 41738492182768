import React, { useState, useEffect, useRef } from 'react';
// services

// components
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Button from 'components/Buttons/Default';
import ButtonGhost from 'components/Buttons/Ghost';
import IconButton from '@mui/material/IconButton';
import ReactPlayer from 'react-player';
import Radio from '@mui/material/Radio';
import Rating from '@mui/material/Rating';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ModalPopup from 'components/ModalPopUp';
import { toast } from 'react-toastify';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import Divider from '@mui/material/Divider';
import TextField from 'components/Inputs/TextField';

// style
import { useTheme } from 'styled-components';

// icons
import CancelIcon from '@mui/icons-material/Cancel';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';

// images
import logo from 'images/Grupo43.svg';
import * as S from './styled';
import { answerQuestion } from '../CreateContent/service';
import { readingContent, retakeTest, saveEvaluation } from '../Perfil/service';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TakeTest = ({
  open, // define o state se aberto ou não
  handleClose, // controla o fechamento do modal
  currentStep = 0, // seta o current step do conteudo
  isPreview = false, // verificar se o usuario ta contunado o teste
  data = [], // define o conteudo
  retakeTest = false,
}) => {
  const theme = useTheme();
  // info geral
  const [capa, setCapa] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  // conteudo
  const [midias, setMidias] = useState([]);
  const [tags, setTags] = useState([]);
  const [dateEnd, setDateEnd] = useState('');
  const [steps, setSteps] = useState(currentStep);
  const [hideSteps, setHideSteps] = useState(true);
  const [continue_test, setContinue_test] = useState(isPreview);
  const [timeDisabled, setTimeDisabled] = useState(true);
  const [progress, setProgress] = useState(0);
  const [allResponse, setAllresponse] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [confirmComment, setConfirmComent] = useState(false);
  const [like, setLike] = useState(false);
  const [resultTestModal, setResultTestModal] = useState(false);
  const [wasApproved, setWasApproved] = useState(false);
  const [selectLike, setSelectLike] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);

  // avaliacao
  const [assessmentValue, setAssessmentValue] = useState(0);
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (
      !isPreview &&
      !hideSteps &&
      continue_test &&
      midias[steps].tipo !== 'VÍDEO'
    ) {
      setTimeout(() => setTimeDisabled(false), 10000);
    }
  }, [steps, continue_test, hideSteps]);

  useEffect(() => {
    setCurrentQuestion(0);
    setAllresponse([]);
    setCapa(data.imagem_capa);
    setTitle(data.titulo);
    setDescription(data.descricao);
    setProgress(data.percentual);
    setDateEnd(data.data_fim ? data.data_fim : null);
    const responseFlow = [].concat(data.midias);
    if (data?.perguntas?.length > 0)
      responseFlow.push({ tipo: 'Perguntas', perguntas: data.perguntas });
    setMidias(responseFlow);
    setHideSteps(true);
    setContinue_test(false);
    if (isPreview) {
      setTimeDisabled(false);
    }
  }, [open]);

  const handleNextStep = () => {
    if (steps < midias.length - 1)
      setSteps(prev => {
        prev += 1;
        return prev;
      });

    if (!isPreview) {
      readingContent(midias[steps].id).then(res => {
        setProgress(res.data.percentual);
      });
      setTimeDisabled(true);
    }
  };

  const handleBackStep = () => {
    if (steps > 0) {
      setSteps(prev => {
        prev -= 1;
        return prev;
      });
    } else {
      handleClose();
    }
  };

  const handleAnswers = (item, index, id) => {
    const prevState = allResponse;
    prevState[index] = {
      perguntaId: id,
      alternativaId: item.id,
    };

    setAllresponse(prevState);
  };

  const handleRetakeTest = async () => {
    const res = await retakeTest(data?.id);
    if (res.data?.success) {
      setAllresponse([]);
      setCurrentQuestion(0);
      setResultTestModal(false);
    } else if (res.data?.message) {
      toast.error(res.data.message);
    }
  };

  const getStatusTest = status => {
    switch (status) {
      case 'APROVADO':
        setWasApproved(true);
        break;
      default:
        setWasApproved(false);
        setResultTestModal(true);
    }
  };
  const handleResponse = async () => {
    setDisabledButton(true);
    if (!isPreview) {
      const res = await answerQuestion(allResponse, data.id);
      if (res.data?.success) {
        getStatusTest(res.data.data?.status);
        setResultTestModal(true);
      } else if (res.data?.message) {
        toast.error(res.data?.message);
        setWasApproved(false);
        setResultTestModal(true);
      }
    }
  };

  const nextQuestion = size => {
    if (currentQuestion >= 0 && currentQuestion < size - 1) {
      if (allResponse[currentQuestion]) {
        setCurrentQuestion(p => {
          p += 1;
          return p;
        });
      } else {
        toast.error('Escolha uma alternativa para prosseguir!');
      }
    } else if (currentQuestion === size - 1) {
      if (allResponse[currentQuestion]) handleResponse();
      else toast.error('Escolha uma alternativa para prosseguir!');
    }
  };

  const getContent = (item, key) => {
    if (item.tipo === 'TEXTO') {
      return (
        <div style={{ marginTop: 50, marginBottom: 50, height: 480 }}>
          <div style={{ padding: 20 }}>
            <h2>{item?.titulo}</h2>
          </div>
          <div style={{ padding: 20 }}>
            <h3>{item.descricao}</h3>
          </div>
          <div style={{ padding: 20 }}>
            <div dangerouslySetInnerHTML={{ __html: item.link }} />
          </div>
        </div>
      );
    }
    if (item.tipo === 'TEXTO') {
      return (
        <div style={{ marginTop: 50, marginBottom: 50, height: 480 }}>
          <div style={{ padding: 20 }}>
            <h2>{item?.titulo}</h2>
          </div>
          <div style={{ padding: 20 }}>
            <h3>{item.descricao}</h3>
          </div>
          <div style={{ padding: 20 }}>
            <div dangerouslySetInnerHTML={{ __html: item.link }} />
          </div>
        </div>
      );
    }

    if (item.tipo === 'IMAGEM') {
      return (
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <div style={{ padding: 20 }}>
            <h2>{item?.titulo}</h2>
          </div>
          <div style={{ padding: 20 }}>
            <h3>{item.descricao}</h3>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}
          >
            <img src={item.link} alt="" height="480" />
          </div>
        </div>
      );
    }

    if (item.tipo === 'IMAGEM') {
      return (
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <div style={{ padding: 20 }}>
            <h2>{item?.titulo}</h2>
          </div>
          <div style={{ padding: 20 }}>
            <h3>{item.descricao}</h3>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}
          >
            <img src={item.link} alt="" height="480" />
          </div>
        </div>
      );
    }

    if (item.tipo === 'VÍDEO_UPLOADING') {
      return (
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <div style={{ padding: 20 }}>
            <h2>{item?.titulo}</h2>
          </div>
          <div style={{ padding: 20 }}>
            <h3>{item.descricao}</h3>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}
          >
            <ReactPlayer
              controls
              playing
              url={item.link}
              onEnded={() => setTimeDisabled(false)}
            />
          </div>
        </div>
      );
    }
    if (item.tipo === 'VÍDEO') {
      return (
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <div style={{ padding: 20 }}>
            <h2>{item?.titulo}</h2>
          </div>
          <div style={{ padding: 20 }}>
            <h3>{item.descricao}</h3>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}
          >
            <ReactPlayer
              controls={!!isPreview}
              playing={false}
              url={item.link}
              onEnded={() => setTimeDisabled(false)}
            />
          </div>
        </div>
      );
    }
    if (item.tipo === 'PDF') {
      return (
        <div
          style={{
            marginTop: 50,
            marginBottom: 50,
            padding: 20,
          }}
        >
          <div style={{ padding: 20 }}>
            <h2>{item?.titulo}</h2>
          </div>
          <div style={{ padding: 20 }}>
            <h3>{item.descricao}</h3>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}
          >
            <embed
              src={item.link}
              width="853"
              height="480"
              type="application/pdf"
            />
          </div>
        </div>
      );
    }
    if (item?.tipo === 'Perguntas') {
      return (
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <div
            style={{
              border: `solid 1px ${theme.palette.brand.primary.light}`,
              marginBottom: 50,
              padding: 20,
            }}
          >
            <div>
              <h2>{item?.perguntas[0]?.is_quiz ? 'Quiz' : 'Teste'} </h2>
            </div>
            {item.perguntas.map((item, index) => {
              return (
                index === currentQuestion && (
                  <S.ContainerQuiz key={index}>
                    <div style={{ marginBottom: 15 }}>
                      <h3>{item.pergunta}</h3>
                    </div>
                    {item?.is_quiz ? (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="verdadeiro"
                            control={<Radio />}
                            label="Verdadeiro"
                            onChange={() =>
                              handleAnswers(
                                item?.alternativas[0],
                                index,
                                item.id,
                              )
                            }
                          />
                          <FormControlLabel
                            value="falso"
                            control={<Radio />}
                            label="Falso"
                            onChange={() =>
                              handleAnswers(
                                item?.alternativas[1],
                                index,
                                item.id,
                              )
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      <div key={key}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                          >
                            {item?.alternativas.map((elem, k) => (
                              <FormControlLabel
                                key={k}
                                onChange={() =>
                                  handleAnswers(elem, index, item.id)
                                }
                                value={elem.id}
                                control={<Radio />}
                                label={elem.alternativa}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                  </S.ContainerQuiz>
                )
              );
            })}
            <div
              style={{
                display: 'flex',
                marginTop: 15,
                justifyContent: 'flex-end',
              }}
            >
              <Button
                disabled={disabledButton}
                onClick={() => nextQuestion(item.perguntas.length)}
              >
                ENVIAR RESPOSTAS
              </Button>
            </div>
          </div>
        </div>
      );
    }
    if (item.tipo === 'VÍDEO') {
      return (
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <div style={{ padding: 20 }}>
            <h2>{item?.titulo}</h2>
          </div>
          <div style={{ padding: 20 }}>
            <h3>{item.descricao}</h3>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}
          >
            <ReactPlayer
              controls={!!isPreview}
              playing={false}
              url={item.link}
              onEnded={() => setTimeDisabled(false)}
            />
          </div>
        </div>
      );
    }
    if (item.tipo === 'PDF') {
      return (
        <div
          style={{
            marginTop: 50,
            marginBottom: 50,
            padding: 20,
          }}
        >
          <div style={{ padding: 20 }}>
            <h2>{item?.titulo}</h2>
          </div>
          <div style={{ padding: 20 }}>
            <h3>{item.descricao}</h3>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}
          >
            <embed
              src={item.link}
              width="853"
              height="480"
              type="application/pdf"
            />
          </div>
        </div>
      );
    }
    if (item?.tipo === 'Perguntas') {
      return (
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <div
            style={{
              border: `solid 1px ${theme.palette.brand.primary.light}`,
              marginBottom: 50,
              padding: 20,
            }}
          >
            <div>
              <h2>{item?.perguntas[0]?.is_quiz ? 'Quiz' : 'Teste'} </h2>
            </div>
            {item.perguntas.map((item, index) => {
              return (
                index === currentQuestion && (
                  <S.ContainerQuiz key={index}>
                    <div style={{ marginBottom: 15 }}>
                      <h3>{item.pergunta}</h3>
                    </div>
                    {item?.is_quiz ? (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="verdadeiro"
                            control={<Radio />}
                            label="Verdadeiro"
                            onChange={() =>
                              handleAnswers(
                                item?.alternativas[0],
                                index,
                                item.id,
                              )
                            }
                          />
                          <FormControlLabel
                            value="falso"
                            control={<Radio />}
                            label="Falso"
                            onChange={() =>
                              handleAnswers(
                                item?.alternativas[1],
                                index,
                                item.id,
                              )
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      <div key={key}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                          >
                            {item?.alternativas.map((elem, k) => (
                              <FormControlLabel
                                key={k}
                                onChange={() =>
                                  handleAnswers(elem, index, item.id)
                                }
                                value={elem.id}
                                control={<Radio />}
                                label={elem.alternativa}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                  </S.ContainerQuiz>
                )
              );
            })}
            <div
              style={{
                display: 'flex',
                marginTop: 15,
                justifyContent: 'flex-end',
              }}
            >
              <Button
                disabled={disabledButton}
                onClick={() => nextQuestion(item.perguntas.length)}
              >
                ENVIAR RESPOSTAS
              </Button>
            </div>
          </div>
        </div>
      );
    }
  };

  const initTest = step => {
    setHideSteps(false);
    setContinue_test(true);
    setSteps(step);
  };

  const isComplete = item => {
    // if (retakeTest) {
    //   return true;
    // }

    if (isPreview) {
      return true;
    }

    return !item.completedMidia;
    // const tentativas = data?.tentativas ? data?.tentativas : 10;
    // const respostas = data?.respostas?.length;
    // const wasApproved = !!(data.conclusao === 100 && data.reprovado === false);
    // if (
    //   (item?.tipo === 'Perguntas' && wasApproved) ||
    //   respostas >= tentativas
    // ) {
    //   return false;
    // }
    // const completo = !item?.respostas?.find(
    //   (_, k) => k === item?.respostas?.length - 1,
    // );
    // return !!completo;
  };

  const handleWhenApproved = () => {
    setResultTestModal(false);
    if (!data.comentarios_habilitados && data.curtidas_habilitadas) {
      setLike(true);
    } else if (data.comentarios_habilitados) {
      setConfirmComent(true);
    } else if (!data.comentarios_habilitados && !data.curtidas_habilitadas) {
      handleClose(false);
    }
  };

  const handleEvaluation = () => {
    saveEvaluation(data.id, {
      // estrelas: assessmentValue,
      curtiu: 'SIM',
      comentario: comment,
    });
    setLike(false);
    handleClose(false);
  };

  const handleFinish = () => {
    if (isPreview) {
      handleClose();
    } else if (!isPreview && midias.length === 1) {
      handleNextStep();
    } else {
      handleClose();
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: 'relative',
          background: theme.palette.brand.primary.background,
        }}
      >
        <Toolbar sx={{ background: theme.palette.brand.primary.background }}>
          <div style={{ position: 'absolute', right: 20, top: 10 }}>
            <CancelIcon
              sx={{
                background: theme.palette.brand.primary.background,
                color: theme.palette.words.text.light,
                height: 35,
                width: 35,
              }}
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </CancelIcon>
          </div>
          <Toolbar>
            <S.LeftHeaderContainer>
              <div className="title">
                <h1>Bem-vindo ao Treinamento Básico</h1>
                <div className="tags">
                  {tags.map((item, key) => (
                    <S.Tag key={key}>
                      <div className="tag">{item.tag}</div>
                    </S.Tag>
                  ))}
                </div>
              </div>
            </S.LeftHeaderContainer>
            <div style={{ display: 'flex', width: '10vw' }}>
              <img src={logo} alt="" />
            </div>
            <S.RightHeaderContainer>
              <S.ProgessContainer>
                {progress !== undefined && progress !== null && (
                  <>
                    <label>{`${progress}% COMPLETO`}</label>
                    <S.Progess>
                      <div className="bar" style={{ width: `${progress}%` }} />
                    </S.Progess>
                    <EmojiEventsOutlinedIcon
                      sx={{ color: theme.palette.words.subtitle.natural }}
                    />
                  </>
                )}
                <div className="date">
                  <RemoveRedEyeOutlinedIcon
                    sx={{
                      color: theme.palette.semantics.feedback.success.natural,
                    }}
                  />
                  {dateEnd && (
                    <label>
                      Ativo até dia {new Date(dateEnd).toLocaleDateString()}
                    </label>
                  )}
                </div>
              </S.ProgessContainer>
            </S.RightHeaderContainer>
          </Toolbar>
        </Toolbar>
      </AppBar>
      <div>
        {continue_test && (
          <>
            <div>
              <S.containerBanner>
                <img
                  style={{
                    position: 'absolute',
                    filter: 'brightness(60%)',
                    height: '280px',
                  }}
                  src={capa}
                  alt=""
                />
                <S.containerTitle>
                  <div style={{ fontSize: 36 }}>
                    <label>{title}</label>
                  </div>
                  <div className="subtitle">
                    <label>{description}</label>
                  </div>
                </S.containerTitle>
              </S.containerBanner>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  height: 'auto',
                  width: '100%',
                }}
              >
                {midias.map(
                  (item, key) =>
                    steps === key && (
                      <div key={key}>{getContent(item, key)}</div>
                    ),
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                margin: 20,
              }}
            >
              <ButtonGhost onClick={handleBackStep}>Voltar</ButtonGhost>
              <div style={{ margin: 10 }} />
              {steps < midias.length - 1 ? (
                <Button disabled={timeDisabled} onClick={handleNextStep}>
                  Avançar
                </Button>
              ) : (
                <Button disabled={timeDisabled} onClick={() => handleFinish()}>
                  Concluir
                </Button>
              )}
            </div>
          </>
        )}

        {!continue_test && (
          <div style={{ display: 'flex' }}>
            {!hideSteps ? (
              <div style={{ width: '70%', height: 100 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  {midias.map(
                    (item, key) =>
                      steps === key && (
                        <div key={key}>{getContent(item, key)}</div>
                      ),
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  {steps > 1 && (
                    <ButtonGhost onClick={handleBackStep}>Voltar</ButtonGhost>
                  )}
                  <div style={{ margin: 10 }} />
                  <Button onClick={handleNextStep}>Avançar</Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  padding: 80,
                  width: '70%',
                  background: theme.palette.brand.primary.background,
                }}
              >
                <div style={{ textAlign: 'start' }}>
                  <h1>{title}</h1>
                </div>
                <div>
                  <img
                    style={{ borderRadius: 4 }}
                    src={capa}
                    alt="img capa"
                    width="100%"
                    height="400px"
                  />
                </div>
                <div style={{ fontSize: 14, overflow: 'scroll' }}>
                  <h3>{description}</h3>
                </div>
              </div>
            )}
            <S.MenuRight>
              {midias &&
                midias.map((item, key) => (
                  <div className="menu-item" key={key}>
                    <div>
                      <div>{/* <h1>{item?.titulo}</h1> */}</div>

                      {isComplete(item) ? (
                        <>
                          <div className="continue">Conteúdo: {item?.tipo}</div>
                          <div className="continue">Status: Continuar</div>
                        </>
                      ) : (
                        <>
                          <div className="concluido">
                            Conteúdo: {item?.tipo}
                          </div>
                          <div className="concluido">Concluído</div>
                        </>
                      )}
                    </div>
                    <div className="icons-colors">
                      <IconButton
                        disabled={!isComplete(item)}
                        onClick={() => initTest(key)}
                      >
                        <PlayCircleIcon
                          sx={{ color: theme.palette.brand.primary.light }}
                        />
                      </IconButton>
                    </div>
                  </div>
                ))}
            </S.MenuRight>
          </div>
        )}
      </div>
      <ModalPopup
        open={confirmComment}
        handleClose={() => {
          setConfirmComent(false);
        }}
        title="Avalie o que você achou deste Conteúdo:"
        buttonCancelTitle="AGORA NÃO"
        buttonConfirmTitle="ENVIAR AVALIAÇÃO"
        height="420px"
        width="600px"
        backgroundColor={theme.palette.system.overlay}
        onClick={() => {
          handleEvaluation();
        }}
        iconStart={
          <WorkspacePremiumIcon
            sx={{ color: theme.palette.brand.secondary.natural }}
          />
        }
      >
        <div
          style={{
            padding: 5,
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <S.CardComment>
              <div
                className="card"
                style={{
                  borderColor: selectLike
                    ? theme.palette.brand.secondary.natural
                    : theme.palette.brand.primary.light,
                }}
                onClick={() => setSelectLike(true)}
              >
                <ThumbUpOutlinedIcon
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              </div>
              <div
                className="card"
                style={{
                  borderColor: !selectLike
                    ? theme.palette.brand.secondary.natural
                    : theme.palette.brand.primary.light,
                }}
                onClick={() => setSelectLike(false)}
              >
                <ThumbDownOffAltOutlinedIcon
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              </div>
            </S.CardComment>
          </div>
          <Divider sx={{ marginBottom: 3, marginTop: 3 }} />
          <div style={{ marginBottom: 15 }}>
            <TextField
              value={comment}
              onChange={val => setComment(val.target.value)}
              placeholder="Descreva com detalhes aqui…"
            />
          </div>
        </div>
      </ModalPopup>

      <ModalPopup
        open={resultTestModal}
        handleClose={() => {
          setResultTestModal(false);
          // TODO: O pop - up só aparece quando é aprovado ou quando encerram - se todas as tentativas -> proxima sprint retornar isso
          if (data.comentarios_habilitados && wasApproved) {
            setConfirmComent(true);
          }
        }}
        title={
          wasApproved
            ? `Parabéns! Você concluiu o conteúdo com sucesso.`
            : 'Infelizmente você foi reprovado.'
        }
        buttonCancelTitle={wasApproved ? 'FECHAR' : 'DEPOIS'}
        buttonConfirmTitle={wasApproved ? 'FINALIZAR' : 'REFAZER TESTE'}
        height="320px"
        width="460px"
        backgroundColor={theme.palette.system.disabled}
        onClick={() =>
          wasApproved ? handleWhenApproved() : handleRetakeTest()
        }
        iconStart={
          wasApproved ? (
            <CheckCircleIcon
              sx={{ color: theme.palette.brand.secondary.natural }}
            />
          ) : (
            <CancelIcon
              sx={{ color: theme.palette.semantics.feedback.attention.natural }}
            />
          )
        }
      >
        <div style={{ padding: 5 }}>
          <div style={{ marginTop: 20 }}>
            <h3>Descrição do conteúdo</h3>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 20,
              transform: 'translateY(10px)',
            }}
          >
            <div>dss</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: 100,
              width: 400,
              backgroundColor: theme.palette.system.overlay,
              borderRadius: 5,
              border: `1px solid ${theme.palette.system.divider}`,
              padding: 10,
            }}
          >
            <div>
              <img
                src={data.imagem_capa}
                alt="capa"
                width={50}
                height={50}
                style={{ borderRadius: 4, marginRight: 10 }}
              />
            </div>
            <div
              style={{
                maxWidth: 340,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <h2> {data.titulo}</h2>
            </div>
          </div>
        </div>
      </ModalPopup>
    </Dialog>
  );
};
export default TakeTest;
