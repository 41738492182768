/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { useState } from 'react';

// Components
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined'; // like
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined'; // dislike
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

// Services
import * as services from '../services';
import { toast } from 'react-toastify';

const LikeModal = ({ contentId, open, handleClose, closeContent }) => {
  const [sense, setSense] = useState(null);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const sendFeeback = async () => {
    if (sense || comment) {
      let data = {};
      if (sense) {
        data.curtiu = sense === 'like' ? 'SIM' : 'NAO';
      }

      if (comment) {
        data.comentario = comment;
      }

      setLoading(true);
      let res = await services.evaluateContent(contentId, data);
      if (res.success) {
        toast.success(res.message);
      }
      setLoading(false);
      handleClose();
      closeContent();
    } else {
      handleClose();
      closeContent();
    }
  };

  return (
    <S.ModalMU open={open}>
      <S.Container>
        <S.Header>
          <div className="titleArea">
            <WorkspacePremiumOutlinedIcon htmlColor={colors.greenMain} />
            <div className="title">Avalie o que achou deste conteúdo</div>
          </div>
          <CloseIcon
            onClick={closeContent}
            htmlColor={colors.greySubtitle}
            style={{
              cursor: 'pointer',
            }}
          />
        </S.Header>

        <S.LikeBox>
          <S.FeedBox
            className={sense === 'like' ? 'like' : ''}
            active={sense === 'like'}
            onClick={() => setSense('like')}
          >
            <ThumbUpOutlinedIcon
              htmlColor={
                sense === 'like' ? colors.greenMain : colors.greySubtitle
              }
            />
          </S.FeedBox>
          <S.FeedBox
            className={sense === 'dislike' ? 'dislike' : ''}
            active={sense === 'dislike'}
            onClick={() => setSense('dislike')}
          >
            <ThumbDownOffAltOutlinedIcon
              htmlColor={
                sense === 'dislike' ? colors.redDanger : colors.greySubtitle
              }
            />
          </S.FeedBox>
        </S.LikeBox>

        <S.CommentBox>
          <TextInput
            label="Escreva um comentário"
            rows={6}
            multiline
            value={comment}
            onChange={e => setComment(e.target.value.slice(0, 255))}
          />
          <div className="counter">{comment.length}/255</div>
        </S.CommentBox>
        <S.Buttons>
          <GhostButton children="Fechar" onClick={() => closeContent()} />
          <DefaultButton
            children="Enviar"
            onClick={() => sendFeeback()}
            loading={loading}
          />
        </S.Buttons>
      </S.Container>
    </S.ModalMU>
  );
};

export default LikeModal;
