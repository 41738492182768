import styled, { css } from 'styled-components';
import colors from 'styles/colors';
import { makeStyles } from '@material-ui/styles';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.system.overlay};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 25px;
    padding-bottom: 15px;
  `}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

export const ImageContainer = styled.div`
  width: 100%;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  position: relative;

  ${props =>
    props.file
      ? `:hover{
      width: 99%;
      height: 99%;
      opacity: 0.9;
      transition-duration: 300ms;
    }`
      : ''};

  img {
    width: 100%;
    border-radius: 5px;
  }

  .expandImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.1;
    cursor: pointer;

    :hover {
      opacity: 1;
      transition-duration: 300ms;
    }
  }
`;

export const ExpandImage = styled.div`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.1;
  cursor: pointer;

  :hover {
    opacity: 1;
    transition-duration: 300ms;
  }
`;

export const Header = styled.div`
  ${({ theme }) => css`
    padding: 30px;
    display: flex;
    background-color: ${theme.palette.system.overlay};
    height: 75px;
    opacity: 1;

    p {
      margin-left: 6px;
      text-align: left;
      font: normal normal 900 20px/20px Texta;
      letter-spacing: 0px;
      color: ${theme.palette.words.title.natural};
      opacity: 1;
    }
  `}
`;

export const DivModal = styled.div`
  ${({ theme }) => css`
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    background-color: ${theme.palette.system.overlay};
    overflow-y: scroll;
  `}
`;

export const useStyles = makeStyles(() => ({
  inputText: {
    margin: '10px 0px',
    width: '100%',
    maxWidth: '395px',
  },
  botaoAddPergunta: {
    border: `2px solid #656E8C`,
    borderRadius: '4px',
    color: '#656E8C',
    backgroundColor: 'transparent',
  },
}));
