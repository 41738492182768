import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import PlayLessonOutlinedIcon from '@mui/icons-material/PlayLessonOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';

import { Texto } from './DraggableList/Texto';
import { Imagem } from './DraggableList/Imagem';
import { PDF } from './DraggableList/Pdf';
import { Video } from './DraggableList/Video';
import { VideoUploading } from './DraggableList/VideoUploading';

export const mediaType = [
  {
    counterKey: 'text',
    Icon: params => <ArticleOutlinedIcon {...params} />,
    label: 'Texto',
    type: 'TEXTO',
    Component: params => <Texto {...params} />,
  },
  {
    counterKey: 'media',
    Icon: params => <ImageOutlinedIcon {...params} />,
    label: 'Imagem/Folder',
    type: 'IMAGEM',
    Component: params => <Imagem {...params} />,
  },
  {
    counterKey: 'slide',
    Icon: params => <PlayLessonOutlinedIcon {...params} />,
    label: 'Apresentação (PDF)',
    type: 'PDF',
    Component: params => <PDF {...params} />,
  },
  {
    counterKey: 'video',
    Icon: params => <SlideshowOutlinedIcon {...params} />,
    label: 'Link Youtube/Vimeo',
    type: 'VÍDEO',
    Component: params => <Video {...params} />,
  },
  {
    counterKey: 'videoUploading',
    Icon: params => <BackupOutlinedIcon {...params} />,
    label: 'Upload Vídeo',
    type: 'VÍDEO_UPLOADING',
    Component: params => <VideoUploading {...params} />,
  },
];
