import api from 'services/api';

export const requestWhitelabel = async idEmpresa => {
  const { data } = await api.get(`/company/${idEmpresa}`);
  return data.data;
};

export const requestWhitelabelOptions = async () => {
  const { data } = await api.get(`/whitelabel`);
  return data?.data ?? [];
};
