import React from 'react';

import * as S from './styled';

export const Info = ({ title, text, icon }) => {
  return (
    <S.Container>
      {icon}
      <S.InfoContainer>
        <S.Title>{title}</S.Title>
        <S.Text>{text}</S.Text>
      </S.InfoContainer>
    </S.Container>
  );
};
