import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from 'styled-components';
import * as S from './styled';

/*
height: definido por props.size  small= 30px || medium= 40px || large= 50px
width: min=120px, default automatico. Recebe valor
loading: aparenta inativo, mas deve ser tratado no onClick
*/
const DefaultButton = ({
  loading,
  children,
  icon,
  disabled,
  width,
  size,
  onClick,
  ...props
}) => {
  const theme = useTheme();
  return (
    <S.StyledButton
      disabled={disabled}
      onClick={!loading ? onClick : undefined}
      width={width}
      size={size}
      loading={loading ? 'true' : 'false'}
      startIcon={icon && icon}
      {...props}
      data-testid="apply-button"
    >
      {loading ? (
        <CircularProgress
          size={24}
          sx={{ color: theme.palette.words.button.contrast }}
        />
      ) : (
        children
      )}
    </S.StyledButton>
  );
};

export default DefaultButton;
