import React from 'react';
import { Info } from '@mui/icons-material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import * as S from './styled';

// items = [ {value: any, label: string}, ... ]
const ColorTabs = ({ handleInfo, value, onChange, items, width, ...props }) => {
  return (
    <S.Wrapper width={width} style={{ marginLeft: '-15px' }}>
      <Tabs
        value={value}
        onChange={onChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            display: 'flex',
            alignItems: 'end',
            marginBottom: '8px',
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        {items &&
          items.map(i => (
            <S.StyledTab
              key={i.value}
              value={i.value}
              label={
                !handleInfo ? (
                  <S.Span>
                    <h3>{i.label}</h3>
                  </S.Span>
                ) : (
                  <S.Span>
                    <h3>{i.label}</h3>
                    <S.Div />
                    <Info onClick={() => handleInfo(i)} />
                  </S.Span>
                )
              }
            />
          ))}
      </Tabs>
    </S.Wrapper>
  );
};

export default ColorTabs;
