import { Link } from '@mui/material';
import Button from 'components/Buttons/Default';

import {
  Content,
  Container,
  Copyrights,
  IconList,
  LogoWrapper,
  List,
} from './styled';

const Footer = props => {
  return (
    <Container>
      <Content>
        <div>
          <LogoWrapper src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63fae3c3284d8727a819849e_onisys-white-new.svg" />
          <IconList>
            <ul>
              <li>
                <Link href="https://www.instagram.com/onisysbynstech/">
                  <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f20b7392f8241_Grupo%2021575.svg" />
                </Link>
              </li>
              <li>
                <Link href="https://www.facebook.com/onisysbynstech/">
                  <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f208ab82f8242_Grupo%2021577.svg" />
                </Link>
              </li>
              <li>
                <Link href="https://www.linkedin.com/company/onisysnaestrada/">
                  <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f208ab82f8242_Grupo%2021577.svg" />
                </Link>
              </li>
              <li>
                <Link href="https://www.youtube.com/@onisysbynstech1528">
                  <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f20e4c02f8240_Grupo%2021578.svg" />
                </Link>
              </li>
            </ul>
          </IconList>
          <p>
            Rua Bernardo Figueiredo, 33 – Serra –
            <br />
            Belo Horizonte - MG, 30220-140
          </p>
        </div>
        <List>
          <ul>
            <li>
              <span>Soluções</span>
            </li>
            <li>
              <a href="https://www.onisys.com.br/monitoramento">Fiscalização</a>
            </li>
            <li>
              <a href="https://www.onisys.com.br/capacitacao">Capacitação</a>
            </li>
            <li>
              <a href="https://www.onisys.com.br/planos">Planos</a>
            </li>
          </ul>
        </List>
        <List>
          <ul>
            <li>
              <span>Empresa</span>
            </li>
            <li>
              <a href="https://www.onisys.com.br/">Sobre</a>
            </li>
            <li>
              <a href="https://www.onisys.com.br/planos">Privacy Policy</a>
            </li>
            <li>
              <a href="https://www.onisys.com.br/">Terms of Use</a>
            </li>
            <li>
              <a href="https://www.onisys.com.br/clientes">Clientes</a>
            </li>
          </ul>
        </List>
        <List>
          <ul>
            <li>
              <span>Aprenda</span>
            </li>
            <li>
              <a href="#" target="_blank">
                Blog
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                Midia
              </a>
            </li>
          </ul>
        </List>
        <List>
          <ul>
            <li>
              <Button href="https://www.onisys.com.br/agende-uma-demonstracao">
                Teste Grátis
              </Button>
            </li>
            <li>
              <a href="https://www.onisys.com.br/agende-uma-demonstracao">
                Solicitar Demo &gt;
              </a>
            </li>
          </ul>
        </List>
      </Content>
      <Copyrights>
        <span>ⓒ 2023 Onisys by NsTech - Todos os Direitos Reservados</span>
      </Copyrights>
    </Container>
  );
};

export default Footer;
