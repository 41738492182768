import { AddCircleOutline, CheckCircleOutline } from '@mui/icons-material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import colors from 'styles/colors';
import * as S from './styled';

const infoMessage = {
  veiculos:
    'Gerencie sua frota. Você pode adicionar, editar, ativar e inativar caminhões.',
  disponibilidade:
    'Visualize e controle os indicadores de disponibilidade da frota. Justifique indisponibilidade.',
  upload: 'Visualize os indicadores da frota. Personalize seus atalhos.',
};

const statusDisponiveis = [
  'ATESTADO MÉDICO',
  'DESCANSO SEMANAL',
  'FÉRIAS MOTORISTA',
  'FORA DE OPERAÇÃO',
  'FROTA ONLINE',
  'MANUTENÇÃO',
  'OUTRO',
];

const statusJustificados = ['MONITORAMENTO INDISPONÍVEL'];

const statusJustificativa = [
  { value: 'ATESTADO MÉDICO', label: 'Atestado médico' },
  { value: 'DESCANSO SEMANAL', label: 'Descanso semanal' },
  { value: 'FÉRIAS MOTORISTA', label: 'Férias motorista' },
  { value: 'FORA DE OPERAÇÃO', label: 'Fora de operação' },
  { value: 'FROTA ONLINE', label: 'Frota online' },
  { value: 'MANUTENÇÃO', label: 'Manutenção' },
  { value: 'MONITORAMENTO INDISPONÍVEL', label: 'Monitoramento indisponível' },
  { value: 'OUTRO', label: 'Outro:' },
];

const cardsFrotaInit = [
  {
    name: 'ativas',
    value: 0,
    text: 'Veículos ativos',
    icon: 'card_ativas.svg',
    selected: false,
  },
  {
    name: 'posicaoInadequada',
    value: 0,
    text: 'Posicionamentos de câmera inadequados',
    icon: 'card_camera_inadequada.svg',
    selected: false,
  },
  {
    name: 'canalIncorreto',
    value: 0,
    text: 'Canais de DVR incorretos',
    icon: 'card_incorreto.svg',
    selected: false,
  },
];

const cardsDisponibilidadeInit = [
  {
    name: 'disponiveis',
    value: 0,
    text: 'disponíveis no período',
    icon: 'card_camera_adequada.svg',
  },
  {
    name: 'abaixo95',
    value: 0,
    text: 'Placas abaixo do mínimo de 95%',
    icon: 'lupa_vermelha.svg',
    selected: false,
  },
  {
    name: 'indispJustificada',
    value: 0,
    text: 'com indisponibilidade justificada',
    icon: 'lupa_vermelha.svg',
    selected: false,
  },
];

const columnsCreateMany = [
  {
    Header: 'Placa',
    id: 'placa',
    accessor: d => (d.placa ? String(d.placa) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => (
      <h3 style={{ color: '#2E3240', fontWeight: '600' }}>
        {row.original.placa}
      </h3>
    ),
  },
  {
    Header: 'Modelo',
    id: 'modelo',
    accessor: d => (d.modelo ? String(d.modelo) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.modelo}</h3>,
  },
  {
    Header: 'Câmbio',
    id: 'cambio',
    accessor: d => (d.cambio ? String(d.cambio) : '-'),
    sortType: 'basic',
    Cell: ({ row }) => <h3>{row.original.cambio}</h3>,
  },
  {
    Header: 'Departamento',
    id: 'departamento',
    accessor: d => (d.departamento ? String(d.departamento) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.departamento}</h3>,
  },
  {
    Header: 'Filial',
    id: 'filial',
    accessor: d => (d.filial ? String(d.filial) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.filial}</h3>,
  },
  {
    Header: 'Cliente',
    id: 'cliente',
    accessor: d => (d.cliente ? String(d.cliente) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.cliente}</h3>,
  },
];

export const columnsFrota2 = [
  {
    header: 'Placa',
    id: 'placa',
    sort: true,
    type: 'string',
  },
  {
    header: 'ID Frota',
    id: 'frota',
    sort: true,
    type: 'string',
  },
  {
    header: 'Modelo',
    id: 'modelo',
    sort: true,
    type: 'string',
  },
  {
    header: 'Filial',
    id: 'filial.nome',
    sort: true,
    type: 'string',
  },
  {
    header: 'Pos. Câmera',
    id: 'pos_camera',
    sort: true,
    type: 'string',
    align: 'center',
    children: (_, item) => {
      return item.pos_camera === 'SIM' ? (
        <CheckCircleOutline htmlColor="#1BC5BD" />
      ) : (
        <AddCircleOutline
          htmlColor="#F64E60"
          sx={{ transform: 'rotate(45deg)' }}
        />
      );
    },
  },
  {
    header: 'Canais DVR',
    id: 'dvr',
    sort: true,
    type: 'string',
    children: (_, item) => {
      let color = colors.greyTiny;
      let Icon = CheckCircleOutline;
      if (item.dvr === 'SIM') {
        color = colors.greenSucces;
        Icon = CheckCircleOutline;
      } else if (item.dvr === 'NAO') {
        color = colors.redDanger;
        Icon = HighlightOffRoundedIcon;
      }

      return (
        <div
          style={{ width: '80px', display: 'flex', justifyContent: 'center' }}
        >
          <Icon htmlColor={color} />
        </div>
      );
    },
  },
  {
    header: 'Status',
    id: 'status',
    sort: true,
    type: 'string',
    conditional: [
      {
        condition: value => value === 'ATIVO',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'INATIVO',
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];

const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Placa',
    value: 'placa',
    selected: true,
    default: true,
  },
  {
    label: 'Frota',
    value: 'frota',
    selected: false,
    default: false,
  },
  {
    label: 'Filial',
    value: 'filialNome',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'distribuidoraNome',
    selected: false,
    default: false,
  },
  {
    label: 'Câmbio',
    value: 'cambio',
    selected: false,
    default: false,
  },
  {
    label: 'Modelo',
    value: 'modelo',
    selected: false,
    default: false,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Carregamento',
    value: 'carregamento',
    selected: false,
    default: false,
  },
  {
    label: 'Propriedade',
    value: 'propriedade',
    selected: false,
    default: false,
  },
  {
    label: 'Empresa Terceira',
    value: 'empresa_terceira',
    selected: false,
    default: false,
  },
  {
    label: 'Departamento',
    value: 'departamento',
    selected: false,
    default: false,
  },
  {
    label: 'Criado em',
    value: 'createdAt',
    selected: false,
    default: false,
  },
  {
    label: 'Posição da câmera',
    value: 'pos_camera',
    selected: false,
    default: false,
  },
  {
    label: 'Padronização DVR',
    value: 'dvr',
    selected: false,
    default: false,
  },
  {
    label: 'Câmera ( Último video disponível )',
    value: 'camera_ultimo_video',
    selected: true,
    default: true,
  },
  {
    label: 'Sensor fadiga ( Último evento )',
    value: 'fadiga_ultimo_evento',
    selected: true,
    default: true,
  },
  {
    label: 'Telemetria ( Último posição )',
    value: 'telemtria_ultima_posicao',
    selected: true,
    default: true,
  },
  {
    label: 'Fornecedor Câmera',
    value: 'tecnologia_camera',
    selected: true,
    default: true,
  },
  {
    label: 'Fornecedor Telemetria',
    value: 'tecnologia_telemetria',
    selected: true,
    default: true,
  },
  {
    label: 'Fornecedor Câm. Fadiga',
    value: 'tecnologia_fadiga',
    selected: true,
    default: true,
  },
];

const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};

export {
  infoMessage,
  cardsFrotaInit,
  cardsDisponibilidadeInit,
  columnsCreateMany,
  statusDisponiveis,
  statusJustificados,
  statusJustificativa,
  fields,
  resetExcelFields,
};
