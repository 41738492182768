import api from 'services/api';

export const getIndex = async provider => {
  const res = await api.get(`${provider ? '/provider' : ''}/guide-data`);
  return res.data;
};

export const responseGuia = async (provider, data) => {
  const response = await api.post(
    `${provider ? '/provider' : ''}/guide-steps/${data.id}`,
    data,
  );
  return response.data;
};

export const evaluateGuia = async (provider, id, data) => {
  const response = await api.put(
    `${provider ? '/provider' : ''}/guide-evaluate/${id}`,
    data,
  );
  return response.data;
};

export const getInfos = async () => {
  const res = await api.get('/first-steps-data');
  return res.data;
};
