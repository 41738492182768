import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  .flex-1 {
    flex: 1;
  }
`;

export const Content = styled.div`
  margin-top: 1rem;
`;
