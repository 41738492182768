import api from 'services/api';

export const requestEmpresas = async query => {
  const res = await api.get(`/provider/empresas`, { params: query });
  return res;
};

export const rejectInvite = async id => {
  const res = await api.post(`/distribuidora/reject-empresa/${id}`);
  return res;
};

export const acceptInvite = async id => {
  const res = await api.post(`/distribuidora/accept-empresa/${id}`);
  return res;
};

export const requestDesviosExcel = async query => {
  const res = await api.get(`/excel-provider/empresas_desvios`, {
    params: query,
  });
  return res;
};

export const requestEmpresa = async (id, query) => {
  const res = await api.get(`/provider/empresas/${id}`, { params: query });
  return res?.data || null;
};

export const requestDesviosEmpresa = async (id, query) => {
  const res = await api.get(`/provider/empresas/${id}/desvios`, {
    params: query,
  });
  return res?.data || null;
};

export const requestLogs = async id => {
  const res = await api.get(`/provider/empresas-logs/${id}`);
  return res;
};

export const requestExcel = async (id, query) => {
  const res = await api.get('/excel/perfil_transportador', {
    params: { id_empresa: id, ...query },
  });
  if (res?.data?.data?.link)
    return { link: res.data.data.link, message: res.data.message };
  return { message: res?.data?.message };
};
