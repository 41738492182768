export const howToColor = (criticality, theme) => {
  switch (criticality) {
    case 'MODERADA':
      return {
        color: theme?.palette.semantics.feedback.warning.natural,
        background: theme?.palette.semantics.feedback.warning.light,
      };
    case 'GRAVE':
      return {
        color: theme?.palette.semantics.feedback.attention.natural,
        background: theme?.palette.semantics.feedback.attention.light,
      };
    case 'GRAVÍSSIMA':
      return {
        color: theme?.palette.semantics.feedback.attention.natural,
        background: theme?.palette.semantics.feedback.attention.light,
      };
    default:
      return {
        color: theme?.palette.semantics.feedback.success.natural,
        background: theme?.palette.semantics.feedback.success.light,
      };
  }
};

export const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Transportadora',
    value: 'motorista.filial.empresa.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'motorista.filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Placa',
    value: 'caminhao.placa',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo de desvio',
    value: 'desvio_tipo.titulo',
    selected: true,
    default: true,
  },
  {
    label: 'Criticidade',
    value: 'desvio_tipo.criticidade',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'pontos',
    selected: true,
    default: true,
  },
  {
    label: 'Objeto',
    value: 'objeto',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Status Embarcadora',
    value: 'status_embarcadora',
    selected: true,
    default: true,
  },
  {
    label: 'Data do desvio',
    value: 'data_desvio',
    selected: true,
    default: true,
  },

  {
    label: 'Disponibilizado em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
  {
    label: 'Movido para pendente em',
    value: 'data_pendente',
    selected: true,
    default: true,
  },
  {
    label: 'Plano de ação',
    value: 'desvio_info.plano_acao',
    selected: true,
    default: true,
  },
  {
    label: 'Previsão de conclusão',
    value: 'data_previsao',
    selected: true,
    default: true,
  },
  {
    label: 'Finalizado em',
    value: 'data_encerramento',
    selected: true,
    default: true,
  },
  {
    label: 'Responsavel',
    value: 'responsavel.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Comentário',
    value: 'comentario',
    selected: true,
    default: true,
  },
  {
    label: 'Observação',
    value: 'desvio_info.observacao',
    selected: true,
    default: true,
  },
  {
    label: 'Aprovado exclusão',
    value: 'aprovado_exclusao',
    selected: true,
    default: true,
  },

  {
    label: 'Data envio revisão',
    value: 'data_exclusao',
    selected: true,
    default: true,
  },

  {
    label: 'Data revisão distribuidora',
    value: 'data_revisao_distribuidora',
    selected: true,
    default: true,
  },

  {
    label: 'Vencimento distribuidora',
    value: 'vencimento_distribuidora',
    selected: true,
    default: true,
  },

  {
    label: 'Usuário distribuidora',
    value: 'responsavel.nome',
    selected: true,
    default: true,
  },

  {
    label: 'Local',
    value: 'avaliacao.local',
    selected: true,
    default: true,
  },

  {
    label: 'Justificativa exclusão',
    value: 'desvio_info.motivo_exclusao',
    selected: true,
    default: true,
  },

  {
    label: 'Observação exclusão',
    value: 'desvio_info.observacao_exclusao',
    selected: true,
    default: true,
  },

  {
    label: 'Status Embarcadora',
    value: 'aprovado_distribuidora',
    selected: true,
    default: true,
  },

  {
    label: 'Observações distribuidora',
    value: 'observacoes_distribuidora',
    selected: true,
    default: true,
  },
];

export const resetFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};
