/* eslint-disable import/order */

// Styles
import * as S from './styled';
import * as C from '../styled';
import colors from 'styles/colors';

// React
import { useEffect, useState } from 'react';

// Components
import RightModal from 'components/RightModal';
import { Switch } from 'components/Inputs/Switch';
import SelectList from 'components/Inputs/SelectList';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { Tooltip } from '@mui/material';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Utils
import emptyImage from 'images/empty/frota.png';

export const Cliente = ({
  title,
  tooltip,
  button,
  setChanged,
  setCurrentData,
  data,
  clients,
  preview,
}) => {
  const [selectedModal, setSelectedModal] = useState('');

  const formatData = () => {
    let ids = data?.map(i => i.id);
    let free = clients?.filter(i => !ids?.includes(i.id));
    return free?.map(i => ({
      value: i.nome,
      id: i.id,
      img: i.logo_url,
    }));
  };

  // --------------------------------------TABLE---------------------------------------//
  const [ordenad, setOrdenad] = useState(0);
  const [userClientes, setUserClientes] = useState(
    data?.map((item, k) => ({ ...item, id_local: data?.length - k })),
  );

  const getOrdenad = () => {
    switch (ordenad) {
      case 0:
        return [...userClientes.sort((a, b) => b.id_local - a.id_local)];
      case 1:
        return [
          ...userClientes.sort((a, b) => `${a.nome}`.localeCompare(b.nome)),
        ];
      case 2:
        return [
          ...userClientes.sort((a, b) => `${b.nome}`.localeCompare(a.nome)),
        ];
    }
  };

  const handleOrdenad = () => {
    if (ordenad == 0) {
      setOrdenad(1);
    } else if (ordenad == 1) {
      setOrdenad(2);
    } else {
      setOrdenad(0);
    }
  };

  const handleStatus = idx => {
    setChanged(true);
    const copyClients = [...userClientes];
    copyClients[idx].ativo = !copyClients[idx].ativo;
    setUserClientes(copyClients);
  };

  useEffect(() => {
    setCurrentData(userClientes);
  }, [userClientes]);

  // --------------------------------------MODAL---------------------------------------//
  const [openModal, setOpenModal] = useState(false);

  const handleAddClients = () => {
    setChanged(true);
    const copyClients = [
      ...data.map((item, k) => ({ ...item, id_local: data?.length - k })),
    ];

    let arrClients = selectedModal.split(',');
    const modalClients = clients.filter(i => arrClients.includes(String(i.id)));

    for (const i in modalClients) {
      copyClients.unshift({
        id: modalClients[i]?.id,
        id_local: copyClients?.length + 1,
        nome: modalClients[i]?.nome,
        logo_url: modalClients[i]?.logo_url,
        ativo: true,
      });
    }
    setUserClientes(copyClients);
    setOrdenad(0);
  };

  const childrenModal = () => {
    return (
      <S.Box>
        <SelectList
          data={formatData() || []}
          value={selectedModal}
          label="Selecione os clientes"
          handleChange={e => setSelectedModal(e)}
          markselected
          selectAll
        />
      </S.Box>
    );
  };

  // --------------------------------------RENDER---------------------------------------//
  return (
    <S.Container>
      <C.HeaderStep>
        <div className="left">
          <div className="title">{title}</div>
          {tooltip && (
            <Tooltip title={tooltip}>
              <HelpOutlineOutlinedIcon className="iconTooltip" />
            </Tooltip>
          )}
        </div>
        {button && (
          <DefaultButton
            children={button}
            icon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => setOpenModal(true)}
            disabled={preview}
          />
        )}
      </C.HeaderStep>

      {userClientes?.length > 0 ? (
        <S.SampleTable>
          <S.HeaderTable onClick={() => handleOrdenad()}>
            <div className="left">
              <p>NOME</p>
              <div className="arrows">
                <ArrowDropUpOutlinedIcon
                  className={ordenad == 1 ? 'top active' : 'top'}
                />
                <ArrowDropDownOutlinedIcon
                  className={ordenad === 2 ? 'bottom active' : 'bottom'}
                />
              </div>
            </div>
            <div className="right">
              <p>STATUS</p>
            </div>
          </S.HeaderTable>
          <S.BodyTable>
            {getOrdenad().map((item, key) => (
              <S.LineTable key={key}>
                <div className="left">
                  <div className="boxImage">
                    {item?.logo_url && <img src={item.logo_url} />}
                    {!item?.logo_url && (
                      <div className="letter">
                        {item?.nome && item?.nome[0]}
                      </div>
                    )}
                  </div>
                  <p>{item?.nome}</p>
                </div>
                <Switch
                  check={item?.ativo}
                  setCheckbox={() => handleStatus(key)}
                />
              </S.LineTable>
            ))}
          </S.BodyTable>
        </S.SampleTable>
      ) : (
        <C.Empty>
          <img className="image" src={emptyImage} />
          <div className="title">Adicione Clientes</div>
          <div className="subtitle">Adicione os clientes da sua empresa</div>
        </C.Empty>
      )}

      <RightModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        children={childrenModal()}
        title="Adicionar Cliente"
        icon={() => (
          <AddCircleOutlineOutlinedIcon htmlColor={colors.greenMain} />
        )}
        textButton="Adicionar"
        onConfirm={() => handleAddClients()}
      />
    </S.Container>
  );
};
