import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  padding-bottom: 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const Paragraph = styled.p`
  display: flex;
  margin-left: 10px;
  justify-content: center;
  font-size: ${props => (props.fontsize ? props.fontsize : '16px')};
  color: ${props => (props.color ? props.color : colors.grey)};
  font-weight: ${props => (props.fontweight ? props.fontweight : 400)};
  min-width: ${props => props.minwidth};

  &.status {
    font-weight: bold;
    color: ${props => props.color};
    background-color: ${props => props.bg};
    text-align: center;
    border-radius: 100px;
  }
`;

export const ColDriver = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  ${({ pontuacao }) =>
    pontuacao &&
    `
    h1{
      color: ${colors.orangeAlert} !important;
    }
  `}

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.greyTitle};
  }

  .container-img-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  img {
    width: 35px;
    height: 35px;
    border: 1px solid ${colors.greyBorder};
    border-radius: 100px;
  }
`;
