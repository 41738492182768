import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 10px 20px;
    border-bottom: ${props =>
      props.noBorder ? 'none' : `1px solid ${theme.palette.system.divider}`};
  `}
`;

export const Question = styled.div`
  ${({ theme }) => css`
    text-transform: lowercase;
    font-weight: 600;
    color: ${theme.palette.words.title.natural};
    font-size: 18px;
    margin-top: 5px;
    word-break: break-all;

    &::first-letter {
      text-transform: uppercase;
    }
  `}
`;

export const ResponsesBox = styled.div`
  margin-top: 5px;
  padding-bottom: 10px;
`;

export const Response = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.palette.words.title.natural};
    word-break: break-all;
    margin-top: 10px;
  `}
`;
