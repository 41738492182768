import styled, { css } from 'styled-components';
import Badge from '@mui/material/Badge';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  width: calc(100% - 60px);
  background-color: ${({ theme }) => theme.palette.system.overlay};
  box-shadow: 0px 5px 15px ${({ theme }) => theme.palette.system.divider};
  z-index: 998;
`;

const Container = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  width: 100%;
`;

const Alert = styled.div`
  ${({ theme, show }) => css`
    display: ${show ? 'flex' : 'none'};
    width: 100%;
    height: 50px;
    background: ${theme.palette.semantics.feedback.warning.natural}B3;
    border: 0.5px solid ${theme.palette.semantics.feedback.warning.natural};
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    a {
      cursor: pointer;
    }
  `}
`;

const AlertInfo = styled.div`
  ${({ theme, show }) => css`
    display: ${show ? 'flex' : 'none'};
    width: 100%;
    height: 50px;
    background: ${theme.palette.semantics.feedback.information.natural}B3;
    border: 0.5px solid ${theme.palette.semantics.feedback.information.natural};
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    .link {
      color: #007bff;
      text-decoration: underline;
      cursor: pointer;
      :hover {
        color: #0056b3;
      }
    }
  `}
`;

const AlertText = styled.div`
  display: inherit;
  gap: 0.5rem;
`;

const FiltersContent = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-right: 20px;

  .subTitle {
    margin-right: 10px;
    color: ${({ theme }) => theme.palette.words.subtitle.natural};
    font-weight: 600;
    opacity: 0.9;
    font-size: 17px;
  }
`;

const BackButton = styled.button`
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  border-radius: 20px;
  transition: all 0.2s;
  margin-right: 10px;

  :hover {
    background: ${({ theme }) => theme.palette.system.highlight};
  }

  :active {
    background: ${({ theme }) => theme.palette.brand.primary.background};
  }

  h2 {
    color: ${({ theme }) => theme.palette.words.subtitle.natural};
    font: normal normal 600 16px/18px Texta;
  }
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Space = styled.div`
  width: 10px;
`;

const Status = styled.span`
  color: ${props => props.color};
  background-color: ${props => props.background};
  margin-left: 15px;
  padding: 2px 20px;
  border-radius: 20px;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 14px;
`;

const StyledBadge = styled(Badge)`
  ${({ theme }) => css`
  & .MuiBadge-badge: {
    backgroundColor: ${theme.palette.semantics.feedback.success.natural},
    color: ${theme.palette.semantics.feedback.success.natural},
    boxShadow: 0 0 0 2px ${theme.palette.background.paper},
    &::after: {
      position: absolute,
      top: 0,
      left: 0,
      width: 100%,
      height: 100%,
      borderRadius: 50%,
      animation: ripple 1.2s infinite ease-in-out,
      border: 1px solid currentColor,
      content: "",
    },
    @keyframes ripple: {
      0%: {
        transform: scale(.8),
        opacity: 1,
      },
      100%: {
        transform: scale(2.4),
        opacity: 0,
      },
    }
  }`}
`;

const OnisysCard = styled.div`
  display: flex;
  width: 39px;
  height: 39px;
  background-color: ${props => props.color};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
`;

const TitleGuia = styled.div`
  text-align: left;
  font: normal normal bold 12px / 26px Texta;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.palette.words.text.light};
  text-transform: uppercase;
  opacity: 1;
  margin-left: 16px;
`;

const Label = styled.div`
  color: var(--main-titles);
  text-align: left;
  font: normal normal medium 16px/26px Texta;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.palette.words.label.natural};
  opacity: 1;
  margin-left: 12px;
`;

const Upgrade = styled.div`
  ${({ theme }) => css`
  margin-left: 10px;
  width: 62px;
  height: 19px;
  background: ${theme.palette.semantics.feedback.information.light}; 0% 0% no-repeat padding-box;
  border-radius: 9999px;
  opacity: 1;
  color: ${theme.palette.semantics.feedback.information.natural};
  font-weight: bold;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`}
`;

// material ui styling
const KeyboardArrowStyling = {
  background: '#fff',
  borderRadius: 100,
  width: 16,
  height: 16,
};

const menu_St = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

const StyleButtonItem = {
  width: 300,
  '&.Mui-selected': {
    backgroundColor: '#EDF2F9',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#EDF2F9',
  },
  ':hover': {
    backgroundColor: '#EDF2F9',
  },
};

export {
  Alert,
  AlertText,
  Container,
  Wrapper,
  FiltersContent,
  LeftContent,
  RightContent,
  BackButton,
  TitleWrapper,
  Space,
  StyledBadge,
  OnisysCard,
  Label,
  menu_St,
  TitleGuia,
  Upgrade,
  KeyboardArrowStyling,
  StyleButtonItem,
  Status,
  AlertInfo,
};
