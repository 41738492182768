import { getDesviosTipos, getStatus } from 'constants/_SERVICES/provider';

// Pages
import CapacitacaoProvider from 'pages/Provider/Capacitacao';
import CapacitacaoTurmasProvider from 'pages/Provider/Capacitacao/Turmas';
import ViewTurmaProvider from 'pages/Provider/Capacitacao/Turmas/viewTurma';
import DetalhesConteudoCapacitacaoProvider from 'pages/Provider/Capacitacao/Config';
import AutomacaoCapacitacao from 'pages/AutomacaoCapacitacao';
import ConfiguracaoAutomacaoCapacitacao from 'pages/AutomacaoCapacitacao/Configuracao';
import DetalhesAutomacaoCapacitacao from 'pages/AutomacaoCapacitacao/Detalhes';
import Historico from 'pages/Historico';

export default {
  '/capacitacao': {
    defaults: [],
    pageFilterName: 'filterCapacitacaoProvider',
    filters: [
      {
        name: 'status',
        placeholder: 'Filtrar por Status',
        mode: 'multiple',
        data: async () => getStatus(),
      },
    ],
    page: <CapacitacaoProvider />,
  },

  '/capacitacao/criar': {
    date: false,
    defaults: [],
    back: -1,
    title: 'Novo Conteúdo',
    subTitle: 'Conteúdo',
    id: true,
    page: <DetalhesConteudoCapacitacaoProvider />,
  },

  '/capacitacao/editar/:id': {
    defaults: [],
    back: -1,
    title: 'Conteúdo',
    id: true,
    status: true,
    page: <DetalhesConteudoCapacitacaoProvider />,
  },

  '/capacitacao/turmas': {
    defaults: [],
    back: -1,
    title: 'Turmas de transportadoras',
    page: <CapacitacaoTurmasProvider />,
  },

  '/capacitacao/viewTurmas/:id': {
    defaults: [],
    back: -1,
    title: 'Adicionar transportadoras',
    subTitle: 'Turmas',
    id: true,
    page: <ViewTurmaProvider />,
  },

  '/capacitacao/automacao': {
    title: '',
    defaults: ['filials', 'empresas'],
    pageFilterName: 'filterCapacitacaoAutomacao',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Tipo',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getDesviosTipos(),
      },
    ],
    date: true,
    page: <AutomacaoCapacitacao />,
  },

  '/automacao/configuracoes': {
    title: 'Configurar automações',
    defaults: [],
    page: <ConfiguracaoAutomacaoCapacitacao />,
  },

  '/automacao/capacitacao/detalhes/:id': {
    title: 'Envio automático',
    defaults: [],
    page: <DetalhesAutomacaoCapacitacao />,
    back: -1,
  },

  '/logs/automacao/capacitacao/:id': {
    back: -1,
    title: 'Histórico da Automação de Conteúdo',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/capacitacao/usuario-conteudo/:id/logs"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },
};
