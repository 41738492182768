import { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';

function useBackgroundColor(name) {
  const theme = useTheme();
  const [background, setBackground] = useState('');
  useEffect(() => {
    if (name) {
      if (
        name.toLowerCase().trim() === 'primária' ||
        name.toLowerCase().trim() === 'preenchida' ||
        name.toLowerCase().trim() === 'iniciado'
      ) {
        setBackground(theme.palette.semantics.feedback.information.light);
      } else if (
        name.toLowerCase().trim() === 'moderada' ||
        name.toLowerCase().trim() === 'analise' ||
        name.toLowerCase().trim() === 'aprovação pendente'
      ) {
        setBackground(theme.palette.semantics.feedback.warning.light);
      } else if (name.toLowerCase().trim() === 'grave') {
        setBackground(theme.palette.semantics.feedback.attention.light);
      } else if (
        name.toLowerCase().trim() === 'aberto' ||
        name.toLowerCase().trim() === 'assinada' ||
        name.toLowerCase().trim() === 'concluído'
      ) {
        setBackground(theme.palette.semantics.feedback.success.light);
      } else if (
        name.toLowerCase().trim() === 'arquivada' ||
        name.toLowerCase().trim() === 'deletada'
      ) {
        setBackground(theme.palette.brand.primary.background);
      } else if (name.toLowerCase().trim() === 'finalizado') {
        setBackground(theme.palette.semantics.feedback.success.light);
      } else {
        setBackground(theme.palette.semantics.feedback.attention.light);
      }
    }
  }, [name]);

  return background;
}

export default useBackgroundColor;
