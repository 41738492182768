import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 90px;
  align-items: center;
  padding-inline: 30px;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
`;

export const Label = styled.label`
  text-align: left;
  font-weight: 900;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.words.label.natural};
  opacity: 1;
  margin-left: 12px;
`;
