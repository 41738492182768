export const columnsTransportadoras = [
  {
    Header: 'Id',
    id: 'id',
    accessor: d => String(d.id),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Nome',
    id: 'nome',
    accessor: d => String(d.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: false,
  },
  {
    Header: 'Atividades Relacionadas',
    id: 'atividades',
    accessor: d => String(d.atividades_relacionadas),
    show: true,
    disableSortBy: true,
    centered: true,
  },
  {
    Header: 'Status',
    id: 'ativo',
    accessor: d => String(d.status),
    show: true,
    disableSortBy: true,
    centered: true,
  },
  {
    Header: 'Ações',
    id: 'acoes',
    accessor: 'acoes',
    show: true,
    disableSortBy: true,
  },
];
