// Styled

// React
import { useEffect, useState } from 'react';

// Components
import Calendar from 'components/Inputs/Calendar';

// Components MUI
import { Divider } from '@mui/material';
import { WithQuestion } from '..';
import * as S from './styled';

// Utils
import { changeText } from '../../actions';
import { parse } from 'date-fns';

export const ResponseData = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosObrigatorios,
  preview,
  approveView,
}) => {
  const redirectFunction = (texto, idCampo) => {
    setCleanField(true);
    changeText(texto, idCampo, setSecoes, secoes, sectionId);
  };

  // ------------------------------------ERROS-------------------------------------------//
  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [cleanField, setCleanField] = useState(false);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setCleanField(false);
    if (approveView) return null;

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }
  }, [errosObrigatorios]);

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanField}
        className={`fieldClass-${field.id}`}
      >
        <WithQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanField}
          preview={preview}
          approveView={approveView}
        />
        <S.ResponseArea>
          <Calendar
            value={parse(field.resposta, 'dd/MM/yyyy', new Date())}
            onChange={e => redirectFunction(e, field.id)}
            futureDate
            pastDate={false}
            noAsterisk
            style={{
              width: preview ? '100%' : '50%',
              maxWidth: preview ? 'auto' : '150px',
            }}
            error={hasErrorObrigatorio && !cleanField}
            disabled={approveView}
          />
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
