export const fields = [
  {
    label: 'Ativo',
    value: 'ativo',
    selected: true,
    default: false,
  },
  {
    label: 'Titulo',
    value: 'titulo',
    selected: true,
    default: false,
  },
  {
    label: 'Velocidade Máxima',
    value: 'violacoes.limiar',
    selected: true,
    default: false,
  },
  {
    label: 'Intervalo',
    value: 'violacoes.intervalo_tempo',
    selected: true,
    default: false,
  },
  {
    label: 'Pontuação',
    value: 'violacoes.pontos',
    selected: true,
    default: false,
  },
  {
    label: 'Tipo de veículo',
    value: 'tipo_veiculo.tipo',
    selected: true,
    default: false,
  },
  {
    label: 'Cliente',
    value: 'distribuidora.nome',
    selected: true,
    default: false,
  },
  {
    label: 'Pista Molhada',
    value: 'pista_molhada',
    selected: true,
    default: false,
  },
  {
    label: 'Carregado',
    value: 'carregado',
    selected: true,
    default: false,
  },
];

export const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = true;
    return i;
  });
};
