import styled from 'styled-components';
import { Button } from '@mui/material';

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 270px;
`;

const DateDisplay = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.palette.system.border};
  border-radius: 4px;
  height: 38px;
  width: 120px;
  padding: 5px;
`;

const StyledButton = styled(Button)`
  && {
    color: ${props => props.textcolor};
    background: ${props => props.backgroundcolor};
    border: 1px solid ${props => props.textcolor};
    font: normal normal 400 16px/24px Texta;
    height: ${props => props.height || '38px'};
    padding: 5px 15px;
    transition: opacity 300ms;

    :hover {
      background: ${props => props.backgroundcolor};
      opacity: 0.7;
    }
  }
`;

export { Main, Footer, ItemsWrapper, DateDisplay, StyledButton };
