import React, { useState } from 'react';
import { Container, Filter, FlexWrapper, GridWrapper } from './styled';
import PlanCard from './PlanCard';
import { plans, features } from './constants';
import Features from './Features';

const Plans = () => {
  const [provider, setProvider] = useState(true);

  return (
    <Container>
      <h1>Conheça nossos planos</h1>
      <FlexWrapper justify="center">
        <Filter active={provider} onClick={() => setProvider(true)}>
          Embarcador
        </Filter>
        <Filter active={!provider} onClick={() => setProvider(false)}>
          Transportador
        </Filter>
      </FlexWrapper>
      <GridWrapper justify="space-around">
        {plans.map(plan => (
          <PlanCard key={plan.id} data={plan} provider={provider} />
        ))}
      </GridWrapper>
      <Features provider={provider} plans={plans} features={features} />
    </Container>
  );
};

export default Plans;
