import React, { forwardRef } from 'react';
import { Header } from '../Header';

import * as S from './styled';
import { RoundedTabs } from 'components/RoundedTabs';
import { tabs } from '../../constants';
import { usePlans } from 'hooks/usePlans';
import { Estatisticas } from '../Estatisticas';
import { dateMap } from '../Estatisticas';
import { useSelector } from 'react-redux';
import { TableToPrint } from 'components/TableToPrint';
import { columnsDesvios, columnsAcoes, columnsFrota } from './constants';

export const PDF = forwardRef(
  (
    {
      id,
      filters,
      period,
      printTabs = [],
      dataDesvios = [],
      dataAcoes = [],
      dataFrota = [],
      totalInativos = 0,
      setLoadingData,
    },
    ref,
  ) => {
    const { planosAtivos } = usePlans();

    const selects = useSelector(state => state.selects);

    const filial = filters?.filial?.length
      ? filters?.filial.map(
          f =>
            selects.empresas
              .find(empresa => empresa.id === +id)
              ?.filiais?.find(i => i.id === +f)?.nome,
        ) || null
      : null;

    return (
      <S.HiddenContainer>
        <S.Container ref={ref}>
          <Header id={id} print />
          <br />
          {printTabs.includes('estatisticas') && (
            <>
              <RoundedTabs
                items={tabs.filter(
                  tab =>
                    !tab.plans ||
                    planosAtivos.some(plan =>
                      tab.plans.includes(plan.id_do_plano),
                    ),
                )}
                value="estatisticas"
              />
              <S.FilterContainer>
                {period && (
                  <S.FilterCard>Últimos {dateMap[period]}</S.FilterCard>
                )}
                {filial && <S.FilterCard>{filial}</S.FilterCard>}
              </S.FilterContainer>
              <Estatisticas
                filters={filters}
                period={period}
                setLoadingData={setLoadingData}
                print
              />
            </>
          )}
          {printTabs.includes('desvios') && (
            <>
              {printTabs.length > 1 && <div className="page-break" />}
              <RoundedTabs
                items={tabs.filter(
                  tab =>
                    !tab.plans ||
                    planosAtivos.some(plan =>
                      tab.plans.includes(plan.id_do_plano),
                    ),
                )}
                value="desvios"
              />
              <S.FilterContainer>
                {period && (
                  <S.FilterCard>Últimos {dateMap[period]}</S.FilterCard>
                )}
                {filial && <S.FilterCard>{filial}</S.FilterCard>}
              </S.FilterContainer>
              <TableToPrint columns={columnsDesvios} data={dataDesvios} />
            </>
          )}
          {printTabs.includes('acoes') && (
            <>
              {printTabs.length > 1 && <div className="page-break" />}
              <RoundedTabs
                items={tabs.filter(
                  tab =>
                    !tab.plans ||
                    planosAtivos.some(plan =>
                      tab.plans.includes(plan.id_do_plano),
                    ),
                )}
                value="acoes"
              />
              <S.FilterContainer>
                {period && (
                  <S.FilterCard>Últimos {dateMap[period]}</S.FilterCard>
                )}
                {filial && <S.FilterCard>{filial}</S.FilterCard>}
              </S.FilterContainer>
              <TableToPrint columns={columnsAcoes} data={dataAcoes} />
            </>
          )}
          {printTabs.includes('frota') && (
            <>
              {printTabs.length > 1 && <div className="page-break" />}
              <RoundedTabs
                items={tabs.filter(
                  tab =>
                    !tab.plans ||
                    planosAtivos.some(plan =>
                      tab.plans.includes(plan.id_do_plano),
                    ),
                )}
                value="frota"
              />
              <S.FilterContainer>
                {period && (
                  <S.FilterCard>Últimos {dateMap[period]}</S.FilterCard>
                )}
                {filial && <S.FilterCard>{filial}</S.FilterCard>}
              </S.FilterContainer>
              <TableToPrint
                columns={columnsFrota}
                data={dataFrota}
                total={
                  <span>
                    Total:{' '}
                    <span
                      style={{ color: '#F64E60' }}
                    >{`(${totalInativos} inativos)`}</span>{' '}
                  </span>
                }
              />
            </>
          )}
        </S.Container>
      </S.HiddenContainer>
    );
  },
);
