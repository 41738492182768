/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { memo, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Components
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { ListItem, Tooltip } from '@mui/material';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

export const ModalSort = ({
  open,
  handleClose,
  data,
  handleSave,
  section,
  title,
}) => {
  const [items, setItems] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setItems(data);
  }, [data]);

  // ------------------------DRAG AND DROP------------------------//
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;
    const reordered = reorder(items, source.index, destination.index);
    setItems(reordered);
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    const ordered = items?.map((item, index) => ({
      ...item,
      ordem: index + 1,
    }));

    const success = await handleSave(
      section ? { ...section, guia: ordered } : ordered,
    );
    setLoading(false);

    success && handleClose();
  };

  const DraggableListItem = ({ item, index }) => {
    return (
      <Draggable draggableId={String(item.id)} index={index}>
        {provided => (
          <ListItem
            component="div"
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <S.LineSection
              key={index}
              active={item.ativa}
              visible={item.listar}
            >
              <p {...provided.dragHandleProps}>
                <MenuOutlinedIcon />
              </p>
              <div className="title">{item.titulo}</div>
              <div className="sts">
                <Tooltip title={item.ativa ? 'Ativo' : 'Inativo'}>
                  <div className="ball" />
                </Tooltip>
              </div>
            </S.LineSection>
          </ListItem>
        )}
      </Draggable>
    );
  };

  const DraggableList = memo(({ items, onDragEnds }) => {
    return (
      <DragDropContext onDragEnd={onDragEnds}>
        <Droppable droppableId="droppable-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items?.map((item, index) => (
                <DraggableListItem
                  item={item}
                  index={index}
                  key={String(item.id)}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  });

  // ------------------------RETURN------------------------//
  return (
    <S.ModalMUI open={open}>
      <S.Container>
        <S.Header>
          <div className="left">
            <FlipToFrontIcon />
            <h1>{title}</h1>
          </div>
          <CloseRoundedIcon onClick={() => handleClose()} />
        </S.Header>
        <S.Content>
          <DraggableList items={items || []} onDragEnds={onDragEnd} />
        </S.Content>
        <S.Footer>
          <GhostButton children="Cancelar" onClick={() => handleClose()} />
          <DefaultButton
            children="Salvar"
            onClick={() => handleSaveChanges()}
            loading={loading}
            disabled={!items?.length}
          />
        </S.Footer>
      </S.Container>
    </S.ModalMUI>
  );
};
