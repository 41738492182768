import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 320px;
  .container-filters {
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding-left: 65px;
    padding-right: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    .title {
      text-align: left;
      font-size: 24px;
      font-weight: 900;
      font-style: normal;
      letter-spacing: 0px;
      color: #4b5166;
      padding-right: 20px;
    }
    .flex-gap {
      display: flex;
      gap: 1rem;
    }
  }
`;
