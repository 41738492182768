import styled, { css } from 'styled-components';

export const Container = styled.div`
  .boxLoading {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
`;

export const Paragraph = styled.p`
  ${({ theme, ...props }) => css`
    font-size: ${props.fontsize ? props.fontsize : '16px'};
    color: ${props.color ? props.color : theme.palette.brand.primary.light};
    font-weight: ${props.fontweight ? props.fontweight : 400};
    min-width: ${props.minwidth};

    &.email {
      text-transform: lowercase;
    }
  `}
`;

export const ColDriver = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    ${({ pontuacao }) =>
      pontuacao &&
      `
    h1{
      color: ${theme.palette.semantics.feedback.warning.natural} !important;
    }
  `}

    h1 {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
    }

    img {
      width: 41px;
      height: 41px;
      border: 1px solid ${theme.palette.system.border};
      border-radius: 100px;
    }

    .css-6hp17o-MuiList-root-MuiMenu-list {
      z-index: 9999;
    }

    &.levelItem {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  `}
`;

export const ItemPopover = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.words.title.natural};
    font-size: 16px;
    font-weight: 600;
  `}
`;
