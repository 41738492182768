import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import ListPage from '../../components/ListPage';

// Services
import { trackEvent } from 'utils/mixpanel';
import { requestFormulariosList, requestCards } from './services';
import ModalSign from 'pages/Fomularios/components/ModalSign';
import {
  columnsFilled,
  columnsPending,
  columnsOccurrencies,
  statusTabs,
} from './constants';

import { usePlans } from 'hooks/usePlans';
import { getFormResponse } from 'pages/Fomularios/Config/Reports/services';

export const Formularios = () => {
  // Redux e hooks
  const params = useParams();
  const { isProvider } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);
  const filter = useSelector(state =>
    isProvider ? state.filterProvider : state.filter,
  );

  // General States
  const [query, setQuery] = useState(null);
  const [status, setStatus] = useState('PREENCHIDOS');
  const [resetTable, setResetTable] = useState(false);
  const [loadingTab, setLoadingTab] = useState(true);
  const [loadingLines, setLoadingLines] = useState([]);

  const [formHist, setFormHist] = useState(null);
  const [modalView, setModalView] = useState(false);
  const [currentHist, setCurrentHist] = useState(null);
  const [currentForm, setCurrentForm] = useState(null);

  const [selectedCard, setSelectedCard] = useState(null);

  // -------------------------TABS (TOP PAGE)---------------------------------------//
  const getCol = () => {
    switch (status) {
      case 'PREENCHIDOS':
        return columnsFilled;
      case 'PENDENTES':
        return columnsPending;
      case 'OCORRENCIAS':
        return columnsOccurrencies;
    }
  };

  const getActions = () => {
    switch (status) {
      case 'PREENCHIDOS':
        return [
          {
            title: 'Visualizar',
            function: id => handleOpenForm(id),
          },
          {
            title: 'Ver Ocorrência',
            function: id => handleOpenOccurrencyNewTab(id),
          },
        ];
      case 'PENDENTES':
        return null;
      case 'OCORRENCIAS':
        return [
          {
            title: 'Abrir em nova guia',
            function: id => id && window.open(`/formularios/ocorrencias/${id}`),
          },
          {
            title: 'Ver Formulário',
            function: id => handleOpenForm(id),
          },
        ];
    }
  };

  // -------------------------- REQUESTS ------------------------------------------//

  const {
    refetch: fetchData,
    isFetching,
    data: resData,
  } = useQuery(
    ['formularios', params?.id, query],
    () => query && requestFormulariosList(params?.id, query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        setLoadingTab(false);
        resetTable && setResetTable(false);
      },
    },
  );

  const data = resData?.data?.data || [];

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cards
  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    [
      'cards-formularios',
      params?.id,
      {
        ...filter,
        status,
      },
    ],
    () =>
      requestCards(params?.id, {
        ...filter,
        status,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, status, selectedCard]);

  // Troca do tab (status)
  const handleStatusTab = async (event, newValue) => {
    setSelectedCard(null);
    setLoadingTab(true);
    setStatus(newValue);
  };

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor válido: nao nulo e maior que 0.
  const handleClickCard = type => {
    if (!loadingCards && !isFetching) {
      const cards = resCards || [];
      const card = cards.find(item => item.type === type);
      if (card.value) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  const handleOpenForm = id => {
    const item = data.find(item => item.id === id);
    if (item) {
      getCurrentResponses(item);
    }
  };

  const handleOpenOccurrencyNewTab = id => {
    const item = data.find(item => item.id === id);
    if (item) {
      window.open(`/formularios/ocorrencias/${item.ocorrencia}`);
    }
  };

  // Configura as tabs da tela
  const headerTabs = {
    last: false,
    value: status,
    items: statusTabs,
    onChange: handleStatusTab,
    disabled: loadingCards || isFetching,
  };

  // ------------------------------------------------ MODAL VIEW CONTROL----------------------------------------//
  const getCurrentResponses = async currentItem => {
    let alunoTipo = 'MOTORISTA';

    if (currentItem?.justificado) {
      toast.warn(`Usuários justificados não possuem respostas!`);
      return null;
    }

    setLoadingLines([currentItem.id]);
    setCurrentHist(currentItem.histId);
    setCurrentForm(currentItem.formId);
    let res = await getFormResponse(
      currentItem.formId,
      currentItem.histId,
      currentItem.alunoId,
      alunoTipo,
    );
    if (res.success) {
      setFormHist(res);
      setModalView(true);
    } else {
      toast.error(res.message);
    }
    setLoadingLines([]);
  };

  return (
    <>
      <ListPage
        datePicker
        headerTabs={headerTabs}
        cards={
          resCards?.map(card => ({
            ...card,
            disabled: isFetching,
          })) || []
        }
        selectedCard={selectedCard}
        handleClickCard={handleClickCard}
        loadingCards={loadingCards}
        tableProps={{
          data,
          columns: getCol(),
          loading: isFetching || loadingTab,
          pageCount: resData?.data?.total || 0,
          visualizedKey: 'visto',
          local: false,
          actions: getActions(),
          reset: resetTable,
          loadingSelection: loadingLines,
          setQuery: q =>
            setQuery({
              ...q,
              ...filter,
              status,
              card: selectedCard,
            }),
          searchEvent: search =>
            trackEvent(user, 'Busca Formulário', null, search),
          sortBy: {
            id: 'data_concluido',
            order: 'DESC',
          },
          placeholder: `Buscar por Título`,
          empty: {
            title: 'Ops! Você não tem nenhum formulário.',
            description: 'Verifique os filtros selecionados.',
          },
        }}
      />
      {formHist && modalView && (
        <ModalSign
          open={modalView}
          onClose={() => {
            setModalView(false);
            setFormHist(false);
            setCurrentHist(null);
          }}
          form={formHist}
          histId={currentHist}
          refetch={getCurrentResponses}
          loading={loadingLines}
          backPath={`/formularios/configuracao/editar/${currentForm}}`}
        />
      )}
    </>
  );
};
