import styled, { css } from 'styled-components';

export const Content = styled.div`
  width: 80vw;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
`;

export const Table = styled.table`
  ${({ theme }) => css`
    width: 100%;
    border-collapse: collapse;
    color: ${theme.palette.words.subtitle.natural};
    font: normal normal 600 24px/33px Texta;

    thead {
      color: ${theme.palette.words.title.natural};
      font: normal normal 900 24px/33px Texta;
    }

    td {
      padding: 5px;
      :first-child {
        text-align: left;
      }
    }

    tbody tr:nth-child(odd) {
      border: 1px solid ${theme.palette.system.border};
    }
  `}
`;
