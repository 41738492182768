import api from 'services/api';

export const requestFiliais = () => {
  const res = api.get('/configuracoes/filiais');
  return res;
};

export const createFilial = async (nome, status) => {
  const res = await api.post('/configuracoes/create_filial', { nome, status });
  return res;
};

export const updateFilial = async (id, nome, status) => {
  const res = await api.put('/configuracoes/update_filial', {
    id: [id],
    nome,
    status,
  });
  return res;
};

export const changeStatus = async (ids, status) => {
  const res = await api.put('/configuracoes/update_filial', {
    id: ids,
    status,
  });
  return res;
};
