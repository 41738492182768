import React from 'react';
import CheckFilterImage from 'images/check_filter.png';
import * as S from './styled';

const CheckFiltersCar = ({ title, subtitle, height, marginTop }) => {
  return (
    <S.Container height={height} marginTop={marginTop}>
      <h1>{title || 'Nenhum dado retornado'}</h1>
      <p>
        {subtitle ||
          'Ainda não há dados suficientes para esta busca. Favor verificar seus filtros'}
      </p>
      <S.Image src={CheckFilterImage} alt="Imagem Verificar Filtros" />
    </S.Container>
  );
};

export default CheckFiltersCar;
