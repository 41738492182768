import React from 'react';

import { SaveAltOutlined } from '@mui/icons-material';
import { Divider } from '@mui/material';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';

import * as S from './styled';
import { formatRelativeDate } from 'utils/dates';

export const Header = ({
  isLoading,
  title,
  foto = '',
  ultima_tratativa,
  ultima_revisao,
  loading = true,
  handleExport = () => {},
}) => {
  return (
    <S.Container>
      <div>
        <S.Avatar>
          <S.Circle>
            {isLoading ? (
              <Loading />
            ) : foto ? (
              <img src={data.foto} alt="Avatar" />
            ) : (
              <span>{title ? title.charAt(0) : ''}</span>
            )}
          </S.Circle>
        </S.Avatar>
      </div>

      <S.Content>
        <S.Flex className="full-width gap-1 align-center justify-between">
          <S.Title>{title}</S.Title>

          <GhostButton
            startIcon={<SaveAltOutlined />}
            size="medium"
            loading={loading}
            onClick={() => handleExport()}
          >
            EXPORTAR
          </GhostButton>
        </S.Flex>
        <Divider />
        {ultima_tratativa !== undefined && (
          <S.Flex className="mt-05 gap-05">
            Última tratativa:
            {ultima_tratativa ? (
              <div className="bold">
                {formatRelativeDate(new Date(ultima_tratativa))}
              </div>
            ) : (
              '-'
            )}
            <S.StatusCircle success />
          </S.Flex>
        )}
        {ultima_revisao !== undefined && (
          <S.Flex className="mt-05 gap-05">
            Última revisão:
            {ultima_revisao ? (
              <div className="bold">
                {formatRelativeDate(new Date(ultima_revisao))}
              </div>
            ) : (
              '-'
            )}
            <S.StatusCircle success />
          </S.Flex>
        )}
      </S.Content>
    </S.Container>
  );
};
