import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 90%;
  max-width: 50rem;
  margin: auto;
  padding-bottom: 3rem;

  .map {
    height: 480px;
  }
`;

export const Title = styled.div`
  margin: 1rem 0;
`;

export const FormWrapper = styled.div`
  margin: 1rem 0;
`;

export const Section = styled.h2`
  ${({ theme }) => css`
    margin-top: 1rem;
    font: normal normal 900 20px/20px Texta;
    color: ${theme.palette.words.subtitle.natural};
  `}
`;
