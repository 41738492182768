import React from 'react';
import * as S from './styled';

import { formatNewHour } from 'utils/dates';

const Card1 = ({ item, color }) => {
  return (
    <S.Container>
      <div className="criticidade" style={{ color }}>
        <svg width="12" height="12" viewBox="0 0 24 30.581">
          <path
            d="M12,0A12.092,12.092,0,0,0,0,12.154c0,8.69,10.8,17.778,11.263,18.159a1.147,1.147,0,0,0,1.474,0C13.2,29.931,24,20.843,24,12.154A12.092,12.092,0,0,0,12,0m0,18.035a5.881,5.881,0,1,1,5.806-5.881A5.852,5.852,0,0,1,12,18.035"
            fill={color}
          />
          <ellipse
            id="Elipse_391"
            data-name="Elipse 391"
            cx="6.6"
            cy="6.843"
            rx="6.6"
            ry="6.843"
            transform="translate(5.434 5.338)"
            fill="#fff"
          />
        </svg>
        {String(item.criticidade).toLowerCase()}
      </div>
      <div className="tipo-desvio" title={item.desvio}>
        {item.desvio}
      </div>
      <p>{formatNewHour(item.data_desvio)}</p>
    </S.Container>
  );
};

export const Card = React.memo(Card1);
