import api from 'services/api';

export const requestBasic = async id => {
  const res = await api.get(`/empresas/${id}`);
  return res?.data?.data ?? null;
};

export const requestSelo = async id => {
  const res = await api.get(`/empresas/${id}/selo`);
  return res?.data?.data ?? null;
};

export const requestPosRanking = async id => {
  const res = await api.get(`/empresas/${id}/ranking`);
  return res?.data?.data ?? null;
};
