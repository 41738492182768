import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    height: 52px;
    border: 1px dashed ${theme.palette.brand.primary.light};
    border-radius: 5px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${theme.palette.words.title.natural};
    cursor: pointer;

    span {
      margin-left: 10px;
    }

    &:hover {
      opacity: 0.8;
    }
  `}
`;
