import styled, { css } from 'styled-components';

export const Filters = styled.div`
  width: 100%;

  .flex {
    display: flex;
  }
  .flex-1 {
    flex: 1;
  }
  .gap-1 {
    gap: 1rem;
  }
  .my-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .justify-between {
    justify-content: space-between;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  position: relative;

  .searchIcon {
    position: absolute;
    top: 8px;
    left: 8px;
  }
`;

export const SearchInput = styled.input`
  ${({ theme }) => css`
    padding: 4px 8px 4px 38px;
    border: none;
    border: 1px solid ${theme.palette.system.divider};
    border-radius: 4px;
    font-size: 1rem;
    height: 2.5rem;

    &::placeholder {
      font-size: 1rem;
    }

    &:focus {
      border: 1px solid ${theme.palette.brand.secondary.natural};
    }
  `}
`;
