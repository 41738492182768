import styled from 'styled-components';

export const Button = styled.div`
  cursor: pointer;
  display: flex;
  width: 13rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border: 1px solid ${({ active }) => (active ? '#95aac9' : '#95aac94d')};
  border-radius: 4px;
  font: normal normal normal 16px/24px Texta;
  color: #656e8c;

  :hover {
    border: 1px solid #95aac9;
  }
`;
export const Item = styled.div`
  cursor: pointer;
  display: flex;
  width: 12rem;
  align-items: center;
  justify-content: space-between;
  margin: 4px 8px;
  padding: 0.5rem 0.5rem;
  border: 1px solid ${({ active }) => (active ? '#BDCC30' : '#95aac94d')};
  border-radius: 4px;
  font: normal normal normal 16px/24px Texta;
  color: ${({ active }) => (active ? '#BDCC30' : '#656e8c')};

  :hover {
    border: 1px solid #bdcc30;
    color: #bdcc30;
  }
`;
