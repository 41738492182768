import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

import { ReactComponent as AlertIcon } from 'images/icons/alertas/alerta.svg';

import { Header, Label } from './styled';
import { CardAlert } from '../Alertas/CardAlert';

const AlertsContent = React.memo(({ data, onClose }) => {
  const alertas = data;
  return (
    <Box sx={{ width: 529, maxWidth: 460, bgcolor: 'background.paper' }}>
      <Header>
        <div className="title">
          <AlertIcon fill="#4B5166" />
          <Label>Alertas</Label>
        </div>

        <IconButton onClick={() => onClose()}>
          <CloseIcon sx={{ color: '#4B5166' }} />
        </IconButton>
      </Header>
      <Divider light />
      <List>
        {alertas.map(alerta => (
          <ListItem>
            <CardAlert data={alerta} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
});

export default AlertsContent;
