import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 24px;
    color: ${theme.palette.brand.primary.natural};
    font-weight: 900;
    text-transform: capitalize;
  `}
`;
