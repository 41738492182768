import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { IconButton } from '@mui/material';
import {
  NavigateBeforeRounded,
  NavigateNextRounded,
} from '@mui/icons-material';
import Loading from 'components/Loading';

import * as S from './styled';
import { fetchSequence } from '../../services';

export const NextPrev = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const index = query.get('index');
  const params = useParams();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth?.user);
  const isProvider = user?.provider;

  const filter = useSelector(state =>
    isProvider ? state.filterProvider : state.filter,
  );

  const {
    data: sequenceData,
    isLoading,
    refetch: refetchSequence,
  } = useQuery(
    ['acidentes_sequence', params.id, index, filter],
    () =>
      fetchSequence(index, {
        initialDate: filter.initialDate,
        finalDate: filter.finalDate,
      }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (index) refetchSequence();
  }, [index]);

  return isLoading ? (
    <S.BoxLoading>
      <Loading />
    </S.BoxLoading>
  ) : (
    <>
      <IconButton
        disabled={!sequenceData?.id_previous}
        onClick={() =>
          navigate(
            `/acidentes/${sequenceData.id_previous}?index=${sequenceData.index_prev}`,
          )
        }
      >
        <NavigateBeforeRounded />
      </IconButton>

      <IconButton
        disabled={!sequenceData?.id_next}
        onClick={() =>
          navigate(
            `/acidentes/${sequenceData.id_next}?index=${sequenceData.index_next}`,
          )
        }
      >
        <NavigateNextRounded />
      </IconButton>
    </>
  );
};
