import Avatar from '@mui/material/Avatar';

const columnsConquistas = [
  {
    Header: 'Título',
    id: 'titulo',
    accessor: d => String(d.conteudo.responsavel.titulo || ''),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Autor',
    id: 'autor',
    accessor: d => String(d?.conteudo.responsavel.nome || ''),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Data da conclusão',
    id: 'conclusao',
    accessor: d => String(d?.createdAt || ''),
    show: true,
    disableSortBy: true,
    centered: true,
  },
  {
    Header: 'Coeficiente atingido',
    id: 'coeficiente',
    show: true,
    accessor: d => String(d?.nota?.nota || ''),
    disableSortBy: true,
    centered: true,
  },
  {
    Header: 'Validade',
    id: 'validade',
    accessor: d => String(d?.validade_inicio),
    show: true,
    disableSortBy: true,
    centered: true,
  },
  {
    Header: 'Ações',
    id: 'acoes',
    accessor: 'acoes',
    show: true,
    disableSortBy: true,
  },
];

export { columnsConquistas };
