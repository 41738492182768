// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGerais } from 'store/modules/formularios/actions';

// Components
import { Switch } from 'components/Inputs/Switch';
import TextInput from 'components/Inputs/TextField';

// Material
import { Tooltip } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import Radio from '../../Fields/Components/Radio';
import * as S from './styled';

// Utils
import { steps } from '../constants';
import * as services from '../services';

export const Configuracoes = ({
  trySave,
  setTrySave,
  setChanged,
  setLoading,
  setCurrentStep,
  setFormPrev,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [obrigatorio, setObrigatorio] = useState(false);
  const [permitir_atraso, setPermitirAtraso] = useState(true);

  const [hasOcurrence, setHasOcurrence] = useState(false);
  const [hasRepeat, setRepeat] = useState(false);

  const [hasApprove, setHasApprove] = useState(false);
  const [collApprove, setCollAprove] = useState(false);
  const [responsavel_aprovar, setResponsavelAprovar] = useState(null);

  const [collTime, setCollTime] = useState(false);
  const [prazo_aprovacao, setPrazoAprovacao] = useState('');

  const [collOccurence, setCollOcurrence] = useState(false);
  const [responsavel_ocorrencia, setResponsavelOcorrencia] = useState(null);

  const [collTratar, setCollTratar] = useState(false);
  const [tratar_ocorrencias, setTratarOcorrencias] = useState('');

  const users = useSelector(state =>
    state.selects.users.filter(user => user.status === 'ATIVO'),
  );
  const formularios = useSelector(state => state.formularios);

  const checkNumberMonitoring = (func, value) => {
    if ((Number(value) && Number(value) < 999) || !value) {
      func(value);
      setChanged(true);
    }
  };

  // Set initial state
  useEffect(() => {
    let hasOc = false;
    const { secoes } = formularios;
    for (const i in secoes) {
      const { campos } = secoes[i];
      for (const j in campos) {
        const { opcoes } = campos[j];
        for (const k in opcoes) {
          if (opcoes[k].gera_ocorrencia) {
            hasOc = true;
          }
        }
      }
    }

    const { gerais } = formularios;
    setObrigatorio(!!gerais.obrigatorio);
    setPermitirAtraso(!!gerais.permitir_atraso);
    setRepeat(gerais.repetir);

    const userApprove = users.find(
      user => user.id === gerais.id_responsavel_aprovador,
    );
    setResponsavelAprovar(userApprove);
    setHasApprove(gerais.aprovador);
    setPrazoAprovacao(gerais.prazo_aprovacao);

    const userOcurrence = users.find(
      user => user.id === gerais.id_responsavel_ocorrencia,
    );
    setResponsavelOcorrencia(userOcurrence);
    setTratarOcorrencias(gerais.prazo_ocorrencia);

    if (hasOc && (!userOcurrence || !gerais.prazo_ocorrencia)) {
      setChanged(true);
      setCollTratar(true);
      setCollOcurrence(true);
    }

    if (
      gerais.aprovador &&
      (gerais.id_responsavel_aprovador || gerais.prazo_aprovacao)
    ) {
      setChanged(true);
    }

    setHasOcurrence(hasOc);
  }, [formularios]);

  // --------------------------------------SAVE CONFIGS-------------------------------------------//
  useEffect(() => {
    if (trySave) {
      setCollAprove(false);
      setCollTime(false);
      setCollOcurrence(false);
      setCollTratar(false);

      if (hasApprove && !responsavel_aprovar) {
        toast.error('Selecione um responsável pela aprovação');
        setCollAprove(true);
        setTrySave(false);
        return;
      }

      if (hasApprove && !prazo_aprovacao) {
        toast.error('Insira o prazo para aprovação do formulário');
        setCollTime(true);
        setTrySave(false);
        return;
      }

      if (hasOcurrence && !responsavel_ocorrencia) {
        toast.error('Selecione um responsável pelas ocorrências');
        setCollOcurrence(true);
        setTrySave(false);
        return;
      }

      if (hasOcurrence && !tratar_ocorrencias) {
        toast.error('Insira o prazo para tratar as ocorrências');
        setCollTratar(true);
        setTrySave(false);
        return;
      }

      const data = {
        pagina: 'configuracoes',
        id: formularios.id,
        obrigatorio,
        aprovador: hasApprove,
        id_responsavel_aprovador: responsavel_aprovar?.id,
        prazo_aprovacao: Number(prazo_aprovacao),
        permitir_atraso,
        ocorrencias: hasOcurrence,
        id_responsavel_ocorrencia: responsavel_ocorrencia?.id,
        prazo_ocorrencia: Number(tratar_ocorrencias),
      };

      sendRequest(data);
    }
  }, [trySave]);

  const sendRequest = async data => {
    setLoading(true);
    const res = await services.SaveConfigs(data);
    if (res.success) {
      // TODO: Unificar dispatch
      dispatch(setGerais({ ...formularios.gerais, ...res.data }));
      toast.success(res.message);
      setChanged(false);
      setCurrentStep(steps[3]);
      setFormPrev(null);
    } else {
      toast.error(res.message);
    }

    setTrySave(false);
    setLoading(false);
  };

  return (
    <S.Box disabled={!formularios.isEditable}>
      <S.ContentLimit>
        <S.BoxConfigure>
          <div className="textArea">Formulário Obrigatório</div>
          <Switch
            check={obrigatorio}
            setCheckbox={value => {
              setObrigatorio(value);
              setChanged(true);
            }}
          />
        </S.BoxConfigure>

        <S.BoxConfigure repeat={hasRepeat} className="repetable">
          <div className="textArea">
            <span>Permitir Atraso</span>
            {hasRepeat && (
              <Tooltip
                className="tooltip"
                title="Permite que o usuário responda até a próxima repetição do formulário"
              >
                <HelpOutlineOutlinedIcon
                  htmlColor={theme.palette.semantics.feedback.unknown.natural}
                />
              </Tooltip>
            )}
          </div>

          <span
            onClick={
              !hasRepeat
                ? () =>
                    toast.error('Formulários sem repetição não permitem atraso')
                : undefined
            }
          >
            <Switch
              disabled={!hasRepeat}
              check={!hasRepeat ? false : permitir_atraso}
              setCheckbox={value => {
                setPermitirAtraso(value);
                setChanged(true);
              }}
            />
          </span>
        </S.BoxConfigure>

        <S.BoxCollapse hasOcurrence>
          <div className="clickColl">
            <div
              className="leftSide"
              onClick={() => setCollAprove(!collApprove)}
            >
              <div className="textArea">Habilitar aprovador</div>
              <ArrowForwardIosOutlinedIcon
                fontSize="18px"
                htmlColor={theme.palette.words.title.natural}
                style={{
                  transform: collApprove ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'all ease .5s',
                  marginLeft: '10px',
                  marginBottom: '-5px',
                  cursor: 'pointer',
                }}
              />
            </div>
            <div className="rigthSide">
              <div className="textArea">{responsavel_aprovar?.nome}</div>
              <Switch
                check={hasApprove}
                setCheckbox={value => {
                  setHasApprove(value);
                  setCollAprove(value);
                  setCollTime(value);
                  setChanged(true);
                }}
              />
            </div>
          </div>
          <S.CollapseMu in={collApprove} unmountOnExit>
            <div className="totalContentScroll">
              {users?.map((user, k) => (
                <S.CollapseItem
                  key={k}
                  onClick={() => {
                    setResponsavelAprovar(user);
                    setHasApprove(true);
                    setChanged(true);
                  }}
                  checked={responsavel_aprovar?.id === user.id}
                >
                  <div className="infoCheck">
                    <Radio
                      value={responsavel_aprovar?.id === user.id}
                      disabled
                    />
                    <div className="boxUser">
                      <div className="imageArea">
                        <span>{user.nome[0]}</span>
                      </div>
                      <div className="nameElement">{user.nome}</div>
                    </div>
                  </div>
                  <div className="locale">{user.filial.nome}</div>
                  <div className="workArea">{user.funcao}</div>
                </S.CollapseItem>
              ))}
            </div>
          </S.CollapseMu>
        </S.BoxCollapse>

        <S.BoxCollapse hasOcurrence={hasApprove}>
          <div className="clickColl">
            <div
              className="leftSide"
              onClick={hasApprove ? () => setCollTime(!collTime) : undefined}
            >
              <div className="textArea">Prazo para aprovação</div>
              <ArrowForwardIosOutlinedIcon
                fontSize="18px"
                htmlColor={theme.palette.words.title.natural}
                style={{
                  transform: collTime ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'all ease .5s',
                  marginLeft: '10px',
                  marginBottom: '-5px',
                  cursor: 'pointer',
                }}
              />
            </div>
            <div className="rigthSide">
              {!!prazo_aprovacao && (
                <div className="textArea number">{prazo_aprovacao} dia(s)</div>
              )}
              <span
                onClick={
                  hasApprove
                    ? () =>
                        toast.error(
                          'É necessário definir o prazo para aprovação',
                        )
                    : undefined
                }
              >
                <Switch check={hasApprove} disabled />
              </span>
            </div>
          </div>
          <S.CollapseMu in={collTime} unmountOnExit>
            <div className="boxTime">
              <TextInput
                label="Prazo em dias"
                style={{ width: '70px' }}
                value={prazo_aprovacao}
                onChange={e => {
                  checkNumberMonitoring(setPrazoAprovacao, e.target.value);
                  setChanged(true);
                }}
              />
              <span>dia(s)</span>
            </div>
          </S.CollapseMu>
        </S.BoxCollapse>

        <S.BoxCollapse hasOcurrence={hasOcurrence}>
          <div className="clickColl">
            <div
              className="leftSide"
              onClick={
                hasOcurrence
                  ? () => setCollOcurrence(!collOccurence)
                  : undefined
              }
            >
              <div className="textArea">Responsável pelas ocorrências</div>
              <ArrowForwardIosOutlinedIcon
                fontSize="18px"
                htmlColor={theme.palette.words.title.natural}
                style={{
                  transform: collOccurence ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'all ease .5s',
                  marginLeft: '10px',
                  marginBottom: '-5px',
                  cursor: 'pointer',
                }}
              />
            </div>

            <div className="rigthSide">
              <div className="textArea">{responsavel_ocorrencia?.nome}</div>
              <span
                onClick={
                  hasOcurrence
                    ? () =>
                        toast.error(
                          'Não é possivel desabilitar esse item, o formulário possui ocorrências nos campos',
                        )
                    : () =>
                        toast.error(
                          'Não é possivel habilitar esse item, o formulário não possui ocorrências nos campos',
                        )
                }
              >
                <Switch
                  check={hasOcurrence}
                  disabled
                  style={{ opacity: 0.5 }}
                />
              </span>
            </div>
          </div>
          <S.CollapseMu in={collOccurence} unmountOnExit>
            <div className="totalContentScroll">
              {users?.map((user, k) => (
                <S.CollapseItem
                  key={k}
                  onClick={() => {
                    setResponsavelOcorrencia(user);
                    setChanged(true);
                  }}
                  checked={responsavel_ocorrencia?.id === user.id}
                >
                  <div className="infoCheck">
                    <Radio
                      value={responsavel_ocorrencia?.id === user.id}
                      disabled
                    />
                    <div className="boxUser">
                      <div className="imageArea">
                        <span>{user.nome[0]}</span>
                      </div>
                      <div className="nameElement">{user.nome}</div>
                    </div>
                  </div>
                  <div className="locale">{user.filial.nome}</div>
                  <div className="workArea">{user.funcao}</div>
                </S.CollapseItem>
              ))}
            </div>
          </S.CollapseMu>
        </S.BoxCollapse>

        <S.BoxCollapse hasOcurrence={hasOcurrence} className="lastCollapse">
          <div className="clickColl">
            <div
              className="leftSide"
              onClick={
                hasOcurrence ? () => setCollTratar(!collTratar) : undefined
              }
            >
              <div className="textArea">Prazo de tratativa das ocorrências</div>
              <ArrowForwardIosOutlinedIcon
                fontSize="18px"
                htmlColor={theme.palette.words.title.natural}
                style={{
                  transform: collTratar ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'all ease .5s',
                  marginLeft: '10px',
                  marginBottom: '-5px',
                  cursor: 'pointer',
                }}
              />
            </div>
            <div className="rigthSide">
              {!!tratar_ocorrencias && (
                <div className="textArea number">
                  {tratar_ocorrencias} dia(s)
                </div>
              )}
              <span
                onClick={
                  hasOcurrence
                    ? () =>
                        toast.error(
                          'Não é possivel desabilitar esse item, o formulário possui ocorrências nos campos',
                        )
                    : () =>
                        toast.error(
                          'Não é possivel habilitar esse item, o formulário não possui ocorrências nos campos',
                        )
                }
              >
                <Switch
                  check={hasOcurrence}
                  disabled
                  style={{ opacity: 0.5 }}
                />
              </span>
            </div>
          </div>
          <S.CollapseMu in={collTratar} unmountOnExit>
            <div className="boxTime">
              <TextInput
                label="Prazo em dias"
                style={{ width: '70px' }}
                value={tratar_ocorrencias}
                onChange={e => {
                  checkNumberMonitoring(setTratarOcorrencias, e.target.value);
                  setChanged(true);
                }}
              />
              <span>dia(s)</span>
            </div>
          </S.CollapseMu>
        </S.BoxCollapse>
      </S.ContentLimit>
    </S.Box>
  );
};
