import React from 'react';
import { Collapse } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import * as S from './styled';

// handleClick={() => setOpen(!open)}

const Section = ({ title, children, open, handleClick }) => {
  const theme = useTheme();
  return (
    <>
      <S.SectionFilter onClick={handleClick} expanded={open}>
        <ArrowForwardIos
          fontSize="20px"
          htmlColor={theme.palette.words.caption.natural}
        />
        <h3>{title}</h3>
      </S.SectionFilter>

      <Collapse in={open} timeout="auto">
        {children}
      </Collapse>
    </>
  );
};

export default Section;
