import api from 'services/api';
import qs from 'qs';

export const getFomrItem = async id => {
  const res = await api.get(`/form/${id}`);
  return res.data;
};

// -------------------------Informações Gerais--------------------------//
export const SaveGeneralInfo = async data => {
  const res = await api.put(`/form/${data.id}`, data);
  return res.data;
};

export const DeleteForm = async id => {
  const res = await api.delete(`/form/${id}`);
  return res.data;
};

// -------------------------Publico--------------------------//
export const SavePublic = async data => {
  const res = await api.put(`/form/${data.id}`, data);
  return res.data;
};

// -------------------------Configuraçoes------------------------------//
export const SaveConfigs = async data => {
  const res = await api.put(`/form/${data.id}`, data);
  return res.data;
};

// ----------------------------Publicar-------------------------------//
export const publishForm = async id => {
  const res = await api.put(`/form/publish-form/${id}`);
  return res.data;
};

// ----------------------------Reports-------------------------------//
export const exportExcel = async query => {
  const res = await api.get(`/form/v1/excel/${query.id}`, { params: query });
  return res;
};

export const getPeriods = async id => {
  const res = await api.get(`/form/v1/periods`, { params: { form: id } });
  return res.data;
};

export const exportPDF = async (id, query) => {
  const res = await api.get(`/form/v1/pdf/${id}`, { params: query });
  return res;
};
