import styled, { css } from 'styled-components';
import Grid from '@mui/material/Grid';

const PageWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  height: 100%;
  overflow: hidden;
`;

const PageContainer = styled.form`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    flex-direction: column;
    div > &:first-child {
      display: flex;
      flex-direction: column;
      h1 {
        color: ${theme.palette.brand.primary.natural};
        font-weight: 600;
      }
      p {
        color: ${theme.palette.brand.primary.natural};
        font-weight: 400;
        font-size: 1.2em;
      }
    }
  `}
`;

const FormWrapper = styled.div`
  ${({ theme }) => css`
    .error {
      background-color: ${theme.palette.semantics.feedback.attention.light};
    }

    .error::placeholder {
      color: ${theme.palette.semantics.feedback.attention.natural};
      font-weight: bold;
    }

    .errorMessage {
      color: ${theme.palette.semantics.feedback.attention.natural};
      font-weight: 600;
      margin-left: 5px;
      font-size: 14px;
      pointer-events: none;
      opacity: 0;
      transition: all ease 0.5s;
      height: 20px;
    }

    .visible {
      pointer-events: all;
      opacity: 1;
    }
  `}
`;

const Label = styled.label`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 380px;
    width: 100%;
    color: ${theme.palette.words.subtitle.natural};
    font-weight: 600;
    opacity: 0.9;
    position: relative;
  `}
`;

const VisibleController = styled.div`
  position: absolute;
  right: 15px;
  top: 50px;
  cursor: pointer;
`;

export { PageWrapper, PageContainer, FormWrapper, Label, VisibleController };
