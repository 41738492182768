import { Content, Container, PartnersList } from './styled';
import FeedbackCard from './FeedbackCard';

const feedbacks = [
  {
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum dictum justo et ipsum rutrum, ac rutrum augue venenatis. Ut luctus volutpat malesuada. Cras volutpat congue elementum. Nam',
    name: 'Leandro Silveira',
    title: 'Co-founder and CEO Company',
    logoSrc:
      'https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f20f84d2f820d_Grupo%2019954.svg',
  },
  {
    comment:
      'Vestibulum dictum justo et ipsum rutrum, ac rutrum augue venenatis. Ut luctus volutpat malesuada. Cras volutpat congue elementum.',
    name: 'André Silva',
    title: 'CTO Company',
    logoSrc:
      'https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f20c24f2f8221_Grupo%2021550.png',
  },
];

const Partners = props => {
  return (
    <Container>
      <Content>
        <h1>As melhores empresas confiam em nós:</h1>
        <PartnersList>
          <ul>
            <li>
              <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f20f84d2f820d_Grupo%2019954.svg" />
            </li>
            <li>
              <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f20fe5a2f820c_Grupo%2019953.svg" />
            </li>
            <li>
              <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f2015d22f820e_Grupo%2019952.svg" />
            </li>
            <li>
              <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faae76f86571bd320411dd_Grupo%2021552.png" />
            </li>
            <li>
              <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faae76f865710c2b0411e3_Grupo%2021553.png" />
            </li>
            <li>
              <img src="https://uploads-ssl.webflow.com/62d6d4b56a3808f21606dbad/63f59494863c676458e30307_Grupo%2021522.png" />
            </li>
          </ul>
        </PartnersList>
        {/* <FeedbackCard data={feedbacks} /> */}
      </Content>
    </Container>
  );
};

export default Partners;
