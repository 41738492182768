import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  border: 2px solid rgb(237, 242, 249);
  border-radius: 4px;

  //width: 100%;
  height: ${({ height }) => height || '450px'};
  .container-filters {
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    .graphtitle {
      text-align: left;
      font-size: 24px;
      font-weight: 900;
      font-style: normal;
      letter-spacing: 0px;
      color: #4b5166;
    }
    .titletop {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const Legenda = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 16px;
  margin-bottom: 16px;
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #95aac9;
    margin-right: 5px;
  }
  .circle2 {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #4b5166;
    margin-right: 5px;
  }
  .text {
    margin-right: 1.875rem;
    font-size: 0.875rem;
  }
`;

export const ContainerGraph = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 1rem;

  &::-webkit-scrollbar {
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #edf2f9;
  }

  &::-webkit-scrollbar-thumb {
    background: #4b5166;
    opacity: 0.1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #4b5160;
  }
`;

export const TooltipContainer = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #cccccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 12px;
  .title {
    font-weight: bold;
  }
`;
