import * as types from './types';

export function setFilter(query) {
  return {
    type: types.SET_FILTER_FORMULARIO,
    payload: { query },
  };
}
export function setReportsFilters(reportsFilters) {
  return {
    type: types.REPORTS_FILTERS,
    payload: { reportsFilters },
  };
}
