import styled, { css } from 'styled-components';

export const Tag = styled.div`
  ${({ theme, selected, variant }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 100px;
    background: ${theme.palette.semantics.feedback[variant ?? 'unknown']
      ?.light};
    padding-right: 10px;
    padding-left: 10px;
    height: 22px;
    margin-right: 5px;
    font-size: 14px;
    color: ${variant
      ? theme.palette.semantics.feedback[variant].natural
      : theme.palette.words.title.natural};
    font-weight: bold;
    border: ${selected
      ? `1px solid ${
          theme.palette.semantics.feedback[variant ?? 'unknown']?.natural
        };`
      : `1px solid transparent`};
    white-space: nowrap;

    &:hover {
      background: ${variant
        ? `${theme.palette.semantics.feedback[variant]?.natural}15`
        : theme.palette.system.border};
    }
  `}
`;

export const TagContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TagLabel = styled.p`
  ${({ theme }) => css`
    font: normal normal medium 14px/20px Texta;
    color: ${theme.palette.words.subtitle.natural};

    span {
      font-size: 20px;
      font-weight: 500;
      color: ${theme.palette.semantics.feedback.attention.dark};
    }
  `}
`;
