import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.palette.brand.primary.background};
  `}
`;

export const Content = styled.div`
  display: flex;
  gap: 4rem;
  img {
    width: 17.5rem;
  }
`;

export const Control = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    h1 {
      font: normal normal 900 28px/35px Texta;
      letter-spacing: 0px;
      color: ${theme.palette.words.title.natural};
      margin-bottom: 1rem;
    }
    p {
      font: normal normal medium 18px/25px Texta;
      letter-spacing: 0px;
      color: ${theme.palette.words.subtitle.natural};
      margin-bottom: 2rem;
    }
  `}
`;

export const Logo = styled.img`
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: 3rem;
`;
