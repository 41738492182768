import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { SaveAlt } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { formatNameDate } from 'utils/dates';

import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

// Components
import GhostButton from 'components/Buttons/Ghost';
import { DefaultTable } from 'components/_Table/templates/default';
import EmptyCard from 'components/Cards/EmptyDataCard';
import ExcelModal from 'components/ExcelModal';
import ExportToExcel from 'utils/exportToCvs';
import Podium from 'components/Podium';
import { requestDriversRanking, requestExcel } from './services';
import { columns, fields, resetExcelFields } from './constants';
import * as S from './styled';

const sortBy = [{ id: 'posicao', desc: false }];

const Ranking = () => {
  const navigate = useNavigate();
  const [filter, filterTelemetriaRanking] = useSelector(state => {
    return [state.filter, state.filterTelemetriaRanking];
  });
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPodium, setLoadingPodium] = useState(true);
  const [podium, setPodium] = useState([]);

  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const formatRankingFilters = useCallback(
    query => {
      const _query = { ...query };

      if (filterTelemetriaRanking.client)
        _query.client = filterTelemetriaRanking.client;
      if (filterTelemetriaRanking.operation)
        _query.operation = filterTelemetriaRanking.operation;
      if (filterTelemetriaRanking.desvio)
        _query.idDesvio = filterTelemetriaRanking.desvio;
      if (filterTelemetriaRanking.veiculo)
        _query.idVeiculo = filterTelemetriaRanking.veiculo;
      if (filterTelemetriaRanking.motoristas)
        _query.motoristas = filterTelemetriaRanking.motoristas;
      if (filterTelemetriaRanking.carregado)
        _query.carregado =
          filterTelemetriaRanking.carregado === 'true'
            ? 1
            : filterTelemetriaRanking.carregado === 'false'
            ? 0
            : filterTelemetriaRanking.carregado;
      if (filterTelemetriaRanking.pista_molhada)
        _query.pista_molhada =
          filterTelemetriaRanking.pista_molhada === 'true' ? 1 : 0;

      return _query;
    },
    [filterTelemetriaRanking],
  );

  useEffect(() => {
    const fetchRanking = async () => {
      setLoading(true);
      const filters = formatRankingFilters({ ...filter });
      const res = await requestDriversRanking(filters);

      if (res && res.data.success) {
        let totalPoints = res.data.data.reduce((acc, cur) => {
          return acc + cur.pontos_km;
        }, 0);

        const mediaPoints = totalPoints / res.data.data.length;

        const rows = res.data.data.map((item, index) => ({
          ...item,
          id: item.id_motorista,
          posicao: index + 1,
          isMedia: item.pontos_km <= mediaPoints,
        }));
        setData(rows || []);

        setLoadingPodium(true);
        const formatedPodium = [];
        if (rows && rows.length) {
          for (let i = 0; i <= 2; i++) {
            if (rows.length > i) {
              formatedPodium.push({
                nameOne: rows[i].nome,
                nameTwo: rows[i].motorista,
                position: rows[i].posicao,
                oldPosition: rows[i].posicao_anterior
                  ? rows[i].posicao_anterior > rows[i].posicao
                    ? 'UP'
                    : rows[i].posicao_anterior < rows[i].posicao
                    ? 'DOWN'
                    : 'EQUAL'
                  : null,
                logo: rows[i].foto,
                hours: null,
                km: Number(rows[i].km_viagem).toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                }),
                points: rows[i].pontos,
                pontos_km: Number(rows[i].pontos_km).toLocaleString(undefined, {
                  maximumFractionDigits: 3,
                }),
              });
            }
          }
        }

        setPodium(formatedPodium);
        setLoadingPodium(false);
      } else {
        toast.error(
          'Erro ao acessar o ranking, tente mais tarde, caso persista procure o nosso time de suporte.',
        );
      }

      setLoading(false);
    };
    fetchRanking();
  }, [
    filter.finalDate,
    filter.initialDate,
    filter.filial,
    filter.client,
    filterTelemetriaRanking,
  ]);

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = formatRankingFilters({
      ...filter,
      orderBy: sortBy.desc ? 'DESC' : 'ASC',
      orderByColumn: sortBy.id != 'posicao' ? sortBy.id : null,
      excelFields: newFields,
    });

    const res = await requestExcel(newQuery);
    if (res.data && res.data?.data?.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `ranking_motoristas_${formatedDate}`,
      });
    else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  const handleVer = id => {
    if (id) navigate(`/motoristas/${id}`);
    else toast.error('Não foi possível acessar os detalhes deste motorista.');
  };

  const renderHeader = () => {
    return (
      <>
        <S.TitleWrapper>
          <h1>Ranking</h1>

          <div>
            <GhostButton
              startIcon={<SaveAlt />}
              size="medium"
              onClick={() => {
                setOpenExcelModal(true);
              }}
            >
              EXPORTAR
            </GhostButton>
          </div>
        </S.TitleWrapper>

        <Podium data={podium} loading={loadingPodium} />
      </>
    );
  };

  const renderTableDrivers = () => {
    const actions = [{ title: 'Ver perfil', function: handleVer }];

    if (loading || data?.length > 0) {
      return (
        <DefaultTable
          loading={loading}
          data={data || []}
          columns={columns}
          actions={actions}
          searchKeys={['nome', 'matricula', 'filial']}
          placeholder="Buscar por nome ou filial"
          sortBy={{ id: 'posicao', order: 'ASC' }}
        />
      );
    }

    return (
      <EmptyCard
        image="motorista.png"
        title="Ops! Não foram encontrados motoristas para essa busca."
        subtitle="Altere os filtros ou faça o cadastro do novo motorista!"
      />
    );
  };

  return (
    <>
      {/* <ConstantsUpdater names={['drivers']} trigger={triggerUpdate} setTrigger={setTriggerUpdate} /> */}
      <S.Main>
        {renderHeader()}
        {renderTableDrivers()}
      </S.Main>
      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}
    </>
  );
};

export default Ranking;
