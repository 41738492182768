// React
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import SelectList from 'components/Inputs/SelectList';

// Components MUI
import List from '@mui/material/List';
import { Popover } from '@mui/material';
import * as S from './styled';

// Utils

const Filters = ({
  id,
  open,
  onClose,
  anchorEl,
  filter,
  setFilter,
  idEmpresa,
  multiple = true,
}) => {
  const [prevFilter, setPrevFilter] = useState({ ...filter });

  const { user } = useSelector(state => state.auth.user);
  const isProvider = user?.provider;

  const selects = useSelector(state => state.selects);

  useEffect(() => {
    setPrevFilter({ ...filter });
  }, [filter]);

  const clientes =
    selects.clients?.map(i => {
      return { value: i.nome, id: i.id };
    }) || [];

  const filiais = idEmpresa
    ? selects.empresas
        .find(empresa => empresa.id === +idEmpresa)
        ?.filiais?.map(i => {
          return { value: i.nome, id: i.id };
        }) || []
    : [];

  const handleChangeMultiple = (e, key) => {
    setPrevFilter(_prevFilter => {
      return { ..._prevFilter, [key]: e };
    });
  };

  const aplyFilter = () => {
    const _prevFilter = { ...prevFilter };
    Object.keys(_prevFilter).forEach(key => {
      if (!_prevFilter[key]) delete _prevFilter[key];
    });
    setFilter({ ..._prevFilter });
    onClose();
  };

  const reset = () => {
    setPrevFilter({ ...filter });
  };

  // Limpa TODOS os valores do estado e foca no botao de aplicar
  const handleClear = () => {
    setPrevFilter({
      initialDate: filter.initialDate,
      finalDate: filter.finalDate,
    });
    const buttonApply = document.getElementById('aplicar');
    buttonApply?.focus();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <S.Main>
        <S.Header>
          <h2>Filtros</h2>
          <S.ClearButton onClick={handleClear}>Limpar filtros</S.ClearButton>
        </S.Header>

        <S.SelectsContainer>
          <List>
            <S.CollapseContent>
              {isProvider ? (
                <SelectList
                  value={prevFilter?.filial}
                  handleChange={handleChangeMultiple}
                  fieldName="filial"
                  label="Filtrar por Filial"
                  data={filiais}
                  selectAll
                  multiple={multiple}
                />
              ) : (
                <SelectList
                  value={prevFilter?.client}
                  handleChange={handleChangeMultiple}
                  fieldName="client"
                  label="Filtrar por Cliente"
                  data={clientes}
                  selectAll
                  multiple={multiple}
                />
              )}
            </S.CollapseContent>
          </List>
        </S.SelectsContainer>
      </S.Main>
      <S.Footer>
        <GhostButton
          onClick={() => {
            reset();
            onClose();
          }}
          size="medium"
        >
          Cancelar
        </GhostButton>

        <DefaultButton
          onClick={aplyFilter}
          size="medium"
          sx={{ marginLeft: '20px' }}
          id="aplicar"
        >
          APLICAR
        </DefaultButton>
      </S.Footer>
    </Popover>
  );
};

export default Filters;
