import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

// services

// components
import { IconButton, SvgIcon, Card, CardMedia } from '@mui/material';
import Button from 'components/Buttons/Default';
import Input from 'components/Inputs/TextField';
import Loading from 'components/Loading';

// utils
import { padWithLeadingZeros } from 'utils/helpers';

// styles
import { useTheme } from 'styled-components';

// icons
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as ativo } from 'images/icons/conteudo/status/ativo.svg';
import { ReactComponent as rascunho } from 'images/icons/conteudo/status/rascunho.svg';
import { ReactComponent as inativo } from 'images/icons/conteudo/status/inativo.svg';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import BoltOutlined from '@mui/icons-material/BoltOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import EmptyBanner from 'images/empty/Capacitacao.svg';
import PlayLessonOutlinedIcon from '@mui/icons-material/PlayLessonOutlined';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

// img
import ImageNotFound from 'images/empty/imageempty.png';
import ModalReviewFull from '../../../TakeTest';
import * as S from './style.js';
import { getContentsApproved, showContent } from '../../services';

const Completados = ({ conteudos, fetchData, setInfo }) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const tipo = searchParams.get('tipo');

  const refScrollTab = useRef(null);

  const [tabSelected, setTabSelected] = useState(0);

  const [mouseDown, setMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isInitScroll, setInitScroll] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const [arrayConteudo, setArrayConteudo] = useState([]);
  const [arrayConteudoBK, setArrayConteudoBK] = useState([]);

  const [arrayTags, setArrayarrayTags] = useState([]);
  const [cards, setCards] = useState([]);
  const [search, setSearchValue] = useState('');

  const [openPreviewFull, setOpenPreviewFull] = useState(false);
  const [curentContent, setCurentContent] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateData = () => {
    setLoading(true);
    getContentsApproved(id, tipo).then(res => {
      if (res.success) {
        const contents = res.data;
        const tags = res.tags;
        setArrayConteudo(contents);
        setArrayConteudoBK(res.data);
        setArrayarrayTags(tags);
        setCards(res.cards);
        setInfo(res.aluno);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    updateData();
  }, []);

  const filterByTags = tag => {
    setFilterLoading(true);
    switch (tag) {
      case 'Todos':
        setTimeout(() => {
          setArrayConteudo(arrayConteudoBK);
          setFilterLoading(false);
        }, 600);
        break;
      default:
        setTimeout(() => {
          const all = arrayConteudoBK.filter(el =>
            el.tags.some(d => d === tag),
          );
          setArrayConteudo(all);
          setFilterLoading(false);
        }, 600);
    }
  };

  const handleClickTag = (tab, tabIndex) => {
    filterByTags(tab);
    setTabSelected(tabIndex);
  };

  const stopDragging = () => {
    setMouseDown(false);
  };

  const startDragging = function (e) {
    setMouseDown(true);
    setStartX(e.pageX - refScrollTab.current.offsetLeft);
    setScrollLeft(refScrollTab.current.scrollLeft);
  };

  const getMedia = (key, value, index) => {
    const tmp = key.toUpperCase();
    switch (tmp) {
      case 'VÍDEO':
        return (
          <S.StyledTooltip key={index} title={`${key} (${value})`} arrow>
            <SlideshowOutlinedIcon
              sx={{ color: theme.palette.brand.primary.light, marginLeft: 1 }}
            />
          </S.StyledTooltip>
        );
      case 'TEXTO':
        return (
          <S.StyledTooltip key={index} title={`${key} (${value})`} arrow>
            <BoltOutlined
              sx={{ color: theme.palette.brand.primary.light, marginLeft: 1 }}
            />
          </S.StyledTooltip>
        );
      case 'IMAGEM':
        return (
          <S.StyledTooltip key={index} title={`${key} (${value})`} arrow>
            <PhotoOutlinedIcon
              sx={{ color: theme.palette.brand.primary.light, marginLeft: 1 }}
            />
          </S.StyledTooltip>
        );
      case 'PDF':
        return (
          <S.StyledTooltip key={index} title={`${key} (${value})`} arrow>
            <PlayLessonOutlinedIcon
              sx={{ color: theme.palette.brand.primary.light, marginLeft: 1 }}
            />
          </S.StyledTooltip>
        );
    }
  };

  const onMouseMove = function (e) {
    if (!mouseDown) {
      return;
    }
    const x = e.pageX - refScrollTab.current.offsetLeft;
    const scroll = x - startX;
    refScrollTab.current.scrollLeft = scrollLeft - scroll;

    if (refScrollTab.current.scrollLeft < 50) {
      setInitScroll(false);
    } else {
      setInitScroll(true);
    }
  };

  const scrollMove = value => {
    refScrollTab.current.scrollLeft = value;
    if (refScrollTab.current.scrollLeft < 50) {
      setInitScroll(false);
    } else {
      setInitScroll(true);
    }
  };

  const getStatus = status => {
    switch (status) {
      case 'DISPONIVEL':
        return (
          <S.containerStatus
            color={theme.palette.semantics.feedback.success.natural}
          >
            <SvgIcon
              component={ativo}
              tyle={{ width: 20, height: 20 }}
              viewBox="0 0 60 47"
            />
            <label>Ativo</label>
          </S.containerStatus>
        );
      case 'VENCIDO':
        return (
          <S.containerStatus color={theme.palette.words.text.light}>
            <SvgIcon
              component={inativo}
              style={{ width: 20, height: 20 }}
              viewBox="0 0 1000 1207"
            />
            <label>Inativo</label>
          </S.containerStatus>
        );

      case 'rascunho':
        return (
          <S.containerStatus
            color={theme.palette.semantics.feedback.warning.natural}
          >
            <SvgIcon
              component={rascunho}
              style={{ width: 20, height: 20 }}
              viewBox="0 0 50 50"
            />
            <label>Rascunho</label>
          </S.containerStatus>
        );
    }
  };
  const formatDate = date => {
    return date ? `${`Até ${new Date(date).toLocaleDateString()}`}` : '';
  };

  const capitalizeFirstLetter = str => {
    str = str.toLowerCase();
    return str;
  };

  const handleSearch = text => {
    setSearchValue(text);

    setFilterLoading(true);
    const checkTags = value => {
      return value.tags.find(a => a.toLowerCase().includes(text.toLowerCase()));
    };

    setTimeout(() => {
      const filters = arrayConteudoBK.filter(
        value =>
          value.titulo.toLowerCase().includes(text.toLowerCase()) ||
          value.descricao.toLowerCase().includes(text.toLowerCase()) ||
          checkTags(value),
      );
      setArrayConteudo(filters);
      setFilterLoading(false);
    }, 900);
  };

  const imageExists = url => {
    return url;
  };

  const handleshowContent = async id => {
    setLoading(true);
    const res = await showContent(id);
    if (res.success) {
      setCurentContent(res.data.content);
      setOpenPreviewFull(true);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div style={{ position: 'absolute', top: '80%', left: '57%' }}>
        <Loading />
      </div>
    );
  }

  return (
    <S.Container>
      {curentContent && openPreviewFull && (
        <ModalReviewFull
          open={openPreviewFull}
          handleClose={() => {
            setOpenPreviewFull(false);
            setArrayConteudo([]);
            updateData();
          }}
          isPreview
          data={curentContent}
        />
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            color: theme.palette.brand.primary.natural,
            fontSize: 20,
            fontWeight: 900,
          }}
        >
          Meus Conteúdos
        </div>
        <S.SearchContainer>
          <Input
            style={{ width: 300 }}
            value={search}
            startIcon={
              <SearchIcon sx={{ color: theme.palette.brand.primary.light }} />
            }
            placeholder="Buscar Conteúdo"
            onChange={e => handleSearch(e.target.value)}
          />
        </S.SearchContainer>
      </div>
      <S.containerCard>
        {cards.map((item, k) => (
          <>
            <div key={k} className="container-card">
              <div className="icon-container">
                {k == 0 && <DisabledByDefaultOutlinedIcon />}
                {k == 1 && <HeartBrokenIcon />}
                {k == 2 && <SlideshowOutlinedIcon />}
              </div>
              <div>
                <div>
                  <h1>{padWithLeadingZeros(item.value, 2)}</h1>
                  <h3>{item.text}</h3>
                </div>
              </div>
            </div>
          </>
        ))}
      </S.containerCard>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <IconButton
          disabled={!isInitScroll}
          onClick={() => scrollMove(refScrollTab.current.scrollLeft - 50)}
        >
          <KeyboardArrowLeftIcon
            sx={{
              color: !isInitScroll
                ? theme.palette.system.overlay
                : 'rgba(0, 0, 0, 0.54)',
            }}
          />
        </IconButton>
        <S.TagContent
          onMouseLeave={stopDragging}
          onMouseUp={stopDragging}
          onMouseDown={startDragging}
          ref={refScrollTab}
          onMouseMove={onMouseMove}
        >
          {arrayTags?.map((i, k) => (
            <IconButton
              key={k}
              disableRipple
              onClick={() => handleClickTag(i, k)}
            >
              <S.Tag tabSelected={tabSelected === k}>{i}</S.Tag>
            </IconButton>
          ))}
        </S.TagContent>
        <IconButton
          onClick={() => scrollMove(refScrollTab.current.scrollLeft + 50)}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
      <div
        style={{
          color: theme.palette.brand.primary.natural,
          fontSize: 20,
          fontWeight: 900,
        }}
      >
        Todos
      </div>
      <S.Space h={20} />
      <S.CardContainer>
        {arrayConteudo?.map(
          (i, k) =>
            !i.reprovado && (
              <S.Card2 key={k} opacity={filterLoading ? '0.5' : '1'}>
                <S.CardMedia>
                  <CardMedia
                    component="img"
                    height="196"
                    image={
                      imageExists(i.imagem_capa) ? i.imagem_capa : ImageNotFound
                    }
                    alt=""
                  />
                </S.CardMedia>
                <S.CardDescription>
                  <S.TagsContainer>
                    {i?.tags?.slice(0, 5)?.map((i, k) => (
                      <S.Tag key={k}>{capitalizeFirstLetter(i)}</S.Tag>
                    ))}
                    <S.containerDate>{formatDate(i.data_fim)}</S.containerDate>
                  </S.TagsContainer>

                  <S.containerTitle>{i.titulo}</S.containerTitle>
                  <S.containerSubTitle>{i.descricao}</S.containerSubTitle>
                  {/* <S.MediaContainer>
                    {Object.entries(i.midiaCount)?.map((i, k) => {
                      return getMedia(i[0], i[1], k);
                    })}
                  </S.MediaContainer> */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: 10,
                    }}
                  >
                    {getStatus(i.status)}

                    <div
                      style={{
                        width: '100%',
                        transform: 'translateY(-11px)',
                        marginLeft: 20,
                      }}
                    >
                      <div style={{ textAlign: 'end' }}>{i?.percentual}%</div>
                      <S.ProgressBar>
                        {/* controla o percentual do progress */}
                        <div
                          style={{ width: `${i?.percentual}%` }}
                          className="progress"
                        />
                      </S.ProgressBar>
                    </div>
                  </div>
                  <>
                    {/* <S.MediaContainer>
                      {Object.entries(i.midias)?.map((i, k) => {
                        return getMedia(i[0], i[1], k);
                      })}
                    </S.MediaContainer> */}
                    <S.ButtonConainer>
                      <Button
                        width="300px"
                        onClick={() => {
                          handleshowContent(i.id);
                        }}
                      >
                        Visualizar
                      </Button>
                    </S.ButtonConainer>
                  </>
                </S.CardDescription>
              </S.Card2>
            ),
        )}
      </S.CardContainer>
      {arrayConteudo?.length === 0 && (
        <S.ContainerEmptyState>
          <div>
            <img src={EmptyBanner} width={256} height={256} alt="empty" />
          </div>
          <S.EmptyStateTitle>
            <h1 style={{ fontSize: 28 }}>
              Ops! você não possui conteúdos disponíveis.
            </h1>
            <S.Space h={20} />
          </S.EmptyStateTitle>
        </S.ContainerEmptyState>
      )}
    </S.Container>
  );
};

export default Completados;
