import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

// Componentes
import { Divider } from '@mui/material';
import Tabs from 'components/Tabs';

import { setCompanies } from 'store/modules/provider/companies/actions';
import * as services from './services';

// Styles
import * as S from './styled';
import AreaRisco from './AreaRisco';
import FatoresRisco from './FatoresRisco';
import DesempenhoGeral from './DesempenhoGeral';
import TratativaDesvios from './TratativaDesvios';

const pageTabs = [
  { value: 'desempenho-geral', label: 'Desempenho Geral' },
  { value: 'areas-de-risco', label: 'Áreas de risco' },
  { value: 'fatores-de-risco', label: 'Fatores de risco' },
  { value: 'tratativa-de-desvios', label: 'Tratativa de desvios' },
];

const GraficosMotoristas = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState('');
  const { user } = useSelector(state => state.auth.user);
  const isProvider = user?.provider;
  const { companies } = useSelector(state => state);

  // Controla requisição das Empresas
  useEffect(() => {
    const getComapniesInfo = async () => {
      const res = await services.getCompanies();
      const comp = res.data.rows.map(i => {
        return { id: i.id, value: i.nome, filiais: i.filial };
      });
      dispatch(setCompanies(comp));
    };

    // Verifica se o Redux ja existe
    if (isProvider && !companies?.length) {
      getComapniesInfo();
    }
  }, [isProvider]);

  const handlePageTab = (event, newValue) => {
    navigate(`/telemetria/estatisticas/${newValue}`);
  };

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === '/telemetria/estatisticas') {
      navigate('/telemetria/estatisticas/desempenho-geral');
    } else {
      const tab = pathname.replace('/telemetria/estatisticas/', '');
      setTab(tab);
    }
  }, [location.pathname]);

  const renderMain = () => (
    <>
      <S.Container>
        <S.Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <S.Title>Estatísticas</S.Title>
          </div>
        </S.Header>
        <Tabs value={tab} items={pageTabs} onChange={handlePageTab} />
        <Divider />
        {tab === 'desempenho-geral' && <DesempenhoGeral />}
        {tab === 'areas-de-risco' && <AreaRisco />}
        {tab === 'fatores-de-risco' && <FatoresRisco />}
        {tab === 'tratativa-de-desvios' && <TratativaDesvios />}
      </S.Container>
    </>
  );

  return renderMain();
};

export default GraficosMotoristas;
