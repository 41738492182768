import api from 'services/api';

export const getCardsAvaliacao = async (id, query) => {
  const res = await api.get(
    `/drivers/perfil/estatisticas/cards-avaliacoes/${id}`,
    { params: query },
  );
  return res.data;
};

export const getRadarDesvio = async (id, query) => {
  const res = await api.get(`/drivers/perfil/estatisticas/radar/${id}`, {
    params: query,
  });
  return res.data;
};

export const getHistorico = async (id, query) => {
  const res = await api.get(`/drivers/perfil/estatisticas/historico/${id}`, {
    params: query,
  });
  return res.data;
};

export const getCardCapacitacao = async (id, query) => {
  const res = await api.get(
    `/drivers/perfil/estatisticas/card-capacitacao/${id}`,
    { params: query },
  );
  return res.data;
};

export const getCardFormulario = async (id, query) => {
  const res = await api.get(`/drivers/perfil/estatisticas/card-forms/${id}`, {
    params: query,
  });
  return res.data;
};

export const getRadarDeRisco = async (id, query) => {
  const res = await api.get(`/drivers/estatisticas/perfil/${id}`, {
    params: query,
  });
  return res.data;
};

export const getFatoresRiscoGraves = async params => {
  const res = await api.get(`/home/fatores-risco/graves`, { params });
  return res.data;
};

export const getCardImprove = async params => {
  const res = await api.get(`/home/fatores-risco/aumento-mes`, { params });
  return res.data;
};
