import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  responsavel: '',
  tipo: '',
  criticidade: '',
  objeto: '',
  exclusaoTab: 0,
  selectedCard: null,
};

export default function filterExclusaoDesvio(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_EXCLUSAO_DESVIO: {
        draft.responsavel = action.payload.query.responsavel;
        draft.tipo = action.payload.query.tipo;
        draft.criticidade = action.payload.query.criticidade;
        draft.objeto = action.payload.query.objeto;
        break;
      }
      case types.CHANGE_TAB_EXCLUSAO_DESVIO: {
        draft.exclusaoTab = action.payload.tab;
        break;
      }
      case types.CHANGE_CARD_EXCLUSAO_DESVIO: {
        draft.selectedCard = action.payload.card;
        break;
      }
    }
  });
}
