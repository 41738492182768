import api from 'services/api';

export const getDesvios = async query => {
  const { data } = await api.get('/telematics_violacao', { params: query });
  return data.data;
};

export const getDesvio = async id => {
  const { data } = await api.get(`/telematics_violacao/${id}`);
  return data.data;
};

export const registerDesvio = async data => {
  const res = data.id
    ? await api.put(`/telematics_violacao/${data.id}`, data)
    : await api.post('/telematics_violacao', data);
  return res.data;
};

export const requestExcel = async (query, isProvider) => {
  const res = await api.get(
    `/excel${isProvider ? '-provider' : ''}/telematics_infractions`,
    { params: query },
  );
  return res;
};

export const activateDesvio = async id => {
  const res = await api.put(`/telematics_violacao/${id}/activate`);
  return res.data;
};

export const desactivateDesvio = async id => {
  const res = await api.put(`/telematics_violacao/${id}/desactivate`);
  return res.data;
};

export const getTiposPadrao = async query => {
  const { data } = await api.get('/telematics_violacao_padrao', {
    params: query,
  });
  return data.data;
};

export const getTiposVeiculos = async () => {
  const { data } = await api.get('/telematics_tipos_veiculos');
  return data.data;
};

export const getCercaEletronica = async id => {
  const { data } = await api.get(`/telematics_violacao/${id}`);
  return data.data;
};

export const requestHistory = async id => {
  const res = await api.get(`/telematics_violacao/${id}/logs`);
  return res;
};
