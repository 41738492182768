import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Componentes
import BarGraph from 'components/Graphs/BarGraph';
import Select from 'components/Inputs/Select';

// Service
import { toast } from 'react-toastify/dist';
import { Grid } from '@mui/material';
import { trackEvent } from 'utils/mixpanel';
import { requestFrota } from '../services';
import * as S from './styled';

const valuesSelect = [
  { value: 'propriedade', name: 'AGREGADOS' },
  { value: 'id_da_filial', name: 'FILIAL' },
  { value: 'cambio', name: 'CAMBIO' },
  { value: 'departamento', name: 'DEPARTAMENTO' },
  { value: 'id_da_distribuidora', name: 'CLIENTE' },
  { value: 'pos_camera', name: 'POS. CÂMERA' },
  { value: 'dvr', name: 'CANAL DVR' },
];

const GraficosFrota = () => {
  const filter = useSelector(state => {
    return state.filter;
  });

  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const [radar, setRadar] = useState(null);
  const [radarSelect, setRadarSelect] = useState('propriedade');
  const [radarLoading, setRadarLoading] = useState(true);

  const fetchRadar = async query => {
    setRadarLoading(true);
    const res = await requestFrota(query);
    if (res.data?.data) setRadar(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setRadarLoading(false);
  };

  useEffect(() => {
    const query = { ...filter, tip_select: radarSelect };
    fetchRadar(query);
  }, [filter, radarSelect]);

  const renderRadarGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <BarGraph
        data={radar}
        colors="default"
        title="Radar de Veiculos"
        loading={radarLoading}
        tooltip
      />
      <div style={{ position: 'absolute', top: '15px', right: '25px' }}>
        <Select
          data={valuesSelect}
          value={radarSelect}
          handleChange={e => {
            trackEvent(user, `GRÁFICO FROTA: ${e.target.value}`);
            setRadarSelect(e.target.value);
          }}
        />
      </div>
    </div>
  );

  const renderMain = () => (
    <>
      <S.Container>
        <S.Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <S.Title>Dashboard Frota</S.Title>
          </div>
        </S.Header>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              {renderRadarGraph()}
            </Grid>
          </Grid>
        </S.Main>
      </S.Container>
    </>
  );

  return renderMain();
};

export default GraficosFrota;
