import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
  justify-content: space-between;
  gap: 16px;

  .description {
    font: normal normal 900 24px/30px Texta;
    letter-spacing: 0px;
    color: #4b5166;
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 4px;
    font: normal normal 700 16px/18px Texta;
  }
`;

export const DesvioItem = styled.div`
  background: rgba(75, 81, 102, 0.1);
  border-radius: 12px;
  color: '#4B5166';
  height: 24px;
  margin-top: 5px;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
`;
