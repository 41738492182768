import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  width: 100%;
  padding: 5px 40px;
`;

export const MediaArchive = styled.div`
  margin-top: 40px;
`;

export const InvalidVideo = styled.div`
  border: 3px solid ${colors.redDanger};
  height: 200px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    font-weight: bold;
    font-size: 30px;
    color: ${colors.redDanger};
  }
`;
