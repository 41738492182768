import { takeLatest, put, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from './types';
import api from '../../../services/api';

import { updateVersionSuccess } from './actions';

export function* updateVersion() {
  const res = yield call(api.get, '/version');
  if (res.data?.data) {
    yield put(updateVersionSuccess(res.data.data));
  } else if (res.data?.message) {
    toast.error(res.data?.message);
  }
}

export default all([takeLatest(types.UPDATE_VERSION_REQUEST, updateVersion)]);
