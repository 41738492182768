import styled from 'styled-components';

export const ControllerArea = styled.div`
  position: fixed;
  z-index: 998;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 65px;
  background-color: #fff;
  box-shadow: 0px -5px 12px -4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px;

  .statusArea {
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-left: 100px;
  }

  .buttonsArea {
    .backArea {
      margin-right: 20px;
    }
  }
`;
