import api from 'services/api';

export const getAcoesDisciplinares = async () => {
  const { data } = await api.get('/acoes-disciplinares');
  return data.data;
};

export const createAcaoDisciplinar = async nome => {
  const res = await api.put(`/acoes-disciplinares`, { nome });
  return res.data;
};

export const desactivateAcaoDisciplinar = async id => {
  const res = await api.delete(`/acoes-disciplinares/${id}`);
  return { ...res.data, status: res.status };
};

export const getModulosCapacitacao = async () => {
  const { data } = await api.get('/capacitacao/conteudos');
  return data.data;
};

export const getFaixas = async params => {
  const res = await api.get('/acoes-suspensoes/faixas', { params });
  return res;
};

export const getFaixa = async (id, params) => {
  const res = await api.get(`/acoes-suspensoes/faixas/${id}`, { params });
  return res;
};

export const registerFaixa = async data => {
  const _data = {
    ...data,
    limiar: Number(data.limiar),
    reincidencias: data.reincidencias.map(r => ({
      ...r,
      reincidencia: Number(r.reincidencia),
      dias: Number(r.dias),
    })),
  };

  const res = data.id
    ? await api.put(`/acoes-suspensoes/faixas/${data.id}`, _data)
    : await api.post('/acoes-suspensoes/faixas', _data);

  return res.data;
};

export const desactivateFaixa = async id => {
  const res = await api.put(`/acoes-suspensoes/faixas/${id}/desativar`);
  return res.data;
};

export const getPontuacoes = async params => {
  const res = await api.get('/acoes-suspensoes/desvios', { params });
  return res;
};

export const updatePontuacao = async (id, data) => {
  const res = await api.put(`/acoes-suspensoes/desvios/${id}`, data);
  return res?.data;
};

export const desactivatePontuacao = async id => {
  const res = await api.put(`/acoes-suspensoes/desvios/${id}/desativar`);
  return res.data;
};

export const getFirstConfig = async () => {
  const { data } = await api.get('/acoes-suspensoes/desvios/verifyDisable');
  return data;
};

export const enableAll = async () => {
  const { data } = await api.post('/acoes-suspensoes/desvios/enableAll');
  return data;
};

export const requestExcelFaixas = async query => {
  const res = await api.get('/excel/acao_suspensao_faixa', { params: query });
  if (res?.data?.data?.link)
    return { link: res.data.data.link, message: res.data.message };
  return { message: res?.data?.message };
};

export const getDecaimento = async () => {
  const res = await api.get(`/acoes-disciplinares-configuracoes-decaimento`);
  return res;
};

export const saveDecaimento = async data => {
  const res = await api.put(
    `/acoes-disciplinares-configuracoes-decaimento`,
    data,
  );

  return res.data;
};
