import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  background: ${({ theme }) => theme.palette.system.white};
  border: 1px solid ${({ theme }) => `${theme.palette.brand.primary.light}4d`};
  border-radius: 4px;
  margin-top: 1rem;

  .text-center {
    text-align: center;
  }
  .no-wrap {
    white-space: nowrap;
  }
`;

export const Title = styled.span`
  font: normal normal 900 2rem Texta;
  text-transform: capitalize;
  letter-spacing: 0px;
  color: #4b5166;
  opacity: 1;
`;

export const Title2 = styled.span`
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  font-family: Texta;
  letter-spacing: 0px;
  color: #4b5166;
`;

export const Quantidade = styled.div`
  text-align: left;
  font: normal normal 900 40px/20px Texta;
  letter-spacing: 0px;
  color: #4b5166;
  margin-right: 0.5rem;
`;

export const Subtitle = styled.div`
  font-family: Texta;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0px;
  color: #95aac9;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Flex = styled.div`
  display: flex;
  .rv {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .w-50 {
    width: 50%;
  }
  &.full-width {
    width: 100%;
  }
  &.mt-05 {
    margin-top: 0.5rem;
  }
  &.mb-05 {
    margin-bottom: 0.5rem;
  }
  &.gap-1 {
    gap: 1rem;
  }
  &.gap-2 {
    gap: 2rem;
  }
  &.align-center {
    align-items: center;
  }
`;

export const Avatar = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
`;

export const Circle = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 3rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #95aac9;
  box-shadow: 0px 3px 6px #00000029;
  font-family: Texta;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span {
    font-size: 4rem;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
  }
`;

export const StatusCircle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 1rem;
  background: ${({ active, theme }) =>
    active
      ? theme.palette.semantics.feedback.success.natural
      : theme.palette.semantics.feedback.attention.natural};
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.8);
`;
