import styled, { css } from 'styled-components';

export const FormCard = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 80vw;
    text-align: left;
    display: flex;
    flex-direction: column;
    background: ${theme.palette.system.overlay};
    border: solid 1px ${theme.palette.system.border};
    border-radius: 4px;
    padding: 2rem;

    p {
      color: ${theme.palette.words.subtitle.natural};
      font: normal normal 400 16px/18px Texta;
    }
  `}
`;

export const FormTab = styled.div`
  ${({ show }) => css`
    display: ${show ? 'contents' : 'none'};
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font: normal normal 900 24px/33px Texta;
    color: ${theme.palette.words.title.natural};

    img {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }
  `}
`;
