export const filter = (data, state, searchKeys) => {
  const { search } = state;

  let filtered = data;

  if (search) {
    filtered = data.filter(item => {
      let found = false;

      searchKeys.forEach(key => {
        const searchable = item;

        // Format keys intro object
        if (key?.includes('.')) {
          const keys = key.split('.');
          let value = searchable;
          keys?.forEach(k => {
            value = value?.[k];
          });
          searchable[key] = value;
        }

        if (
          searchable[key] &&
          searchable?.[key]
            ?.toString()
            ?.toLowerCase()
            ?.includes(search?.toLowerCase())
        ) {
          found = true;
        }
      });

      return found;
    });
  }

  return filtered;
};
