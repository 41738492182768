export const placement = (value, mode, data, placeholder) => {
  const isFilled =
    (mode === 'single' && (value || value === 0)) ||
    (mode === 'multiple' && value?.length > 0);

  if (!isFilled) return placeholder;

  if (mode === 'single')
    return limitText(data?.find(item => item?.value === value)?.label);
  if (mode === 'multiple')
    return limitText(
      data
        ?.filter(item => value?.includes(item?.value))
        ?.map(item => item?.label)
        ?.join(', '),
    );
};

const limitText = (text, limit = 55) => {
  if (text?.length <= limit) return text;
  return `${text?.slice(0, limit)}...`;
};

export const selectionLabel = (mode, value) => {
  if (mode === 'single') return 'Limpar seleção';
  if (mode === 'multiple')
    return value?.length > 0 ? 'Limpar seleção' : 'Selecionar todos';
};
