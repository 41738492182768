import * as S from './styled';

const tagsLoading = ['', '', '', '', '', '', '', '', '', '', ''];

const Tag = ({ children, active, onClick, disabled, loading }) => {
  return (
    <>
      {!loading && (
        <S.TagItem
          onClick={!disabled ? onClick : undefined}
          className={active ? 'active' : ''}
        >
          {children}
        </S.TagItem>
      )}
      {loading &&
        tagsLoading.map((item, key) => <S.TagItemLoading key={key} />)}
    </>
  );
};

export default Tag;
