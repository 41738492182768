import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const TemplatePage = styled.div`
  margin-left: 230px;
  padding: 20px 70px;
  width: 100%;
  margin-bottom: 60px;
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;

export const HeaderPage = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .titlePage {
      font-size: 24px;
      color: ${theme.palette.words.title.natural};
      font-weight: 900;
    }

    .leftHeader {
      display: flex;
      align-items: center;

      .inReview {
        color: ${theme.palette.words.subtitle.natural};
        background-color: ${theme.palette.brand.primary.background};
        margin-right: 25px;
        padding: 5px 20px;
        box-shadow: 0px 2px 3px ${theme.palette.system.divider};
        border-radius: 50px;
        font-weight: 600;
      }
    }
  `}
`;

export const ControllerArea = styled.div`
  ${({ theme }) => css`
    position: fixed;
    z-index: 998;
    bottom: 0;
    right: 0;
    width: calc(100% - 290px);
    height: 65px;
    background-color: ${theme.palette.system.overlay};
    box-shadow: 0px -5px 12px -4px ${theme.palette.system.backdrop};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;

    .statusArea {
      font-weight: 700;
      display: flex;
      align-items: center;
    }

    .buttonsArea {
      .backArea {
        margin-right: 20px;
      }
    }
  `}
`;
