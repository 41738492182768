import * as types from './types';

export function setFilterProvider(query) {
  return {
    type: types.SET_FILTER_REVISAO_OPERACAO,
    payload: { query },
  };
}

export function changeTabRevisaoOperacao({ tab }) {
  return {
    type: '@filterRevisaoOperacao/CHANGE_TAB_REVISAO_OPERACAO',
    payload: { tab },
  };
}
