// Styleds
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

// Components
import { DatePicker } from './Date';
// import Table from 'components/Table';
import Loading from 'components/Loading';
import ConfirmModal from 'components/ConfirmModal';
import { FilterTable } from 'components/_Table/templates/filter';

// Components MUI
import { Box } from '@mui/system';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { CircularProgress, Grid, Menu, MenuItem } from '@mui/material';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import SpellcheckOutlinedIcon from '@mui/icons-material/SpellcheckOutlined';
// import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
// import { Tooltip } from '@mui/material';
import { filters } from './constants';

// Services
import { formatNewHour } from 'utils/dates';
// import qs from 'qs';
import GhostButton from 'components/Buttons/Ghost';
import * as services from '../services';

// Utils
import { fequencyModule } from '../actions';

// Columns
import {
  // columnsTotal,
  // columnsApprove,
  // columnsOcurrence,
  // columnsSmall,
  columnsTotal2,
  columnsApprove2,
  columnsOcurrence2,
  columnsSmall2,
} from './columns';
import { ModalPendentes } from './ModalPendentes';
import ModalSign from '../../../components/ModalSign';
import * as S from './styled';

// filter
// import Filters from '../Filters';
import { ModalJustificativa } from './ModalJustificativa';

let timerRoute = null;
const loading = false;

export const Users = ({ select, currentSelect, setCurrentSelect }) => {
  const theme = useTheme();
  // Redux
  const formularios = useSelector(state => state.formularios);
  const gerais = formularios.gerais;
  const hasRepet = formularios.gerais.repetir;

  const navigate = useNavigate();

  // Data States
  const [dataJustify, setDataJustify] = useState([]);

  // Controllers States
  const [query, setQuery] = useState(null);
  const [signModal, setSignModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectedForms, setSelectedForms] = useState([]);

  const [formHist, setFormHist] = useState(null);
  const [modalView, setModalView] = useState(false);
  const [loadingLine, setLoadingLine] = useState([]);
  const [currentHist, setCurrentHist] = useState(null);
  const [modalJustify, setModalJustify] = useState(false);
  const [modalPendding, setModalPendding] = useState(false);
  const [loadingJustify, setLoadingJustify] = useState(false);

  // ---------------------------------------REQUESTS---------------------------------------//

  const {
    data,
    refetch,
    isLoading: loading,
    isFetching,
  } = useQuery(
    [`/form-reports-table-${formularios.id}`, query],
    () => query && services.getInfosByPeriod(formularios.id, query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => setResetTable(false),
      onError: data => toast.error(data.message),
    },
  );

  const {
    data: resCards,
    isLoading: loadingCards,
    refetch: refetchCards,
  } = useQuery(
    [`/form-reports-cards-${formularios.id}`, currentSelect],
    () =>
      currentSelect &&
      services.getCardsByPeriod(formularios.id, {
        start: currentSelect[0],
        end: currentSelect[1],
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => setResetTable(false),
      onError: data => toast.error(data.message),
    },
  );

  const initialize = useRef(true);
  const [resetTable, setResetTable] = useState(false);
  useEffect(() => {
    if (initialize.current) {
      initialize.current = false;
      return;
    }
    setResetTable(true);
  }, [currentSelect]);

  // -------------------------------------------------FORMAT TABLE--------------------------------------------------//

  const formatTypeColumns = () => {
    if (gerais.aprovador && gerais.ocorrencias) {
      return columnsTotal2;
    }
    if (gerais.aprovador) {
      return columnsApprove2;
    }
    if (gerais.ocorrencias) {
      return columnsOcurrence2;
    }
    return columnsSmall2;
  };

  const actions = [
    {
      title: 'Visualizar',
      function: id => getCurrentResponses(id),
    },
    {
      title: 'Assinar',
      function: id => identifyCurrentItem(id),
      visible: item =>
        gerais.aprovador &&
        !item?.data_assinatura &&
        !selectedForms?.length &&
        !item.justificado,
    },
    {
      title: 'Ver Perfil',
      function: (_, item) =>
        window.open(`/motoristas/${item?.motorista?.id}?step=6`),
      visible: item => item.motorista,
    },
  ];

  // Actions
  const signatureApprove = async () => {
    let ids = [];
    setSignModal(false);
    if (selectedForms?.length > 0) {
      ids = selectedForms.filter(
        item => !data?.rows?.find(i => i.id === item)?.justificado,
      );
    } else {
      ids = currentItem.ids;
    }

    let res = await services.signApprove(formularios.id, { ids });
    if (res.success) {
      toast.success(res.message);
      refetch();
    } else {
      toast.error(res.message);
    }
  };

  const identifyCurrentItem = id => {
    let ids = (selectedForms?.length > 0 ? selectedForms : [+id]).filter(
      item => !data?.rows?.find(i => i.id === item)?.justificado,
    );

    let currentForms = data?.rows?.filter(item => ids.includes(item.id));
    let classNames = currentForms.map(user => user.aluno.nome);

    let ListComponent = (
      <>
        <S.ListUsers>
          <div className="title">
            {selectedForms?.length > 0
              ? 'Os seguintes alunos serão aprovados:'
              : 'O seguinte aluno será aprovado:'}
          </div>
          <ul>
            {classNames.map((user, key) => (
              <li key={key}>{user}</li>
            ))}
          </ul>
        </S.ListUsers>
      </>
    );

    setCurrentItem({ ids: [id], ListComponent });
    setSignModal(true);
  };
  // --------------------------------------------------------------------------------------------------------------//

  // Control back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    if (!signModal) {
      timerRoute = setTimeout(() => {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }, 100);
    }

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [signModal]);

  // ------------------------------------------------ MODAL VIEW CONTROL----------------------------------------//
  const getCurrentResponses = async id => {
    let currentItem = data?.rows?.find(item => +item.id === +id);
    let alunoId = currentItem?.aluno?.id;
    let alunoTipo = currentItem?.motorista ? 'MOTORISTA' : 'GESTOR';

    if (currentItem?.justificado) {
      toast.warn(`Usuários justificados não possuem respostas!`);
      return null;
    }

    setLoadingLine([id]);
    setCurrentHist(+id);
    let res = await services.getFormResponse(
      formularios.id,
      id,
      alunoId,
      alunoTipo,
    );
    if (res.success) {
      setFormHist(res);
      setModalView(true);
    } else {
      toast.error(res.message);
    }
    setLoadingLine([]);
  };

  const calcPercentageCircle = () => {
    const percent = (data?.cards?.[0]?.value * 100) / data?.cards?.[0]?.turmas;
    return Math.round(percent || 0);
  };

  // ------------------------------------------------ MODAL JUSTIFY CONTROL----------------------------------------//
  const handleModalJustify = data => {
    setDataJustify(data);
    setModalPendding(false);
    setModalJustify(true);
  };

  const handleJustify = async data => {
    setLoadingJustify(true);
    let res = await services.justify(formularios.id, {
      justificativa: data,
      data_historico: currentSelect[0],
      alunos: dataJustify,
    });
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    setLoadingJustify(false);
    setDataJustify([]);
    await refetch();
    refetchCards();
    setModalPendding(false);
    setModalJustify(false);
  };

  // ---------------------------------------------RENDER-------------------------------------------//
  return (
    <>
      <S.InfoLine>
        <div className="period">{fequencyModule(gerais)}</div>
        {hasRepet && (
          <label className="filters">
            <span className="label">Periodo selecionado:</span>
            <DatePicker
              initialize={currentSelect}
              setCurrentSelect={setCurrentSelect}
              select={select}
              periodo={gerais.periodo}
            />
          </label>
        )}
      </S.InfoLine>

      <S.Container>
        <S.BoxInfos>
          {!loadingCards && (
            <>
              <S.CircleBar>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-flex',
                    transform: 'scale(2)',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={calcPercentageCircle()}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FactCheckOutlinedIcon
                      htmlColor={theme.palette.brand.secondary.natural}
                      style={{ transform: 'scale(0.7)' }}
                    />
                  </Box>
                </Box>
                <div className="textFields">
                  <div className="text">{resCards?.[0]?.text}</div>
                  <div className="value">
                    {resCards?.[0]?.value}/{resCards?.[0]?.turmas || 0}
                  </div>
                </div>
              </S.CircleBar>
              {formularios.gerais.aprovador && (
                <>
                  <div className="bar" />
                  <S.Card>
                    <div className="text">{resCards?.[1]?.text}</div>
                    <div className="value">{resCards?.[1]?.value}</div>
                  </S.Card>
                </>
              )}
              <div className="bar" />
              <S.Card>
                <div className="text">{resCards?.[2]?.text}</div>
                <div className="value">{resCards?.[2]?.value}</div>
              </S.Card>
              <div className="bar" />
              <S.Card>
                <div className="text">{resCards?.[3]?.text}</div>
                <div className="value">{resCards?.[3]?.value}</div>
              </S.Card>
            </>
          )}
          {loadingCards && (
            <S.BoxLoading>
              <Loading />
            </S.BoxLoading>
          )}
        </S.BoxInfos>

        <S.TableArea>
          <div className="visualButton">
            <GhostButton
              onClick={() => setModalPendding(true)}
              children="alunos pendentes"
              icon={<SchoolOutlinedIcon />}
              customcolor={theme.palette.semantics.feedback.attention.natural}
              style={{ marginRight: '10px' }}
            />
          </div>

          <FilterTable
            data={data?.rows || []}
            columns={formatTypeColumns()}
            setSelectedRows={setSelectedForms}
            loading={loading}
            pageCount={data?.count || 0}
            local={false}
            filters={filters}
            actions={actions}
            onClickRow={id => getCurrentResponses(id)}
            reset={resetTable}
            loadingSelection={loadingLine}
            setQuery={q =>
              setQuery({
                ...q,
                start: currentSelect[0],
                end: currentSelect[1],
              })
            }
            sortBy={{ id: 'aluno.nome', order: 'ASC' }}
            empty={{
              title: hasRepet
                ? 'Ops! Você não tem nenhuma resposta para esse período'
                : 'Ops! Você não tem nenhuma resposta para esse formulário',
              description: hasRepet
                ? 'Verifique o período selecionado'
                : 'Aguarde até que os alunos iniciem as respostas',
            }}
            bulk={
              gerais.aprovador
                ? [
                    {
                      title: 'Assinar',
                      function: () => identifyCurrentItem(),
                    },
                  ]
                : undefined
            }
          />
        </S.TableArea>

        <ConfirmModal
          open={signModal}
          handleClose={() => {
            setSignModal(false);
            setCurrentItem(null);
          }}
          buttonText="Confirmar"
          title={`Tem certeza que deseja assinar ${
            selectedForms?.length > 0 ? 'os formulários?' : 'o formulário'
          }`}
          titleIcon={
            <SpellcheckOutlinedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
          }
          onClick={signatureApprove}
          subtitle={
            currentItem?.ListComponent /* 'Os formulários serão assinados e essa ação não poderá ser desfeita' */
          }
        />
      </S.Container>
      {formHist && modalView && (
        <ModalSign
          open={modalView}
          onClose={() => {
            setModalView(false);
            setFormHist(false);
            setCurrentHist(null);
          }}
          form={formHist}
          histId={currentHist}
          refetch={getCurrentResponses}
          loading={loadingLine}
          backPath={`/formularios/configuracao/editar/${formularios.id}}`}
        />
      )}

      {modalPendding && (
        <ModalPendentes
          open={modalPendding}
          data={data?.pending || []}
          onClose={() => setModalPendding(false)}
          currentPeriod={currentSelect}
          idForm={formularios?.id}
          openModalJustify={handleModalJustify}
          periodo={gerais.periodo}
        />
      )}
      {modalJustify && (
        <ModalJustificativa
          open={modalJustify}
          dataLength={dataJustify.length}
          onClick={handleJustify}
          isLoading={isFetching || loadingJustify}
          onClose={() => {
            setModalJustify(false);
            setModalPendding(true);
          }}
        />
      )}
    </>
  );
};
