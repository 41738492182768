import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTable, useSortBy, usePagination } from 'react-table';
import {
  ExpandMore,
  ExpandLess,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { InputAdornment, SvgIcon, Stack } from '@mui/material';
import Select from 'components/Inputs/Select';
import Loading from 'components/Loading';
import Input from 'components/Inputs/TextField';
import EmptyCard from 'components/Cards/EmptyDataCard';
import { ptMonths } from 'utils/dates';
import Icon from 'components/Icons';
import { useTheme } from 'styled-components';
import { useDebounce } from 'use-debounce';
import { trackEvent } from 'utils/mixpanel';
import { ReactComponent as searchIcon } from '../../images/icons/inputs/Search.svg';
import * as S from './styled';

const TableDisponibility = ({
  handleFilter,
  columns,
  data,
  search,
  mesAno,
  setMesAno,
  onClickRow,
  loading,
  permitIsSortedOccur,
  sortBy,
  pageToMixPanel, // Parâmetro para MixPanel --- Optional
  ...props
}) => {
  const today = new Date();
  const theme = useTheme();

  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const [searchInput, setSearchInput] = useState('');
  const [searchApply, setSearchApply] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [searchTrackEvent] = useDebounce(searchInput, 1000);

  const dataTable = searchApply?.length > 0 ? searchResult : data;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize, pageIndex },
  } = useTable(
    {
      columns,
      data: dataTable,
      initialState: {
        pageSize: 10,
        sortBy,
      },
    },
    useSortBy,
    usePagination,
  );

  // Recebe atualziação da busca e dos dados filtrados
  useEffect(() => {
    if (search) {
      // Limpa espaços e padroniza string de busca
      const searchString = searchInput.toLowerCase().trim();
      // Salva busca aplicada para referenciar os dados corretamente
      setSearchApply(searchString);

      // Só faz busca se searchString for diferente de ''
      if (searchString) {
        let newResult = [];
        for (const key of search.keys) {
          const finded = data.filter(item => {
            const itemString = String(item[key]).toLowerCase().trim();
            return itemString.includes(searchString);
          });
          newResult = [...newResult, ...finded];
        }
        setSearchResult(newResult);
      }
    }
  }, [searchInput, data]);

  useEffect(() => {
    // Valida busca com pelo menos 3 caracteres para o MixPanel
    if (searchTrackEvent.length >= 3) {
      trackEvent(user, pageToMixPanel, null, searchTrackEvent);
    }
  }, [searchTrackEvent]);

  const renderSearch = () => (
    <S.TopWrapper>
      <form id="searchForm" action="" onSubmit={e => e.preventDefault()}>
        <Input
          placeholder={search.inputLabel}
          name="busca"
          id="busca"
          value={searchInput}
          onChange={e =>
            setSearchInput(e.target.value.trim() === '' ? '' : e.target.value)
          }
          style={{
            width: '300px',
            background: theme.palette.brand.primary.background,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  component={searchIcon}
                  color={theme.palette.brand.primary.light}
                />
              </InputAdornment>
            ),
          }}
        />
      </form>
    </S.TopWrapper>
  );

  const renderMonthDiv = () => (
    <S.MonthDiv>
      <S.ArrowButton
        // disabled={Math.abs(today.getMonth() - mesAno.mes) > 5}
        onClick={() => {
          if (mesAno.mes > 0) setMesAno({ ...mesAno, mes: mesAno.mes - 1 });
          else setMesAno({ ano: mesAno.ano - 1, mes: 11 });
        }}
      >
        <KeyboardArrowLeft sx={{ color: theme.palette.words.button.natural }} />
      </S.ArrowButton>

      <div>
        {ptMonths[mesAno.mes]} / {mesAno.ano}
      </div>

      <S.ArrowButton
        disabled={
          today.getMonth() === mesAno.mes && today.getFullYear() === mesAno.ano
        }
        onClick={() => {
          if (mesAno.mes < 11) setMesAno({ ...mesAno, mes: mesAno.mes + 1 });
          else setMesAno({ ano: mesAno.ano + 1, mes: 0 });
        }}
      >
        <KeyboardArrowRight
          sx={{ color: theme.palette.words.button.natural }}
        />
      </S.ArrowButton>
    </S.MonthDiv>
  );

  const renderTable = () => (
    <S.Table {...getTableProps()}>
      <S.Head>
        {headerGroups.map(headerGroup => (
          <S.Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <S.Th
                {...column.getHeaderProps(
                  permitIsSortedOccur &&
                    !column.cantSort &&
                    column.getSortByToggleProps(),
                )}
              >
                {column.render('Header')}
                {permitIsSortedOccur && (
                  <span>
                    {column.isSorted &&
                      (column.isSortedDesc ? (
                        <ExpandLess fontSize="small" />
                      ) : (
                        <ExpandMore fontSize="small" />
                      ))}
                  </span>
                )}
              </S.Th>
            ))}
          </S.Tr>
        ))}
      </S.Head>

      <S.Body {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <S.Tr
              {...row.getRowProps(
                onClickRow && { onClick: () => onClickRow(row) },
              )}
            >
              {row.cells.map(cell => {
                return (
                  <S.Td {...cell.getCellProps()}>{cell.render('Cell')}</S.Td>
                );
              })}
            </S.Tr>
          );
        })}
      </S.Body>
    </S.Table>
  );

  const renderFooter = () => (
    <S.Footer>
      <S.FooterCol>
        <p>Exibindo</p>
        <Select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          data={[
            { name: '10', value: 10 },
            { name: '20', value: 20 },
          ]}
          style={{ height: '35px', width: '70px' }}
        />
        <p>{`de ${dataTable.length}`}</p>
      </S.FooterCol>

      <Stack spacing={2}>
        <S.FooterCol>
          <S.Control onClick={() => gotoPage(0)}>
            <Icon name="chevron-left-outline" />
            <p>Primeiro</p>
          </S.Control>
          <S.Control onClick={() => previousPage()}>
            <Icon name="chevron-left-outline" />
            <p>Anterior</p>
          </S.Control>
          <S.Pages
            count={pageCount || 1}
            page={pageIndex + 1}
            onChange={(e, value) => gotoPage(value - 1)}
            hidePrevButton
            hideNextButton
          />
          <S.Control
            onClick={() => (pageIndex + 1 < pageCount ? nextPage() : null)}
          >
            <p>Próximo</p>
            <Icon name="chevron-right-outline" />
          </S.Control>
          <S.Control onClick={() => gotoPage(pageCount - 1)}>
            <p>Último</p>
            <Icon name="chevron-right-outline" />
          </S.Control>
        </S.FooterCol>
      </Stack>
    </S.Footer>
  );

  return (
    <S.Wrapper {...props}>
      {renderSearch()}
      {renderMonthDiv()}

      {data.length > 0 ? (
        renderTable()
      ) : (
        <EmptyCard
          image="frota.png"
          title="Ops! você não tem nenhum veículo cadastrado."
          subtitle={'Vá em "adicionar veículo" e comece agora mesmo!'}
        />
      )}

      {data.length > 0 && renderFooter()}

      {loading && (
        <S.LoadingDiv>
          <Loading />
        </S.LoadingDiv>
      )}
    </S.Wrapper>
  );
};

export default TableDisponibility;
