import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  responsavel: null,
  client: '',
  operation: '',
  empresas: null,
};

export default function filterRotograma(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_ROTOGRAMA: {
        draft.responsavel = action.payload.query.responsavel;
        draft.client = action.payload.query.client;
        draft.operation = action.payload.query.operation;
        draft.empresas = action.payload.query.empresas;
        break;
      }
      default:
    }
  });
}
