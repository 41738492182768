import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const Title = styled.span`
  font: normal normal 900 2rem Texta;
  text-transform: capitalize;
  letter-spacing: 0px;
  color: #4b5166;
  opacity: 1;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Flex = styled.div`
  display: flex;

  &.full-width {
    width: 100%;
  }
  &.mt-05 {
    margin-top: 0.5rem;
  }
  &.gap-1 {
    gap: 1rem;
  }
  &.align-center {
    align-items: center;
  }
`;
