import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { onisysTheme, getTheme } from '../theme';
import * as services from '../theme/services';

export const CustomThemeProvider = ({ children }) => {
  const [params] = useSearchParams();
  const [theme, setTheme] = useState(onisysTheme);
  const paramPrimaryPaletteId = params.get('primary');
  const paramSecondaryPaletteId = params.get('secondary');

  const { user } = useSelector(state => state.auth);
  const empresa = user?.user?.provider
    ? user?.user?.distribuidora
    : user?.user.filial?.empresa;

  const { data: themes = [] } = useQuery(
    ['whitelabelThemes'],
    () => services.requestWhitelabelOptions(),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(async () => {
    const primaryPaletteId =
      paramPrimaryPaletteId ?? empresa?.id_da_whitelabel_primaria;
    const secondaryPaletteId =
      paramSecondaryPaletteId ?? empresa?.id_da_whitelabel_secundaria;

    if (empresa?.contrato?.is_whitelabel) {
      const _theme = await getTheme(
        themes,
        primaryPaletteId,
        secondaryPaletteId,
      );
      setTheme(_theme);
    } else {
      // reseta tema no logout
      setTheme(onisysTheme);
    }
  }, [empresa, themes]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
