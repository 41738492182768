import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';

// Actions
import {
  setFilter,
  setGroupBy,
} from 'store/modules/filterFadigaAreaRisco/actions';

// Componentes
import { Grid, Tooltip, SvgIcon } from '@mui/material';
import { HelpOutlineOutlined, SaveAlt } from '@mui/icons-material';
import Loading from 'components/Loading';
import Select from 'components/Inputs/Select';
import TableLocal from 'components/TableLocal';
import CloseIcon from '@mui/icons-material/Close';
import GhostButton from 'components/Buttons/Ghost';
import { ReactComponent as filterIco } from 'images/icons/filter.svg';

import { GoogleMap } from 'components/GoogleMap';
import {
  MapInfoButton,
  MapInfoContent,
  MapInfoWrapper,
} from 'components/GoogleMap/styled';
import PlacesAutocomplete from 'components/PlacesAutocomplete';

// Service
import * as S from '../styled';
import { columns } from './columns';
import { requestEstatisticas, requestExcel } from './services';
import FilterModal from './FilterModal';

const valuesSelect = [
  { value: 'QUANTIDADE', name: 'Quantidade de Desvios' },
  { value: 'PONTOS', name: 'Total de Pontos' },
];

const sortBy = [
  {
    id: 'percentual',
    desc: true,
  },
];

const AreaRisco = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector(prevFilter => prevFilter.auth.user);
  const userLevel = user?.nivel;
  const isProvider = user?.provider;
  const [filter, filterRisco, groupBy] = useSelector(state => {
    return [
      isProvider ? state.filterProvider : state.filter,
      state.filterFadigaAreaRisco.filter,
      state.filterFadigaAreaRisco.groupBy,
    ];
  });

  const [tableData, setTableData] = useState([]);
  const [points, setPoints] = useState([]);
  const [map, setMap] = useState(null);
  const [heatmapRef, setHeatmapRef] = useState(null);
  const [mapZoom, setMapZoom] = useState(0);
  const [mapZoomListener, setMapZoomListener] = useState(null);
  const [mapInfo, setMapInfo] = useState({
    open: true,
    content: 'Busque a localização ou navegue no mapa aplicando o zoom',
  });

  const countFilter = Object.getOwnPropertyNames(filterRisco).length;
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const getPoints = useCallback((points, groupBy) => {
    if (groupBy === 'PONTOS') return points;
    return points.map(point => point.location);
  }, []);

  // Altera o heatmap quando os parametros mudam
  useEffect(() => {
    if (map && heatmapRef) {
      heatmapRef.setData(getPoints(points, groupBy));
    }
  }, [map, groupBy, points]);

  // Altera o radius do heatmap de acordo com o zoom
  useEffect(() => {
    if (heatmapRef) heatmapRef.set('radius', mapZoom + 4);
  }, [heatmapRef, mapZoom]);

  useEffect(() => {
    if (map) {
      // Instancia a camada heatmap
      const heatmap = new google.maps.visualization.HeatmapLayer({
        data: [],
        map,
        options: {
          opacity: 0.8,
        },
      });
      setHeatmapRef(heatmap);

      // Eventlistener de alteração de zoom no mapa
      const mapZoomListener = google.maps.event.addListener(
        map,
        'zoom_changed',
        function () {
          setMapZoom(map.get('zoom'));
        },
      );

      setMapZoomListener(mapZoomListener);
    }
    return () => {
      if (heatmapRef) heatmapRef.setMap(null);
      if (mapZoomListener)
        window.google.maps.event.removeListener(mapZoomListener);
    };
  }, [map]);

  const setFilterRisco = handleChange => {
    const newState = handleChange(filterRisco);
    dispatch(setFilter(newState));
  };

  // Fetch data
  const fetchData = async query => {
    setLoading(true);
    const res = await requestEstatisticas(query);
    if (res.data?.success) {
      if (res.data?.data instanceof Array) {
        setPoints(
          res.data.data.map(point => ({
            location: new google.maps.LatLng(point.latitude, point.longitude),
            weight: point.pontos,
          })),
        );
        setTableData(res.data.resumos);
      }
    } else if (res.data?.message) toast.error(res.data.message);
    setLoading(false);
  };

  const formatArrayFilter = useCallback(
    filters => {
      const _filters = {};
      Object.keys(filters).forEach(key => {
        _filters[key] = filters[key]
          .split(',')
          .map(item => item.replaceAll(';;;', ','));
      });

      // Se nivel 2 ou 3 sempre filtra filial
      if (userLevel > 1) _filters.filiais = [user?.id_da_filial];
      _filters.categoria = ['FADIGA'];

      return _filters;
    },
    [userLevel, user],
  );

  useEffect(() => {
    const query = {
      ...filter,
      ...formatArrayFilter(filterRisco),
      mode: groupBy,
    };

    // Só busca os dados depois de carregar o mapa, pra conseguir instanciar as Locations;
    if (map) fetchData(query);
  }, [map, filter, filterRisco, groupBy]);

  const handleChangeGroup = e => {
    dispatch(setGroupBy(e.target.value));
  };

  // Exportção excel
  const handleExcel = async () => {
    setLoadingExcel(true);

    const formatedDate = formatNameDate(new Date());
    const newQuery = {
      ...filter,
      ...formatArrayFilter(filterRisco),
      mode: groupBy,
      excelFields: [
        {
          label: 'Rodovia',
          value: 'rodovia',
        },
        {
          label:
            groupBy === 'QUANTIDADE'
              ? 'Quantidade de Desvios'
              : 'Total de Pontos',
          value: 'total',
        },
        {
          label: 'Percentual do Todo',
          value: 'percentual',
        },
      ],
    };

    const res = await requestExcel(isProvider, newQuery);
    if (res.data?.data?.excel) {
      ExportToExcel({
        excel: res.data.data.excel,
        name: `area_de_risco_fadiga_${formatedDate}`,
      });
      toast.success(res.data.message);
    } else if (res.data.message) toast.error(res.data?.message);
    setLoadingExcel(false);
  };

  // Formatação das colunas
  const formatColumns = useCallback(
    col =>
      col.map(i => {
        if (i.id === 'total')
          return {
            ...i,
            Header:
              groupBy === 'QUANTIDADE'
                ? 'Quantidade de Desvios'
                : 'Total de Pontos',
            Cell: ({ row }) => {
              return <S.TableName center>{row.original.total}</S.TableName>;
            },
          };

        return i;
      }),
    [groupBy],
  );

  // renders
  const renderMap = () => (
    <S.MapContainer>
      <S.MapHeader>
        <S.MapFilters>
          {map && (
            <PlacesAutocomplete
              sx={{
                width: '300px',
                '.MuiOutlinedInput-root': { padding: '3px' },
              }}
              onChange={position => {
                map?.setCenter(position);
                map?.setZoom(11);
                setMapInfo(state => ({
                  ...state,
                  content: 'Clique no mapa para definir o ponto',
                }));
              }}
            />
          )}
          <Select
            style={{ height: '44px', marginTop: '4px' }}
            data={valuesSelect}
            value={groupBy}
            handleChange={handleChangeGroup}
            disabled={loading}
          />
          <S.StyledButton
            textcolor={theme.palette.words.text.natural}
            backgroundcolor="transparent"
            startIcon={<SvgIcon component={filterIco} />}
            height="45px"
            style={{ marginTop: '3px' }}
            onClick={() => setFilterModalOpen(true)}
            disabled={loading}
          >
            Filtros
            <S.Count count={!!countFilter}>{countFilter}</S.Count>
          </S.StyledButton>
        </S.MapFilters>
        <S.MapActions>
          {loading && (
            <S.BoxLoading>
              <Loading />
            </S.BoxLoading>
          )}
        </S.MapActions>
      </S.MapHeader>
      <GoogleMap
        mapContainerClassName="map"
        options={{
          center: {
            lat: -13.923025,
            lng: -56.8509357,
          },
          zoom: 4,
          zoomControl: true,
          styles: [
            {
              stylers: [
                {
                  saturation: -100,
                },
              ],
            },
          ],
        }}
        getMapInstance={mapInstance => setMap(mapInstance)}
      >
        {mapInfo.open && (
          <MapInfoWrapper>
            <MapInfoContent>{mapInfo.content}</MapInfoContent>
            <MapInfoButton
              onClick={e => setMapInfo(state => ({ ...state, open: false }))}
            >
              <CloseIcon fontSize="8px" />
            </MapInfoButton>
          </MapInfoWrapper>
        )}
      </GoogleMap>
    </S.MapContainer>
  );

  const renderTable = () => (
    <div style={{ marginTop: '20px' }}>
      <TableLocal
        columns={formatColumns(columns)}
        data={tableData}
        sortBy={sortBy}
        permitIsSortedOccur
        tableType
        loading={loading}
        bulkActions={[]}
      />
    </div>
  );

  const renderMain = () => (
    <>
      <S.Main>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} xl={12}>
            {renderMap()}
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '1rem 0',
              }}
            >
              <div />
              <S.Title>
                Resumo{' '}
                <Tooltip
                  title="Resumo das informações apresentadas no mapa"
                  placement="top"
                >
                  <HelpOutlineOutlined
                    sx={{ fontSize: '1rem' }}
                    htmlColor={theme.palette.words.text.light}
                  />
                </Tooltip>
              </S.Title>
              <GhostButton
                startIcon={<SaveAlt />}
                loading={loadingExcel}
                size="medium"
                onClick={() => handleExcel()}
                style={{ marginLeft: '10px' }}
              >
                EXPORTAR
              </GhostButton>
            </div>
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            {renderTable()}
          </Grid>
        </Grid>
      </S.Main>

      <FilterModal
        open={filterModalOpen}
        filter={filterRisco}
        setFilter={setFilterRisco}
        handleClose={() => setFilterModalOpen(false)}
      />
    </>
  );

  return renderMain();
};

export default AreaRisco;
