/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setConcluded } from 'store/modules/guia/actions';

// Components
import { View } from '../View';
import { SideBar } from './sidebar';
import { InitialGuide } from '../Inicio';
import { Progress } from 'components/CircularProgress';
import EmptyCard from 'components/Cards/EmptyDataCard';

// Components MUI
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

// Services
import * as services from './services';
import { initialize, initializeTab } from './constants';

const GuiaPage = () => {
  // Redux
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth.user);

  // Data States
  const [data, setData] = useState(initialize);
  const [tabs, setTabs] = useState(initializeTab);
  const [cleanResponse, setCleanResponse] = useState([]);
  const [currentGuide, setCurrentGuide] = useState({
    guide: null,
    first: false,
  });

  // Control States
  const [collapse, setCollapse] = useState([]);
  const [tab, setTab] = useState('monitoramento');
  const [hoverGuide, setHoverGuide] = useState(false);
  const [percents, setPercents] = useState([]);
  const [dataGuide, setDataGuide] = useState([]);

  // ----------------------------------- REQUESTS --------------------------------------------
  const { isFetching: loading } = useQuery(
    ['guia-index', 2],
    () => services.getIndex(user.provider),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setCleanResponse(data.data);
        formatInitialData(data.data);
      },
    },
  );

  const { refetch } = useQuery(['guia-infos', 3], () => services.getInfos(), {
    refetchOnWindowFocus: false,
    enabled: !user.provider,
    onSuccess: data => setDataGuide(data.data),
  });

  const formatInitialData = res => {
    let resTabs = [];
    let resData = {};
    let percentages = [];
    let copyRes = JSON.parse(JSON.stringify(res));
    copyRes.forEach(item => {
      resTabs.push({
        id: item?.id,
        value: item?.titulo,
        label: item?.descricao,
      });
      resData[item?.titulo] = item?.secao?.filter(section => {
        const guides = section.guia.filter(guide => {
          let time = 0;
          let respTime = 0;
          let respondidas = 0;
          let idsResp = [];
          const steps = guide.etapas.filter(step => {
            if (
              step.ativa &&
              step.niveis.split(',').includes(user.nivel.toString()) &&
              step.mostrar_guia
            ) {
              time += step.tempo;
              respTime += step.respondida ? step.tempo : 0;
              respondidas +=
                step.respondida && step.is_etapa_obrigatoria ? 1 : 0;
              step.respondida && idsResp.push(step.id);

              if (step.is_capa) {
                guide.capa = step;
                return false;
              }
              return true;
            }
          });

          if (
            (steps.length > 0 || (guide.capa && guide.capa.ativa)) &&
            guide.ativa
          ) {
            guide.etapas = steps;
            guide.tempo = time;
            guide.respondidas = respondidas;
            percentages.push({
              id_secao: section.id,
              id_guia: guide.id,
              respondidas,
              total:
                steps.filter(s => s.is_etapa_obrigatoria).length +
                (guide?.capa?.is_etapa_obrigatoria ? 1 : 0),
              tempo: time,
              respTempo: respTime,
              idsResp,
            });
            setPercents(percentages);
            return true;
          }
        });

        if (guides.length > 0 && section.ativa) {
          section.guia = guides;
          return true;
        }
      });
    });

    const availableTabs = resTabs.filter(t => resData[t.value]?.length > 0);
    setData(resData);
    setTabs(availableTabs);
    setTab(availableTabs[0]?.value);
  };

  // -------------------------------------- COLLAPSE -----------------------------------------
  useEffect(() => {
    const arr = [];
    data[tab]?.map(() => arr.push(tab === 'primeiros_passos'));
    setCollapse(arr);
  }, [tab, data]);

  const handleCollapse = index => {
    const arr = [];
    collapse?.map((item, key) => {
      if (key === index) {
        arr.push(!item);
      } else {
        arr.push(item);
      }
    });
    setCollapse(arr);
  };

  // -------------------------------------- EXECUTE --------------------------------------------
  const execute = async (id_guia, step) => {
    let currentGuide = null;
    cleanResponse.forEach(arr => {
      arr.secao.forEach(section => {
        section.guia.forEach(guide => {
          if (guide.id === id_guia) {
            currentGuide = guide;
          }
        });
      });
    });
    const currentStep = currentGuide.etapas[step];

    await services
      .responseGuia(user.provider, {
        id: currentStep.id,
        guia: currentStep.id_guia_conteudo,
      })
      .then(res => {
        res?.conclusao_total_guias &&
          dispatch(setConcluded(res.conclusao_total_guias));
      });

    const updatedPercents = percents.map(p => {
      if (p.id_guia === currentStep.id_guia_conteudo) {
        if (!p.idsResp.includes(currentStep.id)) {
          if (currentStep.is_etapa_obrigatoria) p.respondidas += 1;
          p.respTempo += currentStep.tempo;
          p.idsResp = [...p.idsResp, currentStep.id];
        }
      }
      return p;
    });

    setPercents(updatedPercents);
  };

  const sendEvaluation = (id, evaluation) => {
    services.evaluateGuia(user.provider, id, evaluation);
  };

  // -------------------------------------- PERCENTAGE --------------------------------------------
  const guidePercentage = id_guia => {
    const guide = percents.find(p => p.id_guia === id_guia);
    return Math.round((guide?.respondidas / guide?.total) * 100);
  };

  const sectionValues = id_secao => {
    const section = percents.filter(p => p.id_secao === id_secao);
    let finished = 0;
    let totalTime = 0;
    let respTime = 0;
    section.forEach(s => {
      finished += s.respondidas === s.total ? 1 : 0;
      totalTime += s.tempo;
      respTime += s.respTempo;
    });

    return {
      percent: Math.round((finished / section.length) * 100),
      restTasks: section.length - finished,
      restTime: totalTime - respTime,
    };
  };

  const formatText = values => {
    let rest = values.restTasks !== 1 ? 'Restam' : 'Resta';
    let task = values.restTasks !== 1 ? 'tarefas' : 'tarefa';
    let mnt = values.restTime !== 1 ? 'minutos' : 'minuto';

    return `${rest} ${values.restTasks} ${task} (${values.restTime} ${mnt})`;
  };

  // --------------------------------------- RENDER ------------------------------------------
  return (
    <S.Wrapper>
      <SideBar tab={tab} setTab={setTab} tabs={tabs} loading={loading} />
      <S.Content>
        {!!tabs.length && (
          <S.Header>
            <div className="top">
              <h1 className="title">
                <S.Loading isLoading={loading} />
                {tabs.find(t => t.value === tab)?.label}
              </h1>
            </div>
            <div className="bottom">
              <h3 className="description">
                <S.Loading isLoading={loading} />
                Aprenda a utilizar o sistema de forma rápida e prática.
              </h3>
            </div>
          </S.Header>
        )}

        {!!tabs.length &&
          data[tab]?.map((item, key) => {
            const values = sectionValues(item.id);
            return (
              <S.Box key={key}>
                <S.Loading isLoading={loading} />
                <S.BoxHeader percent={values.percent}>
                  <div className="texts">
                    <h2 className="title">{item?.titulo}</h2>
                    <p className="description">{item.descricao}</p>
                  </div>

                  <div className="progress">
                    <div className="bar">
                      <div className="filler" />
                    </div>
                    <div className="percentage">
                      {values.percent}% concluído
                    </div>
                  </div>

                  <div className="timer">
                    <AccessTimeRoundedIcon htmlColor={colors.grey} />
                    <div className="rest">{formatText(values)}</div>
                  </div>
                </S.BoxHeader>
                <S.BoxContent in={collapse[key]}>
                  {(tab !== 'primeiros_passos'
                    ? item?.guia
                    : item?.guia?.[0]?.etapas
                  )?.map((guia, index) => {
                    const percentage =
                      tab !== 'primeiros_passos'
                        ? guidePercentage(guia.id)
                        : guia.respondida
                        ? 100
                        : 0;
                    return (
                      <S.Module
                        key={index}
                        onMouseEnter={() =>
                          setTimeout(() => setHoverGuide(index), 250)
                        }
                        onMouseLeave={() =>
                          setTimeout(() => setHoverGuide(false), 250)
                        }
                        onClick={() =>
                          setCurrentGuide({
                            guide:
                              tab === 'primeiros_passos'
                                ? item?.guia?.[0]
                                : guia,
                            first: tab === 'primeiros_passos',
                            step: index,
                          })
                        }
                      >
                        <div className="status">
                          <Progress
                            value={hoverGuide === index ? 100 : percentage}
                            scale={1.2}
                            thickness={2.5}
                          >
                            {hoverGuide !== index && (
                              <>
                                {percentage === 100 && (
                                  <CheckRoundedIcon
                                    htmlColor={colors.greenSucces}
                                  />
                                )}

                                {percentage !== 100 && (
                                  <div className="value">{percentage}%</div>
                                )}
                              </>
                            )}
                            {hoverGuide === index && (
                              <PlayArrowRoundedIcon
                                htmlColor={colors.greenSucces}
                                style={{
                                  transform: 'rotateY(180deg)',
                                }}
                              />
                            )}
                          </Progress>
                        </div>
                        <div className="infos">
                          <div className="texts">
                            <h2 className="title">{guia?.titulo}</h2>
                            <p className="description">{guia?.descricao}</p>
                          </div>
                          <div className="timer">
                            <AccessTimeRoundedIcon htmlColor={colors.grey} />
                            <div className="time">
                              {guia.tempo}{' '}
                              {guia.tempo > 1 ? 'minutos' : 'minuto'}
                            </div>
                          </div>
                        </div>
                      </S.Module>
                    );
                  })}
                </S.BoxContent>
                {tab !== 'primeiros_passos' && (
                  <S.HandleLine onClick={() => handleCollapse(key)}>
                    <ExpandMoreRoundedIcon
                      htmlColor={colors.greyTitle}
                      style={{
                        transform: collapse[key]
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                        transition: 'all ease-in-out 0.5s',
                      }}
                    />
                    <span className="text">
                      {collapse[key] ? 'Mostrar menos' : 'Mostrar mais'}
                    </span>
                  </S.HandleLine>
                )}
              </S.Box>
            );
          })}

        {!loading && tabs.length === 0 && (
          <EmptyCard
            image="frota.png"
            title="Estamos adicionando novos guias na plataforma"
            subtitle="Aguarde! Em breve você terá acesso a novos conteúdos para te ajudar a evoluir."
          />
        )}
      </S.Content>

      {currentGuide.guide && !currentGuide.first && (
        <View
          open={!!currentGuide?.guide}
          handleClose={() => setCurrentGuide({ guide: null, first: false })}
          callGuide
          execute={execute}
          evaluate={sendEvaluation}
          guide={currentGuide?.guide}
        />
      )}

      {currentGuide.guide && currentGuide.first && (
        <InitialGuide
          open={!!currentGuide?.guide}
          handleClose={() => {
            setCurrentGuide({ guide: null, first: false, step: null });
            refetch();
          }}
          data={dataGuide}
          info={currentGuide?.guide}
          initialStep={currentGuide?.step}
          callGuide
        />
      )}
    </S.Wrapper>
  );
};

export default GuiaPage;
