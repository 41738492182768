import api from 'services/api';

export const requestEstatisticas = async (isProvider, query) => {
  const res = await api.get(
    `/telematics_dashboard/${isProvider ? 'embarcador' : 'transportador'}`,
    {
      params: query,
    },
  );
  return res;
};

export const requestExcel = async (isProvider, query) => {
  const res = await api.get(
    `/${isProvider ? 'excel-provider' : 'excel'}/telematics_dashboard`,
    {
      params: query,
    },
  );
  return res;
};
