import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

// Components
import TextInput from 'components/Inputs/TextField';
import { ReactComponent as arrow_down } from 'images/icons/arrows/down.svg';

// Material UI
import { Grid, Menu, MenuItem, SvgIcon } from '@mui/material';

// Servies
import { formatNewHour, formatNewHourClean } from 'utils/dates';
import * as services from '../services';
import * as S from './styled';

// TODO Incluir foto do motorista

export const InformacoesGerais = ({
  evaluation,
  setChanged,
  setData,
  disabled,
}) => {
  const theme = useTheme();
  // FORM STATES
  const [placaCavalo, setPlacaCavalo] = useState('');
  const [placaReboque, setPlacaReboque] = useState('');
  const [local, setLocal] = useState('');
  const [transportadora, setTransportadora] = useState('');
  const [filial, setFilial] = useState('');
  const [inicial, setInicial] = useState('');
  const [final, setFinal] = useState('');
  const [disponilizado, setDisponibilizado] = useState('');
  const [distributor, setDistributor] = useState(null);

  // Distributors States
  const [distributors, setDistributors] = useState(null);
  const [anchorDistributor, setAnchorDistributor] = useState(null);

  // Formatação do objeto que sera enviado parm como Body
  // na request de update
  useEffect(() => {
    setData({
      placa_reboque: placaReboque,
      local,
      id_da_distribuidora: distributor?.id,
    });
  }, [placaReboque, local, distributor]);

  useEffect(() => {
    if (evaluation) {
      setPlacaCavalo(
        evaluation.caminhao?.placa ? evaluation.caminhao?.placa : '',
      );
      setPlacaReboque(evaluation.placa_reboque ? evaluation.placa_reboque : '');
      setLocal(evaluation.local ? evaluation.local : '');
      setTransportadora(evaluation.filial.empresa.nome);
      setFilial(evaluation.filial.nome);
      setInicial(formatNewHourClean(evaluation.hora_inicial));
      setFinal(formatNewHourClean(evaluation.hora_final));
      setDisponibilizado(
        evaluation.data_pendente
          ? formatNewHourClean(evaluation.data_pendente)
          : formatNewHour(evaluation.createdAt),
      );
      setDistributor(evaluation.distribuidora);
    }
  }, [evaluation]);
  useEffect(() => {
    const getDistributors = async () => {
      const res = await services.selectDistributors();
      setDistributors(res.data);
    };
    getDistributors();
  }, []);

  const changeMonitoring = (value, func) => {
    if (value.length >= 1024) return;
    func(value);
    setChanged(true);
  };

  return (
    <>
      <S.Box>
        <h2>Informações do Motorista</h2>
        <div className="userArea">
          <div className="imageArea">
            {evaluation.motorista.foto && (
              <img src={evaluation.motorista.foto} />
            )}
            {!evaluation.motorista.foto && (
              <span className="letters">{evaluation.motorista.nome[0]}</span>
            )}
          </div>
          <div className="textArea">
            <span className="userName">
              {evaluation.motorista.nome.toLowerCase()}
            </span>
            <span className="filialName">Filial: {evaluation.filial.nome}</span>
          </div>
        </div>
        <Grid
          className="formArea"
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          marginTop={1}
        >
          <Grid item xs={6} md={6}>
            <TextInput
              disabled
              label="Placa Cavalo"
              value={placaCavalo}
              onChange={() => setPlacaCavalo(placaCavalo)}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <TextInput
              label="Placa Reboque"
              value={placaReboque}
              onChange={e => changeMonitoring(e.target.value, setPlacaReboque)}
              disabled={disabled}
              variant={disabled ? 'filled' : 'outlined'}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextInput
              label="Local/Trecho"
              placeholder="Insira sua descrição"
              value={local}
              onChange={e => changeMonitoring(e.target.value, setLocal)}
              InputProps={{ multiline: true }}
              multiline
              rows={6}
              disabled={disabled}
              variant={disabled ? 'filled' : 'outlined'}
            />
            <div className="counter">{`${local ? local.length : 0}/1023`}</div>
          </Grid>
        </Grid>
      </S.Box>
      <S.Box>
        <h2>Informações da Viagem</h2>
        <div className="userArea">
          <div className="imageArea">
            {distributor?.logo_url && <img src={distributor?.logo_url} />}
            {!distributor?.logo_url && (
              <span className="letters">{distributor?.nome[0]}</span>
            )}
          </div>
          <div className="textArea">
            <span className="filialName">Cliente</span>
            <span className="userName">{distributor?.nome}</span>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'flex-end', height: '55px' }}
          >
            {!disabled && (
              <SvgIcon
                onClick={e => setAnchorDistributor(e.currentTarget)}
                fontSize="large"
                component={arrow_down}
              />
            )}
            <Menu
              open={!!anchorDistributor}
              anchorEl={anchorDistributor}
              onClose={() => setAnchorDistributor(null)}
              onClick={() => setAnchorDistributor(null)}
            >
              <Grid container style={{ width: '300px' }}>
                {distributors &&
                  distributors.map((item, index) => (
                    <Grid item md={12} key={index}>
                      <MenuItem
                        key={item.nome}
                        selected={item.id === distributor.id}
                        onClick={() => changeMonitoring(item, setDistributor)}
                      >
                        <Grid item md={3}>
                          <S.StyledDistributorLogo>
                            <S.StyledLogo
                              backgroundImage={item.logo_url && item.logo_url}
                            />
                          </S.StyledDistributorLogo>
                        </Grid>
                        <Grid item md={9}>
                          <p
                            style={{
                              color: theme.palette.words.title.natural,
                              fontSize: '16px',
                              fontWeight: 600,
                            }}
                          >
                            {item.nome}
                          </p>
                        </Grid>
                      </MenuItem>
                    </Grid>
                  ))}
              </Grid>
            </Menu>
          </div>
        </div>
        <Grid
          className="formArea"
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          marginTop={1}
        >
          <Grid item xs={6} md={6}>
            <TextInput
              disabled
              label="Transportadora"
              value={transportadora}
              onChange={() => setTransportadora(transportadora)}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <TextInput
              disabled
              label="Filial"
              value={filial}
              onChange={() => setFilial(filial)}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextInput
              disabled
              label="Data Inicial"
              value={inicial}
              onChange={() => setInicial(inicial)}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <TextInput
              disabled
              label="Data Final"
              value={final}
              onChange={() => setFinal(final)}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextInput
              disabled
              label="Disponibilizado em:"
              value={disponilizado}
              onChange={() => setDisponibilizado(disponilizado)}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        </Grid>
      </S.Box>
    </>
  );
};
