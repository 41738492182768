import styled from 'styled-components';

const Paper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.system.backdrop};
  border-radius: 10px;
  padding: 30px;
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    border-radius: 10px;
  }
`;

export { Paper, ImageContainer };
