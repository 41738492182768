import api from 'services/api';

export const requestDesvios = async (id, query) => {
  const res = await api.get(`/empresas/${id}/pdf/desvios`, { params: query });
  return res;
};

export const requestAcoesSuspensoes = async (id, query) => {
  const res = await api.get(`/empresas/${id}/pdf/acoes-suspensoes`, {
    params: query,
  });
  return res;
};

export const requestFrotas = async (id, query) => {
  const res = await api.get(`/empresas/${id}/pdf/frota`, {
    params: query,
  });
  return res;
};
