import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
  height: 100%;
  background: ${({ theme, selected }) =>
    selected ? theme.palette.system.border : theme.palette.system.white};
  padding: 1rem;
  border: 1px solid
    ${({ theme, selected }) =>
      selected
        ? theme.palette.brand.primary.light
        : `${theme.palette.brand.primary.light}4d`};
  border-radius: 4px;
  cursor: pointer;
`;

export const Radio = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.palette.words.title.natural};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &::after {
    content: '';
    width: 0.7rem;
    height: 0.7rem;
    background-color: ${({ selected, theme }) =>
      selected ? theme.palette.words.title.natural : 'transparent'};
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
`;

export const Title = styled.div`
  font: normal normal 900 20px/24px Texta;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.palette.words.subtitle.natural};
  opacity: 1;
`;

export const Description = styled.div`
  font: normal normal medium 16px/20px Texta;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.palette.words.subtitle.natural};
  opacity: 1;
`;

export const Image = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
`;
