import styled, { css } from 'styled-components';

export const LeftHeaderContainer = styled.div`
  display: flex;
  width: 40vw;
  .title {
    display: flex;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
  }
  .tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
  }
  .tag {
    display: flex;
    justify-content: center;
  }
`;

export const RightHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ProgessContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 60px;
    label {
      font-size: 13px;
      font-weight: 900;
      margin-left: 4px;
      color: ${theme.palette.words.subtitle.natural};
    }
    .date {
      label {
        color: ${theme.palette.semantics.feedback.success.natural};
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-left: 40px;
    }
  `}
`;

export const Progess = styled.div`
  ${({ theme }) => css`
    display: flex;
    border: solid 1px ${theme.palette.brand.primary.light};
    border-radius: 100px;
    width: 207px;
    height: 10px;
    margin: 10px 10px 10px 10px;
    .bar {
      transform: translateY(-1px);
      background-color: ${theme.palette.semantics.feedback.success.natural};
      border-radius: 100px;
      height: 10px;
      // width:100%;
    }
  `}
`;

export const containerBanner = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    position: relative;
    height: 280px;
    background: ${theme.palette.system.black};
  `}
`;

export const containerTitle = styled.div`
  position: absolute;
  top: 30%;
  left: 0%;
  flex-direction: column;
  color: white;
  text-align: left;
  padding: 50px;
`;

export const Tag = styled.div`
  ${({ theme, tabSelected }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: ${theme.palette.semantics.feedback.unknown.light};
    padding-right: 10px;
    padding-left: 10px;
    height: 22px;
    margin-right: 5px;
    font-size: 14px;
    color: ${theme.palette.words.title.natural};
    font-weight: bold;
    border: ${tabSelected
      ? `1px solid ${theme.palette.brand.primary.light};`
      : `none`};
    white-space: nowrap;
  `}
`;

export const MenuRight = styled.div`
  ${({ theme }) => css`
    width: 30%;
    box-shadow: 0px -6px 5px 0px rgb(0 0 0 / 20%);
    height: 100vh;
    .menu-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      width: 100%;
      height: 100px;
      box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 20%);
      h1 {
        font-size: 18px;
      }
      .continue {
        color: ${theme.palette.semantics.feedback.success.natural};
      }
      .concluido {
        color: ${theme.palette.words.text.light};
      }
    }
  `}
`;

export const ContainerQuiz = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 853px;
`;

export const Alternatives = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardComment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px;
    width: 225px;
    height: 50px;
    margin: 5px;
  }
`;
