import styled, { css } from 'styled-components';

export const MapContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

  :hover {
    width: 99%;
    opacity: 0.9;
    transition-duration: 300ms;
  }

  .map {
    height: 250px;
  }
`;

export const MapIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.4;
  cursor: pointer;

  :hover {
    opacity: 1;
    transition-duration: 300ms;
  }
`;

export const Paper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 80vw;
    background-color: ${theme.palette.system.overlay};
    border: 0px 5px 20px ${theme.palette.system.divider};
    border-radius: 8px;
    padding: 25px 25px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const MapModalContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

  .map {
    height: 70vh;
  }
`;
