import styled, { css, keyframes } from 'styled-components';
import colors from 'styles/colors';

const placeholderShimmer = keyframes`
  0% {
    background-position: -40rem 0;
  }
  100% {
    background-position: 40rem 0;
  }
`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  margin-left: -30px;
  height: 450px;
  margin-top: -35px;
  display: flex;
  transition: margin 0.25s ease;
`;

export const Background = styled.div`
  ${({ src }) => css`
    background: #f6f7f8;
    background-size: cover;
    background-position: center;
    background-image: url(${src});
    width: 100%;
    height: 50%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: end;
    align-items: top;
    filter: brightness(0.8);
    transition: all 0.3s ease-in-out;
  `}
`;

export const Content = styled.div`
  background-color: ${colors.white};
  width: 100%;
  height: 50%;
  align-self: flex-end;
  box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all 0.25s ease;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const LineHeader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -70px;
  margin-left: -30px;

  .imgArea {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-color: ${colors.greyBorder};
    border: 2px solid ${colors.greenMain};
    display: flex;
    justify-content: center;
    align-items: center;

    .letter {
      font-size: 36px;
      color: ${colors.greyTitle};
      font-weight: 900;
    }

    img {
      width: 90px;
      height: 90px;
      border-radius: 100px;
      object-fit: cover;
    }
  }

  .infoArea {
    margin-top: 10px;

    .name {
      font-size: 26px;
      font-weight: 900;
      color: ${colors.greyTitle};
    }

    .filial {
      font-size: 18px;
      font-weight: 500;
      color: ${colors.greySubtitle};
      text-align: center;
    }
  }
`;

export const AbsBox = styled.div`
  position: absolute;
  margin-left: -30px;
  width: 100%;
  height: 35px;
  display: flex;
  transition: margin 0.25s ease;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 380px;
  z-index: 3;
  background-color: ${colors.white};
`;

export const AbsBoxFixed = styled.div`
  position: fixed;
  margin-left: -30px;
  width: calc(100% - 7px);
  height: 36px;
  display: flex;
  transition: margin 0.15s ease;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  margin-top: ${props => (props.small ? '70px' : '0px')};
  opacity: ${props => (props.small ? '1' : '0')};
  padding-top: 2px;
  z-index: 3;
  background-color: ${colors.white};
  box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.1);
`;

export const LineTabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  max-width: 800px;
  width: 100%;
  margin-bottom: 3px;
  position: relative;
  z-index: 2;

  .bar {
    width: calc(100% / 4);
    height: 6px;
    background-color: ${colors.greenMain};
    bottom: 0;
    position: absolute;
    z-index: 3;
    margin-bottom: -7px;
    left: 0;
    margin-left: calc((100% / 4 * ${props => props.tab}));
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: all 0.2s ease-in-out;
  }
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1;

  &:hover {
    opacity: 0.7;
  }

  .title {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 500;
    color: ${props => (props.active ? colors.greenMain : colors.grey)};
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  height: 600px;
  margin-top: 430px;
  transition: all 0.25s ease;
  margin-bottom: 130px;
  padding-top: 20px;
`;

export const CardsContainer = styled.div`
  width: 100%;
  height: 124px;
  background-color: ${colors.white};
  border: 1px solid ${colors.greyTiny};
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  overflow: hidden;

  .bar {
    height: 60%;
    width: 1px;
    background-color: ${colors.greyTiny};
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  p {
    font-weight: 600;
    color: ${colors.greySubtitle};
  }

  .texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3px;
    position: relative;
  }

  .txt {
    width: 230px;
    height: 22px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .val {
      transition: all 0.8s ease-in-out;
      opacity: ${props => (props.isLoading ? 0 : 1)};
    }

    .loading {
      border-radius: 5px;
      display: flex;
      width: 100%;
      height: 100%;
      position: absolute;
      justify-content: flex-start;
      align-items: center;
      opacity: ${props => (props.isLoading ? 0.4 : 0)};
      transition: all 0.9s ease;
      background-image: linear-gradient(
        to right,
        #e2e2e8 0%,
        #edeef1 20%,
        #e2e2e8 40%,
        #e2e2e8 100%
      );
      background-size: 80rem 14rem;
      animation: ${placeholderShimmer} 3s linear infinite forwards;
    }
  }

  .iconContainer {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-color: ${colors.greyBorder};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    .MuiSvgIcon-root {
      font-size: 30px;
      color: ${colors.grey};
    }
  }

  @media (max-width: 1110px) {
    .txt {
      width: 200px;
      font-size: 16px;
    }
  }

  @media (max-width: 970px) {
    .iconContainer {
      width: 40px;
      height: 40px;

      .MuiSvgIcon-root {
        font-size: 25px;
      }
    }

    .txt {
      width: 180px;
      font-size: 14px;
    }
  }

  @media (max-width: 880px) {
    .iconContainer {
      width: 30px;
      height: 30px;

      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }

    .txt {
      width: 160px;
      font-size: 13px;
    }
  }

  @media (max-width: 780px) {
    .iconContainer {
      display: none;
    }
  }
`;
