import styled from 'styled-components';
import colors from 'styles/colors';
import { Collapse } from '@mui/material';

export const Box = styled.div`
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
  width: 100%;
  background-color: #fff;
  border: 2px solid ${colors.greyBorder};
  border-radius: 4px;
  padding: 30px;
  margin-top: 20px;
`;

export const ContentLimit = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${colors.grey};
  border-radius: 5px;
`;

export const BoxConfigure = styled.div`
  min-height: 47px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid ${colors.grey};

  &.repetable {
    opacity: ${props => (props.repeat ? 1 : 0.5)};
  }

  .textArea {
    font-size: 17px;
    font-weight: 600;
    color: ${colors.greyTitle};
    display: flex;
    align-items: center;

    .tooltip {
      margin-left: 10px;
      cursor: pointer;
    }
  }
`;

export const BoxCollapse = styled.div`
  padding: 15px 20px;
  min-height: 50px;
  cursor: pointer;
  border-bottom: 1px solid ${colors.grey};

  &.lastCollapse {
    border-bottom: none;
  }

  .clickColl {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: ${props => (props.hasTest ? 1 : 0.5)};

    .leftSide {
      display: flex;
      align-items: center;
      justify-content: start;

      .textArea {
        font-size: 17px;
        font-weight: 600;
        color: ${colors.greyTitle};
      }
    }

    .rigthSide {
      display: flex;
      align-items: center;

      .textArea {
        margin-right: 13px;
        font-weight: 500;
        text-transform: capitalize;
      }

      .number {
        text-transform: lowercase;
      }
    }
  }
`;

export const CollapseMu = styled(Collapse)`
  margin-top: 15px;

  .totalContentScroll {
    overflow-y: scroll;
    max-height: 400px;
  }

  .boxTime {
    display: flex;
    width: 100%;
    align-items: end;
    justify-content: start;
    position: relative;

    .current {
      margin-bottom: 20px;
      margin-left: -5px;
    }

    .sideBox {
      margin-left: 35px;
      height: 42px;
    }
  }
`;

export const CollapseItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: ${props =>
    props.checked ? colors.greyBackgroud : '#0000'};
  border-radius: 4px;
  transition: all ease 0.3s;

  .infoCheck {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: start;

    .boxUser {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-left: 25px;

      .nameElement {
        margin-left: 20px;
        font-size: 17px;
        font-weight: 600;
        color: ${colors.greyTitle};
        text-transform: capitalize;
      }
    }

    .imageArea {
      width: 38px;
      height: 38px;
      border-radius: 38px;
      border: 2px solid ${colors.greenMain};
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        width: 100%;
        height: 100%;
        background-color: ${colors.greyBackgroud};
        border-radius: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: 700;
        color: ${colors.greyTitle};
      }
    }
  }

  .locale {
    flex: 1;
    font-weight: 600;
    color: ${colors.greyTitle};
  }

  .workArea {
    flex: 1;
    font-weight: 600;
    color: ${colors.greyTitle};
  }
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
