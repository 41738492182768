// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// Components
import Calendar from 'components/Inputs/Calendar';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import SelectInput from 'components/Inputs/Select';
import DefaultButton from 'components/Buttons/Default';
import Radio from 'pages/Fomularios/Fields/Components/Radio';
import Checkbox from 'pages/Fomularios/Fields/Components/Checkbox';

// Components MUI
import { Divider } from '@mui/material';
import { TextField } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import * as S from './styled';

// Utils
import { period, week } from './constant';

const FrequencyModal = ({
  // FormStates
  Ofrequencia,
  OsetFrequencia,
  Operiodo,
  OsetPeriodo,
  Odias_da_semana,
  OsetDiasDaSemana,
  Onunca_repetir,
  OsetNuncaRepetir,
  Odata_final,
  OsetDataFinal,
  Ofinal,
  OsetFinal,
  data_inicio,

  // Page States
  setChanged,
  open,
  onClose,
  multiple,
  setMultiple,
}) => {
  const theme = useTheme();
  // Form States
  const [frequencia, setFrequencia] = useState('');
  const [periodo, setPeriodo] = useState('');
  const [dias_da_semana, setDiasDaSemana] = useState('');
  const [final, setFinal] = useState(false);
  const [nunca_repetir, setNuncaRepetir] = useState(false);
  const [dataFinal, setDataFinal] = useState('');
  const [modalMultiple, setModalMultiple] = useState(false);

  // Set Initial values
  useEffect(() => {
    setFrequencia(Ofrequencia);
    setPeriodo(Operiodo);
    setDiasDaSemana(Odias_da_semana);
    setFinal(Ofinal);
    setDataFinal(Odata_final);
    setNuncaRepetir(Onunca_repetir);
  }, [open]);

  const [modalChange, setModalChange] = useState(false);

  const changingMonitoring = (func, value) => {
    func(value);
    setModalChange(true);
  };

  // --------------------------------------SAVE INFOS------------------------------------------//
  const saveModifiers = approved => {
    if (!nunca_repetir) {
      if (!periodo || !+frequencia) {
        toast.error('Selecione o período de repetição');
        return;
      }

      if (periodo === 'SEMANA' && !dias_da_semana) {
        toast.error('Selecione um dia da semana para repetição');
        return;
      }

      if (final && !dataFinal) {
        toast.error('Selecione a data final do formulário');
        return;
      }

      if (multiple && !approved) {
        setModalMultiple(true);
        return;
      }
    }

    OsetFrequencia(+frequencia);
    OsetPeriodo(periodo);
    OsetDiasDaSemana(dias_da_semana);
    OsetNuncaRepetir(nunca_repetir);
    OsetDataFinal(dataFinal);
    OsetFinal(final);

    if (!nunca_repetir) {
      setMultiple(false);
    }

    setChanged(modalChange);

    setModalMultiple(false);
    onClose();
  };

  const handleClose = () => {
    setModalChange(false);
    onClose();
  };

  return (
    <>
      <S.ModalMui open={open} onClose={handleClose} disableEscapeKeyDown>
        <S.Container>
          <S.Header>
            <span>
              <div>
                <CalendarMonthOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
                <h2>Definir Recorrência</h2>
              </div>
              <CloseOutlinedIcon
                htmlColor={theme.palette.brand.primary.light}
                onClick={handleClose}
              />
            </span>
            <Divider />
          </S.Header>
          <S.Body noEvents={nunca_repetir}>
            <S.FrequencyBox>
              <S.MiniTitle>Repetir a cada</S.MiniTitle>
              <S.Grid>
                <TextField
                  id="outlined-number"
                  type="number"
                  onChange={e =>
                    changingMonitoring(setFrequencia, e.target.value)
                  }
                  value={frequencia}
                  sx={{
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                    '& input[type=number]::-webkit-outer-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                  }}
                />
                <SelectInput
                  data={period}
                  value={periodo}
                  handleChange={e =>
                    changingMonitoring(setPeriodo, e.target.value)
                  }
                />
              </S.Grid>
            </S.FrequencyBox>

            <S.DaysBox
              onClick={
                periodo == 'SEMANA'
                  ? undefined
                  : () =>
                      toast.error(
                        'Dias podem ser selecionados somente em repetições semanais',
                      )
              }
            >
              <S.MiniTitle>Repetir na</S.MiniTitle>
              <S.WeekBox noEvents={periodo !== 'SEMANA'}>
                {week.map((item, key) => (
                  <div
                    className={
                      dias_da_semana === item.tooltip
                        ? 'dayBall active'
                        : 'dayBall'
                    }
                    key={key}
                    onClick={() =>
                      changingMonitoring(setDiasDaSemana, item.tooltip)
                    }
                  >
                    {item.day}
                  </div>
                ))}
              </S.WeekBox>
            </S.DaysBox>

            <S.TimeBox>
              <S.MiniTitle>Termina em</S.MiniTitle>
              <S.FinishedBox>
                <div className="line">
                  <Radio
                    value={!final}
                    onChange={() => changingMonitoring(setFinal, false)}
                  />
                  <span>Nunca</span>
                </div>
                <div className="line">
                  <Radio
                    value={final}
                    onChange={() => changingMonitoring(setFinal, true)}
                  />
                  <span>Até</span>
                  <Calendar
                    minDate={new Date(data_inicio)}
                    value={dataFinal}
                    onChange={e => changingMonitoring(setDataFinal, e)}
                    futureDate
                    pastDate={false}
                    noAsterisk
                    disabled={!!Odata_final}
                  />
                </div>
              </S.FinishedBox>
            </S.TimeBox>
          </S.Body>
          <S.Footer>
            <div>
              <Checkbox
                label="Não repetir"
                value={nunca_repetir}
                onChange={() =>
                  changingMonitoring(setNuncaRepetir, !nunca_repetir)
                }
              />
            </div>

            <div>
              <GhostButton children="Cancelar" onClick={handleClose} />
              <DefaultButton
                onClick={() => saveModifiers()}
                children="Salvar"
                style={{ marginLeft: '10px' }}
              />
            </div>
          </S.Footer>
        </S.Container>
      </S.ModalMui>
      <ConfirmModal
        open={modalMultiple}
        titleIcon={
          <WarningAmberOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.attention.natural}
          />
        }
        title="Tem certeza que deseja definir uma repetição?"
        subtitle="As multiplas respostas serão desabilitadas e o usuário poderá responder o formulário uma única vez a cada repetição."
        handleClose={() => setModalMultiple(false)}
        onClick={() => saveModifiers(true)}
        buttonText="Confirmar"
      />
    </>
  );
};

export default FrequencyModal;
