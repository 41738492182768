import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    border: 1px solid ${theme.palette.primary.light};
    border-radius: 4px;
    padding: 15px;
  `}
`;

export const Tag = styled.div`
  ${({ theme }) => css`
    background: #283240;
    height: 25px;
    border-radius: 30px;
    display: flex;
    padding: 5px 15px;
    margin: 2px;
    justify-content: center;
    align-items: center;
    color: #ffffff;

    .name {
      color: white;
      font-size: 14px;
      font-weight: 600;
      align-self: center;
    }

    .ico {
      cursor: pointer;
      font-size: 20px;
      color: white;
    }
  `}
`;

export const Input = styled.div`
  ${({ theme }) => css`
    margin: 3px;
    display: flex;
    min-width: 50%;

    .form {
      display: flex;
      width: 100%;
    }
  `}
`;
