import React, { useState, useEffect, useRef } from 'react';
import TextInput from 'components/Inputs/TextField';
import { useSearchParams } from 'react-router-dom';
// icons
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SlideshowIcon from '@mui/icons-material/Slideshow';

import { Grid } from '@mui/material';
import Loading from 'components/Loading';
import Header from '../components';

// styles
import * as S from './styled';
import { getInfo } from '../services';

const Info = props => {
  const [searchParams] = useSearchParams();
  const [info, setInfo] = useState([]);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const id = searchParams.get('id');
    const tipo = searchParams.get('tipo');
    setLoading(true);
    getInfo(id, capitalizeFirstLetter(tipo)).then(res => {
      setInfo(res.data);
      setCards(res.cards);
      setLoading(false);
    });
  }, []);

  const capitalizeFirstLetter = str => {
    return str[0].toUpperCase() + str.slice(1);
  };
  if (loading) {
    return (
      <div style={{ position: 'absolute', left: '55%' }}>
        <Loading />
      </div>
    );
  }
  return (
    <>
      {' '}
      <h1>Informações Gerais</h1>
      <S.Container>
        <Header nome={info?.nome} filial={info?.filial} foto={info?.foto} />
        <div style={{ marginBottom: 40 }}>
          <div className="divider" />
          <S.containerCard>
            {cards.map((item, k) => {
              return (
                <div key={k} className="container-card">
                  <div className="icon-container">
                    {k === 0 && <WorkspacePremiumIcon />}
                    {k === 1 && <AccessTimeIcon />}
                    {k === 2 && <SlideshowIcon />}
                  </div>
                  <div>
                    <div>
                      <h1>{item.value} </h1>
                      <h3>{item.text}</h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </S.containerCard>
          <div className="divider" />
        </div>
        <Grid item xs={12} md={12}>
          <TextInput label="Nome Completo" value={info?.nome || ''} />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextInput label="Função" value={info?.funcao || ''} />
        </Grid>
        {info?.cpf && (
          <Grid item xs={12} md={12}>
            <TextInput label="CPF" value={info?.cpf || ''} />
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <TextInput label="Telefone" value={info?.contato || ''} />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextInput label="Tipo" value={info?.tipo || ''} />
        </Grid>
      </S.Container>
    </>
  );
};

export default Info;
