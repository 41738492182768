import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: process.env.REACT_APP_REDUX_KEY,
      storage,
      whitelist: [
        'auth',
        'selects',
        'filter',
        'filterFrota',
        'filterDriver',
        'filterPad',
        'filterRankingDriver',
        'pageMap',
        'filterDesvio',
        'filterDesvios',
        'filterDesvioDescarga',
        'filterDesviosConfiguracoes',
        'filterViolacao',
        'filterAvaliacoes',
        'filterAvaliacoesDescarga',
        'filterLayout',
        'myTasks',
        'version',
        'createContents',
        'filterMinhasTarefas',
        'filterRotograma',
        'filterFormulario',
        'filterFormularioMeus',
        'filterMotoristaCronograma',
        'filterConteudo',
        'filterVagas',
        'tables',
        'filterAcoesSuspensoes',
        'filterTelemetriaDesvios',
        'filterTelemetriaRanking',
        'guiaQueue',

        // Acesso Provider
        'filterProvider',
        'filterDesvioProvider',
        'filterDesvioDescargaProvider',
        'filterRevisaoOperacao',
        'filterExclusaoDesvio',
        'filterLayoutProvider',
        'filterEstatisticasDesviosProvider',
        'filterCapacitacaoProvider',
        'filterTelemetriaDesviosProvider',
        'filterTelemetriaRankingProvider',
        'filterDescargaRankingProvider',
        'filterDirecaoRankingProvider',
        'filterFrotaProvider',
        'filterDriverProvider',
        'filterEmpresaProvider',
        'filterRankingDriverProvider',
        'filterAcoesSuspensoesProvider',
        // 'companies',
      ],
    },
    reducers,
  );

  return persistedReducer;
};
