import styled from 'styled-components';
import colors from 'styles/colors';

export const ScrollArea = styled.div`
  position: absolute;
  margin-left: -180px;
  padding-bottom: 80px;
  margin-top: 20px;
  width: 100%;
`;

export const Box = styled.div`
  background-color: #fff;
  border: 2px solid ${colors.greyTiny};
  border-radius: 4px;
  padding: 30px 250px;
`;

export const Header = styled.div`
  height: 300px;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  .gradient {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0px 120px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.95) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );

    .title {
      font-size: 35px;
      color: #fff;
      font-weight: 800;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .description {
      font-size: 18px;
      color: #fff;
      font-weight: 500;
      margin-top: 15px;
    }
  }
`;

export const EvaluationBox = styled.div`
  border: 2px solid ${colors.greyTiny};
  border-radius: 5px;

  .headerBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    width: 100%;
    border-bottom: 1px solid ${colors.grey};

    .typeEval {
      font-size: 25px;
      color: ${colors.greyTitle};
      font-weight: 600;
      margin-left: 10px;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;

export const BoxButton = styled.div`
  margin-top: 20px;
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
