import * as React from 'react';
import { subDays, subMonths } from 'date-fns';
import { Box, FormControl } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore'; // Ícone de seta para baixo
import { styled } from '@mui/material/styles';
import { Item, SelectCustom } from './styled';

const CustomArrowDropDownIcon = styled(ExpandMore)(({ theme, open }) => ({
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.short,
  }),
}));

export const CustomizedSelect = ({
  selectedDate,
  selectedPeriod,
  handleChangeDate,
  handleChangePeriod,
  today,
  width = 200,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState(selectedPeriod ?? '30DIAS');

  const ultimos30Dias = subDays(today, 30);
  const ultimos3Meses = subMonths(today, 3);
  const ultimos6Meses = subMonths(today, 6);
  const ultimos12Meses = subMonths(today, 12);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = event => {
    setValue(event.target.value);

    if (handleChangePeriod instanceof Function)
      handleChangePeriod(event.target.value);

    const dateMap = {
      '30DIAS': ultimos30Dias,
      '3MESES': ultimos3Meses,
      '6MESES': ultimos6Meses,
      '12MESES': ultimos12Meses,
    };

    const selectedDate = dateMap[event.target.value];
    if (selectedDate) {
      handleChangeDate(selectedDate);
    }
  };

  return (
    <Box sx={{ minWidth: width }}>
      <FormControl fullWidth>
        <SelectCustom
          value={selectedPeriod ?? value}
          displayEmpty
          onChange={handleChange}
          onOpen={handleOpen}
          onClose={handleClose}
          IconComponent={props => (
            <CustomArrowDropDownIcon {...props} open={isOpen} />
          )}
        >
          <Item value="30DIAS">Últimos 30 dias</Item>
          <Item value="3MESES">Últimos 3 meses</Item>
          <Item value="6MESES">Últimos 6 meses</Item>
          <Item value="12MESES">Últimos 12 meses</Item>
        </SelectCustom>
      </FormControl>
    </Box>
  );
};
