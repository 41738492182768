import styled from 'styled-components';
import colors from 'styles/colors';

export const Card = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px 40px 15px;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid
    ${props => (props.isError ? colors.redDanger : colors.greyTiny)};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

  h3 {
    color: ${colors.greySubtitle};
    text-align: left;
    font: normal medium 16px Texta;
    text-transform: capitalize;
  }
`;
