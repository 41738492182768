import styled, { css } from 'styled-components';
import { Modal } from '@mui/material';

export const ModalMui = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 60vw;
    height: 90vh;
    background-color: ${theme.palette.system.overlay};
    border-radius: 5px;
    padding: 30px 20px;
    overflow: auto;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 10px;

      .title {
        font-size: 30px;
        font-weight: 700;
      }

      .subtitle {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .text {
        margin-top: 10px;
        font-size: 16px;
      }

      .topic {
        font-size: 20px;
        font-weight: 700;
        margin-top: 10px;
      }
    }
  `}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  div {
    label {
      display: flex;
      justify-items: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      input {
        margin-right: 5px;
      }
    }
  }
`;
