import api from 'services/api';

export const updateWhitelabel = async (idDistribuidora, data) => {
  const res = await api.put(
    `/distribuidora/${idDistribuidora}/whitelabel`,
    data,
  );
  return res.data;
};

export const updateWhitelabelLogo = async (idDistribuidora, data) => {
  const res = await api.put(
    `/distribuidora/${idDistribuidora}/logo_whitelabel`,
    data,
  );
  return res.data;
};

export const requestProvider = async id => {
  const { data } = await api.get(`/distribuidora/${id}`);
  return data.data;
};
