import React from 'react';
import {
  Tooltip,
  Legend,
  ResponsiveContainer,
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Cell,
  Text,
} from 'recharts';
import CheckFiltersCard from 'components/Cards/CheckFiltersCard';
import { useTheme } from 'styled-components';
import Loading from 'components/Loading';
import * as S from './styled';

const RadarGraph = ({
  data = [],
  color,
  title = 'Maiores fatores de risco',
  loading,
  tooltip,
  tabComponent,
  print = false,
}) => {
  const theme = useTheme();

  const WrappedAxisTick = props => {
    const { x, y, payload, textAnchor } = props;

    let label = payload.value;

    if (label) {
      return (
        <Text width={100} x={x} y={y} textAnchor={textAnchor}>
          {payload.value}
        </Text>
      );
    }
    return null;
  };

  return (
    <S.StyledCard print={print}>
      <S.Header>
        <span>{title}</span>
        {tabComponent}
      </S.Header>
      {data?.length > 0 && (
        <S.Legend>
          <div className="container-title">
            <S.CicleLegend color="#F64E60" />
            <span>Período atual</span>
          </div>
          <div className="container-title" style={{ marginLeft: 10 }}>
            <S.CicleLegend color="#4B51661A" />
            <span>Período anterior</span>
          </div>
        </S.Legend>
      )}
      {data?.length > 0 ? (
        <>
          {loading ? (
            <S.BoxLoading>
              <Loading />
            </S.BoxLoading>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart
                data={data}
                margin={{
                  top: 10,
                  right: 10,
                  left: 10,
                  bottom: 50,
                }}
              >
                {tooltip && <Tooltip />}
                <PolarGrid />
                <PolarAngleAxis dataKey="name" tick={WrappedAxisTick} />
                <PolarRadiusAxis angle={90} domain={[0, 1]} opacity={0} />
                <Radar
                  dataKey="previousValue"
                  stroke="#242424"
                  fill="#d1d1d1"
                  fillOpacity={1}
                />
                <Radar
                  dataKey="value"
                  stroke="#F64E60"
                  fill="#F64E60"
                  fillOpacity={0.2}
                />
              </RadarChart>
            </ResponsiveContainer>
          )}
        </>
      ) : (
        <CheckFiltersCard />
      )}
    </S.StyledCard>
  );
};

export default RadarGraph;
