// React
import React, { useState, useEffect } from 'react';

// Components
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import SelectInput from 'components/Inputs/Select';
import SelectList from 'components/Inputs/SelectList';

import { Popover } from '@mui/material';

import { formatNewDate } from 'utils/dates';
import * as S from './styled';

// Utils
import { items } from '../../items';

const Filters = ({
  id,
  open,
  onClose,
  anchorEl,
  setFieldsFilter,
  historySelected,
  setHistorySelected,
  historyForm,
  setPassByFilter,
  setTotalFilter,
  fieldsFilter = [],
  isHistory,
}) => {
  const [idsFieldsFilterAuto, setIdsFieldsFilterAuto] = useState('');
  const [idsFieldsFilterGen, setIdsFieldsFilterGen] = useState('');
  const [filterPeriod, setFilterPeriod] = useState(historySelected);

  // opcoes filters
  const [itemsAutoFilters, setItemsAutoFilters] = useState([]);
  const [itemsGeneralFilters, setItemsGeneralFilters] = useState([]);

  useEffect(() => {
    let isAddAuto = [];
    const itemsAutoFilters = items[1].types.map(item => {
      if (fieldsFilter.includes(item.id_tipo_campo.toString())) {
        isAddAuto.push(item.id_tipo_campo);
      }
      return {
        id: item.id_tipo_campo,
        value: item.nome,
      };
    });

    setItemsAutoFilters(itemsAutoFilters);
    setIdsFieldsFilterAuto(isAddAuto.join(','));

    let isAddGen = [];
    const itemsGeneralFilters = items[0].types.map(item => {
      if (fieldsFilter.includes(item.id_tipo_campo.toString())) {
        isAddGen.push(item.id_tipo_campo);
      }
      return {
        id: item.id_tipo_campo,
        value: item.nome,
      };
    });

    setItemsGeneralFilters(itemsGeneralFilters);
    setIdsFieldsFilterGen(isAddGen.join(','));
  }, []);

  const handleSave = () => {
    if (filterPeriod !== historySelected) {
      setHistorySelected(filterPeriod);
      setPassByFilter(true);
    }

    let totalFilter = isHistory ? 1 : 0;

    if (idsFieldsFilterAuto) {
      totalFilter++;
    }
    if (idsFieldsFilterGen) {
      totalFilter++;
    }

    let idsAutoArr = idsFieldsFilterAuto ? idsFieldsFilterAuto?.split(',') : [];
    let idsGenArr = idsFieldsFilterGen ? idsFieldsFilterGen?.split(',') : [];
    setFieldsFilter([...idsAutoArr, ...idsGenArr]);
    setTotalFilter(totalFilter);
    onClose();
  };

  const handleClear = () => {
    setIdsFieldsFilterAuto('');
    setIdsFieldsFilterGen('');
    setFieldsFilter([]);
    setTotalFilter(isHistory ? 1 : 0);
    onClose();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <S.Main>
        <S.Header>
          <h2>Filtrar por</h2>
          <S.ClearButton onClick={handleClear}>Limpar filtros</S.ClearButton>
        </S.Header>

        <S.SelectsContainer>
          {isHistory && (
            <SelectInput
              value={filterPeriod}
              handleChange={e => setFilterPeriod(e.target.value)}
              data={historyForm.map(item => {
                return {
                  value: item.id,
                  name: (
                    <S.HistoryItem>
                      <S.HistoryText>
                        <div className="text">
                          {' '}
                          {item?.concluido ? 'Concluído em:' : 'Iniciado em:'}
                        </div>{' '}
                        <span className="date">
                          {formatNewDate(
                            item?.concluido
                              ? item.data_concluido
                              : item?.createdAt,
                          )}
                        </span>
                      </S.HistoryText>
                      <S.HistoryStatus concluido={item?.concluido}>
                        {item?.concluido ? 'Concluído' : 'Não Concluído'}
                      </S.HistoryStatus>
                    </S.HistoryItem>
                  ),
                };
              })}
              label="Histórico"
            />
          )}
          <div className="filterSpace">
            <SelectList
              data={itemsGeneralFilters}
              value={idsFieldsFilterGen.toString()}
              handleChange={e => setIdsFieldsFilterGen(e)}
              label="Campos Gerais"
              selectAll
            />
          </div>

          <div className="filterSpace">
            <SelectList
              data={itemsAutoFilters}
              value={idsFieldsFilterAuto.toString()}
              handleChange={e => setIdsFieldsFilterAuto(e)}
              label="Campos Automáticos"
              selectAll
            />
          </div>
        </S.SelectsContainer>

        <S.Footer>
          <GhostButton onClick={onClose} size="medium" id="cancelar">
            CANCELAR
          </GhostButton>

          <DefaultButton onClick={handleSave} size="medium" id="aplicar">
            APLICAR
          </DefaultButton>
        </S.Footer>
      </S.Main>
    </Popover>
  );
};

export default Filters;
