/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// Components
import Loading from 'components/Loading';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { Checkbox, Collapse } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ShopOutlinedIcon from '@mui/icons-material/ShopOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined';
import SimCardAlertOutlinedIcon from '@mui/icons-material/SimCardAlertOutlined';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

// Utils
import { validator } from './validator';
import { defaultForm } from './constants';
import { firestorage } from 'utils/firebase';

export const ModalConfig = ({
  edit,
  open,
  handleClose,
  index,
  setSteps,
  setCapa,
  isCapa,
  setIsCapa,
  firstSteps,
  loading,
}) => {
  const [form, setForm] = useState(defaultForm);
  const [invalidLink, setInvalidLink] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [changed, setChanged] = useState(false);
  const [modalClose, setModalClose] = useState(false);

  useEffect(() => {
    if (open) {
      edit &&
        setForm({
          ...edit,
          mode: edit.link ? 'link' : null,
          niveis: edit.niveis.split(',').map(item => Number(item)),
        });
      !edit && setForm(defaultForm);
    }
  }, [open]);

  const handleChange = (key, value) => {
    if (key === 'tempo' && isNaN(value)) return;
    setChanged(true);
    setForm({ ...form, [key]: value });
  };

  const handleLevels = value => {
    setChanged(true);
    if (form?.niveis?.includes(value)) {
      setForm({
        ...form,
        niveis: form?.niveis?.filter(item => item !== value),
      });
    } else {
      setForm({
        ...form,
        niveis: form?.niveis ? [...form?.niveis, value] : [value],
      });
    }
  };

  const closeModal = () => {
    if (changed) {
      setChanged(false);
      setIsCapa && setIsCapa(false);
      setModalClose(true);
    } else {
      setChanged(false);
      setIsCapa && setIsCapa(false);
      setForm({});
      handleClose();
    }
  };

  const handleSave = async () => {
    const error = validator(form, invalidLink, firstSteps);
    if (error) {
      toast.warn(error);
      return;
    }

    const data = { ...form, niveis: form.niveis.join(',') };

    if (!data.tipo) {
      data.link = null;
      data.mode = null;
    }

    isCapa ? await setCapa(data) : await setSteps(data, index);
    setChanged(false);
    setIsCapa && setIsCapa(false);
    setForm(defaultForm);
    handleClose();
  };

  // ---------------------------UPLOAD VIDEO--------------------------- //

  const onLoadVideo = async (e, type) => {
    const file = e.target.files[0];
    e.target.value = null;
    e.target.files = null;
    await savedVideoFirebase(file, type);
  };

  const savedVideoFirebase = async (data, type) => {
    const file = data;
    const fileReference = firestorage.ref(`/guia/media`).child(file.name);
    setLoadingVideo(true);

    const uploadTask = fileReference.put(data);
    uploadTask.on(
      'state_changed',
      snapShot => {},
      err => {
        toast.error('Erro ao fazer upload da mídia');
        console.log('Err', err);
      },
      async () => {
        await fileReference.getDownloadURL().then(fireBaseUrl => {
          setChanged(true);
          type === 'media' &&
            setForm({ ...form, link: fireBaseUrl, mode: 'upload' });
          type === 'capa' && setForm({ ...form, imagem_capa: fireBaseUrl });
          setLoadingVideo(false);
        });
      },
    );
  };

  // --------------------------- RETURN --------------------------- //
  return (
    <S.ModalMUI open={open}>
      <S.Container>
        <S.Header>
          <div className="left">
            {edit && <AutoFixHighOutlinedIcon />}
            {!edit && <AddCircleOutlineRoundedIcon />}
            <p className="title">
              {edit
                ? `Editar ${isCapa ? 'Capa' : 'Passo'}`
                : `Criar ${isCapa ? 'Capa' : 'Passo'}`}
            </p>
          </div>
          <div className="right">
            <CloseRoundedIcon onClick={() => closeModal()} />
          </div>
        </S.Header>
        <S.Body>
          <S.Section>
            <p className="titulo">Tipo de mídia</p>
            {/* TYPES */}
            <div className="mediaType">
              <S.ButtonMedia
                active={form.tipo === 'VIDEO'}
                onClick={() => handleChange('tipo', 'VIDEO')}
                disabled={loadingVideo || loading}
              >
                <MovieCreationOutlinedIcon />
                <span>Vídeo</span>
              </S.ButtonMedia>

              <S.ButtonMedia
                active={form.tipo === 'IMAGEM'}
                onClick={() => handleChange('tipo', 'IMAGEM')}
                disabled={loadingVideo || loading}
              >
                <ImageOutlinedIcon />
                <span>Imagem/Gif</span>
              </S.ButtonMedia>

              <S.ButtonMedia
                active={!form.tipo}
                onClick={() => handleChange('tipo', null)}
                disabled={loadingVideo || loading}
              >
                <BlockOutlinedIcon />
                <span>Nenhum</span>
              </S.ButtonMedia>
            </div>

            {/* MODES */}
            <Collapse in={!!form.tipo} unmountOnExit>
              <p className="titulo">Modelo</p>
              <div className="mediaType">
                <S.ButtonMedia
                  active={form.mode === 'link'}
                  onClick={() => handleChange('mode', 'link')}
                  disabled={loadingVideo || loading}
                >
                  <InsertLinkOutlinedIcon />
                  <span>Link</span>
                </S.ButtonMedia>

                <S.ButtonMedia
                  active={form.mode === 'upload'}
                  disabled={loadingVideo || loading}
                >
                  {!loadingVideo && (
                    <>
                      <MoveToInboxOutlinedIcon />
                      <span>Upload</span>
                    </>
                  )}
                  {loadingVideo && (
                    <div className="ld">
                      <Loading />
                    </div>
                  )}
                  <input type="file" onChange={e => onLoadVideo(e, 'media')} />
                </S.ButtonMedia>
              </div>
              <Collapse in={!!form.mode} unmountOnExit>
                <Collapse in={form.mode === 'link'}>
                  <TextInput
                    noAsterisk
                    placeholder="Insira o link da mídia"
                    onChange={e => handleChange('link', e.target.value)}
                    value={form.link}
                  />
                </Collapse>

                <S.BoxVideo invalid={invalidLink && form.link}>
                  {form.link && (
                    <>
                      {form.tipo === 'VIDEO' && (
                        <ReactPlayer
                          width="100%"
                          height="100%"
                          controls
                          playing
                          url={form.link}
                          onError={() => setInvalidLink(true)}
                          onReady={() => setInvalidLink(false)}
                          light={form?.imagem_capa}
                        />
                      )}
                      {form.tipo === 'IMAGEM' && (
                        <img
                          src={form.link}
                          onError={() => setInvalidLink(true)}
                          onLoad={() => setInvalidLink(false)}
                        />
                      )}
                    </>
                  )}
                  {!form.link && (
                    <S.Empty>
                      <VideoCallOutlinedIcon />
                      <p>Nenhum link inserido</p>
                    </S.Empty>
                  )}
                  {invalidLink && form.link && (
                    <S.Empty className="error">
                      <SimCardAlertOutlinedIcon />
                      <p>Link inválido</p>
                    </S.Empty>
                  )}
                </S.BoxVideo>
                {form.tipo === 'VIDEO' && form.link && (
                  <S.ButtonCase>
                    {!loadingVideo && (
                      <>
                        <ShopOutlinedIcon />
                        {form.imagem_capa
                          ? 'Alterar capa do vídeo'
                          : 'Adicionar capa do vídeo'}
                      </>
                    )}

                    {loadingVideo && (
                      <div className="ld">
                        <Loading />
                      </div>
                    )}
                    <input
                      type="file"
                      onChange={e => onLoadVideo(e, 'capa')}
                      disabled={loadingVideo || loading}
                    />
                  </S.ButtonCase>
                )}
              </Collapse>
            </Collapse>
          </S.Section>
          <S.Section className="grid">
            <div className="infos">
              <p className="titulo">Titulo</p>
              <TextInput
                noAsterisk
                placeholder="Titulo do passo"
                value={form?.titulo}
                onChange={e =>
                  handleChange('titulo', e.target.value?.slice(0, 35))
                }
              />
              <div className="counter">{form?.titulo?.length || 0}/35</div>
            </div>
            <div className="time">
              <p className="titulo">Tempo (Minutos)</p>
              <TextInput
                noAsterisk
                placeholder="Duração do passo"
                value={form?.tempo}
                onChange={e =>
                  handleChange('tempo', e.target.value?.slice(0, 3))
                }
              />
            </div>
          </S.Section>
          <S.Section>
            <p className="titulo">Descrição</p>
            <TextInput
              value={form?.descricao}
              onChange={e =>
                handleChange('descricao', e.target.value?.slice(0, 300))
              }
              noAsterisk
              placeholder="Descrição do passo"
              multiline
              rows={5}
            />
            <div className="counter">{form?.descricao?.length || 0}/300</div>
          </S.Section>

          {!firstSteps && (
            <S.CheckArea>
              <p className="label">
                Locais de exibição: <span className="req">*</span>
              </p>
              <div className="checks">
                <label>
                  <Checkbox
                    checked={form.mostrar_guia}
                    onChange={() =>
                      handleChange('mostrar_guia', !form.mostrar_guia)
                    }
                  />
                  <p>Tela de guias</p>
                </label>
                <label>
                  <Checkbox
                    checked={form.mostrar_rota}
                    onChange={() =>
                      handleChange('mostrar_rota', !form.mostrar_rota)
                    }
                  />
                  <p>Tela da url</p>
                </label>
                <label>
                  <Checkbox
                    checked={form.mostrar_catalogo}
                    onChange={() =>
                      handleChange('mostrar_catalogo', !form.mostrar_catalogo)
                    }
                  />
                  <p>Catálogo</p>
                </label>
              </div>
            </S.CheckArea>
          )}

          <S.CheckArea>
            <p className="label">
              Exibir para níveis: <span className="req">*</span>
            </p>
            <div className="checks">
              <label>
                <Checkbox
                  checked={form?.niveis?.includes(1)}
                  onChange={() => handleLevels(1)}
                />
                <p>1</p>
              </label>
              <label>
                <Checkbox
                  checked={form?.niveis?.includes(2)}
                  onChange={() => handleLevels(2)}
                />
                <p>2</p>
              </label>
              <label>
                <Checkbox
                  checked={form?.niveis?.includes(3)}
                  onChange={() => handleLevels(3)}
                />
                <p>3</p>
              </label>
            </div>
          </S.CheckArea>
        </S.Body>
        <S.Footer>
          <label>
            <Checkbox
              checked={form.is_etapa_obrigatoria}
              onChange={() =>
                handleChange('is_etapa_obrigatoria', !form.is_etapa_obrigatoria)
              }
            />
            <p>Obrigatório</p>
          </label>
          <GhostButton
            children="Cancelar"
            onClick={() => closeModal()}
            disabled={loading || loadingVideo}
          />
          <DefaultButton
            children={edit ? 'Editar' : 'Adicionar'}
            onClick={() => handleSave()}
            loading={loading}
            disabled={loading || loadingVideo}
          />
        </S.Footer>

        {/* Modal Area */}
        <ConfirmModal
          open={modalClose}
          title="Tem certeza que deseja sair sem salvar?"
          handleClose={() => setModalClose(null)}
          buttonText="Sair"
          isNegative
          subtitle="Todas as alterações serão perdidas"
          onClick={() => {
            setForm({});
            setModalClose(false);
            handleClose();
          }}
          titleIcon={<WarningAmberRoundedIcon htmlColor={colors.redDanger} />}
        />
      </S.Container>
    </S.ModalMUI>
  );
};
