import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-left: 44px;
  padding-left: 20px;
  overflow: hidden;
  .top {
    text-align: left;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    font-family: Texta;
    letter-spacing: 0px;
    color: #4b5166;
  }
  .middle {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    .point {
      margin-right: 5px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
    }
    .text {
      color: #4b5166;
      font-size: 14px;
      font-family: Texta;
      font-weight: normal;
      font-style: normal;
      margin-right: 5%;
    }
  }
`;

export { Container };
