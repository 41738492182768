import styled, { css } from 'styled-components';

const Main = styled.div`
  ${({ theme }) => css`
    height: calc(100vh - 140px);
    display: flex;
    background-color: ${theme.palette.brand.primary.background};
    justify-content: center;
    align-items: center;
  `}
`;

const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 660px;
    height: 350px;
    padding: 25px;
    background-color: ${theme.palette.system.overlay};
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    border-radius: 4;
  `}
`;

const Header = styled.div`
  ${({ theme }) => css`
    .bigTitle {
      font-weight: 900;
      color: ${theme.palette.words.title.natural};
      font-size: 22px;
      display: flex;
      align-items: center;

      em {
        text-transform: capitalize;
        margin-left: 5px;
      }
    }

    .subTitle {
      margin: 10px 0px;
      font-weight: 500;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;

const Body = styled.div`
  margin-top: 10px;
`;

const PlanItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;

    &.header {
      margin-bottom: 15px;
    }

    .left {
      display: flex;
      align-items: center;

      img {
        width: 30px;
      }

      .planTitle {
        margin-left: 10px;
        font-style: italic;
        font-weight: 600;
        color: ${theme.palette.words.subtitle.natural};
      }
    }

    .right {
      display: flex;
      align-items: center;

      .doUp {
        margin-right: 10px;
        font-size: 13px;
        font-weight: 700;
        color: ${theme.palette.semantics.feedback.warning.natural};
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .planDesc {
        text-transform: capitalize;
        border: 1px solid ${theme.palette.brand.secondary.natural};
        font-weight: 800;
        color: ${theme.palette.words.subtitle.natural};
        padding: 2px 10px;
        border-radius: 20px;
        font-size: 13px;
        pointer-events: none;
      }
    }

    .titleTab {
      text-transform: capitalize;
      font-weight: 700;
      color: ${theme.palette.words.subtitle.natural};
      font-size: 14px;
    }

    .accessNew {
      color: ${theme.palette.semantics.feedback.information.natural};
      font-weight: 800;
      font-size: 13px;
      background-color: ${theme.palette.semantics.feedback.information.light};
      border-radius: 20px;
      padding: 2px 10px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  `}
`;

const Footer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Main, Card, Header, Body, PlanItem, Footer };
