import React, { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLessOutlined';
import ExpandMore from '@mui/icons-material/ExpandMoreOutlined';

import * as S from './styled';
import { Popover } from '@mui/material';

export const Select = ({ value, options = [], label, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div>
      <S.Button onClick={handleClick} active={open}>
        {label} {open ? <ExpandLess /> : <ExpandMore />}
      </S.Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map(option => (
          <S.Item
            key={option.value}
            onClick={() => onChange(option.value)}
            active={option.value === value}
          >
            {option.label}
          </S.Item>
        ))}
      </Popover>
    </div>
  );
};
