import api from 'services/api';

const getDesempenhoPtsH = async query => {
  const res = await api.get('/desempenho-pts-hora', { params: query });
  return res.data;
};
const getExportDesempenhoPtsH = async query => {
  const res = await api.get('/excel/desempenho_pts_hora', { params: query });
  return res?.data;
};

const getDesempenhoPtsKm = async query => {
  const res = await api.get('/desempenho-pts-km', { params: query });
  return res.data;
};

const getExportDesempenhoPtsKm = async query => {
  const res = await api.get('/excel/desempenho_pts_km', { params: query });
  return res?.data;
};

const getDesviosTypes = async query => {
  const res = await api.get('/desvios/configuracoes/padroes', {
    params: query,
  });
  return res.data;
};

const getClientes = async query => {
  const res = await api.get('/select-clients');
  const data = res?.data?.data || [];
  return data.map(i => ({ id: i?.id, value: i?.nome }));
};

const getDesvioCriticidade = async query => {
  const res = await api.get('/desvios/estatisticas/desvio-criticidade', {
    params: query,
  });
  return res?.data || { data: [], fator_risco: [] };
};

const getDesvioCriticidadeTipo = async query => {
  const res = await api.get('/desvios/estatisticas/desvio-criticidade-tipo', {
    params: query,
  });
  return res?.data?.data || [];
};

const getPadroesCriticidade = async query => {
  const res = await api.get('/desvios/estatisticas/padroes-criticidade', {
    params: query,
  });
  return res?.data || [];
};

const getHistorico = async query => {
  const res = await api.get(`/desempenho-historico`, {
    params: query,
  });
  return res?.data || [];
};

const getRadarDesvios = async query => {
  const res = await api.get(`/desvios/estatisticas/radar-desvios`, {
    params: query,
  });
  return res;
};

export {
  getDesempenhoPtsH,
  getDesviosTypes,
  getClientes,
  getDesvioCriticidade,
  getDesvioCriticidadeTipo,
  getPadroesCriticidade,
  getDesempenhoPtsKm,
  getExportDesempenhoPtsKm,
  getExportDesempenhoPtsH,
  getHistorico,
  getRadarDesvios,
};
