import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 5px 40px;
`;

export const MediaArchive = styled.div`
  ${({ theme }) => css`
    margin-top: 8px;

    .ck-content {
      padding: 10px;
    }

    .ck-reset {
      border: 2px solid
        ${props =>
          props.isError
            ? theme.palette.semantics.feedback.attention.natural
            : theme.palette.semantics.feedback.unknown.natural};
      border-radius: 4px;
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-weight: 400;
    color: ${theme.palette.words.subtitle.natural};
  `}
`;
