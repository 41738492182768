import React, { useState } from 'react';
import {
  Tooltip,
  Legend,
  ResponsiveContainer,
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Text,
} from 'recharts';
import CheckFiltersCard from 'components/Cards/CheckFiltersCard';
import { paletteColors } from '../../../../styles/colors';
import Loading from 'components/Loading';
import * as S from './styled';

export const RadarGraph = ({
  data,
  series = [],
  labels = [],
  colors,
  margin,
  loading,
  tooltip,
  axisMax,
}) => {
  const chartColors = paletteColors[colors] || paletteColors.default;
  const [opacity, setOpacity] = useState(
    series.reduce((obj, key) => {
      obj[key] = 0.3;
      return obj;
    }, {}),
  );

  const WrappedAxisTick = props => {
    const { x, y, payload, textAnchor } = props;

    let label = payload.value;

    if (label) {
      return (
        <Text width={100} x={x} y={y} textAnchor={textAnchor}>
          {payload.value}
        </Text>
      );
    }
    return null;
  };

  const handleMouseEnter = o => {
    const { dataKey } = o;
    setOpacity(opacity => ({ ...opacity, [dataKey]: 0.5 }));
  };

  const handleMouseLeave = o => {
    const { dataKey } = o;
    setOpacity(opacity => ({ ...opacity, [dataKey]: 0.3 }));
  };

  return (
    <S.Container>
      {loading && (
        <S.BoxLoading>
          <Loading />
        </S.BoxLoading>
      )}
      {!loading && data?.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <RadarChart
            data={data}
            margin={margin}
            width={250}
            height={250}
            layout="centric"
          >
            <Legend
              align="right"
              verticalAlign="top"
              layout="vertical"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
            {tooltip && <Tooltip />}
            <PolarGrid />
            <PolarAngleAxis dataKey="name" tick={WrappedAxisTick} />
            <PolarRadiusAxis
              angle={90}
              domain={[0, axisMax || (dataMax => dataMax)]}
              opacity={0}
            />
            {series.map((item, index) => (
              <Radar
                key={item}
                dataKey={item}
                name={labels[index] ?? item}
                stroke={chartColors[index % chartColors.length]}
                strokeWidth={opacity[item] === 0.3 ? 1 : 2}
                fill={chartColors[index % chartColors.length]}
                fillOpacity={opacity[item]}
              />
            ))}
          </RadarChart>
        </ResponsiveContainer>
      ) : (
        <CheckFiltersCard height="200px" marginTop="45px" />
      )}
    </S.Container>
  );
};
