import styled, { css } from 'styled-components';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled as stMuiUI } from '@mui/material/styles';

const Container = styled.div`
  ${({ theme }) => css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0px;
    right: 0px;
    width: 550px;
    height: 100%;
    background-color: ${theme.palette.system.overlay};
    padding: 0px 30px;
  `}
`;

const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid ${theme.palette.brand.primary.light}4d;

    div {
      display: flex;
      flex-direction: row;
      h2 {
        margin-left: 10px;
      }
    }
  `}
`;

const Main = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 55%;
    padding: 5px 15px;
    overflow-y: scroll;
    margin-bottom: 5px;
    .error {
      color: ${theme.palette.semantics.feedback.attention.natural};
    }
    label {
      display: flex;
      align-items: center;

      input {
        display: none;
      }

      .imgArea {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid ${theme.palette.brand.secondary.natural};
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        .borderImage {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
          }
        }

        .empty {
          background-color: ${theme.palette.system.border};
          width: 100%;
          height: 100%;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 900;
          color: ${theme.palette.words.title.natural};
        }
      }

      .textArea {
        display: flex;
        align-items: center;
        color: ${theme.palette.words.subtitle.natural};
        margin-left: 10px;

        span {
          margin-left: 5px;
        }
      }
    }
  `}
`;

const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    border-top: 1px solid ${theme.palette.brand.primary.light}4d;
  `}
`;

const Section = styled.h3`
  ${({ theme }) => css`
    font: normal normal 900 13px/50px Texta;
    justify-content: center;
    color: ${theme.palette.words.subtitle.natural};
  `}
`;

const CustumTooltip = stMuiUI(({ className, ...props }) => (
  <Tooltip placement="top" arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: 15,
  },
}));
export { Container, Header, Main, Footer, Section, CustumTooltip };
