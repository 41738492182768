// React
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import SelectList from 'components/Inputs/SelectMultiple';

// Components MUI
import List from '@mui/material/List';
import { Popover } from '@mui/material';
import * as S from './styled';

// Utils

const Filters = ({ id, open, onClose, anchorEl, filter, setFilter }) => {
  const [prevFilter, setPrevFilter] = useState({ ...filter });
  const [filiaisFilter, setFiliaisFilter] = useState([]);
  const [selectedEmpresas, setSelectedEmpresas] = useState(false);

  const selects = useSelector(state => state.selects);

  useEffect(() => {
    setPrevFilter({ ...filter });
  }, [filter]);

  useEffect(() => {
    if (prevFilter?.empresas) {
      const count = prevFilter?.empresas.length;

      if (count === 1) {
        setSelectedEmpresas(true);
        const empresa = prevFilter?.empresas;
        const newFiliais = selects.empresas
          .find(company => company.id === Number(empresa))
          .filiais?.map(i => ({ id: i.id, value: i.nome }));
        setFiliaisFilter(newFiliais);
      } else {
        setSelectedEmpresas(false);
        setPrevFilter(_prevFilter => ({ ..._prevFilter, filial: '' }));
      }
    } else {
      setSelectedEmpresas(false);
      setPrevFilter(_prevFilter => ({ ..._prevFilter, filial: '' }));
    }
  }, [prevFilter?.empresas, selects.empresas]);

  const handleChangeMultiple = (e, key) => {
    setPrevFilter(_prevFilter => {
      return { ..._prevFilter, [key]: e };
    });
  };

  const aplyFilter = () => {
    const _prevFilter = { ...prevFilter };
    Object.keys(_prevFilter).forEach(key => {
      if (!_prevFilter[key]) delete _prevFilter[key];
    });
    setFilter({ ..._prevFilter });
    onClose();
  };

  const reset = () => {
    setPrevFilter({ ...filter });
  };

  // Limpa TODOS os valores do estado e foca no botao de aplicar
  const handleClear = () => {
    setPrevFilter({});
    const buttonApply = document.getElementById('aplicar');
    buttonApply?.focus();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <S.Main>
        <S.Header>
          <h2>Filtros</h2>
          <S.ClearButton onClick={handleClear}>Limpar filtros</S.ClearButton>
        </S.Header>

        <S.SelectsContainer>
          <List>
            <S.CollapseContent>
              <SelectList
                value={prevFilter?.empresas}
                handleChange={handleChangeMultiple}
                fieldName="empresas"
                label="Filtrar por Empresas"
                data={selects.empresas}
                selectAll
              />
              {selectedEmpresas && (
                <SelectList
                  value={prevFilter?.filial}
                  handleChange={handleChangeMultiple}
                  fieldName="filial"
                  label="Filtrar por Filial"
                  data={filiaisFilter}
                  selectAll
                />
              )}
            </S.CollapseContent>
          </List>
        </S.SelectsContainer>
      </S.Main>
      <S.Footer>
        <GhostButton
          onClick={() => {
            reset();
            onClose();
          }}
          size="medium"
        >
          Cancelar
        </GhostButton>

        <DefaultButton
          onClick={aplyFilter}
          size="medium"
          sx={{ marginLeft: '20px' }}
          id="aplicar"
        >
          APLICAR
        </DefaultButton>
      </S.Footer>
    </Popover>
  );
};

export default Filters;
