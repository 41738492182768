import { Template } from 'template';

export const useConstants = (environments, user) => {
  const _USER_LEVEL = user?.nivel;

  const _MAPPER = [];
  environments?.forEach(env => {
    if (env.active)
      env.paginas.map(pagina => {
        if (_USER_LEVEL && _USER_LEVEL <= pagina?.restricao_nivel) {
          _MAPPER.push(pagina?.pagina);
        }
      });
  });

  const provider = user?.provider;
  const _USER_ACCESS = provider ? './provider' : './user';

  const _PUBLIC_FOLDERS = require.context('./public', true, /^\.\/[^/]+$/);
  const _PUBLIC_FILES = _PUBLIC_FOLDERS
    .keys()
    .map(key => key.replace('./', ''));

  const _FOLDERS = provider
    ? require.context('./provider', true, /^\.\/[^/]+$/)
    : require.context('./user', true, /^\.\/[^/]+$/);

  const _FILES = _FOLDERS.keys().map(key => key.replace('./', ''));

  const _PUBLIC = {};

  for (const i in _PUBLIC_FILES) {
    const _FOLDER = _PUBLIC_FILES[i];
    const _OBJECT_MODULE = require(`./public/${_FOLDER}`)?.default || {};

    Object.keys(_OBJECT_MODULE).forEach(key => {
      _PUBLIC[key] = _OBJECT_MODULE[key];
    });
  }

  const _PRIVATE = {};

  const checkRoute = (page, key) => {
    const excludes = ['?', ':'];

    const pure = route => {
      return route
        .split('/')
        .map(part =>
          excludes.some(item => part.includes(item)) || +part ? '' : part,
        )
        .join('/');
    };

    const pagePure = pure(page);
    const keyPure = pure(key);

    return pagePure === keyPure;
  };

  for (const i in _FILES) {
    const _FOLDER = _FILES[i];
    const _OBJECT_MODULE = require(`${_USER_ACCESS}/${_FOLDER}`)?.default || {};

    Object.keys(_OBJECT_MODULE).forEach(key => {
      if (_MAPPER.some(item => checkRoute(item, key))) {
        _PRIVATE[key] = {
          ..._OBJECT_MODULE[key],
          page: <Template children={_OBJECT_MODULE[key].page} />,
        };
      }
    });
  }

  return {
    _PUBLIC,
    _PRIVATE,
  };
};
