import * as types from './types';

export function setFilter(query) {
  return {
    type: types.SET_FILTER_AVALIACOES,
    payload: { query },
  };
}

export function changeTabEvaluationDescarga({ tab }) {
  return {
    type: '@evaluations/CHANGE_TAB_EVALUATION_DESCARGA',
    payload: { tab },
  };
}
