/* eslint-disable import/order */
// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import UpgradeModal from './UpgradeModal';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { Divider } from '@mui/material';
import SwitchAccessShortcutAddOutlinedIcon from '@mui/icons-material/SwitchAccessShortcutAddOutlined';

export const Upgrade = () => {
  const theme = useTheme();
  const location = useLocation();
  const [titulo, setTitulo] = useState(null);
  const img = require(`images/Onisys_Simbolo.png`);
  const { pageMap } = useSelector(state => state.pageMap);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('titulo');
    setTitulo(query);
  }, [location]);

  const [planCo, setPlanCo] = useState(null);
  const [planCa, setPlanCa] = useState(null);
  const [planF, setPlanF] = useState(null);

  const [upgradeModal, setUpgradeModal] = useState(false);

  useEffect(() => {
    if (pageMap) {
      let contratacao = pageMap.filter(item => item.id === 1 || item.id === 2);
      let capacitacao = pageMap.filter(item => item.id === 3 || item.id === 4);
      let fiscalizacao = pageMap.filter(item => item.id === 5 || item.id === 6);

      contratacao = contratacao.filter(item => item.active);
      capacitacao = capacitacao.filter(item => item.active);
      fiscalizacao = fiscalizacao.filter(item => item.active);

      setPlanCo(contratacao[0] || { titulo: 'Onisys Contratação' });
      setPlanCa(capacitacao[0] || { titulo: 'Onisys Capacitação' });
      setPlanF(fiscalizacao[0] || { titulo: 'Onisys Monitoramento' });
    }
  }, [pageMap]);

  return (
    <S.Main>
      <S.Card>
        <S.Header>
          <div className="bigTitle">
            <SwitchAccessShortcutAddOutlinedIcon
              style={{ marginRight: '10px' }}
              htmlColor={theme.palette.brand.secondary.natural}
            />
            Faça um um upgrade para ter acesso ao:
            <em>{titulo?.toLowerCase()}</em>
          </div>
          <div className="subTitle">
            Tenha acesso a novas funcionalidades do sistema{' '}
          </div>
        </S.Header>
        <Divider />
        <S.Body>
          <div className="enviroments">
            <S.PlanItem className="header">
              <div className="left">
                <span className="planTitle">Produto</span>
              </div>
              <span className="titleTab">Plano contratado</span>
            </S.PlanItem>
            <S.PlanItem>
              <div className="left">
                <img src={img} />
                <span className="planTitle">{planCo?.titulo}</span>
              </div>
              {planCo?.descricao && (
                <div className="right">
                  {planCo.id < 2 && (
                    <div className="doUp" onClick={() => setUpgradeModal(true)}>
                      Fazer upgrade
                    </div>
                  )}
                  <span className="planDesc">
                    {planCo?.descricao?.toLowerCase()}
                  </span>
                </div>
              )}

              {!planCo?.descricao && (
                <span
                  className="accessNew"
                  onClick={() => setUpgradeModal(true)}
                >
                  Obter acesso
                </span>
              )}
            </S.PlanItem>

            <S.PlanItem>
              <div className="left">
                <img src={img} />
                <span className="planTitle">{planCa?.titulo}</span>
              </div>

              {planCa?.descricao && (
                <div className="right">
                  {planCa.id < 4 && (
                    <div className="doUp" onClick={() => setUpgradeModal(true)}>
                      Fazer upgrade
                    </div>
                  )}
                  <span className="planDesc">
                    {planCa?.descricao?.toLowerCase()}
                  </span>
                </div>
              )}

              {!planCa?.descricao && (
                <span
                  className="accessNew"
                  onClick={() => setUpgradeModal(true)}
                >
                  Obter acesso
                </span>
              )}
            </S.PlanItem>

            <S.PlanItem>
              <div className="left">
                <img src={img} />
                <span className="planTitle">{planF?.titulo}</span>
              </div>
              {planF?.descricao && (
                <div className="right">
                  {planF.id < 6 && (
                    <div className="doUp" onClick={() => setUpgradeModal(true)}>
                      Fazer upgrade
                    </div>
                  )}
                  <span className="planDesc">
                    {planF?.descricao?.toLowerCase()}
                  </span>
                </div>
              )}
              {!planF?.descricao && (
                <span
                  className="accessNew"
                  onClick={() => setUpgradeModal(true)}
                >
                  Obter acesso
                </span>
              )}
            </S.PlanItem>
          </div>
        </S.Body>
        <S.Footer>
          <DefaultButton
            children={`Fazer upgrade para: ${titulo}`}
            style={{ width: '100%' }}
            onClick={() => setUpgradeModal(true)}
          />
        </S.Footer>
      </S.Card>

      <UpgradeModal
        open={upgradeModal}
        handleClose={() => setUpgradeModal(false)}
      />
    </S.Main>
  );
};
