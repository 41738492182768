import { auth } from 'utils/firebase';
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3333',
});

// Serviço exportacoes
const exportURL =
  process.env.REACT_APP_API_URL_EXPORTACOES ||
  process.env.REACT_APP_API_URL ||
  'http://localhost:3333';

api.interceptors.request.use(
  async config => {
    const token = auth.currentUser && (await auth.currentUser.getIdToken());
    // navigator.serviceWorker.controller.postMessage({
    //   type: 'setToken',
    //   token,
    // });

    if (config.url.includes('excel')) {
      config.baseURL = exportURL;
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // return Promise.reject(error);  //default
    // return error.toJSON();
    return error.response;
  },
);

export default api;
