import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { endOfMonth, startOfMonth, subHours, subMonths } from 'date-fns';

import { SvgIcon } from '@mui/material';
import Graph from 'components/Graphs/ComposedChart';
import SelectInput from 'components/Inputs/Select';
import { ReactComponent as filterIco } from 'images/icons/filter.svg';
import Filters from './Filters';
import { PeriodPicker } from '../../Components/PeriodPicker';

import * as S from './styled';
import { requestDriversHistorico } from './services';
import { ptMonths } from 'utils/dates';
import { usePlans } from 'hooks/usePlans';

export const Historico = () => {
  const theme = useTheme();
  const { hasMonitoramento } = usePlans();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState({
    finalDate: new Date(),
    initialDate: startOfMonth(subMonths(new Date(), 11)),
    label: 'motoristas_avaliados',
  });

  const countFilter = Object.getOwnPropertyNames(filter).length - 3;

  const { isFetching, data } = useQuery(
    ['empresas-historico', filter],
    () =>
      filter &&
      requestDriversHistorico({
        ...filter,
        initialDate: subHours(filter.initialDate, 3),
        finalDate: subHours(filter.finalDate, 3),
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const titleComponent = (
    <S.Header>
      <S.Title>Estatisticas</S.Title>
      <PeriodPicker
        period="lastYear"
        periodAdd="month"
        initialDate={filter.initialDate}
        finalDate={filter.finalDate}
        onChange={value =>
          setFilter(state => ({
            ...state,
            initialDate: value.initialDate,
            finalDate: value.finalDate,
          }))
        }
        maxDate={endOfMonth(new Date())}
      />
    </S.Header>
  );

  const selectItens = useMemo(() => {
    const itens = [
      { value: 'motoristas_avaliados', name: 'Motoristas avaliados' },
      { value: 'atendimento_com_sla', name: 'Atendimentos com SLA' },
    ];
    if (hasMonitoramento)
      itens.splice(1, 0, {
        value: 'horas_inspecionadas',
        name: 'Horas inspecionadas',
      });

    return itens;
  }, [hasMonitoramento]);

  const selectComponent = (
    <S.Header>
      <SelectInput
        style={{ height: '45px' }}
        value={filter.label}
        data={selectItens}
        onChange={({ target }) => {
          setFilter(state => ({
            ...state,
            label: target?.value,
          }));
        }}
      />
      <S.StyledButton
        textcolor={theme.palette.words.text.natural}
        backgroundcolor="transparent"
        startIcon={<SvgIcon component={filterIco} />}
        height="45px"
        onClick={event => setAnchorEl(event.currentTarget)}
        disabled={isFetching}
      >
        Filtros
        <S.Count count={!!countFilter}>{countFilter}</S.Count>
      </S.StyledButton>
    </S.Header>
  );

  const getData = _data => {
    return data?.map(item => ({
      ...item,
      value: Number(item.x),
      name: `${ptMonths[item.mes - 1].substring(0, 3)} ${item.ano}`,
    }));
  };

  const rightYAxisMax = datamax => {
    return datamax > 100
      ? Math.ceil(datamax / 100) * 100
      : datamax > 10
      ? Math.ceil(datamax / 10) * 10
      : 10;
  };

  return (
    <>
      <Graph
        data={getData(data)}
        barSeries={[]}
        barLabel={[]}
        barColors="default"
        rightYAxisMax={rightYAxisMax}
        lineSeries={['value', 'meta']}
        lineLabel={
          filter.label === 'atendimento_com_sla'
            ? ['Percentual', 'Meta']
            : ['Quantidade']
        }
        lineColors="meta"
        linePostFix={filter.label === 'atendimento_com_sla' ? '%' : ''}
        lineStrokeDash={['', '5 5']}
        titleComponent={titleComponent}
        tabComponent={selectComponent}
        loading={isFetching}
        tooltip
        wrapped
      />
      <Filters
        id="simple-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        filter={filter}
        setFilter={value =>
          setFilter(state => ({
            ...value,
            initialDate: state.initialDate,
            finalDate: state.finalDate,
            label: state.label,
          }))
        }
      />
    </>
  );
};
