import { Icon } from '@mui/material';
import { Content, Table } from './styled';

const Features = ({ plans, features, provider }) => {
  return (
    <Content>
      <h1>Compare os planos</h1>
      <br />
      <br />

      <Table>
        <thead>
          <tr>
            <td>&nbsp;</td>
            {plans.map(plan => (
              <td key={plan.id}>{plan.name}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {features
            .filter(feature =>
              plans.some(plan =>
                provider
                  ? plan.featuresProvider.includes(feature.id)
                  : plan.features.includes(feature.id),
              ),
            )
            .map(feature => (
              <tr key={feature.id}>
                <td>{feature.name}</td>
                {plans.map(plan => {
                  const itens = provider
                    ? plan.featuresProvider
                    : plan.features;
                  const iconOk = feature.plugin ? 'add_circle' : 'check_circle';

                  return (
                    <td key={plan.id}>
                      {itens.includes(feature.id) ? (
                        <Icon color="secondary">{iconOk}</Icon>
                      ) : (
                        <Icon color="disabled">cancel</Icon>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </Table>

      <br />
      <p style={{ fontSize: '24px' }}>
        * Estas funcionalidades podem ser adicionadas ao seu plano, para mais
        informações, consulte um de nossos consultores.
      </p>
    </Content>
  );
};

export default Features;
