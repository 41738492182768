import styled, { css, keyframes } from 'styled-components';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

export const Container = styled.div`
  ${({ theme }) => css``}
`;

export const ContainerSelects = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #edf2f9;
    border-radius: 4px;
    height: ${p => (p.isOpen ? '48px' : '0')};
    overflow: hidden;
    transition: height 0.6s ease-in-out;
    .container-list {
      display: flex;
      flex-direction: row;
      overflow-x: hidden;
      min-height: 100%;
      align-items: center;
    }
    .container-select {
      display: flex;
      align-items: center;
      flex-direction: row;
      min-height: 100%;
    }
    .filter-list-description {
      font-family: 'Texta';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: ${theme.palette.words.title.natural};
      margin-left: 10px;
    }
    .list-selects {
      margin-right: 8px;
    }

    @media (max-width: 1333px) {
      .container-select {
        overflow-x: auto;
        width: 86%;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      .container-select::-webkit-scrollbar {
        display: none;
      }
      .filter-list-description {
        width: 100px;
      }
    }

    @media (max-width: 1024px) {
      .container-select {
        overflow-x: auto;
        width: 85%;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }
  `}
`;

export const ButtonApply = styled(Button)`
  && {
    ${({ theme }) => css`
      height: 32px;
      background: ${theme.palette.brand.secondary.natural};
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 16px;
      gap: 4px;
      font-family: 'Texta';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      text-transform: none;
      margin-right: 8px;
      white-space: nowrap;
      flex-shrink: 0;
    `}
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  .container-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .btn-right-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
`;

export const ButtonCustom = styled(Button)`
  ${({ theme, ...props }) => css`
    && {
      height: 32px;
      border: 1px solid
        ${props.opacity
          ? `${theme.palette.brand.primary.light}`
          : theme.palette.brand.primary.natural};
      border-radius: 4px;
      font-family: 'Texta';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      background-color: ${props.selected
        ? theme.palette.brand.primary.natural
        : 'transparent'};
      color: ${!props.selected
        ? props.opacity
          ? theme.palette.brand.primary.light
          : theme.palette.brand.primary.natural
        : '#fff'};
      text-transform: none;
    }
    &:hover {
      background-color: ${props.selected
        ? theme.palette.brand.primary.dark
        : `${theme.palette.brand.primary.natural}10`};
      border: 1px solid;
      border-color: ${theme.palette.brand.primary.light};
    }

    &:active {
      background: ${props.selected
        ? theme.palette.brand.primary.dark
        : `${theme.palette.brand.primary.natural}10`};
      box-shadow: 0px 3px 15px ${theme.palette.brand.primary.natural};
    }
  `}
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const ButtonIconCustom = styled(IconButton)`
  ${({ theme, ...props }) => css`
    && {
      height: 32px;
      border: 1px solid
        ${props.opacity
          ? `${theme.palette.brand.primary.light}`
          : theme.palette.brand.primary.natural};
      border-radius: 4px;
      font-family: 'Texta';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      background-color: ${props.selected
        ? theme.palette.brand.primary.natural
        : 'transparent'};
      color: ${!props.selected
        ? props.opacity
          ? theme.palette.brand.primary.light
          : theme.palette.brand.primary.natural
        : '#fff'};
      text-transform: none;
      ${({ isRotating }) =>
        isRotating &&
        css`
          svg {
            animation: ${rotate} 2s linear infinite;
          }
        `}
    }
    &:hover {
      background-color: ${props.selected
        ? theme.palette.brand.primary.dark
        : `${theme.palette.brand.primary.natural}10`};
      border: 1px solid;
      border-color: ${theme.palette.brand.primary.light};
    }

    &:active {
      background: ${`${theme.palette.brand.primary.natural}10`};
      box-shadow: 0px 3px 15px
        ${props.customcolor || theme.palette.brand.primary.natural};
    }
  `}
`;

export const PopoverContaiener = styled.div`
  background-color: #fff;
  justify-content: flex-start;
  border-radius: 4px;
  width: 200px;
  border: 1px solid rgba(149, 170, 201, 0.3);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  .text {
    margin-bottom: 10px;
    font-family: 'Texta';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #4b5166;
  }
  .container-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    justify-content: center;
  }
`;
