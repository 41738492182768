import styled from 'styled-components';
import { IconButton, Pagination } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.system.white};
  position: relative;
`;

export const TopWrapper = styled.div`
  width: 100%;
  padding: 20px 20px 0px 20px;

  #searchForm input {
    padding: 10px;
    color: ${({ theme }) => theme.palette.words.text.light};
    opacity: 1;
  }

  #searchForm fieldset {
    border: 1px solid ${({ theme }) => theme.palette.system.divider};
    border-radius: 4px;
    padding: 10px;
  }
`;

export const MonthDiv = styled.div`
  width: 100%;
  height: 40px;
  background: ${({ theme }) => theme.palette.semantics.feedback.unknown.light};
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 130px;
    text-align: center;
  }
`;

export const ArrowButton = styled(IconButton)`
  && {
    background: ${({ theme }) => theme.palette.system.white};
    height: 24px;
    width: 24px;
    border: 1px solid ${({ theme }) => theme.palette.system.divider};
  }
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: 0px;
`;

export const Head = styled.thead`
  width: 100%;
  background: ${({ theme }) => theme.palette.semantics.feedback.unknown.light};
  color: ${({ theme }) => theme.palette.words.text.light};
  text-transform: uppercase;
`;

export const Body = styled.tbody`
  color: ${({ theme }) => theme.palette.brand.primary.natural};
`;

export const Tr = styled.tr`
  min-height: 27px;
  max-height: 30px;
  margin: 0;
`;

export const Th = styled.th`
  padding: 10px 22px;
  &:last-child {
    padding: 10px 22px 10px 0px !important;
    text-align: end;
    width: 100px;
  }
  &:first-child {
    padding: 10px 0px 10px 22px !important;
    width: 50px;
  }
  &:nth-child(2) {
    padding: 10px 0px 10px 15px !important;
  }
`;

export const Td = styled.td`
  text-align: left;
  color: ${({ theme }) => theme.palette.words.text.light};
  padding: 2px 0px 2px 20px;
  &:last-child {
    padding: 2px 0px 2px 0px !important;
  }
  &:first-child {
    width: 50px;
  }
`;

export const Footer = styled.div`
  color: ${({ theme }) => theme.palette.words.text.light};
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 22px;
  align-items: center;
  height: 100px;

  select {
    margin: 0 25px;
  }
`;

export const FooterCol = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 5px;
  }
`;

export const Control = styled.button`
  margin-left: 15px;
  display: flex;
  flex-direction: justify-content;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
`;

export const LoadingDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 80px;
  z-index: 9999;
  background: ${({ theme }) => theme.palette.system.backdrop};
  border-radius: 4px;
`;

export const Pages = styled(Pagination)`
  margin: 0px 10px;
  &.MuiPagination-root {
    color: ${({ theme }) => theme.palette.words.text.natural};
  }
`;
