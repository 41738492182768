import { InformacoesGerais } from './InfoGerais';
import { Formulario } from './Formulario';
import { Evidencias } from './Evidencias';
import { Assinaturas } from './Assinaturas';

export const steps = [
  {
    title: 'Informações Gerais',
    pageTitle: 'Informações',
    step: 1,
    value: 0,
    Component: changed => InformacoesGerais(changed),
  },
  {
    title: 'Formulário',
    pageTitle: 'Formulário',
    step: 2,
    value: 1,
    Component: changed => Formulario(changed),
  },
  {
    title: 'Evidências',
    pageTitle: 'Evidências',
    step: 3,
    value: 2,
    Component: changed => Evidencias(changed),
  },
  {
    title: 'Assinaturas',
    pageTitle: 'Assinaturas',
    step: 4,
    value: 3,
    Component: changed => Assinaturas(changed),
  },
];
