import api from 'services/api';

export const getAcoesEmpresa = async params => {
  const res = await api.get('/acoes-suspensoes-transportadoras', { params });
  return res;
};

export const getAcoesSuspensoes = async params => {
  const res = await api.get('/acoes-suspensoes', { params });
  return res;
};

export const requestExcelEmpresas = async query => {
  const res = await api.get('/excel/acao_suspensao_transportadoras', {
    params: query,
  });
  if (res?.data?.data?.link)
    return { link: res.data.data.link, message: res.data.message };
  return { message: res?.data?.message };
};
