// Icons
import { ReactComponent as list } from '../../images/icons/tasks/list.svg';
import { ReactComponent as grid } from '../../images/icons/tasks/grid.svg';

// Components
import { Line } from './Line';
import { Grid } from './Grid';

export const sections = {
  'minhas-tarefas': [
    {
      value: 1,
      icon: list,
      title: 'Lista',
      Component: params => Line(params),
    },
    {
      value: 2,
      icon: grid,
      title: 'Grade',
      Component: params => Grid(params),
    },
  ],
};
