export const tipos_contratacao = [
  'Tempo integral',
  'Meio período',
  'Contrato',
  'Temporário',
  'Outro',
];

export const tipos_tempo_experiencia = [
  'Sem experiência',
  '1 ano',
  '2 anos',
  '3 anos',
  '4 anos',
  '5 anos',
  '+6 anos',
];
