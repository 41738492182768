import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  width: ${props => props.width};
  overflow: hidden;
  position: relative;
`;

export const TagContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: hidden;
  max-width: calc(100vw - 280px);
  overflow: hidden;
  scroll-behavior: smooth;
`;
