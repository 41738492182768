import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '250px')};
  position: relative;
`;

export const DropContainer = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: ${props.width ? props.width : '100%'};
    height: ${props.height ? props.height : '500px'};
    border: ${props.file ? '' : '1px dashed'};
    border-radius: 4px;
    background-color: ${theme.palette.system.overlay};
    position: relative;

    input {
      display: none;
    }
  `}
`;

export const Message = styled.div`
  text-align: center;

  p {
    color: ${({ theme }) => theme.palette.words.text.light} !important;
    font-weight: 900;
    font: normal normal medium 14px/15px Texta;
  }
`;

export const Image = styled.div`
  img {
    height: 50px;
    width: 56px;
    margin-bottom: 5px;
  }
`;

export const ImageURL = styled.img`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '100%')};
  border-radius: 4px;
`;

export const IconDivClose = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  margin-right: -10px;
`;
