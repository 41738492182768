/* eslint-disable import/order */

// Styles
import * as S from './styled';

// Components
import { Photo } from '../Photo';
import { Loading } from '../Loading';
import { Tooltip as Tool } from '../Tooltip';

// Components MUI
import { Tooltip } from '@mui/material';

export const TD = ({
  children,
  tooltip,
  subRow,
  selection,
  align = 'left',
  custom,
  onClick,
  hover,
  blocked,
  loading,
  disabled,
  picture,
  attention,
  compactRow,
  ...rest
}) => {
  const content = () => {
    return (
      <S.TD
        align={align}
        selection={selection}
        isLoading={loading}
        className="td"
        {...rest}
      >
        {!selection && <Loading isLoading={loading} />}
        {!selection && loading ? '-' : children}
      </S.TD>
    );
  };

  return (
    <S.Container
      clickable={!!onClick}
      align={align}
      selection={selection}
      onClick={!blocked ? onClick : undefined}
      hover={hover}
      isLoading={loading}
      disabled={disabled}
      data-testid="td"
      compactRow={compactRow}
    >
      <>
        {custom && custom}
        {!custom && (
          <S.Wrapper align={align}>
            {picture?.show && (
              <Photo
                image={picture?.src}
                letter={picture?.fill}
                loading={loading}
              />
            )}

            {attention && attention?.left && (
              <Tool
                placement="right"
                text={attention?.text}
                iconIn={attention?.iconIn}
                iconOut={attention?.iconOut}
                disabled={attention?.disabled}
              />
            )}

            <S.Infos picture={!!picture}>
              {tooltip ? (
                <Tooltip title={children} placement="bottom-start">
                  {content()}
                </Tooltip>
              ) : (
                content()
              )}
              {subRow && (
                <S.SubRow align={align}>
                  {!selection && <Loading isLoading={loading} />}
                  {!selection && loading ? '-' : subRow}
                </S.SubRow>
              )}
            </S.Infos>

            {attention && !attention?.left && (
              <Tool
                text={attention?.text}
                iconIn={attention?.iconIn}
                iconOut={attention?.iconOut}
                disabled={attention?.disabled}
              />
            )}
          </S.Wrapper>
        )}
      </>
    </S.Container>
  );
};
