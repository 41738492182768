import { isValid } from 'date-fns';

export const valueType = (type, value) => {
  if (!value) return value;

  switch (type) {
    case 'date':
      return getInfos(type, value);
    case 'date-hour':
      return getInfos(type, value);
    default:
      return value;
  }
};

const getInfos = (type, value) => {
  const date = new Date(value);

  if (!isValid(date)) return value;

  const day = date.getDate()?.toString();
  const month = (date.getMonth() + 1).toString();
  const year = date.getFullYear();
  const hour = date.getHours().toString()?.padStart(2, '0');
  const minute = date.getMinutes()?.toString()?.padStart(2, '0');

  const single = `${day?.padStart(2, '0')}/${month?.padStart(2, '0')}/${year}`;

  if (type === 'date') return single;
  if (type === 'date-hour') return `${single} ${hour}:${minute}`;
};
