import api from 'services/api';

export const saveEmpresa = async data => {
  const res = await api.post('/company/create', data);
  return res.data ?? res;
};

export const saveDistribuidora = async data => {
  const res = await api.post('/distribuidora', data);
  return res.data ?? res;
};

export const getPlanos = async () => {
  try {
    const { data } = await api.get('/planos');
    return data.data;
  } catch (error) {
    return [];
  }
};

export const getPlano = async id => {
  if (!id) return null;
  try {
    const { data } = await api.get(`/planos/${id}`);
    return data.data;
  } catch (error) {
    return null;
  }
};

export const getCombinacao = async id => {
  if (!id) return null;
  try {
    const { data } = await api.get(`/planos/combinados/${id}`);
    return data.data;
  } catch (error) {
    return {};
  }
};

export const getCombinacoes = async () => {
  try {
    const { data } = await api.get('/planos/combinados');
    return data.data;
  } catch (error) {
    return [];
  }
};

export const getTelemetrias = async () => {
  try {
    const { data } = await api.get('/telemetrias');
    return data.data.map(t => ({ name: t.nome, value: t.nome, id: t.id }));
  } catch (error) {
    return [];
  }
};

export const getTecnologias = async () => {
  try {
    const { data } = await api.get('/tecnologias_monitoramento');
    return data.data.map(t => ({ name: t.nome, value: t.nome, id: t.id }));
  } catch (error) {
    return [];
  }
};

export const getAllTecnologias = async () => {
  try {
    const { data } = await api.get('/tecnologias/todas');
    return data.data.map(t => ({
      name: t.nome,
      value: t.nome,
      id: t.id,
      tipo: t.tipo,
    }));
  } catch (error) {
    return [];
  }
};

export const getFaixas = async () => {
  try {
    const { data } = await api.get('/faixas');
    return data.data.map(f => ({
      ...f,
      name: `${f.min} - ${f.max} motoristas`,
      value: f.id,
    }));
  } catch (error) {
    return [];
  }
};

export const getDeviceStatus = async id => {
  try {
    const { data } = await api.get(`/find-status-devices/${id}`);
    return data.data;
  } catch (error) {
    return [];
  }
};
