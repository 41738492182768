/* eslint-disable import/order */

// Styles
import * as S from './styled';

// Components MUI
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

// Context
import { useTable } from '../../../context';
import { _setSelected } from '../../../context/actions';

export const CheckBox = ({ id, all, rows = [], setSelectedRows }) => {
  const { state, dispatch } = useTable();
  const { selected } = state;

  const handleCheck = () => {
    let newSelection = [];
    if (!all) {
      if (selected.includes(id)) {
        newSelection = [...selected.filter(item => item !== id)];
      } else {
        newSelection = [...selected, id];
      }
    } else {
      if (selected.length) {
        newSelection = [];
      } else {
        newSelection = rows;
      }
    }

    setSelectedRows(newSelection);
    dispatch(_setSelected(newSelection));
  };

  return (
    <S.Container
      checked={all ? selected?.length && !!rows.length : selected?.includes(id)}
      onClick={handleCheck}
      data-testid="checkbox"
    >
      <S.Input
        type="checkbox"
        readOnly
        checked={
          all
            ? selected?.length &&
              !!rows.length &&
              rows.every(item => selected.includes(item))
            : selected?.includes(id)
        }
      />
      {all &&
        !rows.every(item => selected.includes(item)) &&
        selected.length !== 0 && <RemoveRoundedIcon data-testid="remove" />}

      {(!all ||
        (!!rows.length && rows.every(item => selected.includes(item)))) && (
        <CheckRoundedIcon data-testid="check" />
      )}
    </S.Container>
  );
};
