import * as S from './styled';
import { formatNewDate, formatTime } from '../../utils/dates';

const howToColor = (criticality, theme) => {
  switch (criticality) {
    case 'MODERADA':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'GRAVE':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    case 'GRAVÍSSIMA':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    default:
      return theme?.palette?.semantics?.feedback?.information?.natural;
  }
};

const getStatusColor = (status, theme) => {
  switch (status) {
    case 'PENDENTE':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'FINALIZADO':
      return theme?.palette?.semantics?.feedback?.success?.natural;
    case 'DELETADO':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
  }
};

const emptyState = {
  title: 'Ops! Você não tem nenhuma violação disponível.',
  subtitle: 'Verifique os filtros aplicados!',
};

// Desvio de descarga só tem createdAt para analizar data de validade
const getExpirationDate = row => {
  const newDate = new Date(row.createdAt);
  newDate.setDate(newDate.getDate() + 10);
  return formatNewDate(newDate);
};

const infoMessage = 'Violações';

const statusTabs = [
  { value: 'PENDENTE', label: 'Pendentes' },
  { value: 'FINALIZADO', label: 'Finalizados' },
  { value: 'DELETADO', label: 'Deletados' },
];

const columns = [
  {
    Header: 'ID',
    id: 'id',
    accessor: d => (d.id ? String(d.id) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <S.TableName>{row.original.id}</S.TableName>,
  },
  {
    Header: 'Motorista/Filial',
    id: 'motorista.nome',
    accessor: d => (d.motorista?.nome ? String(d.motorista.nome) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => (
      <S.TableTwoInputs>
        <S.TableName>{row.original.motorista?.nome.toLowerCase()}</S.TableName>
        <S.TableSubname>
          Filial: <strong>{row.original.motorista?.filial?.nome}</strong>
        </S.TableSubname>
      </S.TableTwoInputs>
    ),
  },
  {
    Header: 'Placa',
    id: 'caminhao.placa',
    accessor: d => (d.caminhao ? String(d.caminhao.placa) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => (
      <S.TableName>{row.original.caminhao?.placa}</S.TableName>
    ),
  },
  {
    Header: 'Violação',
    id: 'tipo.descricao',
    accessor: d => (d.tipo?.descricao ? String(d.tipo.descricao) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => (
      <S.TableName>{row.original.tipo?.descricao}</S.TableName>
    ),
  },
  {
    Header: 'Data',
    id: 'data_violacao',
    accessor: d => (d.data_violacao ? String(d.data_violacao) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => {
      return (
        row.original.data_violacao && (
          <S.TableTwoInputs>
            <S.TableName>
              {formatNewDate(row.original.data_violacao)}
            </S.TableName>
            <S.TableSubname>
              Horário: {formatTime(row.original.data_violacao)}
            </S.TableSubname>
          </S.TableTwoInputs>
        )
      );
    },
  },
  {
    Header: 'Criticidade',
    id: 'tipo.criticidade',
    accessor: d => (d.tipo?.criticidade ? String(d.tipo.criticidade) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => {
      return (
        <S.Criticidade color={howToColor(row.original.tipo?.criticidade)}>
          <p>{row.original.tipo?.criticidade.toLowerCase()}</p>
        </S.Criticidade>
      );
    },
  },
  {
    Header: 'Disponibilizado em',
    id: 'createdAt',
    accessor: d => (d.createdAt ? String(d.createdAt) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => (
      <S.TableTwoInputs>
        <S.TableName>{formatNewDate(row.original.createdAt)}</S.TableName>
        <S.TableSubname>
          Vencimento: {getExpirationDate(row.original)}
        </S.TableSubname>
      </S.TableTwoInputs>
    ),
  },
  {
    Header: 'Responsável',
    id: 'responsavel.nome',
    accessor: d => (d.responsavel?.nome ? String(d.responsavel.nome) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => (
      <S.TableName>{row.original.responsavel?.nome}</S.TableName>
    ),
  },

  {
    Header: 'Ações',
    id: 'acoes',
    disableSortBy: true,
    accessor: d => (d.acoes ? String(d.acoes) : '-'),
    show: true,
  },
];

const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo de violação',
    value: 'tipo.descricao',
    selected: true,
    default: true,
  },
  {
    label: 'Criticidade',
    value: 'tipo.criticidade',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'tipo.peso',
    selected: false,
    default: false,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Data da violação',
    value: 'data_violacao',
    selected: true,
    default: true,
  },
  {
    label: 'Disponibilizado em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
  {
    label: 'Previsão de conclusão',
    value: 'data_previsao',
    selected: false,
    default: false,
  },
  {
    label: 'Data conclusão',
    value: 'data_conclusao',
    selected: true,
    default: true,
  },
  {
    label: 'Data exclusão',
    value: 'data_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Justificativa exclusão',
    value: 'motivo_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Motorista',
    value: 'motorista.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'motorista.filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Veículo',
    value: 'caminhao.placa',
    selected: true,
    default: true,
  },
  {
    label: 'Link da imagem',
    value: 'img_ocorrrencia',
    selected: false,
    default: false,
  },
  {
    label: 'Plano de ação',
    value: 'plano_de_acao',
    selected: true,
    default: true,
  },
  {
    label: 'Observação',
    value: 'observacao',
    selected: false,
    default: false,
  },
  {
    label: 'Responsável',
    value: 'responsavel.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Criador',
    value: 'criador.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Cometário motorista',
    value: 'comentario_motorista',
    selected: false,
    default: false,
  },
  {
    label: 'Latitude',
    value: 'latitude',
    selected: false,
    default: false,
  },
  {
    label: 'Longitude',
    value: 'longitude',
    selected: false,
    default: false,
  },
];

const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};

export {
  emptyState,
  howToColor,
  getStatusColor,
  infoMessage,
  statusTabs,
  columns,
  fields,
  resetExcelFields,
};
