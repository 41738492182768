import React from 'react';

import * as S from './styled';

export const ChipSelect = ({
  value,
  disabled = false,
  options = [],
  label,
  onChange,
  justify = 'end',
}) => {
  return (
    <S.Container justify={justify}>
      <S.Label>{label}</S.Label>
      <S.ListContainer>
        {options.map(option => (
          <S.Chip
            key={option.value}
            onClick={() => !disabled && onChange(option.value)}
            active={disabled || option.value === value}
          >
            {option.label}
          </S.Chip>
        ))}
      </S.ListContainer>
    </S.Container>
  );
};
