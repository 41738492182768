import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, ...props }) => css`
    background-color: #fff;
    height: 170px;
    flex: 1;
    border-radius: 5px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: all ease 0.3s;
    border: 2px solid
      ${props.counter
        ? theme.palette.brand.secondary.natural
        : theme.palette.words.text.light};

    &:hover {
      background-color: rgba(245, 245, 245, 10);
      transform: scale(1.05);
    }

    &:hover .footer {
      opacity: 1;
    }

    .header {
      display: flex;
      width: 100%;
      height: 20vh;
      align-items: center;
      justify-content: flex-end;
    }

    .typeCard {
      color: ${props.counter
        ? theme.palette.brand.secondary.natural
        : theme.palette.words.text.light};
      font-weight: 600;
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 60vh;
      align-items: center;
      justify-content: center;
    }

    .footer {
      display: flex;
      height: 20vh;
      width: 100%;
      padding: 5px;
      align-items: center;
      justify-content: flex-end;
      opacity: 0;
    }
  `}
`;

export const Counter = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    width: 22px;
    height: 22px;
    background: ${props.counter > 0
      ? theme.palette.brand.secondary.natural
      : theme.palette.words.text.light};
    margin: 8px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
  `}
`;
