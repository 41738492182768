import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';

// Utils
import { formatNewDate } from 'utils/dates';

// Components
import { OcorrenciasCards } from 'components/Cards/OcurrenceCard';
import { DesviosCards } from 'components/Cards/InfractionCard';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

import * as S from './styled';

export const ExportProfilePdf = ({
  driver,
  driverRanking,
  graphsDirecao,
  graphsDescarga,
  graphsPad,
  printRef,
  period,
}) => {
  // Calcular idade do motor:
  const calculateAge = dob => {
    const diff_ms = Date.now() - dob.getTime();
    const age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  // ---------------------------- Chegagens GRÁFICOS DIREÇÃO -------------------------------

  // Checar existência e algumas edições dos gráficos de direção
  let hasPtsHrDirecao = true;
  let maxValuePtsHrDirecao = 0;
  if (graphsDirecao.ptsHr) {
    let zeroValue = 0;
    for (let p of graphsDirecao.ptsHr) {
      p.x = p.x.substring(0, 3);
      if (parseFloat(p.motorista) > maxValuePtsHrDirecao)
        maxValuePtsHrDirecao = parseFloat(p.motorista);
      if (parseFloat(p.empresa) > maxValuePtsHrDirecao)
        maxValuePtsHrDirecao = parseFloat(p.empresa);
      if (p.motorista === 0) zeroValue += 1;
    }
    if (zeroValue === 12) hasPtsHrDirecao = false;
  }

  // Checar se há gráfico e se existe algum texto muito longo para o gráfico de barras
  let hasRadarTiposDirecao = true;
  if (graphsDirecao.radarTipos) {
    if (graphsDirecao.radarTipos.length === 0) hasRadarTiposDirecao = false;
  }

  if (hasRadarTiposDirecao) {
    for (let bar of graphsDirecao.radarTipos) {
      if (bar.name.length > 14) {
        bar.name = `${bar.name.substring(0, 14)}...`;
      }
    }
  }

  // ---------------------------- Chegagens GRÁFICOS DESCARGA -------------------------------

  // Checar existência e algumas edições do gráfico de descarga
  let hasPtsDescarga = true;
  let maxValuePtsDescarga = 0;
  if (graphsDescarga.ptsDescarga) {
    let zeroValue = 0;
    for (let p of graphsDescarga.ptsDescarga) {
      p.x = p.x.substring(0, 3);
      if (parseFloat(p.motorista) > maxValuePtsDescarga)
        maxValuePtsDescarga = parseFloat(p.motorista);
      if (parseFloat(p.empresa) > maxValuePtsDescarga)
        maxValuePtsDescarga = parseFloat(p.empresa);
      if (p.motorista === 0) zeroValue += 1;
    }
    if (zeroValue === 12) hasPtsDescarga = false;
  }

  // Checar se há gráfico e se existe algum texto muito longo para o gráfico de barras
  let hasRadarTiposDescarga = true;
  if (graphsDescarga.radarTipos) {
    if (graphsDescarga.radarTipos.length === 0) hasRadarTiposDescarga = false;
  }

  if (hasRadarTiposDescarga) {
    for (let bar of graphsDescarga.radarTipos) {
      if (bar.name.length > 14) {
        bar.name = `${bar.name.substring(0, 14)}...`;
      }
    }
  }

  // ---------------------------- Chegagens GRÁFICOS OCORRÊNCIAS (PAD) -------------------------------

  // Checar existência e algumas edições do gráfico de PAD
  let hasPtsPad = true;
  let maxValuePad = 0;
  if (graphsPad.dataPad) {
    let zeroValue = 0;
    for (let p of graphsPad.dataPad) {
      p.x = p.x.substring(0, 3);
      p.motorista = +p.motorista;
      if (+p.motorista > maxValuePad) maxValuePad = +p.motorista;
      if (p.motorista === 0) zeroValue += 1;
    }
    if (zeroValue === 12) hasPtsPad = false;
  }

  // Checar se há gráfico e se existe algum texto muito longo para o gráfico de barras
  let hasRadarPad = true;
  if (graphsPad.radarTipos) {
    if (graphsPad.radarTipos.length === 0) hasRadarPad = false;
  }

  if (hasRadarPad) {
    for (let bar of graphsPad.radarTipos) {
      if (bar.name.length > 14) {
        bar.name = `${bar.name.substring(0, 14)}...`;
      }
    }
  }

  const logo = require('images/onisys.png');
  const capa = require(`images/defaultFormulario.jpg`);

  const { user } = useSelector(state => state.auth.user);
  const companyLogo = user?.filial?.empresa?.logo_url;

  // -------------------------------------------------Retorno de cada gráfico--------------------------------------------------//

  const ptsHrDirecao = () => {
    const graph = (
      <>
        <S.Header2>
          <h2>Histórico Pontos/Hr</h2>
        </S.Header2>
        <LineChart
          width={730}
          height={250}
          data={graphsDirecao.ptsHr}
          margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="x" />
          <YAxis
            domain={[
              0,
              Math.ceil(maxValuePtsHrDirecao) +
                Math.ceil(maxValuePtsHrDirecao * 0.2),
            ]}
          />
          {/* <Tooltip /> */}
          <Legend height={10} />
          <Line
            type="monotone"
            dataKey="empresa"
            stroke="#8884d8"
            isAnimationActive={false}
          >
            <LabelList dataKey="empresa" position="top" stroke="#8884d8" />
          </Line>
          <Line
            type="monotone"
            dataKey="motorista"
            stroke="#82ca9d"
            isAnimationActive={false}
          >
            <LabelList dataKey="motorista" position="top" stroke="#82ca9d" />
          </Line>
        </LineChart>
      </>
    );
    return graph;
  };

  const infractionTypeDirecao = () => {
    const graph = (
      <>
        <S.Header2>
          <h2>Radar tipos de desvios</h2>
        </S.Header2>
        <BarChart
          width={730}
          height={250}
          data={graphsDirecao.radarTipos}
          margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" interval={0} />
          <YAxis domain={[0, 'dataMax + 1']} />
          {/* <Tooltip /> */}
          {/* <Legend /> */}
          <Bar
            type="monotone"
            dataKey="value"
            fill="#82ca9d"
            isAnimationActive={false}
          >
            <LabelList dataKey="value" position="top" stroke="#82ca9d" />
          </Bar>
        </BarChart>
      </>
    );

    return graph;
  };

  const ptsDescarga = () => {
    const graph = (
      <>
        <S.Header2>
          <h2>Histórico Pontos/Descarga</h2>
        </S.Header2>
        <LineChart
          width={730}
          height={250}
          data={graphsDescarga.ptsDescarga}
          margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="x" />
          <YAxis
            domain={[
              0,
              Math.ceil(maxValuePtsDescarga) +
                Math.ceil(maxValuePtsDescarga * 0.2),
            ]}
          />
          {/* <Tooltip /> */}
          <Legend height={10} />
          <Line
            type="monotone"
            dataKey="empresa"
            stroke="#8884d8"
            isAnimationActive={false}
          >
            <LabelList dataKey="empresa" position="top" stroke="#8884d8" />
          </Line>
          <Line
            type="monotone"
            dataKey="motorista"
            stroke="#82ca9d"
            isAnimationActive={false}
          >
            <LabelList dataKey="motorista" position="top" stroke="#82ca9d" />
          </Line>
        </LineChart>
      </>
    );

    return graph;
  };

  const infractionTypeDescarga = () => {
    const graph = (
      <>
        <S.Header2>
          <h2>Radar tipos de desvios</h2>
        </S.Header2>
        <BarChart
          width={730}
          height={250}
          data={graphsDescarga.radarTipos}
          margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 'dataMax + 1']} />
          {/* <Tooltip /> */}
          {/* <Legend /> */}
          <Bar
            type="monotone"
            dataKey="value"
            fill="#82ca9d"
            isAnimationActive={false}
          >
            <LabelList dataKey="value" position="top" stroke="#82ca9d" />
          </Bar>
        </BarChart>
      </>
    );

    return graph;
  };

  const ptsPad = () => {
    const graph = (
      <>
        <S.Header2>
          <h2>Histórico nº de ocorrências</h2>
        </S.Header2>
        <LineChart
          width={730}
          height={250}
          data={graphsPad.dataPad}
          margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="x" />
          <YAxis
            domain={[0, Math.ceil(maxValuePad) + Math.ceil(maxValuePad * 0.2)]}
            allowDecimals={false}
          />
          {/* <Tooltip /> */}
          <Legend height={10} />
          <Line
            type="monotone"
            dataKey="motorista"
            stroke="#82ca9d"
            isAnimationActive={false}
          >
            <LabelList dataKey="motorista" position="top" stroke="#82ca9d" />
          </Line>
        </LineChart>
      </>
    );

    return graph;
  };

  const infractionTypePad = () => {
    const graph = (
      <>
        <S.Header2>
          <h2>Radar tipos de ocorrências</h2>
        </S.Header2>
        <BarChart
          width={730}
          height={250}
          data={graphsPad.radarTipos}
          margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 'dataMax + 1']} />
          {/* <Tooltip /> */}
          {/* <Legend /> */}
          <Bar
            type="monotone"
            dataKey="value"
            fill="#82ca9d"
            isAnimationActive={false}
          >
            <LabelList dataKey="value" position="top" stroke="#82ca9d" />
          </Bar>
        </BarChart>
      </>
    );

    return graph;
  };

  return (
    <S.ModalMUI open={false} onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="logoArea">
            <img src={logo} />
          </div>
          <div className="formIdent">
            <span>Perfil do motorista {driver.driver.motorista?.nome}</span>
            {period && (
              <span>
                Período: {formatNewDate(period[0])} a {formatNewDate(period[1])}{' '}
              </span>
            )}
          </div>
        </S.Infos>
        <S.Header bg={companyLogo || logo}>
          <div className="topInfos">
            <div className="coverArea">
              <div className="background" />
            </div>
            <div className="textsArea">
              <div className="titleForm">
                Perfil do motorista {driver.driver.motorista?.nome}
              </div>
              <div className="descriptionForm">
                Período: {formatNewDate(period[0])} a {formatNewDate(period[1])}
              </div>
              {driver.driver.motorista.data_de_admissao && (
                <div className="descriptionForm">
                  Data de admissão:{' '}
                  {formatNewDate(driver.driver.motorista.data_de_admissao)}
                </div>
              )}
              {driver.driver.motorista.data_de_nascimento && (
                <div className="descriptionForm">
                  Idade:{' '}
                  {calculateAge(
                    new Date(driver.driver.motorista.data_de_nascimento),
                  )}{' '}
                  anos
                </div>
              )}
            </div>
          </div>
          <Divider />
          {driverRanking && (
            <div className="bottomInfos">
              <div className="card">
                <div className="cardInfos">
                  <div className="title">Posição no ranking geral:</div>
                  <div className="numbers">{driverRanking?.position || 1}</div>
                </div>
              </div>
              <div className="bar" />
              <div className="card">
                <div className="cardInfos">
                  <div className="title">Horas avaliadas:</div>
                  <div className="numbers">{driverRanking?.horas || 0}</div>
                </div>
              </div>
              <div className="bar" />
              <div className="card">
                <div className="cardInfos">
                  <div className="title">Pontos por hora:</div>
                  <div className="numbers">
                    {driverRanking?.pontos_por_hora || 0}
                  </div>
                </div>
              </div>
              <div className="bar" />
              <div className="card">
                <div className="cardInfos">
                  <div className="title">Pontos totais:</div>
                  <div className="numbers">
                    {driverRanking?.pontos_totais || 0}
                  </div>
                </div>
              </div>
            </div>
          )}
        </S.Header>

        {/* Seção de desvios */}
        {driver.driverInfractions && driver.driverInfractions.length > 0 && (
          <>
            <S.CardHeader>
              <h1>Desvios direção</h1>
            </S.CardHeader>

            <DesviosCards
              desvios={driver.driverInfractions}
              driver={driver.driver.motorista}
            />
          </>
        )}

        {/* Seção gráficos direção */}
        <>
          {(hasPtsHrDirecao || hasRadarTiposDirecao) && (
            <S.CardHeader>
              <h1>Gráficos de direção</h1>
            </S.CardHeader>
          )}
          {hasPtsHrDirecao && ptsHrDirecao()}
          {hasRadarTiposDirecao && infractionTypeDirecao()}
        </>

        {/* Seção de desvios de direção */}
        {driver.driverUnloadingfractions &&
          driver.driverUnloadingfractions.length > 0 && (
            <>
              <S.CardHeader>
                <h1>Desvios descarga</h1>
              </S.CardHeader>

              <DesviosCards
                desvios={driver.driverUnloadingfractions}
                driver={driver.driver.motorista}
              />
            </>
          )}

        {/* Seção gráficos descarga */}
        <>
          {(hasPtsDescarga || hasRadarTiposDescarga) && (
            <S.CardHeader>
              <h1>Gráficos de descargas</h1>
            </S.CardHeader>
          )}
          {hasPtsDescarga && ptsDescarga()}
          {hasRadarTiposDescarga && infractionTypeDescarga()}
        </>

        {/* Seção de ocorrências PAD */}
        {driver.padInfractions && driver.padInfractions.length > 0 && (
          <>
            <S.CardHeader>
              <h1>Ocorrências (PAD)</h1>
            </S.CardHeader>

            <OcorrenciasCards
              ocorrencias={driver.padInfractions}
              driver={driver.driver.motorista}
            />
          </>
        )}

        {/* Seção gráficos PAD */}
        <>
          {(hasPtsPad || hasRadarPad) && (
            <S.CardHeader>
              <h1>Graficos PAD</h1>
            </S.CardHeader>
          )}
          {hasPtsPad && ptsPad()}
          {hasRadarPad && infractionTypePad()}
        </>
      </S.Container>
    </S.ModalMUI>
  );
};
