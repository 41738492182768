import api from 'services/api';

const getTransportadoras = async query => {
  const res = await api.get('/provider/training/transportadoras', {
    params: query,
  });
  return res;
};

const createTurmaTransportadora = async data => {
  const res = await api.post(
    '/provider/training/create-turma-transportadora',
    data,
  );
  return res;
};

// inativa motorista
const inativateTransportadora = async data => {
  const res = await api.put(
    `/provider/training/update-status-transportadora`,
    data,
  );
  return res;
};

export {
  getTransportadoras,
  createTurmaTransportadora,
  inativateTransportadora,
};
