export const defaultTheme = {
  palette: {
    brand: {
      primary: {
        light: '#F6C9CB',
        natural: '#F14F4F',
        dark: '#C03F3F',
        background: '#FDF9F9',
      },
      secondary: {
        light: '#8AB8F8',
        natural: '#0C5F94',
        dark: '#0F77BA',
        background: '#EDF2F9',
      },
    },
    semantics: {
      feedback: {
        attention: {
          light: '#F6C9CB',
          natural: '#EB5757', // theme.palette.semantics.feedback.attention.natural
          dark: '#DF2B34',
        },
        warning: {
          light: '#ffde9e',
          natural: '#FFA801', // theme.palette.semantics.feedback.warning.natural
          dark: '#E3B40B',
        },
        success: {
          light: '#DBF3E5',
          natural: '#17A841', // theme.palette.semantics.feedback.success.natural
          dark: '#219653',
        },
        information: {
          light: '#DBE9F3',
          natural: '#2F80ED', // theme.palette.semantics.feedback.information.natural
          dark: '#0c12f2',
        },
        unknown: {
          light: '#F2F2F2', // theme.palette.semantics.feedback.unknown.light
          natural: '#BDBDBD', // theme.palette.semantics.feedback.unknown.natural
          dark: '#4F4F4F',
        },
      },
    },
    words: {
      text: {
        light: '#C3C3C3',
        natural: '#4F4F4F', // theme.palette.words.text.natural
        dark: '#000000',
        contrast: '#FFFFFF',
      },
      title: {
        light: '#C3C3C3',
        natural: '#4F4F4F', // theme.palette.words.title.natural
        dark: '#000000',
        contrast: '#FFFFFF',
      },
      subtitle: {
        light: '#C3C3C3',
        natural: '#4F4F4F', // theme.palette.words.subtitle.natural
        dark: '#000000',
        contrast: '#FFFFFF',
      },
      caption: {
        light: '#C3C3C3',
        natural: '#4F4F4F', // theme.palette.words.caption.natural
        dark: '#000000',
        contrast: '#FFFFFF',
      },
      label: {
        light: '#C3C3C3',
        natural: '#4F4F4F', // theme.palette.words.label.natural
        dark: '#000000',
        contrast: '#FFFFFF',
      },
      button: {
        light: '#C3C3C3',
        natural: '#4F4F4F', // theme.palette.words.button.natural
        dark: '#000000',
        contrast: '#FFFFFF',
      },
      link: {
        light: '#C3C3C3',
        natural: '#4F4F4F', // theme.palette.words.link.natural
        dark: '#000000',
        contrast: '#FFFFFF',
      },
      placeholder: {
        light: '#C3C3C3',
        natural: '#4F4F4F', // theme.palette.words.placeholder.natural
        dark: '#000000',
        contrast: '#FFFFFF',
      },
      chart: {
        natural: '#666',
      },
      disabled: {
        light: '#C3C3C3',
        natural: '#4F4F4F', // theme.palette.words.disabled.natural
        dark: '#000000',
        contrast: '#FFFFFF',
      },
    },
    system: {
      transparent: 'transparent', // theme.palette.system.transparent
      background: 'transparent', // theme.palette.system.background
      border: '#EDF2F9', // theme.palette.system.border
      overlay: '#FFFFFF', // theme.palette.system.overlay
      highlight: '#FFFFFF', // theme.palette.system.highlight
      disabled: '#F2F2F2', // theme.palette.system.disabled
      divider: '#E0E0E0', // theme.palette.system.divider
      scrollbar: '#707070', // theme.palette.system.scrollbar
      backdrop: '#22222222', // theme.palette.system.backdrop
      shadow: '#00000028', // theme.palette.system.shadow
      white: '#FFF',
      black: '#000000',
    },
  },
};
