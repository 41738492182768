import { useEffect, useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ReactPlayer from 'react-player';
import * as S from './styled';

export const ModalPlayer = ({ item, open, handleClose, mode, execute }) => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setAnimation(true);
        item?.tipo === 'IMAGEM' && execute(item);
      }, 100);
    }
  }, [open]);

  const closeModal = () => {
    setAnimation(false);
    setTimeout(() => {
      handleClose();
    }, 200);
  };

  return (
    <S.ModalMUI open={open}>
      <>
        <S.CloseArea>
          <CloseRoundedIcon onClick={closeModal} />
        </S.CloseArea>
        <S.Container animation={animation} mode={mode}>
          {item?.link && (
            <S.Midia>
              <>
                {item?.tipo === 'VIDEO' && (
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    controls
                    playing={false}
                    url={item?.link}
                    light={item?.imagem_capa}
                    onEnded={() => execute && execute(item)}
                  />
                )}
                {item?.tipo === 'IMAGEM' && <img src={item?.link} />}
              </>
            </S.Midia>
          )}
        </S.Container>
      </>
    </S.ModalMUI>
  );
};
