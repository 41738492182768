import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
`;

export const SpacedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Footer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: end;
  gap: 1rem;
`;

export const LocationTitle = styled.div`
  margin-bottom: 1rem;

  h2 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 900;
    color: ${({ theme }) => theme.palette.words.subtitle.natural};
  }
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.words.subtitle.natural};
  }
`;

export const LocationContainer = styled.div`
  padding: 2rem 1rem;
  margin: 2rem 0;
  background-color: white;
  border: 2px solid #95aac9;
  border-radius: 4px;
`;
