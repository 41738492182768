import { Grid } from '@mui/material';
import { formatNewHour } from 'utils/dates';
import { firestorage } from 'utils/firebase';
import { useCallback } from 'react';
import MapDesvio from 'pages/Desvios/components/MapDesvio';

// Columns

import * as S from './styled';

export const ExportToPdf = ({ multa, printRef }) => {
  const logo = require('images/onisys.png');

  const getFileName = useCallback(fileUrl => {
    try {
      let newName = '-';
      if (fileUrl) newName = firestorage.refFromURL(fileUrl)?.name;
      return newName;
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <S.ModalMUI onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="formIdent">
            <img src={logo} />
          </div>

          <div className="formIdent">
            <div className="titleForm">
              <span>Motorista: {multa?.motorista?.nome}</span>
            </div>
            <div className="titleForm">
              <span>Multa: {multa?.id}</span>
            </div>
          </div>
        </S.Infos>
        <S.CardHeader>
          <h1>Informações da multa</h1>
        </S.CardHeader>
        <S.Header>
          <div className="topInfos">
            <div className="coverArea">
              {multa?.latitude && (
                <MapDesvio
                  position={{
                    lat: parseFloat(multa.latitude),
                    lng: parseFloat(multa.longitude),
                  }}
                />
              )}
            </div>
            <div className="textsArea">
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Motorista:</div>
                  <S.InputToPDF>{multa?.motorista?.nome}</S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Filial:</div>
                  <S.InputToPDF>{multa?.motorista?.filial.nome}</S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Tipo de multa:</div>
                  <S.InputToPDF>{multa.multa_tipo?.titulo ?? '-'}</S.InputToPDF>
                </Grid>
                <Grid item xs={5}>
                  <div className="normalText">Valor da multa:</div>
                  <S.InputToPDF>
                    {`R$ ${multa.multa_tipo?.valor.toFixed(2)}`}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={2}>
                  <div className="normalText">Pontos:</div>
                  <S.InputToPDF>
                    {(multa?.multa_tipo && multa.multa_tipo?.pontos) || '-'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={5}>
                  <div className="normalText">Data da infração:</div>
                  <S.InputToPDF>
                    {(multa?.data_multa &&
                      formatNewHour(multa?.data_multa, true)) ||
                      '-'}
                  </S.InputToPDF>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="normalText">Agregado:</div>
                  <S.InputToPDF>{`${
                    multa.motorista?.agregado ? 'Sim' : 'Não'
                  }`}</S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Placa:</div>
                  <S.InputToPDF>{multa.caminhao?.placa ?? '-'}</S.InputToPDF>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="normalText">Localização:</div>
                  <S.InputToPDF>{` ${
                    multa?.endereco
                      ? `${multa.endereco}${
                          multa.km ? `, Km ${multa.km}` : ''
                        }${multa.cep ? `, CEP: ${multa.cep}` : ''}`
                      : '-'
                  }`}</S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="normalText">Latitude:</div>
                  <S.InputToPDF>{` ${multa?.latitude || '-'}`}</S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Longitude:</div>
                  <S.InputToPDF>{multa?.longitude || '-'}</S.InputToPDF>
                </Grid>
              </Grid>
            </div>
          </div>
        </S.Header>
        <S.CardHeader>
          <h1>Informações Complementares</h1>
        </S.CardHeader>
        <S.Header>
          <div className="bottomInfos">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <div className="normalText">Observações:</div>
                <S.InputToPDF lines={4}>{multa.observacao || '-'}</S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="normalText">Arquivo:</div>
                <S.InputToPDF>{getFileName(multa?.arquivo)}</S.InputToPDF>
              </Grid>
            </Grid>
          </div>
        </S.Header>
      </S.Container>
    </S.ModalMUI>
  );
};
