import styled, { css } from 'styled-components';

import { Modal } from '@mui/material';

export const BigModal = styled(Modal)`
  transition: all ease 0.3s;
  margin-top: ${props => props.visible};
`;

export const Cover = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background-color: ${theme.palette.system.highlight};
    position: relative;
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 10px ${theme.palette.system.border};
    padding: 0px 20px;
    z-index: 9999;
    background-color: ${theme.palette.system.overlay};

    .rigthArea {
      display: flex;
      align-items: center;
      z-index: 2;
    }
  `}
`;

export const Footer = styled.footer`
  ${({ theme, footer }) => css`
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
    z-index: 2;
    box-shadow: 0px 0px 10px ${theme.palette.system.border};
    z-index: 9999;
    opacity: ${footer ? 1 : 0};

    .statusLine {
      margin-left: 120px;
      font-weight: 700;
      font-size: 16px;
    }
  `}
`;

export const Body = styled.div`
  padding-top: 70px;
  padding-bottom: 60px;
  height: 100%;
  display: flex;
  z-index: 1;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.brand.primary.background};
    width: 100%;
    padding: 20px 180px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  `}
`;
