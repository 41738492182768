// React
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { auth } from 'utils/firebase';

// Redux
import { useDispatch } from 'react-redux';
import { updateNumNotification } from 'store/modules/header/actions';

// Utils
import api from 'services/api';

export const requestNotifications = async () => {
  const res = await api.get(`/notificacoes/push`);
  return res.data;
};

const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission();
  return permission;
};

const triggerBackgroundSync = () => {
  if ('serviceWorker' in navigator && 'SyncManager' in window) {
    navigator.serviceWorker.ready
      .then(registration => {
        return registration.sync.register('background-sync');
      })
      .catch(error => {
        console.error('Erro ao registrar o Background Sync:', error);
      });
  }
};

const isServiceWorkerRegistered = () => {
  return (
    'serviceWorker' in navigator && navigator.serviceWorker.controller !== null
  );
};

const sendMessageToServiceWorker = async message => {
  const registration = await navigator.serviceWorker.ready;
  if (registration.active) {
    registration.active.postMessage(message);
  }
};

const handleReadNotification = async selectedId => {
  const res = await api.post(`/notificacoes/view`, { ids: selectedId });
  return res;
};

export const NotificationsPush = () => {
  const dispatch = useDispatch();

  const fetchNotifications = async () => {
    const permissionGranted = await requestNotificationPermission();
    const token = auth.currentUser && (await auth.currentUser.getIdToken());
    const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3333';

    await sendMessageToServiceWorker({ token, baseURL });
    if (permissionGranted && !isServiceWorkerRegistered()) {
      triggerBackgroundSync();
    }
    const notifications = await requestNotifications();

    const isOpenPush = notifications?.data?.rows.filter(
      item => item.visualizado === false,
    );

    dispatch(
      updateNumNotification({
        totalNotifications: isOpenPush.length || 0,
        openPush: isOpenPush,
        allIds: notifications?.data?.allIds,
      }),
    );
  };

  useEffect(() => {
    triggerBackgroundSync();
  }, []);

  useQuery(['push-notifications', 1], () => fetchNotifications(), {
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    refetchInterval: 1000 * 60 * 1,
  });

  return <></>;
};
