// Styles
import { useEffect } from 'react';
import * as S from './styled';

// Context
import { useTable } from '../../../context';
import { _setPageSize } from '../../../context/actions';

export const Counter = ({
  fixedValue = 20,
  sizes = [10, 20, 30],
  disabled,
  loading = false,
}) => {
  const { state, dispatch } = useTable();
  const { pageSize, count, pageIndex } = state;
  const inicial = count ? pageSize * pageIndex + 1 : 0;
  let final = pageSize * pageIndex + pageSize;
  if (final > count) final = count;

  useEffect(
    () =>
      !fixedValue &&
      !sizes.includes(pageSize) &&
      dispatch(_setPageSize(sizes[0])),
    [sizes],
  );

  const handleChange = value => {
    dispatch(_setPageSize(value));
  };

  return (
    <S.Container disabled={disabled} id="counter-table-component">
      {!fixedValue && (
        <>
          <label className="labelElement">
            <select
              data-testid="select"
              value={pageSize}
              onChange={e => handleChange(e.target.value)}
              className="select"
              disabled={disabled}
            >
              {sizes.map((size, index) => (
                <option key={index} value={size} data-testid="select-option">
                  {size} p/pág.
                </option>
              ))}
            </select>
          </label>
        </>
      )}
      <p className="view">
        Exibindo {count ? `${inicial}-` : ''}
        {final} de {!loading && count}
      </p>
      {loading && <div className="loading">. . .</div>}
    </S.Container>
  );
};
