import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  padding: 10px 20px;
  border-bottom: ${props =>
    props.noBorder ? 'none' : `1px solid ${colors.greyTiny}`};
  opacity: ${props => props.opacity};
  margin-left: ${props => props.margin}%;
  transition: opacity 0.4s ease-in-out, margin 0.4s ease-in-out;
  overflow: hidden;
  width: 100%;
`;

export const Ribbon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${props =>
    props.correct ? colors.greenSucces : colors.redDanger};
  color: ${colors.white};
  width: 160px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  transform: rotate(45deg);
  margin-top: 25px;
  margin-right: -45px;
`;

export const Question = styled.div`
  text-transform: lowercase;
  font-weight: 600;
  color: ${colors.greyTitle};
  font-size: 18px;
  margin-top: 5px;
  word-break: break-all;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const ResponsesBox = styled.div`
  margin-top: 5px;
  padding-bottom: 10px;
  transition: opacity 0.5s ease-in-out;
`;

export const Response = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  color: ${colors.greyTitle};
  word-break: break-all;
  margin-top: 10px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  .alternative {
    cursor: pointer;
    padding: 5px 0px;
    padding-right: 15px;

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;
