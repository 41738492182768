import api from 'services/api';

// retorna uma lista de turma
const getTurmas = async () => {
  const res = await api.get('/provider/training/turmas');
  return res;
};

// retorna uma turma
const getTurma = async id => {
  const res = await api.get(`/provider/training/turma/${id}`);
  return res;
};

// Pega Lista de atividades da turma
const getTurmaActivities = async id => {
  const res = await api.get(`/provider/training/turma-activities/${id}`);
  return res;
};

// cria a turma
const createTurma = async data => {
  const res = await api.post(`/provider/training/create-turma`, data);
  return res;
};

// atualiza a turma
const updateTurma = async (id, data) => {
  const res = await api.put(`/provider/training/turma/${id}`, data);
  return res;
};

// deleta a turma
const deleteTurma = async id => {
  const res = await api.delete(`/provider/training/turma/${id}`);
  return res;
};

// deleta a relacão turma conteudo/formulario
const deleteActivitieTurma = async body => {
  const res = await api.put(`/training/turma-delete-relation`, body);
  return res;
};

// deleta um ou mais aluno da turma
const deleteTransportadoraTurma = async data => {
  const res = await api.put(
    `/provider/training/update-status-transportadora/`,
    data,
  );
  return res;
};
// associa alunos a uma turma
const createTurmaAluno = async data => {
  const res = await api.post('/training/create-turma-aluno', data);
  return res;
};

export {
  getTurmas,
  getTurma,
  updateTurma,
  createTurmaAluno,
  deleteTurma,
  createTurma,
  deleteTransportadoraTurma,
  deleteActivitieTurma,
  getTurmaActivities,
};
