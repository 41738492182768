import styled, { css } from 'styled-components';
import Icon from 'components/Icons';

export const Wrapper = styled.div``;

// ------------------------HEADER---------------------------//
export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    display: none;
  }
`;

export const Title = styled.h3``;

export const AddButton = styled.label`
  ${({ theme }) => css`
    min-width: 120px;
    cursor: pointer;
    background: transparent;
    border: 1px solid;
    border-color: ${theme.palette.brand.primary.natural};
    border-radius: 4px;
    text-transform: uppercase;
    color: ${theme.palette.brand.primary.natural};
    font-weight: 900;
    padding: 5px 10px;
    font-size: 14px;
    text-align: center;

    &:hover {
      border-color: ${theme.palette.brand.secondary.natural};
      color: ${theme.palette.brand.secondary.natural};
      transition: all ease 0.15s;
    }
  `}
`;

// ------------------------MAIN---------------------------//
export const Box = styled.div`
  ${({ theme, isError }) => css`
    display: flex;
    width: 100%;
    min-height: 180px;
    border: 2px dashed
      ${isError
        ? theme.palette.semantics.feedback.attention.natural
        : theme.palette.words.subtitle.light};
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
    position: relative;

    &:hover {
      border: 2px dashed ${theme.palette.words.subtitle.natural};
    }

    &.opacity {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${theme.palette.semantics.feedback.unknown.light};
      font-size: 35px;
      font-weight: 700;
      color: ${theme.palette.words.subtitle.natural};
      opacity: 0.3;
    }

    &.opacity::after {
      content: 'Solte o(s) arquivo(s) aqui para fazer upload';
      font-size: 20px;
    }
  `}
`;

export const EmptyBox = styled.label`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: inherit;
    width: 100%;
    cursor: pointer;

    input {
      display: none;
    }

    img {
      width: 50px;
      pointer-events: none;
    }

    span {
      text-align: center;
      font-weight: 700;
      color: ${theme.palette.words.text.light};
      pointer-events: none;
    }

    &.disabled {
      background-color: ${theme.palette.system.disabled};
      cursor: default;
    }
  `}
`;

export const BoxLoading = styled.div`
  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const AreaMinimal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const EvidenceMinimal = styled.span`
  ${({ theme, ...props }) => css`
    margin-left: 10px;
    color: ${theme.palette.words.subtitle.natural};
    font-size: 14px;
    cursor: pointer;
    opacity: ${props.visible ? 1 : 0};
    pointer-events: ${props.visible ? 'all' : 'none'};

    &.left {
      margin-right: 10px;
    }

    &:hover {
      opacity: 0.8;
    }
  `}
`;

// export const BoxInput = styled.div`
//     input {
//         display: none;
//     }
// `

export const BoxMinimal = styled.label`
  ${({ theme, ...props }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.1s;

    &:hover {
      opacity: 0.8;
    }

    .iconsBox {
      width: 30px;
      position: relative;
      display: flex;
      align-items: center;

      img {
        width: 24px;
      }

      .circleCounter {
        background-color: ${props.hasItems
          ? theme.palette.semantics.feedback.success.natural
          : theme.palette.semantics.feedback.attention.natural};
        color: ${theme.palette.words.text.contrast};
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        width: 17px;
        height: 17px;
        border-radius: 20px;
        font-size: 12px;
        position: absolute;
        top: 0;
        right: 0%;
        margin-right: -4px;
        margin-top: -4px;
      }
    }

    .boxText {
      .minimalText {
        margin-left: 10px;
        color: ${props.hasError
          ? theme.palette.semantics.feedback.attention.natural
          : theme.palette.words.subtitle.natural};
        font-size: 13px;
        font-weight: 600;
        transition: all ease 0.1s;
      }

      .requirePoint {
        color: ${theme.palette.semantics.feedback.attention.natural};
        font-weight: bold;
      }
    }

    input {
      display: none;
    }
  `}
`;

// ------------------------IMAGES---------------------------//
export const ContentImage = styled.div`
  position: relative;
  margin: ${props => (props.unic ? '0px' : '5px')};
`;

export const ImagesArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.unic ? '1fr' : '1fr 1fr 1fr')};
  padding: 10px 5px;
`;

export const BoxImage = styled.div`
  ${({ theme, ...props }) => css`
    height: ${props.unic ? props.unicSize : '150px'};
    max-height: ${props.unic ? props.unicSize : '150px'};
    background-color: ${theme.palette.system.black};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      height: 100%;
      max-height: ${props => (props.unic ? props.unicSize : '100%')};
      width: auto;
      max-width: 100%;
    }

    embed {
      height: 100%;
      max-height: ${props => (props.unic ? props.unicSize : '100%')};
      width: auto;
      max-width: 100%;
    }

    .unicCenter {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  `}

  .react-player-wrapper {
    display: block;
    position: relative;
    height: 100%;
    max-width: 100%;
  }
`;

export const IconBox = styled(Icon)`
  position: absolute;
  right: 0;
  margin-top: 12px;
  margin-right: 7px;
`;

export const Legend = styled.div`
  color: ${({ theme }) => theme.palette.words.subtitle.natural};
  margin-left: 10px;
  margin-top: -4px;
`;

// ------------------------ERROR---------------------------//
export const BoxError = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 180px;
    border: 2px dashed ${theme.palette.semantics.feedback.attention.natural};
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 20px;
    color: ${theme.palette.semantics.feedback.attention.natural};
    font-weight: 900;
  `}
`;
