import * as S from './styled';

const Text = ({
  value,
  onChange,
  index,
  disabled,
  onFocus,
  onClick,
  placeholder,
  ...props
}) => {
  return (
    <S.TextAnswers
      value={value}
      placeholder={!placeholder ? `Resposta ${index + 1}` : placeholder}
      onChange={!disabled ? e => onChange(e.target.value) : undefined}
      type="text"
      hasDisabled={disabled}
      readOnly={disabled}
      onFocus={onFocus}
      {...props}
    />
  );
};

export default Text;
