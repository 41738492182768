import React from 'react';
import * as S from './styled';

export const CardCriticidade = ({ data }) => {
  return (
    <S.Container>
      <S.Flex className="gap-1">
        <S.Quantidade>{data?.TOTAL}</S.Quantidade>
        <div>
          <S.Title>Desvios tratados</S.Title>
          <S.Subtitle>(Últimos 7 dias)</S.Subtitle>
        </div>
      </S.Flex>
      <S.FlexBar>
        <div
          className="gravissimo"
          style={{ width: `${(100 * data?.GRAVÍSSIMO) / (data?.TOTAL ?? 1)}%` }}
        />
        <div
          className="grave"
          style={{ width: `${(100 * data?.GRAVE) / (data?.TOTAL ?? 1)}%` }}
        />
        <div
          className="moderado"
          style={{ width: `${(100 * data?.MODERADO) / (data?.TOTAL ?? 1)}%` }}
        />
        <div
          className="primario"
          style={{ width: `${(100 * data?.PRIMÁRIO) / (data?.TOTAL ?? 1)}%` }}
        />
      </S.FlexBar>
      <S.Grid>
        <div>
          <span className="bold gravissimo">Gravíssmo:</span> {data?.GRAVÍSSIMO}
        </div>
        <div>
          <span className="bold grave">Grave:</span> {data?.GRAVE}
        </div>
        <div>
          <span className="bold moderado">Moderado:</span> {data?.MODERADO}
        </div>
        <div>
          <span className="bold primario">Primário:</span> {data?.PRIMÁRIO}
        </div>
      </S.Grid>
    </S.Container>
  );
};
