import styled, { css } from 'styled-components';

export const AddButton = styled.p`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 5;
    border: none;
    background: inherit;
    color: ${theme.palette.brand.secondary.natural};
    padding: 5px 0;
    margin: 1rem 0;
    font-size: 18px;
  `}
`;
