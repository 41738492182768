import api from 'services/api';

export const getRoutes = async () => {
  const res = await api.get('/provider/guide-panel-controller-routes');
  return res.data;
};

export const getIndex = async () => {
  const res = await api.get('/provider/guide-panel-controller');
  return res.data;
};

export const saveContent = async data => {
  const res = await api.post('/guide-panel-controller', data);
  return res.data;
};

export const updateContent = async data => {
  const res = await api.put(`/guide-panel-controller/${data.id}`, data);
  return res.data;
};

export const orderGuide = async data => {
  const res = await api.put('/guides-order', data);
  return res.data;
};

export const updateGuideStatus = async id => {
  const res = await api.put(`/guide-change-status/${id}`);
  return res.data;
};
