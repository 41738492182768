import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
`;

export const InfoLine = styled.div`
  ${({ theme, ...props }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;

  .period {
    width: 250px;
  }

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;

    .period {
      .date {
        font-size: 14px;
      }
    }

    .filters {
      display: flex;
      align-items: center;
      justify-content: center;

      .arrow {
        display: flex;
        align-items: center;

        &.next {
          opacity: ${props.last ? 0.4 : 1};
          cursor: ${props.last ? 'not-allowed' : 'pointer'};
        }

        &.back {
          opacity: ${props.first ? 0.4 : 1};
          cursor: ${props.first ? 'not-allowed' : 'pointer'};
        }

        span {
          font-size: 14px;
          font-weight: 600;
          color: ${theme.palette.words.subtitle.natural};
        }
      }

      .textMedium {
        margin: 0px 15px;
        font-size: 14px;
        font-weight: 600;
        color: ${theme.palette.words.title.natural};
        width: 165px;
      }
    }
  `}
`;

export const TotalBox = styled.div`
  grid-area: line;
  width: 100%;
`;

export const MediumBox = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
  margin-top: 40px;

  @media (max-width: 1327px) {
    flex-direction: column;
  }
`;

export const LoadingBox = styled.div``;
