import React, { useState } from 'react';
import { Grid, Modal } from '@mui/material';
import { CalendarTodayOutlined, OpenInFull } from '@mui/icons-material';

// Components
import TextInput from 'components/Inputs/TextField';
import GhostButton from 'components/Buttons/Ghost';
import Loading from 'components/Loading';
import ImageModal from 'components/ImageModal';

// Utils
import { formatNewDate } from 'utils/dates';

// Style
import { useTheme } from 'styled-components';
import * as S from './styled';

const CaronaAutorizadaModal = params => {
  const theme = useTheme();
  const { carona } = params;
  const [expandedImage, setExpandedImage] = useState(false);
  const classes = S.useStyles();

  //  ------------------   RENDERIZACAO --------------------------------
  // Lado Esquerdo do detalhe da Carona
  const renderImage = () => {
    return (
      <S.ColumnWrapper>
        <S.ImageContainer file={!!carona.print}>
          {carona.print && (
            <>
              <S.ExpandImage onClick={() => setExpandedImage(true)}>
                <OpenInFull
                  sx={{
                    color: theme.palette.brand.secondary.natural,
                    fontSize: 40,
                  }}
                />
              </S.ExpandImage>
            </>
          )}
          <S.Image src={carona.print} alt={carona.print} />
        </S.ImageContainer>
      </S.ColumnWrapper>
    );
  };

  // Lado direito detalhe da Carona
  const renderCaronaInfo = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing="24px">
          <Grid item xs={2} sm={2}>
            <TextInput
              id="id"
              label="ID"
              value={carona.id}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <TextInput
              id="empresa"
              label="Empresa"
              value={
                carona.avaliacao?.filial?.empresa?.nome
                  ? carona.avaliacao.filial.empresa.nome
                  : ''
              }
              InputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={5} sm={5}>
            <TextInput
              id="nome"
              label="Filial"
              value={
                carona.avaliacao?.filial?.nome
                  ? carona.avaliacao.filial.nome
                  : ''
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="id_avaliacao"
              label="ID Avaliação"
              value={carona.avaliacao?.id ? carona.avaliacao.id : ''}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="data_ocorrencia"
              label="Data da Ocorrência"
              value={formatNewDate(carona.data_ocorrencia)}
              inputProps={{ readOnly: true }}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="motorista"
              label="Motorista"
              value={
                carona.avaliacao?.motorista?.nome
                  ? carona.avaliacao.motorista.nome
                  : ''
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="placa"
              label="placa"
              value={
                carona.avaliacao?.caminhao?.placa
                  ? carona.avaliacao.caminhao.placa
                  : ''
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12} display="flex" justifyContent="flex-end">
            <GhostButton
              id="btnVoltar"
              size="medium"
              onClick={() => params.setCloseModal()}
              style={{ marginLeft: '10px' }}
            >
              VOLTAR
            </GhostButton>
          </Grid>
        </Grid>
      </S.ColumnWrapper>
    );
  };

  const renderPage = () => {
    return (
      <>
        <S.Main>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              {renderImage()}
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              {renderCaronaInfo()}
            </Grid>
          </Grid>
        </S.Main>
        <ImageModal
          open={expandedImage}
          handleClose={() => setExpandedImage(false)}
          image={carona.print}
        />
      </>
    );
  };

  return carona ? (
    <Modal
      open={!!carona}
      onClose={() => params.setCloseModal()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <S.DivModal className={classes.paper}>
        <Grid>
          <S.Header>
            {
              // TODO: Verificar se vai ter ícone nesse modal
              // <IconM sx={{ color: theme.palette.brand.secondary.natural }} fontSize='medium'>add_circle_outline</IconM>
            }
            <p>{`Detalhe Carona Autorizada #${carona.id}`}</p>
          </S.Header>
          {renderPage()}
        </Grid>
      </S.DivModal>
    </Modal>
  ) : (
    <Loading />
  );
};

export default CaronaAutorizadaModal;
