// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// Components
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';
import { DefaultTable } from 'components/_Table/templates/default';

// Components Material UI
import { Grid } from '@mui/material';
import {
  AddCircleOutline,
  EditOutlined,
  DeleteOutline,
} from '@mui/icons-material';
import ModalFilial from './ModaBases';
import * as C from '../styled';
import * as S from './styled';

// Utils
import { columns } from './columns';
import * as services from './services';
import { trackEvent } from 'utils/mixpanel';
import { useSelector } from 'react-redux';

export const MinhasBases = () => {
  const user = useSelector(state => state.auth?.user?.user);

  const theme = useTheme();
  // General States
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedRow, setSelectedRow] = useState('');

  const sortBy = [
    {
      id: 'createdAt',
      desc: true,
    },
  ];

  // ---------------------------REQUESTS-------------------------------//
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoadingTable(true);
    const res = await services.requestBases();

    // Formatação do filtro de itens disponiveis
    const ftl = res.filter;
    const arrFtl = [];
    for (const i in ftl) {
      arrFtl.push({
        name: ftl[i].base.nome,
        value: ftl[i].base.id_da_base,
      });
    }
    setFilter(arrFtl);

    // Formatação do "Data" para busca na tabela
    const dts = res.data;

    const arrDts = [];
    for (const i in dts) {
      arrDts.push({
        status: dts[i].status === 1 ? 'ATIVA' : 'INATIVA',
        nome: dts[i].base.nome,
        sigla: dts[i].base.sigla,
        id_da_base: dts[i].base.id,
        id_do_usuario: dts[i].id_do_usuario,
        id: dts[i].id,
        createdAt: dts[i].createdAt,
      });
    }
    setData(arrDts);
    setLoadingTable(false);
  };

  // --------------Format Table Columns------------------------//
  const [formatedColumns, setFormatedColumns] = useState([]);

  // ----------------------Modal Add Base-------------------------//
  const [openModal, setOpenModal] = useState(false);
  const renderModal = () => {
    return (
      openModal && (
        <ModalFilial
          open={openModal}
          handleClose={() => {
            setOpenModal(false);
          }}
          filter={filter}
          fetchData={fetchData}
        />
      )
    );
  };

  // ------------------Actions column---------------------------//
  const [idsAtivar, setIdsAtivar] = useState([]);
  const [idsDesativar, setIdsDesativar] = useState([]);
  const [modalText, setModalText] = useState('');
  const [companiesList, setCompaniesList] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);

  const handleSetDesativar = id => {
    const desativar = [];

    if (selectedData.length > 0) {
      for (const i in data) {
        const item = data[i].id;
        if (selectedData.includes(item)) {
          desativar.push(data[i]);
        }
      }
      setIdsDesativar(desativar);
    } else {
      for (const i in data) {
        if (data[i].id === Number(id)) {
          desativar.push(data[i]);
          setIdsDesativar(desativar);
        }
      }
    }

    setModalText(`Tem certeza que deseja desativar?`);
    setCompaniesList(formatText('desativadas', desativar));
  };

  const handleSetAtivar = id => {
    const ativar = [];

    if (selectedData.length > 0) {
      for (const i in data) {
        const item = data[i].id;
        if (selectedData.includes(item)) {
          ativar.push(data[i]);
        }
      }
      setIdsAtivar(ativar);
    } else {
      for (const i in data) {
        if (data[i].id === Number(id)) {
          ativar.push(data[i]);
          setIdsAtivar(ativar);
        }
      }
    }

    setModalText(`Tem em certeza que deseja ativar?`);
    setCompaniesList(formatText('ativadas', ativar));
  };

  const confirmAtivar = async () => {
    setLoadingTable(true);

    const formatedData = [];
    for (const i in idsAtivar) {
      formatedData.push(idsAtivar[i].id_da_base);
    }

    const res = await services.updateBase({ ids: formatedData, status: 1 });
    if (res.success) {
      toast.success(res.message);
      fetchData();
    } else {
      toast.error(res.message);
    }

    setIdsAtivar([]);
    setLoadingTable(false);
  };

  const confirmDesativar = async () => {
    setLoadingTable(true);

    const formatedData = [];
    for (const i in idsDesativar) {
      formatedData.push(idsDesativar[i].id_da_base);
    }

    const res = await services.updateBase({ ids: formatedData, status: 0 });
    if (res.success) {
      toast.success(res.message);
      fetchData();
    } else {
      toast.error(res.message);
    }

    setIdsDesativar([]);
    setLoadingTable(false);
  };

  const formatText = (text, filials) => {
    return (
      <>
        <span>
          As seguintes Bases serão
          <span style={{ fontWeight: '900' }}> {text.toUpperCase()}</span>:
        </span>
        <ul style={{ listStyle: 'disc', marginLeft: '15px' }}>
          {filials.map((i, k) => (
            <li key={k}>{i.nome}</li>
          ))}
        </ul>
      </>
    );
  };

  const handleAtivarDesativar = id => {
    const status = data.some(item => item.id === id);
    status === 1 ? handleSetAtivar(id) : handleSetDesativar(id);
  };

  const bulkActions = [
    {
      title: 'Desativar',
      function: handleSetDesativar,
    },
    {
      title: 'Ativar',
      function: handleAtivarDesativar,
    },
  ];
  const actions = [
    {
      title: 'Ativar/Desativar',
      function: handleSetAtivar,
    },
  ];
  return (
    <S.Container>
      <>
        <C.TitleArea className="space">
          <span className="textArea">Minhas Bases</span>

          <DefaultButton
            startIcon={<AddCircleOutline />}
            size="medium"
            sx={{ marginLeft: '10px' }}
            onClick={() => setOpenModal(true)}
          >
            ADICIONAR NOVA BASE
          </DefaultButton>
        </C.TitleArea>
        <Grid item md={12} paddingTop={1}>
          <DefaultTable
            data={data || []}
            columns={columns}
            actions={actions}
            searchKeys={['nome']}
            loading={loadingTable}
            placeholder="Buscar por nome"
            sortBy={{ id: 'ativo', order: 'ASC' }}
            setSelectedRows={setSelectedData}
            searchEvent={search =>
              trackEvent(user, 'Buscar minhas bases', null, search)
            }
            bulk={bulkActions}
            empty={{
              title: 'Ops! você não tem nenhuma base cadastrada',
              description: 'Adicione uma nova base',
              image: 'frota.png',
            }}
          />
        </Grid>

        {
          /* Confirmação de ativacao */
          idsAtivar && (
            <ConfirmModal
              open={idsAtivar.length > 0}
              handleClose={() => setIdsAtivar(null)}
              title={modalText}
              titleIcon={
                <EditOutlined
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
              }
              subtitle={companiesList}
              buttonText="Confirmar"
              onClick={() => confirmAtivar()}
              loading={false}
            />
          )
        }

        {
          /* Confirmação de desativacao */
          idsDesativar && (
            <ConfirmModal
              open={idsDesativar.length > 0}
              handleClose={() => setIdsDesativar(null)}
              title={modalText}
              titleIcon={
                <DeleteOutline
                  sx={{
                    color: theme.palette.semantics.feedback.attention.natural,
                  }}
                  fontSize="medium"
                />
              }
              subtitle={companiesList}
              buttonText="Confirmar"
              onClick={() => confirmDesativar()}
              loading={false}
            />
          )
        }
        {renderModal()}
      </>
    </S.Container>
  );
};
