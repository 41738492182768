import styled, { css } from 'styled-components';
import { Modal } from '@mui/material';

export const ModalMUI = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 70%;
    height: 85%;
    max-height: 600px;
    border-radius: 4px;
    background-color: ${theme.palette.system.overlay};
  `}
`;

export const Main = styled.div`
  display: flex;
  overflow: scroll;
  height: calc(100% - 120px);
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    opacity: 1;

    .left {
      display: flex;
      align-items: center;

      p {
        margin-left: 6px;
        text-align: left;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0px;
        color: ${theme.palette.words.title.natural};
        opacity: 1;
      }
    }

    .right {
      .MuiSvgIcon-root {
        cursor: pointer;
      }
    }
  `}
`;

export const Footer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 15px;
`;
