// Styled

// React
import { useEffect, useState } from 'react';

// Components
import Select from 'components/Inputs/Select';
import DropZoneArchives from 'components/Inputs/DropZoneCustom';

// Components MUI
import { Divider } from '@mui/material';
import { WithQuestion } from '..';
import * as S from './styled';

// Actions
import { changeCheck, addImage, editImage, deleteImage } from '../../actions';

export const ResponseSelecao = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosEvidencias,
  errosObrigatorios,
  preview,
  approveView,
}) => {
  const [cleanError, setCleanError] = useState([]);
  const [wrongField, setWrongField] = useState([]);

  const redirectFunction = (executable, idResposta, idCampo, item) => {
    if (errosEvidencias) setCleanError([...cleanError, idResposta]);
    executable(idResposta, idCampo, item, setSecoes, secoes, sectionId);
  };

  // --------------------------------------PAGE ERRORS---------------------------------//
  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setCleanError([]);

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }

    if (errosEvidencias) {
      const wrongs = [];
      const hasError = false;
      for (const i in errosEvidencias) {
        const erros = errosEvidencias[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          const { opcoes } = field;
          for (const j in opcoes) {
            if (opcoes[j].id === erros.idResposta) {
              wrongs.push(erros.idResposta);
            }
          }
        }
      }
      setWrongField(wrongs);

      initialFieldState(hasError);
    }
  }, [errosEvidencias, errosObrigatorios]);

  // --------------------------------------PAGE CONFIGURE---------------------------------//

  const [options, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState([]);

  useEffect(() => {
    initialFieldState();
  }, [field]);

  const initialFieldState = hasError => {
    const { opcoes } = field;
    const crOptions = [];
    opcoes.forEach(item =>
      crOptions.push({ value: item.id, name: item.item, ...item }),
    );
    setOptions(crOptions);

    for (const i in opcoes) {
      if (opcoes[i].checked) {
        setCurrentValue(opcoes[i]);
      }
    }
  };

  const handleValue = id => {
    const currentOption = field.opcoes.find(item => item.id == id);
    setCurrentValue(currentOption);
    redirectFunction(changeCheck, currentOption.id, field.id, 'unic');
  };

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanError.length}
        className={`fieldClass-${field.id}`}
      >
        <WithQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanError.length}
          preview={preview}
          approveView={approveView}
        />
        <S.ResponseArea isChecked={currentValue.checked} preview={preview}>
          <span className="checkarea">
            {!!options.length && (
              <Select
                value={currentValue?.id || ''}
                data={options}
                handleChange={e => handleValue(e.target.value)}
                error={hasErrorObrigatorio && !cleanError.length}
                disabled={approveView}
              />
            )}
          </span>
          <span className="blockArea">
            {options.map((opt, idx) => {
              if (currentValue?.id === opt.id && opt.evidencia) {
                return (
                  <DropZoneArchives
                    key={idx}
                    data={opt.arquivos}
                    multiple={false}
                    fileDir="formularios/evidencias"
                    addFunction={item =>
                      redirectFunction(
                        addImage,
                        currentValue?.id,
                        field.id,
                        item,
                      )
                    }
                    editFunction={item =>
                      redirectFunction(
                        editImage,
                        currentValue?.id,
                        field.id,
                        item,
                      )
                    }
                    deleteFunction={item =>
                      redirectFunction(
                        deleteImage,
                        currentValue?.id,
                        field.id,
                        item,
                      )
                    }
                    saveNow
                    setSaveNow={() => {}}
                    minimal
                    minimalText={!approveView ? 'Adicionar evidência' : null}
                    minimalRequired
                    haveLegend
                    onlyRead={approveView}
                    newImagesByModal="Alterar Evidência"
                    error={wrongField.includes(currentValue?.id)}
                    tooltip={approveView}
                  />
                );
              }
            })}
          </span>
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
