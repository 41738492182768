// COMPONENTS
import EmptyCard from 'components/Cards/EmptyDataCard';

// UTILS
import { formatNewDate } from 'utils/dates';
import { useTheme } from 'styled-components';
import * as S from './styled';

export const DesviosCards = ({ desvios, driver }) => {
  const theme = useTheme();
  const setStatusColor = status => {
    switch (status) {
      case 'ABERTO':
        return theme.palette.semantics.feedback.attention.natural;
      case 'ANALISE':
        return theme.palette.semantics.feedback.unknown.natural;
      case 'PENDENTE':
        return theme.palette.semantics.feedback.warning.natural;
      case 'FINALIZADO':
        return theme.palette.semantics.feedback.success.natural;
      case 'DELETADO':
        return theme.palette.semantics.feedback.unknown.light;
    }
  };

  const setTypeColor = type => {
    switch (type) {
      case 'PRIMÁRIA':
        return {
          color: theme.palette.semantics.feedback.information.natural,
          background: theme.palette.semantics.feedback.success.light,
        };
      case 'MODERADA':
        return {
          color: theme.palette.semantics.feedback.warning.natural,
          background: theme.palette.semantics.feedback.warning.light,
        };
      case 'GRAVE':
        return {
          color: theme.palette.semantics.feedback.attention.natural,
          background: theme.palette.semantics.feedback.attention.light,
        };
      case 'GRAVÍSSIMA':
        return {
          color: theme.palette.semantics.feedback.attention.natural,
          background: theme.palette.semantics.feedback.attention.light,
        };
      default:
        return {};
    }
  };

  return (
    <div>
      {desvios &&
        desvios.map((desvio, idx) => (
          <S.DesvioBox key={idx} color={setStatusColor(desvio.status)}>
            <div className="imgArea">
              <img src={desvio.img_ocorrencia} />
            </div>
            <div className="contentArea">
              <div className="top">
                <span className="texts">
                  <span className="date">
                    {formatNewDate(desvio.data_desvio)}
                  </span>
                  <span className="id">#{desvio.id}</span>
                </span>
              </div>
              <div className="medium">
                <span className="dsTitle">{`Desvio: ${desvio.tipo?.descricao}`}</span>
                <span className="name">{driver.nome.toLowerCase()}</span>
              </div>
              <div className="bottom">
                <span className="status">
                  <span className="ball" />
                  <span className="text">{desvio.status.toLowerCase()}</span>
                </span>
                <span className="type">
                  Criticidade{' '}
                  <span style={setTypeColor(desvio.tipo?.criticidade)}>
                    {desvio.tipo?.criticidade}
                  </span>
                </span>
              </div>
            </div>
          </S.DesvioBox>
        ))}
      {/* {desvios.length <= 0 && (
        <EmptyCard
          image={"frota.png"}
          title={"Nenhum desvio na avaliação"}
          subtitle={"Nada para exibir"}
        />
      )} */}
    </div>
  );
};
