import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { subDays } from 'date-fns';

import { SvgIcon } from '@mui/material';
import { DefaultTable } from 'components/_Table/templates/default';
import DrawerFilters from 'pages/Desvios/Estatisticas/DesempenhoGeral/components/DrawerFilters';
import { CustomizedSelect } from 'components/SectionPeriod';
import { ReactComponent as filterIco } from 'images/icons/filter.svg';

import { usePlans } from 'hooks/usePlans';

import * as S from './styled';
import { requestEmpresasGeral } from './services';
import { columns, filtersGraficoParams } from './constants';
import { StyledButton } from 'pages/Desvios/Estatisticas/DesempenhoGeral/styled';
import { PerfilEmpresas } from './PerfilEmpresas';

export const ListaEmpresas = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filtersPage, setFiltersPage] = useState({
    finalDate: new Date(),
    initialDate: subDays(new Date(), 30),
  });

  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const { planosAtivos } = usePlans();
  const { empresas } = useSelector(state => state.selects);

  const { isFetching, data: resData } = useQuery(
    ['empresas-geral', query],
    () => query && requestEmpresasGeral(query),
    {
      refetchOnWindowFocus: false,
      onSettled: () => {
        resetTable && setResetTable(false);
      },
    },
  );

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filtersPage]);

  useEffect(() => {
    const filterClient = filtersGraficoParams.find(
      filter => filter.name === 'empresas',
    );
    if (filterClient) {
      filterClient.data = empresas.map(item => ({
        id: item.id,
        value: item.nome,
      }));
    }
  }, [empresas]);

  return (
    <S.Container>
      <S.Header>
        <S.Title>Lista de empresas</S.Title>
        <S.Actions>
          <CustomizedSelect
            today={filtersPage.finalDate}
            selectedDate={filtersPage.initialDate}
            handleChangeDate={value => {
              setFiltersPage(state => ({ ...state, initialDate: value }));
              setSelectedRow(null);
            }}
          />
          <StyledButton
            textcolor={theme.palette.words.text.natural}
            backgroundcolor="transparent"
            startIcon={<SvgIcon component={filterIco} />}
            height="45px"
            style={{ marginTop: '3px' }}
            onClick={event => setAnchorEl(event.currentTarget)}
            disabled={isFetching}
          >
            Filtros
          </StyledButton>
        </S.Actions>
      </S.Header>
      <S.Content>
        <S.TableContainer>
          <DefaultTable
            compactRow
            local={false}
            data={
              resData?.data?.data.map(item => ({
                ...item,
                id: item.id_empresa,
              })) || []
            }
            pageCount={resData?.data?.total || 0}
            columns={columns.filter(
              col =>
                !col.plans ||
                planosAtivos.some(plan => col.plans.includes(plan.id_do_plano)),
            )}
            reset={resetTable}
            loading={isFetching}
            placeholder="Buscar Empresa"
            empty={{
              title: 'Nenhuma empresa encontrada',
              description: '',
            }}
            sortBy={{ id: 'nome', order: 'ASC' }}
            setQuery={q =>
              setQuery({
                ...q,
                ...filtersPage,
              })
            }
            onClickRow={id => setSelectedRow(id)}
            disabledRows={[selectedRow]}
          />
        </S.TableContainer>
        <S.GraphContainer>
          <PerfilEmpresas
            id={selectedRow}
            empresa={
              selectedRow && resData?.data?.data
                ? resData.data.data.find(
                    item => item.id_empresa === selectedRow,
                  )
                : null
            }
            filters={filtersPage}
          />
        </S.GraphContainer>
      </S.Content>
      <DrawerFilters
        open={anchorEl}
        handleClose={() => setAnchorEl(null)}
        onSelected={selected => {
          setFiltersPage(state => ({ ...state, ...selected }));
          setSelectedRow(null);
        }}
        dataSelect={filtersGraficoParams}
        onClean={() => {
          setFiltersPage(state => ({ ...state, empresas: null }));
          setSelectedRow(null);
        }}
      />
    </S.Container>
  );
};
