import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { CardAlert } from '../CardAlert';
import { ignoreAlert } from '../services';

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    height: 530,
    width: 530,
    borderRadius: '8px',
  },
}));

export const ShowAlert = ({ data = [] }) => {
  const [open, setOpen] = useState(null);

  useEffect(() => {
    const exibir = data?.find(al => !al.visualizado) ?? null;
    setOpen(exibir);
  }, [data]);

  const handleClose = () => {
    if (open) ignoreAlert(open.id_notificacao);
    setOpen(false);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={!!open}
    >
      <CardAlert data={open} onIgnore={() => setOpen(null)} modal />
    </BootstrapDialog>
  );
};
