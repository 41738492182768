import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

// Components
import { AddCircleOutline } from '@mui/icons-material';
import ExcelModal from 'components/ExcelModal';
import FiltersGlobal from 'components/FiltersGlobal';
import { ButtonApply } from './styled';

import { trackEvent } from 'utils/mixpanel';
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import { columns, fields, resetExcelFields } from './constants';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

import {
  desactivateConfig,
  requestConfigurations,
  requestExcel,
} from './services';
import ConfirmModal from 'components/ConfirmModal';
import ListPage from 'pages/_templates/ListPage';

import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

const TelemetriaConfiguracoes = () => {
  // Redux e hooks
  const navigate = useNavigate();
  const user = useSelector(state => state.auth?.user?.user);
  const isProvider = user?.provider;

  const [query, setQuery] = useState({});
  const [desativar, setDesativar] = useState(null);
  // const [loadingLines, setLoadingLines] = useState([]);

  const [client, setClient] = useState(0);

  // Excel Fields
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const { veiculos, desviosTiposPadrao } = useFetchMultipleWithCache();

  const {
    refetch: fetchData,
    isLoading,
    data: resData,
  } = useQuery(
    ['configuracoes-desvios', query],
    () => query && requestConfigurations(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {},
    },
  );

  const data = useMemo(() => {
    let newData = [];
    if (resData?.data?.data) {
      newData = resData.data.data.map(item => ({ ...item, isProvider }));
      if (!isProvider && client) {
        newData = newData.filter(item => item.id_distribuidora === client);
      }
      if (!isProvider && !client) {
        newData = newData.filter(item => !item.id_distribuidora);
      }
    }
    return newData;
  }, [resData, client]);

  const clients =
    !isProvider &&
    useMemo(() => {
      let newClients = [];
      if (resData?.data?.data?.length) {
        for (const grupo of resData.data.data) {
          if (grupo.distribuidora) {
            const newClient = {
              value: grupo.distribuidora.id,
              label: grupo.distribuidora.nome,
            };
            if (!newClients.find(ni => ni.value === newClient.value))
              newClients.push(newClient);
          }
        }
      }
      newClients.sort((c1, c2) => c1.label.localeCompare(c2.label));
      newClients = [{ value: 0, label: 'Minhas configurações' }, ...newClients];
      return newClients;
    }, [resData]);

  const handleAtivar = async item => {
    if (!item.id_empresa && !isProvider)
      return toast.warning('Esse grupo foi criado pelo cliente');
    if (item.ativo) return toast.success('Esse grupo já está ativo');

    navigate(`/desvios/configuracoes/${item.id}`);
  };

  const handleDesativar = async item => {
    if (!item.id_empresa && !isProvider)
      return toast.warning('Esse grupo foi criado pelo cliente');
    if (!item.ativo) return toast.success('Esse grupo já está inativo');

    item?.titulo !== 'Desvios Onisys'
      ? setDesativar(item)
      : toast.warning('Esse grupo foi criado pelo cliente');
  };

  const confirmDesativar = async item => {
    const res = await desactivateConfig(item.id);
    if (res.success) {
      fetchData();
      toast.success(res.message);
    } else toast.error(res.message);
    setDesativar(null);
  };

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = {
      ...query,
      excelFields: newFields,
    };

    const res = await requestExcel(newQuery);
    if (res.data && res.data?.data?.excel) {
      ExportToExcel({
        excel: res.data.data.excel,
        name: `desvios_${formatedDate}`,
      });
      toast.success(res.data.message);
    } else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  const actions = [
    {
      title: 'Ativar',
      function: (id, item) => handleAtivar(item),
    },
    {
      title: 'Desativar',
      function: (id, item) => handleDesativar(item),
    },
    {
      title: 'Editar',
      function: id => navigate(`/desvios/configuracoes/${id}`),
    },
    {
      title: 'Ver histórico',
      function: id => navigate(`/logs/desvios/configuracoes/${id}`),
    },
  ];

  const getColumns = cols =>
    cols
      .filter(col => !isProvider || col.id !== 'distribuidora.nome')
      .map(col => {
        if (col.id === 'ativo')
          return {
            ...col,
            switch: {
              value: (_, item) => item.ativo,
              onChange: (value, item) =>
                value ? handleAtivar(item) : handleDesativar(item),
            },
          };
        if (col.id === '')
          return {
            ...col,
            attention: (_, item) => {
              if (!item.id_empresa && !isProvider)
                return {
                  text: 'Desvio configurado pelo cliente',
                };
            },
          };
        return col;
      });

  const headerTabs = !isProvider && {
    value: client,
    items: clients,
    onChange: (event, newValue) => {
      setClient(newValue);
    },
    disabled: false,
  };

  // filtros utilizaods no acesso Provider
  const filtersList = Boolean(desviosTiposPadrao.data) &&
    Boolean(veiculos.data) && [
      {
        filterName: 'desvio',
        label: 'Desvios',
        options: desviosTiposPadrao.data,
      },
      {
        filterName: 'veiculo',
        label: 'Tipo de veículo',
        options: veiculos.data,
      },
    ];

  const renderFilters = () => {
    return (
      Boolean(filtersList) && (
        <FiltersGlobal
          hideDate
          hideRefleshButton
          customComponent={
            <ButtonApply
              startIcon={<AddCircleOutline />}
              onClick={() => {
                navigate(`/desvios/configuracoes/criar`);
              }}
            >
              Criar grupo
            </ButtonApply>
          }
          refetch={fetchData}
          isFetching={isLoading}
          handleFilters={props => setQuery({ ...query, ...props })}
          handleExport={() => {
            trackEvent(user, 'EXPORTAR PLANILHA DESVIOS');
            setOpenExcelModal(true);
          }}
          data={filtersList}
        />
      )
    );
  };

  return (
    <>
      <ListPage
        title="Configurações"
        headerTabs={headerTabs}
        customComponent={renderFilters()}
        tableTemplate="default"
        tableProps={{
          local: true,
          data,
          columns: getColumns(columns),
          loading: isLoading,
          // NOP pageCount: data.length,
          // NOP  reset: resetTable,
          // NOP loadingSelection: loadingLines,
          // NOP setQuery: q => setQuery(q),
          searchKeys: ['titulo'],
          actions,
          searchEvent: search => trackEvent(user, 'Buscar grupo', null, search),
          sortBy: { id: 'titulo', order: 'DESC' },
          placeholder: 'Buscar grupo',
          empty: {
            title:
              'Ops! Você não tem nenhuma configuração de desvio disponível.',
            description: 'Verifique os filtros aplicados!',
          },
        }}
      />

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      {desativar && (
        <ConfirmModal
          handleClose={() => setDesativar(null)}
          open={!!desativar}
          title="Tem certeza que deseja desabilitar este grupo?"
          subtitle="Todas os desvios do grupo serão desabilitados"
          buttonText="Desabilitar"
          onClick={() => confirmDesativar(desativar)}
        />
      )}
    </>
  );
};

export default TelemetriaConfiguracoes;
