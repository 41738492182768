import { formatNewDate } from 'utils/dates';
import colors from 'styles/colors';
import * as S from './styled';

export const fequencyModule = cont => {
  const inicio = cont?.data_inicio;
  const final = cont?.data_fim;

  let finalModule = null;

  if (final) {
    finalModule = (
      <S.PreviewDate>
        Previsão de encerramento:{' '}
        <span className="raw">{formatNewDate(final)}</span>
      </S.PreviewDate>
    );
  } else {
    finalModule = (
      <S.PreviewDate>
        Previsão de encerramento: <span className="raw">Nunca encerrar</span>
      </S.PreviewDate>
    );
  }

  const initialDate = (
    <S.PreviewDate>
      Data de início: <span className="raw">{formatNewDate(inicio)}</span>
    </S.PreviewDate>
  );

  return (
    <>
      {initialDate}
      {finalModule}
    </>
  );
};

export const statusUser = status => {
  switch (status?.toLowerCase()) {
    case 'não iniciou':
      return (
        <S.StatusBox
          className="status"
          bg={colors.orangeBackground}
          cl={colors.orangeAlert}
        >
          {status}
        </S.StatusBox>
      );
    case 'em andamento':
      return (
        <S.StatusBox
          className="status"
          bg={colors.blueBackground}
          cl={colors.blueInfo}
        >
          {status}
        </S.StatusBox>
      );
    case 'aprovado':
      return (
        <S.StatusBox
          className="status"
          bg={colors.greenMainBackground}
          cl={colors.greenMain}
        >
          {status}
        </S.StatusBox>
      );
    case 'reprovado':
      return (
        <S.StatusBox
          className="status"
          bg={colors.redBackground}
          cl={colors.redDanger}
        >
          {status}
        </S.StatusBox>
      );
    default:
      return <S.StatusBox className="status">Indefinido</S.StatusBox>;
  }
};
