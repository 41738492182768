import React from 'react';
import { Controller } from 'react-hook-form';
import Input from 'components/Inputs/DropZoneCustom';

const TextField = ({ control, name, rules, data, errors, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <Input
          data={value}
          error={!!errors?.[name]?.message}
          addFunction={value =>
            onChange(Array.isArray(value) ? value[0].imagem_url : '')
          }
          deleteFunction={() => onChange('')}
          {...props}
        />
      )}
    />
  );
};

export default TextField;
