const conditional = [
  {
    condition: (_, item) => item.convite === 'PENDENTE',
    style: theme => ({
      opacity: 0.5,
    }),
  },
];

export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Empresa',
    id: 'nome',
    type: 'string',
    sort: true,
    picture: 'logo_url',
    align: 'flex-start',
    conditional,
  },
  {
    header: '',
    id: 'convite',
    type: 'string',
    sort: false,
    align: 'flex-start',
    conditional,
  },
  {
    header: 'CNPJ',
    id: 'cnpj',
    type: 'string',
    sort: false,
    conditional,
  },
  {
    header: 'Motoristas',
    id: 'n_motoristas_ativos',
    type: 'number',
    sort: true,
    align: 'center',
    conditional,
  },
];
