import { useState, useEffect, useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, Grid } from '@mui/material';
import {
  MapInfoButton,
  MapInfoContent,
  MapInfoWrapper,
  RadiusControlActions,
  RadiusControlButton,
  RadiusControlTitle,
  RadiusControlWrapper,
} from 'components/GoogleMap/styled';
import { GoogleMap } from 'components/GoogleMap';
import RadioGroup from 'components/Inputs/RadioGroup';
import RemoveIcon from '@mui/icons-material/Remove';
import PlacesAutocomplete from 'components/PlacesAutocomplete';
import * as S from './styled';
import { useTheme } from 'styled-components';

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 4,
};

const Pontos = ({ pontos = [], onChange, disabled }) => {
  const theme = useTheme();
  const [map, setMap] = useState(null);
  const [singlePoint, setSinglePoint] = useState(true);
  const [markers, setMarkers] = useState([]);
  const [markerRefs, setMarkerRefs] = useState([]);
  const [polyRef, setPolyRef] = useState(null);
  const [circleRef, setCircleRef] = useState(null);
  const [radius, setRadius] = useState(100);
  const [mapInfo, setMapInfo] = useState({
    open: true,
    content: 'Busque a localização e navegue no mapa aplicando o zoom',
  });

  const handleChange = useCallback(
    (singlePoint, markers, radius, path) => {
      let _pontos = [];

      if (singlePoint) {
        _pontos = [
          {
            latitude: markers[0].position.lat,
            longitude: markers[0].position.lng,
            raio: radius,
            is_marcador: true,
          },
        ];
      } else {
        const rotas = [...path];
        const _markers = markers.map((item, idx) => ({
          posicao: idx + 1,
          latitude: item.position.lat,
          longitude: item.position.lng,
          is_marcador: true,
        }));
        _pontos = rotas.concat(_markers);
      }
      if (onChange instanceof Function) onChange(_pontos);
    },
    [onChange],
  );

  // Configura os pontos default ao editar
  useEffect(async () => {
    if (map) {
      if (!markers.length && pontos.length) {
        const isSinglePoint = pontos.length === 1 && !!pontos[0].raio;
        if (isSinglePoint) {
          setRadius(pontos[0].raio);
          const position = {
            lat: parseFloat(pontos[0].latitude),
            lng: parseFloat(pontos[0].longitude),
          };
          const address = await getGeocodeLocation(position);
          setMarkers([
            {
              position,
              title: '',
              address,
            },
          ]);
          map.setCenter(position);
        } else {
          const _markers = pontos
            .filter(item => item.is_marcador)
            .sort((a, b) => a.posicao - b.posicao);
          const formattedMarkers = _markers.map((item, index) => ({
            position: {
              lat: parseFloat(item.latitude),
              lng: parseFloat(item.longitude),
            },
            title:
              index === 0
                ? 'Inicial'
                : index === _markers.length - 1
                ? 'Final'
                : '',
          }));
          formattedMarkers[0].address = await getGeocodeLocation(
            formattedMarkers[0].position,
          );
          formattedMarkers[formattedMarkers.length - 1].address =
            await getGeocodeLocation(
              formattedMarkers[formattedMarkers.length - 1].position,
            );

          setMarkers(formattedMarkers);
          map.setCenter({
            lat: parseFloat(_markers[0]?.latitude),
            lng: parseFloat(_markers[0]?.longitude),
          });
        }
        setSinglePoint(isSinglePoint);
        map.setZoom(13);
      }
    }
  }, [map, markers, pontos]);

  // Controla a exibição dos marcadores no mapa
  // Atualiza o valor no formulario
  useEffect(() => {
    if (!map) return;
    const directionsService = new google.maps.DirectionsService();

    if (markers.length) {
      setMapInfo(state => ({
        ...state,
        content: singlePoint
          ? 'Defina o tamanho do raio'
          : markers.length > 1
          ? 'Configure os parâmetros nos campos abaixo do mapa'
          : 'Clique no próximo ponto para finalizar a cerca',
      }));
    }

    markerRefs.forEach(marker => {
      window.google.maps.event.removeListener(marker.dragListener);
      window.google.maps.event.removeListener(marker.clickListener);
      window.google.maps.event.removeListener(marker.infoListener);
      window.google.maps.event.removeListener(marker.removeListener);
      marker.ref.setMap(null);
    });
    circleRef?.setMap(null);

    markers.forEach(({ position, title }, index) => {
      // Add marker
      const icon =
        !title && !singlePoint
          ? {
              path: google.maps.SymbolPath.CIRCLE,
              strokeOpacity: 0.7,
              scale: 6,
            }
          : undefined;
      const markerRef = new window.google.maps.Marker({
        map,
        draggable: true,
        position,
        label: title,
        icon,
      });
      let markerInfowindow = new google.maps.InfoWindow({
        content: ' ',
      });

      let markerClickListener = null;
      let markerInfoListener = null;
      let markerRemoveListener = null;

      // Add marker click Listner
      markerClickListener = google.maps.event.addListener(
        markerRef,
        'click',
        function () {
          markerInfowindow.setContent(
            // eslint-disable-next-line react/no-this-in-sfc
            `<button style="border: none; background: inherit; padding: 4px; font: normal normal 14px/16px Texta;" id="btn-remover-${index}" value="${index}" type="button">DESFAZER PONTO</button>`,
          );
          markerInfowindow.open(map, this);
        },
        { once: true },
      );

      // Add infoWindow to marker
      markerInfoListener = google.maps.event.addListener(
        markerInfowindow,
        'domready',
        function () {
          const removeButton = document.getElementById(`btn-remover-${index}`);
          if (removeButton) {
            markerRemoveListener = google.maps.event.addDomListener(
              removeButton,
              'click',
              () => {
                setMarkers(_markers => {
                  const markers = [..._markers];
                  markers.splice(index, 1);
                  if (markers.length > 1)
                    markers[markers.length - 1].title = 'Final';
                  return markers ?? [];
                });
              },
            );
          }
        },
      );

      // Add marker dragEndListner
      const markerDragListener = google.maps.event.addListener(
        markerRef,
        'dragend',
        async function () {
          const position = markerRef.getPosition();
          const address = await getGeocodeLocation(position);
          setMarkers(_markers => {
            const markers = [..._markers];
            markers[index].position = {
              lat: position.lat(),
              lng: position.lng(),
            };
            markers[index].address = address;
            return markers;
          });
        },
      );

      setMarkerRefs(_markerRefs => [
        ..._markerRefs,
        {
          ref: markerRef,
          dragListener: markerDragListener,
          clickListener: markerClickListener,
          infoListener: markerInfoListener,
          removeListener: markerRemoveListener,
        },
      ]);
    });

    // Add radius overlay and handleChange
    if (singlePoint && markers.length > 0) {
      const circleRef = new window.google.maps.Circle({
        map,
        strokeColor: theme.palette.brand.primary.dark,
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: theme.palette.brand.primary.natural,
        fillOpacity: 0.35,
        center: markers[0].position,
        radius,
      });
      setCircleRef(circleRef);
      handleChange(singlePoint, markers, radius);
    }

    // Clean polyliine and handleChange
    if (!singlePoint && markers.length === 1) {
      handleChange(singlePoint, markers, null, []);
      if (polyRef) polyRef?.setMap(null);
    }

    // Add polyline between markers and handleChange
    if (!singlePoint && markers.length >= 2) {
      const _markers = [...markers];
      const origin = _markers.shift();
      const destination = _markers.pop();

      directionsService.route(
        {
          origin: origin.position,
          destination: destination.position,
          waypoints: _markers.map(marker => ({
            location: marker.position,
            stopover: false,
          })),

          travelMode: 'DRIVING',
        },
        (response, status) => {
          if (status === 'OK') {
            const path = [...response.routes[0].overview_path];
            const pathArr = path.map((position, idx) => ({
              posicao: idx + 1,
              latitude: position.lat(),
              longitude: position.lng(),
              is_marcador: false,
            }));

            handleChange(singlePoint, markers, null, pathArr);

            if (polyRef) polyRef?.setMap(null);

            const _polyRef = new window.google.maps.Polyline({
              map,
              path,
              geodesic: true,
              strokeColor: theme.palette.brand.primary.dark,
              strokeOpacity: 0,
              icons: [
                {
                  icon: lineSymbol,
                  offset: '0',
                  repeat: '20px',
                },
              ],
            });
            setPolyRef(prev => {
              if (prev) prev.setMap(null);
              return _polyRef;
            });
          }
        },
      );
    }

    return () => {
      markerRefs.forEach(marker => {
        window.google.maps.event.removeListener(marker.dragListener);
        window.google.maps.event.removeListener(marker.clickListener);
        window.google.maps.event.removeListener(marker.infoListener);
        window.google.maps.event.removeListener(marker.removeListener);
        marker.ref.setMap(null);
      });
      circleRef?.setMap(null);
      polyRef?.setMap(null);
    };
  }, [map, markers, singlePoint, radius]);

  // Add marcador ao clicar
  useEffect(() => {
    const handleAddMarker = (position, address) => {
      setMarkers(_markers => {
        if (singlePoint) {
          return !_markers.length
            ? [{ position, address, title: '' }]
            : _markers;
        }
        if (!_markers.length) return [{ position, address, title: 'Inicial' }];
        if (_markers.length === 1)
          return [..._markers, { position, address, title: 'Final' }];
        let arr = [..._markers];
        arr.splice(arr.length - 1, 0, {
          position,
          address,
          title: '',
        });
        return arr;
      });
    };

    let clickListener = null;
    if (map) {
      clickListener = map.addListener(
        'click',
        async e => {
          const location = { lat: e.latLng.lat(), lng: e.latLng.lng() };
          let address = await getGeocodeLocation(location);
          handleAddMarker(location, address);
        },
        { once: true },
      );
    }

    return () =>
      clickListener && window.google.maps.event.removeListener(clickListener);
  }, [map, singlePoint]);

  // Busca descricao do ponto
  const getGeocodeLocation = useCallback(async location => {
    if (!window.google?.maps) return '';
    const geocoder = new window.google.maps.Geocoder();
    if (!geocoder) return '';
    try {
      const response = await geocoder.geocode({ location });

      if (response.results[0]) return response.results[0].formatted_address;
      return 'Endereço não encontrado.';
    } catch (e) {
      console.warn(`Geocoder failed due to: ${e}`);
    }
  }, []);

  // Altera tipo de cerca
  const handleChangeType = useCallback(
    (_, value) => {
      setSinglePoint(value === 'true');
      if (value === 'true' && markers.length) {
        setMarkers(_markers => [{ ..._markers[0], title: '' }]);
        polyRef?.setMap(null);
      } else if (value === 'false' && markers.length) {
        setMarkers(_markers => [{ ..._markers[0], title: 'Inicial' }]);
      }
    },
    [markers, polyRef],
  );

  return (
    <>
      <Grid item xs={12} sm={6}>
        {map && (
          <PlacesAutocomplete
            required
            label="Localização"
            onChange={position => {
              map?.setCenter(position);
              map?.setZoom(13);
              setMapInfo(state => ({
                ...state,
                content: 'Clique no mapa para definir o ponto',
              }));
            }}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={6} display="flex">
        <FormControl sx={{ justifyContent: 'center' }} disabled={disabled}>
          <S.Label>
            Tipo de Cerca<span>*</span>
          </S.Label>

          <div style={{ flex: 1, display: 'flex' }}>
            <RadioGroup
              row
              options={[
                { value: true, label: 'Ponto único' },
                { value: false, label: 'Ponto inicial e final' },
              ]}
              value={singlePoint}
              onChange={handleChangeType}
            />
          </div>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12}>
        <GoogleMap
          mapContainerClassName="map"
          options={{
            center: {
              lat: -13.923025,
              lng: -56.8509357,
            },
            zoom: 4,
            zoomControl: true,
            scrollwheel: false,
          }}
          getMapInstance={mapInstance => setMap(mapInstance)}
        >
          {mapInfo.open && (
            <MapInfoWrapper>
              <MapInfoContent>{mapInfo.content}</MapInfoContent>
              <MapInfoButton
                onClick={e => setMapInfo(state => ({ ...state, open: false }))}
              >
                <CloseIcon fontSize="8px" />
              </MapInfoButton>
            </MapInfoWrapper>
          )}
          {singlePoint && !!markers.length && (
            <RadiusControlWrapper>
              <RadiusControlTitle>Raio</RadiusControlTitle>
              <RadiusControlActions>
                <RadiusControlButton
                  type="button"
                  onClick={() =>
                    setRadius(radius => (radius > 0 ? radius - 50 : 0))
                  }
                >
                  <RemoveIcon />
                </RadiusControlButton>
                <p>{radius}m</p>
                <RadiusControlButton
                  type="button"
                  onClick={() => setRadius(radius => radius + 50)}
                >
                  <AddIcon />
                </RadiusControlButton>
              </RadiusControlActions>
            </RadiusControlWrapper>
          )}
        </GoogleMap>
      </Grid>
    </>
  );
};

export default Pontos;
