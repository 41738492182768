import { formatNewDate } from 'utils/dates';

export const iconsCards = {
  com_ocorrencias: 'warning.svg',
  aprovacao_vencida: 'schedule_red.svg',
  aprovacao_pendente: 'formularios_red.svg',
  nao_iniciados: 'formularios_red.svg',
  iniciados: 'formularios.svg',
  criticidade_alta: 'Grupo12754.svg',
  tratativa_vencida: 'warning.svg',
  tratativa_proxima_vencimento: 'atention.svg',
};

export const statusTabs = [
  { value: 'PREENCHIDOS', label: 'Preenchidas' },
  { value: 'PENDENTES', label: 'Pendentes' },
  { value: 'OCORRENCIAS', label: 'Ocorrências' },
];

const periodos = {
  DIA: 'dias',
  SEMANA: 'semanas',
  MES: 'mêses',
};

const singulares = {
  DIA: 'diário',
  SEMANA: 'semanal',
  MES: 'mensal',
};

export const columnsFilled = [
  {
    header: 'Formulário',
    id: 'titulo',
    type: 'string',
    sort: true,
    tooltip: true,
    subRow: {
      prefix: 'Recorrência:',
      value: (_, item) =>
        item?.frequencia > 1
          ? `à cada ${item?.frequencia} ${
              periodos[item?.periodo] ?? item?.periodo
            }`
          : singulares[item?.periodo] ?? 'Não definida',
      type: 'string',
    },
  },
  {
    header: 'Data de Preenchimento',
    id: 'data_concluido',
    type: 'string',
    sort: true,
    value: (_, item) =>
      item?.data_preenchimento ? formatNewDate(item.data_preenchimento) : '',
  },
  {
    header: 'Aprovador',
    id: 'aprovador',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    width: 200,
    conditional: [
      {
        condition: value => value === 'Finalizado',
        style: theme => ({
          color: theme?.palette?.word?.subtitle?.natural,
        }),
      },
      {
        condition: value => value === 'Aprovação pendente',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.warning?.natural,
        }),
      },
      {
        condition: value => value === 'Aprovação vencida',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.attention?.natural,
        }),
      },
    ],
  },
  {
    header: 'Prazo de Aprovação',
    id: 'prazo_aprovacao',
    type: 'string',
    sort: true,
    value: (_, item) =>
      item?.prazo_aprovacao ? formatNewDate(item.prazo_aprovacao) : '',
  },
  {
    header: 'Ocorrência',
    id: 'ocorrencia',
    type: 'string',
    sort: false,
    value: (_, item) => (item?.ocorrencia ? 'SIM' : ''),
  },
];

export const columnsPending = [
  {
    header: 'Formulário',
    id: 'formulario',
    type: 'string',
    sort: false,
    tooltip: true,
    subRow: {
      prefix: 'Recorrência:',
      value: (_, item) =>
        item?.frequencia > 1
          ? `à cada ${item?.frequencia} ${
              periodos[item?.periodo] ?? item?.periodo
            }`
          : singulares[item?.periodo] ?? 'Não definida',
      type: 'string',
    },
  },
  {
    header: 'Data Limite',
    id: 'data_limite',
    type: 'string',
    sort: false,
    value: (_, item) =>
      item?.data_limite ? formatNewDate(item.data_limite) : '',
  },
  {
    header: 'Aprovador',
    id: 'aprovador',
    type: 'string',
    sort: false,
    tooltip: true,
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: false,
    width: 200,
    conditional: [
      {
        condition: value => value === 'Não iniciado',
        style: theme => ({
          color: theme?.palette?.word?.subtitle?.natural,
        }),
      },
      {
        condition: value => value === 'Iniciado',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.warning?.natural,
        }),
      },
    ],
  },
];

export const columnsOccurrencies = [
  {
    header: 'Ocorrência',
    id: 'ocorrencia',
    type: 'string',
    sort: true,
  },
  {
    header: 'Formulário',
    id: 'formularios',
    type: 'string',
    sort: true,
    tooltip: true,
    subRow: {
      prefix: 'Recorrência:',
      value: (_, item) =>
        item?.frequencia > 1
          ? `à cada ${item?.frequencia} ${
              periodos[item?.periodo] ?? item?.periodo
            }`
          : singulares[item?.periodo] ?? 'Não definida',
      type: 'string',
    },
  },
  {
    header: 'Data de Preenchimento',
    id: 'data_concluido',
    type: 'string',
    sort: true,
    value: (_, item) =>
      item?.data_preenchimento ? formatNewDate(item.data_preenchimento) : '',
  },
  {
    header: 'Responsável',
    id: 'responsavel',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    width: 200,
    conditional: [
      {
        condition: value => value === 'FINALIZADO' || value === 'DELETADO',
        style: theme => ({
          color: theme?.palette?.word?.subtitle?.natural,
        }),
      },
      {
        condition: value => value === 'PENDENTE' || value === 'ANALISE',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.warning?.natural,
        }),
      },
      {
        condition: value => value === 'VENCIDA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.attention?.natural,
        }),
      },
    ],
  },
  {
    header: 'Vence em',
    id: 'vence_em',
    type: 'string',
    sort: true,
    value: (_, item) => (item?.vence_em ? formatNewDate(item.vence_em) : ''),
  },
];
