import styled, { css } from 'styled-components';

export const Box = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;

    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      .item {
      }
    }

    .editor {
      margin-top: 30px;
      width: 100%;

      .title {
        margin-bottom: 10px;
        color: ${theme.palette.words.subtitle.natural};

        .required {
          font-size: 20px;
          font-weight: 500;
          color: red;
        }
      }
    }

    .drop {
      margin-top: 30px;
    }
  `}
`;

export const Counter = styled.span`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: end;
    font-size: 11px;
    font-weight: 600;
    padding-right: 3px;
    color: ${theme.palette.words.title.natural};
  `}
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
