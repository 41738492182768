/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  initializeData,
  setConteudo,
} from 'store/modules/provider/capacitacaoConteudo/actions';

// Components
import Templates from '../components/Templates';
import { ViewCard } from '../components/ViewCard';
import { InfoCard } from '../components/InfoCards';
import PreviewModal from '../components/ModalPreview';

// Constants
import * as services from './services';
import { useNavigate } from 'react-router-dom';
import ModalAdd from '../components/ModalAdd';
import ModalTemplates from '../components/ModalTemplates';

const HomeCapacitacao = () => {
  const { user } = useSelector(state => {
    return state.auth.user;
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contents, setContents] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [inSearch, setInSearch] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [tags, setTags] = useState([]);
  const [cards, setCards] = useState([0, 1, 2, 3]);
  const [openPreview, setOpenPreview] = useState(false);
  const [modalTemplate, setModalTemplate] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);

  // ----------------------------------REQUESTS----------------------------------//
  // Contents
  const { isLoading: loadingContent } = useQuery(
    ['home-cap-contents', 1],
    () => services.getContents({ home: true }),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setTags(data?.data?.tags);
        setContents([
          ...data?.data?.contentsEmbarcadoras,
          ...data?.data?.contents,
          ...data?.data?.contentsOnisys,
        ]);
        setAllTemplates([
          ...data?.data?.contentsEmbarcadoras.filter(
            item => item.habilitar_template,
          ),
          ...data?.data?.contents,
          ...data?.data?.contentsOnisys,
        ]);
      },
      onError: data => toast.error(data?.message),
    },
  );

  // Cards
  const { isLoading: loadingCards } = useQuery(
    ['home-cap-cards', 2],
    () => services.getCards(),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => setCards(data?.data),
      onError: data => toast.error(data?.message),
    },
  );

  // ----------------------------------CARDS ACTIONS----------------------------------//
  const previewContent = async id_cont => {
    const cont = contents.find(c => c.id === id_cont);

    const id = cont.id;

    const geral = {
      titulo: cont?.titulo,
      descricao: cont?.descricao,
      capa: cont?.imagem_capa,
      data_inicio: cont?.data_inicio,
      data_fim: cont?.data_fim,
      tags: cont?.tags,
      status: cont?.status,
    };

    const midias = cont?.midias?.map(m => {
      return {
        id: m.id,
        titulo: m.titulo,
        descricao: m.descricao,
        link: m.link,
        draggableId: m.ordem,
        ordem: m.ordem,
        tipo: m.tipo,
      };
    });

    const avaliacao = cont?.perguntas;

    dispatch(
      initializeData({
        id,
        geral,
        midias,
        avaliacao,
      }),
    );
    setOpenPreview(true);
    setInSearch(true);
  };

  const redirectEdit = id => {
    const currentContent = contents.find(c => c.id === id);

    if (currentContent?.isEmbarcadora) {
      if (currentContent?.needPublic) {
        navigate(`/capacitacao/criar?conteudo=${id}&embarcadora=true`);
      } else {
        navigate(`/capacitacao/editar/${id}?embarcadora=true`);
      }
    } else {
      navigate(`/capacitacao/editar/${id}?step=6`);
    }
  };

  return (
    <S.Container>
      <S.LineTitle>
        <h1>Olá {user?.nome}, veja seu resumo!</h1>
      </S.LineTitle>
      <S.LineHeader>
        <S.LineCards>
          {cards.map((card, index) => (
            <InfoCard key={index} card={card} loading={loadingCards} />
          ))}
        </S.LineCards>
        <S.LineView>
          <ViewCard loading={loadingCards} />
        </S.LineView>
      </S.LineHeader>

      <S.LineTemplates>
        <span onClick={() => navigate('/capacitacao/conteudos')}>
          Ver Todos
        </span>
      </S.LineTemplates>

      <Templates
        templates={contents}
        setModalAdd={() => setModalAdd(true)}
        query={[]}
        inSearch={inSearch}
        loadingScreen={loadingContent}
        loadingCard={false}
        onClickNavigateEdit={id => redirectEdit(id)}
        onClickSketch={id => {
          dispatch(setConteudo(id, 'id'));
          navigate(`/capacitacao/criar?conteudo=${id}`);
        }}
        onClickView={id => previewContent(id)}
      />

      {openPreview && (
        <PreviewModal
          open={openPreview}
          onClose={() => {
            setOpenPreview(false);
            setTimeout(() => {
              setInSearch(false);
            }, 500);
          }}
          backPath="/capacitacao/home"
        />
      )}

      <ModalAdd
        open={modalAdd}
        onClose={() => setModalAdd(false)}
        setModalTemplate={setModalTemplate}
        title="Criar Conteúdo"
        textLeft="Criar conteúdo do zero"
        textRight="Utilizar template"
        description={
          'Você poderá criar um conteúdo clicando em "Criar conteúdo do zero" ou poderá copiar um conteúdo já existente e editar como preferir clicando em "Utilizar template".'
        }
        actionRight={() => setModalTemplate(true)}
        actionLeft={() => navigate('/capacitacao/criar')}
      />

      {modalTemplate && (
        <ModalTemplates
          tags={tags}
          templates={allTemplates}
          open={modalTemplate}
          onClose={() => setModalTemplate(false)}
        />
      )}
    </S.Container>
  );
};

export default HomeCapacitacao;
