import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  width: 100%;
  color: #4b5166;
  font-size: 24px;
  font-weight: 900;
  border-bottom: 1px solid #edf2f9;
  margin-bottom: 16px;

  @media (max-width: 1400px) {
    font-size: 16px;
  }
`;
