export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'string',
    sort: false,
    align: 'center',
    width: 300,
  },
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: false,
  },
  {
    header: 'Sigla',
    id: 'sigla',
    type: 'string',
    sort: false,
    align: 'center',
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: false,
    align: 'center',
    conditional: [
      {
        condition: value => value === 'ATIVA',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'INATIVA',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
    ],
    width: 200,
  },
];
