import { Modal } from '@mui/material';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const ModalMUI = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: 90%;
  max-height: 800px;
  width: 500px;
  background-color: ${colors.white};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.greyTiny};
  padding: 15px 20px;

  .left {
    display: flex;
    align-items: center;
    justify-content: start;

    .MuiSvgIcon-root {
      color: ${colors.blueInfo};
    }

    .title {
      margin-left: 20px;
      font-size: 22px;
      font-weight: 900;
      color: ${colors.greyTitle};
    }
  }

  .right {
    .MuiSvgIcon-root {
      color: ${colors.greyTitle};
      cursor: pointer;
    }
  }
`;

export const Body = styled.div`
  padding: 20px;
  height: calc(100% - 120px);
  overflow: scroll;
`;

export const Section = styled.div`
  position: relative;

  .title {
    font-weight: 600;
    color: ${colors.greyTitle};
    font-size: 17px;
  }

  .mediaType {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .counter {
    font-weight: 600;
    color: ${colors.grey};
    font-size: 13px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 5px;
    margin-bottom: 2px;
  }

  &.grid {
    display: flex;
    gap: 20px;

    .infos {
      position: relative;
      flex: 2;
    }

    .time {
      position: relative;
      flex: 1;
    }
  }
`;

export const ButtonMedia = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid
    ${props => (props.active ? colors.blueInfo : colors.greyBorder)};
  flex: 1;
  padding: 8px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  height: 43px;

  input {
    display: none;
  }

  .ld {
    position: relative;
    width: 100%;
    height: 100%;
    transform: scale(0.4);
  }

  &:hover {
    background-color: ${colors.greyBorder};
    opacity: 0.6;
  }

  .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 5px;
    color: ${props => (props.active ? colors.blueInfo : colors.greyTitle)};
  }

  span {
    font-weight: 600;
    color: ${props => (props.active ? colors.blueInfo : colors.greyTitle)};
  }
`;

export const BoxVideo = styled.div`
  width: 100%;
  height: 180px;
  border: 2px solid
    ${props => (props.invalid ? colors.redDanger : colors.greyTiny)};
  margin-top: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ButtonCase = styled.label`
  display: flex;
  border: 1px solid ${colors.greyTiny};
  border-radius: 4px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${colors.greySubtitle};
  margin-bottom: 10px;
  margin-top: -6px;
  gap: 4px;
  cursor: pointer;

  input {
    display: none;
  }

  &:hover {
    opacity: 0.8;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }

  .ld {
    position: relative;
    width: 100%;
    height: 100%;
    transform: scale(0.4);
  }
`;

export const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;

  .MuiSvgIcon-root {
    font-size: 30px;
    color: ${colors.grey};
  }

  p {
    font-size: 16px;
    color: ${colors.grey};
    font-weight: 600;
  }

  &.error {
    .MuiSvgIcon-root {
      color: ${colors.redDanger};
    }

    p {
      color: ${colors.redDanger};
    }
  }
`;

export const CheckArea = styled.div`
  margin-top: 20px;

  .req {
    font-weight: 700;
    font-size: 17px;
    color: ${colors.redDanger};
  }

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .checks {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
  }
`;

export const Footer = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  border-top: 1px solid ${colors.greyTiny};
  position: absolute;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  background-color: ${colors.white};
  width: 100%;
  bottom: 0;
  gap: 15px;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 60px;
    cursor: pointer;
    transform: scale(1.2);

    &:hover {
      opacity: 0.6;
    }

    p {
      font-size: 16px;
      color: ${colors.greyTitle};
      font-weight: 600;
    }
  }
`;
