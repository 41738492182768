import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    overflow: hidden;
    width: 100vw;
    padding: 7rem 0;
    h1 {
      font: normal normal 900 32px/45px Texta;
    }
    p {
      color: ${theme.palette.words.subtitle.natural};
      font: normal normal medium 18px/25px Texta;
      margin: 1.5rem 0;
      max-width: 24rem;
    }

    ::before {
      content: '';
      position: absolute;
      bottom: -150px;
      left: -150px;
      width: 280px;
      height: 300px;
      background: ${theme.palette.semantics.feedback.unknown.natural}a2;
      border-radius: 50%;
    }

    ::after {
      content: '';
      position: absolute;
      top: -150px;
      right: -150px;
      width: 280px;
      height: 300px;
      background: ${theme.palette.brand.secondary.light}a2;
      border-radius: 50%;
    }
  `}
`;

export const Content = styled.div`
  width: 80vw;
  max-width: 800px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
`;
