import api from '../../../services/api';

export const getCae = async id => {
  const res = await api.get(`/cae/${id}`);
  return res;
};

export const requestDrivers = async () => {
  const res = await api.get('/select-drivers');
  return res;
};

export const requestTrucks = async () => {
  const res = await api.get('/select-trucks');
  return res;
};

export const requestFilials = async () => {
  const res = await api.get('/select-filials');
  return res;
};

export const createCae = async query => {
  const res = await api.post(`/create-cae`, query);
  return res;
};

export const updateCae = async (id, query) => {
  const res = await api.put(`/cae/${id}`, query);
  return res;
};

export const approveCae = async data => {
  const res = await api.put(`/cae/${data.id}`, data);
  return res.data;
};
