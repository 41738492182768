import styled, { keyframes } from 'styled-components';

// Animação que muda a cor de fundo
const loading = keyframes`
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
`;

const SkeletonBar = styled.div`
  width: 90%;
  height: 20px;
  background-color: #e0e0e0;
  margin: 5px 0;
  border-radius: 4px;
  animation: ${loading} 1.5s ease-in-out infinite; // Aplicando a animação
`;

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: #f0f0f0;

  border-radius: 8px;
  opacity: 0.4;
`;

const SkeletonHeader = styled.div`
  width: 80%;
  height: 20px;
  background-color: #e0e0e0;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const GraphSkeleton = () => {
  return (
    <SkeletonContainer>
      <SkeletonHeader />
      {Array.from({ length: 5 }).map((_, index) => (
        <SkeletonBar key={`skeleton-bar-${index}`} />
      ))}
    </SkeletonContainer>
  );
};

export default GraphSkeleton;
