/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import ReactPlayer from 'react-player';
import React, { useState, useEffect } from 'react';

// Components
import TextInput from 'components/Inputs/TextField';

// Material UI
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const Video = ({
  item,
  setChanged,
  media,
  index,
  setOpenConfirmDelete,
  deleteMedia,
  error,
  isPublished,
}) => {
  const theme = useTheme();
  const [video, setVideo] = useState(item.link);
  const [videoTexto, setVideoTexto] = useState(item.titulo);
  const [videoDescription, setVideoDescription] = useState(item.descricao);

  useEffect(() => {
    Object.assign(media[index], {
      link: video,
      tipo: 'VÍDEO',
      descricao: videoDescription,
      titulo: videoTexto,
    });
    setChanged(true);
  }, [video, videoTexto, videoDescription]);

  const hanldeDelete = () => {
    if (video || videoTexto || videoDescription) {
      setOpenConfirmDelete(true);
    } else {
      setChanged(true);
      deleteMedia();
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={() => hanldeDelete()}>
          <DeleteOutlineOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.attention.natural}
          />
        </IconButton>
      </div>
      <S.Container>
        <TextInput
          label="Titulo"
          placeholder="Titulo"
          value={videoTexto}
          onChange={e => setVideoTexto(e.target.value.slice(0, 200))}
          error={error?.titulo && !videoTexto}
          disabled={isPublished && !!item.id}
        />
        <TextInput
          label="Descrição"
          placeholder="Descrição"
          value={videoDescription}
          onChange={e => setVideoDescription(e.target.value.slice(0, 200))}
          error={error?.descricao && !videoDescription}
          disabled={isPublished && !!item.id}
        />
        <TextInput
          label="Link do Vídeo"
          placeholder="Youtube ou Vimeo"
          value={video}
          onChange={e => setVideo(e.target.value)}
          error={error?.link && !video}
          disabled={isPublished && !!item.id}
        />
        <div
          style={{
            display: 'flex',
            marginTop: 15,
            justifyContent: 'center',
          }}
        >
          {video && (video.includes('youtu') || video.includes('vimeo')) && (
            <ReactPlayer
              width="100%"
              height="500px"
              controls={false}
              playing={false}
              url={video}
            />
          )}

          {video && !video.includes('youtu') && !video.includes('vimeo') && (
            <S.InvalidVideo>
              <div className="text">LINK INVÁLIDO</div>
            </S.InvalidVideo>
          )}
        </div>
      </S.Container>
    </div>
  );
};
