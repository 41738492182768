import api from '../../../../services/api';

export const requestDriversGeral = async query => {
  const res = await api.get(`/drivers/estatisticas/geral`, {
    params: query,
  });
  return res;
};

export const requestDriversPerfil = async (id, query) => {
  const res = await api.get(`/drivers/estatisticas/perfil/${id}`, {
    params: query,
  });
  return res;
};

export const requestDriversLastInfractions = async (id, query) => {
  const res = await api.get(`/drivers/estatisticas/perfil/desvios/${id}`, {
    params: query,
  });
  return res;
};
