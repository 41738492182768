import React, { useEffect, useRef, useState } from 'react';
import fileUpload from 'images/image_file_2.png';
import { toast } from 'react-toastify';
import { firestorage } from 'utils/firebase';
import * as S from './styled';

const DropZone = ({
  disabled,
  setFileName,
  height,
  width,
  setFileUrl,
  fileUrl,
  cancelImage,
  id_layout,
  base,
}) => {
  const [currentFile, setCurrentFile] = useState(null);
  const fileInputRef = useRef();

  const drag = event => {
    event.preventDefault();
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const filesSelected = async () => {
    if (fileInputRef.current?.files?.length) {
      const { files } = fileInputRef.current;
      const file = fileInputRef.current.files[0];
      if (files.length > 1) {
        toast.error('Só é permitido fazer upload de uma imagem por vez');
      } else {
        setCurrentFile(file);
      }
    }
  };

  const fileDrop = event => {
    event.preventDefault();

    const files = event?.dataTransfer?.files;
    const file = event?.dataTransfer?.files[0];
    if (files.length > 1) {
      toast.error('Só é permitido fazer upload de uma imagem por vez');
    } else {
      setCurrentFile(file);
    }
  };

  const validateFile = file => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const savedFileFirebase = async data => {
    const uploadTask = firestorage
      .ref(`/layout/${base.sigla}/${id_layout}/${data.name}`)
      .put(data);
    uploadTask.on(
      'state_changed',
      snapShot => {},
      err => {
        console.log('Err', err);
      },
      () => {
        firestorage
          .ref(`/layout/${base.sigla}/${id_layout}/`)
          .child(data.name)
          .getDownloadURL()
          .then(fireBaseUrl => {
            setFileUrl(fireBaseUrl);
          });
      },
    );
  };

  useEffect(() => {
    if (cancelImage) {
      setFileName && setFileName(currentFile);
    } else {
      handleFiles(currentFile);
    }
  }, [currentFile, cancelImage]);

  const handleFiles = data => {
    if (data) {
      if (validateFile(data)) {
        savedFileFirebase(data);
        setFileName && setFileName(data);
      } else {
        toast.error('Tipo de arquivo inválido');
      }
    }
  };

  return (
    <S.Container height={height} width={width}>
      <S.DropContainer
        onDragOver={drag}
        onDragEnter={drag}
        onDragLeave={drag}
        onDrop={fileDrop}
        onClick={fileInputClicked}
        height={height}
        width={width}
        file={!!fileUrl}
      >
        {!fileUrl && (
          <>
            <S.Message>
              <S.Image>
                <img src={fileUpload} alt={fileUpload} />
              </S.Image>
              <p>Solte o arquivo aqui ou clique para fazer upload</p>
              <p>( jpeg, jpg ou png )</p>
            </S.Message>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple
              onChange={filesSelected}
              disabled={disabled}
            />
          </>
        )}
        {fileUrl && (
          <S.ImageURL
            height={height}
            width={width}
            src={fileUrl}
            alt={fileUrl}
          />
        )}
      </S.DropContainer>
    </S.Container>
  );
};

export default DropZone;
