import api from 'services/api';

export const createRoute = async data => {
  const res = await api.post('/provider/rotograma', data);
  return res;
};

export const editRoute = async data => {
  const res = await api.put(`/provider/rotograma/${data.id}`, data);
  return res;
};

export const deleteRotograma = async data => {
  const res = await api.delete(`/provider/rotograma/${data.id}`, data);
  return res;
};

export const getRotogramas = async query => {
  const res = await api.get('/provider/rotograma', { params: query });
  return res.data;
};

export const requestCards = async query => {
  const res = await api.get('/provider/rotograma-cards', { params: query });
  return res;
};

export const requestExcel = async query => {
  const res = await api.get('/excel-provider/rotograma', { params: query });
  return res;
};

export const requestLogs = async id => {
  const res = await api.get(`/provider/rotograma/logs/${id}`);
  return res.data;
};

export const requestRotogramaRotas = async query => {
  const res = await api.get('/provider/rotograma-rotas', { params: query });
  return res;
};
