import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100vw;
    min-height: 22rem;
    background: ${theme.palette.words.title.natural};
    color: ${theme.palette.words.title.contrast};
  `}
`;

export const Content = styled.div`
  max-width: 1200px;
  padding: 6rem 2rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: start;
  justify-content: space-between;

  @media only screen and (min-width: 640px) {
    flex-direction: row;
  }
`;

export const LogoWrapper = styled.img`
  width: 150px;
`;

export const IconList = styled.div`
  ${({ theme }) => css`
    padding: 2rem 0;
    font: normal normal 600 16px/23px Texta;
    color: ${theme.palette.words.subtitle.natural};
    ul {
      display: flex;
      gap: 1rem;
    }
  `}
`;

export const List = styled.div`
  ${({ theme }) => css`
    margin-right: 1rem;

    ul li {
      padding-bottom: 1rem;
      font: normal normal 600 16px/23px Texta;
    }
    a {
      text-decoration: none;
      color: ${theme.palette.words.subtitle.light};
    }
    span {
      color: ${theme.palette.words.subtitle.contrast};
    }
  `}
`;

export const Copyrights = styled.div`
  ${({ theme }) => css`
    background: inherit;
    display: flex;
    justify-content: center;

    span {
      width: 100%;
      max-width: 1200px;
      padding: 3rem 0;
      font: normal normal 600 14px/20px Texta;
      border-top: 0.5px solid ${theme.palette.words.subtitle.natural};
      text-align: center;
    }
  `}
`;
