// React
import { useEffect, useState } from 'react';

// Styles
import { useTheme } from 'styled-components';

// Components
import Icon from 'components/Icons';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Components MI
import { DeleteOutline } from '@mui/icons-material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Tooltip } from '@mui/material';
import TextInput from '../../TextField';
import * as S from './styled';

const EditModalFile = ({
  data, // Itens a serem exibidos no modal
  activeItem = 0, // Item que é exibido ao inicializar modal
  setData, // Formatação do objeto que compõe o modal
  open, // Determina abertura
  setOpen, // Controle abertura e fechamento do modal
  automaticSave, // Verifica se o modal deve ser async
  setItemDelete, // Identifição do item que deve ser deletado
  handleDelete, // Chama função que verifica onde deve ser feito o delete (DB ou Local)
  itemEdit, // Identifição do item que deve ser editado
  editInDatabase, // Chama função que executa o edit
  haveLegend, // Determina se a legenda será exibida no modal
  deleteFunction, // Chama função que executa o delete da imagem
  setItemEdit, // Array com os itens que devem ser editados
  onlyRead,

  inputRef,
  handleInput,
  multiple,
  accept,
  newImagesByModal,
}) => {
  const theme = useTheme();
  // General States
  const [margin, setMargin] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalData, setModalData] = useState(data);

  useEffect(() => {
    setModalData(data);
  }, [data]);

  // --------------Configure Slide and Postion----------------------//
  useEffect(() => {
    setMargin(activeItem * 540);
    calcPosition(activeItem * 540);
    setCurrentItem(activeItem);
  }, [activeItem]);

  const handleNext = () => {
    const total = data.length;
    const totalMargin = 540 * total - 540;
    if (margin < totalMargin) {
      setMargin(margin + 540);
      calcPosition(margin + 540);
    }
  };

  const handleBack = () => {
    if (margin > 0) {
      setMargin(margin - 540);
      calcPosition(margin - 540);
    }
  };

  const calcPosition = currentPos => {
    const pos = currentPos / 540;
    setCurrentItem(pos);
  };

  // ----------------------Controll Input text----------------------//
  const [legend, setLegend] = useState('');
  useEffect(() => {
    toggleData(data);
  }, [currentItem]);

  const toggleLegend = value => {
    const copyData = [...data];
    copyData[currentItem].legenda = value.slice(0, 100) || '';

    setModalData(copyData);
    toggleData(copyData);

    if (copyData[currentItem].id) {
      if (itemEdit) {
        let toggled = false;
        const copyItemEdit = [...itemEdit];

        for (const i in itemEdit) {
          if (copyData[currentItem].id === itemEdit[i].id) {
            copyItemEdit[i].legenda = value;
            toggled = true;
          }
        }

        if (!toggled) {
          setItemEdit([
            ...itemEdit,
            {
              id: copyData[currentItem].id,
              legenda: value,
            },
          ]);
        } else {
          setItemEdit(copyItemEdit);
        }
      } else {
        setItemEdit([
          {
            id: copyData[currentItem].id,
            legenda: value,
          },
        ]);
      }
    }
  };

  const toggleData = archive => {
    if (modalData[0]) {
      const newLegend = archive[+currentItem]?.legenda;
      setLegend(newLegend);
    }
  };

  // -----------------------------Save------------------------------//
  const handleSave = async () => {
    setData(modalData);
    editInDatabase();
    setOpen();
  };

  const resetForce = () => {
    setCurrentItem(1);
    setTimeout(() => {
      setCurrentItem(0);
    }, 100);
  };

  return (
    <>
      <S.ModalType open={open}>
        <S.Background>
          <S.Header>
            <S.LeftHeader>
              <ImageOutlinedIcon
                htmlColor={theme.palette.brand.secondary.natural}
              />
              <S.Title>
                {!onlyRead ? `Editar Fotos ` : 'Visualização de Arquivos '}
                <span>{`( ${currentItem + 1} de ${data.length} )`}</span>
              </S.Title>
            </S.LeftHeader>

            <div className="right">
              {newImagesByModal && !onlyRead && (
                <label>
                  {newImagesByModal}
                  <input
                    type="file"
                    ref={inputRef}
                    onChange={() => handleInput()}
                    multiple={multiple}
                    accept={accept}
                    disabled={onlyRead}
                  />
                </label>
              )}

              <span onClick={() => setOpen()} style={{ cursor: 'pointer' }}>
                <Icon
                  name="close"
                  size={25}
                  color={theme.palette.brand.primary.light}
                />
              </span>
            </div>
          </S.Header>
          <S.ImagesArea>
            <div className="arrow back" onClick={() => handleBack()}>
              <ArrowBackIosNewOutlinedIcon
                htmlColor={theme.palette.brand.primary.light}
              />
            </div>

            <S.Slider width={data.length} margin={margin}>
              {typeof data === 'object' &&
                data.map((imagem, idx) => (
                  <S.Sliders key={idx}>
                    <embed src={imagem.imagem_url} draggable={false} />
                  </S.Sliders>
                ))}
            </S.Slider>

            <div className="arrow next" onClick={() => handleNext()}>
              <ArrowForwardIosOutlinedIcon
                htmlColor={theme.palette.brand.primary.light}
              />
            </div>
          </S.ImagesArea>
          <S.LegendArea haveLegend={haveLegend}>
            {data[0] && haveLegend && (
              <>
                {!onlyRead && (
                  <TextInput
                    placeholder="Insira sua Legenda"
                    value={legend}
                    onChange={e => toggleLegend(e.target.value)}
                    disabled={onlyRead}
                    noAsterisk
                  />
                )}
                {onlyRead && (
                  <Tooltip title={legend}>
                    <span>
                      <TextInput
                        placeholder="Insira sua Legenda"
                        value={legend}
                        onChange={e => toggleLegend(e.target.value)}
                        disabled={onlyRead}
                        noAsterisk
                      />
                    </span>
                  </Tooltip>
                )}
              </>
            )}
          </S.LegendArea>
          <S.ButtonsArea>
            <div className="areaLeft">
              {deleteFunction && !onlyRead && (
                <S.ButtonDelete
                  onClick={() => {
                    setDeleteModal(true);
                    setItemDelete(data[currentItem]);
                  }}
                >
                  Excluir
                </S.ButtonDelete>
              )}
            </div>
            <div className="areaRight">
              <GhostButton onClick={() => setOpen()} children="Voltar" />
              {!onlyRead && (
                <DefaultButton
                  style={{
                    marginLeft: '10px',
                  }}
                  children={automaticSave ? 'Salvar Alterações' : 'Concluir'}
                  onClick={() => handleSave()}
                />
              )}
            </div>
          </S.ButtonsArea>
        </S.Background>
      </S.ModalType>

      <ConfirmModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        title="Tem certeza que deseja excluir essa imagem?"
        titleIcon={
          <DeleteOutline
            sx={{ color: theme.palette.semantics.feedback.attention.natural }}
            fontSize="medium"
          />
        }
        subtitle={
          automaticSave
            ? 'Essa ação não poderá ser desfeita'
            : 'Essa ação será concluida após salvar as informações'
        }
        buttonText="Confirmar"
        onClick={() => {
          handleDelete();
          setDeleteModal(false);
          if (data.length > 0) {
            if (!currentItem) {
              resetForce();
            } else {
              setCurrentItem(0);
              setMargin(0);
            }
          }
        }}
      />
    </>
  );
};

export default EditModalFile;
