import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    min-width: 60vw;
    text-align: left;
    border-radius: 4px;
    border: solid 1px ${theme.palette.system.border};
    padding: 2rem;
    margin-bottom: 2rem;

    p {
      font: normal normal bold 21px/30px Texta;
      letter-spacing: 0px;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;

export const PartnerInfo = styled.div`
  margin: 2rem 0;
  display: flex;
  gap: 2rem;

  h2 {
    font: normal normal 900 21px/29px Texta;
  }
  p {
    font: normal normal 600 18px/25px Texta;
  }
`;

export const PartnerAvatar = styled.img`
  width: 116px;
  height: 116px;
  border-radius: 50%;
`;

export const PartnerLogo = styled.img`
  width: 10rem;
`;

export const Control = styled.div`
  position: absolute;
  display: flex;
  right: 4rem;
  bottom: 3rem;
`;
