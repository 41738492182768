import React, { useEffect, useState, useMemo } from 'react';
import { subDays, format, isValid, parse, getISOWeek } from 'date-fns';
import { useSelector } from 'react-redux';
import { ptBR } from 'date-fns/locale';

// components custom
import Tabs from './components/Tabs';
import { Graph } from './components/Graph';
import { BarraPercentual } from './components/BarPercent';
import { DefaultTable } from 'components/_Table/templates/default';
import FiltersGlobal from 'components/FiltersGlobal';
import RadarDesvios from './components/RadarDesvios';

// styled
import { Container, HeaderDesvios, BoxGraphs } from './styled';

// constants
import {
  tabsItemsPth,
  tabsItemsPtkm,
  colorsCriticidade,
  tabsItemsPthsProvider,
  tabsItemsPtkmProvider,
  tabsRadar,
} from './constants';
import { columns } from './columns';

// services
import {
  getDesempenhoPtsH,
  getDesvioCriticidade,
  getDesvioCriticidadeTipo,
  getPadroesCriticidade,
  getDesempenhoPtsKm,
  getExportDesempenhoPtsKm,
  getExportDesempenhoPtsH,
  getHistorico,
  getRadarDesvios,
} from './services';
import { usePlans } from 'hooks/usePlans';
import { toast } from 'react-toastify';
import { HistoryPoints } from './components/HistoryPoints';
import { useQuery } from 'react-query';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

const DesempenhoGeral = () => {
  const { desviosTiposPadrao, clients, criticidade } =
    useFetchMultipleWithCache();
  const defaultDate = {
    initialDate: subDays(new Date(), 30),
    finalDate: new Date(),
  };
  const selects = useSelector(state => state.selects);

  const filiais =
    selects.filials?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];
  const { isProvider, hasMonitoramento, hasTelemetria } = usePlans();

  const empresas =
    selects?.empresas?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  // tabs ptskm e ptsH
  const [currentTab, setCurrentTab] = useState(0);

  // states ptskm
  const [pontoskm, setPontoskm] = useState([]);

  // states ptsH
  const [PontosH, setPontosH] = useState([]);

  // states compartilhados
  const [currentSectionGraph, setCurrentSectionGraaph] = useState(
    isProvider ? 'Empresas' : 'Desvios',
  );
  const [openIntervalDesvios, setOpenIntervalDesvios] = useState(defaultDate);
  const [loadingTable, setLoadingTable] = useState(false);
  const [filtersRadar, setFilterRadar] = useState(defaultDate);
  const [meta, setMeta] = useState(0);
  const [filtersPage, setFiltersPage] = useState(defaultDate);
  const [filtersPageCriticidade, setFiltersPageCriticidade] = useState(null);
  const [desvioCriticidade, setDesvioCriticidade] = useState([]);
  const [optionsDesvio, setOptionsDesvio] = useState([]);
  const [currentOptionSelect, setCurrentOptionsSelect] = useState(null);
  const [desvioCriticidadeList, setDesvioCriticidadeList] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [periodDate, setPeriodDate] = useState('day');

  const [historicoTab, setHistoricoTab] = useState(
    hasMonitoramento ? 'ptsHr' : 'ptsKm',
  );
  const [historyDates, setHistoryDates] = useState(defaultDate);

  const handlePageTab = (event, newValue) => {
    setCurrentSectionGraaph(isProvider ? 'Empresas' : 'Desvios');
    setCurrentTab(newValue);
  };

  const initial = new Date(historyDates.initialDate);
  const final = new Date(historyDates.finalDate);

  const differenceInDays = (final - initial) / (1000 * 60 * 60 * 24);

  const formatarNome = (item, periodo, isKey = false) => {
    const { dia, mes, ano } = item;
    switch (periodo) {
      case 'day':
        return isKey
          ? `${String(dia).padStart(2, '0')}/${String(mes).padStart(
              2,
              '0',
            )}/${ano}`
          : `${String(dia).padStart(2, '0')}`;
      case 'month':
        const date = new Date(ano, mes - 1, dia);
        const formatDate = format(date, 'MMM yy', { locale: ptBR });
        return formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
      case 'week':
        const data = new Date(ano, mes - 1, dia);
        const numeroSemana = getISOWeek(data);
        return `S-${String(numeroSemana).padStart(2, '0')}-${ano}`;
      default:
        throw new Error('Período inválido');
    }
  };

  const definirAgrupamento = (periodType, differenceInDays) => {
    if (periodType === 'day') {
      if (differenceInDays > 31 && differenceInDays < 24 * 7) {
        return 'week';
      }
      if (differenceInDays > 31 && differenceInDays >= 24 * 7) {
        return 'month';
      }
    } else if (periodType === 'week') {
      if (differenceInDays > 24 * 7) {
        return 'month';
      }
    }
    return periodType;
  };

  const aggregateDataByPeriod = (data, periodType) => {
    const _data = data?.reduce((acumulador, item) => {
      const [dia, mes, ano] = item.name.split('/');
      const group = definirAgrupamento(periodType, differenceInDays);

      const { meta, x } = item;
      const name = formatarNome({ dia, mes, ano }, group);
      const key = formatarNome({ dia, mes, ano }, group, true);
      const existente = acumulador.find(r => r.key === key);

      if (existente) {
        existente.x += x;
        existente.count += 1;
      } else {
        acumulador.push({
          name,
          meta,
          x,
          count: 1,
          key,
        });
      }

      return acumulador;
    }, []);

    return _data.map(d => {
      return { ...d, x: Number((d.x / d.count).toFixed(2)) };
    });
  };

  const transformData = (data, periodType = 'day') => {
    const processedData = aggregateDataByPeriod(data, periodType);

    return processedData.filter(item => item.name);
  };

  const fetchDesvioCriticidade = async () => {
    setCurrentOptionsSelect(null);
    const params = {
      ...openIntervalDesvios,
      filial: filtersPageCriticidade?.filiais || null,
      client: filtersPageCriticidade?.cliente,
      empresas: filtersPageCriticidade?.empresas || null,
    };
    const res = await getDesvioCriticidade(params);
    setDesvioCriticidade(res.data);
    if (res.fator_risco?.length)
      setCurrentOptionsSelect(res.fator_risco[0]?.id);
  };

  const fetchDataExport = async () => {
    const params = {
      ...filtersPage,
      tab: currentSectionGraph,
    };
    const res =
      currentTab === 0
        ? await getExportDesempenhoPtsKm(params)
        : await getExportDesempenhoPtsH(params);

    return res;
  };

  const downloadFile = async () => {
    const data = await fetchDataExport();
    if (data?.data?.link) {
      const anchor = document.createElement('a');
      anchor.href = data?.data?.link;
      anchor.download = 'download';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } else {
      toast.error(data.message);
    }
  };

  const fetchDesvioCriticidadeTipo = async () => {
    setLoadingTable(true);
    const [idDesvioTipo, criticidade] = String(currentOptionSelect).split('-');

    const params = {
      idDesvioTipo: parseInt(idDesvioTipo),
      criticidade,
      ...openIntervalDesvios,
      filial: filtersPageCriticidade?.filiais || null,
      client: filtersPageCriticidade?.cliente,
      empresas: filtersPageCriticidade?.empresas || null,
    };
    const response = await getDesvioCriticidadeTipo(params);
    setDesvioCriticidadeList(response || []);
    setLoadingTable(false);
  };

  const tabs = useMemo(() => {
    let filteredTabs = [];

    if (hasMonitoramento) {
      filteredTabs.push({ value: 0, label: 'Pontos/hora' });
    }
    if (hasTelemetria) {
      filteredTabs.push({ value: 1, label: 'Pontos/km' });
    }

    return filteredTabs;
  }, [hasMonitoramento, hasTelemetria]);

  const historyTabs = useMemo(() => {
    let filteredTabs = [];
    if (hasMonitoramento) {
      filteredTabs.push({ value: 'ptsHr', label: 'PONTOS/HORA' });
    }
    if (hasTelemetria) {
      filteredTabs.push({ value: 'ptsKm', label: 'PONTOS/KM' });
    }
    return filteredTabs;
  }, [hasMonitoramento, hasTelemetria]);

  const { isLoading: loadingPtsHora } = useQuery(
    ['pontos-por-hora', currentSectionGraph, filtersPage, currentTab],
    () =>
      getDesempenhoPtsH({
        ...filtersPage,
        tab: currentSectionGraph,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: hasMonitoramento, // Desabilita a execução automática
      onSuccess: res => {
        setPontosH(res?.data || []);
        setMeta(res?.meta || 0);
      },
    },
  );

  const { isLoading: loadingPtsKm } = useQuery(
    ['pontos-por-km', currentSectionGraph, filtersPage, currentTab],
    () =>
      getDesempenhoPtsKm({
        ...filtersPage,
        tab: currentSectionGraph,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: hasTelemetria, // Desabilita a execução automática
      onSuccess: res => {
        setPontoskm(res?.data || []);
        setMeta(res?.meta || 0);
      },
    },
  );

  const { data: _historyData, isFetching: isLoadingHistory } = useQuery(
    ['empresa-historico', historyDates],
    () => getHistorico({ ...historyDates }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: resDataRadar, isFetching: loadingRadar } = useQuery(
    ['radar-desvios', filtersRadar],
    () => getRadarDesvios(filtersRadar),
    {
      onError: error => toast.error(error),
      refetchOnWindowFocus: false,
    },
  );

  const { isFetching: loadingPadroesCriticidade } = useQuery(
    ['radar-desvios', openIntervalDesvios],
    () =>
      getPadroesCriticidade({
        ...openIntervalDesvios,
      }),
    {
      onError: error => toast.error(error),
      refetchOnWindowFocus: false,
      onSuccess: res => {
        const data =
          res?.data.length > 0
            ? res?.data.map(item => {
                return {
                  value: item?.id,
                  label: item?.nome,
                  criticality: item?.criticidade,
                  color: colorsCriticidade[item?.criticidade],
                };
              })
            : [];
        setOptionsDesvio(data);
      },
    },
  );

  useEffect(() => {
    if (!hasMonitoramento && hasTelemetria) {
      setCurrentTab(1);
    }
  }, []);

  useEffect(() => {
    fetchDesvioCriticidade();
  }, [openIntervalDesvios, filtersPageCriticidade]);

  useEffect(() => {
    if (currentOptionSelect) fetchDesvioCriticidadeTipo();
  }, [openIntervalDesvios, currentOptionSelect]);

  useEffect(() => {
    if (_historyData?.success) {
      const _data =
        _historyData?.[isProvider ? 'global' : 'empresa']?.reduce(
          (acc, item) => {
            acc.push({
              name: item.period,
              x: historicoTab === 'ptsHr' ? item.pontos_horas : item.pontos_kms,
              meta:
                historicoTab === 'ptsHr'
                  ? _historyData.meta_pontos_hora ?? 5
                  : _historyData.meta_pontos_km ?? 10,
            });
            return acc;
          },
          [],
        ) ?? [];

      setHistoryData(transformData(_data, periodDate));
    } else {
      setHistoryData([]);
    }
  }, [_historyData, historicoTab, periodDate]);

  const filtersDefault = [
    {
      filterName: 'filial',
      label: 'Filiais',
      options: filiais || [],
    },
    {
      filterName: 'client',
      label: 'Clientes',
      options: clients?.data || [],
    },
    {
      filterName: 'desvio',
      label: 'Desvios',
      options: desviosTiposPadrao.data || [],
    },
    {
      filterName: 'criticidade',
      label: 'Criticidade',
      options: criticidade.data || [],
    },
  ];

  const filtersDefaultProvider = [
    {
      filterName: 'desvio',
      label: 'Desvios',
      options: desviosTiposPadrao.data || [],
    },
    {
      filterName: 'criticidade',
      label: 'Criticidade',
      options: criticidade.data || [],
    },
    {
      filterName: 'empresa',
      label: 'Empresa',
      options: empresas || [],
    },
  ];

  return (
    <Container>
      <FiltersGlobal
        hideRefleshButton
        data={
          isProvider
            ? filtersDefaultProvider.filter(
                item => item.filterName !== 'criticidade',
              )
            : filtersDefault.filter(item => item.filterName !== 'criticidade')
        }
        handleFilters={val => setFiltersPage({ ...filtersPage, ...val })}
        customComponent={
          <Tabs value={currentTab} items={tabs} onChange={handlePageTab} />
        }
        handleExport={() => {
          downloadFile();
        }}
      />
      <Graph
        currentTab={currentTab}
        data={
          currentTab === 0
            ? PontosH.sort((a, b) => b.qtde_atual - a.qtde_atual)
            : pontoskm.sort((a, b) => b.qtde_atual - a.qtde_atual)
        }
        metaValue={meta}
        tabsItems={
          currentTab === 0
            ? isProvider
              ? tabsItemsPtkmProvider
              : tabsItemsPtkm
            : isProvider
            ? tabsItemsPthsProvider
            : tabsItemsPth
        }
        isLoading={loadingPtsHora || loadingPtsKm}
        currentSelection={currentSectionGraph}
        handleTabClick={newValue => setCurrentSectionGraaph(newValue)}
      />

      <BoxGraphs style={{ marginTop: 56 }}>
        <div className="main-header">Histórico de pontos</div>
        <FiltersGlobal
          hideRefleshButton
          data={
            isProvider
              ? filtersDefaultProvider.filter(
                  item => item.filterName === 'empresa',
                )
              : filtersDefault.filter(item => item.filterName === 'empresa')
          }
          enablePeriodFilters
          handlePeriodFilter={period => setPeriodDate(period)}
          hideExportButton
          handleFilters={val => setHistoryDates({ ...historyDates, ...val })}
          customComponent={
            <Tabs value={currentTab} items={tabs} onChange={handlePageTab} />
          }
          handleExport={() => {
            downloadFile();
          }}
        />
        <HistoryPoints
          interval={
            periodDate === 'day'
              ? 0 // Exibe todos os dias
              : periodDate === 'week'
              ? 0 // Mostra só uma semana por vez
              : periodDate === 'month'
              ? 0 // Mostra só um mês por vez
              : 'auto'
          }
          isLoading={isLoadingHistory}
          data={historyData || []}
          tab={historicoTab}
          tabs={historyTabs}
          dates={historyDates}
          handleChangeTab={value => setHistoricoTab(value)}
          handleDate={dates => setHistoryDates(dates)}
        />
      </BoxGraphs>

      <div style={{ marginBottom: 42 }}>
        <RadarDesvios
          empresas={empresas}
          filiais={filiais}
          clientes={clients}
        />
      </div>
      <HeaderDesvios>
        <FiltersGlobal
          hideRefleshButton
          data={
            isProvider
              ? filtersDefaultProvider.filter(
                  item =>
                    item.filterName !== 'desvio' &&
                    item.filterName !== 'criticidade',
                )
              : filtersDefault.filter(
                  item =>
                    item.filterName !== 'desvio' &&
                    item.filterName !== 'criticidade',
                )
          }
          handleFilters={val =>
            setOpenIntervalDesvios({ ...openIntervalDesvios, ...val })
          }
          hideExportButton
          customComponent={
            <div className="headerText">
              <span className="main-header">Desvios por criticidade</span>
              <span className="sub-header">(Total de pontos)</span>
            </div>
          }
          handleExport={() => {
            downloadFile();
          }}
        />
      </HeaderDesvios>
      <BarraPercentual
        isLoading={loadingPadroesCriticidade}
        value={currentOptionSelect}
        data={desvioCriticidade}
        selectOptions={optionsDesvio}
        handleSelect={val => setCurrentOptionsSelect(val)}
      />
      <DefaultTable
        data={desvioCriticidadeList}
        columns={columns(isProvider)}
        searchKeys={['nome']}
        loading={loadingTable}
        placeholder="Buscar"
        sortBy={{ id: 'status', order: 'ASC' }}
        searchEvent={search =>
          trackEvent(user, 'Busca desvio por criticidade', null, search)
        }
        empty={{
          title: 'Nenhum desvio encontrado',
          description: '',
          image: 'frota.png',
        }}
      />
    </Container>
  );
};

export default DesempenhoGeral;
