import { useTheme } from 'styled-components';

import { SvgIcon } from '@mui/material';
import * as S from './styled';
import { steps } from '../../Config/constants';

import { itensBar } from './constants';

export const Sidebar = ({ page, setPage, setChanged }) => {
  const theme = useTheme();
  const handlePage = i => {
    if (i.id !== page) {
      setPage(steps[i.id]);
      setChanged(false);
    }
  };

  return (
    <S.Container>
      <p className="titleSec">Publique</p>
      {itensBar.map((i, k) => {
        if (k < 4) {
          return (
            <span
              onClick={() => handlePage(i)}
              className={page === i.id ? 'navBarItem active' : 'navBarItem'}
              key={k}
            >
              <SvgIcon
                component={i.icon}
                stroke={
                  page === i.id
                    ? theme.palette.brand.secondary.natural
                    : theme.palette.brand.primary.light
                }
              />
              <span>{i.page}</span>
            </span>
          );
        }
      })}
      <p className="titleSec">Analise</p>
      {itensBar.map((i, k) => {
        if (k >= 4) {
          return (
            <span
              onClick={() => handlePage(i)}
              className={page === i.id ? 'navBarItem active' : 'navBarItem'}
              key={k}
            >
              <SvgIcon
                component={i.icon}
                stroke={
                  page === i.id
                    ? theme.palette.brand.secondary.natural
                    : theme.palette.brand.primary.light
                }
              />
              <span>{i.page}</span>
            </span>
          );
        }
      })}
    </S.Container>
  );
};
