import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';
import { ReactComponent as ConfigIcon } from '../../../images/icons/notifications/12728.svg';

import { requestNotifications } from '../services';
import { readAllNotifications } from './services';

import { ReactComponent as SinoIcon } from 'images/notifiations/sino2.svg';
import { ReactComponent as ListIcon } from 'images/notifiations/list.svg';
import { ShowNotification } from './components';
import { updateNumNotification } from 'store/modules/header/actions';

import {
  Header,
  Label,
  ListItemContainer,
  ItemContainer,
  menu_St,
  SeeMore,
} from './styled';

const NotificationContent = React.memo(({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedId, setSelectedIndex] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [itemAnchorEl, setItemAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const [itemsPerPage, setItemsPerPage] = React.useState(20);
  const [ids, setIds] = React.useState(null);
  const header = useSelector(state => state.header);

  const handleListItemClick = (event, item) => {
    setSelectedIndex(item.id);
    onClose();
    navigate(item.link);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setItemAnchorEl(null);
  };

  const fetchNotifications = async () => {
    const res = await requestNotifications();
    const data = res.data.data;
    if (res.data.success) {
      setNotifications(data.rows);
      setIds(data.allIds);
    }
  };

  const handleReadAllNotifications = async () => {
    setAnchorEl(null);
    const res = await readAllNotifications(ids || header?.allIds);
    if (res.data.success) {
      toast.success(res.data.message);
      fetchNotifications();
    } else toast.error(res.data.message);
  };

  const handleReadAllNotificationsPush = async () => {
    const res = await readAllNotifications(header?.allIds);
  };

  React.useEffect(() => {
    fetchNotifications();
  }, []);

  const handleClosePush = () => {
    dispatch(
      updateNumNotification({
        openPush: false,
      }),
    );
  };

  return (
    notifications && (
      <Box sx={{ width: 529, maxWidth: 460, bgcolor: 'background.paper' }}>
        <Header>
          <ShowNotification
            open={header?.openPush.length > 0}
            data={header?.openPush || []}
            handleClose={handleClosePush}
            handleRead={handleReadAllNotificationsPush}
          />
          <div className="title">
            <SinoIcon fill="#4B5166" />

            <Label
              style={{
                textAlign: 'left',
                fontWeight: 900,
                fontSize: 20,
                fontFamily: 'Texta',
                color: '#4B5166',
              }}
            >
              Notificações
            </Label>
          </div>
          <div>
            <IconButton onClick={e => handleMenu(e)} disableRipple>
              <ConfigIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              PaperProps={menu_St}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleReadAllNotifications}>
                Marcar tudo como lida
              </MenuItem>
            </Menu>
            <IconButton onClick={() => onClose()}>
              <CloseIcon sx={{ color: '#4B5166' }} />
            </IconButton>
          </div>
        </Header>
        <Divider light />
        <List component="nav" aria-label="main mailbox folders">
          {notifications &&
            notifications.slice(0, itemsPerPage).map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  sx={{ padding: 0, height: 69, alignItems: 'flex-start' }}
                  selected={!item?.visualizado}
                  onClick={event => handleListItemClick(event, item)}
                >
                  <ListItemContainer>
                    <ItemContainer>
                      <div className="title">
                        <div>{item.descricao ? item.descricao : ''}</div>
                        {item?.total > 0 && (
                          <div className="group">
                            <ListIcon style={{ marginTop: 5 }} />
                            <span>{item?.total}</span>
                          </div>
                        )}
                      </div>

                      <div className="footer">
                        <div className="time">{item?.time}</div>
                        <div
                          className="link"
                          onClick={event => handleListItemClick(event, item)}
                        >
                          {item?.linkText}
                        </div>
                      </div>
                    </ItemContainer>
                  </ListItemContainer>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          {notifications.length > itemsPerPage && (
            <SeeMore onClick={() => setItemsPerPage(itemsPerPage + 20)}>
              <span className="textSeeMore">Ver Mais</span>
              <div className="divIcon">
                <KeyboardArrowDownIcon className="icon" />
              </div>
            </SeeMore>
          )}
        </List>
        <Menu
          id="menu-appbar"
          anchorEl={itemAnchorEl}
          keepMounted
          PaperProps={menu_St}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          open={Boolean(itemAnchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleReadAllNotifications}>
            Marcar notificação como lida
          </MenuItem>
        </Menu>
      </Box>
    )
  );
});

export default NotificationContent;
