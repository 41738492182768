import styled, { css } from 'styled-components';

const Modifiers = {
  mode: () => css`
    transform: rotateY(90deg);
  `,
};

export const Container = styled.div`
  ${({ theme, mode, filled }) => css`
    width: 267px;
    height: 340px;
    position: relative;
    cursor: pointer;

    transform: scale(0.95);
    transition: all 0.25s ease-in-out;

    &:hover {
      transform: scale(1);
    }

    .image {
      width: 100%;
      height: 220px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent linear-gradient(359deg, #3f4457 0%, #313644 100%)
        0% 0% no-repeat padding-box;

      .boxIcon {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 0px 15px;
        margin-bottom: 10px;

        .MuiSvgIcon-root {
          font-size: 50px;
          color: ${theme.palette.system.white};
        }

        .timer {
          color: ${theme.palette.system.white};
          font-weight: 400;
          font-size: 14px;
        }
      }

      .content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .case {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .default {
          height: 130px;
        }
      }

      .case {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    .progress {
      width: 100%;
      height: 5.5px;
      background-color: ${filled
        ? theme.palette.brand.secondary.light
        : theme.palette.words.text.light};
      box-shadow: 0px 3px 8px ${theme.palette.system.shadow};
    }

    .infos {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title {
        color: ${theme.palette.words.title};
        font-weight: 900;
        font-size: 16px;
      }

      .section {
        color: ${theme.palette.words.text.natural};
        font-weight: 600;
        font-size: 15px;

        span {
          color: ${theme.palette.words.title.dark};
          font-weight: 700;
        }
      }

      .description {
        color: ${theme.palette.words.text.natural};
        font-weight: 500;
        font-size: 14px;
      }
    }

    ${mode && Modifiers.mode({ theme, mode })}
  `}
`;

export const Loading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  opacity: ${props => (props.isLoading ? 1 : 0)};
  z-index: ${props => (props.isLoading ? 2 : -1)};
  background-image: linear-gradient(
    to right,
    #e2e2e8 0%,
    #edeef1 20%,
    #e2e2e8 40%,
    #e2e2e8 100%
  );
  background-size: 80rem 14rem;
  animation: placeholderShimmer 3s linear infinite forwards;

  @keyframes placeholderShimmer {
    0% {
      background-position: -40rem 0;
    }
    100% {
      background-position: 40rem 0;
    }
  }
`;
