import styled from 'styled-components';
import Card from '@mui/material/Card';

const StyledCard = styled(Card)`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Header = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LoadingBox = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export { StyledCard, Header, LoadingBox };
