import styled, { css } from 'styled-components';
import colors from 'styles/colors';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 20px;
`;

const ImageArea = styled.div`
  ${({ theme }) => css`
    width: 55px;
    height: 55px;
    border: 2px solid ${theme.palette.brand.secondary.natural};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }

    .letters {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: ${theme.palette.semantics.feedback.unknown.light};
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 900;
      color: ${theme.palette.words.title.natural};
      font-size: 19px;
    }
  `}
`;

const TextArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    .userName {
      color: ${theme.palette.words.title.natural};
      font-weight: 700;
      font-size: 18px;
      text-transform: capitalize;
    }
    .filialName {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 14px;
    }
  `}
`;

const TextEmpresa = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.words.title.natural};
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  `}
`;

const Label = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    color: ${props.hasError
      ? theme.palette.semantics.feedback.attention.natural
      : theme.palette.words.subtitle.natural};

    span {
      color: ${theme.palette.semantics.feedback.attention.natural};
      font-weight: bold;
      font-size: 18px;
    }
  `}
`;

export { Container, ImageArea, TextArea, TextEmpresa, Label };
