// Styleds
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';

// Material UI
import { EditOutlined } from '@mui/icons-material';
import { Icon, IconButton, Modal, Grid } from '@mui/material';

// Components
import Input from 'components/Inputs/TextField';
import { Switch } from 'components/Inputs/Switch';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';
import * as S from './styled';

// Utils
import * as service from '../service';

const ModalAddFilial = ({ open, dataEdit, handleClose, request }) => {
  const theme = useTheme();
  // GENERAL STATES
  const [nome, setNome] = useState('');
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [margin, setMargin] = useState('-550px');
  const [confirmAdd, setConfirmAdd] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);

  // Filling in the fields with the current Filial
  useEffect(() => {
    if (dataEdit) {
      setNome(dataEdit.nome);

      switch (dataEdit.status) {
        case 'ATIVA':
          setStatus(true);
          break;
        case undefined:
          setStatus(true);
          break;
        default:
          setStatus(false);
      }
    }
  }, [dataEdit]);

  // ------------------------FUNÇÕES DE EXECUÇÃO--------------------//

  const verifyFields = () => {
    if (!nome) {
      toast.error('Preencha um nome para filial');
      return;
    }

    dataEdit.id ? setConfirmEdit(true) : setConfirmAdd(true);
  };

  const handleAddFilial = async () => {
    setLoading(true);
    const res = await service.createFilial(nome, status);
    if (res.data.success) {
      toast.success(res.data.message);
      behaviorCloseModal();
      setTimeout(() => {
        request();
      }, 381);
    } else {
      toast.error(res.data.message);
    }
    setLoading(false);
  };

  const handleEditFilial = async () => {
    setLoading(true);
    const res = await service.updateFilial(dataEdit.id, nome, status);
    if (res.data.success) {
      toast.success(res.data.message);
      behaviorCloseModal();
      setTimeout(() => {
        request();
      }, 381);
    } else {
      toast.error(res.data.message);
    }
    setLoading(false);
  };

  // ------------------------CONTROLE DE MODAL--------------------//
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setMargin('0px');
      }, 10);
    }
  }, [open]);

  const behaviorCloseModal = async () => {
    setMargin('-550px');
    setTimeout(() => {
      handleClose();
    }, 380);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={behaviorCloseModal}
        disableEscapeKeyDown={false}
      >
        <S.Container margin={margin}>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              {dataEdit ? (
                <>
                  <EditOutlined
                    sx={{ color: theme.palette.brand.secondary.natural }}
                    fontSize="medium"
                  />
                  <h2>Editar Filial</h2>
                </>
              ) : (
                <>
                  <Icon
                    sx={{ color: theme.palette.brand.secondary.natural }}
                    fontSize="medium"
                  >
                    add_circle_outline
                  </Icon>
                  <h2>Adicionar Nova Filial</h2>
                </>
              )}
            </div>

            <IconButton size="small" onClick={behaviorCloseModal}>
              <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                close
              </Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES DE PERFIL</S.Section>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Input
                  required
                  label="Nome Completo"
                  placeholder=""
                  value={nome}
                  onChange={e => setNome(e.target.value)}
                  name="nome"
                  id="nome"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  check={status}
                  setCheckbox={setStatus}
                  textOn="Filial Ativa"
                  textOff="Filial Inativa"
                />
              </Grid>
            </Grid>
          </S.Main>

          <S.Footer>
            <div>
              <GhostButton onClick={behaviorCloseModal} size="medium">
                CANCELAR
              </GhostButton>

              <DefaultButton
                onClick={verifyFields}
                size="medium"
                sx={{ marginLeft: '20px' }}
              >
                {dataEdit ? 'Salvar Alterações' : 'Salvar'}
              </DefaultButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>

      {/* Confirmação de adição */}
      <ConfirmModal
        open={confirmEdit}
        handleClose={() => setConfirmEdit(false)}
        title="Deseja editar filial?"
        titleIcon={
          <Icon
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          >
            add_circle_outline
          </Icon>
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => handleEditFilial()}
        loading={loading}
      />

      {/* Confirmação de edição */}
      <ConfirmModal
        open={confirmAdd}
        handleClose={() => setConfirmAdd(false)}
        title="Deseja confirmar criação da filial?"
        titleIcon={
          <EditOutlined
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          />
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => handleAddFilial()}
        loading={loading}
      />
    </>
  );
};

export default ModalAddFilial;
