import styled, { css } from 'styled-components';

export const Label = styled.p`
  ${({ theme }) => css`
    font: normal normal medium 14px/20px Texta;
    color: ${theme.palette.words.subtitle.natural};

    span {
      font-size: 20px;
      font-weight: 500;
      color: ${theme.palette.semantics.feedback.attention.dark};
    }
  `}
`;
