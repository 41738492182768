import React from 'react';
import { Modal, IconButton, Icon } from '@mui/material';
import Button from 'components/Buttons/Default';
import { ReactComponent as WarningIco } from 'images/icons/warning.svg';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useTheme } from 'styled-components';
import * as S from './styled';

const AlertModal = ({
  handleClose,
  open,
  title,
  subtitle,
  buttonText,
  onClick,
}) => {
  const theme = useTheme();
  const body = (
    <S.Paper>
      <S.Header>
        <S.Title>
          <WarningIco
            strokeWidth="1.5"
            stroke={theme.palette.semantics.feedback.attention.natural}
            fill="none"
            fontSize="medium"
          />
          <h1>{title}</h1>
        </S.Title>

        <IconButton size="small" onClick={handleClose}>
          <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
            close
          </Icon>
        </IconButton>
      </S.Header>

      <S.Main>
        {subtitle && (
          <S.StyledToast>
            <CheckCircleOutlineOutlinedIcon
              sx={{ color: theme.palette.semantics.feedback.attention.natural }}
            />
            <h1>{subtitle}</h1>
          </S.StyledToast>
        )}
      </S.Main>

      <S.Footer>
        <Button
          onClick={onClick}
          size="medium"
          variant="contained"
          style={{
            font: 'normal normal 900 14px/18px Texta',
            height: '40px',
          }}
        >
          {buttonText}
        </Button>
      </S.Footer>
    </S.Paper>
  );

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
};

export default AlertModal;
