import api from 'services/api';

const getMotoristas = async query => {
  const res = await api.get('/training/alunos', { params: query });
  return res;
};

const createTurmaAluno = async data => {
  const res = await api.post('/training/create-turma-aluno', data);
  return res;
};

// inativa motorista
const inativateGestor = async (id, data) => {
  const res = await api.put(`/configuracoes/update-status-user/${id}`, data);
  return res;
};

// inativa motorista
const inativateDriver = async id => {
  const res = await api.put(`driver-change-status/${id}`);
  return res;
};

export { getMotoristas, createTurmaAluno, inativateGestor, inativateDriver };
