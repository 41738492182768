import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import ListPage from 'pages/Motoristas/PerfilMotorista/components/ListPage';

// Services
import { trackEvent } from 'utils/mixpanel';
import { requestFrotas, requestCards } from './services';
import { columns } from './constants';
import ModalAddFrota from 'pages/Provider/Frota/ModalAddFrota';

export const Frota = ({ filters }) => {
  // Redux e hooks
  const params = useParams();
  const user = useSelector(state => state.auth?.user?.user);

  // General States
  const [query, setQuery] = useState(null);
  const [dataView, setDataView] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  const [selectedCard, setSelectedCard] = useState(null);

  const actions = [
    {
      title: 'Ver detalhes',
      function: id => handleView(id),
    },
  ];

  // -------------------------- REQUESTS ------------------------------------------//

  const { isFetching, data: resData } = useQuery(
    ['frota-empresa', params?.id, query],
    () => query && requestFrotas(params?.id, query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        setResetTable(false);
      },
    },
  );

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cards
  const { isLoading: loadingCards, data: resCards } = useQuery(
    ['cards-frota', params?.id, query],
    () => requestCards(params?.id, { ...query, card: null }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [selectedCard, filters]);

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor válido: nao nulo e maior que 0.
  const handleClickCard = type => {
    if (!loadingCards && !isFetching) {
      const cards = resCards || [];
      const card = cards.find(item => item.type === type);

      if (card.value) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  const handleView = id => {
    const truckView = resData?.data?.data?.find(
      truck => String(truck.id) === String(id),
    );
    if (truckView) setDataView(truckView);
  };

  return (
    <>
      <ListPage
        cards={
          resCards?.map(card => ({
            ...card,
            disabled: isFetching,
          })) || []
        }
        selectedCard={selectedCard}
        handleClickCard={handleClickCard}
        loadingCards={loadingCards}
        tableProps={{
          data: resData?.data?.data || [],
          columns,
          loading: isFetching,
          pageCount: resData?.data?.total || 0,
          visualizedKey: 'visto',
          local: false,
          actions,
          reset: resetTable,
          onClickRow: handleView,
          loadingSelection: loadingLines,
          setQuery: q => {
            setQuery({
              ...q,
              filial: filters.filial ? filters.filial.split(',') : null,
              card: selectedCard,
            });
          },
          searchEvent: search => trackEvent(user, 'Busca Frota', null, search),
          sortBy: {
            id: 'placa',
            order: 'ASC',
          },
          placeholder: `Buscar por Placa ou Modelo`,
          empty: {
            title: 'Ops! Você não tem nenhuma ação.',
            description: 'Verifique os filtros selecionados.',
          },
        }}
      />
      {dataView && (
        <ModalAddFrota
          open={Boolean(dataView)}
          dataEdit={dataView}
          handleClose={() => setDataView(null)}
          readOnly
        />
      )}
    </>
  );
};
