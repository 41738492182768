import { toast } from 'react-toastify';
import api from 'services/api';

export const getContents = async query => {
  const res = await api.get('/training/contents', { params: query });
  return res.data;
};

export const getCards = async () => {
  const res = await api.get('/training/dashboard/cards');
  return res.data;
};
