import React from 'react';
import { Skeleton } from '@mui/material';
import {
  Tooltip,
  ResponsiveContainer,
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  Text,
  PolarRadiusAxis,
} from 'recharts';
import CheckFiltersCard from 'components/Cards/CheckFiltersCard';
import * as S from './styled';

const RadarGraph = ({ data, color, title, loading, tooltip, tabComponent }) => {
  const WrappedAxisTick = props => {
    const { x, y, payload, textAnchor } = props;

    let label = payload.value;

    if (label) {
      return (
        <Text width={100} x={x} y={y} textAnchor={textAnchor}>
          {payload.value}
        </Text>
      );
    }
    return null;
  };

  return (
    <S.StyledCard>
      <S.Header>
        <span>{title}</span>
        {tabComponent}
      </S.Header>
      <S.Legend>
        <div className="container-title">
          <S.CicleLegend color="#F64E60" />
          <span>Período atual</span>
        </div>
        <div className="container-title">
          <S.CicleLegend color="#4B51661A" />
          <span>Período anterior</span>
        </div>
      </S.Legend>
      {data?.length > 0 ? (
        <>
          {loading ? (
            <S.BoxLoading>
              <Skeleton
                width={280}
                height={180}
                variant="rounded"
                animation="wave"
              />
            </S.BoxLoading>
          ) : (
            <ResponsiveContainer width="100%" height="100%" className="graph">
              <RadarChart
                data={data}
                margin={{
                  top: 20,
                  right: 20,
                  left: 20,
                  bottom: 10,
                }}
                width={300}
                height={200}
                layout="centric"
              >
                {tooltip && <Tooltip />}
                <PolarGrid />
                <PolarAngleAxis dataKey="name" tick={WrappedAxisTick} />
                <PolarRadiusAxis angle={90} domain={[0, 1]} opacity={0} />
                <Radar
                  dataKey="previousValue"
                  stroke="#242424"
                  fill="#d1d1d1"
                  fillOpacity={1}
                />
                <Radar
                  dataKey="value"
                  stroke="#F64E60"
                  fill="#F64E60"
                  fillOpacity={0.2}
                />
              </RadarChart>
            </ResponsiveContainer>
          )}
        </>
      ) : (
        <CheckFiltersCard />
      )}
    </S.StyledCard>
  );
};

export default RadarGraph;
