import { useTheme } from 'styled-components';
import { formatNewDate } from '../../utils/dates';
import * as S from './styled';

const howToColor = criticality => {
  const theme = useTheme();
  switch (criticality) {
    case 'baixo':
      return theme?.palette?.semantics?.feedback?.information?.natural;
    case 'medio':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'alto':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    default:
      return theme?.palette?.semantics?.feedback?.unknown?.natural;
  }
};

const getStatusColor = (status, theme) => {
  switch (status) {
    case 'ABERTO':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'PENDENTE':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'FINALIZADO':
      return theme?.palette?.semantics?.feedback?.information?.natural;
    case 'DELETADO':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
  }
};

const getCriticidadeColor = (status, theme) => {
  switch (status) {
    case 'PRIMÁRIA':
      return theme?.palette?.semantics?.feedback?.information?.natural;
    case 'MODERADA':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'GRAVE':
    case 'GRAVÍSSIMA':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    default:
      theme?.palette?.semantics?.feedback?.unknown?.natural;
  }
};

const emptyState = {
  title: 'Ops! Você não tem nenhum desvio disponível.',
  description: 'Verifique os filtros aplicados!',
};

const getExpirationDate = row => {
  let newDate = new Date(row.data_violacao);
  newDate.setDate(newDate.getDate() + 10);

  return formatNewDate(newDate);
};

const statusTabs = [
  { value: 'ABERTO', label: 'Abertos' },
  { value: 'PENDENTE', label: 'Pendentes' },
  { value: 'FINALIZADO', label: 'Finalizados' },
  { value: 'DELETADO', label: 'Deletados' },
];

const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista/Filial',
    id: 'viagem.motorista.nome',
    type: 'string',
    sort: true,
    subRow: {
      prefix: ' Filial:',
      key: 'viagem.motorista.filial.nome',
      value: (value, item) => item.viagem?.motorista?.filial?.nome,
      type: 'string',
    },
  },
  {
    header: 'Placa',
    id: 'viagem.caminhao.placa',
    type: 'string',
    style: { textTransform: 'uppercase' },
    sort: true,
    width: 120,
  },
  {
    header: 'Desvio',
    id: 'tipo_violacao.tipo_violacao_padrao.tipo',
    type: 'string',
    sort: true,
  },
  {
    header: 'Criticidade',
    id: 'criticidade',
    type: 'string',
    sort: true,
    align: 'center',
    children: (value, item) => {
      const theme = useTheme();
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <S.Pontos color={getCriticidadeColor(item.criticidade, theme)}>
            <p>{item.criticidade ?? '-'}</p>
          </S.Pontos>
        </div>
      );
    },
  },
  {
    header: 'Data Desvio',
    id: 'data_violacao',
    type: 'string',
    value: (value, item) => formatNewDate(item.data_violacao),
    sort: true,
    subRow: {
      prefix: ' Venc:',
      key: 'item.data_vencimento',
      value: (value, item) => formatNewDate(item.data_vencimento),
      type: 'string',
    },
  },
  {
    header: 'Localização',
    id: 'endereco',
    value: (value, item) =>
      item.endereco
        ? item.endereco
        : item.latitude
        ? `lat: ${item.latitude}, lng: ${item.longitude}`
        : 'Não informado',
    sort: true,
  },
];

const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'viagem.motorista.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'viagem.motorista.filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Placa',
    value: 'viagem.caminhao.placa',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo do desvio',
    value: 'tipo_violacao.tipo_violacao_padrao.tipo',
    selected: true,
    default: true,
  },
  {
    label: 'Criticidade',
    value: 'criticidade',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'pontos',
    selected: true,
    default: true,
  },
  {
    label: 'Data do desvio',
    value: 'data_violacao',
    selected: true,
    default: true,
  },
  {
    label: 'Localização',
    value: 'endereco',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'viagem.distribuidora.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Previsão de conclusão',
    value: 'data_previsao',
    selected: false,
    default: false,
  },
  {
    label: 'Data conclusão',
    value: 'data_encerramento',
    selected: false,
    default: false,
  },
  {
    label: 'Plano de ação',
    value: 'plano_de_acao',
    selected: false,
    default: false,
  },
  {
    label: 'Observação',
    value: 'observacao',
    selected: false,
    default: false,
  },
  {
    label: 'Responsável',
    value: 'responsavel.nome',
    selected: false,
    default: false,
  },
  {
    label: 'Disponibilizado Em',
    value: 'createdAt',
    selected: false,
    default: false,
  },
  {
    label: 'Justificativa exclusão',
    value: 'motivo_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Observação exclusão',
    value: 'observacao_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Data exclusão',
    value: 'data_exclusao',
    selected: false,
    default: false,
  },
];

const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};

export {
  emptyState,
  howToColor,
  getStatusColor,
  getCriticidadeColor,
  statusTabs,
  columns,
  fields,
  resetExcelFields,
};
