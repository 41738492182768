import styled from 'styled-components';
import colors from 'styles/colors';
import { Modal } from '@mui/material';

export const ModalMUI = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 80%;
  max-width: 800px;
  height: 70%;
  max-height: 700px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${colors.greyBorder};
  /* overflow: hidden; */
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  .left {
    display: flex;
    align-items: center;

    span {
      margin-left: 20px;
      font-size: 20px;
      font-weight: 900;
      color: ${colors.greyTitle};
    }
  }
`;

export const Warning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .warningBox {
    background-color: ${colors.orangeBackground};
    border: 2px solid ${colors.orangeAlert};
    padding: 10px 20px;
    border-radius: 5px;
    width: 100%;
    font-weight: 500;
    color: ${colors.orangeAlert};
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }
`;

export const Body = styled.div`
  padding: 20px;
  pointer-events: ${props => (props.isLoading ? 'none' : 'all')};
  height: 100%;
  overflow: hidden;

  .first {
    margin-bottom: 30px;
  }

  .boxSession {
    height: ${props => (props.hasTwo ? '120px' : '100%')};

    p {
      font-weight: 600;
      color: ${colors.greySubtitle};
    }

    .grade {
      overflow-y: scroll;
      max-height: ${props => (props.hasTwo ? '270px' : '60%')};
    }
  }

  .emptyBox {
    display: flex;
    align-items: center;
    justify-content: center;

    .rigth {
      margin-left: 20px;

      p {
        font-size: 25px;
        font-weight: 900;
        color: ${colors.greyTitle};
      }

      span {
        font-size: 16px;
        color: ${colors.greySubtitle};
        font-weight: 500;
      }

      .buttonArea {
        margin-top: 20px;
      }
    }
  }
`;

export const ListItem = styled.div`
  display: flex;
  margin-top: 10px;
  border-bottom: 1px solid ${colors.greyBorder};
  padding: 4px 2px;
  height: 50px;
  overflow: hidden;
  position: relative;

  .visisbleArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .boxInformationEl {
      display: flex;

      .titleEl {
        margin-left: 10px;
        font-weight: 600;
        color: ${colors.greySubtitle};
        transform: ${props =>
          props.isSelected ? 'translateY(-50px)' : 'translateY(0px)'};
        transition: all ease 0.2s;
      }

      .excludeText {
        transform: ${props =>
          props.isSelected ? 'translateY(0px)' : 'translateY(50px)'};
        transition: all ease 0.2s;
        color: ${colors.redDanger};
        font-weight: 600;
        position: absolute;
        left: 0;
        margin-left: 32px;
      }
    }

    .boxActionElement {
      button {
        margin-right: 10px;
        font-weight: 500;
        color: ${colors.greySubtitle};
        border: none;
        background-color: transparent;
      }

      .view {
      }

      .remove {
        color: ${colors.redDanger};
        font-weight: 600;
      }
    }
  }

  .invisibleArea {
    width: 20%;
    height: 100%;
    margin-right: ${props => (props.isSelected ? '0px' : '-100%')};
    right: 0;
    background-color: ${colors.redDanger};
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    transition: all ease 0.1s;

    span {
      color: #fff;
    }

    .buttonArea {
      display: flex;

      button {
        background-color: transparent;
        border: none;
        margin-right: 10px;
      }
    }
  }
`;
