import colors from 'styles/colors';

import { SvgIcon } from '@mui/material';
import * as S from './styled';

import { itensBar, itensBarEmbarcadora } from './constants';

export const Sidebar = ({
  page,
  setPage,
  setChanged,
  isEmbarcadora,
  steps,
}) => {
  const handlePage = i => {
    if (i.id !== page) {
      setPage(steps[i.id]);
      setChanged(false);
    }
  };

  return (
    <S.Container>
      <p className="titleSec">Publique</p>
      {(!isEmbarcadora ? itensBar : itensBarEmbarcadora).map((i, k) => {
        if ((k < 6 && !isEmbarcadora) || (k < 2 && isEmbarcadora)) {
          return (
            <span
              onClick={() => handlePage(i)}
              className={page === i.id ? 'navBarItem active' : 'navBarItem'}
              key={k}
            >
              <SvgIcon
                component={i.icon}
                stroke={page === i.id ? colors.greenMain : colors.grey}
              />
              <span>{i.page}</span>
            </span>
          );
        }
      })}
      <p className="titleSec">Analise</p>
      {(!isEmbarcadora ? itensBar : itensBarEmbarcadora).map((i, k) => {
        if ((k >= 6 && !isEmbarcadora) || (k >= 2 && isEmbarcadora)) {
          return (
            <span
              onClick={() => handlePage(i)}
              className={page === i.id ? 'navBarItem active' : 'navBarItem'}
              key={k}
            >
              <SvgIcon
                component={i.icon}
                stroke={page === i.id ? colors.greenMain : colors.grey}
              />
              <span>{i.page}</span>
            </span>
          );
        }
      })}
    </S.Container>
  );
};
