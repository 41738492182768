/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// Material UI
import { Tooltip } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';

const EvaluationCard = ({ item, selected, onClick }) => {
  const theme = useTheme();
  const { id, title, type, Icon } = item;

  return (
    <S.Container isSelected={selected} onClick={onClick}>
      <S.Header isSelected={selected}>
        <div className="iconArea">
          <Icon
            htmlColor={
              selected
                ? theme.palette.brand.secondary.natural
                : theme.palette.words.text.natural
            }
            fontSize="large"
            style={{
              transform: 'scale(0.8)',
            }}
          />
        </div>
        <div className="titleArea">{title}</div>
      </S.Header>
      <S.Content isSelected={selected}>
        <div className="boxItem">
          {type === 'quiz' && (
            <>
              <div className="option">
                <RadioButtonCheckedOutlinedIcon
                  htmlColor={
                    selected
                      ? theme.palette.brand.secondary.natural
                      : theme.palette.words.text.natural
                  }
                />
                <label>Verdadeiro</label>
              </div>
              <div className="option">
                <RadioButtonUncheckedOutlinedIcon
                  htmlColor={
                    selected
                      ? theme.palette.brand.secondary.natural
                      : theme.palette.words.text.natural
                  }
                />
                <label>Falso</label>
              </div>
            </>
          )}
          {type === 'test' && (
            <>
              <div className="option">
                <label>A</label>
              </div>
              <div className="option">
                <label>B</label>
              </div>
              <div className="option">
                <label>C</label>
              </div>
            </>
          )}
        </div>
      </S.Content>
      <S.Footer>
        <Tooltip
          title={
            type === 'quiz'
              ? 'Perguntas de verdadeiro ou falso com possibilidade de feedback imediato para o usuário'
              : 'Perguntas com respostas customizáveis e pode ser definido um coeficiente minimo para aprovação na etapa de configurações'
          }
        >
          <HelpOutlineOutlinedIcon
            htmlColor={
              selected
                ? theme.palette.brand.secondary.natural
                : theme.palette.semantics.feedback.unknown.natural
            }
          />
        </Tooltip>
      </S.Footer>
    </S.Container>
  );
};

export default EvaluationCard;
