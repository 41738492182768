import React, { useEffect, useState } from 'react';
import { Container, Grid, Stepper, Step, StepLabel } from '@mui/material';

import TextInput from 'components/Inputs/TextField';
import Button from 'components/Buttons/Default';

import { validateBr } from 'js-brasil';
import { toast } from 'react-toastify';
import { cnpjMask, cepMask } from 'utils/masks';

import logo from 'images/Grupo43.svg';
import bg from 'images/logobg.svg';
import demo from 'images/demo.png';
import logoEnterprise from 'images/logo.svg';
import { useTheme } from 'styled-components';
import { requestCadastro } from './services';
import * as S from './styled';

const CadastroEmpresa = () => {
  const theme = useTheme();
  const steps = ['Informações de Usuário', 'Adicione sua Empresa'];
  const messagesMandatory = {
    nome_do_contato: 'Campo "Nome" é obrigatório',
    email_do_contato: 'Campo Email é obrigatório',
    senha: 'Campo Senha é obrigatório',
    confirmar_senha: 'Campo Confirmar Senha é obrigatório',
  };
  const messagesValidation = {
    email_do_contato: 'Digite um e-mail válido',
    confirmar_senha: 'Senhas devem ser idênticas',
  };
  const [inputs, setInputs] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(new Set());
  const [message, setMessage] = useState(null);
  const [valid, setValid] = useState(null);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleChange = async e => {
    let { value } = e.target;
    if (e.target.name === 'cnpj') {
      value = cnpjMask(value);
    }
    if (e.target.name === 'cep') {
      if (value.length >= 8) {
        value = await cepMask(e.target.value);
        setInputs(values => ({
          ...values,
          cep: value.cep,
          endereco: value.logradouro,
          bairro: value.bairro,
          cidade: value.localidade,
          estado: value.uf,
        }));
        return;
      }
    }
    setInputs(values => ({ ...values, [e.target.name]: value }));
  };

  const validateInputs = item => {
    let isValid = true;

    switch (item) {
      case 'email_do_contato':
        isValid = validateBr.email(inputs.email_do_contato);
        if (!isValid) {
          const doc = document.getElementById(item);
          doc?.focus();
          setValid(messagesValidation[item]);
        }
        break;
      case 'confirmar_senha':
        isValid = inputs.senha === inputs.confirmar_senha;
        if (!isValid) {
          const doc = document.getElementById(item);
          doc?.focus();
          setValid(messagesValidation[item]);
        }
      default:
        break;
    }
    return isValid;
  };

  const checkInputs = e => {
    const stepOne = [
      'nome_do_contato',
      'email_do_contato',
      'senha',
      'confirmar_senha',
    ];
    const stepTwo = ['nome', 'cnpj', 'cep'];
    let isCompleted = true;
    if (activeStep === 0) {
      stepOne.forEach(item => {
        if (isCompleted && !inputs[item]) {
          isCompleted = false;
          const doc = document.getElementById(item);
          doc?.focus();
          setMessage(messagesMandatory[item]);
        } else if (isCompleted) {
          isCompleted = validateInputs(item);
        }
      });

      if (isCompleted) {
        setActiveStep(activeStep + 1);
      }
    } else {
      stepTwo.forEach(item => {
        if (isCompleted && !inputs[item]) {
          isCompleted = false;
          const doc = document.getElementById(item);
          doc?.focus();
          setMessage(messagesMandatory[item]);
        } else if (isCompleted) {
          isCompleted = validateInputs(item);
        }
      });
      if (isCompleted) {
        handleSubmit(e);
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setMessage('');
    setValid('');
    const res = await requestCadastro(inputs);
    if (res && res.success) {
      toast.success(
        'Obrigado! Estamos analisando sua solicitação de cadastro. Você receberá um e-mail assim que for aprovado, para verificar sua conta e logar.',
      );
    } else {
      toast.error(res.message || 'Erro ao cadastrar empresa.');
    }
  };

  const renderUserForm = () => {
    const texts = {
      title: 'Cadastre-se agora',
      subTitle: 'Informações de Usuário:',
    };
    return (
      <Grid
        style={{
          overflowY: 'initial',
          padding: '20px 100px 100px 100px',
        }}
        item
        sm={12}
      >
        <S.PageContainer>
          <div>
            <h1 style={{ fontSize: '38px', margin: '20px 0' }}>
              {texts.title}
            </h1>
            <p style={{ margin: '20px 0' }}>{texts.subTitle}</p>
          </div>
          <S.FormWrapper>
            <TextInput
              label="Nome"
              placeholder="Nome"
              value={inputs.nome_do_contato || ''}
              onChange={handleChange}
              name="nome_do_contato"
              required
              message={message === messagesMandatory.nome_do_contato && message}
              id="nome_do_contato"
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <TextInput
              label="Email"
              placeholder="E-mail"
              value={inputs.email_do_contato || ''}
              onChange={handleChange}
              name="email_do_contato"
              id="email_do_contato"
              message={
                message === messagesMandatory.email_do_contato && message
              }
              required
              isValid={valid === messagesValidation.email_do_contato && valid}
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <TextInput
              label="Telefone"
              placeholder="Telefone"
              value={inputs.telefone_do_contato || ''}
              onChange={handleChange}
              id="telefone_do_contato"
              name="telefone_do_contato"
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <TextInput
              label="Senha"
              placeholder="Insira sua senha"
              value={inputs.senha || ''}
              onChange={handleChange}
              type="password"
              name="senha"
              id="senha"
              message={message === messagesMandatory.senha && message}
              required
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <TextInput
              label="Confirmar Senha"
              placeholder="Confirmar Senha"
              value={inputs.confirmar_senha || ''}
              onChange={handleChange}
              name="confirmar_senha"
              type="password"
              id="confirmar_senha"
              message={message === messagesMandatory.confirmar_senha && message}
              required
              isValid={valid === messagesValidation.confirmar_senha && valid}
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <Button
              onClick={checkInputs}
              size="large"
              width="100%"
              sx={{
                marginTop: '35px',
              }}
              children="AVANÇAR"
            />
          </S.FormWrapper>
          <p style={{ marginTop: '20px' }}>
            Já tem uma conta?{' '}
            <span>
              <a
                style={{
                  color: theme.palette.brand.secondary.dark,
                  textDecoration: 'underline',
                }}
                href="/login"
              >
                Faça o login
              </a>
            </span>
          </p>
        </S.PageContainer>
      </Grid>
    );
  };

  const renderCompanyForm = () => {
    const texts = {
      title: 'Cadastre-se agora',
      subTitle: 'Informações da Empresa:',
    };
    return (
      <Grid
        style={{
          overflowY: 'initial',
          padding: '20px 100px 100px 100px',
          height: '100%',
        }}
        item
        sm={12}
      >
        <S.PageContainer>
          <div>
            <h1 style={{ margin: '20px 0' }}>{texts.title}</h1>
            <p style={{ margin: '20px 0' }}>{texts.subTitle}</p>
          </div>
          <S.FormWrapper>
            <TextInput
              label="Nome da Empresa"
              placeholder="Nome da Empresa"
              value={inputs.nome || ''}
              onChange={handleChange}
              name="nome"
              required
              message={message === messagesMandatory.nome && message}
              id="nome"
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <TextInput
              label="CNPJ"
              placeholder="CNPJ"
              value={inputs.cnpj || ''}
              onChange={handleChange}
              name="cnpj"
              required
              message={message === messagesMandatory.cnpj && message}
              id="cnpj"
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <TextInput
              label="Endereço"
              placeholder="Insira o CEP da Empresa"
              value={inputs.cep || ''}
              onChange={handleChange}
              name="cep"
              required
              message={message === messagesMandatory.cep && message}
              id="cep"
            />
          </S.FormWrapper>
          <S.FormWrapper>
            <Button
              onClick={checkInputs}
              size="large"
              width="100%"
              sx={{
                marginTop: '35px',
              }}
              children="FINALIZAR CADASTRO"
            />
          </S.FormWrapper>
          <p style={{ marginTop: '20px' }}>
            Já tem uma conta?{' '}
            <span>
              <a
                style={{
                  color: theme.palette.brand.secondary.dark,
                  textDecoration: 'underline',
                }}
                href="/login"
              >
                Faça o login
              </a>
            </span>
          </p>
        </S.PageContainer>
      </Grid>
    );
  };

  // const renderImage = () => {
  //     return (
  //         <Grid style={{

  //         }} item sm={6}>

  //             <div style={{
  //                 backgroundImage: `url(images/bg-cadastro.png)`,
  //                 backgroundSize: '100% 100%',
  //                 height: '100%'
  //             }}>
  //             </div>
  //         </Grid>
  //     )
  // }
  const renderImage = () => {
    return (
      <Grid item sm={6}>
        <div
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <img width="30%" src={logoEnterprise} />
          <div
            style={{
              overflow: 'hidden',
            }}
          >
            <img
              style={{
                alignSelf: 'flex-end',
                marginLeft: '25%',
                overflow: 'hidden',
              }}
              width="95%"
              src={demo}
            />
          </div>
          <p
            style={{
              font: 'normal normal 24px/31px Texta',
              color: theme.palette.words.text.contrast,
              textAlign: 'center',
              marginTop: '30px',
            }}
          >
            Gerencie o{' '}
            <span style={{ color: theme.palette.brand.secondary.natural }}>
              desempenho{' '}
            </span>
            de seus motoristas
          </p>
        </div>
      </Grid>
    );
  };

  const isStepFinished = stp => {
    return completedSteps.has(stp);
  };

  const renderStepper = () => {
    return (
      <Grid item sm={12} spacing={12}>
        <Grid item sm={12}>
          <img
            height={100}
            style={{ padding: '50px 50px 0 50px' }}
            src={logo}
          />
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid columnSpacing={4} item sm={7}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, idx) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepFinished(idx)) {
                  stepProps.completed = false;
                }
                return (
                  <Step orientation="vertical" key={label} {...stepProps}>
                    <StepLabel
                      sx={{
                        '.Mui-active': {
                          color: `${theme.palette.words.text.contrast} !important`,
                        },
                        '.MuiStepLabel-label': {
                          color: `${theme.palette.words.subtitle.natural} !important`,
                        },
                        '.MuiStepIcon-text': {
                          fill: `${theme.palette.semantics.feedback.success.natural} !important`,
                        },
                        '.Mui-completed': {
                          color:
                            theme.palette.semantics.feedback.success.natural,
                        },
                      }}
                      {...labelProps}
                      StepIconProps={{
                        sx: {
                          border: `2px solid ${theme.palette.semantics.feedback.success.natural} !important`,
                          borderRadius: '50%',
                          color: theme.palette.words.label.contrast,
                        },
                      }}
                    >
                      <p
                        style={{
                          color: `${theme.palette.words.subtitle.natural} !important`,
                        }}
                      >
                        {label}
                      </p>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
        </div>
      </Grid>
    );
  };

  return (
    <Grid container xs={12}>
      <S.PageWrapper>
        <Grid sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
          {renderStepper()}
          {activeStep === 0 && renderUserForm()}
          {activeStep === 1 && renderCompanyForm()}
        </Grid>
        {renderImage()}
      </S.PageWrapper>
    </Grid>
  );
};

export default CadastroEmpresa;
