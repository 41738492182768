import { formatNewDate, formatTime } from 'utils/dates';

export const columnsDefault = [
  {
    header: 'Id',
    id: 'id',
    sort: true,
    type: 'number',
    align: 'center',
    width: 100,
  },
  {
    header: 'Empresa/FIlial',
    id: 'filial.empresa.nome',
    sort: false,
    type: 'string',
  },
  {
    header: 'Placa',
    id: 'caminhao.placa',
    sort: true,
    type: 'string',
    style: {
      textTransform: 'uppercase',
    },
  },
  {
    header: 'Data Avaliação',
    id: 'data_avaliacao',
    sort: true,
    type: 'string',
    value: value => formatNewDate(value),
    subRow: {
      prefix: 'Horário:',
      value: (_, item) => formatTime(item?.data_avaliacao, true),
    },
  },
  {
    header: 'Disponibilizado',
    id: 'data_revisao',
    align: 'center',
    sort: true,
    type: 'string',
    value: value => formatNewDate(value),
    subRow: {
      prefix: 'Horário:',
      value: (_, item) => formatTime(item?.data_revisao, true),
    },
  },
];

export const columnsPending2 = [
  ...columnsDefault,
  {
    header: 'Prazo',
    id: 'data_revisao',
    sort: true,
    type: 'string',
    align: 'center',
    value: value => {
      const date = new Date(value);
      date.setDate(date.getDate() + 10);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    conditional: [
      {
        condition: value => value,
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.warning?.natural,
          backgroundColor: theme?.palette?.semantics?.feedback?.warning?.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];

export const columnsApproved2 = [
  ...columnsDefault,
  {
    header: 'Aprovado em',
    id: 'data_revisao_distribuidora',
    sort: true,
    align: 'center',
    value: value => formatNewDate(value),
    conditional: [
      {
        condition: value => value,
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.information?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.information?.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Responsável',
    id: 'revisor.nome',
    sort: true,
  },
];

export const columnsRejected2 = [
  ...columnsDefault,
  {
    header: 'Rejeitado em',
    id: 'data_revisao_distribuidora',
    sort: true,
    align: 'center',
    value: value => formatNewDate(value),
    conditional: [
      {
        condition: value => value,
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.attention?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.attention?.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Responsável',
    id: 'revisor.nome',
    sort: true,
  },
];
