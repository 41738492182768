import api from 'services/api';

export const getAcidente = async id => {
  const { data } = await api.get(`/acidentes/${id}`);
  if (data.success) return data?.data;
  return null;
};

export const getAcidentes = async params => {
  const { data } = await api.get('/acidentes', { params });
  return data;
};

export const getTiposAcidente = async () => {
  const { data } = await api.get('/select-acidentes-tipos');
  return data.data;
};

export const getCausas = async () => {
  const { data } = await api.get('/select-acidentes-causas');
  return data.data;
};

export const getEstadosFisicos = async () => {
  const { data } = await api.get('/select-acidentes-estados-fisicos');
  return data.data;
};

export const getHistoricoAcidentes = async params => {
  const { data } = await api.get('/acidentes-historico', { params });
  if (data.data.length) {
    return data.data;
  }
  return [];
};

export const getTotais = async params => {
  const { data } = await api.get('/acidentes-total', { params });
  if (data.data.length) {
    return data.data.map(item => ({
      ...item,
      total: item.x,
    }));
  }
  return [];
};

export const saveAcidente = async payload => {
  const { data } = payload.id
    ? await api.put(`/acidentes/${payload.id}`, payload)
    : await api.post(`/acidentes`, payload);
  return data;
};

export const fetchSequence = async (index, query) => {
  const res = await api.get(`/acidentes/paginator/${index}`, { params: query });
  if (res?.data) return res.data;
  return null;
};

export const requestExcel = async query => {
  const res = await api.get('/excel/acidentes', { params: query });
  if (res?.data?.data?.link)
    return { link: res.data.data.link, message: res.data.message };
  return { message: res?.data?.message };
};

export const checkUpload = async data => {
  const res = await api.post(`/acidentes/check-upload`, data);
  return res;
};

export const createMany = async data => {
  const res = await api.post(`/acidentes/create-many`, data);
  return res;
};
