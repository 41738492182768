import { toast } from 'react-toastify';

export const verifyErrors = (fields, values, verify) => {
  const errors = {};

  fields.forEach(field => {
    if (
      field.name === 'avaliacao_risco' &&
      field.required &&
      !values[field.name]
    ) {
      !verify && toast.error('Anexe a avaliação de risco');
      errors[field.name] = 'Campo obrigatório';
    } else if (
      field.name === 'imagens' &&
      field.required &&
      !values[field?.name]?.length
    ) {
      !verify && toast.error('Anexe ao menos uma imagem nas evidências');
      errors[field.name] = 'Campo obrigatório';
    }
  });

  return !!Object.keys(errors).length;
};
