import colors from 'styles/colors';

import SearchIcon from '@mui/icons-material/Search';
import * as S from './styled';

const Search = ({ value, setValue, placeholder, ...props }) => {
  return (
    <S.Wrapper {...props}>
      <SearchIcon className="icon" htmlColor={colors.grey} />
      <S.Input
        placeholder={placeholder || 'Buscar Formulário'}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </S.Wrapper>
  );
};

export default Search;
