import React from 'react';
import { Container, Actions } from './styled';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

const Header = ({ title, actions = [], info }) => {
  return (
    <Container>
      <h1>{title}</h1>

      {!!actions.length && (
        <Actions data-testid="actions">
          {info && <span>{info}</span>}
          {actions.map((action, idx) => {
            switch (action.variant) {
              case 'ghost':
                return (
                  <GhostButton
                    key={idx}
                    startIcon={action.icon}
                    size="medium"
                    loading={action.loading}
                    onClick={
                      action.function instanceof Function
                        ? action.function
                        : undefined
                    }
                  >
                    {action.title}
                  </GhostButton>
                );

              default:
                return (
                  <DefaultButton
                    key={idx}
                    startIcon={action.icon}
                    size="medium"
                    loading={action.loading}
                    onClick={
                      action.function instanceof Function
                        ? action.function
                        : undefined
                    }
                  >
                    {action.title}
                  </DefaultButton>
                );
            }
          })}
        </Actions>
      )}
    </Container>
  );
};

export default Header;
