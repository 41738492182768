import styled from 'styled-components';

export const ShowMonthAndYear = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* subtitulo-heavy */
  font-family: 'Texta';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height */

  color: #4b5166;

  .last-month {
    padding-left: 72px;
  }
  .next-month {
    padding-right: 72px;
  }
`;

export const HeaderCalendar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(149, 170, 201, 0.3);
  margin-left: 22px;
  margin-right: 22px;
`;
