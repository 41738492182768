import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Grid } from '@mui/material';
import GhostButton from 'components/Buttons/Ghost';
import Loading from 'components/Loading';

import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';

import * as S from './styled';
import Config from './Config';
import Info from './Info';
import NextPrev from './NextPrev';
import { Icon } from 'components/IconsOnisys';

// services
import { showDetails } from '../services';

const DetalheDesvio = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState([]);

  useQuery(['detalhes'], () => showDetails(params.id), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setIsFetching(false);
      setData(data);
    },
  });

  //  ------------------   RENDERIZACAO --------------------------------
  const renderHeader = () => {
    return (
      <S.SpacedRow>
        <h1>Detalhes do envio automático</h1>

        <S.SpacedRow>
          <GhostButton
            startIcon={<HistoricoIcon />}
            size="medium"
            onClick={() => navigate(`/logs/automacao/capacitacao/${params.id}`)}
            sx={{ marginRight: '1rem' }}
          >
            HISTÓRICO DE ALTERAÇÕES
          </GhostButton>

          <GhostButton
            startIcon={<Icon name="icone_motoristas" />}
            size="medium"
            onClick={() => navigate(`/motoristas/${data?.motorista?.id}`)}
            sx={{ marginRight: '1rem' }}
          >
            VER PERFIL DO MOTORISTA
          </GhostButton>
          <NextPrev />
        </S.SpacedRow>
      </S.SpacedRow>
    );
  };

  return (
    <>
      {isFetching && <Loading />}
      {!isFetching && (
        <>
          <S.Main>
            {renderHeader()}
            <Grid container spacing="20px" marginTop="10px">
              <Grid item xs={12} sm={12} md={6}>
                <Info data={data} />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Config data={data} />
              </Grid>
            </Grid>
          </S.Main>
        </>
      )}
    </>
  );
};

export default DetalheDesvio;
