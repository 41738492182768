// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

// Components
import SelectInput from 'components/Inputs/Select';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Components Material UI

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Icon, IconButton, Modal, Grid } from '@mui/material';

// Utils
import * as S from './styled';
import { handleAddTurma } from '../../services';

const ModalAddTurma = ({ open, handleClose, data, fetchData }) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const tipo = searchParams.get('tipo');

  // General States
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const behaviorCloseModal = () => {
    handleClose();
  };

  const handleSave = async () => {
    setLoading(true);
    const turma = data.find(item => Number(item.id) === Number(selected));
    const obj = {
      tipo,
      alunoId: Number(id),
      turmaId: Number(turma.id),
    };
    handleAddTurma(obj)
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const options = data.map(item => ({
      name: item.nome.toString(),
      value: item.id,
      tipo: item.nome,
      alunoId: id,
      turmaId: item.id,
    }));
    setOptions(options);
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={behaviorCloseModal}
        disableEscapeKeyDown={false}
      >
        <S.Container>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              <>
                <SettingsOutlinedIcon
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
                <h2>Adicionar Turma</h2>
              </>
            </div>

            <IconButton size="small" onClick={behaviorCloseModal}>
              <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                close
              </Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES GERAIS</S.Section>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectInput
                required
                handleChange={e => {
                  setSelected(e.target.value);
                }}
                name="turmas"
                value={selected}
                label="Selecione a turma"
                data={[{ name: 'Selecione uma turma', value: '' }, ...options]}
              />
            </Grid>
          </S.Main>

          <S.Footer>
            <div />
            <div>
              <GhostButton onClick={behaviorCloseModal} size="medium">
                CANCELAR
              </GhostButton>

              <DefaultButton
                onClick={handleSave}
                loading={loading}
                size="medium"
                sx={{ marginLeft: '20px' }}
              >
                Salvar
              </DefaultButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>
    </>
  );
};

export default ModalAddTurma;
