import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: ${({ print }) => (print ? '300px' : '320px')};
  .container-filters {
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    .graphtitle {
      text-align: left;
      font-size: 24px;
      font-weight: 900;
      font-style: normal;
      letter-spacing: 0px;
      color: #4b5166;
    }
    .titletop {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  .legend-container {
    display: flex;
    justify-content: flex-end;
    transform: translateY(-40px);
    width: 100%;
    padding-right: 30px;
    .point1 {
      width: 12px;
      height: 12px;
      border-radius: 10px;
      background-color: #f64e60;
      border-radius: 100%;
      margin-right: 5px;
    }
    .point2 {
      width: 12px;
      height: 12px;
      background-color: #656e8c;
      border-radius: 100%;
      margin-right: 5px;
    }
  }
  .legend {
    display: flex;
    justify-content: center;
    .format-point {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }
  }
`;
