import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 230px;
    background-color: ${theme.palette.brand.primary.natural};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: -1;
    overflow: hidden;

    .titlePodium {
      font-size: 100px;
      color: ${theme.palette.words.text.contrast};
      font-weight: 600;
      transition: all ease 0.5s;
    }

    .titlePodium.noDisplay {
      display: none;
    }

    .titlePodium.moveTitle {
      margin-top: -50px;
      opacity: 0;
    }

    .emptyBox {
      display: flex;
      flex-direction: column;
      align-items: center;

      .text {
        font-size: 20px;
        color: ${theme.palette.words.text.contrast};
      }

      .sub {
        font-size: 16px;
        color: ${theme.palette.words.text.contrast};
      }
    }
  `}
`;

export const BoxPosition = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    .ring {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 136px;
      height: 136px;

      .overRing {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .imageArea {
          border: 2px solid ${theme.palette.brand.secondary.natural};
          width: 75px;
          height: 75px;
          border-radius: 80px;
          padding: 2px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            border-radius: 80px;
            background-color: ${theme.palette.system.highlight};
          }
        }
      }
    }

    .userInfo {
      position: relative;
      width: 200px;
      opacity: 0;
      transition: all ease 0.8s;
      margin-bottom: -500px;
      color: ${theme.palette.words.text.contrast};
      display: flex;
      flex-direction: column;
      align-items: center;

      .diferrencePos {
        position: absolute;
        font-size: 16px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        z-index: 1;

        .svg {
          width: 8px;
          margin-right: 10px;
        }

        .svg-up {
          margin-right: 10px;
        }
      }

      .footerInfo {
        position: absolute;
        top: 110px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .nameBox {
        font-weight: 500;
        width: 20vw;
        overflow: hidden;
        text-align: center;
        text-transform: capitalize;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .contentBox {
        font-size: 13px;
      }
    }

    .userInfo.animeted {
      margin-bottom: 0;
      opacity: 1;
    }

    .userInfo.pos1 {
      height: 160px;

      .nameBox {
        font-size: 20px;
      }
    }

    .ring::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 184px;
      padding: 20px;
      -webkit-mask: linear-gradient(${theme.palette.system.highlight} 0 0)
          content-box,
        linear-gradient(${theme.palette.system.highlight} 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    .pos1.ring {
      width: 152px;
      height: 152px;
    }

    .pos1.ring::before {
      padding: 20px;
      background: linear-gradient(
        45deg,
        rgba(255, 168, 1, 1) 0%,
        rgba(108, 96, 82, 1) 50%,
        rgba(75, 81, 102, 1) 75%
      );
    }

    .pos2.ring::before {
      background: linear-gradient(
        45deg,
        rgba(180, 180, 180, 1) 0%,
        rgba(75, 81, 102, 1) 60%,
        rgba(75, 81, 102, 1) 100%
      );
    }

    .pos3.ring::before {
      background: linear-gradient(
        45deg,
        rgba(188, 162, 111, 1) 0%,
        rgba(117, 111, 105, 1) 35%,
        rgba(75, 81, 102, 1) 60%
      );
    }
  `}
`;
