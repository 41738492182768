// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

// Styled
import Tasks from 'components/Tasks';
import * as S from './styled';

// Components

// Serives
import * as services from './services';
import { mockData, mockDay } from './mock';

const MyTasks = () => {
  const { status } = useSelector(state => state.filterMinhasTarefas);
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [reset, setReset] = useState(true);

  const {
    data: res,
    isError,
    isLoading,
  } = useQuery(['tasks', 1], () => services.getTasks());

  useEffect(() => {
    if (!isLoading) {
      if (!isError) {
        setData(res.data);
      } else {
        toast.error(res.message);
      }
    }
  }, [res]);

  // Filter Local
  useEffect(() => {
    if (data && status) {
      const statusTask = status.split(',');
      const filteredTasks = [];

      for (const i in data) {
        const { mes } = data[i];
        const { semanas } = data[i];
        const validWeeks = [];

        for (const j in semanas) {
          const { tarefas } = semanas[j];
          const { inicio } = semanas[j];
          const { final } = semanas[j];

          const validTasks = [];
          for (const k in tarefas) {
            const task = tarefas[k];

            if (statusTask.includes(task.status)) {
              validTasks.push(task);
            }
          }
          validWeeks.push({
            inicio,
            final,
            tarefas: validTasks,
          });
        }
        filteredTasks.push({
          mes,
          semanas: validWeeks,
        });
      }
      setFilteredData([...filteredTasks]);
    } else {
      setFilteredData(null);
    }

    setReset(false);
  }, [status, data]);

  return (
    <S.Wrapper>
      {data && (
        <Tasks
          data={filteredData || data}
          reset={reset}
          setReset={setReset}
          loading={isLoading}
        />
      )}
    </S.Wrapper>
  );
};

export default MyTasks;
