import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, expanded }) => css`
    width: 428px;
    height: 100%;
    max-height: 100%;
    background-color: ${theme.palette.system.overlay};
    position: absolute;
    margin-left: ${expanded ? '0px' : '-428px'};
    transition: all ease 0.3s;
    cursor: pointer;
    padding: 15px 30px;
    z-index: 5;
    overflow: auto;
  `}
`;

export const Header = styled.div`
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SectionArea = styled.div``;

export const IconsArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 15px;
`;

export const TitleSections = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.words.title.natural};
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
    display: flex;
    justify-content: start;
    align-items: center;

    span {
      margin-right: 10px;
    }
  `}
`;

export const ItemBox = styled.div`
  ${({ theme }) => css`
    display: flex;

    img {
      width: 20px;
    }

    .itemName {
      margin-left: 8px;
      color: ${theme.palette.words.subtitle.natural};
    }

    &:hover {
      .itemName {
        color: ${theme.palette.words.title.natural};
      }
    }
  `}
`;

export const NotFoundItems = styled.div`
  ${({ theme }) => css`
    font-weight: 600;
    color: ${theme.palette.semantics.feedback.unknown.natural};
    width: 100%;
  `}
`;
