// Styles
import * as S from './styled';

// Components
import { TD } from './TRow';
import { TH } from './THead';
import { Empty } from './Empty';
import { Actions } from './Actions';
import { CheckBox } from './CheckBox';

// Context && Hooks
import { useScope } from './useScope';
import { useTable } from '../../context';

export const Body = ({
  empty,
  sortBy,
  actions,
  loading,
  pageCount,
  hideLines,
  data = [],
  compactRow,
  fixedItems = 20,
  onClickRow,
  cachePerPage,
  columns = [],
  visualizedKey,
  transparent,
  disabledRows = [],
  local = true,
  reset = false,
  emptyFill = '-',
  setSelectedRows,
  searchKeys = [],
  notClickable = [],
  selectedByDefault,
  loadingSelection = [],
  actionsTitle = 'Ações',
}) => {
  const { state, dispatch } = useTable();

  const sort = state?.sortBy || sortBy || { id: 'id', order: 'ASC' };

  const { lines, generate } = useScope({
    data,
    sort,
    state,
    reset,
    local,
    columns,
    loading,
    dispatch,
    pageCount,
    hideLines,
    emptyFill,
    fixedItems,
    searchKeys,
    cachePerPage,
    setSelectedRows,
    selectedByDefault,
    loadingSelection,
  });

  return (
    <>
      <S.Container id="body-table-component" transparent={transparent}>
        {/* SELECTION COLUMNS */}
        {setSelectedRows && (
          <S.Column selection data-testid="selection">
            <thead>
              <TH
                selection
                loading={loading || !!loadingSelection?.length}
                children={
                  <CheckBox
                    all
                    rows={generate?.[0]?.rows?.map(row => row.original)}
                    setSelectedRows={setSelectedRows}
                  />
                }
                align="center"
              />
            </thead>

            <tbody>
              {generate[0]?.rows?.map((row, idx) => (
                <TD
                  key={idx}
                  selection
                  align="center"
                  loading={loading || loadingSelection?.includes(row.original)}
                  disabled={loading || !!loadingSelection?.length}
                  hover={row.item[visualizedKey]}
                  compactRow={compactRow}
                  children={
                    <CheckBox
                      id={row.original}
                      setSelectedRows={setSelectedRows}
                    />
                  }
                />
              ))}
            </tbody>
          </S.Column>
        )}

        {/* MAIN COLUMNS */}
        <S.Scrollable>
          {generate?.map((column, index) => (
            <S.Column
              style={{
                width: column?.header?.width,
                minWidth: column?.header?.width,
              }}
              lines={lines}
              key={index}
            >
              <thead>
                <TH
                  up={sort?.id === column?.header?.id && sort?.order === 'ASC'}
                  down={
                    sort?.id === column?.header?.id && sort?.order === 'DESC'
                  }
                  sort={column.header.sort}
                  access={column.header.id}
                  children={column?.header?.name}
                  align={column?.header?.align}
                  key={index}
                  loading={loading || !!loadingSelection?.length}
                  description={column?.header?.description}
                />
              </thead>

              <tbody>
                {column?.rows.map((row, idx) => {
                  let custom = null;
                  if (column.header.children) {
                    custom = column.header.children(row.value, row.item);
                  }

                  return (
                    <TD
                      blocked={notClickable?.includes(column.header.id)}
                      tooltip={column.header.tooltip}
                      align={column?.header?.align}
                      picture={row.picture}
                      custom={custom}
                      children={row.value}
                      subRow={row.subRow}
                      key={idx}
                      hover={row.item[visualizedKey]}
                      compactRow={compactRow}
                      loading={
                        loading || loadingSelection?.includes(row.original)
                      }
                      disabled={
                        loading ||
                        !!loadingSelection?.length ||
                        disabledRows.includes(row.original)
                      }
                      style={row.style}
                      attention={row.attention}
                      onClick={
                        onClickRow || row.onClick
                          ? row.onClick
                            ? row.onClick
                            : () => onClickRow(row.original, row.item)
                          : null
                      }
                    />
                  );
                })}
              </tbody>
            </S.Column>
          ))}
        </S.Scrollable>

        {/* ACTIONS COLUMN */}
        {actions && (
          <S.Column selection className="column">
            <thead>
              <TH
                children={actionsTitle}
                loading={loading || !!loadingSelection?.length}
                align="center"
              />
            </thead>

            <tbody>
              {generate?.[0]?.rows?.map((row, idx) => (
                <TD
                  key={idx}
                  selection
                  loading={loading || loadingSelection?.includes(row.original)}
                  disabled={loading || !!loadingSelection?.length}
                  hover={row.item[visualizedKey]}
                  compactRow={compactRow}
                  children={
                    <Actions
                      options={actions}
                      id={row.original}
                      item={row.item}
                    />
                  }
                  align="center"
                />
              ))}
            </tbody>
          </S.Column>
        )}
      </S.Container>

      {/* EMPTY */}
      {!loading && generate?.[0]?.rows?.length === 0 && (
        <Empty fixedItems={fixedItems} {...empty} />
      )}
    </>
  );
};
