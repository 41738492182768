import { useTheme } from 'styled-components';

import SearchIcon from '@mui/icons-material/Search';
import * as S from './styled';

const Search = ({ value, setValue, placeholder, ...props }) => {
  const theme = useTheme();
  return (
    <S.Wrapper {...props}>
      <SearchIcon className="icon" htmlColor={theme.palette.words.text.light} />
      <S.Input
        placeholder={placeholder || 'Buscar Formulário'}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </S.Wrapper>
  );
};

export default Search;
