import * as S from './styled';

const tagsLoading = ['', '', '', '', '', '', '', '', '', '', ''];

const Tag = ({ children, active, onClick, loading }) => {
  return (
    <>
      {!loading && (
        <S.TagItem className={active ? 'active' : ''}>
          {children}
          <S.TagItemButton onClick={onClick}>X</S.TagItemButton>
        </S.TagItem>
      )}
      {loading &&
        tagsLoading.map((item, key) => <S.TagItemLoading key={key} />)}
    </>
  );
};

export default Tag;
