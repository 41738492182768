import styled, { css } from 'styled-components';

export const Box = styled.div`
  ${({ theme, ...props }) => css`
    border: 2px solid
      ${props.isCheck
        ? theme.palette.brand.secondary.natural
        : theme.palette.semantics.feedback.unknown.natural};
    width: ${props.preview ? '18px' : '24px'};
    height: ${props.preview ? '18px' : '24px'};
    border-radius: 4px;
    cursor: ${props.disabled ? 'default' : 'pointer'};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props.isCheck
      ? theme.palette.brand.secondary.natural
      : theme.palette.system.transparent};
    pointer-events: ${props.disabled ? 'none' : 'all'};

    input {
      display: none;
    }

    @media (max-width: 1260px) {
      width: ${props.preview ? '14px' : '20px'};
      height: ${props.preview ? '14px' : '20px'};
    }
  `}
`;

export const Label = styled.label`
  ${({ theme, ...props }) => css`
    display: flex;
    align-items: center;
    cursor: ${props.disabled ? 'default' : 'pointer'};
    font-size: ${props.preview ? '14px' : '16px'};

    span {
      margin-left: 10px;
      font-weight: 600;
      color: ${props.hasError
        ? theme.palette.semantics.feedback.attention.natural
        : theme.palette.words.subtitle.natural};
    }

    @media (max-width: 1260px) {
      font-size: 14px;
    }
  `}
`;
