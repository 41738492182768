import exportFromJSON from 'export-from-json';
import { writeFileXLSX, utils } from 'xlsx';

export default function ExportToCsv({ excel, name, type }) {
  const data = excel;
  const fileName = name || 'download';
  const exportType = type || 'xls';

  return exportFromJSON({ data, fileName, exportType });
}

export const exportToExcel = (data, fileName) => {
  if (typeof data !== 'object') return;

  const wb = utils.book_new();
  if (data instanceof Array) {
    const ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, 'Data');
  } else
    Object.keys(data).forEach(key => {
      const ws = utils.json_to_sheet(data[key]);
      utils.book_append_sheet(wb, ws, key);
    });
  writeFileXLSX(wb, `${fileName}.xlsx`);
};
