import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { Icon, IconButton, Modal, Grid, Button } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { toast } from 'react-toastify';

import Input from 'components/Inputs/TextField';
import SelectInput from 'components/Inputs/Select';
import DefaultButton from 'components/Buttons/Default';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';

import { formatNewDate } from 'utils/dates';
import { firestorage } from 'utils/firebase';
import { validateCPF } from 'utils/helpers';
import * as S from './styled';

// Service
import { changeDriverStatus, createDriver, updateDriver } from '../services';

const ModalAddMotorista = ({
  open,
  dataEdit,
  handleClose,
  // setTriggerUpdate,
  fetch = () => {},
}) => {
  const theme = useTheme();
  const { filials, clients } = useSelector(state => {
    return state.selects;
  });

  const [data, setData] = useState({
    nome: '',
    cpf: '',
    matricula: '',
    funcao: '',
    genero: null,
    data_de_nascimento: '',
    data_de_admissao: '',
    id_da_filial: '',
    id_da_distribuidora: null,
    empresa_terceira: '',
    cnh: '',
    primeira_cnh: '',
    validade_cnh: '',
    estado_civil: null,
    fumante: null,
    celular: '',
    agregado: null,
    foto: null,
  });

  // Carregas dados para editar
  // Dados originais ficam em dataEdit
  const cleanFields = () => {
    setData({
      nome: '',
      cpf: '',
      matricula: '',
      funcao: '',
      genero: null,
      data_de_nascimento: '',
      data_de_admissao: '',
      id_da_filial: '',
      id_da_distribuidora: null,
      empresa_terceira: '',
      cnh: '',
      primeira_cnh: '',
      validade_cnh: '',
      estado_civil: null,
      fumante: null,
      celular: '',
      agregado: null,
      foto: null,
    });
  };

  const inputFile = useRef(null);

  useEffect(() => {
    if (dataEdit && dataEdit.id) {
      const formatedData = {
        ...dataEdit,
        agregado: dataEdit.agregado ? 'SIM' : 'NÃO',
      };
      for (const key in dataEdit) {
        if (!formatedData[key]) formatedData[key] = '';
        if (
          formatedData[key] !== '' &&
          (key === 'data_de_nascimento' ||
            key === 'data_de_admissao' ||
            key === 'validade_cnh' ||
            key === 'primeira_cnh')
        )
          formatedData[key] = String(formatNewDate(formatedData[key]))
            .replace('/', '-')
            .replace('/', '-');
      }
      setData(formatedData);
    } else {
      cleanFields();
    }
  }, [open]);

  const [loading, setLoading] = useState(false);
  const [openConfirmSave, setOpenConfirmSave] = useState(false);
  const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
  const [openConfirmChangeStatus, setOpenConfirmChangeStatus] = useState(false);

  const updateDriverImage = async () => {
    const file = inputFile.current.files[0];
    if (file) {
      try {
        setLoading(true);
        const dh = new Date().toDateString();
        if (!file) return toast.error('Não foi possível enviar sua imagem');

        const uploadTask = firestorage
          .ref(`/motoristas/${dh}_${file.name}`)
          .put(file);
        uploadTask.on(
          'state_changed',
          snapShot => {},
          err => {
            console.log('Err', err);
          },
          () => {
            firestorage
              .ref(`/motoristas/`)
              .child(`${dh}_${file.name}`)
              .getDownloadURL()
              .then(fireBaseUrl => {
                setData({ ...data, foto: fireBaseUrl });
              });
          },
        );
      } catch (error) {
        toast.error('Não foi possível enviar sua imagem');
      }
      setLoading(false);
    }
  };

  const formatCelular = value => {
    if (!value) return '';
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '($1)-$2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    return value;
  };

  // Tratamento dos inputs
  // Selects são são controlados por data =[...]
  // TextField é condicional
  const handleChange = e => {
    let { value } = e.target;
    const { name } = e.target;

    if (name === 'nome') {
      value = value.toLocaleUpperCase();
    } else if (name == 'cpf') {
      const numbers = /^[0-9.-]+$/;
      value = value
        .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})*/, '$1.$2.$3-$4')
        .match(numbers);
      if (value && value.input) value = value.input;
      if (value && value.length > 14) {
        value = value.slice(0, 14);
      }
    } else if (name == 'celular') {
      value = formatCelular(value);
    } else if (
      name == 'data_de_admissao' ||
      name == 'data_de_nascimento' ||
      name == 'validade_cnh' ||
      name == 'primeira_cnh'
    ) {
      const numbersss = /^[0-9-]+$/;
      value = value
        .replace(/^(\d{2})(\d{2})(\d{4})*/, '$1-$2-$3')
        .match(numbersss);
      if (value && value.input.length > 10) {
        value = value.input.slice(0, 10);
      }
    } else if (name === 'funcao' || name === 'cnh') {
      value = value.slice(0, 30);
    }

    setData({ ...data, [name]: value });
  };

  // Verifica se os campos estão preenchidos
  const validateInputs = () => {
    const notRequired = [
      'estado_civil',
      'fumante',
      'empresa_terceira',
      'cnh',
      'primeira_cnh',
      'validade_cnh',
      'id_da_distribuidora',
      'id_gestor',
      'foto',
      'distribuidora',
      'assinatura_adesao',
      'resetar_senha',
      'disabled_at',
    ];
    const validDates = [
      'data_de_nascimento',
      'data_de_admissao',
      'primeira_cnh',
      'validade_cnh',
    ];
    for (const item in data) {
      if (!notRequired.includes(item) && !data[item]) {
        toast.warning(`O campo ${item} é obrigatório`);
        const doc = document.getElementById(item);
        doc?.focus();
        return false;
      }
    }
    for (const date of validDates) {
      let skip = 0;
      if (
        data[date] === null ||
        data[date][0] === null ||
        data[date] === undefined ||
        data[date][0] === undefined
      )
        skip = +1;
      else if (
        data[date][0].split('-')[0] > 31 ||
        (data[date][0] && data[date][0].split('-')[1] > 12)
      ) {
        toast.warning(`Formato inválido de data: ${data[date]}`);
        return false;
      }
    }

    if (
      data.celular !== null &&
      data.celular !== undefined &&
      data.celular !== ''
    ) {
      const dado = data.celular
        .replace('(', '')
        .replace(')', '')
        .replace('-', '')
        .replace('-', '');
      if (dado.length > 11 || dado.length < 11) {
        toast.warning(`Formato do celular incorreto`);
        return false;
      }
    }

    const validatedCPF = validateCPF(data.cpf);
    if (!validatedCPF) {
      toast.warning('CPF Inválido');
      return false;
    }

    return true;
  };

  // Valida dados e abre janela de confirmação para add ou update
  const handleApply = async () => {
    const validated = validateInputs();
    if (!validated) return;
    if (dataEdit) setOpenConfirmUpdate(true);
    else setOpenConfirmSave(true);
  };

  // Finaliza adição
  // setTriggerUpdate(true) força atualização dos dados armazenados
  const handleAddMotorista = async data => {
    setLoading(true);
    const res = await createDriver(data);
    if (res.data?.success) {
      toast.success(res.data.message);
      fetch();
      // setTriggerUpdate(true);
      handleClose();
    } else if (res.data) {
      if (res.data.message) toast.error(res.data.message);
      if (res.data.errors) {
        toast.error(res.data.errors);
        res.data.errors.forEach(err => toast.error(err));
      }
    }

    setLoading(false);
    setOpenConfirmSave(false);
  };

  // Finaliza edição
  // setTriggerUpdate(true) força atualização dos dados armazenados
  const handleEditMotorista = async data => {
    setLoading(true);

    const res = await updateDriver(data);
    if (res && res.data?.success) {
      toast.success(res.data.message);
      fetch();
      // setTriggerUpdate(true);
      handleClose();
    } else if (res && res.data) {
      if (res.data.message) toast.error(res.data.message);
      if (res.data.errors) {
        toast.error(res.data.errors);
        res.data.errors.forEach(err => toast.error(err));
      }
    } else
      toast.error(
        'Erro ao editar motorista, tente mais tarde, caso persista procure o nosso time de suporte.',
      );

    setLoading(false);
    setOpenConfirmUpdate(false);
  };

  // Finaliza edição de status
  // setTriggerUpdate(true) força atualização dos dados armazenados
  const handleDesativarAtivar = async truckId => {
    setLoading(true);

    const res = await changeDriverStatus(truckId);
    if (res && res.data?.success) {
      toast.success(res.data.message);
      // setTriggerUpdate(true);
      handleClose();
    } else if (res && res.data?.message) toast.error(res.data.message);
    else
      toast.error(
        'Erro ao ativar/desativar motorista, tente mais tarde, caso persista procure o nosso time de suporte',
      );

    setLoading(false);
    setOpenConfirmChangeStatus(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} disableEscapeKeyDown={false}>
        <S.Container>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              {dataEdit ? (
                <>
                  <EditOutlined
                    sx={{ color: theme.palette.brand.secondary.natural }}
                    fontSize="medium"
                  />
                  <h2>Editar Motorista</h2>
                </>
              ) : (
                <>
                  <Icon
                    sx={{ color: theme.palette.brand.secondary.natural }}
                    fontSize="medium"
                  >
                    add_circle_outline
                  </Icon>
                  <h2>Adicionar Novo Motorista</h2>
                </>
              )}
            </div>

            <IconButton size="small" onClick={handleClose}>
              <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                close
              </Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES DE PERFIL</S.Section>
              </Grid>

              <Grid item xs={12} sm={12}>
                <label>
                  <input
                    type="file"
                    multiple={false}
                    ref={inputFile}
                    onChange={updateDriverImage}
                    disabled={loading}
                  />
                  <div className="imgArea">
                    {data.foto && (
                      <span className="borderImage">
                        <img src={data.foto} />
                      </span>
                    )}

                    {!data.foto && (
                      <span className="empty">{data.nome[0]}</span>
                    )}
                  </div>
                  <div className="textArea">
                    <EditOutlinedIcon htmlColor="#656E8C" />
                    <span>Alterar Foto</span>
                  </div>
                </label>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Input
                  required
                  label="Nome"
                  placeholder="Nome"
                  value={data.nome || ''}
                  onChange={handleChange}
                  name="nome"
                  id="nome"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Input
                  required
                  label="CPF"
                  placeholder="CPF"
                  value={data.cpf || ''}
                  onChange={handleChange}
                  name="cpf"
                  id="cpf"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <SelectInput
                  required
                  handleChange={handleChange}
                  name="id_da_filial"
                  label="Filial"
                  data={filials.map(item => {
                    return { name: item.nome, value: item.id };
                  })}
                  value={data.id_da_filial}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Input
                  required
                  label="Matrícula"
                  placeholder="Matrícula"
                  value={data.matricula || ''}
                  onChange={handleChange}
                  name="matricula"
                  id="matricula"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <SelectInput
                  required
                  handleChange={handleChange}
                  name="genero"
                  label="Gênero"
                  data={[
                    { name: 'Feminino', value: 'Feminino' },
                    { name: 'Masculino', value: 'Masculino' },
                    { name: 'Outro', value: 'Outro' },
                  ]}
                  value={data.genero}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Input
                  required
                  label="Função"
                  placeholder="Função"
                  value={data.funcao || ''}
                  onChange={handleChange}
                  name="funcao"
                  id="funcao"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Input
                  required
                  label="Celular"
                  placeholder="(00)-00000-0000"
                  value={data.celular || ''}
                  onChange={handleChange}
                  name="celular"
                  id="celular"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Input
                  required
                  label="Data de nascimento"
                  placeholder="dd/mm/aaaa"
                  value={data.data_de_nascimento || ''}
                  onChange={handleChange}
                  name="data_de_nascimento"
                  id="data_de_nascimento"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Input
                  required
                  label="Data de admissão"
                  placeholder="dd/mm/aaaa"
                  value={data.data_de_admissao || ''}
                  onChange={handleChange}
                  name="data_de_admissao"
                  id="data_de_admissao"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <SelectInput
                  required
                  handleChange={handleChange}
                  name="agregado"
                  label="Agregado"
                  data={[
                    { name: 'SIM', value: 'SIM' },
                    { name: 'NÃO', value: 'NÃO' },
                  ]}
                  value={data.agregado}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES EXTRAS (Não obrigatórias)</S.Section>
              </Grid>

              <Grid item xs={6} sm={6}>
                <Input
                  label="Empresa terceira"
                  placeholder="Empresa terceira"
                  value={data.empresa_terceira || null}
                  onChange={handleChange}
                  name="empresa_terceira"
                  id="empresa_terceira"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Input
                  label="CNH"
                  placeholder="CNH"
                  value={data.cnh || null}
                  onChange={handleChange}
                  name="cnh"
                  id="cnh"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Input
                  label="Validade CNH"
                  placeholder="dd/mm/aaaa"
                  value={data.validade_cnh || null}
                  onChange={handleChange}
                  name="validade_cnh"
                  id="validade_cnh"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Input
                  label="Primeira CNH"
                  placeholder="dd/mm/aaaa"
                  value={data.primeira_cnh || null}
                  onChange={handleChange}
                  name="primeira_cnh"
                  id="primeira_cnh"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <SelectInput
                  handleChange={handleChange}
                  name="estado_civil"
                  label="Estado Civil"
                  data={[
                    { name: 'Solteiro(a)', value: 'Solteiro' },
                    { name: 'Casado(a)', value: 'Casado' },
                    { name: 'Separado(a)', value: 'Separado' },
                    { name: 'Divorciado(a)', value: 'Divorciado' },
                    { name: 'Viúvo(a)', value: 'Viúvo' },
                    { name: 'União Estavel', value: 'União Estavel' },
                  ]}
                  value={data.estado_civil}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <SelectInput
                  handleChange={handleChange}
                  name="fumante"
                  label="Fumante"
                  data={[
                    { name: 'SIM', value: 'SIM' },
                    { name: 'NÃO', value: 'NÃO' },
                  ]}
                  value={data.fumante}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <SelectInput
                  handleChange={handleChange}
                  name="id_da_distribuidora"
                  label="Cliente Exclusivo"
                  clearLabel="Limpar"
                  data={clients.map(item => {
                    return { name: item.nome, value: item.id };
                  })}
                  value={data.id_da_distribuidora}
                />
              </Grid>
            </Grid>
          </S.Main>

          <S.Footer>
            {dataEdit ? (
              <Button
                onClick={() => setOpenConfirmChangeStatus(true)}
                size="medium"
                variant="contained"
                style={{
                  color:
                    dataEdit.status === 'ATIVO'
                      ? theme.palette.semantics.feedback.attention.natural
                      : theme.palette.brand.secondary.natural,
                  background: '#FFF',
                  border:
                    dataEdit.status === 'ATIVO'
                      ? `1px solid ${theme.palette.semantics.feedback.attention.natural}`
                      : `1px solid ${theme.palette.brand.secondary.natural}`,
                  font: 'normal normal 900 14px/18px Texta',
                  height: '40px',
                }}
              >
                {dataEdit.status === 'ATIVO'
                  ? 'DESATIVAR MOTORISTA'
                  : 'REATIVAR MOTORISTA'}
              </Button>
            ) : (
              <div />
            )}
            <div>
              <GhostButton onClick={handleClose} size="medium">
                CANCELAR
              </GhostButton>

              <DefaultButton
                onClick={handleApply}
                size="medium"
                sx={{ marginLeft: '20px' }}
              >
                SALVAR
              </DefaultButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>

      {/* Confirmação de adição */}
      <ConfirmModal
        open={openConfirmSave}
        handleClose={() => setOpenConfirmSave(false)}
        title="Deseja adicionar motorista?"
        titleIcon={
          <Icon
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          >
            add_circle_outline
          </Icon>
        }
        subtitle="Tem certeza que deseja criar um acesso para este motorista?"
        buttonText="Confirmar"
        onClick={() => handleAddMotorista(data)}
        loading={loading}
      />

      {/* Confirmação de edição */}
      <ConfirmModal
        open={openConfirmUpdate}
        handleClose={() => setOpenConfirmUpdate(false)}
        title="Deseja editar motorista?"
        titleIcon={
          <EditOutlined
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          />
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => handleEditMotorista(data)}
        loading={loading}
      />

      {
        /* Confirmação de exclusão / reativação */
        dataEdit && (
          <ConfirmModal
            open={openConfirmChangeStatus}
            handleClose={() => setOpenConfirmChangeStatus(false)}
            title={
              dataEdit.status === 'ATIVO'
                ? 'Deseja desativar este motorista?'
                : 'Deseja reativar este motorista?'
            }
            titleIcon={
              <EditOutlined
                sx={{ color: theme.palette.brand.secondary.natural }}
                fontSize="medium"
              />
            }
            subtitle="Motoristas inativos não tem avaliações geradas independente de estarem rodando!"
            buttonText="Confirmar"
            onClick={() => handleDesativarAtivar(dataEdit.id)}
            loading={loading}
          />
        )
      }
    </>
  );
};

export default ModalAddMotorista;
