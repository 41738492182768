export const formatTaskColor = (status, disabled, theme) => {
  switch (status) {
    case 'PENDENTE': {
      return {
        backgroundColor: disabled
          ? theme?.palette.semantics.feedback.warning.natural
          : theme?.palette.brand.secondary.natural,
        color: theme?.palette.words.text.contrast,
      };
    }
    case 'VENCIDO': {
      return {
        backgroundColor: theme?.palette.semantics.feedback.attention.natural,
        color: theme?.palette.words.text.contrast,
      };
    }
    case 'CONCLUIDO': {
      return {
        backgroundColor: theme?.palette.system.border,
        color: theme?.palette.words.title.natural,
      };
    }
  }
};

export const formatIconColor = (status, theme) => {
  switch (status) {
    case 'PENDENTE': {
      return theme?.palette.words.text.contrast;
    }
    case 'VENCIDO': {
      return theme?.palette.words.text.contrast;
    }
    case 'CONCLUIDO': {
      return theme?.palette.words.title.natural;
    }
  }
};

export const formatBorderColor = (status, theme) => {
  switch (status) {
    case 'PENDENTE': {
      return theme?.palette.brand.secondary.natural;
    }
    case 'VENCIDO': {
      return theme?.palette.semantics.feedback.attention.natural;
    }
    case 'CONCLUIDO': {
      return theme?.palette.system.border;
    }
  }
};
