import { useEffect, useRef, useState } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import Loading from 'components/Loading';
import { firebaseConfig } from 'utils/config';

const render = status => {
  switch (status) {
    case Status.LOADING:
      return <Loading />;
    case Status.FAILURE:
      return <>Falha ao carregar o mapa.</>;
  }
};

const MyMapComponent = ({
  options,
  mapContainerClassName,
  getMapInstance,
  children,
}) => {
  const [map, setMap] = useState(null);
  const ref = useRef();

  useEffect(() => {
    setMap(
      new window.google.maps.Map(ref.current, {
        center: { lat: -13.923025, lng: -56.8509357 },
        zoom: 4,
        disableDefaultUI: true,
        gestureHandling: 'cooperative',
        ...options,
      }),
    );
  }, []);

  useEffect(() => {
    if (map && getMapInstance instanceof Function) getMapInstance(map);
  }, [map]);

  return (
    <div style={{ position: 'relative' }}>
      <div className={mapContainerClassName} ref={ref} id="map" />
      {children}
    </div>
  );
};

export const GoogleMap = props => {
  return (
    <Wrapper
      id="wrapper"
      apiKey={firebaseConfig.apiKey}
      libraries={['marker', 'places', 'visualization']}
      render={render}
    >
      <MyMapComponent {...props} />
    </Wrapper>
  );
};

export const calculateCenter = positions => {
  if (positions.length === 0) {
    return { lat: 0, lng: 0 }; // Retorna o centro do mapa como lat:0, lng:0 se a lista estiver vazia
  }

  let totalLat = 0;
  let totalLng = 0;

  positions.forEach(function (position) {
    totalLat += position.lat;
    totalLng += position.lng;
  });

  let avgLat = totalLat / positions.length;
  let avgLng = totalLng / positions.length;

  return { lat: avgLat, lng: avgLng };
};

export const calculateZoom = (map, positions) => {
  let bounds = new google.maps.LatLngBounds();

  positions.forEach(function (position) {
    if (position.lat)
      bounds.extend(new google.maps.LatLng(position.lat, position.lng));
  });

  map.fitBounds(bounds);
};
