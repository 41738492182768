// Styles
import { useTheme } from 'styled-components';

// React
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import { SvgIcon } from '@mui/material';
import { NotFoundConfig } from './NotFound';

// Material UI
import * as S from './styled';

// Constants
import { pages } from './constants';

import * as api from './Perfil&Empresa/services';

export const Configuracoes = () => {
  const theme = useTheme();
  const { user } = useSelector(state => state.auth.user);
  const company = user.filial.id_da_empresa;
  let page = useLocation().pathname.split('/')[2];
  const navigate = useNavigate();
  const [component, setComponent] = useState(<></>);
  const [planos, setPlanos] = useState([]);

  useEffect(() => {
    requestPlanosContratados();
  }, []);

  useEffect(() => {
    if (!page) page = 'perfil';
    renderComponent();
  }, [page]);

  const requestPlanosContratados = async () => {
    const data = await api.requireInfos();

    const { planos } = data;
    let planosIds = [];
    planos.map(item => planosIds.push(item.id));
    setPlanos(planosIds);
  };

  const renderComponent = () => {
    // Usuários nível > 1 acessam submenus de Perfil, Notificações e Segurança
    // if (
    //   user.nivel > 1 &&
    //   page !== 'perfil' &&
    //   page !== 'notificacoes' &&
    //   page !== 'seguranca'
    // ) {
    //   setComponent(<NotFoundConfig />);
    //   return;
    // }

    let currentPage = null;
    for (const i in pages) {
      if (pages[i].id === page) {
        currentPage = pages[i].element;
        setComponent(currentPage);
        return;
      }
    }

    if (!page) {
      currentPage = pages[0].element;
      setComponent(currentPage);
      return;
    }

    setComponent(<NotFoundConfig />);
  };

  return (
    <S.Container>
      <S.NavBar>
        {pages.map((i, k) => {
          let okPlans = true;
          let okLevel = true;
          let okCompany = true;

          if (i.plans) okPlans = planos.some(item => i.plans.includes(item));
          if (i.levels) okLevel = i.levels.includes(user.nivel);
          if (i.company) okCompany = i.company.includes(company);

          if (okPlans && okLevel && okCompany)
            return (
              <span
                onClick={() => navigate(i.linkTo, { replace: true })}
                className={page === i.id ? 'navBarItem active' : 'navBarItem'}
                key={k}
              >
                <SvgIcon
                  component={i.icon}
                  stroke={
                    page === i.id
                      ? theme.palette.brand.secondary.natural
                      : theme.palette.brand.primary.light
                  }
                />
                <span>{i.page}</span>
              </span>
            );
        })}
      </S.NavBar>
      <S.TemplatePage>
        <div className="alignContent">{component}</div>
      </S.TemplatePage>
    </S.Container>
  );
};
