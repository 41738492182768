import { Modal } from '@mui/material';
import styled from 'styled-components';
import colors from 'styles/colors';

export const ModalMUI = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 440px;
  height: 530px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.greyTiny};
  padding-bottom: 15px;

  .left {
    display: flex;
    align-items: center;

    h2 {
      margin-left: 5px;
    }

    .MuiSvgIcon-root {
      color: ${colors.greenMain};
      cursor: default;
    }
  }

  .MuiSvgIcon-root {
    color: ${colors.greySubtitle};
    cursor: pointer;
  }
`;

export const Body = styled.div`
  margin-top: 20px;

  .input {
    position: relative;

    .counter {
      position: absolute;
      right: 0;
      margin-top: -20px;
      font-size: 12px;
      font-weight: 600;
      color: ${colors.greySubtitle};
      margin-right: 10px;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;

    .labelBox {
      flex: 1;
    }
  }
`;

export const Footer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  bottom: 20px;
  right: 20px;
  gap: 20px;
`;
