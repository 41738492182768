import styled, { css } from 'styled-components';

export const Box = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: #fff;
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;
  `}
`;

export const Label = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.words.subtitle.natural};
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    span {
      margin-right: 7px;
    }
  `}
`;

export const FalseSelect = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${theme.palette.words.subtitle.natural};
    width: 100%;
    padding: 13px 20px;
    border-radius: 4px;

    div {
      margin-right: 5px;
      font-weight: 500;
      color: ${theme.palette.words.subtitle.natural};
      opacity: ${props => (props.isPublished ? 0.5 : 1)};
    }
  `}
`;

export const MultipleContent = styled.div`
  opacity: ${props => (props.isPublished ? 0.5 : 1)};
`;

export const BoxTags = styled.div`
  margin-top: 10px;
  align-items: center;
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  gap: 1;
`;

export const FinishBox = styled.div`
  position: relative;

  .textBox {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: 0;
    cursor: pointer;
  }
`;

export const Counter = styled.span`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: end;
    font-size: 11px;
    font-weight: 600;
    padding-right: 3px;
    color: ${theme.palette.words.title.natural};
  `}
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
