// Styled

// React
import { useEffect, useState } from 'react';

// Components
import DropZoneArchives from 'components/Inputs/DropZoneCustom';

// Components MUI
import { Divider } from '@mui/material';
import { WithQuestion } from '..';
import * as S from './styled';

// Utils
import { changeText } from '../../actions';

export const ResponseFoto = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosObrigatorios,
  preview,
  approveView,
}) => {
  const redirectFunction = (executable, idCampo, texto) => {
    setCleanField(true);
    executable(texto, idCampo, setSecoes, secoes, sectionId);
  };

  const addImage = item => {
    setCleanField(true);
    redirectFunction(changeText, field.id, item[0].imagem_url);
  };

  const deleteImage = item => {
    setCleanField(true);
    if (item) {
      redirectFunction(changeText, field.id, '');
    }
  };

  // ------------------------------------ERROS-------------------------------------------//
  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [cleanField, setCleanField] = useState(false);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setCleanField(false);
    if (approveView) return null;

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }
  }, [errosObrigatorios]);

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanField}
        className={`fieldClass-${field.id}`}
      >
        <WithQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanField}
          preview={preview}
          approveView={approveView}
        />
        <S.ResponseArea>
          <DropZoneArchives
            data={
              field.resposta
                ? { imagem_url: field.resposta, legenda: '' }
                : null
            }
            multiple={false}
            fileDir="formularios/campos"
            addFunction={addImage}
            deleteFunction={deleteImage}
            automaticSave
            titleButton="Adicionar Imagem"
            onlyRead={approveView}
          />
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
