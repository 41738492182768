import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  padding: 0px 20px;
`;

export const ButtonContianer = styled.div`
  display: flex;
  justify-content: space-between;

  .titleTurma {
    text-transform: capitalize;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
export const Paragraph = styled.p`
  font-size: ${props => (props.fontsize ? props.fontsize : '16px')};
  color: ${props => (props.color ? props.color : colors.grey)};
  font-weight: ${props => (props.fontweight ? props.fontweight : 400)};
  min-width: ${props => props.minwidth};

  &.status {
    font-weight: bold;
    color: ${props => props.color};
    background-color: ${props => props.bg};
    text-align: center;
    border-radius: 100px;
  }

  &.activity {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: ${props => (props.hasActivity ? 600 : 400)};
    color: ${props => (props.hasActivity ? colors.greyTitle : colors.greyTiny)};

    span {
      margin-right: 10px;
    }
  }
`;

export const ColDriver = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  text-transform: capitalize;

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.greyTitle};
  }

  .container-img-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  img {
    width: 41px;
    height: 41px;
    border: 1px solid ${colors.greyBorder};
    border-radius: 100px;
  }
`;
