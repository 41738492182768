import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  border: 2px solid rgb(237, 242, 249);
  border-radius: 4px;

  width: 100%;
  height: ${({ print }) => (print ? '300px' : '320px')};
  .container-filters {
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    .graphtitle {
      text-align: left;
      font-size: 24px;
      font-weight: 900;
      font-style: normal;
      letter-spacing: 0px;
      color: #4b5166;
    }
    .titletop {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;
