import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .icon {
    position: absolute;
    margin-left: 10px;
  }
`;

export const Input = styled.input`
  ${({ theme }) => css`
    width: 280px;
    padding: 10px 10px 10px 36px;
    outline: none;
    background-color: transparent;
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    border-radius: 6px;
    font-size: 16px;
    color: ${theme.palette.words.text.light};

    &::placeholder {
      color: ${theme.palette.words.text.light};
      font-weight: 500;
    }
  `}
`;
