import api from 'services/api';

export const getCards1 = async params => {
  const res = await api.get(`/home/tarefas/cards1`, { params });
  return res.data;
};

export const getCards2 = async params => {
  const res = await api.get(`/home/tarefas/cards2`, { params });
  return res.data;
};

export const getCards3 = async params => {
  const res = await api.get(`/home/tarefas/cards3`, { params });
  return res.data;
};

export const getCardsRevisoes = async params => {
  const res = await api.get(`/home/tarefas/revisoes-aprovadas`, { params });
  return res.data;
};

export const historicoDesvios = async params => {
  const res = await api.get(`/home/tarefas/historico-desvios`, { params });
  return res.data;
};

export const alertaDesvios = async params => {
  const res = await api.get(`/home/tarefas/alerta-desvios`, { params });
  return res.data;
};

export const minhasTarefas = async params => {
  const res = await api.get(`/home/tarefas/minhas-tarefas`, { params });
  return res.data;
};
