import React, { useState, useEffect } from 'react';
import { OpenInFull, CloseOutlined } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import { Modal } from '@mui/material';
import * as S from './styled';
import { GoogleMap } from 'components/GoogleMap';

export const Map = ({ position }) => {
  const [map, setMap] = useState(null);
  const [markerRef, setMarkerRef] = useState(null);

  // Adiciona o marcador no mapa
  useEffect(() => {
    if (!map || !position) return;
    const _markerRef = new window.google.maps.Marker({
      map,
      position,
    });
    setMarkerRef(_markerRef);
    map.setCenter(position);
    map.setZoom(14);

    return () => {
      if (markerRef) markerRef.setMap(null);
    };
  }, [map, position]);

  return (
    <GoogleMap
      mapContainerClassName="map"
      options={{
        center: {
          lat: -13.923025,
          lng: -56.8509357,
        },
        zoom: 4,
        zoomControl: true,
      }}
      getMapInstance={mapInstance => setMap(mapInstance)}
    />
  );
};

const MapDesvio = ({ position }) => {
  const theme = useTheme();
  const [expandedImage, setExpandedImage] = useState(false);
  return (
    <>
      <S.MapContainer>
        <Map position={position} />
        <S.MapIcon onClick={() => setExpandedImage(true)}>
          <OpenInFull
            sx={{
              color: theme.palette.brand.secondary.natural,
              fontSize: 40,
            }}
          />
        </S.MapIcon>
      </S.MapContainer>
      <Modal open={expandedImage} onClose={() => setExpandedImage(false)}>
        <S.Paper>
          <S.MapModalContainer>
            <Map position={position} />
            <S.MapIcon onClick={() => setExpandedImage(false)}>
              <CloseOutlined
                sx={{
                  color: theme.palette.brand.secondary.natural,
                  fontSize: 40,
                }}
              />
            </S.MapIcon>
          </S.MapModalContainer>
        </S.Paper>
      </Modal>
    </>
  );
};

export default MapDesvio;
