import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid
    ${props => (props.isError ? colors.redDanger : colors.greyTiny)};
  display: flex;
`;

export const Left = styled.div`
  flex: 2;
  border-right: 1px solid ${colors.greyTiny};
  padding: 20px 30px;
`;

export const Right = styled.div`
  flex: 1;
`;

export const TitleArea = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  h3 {
    font-size: 22px;
    font-weight: 900;
    color: ${colors.greyTitle};
    margin-left: 10px;
  }
`;

export const QuestionsArea = styled.div`
  margin-bottom: 20px;

  p {
    font-size: 17px;
    font-weight: 900;
    color: ${colors.greyTitle};
  }
`;

export const OptionsArea = styled.div`
  margin-top: 25px;

  .headerLine {
    span {
      color: ${colors.greySubtitle};
      font-size: 16px;
      font-weight: 600;
    }

    .op {
      margin-left: 40px;
    }
  }

  .options {
    margin-top: 10px;

    .option {
      display: flex;
      align-items: center;

      .text {
        margin-left: 47px;
        font-weight: 600;
        color: ${colors.greySubtitle};

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    .add {
      margin-top: 10px;
      cursor: pointer;
      display: inline-flex;
      transition: all 0.1s ease-in-out;

      &:hover {
        .addIcon {
          color: ${colors.greenMain};
        }

        .addText {
          color: ${colors.greenMain};
        }
      }

      .addIcon {
        margin-left: 9px;
      }

      .addText {
        margin-left: 50px;
      }
    }
  }
`;

export const InputOption = styled.input`
  border: none;
  border-bottom: 1px solid
    ${props => (props.isError ? colors.redDanger : colors.greyTiny)};
  margin-left: 47px;
  margin-right: 20px;
  width: 40%;
  font-weight: 600;
  color: ${colors.greySubtitle};
  font-size: 16px;

  &::placeholder {
    color: ${props => (props.isError ? colors.redDanger : colors.grey)};
    font-size: 14px;
  }
`;

export const FeedbackArea = styled.div`
  p {
    font-size: 17px;
    font-weight: 900;
    color: ${colors.greyTitle};
  }
`;

export const HeaderRight = styled.div`
  padding: 20px 30px;
  display: flex;
  justify-content: end;
`;

export const BodyRight = styled.div`
  .titleBody {
    font-size: 14px;
    font-weight: 700;
    color: ${colors.greyTitle};
    text-transform: uppercase;
    margin-left: 20px;
  }
`;

export const QuestionsLine = styled.div`
  margin-top: 10px;
`;

export const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  background-color: ${props =>
    props.isError
      ? colors.redBackground
      : props.isSelected
      ? colors.greyBorder
      : 'transparent'};

  &:hover {
    background-color: ${colors.greyBorder};
  }

  .left {
    display: flex;
    padding-left: 20px;
    align-items: center;
    height: 100%;
    width: 100%;

    .index {
      font-size: 18px;
      font-weight: bold;
      color: ${props => (props.isError ? colors.redDanger : colors.greyTitle)};
    }

    .text {
      margin-left: 15px;
      font-weight: 600;
      color: ${props =>
        props.isError
          ? colors.redDanger
          : props.isEmpty
          ? colors.grey
          : colors.greyTitle};

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .bar {
      width: 3px;
      height: 45px;
      background-color: ${props =>
        props.isSelected ? colors.greenMain : 'transparent'};
      margin-left: 15px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      transition: all 0.2s ease-in-out;
    }
  }
`;
