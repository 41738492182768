import React from 'react';
import Modal from '@mui/material/Modal';
import Icon from 'components/Icons';

import { Button } from '@mui/material';
import Loading from 'components/Loading';
import { useTheme } from 'styled-components';
import * as S from './styled';

const SaveModal = ({
  handleClose,
  open,
  title,
  onClick,
  disabled,
  children,
  width = '400px',
  height = '200px',
  backgroundColor = '#FFFFFF',
  iconStart = null,
  buttonCancelTitle = 'Não',
  buttonConfirmTitle = 'Sim',
}) => {
  const theme = useTheme();

  const root = {
    flexGrow: 1,
  };

  const body = (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        width,
        height,
        backgroundColor,
        border: `0px 5px 20px ${theme.palette.system.divider}`,
        borderRadius: '4px',
        padding: '25px 25px',
        margin: '0 auto',
      }}
    >
      <S.Header>
        <S.Title>
          {iconStart && iconStart} {title}
        </S.Title>
        <S.RightHeader>
          <S.CloseButton
            startIcon={
              <Icon name="close" color={theme.palette.brand.primary.natural} />
            }
            onClick={handleClose}
            variant="inherit"
            style={{ backgroundColor: theme.palette.system.transparent }}
          />
        </S.RightHeader>
      </S.Header>
      <S.Main>{children}</S.Main>
      <S.Footer>
        <S.RightFooter>
          <Button
            size="small"
            variant="contained"
            style={{
              backgroundColor: theme.palette.semantics.feedback.unknown.light,
              color: theme.palette.info.main,
            }}
            onClick={handleClose}
          >
            {buttonCancelTitle}
          </Button>
          <Button
            disabled={disabled}
            onClick={onClick}
            size="small"
            type="submit"
            color="primary"
            variant="contained"
            style={{
              color: theme.palette.words.button.contrast,
              marginLeft: '10px',
            }}
          >
            {buttonConfirmTitle}
          </Button>
        </S.RightFooter>
      </S.Footer>
      <div style={{ left: 0, top: 0 }} />
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      {disabled && <Loading />}
    </div>
  );
};

export default SaveModal;
