export const columnsSmall = [
  {
    Header: 'Nome',
    id: 'nome',
    identifier: 'nome',
    accessor: d => String(d.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Tipo',
    id: 'tipo',
    identifier: 'tipo',
    accessor: d => Number(d.tipo),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Filial',
    id: 'filial',
    identifier: 'filial',
    accessor: d => Number(d.filial),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Nota',
    id: 'nota',
    identifier: 'nota',
    accessor: d => Number(d.nota),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Tentativas',
    id: 'tentativas',
    identifier: 'tentativas',
    accessor: d => Number(d.tentativas),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
];
