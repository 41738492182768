import api from 'services/api';

export const getList = async data => {
  const res = await api.get('/telematics_dashboard/ranking', { params: data });
  return res.data;
};

export const requestExcel = async query => {
  const res = await api.get('/excel-provider/telematics_ranking', {
    params: query,
  });
  return res;
};
