import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  max-width: 75%;
  max-height: 90%;
  background: white;
  padding: 30px;
  border-radius: 5px;
  overflow-y: auto;

  .filter-container {
    position: absolute;
    transform: translate(310px, 35px);
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
  }
  .table-container {
    display: flex;
    max-height: 30%;
    overflow: hidden;
  }

  .leftHeader {
    display: flex;
    align-items: center;
    justify-content: start;

    h1 {
      margin-left: 10px;
    }
  }
`;

export const Paragraph = styled.p`
  font-size: ${props => (props.fontsize ? props.fontsize : '16px')};
  color: ${props => (props.color ? props.color : colors.grey)};
  font-weight: ${props => (props.fontweight ? props.fontweight : 400)};
  min-width: ${props => props.minwidth};

  &.status {
    font-weight: bold;
    color: ${props => props.color};
    background-color: ${props => props.bg};
    text-align: center;
    border-radius: 100px;
  }
`;

export const ColDriver = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  text-transform: capitalize;

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.greyTitle};
  }

  .container-img-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  img {
    width: 41px;
    height: 41px;
    border: 1px solid ${colors.greyBorder};
    border-radius: 100px;
  }
`;
