// Styled

// React
import { useEffect, useState } from 'react';

// Components
import TextInput from 'components/Inputs/TextField';

// Components MUI
import { Divider } from '@mui/material';
import { WithQuestion } from '..';
import * as S from './styled';

// Actions
import { changeText } from '../../actions';

export const ResponseTexto = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosObrigatorios,
  preview,
  approveView,
}) => {
  const redirectFunction = (executable, idCampo, texto) => {
    setCleanField(true);
    executable(texto, idCampo, setSecoes, secoes, sectionId);
  };

  const [tam, setTam] = useState(0);
  useEffect(() => {
    if (field.id_tipo_campo === 5) {
      setTam(155);
    } else if (field.id_tipo_campo === 6) {
      setTam(1000);
    }
  }, [field]);

  // ------------------------------------ERROS-------------------------------------------//
  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [cleanField, setCleanField] = useState(false);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setCleanField(false);

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }
  }, [errosObrigatorios]);

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanField}
        className={`fieldClass-${field.id}`}
      >
        <WithQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanField}
          preview={preview}
          approveView={approveView}
        />
        <S.ResponseArea>
          <TextInput
            noAsterisk
            value={field.resposta}
            onChange={e =>
              redirectFunction(
                changeText,
                field.id,
                e.target.value.slice(0, tam),
              )
            }
            error={hasErrorObrigatorio && !cleanField}
            disabled={approveView}
          />
          <div className="counter">{`${
            field.resposta?.length || 0
          }/${tam}`}</div>
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
