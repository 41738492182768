/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useMemo, useState } from 'react';

// MUI
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

// Actions
import { placement, selectionLabel } from './actions';

// Assets
import empty from '../../../../assets/empty.png';

export const Select = ({
  mode = 'single',
  data = [],
  placeholder = 'Selecione uma opção...',
  value,
  setValue,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [search, setSearch] = useState('');

  // ----------------------------HANDLERS----------------------------//
  const handleClose = () => setAnchorEl(null);

  const handleClick = e => setAnchorEl(e.currentTarget);

  const handleSelect = val => {
    if (mode === 'single') {
      if (value === val) {
        setValue(null);
        handleClose();
      } else {
        setValue(val);
        handleClose();
      }
    } else if (mode === 'multiple') {
      if (value.includes(val)) {
        setValue(value.filter(v => v !== val));
      } else {
        setValue([...value, val]);
      }
    }
  };

  const handleAction = () => {
    if (mode === 'single') {
      setValue(null);
    } else if (mode === 'multiple') {
      if (value?.length) {
        setValue([]);
      } else {
        setValue(data.map(item => item.value));
      }
    }
  };

  // ----------------------------DATA----------------------------//
  const selectable = useMemo(() => {
    if (!search) return data;
    const searchItems = data.filter(item =>
      item.label.toLowerCase().includes(search.toLowerCase()),
    );
    return searchItems;
  }, [search]);

  // ----------------------------RENDER----------------------------//
  return (
    <div>
      <S.Container
        id="basic-button"
        data-testid="select"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        open={open}
      >
        <S.Placeholder
          isSelected={
            (mode === 'multiple' && !!value?.length) ||
            (mode === 'single' && (!!value || value === 0))
          }
        >
          {placement(value, mode, data, placeholder)}
        </S.Placeholder>
        <ExpandMoreRoundedIcon className="icon" />
      </S.Container>
      <S.MenuBox
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <S.SearchBox>
          <S.SearchInput
            data-testid="search"
            placeholder="Buscar..."
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <SearchRoundedIcon className="icon" />
        </S.SearchBox>
        <S.LineAction onClick={handleAction} isEmpty={!selectable.length}>
          {selectionLabel(mode, value)}
        </S.LineAction>
        <S.ScrollOptions>
          {!!selectable.length &&
            selectable.map((item, idx) => (
              <S.Option
                data-testid="option"
                aria-hidden={!open}
                onClick={() => handleSelect(item.value)}
                key={idx}
                checked={mode === 'single' && value === item?.value}
              >
                {mode === 'multiple' && (
                  <S.Check
                    data-testid="check"
                    checked={
                      (mode === 'multiple' && value?.includes(item?.value)) ||
                      (mode === 'single' && value === item?.value)
                    }
                  />
                )}
                {item.label}
              </S.Option>
            ))}

          {!selectable.length && (
            <S.Empty data-testid="empty">
              <S.EmptyImage src={empty} />
              <S.EmptyText>Nenhum resultado encontrado</S.EmptyText>
            </S.Empty>
          )}
        </S.ScrollOptions>
      </S.MenuBox>
    </div>
  );
};
