import styled, { css } from 'styled-components';

export const Section = styled.div`
  ${({ theme }) => css`
    margin-top: 40px;
    width: 100%;

    .slider {
      background-color: ${theme.palette.system.white};
      border: 2px solid ${theme.palette.system.divider};
      box-shadow: ${theme.palette.system.shadow};
      border-radius: 5px;
      margin-top: 10px;
    }
  `}
`;

export const SectionHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin-left: 10px;
      color: ${theme.palette.brand.primary.dark};
      position: relative;
      min-width: 250px;
    }

    .infos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .arrows {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90px;

        .box {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: ${theme.palette.system.white};
          box-shadow: 0px 0px 10px ${theme.palette.system.shadow};
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }

        .MuiSvgIcon-root {
          color: ${theme.palette.brand.primary.dark};
          cursor: pointer;
          font-size: 19px;
        }
      }
    }

    .pointer {
      margin-right: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9px;

      button {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #bbb;
        border: none;
        cursor: pointer;

        &.active {
          background-color: #000;
        }
      }
    }
  `}
`;

export const Loading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  opacity: ${props => (props.isLoading ? 1 : 0)};
  z-index: ${props => (props.isLoading ? 2 : -1)};
  background-image: linear-gradient(
    to right,
    #e2e2e8 0%,
    #edeef1 20%,
    #e2e2e8 40%,
    #e2e2e8 100%
  );
  background-size: 80rem 14rem;
  animation: placeholderShimmer 3s linear infinite forwards;

  @keyframes placeholderShimmer {
    0% {
      background-position: -40rem 0;
    }
    100% {
      background-position: 40rem 0;
    }
  }
`;
