import {
  getExecutores,
  getAprovadores,
  getBases,
  getTamanhos,
  getStates,
  getClients,
  getTypeClientIp,
  getEmpresasLayout,
} from 'constants/_SERVICES/user';

// Pages
import LayoutDescarga from 'pages/LayoutDescarga';
import ConfigLayout from 'pages/LayoutDescarga/Config';
import CaeDetail from 'pages/LayoutDescarga/Cae/Detalhe';
import LayoutPdf from 'pages/LayoutDescarga/Config/ModelPdf';

export default {
  '/layout/criar': {
    back: '/layout',
    title: 'Criar Layout',
    status: true,
    defaults: [],
    page: <ConfigLayout />,
  },

  '/layout': {
    header: {},
    pageFilterName: 'filterLayout',
    defaults: [],
    filters: [
      {
        name: 'client',
        placeholder: 'Filtrar por Cliente',
        mode: 'multiple',
        data: async () => getClients(),
      },
      {
        name: 'tipo_cliente',
        placeholder: 'Filtrar por Tipo de Cliente',
        mode: 'single',
        data: async () => getTypeClientIp(),
        dependency: [
          {
            type: 'user',
            name: 'client',
            clear: true,
            visible: true,
            maxSelect: 1,
            value: 4,
          },
        ],
      },
      {
        name: 'empresas',
        placeholder: 'Filtrar por Empresa',
        mode: 'multiple',
        data: async () => getEmpresasLayout(),
      },
      {
        name: 'estado',
        placeholder: 'Filtrar por Estado',
        mode: 'single',
        section: 'Layout',
        data: async () => getStates(),
      },
      {
        name: 'executor',
        placeholder: 'Filtrar por Executor',
        mode: 'multiple',
        section: 'Layout',
        data: async () => getExecutores(),
      },
      {
        name: 'aprovador',
        placeholder: 'Filtrar por Aprovador',
        mode: 'multiple',
        section: 'Layout',
        data: async () => getAprovadores(),
      },
      {
        name: 'base',
        placeholder: 'Filtrar por Base',
        mode: 'multiple',
        section: 'Layout',
        data: async () => getBases(),
      },
      {
        name: 'tmc',
        placeholder: 'Filtrar por Tam Max',
        mode: 'single',
        section: 'Layout',
        data: async () => getTamanhos(),
      },
    ],
    page: <LayoutDescarga />,
  },

  '/layout/:id': {
    back: -1,
    title: 'Layout',
    id: true,
    status: true,
    defaults: [],
    page: <ConfigLayout />,
  },

  '/layout/pdf/:id': {
    title: 'Impressão Layout',
    id: true,
    defaults: [],
    page: <LayoutPdf />,
  },

  '/cae/:id': {
    back: -1,
    title: 'Cae',
    id: true,
    defaults: [],
    page: <CaeDetail />,
  },
};
