// Styles
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';

// Components
import Loading from 'components/Loading';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { CircularProgress, Divider } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import * as S from './styled';

// Utils
import {
  deleteActivitieTurma,
  deleteTurma,
  getTurmaActivities,
} from '../services';

export const ModalListTasks = ({
  open,
  onClose,
  conteudos,
  idDisabled,
  fetchTurma,
  setNeedRefetch,
}) => {
  // Empty Image
  const img = require(`images/empty/motorista.png`);

  // Modal Controler
  const [itemSelected, setItemSelected] = useState(null);

  // States Page
  const [content, setContent] = useState(conteudos);
  const [loading, setLoading] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);

  // Cotrole de requisição para atualizações fora da página
  const { data, isError, isLoading, isFetching } = useQuery(
    ['list-activities-turma', 1],
    () => getTurmaActivities(idDisabled),
    {
      refetchOnWindowFocus: true,
    },
  );

  useEffect(() => {
    setLoading(true);
  }, [idDisabled]);

  useEffect(() => {
    if (isLoading || isError || isFetching) {
      return null;
    }
    if (data.data.conteudos.length !== content.length && !firstCharge) {
      setNeedRefetch(true);
    }

    setContent(data.data.conteudos);
    setFirstCharge(false);
    setLoading(false);
  }, [data]);

  // ----------------------------ACTIONS------------------------------//
  const openNewTab = (id, route) => {
    const newTab = window.open(`${route}/${id}`, id);
    return newTab;
  };

  const deleteRelation = async (idAct, type) => {
    const res = await deleteActivitieTurma({
      type,
      idAct,
    });

    if (res.data.success) {
      toast.success(res.data.message);
      if (type === 'content') {
        const filterContent = content.filter(item => item.id !== idAct);
        setContent([...filterContent]);
        setNeedRefetch(true);
      }
    } else {
      toast.error(res.data.message);
    }
  };

  const constDeleteEmpty = async () => {
    setLoading(true);
    const res = await deleteTurma(idDisabled);
    if (res.data.success) {
      toast.success(res.data.message);
      Promise.all([setLoading(false), onClose()]).then(() => {
        fetchTurma();
      });
    } else {
      toast.error(res.data.message);
      setLoading(false);
    }
  };

  // --------------------------RETURN--------------------------------//
  return (
    <S.ModalMUI open={open} onClose={onClose}>
      <S.Content>
        {!isLoading && !loading && (
          <>
            <S.Header>
              <div className="left">
                <BallotOutlinedIcon htmlColor={colors.orangeAlert} />
                <span>Itens ativos vinculados</span>
              </div>
              <div className="rigth">
                <CloseOutlinedIcon
                  htmlColor={colors.greySubtitle}
                  style={{ cursor: 'pointer' }}
                  onClick={onClose}
                />
              </div>
            </S.Header>

            <Divider />

            <S.Warning>
              <div className="warningBox">
                <WarningAmberOutlinedIcon htmlColor={colors.orangeAlert} />
                <span>
                  É necessário excluir ou desativar todos os itens vinculados à
                  essa turma antes de desativá-la
                </span>
              </div>
            </S.Warning>

            <S.Body
              onMouseLeave={() => setItemSelected(null)}
              isLoading={loading}
              hasTwo={content.length > 0}
            >
              {content.length > 0 && (
                <div className="boxSession">
                  <p>Conteúdos</p>
                  <div className="grade">
                    {content?.map((item, key) => (
                      <S.ListItem
                        isSelected={itemSelected === item.id}
                        key={key}
                      >
                        <div className="visisbleArea">
                          <div className="boxInformationEl">
                            <FormatListBulletedOutlinedIcon
                              htmlColor={
                                itemSelected === item.id
                                  ? colors.redDanger
                                  : colors.grey
                              }
                            />
                            <div className="titleEl">
                              {item.conteudo.titulo}
                            </div>

                            <div className="excludeText">
                              Tem certeza que deseja remover essa turma do
                              conteúdo?
                            </div>
                          </div>
                          <div className="boxActionElement">
                            <button
                              className="view"
                              onClick={() =>
                                window.open(
                                  `/capacitacao/editar/${item.id_conteudo}`,
                                )
                              }
                            >
                              Visualizar
                            </button>
                            <button
                              className="remove"
                              onClick={() => setItemSelected(item.id)}
                            >
                              Remover
                            </button>
                          </div>
                        </div>
                        <div className="invisibleArea">
                          <div className="buttonArea">
                            {!loading && (
                              <>
                                <button
                                  onClick={() =>
                                    deleteRelation(item.id, 'content')
                                  }
                                >
                                  <CheckOutlinedIcon htmlColor="#fff" />
                                </button>
                                <button onClick={() => setItemSelected(null)}>
                                  <CloseOutlinedIcon htmlColor="#fff" />
                                </button>
                              </>
                            )}
                            {loading && (
                              <CircularProgress
                                size={24}
                                sx={{ color: '#FFF' }}
                              />
                            )}
                          </div>
                        </div>
                      </S.ListItem>
                    ))}
                  </div>
                </div>
              )}

              {content && content.length <= 0 && (
                <div className="emptyBox">
                  <div className="left">
                    <img src={img} />
                  </div>
                  <div className="rigth">
                    <p>Nenhuma atividade relacionada!</p>
                    <span>A turma agora pode ser desativada</span>
                    <div className="buttonArea">
                      <DefaultButton
                        children="Desativar Turma"
                        style={{
                          backgroundColor: colors.redDanger,
                        }}
                        onClick={() => constDeleteEmpty()}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              )}
            </S.Body>
          </>
        )}
        {(isLoading && content.length <= 0) ||
          (loading && (
            <div>
              <Loading />
            </div>
          ))}
      </S.Content>
    </S.ModalMUI>
  );
};
