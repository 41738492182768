import api from 'services/api';

export const getHistoricoTratativas = async query => {
  const res = await api.get('/equipe-historico-tempo-medio-tipo', {
    params: query,
  });
  return res.data;
};

export const getEquipe = async query => {
  const res = await api.get('/equipe-estatisticas/card-equipe', {
    params: query,
  });
  return res.data;
};

export const getJustified = async query => {
  const res = await api.get('/equipe-estatisticas/justificativa-exclusao', {
    params: query,
  });
  return res.data;
};

export const getMovimentacoes = async query => {
  const res = await api.get('/equipe-estatisticas/revisoes-exclusao', {
    params: query,
  });
  return res.data;
};

export const getDesviosTratados = async query => {
  const res = await api.get('/equipe-desvios-tratados-prazo', {
    params: query,
  });
  return res.data;
};
