import useColor from 'hooks/useColor';
import useBackgroundColor from 'hooks/useBackgroundColor';
import { formatNewHour } from 'utils/dates';
import * as S from './styled';

export const columns = [
  // Indice 0 = Colunas para avaliações
  [
    {
      Header: 'ID',
      id: 'id',
      accessor: a => (a.id ? a.id : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => <p>{row.original.id}</p>,
    },
    {
      Header: 'Categoria',
      id: 'categoria',
      accessor: a => (a.tipo ? a.tipo : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Col>
          <h1>{row.original.tipo}</h1>
        </S.Col>
      ),
    },
    {
      Header: 'Semana',
      id: 'semana',
      accessor: a => (a.semana ? a.semana : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => <p>{row.original.semana}</p>,
    },
    {
      Header: 'Data',
      id: 'data',
      accessor: a => (a.data_avaliacao ? a.data_avaliacao : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <p>
          {row.original.data_avaliacao &&
            formatNewHour(row.original.data_avaliacao)}
        </p>
      ),
    },
    {
      Header: 'Pontuação',
      id: 'pontuacao',
      accessor: a => (a.pontuacao ? a.pontuacao : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => <S.Status>{row.original.pontuacao}</S.Status>,
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: a => (a.status ? String(a.status.toLowerCase()) : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Status
          color={useColor(row.original.status && row.original.status)}
          background={useBackgroundColor(
            row.original.status && row.original.status,
          )}
        >
          <p>{row.original.status && row.original.status.toLowerCase()}</p>
        </S.Status>
      ),
    },
    {
      Header: 'Ações',
      id: 'acoes',
      identifier: 'acoes',
      accessor: 'acoes',
      show: true,
      disableSortBy: true,
    },
  ],
  // Indice 1 = Colunas para Desvios
  [
    {
      Header: 'ID',
      id: 'id',
      accessor: d => (d.id ? String(d.id) : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Col>
          <p>{row.original.id}</p>
        </S.Col>
      ),
    },
    {
      Header: 'Semana',
      id: 'semana',
      accessor: d => (d.avaliacao.semana ? String(d.avaliacao.semana) : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <p>{row.original.avaliacao.semana && row.original.avaliacao.semana}</p>
      ),
    },
    {
      Header: 'Data',
      id: 'data',
      accessor: d => (d.data_desvio ? String(d.data_desvio) : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <p>
          {row.original.data_desvio
            ? formatNewHour(row.original.data_desvio)
            : ''}
        </p>
      ),
    },
    {
      Header: 'Desvio',
      id: 'desvio',
      accessor: d => (d.tipo ? String(d.tipo.descricao) : '-'),
      sortType: 'basic',
      Cell: ({ row }) => (
        <p>{row.original.tipo.descricao && row.original.tipo.descricao}</p>
      ),
    },
    {
      Header: 'Criticidade',
      id: 'criticidade',
      accessor: d => (d.tipo ? String(d.tipo.criticidade) : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Status
          color={useColor(
            row.original.tipo.criticidade && row.original.tipo.criticidade,
          )}
          background={useBackgroundColor(
            row.original.tipo.criticidade && row.original.tipo.criticidade,
          )}
        >
          {row.original.tipo.criticidade}
        </S.Status>
      ),
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: d => (d.status ? String(d.status.toLowerCase()) : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Status
          color={useColor(row.original.status && row.original.status)}
          background={useBackgroundColor(
            row.original.status && row.original.status,
          )}
        >
          <p>{row.original.status && row.original.status.toLowerCase()}</p>
        </S.Status>
      ),
    },
    {
      Header: 'Ações',
      id: 'acoes',
      identifier: 'acoes',
      accessor: 'acoes',
      show: true,
      disableSortBy: true,
    },
  ],
  // Indice 2 = Colunas para avaliações descarga
  [
    {
      Header: 'ID',
      id: 'id',
      accessor: a => (a.id ? a.id : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => <p>{row.original.id}</p>,
    },
    {
      Header: 'Categoria',
      id: 'categoria',
      accessor: a => (a.tipo ? a.tipo : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Col>
          <h1>{row.original.tipo ? row.original.tipo : 'Descarga'}</h1>
        </S.Col>
      ),
    },
    {
      Header: 'Semana',
      id: 'semana',
      accessor: a => (a.semana ? a.semana : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => <p>{row.original.semana}</p>,
    },
    {
      Header: 'Data',
      id: 'data',
      accessor: a => (a.data_avaliacao ? a.data_avaliacao : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <p>
          {row.original.data_avaliacao &&
            formatNewHour(row.original.data_avaliacao)}
        </p>
      ),
    },
    {
      Header: 'Pontuação',
      id: 'pontuacao',
      accessor: a => (a.pontuacao ? a.pontuacao : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => <S.Status>{row.original.pontuacao}</S.Status>,
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: a => (a.status ? String(a.status.toLowerCase()) : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Status
          color={useColor(row.original.status && row.original.status)}
          background={useBackgroundColor(
            row.original.status && row.original.status,
          )}
        >
          <p>{row.original.status && row.original.status.toLowerCase()}</p>
        </S.Status>
      ),
    },
    {
      Header: 'Ações',
      id: 'acoes',
      identifier: 'acoes',
      accessor: 'acoes',
      show: true,
      disableSortBy: true,
    },
  ],
  // Indice 3 = Colunas para desvios descarga
  [
    {
      Header: 'ID',
      id: 'id',
      accessor: d => (d.id ? String(d.id) : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Col>
          <p>{row.original.id}</p>
        </S.Col>
      ),
    },
    // {//TODO: Arrumar qual o accessor e row.tipo
    //     Header: 'Categoria',
    //     id: 'categoria',
    //     sortType: 'basic',
    //     show: true,
    //     Cell: ({row}) => (
    //         <S.Col>
    //             <p>{row.original.categoria ? row.original.categoria : ''}</p>
    //         </S.Col>
    //     )
    // },
    {
      Header: 'Semana',
      id: 'semana',
      accessor: d => (d.avaliacao.semana ? String(d.avaliacao.semana) : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <p>{row.original.avaliacao.semana && row.original.avaliacao.semana}</p>
      ),
    },
    {
      Header: 'Data',
      id: 'data',
      accessor: d => (d.data_desvio ? String(d.data_desvio) : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <p>
          {row.original.data_desvio
            ? formatNewHour(row.original.data_desvio)
            : ''}
        </p>
      ),
    },
    {
      Header: 'Desvio',
      id: 'desvio',
      accessor: d => (d.tipo ? String(d.tipo.descricao) : '-'),
      sortType: 'basic',
      Cell: ({ row }) => (
        <p>{row.original.tipo.descricao && row.original.tipo.descricao}</p>
      ),
    },
    {
      Header: 'Criticidade',
      id: 'criticidade',
      accessor: d => (d.tipo ? String(d.tipo.criticidade) : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Status
          color={useColor(
            row.original.tipo.criticidade && row.original.tipo.criticidade,
          )}
          background={useBackgroundColor(
            row.original.tipo.criticidade && row.original.tipo.criticidade,
          )}
        >
          {row.original.tipo.criticidade}
        </S.Status>
      ),
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: d => (d.status ? String(d.status.toLowerCase()) : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Status
          color={useColor(row.original.status && row.original.status)}
          background={useBackgroundColor(
            row.original.status && row.original.status,
          )}
        >
          <p>{row.original.status && row.original.status.toLowerCase()}</p>
        </S.Status>
      ),
    },
    {
      Header: 'Ações',
      id: 'acoes',
      identifier: 'acoes',
      accessor: 'acoes',
      show: true,
      disableSortBy: true,
    },
  ],
  // Indice 4 = Colunas para Formulários
  [
    {
      Header: 'ID',
      id: 'id',
      accessor: c => (c.id_hist ? c.id_hist : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => <p>{row.original.id_hist}</p>,
    },
    {
      Header: 'Data',
      id: 'data',
      accessor: c => c.createdAt && c.createdAt,
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <p>{row.original.createdAt && formatNewHour(row.original.createdAt)}</p>
      ),
    },
    {
      Header: 'Título',
      id: 'titulo',
      accessor: c => (c.formulario.titulo ? c.formulario.titulo : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Col>
          <h1>
            {row.original.formulario.titulo
              ? row.original.formulario.titulo
              : '-'}
          </h1>
        </S.Col>
      ),
    },
    {
      Header: 'Aprovador',
      id: 'aprovador',
      accessor: c =>
        c.formulario.responsavel_aprovador
          ? c.formulario.responsavel_aprovador.nome
          : '-',
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Col>
          <h1>
            {row.original.formulario.responsavel_aprovador
              ? row.original.formulario.responsavel_aprovador.nome
              : '-'}
          </h1>
        </S.Col>
      ),
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: c => (c.usuario_assinatura ? c.usuario_assinatura : '-'),
      sortType: 'basic',
      show: true,
      Cell: ({ row }) => (
        <S.Status
          color={useColor(row.original.status)}
          background={useBackgroundColor(row.original.status)}
        >
          <p>{row.original.status}</p>
        </S.Status>
      ),
    },
    {
      Header: 'Ações',
      id: 'acoes',
      identifier: 'acoes',
      accessor: 'acoes',
      show: true,
      disableSortBy: true,
    },
  ],
];

export const avaliacoesFields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'motorista.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'distribuidora.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Veículo',
    value: 'caminhao.placa',
    selected: true,
    default: true,
  },
  {
    label: 'Frota',
    value: 'caminhao.frota',
    selected: false,
    default: false,
  },
  {
    label: 'Responsável',
    value: 'avaliador.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Itens avaliados',
    value: 'n_de_itens_avaliados',
    selected: false,
    default: false,
  },
  {
    label: 'Itens atendidos',
    value: 'itens_atendidos',
    selected: false,
    default: false,
  },
  {
    label: 'Atendimento',
    value: 'percentual_de_atendimento',
    selected: false,
    default: false,
  },
  {
    label: 'Pontuação',
    value: 'pontuacao',
    selected: true,
    default: true,
  },
  {
    label: 'Tempo de avaliação',
    value: 'horas',
    selected: true,
    default: true,
  },
  {
    label: 'Previsão de conclusão',
    value: 'data_feedback',
    selected: true,
    default: true,
  },
  {
    label: 'Data da viagem',
    value: 'data_avaliacao',
    selected: true,
    default: true,
  },
  {
    label: 'Hora início',
    value: 'hora_inicial',
    selected: true,
    default: true,
  },
  {
    label: 'Hora fim',
    value: 'hora_final',
    selected: true,
    default: true,
  },
  {
    label: 'Data preenchimento',
    value: 'data_finalizacao',
    selected: true,
    default: true,
  },
  {
    label: 'Data processamento',
    value: 'data_processamento',
    selected: false,
    default: false,
  },
  {
    label: 'Disponibilizado Em',
    value: 'data_pendente',
    selected: true,
    default: true,
  },
  {
    label: 'Placa reboque',
    value: 'placa_reboque',
    selected: false,
    default: false,
  },
  {
    label: 'Local',
    value: 'local',
    selected: false,
    default: false,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Justificativa indisponibilidade',
    value: 'log',
    selected: true,
    default: true,
  },
  {
    label: 'Data assinatura',
    value: 'assinada_em',
    selected: true,
    default: true,
  },
  {
    label: 'Observações',
    value: 'observacoes',
    selected: false,
    default: false,
  },
  {
    label: 'Semana',
    value: 'semana',
    selected: true,
    default: true,
  },
  {
    label: 'Justificativa arquivamento',
    value: 'justificativa',
    selected: false,
    default: false,
  },
  {
    label: 'Tipo',
    value: 'tipo',
    selected: false,
    default: false,
  },
  {
    label: 'Contestação',
    value: 'contestacao',
    selected: false,
    default: false,
  },
  {
    label: 'Testemunha',
    value: 'testemunha.nome',
    selected: false,
    default: false,
  },
  {
    label: 'Data assinatura testemunha',
    value: 'assinatura_testemunha_em',
    selected: false,
    default: false,
  },
  {
    label: 'Status revisão cliente',
    value: 'revisao_da_operacao',
    selected: false,
    default: false,
  },
  {
    label: 'Data revisão cliente',
    value: 'data_revisao',
    selected: false,
    default: false,
  },
  {
    label: 'Data avaliação cliente',
    value: 'data_revisao_distribuidora',
    selected: false,
    default: false,
  },
  {
    label: 'Observação revisão cliente',
    value: 'motivo_revisao',
    selected: false,
    default: false,
  },
  {
    label: 'Data video',
    value: 'data_video_original',
    selected: true,
    default: true,
  },
];

export const desviosFields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo do desvio',
    value: 'tipo.descricao',
    selected: true,
    default: true,
  },
  {
    label: 'Criticidade',
    value: 'tipo.criticidade',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'tipo.peso',
    selected: true,
    default: true,
  },
  {
    label: 'Objeto',
    value: 'objeto',
    selected: true,
    default: true,
  },
  {
    label: 'Avaliação',
    value: 'id_da_avaliacao',
    selected: false,
    default: false,
  },
  {
    label: 'Data do desvio',
    value: 'data_desvio',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'motorista.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'motorista.filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Veículo',
    value: 'caminhao.placa',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'distribuidora.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Link da Imagem',
    value: 'img_ocorrencia',
    selected: false,
    default: false,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Data aprovação SSMA',
    value: 'data_pendente',
    selected: true,
    default: true,
  },
  {
    label: 'Previsão de conclusão',
    value: 'data_previsao',
    selected: false,
    default: false,
  },
  {
    label: 'Data conclusão',
    value: 'data_encerramento',
    selected: true,
    default: true,
  },
  {
    label: 'Plano de ação',
    value: 'plano_de_acao',
    selected: true,
    default: true,
  },
  {
    label: 'Observação',
    value: 'observacao',
    selected: false,
    default: false,
  },
  {
    label: 'Evidência',
    value: 'arquivo_justificativa',
    selected: false,
    default: false,
  },
  {
    label: 'Responsável',
    value: 'responsavel.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Criador',
    value: 'criador.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Disponibilizado Em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
  {
    label: 'Justificativa exclusão',
    value: 'motivo_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Observação exclusão',
    value: 'observacao_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Data exclusão',
    value: 'data_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Data feedback distribuidora',
    value: 'data_alteracao_distribuidora',
    selected: false,
    default: false,
  },
  {
    label: 'Status revisão distribuidora',
    value: 'aprovado_distribuidora',
    selected: true,
    default: true,
  },
  {
    label: 'Observações distribuidora',
    value: 'observacoes_distribuidora',
    selected: false,
    default: false,
  },
  {
    label: 'Contestação motorista',
    value: 'comentario_contestacao',
    selected: false,
    default: false,
  },
];

export const avaliacoesDescargaFields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'motorista.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'distribuidora.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Veículo',
    value: 'caminhao.placa',
    selected: true,
    default: true,
  },
  {
    label: 'Frota',
    value: 'caminhao.frota',
    selected: true,
    default: true,
  },
  {
    label: 'Responsável',
    value: 'avaliador.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Itens avaliados',
    value: 'n_de_itens_avaliados',
    selected: true,
    default: true,
  },
  {
    label: 'Itens atendidos',
    value: 'itens_atendidos',
    selected: true,
    default: true,
  },
  {
    label: 'Atendimento',
    value: 'percentual_de_atendimento',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'pontuacao',
    selected: true,
    default: true,
  },
  {
    label: 'Tempo de avaliação',
    value: 'horas',
    selected: true,
    default: true,
  },
  {
    label: 'Previsão de conclusão',
    value: 'data_feedback',
    selected: true,
    default: true,
  },
  {
    label: 'Data da viagem',
    value: 'data_avaliacao',
    selected: true,
    default: true,
  },
  {
    label: 'Hora início',
    value: 'hora_inicial',
    selected: true,
    default: true,
  },
  {
    label: 'Hora fim',
    value: 'hora_final',
    selected: true,
    default: true,
  },
  {
    label: 'Data preenchimento',
    value: 'data_finalizacao',
    selected: true,
    default: true,
  },
  {
    label: 'Data processamento',
    value: 'data_processamento',
    selected: true,
    default: true,
  },
  {
    label: 'Disponibilizado Em',
    value: 'data_pendente',
    selected: true,
    default: true,
  },
  {
    label: 'Placa reboque',
    value: 'placa_reboque',
    selected: true,
    default: true,
  },
  {
    label: 'Local',
    value: 'local',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Justificativa indisponibilidade',
    value: 'log',
    selected: true,
    default: true,
  },
  {
    label: 'Data assinatura',
    value: 'assinada_em',
    selected: true,
    default: true,
  },
  {
    label: 'Observações',
    value: 'observacoes',
    selected: true,
    default: true,
  },
  {
    label: 'Semana',
    value: 'semana',
    selected: true,
    default: true,
  },
  {
    label: 'Justificativa arquivamento',
    value: 'justificativa',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo',
    value: 'tipo',
    selected: true,
    default: true,
  },
  {
    label: 'Contestação',
    value: 'contestacao',
    selected: true,
    default: true,
  },
  {
    label: 'Testemunha',
    value: 'testemunha.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Data assinatura testemunha',
    value: 'assinatura_testemunha_em',
    selected: true,
    default: true,
  },
  {
    label: 'Status revisão cliente',
    value: 'revisao_da_operacao',
    selected: true,
    default: true,
  },
  {
    label: 'Data revisão cliente',
    value: 'data_revisao',
    selected: true,
    default: true,
  },
  {
    label: 'Data avaliação cliente',
    value: 'data_revisao_distribuidora',
    selected: true,
    default: true,
  },
  {
    label: 'Observação revisão cliente',
    value: 'motivo_revisao',
    selected: true,
    default: true,
  },
  {
    label: 'Data video',
    value: 'data_video_original',
    selected: true,
    default: true,
  },
];

export const desviosDescargaFields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo do desvio',
    value: 'tipo.descricao',
    selected: true,
    default: true,
  },
  {
    label: 'Criticidade',
    value: 'tipo.criticidade',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'tipo.peso',
    selected: true,
    default: true,
  },
  {
    label: 'Objeto',
    value: 'objeto',
    selected: true,
    default: true,
  },
  {
    label: 'Avaliação',
    value: 'id_da_avaliacao',
    selected: false,
    default: false,
  },
  {
    label: 'Data do desvio',
    value: 'data_desvio',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'motorista.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'motorista.filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Veículo',
    value: 'caminhao.placa',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'distribuidora.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Link da Imagem',
    value: 'img_ocorrencia',
    selected: false,
    default: false,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Data aprovação SSMA',
    value: 'data_pendente',
    selected: true,
    default: true,
  },
  {
    label: 'Previsão de conclusão',
    value: 'data_previsao',
    selected: false,
    default: false,
  },
  {
    label: 'Data conclusão',
    value: 'data_encerramento',
    selected: true,
    default: true,
  },
  {
    label: 'Data exclusão',
    value: 'data_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Plano de ação',
    value: 'plano_de_acao',
    selected: true,
    default: true,
  },
  {
    label: 'Observação',
    value: 'observacao',
    selected: false,
    default: false,
  },
  {
    label: 'Evidência',
    value: 'arquivo_justificativa',
    selected: false,
    default: false,
  },
  {
    label: 'Responsável',
    value: 'responsavel.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Criador',
    value: 'criador.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Disponibilizado Em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
  {
    label: 'Justificativa exclusão',
    value: 'motivo_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Contestação motorista',
    value: 'comentario_contestacao',
    selected: false,
    default: false,
  },
];

export const padFields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Violação',
    value: 'violacao.descricao',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'motorista.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'motorista.filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Responsável',
    value: 'responsavel.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Data ocorrência',
    value: 'data_ocorrencia',
    selected: true,
    default: true,
  },
  {
    label: 'Data feedback',
    value: 'data_feedback',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação ativa',
    value: 'pontuacao',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação inicial',
    value: 'violacao.pontos',
    selected: true,
    default: true,
  },
];

export const formFields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Data',
    value: 'data',
    selected: true,
    default: true,
  },
  {
    label: 'Titulo formulário',
    value: 'titulo_formulario',
    selected: true,
    default: true,
  },
  {
    label: 'Aprovador',
    value: 'aprovador',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
];

export const resetFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};
