export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Nome',
    id: 'nome',
    picture: 'foto',
    type: 'string',
    sort: true,
  },
  {
    header: 'Filial',
    id: 'filial.nome',
    type: 'string',
    sort: true,
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    conditional: [
      {
        condition: value => value === 'ATIVO',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'INATIVO',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Turmas',
    id: 'turmas',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Atividades',
    id: 'conteudos',
    type: 'number',
    sort: true,
    align: 'center',
  },
];
