import React, { useEffect, useRef, forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { useTheme } from 'styled-components';
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useRowSelect,
} from 'react-table';

/*

  REVERT | Merged PR 419: PBI-1028: tabela persiste configurações

  import { useDispatch, useSelector } from 'react-redux';
  import { useLocation } from 'react-router-dom';
  import { setTableParams } from 'store/modules/tables/actions';
  import { useDebounce } from 'use-debounce';

*/
// Components
import EmptyDataCard from 'components/Cards/EmptyDataCard';
import Select from 'components/Inputs/Select';
import Input from 'components/Inputs/TextField';
import Icon from 'components/Icons';
import { ReactComponent as searchIcon } from 'images/icons/inputs/Search.svg';
import Loading from 'components/Loading';

// Material
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import { MoreHoriz } from '@mui/icons-material';
import {
  IconButton,
  Popover,
  MenuItem,
  InputAdornment,
  SvgIcon,
  Stack,
} from '@mui/material';
import { trackEvent } from 'utils/mixpanel';
import { objectGet } from 'utils/helpers';
import * as S from './styled';

/*   REVERT | Merged PR 419: PBI-1028: tabela persiste configurações

// Converte o path da página em um nome de tabela do tipo: "configuracoes/envios"
const getTableName = path => {
  let key = path;
  const idSplit = path.split('/')[path.split('/').length - 1];
  if (!isNaN(idSplit)) key = path.split(idSplit)[0];
  return key.slice(1);
};

*/

const TableLocal = ({
  columns, // Colunas a serem exibidas -- Required
  data, // Itens a serem exibidos -- Required
  onClickRow, // Função executada ao clicar sobre a linha -- Optional
  permitIsSortedOccur, // Permite a ordenação da tabela -- Optional
  lastChildWidth,
  sortBy, // Define a ordenação da tabela
  pageSizes, // Quantidade de itens por pagina (default 20) -- Optional
  setSelectedData, // Armazena os Ids de itens marcados no checkbox -- Optional
  selectedDefault = [], // marca como default o checkbox
  setSelectedRow, // Define a linha clicada (para executar função sem marcar checkbox) -- Optional
  actions, // Ações referentes ao coluna "Ações" -- Optional
  setActionsData, // Armazena os dados da linha selecionada em "Ações" -- Optional
  bulkActions, // Ações referentes às linhas selecionadas no topo da tabela -- Optional
  headerActions, // Botões de ação no topo da tabela (quando não há nada selecionado) -- Optional
  headerFilters, // Acções que aparecem ao lado do searchbar -- Optional
  resetPaginationOnFilter, // Se true, volta pra pagina 1 ao mudar o data.
  search, // Objeto contendo label e keys pesquisadas -- Optional
  tableType, // True ==  Altera estilização da tabela (cabeçalho, input, e sombra) -- Optional
  empty, // Objeto que recebe image, title, subtitle
  blockSizePage, // Bloqueia a troca do tamanho da pagina
  loading, // Caso a tabela precise de loading
  heightEmpty, // Defini a altura do empty state (usado para tabelas em modais) -- Optional
  pageToMixPanel, // Parâmetro para MixPanel --- Optional
  selectObject, // Parâmetro para passar objeto ao invés de ids --- Optional
  ...props
}) => {
  // Redux: persistencia de parametros da tabela de acordo com o useLocation().pathname
  // tablePersist pode inicializar como undefined! Neste caso ele vai ser
  //    configurado na primeira chamada do handleTablePersist

  /*   REVERT | Merged PR 419: PBI-1028: tabela persiste configurações

  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const tableName = getTableName(location);
  const tablePersist = useSelector(state => {
    return state.tables[tableName];
  });

  */

  const theme = useTheme();
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const [anchorAction, setAnchorAction] = useState(false);
  const [anchorMore, setAnchorMore] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchApply, setSearchApply] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [searchTrackEvent] = useDebounce(searchInput, 1000);

  const dataTable = searchApply?.length > 0 ? searchResult : data;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageSize, pageIndex },
    /*

    REVERT | Merged PR 419: PBI-1028: tabela persiste configurações

    state: { pageSize, pageIndex, sortBy: sortByApplied },

    */
  } = useTable(
    {
      columns,
      data: dataTable,
      initialState: {
        selectedRowIds: selectedDefault || [],

        pageSize: pageSizes || 20,
        sortBy,
        selectedRowIds: selectedDefault || [],
        /*

        REVERT | Merged PR 419: PBI-1028: tabela persiste configurações
        pageIndex: tablePersist?.pageIndex || 0,
        pageSize: pageSizes || tablePersist?.pageSize || 20,
        sortBy: tablePersist?.sortBy || sortBy || [{ id: 'id', desc: false }],
        */
      },
      disableSortBy: !permitIsSortedOccur,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      // Exibir checkbox apenas quando for passado setSelectedData
      setSelectedData &&
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);

      if (columns[columns.length - 1]?.id === 'acoes') {
        // Evento de clique em Ações
        const clickAction = event => {
          setAnchorAction(event.currentTarget);
        };

        // Define coluna de ações
        columns[columns.length - 1].Cell = function ({ row }) {
          return (
            <IconButton
              size="medium"
              id={row.original.id}
              key={row.original.id}
              onClick={e => {
                clickAction(e);
                if (selectObject) setActionsData(row.original);
              }}
            >
              <MoreHoriz fontSize="large" />
            </IconButton>
          );
        };
      }
    },
  );

  const renderAcao = () => {
    return (
      <Popover
        id={anchorAction ? 'popover' : undefined}
        open={Boolean(anchorAction)}
        anchorEl={anchorAction}
        onClose={() => setAnchorAction(null)}
        onClick={() => setAnchorAction(null)} // Fechar ao clicar dentro tmb
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {actions?.map((action, key) => (
          <MenuItem
            key={action.name}
            onClick={() => action.action(anchorAction.id)}
          >
            <S.ItemPopover>{action.text}</S.ItemPopover>
          </MenuItem>
        ))}
      </Popover>
    );
  };

  const renderBulkMenu = options => {
    return (
      <S.BulkOptions>
        <S.BulkItem>
          {selectedFlatRows.length}{' '}
          {selectedFlatRows.length === 1 ? 'Selecionado' : 'Selecionados'}
        </S.BulkItem>
        {options.map((i, idx) => {
          if (idx < 3) {
            return (
              <S.BulkItem
                key={idx}
                className="option"
                onClick={() => i.function()}
              >
                {i.title}
              </S.BulkItem>
            );
          }
        })}

        {options.length > 3 && (
          <>
            <S.BulkItem
              className="option"
              onClick={e => setAnchorMore(e.currentTarget)}
            >
              <span
                style={{
                  display: 'inline-flex',
                  justifyContent: 'center',
                }}
              >
                <span>Mais</span>
                <ArrowDropDownIcon sx={{ fontSize: 20 }} />
              </span>
            </S.BulkItem>
            <Popover
              id={anchorMore ? 'popover' : undefined}
              open={Boolean(anchorMore)}
              anchorEl={anchorMore}
              onClose={() => setAnchorMore(null)}
              onClick={() => setAnchorMore(null)} // Fechar ao clicar dentro tmb
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {options?.map((i, idx) => {
                if (idx >= 3) {
                  return (
                    <MenuItem key={idx}>
                      <S.ItemPopover onClick={() => i.function()}>
                        {i.title}
                      </S.ItemPopover>
                    </MenuItem>
                  );
                }
              })}
            </Popover>
          </>
        )}
      </S.BulkOptions>
    );
  };

  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <S.TableCheckbox
            indeterminate={indeterminate}
            check={rest.checked}
            style={{
              backgroundColor:
                rest.checked || indeterminate
                  ? theme.palette.brand.secondary.natural
                  : theme.palette.system.transparent,
            }}
          >
            {indeterminate && (
              <RemoveIcon htmlColor={theme.palette.words.button.contrast} />
            )}
            {!indeterminate && (
              <CheckIcon
                className="checkIcon"
                htmlColor={theme.palette.words.button.contrast}
              />
            )}
            <input type="checkbox" ref={resolvedRef} {...rest} />
          </S.TableCheckbox>
        </div>
      );
    },
  );

  const resetScroll = () => {
    let table = document?.getElementById('local-table');
    table?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const renderFooter = () => (
    <S.Footer blockSizePage={blockSizePage}>
      {!blockSizePage && (
        <S.FooterCol>
          <p>Exibindo</p>
          <Select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
            data={[
              { name: '10', value: 10 },
              { name: '20', value: 20 },
            ]}
            style={{ height: '35px', width: '70px' }}
          />
          <p>{`de ${dataTable.length}`}</p>
        </S.FooterCol>
      )}
      <Stack spacing={2}>
        <S.FooterCol>
          <S.Control
            onClick={() => {
              resetScroll();
              gotoPage(0);
            }}
          >
            <Icon name="chevron-left-outline" />
            <p>Primeiro</p>
          </S.Control>
          <S.Control
            onClick={() => {
              resetScroll();
              gotoPage(pageIndex - 1);
            }}
          >
            <Icon name="chevron-left-outline" />
            <p>Anterior</p>
          </S.Control>
          <S.Pages
            count={pageCount}
            page={pageIndex + 1}
            onChange={(e, value) => {
              resetScroll();
              gotoPage(value - 1);
            }}
            hidePrevButton
            hideNextButton
          />
          <S.Control
            onClick={() => {
              resetScroll();
              gotoPage(pageIndex + 1);
            }}
          >
            <p>Próximo</p>
            <Icon name="chevron-right-outline" />
          </S.Control>
          <S.Control
            onClick={() => {
              resetScroll();
              gotoPage(pageCount - 1);
            }}
          >
            <p>Último</p>
            <Icon name="chevron-right-outline" />
          </S.Control>
        </S.FooterCol>
      </Stack>
    </S.Footer>
  );

  /*

  useEffect(() => {
    const handleTablePersist = data => {
      if (tableName)
        dispatch(
          setTableParams({
            tableName,
            data,
          }),
        );
    };

    handleTablePersist({ pageIndex, sortBy: sortByApplied, pageSize });
  }, [pageIndex, sortByApplied, pageSize]);

  */

  useEffect(() => {
    setSelectedData &&
      (selectObject
        ? setSelectedData(selectedFlatRows.map(item => item.original))
        : setSelectedData(selectedFlatRows.map(item => item.original.id)));
  }, [selectedFlatRows]);

  useEffect(() => {
    // Usado para obter o id da linha selecionada antes de exibir o conteudo
    // Ex: opção da coluna de ações depende do item clicado

    setSelectedRow && setSelectedRow(anchorAction?.id);
  }, [anchorAction]);

  // Recebe atualziação da busca e dos dados filtrados
  useEffect(() => {
    if (search) {
      // Limpa espaços e padroniza string de busca
      const searchString = searchInput.toLowerCase().trim();
      // Salva busca aplicada para referenciar os dados corretamente
      setSearchApply(searchString);

      // Só faz busca se searchString for diferente de ''
      if (searchString) {
        let newResult = [];
        for (const key of search.keys) {
          const finded = data.filter(item => {
            const itemString = String(objectGet(item, key))
              .toLowerCase()
              .trim();
            return itemString.includes(searchString);
          });
          newResult = [...newResult, ...finded];
        }
        setSearchResult(newResult);
      }
    }
  }, [searchInput, data]);

  useEffect(() => {
    // Valida busca com pelo menos 3 caracteres para o MixPanel
    if (searchTrackEvent.length >= 3) {
      trackEvent(user, pageToMixPanel, null, searchTrackEvent);
    }
  }, [searchTrackEvent]);

  return (
    <S.Wrapper {...props} tableType={tableType} id="local-table">
      {search && (
        <S.TopWrapper tableType={tableType}>
          <S.Filters>
            <form id="searchForm" action="" onSubmit={e => e.preventDefault()}>
              <Input
                noAsterisk
                placeholder={search.inputLabel}
                value={searchInput}
                onChange={e =>
                  setSearchInput(
                    e.target.value.trim() === '' ? '' : e.target.value,
                  )
                }
                style={{
                  width: '300px',
                  background: tableType
                    ? theme.palette.system.overlay
                    : theme.palette.brand.primary.background,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        component={searchIcon}
                        color={theme.palette.words.text.light}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            {headerFilters}
          </S.Filters>
          <S.Actions>
            {
              // Mais de uma linha selecionada altera o header para bulkActions
              bulkActions
                ? selectedFlatRows.length >= 1 && renderBulkMenu(bulkActions)
                : !!headerActions && headerActions
            }
          </S.Actions>
        </S.TopWrapper>
      )}

      <S.Table {...getTableProps()} tableType={tableType}>
        <S.Head>
          {headerGroups.map((headerGroup, key) => (
            <S.Tr
              key={key}
              lastChildWidth={lastChildWidth}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, idx) => (
                <S.Th
                  key={idx}
                  lastChildWidth={lastChildWidth}
                  center={false}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <S.Title
                    style={{
                      justifyContent: column.centered ? 'center' : 'start',
                      textAlign: column.centered ? 'center' : 'start',
                    }}
                  >
                    {column.render('Header')}
                    <S.ExpandIconContainer>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ExpandLessIcon fontSize="small" />
                        ) : (
                          <ExpandMoreIcon fontSize="small" />
                        )
                      ) : (
                        ''
                      )}
                    </S.ExpandIconContainer>
                  </S.Title>
                </S.Th>
              ))}
            </S.Tr>
          ))}
        </S.Head>

        {!loading && data && data?.length > 0 && page ? (
          <S.Body {...getTableBodyProps()}>
            {page.map((row, k) => {
              prepareRow(row);
              return (
                <S.Tr key={k}>
                  {row.cells.map((cell, k) => {
                    return (
                      <S.Td
                        key={k}
                        lastChildWidth={lastChildWidth}
                        {...cell.getCellProps()}
                        onClick={
                          onClickRow &&
                          cell.column.id !== 'acoes' &&
                          cell.column.id !== 'selection' &&
                          cell.column.id !== 'status' &&
                          cell.column.id !== 'atividades'
                            ? () => onClickRow(cell.row.original)
                            : null
                        }
                        style={{
                          cursor: onClickRow ? 'pointer' : 'default',
                        }}
                      >
                        {cell.render('Cell')}
                      </S.Td>
                    );
                  })}
                </S.Tr>
              );
            })}
          </S.Body>
        ) : null}
      </S.Table>

      {loading && (
        <div
          style={{
            width: '100%',
            position: 'relative',
            background:
              '0% 0% no-repeat padding-box padding-box rgba(75, 81, 102, 0.2)',
            minHeight: '300px',
            borderRadius: '4px',
          }}
        >
          <Loading />
        </div>
      )}
      {page && page?.length <= 0 && !loading && (
        <div style={{ marginTop: '-28px' }}>
          <EmptyDataCard
            image={empty?.image ? empty.image : 'motorista.png'}
            title={
              searchInput
                ? `Nenhum resultado encontrado para "${searchInput}"`
                : empty?.title
                ? empty.title
                : 'Não foram encontrados resultados para este período'
            }
            subtitle={
              empty?.subtitle
                ? empty.subtitle
                : 'Verifique os filtros selecionados.'
            }
            height={heightEmpty}
          />
        </div>
      )}

      {renderFooter()}
      {renderAcao()}
    </S.Wrapper>
  );
};

export default TableLocal;
