import { toast } from 'react-toastify';
import { useEffect, useState, useRef } from 'react';

// Components
import DataHour from 'components/Inputs/DataHour';
import TextInput from 'components/Inputs/TextField';
import SelectOne from 'components/Inputs/SelectOne';
import { ReactComponent as arrow_down } from 'images/icons/arrows/down.svg';

// Material UI
import { Grid, Menu, MenuItem, SvgIcon } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

// Servies
import {
  formatEmptyHour,
  formatNewHour,
  formatNewHourClean,
} from 'utils/dates';
import { useTheme } from 'styled-components';
import { steps } from '../constants';
import * as services from '../services';
import * as S from './styled';

export const InformacoesGerais = ({
  setChanged,
  disabled,
  setRecharge,
  trySave,
  setTrySave,
  evaluation,
  setCurrentStep,
  setEvalId,
}) => {
  const theme = useTheme();
  // Input References
  const motoristaRef = useRef(null);
  const caminhaoRef = useRef(null);
  const distribuidoraRef = useRef(null);
  const inicialRef = useRef(null);
  const finalRef = useRef(null);

  // FORM STATES
  const [motorista, setMotorista] = useState(null);
  const [placaCavalo, setPlacaCavalo] = useState('');
  const [placaReboque, setPlacaReboque] = useState('');
  const [local, setLocal] = useState('');
  const [transportadora, setTransportadora] = useState('');
  const [inicial, setInicial] = useState(null);
  const [final, setFinal] = useState(null);
  const [distributor, setDistributor] = useState(null);

  // Distributors States
  const [drivers, setDrivers] = useState(null);
  const [trucks, setTrucks] = useState(null);
  const [truck, setTruck] = useState(null);
  const [distributors, setDistributors] = useState(null);
  const [anchorDistributor, setAnchorDistributor] = useState(null);

  const changeMonitoring = (value, func) => {
    if (value?.length >= 1024) return;
    func(value);
    setChanged(true);
  };

  // ----------------------REQUEST---------------------------//
  useEffect(() => {
    const getDistributors = async () => {
      const res = await services.selectDistributors();
      setDistributors(res.data);
    };
    getDistributors();
  }, []);

  useEffect(() => {
    const getDrivers = async () => {
      const res = await services.selectDrivers();
      setDrivers(res.data);
    };
    getDrivers();
  }, []);

  useEffect(() => {
    const getTrucks = async () => {
      const res = await services.selectTrucks();
      setTrucks(res.data);
    };
    getTrucks();
  }, []);

  // ------------------------SAVE INFORMATIONS-------------------------//

  // Verifications
  useEffect(() => {
    if (trySave) {
      // Data have two diferents formats, one to create, another to update
      let data = null;
      if (!evaluation) {
        let error = false;

        // Verify Fields
        if (!motorista) {
          motoristaRef.current.classList.add('error');
          error = true;
        }
        if (!truck) {
          caminhaoRef.current.classList.add('error');
          error = true;
        }
        if (!distributor) {
          distribuidoraRef.current.classList.add('error');
          error = true;
        }

        if (!inicial) {
          inicialRef.current.classList.add('error');
          error = true;
        }

        if (!final) {
          finalRef.current.classList.add('error');
          error = true;
        }

        if (error) {
          toast.error('Preencha todos os campos obrigatórios');
          setTrySave(false);
          return;
        }

        if (inicial > final) {
          toast.error('A data incial não pode ser maior que a final');
          inicialRef.current.classList.add('error');
          finalRef.current.classList.add('error');
          setTrySave(false);
          return;
        }

        if (isNaN(inicial.getTime()) && isNaN(final.getTime())) {
          toast.error('Data inicial e final são inválidas');
          inicialRef.current.classList.add('error');
          finalRef.current.classList.add('error');
          setTrySave(false);
          return;
        }

        if (isNaN(inicial.getTime())) {
          toast.error('Data inicial inválida');
          inicialRef.current.classList.add('error');
          setTrySave(false);
          return;
        }

        if (isNaN(final.getTime())) {
          toast.error('Data final inválida');
          finalRef.current.classList.add('error');
          setTrySave(false);
          return;
        }

        data = {
          id_do_motorista: motorista.id,
          id_da_filial: motorista.filial.id,
          id_da_distribuidora: distributor.id,
          id_do_caminhao: truck,
          hora_inicial: inicial,
          hora_final: final,
          local,
          placa_reboque: placaReboque,
          disponibilizado_em: new Date(),
        };
      } else {
        data = {
          placa_reboque: placaReboque,
          local,
          id_da_distribuidora: distributor?.id,
        };
      }

      createEvaluantion(data);
    }
  }, [trySave]);

  // Request
  const createEvaluantion = async data => {
    // This functions verify if evaluantion is existing or another need be created
    if (!evaluation) {
      const res = await services.createEvaluantion(data);

      if (!res.success) {
        toast.error(res.message);
      } else {
        toast.success(res.message);
        setEvalId(res.evaluation.id);
        setRecharge(true);
        setCurrentStep(steps[1]);
      }
    } else {
      const res = await services.updateHeaderEvaluation(evaluation?.id, {
        event: 'updateHeader',
        data,
      });

      if (res.data.success) {
        toast.success(res.data.message);
        setCurrentStep(steps[1]);
        setRecharge(true);
      } else {
        toast.error(res.data.message);
      }
    }

    setTrySave(false);
  };

  // Fill fildes if evaluantion is existing
  useEffect(() => {
    if (evaluation) {
      setPlacaCavalo(
        evaluation.caminhao?.placa ? evaluation.caminhao?.placa : '',
      );
      setPlacaReboque(evaluation.placa_reboque ? evaluation.placa_reboque : '');
      setLocal(evaluation.local ? evaluation.local : '');
      setTransportadora(evaluation.filial.empresa?.nome);
      setInicial(formatNewHourClean(evaluation.hora_inicial));
      setFinal(formatNewHourClean(evaluation.hora_final));
      setDistributor(evaluation.distribuidora);
      setMotorista(evaluation.motorista);
    }
  }, [evaluation]);

  // CONTROLLER FOR DRIVER INFORMATION
  const handleDriver = driverId => {
    for (const i in drivers) {
      if (drivers[i].id === driverId) {
        changeMonitoring(drivers[i], setMotorista);
      }
    }
  };

  return (
    <>
      <S.Box>
        <h2>Informações do Motorista</h2>
        <div className="userArea">
          <div className="imageArea">
            {motorista?.foto && <img src={motorista.foto} />}
            {motorista?.foto === null && (
              <span className="letters">{motorista.nome[0]}</span>
            )}
            {motorista === null && <AddOutlinedIcon />}
          </div>
          {!evaluation && (
            <div
              className="textArea"
              style={{ width: '38%', marginTop: '-30px' }}
            >
              <SelectOne
                value={motorista}
                handleChange={handleDriver}
                data={drivers}
                required
                label={
                  motorista
                    ? 'Motorista selecionado:'
                    : 'Selecione um motorista'
                }
                type="driver"
                refs={motoristaRef}
              />
            </div>
          )}
          {evaluation && (
            <div className="textArea">
              <span className="userName">
                {evaluation.motorista?.nome.toLowerCase()}
              </span>
              <span className="filialName">
                Filial: {evaluation.filial?.nome}
              </span>
            </div>
          )}
        </div>
        <Grid
          className="formArea"
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          marginTop={1}
        >
          <Grid item xs={6} md={6}>
            {!evaluation?.id && (
              <SelectOne
                value={truck}
                disabled={disabled}
                required
                handleChange={value => changeMonitoring(value, setTruck)}
                data={trucks}
                label={
                  truck ? 'Caminhão selecionado:' : 'Selecione um caminhão'
                }
                type="truck"
                refs={caminhaoRef}
                variant={disabled ? 'filled' : 'outlined'}
              />
            )}
            {evaluation?.id && (
              <TextInput
                disabled
                label="Placa Cavalo"
                value={placaCavalo}
                onChange={() => setPlacaCavalo(placaCavalo)}
                props={{ readOnly: true }}
                variant="filled"
              />
            )}
          </Grid>

          <Grid item xs={6} md={6}>
            <TextInput
              label="Placa Reboque"
              value={placaReboque}
              onChange={e => changeMonitoring(e.target.value, setPlacaReboque)}
              disabled={disabled}
              props={{ readOnly: true }}
              variant={disabled ? 'filled' : 'outlined'}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextInput
              label="Local/Trecho"
              placeholder="Insira sua descrição"
              value={local}
              onChange={e => changeMonitoring(e.target.value, setLocal)}
              InputProps={{ multiline: true }}
              multiline
              disabled={disabled}
              props={{ readOnly: true }}
              variant={disabled ? 'filled' : 'outlined'}
              rows={6}
            />
            <div className="counter">{`${local ? local?.length : 0}/1023`}</div>
          </Grid>
        </Grid>
      </S.Box>
      <S.Box>
        <h2>Informações da Viagem</h2>
        <div className="userArea">
          <div className="imageArea">
            {distributor?.logo_url && <img src={distributor?.logo_url} />}
            {distributor && !distributor?.logo_url && (
              <span className="letters">{distributor?.nome[0]}</span>
            )}
            {distributor === null && <AddOutlinedIcon />}
          </div>
          <div className="textArea">
            <span className="filialName">
              Cliente{' '}
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'red' }}>
                *
              </span>
            </span>
            {distributor && (
              <span className="userName">{distributor.nome}</span>
            )}
            {!distributor && (
              <span className="userName" ref={distribuidoraRef}>
                Selecione uma distribuidora
              </span>
            )}
          </div>
          <div
            style={{ display: 'flex', alignItems: 'flex-end', height: '55px' }}
          >
            {!disabled && (
              <SvgIcon
                onClick={e => setAnchorDistributor(e.currentTarget)}
                fontSize="large"
                component={arrow_down}
                style={{ cursor: 'pointer' }}
              />
            )}
            <Menu
              open={!!anchorDistributor}
              anchorEl={anchorDistributor}
              onClose={() => setAnchorDistributor(null)}
              onClick={() => setAnchorDistributor(null)}
            >
              <Grid container style={{ width: '300px' }}>
                {distributors &&
                  distributors.map((item, index) => (
                    <Grid item md={12} key={index}>
                      <MenuItem
                        key={item.nome}
                        selected={item?.id === distributor?.id}
                        onClick={() => changeMonitoring(item, setDistributor)}
                      >
                        <Grid item md={3}>
                          <S.StyledDistributorLogo>
                            <S.StyledLogo
                              backgroundImage={item.logo_url && item.logo_url}
                            />
                          </S.StyledDistributorLogo>
                        </Grid>
                        <Grid item md={9}>
                          <p
                            style={{
                              color: theme.palette.words.title.natural,
                              fontSize: '16px',
                              fontWeight: 600,
                            }}
                          >
                            {item.nome}
                          </p>
                        </Grid>
                      </MenuItem>
                    </Grid>
                  ))}
              </Grid>
            </Menu>
          </div>
        </div>
        <Grid
          className="formArea"
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          marginTop={1}
        >
          <Grid item xs={6} md={6}>
            <TextInput
              disabled
              required
              label="Transportadora"
              value={
                evaluation?.id
                  ? transportadora
                  : motorista
                  ? motorista?.filial?.empresa?.nome
                  : 'Selecione um motorista para preencher esse campo!'
              }
              onChange={e =>
                changeMonitoring(e.target.value, setTransportadora)
              }
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <TextInput
              disabled
              required
              label="Filial"
              value={
                motorista
                  ? motorista.filial.nome
                  : 'Selecione um motorista para preencher esse campo!'
              }
              onChange={e => {}}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6} md={6}>
            {!evaluation?.id && (
              <DataHour
                disabled={disabled}
                required
                label="Data Inicial"
                value={inicial}
                onChange={value => changeMonitoring(value, setInicial)}
                refs={inicialRef}
                variant={disabled ? 'filled' : 'outlined'}
              />
            )}
            {evaluation?.id && (
              <TextInput
                disabled
                label="Data Inicial"
                value={inicial}
                onChange={() => setInicial(inicial)}
                props={{ readOnly: true }}
                variant="filled"
              />
            )}
          </Grid>

          <Grid item xs={6} md={6}>
            {!evaluation?.id && (
              <DataHour
                required
                disabled={disabled}
                label="Data Final"
                value={final}
                onChange={value => changeMonitoring(value, setFinal)}
                refs={finalRef}
                variant={disabled ? 'filled' : 'outlined'}
              />
            )}
            {evaluation?.id && (
              <TextInput
                disabled
                label="Data Final"
                value={final}
                onChange={() => setFinal(final)}
                props={{ readOnly: true }}
                variant="filled"
              />
            )}
          </Grid>

          <Grid item xs={12} md={12}>
            <TextInput
              disabled
              required
              label="Disponibilizado em:"
              value={formatEmptyHour()}
              onChange={() => {}}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        </Grid>
      </S.Box>
    </>
  );
};
