import { Modal } from '@mui/material';
import styled from 'styled-components';
import colors from 'styles/colors';

export const ModalMUI = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailArea = styled.div`
  width: 70%;
  max-width: 800px;
  height: 95%;
  max-height: 800px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  overflow: hidden;
  transition: all ease 0.2s;
  background-color: ${colors.white};
  border-radius: 5px;

  .container {
    width: 99%;
    position: relative;
  }
`;

export const DetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  width: 100%;

  .leftArea {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 200px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const ScrollBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: scroll;
`;

export const DetailsBox = styled.div`
  border: 2px solid ${colors.greyBorder};
  border-radius: 4px;
  padding: 30px;
  padding-bottom: 50px;

  .header {
    top: 0;
    right: 0;
    margin-top: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .userInfos {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .photo {
      width: 100px;
      height: 100px;
      border-radius: 100px;
      overflow: hidden;
      border: 3px solid ${colors.greenMain};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      margin-bottom: 5px;

      .name {
        font-size: 30px;
        font-weight: 900;
        color: ${colors.greyTitle};
        background-color: ${colors.greyBorder};
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 100px;
      }
    }

    .name {
      font-size: 25px;
      font-weight: 600;
      color: ${colors.greyTitle};
    }

    .filial {
      font-size: 18px;
      font-weight: 500;
      color: ${colors.greySubtitle};
    }
  }

  .contentInfos {
    display: flex;
    justify-content: space-between;
    padding: 30px;

    .info {
      display: flex;

      .icon {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: ${colors.greyBorder};
      }

      .texts {
        margin-left: 10px;

        .title {
          font-size: 18px;
          font-weight: 600;
          color: ${colors.greyTitle};
        }

        .subTitle {
          font-size: 16px;
          font-weight: 900;
          color: ${colors.grey};
        }
      }
    }
  }
`;

export const EvaluationBox = styled.div`
  padding: 30px;

  .evalType {
    display: flex;
    align-items: center;

    .text {
      font-size: 25px;
      font-weight: 600;
      color: ${colors.greyTitle};
      margin-left: 10px;
      margin-top: -8px;
    }
  }
`;
