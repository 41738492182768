import React from 'react';
import { Switch as Input } from 'components/Inputs/Switch';
import { Controller } from 'react-hook-form';

const Switch = ({ control, name, rules, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <Input
          check={value}
          setCheckbox={() => {
            onChange(!value);
          }}
          {...props}
        />
      )}
    />
  );
};

export default Switch;
