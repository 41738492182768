/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import { useEffect, useState } from 'react';

// Components
import Loading from 'components/Loading';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import { InitialModal } from './WelcomeModal';

// Components MUI
import { Tooltip } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

// Utils
import * as services from './services';
import OnisysLogo from 'images/Onisys_Simbolo.png';
import { modelMonitoramento as modelDefault } from './constants';
import { useSelector } from 'react-redux';

export const InitialGuide = ({
  open,
  handleClose,
  data = [],
  info,
  preview,
  initialStep,
  callGuide,
}) => {
  const { user } = useSelector(state => state.auth.user);

  const [step, setStep] = useState(0);
  const [clients, setClients] = useState([]);
  const [errors, setErrors] = useState(null);
  const [okOpen, setOkOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [finished, setFinished] = useState(true);
  const [guideData, setGuideData] = useState(data);
  const [transition, setTransition] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [initialModal, setInitialModal] = useState(!callGuide && !preview);

  // Model Config
  const [model, setModel] = useState(modelDefault);

  // ----------------------FORMAT INITIAL DATA----------------------//
  useEffect(() => {
    const newModel = { ...modelDefault };
    newModel.title = info?.titulo || modelDefault?.title;
    newModel.id = info?.id || modelDefault.steps[0].id;
    newModel.steps =
      info?.etapas?.map((item, k) => {
        const step = modelDefault.steps.find(step => step.id === item?.id);

        return {
          ...step,
          id: item?.id,
          title: item?.titulo,
          tooltip: item?.observacoes,
          link: item?.link,
          tipo: item?.tipo,
          description: item?.descricao,
          niveis: item?.niveis,
          ativa: item?.ativa,
        };
      }) || modelDefault.steps;

    newModel.steps = newModel.steps.filter(
      item =>
        item.niveis?.split(',').includes(user.nivel.toString()) && item.ativa,
    );

    // Fecha se não houver nenhum item para exibir (Prevenção de erros)
    if (newModel.steps.length === 0) {
      // TODO: Verificar comportamento no primeiro login
      // setFinished(true);
      setTimeout(() => {
        handleClose();
      }, 450);
      return;
    }

    setTimeout(() => {
      setFinished(false);
    }, 250);

    setOkOpen(true);
    setModel(newModel);

    const fetchClientes = async () => {
      const res = await services.selectClients();
      setClients(res);
    };
    fetchClientes();

    setStep(initialStep || 0);
  }, [info]);

  // --------------------- HANDLES ---------------------//
  const handleNext = async (save, skip) => {
    const service = model.steps[step].service;

    if (save && currentData && service && !preview) {
      const validator = model.steps[step].validator;
      const err = (validator && validator(currentData)) || false;

      if (err) {
        toast.error('Preencha todos os campos obrigatórios');
        setErrors(err);
        return;
      }

      setLoading(true);
      let res = await service(currentData);
      if (!res?.success) {
        setLoading(false);
        toast.error(res.message || 'Erro ao salvar dados');
        return;
      }

      setGuideData({
        ...guideData,
        [`${model.steps[step].acessor}`]: Array.isArray(res?.data)
          ? res?.data?.reverse()
          : res?.data,
      });
      toast.success('Dados salvos com sucesso');
    }

    if (changed && !save && !skip && !preview) {
      setOpenConfirm('next');
      return;
    }

    !preview && services.register(model.steps[step].id, model.id);

    setOpenConfirm(false);
    setErrors(null);
    setChanged(false);
    setCurrentData(null);

    let doc = document.getElementById('steperComponentView');
    doc.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (step < model.steps.length - 1 && !callGuide) {
      setTransition(true);
      setTimeout(() => {
        setTransition(false);
      }, 600);
      setTimeout(() => {
        setLoading(false);
        setStep(step + 1);
      }, 250);
    } else {
      setFinished(true);
      setTimeout(() => {
        handleClose();
      }, 450);
    }
  };

  const handleBack = (_, skip) => {
    if (changed && !skip && !preview) {
      setOpenConfirm('back');
      return;
    }

    setOpenConfirm(false);
    setErrors(null);
    setChanged(false);
    setCurrentData(null);

    let doc = document.getElementById('steperComponentView');
    doc.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setTransition(true);
    setTimeout(() => {
      setTransition(false);
    }, 600);
    setTimeout(() => {
      setStep(step - 1);
    }, 250);
  };

  const updateData = data => {
    const currentStep = model.steps[step];
    const stepData = guideData[currentStep?.acessor] || [];
    const found = stepData.find(item => item.id === data.id);
    if (found) {
      const index = stepData.indexOf(found);
      stepData[index] = data;
    } else {
      stepData.unshift(data);
    }
    setGuideData({
      ...guideData,
      [`${currentStep?.acessor}`]: stepData,
    });
  };

  const uploadManyData = data => {
    const currentStep = model.steps[step];
    setGuideData({
      ...guideData,
      [`${currentStep?.acessor}`]: [
        ...data,
        ...guideData[currentStep?.acessor],
      ],
    });
  };

  const newTab = () => {
    window.open(model.support, '_blank');
  };

  // --------------------- RENDER ---------------------//
  const renderComponent = () => {
    const currentStep = model.steps[step];
    let Component = currentStep?.Component;

    if (open && Component)
      return (
        <Component
          preview={preview}
          title={currentStep?.actionTitle}
          // tooltip={currentStep?.tooltipAction}
          button={currentStep?.button}
          setChanged={setChanged}
          setCurrentData={setCurrentData}
          errors={errors}
          data={guideData[currentStep?.acessor]}
          clients={clients}
          updateData={updateData}
          uploadManyData={uploadManyData}
          activedFilials={guideData?.filiais}
        />
      );
  };

  return (
    <S.ModalMUI open={open && okOpen}>
      <S.Container>
        <S.ProgressArea
          progress={((step + 1) * 100) / model.steps.length}
          transition={finished}
        >
          <div className="line" />
        </S.ProgressArea>
        <S.MediaArea transition={finished}>
          <S.TitleArea>
            <div className="left">
              <img src={OnisysLogo} />
              <div className="product">{model.title}</div>
            </div>
            {preview && (
              <div className="right">
                <CloseRoundedIcon onClick={() => handleClose()} />
              </div>
            )}
          </S.TitleArea>
          <S.MediaContainer transition={transition}>
            {model?.steps[step]?.title && (
              <div className="headerLine">
                <div className="title">{model?.steps[step]?.title}</div>
                {/* <Tooltip title={model?.steps[step]?.tooltip}>
                  <HelpOutlineOutlinedIcon
                    htmlColor={colors.greyTiny}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip> */}
              </div>
            )}
            {model?.steps[step]?.link && (
              <div className="videoArea">
                {model?.steps[step]?.tipo === 'VIDEO' && (
                  <ReactPlayer
                    width="100%"
                    height="350px"
                    controls
                    url={model?.steps[step]?.link}
                    className="react-player"
                  />
                )}

                {model?.steps[step]?.tipo === 'IMAGEM' && (
                  <img src={model?.steps[step]?.link} />
                )}
              </div>
            )}
            {model?.steps[step]?.description && (
              <div className="description">
                {model?.steps[step]?.description}
              </div>
            )}
          </S.MediaContainer>
        </S.MediaArea>
        <S.ActionsArea id="steperComponentView" transition={finished}>
          <S.ActionsContainer transition={transition}>
            {!loading && renderComponent()}
            {loading && (
              <S.BoxLoading>
                <Loading />
              </S.BoxLoading>
            )}
          </S.ActionsContainer>
        </S.ActionsArea>
        <S.SupportArea onClick={() => newTab()}>
          <HeadsetMicOutlinedIcon />
        </S.SupportArea>
        <S.FooterArea transition={finished}>
          <div className="left">
            {!!step && !callGuide && (
              <GhostButton children="Voltar" onClick={handleBack} />
            )}
          </div>
          <div className="right">
            <button
              className="lineButton"
              onClick={() => handleNext()}
              disabled={loading || transition}
            >
              {callGuide ? 'Fechar' : 'Pular'}
            </button>
            <DefaultButton
              children={
                model?.steps[step]?.hasSave
                  ? callGuide
                    ? 'Salvar'
                    : 'Salvar e Avançar'
                  : 'Avançar'
              }
              onClick={() => handleNext(model?.steps[step]?.hasSave)}
              disabled={!changed && model?.steps[step]?.hasSave}
              loading={loading}
            />
          </div>
        </S.FooterArea>

        {/* Modal Area */}
        <ConfirmModal
          open={!!openConfirm}
          handleClose={() => setOpenConfirm(false)}
          onClick={() =>
            openConfirm === 'next'
              ? handleNext(null, true)
              : handleBack(null, true)
          }
          buttonText="Confirmar"
          title="Tem cereza que deseja sair dessa etapa?"
          subtitle="Todos as alterações não salvas serão perdidas."
          titleIcon={<WarningAmberRoundedIcon htmlColor={colors.redDanger} />}
        />

        <InitialModal
          open={initialModal}
          onClose={() => setInitialModal(false)}
        />
      </S.Container>
    </S.ModalMUI>
  );
};
