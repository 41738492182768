import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  status: '',
};

export default function filterFormularioMeus(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_FORMULARIO_MEUS: {
        draft.status = action.payload.query.status;
        break;
      }
    }
  });
}
