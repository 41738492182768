import * as S from './styled';

const ItemText = ({ value, placeholder, onChange, onFocus, disabled }) => {
  return (
    <S.Input
      value={value}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
      onFocus={onFocus}
      readOnly={disabled}
      disabled={disabled}
    />
  );
};

export default ItemText;
