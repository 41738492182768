import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  letter-spacing: 0px;
  gap: 0.5rem;
`;

export const Title = styled.div`
  align-content: end;
  font: normal normal 900 20px/16px Texta;
  color: #4b5166;
`;

export const Icon = styled.div`
  width: 3rem;
  padding: 0 0.25rem;
`;

export const Text = styled.div`
  align-content: center;
`;

export const Subtitle = styled.div`
  font: normal normal medium 16px/16px Texta;
  color: #4b5166;

  span {
    font: normal normal 900 16px/16px Texta;
    color: #bdcc30;
  }
`;
