import React from 'react';
import styled from 'styled-components';

// Estilos do card
const CardContainer = styled.div`
  display: flex;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #95aac94d;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  margin-bottom: 16px;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: 1rem;
  font-style: normal;
  text-align: center;
  color: #4b5166;
`;

const PercentageChange = styled.div`
  color: ${p => p.color};
  text-align: center;
  font-weight: 900;
`;

const TotalValue = styled.div`
  font-size: ${p => p.fontSizeTitle};
  color: ${p => p.color};
`;

const Description = styled.p`
  font-size: 1em;
  color: #666;
`;

const Meta = styled.div`
  display: flex;
  font-size: 0.9em;
  color: #4b5166;
  text-align: center;
  flex-direction: row;
`;

// Componente Card
const Card = ({
  title,
  percentage,
  total,
  valueFooter,
  description,
  isPositive,
  fontSizeTotal = 2,
  colorTotal = '#4B5166',
  titleFooter = '',
  ...props
}) => {
  return (
    <CardContainer style={{ ...props }}>
      <Title>{title}</Title>
      <PercentageChange
        isPositive={isPositive}
        fontSize={fontSizeTotal}
        color={colorTotal}
        style={{ fontSize: `${fontSizeTotal}rem` }}
      >
        {percentage}
      </PercentageChange>
      <TotalValue>{total}</TotalValue>
      <Description>{description}</Description>
      <Meta>
        {titleFooter}
        {valueFooter && titleFooter && ':'} {valueFooter}
      </Meta>
    </CardContainer>
  );
};

export default Card;
