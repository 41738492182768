import styled, { css, keyframes } from 'styled-components';

const jump = keyframes`
    0% {
        padding-top: 0px;
    }
    50% {
        padding-top: 4px;
    }
    100% {
        padding-top: 0px;
    }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 90px;
  align-items: center;
  padding-inline: 30px;
  .title {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
  }
`;

export const ContainerPushNotification = styled.label`
  .footer-push {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .time {
    text-align: left;
    font-size: 13px;
    font-weight: medium;
    letter-spacing: 0px;
    color: #95aac9;
  }
  .link {
    text-align: right;
    text-decoration: underline;
    font-size: 13px;
    font-weight: medium;
    letter-spacing: 0px;
    color: #bdcc30;
  }
  .link:hover {
    opacity: 0.5;
  }
`;

const Label = styled.label`
  width: 100%;
  text-align: left;
  font: normal normal 900 15px/15px Texta;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.palette.words.label.natural};
  opacity: 1;
  margin-left: 12px;
`;

const ListItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const ItemContainer = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 69px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  .title {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 14px;
    font-weight: 900;
    font-family: Texta;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #4b5166;
    height: 70%;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    height: 30%;
    .time {
      text-align: left;
      font-size: 13px;
      font-weight: medium;
      letter-spacing: 0px;
      color: #95aac9;
    }
    .link {
      text-align: right;
      text-decoration: underline;
      font-size: 13px;
      font-weight: medium;
      letter-spacing: 0px;
      color: #bdcc30;
    }
    .link:hover {
      opacity: 0.5;
    }
  }
  .group {
    display: flex;
    padding-left: 20px;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
  }
  .group > span {
    padding-left: 4px;
  }
`;
const menu_St = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

const SeeMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;

  .textSeeMore {
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.words.title.natural};
    cursor: pointer;
  }

  .divIcon {
    background-color: red;
    animation: ${jump} 1s ease infinite;
    position: relative;
    display: flex;
    justify-content: center;

    .icon {
      cursor: pointer;
      position: absolute;
    }
  }
`;

export { Header, Label, ListItemContainer, ItemContainer, menu_St, SeeMore };
