import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';

import { IconButton, Divider, Grid } from '@mui/material';
import {
  Check,
  SaveAlt,
  NavigateBeforeRounded,
  NavigateNextRounded,
  WarningAmber,
} from '@mui/icons-material';
import SelectCliente from 'components/Inputs/SelectCliente';
import SelectInput from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextField';
import Calendar from 'components/Inputs/Calendar';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import ConfirmModal from 'components/ConfirmModal';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import Loading from 'components/Loading';

import { formatNewHourClean } from 'utils/dates';
import { useTheme } from 'styled-components';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { changeHeaderStatus } from 'store/modules/header/actions';
import ImageViolacao from '../../TelemetriaDesvios/ImageViolacao';
import * as services from '../services';
import { getStatusColor, getCriticidadeColor } from '../constants';
import DeleteModal from '../DeleteModal';
import MapDesvio from '../../TelemetriaDesvios/MapDesvio';
import { ExportToPdf } from './Export';
import * as S from './styled';

const DetalheDesvioTelemetria = () => {
  const theme = useTheme();
  const printRefPdf = useRef();
  const params = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const index = query.get('index');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [view, setView] = useState('video');
  const { user } = useSelector(state => state.auth.user);
  const userLevel = user.nivel;
  const userFilials = user.usuario_filiais;
  const { users, clients } = useSelector(state => {
    return state.selects;
  });

  const filter = useSelector(state => {
    return state.filter;
  });

  const _filter = useSelector(state => {
    return state.filterFadigaDesvios;
  });
  const { sortBy, ...filterFadigaDesvios } = _filter;

  const [desvioDeleteModal, setDesvioDeleteModal] = useState(null);

  // Controla modal de confirmação das ações:
  // APROVAR, SALVAR, FINALIZAR E ENVIAR PARA PAD
  const [openModal, setOpenModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectFator, setSelectFator] = useState('');

  const componentRef = useRef();

  const handleChange = (name, value) => {
    setFormData(prev => {
      return { ...prev, [name]: value };
    });
  };

  const {
    data: infraction,
    status,
    error,
    refetch,
  } = useQuery(
    ['desvios_fadiga', params.id],
    () => services.fetchInfraction(params.id, {}),
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: sequenceData,
    isLoading,
    refetch: fetchSequence,
  } = useQuery(
    [
      'desvios_fadiga_sequence',
      params.id,
      index,
      filter,
      filterFadigaDesvios,
      sortBy,
    ],
    () =>
      services.fetchSequence(index, {
        initialDate: filter.initialDate,
        finalDate: filter.finalDate,
        idCliente: filter.client,
        idFilial: filter.filial,
        status: filterFadigaDesvios.status,
        categoria: filterFadigaDesvios.categoria?.split(',').filter(i => !!i),
        criticidade: filterFadigaDesvios.criticidade
          ?.split(',')
          .filter(i => !!i),
        proximoVencimento: filterFadigaDesvios.proximoVencimento,
        idDesvio: filterFadigaDesvios.desvio,
        idResponsavel: filterFadigaDesvios.responsavel,
        sortBy,
      }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (index) fetchSequence();
  }, [index]);

  // Lista de responsáveis
  const responsibles = useMemo(
    () =>
      users
        .filter(i => {
          if (userLevel === 1)
            return (
              i.nivel === 1 ||
              i.id_da_filial === infraction?.viagem?.motorista?.id_da_filial
            );
          if (userFilials.includes(i.id_da_filial)) return true;
          return false;
        })
        .map(usr => ({ name: usr.nome, value: usr.id })),
    [users, userLevel, infraction],
  );

  const handlePrint = useReactToPrint({
    content: () => printRefPdf.current,
    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setLoadingPdf(false);
    },
    copyStyles: true,
    documentTitle: `Desvio_${infraction?.id}`,
  });

  useEffect(() => {
    if (infraction) {
      setFormData({
        id_responsavel: infraction.id_responsavel,
        data_previsao: infraction.data_previsao,
        plano_de_acao: infraction.plano_de_acao,
        fator_de_risco: infraction.fator_de_risco,
        arquivo_observacao: infraction.arquivo_observacao,
        observacao: infraction.observacao,
      });
      if (infraction.fator_de_risco)
        setSelectFator(
          ['Jornada excedida', 'Descanso não cumprido'].includes(
            infraction.fator_de_risco,
          )
            ? infraction.fator_de_risco
            : 'Outros',
        );
      else setSelectFator('');
      dispatch(
        changeHeaderStatus({
          status: infraction.status,
          color: getStatusColor(infraction.status, theme),
          background: `${getStatusColor(infraction.status, theme)}30`,
        }),
      );
    }
  }, [infraction]);

  useEffect(() => {
    if (status === 'error' && error) {
      toast.error(error);
    }
  }, [status, error]);

  // Controla modal de confirmação das ações:
  // APROVAR, SALVAR
  const handleOpenModal = event => {
    let objOpen = {
      event,
      titleIcon: (
        <Check
          sx={{ color: theme.palette.brand.secondary.natural }}
          fontSize="medium"
        />
      ),
      buttonText: 'CONFIRMAR',
    };

    switch (event) {
      case 'SALVAR':
        let hasChange = false;
        for (const key in formData) {
          // Um dos 2 nao é nulo ou "" e são diferentes
          if (
            (formData[key] || infraction[key]) &&
            formData[key] !== infraction[key]
          ) {
            if (
              (typeof formData[key] === 'string' &&
                (formData[key].trim() || key === 'fator_de_risco')) ||
              typeof formData[key] !== 'string'
            )
              hasChange = true;
          }
        }
        if (!hasChange) {
          toast.warning('Nenhuma alteração foi aplicada!');
          return;
        }
        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja salvar desvio?',
          subtitle: 'Revise todos os campos alterados!',
        };
        break;

      case 'APROVAR':
        if (!formData.id_responsavel) {
          toast.warning('O campo Responsável é obrigatório!');
          const element = document.getElementById('responsavel');
          element?.focus();
          return;
        }
        if (!formData.data_previsao) {
          toast.warning('O campo Previsão de Conclusão é obrigatório!');
          const element = document.getElementById('data_previsao');
          element?.click();
          return;
        }
        if (infraction?.plano_de_acao !== formData?.plano_de_acao) {
          toast.warning('Utilize o botão SALVAR para alterar o plano de ação!');
          const element = document.getElementById('btnSalvar');
          element?.focus();
          return;
        }
        if (infraction?.arquivo_observacao !== formData?.arquivo_observacao) {
          toast.warning('Utilize o botão SALVAR para alterar o arquivo!');
          const element = document.getElementById('btnSalvar');
          element?.focus();
          return;
        }
        if (infraction.criticidade == 'GRAVÍSSIMA' && !formData.plano_de_acao) {
          toast.warning(
            'O campo Plano de Ação é obrigatório para desvio de criticidade GRAVÍSSIMA!',
          );
          const element = document.getElementById('plano_de_acao');
          element?.click();
          return;
        }

        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja aprovar desvio?',
          subtitle: 'O desvio será movido para PENDENTE',
        };
        break;

      case 'FINALIZAR':
        if (!formData.id_responsavel) {
          toast.warning('O campo "Responsável" é obrigatório.');
          const element = document.getElementById('id_responsavel');
          element?.focus();
          return;
        }
        if (!infraction.data_previsao) {
          toast.warning('O campo Previsão de Conclusão é obrigatório!');
          const element = document.getElementById('data_previsao');
          element?.click();
          return;
        }
        if (infraction?.plano_de_acao !== formData?.plano_de_acao) {
          toast.warning('Utilize o botão SALVAR para alterar o plano de ação!');
          const element = document.getElementById('btnSalvar');
          element?.focus();
          return;
        }
        if (infraction?.arquivo_observacao !== formData?.arquivo_observacao) {
          toast.warning('Utilize o botão SALVAR para alterar o arquivo!');
          const element = document.getElementById('btnSalvar');
          element?.focus();
          return;
        }
        if (
          infraction.criticidade == 'GRAVÍSSIMA' &&
          !formData.arquivo_observacao
        ) {
          toast.warning(
            'O campo Arquivo Justificativa é obrigatório para desvio de criticidade GRAVÍSSIMA!',
          );
          const element = document.getElementById('arquivo_observacao');
          element?.click();
          return;
        }

        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja finalizar desvio?',
          subtitle: 'O desvio será movido para FINALIZADO',
        };
        break;
    }
    if (objOpen.title) setOpenModal(objOpen);
  };

  // Ações da confirmação do modal:
  // Mesmas das de cima + ALTERAR_DISTRIBUIDORA
  const handleConfirmModal = () => {
    const { event } = openModal;
    switch (event) {
      case 'SALVAR':
        handleSalvar();
        break;

      case 'APROVAR':
        handleAprovar();
        break;

      case 'FINALIZAR':
        handleFinalizar();
        break;
    }
  };

  // ---------------  AÇÕES --------------------------------
  const handleSalvar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const payload = {
      id_responsavel: formData.id_responsavel,
      data_previsao: formData.data_previsao,
      plano_de_acao: formData.plano_de_acao,
      fator_de_risco: formData.fator_de_risco,
      arquivo_observacao: formData.arquivo_observacao,
      observacao: formData.observacao,
    };
    const res = await services.saveInfraction(infraction.id, payload);
    if (res.data?.success) {
      toast.success(res.data?.message);
      refetch();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleAprovar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const data = {
      ids: [infraction.id],
      id_responsavel: formData.id_responsavel,
      data_previsao: formData.data_previsao,
      plano_de_acao: formData.plano_de_acao,
      fator_de_risco: formData.fator_de_risco,
      arquivo_observacao: formData.arquivo_observacao,
      observacao: formData.observacao,
    };
    const res = await services.approveInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      navigate('/fadiga/desvios');
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleFinalizar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const payload = {
      ids: [infraction.id],
      id_responsavel: formData.id_responsavel,
      data_previsao: formData.data_previsao,
      data_encerramento: new Date(),
      plano_de_acao: formData.plano_de_acao,
      fator_de_risco: formData.fator_de_risco,
      arquivo_observacao: formData.arquivo_observacao,
      observacao: formData.observacao,
    };
    const res = await services.finishInfractions(payload);
    if (res.data?.success) {
      toast.success(res.data?.message);
      navigate('/fadiga/desvios');
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleDeletar = async data => {
    setLoadingModal(true);
    const res = await services.deleteInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      navigate('/fadiga/desvios');
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setDesvioDeleteModal(null);
  };

  //  ------------------   RENDERIZACAO --------------------------------
  const renderHeader = () => {
    return (
      <S.SpacedRow>
        <h1>Detalhe do desvio</h1>

        <S.SpacedRow>
          <GhostButton
            startIcon={<HistoricoIcon />}
            size="medium"
            onClick={() => navigate(`/logs/fadiga/desvios/${params.id}`)}
            sx={{ marginRight: '1rem' }}
          >
            HISTÓRICO DE ALTERAÇÕES
          </GhostButton>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={handlePrint}
            style={{ marginRight: '1rem' }}
            loading={loadingPdf}
          >
            IMPRIMIR
          </GhostButton>

          {isLoading ? (
            <S.BoxLoading>
              <Loading />
            </S.BoxLoading>
          ) : (
            <>
              <IconButton
                disabled={!sequenceData?.id_previous}
                onClick={() =>
                  navigate(
                    `/fadiga/desvios/${sequenceData.id_previous}?index=${sequenceData.index_prev}`,
                  )
                }
              >
                <NavigateBeforeRounded />
              </IconButton>

              <IconButton
                disabled={!sequenceData?.id_next}
                onClick={() =>
                  navigate(
                    `/fadiga/desvios/${sequenceData.id_next}?index=${sequenceData.index_next}`,
                  )
                }
              >
                <NavigateNextRounded />
              </IconButton>
            </>
          )}
        </S.SpacedRow>
      </S.SpacedRow>
    );
  };

  // Lado Esquerdo do detalhe do desvio
  // Não editável
  const renderTravelInfo = () => {
    const hasImage = infraction?.viagem?.motorista?.foto
      ? infraction.viagem.motorista.foto
      : false;
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            justifyContent="center"
            position="relative"
          >
            {infraction?.video_violacao && view === 'video' ? (
              <>
                <ImageViolacao infraction={infraction} />
                <S.AbsoluteIconButton
                  sx={{
                    right: -16,
                  }}
                  onClick={() => setView('mapa')}
                >
                  <NavigateNextRounded />
                </S.AbsoluteIconButton>
              </>
            ) : (
              <>
                {infraction?.video_violacao && view === 'mapa' && (
                  <S.AbsoluteIconButton
                    sx={{
                      left: -8,
                    }}
                    onClick={() => setView('video')}
                  >
                    <NavigateBeforeRounded />
                  </S.AbsoluteIconButton>
                )}
                <MapDesvio
                  position={{
                    lat: parseFloat(infraction.latitude),
                    lng: parseFloat(infraction.longitude),
                  }}
                />
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={12} display="flex" flexDirection="row">
            <S.StyledLogoWrapper>
              <S.StyledLogo backgroundImage={hasImage}>
                {!hasImage && <h2>{infraction.viagem?.motorista?.nome[0]}</h2>}
              </S.StyledLogo>
            </S.StyledLogoWrapper>

            <S.StyledTextWrapper>
              <h1>{infraction.viagem?.motorista?.nome?.toLowerCase()}</h1>
              <p>Filial: {infraction.viagem?.motorista?.filial?.nome}</p>
            </S.StyledTextWrapper>
          </Grid>

          <Divider style={{ margin: '10px 0px 10px 10px', width: '100%' }} />

          <Grid item xs={12} sm={8}>
            <TextInput
              id="desvio"
              label="Desvio"
              value={infraction.tipo_violacao?.tipo_violacao_padrao?.tipo}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextInput
              id="data_desvio"
              label="Data do desvio"
              value={formatNewHourClean(infraction.data_violacao, true)}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput
              id="pontos"
              label="Pontuação"
              value={`${infraction.pontos} pontos`}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput
              id="criticidade"
              label="Criticidade"
              value={infraction.criticidade}
              inputProps={{ readOnly: true }}
              variant="filled"
              startIcon={
                <WarningAmber
                  sx={{
                    color: getCriticidadeColor(infraction.criticidade, theme),
                  }}
                />
              }
              fillcolor={getCriticidadeColor(infraction.criticidade, theme)}
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <TextInput
              id="veiculo"
              label="Tipo de Veículo"
              value={
                infraction.viagem?.caminhao?.tipo_veiculo?.tipo ??
                'Não Informado'
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextInput
              id="placa"
              label="Placa"
              value={infraction.viagem?.caminhao?.placa ?? '-'}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              id="local"
              label="Localização"
              value={
                infraction?.endereco
                  ? infraction.endereco
                  : infraction?.latitude
                  ? `lat: ${infraction.latitude}, lng: ${infraction.longitude}`
                  : 'Não informado'
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          {(typeof infraction.carregado === 'boolean' ||
            typeof infraction.pista_molhada === 'boolean') && (
            <Grid item xs={12} sm={12}>
              <TextInput
                id="condicoes"
                label="Condições"
                value={`${
                  infraction.carregado
                    ? 'Veículo carregado; '
                    : infraction.carregado === false
                    ? 'Veículo vazio; '
                    : 'Não Informado; '
                }${
                  infraction.pista_molhada
                    ? 'Pista molhada;'
                    : infraction.pista_molhada === false
                    ? 'Pista seca;'
                    : ''
                }`}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
          )}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  const renderFooter = () => {
    return (
      <Grid item xs={12} sm={12} display="flex" justifyContent="space-between">
        <div>
          {infraction.status === 'ABERTO' && userLevel < 3 && (
            <GhostButton
              size="medium"
              customcolor={theme.palette.semantics.feedback.attention.natural}
              onClick={() => setDesvioDeleteModal({ ...infraction })}
            >
              EXCLUIR
            </GhostButton>
          )}
        </div>
        <div style={{ display: 'flex', gap: '1rem' }}>
          {(infraction.status === 'ABERTO' ||
            infraction.status === 'PENDENTE' ||
            infraction.status === 'FINALIZADO') && (
            <GhostButton
              id="btnSalvar"
              size="medium"
              onClick={() => handleOpenModal('SALVAR')}
            >
              SALVAR
            </GhostButton>
          )}

          {infraction.status === 'ABERTO' && (
            <DefaultButton
              size="medium"
              onClick={() => {
                handleOpenModal('APROVAR');
              }}
            >
              APROVAR
            </DefaultButton>
          )}

          {infraction.status === 'PENDENTE' && (
            <DefaultButton
              size="medium"
              onClick={() => {
                handleOpenModal('FINALIZAR');
              }}
            >
              FINALIZAR
            </DefaultButton>
          )}
        </div>
      </Grid>
    );
  };

  // Lado direito detalhe do desvio
  // Dados editáveis
  const renderInfractionInfo = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <SelectCliente
              value={
                infraction?.viagem?.id_distribuidora ??
                infraction?.id_distribuidora
              }
              setValue={() => {}}
              distributors={clients}
              disabled
            />
          </Grid>

          <Grid item xs={8} sm={8}>
            <SelectInput
              disabled={infraction.status === 'DELETADO'}
              id="responsavel"
              label="Atribuir Responsável"
              value={formData.id_responsavel}
              onChange={e => handleChange('id_responsavel', e.target.value)}
              placeholder="Atribuir por Responsável"
              data={responsibles}
              required
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <Calendar
              disabled={infraction.status === 'DELETADO'}
              id="data_previsao"
              value={formData.data_previsao}
              onChange={newDate => handleChange('data_previsao', newDate)}
              futureDate
              pastDate={false}
              label="Previsão de Conclusão"
              required
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              disabled={infraction.status === 'DELETADO'}
              required={infraction.criticidade === 'GRAVÍSSIMA'}
              id="plano_de_acao"
              placeholder="Definir Plano de Ação"
              label="Plano de Ação"
              onChange={e => handleChange('plano_de_acao', e.target.value)}
              value={formData.plano_de_acao}
              multiline
              rows={4}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputAttachedFile
              disabled={infraction.status === 'DELETADO'}
              required={infraction.criticidade === 'GRAVÍSSIMA'}
              idInput="input_file_detalhe"
              label="Arquivo Justificativa"
              inputLabel="Anexar Evidência"
              fileUrl={formData.arquivo_observacao}
              setFileUrl={value => handleChange('arquivo_observacao', value)}
              fileDir={`/desvios-fadiga/${infraction.id}`}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <SelectInput
              disabled={infraction.status === 'DELETADO'}
              id="select_fator_de_risco"
              label="Fator de Risco"
              value={selectFator}
              onChange={e => {
                setSelectFator(e.target.value);
                handleChange(
                  'fator_de_risco',
                  e.target.value === 'Outros' ? '' : e.target.value,
                );
              }}
              placeholder="Selecione o fator de risco"
              clearLabel="Limpar"
              data={[
                { value: 'Jornada excedida', name: 'Jornada excedida' },
                {
                  value: 'Descanso não cumprido',
                  name: 'Descanso não cumprido',
                },
                {
                  value: 'Outros',
                  name: 'Outros',
                },
              ]}
            />
          </Grid>

          {selectFator === 'Outros' && (
            <Grid item xs={12} sm={12}>
              <TextInput
                disabled={infraction.status === 'DELETADO'}
                id="fator_de_risco"
                placeholder="Insira o Fator de Risco"
                label="Outros fatores"
                onChange={e => handleChange('fator_de_risco', e.target.value)}
                value={formData.fator_de_risco}
              />
            </Grid>
          )}

          {infraction.status === 'DELETADO' && infraction.arquivo_exclusao && (
            <Grid item xs={12} sm={12}>
              <InputAttachedFile
                disabled
                idInput="input_file_exclusao"
                label="Arquivo Justificativa Exclusão"
                inputLabel="Anexar Evidência"
                fileUrl={formData.arquivo_exclusao}
                setFileUrl={value => handleChange('arquivo_exclusao', value)}
                fileDir={`/desvios-fadiga/${infraction.id}`}
              />
            </Grid>
          )}

          {renderFooter()}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  return (
    <>
      {status === 'loading' && <Loading />}
      {status === 'success' && (
        <>
          <S.Main>
            {renderHeader()}
            <Grid container spacing="20px" marginTop="10px" ref={componentRef}>
              <Grid item xs={12} sm={12} md={6}>
                {renderTravelInfo()}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {renderInfractionInfo()}
              </Grid>
            </Grid>
          </S.Main>
          {
            // Este modal controla todas as confirmações
            // openModal.event define a acao em handleConfirmModal
            openModal && (
              <ConfirmModal
                handleClose={() => setOpenModal(null)}
                open={Boolean(openModal)}
                title={openModal.title}
                subtitle={openModal.subtitle}
                titleIcon={openModal.titleIcon}
                buttonText={openModal.buttonText}
                onClick={handleConfirmModal}
                loading={loadingModal}
              />
            )
          }
          {desvioDeleteModal && (
            <DeleteModal
              handleConfirm={handleDeletar}
              desvio={desvioDeleteModal}
              handleClose={() => setDesvioDeleteModal(null)}
              loading={loadingModal}
            />
          )}

          <ExportToPdf
            infraction={infraction}
            printRef={printRefPdf}
            responsavel={users.find(
              user => user.id === infraction?.id_responsavel,
            )}
            cliente={clients.find(
              client => client.id === infraction?.id_distribuidora,
            )}
          />
        </>
      )}
    </>
  );
};

export default DetalheDesvioTelemetria;
