import { Controller } from 'react-hook-form';

import { Tag, TagContainer, TagLabel } from 'components/Tags';

const Criticidade = ({ name, control, disable }) => {
  return (
    <>
      <TagLabel>
        Criticidade
        <span>*</span>
      </TagLabel>
      <TagContainer>
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Campo obrigatório.',
            },
          }}
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <>
              <Tag
                variant="information"
                selected={value === 'PRIMÁRIA'}
                onClick={() => !disable && onChange('PRIMÁRIA')}
              >
                Primária
              </Tag>
              <Tag
                variant="warning"
                selected={value === 'MODERADA'}
                onClick={() => !disable && onChange('MODERADA')}
              >
                Moderada
              </Tag>
              <Tag
                variant="attention"
                selected={value === 'GRAVE'}
                onClick={() => !disable && onChange('GRAVE')}
              >
                Grave
              </Tag>
              <Tag
                variant="attention"
                selected={value === 'GRAVÍSSIMA'}
                onClick={() => !disable && onChange('GRAVÍSSIMA')}
              >
                Gravíssima
              </Tag>
            </>
          )}
        />
      </TagContainer>
    </>
  );
};

export default Criticidade;
