import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0px;
  right: 0px;
  width: 550px;
  height: 100%;
  background-color: #fff;
  margin-right: ${props => props.margin};
  transition: all ease 0.5s;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #95aac94d;
  padding: 0px 30px;

  div {
    display: flex;
    flex-direction: row;
    h2 {
      margin-left: 10px;
    }
  }
`;

export const Main = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 5px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 80px;
  border-top: 1px solid #95aac94d;
  padding: 0px 30px;
`;
