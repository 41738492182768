import React from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { IconButton, SvgIcon } from '@mui/material';
import CheckFiltersCard from 'components/Cards/CheckFiltersCard';
import { useTheme } from 'styled-components';
import * as S from './styled';
import { paletteColors } from '../../../styles/colors';
import { ReactComponent as settingsIcon } from '../../../images/icons/sidebar/inicio.svg';
import Loading from '../../Loading';

const BarGraph = ({
  data,
  colors,
  title,
  legend,
  loading,
  tooltip,
  tabComponent,
  width,
  maxWidth,
  enableLabel = false,
}) => {
  const theme = useTheme();
  const chartColors = paletteColors[colors] || paletteColors.default;

  const CustomizedAxisTick = props => {
    const { x, y, payload } = props;

    let label = payload.value;
    if (label.length > 12) label = `${label.slice(0, 10)}...`;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={10}
          y={10}
          dy={5}
          textAnchor="end"
          fill="#666"
          transform="rotate(-30)"
        >
          {label}
        </text>
      </g>
    );
  };

  const CustomBarLabel = ({ x, y, value, fill }) => (
    <text x={x} y={y} dy={-5} textAnchor="middle" fill={fill} fontSize={14}>
      {value}
    </text>
  );

  return (
    <S.StyledCard maxwidth={maxWidth}>
      <S.Header>
        {typeof title === 'string' ? <h2>{title}</h2> : title}
        {tabComponent}
      </S.Header>

      {data?.length > 0 ? (
        <ResponsiveContainer width={width || '100%'} height="100%">
          {loading ? (
            <S.BoxLoading>
              <Loading />
            </S.BoxLoading>
          ) : (
            <BarChart
              // width={'100%'}
              // height={'100%'}
              data={data}
              margin={{
                top: 20,
                right: 20,
                left: 10,
                bottom: 15,
              }}
            >
              <CartesianGrid
                strokeDasharray="1 0"
                horizontal
                vertical={false}
              />
              <XAxis
                dataKey="name" // default
                axisLine={false}
                tick={<CustomizedAxisTick />}
                height={50}
                interval={0}
              />
              <YAxis
                type="number"
                domain={[0, dataMax => Math.round(dataMax + 0.5)]}
                tickCount={8}
                allowDecimals
                axisLine={false}
              />
              {tooltip && (
                <Tooltip
                  cursor={{
                    fill: theme.palette.semantics.feedback.unknown.light,
                    opacity: '0.4',
                  }}
                />
              )}
              {legend && <Legend />}
              <Bar
                dataKey="value" // default
                radius={15}
                barSize={10}
                onMouseOver={false}
              >
                {data.map((entry, index) => (
                  <>
                    <Cell
                      key={`${entry.x}`}
                      fill={chartColors[index % chartColors.length]}
                    />
                    {enableLabel && (
                      <LabelList
                        dataKey="value"
                        content={
                          <CustomBarLabel
                            fill={chartColors[index % chartColors.length]}
                          />
                        }
                      />
                    )}
                  </>
                ))}
              </Bar>
            </BarChart>
          )}
        </ResponsiveContainer>
      ) : (
        <CheckFiltersCard height="200px" marginTop="45px" />
      )}
    </S.StyledCard>
  );
};

export default BarGraph;
