/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// Components
import DataHour from 'components/Inputs/DataHour';
import TextInput from 'components/Inputs/TextField';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';

// Components MUI
import {
  Divider,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';

// Utils
import { addDays } from 'date-fns';
import { formatNewDate, formatNewHour } from 'utils/dates';

export const CaePrint = ({ cae, posto, printRef }) => {
  const theme = useTheme();

  //  ---------------------- RENDER --------------------------------

  // Lado Esquerdo
  const renderDriverInfo = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12} display="flex" justifyContent="center">
            <S.ImageContainer>
              {cae?.imagem_cae && <img src={cae?.imagem_cae} />}
              {!cae?.imagem_cae && (
                <div className="content">
                  <NoPhotographyOutlinedIcon
                    sx={{ fontSize: 80 }}
                    htmlColor={theme.palette.words.subtitle.natural}
                  />
                  <p>Sem Imagem</p>
                </div>
              )}
            </S.ImageContainer>
          </Grid>

          <Divider style={{ margin: '10px 10px 10px 10px', width: '100%' }} />

          <Grid item xs={6} sm={6}>
            <TextInput
              label="Motorista"
              value={cae?.nome_motorista || ''}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextInput
              id="placa"
              label="Placa do Veículo"
              value={cae?.placa || ''}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="cliente"
              label="Cliente"
              value={posto?.distribuidora?.nome}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="transportadora"
              label="Transportadora"
              value={cae?.usuario?.filial?.empresa?.nome} // TODO! Nao tem
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="filial"
              label="Filial"
              value={cae?.usuario?.filial?.nome} // TODO! Nao tem
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        </Grid>
      </S.ColumnWrapper>
    );
  };

  // Lado direito
  const renderCaeInfo = () => {
    const severidade = [1, 2, 3, 4];

    const getStatusColor = status => {
      switch (status) {
        case 'ABERTO':
          return theme.palette.semantics.feedback.warning.natural;
        case 'PENDENTE':
          return theme.palette.semantics.feedback.warning.natural;
        case 'FINALIZADO':
          return theme.palette.semantics.feedback.success.natural;
        case 'DELETADO':
          return theme.palette.semantics.feedback.attention.natural;
      }
    };

    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing="24px">
          <Grid item xs={6} sm={6}>
            <TextInput
              id="titulo"
              label="Título do Evento"
              value={cae?.questao?.abreviacao}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <TextInput
              id="codigo"
              label="Código"
              value={cae?.questao?.id}
              InputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="situacao"
              label="Situação Atual"
              value={cae?.status}
              variant="filled"
              fillcolor={getStatusColor(cae?.status)}
              inputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="reportadoem"
              label="Reportado em"
              value={cae?.createdAt && formatNewDate(cae?.createdAt)}
              inputProps={{ readOnly: true }}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <DataHour
              disabled
              label="Data e hora do Ocorrido"
              value={cae?.data_ocorrencia}
              variant="filled"
              required
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              id="endereco"
              label="Localização / Endereço"
              value={posto?.endereco}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <h3>Severidade do Evento</h3>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={cae?.questao?.severidade}
                style={{ justifyContent: 'space-between', paddingTop: '15px' }}
              >
                {severidade.map(item => (
                  <FormControlLabel
                    key={`${item}`}
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="consequencia"
              label="Consequência"
              value={cae?.questao?.consequencia}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="data_limite"
              label="Data Limite Adequação"
              value={
                cae?.data_ocorrencia &&
                formatNewHour(
                  addDays(
                    new Date(cae?.data_ocorrencia),
                    cae?.questao?.prazo_bloqueio,
                  ),
                )
              }
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              label="Descrição da Ocorrência"
              placeholder="Observação da Ocorrência"
              multiline
              rows={4}
              value={cae?.observacao_cae || ''}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputAttachedFile
              idInput="input_file_layout_evidence"
              label="Evidência CAE"
              inputLabel="Nenhum arquivo"
              fileUrl={cae?.arquivo_evidencia}
            />
          </Grid>
        </Grid>
      </S.ColumnWrapper>
    );
  };

  const renderPage = () => {
    return (
      <>
        <S.Main>
          <Grid
            container
            spacing="20px"
            marginTop="10px"
            ref={printRef}
            style={{
              padding: '20px',
            }}
          >
            <S.Header>
              <h1>Comunicação de Atendimento de Emergência</h1>

              <div className="infos">
                ID: #{cae?.id} | Data: {formatNewDate(new Date())}
              </div>
            </S.Header>
            {renderDriverInfo()}
            {renderCaeInfo()}
          </Grid>
        </S.Main>
      </>
    );
  };

  return <div style={{ display: 'none' }}>{renderPage()}</div>;
};
