import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  status: '',
  agregado: '',
};

export default function filterDriverProvider(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_DRIVER: {
        draft.status = action.payload.query.status;
        draft.agregado = action.payload.query.agregado;
        break;
      }
      default:
    }
  });
}
