/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';

// Components
import RightModal from 'components/RightModal';
import Input from 'components/Inputs/TextField';
import ConfirmModal from 'components/ConfirmModal';
import SelectInput from 'components/Inputs/Select';

// Components MUI
import { Icon, Grid } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Utils
import { celMask } from 'utils/masks';
import { firestorage } from 'utils/firebase';
import { formaterFields, validateFields, formatInitialData } from './actions';
import { Switch } from 'components/Inputs/Switch';

// Service
import * as serives from '../../services';
import { validateCPF } from 'utils/helpers';

const defaultData = {
  nome: '',
  cpf: '',
  matricula: '',
  funcao: '',
  genero: null,
  data_de_nascimento: '',
  status: 'ATIVO',
  data_de_admissao: '',
  id_da_filial: '',
  id_da_distribuidora: null,
  empresa_terceira: '',
  cnh: '',
  primeira_cnh: '',
  validade_cnh: '',
  estado_civil: null,
  fumante: null,
  celular: '',
  agregado: 'NAO',
  foto: null,
};

export const ModalMotoristas = ({
  open,
  dataEdit,
  handleClose,
  setDataEdit,
  setNewData,
  activedFilials,
}) => {
  const { clients } = useSelector(state => state.selects);

  const inputFile = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(defaultData);

  const [confirmAdd, setConfirmAdd] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);

  useEffect(() => {
    if (!dataEdit) {
      setData(defaultData);
    } else {
      let newData = { ...dataEdit };
      for (let key in newData) {
        const val = formatInitialData(key, newData[key]);
        newData[key] = val;
      }
      setData(newData);
    }
  }, [dataEdit]);

  // --------------------------------UPLOAD IMAGE--------------------------------//

  const updateDriverImage = async () => {
    const file = inputFile.current.files[0];
    if (file) {
      try {
        setLoading(true);
        const dh = new Date().toDateString();
        if (!file) return toast.error('Não foi possível enviar sua imagem');

        const uploadTask = firestorage
          .ref(`/motoristas/${dh}_${file.name}`)
          .put(file);
        uploadTask.on(
          'state_changed',
          snapShot => {},
          err => {
            console.log('Err', err);
          },
          () => {
            firestorage
              .ref(`/motoristas/`)
              .child(`${dh}_${file.name}`)
              .getDownloadURL()
              .then(fireBaseUrl => {
                setData({ ...data, foto: fireBaseUrl });
              });
          },
        );
      } catch (error) {
        toast.error('Não foi possível enviar sua imagem');
      }
      setLoading(false);
    }
  };

  // --------------------------------HANDLE CHANGE--------------------------------//
  const handleChange = e => {
    let { name, value } = e.target;
    const val = formaterFields(name, value);
    setData({ ...data, [name]: val });
  };

  const changeStatus = () => {
    setData({ ...data, status: data.status === 'ATIVO' ? 'INATIVO' : 'ATIVO' });
  };

  // --------------------------------SAVE ACTIONS--------------------------------//
  const handleSave = async () => {
    const err = validateFields(data);
    if (err) return toast.error('Preencha todos os campos obrigatórios');

    const validatedCPF = validateCPF(data.cpf);
    if (!validatedCPF) return toast.warning('CPF Inválido');

    dataEdit ? setConfirmEdit(true) : setConfirmAdd(true);
  };

  const handleCreate = async () => {
    setLoading(true);
    const res = await serives.createDriver(data);
    setLoading(false);
    setConfirmAdd(false);
    if (res.data.success) {
      toast.success(res.data.message);
      setNewData(res.data.data);
      handleClose();
    } else {
      toast.error(res.data.message);
    }
  };

  const handleEdit = async () => {
    setLoading(true);
    setNewData({ ...data, id_local: dataEdit.id_local });
    const res = await serives.updateDriver(dataEdit.id, data);
    setLoading(false);
    setConfirmEdit(false);
    if (res.data.success) {
      toast.success(res.data.message);
      setNewData(res.data.data);
      handleClose();
    } else {
      toast.error(res.data.message);
    }
  };

  // --------------------------------RENDER--------------------------------//
  return (
    <>
      <RightModal
        open={open}
        title={dataEdit ? 'Editar Motorista' : 'Cadastrar Motorista'}
        icon={() =>
          dataEdit ? (
            <EditOutlined htmlColor={colors.greenMain} />
          ) : (
            <AddCircleOutlineOutlinedIcon htmlColor={colors.greenMain} />
          )
        }
        handleClose={() => {
          handleClose();
          setDataEdit(null);
        }}
        onConfirm={handleSave}
        noClose
      >
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <S.Section>INFORMAÇÕES DE PERFIL</S.Section>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label>
                <input
                  type="file"
                  multiple={false}
                  ref={inputFile}
                  onChange={updateDriverImage}
                  disabled={loading}
                />
                <div className="imgArea">
                  {data.foto && (
                    <span className="borderImage">
                      <img src={data.foto} />
                    </span>
                  )}

                  {!data.foto && <span className="empty">{data.nome[0]}</span>}
                </div>
                <div className="textArea">
                  <EditOutlinedIcon htmlColor="#656E8C" />
                  <span>Alterar Foto</span>
                </div>
              </label>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Input
                required
                label="Nome"
                placeholder="Nome"
                value={data.nome || ''}
                onChange={handleChange}
                name="nome"
                id="nome"
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Input
                required
                label="CPF"
                placeholder="CPF"
                value={data.cpf || ''}
                onChange={handleChange}
                name="cpf"
                id="cpf"
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <SelectInput
                required
                handleChange={handleChange}
                name="id_da_filial"
                label="Filial"
                data={[
                  { name: 'Selecione uma filial', value: '' },
                  ...activedFilials.map(f => ({
                    name: f.nome,
                    value: f.id,
                  })),
                ]}
                value={data.id_da_filial}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Input
                required
                label="Matrícula"
                placeholder="Matrícula"
                value={data.matricula || ''}
                onChange={handleChange}
                name="matricula"
                id="matricula"
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <SelectInput
                required
                handleChange={handleChange}
                name="genero"
                label="Gênero"
                data={[
                  { name: 'Feminino', value: 'Feminino' },
                  { name: 'Masculino', value: 'Masculino' },
                  { name: 'Outro', value: 'Outro' },
                ]}
                value={data.genero}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Input
                required
                label="Função"
                placeholder="Função"
                value={data.funcao || ''}
                onChange={handleChange}
                name="funcao"
                id="funcao"
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Input
                required
                label="Celular"
                placeholder="(00)-00000-0000"
                value={celMask(data.celular || '')}
                onChange={handleChange}
                name="celular"
                id="celular"
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Input
                required
                label="Data de nascimento"
                placeholder="dd/mm/aaaa"
                value={data.data_de_nascimento || ''}
                onChange={handleChange}
                name="data_de_nascimento"
                id="data_de_nascimento"
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Input
                required
                label="Data de admissão"
                placeholder="dd/mm/aaaa"
                value={data.data_de_admissao || ''}
                onChange={handleChange}
                name="data_de_admissao"
                id="data_de_admissao"
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <SelectInput
                required
                handleChange={handleChange}
                name="agregado"
                label="Agregado"
                data={[
                  { name: 'SIM', value: 'SIM' },
                  { name: 'NÃO', value: 'NÃO' },
                ]}
                value={
                  data.agregado === true || data.agregado === 'SIM'
                    ? 'SIM'
                    : 'NÃO'
                }
              />
            </Grid>

            {dataEdit && (
              <Grid
                item
                xs={6}
                sm={6}
                display="flex"
                alignContent="center"
                marginTop="25px"
              >
                <Switch
                  check={data.status === 'ATIVO'}
                  setCheckbox={changeStatus}
                  textOn="Motorista Ativo"
                  textOff="Motorista Inativo"
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <S.Section>INFORMAÇÕES EXTRAS (Não obrigatórias)</S.Section>
            </Grid>

            <Grid item xs={6} sm={6}>
              <Input
                label="Empresa terceira"
                placeholder="Empresa terceira"
                value={data.empresa_terceira || null}
                onChange={handleChange}
                name="empresa_terceira"
                id="empresa_terceira"
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Input
                label="CNH"
                placeholder="CNH"
                value={data.cnh || null}
                onChange={handleChange}
                name="cnh"
                id="cnh"
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Input
                label="Validade CNH"
                placeholder="dd/mm/aaaa"
                value={data.validade_cnh || null}
                onChange={handleChange}
                name="validade_cnh"
                id="validade_cnh"
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Input
                label="Primeira CNH"
                placeholder="dd/mm/aaaa"
                value={data.primeira_cnh || null}
                onChange={handleChange}
                name="primeira_cnh"
                id="primeira_cnh"
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <SelectInput
                handleChange={handleChange}
                name="estado_civil"
                label="Estado Civil"
                data={[
                  { name: 'Solteiro(a)', value: 'Solteiro' },
                  { name: 'Casado(a)', value: 'Casado' },
                  { name: 'Separado(a)', value: 'Separado' },
                  { name: 'Divorciado(a)', value: 'Divorciado' },
                  { name: 'Viúvo(a)', value: 'Viúvo' },
                  { name: 'União Estavel', value: 'União Estavel' },
                ]}
                value={data.estado_civil}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <SelectInput
                handleChange={handleChange}
                name="fumante"
                label="Fumante"
                data={[
                  { name: 'SIM', value: 'SIM' },
                  { name: 'NÃO', value: 'NÃO' },
                ]}
                value={data.fumante}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <SelectInput
                handleChange={handleChange}
                name="id_da_distribuidora"
                label="Cliente Exclusivo"
                clearLabel="Limpar"
                data={clients.map(item => {
                  return { name: item.nome, value: item.id };
                })}
                value={data.id_da_distribuidora}
              />
            </Grid>
          </Grid>
        </S.Main>
      </RightModal>

      {/* Confirmação de edição */}
      <ConfirmModal
        open={confirmEdit}
        handleClose={() => setConfirmEdit(false)}
        title="Deseja editar o motorista?"
        titleIcon={
          <Icon sx={{ color: colors.greenMain }} fontSize="medium">
            add_circle_outline
          </Icon>
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => handleEdit()}
        loading={loading}
      />

      {/* Confirmação de adição */}
      <ConfirmModal
        open={confirmAdd}
        handleClose={() => setConfirmAdd(false)}
        title="Deseja confirmar criação do motorista?"
        titleIcon={
          <EditOutlined sx={{ color: colors.greenMain }} fontSize="medium" />
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => handleCreate()}
        loading={loading}
      />
    </>
  );
};
