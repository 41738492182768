import * as types from './types';

export function setFilter(payload) {
  return {
    type: types.SET_FILTER,
    payload,
  };
}

export function setSortBy(payload) {
  return {
    type: types.SET_SORT_BY,
    payload,
  };
}

export function ResetFilter() {
  return {
    type: types.RESET_FILTER,
  };
}
