/*
Componente DateRangePicker editavel de 'react-date-range'
Recebe do redux:
filter.initialDate e filter.finalDate: tipo Date (nunca deve ser null)
setDilter: dispach(setFilter(...))

anchorEl: controla modal
range: estado do vetor de objetos do componente, onde range[0]:
startDate: new Date(filter.initialDate),
endDate: new Date(filter.finalDate),
key: 'selection'
*/

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import {
  setFilterProvider,
  setInitialStateDateProvider,
} from 'store/modules/provider/filterProvider/actions';
import { setFilter, setInitialStateDate } from 'store/modules/filter/actions';
import { ptBR } from 'date-fns/locale';
import { startOfDay, endOfDay } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import React, { useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import { ArrowForward, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { addDays } from 'date-fns';
import { defaultStaticRanges } from './defined';
import { formatShortDate } from '../../../utils/dates';
import * as S from './styled';

import DefaultButton from '../../Buttons/Default';
import GhostButton from '../../Buttons/Ghost';

// const maxDate = new Date()
// const minDate = new Date(maxDate.getFullYear(), maxDate.getMonth() - 11, 1)

const today = new Date();
const firstDayMonth = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0);

const CustomDateRangePicker = ({ height }) => {
  const theme = useTheme();
  const [maxDate, setMaxDate] = useState(new Date());
  const [minDate, setMinDate] = useState(
    new Date(maxDate.getFullYear(), maxDate.getMonth() - 11, 1),
  );

  // Redux
  const filter = useSelector(state => {
    return state.filter;
  });
  const filterProvider = useSelector(state => {
    return state.filterProvider;
  });
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const { provider } = user;
  const dispatch = useDispatch();

  // Controle do modal
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Estado do componente
  // Array de ranges (usado apenas o range[0])
  const [range, setRange] = useState(
    provider
      ? [
          {
            startDate: new Date(filterProvider.initialDate),
            endDate: new Date(filterProvider.finalDate),
            key: 'selection',
          },
        ]
      : [
          {
            startDate: new Date(filter.initialDate),
            endDate: new Date(filter.finalDate),
            key: 'selection',
          },
        ],
  );

  const handleDate = (initialDate, finalDate, dateLabel) => {
    dispatch(
      provider
        ? setFilterProvider({ initialDate, finalDate, dateLabel })
        : setFilter({ initialDate, finalDate, dateLabel }),
    );
  };

  // Atualiza filtro ao recarregar página
  useEffect(() => {
    dispatch(provider ? setInitialStateDateProvider() : setInitialStateDate());
  }, [dispatch]);

  // Atualiza componente com valor do redux
  useEffect(() => {
    if (
      range &&
      anchorEl &&
      String(filter.initialDate) != String(range[0].startDate) &&
      String(filter.finalDate) != String(range[0].endDate)
    ) {
      if (provider) {
        setRange([
          {
            startDate: new Date(filterProvider.initialDate),
            endDate: new Date(filterProvider.finalDate),
            key: 'selection',
          },
        ]);
      } else {
        setRange([
          {
            startDate: new Date(filter.initialDate),
            endDate: new Date(filter.finalDate),
            key: 'selection',
          },
        ]);
      }
    }
  }, [filter, filterProvider, anchorEl]);

  // Controle do modal
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  // Atualiza filtro redux apenas quando fecha o modal
  const handleClose = () => {
    setAnchorEl(null);
  };

  const diffInMonths = (end, start) => {
    const timeDiff = Math.abs(end.getTime() - start.getTime());
    return Math.round(timeDiff / (2e3 * 3600 * 365.25));
  };

  // Valor recebido pelo componente: Range[0] = [value]
  const handleChange = value => {
    const { startDate, endDate } = value;
    const res = diffInMonths(startDate, endDate);
    if (res <= 12)
      setRange([
        {
          ...value,
          endDate: value.isLabel
            ? value.endDate
            : addDays(new Date(endDate).setHours(23, 59, 59), 0),
          isLabel: false,
        },
      ]);
    else {
      toast.warning(
        'Você não pode selecionar um intervalo superior a 12 meses!',
      );
    }
  };

  // Aplica valor atual ao redux
  const handleApply = () => {
    // Verifica se é uma data predefinida
    const dateLabelFinded = defaultStaticRanges.find(item => {
      const newRange = item.range();
      if (
        newRange.endDate === range[0].endDate &&
        newRange.startDate === range[0].startDate
      )
        return true;
      return false;
    });
    // Atribui a label caso for selecionado algum
    let dateLabel = '';
    if (dateLabelFinded) dateLabel = dateLabelFinded.label;

    const start = range[0].startDate;
    const end = range[0].endDate;
    handleDate(start, end, dateLabel);
    handleClose();
    toast.success('Filtro de data aplicado com sucesso!');
  };

  // String do botao do filtro
  const getDateString = () => {
    let str = null;
    if (provider) {
      str = `${formatShortDate(filterProvider.initialDate)} - ${formatShortDate(
        filterProvider.finalDate,
      )}`;
    } else {
      str = `${formatShortDate(filter.initialDate)} - ${formatShortDate(
        filter.finalDate,
      )}`;
    }
    return str;
  };

  return (
    <div>
      <S.StyledButton
        textcolor={
          anchorEl
            ? theme.palette.brand.secondary.natural
            : theme.palette.words.subtitle.natural
        }
        backgroundcolor="transparent"
        endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
        height={height ?? '45px'}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        data-testid="custom-date-range-picker"
      >
        {getDateString()}
      </S.StyledButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <S.Main data-testid="date-range-picker">
          <DateRangePicker
            // minDate={minDate}
            // maxDate={maxDate}

            onChange={item => handleChange(item.selection)}
            showMonthAndYearPickers={false}
            showMonthArrow // default
            showDateDisplay={false}
            showPreview // default
            dayDisplayFormat="d" // default
            weekdayDisplayFormat="EEEEEE"
            dateDisplayFormat="MMM d, yyyy" // default
            monthDisplayFormat="MMMM"
            rangeColors={[theme.palette.brand.secondary.natural]}
            moveRangeOnFirstSelection={false} // default
            locale={ptBR}
            months={2}
            ranges={range}
            direction="horizontal"
            preventSnapRefocus
            calendarFocus="backwards"
            inputRanges={[]}
            staticRanges={defaultStaticRanges}
          />

          <S.Footer>
            <S.ItemsWrapper>
              <S.DateDisplay data-testid="start-date-display">
                <h3>{range[0].startDate.toLocaleDateString()}</h3>
              </S.DateDisplay>

              <ArrowForward />

              <S.DateDisplay data-testid="end-date-display">
                <h3>{range[0].endDate.toLocaleDateString()}</h3>
              </S.DateDisplay>
            </S.ItemsWrapper>

            <S.ItemsWrapper width="350px">
              <GhostButton onClick={handleClose} size="medium">
                CANCELAR
              </GhostButton>

              <DefaultButton onClick={handleApply} size="medium">
                APLICAR DATA
              </DefaultButton>
            </S.ItemsWrapper>
          </S.Footer>
        </S.Main>
      </Popover>
    </div>
  );
};

export default CustomDateRangePicker;
