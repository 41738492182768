import { Modal } from '@mui/material';
import styled, { css } from 'styled-components';

export const ModalMUI = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Container = styled.div`
  ${({ theme, animation }) => css`
    max-width: 900px;
    width: 90%;
    min-height: 500px;
    height: auto;
    transition: all 0.2s ease-in-out;
    margin-bottom: ${animation ? '0px' : '-100%'};
    /* background: transparent linear-gradient(359deg, #3f4457 0%, #313644 100%) 0%
      0% no-repeat padding-box; */
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px ${theme.palette.system.shadow}; */
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const CloseArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    max-width: 900px;
    width: 90%;
    justify-content: flex-end;
    margin-bottom: 10px;

    .MuiSvgIcon-root {
      color: ${theme.palette.system.white};

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
  `}
`;

export const Midia = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`;
