import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  font: normal normal 900 24px/30px Texta;
  letter-spacing: 0px;
  color: #4b5166;
`;

export const DesvioItem = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 16px;

  .title {
    font: normal normal bold 16px/20px Texta;
    letter-spacing: 0px;
    white-space: nowrap;
    color: #4b5166;
  }
  .desvios-padroes {
    text-align: left;
    letter-spacing: 0px;
    color: #95aac9;
  }
  .desvios-padroes > span {
    font-weight: bold;
  }
`;
