import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';

import { IconButton, Divider, Grid } from '@mui/material';
import {
  SaveAlt,
  NavigateBeforeRounded,
  NavigateNextRounded,
  WarningAmber,
} from '@mui/icons-material';
import TextInput from 'components/Inputs/TextField';
import GhostButton from 'components/Buttons/Ghost';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import Loading from 'components/Loading';

import { formatNewDate, formatNewHourClean } from 'utils/dates';
import { useTheme } from 'styled-components';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { changeHeaderStatus } from 'store/modules/header/actions';
import ImageViolacao from 'pages/TelemetriaDesvios/ImageViolacao';
import * as services from '../services';
import { getStatusColor, getCriticidadeColor } from '../constants';
import MapDesvio from '../../../TelemetriaDesvios/MapDesvio';
import { ExportToPdf } from './Export';
import * as S from './styled';

const DetalheDesvioFadiga = () => {
  const theme = useTheme();
  const printRefPdf = useRef();
  const params = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const index = query.get('index');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [view, setView] = useState('video');

  const filter = useSelector(state => {
    return state.filterProvider;
  });

  const _filter = useSelector(state => {
    return state.filterFadigaDesviosProvider;
  });
  const { sortBy, ...filterFadigaDesvios } = _filter;

  const [loadingPdf, setLoadingPdf] = useState(false);
  const [formData, setFormData] = useState({});

  const componentRef = useRef();

  const handleChange = (name, value) => {
    setFormData(prev => {
      return { ...prev, [name]: value };
    });
  };

  const {
    data: infraction,
    status,
    error,
  } = useQuery(
    ['desvios_fadiga', params.id],
    () => services.fetchInfraction(params.id, {}),
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: sequenceData,
    isLoading,
    refetch: fetchSequence,
  } = useQuery(
    [
      'desvios_fadiga_sequence',
      params.id,
      index,
      filter,
      filterFadigaDesvios,
      sortBy,
    ],
    () =>
      services.fetchSequence(index, {
        initialDate: filter.initialDate,
        finalDate: filter.finalDate,
        idCliente: filter.empresas?.split(',').filter(i => !!i),
        idFilial: filter.filial?.split(',').filter(i => !!i),
        motoristas: filter.motoristas,
        status: filterFadigaDesvios.status,
        categoria: filterFadigaDesvios.categoria.split(',').filter(i => !!i),
        criticidade: filterFadigaDesvios.criticidade
          ?.split(',')
          .filter(i => !!i),
        proximoVencimento: filterFadigaDesvios.proximoVencimento,
        idDesvio: filterFadigaDesvios.desvio,
        sortBy,
      }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (index) fetchSequence();
  }, [index]);

  const handlePrint = useReactToPrint({
    content: () => printRefPdf.current,
    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setLoadingPdf(false);
    },
    copyStyles: true,
    documentTitle: `Desvio_${infraction?.id}`,
  });

  useEffect(() => {
    if (infraction) {
      setFormData({
        id_responsavel: infraction.id_responsavel,
        data_previsao: infraction.data_previsao,
        plano_de_acao: infraction.plano_de_acao,
        fator_de_risco: infraction.fator_de_risco,
        arquivo_observacao: infraction.arquivo_observacao,
        observacao: infraction.observacao,
      });
      dispatch(
        changeHeaderStatus({
          status: infraction.status,
          color: getStatusColor(infraction.status, theme),
          background: `${getStatusColor(infraction.status, theme)}30`,
        }),
      );
    }
  }, [infraction]);

  useEffect(() => {
    if (status === 'error' && error) {
      toast.error(error);
    }
  }, [status, error]);

  //  ------------------   RENDERIZACAO --------------------------------
  const renderHeader = () => {
    return (
      <S.SpacedRow>
        <h1>Detalhe do desvio</h1>

        <S.SpacedRow>
          <GhostButton
            startIcon={<HistoricoIcon />}
            size="medium"
            onClick={() => navigate(`/logs/fadiga/desvios/${params.id}`)}
            sx={{ marginRight: '1rem' }}
          >
            HISTÓRICO DE ALTERAÇÕES
          </GhostButton>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={handlePrint}
            style={{ marginRight: '1rem' }}
            loading={loadingPdf}
          >
            IMPRIMIR
          </GhostButton>

          {isLoading ? (
            <S.BoxLoading>
              <Loading />
            </S.BoxLoading>
          ) : (
            <>
              <IconButton
                disabled={!sequenceData?.id_previous}
                onClick={() =>
                  navigate(
                    `/fadiga/desvios/${sequenceData.id_previous}?index=${sequenceData.index_prev}`,
                  )
                }
              >
                <NavigateBeforeRounded />
              </IconButton>

              <IconButton
                disabled={!sequenceData?.id_next}
                onClick={() =>
                  navigate(
                    `/fadiga/desvios/${sequenceData.id_next}?index=${sequenceData.index_next}`,
                  )
                }
              >
                <NavigateNextRounded />
              </IconButton>
            </>
          )}
        </S.SpacedRow>
      </S.SpacedRow>
    );
  };

  // Lado Esquerdo do detalhe do desvio
  // Não editável
  const renderTravelInfo = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            justifyContent="center"
            position="relative"
          >
            {infraction?.viagem?.motorista.assinatura_adesao &&
            infraction?.video_violacao &&
            view === 'video' ? (
              <>
                <ImageViolacao infraction={infraction} />
                <S.AbsoluteIconButton
                  sx={{
                    right: -16,
                  }}
                  onClick={() => setView('mapa')}
                >
                  <NavigateNextRounded />
                </S.AbsoluteIconButton>
              </>
            ) : (
              <>
                {infraction?.video_violacao && view === 'mapa' && (
                  <S.AbsoluteIconButton
                    sx={{
                      left: -8,
                    }}
                    onClick={() => setView('video')}
                  >
                    <NavigateBeforeRounded />
                  </S.AbsoluteIconButton>
                )}
                <MapDesvio
                  position={{
                    lat: parseFloat(infraction.latitude),
                    lng: parseFloat(infraction.longitude),
                  }}
                />
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={12} display="flex" flexDirection="row">
            <S.StyledLogoWrapper>
              <S.StyledLogo>
                <h2>
                  {infraction.viagem?.motorista?.filial?.empresa?.nome[0]}
                </h2>
              </S.StyledLogo>
            </S.StyledLogoWrapper>

            <S.StyledTextWrapper>
              <h1>
                {infraction.viagem?.motorista?.filial?.empresa?.nome?.toLowerCase()}
              </h1>
              <p>Filial: {infraction.viagem?.motorista?.filial?.nome}</p>
            </S.StyledTextWrapper>
          </Grid>

          <Divider style={{ margin: '10px 0px 10px 10px', width: '100%' }} />

          <Grid item xs={12} sm={8}>
            <TextInput
              id="desvio"
              label="Desvio"
              value={infraction.tipo_violacao?.tipo_violacao_padrao?.tipo}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextInput
              id="data_desvio"
              label="Data do desvio"
              value={formatNewHourClean(infraction.data_violacao, true)}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput
              id="pontos"
              label="Pontuação"
              value={`${infraction.pontos} pontos`}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput
              id="criticidade"
              label="Criticidade"
              value={infraction.criticidade}
              inputProps={{ readOnly: true }}
              variant="filled"
              startIcon={
                <WarningAmber
                  sx={{
                    color: getCriticidadeColor(infraction.criticidade, theme),
                  }}
                />
              }
              fillcolor={getCriticidadeColor(infraction.criticidade, theme)}
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <TextInput
              id="veiculo"
              label="Tipo de Veículo"
              value={
                infraction.viagem?.caminhao?.tipo_veiculo?.tipo ??
                'Não Informado'
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextInput
              id="placa"
              label="Placa"
              value={infraction.viagem?.caminhao?.placa ?? '-'}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              id="local"
              label="Localização"
              value={
                infraction?.endereco
                  ? infraction.endereco
                  : infraction?.latitude
                  ? `lat: ${infraction.latitude}, lng: ${infraction.longitude}`
                  : 'Não informado'
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          {(typeof infraction.carregado === 'boolean' ||
            typeof infraction.pista_molhada === 'boolean') && (
            <Grid item xs={12} sm={12}>
              <TextInput
                id="condicoes"
                label="Condições"
                value={`${
                  infraction.carregado
                    ? 'Veículo carregado; '
                    : infraction.carregado === false
                    ? 'Veículo vazio; '
                    : 'Não Informado; '
                }${
                  infraction.pista_molhada
                    ? 'Pista molhada;'
                    : infraction.pista_molhada === false
                    ? 'Pista seca;'
                    : ''
                }`}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
          )}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  // Lado direito detalhe do desvio
  // Dados editáveis
  const renderInfractionInfo = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div className="imageArea">
              {infraction.viagem?.distribuidora?.logo_url && (
                <img src={infraction.viagem?.distribuidora.logo_url} />
              )}
              {!infraction.viagem?.distribuidora?.logo_url && (
                <span className="letters">
                  {infraction.viagem?.distribuidora?.nome[0]}
                </span>
              )}
            </div>
            <span className="distributorName">
              {infraction.viagem?.distribuidora?.nome}
            </span>
          </Grid>

          <Grid item xs={8} sm={8}>
            <TextInput
              id="responsavel"
              label="Responsável"
              value={infraction.responsavel?.nome}
              onChange={e => {}}
              placeholder="Responsável não definido"
              disabled
              props={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="data_previsao"
              label="Previsão de Conclusão"
              value={formatNewDate(infraction.data_previsao)}
              onChange={() => {}}
              placeholder="Sem Previsão"
              disabled
              props={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              id="plano_de_acao"
              placeholder="Plano de Ação Não Definido"
              label="Plano de Ação"
              onChange={() => {}}
              value={infraction.plano_de_acao}
              multiline
              rows={2}
              props={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              id="fator_de_risco"
              label="Fator de risco"
              value={infraction.fator_de_risco}
              onChange={() => {}}
              placeholder="Fator não definido"
              disabled
              props={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputAttachedFile
              idInput="input_file_detalhe"
              disabled
              label="Arquivo Justificativa"
              inputLabel="Anexar Evidência"
              fileUrl={formData.arquivo_observacao}
              setFileUrl={value => handleChange('arquivo_observacao', value)}
              fileDir={`/desvios-fadiga/${infraction.id}`}
              // deleteFromStorage nao apagar arquivo antigo
            />
          </Grid>
        </Grid>
      </S.ColumnWrapper>
    );
  };

  return (
    <>
      {status === 'loading' && <Loading />}
      {status === 'success' && (
        <>
          <S.Main>
            {renderHeader()}
            <Grid container spacing="20px" marginTop="10px" ref={componentRef}>
              <Grid item xs={12} sm={12} md={6}>
                {renderTravelInfo()}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {renderInfractionInfo()}
              </Grid>
            </Grid>
          </S.Main>

          <ExportToPdf infraction={infraction} printRef={printRefPdf} />
        </>
      )}
    </>
  );
};

export default DetalheDesvioFadiga;
