// React
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import DefaultButton from 'components/Buttons/Default';

// Components MI
import { Popover } from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

// Utils
import { ptDays } from 'utils/dates';
import { formatNewDate } from 'utils/dates';
import { changeCurrentWeek } from 'store/modules/myTasks/actions';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import {
  formatTaskColor,
  formatIconColor,
  formatBorderColor,
} from './constants';
import * as S from './styled';

export const Grid = ({
  semanas,
  semana,
  setSemana,
  currentDay,
  tasksContent,
  setCurrentWeek,
  resetForced,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [monthContent, setMonthContent] = useState(null);
  const [anchorTask, setAnchorTask] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);

  const iconGenerator = icon => {
    if (!icon) icon = 'edicao.svg';
    return require(`../../../images/icons/logs/${icon}`);
  };

  // Open week in current day by default
  useEffect(() => {
    if (!semana) {
      if (resetForced) {
        const today = new Date(currentDay);
        for (const i in semanas) {
          if (
            today > new Date(semanas[i].inicio) &&
            today < new Date(semanas[i].final)
          ) {
            setSemana(i);
            setCurrentWeek(i);
            dispatch(
              changeCurrentWeek({
                currentWeek: Number(i),
              }),
            );
          }
        }
      } else {
        setCurrentWeek(tasksContent.currentWeek);
        setSemana(tasksContent.savedWeek);
      }
    }
  }, [semanas]);

  // Format data for separate tasks in days
  useEffect(() => {
    if (semanas) {
      const totalContent = [];
      for (const i in semanas) {
        const content = semanas[i];

        const days = [];
        for (let i = 0; i <= 6; i++) {
          const inicio = new Date(content.inicio);
          const currentDay = inicio.getDate();
          days.push(new Date(inicio.setDate(currentDay + i)));
        }

        const formatedData = [];
        const { tarefas } = content;

        for (const i in days) {
          const day = days[i];
          const tasks = [];

          for (const i in tarefas) {
            if (
              new Date(tarefas[i].data).toLocaleDateString() ===
              new Date(day).toLocaleDateString()
            ) {
              tasks.push(tarefas[i]);
            }
          }
          formatedData.push({ day, tasks });
        }
        totalContent.push(formatedData);
      }
      setMonthContent(totalContent);
    }
  }, [semanas]);

  // Render Card on click in task
  const clickOnBox = event => {
    setAnchorTask(event.currentTarget);
  };

  const renderTask = () => {
    return (
      <Popover
        id={anchorTask ? 'popover' : undefined}
        open={Boolean(anchorTask)}
        anchorEl={anchorTask}
        onClose={() => {
          setAnchorTask(null);
          setClickedItem(null);
        }}
        onClick={() => setAnchorTask(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{
          marginTop: '10px',
        }}
      >
        <S.PopoverBox color={formatBorderColor(clickedItem.status, theme)}>
          <div className="topArea">
            <img src={iconGenerator(clickedItem.icon)} />
            <span className="textArea">
              <span className="desc">{`[${clickedItem.evento}] ${clickedItem.title}`}</span>
              <span className="data">{formatNewDate(clickedItem.data)}</span>
            </span>
          </div>
          <div className="bottomArea">{formatStatusArea(clickedItem)}</div>
        </S.PopoverBox>
      </Popover>
    );
  };

  // Form button with navigate hook
  const formatStatusArea = task => {
    const disabled = task.disabled;
    switch (task.status) {
      case 'PENDENTE': {
        if (disabled) return <S.FinishButton>Agendado</S.FinishButton>;
        return (
          <DefaultButton
            children="Ver Tarefa"
            onClick={() => {
              if (task.params) {
                if (!task.disabled)
                  navigate(`${task.route}`, {
                    state: task.params,
                  });
              } else navigate(`${task.route}${task.id_da_tarefa}`);
            }}
          />
        );
      }
      case 'VENCIDO': {
        return (
          <DefaultButton
            children="Ver Tarefa"
            style={{
              backgroundColor:
                theme.palette.semantics.feedback.attention.natural,
            }}
            onClick={() => {
              if (task.params) {
                if (!task.disabled)
                  navigate(`${task.route}`, {
                    state: task.params,
                  });
              } else navigate(`${task.route}${task.id_da_tarefa}`);
            }}
          />
        );
      }
      case 'CONCLUIDO': {
        return (
          <S.FinishButton
            onClick={() => {
              if (task.params) {
                if (!task.disabled)
                  navigate(`${task.route}`, {
                    state: task.params,
                  });
              } else navigate(`${task.route}${task.id_da_tarefa}`);
            }}
          >
            Concluído
          </S.FinishButton>
        );
      }
    }
  };

  const formtTitle = title => {
    if (title.length > 15) {
      return `${title.slice(0, 12)}... `;
    }
    return title;
  };

  return (
    <S.Wrapper>
      <S.WeekBox total={semanas.length} semana={semana}>
        {monthContent &&
          monthContent.map((data, idx) => (
            <S.Slide
              key={idx}
              semana={semana}
              total={semanas.length}
              className={semana == idx ? 'visible' : 'invisible'}
            >
              {data &&
                data.map((week, idx) => (
                  <S.Col key={idx}>
                    <S.Header
                      active={
                        week.day.toLocaleDateString() ===
                        new Date().toLocaleDateString()
                      }
                    >
                      <span className="day">
                        {ptDays[week.day.getDay()].slice(0, 3)}
                      </span>
                      <span className="number">{week.day.getDate()}</span>
                    </S.Header>
                    <S.TasksBox>
                      {week.tasks.map((task, k) => (
                        <S.BoxItem key={k}>
                          <S.Task
                            onClick={e => {
                              clickOnBox(e);
                              setClickedItem(task);
                            }}
                            style={formatTaskColor(
                              task.status,
                              task.disabled,
                              theme,
                            )}
                          >
                            <CreateOutlinedIcon
                              htmlColor={formatIconColor(task.status, theme)}
                              fontSize="18px"
                              className="icon"
                            />
                            <span>
                              {formtTitle(task.title)} {task.id_da_tarefa}
                            </span>
                          </S.Task>
                        </S.BoxItem>
                      ))}
                    </S.TasksBox>
                  </S.Col>
                ))}
            </S.Slide>
          ))}
      </S.WeekBox>
      {clickedItem && renderTask()}
    </S.Wrapper>
  );
};
