import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { formatNewDate } from 'utils/dates';
import Table from 'components/TableLocal';

// Columns

import * as S from './styled';
import { columns } from '../columns';
import { getStatus } from '../../constants';

export const ExportToPdf = ({
  infraction,
  data = [{ nome: '', data: '', pontos: '' }],
  printRef,
  formatTable,
}) => {
  const isSuspensao = useMemo(
    () => !!infraction?.acao?.acao?.is_reincidencia,
    [infraction],
  );

  const logo = require('images/onisys.png');

  const { user } = useSelector(state => state.auth.user);
  const comapnyLogo = user?.filial?.empresa?.logo_url;

  return (
    <S.ModalMUI open={false} onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="formIdent">
            <div className="logoArea">
              <img src={logo} />
            </div>
            <div className="titleForm">
              <span>
                {`${isSuspensao ? 'Supensão' : 'Ação Disciplinar'} - ID`}{' '}
                {infraction?.id}
              </span>
            </div>
          </div>
        </S.Infos>
        <S.CardHeader>
          <h1>{`Informações da ${
            isSuspensao ? 'Supensão' : 'Ação Disciplinar'
          }`}</h1>
        </S.CardHeader>
        <S.Header>
          <div className="topInfos">
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <div className="normalText">Motorista:</div>
                <S.InputToPDF>{infraction?.motorista.nome}</S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="normalText">Filial:</div>
                <S.InputToPDF>
                  {infraction?.motorista.filial?.nome}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="normalText">Ação Disciplinar:</div>
                <S.InputToPDF>{infraction?.acao.acao.nome}</S.InputToPDF>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className="normalText">Pontuação:</div>
                <S.InputToPDF>
                  {` ${infraction?.acao.limiar || '-'}`}
                  {'  Pontos'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className="normalText">Faixa:</div>
                <S.InputToPDF>{infraction?.acao.titulo || '-'}</S.InputToPDF>
              </Grid>
              {isSuspensao && (
                <>
                  <Grid item xs={6} md={3}>
                    <div className="normalText">Data da suspensão:</div>
                    <S.InputToPDF>
                      {formatNewDate(infraction?.data_inicio) || '-'}
                    </S.InputToPDF>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="normalText">Data da liberção:</div>
                    <S.InputToPDF>
                      {formatNewDate(infraction?.data_fim) || '-'}
                    </S.InputToPDF>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="normalText">Incidência/Reincidência:</div>
                    <S.InputToPDF>
                      {infraction?.reincidencia?.reincidencia || '-'}
                    </S.InputToPDF>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="normalText">Dias de suspensão:</div>
                    <S.InputToPDF>
                      {infraction?.reincidencia?.dias || '-'}
                    </S.InputToPDF>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6}>
                <div className="normalText">Capacitação:</div>
                <S.InputToPDF>
                  {infraction?.acao.capacitacao?.titulo || '-'}
                </S.InputToPDF>
              </Grid>
              {!isSuspensao && (
                <>
                  <Grid item xs={6} md={3}>
                    <div className="normalText">Data alcance de faixa:</div>
                    <S.InputToPDF>
                      {formatNewDate(infraction?.data_inicio) || '-'}
                    </S.InputToPDF>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="normalText">Data de finalização:</div>
                    <S.InputToPDF>
                      {formatNewDate(infraction?.data_fim) || '-'}
                    </S.InputToPDF>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6}>
                <div className="normalText">Status:</div>
                <S.InputToPDF>
                  {getStatus(infraction?.status, isSuspensao) || '-'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="normalText">Responsável:</div>
                <S.InputToPDF>
                  {infraction?.responsavel?.nome || '-'}
                </S.InputToPDF>
              </Grid>
              {isSuspensao && (
                <Grid item xs={12} md={6}>
                  <div className="normalText">Motivo da liberação:</div>
                  <S.InputToPDF>
                    {infraction?.motivo_liberacao || '-'}
                  </S.InputToPDF>
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <div className="normalText">Observações:</div>
                <S.InputToPDF>{infraction?.observacao || '-'}</S.InputToPDF>
              </Grid>
            </Grid>
          </div>
        </S.Header>
        <S.CardHeader>
          <h1>Desvios Contabilizados</h1>
        </S.CardHeader>
        <S.Header>
          <S.TableArea>
            {!!data?.length && (
              <Table
                columns={formatTable(columns.filter(c => c.id != 'acoes'))}
                data={infraction?.violacao_acao_suspensao_motorista ?? []}
                pageCount={
                  infraction?.violacao_acao_suspensao_motorista?.length ?? 0
                }
                sortBy={[]}
                empty={{
                  title: 'Ops! Não há desvios registrados.',
                  subtitle: 'Verifique o período selecionado',
                }}
              />
            )}
            <div className="coverInputBottom" />
          </S.TableArea>
        </S.Header>
        <S.CardHeader>
          <h1>Assinaturas</h1>
        </S.CardHeader>
        <S.Header>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="containerSignatures">
                <div className="lineApprover" />
                <div className="containerSignaturesTipe">
                  Responsável: {infraction?.responsavel?.nome.toUpperCase()}
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="containerSignatures">
                <div className="lineApprover" />
                <div className="containerSignaturesTipe">
                  Motorista: {infraction?.motorista.nome.toUpperCase()}
                </div>
              </div>
            </Grid>
          </Grid>
        </S.Header>
      </S.Container>
    </S.ModalMUI>
  );
};
