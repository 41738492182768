import { Modal } from '@mui/material';
import styled, { css } from 'styled-components';

export const ModalMUI = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Content = styled.div`
  width: 70%;
  max-width: 850px;
  height: 600px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .imageBox {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      border: 2px solid ${theme.palette.brand.secondary.natural};
      overflow: hidden;
      padding: 5px;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  `}
`;

export const Body = styled.div``;

export const Table = styled.div``;

export const SearchArea = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTable = styled.div`
  ${({ theme }) => css`
    height: 50px;
    width: 100%;
    background-color: ${theme.palette.brand.primary.background};
    display: flex;
    align-items: center;
    font-weight: bold;
    color: ${theme.palette.semantics.feedback.unknown.natural};
    font-size: 16px;

    .columnHeader {
      display: flex;
      padding: 15px 20px;

      &.flex3 {
        flex: 2;
      }
    }

    .center {
      justify-content: center;
    }
  `}
`;

export const BodyTable = styled.div`
  ${({ theme }) => css`
    overflow-y: scroll;
    overflow-x: hidden;
    height: 470px;
    display: flex;
    justify-content: start;
    align-items: center;
    text-transform: capitalize;
  `}

  .center {
    justify-content: center;
  }
`;

export const EmptyArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: -80px;

    img {
      width: 200px;
    }

    .emeptyText {
      margin-left: 20px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
      font-size: 25px;
    }
  `}
`;

export const Popup = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 130px;

    button {
      width: 100%;
      border: none;
      background-color: ${theme.palette.system.overlay};
      border-radius: none;
      text-align: start;
      padding: 8px 10px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
      font-size: 15px;
      transition: all ease 0.2s;

      &:hover {
        background-color: ${theme.palette.system.border};
      }
    }
  `}
`;
