// Styled
import { useTheme } from 'styled-components';

// React
import { useState } from 'react';

// Components
import TextField from 'components/Inputs/TextField';

// Components MUI
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import DefaultButton from 'components/Buttons/Default';
import GhostButton from 'components/Buttons/Ghost';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Check } from '@mui/icons-material';

// Utils
import InfoCard from 'components/Cards/InfoCard';
import { toast } from 'react-toastify';
import * as S from './styled';

// Constants
import { justifications } from '../../constants';

export const ModalJustificativa = ({
  open,
  dataLength,
  onClose,
  onClick,
  isLoading,
}) => {
  const theme = useTheme();
  const [justify, setJustify] = useState('Férias');
  const [outro, setOutro] = useState('');

  const handleRadio = event => {
    const newValue = event.target.value;
    setJustify(newValue);
    setOutro('');
  };

  const handleOutro = event => {
    const newValue = event.target.value;
    if (newValue.trim() === '' && !outro) return;
    setOutro(newValue);
  };

  const handleJustify = data => {
    if (!outro && !justify) {
      toast.warning('É necessário selecionar um motivo!');
      return;
    }
    onClick(outro || justify);
    // onClose();
  };

  return (
    <>
      <S.ModalMUI open={open}>
        <S.Content>
          <S.Header>
            <div className="left">
              <Check htmlColor={theme.palette.secondary.main} />
              <span className="title">
                {`Deseja justificar os alunos selecionados? (${dataLength})`}
              </span>
            </div>
            <div className="right">
              <CloseOutlinedIcon
                htmlColor={theme.palette.words.button.contrast}
                onClick={() => !isLoading && onClose()}
              />
            </div>
          </S.Header>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <InfoCard message="Os alunos selecionados serão retirados da listagem de pendentes." />
          </div>
          <S.Body>
            <div className="justify">
              <h3>Justificativa</h3>
              <span>*</span>
              <h3>:</h3>
            </div>

            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Justificar"
                name="Justificar"
                value={justify}
                onChange={handleRadio}
              >
                {justifications.map(item => (
                  <S.StyledContent>
                    <div className="form">
                      <FormControlLabel
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                        key={`radio_${item.value}`}
                      />
                    </div>

                    {item.value === 'OUTRO' && (
                      <div className="text">
                        <TextField
                          name="outro"
                          value={outro}
                          variant="outlined"
                          placeholder="Insira o motivo"
                          onChange={handleOutro}
                          maxRows={1}
                        />
                      </div>
                    )}
                  </S.StyledContent>
                ))}
              </RadioGroup>
            </FormControl>
          </S.Body>
          <S.Footer>
            <GhostButton onClick={onClose} disabled={isLoading}>
              Cancelar
            </GhostButton>
            <div style={{ marginLeft: 5 }} />
            <DefaultButton
              loading={isLoading}
              onClick={handleJustify}
              disabled={isLoading}
            >
              Justificar
            </DefaultButton>
          </S.Footer>
        </S.Content>
      </S.ModalMUI>
    </>
  );
};
