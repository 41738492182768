import React from 'react';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import * as S from './styled';

// > image = .png salvo em images/empty
// > title e subtitle de cada card deve ser personalizado
// > handleDefaultButton handleGhostButton define as funções dos botoes
// > height e margintop são opcionais
const Empty = ({
  image,
  title,
  subtitle,
  height,
  margintop,
  handleDefaultButton,
  defaultButtonContent,
  handleGhostButton,
  ghostButtonContent,
  vertical = false,
}) => {
  const img = image ? require(`images/empty/${image}`) : '';

  return (
    <S.Container
      height={height}
      margintop={margintop}
      direction={vertical ? 'column' : 'row'}
    >
      {img && !vertical && <S.Image src={img} alt="empty_data_image" />}
      <S.TextButtons>
        <h1>{title || 'Nenhum dado retornado'}</h1>

        <h2>
          {subtitle ||
            'Ainda não há dados suficientes para esta busca. Favor verificar seus filtros'}
        </h2>

        <S.ButtonsContainer>
          {handleDefaultButton && (
            <DefaultButton size="medium" onClick={handleDefaultButton}>
              {defaultButtonContent ?? ''}
            </DefaultButton>
          )}

          {handleGhostButton && (
            <GhostButton
              size="medium"
              onClick={handleGhostButton}
              sx={{ marginLeft: '20px' }}
            >
              {ghostButtonContent ?? ''}
            </GhostButton>
          )}
        </S.ButtonsContainer>
      </S.TextButtons>
      {img && vertical && <S.Image src={img} alt="empty_data_image" />}
    </S.Container>
  );
};

export default Empty;
