/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import InfoCard from 'components/Cards/InfoCard';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { Checkbox } from '@mui/material';
import { SaveAlt, Close } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';

// Utils
import * as XLSX from 'xlsx';
import * as services from '../../services';
import csvUpload from 'images/csv_upload.png';

const templateFile =
  'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/ModelosUpload%2Fcadastro_de_motoristas.xlsx?alt=media&token=2516a61c-265c-49c1-9fa7-10a5661c288a';

export const ModalUpload = ({ open, handleClose, setNewUpload }) => {
  // Refs
  const inputRef = useRef(null);

  // Redux
  const { filials } = useSelector(state => state.selects);

  // Loadings
  const [downloading, setDownloading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  // Data
  const [success, setSuccess] = useState(false);
  const [dataErros, setDataErros] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const [confirmSave, setConfirmSave] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(true);

  const textFormater = (text, size = 20) => {
    if (text?.length > 20) {
      return `${text?.slice(0, size)}...`;
    }
    return text;
  };

  // --Download File
  const handleDownload = () => {
    setDownloading(true);
    setTimeout(() => {
      setDownloading(false);
    }, 2000);
  };

  // --Load File
  const handleLoadFile = e => {
    setLoadingFile(true);
    const [file] = e.target.files;
    handleReadLoadedFile(file);
  };

  const handleReadLoadedFile = file => {
    const reader = new FileReader();
    reader.onload = async evt => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { raw: false });

      if (data.length < 1) toast.warning('Não há dados na planilha carregada!');
      else {
        handleCheckData(data);
      }
    };
    reader.readAsBinaryString(file);
  };

  const handleCheckData = async data => {
    const res = await services.checkUpload(data);
    if (res.data.success) {
      const length = res.data.data.length;
      setCheckedData(
        res.data.data.map((item, k) => ({
          ...item,
          checked: true,
          id_local: length - k,
        })),
      );

      setTimeout(() => {
        setSuccess(true);
        setLoadingFile(false);
        if (res.data.alerta_faixa) toast.warning(res.data.message);
      }, 3000);
    } else if (res.data.errors) {
      setDataErros(res.data.errors);
      setLoadingFile(false);
      inputRef.current.value = '';
    }
  };

  // --------------------------------------TABLE---------------------------------------//
  const [ordenad, setOrdenad] = useState(0);

  const getOrdenad = () => {
    switch (ordenad) {
      case 0:
        return [...checkedData.sort((a, b) => b.id_local - a.id_local)];
      case 1:
        return [
          ...checkedData.sort((a, b) => `${a.nome}`.localeCompare(b.nome)),
        ];
      case 2:
        return [
          ...checkedData.sort((a, b) => `${b.nome}`.localeCompare(a.nome)),
        ];
    }
  };

  const handleOrdenad = () => {
    if (ordenad == 0) {
      setOrdenad(1);
    } else if (ordenad == 1) {
      setOrdenad(2);
    } else {
      setOrdenad(0);
    }
  };

  const handleCheck = item => {
    const data = checkedData.map(i => {
      if (i.id_local === item.id_local) {
        return { ...i, checked: !i.checked };
      }
      return i;
    });
    setCheckedData(data);
  };

  const calcSelecteds = () => {
    let count = 0;
    checkedData.forEach(item => {
      if (item.checked) count++;
    });
    return count;
  };

  const allChecked = () => {
    let isAllChecked = true;
    checkedData.forEach(item => {
      if (!item.checked) isAllChecked = false;
    });

    setIsAllChecked(!isAllChecked);
    setCheckedData(
      checkedData.map(item => ({
        ...item,
        checked: !isAllChecked,
      })),
    );
  };

  // --------------------------------------SAVE---------------------------------------//
  const handleSave = async () => {
    const data = checkedData.filter(item => item.checked);
    setLoadingSave(true);
    const res = await services.createManyMotoristas(data);
    setLoadingSave(false);
    if (res.data.success) {
      toast.success(res.data.message || 'Motoristas cadastrados com sucesso!');
      setNewUpload(res?.data?.data?.reverse() || []);
    } else {
      toast.error(res.data.message);
    }
    setSuccess(false);
    handleClose();
  };

  // --------------------------------------RENDER---------------------------------------//
  return (
    <S.ModalMui open={open}>
      <S.Container success={success}>
        <div className="slider">
          <div className="step1">
            <S.Header>
              <div className="left">
                <TextSnippetOutlinedIcon htmlColor={colors.greenMain} />
                <h2>Faça o upload da planilha</h2>
              </div>
              <div className="right">
                <CloseRoundedIcon
                  htmlColor={colors.grey}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setDataErros([]);
                    handleClose();
                  }}
                />
              </div>
            </S.Header>
            <S.Center isError={dataErros.length > 0}>
              {!dataErros.length > 0 && <img src={csvUpload} />}
              {dataErros.length > 0 && (
                <S.Erros>
                  <div className="header">
                    <WarningAmberRoundedIcon htmlColor={colors.redDanger} />
                    <h3>
                      Fique atento! Sua planilha contém os seguintes erros:
                    </h3>
                  </div>
                  <ul>
                    {dataErros.map((erro, index) => (
                      <li key={index}>
                        <Close htmlColor={colors.redDanger} />
                        {erro}
                      </li>
                    ))}
                  </ul>
                </S.Erros>
              )}
            </S.Center>
            <S.Description>
              Baixe em seu computador a planilha modelo para a inserção em massa
              de motoristas. Fique atento a formatação dos dados e informações
              obrigatórias!
            </S.Description>
            <InfoCard
              message="Adicione seus motoristas de uma só vez! Baixe nosso modelo de planilha, preencha os dados e faça o upload do arquivo."
              key="info"
            />
            <S.Footer>
              <DefaultButton
                startIcon={<SaveAlt />}
                children="Baixar modelo"
                width="100%"
                href={templateFile}
                onClick={handleDownload}
                loading={downloading}
              />
              <DefaultButton
                startIcon={<SaveAlt sx={{ transform: 'rotate(180deg)' }} />}
                children="Fazer upload"
                width="100%"
                component="label"
                htmlFor="contained-button-file"
                loading={loadingFile}
              />

              <input
                accept=".xlsx, .xls"
                id="contained-button-file"
                type="file"
                ref={inputRef}
                onChange={handleLoadFile}
              />
            </S.Footer>
          </div>
          <div className="step2">
            <S.Header className="header">
              <div className="left">
                <PeopleOutlineOutlinedIcon htmlColor={colors.greenMain} />
                <h2>Selecionar Motoristas</h2>
                <p>Motoristas selecionados: {calcSelecteds()}</p>
              </div>
              <div className="right">
                <CloseRoundedIcon
                  htmlColor={colors.grey}
                  style={{ cursor: 'pointer' }}
                  onClick={handleClose}
                />
              </div>
            </S.Header>
            <S.SampleTable>
              <S.HeaderTable>
                <div className="left">
                  <Checkbox
                    onChange={() => allChecked()}
                    checked={isAllChecked}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 35,
                      },

                      '& .Mui-checked': {
                        color: colors.greenMain,
                      },
                    }}
                  />
                </div>

                <div className="left space" onClick={() => handleOrdenad()}>
                  <p>NOME</p>
                  <div className="arrows">
                    <ArrowDropUpOutlinedIcon
                      className={ordenad == 1 ? 'top active' : 'top'}
                    />
                    <ArrowDropDownOutlinedIcon
                      className={ordenad === 2 ? 'bottom active' : 'bottom'}
                    />
                  </div>
                </div>

                <div className="left">
                  <p>FUNÇÃO</p>
                </div>

                <div className="left">
                  <p>FILIAL</p>
                </div>
              </S.HeaderTable>
              <S.BodyTable>
                {getOrdenad().map((item, key) => (
                  <S.LineTable key={key}>
                    <p className="prh">
                      <Checkbox
                        checked={item.checked}
                        onChange={() => handleCheck(item)}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 35,
                          },
                          '& .Mui-checked': {
                            color: colors.greenMain,
                          },
                        }}
                      />
                    </p>
                    <div className="left">
                      <p>{textFormater(item?.nome)}</p>
                    </div>
                    <p className="prh">{textFormater(item?.funcao)}</p>
                    <p className="prh">
                      {textFormater(
                        filials.find(i => +i.id === +item?.id_da_filial)?.nome,
                        15,
                      )}
                    </p>
                  </S.LineTable>
                ))}
              </S.BodyTable>
            </S.SampleTable>
            <S.Footer className="footer">
              <GhostButton children="Cancelar" onClick={handleClose} />
              <DefaultButton
                children="Salvar"
                loading={loadingSave}
                onClick={() => setConfirmSave(true)}
              />
            </S.Footer>
          </div>
        </div>

        <ConfirmModal
          open={confirmSave}
          handleClose={() => setConfirmSave(false)}
          onClick={() => handleSave()}
          buttonText="Confirmar"
          title="Tem cereza que deseja adicionar os motoristas na plataforma?"
          subtitle="Motoristas não selecionados serão descartados durante o processo."
          titleIcon={<WarningAmberRoundedIcon htmlColor={colors.redDanger} />}
          loading={loadingSave}
        />
      </S.Container>
    </S.ModalMui>
  );
};
