import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, hasError, preview }) => css`
    .headerArea {
      display: flex;
      justify-content: space-between;
      flex-direction: ${preview ? 'column-reverse' : 'row'};

      .boxQuestion {
        display: flex;

        .question {
          font-weight: 700;
          font-size: ${preview ? '14px' : '17px'};
          color: ${hasError
            ? theme.palette.semantics.feedback.attention.natural
            : theme.palette.system.black};
          word-break: break-all;
        }

        span {
          color: ${theme.palette.semantics.feedback.attention.natural};
          margin-left: 5px;
          font-weight: 900;
        }
      }
    }

    .description {
      margin-top: 8px;
      margin-bottom: 15px;
      font-weight: 500;
      color: ${hasError
        ? theme.palette.semantics.feedback.attention.natural
        : theme.palette.words.subtitle.natural};
      font-size: ${preview ? '12px' : '14px'};
    }
  `}
`;

export const IconsArea = styled.div`
  ${({ theme }) => css`
    display: flex;

    .icons {
      margin-left: 10px;
    }

    .boxComment {
      position: relative;

      .hasCommnet {
        background-color: ${theme.palette.semantics.feedback.attention.natural};
        color: ${theme.palette.words.text.contrast};
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        width: 17px;
        height: 17px;
        border-radius: 20px;
        font-size: 12px;
        position: absolute;
        top: 0;
        right: 0%;
        margin-right: -4px;
        margin-top: -4px;
      }
    }
  `}
`;
