/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

// Componets
import ConfirmModal from 'components/ConfirmModal';

// Material UI
import { ListItem } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

// Constants
import { mediaType } from '../constants';

export const DraggableItems = ({
  item,
  index,
  media,
  setChanged,
  setMedia,
  setCounters,
  counters,
  errors,
  setErrors,
  isPublished,
}) => {
  const theme = useTheme();
  const [error, setError] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  useEffect(() => {
    const error = errors?.find(a => a.index === index);
    setError(error);
  }, [errors]);

  const deleteMidia = () => {
    const { counterKey } = mediaType?.find(a => a.type === item.tipo);
    if (counters[counterKey] > 0) {
      setCounters(prevState => ({
        ...prevState,
        [counterKey]: prevState[counterKey] - 1,
      }));

      const result = media?.filter((_, k) => k !== index);
      if (errors) setErrors(null);
      setMedia(result);
      setOpenConfirmDelete(false);
    }
    setChanged(true);
  };

  const renderComponent = () => {
    const { Component } = mediaType?.find(a => a.type === item.tipo);
    return (
      <Component
        item={item}
        setChanged={setChanged}
        media={media}
        index={index}
        setMedia={setMedia}
        setOpenConfirmDelete={setOpenConfirmDelete}
        deleteMedia={deleteMidia}
        error={error}
        isPublished={isPublished}
      />
    );
  };

  return (
    <>
      <Draggable draggableId={String(item.draggableId)} index={index}>
        {provided => (
          <ListItem
            component="div"
            sx={{ marginBottom: 5, padding: 0, zIndex: 1 }}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <S.Card
              actived={index < 8}
              key={index}
              isError={error?.titulo && error?.descricao && error?.link}
              id={`card-section-content-${index}`}
            >
              <div {...provided.dragHandleProps}>
                <MenuOutlinedIcon />
              </div>
              {renderComponent()}
            </S.Card>
          </ListItem>
        )}
      </Draggable>
      <ConfirmModal
        open={openConfirmDelete}
        handleClose={() => setOpenConfirmDelete(false)}
        title="Tem certeza que deseja excluir essa mídia?"
        subtitle="Todos os campos preenchidos no item serão apagados."
        titleIcon={
          <HighlightOffIcon
            sx={{ color: theme.palette.semantics.feedback.attention.natural }}
            fontSize="medium"
          />
        }
        buttonText="Confirmar"
        onClick={() => deleteMidia(index, item)}
      />
    </>
  );
};
