import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { usePlans } from 'hooks/usePlans';

import CustomDateRangePicker from 'components/Header/DateRangePicker';
import { Grid } from '@mui/material';
import { Header } from '../components/Header';
import { Card } from '../components/Card';
import { Movimentacoes } from '../components/Movimentacoes';

import * as S from '../styled';
import * as API from '../services';

// services
import { getMovimentacoes, getStats } from '../services';
import { Historico } from '../components/Historico';
import { Atividades } from '../components/Atividades';
import { downloadFromLink } from 'utils/helpers';

export const EstatisticasTorrePlus = () => {
  const params = useParams();
  const { isProvider } = usePlans();
  const [groupBy, setGroupBy] = useState('criticidade');
  const [loadingExport, setLoadingExports] = useState(false);

  const filter = useSelector(state => {
    return isProvider ? state.filterProvider : state.filter;
  });

  const { isLoading, data = {} } = useQuery(
    ['equipe-estatisticas', params.id, filter],
    () => getStats(params.id, filter),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { isLoading: isLoadingMovimentacoes, data: movimentacoes = [] } =
    useQuery(
      ['equipe-movimentacoes', params.id, groupBy, filter],
      () => getMovimentacoes(params.id, { ...filter, groupBy }),
      {
        refetchOnWindowFocus: false,
      },
    );

  const handleExport = async () => {
    setLoadingExports(true);
    const res = await API.exportDepenhoDoUsuarioTorrePlus({
      ...filter,
      groupBy,
      user_id: params.id,
    });
    downloadFromLink(res?.data?.link);
    setLoadingExports(false);
  };

  return (
    <S.Container>
      <Header
        foto={data?.foto}
        title={data?.nome}
        isLoading={isLoading}
        ultima_tratativa={data?.ultima_tratativa ?? null}
        loading={loadingExport}
        handleExport={handleExport}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <S.Flex>
            <S.Title>Tempo médio de tratativas</S.Title>
            <CustomDateRangePicker height="45px" />
          </S.Flex>
          <br />
          <S.Flex className="gap-05">
            <div>
              <Card
                loading={isLoading}
                direction="column"
                title={data?.tempo_tratativas?.TOTAL ?? ''}
              />
            </div>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Card
                  loading={isLoading}
                  type="gravissimo"
                  subtitle="Desvios gravíssimos"
                  title={data?.tempo_tratativas?.GRAVÍSSIMOS ?? ''}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  loading={isLoading}
                  type="moderado"
                  subtitle="Desvios moderados"
                  title={data?.tempo_tratativas?.MODERADOS ?? ''}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  loading={isLoading}
                  type="grave"
                  subtitle="Desvios graves"
                  title={data?.tempo_tratativas?.GRAVES ?? ''}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  loading={isLoading}
                  type="primario"
                  subtitle="Desvios primários"
                  title={data?.tempo_tratativas?.PRIMÁRIOS ?? ''}
                />
              </Grid>
            </Grid>
          </S.Flex>
          <br />
          <Movimentacoes
            data={movimentacoes}
            groupBy={groupBy}
            setGroupBy={setGroupBy}
            loading={isLoadingMovimentacoes}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Atividades id={params.id} filter={filter} />
        </Grid>
        <Grid item xs={12}>
          <Historico id={params.id} />
        </Grid>
      </Grid>
    </S.Container>
  );
};
