import api from 'services/api';

const requestViolations = async query => {
  const res = await api.get('/violations', { params: query });
  return res;
};

const fetchViolation = async id => {
  const res = await api.get(`/violation/${id}`);
  return res;
};

const requestHistory = async id => {
  const res = await api.get(`/violation-logs/${id}`);
  return res;
};

const requestCards = async query => {
  const res = await api.get('/violation-cards', { params: query });
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel/violations', { params: query });
  return res;
};

const saveViolations = async data => {
  const res = await api.put(`/violations-update`, data);
  return res;
};

const deleteViolations = async data => {
  const res = await api.put(`/violations-delete`, data);
  return res;
};

const viewViolations = async data => {
  const res = await api.put(`/violations-view`, data);
  return res;
};

const finishViolations = async data => {
  const res = await api.put(`/violations-finish`, data);
  return res;
};

const sendPadViolations = async data => {
  const res = await api.post(`/violations-send-pad`, data);
  return res;
};

export {
  requestViolations,
  fetchViolation,
  requestCards,
  requestExcel,
  saveViolations,
  deleteViolations,
  finishViolations,
  viewViolations,
  sendPadViolations,
  requestHistory,
};
