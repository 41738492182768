import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 5px 40px;
`;

export const MediaArchive = styled.div`
  margin-top: 40px;
`;

export const InvalidVideo = styled.div`
  ${({ theme }) => css`
    border: 3px solid ${theme.palette.semantics.feedback.attention.natural};
    height: 200px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      font-weight: bold;
      font-size: 30px;
      color: ${theme.palette.semantics.feedback.attention.natural};
    }
  `}
`;
