import React, { useState, useEffect } from 'react';
import Icon from 'components/Icons';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import ListItemText from '@mui/material/ListItemText';
import { formatNewHour } from 'utils/dates';
import { useTheme } from 'styled-components';
import * as S from './styled';

const ListaCaes = ({ caes, posto, handleCaes, handleOpenCae }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(!!(caes && caes.length > 0));
  }, [caes]);

  return (
    <Modal open={open}>
      <S.Main>
        <S.Header>
          <S.Title>
            Lista de Comunicação de Atendimente de Emergência (CAE)
          </S.Title>
          <S.CloseButton
            startIcon={
              <Icon name="close" color={theme.palette.brand.primary.dark} />
            }
            onClick={() => handleCaes(null)}
          />
        </S.Header>

        <S.Container>
          <Grid container>
            <S.StyledHeaderWrapper
              container
              style={{ backgroundColor: theme.palette.system.divider }}
            >
              <Grid item md={1}>
                <ListItemText primary="ID" />
              </Grid>
              <Grid item md={5}>
                <ListItemText primary="Posto" />
              </Grid>
              <Grid item md={4}>
                <ListItemText primary="Ocorrência" />
              </Grid>
              <Grid item md={2}>
                <ListItemText primary="Data" />
              </Grid>
            </S.StyledHeaderWrapper>

            {caes &&
              caes.map(({ id, questao, data_ocorrencia }) => (
                <S.StyledRowWrapper
                  key={id}
                  onClick={() => handleOpenCae(id)}
                  container
                >
                  <Grid item md={1}>
                    <S.StyledList style={{ fontSize: '12px' }} primary={id} />
                  </Grid>
                  <Grid item md={5}>
                    <S.StyledList
                      style={{ fontSize: '12px' }}
                      primary={posto}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <S.StyledList
                      style={{ fontSize: '12px' }}
                      primary={questao && questao.abreviacao}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <S.StyledList
                      style={{ fontSize: '12px' }}
                      primary={
                        data_ocorrencia && formatNewHour(data_ocorrencia)
                      }
                    />
                  </Grid>
                </S.StyledRowWrapper>
              ))}
          </Grid>
        </S.Container>
      </S.Main>
    </Modal>
  );
};
export default ListaCaes;
