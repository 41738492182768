/* eslint-disable import/order */
import * as S from './styled';
import colors from 'styles/colors';

// Components
import GhostButton from 'components/Buttons/Ghost';

// Components MUI
import CloseIcon from '@mui/icons-material/Close';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';

export const FeedbackModal = ({
  open,
  handleClose,
  feedback = '',
  handleNext,
}) => {
  return (
    <S.ModalM open={open} onClose={handleClose}>
      <S.Container>
        <S.Header>
          <div className="titleArea">
            <SentimentDissatisfiedOutlinedIcon htmlColor={colors.redDanger} />
            <div className="title">Ops! Você errou!</div>
          </div>
          <CloseIcon
            onClick={() => {
              handleNext();
              handleClose();
            }}
            htmlColor={colors.greySubtitle}
            style={{
              cursor: 'pointer',
            }}
          />
        </S.Header>
        <S.Body>
          <div className="feed">Feedback:</div>
          <div className="text">{feedback}</div>
        </S.Body>
        <S.Buttons>
          <GhostButton
            children="Entendi"
            onClick={() => {
              handleNext();
              handleClose();
            }}
          />
        </S.Buttons>
      </S.Container>
    </S.ModalM>
  );
};
