import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { usePlans } from 'hooks/usePlans';
import { AddCircleOutline, WarningAmberOutlined } from '@mui/icons-material';

import ConfirmModal from 'components/ConfirmModal';
import { FilterTable } from 'components/_Table/templates/filter';
import Actions from 'pages/_templates/ListPage/components/Header/Actions';
import InfoCard from 'components/Cards/InfoCard';
import ModalFaixas from '../ModalFaixa';
import { getColumns } from './constants';
import { desactivateFaixa } from '../../services';

const ListaFaixas = ({
  data = [],
  loading = false,
  refetch,
  formModal,
  setFormModal,
  headerActions = null,
  readOnly = false,
  client = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isProvider, isOpLogistico } = usePlans();
  const [loadingLines, setLoadingLines] = useState([]);
  const [confirmDesativar, setConfirmDesativar] = useState(null);

  // HANDLERS
  const handleAtivar = async (id, row) => {
    const registro = row ?? data.find(item => String(item.id) === String(id));

    if (registro.ativo) return toast.success('Esse registro já está ativo');

    registro.ativo = true;

    // Se ativar sugestao, deve editar imediatamente!
    handleEditar(registro.id, registro);
  };

  const handleDesativar = (id, row) => {
    const registro = row
      ? { ...row }
      : data.find(item => String(item.id) === String(id));

    if (!registro.ativo) return toast.success('Esse registro já está inativo');

    setConfirmDesativar(registro);
  };

  const handleConfirmDesativar = async id => {
    setLoadingLines([id]);
    const res = await desactivateFaixa(id);
    setLoadingLines([]);
    if (res.success) {
      setLoadingLines([]);
      setConfirmDesativar(null);
      refetch();
      toast.success(res.message);
    } else toast.error(res.message);
  };

  const handleEditar = (id, row) => {
    const sortData = data.sort((a, b) => b.limiar - a.limiar);
    const registro =
      row ?? sortData.find(item => String(item.id) === String(id));
    if (registro) {
      const anteriorAtivo = sortData.find(
        item => item.ativo && item.limiar < registro.limiar,
      );
      const min = anteriorAtivo?.limiar ?? 0;

      setFormModal({
        open: true,
        data: {
          ...registro,
          ativo: registro.ativo,
          min,
        },
      });
    } else toast.error('Não foi possível encontrar esse registro');
  };

  const handleVisualizar = (id, row) => {
    if (row) {
      setFormModal({
        open: true,
        data: {
          ...row,
        },
        disabled: true,
      });
    } else toast.error('Não foi possível encontrar esse registro');
  };

  const handleAdicionar = () => {
    const sortData = data
      .filter(a => a.ativo)
      .sort((a, b) => a.limiar - b.limiar);
    let min = 0;
    if (sortData.length > 0) min = sortData[sortData.length - 1].limiar;

    setFormModal({ open: true, data: { min } });
  };

  const actions = [
    {
      title: 'Ativar',
      function: handleAtivar,
    },
    {
      title: 'Desativar',
      function: handleDesativar,
    },
    {
      title: 'Editar',
      function: handleEditar,
    },
    {
      title: 'Ver histórico',
      function: id => navigate(`/logs/acoes-suspensoes/configuracoes/${id}`),
    },
  ];

  const readOnlyActions = [
    {
      title: 'Ver detalhe',
      function: handleVisualizar,
    },
  ];

  return (
    <>
      <InfoCard
        message={
          readOnly
            ? 'As ações disciplinares configuradas pelo cliente são apenas sugestões de ações a serem tomadas para cada faixa de pontuação. A suspensão pode ser obrigatória, de acordo com a configuração do cliente.'
            : isProvider && !isOpLogistico
            ? 'Configure as faixas de pontuação e as ações disciplinares que as empresas poderão tomar para cada faixa.'
            : 'Configure as faixas de pontuação e as de ações disciplinares para cada faixa.'
        }
        key="info"
      />

      <FilterTable
        local
        data={data}
        columns={getColumns({
          readOnly,
          handleAtivar,
          handleDesativar,
          client,
        })}
        sortBy={{ id: 'ativo', order: 'DESC' }}
        searchKeys={['titulo', 'limiar']}
        placeholder="Buscar Faixa"
        loading={loading}
        loadingSelection={loadingLines}
        actions={readOnly ? readOnlyActions : actions}
        empty={{
          title: 'Ainda não existem faixas de pontuação cadastradas',
          subtitle: !readOnly && 'Adicione uma faixa de pontuação',
          action: !readOnly && {
            title: 'Adicionar Faixa',
            function: handleAdicionar,
            icon: <AddCircleOutline />,
          },
        }}
        headerActions={headerActions && <Actions actions={headerActions} />}
      />

      <ModalFaixas
        acoes={data}
        registro={formModal.data}
        open={formModal?.open}
        disabled={formModal?.disabled}
        handleClose={() => setFormModal({ data: null, open: false })}
        refetch={refetch}
      />

      <ConfirmModal
        open={!!confirmDesativar}
        handleClose={() => setConfirmDesativar(null)}
        title={`Deseja desativar a faixa ${confirmDesativar?.titulo}?`}
        titleIcon={
          <WarningAmberOutlined
            sx={{ color: theme.palette.semantics.feedback.attention.natural }}
            fontSize="medium"
          />
        }
        subtitle="Atenção! Motoristas com ações pendentes nesta faixa serão movidos para ações da faixa anterior, e conteúdos não iniciados, serão desconsiderados."
        buttonText="Confirmar"
        onClick={() => handleConfirmDesativar(confirmDesativar.id)}
        loading={!!loadingLines.length}
      />
    </>
  );
};

export default ListaFaixas;
