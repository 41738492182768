import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  gap: 1rem;
`;
