import styled, { css } from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  width: 100%;
`;

export const SliderBox = styled.div`
  display: flex;
  min-height: 850px;
  width: 100%;
`;

export const SlideComponent = styled.div`
  position: relative;
  width: 100%;
`;

export const PreviewDate = styled.div`
  ${({ theme }) => css`
    font-weight: 600;
    color: ${theme.palette.words.title.natural};
    font-size: 15px;

    .raw {
      font-weight: 500;
      font-size: 14px;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;

export const HeaderLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  width: 130px;

  button {
    width: 100%;
    border: none;
    background-color: #fff;
    border-radius: none;
    text-align: start;
    padding: 8px 10px;
    font-weight: 600;
    color: ${colors.greyTitle};
    font-size: 15px;
    transition: all ease 0.2s;

    &:hover {
      background-color: ${colors.greyBorder};
    }
  }
`;
