import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Grid, IconButton, SvgIcon } from '@mui/material';
import { SaveAlt, Close, CheckCircleOutline } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import csvDownload from 'images/csv_download.png';
import csvUpload from 'images/csv_upload.png';
import { useTheme } from 'styled-components';

import DefaultButton from 'components/Buttons/Default';
import GhostButton from 'components/Buttons/Ghost';
import Table from 'components/TableLocal';
import ConfirmModal from 'components/ConfirmModal';

// Components
import InfoCard from 'components/Cards/InfoCard';

// Image
import { ReactComponent as ProhibitedIco } from 'images/icons/prohibited.svg';
import { ReactComponent as WarningIco } from 'images/icons/warning.svg';
import { ReactComponent as DeleteIco } from 'images/icons/delete.svg';
import { columnsCreateMany, columnsCreateManyProvider } from '../constants';
import * as S from './styled';

// Service
import { createMany, checkUpload } from '../services';
import { usePlans } from 'hooks/usePlans';

const text = {
  baixarModelo:
    'Baixe em seu computador a planilha modelo para a inserção em massa de acidentes. Fique atento a formatação dos dados e informações obrigatórias!',
  fazerUpload:
    'Selecione a planilha "modelo" preenchida com os dados dos acidentes que serão adicionados. Em seguida clique em "FAZER UPLOAD" e acompanhe o envio dos dados.',
  sendoEnviado:
    'Seu arquivo está sendo enviado! Aguarde até que esteja 100% concluído o processo de envio do arquivo.',
  contemErros: 'Fique atento! Sua planilha contém os seguintes erros:',
  successo: 'Acidentes carregados com sucesso! Verifique seu dados.',
};

const templateFile =
  'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/ModelosUpload%2Fcadastro_de_acidentes.xlsx?alt=media&token=8fa8f14e-14a3-423e-bb91-6ed761b797df';

const templateFileProvider =
  'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/ModelosUpload%2Fcadastro_de_acidentes_emb.xlsx?alt=media&token=db87f216-f534-4d3d-abfb-c4b9cafe6070';

const sortBy = [
  {
    id: 'placa',
    desc: true,
  },
];

// rendermain() está todo compenetizável,
// as cosntantes estão declaradas acima e nos imports
//

function excelDateToJSDate(serial) {
  let utc_days = Math.floor(serial - 25569);
  let utc_value = utc_days * 86400;
  let date_info = new Date(utc_value * 1000);
  let fractional_day = serial - Math.floor(serial) + 0.0000001;
  let total_seconds = Math.floor(86400 * fractional_day);
  let seconds = total_seconds % 60;
  total_seconds -= seconds;
  let hours = Math.floor(total_seconds / (60 * 60));
  let minutes = Math.floor(total_seconds / 60) % 60;
  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds,
  );
}

const excelHourToString = days =>
  new Date(days * 864e5).toLocaleString('pt-BR', {
    timeStyle: 'short',
    timeZone: 'UTC',
  });

const AddAcidentes = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isProvider } = usePlans();

  const [etapa, setEtapa] = useState(2);
  const [downloading, setDownloading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const [fileLoaded, setFileLoaded] = useState(null);
  const [dataErros, setDataErros] = useState([]);
  const [checkedData, setCheckedData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [saving, setSaving] = useState(false);

  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [openConfirmSave, setOpenConfirmSave] = useState(false);

  // Estado downloading indica que o arquivo está sendo baixado
  // Atualiza para etapa 2 (upload) com delay
  const handleDownload = () => {
    setDownloading(true);
    setTimeout(() => {
      setDownloading(false);
      setEtapa(2);
    }, 2000);
  };

  // Carrega arquivo em fileLoaded
  // Atualiza para etapa 3 e chama handleReadLoadedFile
  const handleLoadFile = e => {
    setLoadingFile(true);
    const [file] = e.target.files;
    setFileLoaded(file);
    setEtapa(3);
    handleReadLoadedFile(file);
  };

  // Lê arquivo e registra json em data
  const handleReadLoadedFile = file => {
    const reader = new FileReader();
    reader.onload = evt => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {
        type: 'binary',
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { raw: true });

      if (data.length < 1) toast.warning('Não há dados na planilha carregada!');
      else {
        const formattedData = data.map(x => ({
          ...x,
          Motorista: x['Motorista (CPF)'],
          'Data do acidente': x['Data do acidente']
            ? format(excelDateToJSDate(x['Data do acidente']), 'dd/MM/yyyy')
            : null,
          'Hora do acidente': x['Hora do acidente']
            ? excelHourToString(x['Hora do acidente'])
            : null,
          'Prazo de investigação': x['Prazo de investigação']
            ? format(
                excelDateToJSDate(x['Prazo de investigação']),
                'dd/MM/yyyy',
              )
            : null,
        }));

        handleChekData(formattedData);
        setLoadingFile(false);
      }
    };
    reader.readAsBinaryString(file);
  };

  // Chamada no X do arquivo ou em "REFAZER"
  const handleCancelFile = () => {
    setFileLoaded(null);
    setEtapa(2);
    setCheckedData(null);
    setDataErros([]);
    setOpenConfirmCancel(false);
  };

  // Chamada quando carrega uma planilha modelo com algum dado
  const handleChekData = async data => {
    const res = await checkUpload(data);
    if (res.data.success) {
      setCheckedData(res.data.data);

      setTimeout(() => {
        setEtapa(4);
      }, 3000);
    } else if (res.data.errors) {
      setDataErros(res.data.errors);
    }
  };

  // Função final. Só é chamada após as validações pelo botao SALVAR
  const handleSave = async () => {
    setSaving(true);
    // Salva apenas marcados
    const saveData = checkedData.filter(item => selectedData.includes(item.id));

    const res = await createMany(saveData);
    if (res.status === 200) {
      toast.success(res.data.message);
      navigate('/acidentes');
    } else if (res.data?.message) {
      toast.error(res.data.message);
    }
    setSaving(false);
    setOpenConfirmSave(false);
  };

  // Download e carregamento do arquivo
  const renderEtapa1_2 = () => {
    return (
      <S.StyledCard>
        <Grid container>
          <Grid item xl={5} md={5} xs={12}>
            <S.Content>
              <img src={csvDownload} alt="csv_download" />
              <S.TextContent>
                <h2>1. Baixar modelo</h2>

                <h3>{text.baixarModelo}</h3>

                <DefaultButton
                  startIcon={<SaveAlt />}
                  onClick={handleDownload}
                  href={isProvider ? templateFileProvider : templateFile}
                  loading={downloading}
                >
                  BAIXAR MODELO
                </DefaultButton>
              </S.TextContent>
            </S.Content>
          </Grid>

          {/* Divider vertical só aparece em telas maiores */}
          <Grid
            item
            xl={1}
            md={1}
            justifyContent="center"
            display={{ xs: 'none', md: 'flex', xl: 'flex' }}
          >
            <S.DividerVertical />
          </Grid>

          {/* Divider horizontal só aparece em telas menores */}
          <Grid
            item
            xs={12}
            justifyContent="center"
            display={{ xs: 'flex', md: 'none', xl: 'none' }}
          >
            <S.DividerHorizontal />
          </Grid>

          <Grid item xl={5} md={5} xs={12}>
            <S.Content>
              <img src={csvUpload} alt="csv_upload" />
              <S.TextContent>
                <h2>2. Fazer upload</h2>

                <h3>{text.fazerUpload}</h3>

                <label htmlFor="contained-button-file">
                  <S.InputFile
                    accept=".xlsx, .xls"
                    id="contained-button-file"
                    type="file"
                    onChange={handleLoadFile}
                  />
                  <DefaultButton
                    startIcon={<SaveAlt sx={{ transform: 'rotate(180deg)' }} />}
                    component="span"
                    disabled={etapa < 2}
                  >
                    FAZER UPLOAD
                  </DefaultButton>
                </label>
              </S.TextContent>
            </S.Content>
          </Grid>
        </Grid>
      </S.StyledCard>
    );
  };

  // Após carregar arquivo
  // Mostra erros a serem corrigidos
  const renderEtapa3 = () => {
    return (
      <S.StyledCard>
        <S.UploadContent>
          <img src={csvUpload} alt="csv_upload" />

          {dataErros.length > 0 && (
            <SvgIcon
              component={ProhibitedIco}
              htmlColor="red"
              style={{ fontSize: '32px', margin: '10px' }}
            />
          )}

          <h2>
            {loadingFile
              ? 'Carregando...'
              : dataErros.length > 0
              ? 'Erro no carregamento '
              : ''}
          </h2>

          {etapa < 4 && <h3>{text.sendoEnviado}</h3>}
          {fileLoaded && (
            <S.FileLoaded>
              <p>{fileLoaded.name}</p>
              <IconButton
                size="small"
                onClick={() => setOpenConfirmCancel(true)}
              >
                <Close
                  fontSize="15px"
                  htmlColor={theme.palette.words.subtitle.natural}
                  style={{ strokeWidth: '1.5px' }}
                />
              </IconButton>
            </S.FileLoaded>
          )}

          {dataErros.length > 0 && (
            <S.ErrorContainer>
              <S.ErrorHeader>
                <WarningIco
                  strokeWidth="1.5"
                  stroke="red"
                  fill="none"
                  fontSize="medium"
                />
                <h1>{text.contemErros}</h1>
              </S.ErrorHeader>

              <Grid container spacing={2}>
                {dataErros.map(msgErro => (
                  <Grid item xs={6} md={6} xl={6}>
                    <p>{msgErro}</p>
                  </Grid>
                ))}
              </Grid>
            </S.ErrorContainer>
          )}

          {checkedData && !dataErros.length > 0 && (
            <S.SuccessContainer>
              <CheckCircleOutline
                htmlColor={theme.palette.semantics.feedback.success.natural}
                fontSize="medium"
              />
              <h3>{text.successo}</h3>
            </S.SuccessContainer>
          )}
        </S.UploadContent>
      </S.StyledCard>
    );
  };

  // Etapa 4: exibe dados validados para adicionar
  const renderTable = () => {
    return (
      <Table
        columns={isProvider ? columnsCreateManyProvider : columnsCreateMany}
        data={checkedData}
        sortBy={sortBy}
        permitIsSortedOccur
        setSelectedData={setSelectedData}
        selectedDefault={checkedData.map(i => i.id)} // Seleciona tudo por padrao
      />
    );
  };

  // Componentes da página
  const renderMain = () => (
    <S.Main>
      <S.TitleWrapper>
        <h1>Adicionar em Massa - Acidentes</h1>
        <div>
          {etapa > 2 && (
            <GhostButton
              size="medium"
              onClick={() => setOpenConfirmCancel(true)}
            >
              REFAZER
            </GhostButton>
          )}
          {checkedData && etapa === 4 && (
            <DefaultButton
              size="medium"
              sx={{ marginLeft: '15px' }}
              onClick={() => setOpenConfirmSave(true)}
              loading={saving}
            >
              SALVAR E AVANÇAR
            </DefaultButton>
          )}
        </div>
      </S.TitleWrapper>

      <div style={{ padding: '15px 0px' }}>
        <InfoCard
          message={
            etapa < 4
              ? 'Adicione seus acidentes de uma só vez! Baixe nosso modelo de planilha, preencha os dados e faça o upload do arquivo.'
              : 'Revise todos os dados que serão inseridos. Após clicar em "salvar e avançar", todos os acidentes serão cadastrados.'
          }
        />
      </div>

      {etapa < 3 && renderEtapa1_2()}
      {etapa === 3 && renderEtapa3()}
      {etapa === 4 && renderTable()}
    </S.Main>
  );

  return (
    <>
      {renderMain()}

      <ConfirmModal
        open={openConfirmCancel}
        handleClose={() => setOpenConfirmCancel(false)}
        title="Deseja excluir arquivo carregado?"
        titleIcon={<DeleteIco />}
        subtitle="Você deverá refazer o carregamento da planiha."
        buttonText="Excluir"
        onClick={handleCancelFile}
        isNegative
      />

      <ConfirmModal
        open={openConfirmSave}
        handleClose={() => setOpenConfirmSave(false)}
        title="Deseja confirmar adição dos acidentes?"
        // titleIcon={<DeleteIco />}
        subtitle={
          selectedData?.length === checkedData?.length
            ? 'Todos os acidentes listados serão adicionados.'
            : 'Você realmente deseja descartar os acidentes não selecionados?'
        }
        buttonText="Confirmar"
        onClick={handleSave}
      />
    </>
  );
};

export default AddAcidentes;
