import React from 'react';
import * as S from './styled';
import { Link } from './Link';
import { Table } from './Table';
import Loading from 'components/Loading';

export const SingleTable = ({
  title,
  subtitle,
  link,
  data = [],
  columns = [],
  loading = false,
}) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Subtitle>{subtitle}</S.Subtitle>
      {loading ? (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      ) : (
        <Table data={data} columns={columns} />
      )}
      {link && <Link {...link} />}
    </S.Container>
  );
};
