import styled, { css, keyframes } from 'styled-components';

const opacity = keyframes`
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
`;

export const Container = styled.div`
  .boxLoading {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
`;

export const BoxArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.system.overlay};
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    border-radius: 5px;
    width: 100%;
    padding: 15px 70px;
    margin-top: 20px;
  `}
`;

export const UserArea = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    .topArea {
      position: relative;
      display: flex;

      .ring--imageArea {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 2px solid ${theme.palette.brand.secondary.natural};
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .image {
        width: 62px;
        height: 62px;
        border-radius: 50%;
        border: 2px solid ${theme.palette.system.border};
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${props.logo
          ? theme.palette.system.black
          : theme.palette.system.border};

        img {
          width: 100%;
          border-radius: 200px;
        }

        span {
          font-size: 20px;
          font-weight: 700;
          color: ${theme.palette.words.title.natural};
        }
      }

      .changePhotoArea {
        top: 0;
        right: 0;
        margin-left: -20px;
        background-color: ${theme.palette.system.overlay};
        width: 32px;
        height: 32px;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0px 3px 6px ${theme.palette.system.shadow};
        display: flex;
        justify-content: center;
        align-items: center;

        input {
          display: none;
        }
      }
    }

    .bottomArea {
      margin-top: 10px;
      font-size: 24px;
      width: 100%;
      font-weight: 700;
      color: ${theme.palette.words.title.natural};
    }
  `}
`;

export const PlainArea = styled.div`
  ${({ theme, ...props }) => css`
    background-color: ${theme.palette.words.title.natural};
    border-radius: 5px;
    width: 100%;
    height: 185px;
    margin-top: 20px;
    padding: 20px 25px;

    .topPlain {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftArea {
        display: flex;
        align-items: center;
        color: ${theme.palette.words.text.contrast};

        .plainTitle {
          font-weight: 700;
          font-size: 18px;
        }

        .plainCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          border: 1px solid ${theme.palette.brand.secondary.natural};
          border-radius: 40px;
          padding: 0px 8px;

          span {
            font-size: 13px;
          }
        }
      }
    }

    .bottomPlain {
      margin-top: 12px;

      .rowText {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: ${theme.palette.words.text.contrast};

        .textLeft {
          display: flex;

          .textFaixas {
            opacity: 0.8;
          }

          .numberFaixas {
            margin-left: 15px;
            font-weight: 700;
          }
        }

        .textRight {
          opacity: 0.8;
        }
      }

      .rowBar {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        position: relative;

        .emptyBar {
          height: 5px;
          width: 100%;
          background-color: ${theme.palette.system.border};
          border-radius: 20px;
        }

        .completedBar {
          border-radius: 20px;
          background: ${props.colorBar};
          background-size: 400% 400%;
          height: 5px;
          width: ${props.percentage}%;
          position: absolute;
          animation: ${opacity} 2s ease infinite;
        }
      }
    }
  `}
`;

export const TitleSection = styled.div`
  ${({ theme }) => css`
    font-size: 20px;
    color: ${theme.palette.words.title.natural};
    font-weight: 700;
    width: 100%;
    text-align: start;
    margin-top: 30px;
    margin-bottom: 20px;
  `}
`;

export const BoxInformation = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    padding: 18px;

    .image {
      img {
        width: 100%;
        border-radius: 200px;
      }
      span {
        font-size: 20px;
        font-weight: 700;
        color: ${theme.palette.words.title.natural};
      }
    }
  `}
`;

export const CompanyInfo = styled.div`
  ${({ theme }) => css`
    width: 100%;

    .image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      border: 2px solid ${theme.palette.system.border};
      align-items: center;
      justify-content: center;
    }

    .green {
      border: 2px solid ${theme.palette.brand.secondary.natural}!important;
    }

    .infoName {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;

      .nameItem {
        font-size: 13px;
        color: ${theme.palette.words.subtitle.natural};
        opacity: 0.8;
        font-weight: 600;
      }

      .nameCustom {
        font-size: 16px;
        color: ${theme.palette.words.title.natural};
        font-weight: 700;
      }
    }

    label {
      font-size: 14px;
      color: ${theme.palette.words.subtitle.natural};
      font-weight: bold;
      opacity: 0.8;
    }
  `}
`;

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
`;
