import { useTheme } from 'styled-components';

import { Icon, IconButton } from '@mui/material';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import * as S from './styled';

const ModalAdd = ({ open, onClose, setModalTitle, setModalTemplate }) => {
  const theme = useTheme();
  return (
    <S.ModalArea open={open} onClose={onClose} disableEscapeKeyDown={false}>
      <S.Container>
        <S.Header>
          <div style={{ alignItems: 'center' }}>
            <FormatListBulletedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <h2>Criar Formulário</h2>
          </div>

          <IconButton size="small" onClick={onClose}>
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <S.Description>
          {' '}
          Você poderá criar um formulário clicando em "Criar formulário do zero"
          ou poderá copiar um formulário já existente e editar como preferir
          clicando em "Utilizar template".
        </S.Description>

        <S.Body>
          <S.Box
            onClick={() => {
              setModalTitle(true);
              onClose();
            }}
          >
            <AddCircleOutlineOutlinedIcon
              htmlColor={theme.palette.brand.primary.light}
            />
            <span>Criar formulário do zero</span>
          </S.Box>
          <S.Box
            onClick={() => {
              setModalTemplate(true);
              onClose();
            }}
          >
            <BookOutlinedIcon htmlColor={theme.palette.brand.primary.light} />
            <span>Utilizar template</span>
          </S.Box>
        </S.Body>
      </S.Container>
    </S.ModalArea>
  );
};

export default ModalAdd;
