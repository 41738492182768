import { combineReducers } from 'redux';
import auth from './auth/reducer';
import createContents from './contents/reducer';
import filter from './filter/reducer';
import filterFrota from './filterFrota/reducer';
import filterDriver from './filterDriver/reducer';
import filterRankingDriver from './filterRankingDriver/reducer';
import filterPad from './filterPad/reducer';
import selects from './selects/reducer';
import pageMap from './pageMap/reducer';
import filterLayout from './filterLayout/reducer';
import filterDesvio from './filterDesvio/reducer';
import filterDesvios from './filterDesvios/reducer';
import filterDesvioDescarga from './filterDesvioDescarga/reducer';
import filterDesviosConfiguracoes from './filterDesviosConfiguracoes/reducer';
import filterViolacao from './filterViolacao/reducer';
import filterAvaliacoes from './filterAvaliacoes/reducer';
import filterAvaliacoesDescarga from './filterAvaliacoesDescarga/reducer';
import filterMinhasTarefas from './filterMinhasTarefas/reducer';
import formularios from './formularios/reducer';
import filterRotograma from './filterRotograma/reducer';
import filterFormulario from './filterFormulario/reducer';
import filterFormularioMeus from './filterFormularioMeus/reducer';
import filterVagas from './filterVagas/reducer';
import filterMotoristaCronograma from './filterMotoristaCronograma/reducer';
import filterAcoesSuspensoes from './filterAcoesSuspensoes/reducer';
import filterFadigaDesvios from './filterFadigaDesvios/reducer';
import filterFadigaRanking from './filterFadigaRanking/reducer';
import filterFadigaDesempenho from './filterFadigaDesempenho/reducer';
import filterFadigaAreaRisco from './filterFadigaAreaRisco/reducer';
import filterFadigaFatoresRisco from './filterFadigaFatoresRisco/reducer';
import filterFadigaTratativaDesvios from './filterFadigaTratativaDesvios/reducer';
import filterTelemetriaDesvios from './filterTelemetriaDesvios/reducer';
import filterTelemetriaRanking from './filterTelemetriaRanking/reducer';
import filterTelemetriaDesempenho from './filterTelemetriaDesempenho/reducer';
import filterTelemetriaAreaRisco from './filterTelemetriaAreaRisco/reducer';
import filterTelemetriaFatoresRisco from './filterTelemetriaFatoresRisco/reducer';
import filterTelemetriaTratativaDesvios from './filterTelemetriaTratativaDesvios/reducer';
import filterCapacitacaoAutomacao from './filterCapacitacaoAutomacao/reducer';
import filterConteudo from './filterConteudo/reducer';
import header from './header/reducer';
import myTasks from './myTasks/reducer';
import version from './version/reducer';
import tables from './tables/reducer';
import guiaQueue from './guia/reducer';

// Acesso Provider
import filterProvider from './provider/filterProvider/reducer';
import filterDesvioProvider from './provider/filterDesvioProvider/reducer';
import filterDesvioDescargaProvider from './provider/filterDesvioDescargaProvider/reducer';
import companies from './provider/companies/reducer';
import filterRevisaoOperacao from './provider/filterRevisaoOperacao/reducer';
import filterExclusaoDesvio from './provider/filterExclusaoDesvio/reducer';
import filterLayoutProvider from './provider/filterLayoutProvider/reducer';
import filterEstatisticasDesviosProvider from './provider/filterEstatisticasDesviosProvider/reducer';
import filterCapacitacaoProvider from './provider/filterCapacitacaoProvider/reducer';
import conteudoProvider from './provider/capacitacaoConteudo/reducer';
import filterFadigaDesviosProvider from './provider/filterFadigaDesviosProvider/reducer';
import filterFadigaRankingProvider from './provider/filterFadigaRankingProvider/reducer';
import filterTelemetriaDesviosProvider from './provider/filterTelemetriaDesviosProvider/reducer';
import filterTelemetriaRankingProvider from './provider/filterTelemetriaRankingProvider/reducer';
import filterDescargaRankingProvider from './provider/filterDescargaRankingProvider/reducer';
import filterDirecaoRankingProvider from './provider/filterDirecaoRankingProvider/reducer';
import filterFrotaProvider from './provider/filterFrotaProvider/reducer';
import filterDriverProvider from './provider/filterDriverProvider/reducer';
import filterEmpresaProvider from './provider/filterEmpresaProvider/reducer';
import filterRankingDriverProvider from './provider/filterRankingDriverProvider/reducer';
import filterDesviosConfiguracoesProvider from './provider/filterDesviosConfiguracoesProvider/reducer';
import filterEmpresasRankingProvider from './provider/filterEmpresasRankingProvider/reducer';
import filterAcoesSuspensoesProvider from './provider/filterAcoesSuspensoesProvider/reducer';

// type para limpar o redux
const DESTROY_SESSION = '@DESTROY_SESSION';

const appReducer = combineReducers({
  auth,
  createContents,
  filter,
  filterDesvio,
  filterDesvios,
  filterDesvioDescarga,
  filterDesviosConfiguracoes,
  filterViolacao,
  filterFrota,
  filterDriver,
  filterRankingDriver,
  filterPad,
  filterLayout,
  filterAvaliacoes,
  filterAvaliacoesDescarga,
  filterMinhasTarefas,
  formularios,
  filterRotograma,
  filterFormulario,
  filterFormularioMeus,
  filterMotoristaCronograma,
  filterAcoesSuspensoes,
  filterFadigaDesvios,
  filterFadigaRanking,
  filterFadigaDesempenho,
  filterFadigaAreaRisco,
  filterFadigaFatoresRisco,
  filterFadigaTratativaDesvios,
  filterTelemetriaDesvios,
  filterTelemetriaRanking,
  filterTelemetriaDesempenho,
  filterTelemetriaAreaRisco,
  filterTelemetriaFatoresRisco,
  filterTelemetriaTratativaDesvios,
  filterCapacitacaoAutomacao,
  filterVagas,
  filterConteudo,
  selects,
  pageMap,
  header,
  myTasks,
  version,
  tables,
  guiaQueue,

  // Acesso Provider
  filterProvider,
  filterDesvioProvider,
  filterDesvioDescargaProvider,
  filterRevisaoOperacao,
  filterExclusaoDesvio,
  filterLayoutProvider,
  filterEstatisticasDesviosProvider,
  filterCapacitacaoProvider,
  filterFadigaDesviosProvider,
  filterFadigaRankingProvider,
  filterTelemetriaDesviosProvider,
  filterTelemetriaRankingProvider,
  conteudoProvider,
  companies,
  filterDescargaRankingProvider,
  filterDirecaoRankingProvider,
  filterFrotaProvider,
  filterDriverProvider,
  filterEmpresaProvider,
  filterRankingDriverProvider,
  filterEmpresasRankingProvider,
  filterDesviosConfiguracoesProvider,
  filterAcoesSuspensoesProvider,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) state = undefined;
  return appReducer(state, action);
};

export default rootReducer;
