import React from 'react';
import { useTheme } from 'styled-components';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import { useLocation, useParams } from 'react-router-dom';
import Header from '../components/Header';
import { Content, Features, PartnerList, PlanDetails, Wrapper } from './styled';
import Form from './Form';
import { plans, features } from '../components/Plans/constants';

const Detalhe = () => {
  const theme = useTheme();
  const params = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const provider = query.get('provider') === 'true';

  const plan = plans.find(p => p.id == params.id);

  return (
    <Wrapper>
      <Header hasNavbar={false} />
      <Content>
        <PlanDetails>
          <h1>
            Cadastre-se e solicite demonstração do plano{' '}
            <span>{plan.name}</span>
          </h1>
          <p
            dangerouslySetInnerHTML={{
              __html: provider
                ? plan.descriptionProvider.replace('\n', '<br /><br />')
                : plan.description.replace('\n', '<br /><br />'),
            }}
          />

          <Features>
            <h3>Principais Funcionalidades</h3>
            <ul>
              {features
                .filter(feat => plan.features.includes(feat.id) && !feat.plugin)
                .map(feature => (
                  <li key={feature.id}>
                    <CheckRoundedIcon
                      sx={{
                        color: theme.palette.semantics.feedback.success.natural,
                        width: '12px',
                      }}
                    />
                    {feature.name}
                  </li>
                ))}
              <li>
                <CheckRoundedIcon
                  sx={{
                    color: theme.palette.semantics.feedback.success.natural,
                    width: '12px',
                  }}
                />
                E muito mais...
              </li>
            </ul>
            <br />
            <ul>
              {features
                .filter(feat => plan.features.includes(feat.id) && feat.plugin)
                .map(feature => (
                  <li key={feature.id}>
                    <AddRoundedIcon
                      sx={{
                        color: theme.palette.semantics.feedback.success.natural,
                        width: '12px',
                      }}
                    />
                    {feature.name}
                  </li>
                ))}
            </ul>
          </Features>
          <p>* Estas funcionalidades podem ser adicionadas ao seu plano.</p>
        </PlanDetails>
        <Form plan={plan} provider={provider} />
      </Content>
      <PartnerList>
        <p>
          Descubra o porque as melhores empresas do país confiam em nós e têm
          seus processos de segurança aprimorados com a Onisys:
        </p>
        <ul>
          <li>
            <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f20f84d2f820d_Grupo%2019954.svg" />
          </li>
          <li>
            <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f20fe5a2f820c_Grupo%2019953.svg" />
          </li>
          <li>
            <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f2015d22f820e_Grupo%2019952.svg" />
          </li>
          <li>
            <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faae76f86571bd320411dd_Grupo%2021552.png" />
          </li>
          <li>
            <img src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faae76f865710c2b0411e3_Grupo%2021553.png" />
          </li>
          <li>
            <img src="https://uploads-ssl.webflow.com/62d6d4b56a3808f21606dbad/63f59494863c676458e30307_Grupo%2021522.png" />
          </li>
        </ul>
      </PartnerList>
    </Wrapper>
  );
};

export default Detalhe;
