import React from 'react';
import { Actions as Container } from '../styled';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

const Actions = ({ actions }) => {
  return (
    <Container>
      {actions.map((action, idx) => {
        switch (action.variant) {
          case 'ghost':
            return (
              <GhostButton
                key={idx}
                startIcon={action.icon}
                size="medium"
                loading={action.loading}
                onClick={
                  action.function instanceof Function
                    ? action.function
                    : undefined
                }
              >
                {action.title}
              </GhostButton>
            );

          default:
            return (
              <DefaultButton
                key={idx}
                startIcon={action.icon}
                size="medium"
                loading={action.loading}
                onClick={
                  action.function instanceof Function
                    ? action.function
                    : undefined
                }
              >
                {action.title}
              </DefaultButton>
            );
        }
      })}
    </Container>
  );
};

export default Actions;
