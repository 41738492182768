import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  `}
`;

export const PhoneScale = styled.div`
  ${({ theme }) => css`
    border: 12px solid ${theme.palette.system.border};
    border-radius: 20px;
    width: 320px;
    height: 500px;

    .loadControll {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  `}
`;

export const TitlePrev = styled.h4`
  ${({ theme }) => css`
    font-size: 20px;
    color: ${theme.palette.words.subtitle.natural};
    margin: 10px;
  `}
`;
