import api from 'services/api';

export const register = async (id, guia) => {
  const res = await api.post(`/guide-steps/${id}`, { guia });
  return res.data;
};

export const saveInfos = async data => {
  const res = await api.put('/configuracoes/update_empresa', data);
  return res.data;
};

export const saveFiliais = async data => {
  const res = await api.post(`/configuracoes/create_many_filial`, data);
  return res.data;
};

export const saveClientes = async data => {
  const res = await api.post(`/configuracoes/relacaoManyDistribuidoras`, data);
  return res.data;
};

export const checkUpload = async data => {
  const res = await api.post(`/drivers/checkUpload`, data);
  return res;
};

export const createUsuario = async data => {
  const res = await api.post('/configuracoes/userExists', data);
  return res;
};

export const updateUsuario = async data => {
  const res = await api.put('/configuracoes/user', data);
  return res;
};

export const createManyMotoristas = async data => {
  const res = await api.post(`/drivers/storeMany`, data);
  return res;
};

export const createDriver = async data => {
  const res = await api.post(`/driver`, data);
  return res;
};

export const updateDriver = async (id, data) => {
  const res = await api.put(`/driver/${id}`, data);
  return res;
};

export const selectClients = async () => {
  const res = await api.get('/configuracoes/distribuidorasAll');
  return res.data;
};
