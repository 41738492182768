import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 250px;
    height: 100%;

    position: fixed;

    background: ${theme.palette.system.overlay};
    box-shadow: 0px 3px 15px ${theme.palette.system.black}1a;
    margin: -12px;
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .MuiList-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      div {
        height: 60px;
        display: flex;
        align-items: center;
        span {
          font-size: 16px;
          font: normal medium 16px/24px Texta;
        }
      }
    }
  `}
`;

export { Wrapper };
