import { Button } from '@mui/material';
import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 24px;
    color: ${theme.palette.brand.primary.natural};
    font-weight: 900;
    letter-spacing: 1.1px;
  `}
`;

export const HeaderActions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  && {
    text-transform: none;
    color: ${props => props.textcolor};
    background: ${props => props.backgroundcolor};
    border: 2px solid ${props => props.theme.palette.brand.primary.light};
    font: normal normal 400 16px/24px Texta;
    height: ${props => props.height || '38px'};
    padding: 5px 15px;
    transition: opacity 300ms;
    box-shadow: none;

    :hover {
      background: ${props => props.backgroundcolor};
      border-color: black;
      opacity: 0.7;
    }

    :disabled {
      background: ${props => props.theme.palette.system.disabled};
      opacity: 0.5;
    }
  }
`;

export const TooltipContainer = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #cccccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 12px;
  .title {
    font-weight: bold;
  }
`;
