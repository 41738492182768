import { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import { EmailOutlined } from '@mui/icons-material';
import Icon from 'components/Icons';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import Loading from 'components/Loading';
import { ReactComponent as SeguindoIco } from 'images/icons/seguindo.svg';
import { ReactComponent as SeguirIco } from 'images/icons/seguir.svg';
import { InputTags } from 'components/InputTags';

import * as S from './styled';
import { toast } from 'react-toastify';

const EmailSeguidor = ({ posto, handleClose, handleSeguir, handleSalvar }) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState(null);
  const [canSave, setCanSave] = useState(false);

  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const userEmail = user.email;

  const onClose = () => {
    setEmails([]);
    if (handleClose instanceof Function) handleClose();
  };

  const onSeguir = async () => {
    if (loading) return;

    setLoading(true);
    await handleSeguir(posto);
    setLoading(false);
  };

  const onSalvar = async () => {
    if (!canSave) {
      toast.warning(
        'Nenhuma alteração aplicada. Verifique se o e-mail foi inserido em tag',
      );
      return;
    }
    setLoading(true);
    const emailsSalvar = emails.map(item => item.email);
    await handleSalvar(posto.id, emailsSalvar);
    setLoading(false);
    setCanSave(false);
  };

  const seguindo = posto?.seguindo;

  useEffect(() => {
    if (posto) {
      const emailsSalvos = posto.emails_posto.map(item => {
        return { ...item, blocked: item.seguidor };
      });

      // Primeiro carregamento
      if (emails === null) {
        setEmails(emailsSalvos);
        return;
      }
      // Trata seguir e desseguir
      const userEmailSalvo = emailsSalvos.find(item => item.email == userEmail);
      const userEmailListed = emails.some(item => item.email == userEmail);
      if (userEmailSalvo && !userEmailListed) {
        const newEmails = [userEmailSalvo, ...emails];
        setEmails(newEmails);
      } else if (!userEmailSalvo && userEmailListed) {
        const newEmails = emails.filter(item => item.email !== userEmail);
        setEmails(newEmails);
      }
      // Se não é uma dessas condições libera o botão de salvar
      else {
        const cEmails = emails.map(item => item.email).join('');
        const cEmailsSalvos = emailsSalvos.map(item => item.email).join('');
        if (cEmails != cEmailsSalvos) setCanSave(true);
      }
    }
  }, [emails]);

  return (
    <Modal
      open={Boolean(posto)}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <S.Container>
        <S.Header>
          <div style={{ alignItems: 'center' }}>
            <div>
              <EmailOutlined
                sx={{ color: theme.palette.brand.secondary.natural }}
                fontSize="medium"
              />
              <h2>Configurar notificações por e-mail</h2>
            </div>
          </div>

          <IconButton size="medium" onClick={() => onClose()}>
            <Icon
              sx={{ color: theme.palette.words.subtitle.natural }}
              name="close"
              size="30px"
            />
          </IconButton>
        </S.Header>

        {emails ? (
          <S.Main>
            <div className="titleContainer">
              <h2 className="title">{posto.nome}</h2>
              <h3 className="ibm">{`IBM: ${posto.ibm}`}</h3>
            </div>

            <S.Seguir seguindo={seguindo} onClick={onSeguir}>
              {seguindo ? (
                <SeguindoIco className="ico" />
              ) : (
                <SeguirIco className="ico" />
              )}
              <div>
                <h2 className="title">
                  {seguindo
                    ? 'Você está seguindo esse layout'
                    : 'Você não segue esse layout'}
                </h2>
                <h3 className="subtitle">
                  {seguindo
                    ? 'Parar de seguir'
                    : 'Siga para receber notificações no seu e-mail'}
                </h3>
              </div>
            </S.Seguir>

            <h2 className="titleEmails">Notificações por e-mail</h2>

            <h2 className="cabecalho">E-mails</h2>

            <InputTags
              tags={emails}
              setTags={setEmails}
              tagKey="email"
              validationKey="email"
              placeHolder="Insira um e-mail"
            />

            <h2 className="cabecalho">
              Cadastre e-mails para serem notificados dos seguintes itens:
            </h2>

            <S.Notificacoes>
              {posto?.tipos_notificacoes &&
                posto.tipos_notificacoes.map(item => (
                  <p className="item">{`- ${item.titulo}`}</p>
                ))}
            </S.Notificacoes>
          </S.Main>
        ) : (
          <Loading />
        )}

        <S.Footer>
          <GhostButton onClick={() => onClose()} size="medium">
            Cancelar
          </GhostButton>

          <DefaultButton
            size="medium"
            sx={{ marginLeft: '20px' }}
            loading={loading}
            onClick={() => onSalvar()}
          >
            Salvar
          </DefaultButton>
        </S.Footer>
      </S.Container>
    </Modal>
  );
};

export default EmailSeguidor;
