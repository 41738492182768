import styled, { css } from 'styled-components';

import { Modal } from '@mui/material';

export const ModalArea = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 540px;
    height: 365px;
    background-color: ${theme.palette.system.overlay};
    padding: 10px 30px;
    transition: all ease 0.5s;
    border-radius: 5px;
    box-shadow: 0px 0px 15px ${theme.palette.system.black};
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid ${theme.palette.brand.primary.light}4d;

    div {
      display: flex;
      flex-direction: row;
      h2 {
        margin-left: 10px;
      }
    }
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.palette.words.subtitle.natural};
  `}
`;

export const Body = styled.div`
  display: flex;
`;

export const Box = styled.div`
  ${({ theme }) => css`
    width: 227px;
    height: 170px;
    border: 1px solid ${theme.palette.brand.primary.light};
    margin: 15px;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      margin-top: 5px;
      font-size: 15px;
      color: ${theme.palette.words.title.natural};
    }

    &:hover {
      background-color: ${theme.palette.brand.primary.background};
    }
  `}
`;
