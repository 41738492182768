export const columnsTransportador = [
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: false,
    align: 'center',
  },
  {
    header: 'Email',
    id: 'email',
    type: 'string',
    sortType: 'basic',
    sort: false,
    align: 'center',
  },
  {
    header: 'Filial',
    id: 'filial.nome',
    type: 'string',
    sort: false,
    align: 'center',
  },
  {
    header: 'Função',
    id: 'funcao',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Matrícula',
    id: 'matricula',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Perfil de acesso',
    id: 'usuario_nivel.titulo',
    type: 'string',
    sort: true,
    align: 'center',
  },
];

export const columnsEmbarcador = [
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: false,
  },
  {
    header: 'Email',
    id: 'email',
    type: 'string',
    sort: false,
  },
  {
    header: 'Função',
    id: 'funcao',
    type: 'string',
    sort: true,
  },
  {
    header: 'Matrícula',
    id: 'matricula',
    type: 'string',
    sort: true,
  },
  {
    header: 'Perfil de acesso',
    id: 'usuario_nivel.titulo',
    type: 'string',
    sort: true,
  },
];
