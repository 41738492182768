import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import { Modal, IconButton, Icon, Grid, FormControl } from '@mui/material';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import TextInput from 'components/Inputs/TextField';
import RadioGroup from 'components/Inputs/RadioGroup';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import * as S from './styled';

const DeleteModal = ({ desvio, handleClose, handleConfirm, loading }) => {
  const theme = useTheme();

  const title = `Tem certeza que deseja excluir este desvio?`;
  const subtitle = 'O desvio passará para o status Deletado.';

  // Icone do header variável com titleIconName
  const titleIcon = (
    <Icon
      sx={{ color: theme.palette.semantics.feedback.attention.natural }}
      fontSize="medium"
    >
      delete-outline
    </Icon>
  );

  // Objeto de dados a serem preenchidos
  const [data, setData] = useState({
    motivo_exclusao: desvio.motivo_exclusao || '',
    observacao_exclusao: desvio.observacao_exclusao || '',
    arquivo_exclusao: desvio.arquivo_exclusao || '',
  });

  const handleChange = (name, value) => {
    setData(prev => {
      return { ...prev, [name]: value };
    });
  };

  // Bloqueia botão de confirmar quando há item sem preencher
  const checkReady = () => {
    let ready = true;
    if (!data.motivo_exclusao) ready = false;
    else if (!data.arquivo_exclusao) ready = false;
    return ready;
  };

  const handleClickConfirm = () => {
    const sendData = { ...data };
    const req = {
      ...sendData,
      data_exclusao: new Date(),
      ids: [parseInt(desvio.id)],
      // Array para possivel implementacao de bulk-action
    };
    handleConfirm(req);
  };

  const open = Boolean(desvio);

  return (
    <Modal open={open}>
      <S.Paper>
        <S.Header>
          <S.Title>
            {titleIcon}
            <h1>{title}</h1>
          </S.Title>

          <IconButton size="small" onClick={handleClose}>
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <InfoCard message={subtitle} />

        <Grid container rowSpacing={2} columnSpacing={2} marginY={1}>
          <Grid item xs={12} sm={12}>
            <FormControl>
              <RadioGroup
                options={[
                  {
                    value: 'Desvio duplicado',
                    label: 'Desvio duplicado',
                  },
                  {
                    value: 'Desvio incompatível',
                    label: 'Desvio incompatível',
                  },
                ]}
                value={data.motivo_exclusao ?? ''}
                onChange={e => handleChange('motivo_exclusao', e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              label="Observações da exclusão"
              value={data.observacao_exclusao}
              onChange={e =>
                handleChange('observacao_exclusao', e.target.value)
              }
              name="observacao_exclusao"
              maxRows={2}
              multiline
              inputProps={{ width: '100%' }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputAttachedFile
              idInput="input_file_delete"
              required
              label="Arquivo justificativa"
              inputLabel="Anexar Evidência"
              fileUrl={data.arquivo_exclusao}
              setFileUrl={value => handleChange('arquivo_exclusao', value)}
              fileDir={`/desvios-telematics/${desvio.id}`}
              // deleteFromStorage  não apagar arquivo justificativa antigo
            />
          </Grid>
        </Grid>

        <S.Footer>
          <GhostButton size="medium" onClick={handleClose}>
            Cancelar
          </GhostButton>

          <DefaultButton
            onClick={handleClickConfirm}
            size="medium"
            style={{ marginLeft: '10px' }}
            disabled={!checkReady()}
            loading={loading}
          >
            Confirmar
          </DefaultButton>
        </S.Footer>
      </S.Paper>
    </Modal>
  );
};

export default DeleteModal;
