import { toast } from 'react-toastify';
import api from 'services/api';

const requestContents = async query => {
  const res = await api.get('/training/contents', { params: query });
  if (res.data?.success) {
    return res.data.data;
  }
  if (res.data?.message) {
    toast.error(res.data.message);
    return [];
  }
};

const getTamplete = async id => {
  const res = await api.get(`/training/template/${id}`);
  if (res.data?.success) {
    return res.data.data;
  }
  if (res.data?.message) {
    toast.error(res.data.message);
    return [];
  }
};

const answerQuestion = async (data, id) => {
  const res = await api.post(`/training/content/answer-question`, {
    respostas: data,
    id,
  });
  return res;
};

export { requestContents, getTamplete, answerQuestion };
