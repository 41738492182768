import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ height }) => css`
    width: 100%;
    height: ${height || `500px`};
    max-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme, background }) => css`
    width: 260px;
    height: 300px;
    background-image: url(${background});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;

export const Image = styled.img``;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: flex-start;

  button {
    margin-top: 15px;
  }
`;

export const Title = styled.h1`
  width: 400px;
`;

export const Subtitle = styled.h2`
  text-align: left;
`;
