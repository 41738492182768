import styled, { css } from 'styled-components';

export const TemplatePage = styled.div`
  &.isEditTemplate {
    margin-left: 120px;
    width: 100%;
    margin-bottom: 60px;
  }
`;

export const Container = styled.div`
  padding: 5px 150px;
`;

export const ControllerArea = styled.div`
  position: fixed;
  z-index: 998;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 65px;
  background-color: #fff;
  box-shadow: 0px -5px 12px -4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px;

  .statusArea {
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-left: 100px;
  }

  .buttonsArea {
    .backArea {
      margin-right: 20px;
    }
  }
`;

export const ButtonPrev = styled.button`
  ${({ theme }) => css`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000;
    font-size: 18px;
    margin-right: 30px;

    span {
      font-weight: bold;
      color: ${theme.palette.words.subtitle.natural};
      margin-right: 5px;
    }
  `}
`;

export const HeaderPage = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    &.isEditHeader {
      margin-top: 0px;
    }

    .titlePage {
      font-size: 24px;
      color: ${theme.palette.words.title.natural};
      font-weight: 900;
    }

    .headerButtons {
      display: flex;
    }
  `}
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
