/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { Box, Title } from '../styled';

// React
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';

// Components
import Input from 'components/Inputs/TextField';
import SelectOne from 'components/Inputs/SelectOne';
import DropZoneArchives from 'components/Inputs/DropZoneCustom';

// Components MUI
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

// Constants
import { verifyErrors } from './actions';
import { saveUpdate } from '../services';
import { identify, fields } from './constants';

export const Local = ({
  setChanged,
  trySave,
  setTrySave,
  setCurrentStep,
  posto,
  cliente,
  setPosto,
  steps,
  produtos,
  id,
  setLoading,
  isEdit,
}) => {
  const compose = fields[cliente];
  const [errors, setErrors] = useState({});

  const [tanks, setTanks] = useState(posto?.tanques || []);
  const [croqui, setCroqui] = useState(posto?.arquivo_croqui);

  useEffect(() => {
    const data = {
      arquivo_croqui: croqui || null,
      tanques: tanks,
    };
    setChanged(verifyErrors(compose, data));
  }, []);

  // --------------------CROQUI--------------------//
  const handleCroqui = data => {
    setChanged(true);
    setCroqui(data?.[0].imagem_url);
  };

  const handleDeleteCroqui = () => {
    setChanged(true);
    setCroqui(null);
  };

  // --------------------ACTIONS--------------------//
  const handleNewTank = () => {
    setChanged(true);
    setTanks([
      ...tanks,
      {
        id_do_produto: produtos?.[0]?.id,
        produto: produtos?.[0],
      },
    ]);
  };

  const handleDelete = idx => {
    setChanged(true);
    const newTanks = tanks?.filter((_, index) => index !== idx);
    setTanks(newTanks);
  };

  const handleProduct = (idx, value) => {
    const newProduct = produtos.find(item => item.id === value);
    setChanged(true);
    const newTanks = tanks?.map((item, index) =>
      index === idx
        ? { ...item, id_do_produto: newProduct.id, produto: newProduct }
        : item,
    );
    setTanks(newTanks);
  };

  const handleCapacity = (idx, value) => {
    setChanged(true);
    const newTanks = tanks?.map((item, index) =>
      index === idx ? { ...item, capacidade: value } : item,
    );
    setTanks(newTanks);
  };

  // -----------------------SAVE------------------------//
  useEffect(() => {
    if (trySave) {
      const data = {
        arquivo_croqui: croqui || null,
        tanques: tanks,
      };

      const hasErrors = verifyErrors(compose, data, setErrors);
      if (hasErrors) {
        setTrySave(false);
      } else {
        save(data);
      }
    }
  }, [trySave]);

  const save = async data => {
    setLoading(true);
    const res = await saveUpdate({ ...posto, ...data, etapa: 2 }, id);
    setTrySave(false);
    setLoading(false);
    setChanged(false);
    if (res.success) {
      toast.success(res.message);
      setPosto(res?.posto);
      !isEdit && setCurrentStep(steps[2]);
    } else {
      toast.error(res.message);
    }
  };

  // --------------------RENDER--------------------//
  return (
    <S.Container>
      <Title>Local de Entrega/Croqui</Title>
      <Box>
        <DropZoneArchives
          data={croqui ? { imagem_url: croqui } : null}
          multiple={false}
          fileDir="layout"
          addFunction={handleCroqui}
          deleteFunction={handleDeleteCroqui}
          automaticSave
          noTopButton
          unicCenter
          error={errors.arquivo_croqui}
        />
      </Box>

      <Title>Identificar no Croqui</Title>
      <S.Info>
        {identify.map((item, idx) => (
          <p key={`txt_${idx}`}>
            {idx + 1} - {item}
          </p>
        ))}
      </S.Info>

      <Title>Mapeamento de Tanques</Title>
      <S.Square>
        <S.Header>
          <S.Title space={1} className="index">
            Tanque Nº
          </S.Title>
          <S.Title space={1}>Disco</S.Title>
          <S.Title space={2}>Produtos</S.Title>
          <S.Title space={2}>Capacidade (Litros)</S.Title>
          <S.Title space={2} />
        </S.Header>

        {tanks.map((tank, idx) => (
          <S.Line key={idx}>
            <S.Column space={1} className="index">
              {idx + 1}
            </S.Column>
            <S.Column space={1} className="tank">
              <S.Tank
                color={
                  produtos.find(item => item.id === tank?.id_do_produto)?.disco
                }
              />
            </S.Column>
            <S.Column space={2}>
              <SelectOne
                data={produtos.map(item => ({
                  value: item.id,
                  text: item.produto,
                }))}
                value={tank?.id_do_produto}
                tam={250}
                handleChange={value => handleProduct(idx, value)}
                noAsterisk
                error={errors?.tanques?.[idx]?.produto}
              />
            </S.Column>
            <S.Column space={2}>
              <Input
                noAsterisk
                type="number"
                value={tank?.capacidade}
                error={errors?.tanques?.[idx]?.capacidade}
                onChange={e =>
                  handleCapacity(idx, +e.target.value > 0 ? +e.target.value : 0)
                }
              />
            </S.Column>
            <S.Column space={2}>
              <DeleteOutlineOutlinedIcon
                className="trash"
                onClick={() => handleDelete(idx)}
              />
            </S.Column>
          </S.Line>
        ))}

        <S.Additional>
          <S.Button onClick={handleNewTank}>
            <AddCircleOutlineRoundedIcon />
            Adicionar tanque
          </S.Button>
        </S.Additional>
      </S.Square>
    </S.Container>
  );
};
