import { toast } from 'react-toastify';
import api from 'services/api';

const createUser = async data => {
  const res = await api.post('/configuracoes/userExists', data);
  return res;
};
const updateUser = async data => {
  const res = await api.put('/configuracoes/user', data);
  return res;
};

const getMotoristas = async () => {
  const res = await api.get('/training/alunos');
  return res;
};

// inativa motorista
const inativateGestor = async (id, data) => {
  const res = await api.put(`/configuracoes/update-status-user/${id}`, data);
  return res;
};

// inativa motorista
const inativateDriver = async id => {
  const res = await api.put(`driver-change-status/${id}`);
  return res;
};

const getTurmas = async () => {
  const res = await api.get('/training/turmas');
  return res;
};

const addAlunosTurma = async (id, alunos) => {
  const res = await api.get('/training/turmas', {
    id_turma: id,
    alunos,
  });
  return res;
};

const createTurmaAluno = async data => {
  const res = await api.post('/training/create-turma-aluno', data);
  return res;
};

export {
  createUser,
  getMotoristas,
  updateUser,
  getTurmas,
  createTurmaAluno,
  inativateGestor,
  addAlunosTurma,
  inativateDriver,
};
