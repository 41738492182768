import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import DefaultButton from 'components/Buttons/Default';
import TextField from 'components/Inputs/TextField';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';

import * as S from '../styled';
import { usePlans } from 'hooks/usePlans';

const Config = ({ multa }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { isProvider } = usePlans();

  return (
    <S.ColumnWrapper>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          {isProvider ? (
            <>
              <div className="imageArea">
                {multa.empresa?.logo_url ? (
                  <img src={multa.empresa.logo_url} />
                ) : (
                  <span className="letters">{multa.empresa?.nome[0]}</span>
                )}
              </div>
              <div>
                <div className="distributorArea">Empresa</div>
                <div className="distributorName">{multa.empresa.nome}</div>
              </div>
            </>
          ) : (
            <>
              <div className="imageArea">
                {multa.distribuidora?.logo_url ? (
                  <img src={multa.distribuidora.logo_url} />
                ) : (
                  <span className="letters">{multa.distribuidora.nome[0]}</span>
                )}
              </div>
              <div>
                <div className="distributorArea">Cliente</div>
                <div className="distributorName">
                  {multa.distribuidora.nome}
                </div>
              </div>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled
            label="Observações"
            name="observacao"
            placeholder="Preencha a observação"
            id="observacao"
            value={multa.observacao}
            multiline
            rows={4}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <InputAttachedFile
            disabled
            idInput="arquivo"
            label="Arquivo"
            inputLabel="Anexar Arquivo"
            fileUrl={multa.arquivo}
            fileDir="multas/arquivos"
          />
        </Grid>

        <Grid item xs={12} mt={2} display="flex" justifyContent="end" gap={1}>
          <DefaultButton
            children="Editar"
            onClick={() => navigate(`/multas/editar/${params.id}`)}
            disabled={
              (!isProvider && multa.provider) || (isProvider && !multa.provider)
            }
          />
        </Grid>
      </Grid>
    </S.ColumnWrapper>
  );
};

export default Config;
