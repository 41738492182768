import styled, { css } from 'styled-components';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`;

const SelectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

const CollapseContent = styled.div`
  padding: 0 1rem;
`;

const ClearButton = styled.button`
  ${({ theme }) => css`
    border: none;
    background: transparent;
    font: normal 600 14px Texta;
    text-decoration: underline;
    color: ${theme.palette.words.subtitle.natural};
    opacity: 1;
    :hover {
      opacity: 0.6;
    }
  `}
`;

export { Main, Header, ClearButton, SelectsContainer, CollapseContent };
