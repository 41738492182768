import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  desvio: '',
  veiculo: '',
  motoristas: '',
};

export default function filterRankingDirecao(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_PROVIDER: {
        return { ...state, ...action.payload };
      }
      case types.RESET_FILTER_PROVIDER: {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
