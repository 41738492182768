import styled, { css } from 'styled-components';
import Card from '@mui/material/Card';

const StyledPageTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.palette.brand.primary.natural};
    font: normal normal 900 25px/1px Texta;
    margin-right: '20px';
    text-align: 'left';
    display: flex;
    justify-content: flex-start;
  `}
`;

const StyledTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.palette.brand.primary.natural};
    font: normal normal 800 18px/20px Texta;
    margin: 10px 0 10px;
  `}
`;

const StyledMain = styled.div`
  justify-content: center;
  align-content: center;
  display: flex;
  padding-right: 130px;
  padding-left: 130px;
  margin-left: 210px;
  margin-top: 30px;
`;

const StyledCard = styled(Card)`
  ${({ theme }) => css`
    width: 100%;
    padding: 28px;
    border: 1px solid ${theme.palette.brand.primary.light}4d;

    margin: 40px 0;

    input#file-upload-button {
      display: none;
    }
  `}
`;

const StyledLogoWrapper = styled.div`
  ${({ theme }) => css`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid ${theme.palette.brand.secondary.natural};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const StyledLogo = styled.div`
  ${({ theme, ...props }) => css`
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: ${props.border};
    background-image: url(${props.backgroundImage});
    background-size: contain;
    background-color: ${theme.palette.system.border};
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 20px;
      color: ${theme.palette.brand.primary.natural};
      text-align: center;
    }
  `}
`;

const StyledDistributorLogo = styled.div`
  ${({ theme }) => css`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid ${theme.palette.brand.secondary.natural};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const StyledTextWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 16px;
      color: ${theme.palette.words.title.natural};
      font-weight: 600;
      text-transform: capitalize;
    }
    p {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 13px;
      font-weight: 500;
    }
  `}
`;

const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    opacity: 1;
    box-shadow: 0px 5px 10px #12263f08;
    border: 1px solid ${theme.palette.system.border};
    border-radius: 5px;
  `}
`;

const Wrapper2 = styled.div`
  margin: 20px 50px;
  padding-top: ${props => (props.margin ? '250px' : '0px')};
`;

const Header = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.system.border};
    color: ${theme.palette.words.title.natural};
    width: 100%;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1.1px;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;

    ${({ initial }) =>
      initial &&
      `
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `};

    h3 {
      flex: 1;
      font-size: 18px;
    }

    span {
      display: flex;
      align-items: center;
      margin-right: 20px;
      p {
        color: ${theme.palette.words.subtitle.natural};
        font-size: 16px;
        font-weight: 500;
        text-transform: none;
      }
    }
  `}
`;

const Row = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 70px;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    background: ${props =>
      props.count % 2 === 0
        ? theme.palette.system.transparent
        : theme.palette.system.white};
    border-top: 1px solid ${theme.palette.system.border};

    p {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 15px;
    }
    :last-child {
      margin-bottom: 8px;
    }
  `}
`;

const Button = styled.div`
  ${({ theme, value }) => css`
    display: flex;
    flex-direction: row;
    ${({ value }) => {
      return (
        value == 'SIM' &&
        `
    div:nth-child(1) {
      border-color: ${theme.palette.semantics.feedback.success.natural} !important;
      background: ${theme.palette.semantics.feedback.success.natural} !important;
      color: ${theme.palette.words.text.contrast};
    }
  `
      );
    }}

    ${value == 'NAO' &&
    `
    div:nth-child(2) {
      border-left-width: 1px;
      border-right-width: 1px;
      border-color: ${theme.palette.semantics.feedback.attention.natural} !important;
      background: ${theme.palette.semantics.feedback.attention.natural} !important;
      color: ${theme.palette.words.text.contrast};
    }
    div:nth-child(1) {
      border-right-width: 1px;
      border-right-color: ${theme.palette.semantics.feedback.attention.natural} !important;
    }
    div:nth-child(3) {
      border-left-width: 1px;
      border-left-color: ${theme.palette.semantics.feedback.attention.natural} !important;
    }
  `}

  ${value == 'N/A' &&
    `
    div:nth-child(3) {
      border-color: ${theme.palette.semantics.feedback.information.natural} !important;
      background: ${theme.palette.semantics.feedback.information.light} !important;
      color: ${theme.palette.semantics.feedback.information.natural};
    }
  `}

  div {
      width: 38px;
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${theme.palette.brand.primary.light};
      color: ${theme.palette.words.text.light};
      cursor: pointer;
      background: ${theme.palette.system.overlay};

      &:nth-child(1) {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        width: 45px;
        height: 33px;
      }

      &:nth-child(2) {
        border-left-width: 0px;
        border-right-width: 0px;
        width: 45px;
        height: 33px;
      }

      &:nth-child(3) {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        width: 45px;
        height: 33px;
      }
    }
  `}
`;

const StyledToast = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid ${theme.palette.semantics.feedback.information.natural};
    border-radius: 4px;
    background: ${theme.palette.semantics.feedback.information.light} 0% 0%
      no-repeat padding-box;
    height: 40px;
    padding: 11px;
    h1 {
      width: 100%;
      color: ${theme.palette.brand.primary.natural};
      font-size: 14px;
      margin-left: 15px;
    }
  `}
`;

const StorageImage = styled.img`
  width: 100%;
  height: 250px;
  border-radius: 4px;
`;

const Expand = styled.div`
  position: absolute;
  z-index: 999;
  top: 25px;
  right: 0;
  margin-right: 35px;
  margin-top: 10px;
  cursor: pointer;
  transform: scale(1);
  transition: 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

const Close = styled.div`
  position: absolute;
  z-index: 999;
  top: 25px;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  transform: scale(1);
  transition: 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

const Col = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 5px;

    h1 {
      font-size: 16px;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: ${theme.palette.words.text.light};
    }
  `}
`;

const Previsao = styled.div`
  border-radius: 50px;
  padding: 3px 1px;
  width: auto;
  font-weight: 600;
  text-align: center;
  background: ${props => props.background};

  color: ${props => props.color};
  font-size: 14px;
`;

const Criticidade = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    align-items: center;
    margin-top: 5px !important;

    div {
      max-width: 7px;
      max-height: 7px;
      border-radius: 10px;
      margin-right: 5px;
      background: ${props.background};
    }

    p {
      color: ${props.color};
      font-size: 13px;
      text-transform: capitalize;
      text-align: left;
      padding: 2px 4px;
      border-radius: 10px;
      background: ${props.background};
    }
  `}
`;

const MainHeader = styled.div`
  display: flex;
  margin: 15px;
  justify-content: space-between;
`;

const MainGraphs = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`;

const FirstGraph = styled.div`
  width: 50%;
`;

const SecondGraph = styled.div`
  width: 50%;
  margin-left: 20px;
`;

const Status = styled.h3`
  width: 90px;
  padding: 4px;
  display: flex;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  background-color: ${props => props.background};
  color: ${props => props.color};
`;

const Pontuacao = styled.p`
  display: flex;
  justify-content: flex-start;
  margin-left: 20%;
`;

const BoxInformation = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    padding: 18px;

    .image {
      img {
        width: 100%;
        border-radius: 200px;
      }
      span {
        font-size: 20px;
        font-weight: 700;
        color: ${theme.palette.words.title.natural};
      }
    }
  `}
`;

const containerCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 100%;
    height: 132px;
    background: ${theme.palette.system.overlay};
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    .container-card {
      display: flex;
      width: 33%;
      height: 3px;
      justify-content: center;
      align-items: center;
    }
    .container-card-divider {
      height: 132px;
      height: 45.43px;
      border: 1px solid ${theme.palette.brand.primary.light}4d;
    }
    .icon-container {
      padding: 8px 8px 8px 8px;
      color: ${theme.palette.words.text.light};
      background: #eee;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
    h1 {
      font-size: 20px;
      line-height: 1;
    }
    h3 {
      color: ${theme.palette.words.text.light};
      line-height: 1;
      font-size: 14px;
    }
  `}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const PTable = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.words.title.natural};
    font-size: '16px';
    font-weight: 600;
  `}
`;

const Page = styled.div`
  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: flex;
      page-break-before: always;
      /* align-items: center; */
    }

    .page-break-inside {
      page-break-inside: auto;
    }
  }

  @page {
    size: auto;
  }
`;

export {
  StyledPageTitle,
  StyledTitle,
  StyledMain,
  StyledLogo,
  StyledCard,
  StyledLogoWrapper,
  StyledTextWrapper,
  StyledToast,
  StyledDistributorLogo,
  StorageImage,
  Row,
  Button,
  Header,
  Wrapper,
  Wrapper2,
  Expand,
  Close,
  Col,
  Previsao,
  Criticidade,
  MainHeader,
  MainGraphs,
  FirstGraph,
  SecondGraph,
  Status,
  Pontuacao,
  BoxInformation,
  containerCard,
  TitleWrapper,
  IconContainer,
  PTable,
  Page,
};
