// Components
import { Gerais } from './Gerais';
import { Local } from './Local';
import { Formulario } from './Formulario';
import { Evidencias } from './Evidencias';
import { Assinatura } from './Assinatura';
import { Cae } from './Cae';
import { Historico } from './Historico';

export const steps = [
  {
    title: 'Informações Gerais',
    pageTitle: 'Informações Gerais',
    page: 'Informações Gerais',
    step: 1,
    value: 0,
    Component: changed => Gerais(changed),
  },
  {
    title: 'Local/Tanques',
    pageTitle: 'Local/Tanques',
    page: 'Local/Tanques',
    step: 2,
    value: 1,
    Component: changed => Local(changed),
  },
  {
    title: 'Checklist',
    pageTitle: 'Checklist',
    page: 'Checklist',
    step: 3,
    value: 2,
    Component: changed => Formulario(changed),
  },
  {
    title: 'Evidências',
    pageTitle: 'Evidências',
    page: 'Evidências',
    step: 4,
    value: 3,
    Component: changed => Evidencias(changed),
  },
  {
    title: 'Assinaturas',
    pageTitle: 'Assinaturas',
    page: 'Assinaturas',
    step: 5,
    value: 4,
    Component: changed => Assinatura(changed),
  },
  {
    title: 'CAE',
    pageTitle: 'CAE',
    page: 'CAE`s (Comunicação de Atendimento de Emergência)',
    step: 6,
    value: 5,
    Component: changed => Cae(changed),
  },
  {
    title: 'Histórico de Alterações',
    pageTitle: 'Histórico de Alterações',
    page: 'Histórico de Alterações',
    step: 7,
    value: 6,
    Component: changed => Historico(changed),
  },
];
