import React from 'react';
import { Close } from '@mui/icons-material';
import { Container } from './styled';

export const Tip = ({ text, onClick }) => {
  return (
    <Container className="filter">
      <div className="text" title={text}>
        {String(text).toLowerCase()}
      </div>
      <div className="icon" onClick={onClick}>
        <Close fontSize="small" />
      </div>
    </Container>
  );
};
