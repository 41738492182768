import colors from 'styles/colors';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import * as S from './styled';

const CardAdd = ({ title, onClick }) => {
  return (
    <S.Wrapper onClick={onClick} className="addCard">
      <AddCircleOutlineIcon htmlColor={colors.greyTiny} />
      <span>{title}</span>
    </S.Wrapper>
  );
};

export default CardAdd;
