import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0px 20px;
`;

export const ButtonContianer = styled.div`
  display: flex;
  justify-content: space-between;

  .titleTurma {
    text-transform: capitalize;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const Paragraph = styled.p`
  ${({ theme, ...props }) => css`
    font-size: ${props.fontsize ? props.fontsize : '16px'};
    color: ${props.color ? props.color : theme.palette.brand.primary.light};
    font-weight: ${props.fontweight ? props.fontweight : 400};
    min-width: ${props.minwidth};

    &.status {
      font-weight: bold;
      color: ${props.color};
      background-color: ${props.bg};
      text-align: center;
      border-radius: 100px;
    }

    &.activity {
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: ${props.hasActivity ? 600 : 400};
      color: ${props.hasActivity
        ? theme.palette.words.title.natural
        : theme.palette.semantics.feedback.unknown.natural};

      span {
        margin-right: 10px;
      }
    }
  `}
`;

export const ColDriver = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    text-transform: capitalize;

    h1 {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
    }

    .container-img-name {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    img {
      width: 41px;
      height: 41px;
      border: 1px solid ${theme.palette.system.border};
      border-radius: 100px;
    }
  `}
`;
