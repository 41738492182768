// Styled
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useEffect, useRef, useState } from 'react';

// Compoenents
import InfoCard from 'components/Cards/InfoCard';
import DefaultButton from 'components/Buttons/Default';
import GhostButton from 'components/Buttons/Ghost';
import Loading from 'components/Loading';

// Components Material
import { Divider } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

// Utils
import OnisysLogo from 'images/Onisys_Simbolo.png';
import { formatNewDate } from 'utils/dates';
import Filters from '../Filters';
import { items } from '../../items';
import { formatStatusUser } from '../../actions';
import * as S from './styled';

// Resquests
import * as services from '../../Config/Reports/services';
import * as request from '../../ParaMim/services';

// Empty Image
const emptyImage = require('images/empty/padconfig.png');

const ModalSign = ({
  open,
  onClose,
  form,
  backPath,
  histId,
  refetch,
  loading,
  isHistory,
  histories = [],
}) => {
  const theme = useTheme();
  // Print Ref
  const printRef = useRef();

  // Redux
  const { user } = useSelector(state => state.auth.user);

  // Capa default Header
  const img = require(`images/defaultFormulario.jpg`);

  // General States
  const [secoes, setSecoes] = useState(form?.form?.secoes);
  const [formulario, setFormulario] = useState(form?.data?.form);
  const [aluno, setAluno] = useState(form?.aluno);
  const [aprovador, setAprovador] = useState(form?.aprovador);
  const [warningView, setWarningView] = useState(false);

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  const navigate = useNavigate();
  useEffect(() => {
    if (backPath) {
      window.onpopstate = event => {
        event.preventDefault();
        navigate(backPath);
      };
    }
  }, [backPath]);

  useEffect(() => {
    let sections = form?.data?.form?.secoes;
    sections.map(section => {
      section.campos.map(field => {
        if (!field.resposta) {
          field.resposta = 'Não respondido pelo usuário';
        }
      });
    });
    setSecoes(form?.data?.form?.secoes);
  }, [form]);

  // Modal Controller
  const [visible, setVisible] = useState('100vh');

  useEffect(() => {
    if (open) {
      openModal();
    }
  }, [open]);

  const openModal = () => {
    setTimeout(() => {
      setVisible('0px');
      setTimeout(() => {
        setWarningView(true);
      }, 500);
    }, 300);
  };

  const backModal = () => {
    setVisible('100vh');
    setWarningView(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  // -------------------------------------SET FIELDS---------------------------------------//
  const formatFieldResponse = (field, sectionId, key) => {
    let ResponseComponent = null;

    for (const i in items) {
      const { types } = items[i];
      types.find(item => {
        if (item.id_tipo_campo == field.id_tipo_campo) {
          ResponseComponent = item.ViewComponent;
        }
      });
    }

    return (
      <ResponseComponent
        field={field}
        secoes={secoes}
        setSecoes={() => {}}
        sectionId={sectionId}
        selects={form?.data?.selects}
        key={key}
        approveView
      />
    );
  };

  // -------------------------------------SIGN---------------------------------------//
  const signForm = async () => {
    const res = await services.signApprove(formulario.id, { ids: [histId] });

    if (res.success) {
      toast.success(res.message);
      refetch(histId);
    } else {
      toast.error(res.message);
    }
  };

  // ------------------------------------- EXPORTAR ---------------------------------------//
  const [loadingPdf, setLoadingPdf] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setLoadingPdf(false);
    },
    copyStyles: true,
    documentTitle: `formulario_id_${formulario?.id}_${aluno?.nome}`,
    pageStyle: `@page
        { size: auto; margin: 0mm; }
        @media print { body { -webkit-print-color-adjust: exact; padding: 20px !important; } }`,
  });

  // -------------------------------------FILTERS---------------------------------------//

  const [anchorEl, setAnchorEl] = useState(null);
  const [fieldsFilter, setFieldsFilter] = useState([]);
  const [sectionFiltered, setSectionFiltered] = useState(null);
  const [historySelected, setHistorySelected] = useState(histId);
  const [passByFilter, setPassByFilter] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [totalFilter, setTotalFilter] = useState(isHistory ? 1 : 0);
  const [isFilterEmpty, setIsFilterEmpty] = useState(false);

  const handleClickFilter = event => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (!secoes) return;
    let newSecoes = [];

    if (!fieldsFilter.length) {
      setIsFilterEmpty(false);
      setSectionFiltered(null);
      return;
    }
    let copySections = JSON.parse(JSON.stringify(secoes));
    let isEmpty = true;
    for (let i in copySections) {
      let newSection = copySections[i];
      newSection.campos = newSection.campos.filter(field => {
        if (fieldsFilter.includes(field.id_tipo_campo.toString())) {
          isEmpty = false;
          return field;
        }
      });
      newSecoes.push(newSection);
    }

    setIsFilterEmpty(isEmpty);
    setSectionFiltered([...newSecoes]);
  }, [fieldsFilter, secoes]);

  useEffect(() => {
    if (passByFilter) {
      setLoadingHistory(true);
      const getHistory = async () => {
        const res = await request.getFormResponseHistory(
          formulario.id,
          historySelected,
          user.id,
          'GESTOR',
        );

        if (res.success) {
          setSecoes(res?.data?.form?.secoes);
          setFormulario(res?.data?.form);
          setAluno(res?.aluno);
          setAprovador(res?.aprovador);
        }
        setLoadingHistory(false);
      };
      getHistory();
    }
  }, [historySelected]);

  return (
    <>
      <S.BigModal open={open} onClose={onClose} visible={visible}>
        <S.Cover className=".coverModal">
          <S.PrevieWarning view={warningView}>
            <div className="warning">
              Você esta acessando o modo de visualização, as perguntas nessa
              página não podem ser respondidas
            </div>
            <span className="close" onClick={() => setWarningView(false)}>
              Fechar
            </span>
          </S.PrevieWarning>
          <S.Header>
            <div className="left">
              <h1>{formulario?.titulo}</h1>
            </div>
            <div className="medium">
              <img src={OnisysLogo} />
            </div>
            <div className="right">
              <S.FilterBox
                onClick={!loadingHistory ? handleClickFilter : undefined}
              >
                <FilterAltOutlinedIcon color="disabled" />
                <div>Filtros</div>
                <ArrowDropDownOutlinedIcon color="disabled" />
                {!!totalFilter && (
                  <div className="numberFilter">{totalFilter}</div>
                )}
              </S.FilterBox>
              {anchorEl && (
                <Filters
                  id={anchorEl ? 'simple-popover' : undefined}
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  setFieldsFilter={setFieldsFilter}
                  fieldsFilter={fieldsFilter}
                  historySelected={historySelected}
                  setHistorySelected={setHistorySelected}
                  historyForm={histories}
                  isHistory={isHistory}
                  setPassByFilter={setPassByFilter}
                  setTotalFilter={setTotalFilter}
                />
              )}
              <CancelRoundedIcon
                htmlColor={theme.palette.semantics.feedback.unknown.natural}
                onClick={() => backModal()}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </S.Header>
          <S.Body>
            <>
              {loadingHistory && <Loading />}
              <S.Content
                className="contentControllScroll"
                loading={loadingHistory}
              >
                <div className="currentUser">
                  <div className="leftUser">
                    <div className="ball">
                      {aluno?.foto && <img />}
                      {!aluno?.foto && (
                        <div className="circle">
                          {aluno?.nome ? aluno?.nome[0] : '-'}
                        </div>
                      )}
                    </div>
                    <div className="name">{aluno?.nome || '-'}</div>
                    <div className="status">
                      {formatStatusUser(
                        !isHistory
                          ? formulario.concluido
                            ? 'Concluido'
                            : 'Iniciado'
                          : histories.find(h => h.id === historySelected)
                              ?.concluido
                          ? 'Concluido'
                          : 'Nao concluido',
                        theme,
                      )}
                    </div>
                  </div>
                  <div className="rightUser">
                    <GhostButton
                      children="Exportar"
                      onClick={
                        !isFilterEmpty
                          ? handlePrint
                          : () => toast.warning('Não há campos para exportar')
                      }
                      loading={loadingPdf}
                    />
                  </div>
                </div>

                <div className="printArea" ref={printRef}>
                  <S.HeaderSection src={formulario?.capa || img}>
                    <span className="primaryBox">
                      <div className="titleForm">{formulario?.titulo}</div>
                      <div className="rightArea">
                        {isHistory && (
                          <S.InfoBoxHeader>
                            <div className="date">
                              <div className="text">Histórico iniciado em:</div>
                              <div className="day">
                                {formatNewDate(
                                  histories.find(h => h.id === historySelected)
                                    ?.createdAt,
                                )}
                              </div>
                            </div>
                            <div className="date">
                              <div className="text">
                                Histórico concluido em:
                              </div>
                              <div className="day">
                                {histories.find(h => h.id === historySelected)
                                  ?.data_concluido
                                  ? formatNewDate(
                                      histories.find(
                                        h => h.id === historySelected,
                                      ).data_concluido,
                                    )
                                  : '-'}
                              </div>
                            </div>
                          </S.InfoBoxHeader>
                        )}
                        <span className="legend">
                          <span className="redPointer">*</span>
                          <span className="textReq">Obrigatórios</span>
                        </span>
                      </div>
                    </span>
                    <Divider />
                    <div className="descriptionForm">
                      {formulario?.descricao}
                    </div>
                    {!fieldsFilter.length && <div className="cover" />}
                  </S.HeaderSection>

                  {!isFilterEmpty &&
                    (sectionFiltered || secoes)?.map((secao, idx) => {
                      if (secao.campos.length) {
                        return (
                          <S.BodySection
                            key={idx}
                            className={`section-controller-${idx}`}
                          >
                            <span className="topArea">
                              <div className="titleSection">
                                {secao?.titulo}
                              </div>
                              <span className="counterSec">{`Seção ${idx + 1}/${
                                secoes?.length
                              }`}</span>
                            </span>
                            {secao.descricao && (
                              <div className="descriptSection">
                                {secao?.descricao}
                              </div>
                            )}
                            <Divider />
                            {secao.campos.map((campo, key) =>
                              formatFieldResponse(campo, secao.id, key),
                            )}
                          </S.BodySection>
                        );
                      }
                    })}

                  {isFilterEmpty && (
                    <S.EmptyAreaFilter>
                      <div className="imageArea">
                        <img src={emptyImage} />
                      </div>
                      <div className="textArea">
                        <div className="title">
                          Ops! Nenhum dos campos selecionados pertence ao
                          formulário
                        </div>
                        <div className="description">
                          Verifique os filtros aplicados
                        </div>
                      </div>
                    </S.EmptyAreaFilter>
                  )}

                  <S.FooterSection>
                    <div className="headerArea">Assinaturas</div>

                    <Divider />

                    <div className="userArea">
                      <div className="top">
                        <div className="title">Declaração Executor</div>
                        <div className="status">
                          {formatStatusUser(
                            !isHistory
                              ? formulario.concluido
                                ? 'Concluido'
                                : 'Iniciado'
                              : histories.find(h => h.id === historySelected)
                                  ?.concluido
                              ? 'Concluido'
                              : 'Nao concluido',
                            theme,
                          )}
                        </div>
                      </div>

                      <div className="medium">
                        <div className="ball">
                          <div className="circle">
                            {aluno?.nome ? aluno?.nome[0] : '-'}
                          </div>
                        </div>
                        <div className="nameArea">
                          <div className="mame">{aluno?.nome || '-'}</div>
                          <div className="concluded">
                            {!formulario?.concluido
                              ? 'Aguardando Finalização'
                              : `Finalizada em: ${aluno?.conclusao}`}
                          </div>
                        </div>
                      </div>
                    </div>

                    {formulario.aprovador && (
                      <>
                        <Divider />

                        <div className="userArea">
                          <div className="top">
                            <div className="title">Assinatura Aprovador</div>
                            <div className="status">
                              {formatStatusUser(
                                aprovador?.assinatura ? 'Assinado' : 'Pendente',
                                theme,
                              )}
                            </div>
                          </div>

                          <div className="medium">
                            <div className="ball">
                              <div className="circle">
                                {aprovador?.nome ? aprovador?.nome[0] : '-'}
                              </div>
                            </div>
                            <div className="nameArea">
                              <div className="mame">
                                {aprovador?.nome || '-'}
                              </div>
                              <div className="concluded">
                                {' '}
                                {aprovador?.assinatura
                                  ? `Assinada em: ${aprovador?.assinatura}`
                                  : 'Aguardando aprovação'}
                              </div>
                            </div>
                          </div>

                          {formulario.status.toUpperCase() !== 'FINALIZADO' &&
                            !isHistory &&
                            !aprovador?.assinatura && (
                              <InfoCard
                                message="O formulário so poderá ser assinado após a finalização do aluno"
                                key="info"
                              />
                            )}

                          <div className="bottom">
                            {user.id === formulario.id_responsavel_aprovador &&
                              !isHistory && (
                                <DefaultButton
                                  style={{ minWidth: '100%' }}
                                  disabled={
                                    formulario?.status?.toUpperCase() !==
                                      'FINALIZADO' || aprovador?.assinatura
                                  }
                                  loading={loading}
                                  onClick={() => signForm()}
                                >
                                  <ModeEditOutlineOutlinedIcon />
                                  {aprovador.assinatura
                                    ? 'Formulário assinado'
                                    : formulario?.status?.toUpperCase() !==
                                      'FINALIZADO'
                                    ? 'Aguardando finalização do aluno'
                                    : 'Assinar como aprovador'}
                                </DefaultButton>
                              )}
                          </div>
                        </div>
                      </>
                    )}
                  </S.FooterSection>
                </div>
              </S.Content>
            </>
          </S.Body>
        </S.Cover>
      </S.BigModal>
    </>
  );
};

export default ModalSign;
