import styled, { css } from 'styled-components';

export const Switch = styled.div`
  ${({ theme, checked }) => css`
    display: flex;
    width: 70px;
    height: 35px;
    border-radius: 35px;
    background-color: ${checked
      ? theme.palette.semantics.feedback.success.natural
      : theme.palette.semantics.feedback.unknown.natural};
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 3px;
    cursor: pointer;
    transition: all ease 0.5s;

    &:hover {
      opacity: 0.8;
    }
  `}
`;

export const Circle = styled.div`
  ${({ theme, checked }) => css`
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: white;
    display: flex;
    align-items: center;
    position: absolute;
    transform: translateX(${checked ? '35px' : '0px'});
    transition: all ease 0.5s;
    justify-content: center;

    .MuiSvgIcon-root {
      color: ${checked
        ? theme.palette.semantics.feedback.success.natural
        : theme.palette.semantics.feedback.unknown.natural};
    }
  `}
`;
