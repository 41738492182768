import React from 'react';
import { Container } from './styled';
import Actions from './Actions';
import Stepper from 'components/Stepper';

const Header = ({
  title,
  subtitle,
  actions = [],
  steps = null,
  currentStep = null,
}) => {
  return (
    <Container>
      <div style={{ flex: 1 }}>
        <h1>{title}</h1>
        {subtitle && <h3>{subtitle}</h3>}
      </div>
      {!!steps?.length && currentStep && (
        <>
          <div style={{ flex: 1 }}>
            <Stepper steps={steps} currentStep={currentStep} />
          </div>
          <div style={{ flex: 1 }} />
        </>
      )}
      {!!actions.length && <Actions actions={actions} />}
    </Container>
  );
};

export default Header;
