import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Controller, useFieldArray } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Divider, Grid, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';

import Select from 'components/Inputs/Select';
import { DashedButton } from 'components/Buttons/Dashed';
import TextInput from 'components/Inputs/TextField';

import Switch from '../components/Switch';
import * as S from './styled';
import * as SS from '../styled';
import * as services from '../../services';
import Criticidade from '../components/Criticidade';
import Condicoes from '../components/Condicoes';
import Collapse from '../components/Collapse';
import Pontos from '../components/Pontos';

const Cerca = ({
  control,
  errors,
  disableForm,
  watch,
  getValues,
  isProvider,
}) => {
  const theme = useTheme();
  const [canAddReturn, setCanAddReturn] = useState(false);

  const { data: _tiposVeiculos = [] } = useQuery(
    ['tipos-veiculos-telemetria'],
    () => services.getTiposVeiculos(),
    { staleTime: Infinity },
  );

  const tiposVeiculos = [
    { value: null, name: 'Todos' },
    ..._tiposVeiculos
      .filter(veiculo => veiculo.id >= 0)
      .map(tipo => ({
        name: tipo.tipo,
        value: tipo.id,
      })),
  ];

  const { fields, append, remove } = useFieldArray({
    name: 'desvios_tipos',
    control,
  });

  const handleAddRetorno = () => {
    const fields = getValues('desvios_tipos');
    if (fields.length > 0) {
      const pontos = [
        ...fields[fields.length - 1].desvios_tipos_cerca_pontos.filter(
          ponto => ponto.is_marcador,
        ),
      ]
        .reverse()
        .map((item, idx) => ({ ...item, posicao: idx + 1 }));
      const titulo = `Retorno ${fields[fields.length - 1].titulo || ''}`;

      append({ ativo: true, desvios_tipos_cerca_pontos: pontos, titulo });
    }
  };

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (
        values.desvios_tipos.length === 1 &&
        values.desvios_tipos[0]?.desvios_tipos_cerca_pontos?.length > 1
      ) {
        setCanAddReturn(true);
      } else setCanAddReturn(false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <br />
      <Divider />

      {fields.map((field, index) => {
        const canRemove = !field.createdAt && fields.length > 1;
        return (
          <Collapse
            key={field.id}
            handleRemove={() => remove(index)}
            canRemove={!disableForm && canRemove}
            last={index + 1 === fields.length}
            title={`Cerca eletrônica ${index > 0 ? `(${index + 1})` : ''}`}
            switchProps={{
              control,
              name: `desvios_tipos[${index}].ativo`,
              disabled: disableForm,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3} sm={1.5} sx={{ marginTop: '2.5rem' }}>
                <Switch
                  control={control}
                  name={`desvios_tipos[${index}].ativo`}
                  disabled={disableForm}
                />
              </Grid>

              <Grid item xs={9} sm={4.5}>
                <Controller
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].titulo`}
                  render={({ field }) => (
                    <TextInput
                      required
                      disabled={disableForm}
                      error={!!errors.desvios_tipos?.[index]?.titulo}
                      message={errors.desvios_tipos?.[index]?.titulo?.message}
                      label="Título da cerca eletrônica"
                      placeholder="Insira um título"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name={`desvios_tipos[${index}].id_tipo_veiculo`}
                  render={({ field: { onChange, name, ref, value } }) => (
                    <Select
                      ref={ref}
                      disabled={disableForm}
                      name={name}
                      value={value}
                      handleChange={onChange}
                      error={!!errors.desvios_tipos?.[index]?.id_tipo_veiculo}
                      data={tiposVeiculos}
                      label="Selecione o tipo de veículo"
                    />
                  )}
                />
              </Grid>

              <Controller
                control={control}
                name={`desvios_tipos[${index}].desvios_tipos_cerca_pontos`}
                render={({ field: { onChange, value } }) => (
                  <Pontos
                    disabled={disableForm}
                    pontos={value || []}
                    onChange={onChange}
                  />
                )}
              />

              <Grid item xs={4} sm={4}>
                <Controller
                  control={control}
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  name={`desvios_tipos[${index}].limiar`}
                  render={({ field }) => (
                    <TextInput
                      required
                      disabled={disableForm}
                      type="number"
                      inputProps={{ min: 0 }}
                      error={!!errors.desvios_tipos?.[index]?.limiar}
                      message={errors.desvios_tipos?.[index]?.limiar?.message}
                      label="Velocidade máx."
                      placeholder="Insira a velocidade"
                      {...field}
                      onChange={({ target }) =>
                        field.onChange(
                          target.value ? parseInt(target.value) : '',
                        )
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4} sm={4}>
                <Controller
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].intervalo_tempo`}
                  render={({ field }) => (
                    <TextInput
                      required
                      disabled={disableForm}
                      type="number"
                      inputProps={{ min: 0 }}
                      error={!!errors.desvios_tipos?.[index]?.intervalo_tempo}
                      message={
                        errors.desvios_tipos?.[index]?.intervalo_tempo?.message
                      }
                      label={
                        <span>
                          Intervalo de tempo (min.){' '}
                          <Tooltip
                            title="Tempo de tolerância antes que o desvio comece a ser contabilizado."
                            arrow
                          >
                            <HelpIcon sx={{ fontSize: '1rem' }} />
                          </Tooltip>
                        </span>
                      }
                      placeholder="Insira o intervalo de tempo"
                      {...field}
                      onChange={({ target }) =>
                        field.onChange(
                          target.value ? parseInt(target.value) : '',
                        )
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4} sm={4}>
                <Controller
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].pontos`}
                  render={({ field }) => (
                    <TextInput
                      required
                      disabled={disableForm}
                      type="number"
                      inputProps={{ min: 0 }}
                      error={!!errors.desvios_tipos?.[index]?.pontos}
                      message={errors.desvios_tipos?.[index]?.pontos?.message}
                      label="Pontuação"
                      placeholder="Insira a pontuação"
                      {...field}
                      onChange={({ target }) =>
                        field.onChange(
                          target.value ? parseInt(target.value) : '',
                        )
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Criticidade
                  name={`desvios_tipos[${index}].criticidade`}
                  control={control}
                  errors={errors}
                  disableForm={disableForm}
                />
                {errors.desvios_tipos?.[index]?.criticidade?.message && (
                  <span
                    style={{
                      color: theme.palette.semantics.feedback.attention.dark,
                      margin: '10px 0',
                    }}
                  >
                    {errors.desvios_tipos?.[index]?.criticidade?.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Condicoes
                  path={`desvios_tipos[${index}]`}
                  control={control}
                  disableForm={disableForm}
                />
              </Grid>
            </Grid>
          </Collapse>
        );
      })}

      {canAddReturn && (
        <S.AddButton type="button" onClick={handleAddRetorno}>
          <AddIcon fontSize="inherit" />
          Adicionar retorno
        </S.AddButton>
      )}

      <DashedButton
        type="button"
        disabled={disableForm}
        onClick={() => append({ ativo: true })}
      >
        <AddIcon />
        ADICIONAR DESVIO
      </DashedButton>
    </>
  );
};

export default Cerca;
