import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 24px;
    color: ${theme.palette.brand.primary.natural};
    font-weight: 900;
    letter-spacing: 1.1px;
  `}
`;

export const StyledButton = styled(Button)`
  && {
    text-transform: none;
    color: ${props => props.textcolor};
    background: ${props => props.backgroundcolor};
    border: 2px solid ${props => props.theme.palette.brand.primary.light};
    font: normal normal 400 16px/24px Texta;
    height: ${props => props.height || '38px'};
    padding: 5px 15px;
    transition: opacity 300ms;
    box-shadow: none;

    :hover {
      background: ${props => props.backgroundcolor};
      border-color: black;
      opacity: 0.7;
    }

    :disabled {
      background: ${props => props.theme.palette.system.disabled};
      opacity: 0.5;
    }
  }
`;

export const Count = styled.p`
  ${({ theme, ...props }) => css`
    font: normal normal 900 12px/16px Texta;
    color: ${theme.palette.words.text.contrast};
    display: ${props.count ? 'block' : 'none'};
    background: ${theme.palette.brand.primary.natural};
    width: 16px;
    border-radius: 8px;
    position: absolute;
    top: -3px;
    right: -3px;
  `}
`;
