import api from 'services/api';

export const requestBases = async () => {
  const res = await api.get('/configuracoes/bases-layout');
  return res.data;
};
export const createBase = async data => {
  const res = await api.post('/configuracoes/bases-layout', data);
  return res.data;
};

export const updateBase = async data => {
  const res = await api.put('/configuracoes/bases-layout', data);
  return res.data;
};
