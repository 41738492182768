import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 4px;
  justify-content: space-between;
  .description {
    font: normal normal 900 24px/30px Texta;
    letter-spacing: 0px;
    color: #4b5166;
  }
  .description-client {
    font-size: 14px;
    color: #0c12f2;
  }
  .alertas {
    font-size: 14px;
    color: #f64e60;
  }
`;

export const DesvioItem = styled.div`
  background: rgba(75, 81, 102, 0.1);
  border-radius: 12px;
  color: '#4B5166';
  height: 24px;
  margin-left: 20px;
  margin-top: 4px;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
`;
