import api from 'services/api';
import { iconsCards } from './constants';

export const requestAcoesSuspensoes = async (id, query) => {
  const res = await api.get(`/empresas/${id}/acoes-suspensoes`, {
    params: query,
  });
  return res;
};

export const requestCards = async (id, query) => {
  const res = await api.get(`/empresas/${id}/acoes-suspensoes-cards`, {
    params: query,
  });
  if (res?.data?.data)
    return res.data.data.map(card => ({
      ...card,
      icon: iconsCards[card.type] || 'Grupo12741.svg',
    }));
  return [];
};
