import styled from 'styled-components';
import colors from 'styles/colors';

const Paper = styled.div`
  position: absolute;
  width: 540px;
  background-color: ${colors.white};
  border: 0px 5px 20px ${colors.greyBorder};
  border-radius: 8px;
  padding: 25px 25px;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  h1 {
    color: #4b5166;
    font: normal 900 20px Texta;
    margin-left: 10px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
`;

export { Paper, Header, Title, Footer };
