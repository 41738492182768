/* eslint-disable import/order */

// Styled
import * as S from './styled';
import colors from 'styles/colors';

// React
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

// Components
import Tag from '../Tag';
import LikeModal from './Like';
import { MediaView } from '../MediaView';
import { EvaluationView } from './Evaluation';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Material UI
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import DoorFrontOutlinedIcon from '@mui/icons-material/DoorFrontOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined'; // Quiz
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'; // Teste

// Utils
import * as services from './services';
import { formatModule } from './constants';
import { formatStatus } from '../../actions';
import OnisysLogo from 'images/Onisys_Simbolo.png';
import {
  formatStLine,
  formatStEval,
  formatIconColor,
  definePercentage,
  defineBarColor,
  formatFinalDate,
  formatIconColorEval,
  formaterStatus,
  defineConclusion,
} from './actions';
import { toast } from 'react-toastify';
import { FinishModal } from './Evaluation/Finish';

const img =
  'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/capacitacao%2Finfo%2Fcapas%2F1673467756358%2F2023-01-11_17-09-19_Capa_Conteudo_Capacitacao.jpg?alt=media&token=3ca2cd3b-f1c8-438a-a277-88580f7cbdbd';

let timer = null;

const ResponseModal = ({
  open,
  onClose,
  backPath,
  data,
  setCurentContent,
  preview,
  refetch,
}) => {
  // Navigate
  const navigate = useNavigate();
  const midias = data?.midias;
  const avaliacao = data?.perguntas;
  const concluded =
    data?.status_usuario === 'APROVADO' || data?.status_usuario === 'REPROVADO';
  const isFinished = data?.status === 'Finalizado';

  // Setters
  const [currentModule, setCurrentModule] = useState(null);
  const [currentElement, setCurrentElement] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  // Modal
  const [modalLike, setModalLike] = useState(false);
  const [finishedModal, setFinishedModal] = useState(false);
  const [warningView, setWarningView] = useState(false);

  // Executed
  const [unloked, setUnloked] = useState([0]);
  const [executed, setExecuted] = useState([]);
  const [canCall, setCanCall] = useState(true);
  const [questions, setQuestions] = useState(avaliacao);
  const [allCompleted, setAllCompleted] = useState(false);
  const [totalIndex, setTotalIndex] = useState(0);
  const [currentStatus, setCurrentStatus] = useState(null);

  // Config
  const [visibleTab, setVisibleTab] = useState(true);
  const [is_quiz, setIsQuiz] = useState(false);

  // --------------------------Initializers--------------------------//
  useEffect(() => {
    let mds = midias?.length;
    let av = avaliacao?.length ? 1 : 0;
    const total = mds + av - 1;
    setTotalIndex(total);
    setIsQuiz(avaliacao?.length && avaliacao[0].is_quiz);
    setQuestions(avaliacao);

    let mdsCompleted = [];
    let indexExecuted = [];
    midias?.forEach((item, index) => {
      if (item.completedMidia) {
        mdsCompleted.push(item);
        indexExecuted.push(index);
      }
    });

    setExecuted(indexExecuted);

    if (mds !== mdsCompleted?.length) {
      setAllCompleted(false);
    } else {
      setAllCompleted(true);
    }

    let lastRes = 0;
    avaliacao?.forEach((item, index) => {
      if (item?.resposta?.id_alternativa) {
        lastRes = index + 1;
      }
    });

    if (lastRes !== avaliacao?.length && lastRes) {
      setCurrentQuestion(lastRes);
    } else {
      setCurrentQuestion(0);
    }

    for (let i in midias) {
      if (+i === midias.length - 1 && midias[i].completedMidia) {
        setCurrentModule(+i);
        setUnloked([...unloked, +i + 1]);
        return;
      }

      if (!midias[i].completedMidia) {
        setUnloked([...unloked, +i]);
        setCurrentModule(i == 0 ? null : i - 1);
        return;
      }
    }
  }, [data]);

  // ----------------------Modal Controller---------------------------------//
  const [visible, setVisible] = useState('100vh');
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    if (open) {
      openModal();
    }
  }, [open]);

  const openModal = () => {
    setTimeout(() => {
      setVisible('0px');
      if (preview) {
        setTimeout(() => {
          setWarningView(true);
        }, 300);
      }
    }, 150);
  };

  const backModal = () => {
    setVisible('100vh');
    setWarningView(false);
    refetch && !preview && !concluded && !isFinished && refetch();
    setTimeout(() => {
      onClose();
    }, 250);
  };

  const falseBack = () => {
    setVisible('100vh');
    setTimeout(() => {
      setVisible('0px');
    }, 400);
  };

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    if (backPath) {
      window.onpopstate = event => {
        event.preventDefault();
        navigate(backPath);
      };
    }
  }, [backPath]);

  // ------------------------------------Modules controll------------------------------------//

  useEffect(() => {
    setCanCall(true);
    let mds = midias?.length;
    if (currentModule !== null && currentModule < mds) {
      setCurrentElement(midias[currentModule]);
    } else if (currentModule !== null) {
      setCurrentElement(avaliacao);
    }
  }, [currentModule]);

  const handleNextModule = async () => {
    if (currentModule < totalIndex) {
      setUnloked([
        ...unloked,
        (currentModule || 0) + (currentModule !== null ? 1 : 0),
      ]);
      if (!executed.includes(currentModule)) {
        setExecuted([...executed, currentModule]);
      }

      setSlide(300);
      setCurrentModule((currentModule || 0) + 1);
      setTimeout(() => {
        setSlide(0);
        let element = document.getElementById('ViewBoxContent');
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }, 150);
    } else {
      if (isFinished || preview || concluded) {
        backModal();
        return;
      }

      if (avaliacao[0]?.is_quiz === false) {
        if (data.comentarios_habilitados) {
          setModalLike(true);
        } else {
          backModal();
        }
      } else {
        setFinishedModal(true);
      }
    }
  };

  const handlePrevModule = () => {
    if (currentModule > 0) {
      setSlide(300);
      setCurrentModule(currentModule - 1);
      setTimeout(() => {
        setSlide(0);
        let element = document.getElementById('ViewBoxContent');
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }, 150);
    } else {
      setCurrentModule(null);
    }
  };

  const handleByBar = index => {
    if (index !== currentModule) {
      setSlide(300);
      setCurrentModule(index);
      setTimeout(() => {
        setSlide(0);
        let element = document.getElementById('ViewBoxContent');
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }, 150);
    }
  };

  const formatSizeTitle = title => {
    if (title.length > 40) {
      return `${title.slice(0, 40)}...`;
    }
    return title;
  };

  // ------------------------------------------EFFECTS-------------------------------------------//
  const shakeEffect = id => {
    let element = document.getElementById(id);
    element?.classList?.add('shake');
    setTimeout(() => {
      element?.classList?.remove('shake');
    }, 200);
  };

  const rotateEffect = id => {
    let element = document.getElementById(id);
    element?.classList?.add('rotate');
    setTimeout(() => {
      element?.classList?.remove('rotate');
    }, 1000);
  };

  // ----------------------------------------CONTROLL STEPS------------------------------------//
  const [blocked, setBlocked] = useState(true);
  const [inVideo, setInVideo] = useState(false);

  useEffect(() => {
    unlockNext();
  }, [currentModule]);

  const unlockNext = enabled => {
    !blocked && setBlocked(true);
    clearTimeout(timer);

    if (
      (currentModule === totalIndex && avaliacao?.length && !enabled) ||
      isFinished ||
      preview
    ) {
      return;
    }
    if (
      (!midias[currentModule]?.tipo.includes('VÍDEO') &&
        !midias[currentModule]?.tipo.includes('PDF')) ||
      enabled
    ) {
      setInVideo(false);
      timer = setTimeout(
        async () => {
          setBlocked(false);

          let newUnloked =
            (currentModule || 0) + (currentModule !== null ? 1 : 0);
          if (
            !unloked.includes(newUnloked) &&
            !midias[newUnloked]?.completedMidia
          ) {
            rotateEffect(`line-bar-${newUnloked}`);
            setTimeout(() => {
              setUnloked([...unloked, newUnloked]);
            }, 700);
          }

          if (!executed.includes(currentModule)) {
            setExecuted([...executed, currentModule]);

            const crMidia = midias[currentModule]?.id;
            if (crMidia) {
              let res = await services.viewMidia(crMidia);
              if (!res.success) {
                toast.error('Ocorreu um erro inesperado ao salvar midia');
                onClose();
              }
              if (currentModule === totalIndex && !avaliacao.length) {
                setCurrentStatus('APROVADO');
              }
            }
          }
        },
        enabled ? 0 : 5000,
      );
    } else {
      if (!midias[currentModule]?.tipo.includes('PDF')) {
        setInVideo(true);
      }
    }

    if (currentModule === null) {
      clearTimeout(timer);
    }
  };

  const handleCloseVerifier = () => {
    const isConcluded = defineConclusion(
      midias,
      executed,
      questions,
      totalIndex,
    );

    if (isFinished || preview || concluded) {
      backModal();
      return;
    }

    if (isConcluded && !concluded) {
      if (avaliacao[0]?.is_quiz === false) {
        if (data?.comentarios_habilitados) {
          setModalLike(true);
        } else {
          backModal();
        }
      } else {
        setFinishedModal(true);
      }
    } else {
      backModal();
    }
  };

  // ---------------------------------------RENDER--------------------------------------------//

  const CurrentMediaView = useMemo(() => {
    return (
      <MediaView
        item={currentElement}
        unlockNext={unlockNext}
        canCall={canCall}
        setCanCall={setCanCall}
        free={
          isFinished || preview || concluded || executed.includes(currentModule)
        }
      />
    );
  }, [currentElement, canCall]);

  return (
    <S.BigModal open={open} disableEscapeKeyDown visible={visible}>
      <S.Cover>
        {preview && (
          <S.PrevieWarning view={warningView}>
            <div className="warning">
              Você esta acessando o modo de visualização, o conteúdo nessa
              página não pode ser executado. Etapas não concluídas são
              bloqueadas.
            </div>
            <span className="close" onClick={() => setWarningView(false)}>
              Fechar
            </span>
          </S.PrevieWarning>
        )}
        <S.Header visible={visibleTab}>
          <div className="left">
            <h1>{formatSizeTitle(data?.titulo)}</h1>
          </div>
          <div className="medium">
            <img src={OnisysLogo} />
          </div>
          <div className="right">
            <S.Progress
              bar={definePercentage(
                midias,
                executed,
                avaliacao,
                totalIndex,
                is_quiz,
                data?.coeficiente_minimo,
              )}
              colored={defineBarColor(midias, executed, avaliacao, totalIndex)}
            >
              <div className="numeric">
                {definePercentage(
                  midias,
                  executed,
                  avaliacao,
                  totalIndex,
                  is_quiz,
                  data?.coeficiente_minimo,
                )}
                % COMPLETO
              </div>
              <div className="barArea">
                <div className="filled" />
                <div className="empty" />
              </div>
              <EmojiEventsOutlinedIcon
                htmlColor={defineBarColor(
                  midias,
                  executed,
                  avaliacao,
                  totalIndex,
                )}
              />
            </S.Progress>
            <span className="stArea">
              {!preview && (
                <>
                  {formatStatus(data?.status)}
                  <div
                    className="timeST"
                    {...formatFinalDate(data?.data_fim)}
                  />
                </>
              )}
              {preview && (
                <div className="previewWeb">Modo de Visualização</div>
              )}
            </span>
            <CancelRoundedIcon
              htmlColor={colors.greyTiny}
              onClick={() => handleCloseVerifier()}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </S.Header>
        <S.Body>
          {(concluded || currentStatus || isFinished) && (
            <S.Ribbon
              {...formaterStatus(
                currentStatus || data?.status_usuario || data?.status,
              )}
            />
          )}
          <S.Content id="content-over-scroll-box">
            <S.ZeroSelect
              background={data?.imagem_capa || img}
              view={currentModule !== null}
            >
              <div className="titleArea">
                <h1 className="title">{data?.titulo}</h1>
                <div className="tags">
                  {data?.tags?.map((item, index) => (
                    <Tag key={index}>{item?.tag}</Tag>
                  ))}
                </div>
              </div>
              <div className="background">
                <div className="gradient">
                  <h1 className="title">{data?.titulo}</h1>
                  <div className="description">{data?.descricao}</div>
                </div>
              </div>
              {currentModule == null && (
                <div className="descriptionArea">
                  <div className="descriptionTab">Descrição</div>
                  <div className="descriptionText">{data?.descricao}</div>
                </div>
              )}
            </S.ZeroSelect>
            <S.BoxView slide={slide} id="ViewBoxContent">
              {currentModule !== null &&
                currentElement?.tipo &&
                CurrentMediaView}
              {currentModule !== null && !currentElement?.tipo && (
                <EvaluationView
                  is_quiz={is_quiz}
                  conteudo={data}
                  setBlocked={setBlocked}
                  unlockNext={unlockNext}
                  questions={questions}
                  setQuestions={setQuestions}
                  currentQuestion={currentQuestion}
                  setCurrentQuestion={setCurrentQuestion}
                  setCurrentStatus={setCurrentStatus}
                  setCurentContent={setCurentContent}
                  falseBack={falseBack}
                  backModal={backModal}
                  isFinished={isFinished || preview}
                />
              )}
            </S.BoxView>
            {currentModule !== null && (
              <S.Footer>
                <GhostButton
                  children="Voltar"
                  onClick={() => handlePrevModule()}
                />
                <DefaultButton
                  disabled={
                    blocked &&
                    !currentElement?.completedMidia &&
                    !executed.includes(currentModule)
                  }
                  children={
                    currentModule < totalIndex
                      ? blocked &&
                        !currentElement?.completedMidia &&
                        !executed.includes(currentModule)
                        ? inVideo
                          ? 'Assista ao vídeo'
                          : preview
                          ? 'Modo de visualização'
                          : midias[currentModule]?.tipo.includes('PDF')
                          ? 'Visualize todo o PDF'
                          : 'Aguarde'
                        : 'Próximo'
                      : concluded
                      ? 'Fechar'
                      : 'Concluir'
                  }
                  onClick={() => handleNextModule()}
                />
              </S.Footer>
            )}

            {currentModule === null && (
              <S.Footer>
                <DefaultButton
                  children={totalIndex >= 0 ? 'Iniciar' : 'Concluir'}
                  onClick={
                    totalIndex >= 0
                      ? () => setCurrentModule(0)
                      : () => backModal()
                  }
                />
              </S.Footer>
            )}
          </S.Content>
          <S.Bar visible={visibleTab}>
            <div className="iconAbs" onClick={() => setVisibleTab(!visibleTab)}>
              <ArrowForwardIosOutlinedIcon />
            </div>
            <S.LineModule
              onClick={() => setCurrentModule(null)}
              active={currentModule === null}
            >
              <div className="left">
                <div className="type">
                  <DoorFrontOutlinedIcon htmlColor={colors.greyTiny} />
                  <div className="text">Começar: Introdução</div>
                </div>
                <div className="status">Informações iniciais</div>
              </div>
              <div className="rigth">
                <PlayCircleFilledIcon htmlColor={colors.greyTiny} />
              </div>
            </S.LineModule>
            {midias?.map((midia, index) => {
              let { Icon: View, label: text } = formatModule(midia.tipo);
              return (
                <S.LineModule
                  id={`line-bar-${index}`}
                  key={index}
                  lock={!midia.completedMidia && !unloked.includes(index)}
                  onClick={
                    midia.completedMidia || unloked.includes(index)
                      ? () => handleByBar(index)
                      : () => shakeEffect(`line-bar-${index}`)
                  }
                  active={currentModule === index}
                >
                  <div className="left">
                    <div className="type">
                      {!midia.completedMidia && !unloked.includes(index) && (
                        <LockOutlinedIcon className="lock" />
                      )}
                      {(midia.completedMidia || unloked.includes(index)) && (
                        <View
                          htmlColor={formatIconColor(
                            midia.completedMidia,
                            unloked,
                            executed,
                            index,
                          )}
                        />
                      )}
                      <div className="text">Conteúdo: {text}</div>
                    </div>
                    <div className="status">
                      <span className="fixed">Status:</span>
                      <span
                        className="defined"
                        {...formatStLine(
                          midia.completedMidia,
                          unloked,
                          executed,
                          index,
                        )}
                      />
                    </div>
                  </div>
                  <div className="rigth">
                    <PlayCircleFilledIcon
                      className={
                        !midia.completedMidia && !unloked.includes(index)
                          ? 'lock'
                          : null
                      }
                      style={{
                        opacity:
                          !midia.completedMidia && !unloked.includes(index)
                            ? '0.5'
                            : '1',
                      }}
                      htmlColor={formatIconColor(
                        midia.completedMidia,
                        unloked,
                        executed,
                        index,
                      )}
                    />
                  </div>
                </S.LineModule>
              );
            })}

            {avaliacao?.length > 0 && (
              <S.LineModule
                onClick={
                  allCompleted || unloked.includes(totalIndex)
                    ? () => handleByBar(totalIndex)
                    : () => shakeEffect(`line-bar-${totalIndex}`)
                }
                active={currentModule === totalIndex}
                lock={!allCompleted && !unloked.includes(totalIndex)}
                id={`line-bar-${totalIndex}`}
              >
                <div className="left">
                  <div className="type">
                    {(allCompleted || unloked.includes(totalIndex)) && (
                      <>
                        {is_quiz ? (
                          <OfflineBoltOutlinedIcon
                            htmlColor={formatIconColorEval(
                              totalIndex,
                              executed,
                              questions,
                            )}
                          />
                        ) : (
                          <CheckCircleOutlineOutlinedIcon
                            htmlColor={formatIconColorEval(
                              totalIndex,
                              executed,
                              questions,
                            )}
                          />
                        )}
                      </>
                    )}

                    {!allCompleted && !unloked.includes(totalIndex) && (
                      <LockOutlinedIcon className="lock" />
                    )}
                    <div className="text">
                      Perguntas: {is_quiz ? 'Quiz' : 'Teste Online'}
                    </div>
                  </div>
                  <div className="status">
                    <span className="fixed">Status:</span>
                    <span
                      className="defined"
                      {...formatStEval(
                        totalIndex,
                        executed,
                        questions,
                        is_quiz,
                        data?.coeficiente_minimo,
                      )}
                    />
                  </div>
                </div>
                <div className="rigth">
                  <PlayCircleFilledIcon
                    className={
                      !allCompleted && !unloked.includes(totalIndex)
                        ? 'lock'
                        : null
                    }
                    style={{
                      opacity:
                        !allCompleted && !unloked.includes(totalIndex)
                          ? '0.5'
                          : '1',
                    }}
                    htmlColor={formatIconColorEval(
                      totalIndex,
                      executed,
                      questions,
                    )}
                  />
                </div>
              </S.LineModule>
            )}
          </S.Bar>
        </S.Body>
        <LikeModal
          contentId={data?.id}
          open={modalLike}
          handleClose={() => setModalLike(false)}
          closeContent={backModal}
        />

        <FinishModal
          open={finishedModal}
          handleClose={() => setFinishedModal(false)}
          status="CONCLUIDO"
          conteudo={data}
          confirm={
            data?.comentarios_habilitados
              ? () => setModalLike(true)
              : () => backModal()
          }
        />
      </S.Cover>
    </S.BigModal>
  );
};

export default ResponseModal;
