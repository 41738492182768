import { useRef, useState } from 'react';

import { IconButton } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import * as S from './styled';

const SliderTag = ({ children, width = '86vw' }) => {
  const refScrollTab = useRef(null);
  const [initialScroll, setInitScroll] = useState(true);

  const scrollMove = value => {
    refScrollTab.current.scrollLeft = value;
    if (value <= 0) {
      setInitScroll(true);
    } else {
      setInitScroll(false);
    }
  };

  return (
    <S.Wrapper width={width}>
      <IconButton
        disabled={initialScroll}
        onClick={() => scrollMove(refScrollTab.current.scrollLeft - 300)}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <S.TagContent ref={refScrollTab}>{children}</S.TagContent>
      <IconButton
        onClick={() => scrollMove(refScrollTab.current.scrollLeft + 300)}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </S.Wrapper>
  );
};

export default SliderTag;
