export const validator = (form, invalid, first) => {
  let message = null;
  if (!form.titulo && !form.descricao && !form.link) {
    message = 'Preencha pelo menos um tipo de texto ou mídia para continuar';
  } else if (
    !form.mostrar_guia &&
    !form.mostrar_rota &&
    !form.mostrar_catalogo &&
    !first
  ) {
    message = 'Selecione pelo menos um local de exibição do passo';
  } else if (form.niveis.length === 0) {
    message = 'Selecione pelo menos um nível';
  } else if (invalid) {
    message =
      'O Player não suporta esse link. Verifique a extensão do arquivo ou remova o link para continuar';
  }

  return message;
};
