import * as types from './types';

export function setSection(currentSection) {
  return {
    type: types.SET_SECTION,
    payload: { currentSection },
  };
}

export function setItems(secoes) {
  return {
    type: types.SET_ITEMS,
    payload: { secoes },
  };
}

// export function setElement(item) {
//   return {
//     type: types.SET_ELEMENT,
//     payload: {item}
//   }
// }

export function setTitle(title) {
  return {
    type: types.SET_TITLE,
    payload: { title },
  };
}

export function setId(id) {
  return {
    type: types.SET_ID,
    payload: { id },
  };
}

export function setCapa(capa) {
  return {
    type: types.SET_CAPA,
    payload: { capa },
  };
}

export function setStatus(status) {
  return {
    type: types.SET_STATUS,
    payload: { status },
  };
}

export function setGerais(gerais) {
  return {
    type: types.SET_GERAIS,
    payload: { gerais },
  };
}

export function setIsEditable(isEditable) {
  return {
    type: types.IS_EDITABLE,
    payload: { isEditable },
  };
}

export function reset() {
  return {
    type: types.RESET_STATE,
    payload: {},
  };
}
