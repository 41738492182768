import styled, { css } from 'styled-components';

export const StepOneBox = styled.div`
  ${({ theme }) => css`
    .boxText {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;

      .typeDesc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        color: ${theme.palette.words.title.natural};
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .counter {
      align-self: flex-end;
      font-size: 12px;
      color: ${theme.palette.words.text.light};
    }
  `}
`;

export const SelectMoeda = styled.div`
  ${({ theme }) => css`
    margin-left: 5px;

    .title {
      font-weight: 600;
      color: ${theme.palette.words.subtitle.natural};
      font-size: 16px;
    }

    .boxOptions {
      margin-top: 10px;
      margin-left: 10px;

      .item {
        display: flex;
        align-items: center;
        justify-content: start;

        span {
          margin-left: 10px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  `}
`;
