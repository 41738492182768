import {
  getDepartamento,
  getPropriedade,
  getCargas,
} from 'constants/_SERVICES/provider/index';

// Pages
import Frota from 'pages/Provider/Frota';
import LogFrota from 'pages/Provider/Frota/History';

export default {
  '/frota': {
    defaults: ['empresas', 'filials'],
    pageFilterName: 'filterFrotaProvider',
    filters: [
      {
        name: 'departamento',
        placeholder: 'Filtrar por Departamento',
        section: 'Frota',
        mode: 'single',
        data: async () => getDepartamento(),
      },
      {
        name: 'propriedade',
        placeholder: 'Filtrar por Propriedade',
        section: 'Frota',
        mode: 'single',
        data: async () => getPropriedade(),
      },
      {
        name: 'carga',
        placeholder: 'Filtrar por Carga',
        section: 'Frota',
        mode: 'single',
        data: async () => getCargas(),
      },
    ],
    page: <Frota pageDefault="veiculos" />,
  },

  '/logs/frota/:id': {
    back: '/frota',
    title: 'Histórico de alterações - Frota',
    defaults: [],
    page: <LogFrota />,
  },
};
