// React
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// Styles
import { useTheme } from 'styled-components';

// Compoenents
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';
import GhostButton from 'components/Buttons/Ghost';

// Compoenents Material
import { EditOutlined } from '@mui/icons-material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

// Utils
import { signOut } from 'store/modules/auth/saga';
import { linkWithPopup, OAuthProvider, unlink } from 'firebase/auth';
import {
  auth as firebaseAuth,
  getReauthenticationCredential,
} from 'utils/firebase';

import * as services from './services';
import * as C from '../styled';
import * as S from './styled';
import colors from '../../../styles/colors';

const logo_microsoft = require('images/microsoft.png');

export const Seguranca = () => {
  const theme = useTheme();
  const newP = useRef(null);
  const conP = useRef(null);

  const dispatch = useDispatch();

  // Form Controllers
  const [newPass, setNewPass] = useState('');
  const [oldPass, setOldPass] = useState('');

  const [confirmPass, setConfirmPass] = useState('');
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);

  // Visisble Pass
  const [visibleA, setVisibleA] = useState(false);
  const [visibleN, setVisibleN] = useState(false);
  const [visibleC, setVisibleC] = useState(false);

  useEffect(() => {
    if (!loading && firebaseAuth?.currentUser?.providerData) {
      const providers = firebaseAuth?.currentUser?.providerData.map(
        item => item.providerId,
      );
      setProviders(providers);
    }
  }, [loading]);

  const changePass = async () => {
    const user = firebaseAuth?.currentUser;

    const credential = getReauthenticationCredential(user.email, oldPass);
    try {
      firebaseAuth.currentUser
        .reauthenticateWithCredential(credential)
        .then(res => {
          if (!newPass && !confirmPass) {
            toast.error('Os campos não devem estar vazios');
            newP.current.classList.add('error');
            conP.current.classList.add('error');
            return;
          }

          if (!newPass) {
            toast.error('O campo de nova senha não deve estar vazio');
            newP.current.classList.add('error');
            return;
          }

          if (!confirmPass) {
            toast.error('Confirme sua nova senha');
            conP.current.classList.add('error');
            return;
          }

          if (newPass !== confirmPass) {
            toast.error('As senhas devem ser iguais');
            newP.current.classList.add('error');
            conP.current.classList.add('error');
            return;
          }

          setModal(true);
        })
        .catch(error => {
          toast.error('Senha atual incorreta');
        });
    } catch (error) {
      console.log(error);
      toast.error('Senha atual incorreta');
    }
  };

  const handlePassword = async () => {
    setLoading(true);
    const res = await services.requestTogglePassword({ password: newPass });
    if (res.success) {
      toast.success(res.message);
      setNewPass('');
      setConfirmPass('');
      signOut();
    } else {
      toast.error(res.message);
    }
    setModal(false);
    setLoading(false);
  };

  const handleMicrosoft = () => {
    const providerId = 'microsoft.com';
    setLoading(true);
    // Vincular conta
    if (!providers.includes(providerId)) {
      const provider = new OAuthProvider('microsoft.com');
      provider.setCustomParameters({
        prompt: 'select_account',
      });

      linkWithPopup(firebaseAuth.currentUser, provider)
        .then(result => {
          toast.success('Conta vinculada com sucesso!');
        })
        .catch(error => {
          console.log('error:\n', error);
          toast.error('Erro ao vincular conta!');
          // TODO: tipos de erros
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // Desvincular conta
    else {
      if (providers.length < 2) {
        toast.warning(
          'Defina uma nova senha antes de desvincular sua conta MS.',
        );
        setLoading(false);
        return;
      }
      unlink(firebaseAuth.currentUser, providerId)
        .then(result => {
          toast.success('Conta desvinculada com sucesso!');
        })
        .catch(error => {
          console.log('error:\n', error);
          toast.error('Erro ao desvincular conta!');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <S.Container>
      <C.TitleArea>
        <span className="textArea">Segurança</span>
      </C.TitleArea>
      <S.InputArea>
        <div className="inputBox">
          <S.Label>
            Senha Atual
            <S.PasswordField
              type={visibleA ? 'text' : 'password'}
              placeholder="Insira sua senha atual"
              value={oldPass}
              onChange={e => setOldPass(e.target.value)}
              onClick={e => e.currentTarget.classList.remove('error')}
              onBlur={() => setVisibleA(false)}
              ref={newP}
            />
            <S.VisibleController onClick={() => setVisibleA(!visibleN)}>
              {visibleA && (
                <VisibilityOffOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
              )}
              {!visibleA && (
                <VisibilityOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
              )}
            </S.VisibleController>
          </S.Label>
        </div>
        <div className="inputBox">
          <S.Label>
            Nova Senha
            <S.PasswordField
              type={visibleN ? 'text' : 'password'}
              placeholder="Insira sua nova senha"
              value={newPass}
              onChange={e => setNewPass(e.target.value)}
              onClick={e => e.currentTarget.classList.remove('error')}
              onBlur={() => setVisibleN(false)}
              ref={newP}
            />
            <S.VisibleController onClick={() => setVisibleN(!visibleN)}>
              {visibleN && (
                <VisibilityOffOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
              )}
              {!visibleN && (
                <VisibilityOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
              )}
            </S.VisibleController>
          </S.Label>
        </div>
        <div className="inputBox">
          <S.Label>
            Confirmar Nova Senha
            <S.PasswordField
              type={visibleC ? 'text' : 'password'}
              placeholder="Confirme sua senha"
              value={confirmPass}
              onChange={e => setConfirmPass(e.target.value)}
              onClick={e => e.currentTarget.classList.remove('error')}
              onBlur={() => setVisibleC(false)}
              ref={conP}
            />
            <S.VisibleController onClick={() => setVisibleC(!visibleC)}>
              {visibleC && (
                <VisibilityOffOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
              )}
              {!visibleC && (
                <VisibilityOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
              )}
            </S.VisibleController>
          </S.Label>
        </div>
        <DefaultButton
          size="medium"
          sx={{ width: '380px', marginTop: '20px' }}
          onClick={() => changePass()}
        >
          REDEFINIR SENHA
        </DefaultButton>

        <GhostButton
          variant="contained"
          size="medium"
          style={{
            width: '380px',
            marginTop: '20px',
            background: theme.palette.brand.primary.background,
            color: colors.blueTitle,
            border: `1px solid ${colors.blueTitle}`,
          }}
          onClick={() => handleMicrosoft()}
          loading={loading}
        >
          <img
            src={logo_microsoft}
            width="24px"
            height="24px"
            style={{ marginRight: '10px' }}
          />
          {!providers.includes('microsoft.com')
            ? 'Vincular conta Microsoft'
            : 'Desvincular conta Microsoft'}
        </GhostButton>
      </S.InputArea>

      {
        /* Confirmação */
        modal && (
          <ConfirmModal
            open={modal}
            handleClose={() => setModal(null)}
            titleIcon={
              <EditOutlined
                sx={{ color: theme.palette.brand.secondary.natural }}
                fontSize="medium"
              />
            }
            title="Tem certeza que deseja alterar a senha?"
            subtitle="A nova senha será utilizada para fazer login na plataforma"
            buttonText="Confirmar"
            onClick={() => handlePassword()}
            loading={loading}
          />
        )
      }
    </S.Container>
  );
};
