import { createContext, useContext, useMemo, useReducer } from 'react';
import * as types from './types';

let initialData = {
  search: '',
  pageIndex: 0,
  pageSize: 20,
  sortBy: null,
  selected: [],
  count: 0,
  selectedDefault: null,
  cachePerPage: false,
  started: false,
};

// Context
const TableContext = createContext(undefined);

const TableReducer = (state, action) => {
  switch (action.type) {
    case types._SET_SEARCH:
      return {
        ...state,
        search: action.payload.search,
        pageIndex: 0,
      };
    case types._SET_PAGE:
      return {
        ...state,
        pageIndex: action.payload,
        selected: state.cachePerPage ? state.selected : [],
      };
    case types._SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: +action.payload,
      };
    case types._SET_SORT:
      return {
        ...state,
        sortBy: action.payload,
      };
    case types._SET_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };
    case types._SET_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case types._SET_DEFAULT:
      return {
        ...state,
        selectedDefault: action.payload,
      };
    case types._SET_CACHE_PAGE:
      return {
        ...state,
        cachePerPage: action.payload,
      };
    case types._SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case types._SET_STARTED:
      return {
        ...state,
        started: true,
        search: action.payload ? '' : state.search,
        pageIndex: action.payload ? 0 : state.pageIndex,
      };
    case types._SET_TABLE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

// Provider
export const TableProvider = ({ children, persist }) => {
  if (persist) {
    const persistData = JSON.parse(localStorage.getItem(`table-${persist}`));

    initialData = {
      ...initialData,
      ...persistData,
    };
  }

  const [state, dispatch] = useReducer(TableReducer, initialData);
  const value = useMemo(() => ({ state, dispatch }), [state]);

  if (persist) {
    localStorage.setItem(
      `table-${persist}`,
      JSON.stringify({ ...state, started: false }),
    );
  }

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};

// Hook
export const useTable = () => {
  const context = useContext(TableContext);
  if (context == undefined) {
    throw new Error('Use error - call location - Maybe you forgot Provider');
  }
  return context;
};
