import React from 'react';
import { useTheme } from 'styled-components';
import { Wrapper, Container } from './styled';

const TextInput = ({
  label,
  required,
  disabled,
  noAsterisk,
  children,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Wrapper>
      <p>
        {label}
        {!noAsterisk && (
          <span
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: required
                ? theme.palette.semantics.feedback.attention.dark
                : theme.palette.system.transparent,
            }}
          >
            *
          </span>
        )}
      </p>
      <Container {...props} disabled={disabled == true}>
        {children}
      </Container>
    </Wrapper>
  );
};
export default TextInput;
