import PaletteIcon from '@mui/icons-material/PaletteOutlined';
// Pages (in order)
import { PerfilEmpresa } from '../../Configuracoes/Perfil&Empresa';
import { Personalizacao } from './Personalizacao';
import { Usuarios } from '../../Configuracoes/Usuarios';
import { MinhasBases } from './MinhasBases';
import { Notificacoes } from 'pages/Configuracoes/Notificacoes';
import { Seguranca } from './Seguranca';
import { Configuracoes } from './Configuracoes';
import { Relatorios } from 'pages/Configuracoes/Relatorios';
import { EscalationList } from 'pages/Configuracoes/EscalationList';
import { Empresas } from './Empresas';

// Icons
import { ReactComponent as empresa } from '../../../images/icons/configuracoes/empresa.svg';
import { ReactComponent as usuarios } from '../../../images/icons/configuracoes/usuarios.svg';
import { ReactComponent as notificacoes } from '../../../images/icons/configuracoes/notificacoes.svg';
import { ReactComponent as seguranca } from '../../../images/icons/configuracoes/seguranca.svg';
import { ReactComponent as configuracoes } from '../../../images/icons/configuracoes/configuracoes.svg';
import { ReactComponent as relatorios } from '../../../images/icons/configuracoes/relatorios.svg';
import { ReactComponent as escalation } from '../../../images/icons/configuracoes/icone-escalation.svg';
import { ReactComponent as iconEmpresa } from '../../../images/icons/configuracoes/empresas2.svg';

export const pages = [
  {
    value: 0,
    page: 'Perfil & Empresa',
    id: 'perfil',
    linkTo: '/configuracoes/perfil',
    icon: empresa,
    element: <PerfilEmpresa />,
  },
  {
    value: 2,
    page: 'Empresas',
    id: 'empresas',
    linkTo: '/configuracoes/empresas',
    icon: iconEmpresa,
    element: <Empresas />,
    plans: [190, 200],
  },
  {
    value: 1,
    page: 'Personalização',
    id: 'personalizacao',
    linkTo: '/configuracoes/personalizacao',
    icon: PaletteIcon,
    element: <Personalizacao />,
    whitelabel: true,
  },
  {
    value: 3,
    page: 'Usuários',
    id: 'usuarios',
    linkTo: '/configuracoes/usuarios',
    icon: usuarios,
    element: <Usuarios />,
  },
  {
    value: 4,
    page: 'Minhas bases',
    id: 'bases',
    linkTo: '/configuracoes/bases',
    icon: usuarios,
    plans: [120],
    element: <MinhasBases />,
    plans: [120],
  },
  {
    value: 5,
    page: 'Notificações',
    id: 'notificacoes',
    linkTo: '/configuracoes/notificacoes',
    icon: notificacoes,
    element: <Notificacoes />,
  },
  {
    value: 6,
    page: 'Relatórios',
    id: 'relatorios',
    linkTo: '/configuracoes/relatorios',
    icon: relatorios,
    element: <Relatorios />,
    // levels: [1],
    plans: [190, 200],
  },
  {
    value: 7,
    page: 'Segurança',
    id: 'seguranca',
    linkTo: '/configuracoes/seguranca',
    icon: seguranca,
    element: <Seguranca />,
  },
  {
    value: 8,
    page: 'Configurações',
    id: 'configuracoes',
    linkTo: '/configuracoes/configuracoes',
    icon: configuracoes,
    element: <Configuracoes />,
  },
  {
    value: 9,
    page: 'Deslocamentos',
    id: 'escalation-list',
    linkTo: '/configuracoes/escalation-list',
    icon: escalation,
    element: <EscalationList />,
    plans: [190, 200],
    levels: [1],
  },
];
