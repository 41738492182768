export function changeHeaderStatus({ status, color, background }) {
  return {
    type: '@evaluations/CHANGE_HEADER_STATUS',
    payload: { status, color, background },
  };
}

export function updateNumNotification({
  totalNotifications,
  openPush,
  allIds,
}) {
  return {
    type: '@evaluations/CHANGE_NOTIFICATIONS_COUNT',
    payload: { totalNotifications, openPush, allIds },
  };
}

export function setAlert(message) {
  return {
    type: '@evaluations/SET_ALERT',
    payload: message,
  };
}

export function closeAlert() {
  return {
    type: '@evaluations/CLOSE_ALERT',
  };
}

export function updateProfilePhoto({ profile_photo }) {
  return {
    type: '@evaluations/UPDATE_PROFILE_PHOTO',
    payload: { profile_photo },
  };
}

export function openModalInfo() {
  return {
    type: '@evaluations/OPEN_MODAL_INFO',
  };
}
export function closeModalInfo() {
  return {
    type: '@evaluations/CLOSE_MODAL_INFO',
  };
}

export function showMsgInfo() {
  return {
    type: '@evaluations/SHOW_MSG_INFO',
  };
}

export function hideMsgInfo() {
  return {
    type: '@evaluations/HIDE_MSG_INFO',
  };
}

export function fullScreenMode(fullScreenMode) {
  return {
    type: '@evaluations/FULL_SCREEN_MODE',
  };
}

export function exitFullScreenMode(fullScreenMode) {
  return {
    type: '@evaluations/EXIT_FULL_SCREEN_MODE',
  };
}
