import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// Components
import FormEvaluation from 'components/Form';
import CardIndicadores from 'components/Cards/Indicador';

// Material UI
import { Grid } from '@mui/material';
import { ModalDesvio } from './ModalDesvio';

// Services
import * as services from '../services';

export const Formulario = ({
  evaluation,
  disabled,
  setPage,
  setTitle,
  setRecharge,
  initialDate,
  finalDate,
}) => {
  const [modalDesvio, setModalDesvio] = useState(false);
  const [infosDesvio, setInfosDesvios] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [sections, setSections] = useState(null);
  const [desvios, setDesvios] = useState(null);
  const [avaliados, setAvaliados] = useState(0);
  const [atendidos, setAtendidos] = useState(0);
  const [percentege, setPercentege] = useState(0);
  const [objNone, setObjNone] = useState(null);

  useEffect(() => {
    getAnswers();
  }, []);

  const getAnswers = async () => {
    const res = await services.requestAnswersEvaluation(evaluation.id);
    setAnswers(res?.data?.respostas || []);
    setDesvios(res?.data?.desvios || []);

    const types = await services.requestTypesQuestions();
    setSections(types.data);
  };

  const responseQuestions = async (id, answer, oldAnswer) => {
    if (oldAnswer?.resposta === 'NAO') {
      toast.error('Não é possivel alterar perguntas com desvios');
    } else {
      const answerObject = {
        resposta: answer,
        imagem_uri: null,
        objeto: null,
        id_questao: id,
        id_avaliacao: evaluation.id,
      };

      const body = {
        event: 'answerOneQuestion',
        data: { id, answer: answerObject, oldAnswer },
      };

      const res = await services.answerOneQuestion(evaluation.id, body);
      if (res && !res.data.success) {
        toast.error('Erro ao responder pergunta.');
      } else {
        return res.data;
      }
    }
  };

  const openModal = (id, answer, oldAnswer) => {
    setInfosDesvios(oldAnswer);
    setModalDesvio(true);
    setObjNone({ id, answer, oldAnswer });
  };

  const handlePage = () => {
    setPage(4);
    setTitle('Desvios na Avaliação');
  };

  // ----------------------Calculo dos cards (informações)----------------------//
  useEffect(() => {
    let av = 0;
    let at = 0;
    let pc = 0;

    for (const i in answers) {
      // N de itens avaliados
      if (answers[i].resposta === 'SIM' || answers[i].resposta === 'NAO') {
        av++;
      }
      // N de itens atendidos
      if (answers[i].resposta === 'SIM') {
        at++;
      }
    }

    // Porcentagem de atendimento
    pc = ((at * 100) / av).toFixed(2);
    if (!pc) pc = 0;

    setAvaliados(av);
    setAtendidos(at);
    setPercentege(pc);
  }, [sections]);
  return (
    <>
      <Grid item style={{ margin: '20px 0px' }}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <CardIndicadores
              value={evaluation.pontuacao}
              icon="Grupo12743.svg"
              smalltype="true"
              text="Pontos"
            />
          </Grid>
          <Grid item md={3}>
            <CardIndicadores
              value={avaliados}
              icon="Grupo12741.svg"
              smalltype="true"
              text="Itens Avaliados"
            />
          </Grid>
          <Grid item md={3}>
            <CardIndicadores
              value={atendidos}
              icon="Grupo12742.svg"
              smalltype="true"
              text="Itens de Atendidos"
            />
          </Grid>
          <Grid item md={3}>
            <CardIndicadores
              value={`${percentege || 0}%`}
              smalltype="true"
              icon="Grupo12742.svg"
              text="Atendimento"
            />
          </Grid>
        </Grid>
      </Grid>
      {answers && sections && (
        <FormEvaluation
          sections={sections}
          answers={answers}
          desvios={desvios}
          setAnswers={setAnswers}
          onClickRow={responseQuestions}
          ifNo={openModal}
          clickOutlier={handlePage}
          disabled={disabled}
          forbiden="Avaliação assinada por motorista e/ou avaliador não pode ser modificada"
        />
      )}
      {modalDesvio && infosDesvio && (
        <ModalDesvio
          open={modalDesvio}
          onClose={() => {
            setModalDesvio(false);
            setInfosDesvios(null);
          }}
          infos={infosDesvio}
          evaluation={evaluation}
          setRecharge={setRecharge}
          responseQuestions={responseQuestions}
          obj={objNone}
          initialDate={initialDate}
          finalDate={finalDate}
        />
      )}
    </>
  );
};
