import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateBox = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .date {
      background-color: ${theme.palette.system.overlay};
      padding: 5px 15px;
      border: 3px solid ${theme.palette.system.border};
      border-radius: 5px;
      color: ${theme.palette.words.subtitle.natural};
      width: 120px;
      text-align: center;
      margin: 0px 10px 0px 10px;
      font-weight: 600;
      color: ${theme.palette.words.subtitle.natural};
    }

    .outedBar {
      width: 100%;
      height: 2px;
      background-color: ${theme.palette.system.border};
      position: absolute;
      z-index: -1;
    }

    .arrow {
      cursor: pointer;
      font-size: 32px;
    }

    .arrow.null {
      cursor: not-allowed;
    }
  `}
`;
