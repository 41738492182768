import { useDispatch } from 'react-redux';
import { setSection } from 'store/modules/formularios/actions';

import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import * as S from './styled';

const AddComponent = ({ setExpanded, item }) => {
  const dispatch = useDispatch();

  const setClickSection = index => {
    dispatch(setSection(index));
  };

  return (
    <S.Container
      onClick={() => {
        setExpanded(true);
        setClickSection(item.index);
      }}
    >
      <ControlPointOutlinedIcon />
      <span>Adicionar Campos </span>
    </S.Container>
  );
};

export default AddComponent;
