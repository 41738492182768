import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { firestorageVideos } from 'utils/firebase';
import Loading from 'components/Loading';
import EmptyCard from 'components/Cards/EmptyDataCard';
import * as S from './styled';

export const VideoAvaliacao = ({ evaluation }) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [status, setStatus] = useState(0);
  // 0: Não carregado
  // 1: Tem link com vídeo funcional
  // 2: Tem link mas não tem vídeo: Vídeo removido
  // 3: Não tem link de vídeo

  useEffect(() => {
    const getVideoData = () => {
      // Obs: homolog não tem vídeos. valor mocado para REACT_APP_ENV = 'development'
      const caminho =
        process.env.REACT_APP_ENV !== 'production'
          ? 'Transpedrosa/Moveit Transpedrosa/10207#ADRIANO DOUGLAS LOPES/2022-39/10207#ADRIANO DOUGLAS LOPES_2022-39.mp4'
          : evaluation?.caminho_cloud || '';
      if (caminho) {
        firestorageVideos
          .ref(caminho)
          .getDownloadURL()
          .then(fireBaseUrl => {
            if (fireBaseUrl) {
              setVideoUrl(fireBaseUrl);
              setStatus(1);
            }
          })
          .catch(err => {
            console.log(err);
            setStatus(2);
          });
      } else {
        setStatus(3);
      }
    };
    getVideoData();
  }, [evaluation]);

  return (
    <S.Main>
      {status === 0 && <Loading />}
      {status === 1 && (
        <S.VideoContainer>
          <ReactPlayer
            url={videoUrl}
            controls
            playing
            width="100%"
            height="100%"
          />
        </S.VideoContainer>
      )}
      {status === 2 && (
        <EmptyCard
          image="frota.png"
          title="Ops! O vídeo não está mais disponível por tempo de expiração."
          subtitle="O periodo de disponibilidade é de 60 dias."
        />
      )}
      {status === 3 && (
        <EmptyCard
          image="frota.png"
          title="Ops! O vídeo não está disponível."
          subtitle="Não existe um vídeo relacionado à esta avaliação."
        />
      )}
    </S.Main>
  );
};
