import styled from 'styled-components';
import colors from 'styles/colors';
import { Modal } from '@mui/material';

export const ModalTurmas = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  max-height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

export const ContainerModal = styled.div`
  width: 65%;
  max-height: 96%;
  background-color: #fff;
  border: 2px solid ${colors.greyBorder};
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const TableContainer = styled.div`
  width: 100%;
  position: relative;

  .banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 361px;
    background-color: #fff;
    border: 2px solid ${colors.greyBorder};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;

    h3 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 500;
      color: ${colors.greyTitle};
    }
  }
`;
export const Paragraph = styled.p`
  font-size: ${props => (props.fontsize ? props.fontsize : '16px')};
  color: ${props => (props.color ? props.color : colors.grey)};
  font-weight: ${props => (props.fontweight ? props.fontweight : 400)};
  min-width: ${props => props.minwidth};

  &.status {
    font-weight: bold;
    color: ${props => props.color};
    background-color: ${props => props.bg};
    text-align: center;
    border-radius: 100px;
  }
`;

export const ColDriver = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.greyTitle};
  }

  .container-img-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  img {
    width: 41px;
    height: 41px;
    border: 1px solid ${colors.greyBorder};
    border-radius: 100px;
  }
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;

export const ButtonContianer = styled.div`
  display: flex;
  justify-content: space-between;

  .titleTurma {
    text-transform: capitalize;
  }
`;

export const AbsoluteButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: 30px;
`;
