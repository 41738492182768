import React from 'react';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import * as S from './styled';

const ModalBottom = ({ open, data, handleClose, handleApply }) => {
  return (
    <S.Main open={open}>
      <S.Wrapper>
        <h2>{data.length}</h2>
        <h3>selecionado(s)</h3>
      </S.Wrapper>

      <S.Wrapper>
        <GhostButton size="medium" onClick={handleClose}>
          CANCELAR
        </GhostButton>

        <DefaultButton
          sx={{ marginLeft: '20px' }}
          size="medium"
          onClick={handleApply}
        >
          JUSTIFICAR INDISPONIBILIDADE
        </DefaultButton>
      </S.Wrapper>
    </S.Main>
  );
};

export default ModalBottom;
