import styled, { css } from 'styled-components';

export const QuestionBox = styled.div`
  ${({ theme, hasError }) => css`
    padding: 20px;
    background-color: ${hasError
      ? theme.palette.semantics.feedback.attention.light
      : theme.palette.system.highlight};
  `}
`;

export const ResponseArea = styled.div`
  ${({ preview }) => css`
    margin-top: 18px;
    display: grid;
    grid-template-columns: ${preview ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
    gap: 10px;
    width: 100%;
  `}
`;
