import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 5px 40px;
`;

export const MediaArchive = styled.div`
  margin-top: 8px;
`;
