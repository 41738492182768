/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConteudo } from 'store/modules/provider/capacitacaoConteudo/actions';

// Components
import { EmptyContent } from './Empty';
import Loading from 'components/Loading';
import { EvaluationModules } from './Modules';
import ConfirmModal from 'components/ConfirmModal';
import EvaluationCard from '../../components/EvaluationCard';

// Icons
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

// Constants
import { options, validation } from './constants';

// Services
import * as services from '../services';

export const Avaliacao = ({
  setChanged,
  trySave,
  setTrySave,
  setCurrentStep,
  isEdit,
  isPublished,
  steps,
}) => {
  // Redux
  const dispatch = useDispatch();
  const { id, avaliacao } = useSelector(state => state.conteudoProvider);

  // Inital Question
  const questionDefinition = {
    pergunta: '',
    feedback: '',
    ordem: 0,
    alternativas: [],
    is_quiz: true,
  };

  const [questions, setQuestions] = useState([]);
  const [changeTest, setChangeTest] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quizOp, setQuizOp] = useState(-1);
  const [errors, setErrors] = useState([]);

  // Initialize Data
  useEffect(() => {
    if (avaliacao && avaliacao?.length > 0) {
      setQuestions(JSON.parse(JSON.stringify(avaliacao)));
      avaliacao[0]?.is_quiz ? setQuizOp(0) : setQuizOp(1);
      setChanged(false);
    }
  }, [avaliacao]);

  // --------------------------SQUARE BUTTONS--------------------------//

  const handleQuiz = skip => {
    if (quizOp === 0) return;
    let has_items = false;

    questions.map(item => {
      if (item.pergunta) has_items = true;

      item?.alternativas.forEach(opcao => {
        if (opcao.alternativa) has_items = true;
      });
    });

    if (!has_items || skip) {
      if (avaliacao && avaliacao.length > 0 && avaliacao[0]?.is_quiz) {
        setQuestions(JSON.parse(JSON.stringify(avaliacao)));
      } else {
        const new_question = questionDefinition;
        new_question.is_quiz = true;
        new_question.alternativas = [
          {
            alternativa: 'verdadeiro',
            correta: true,
            ordem: 0,
          },
          {
            alternativa: 'falso',
            correta: false,
            ordem: 1,
          },
        ];
        setQuestions([new_question]);
      }
      setChanged(true);
      setErrors([]);
      setSelectedIndex(0);
      setChangeTest(false);
      setQuizOp(0);
      scrollInSelection();
    } else {
      setChangeTest(true);
    }
  };

  const handleTest = skip => {
    if (quizOp === 1) return;
    let has_items = false;

    questions.map(item => {
      if (item.pergunta || item.feedback) has_items = true;
    });

    if (!has_items || skip) {
      if (avaliacao && avaliacao.length > 0 && !avaliacao[0]?.is_quiz) {
        setQuestions(JSON.parse(JSON.stringify(avaliacao)));
      } else {
        const new_question = questionDefinition;
        new_question.is_quiz = false;
        new_question.alternativas = [
          {
            alternativa: '',
            correta: true,
            ordem: 0,
          },
          {
            alternativa: '',
            correta: false,
            ordem: 1,
          },
        ];
        setQuestions([new_question]);
      }
      setChanged(true);
      setQuizOp(1);
      setErrors([]);
      setSelectedIndex(0);
      setChangeTest(false);
      scrollInSelection();
    } else {
      setChangeTest(true);
    }
  };

  const scrollInSelection = () => {
    let element = document.getElementById('content-eval');
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'end',
    });
  };

  // --------------------------SAVE ITEMS--------------------------//
  useEffect(() => {
    if (trySave) {
      let errors = validation(questions);
      if (errors) {
        toast.error('Preencha todos os campos obrigatórios!');
        setErrors(errors);
        setTrySave(false);
        setSelectedIndex(errors[0].index);
        let element = document.getElementById('evaluation_modules_square');
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        });
      } else {
        if (avaliacao?.length && !questions?.length) {
          setConfirmRemove(true);
        } else {
          sendRequest();
        }
      }
    }
  }, [trySave]);

  const sendRequest = async () => {
    setLoading(true);

    const res = await services.EditInfos(id, {
      pagina: 'avaliacao',
      perguntas: questions,
    });

    if (res.success) {
      setTrySave(false);
      setLoading(false);
      setChanged(false);
      !isEdit && setCurrentStep(steps[3]);
      dispatch(setConteudo(res.data.perguntas, 'avaliacao'));
      toast.success('Informações salvas com sucesso!');
    } else {
      toast.error(res.message);
      setTrySave(false);
      setLoading(false);
    }
  };

  // ------------------------------RENDER------------------------------//

  return (
    <>
      {!loading && (
        <div id="content-eval">
          <S.Square>
            {options.map((item, index) => (
              <EvaluationCard
                item={item}
                key={index}
                selected={index === quizOp}
                onClick={!index ? () => handleQuiz() : () => handleTest()}
              />
            ))}
          </S.Square>

          <S.Space />

          {quizOp !== -1 && (
            <EvaluationModules
              questions={questions}
              setQuestions={setQuestions}
              quizOp={quizOp}
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
              setQuizOp={setQuizOp}
              id="evaluation_modules_square"
              errors={errors}
              setChanged={setChanged}
              isPublished={isPublished}
            />
          )}
          {quizOp == -1 && <EmptyContent />}

          {/* Modal Area */}
          <ConfirmModal
            handleClose={() => setChangeTest(false)}
            open={changeTest}
            title="Tem certeza que deseja mudar o tipo de avaliação?"
            subtitle="Ao mudar o tipo, todas as perguntas criadas serão descartadas."
            titleIcon={<WarningAmberRoundedIcon htmlColor={colors.redDanger} />}
            buttonText="Confirmar"
            onClick={
              quizOp === 0 ? () => handleTest(true) : () => handleQuiz(true)
            }
          />

          <ConfirmModal
            handleClose={() => setConfirmRemove(false)}
            open={confirmRemove}
            title="Tem certeza que deseja remover a avaliação do conteúdo?"
            subtitle="Ao salvar essa etapa sem nenhuma pergunta, a avaliação será removida do conteúdo."
            titleIcon={<WarningAmberRoundedIcon htmlColor={colors.redDanger} />}
            buttonText="Confirmar"
            onClick={() => sendRequest()}
          />
        </div>
      )}
      {loading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}
    </>
  );
};
