// Styled
import { useTheme } from 'styled-components';

// React
import { useState } from 'react';

// Components
import TextInput from 'components/Inputs/TextField';
import DefaultButton from 'components/Buttons/Default';

// Components Material
import { Icon, IconButton } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import * as S from './styled';

const ModalComments = ({ open, onClose, comentario, onChange, onlyRead }) => {
  const theme = useTheme();
  const [value, setValue] = useState(comentario || '');

  const saveComment = () => {
    onChange(value);
    onClose();
  };

  return (
    <S.ModalArea
      open={open}
      onClose={() => {
        onClose();
      }}
      disableEscapeKeyDown={false}
    >
      <S.Container>
        <S.Header>
          <div style={{ alignItems: 'center' }}>
            <FormatListBulletedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <h2>
              {!onlyRead ? 'Adicionar Comentário' : 'Comentário do aluno'}
            </h2>
          </div>

          <IconButton
            size="small"
            onClick={() => {
              onClose();
            }}
          >
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <S.Body>
          <TextInput
            placeholder="Insira comentário para a questão"
            label="Comentário"
            value={value}
            onChange={e => setValue(e.target.value.slice(0, 200))}
            disabled={onlyRead}
          />
          <S.Counter>{`${value?.length}/200`}</S.Counter>
          <S.Box>
            {!onlyRead && (
              <DefaultButton
                children={comentario ? 'Salvar Alteração' : 'Adicionar'}
                onClick={() => saveComment()}
              />
            )}
          </S.Box>
        </S.Body>
      </S.Container>
    </S.ModalArea>
  );
};

export default ModalComments;
