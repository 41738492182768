// React
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Compoenents
import { toast } from 'react-toastify';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';

import { PaletteOutlined } from '@mui/icons-material';
import { useQuery } from 'react-query';
import * as services from 'styles/theme/services';
import { updateProvider } from 'store/modules/auth/action';

import {
  requestProvider,
  updateWhitelabel,
  updateWhitelabelLogo,
} from './services';
import SettingsModal from './SettingsModal';
import * as C from '../styled';
import * as S from './styled';

export const Personalizacao = () => {
  const dispatch = useDispatch();
  const [togleModal, setTogleModal] = useState(false);
  const [whitelabelConfig, setWhitelabelConfig] = useState(null);
  const [queryString, setQueryString] = useState('');
  const { user } = useSelector(state => state.auth.user);
  const id_da_distribuidora = user?.id_da_distribuidora;

  const { data: distribuidora, refetch } = useQuery(
    ['distribuidora', id_da_distribuidora],
    () => requestProvider(id_da_distribuidora),
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (distribuidora) {
      const config = {
        id_da_whitelabel_primaria: distribuidora.id_da_whitelabel_primaria,
        id_da_whitelabel_secundaria: distribuidora.id_da_whitelabel_secundaria,
      };
      setWhitelabelConfig(config);
      handlePreview(config);
    }
  }, [distribuidora]);

  const handleSave = useCallback(
    async data => {
      const res = await updateWhitelabel(id_da_distribuidora, data);
      if (res.success) {
        dispatch(updateProvider({ ...user.distribuidora, ...data }));
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    },
    [distribuidora, id_da_distribuidora],
  );

  const handleSaveLogo = useCallback(
    async logo => {
      const data = {
        logo_whitelabel: logo,
      };
      const res = await updateWhitelabelLogo(id_da_distribuidora, data);
      if (res.success) {
        refetch();
        dispatch(updateProvider({ ...user.distribuidora, ...data }));
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    },
    [distribuidora, id_da_distribuidora],
  );

  const handlePreview = useCallback(whitelabelConfig => {
    const queryParams = {
      primary: whitelabelConfig?.id_da_whitelabel_primaria ?? '',
      secondary: whitelabelConfig?.id_da_whitelabel_secundaria ?? '',
    };

    setQueryString(`?${new URLSearchParams(queryParams).toString()}`);
  }, []);

  const reset = useCallback(() => {
    const config = {
      id_da_whitelabel_primaria: distribuidora.id_da_whitelabel_primaria,
      id_da_whitelabel_secundaria: distribuidora.id_da_whitelabel_secundaria,
    };
    setWhitelabelConfig(config);
    handlePreview(config);
  }, [distribuidora]);

  return (
    <>
      <S.Container>
        <C.TitleArea>
          <span className="textArea">Personalização</span>
        </C.TitleArea>
        <S.BoxArea>
          <S.Actions>
            <DefaultButtonPopover
              size="medium"
              onClick={() => setTogleModal(true)}
              icon={<PaletteOutlined />}
            >
              Personalizar
            </DefaultButtonPopover>
          </S.Actions>
          <S.Monitor>
            <iframe
              sandbox="allow-scripts allow-same-origin"
              src={`${window.location.origin}${queryString}`}
            />
          </S.Monitor>
          <S.Actions>
            <GhostButton size="medium" onClick={() => reset()}>
              Cancelar
            </GhostButton>

            <DefaultButtonPopover
              size="medium"
              sx={{ marginLeft: '10px' }}
              onClick={() => handleSave(whitelabelConfig)}
            >
              Salvar Alterações
            </DefaultButtonPopover>
          </S.Actions>
        </S.BoxArea>
      </S.Container>
      <SettingsModal
        distribuidoraId={id_da_distribuidora}
        open={togleModal}
        logoURL={distribuidora?.logo_whitelabel}
        whitelabelConfig={whitelabelConfig}
        handleClose={() => setTogleModal(false)}
        setWhitelabelConfig={setWhitelabelConfig}
        handleSaveLogo={handleSaveLogo}
        handlePreview={() => handlePreview(whitelabelConfig)}
      />
    </>
  );
};
