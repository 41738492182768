// --------------------------Check Controll----------------------------------------------//
export const changeCheck = (
  idResposta,
  idCampo,
  item,
  setSecoes,
  secoes,
  sectionId,
) => {
  secoes.map(secao => {
    if (secao.id === sectionId) {
      secao.campos.map(campo => {
        if (campo.id === idCampo) {
          campo.opcoes.map(opcao => {
            if (item === 'unic') {
              opcao.checked = false;
            }

            if (opcao.id === idResposta) {
              if (item === 'unic') {
                campo.resposta = opcao.item;
              } else if (opcao.checked) {
                campo.resposta = campo.resposta
                  ? campo.resposta
                      .split(';')
                      .filter(item => item !== opcao.item)
                  : '';
                campo.resposta = campo.resposta.join(';');
              } else if (campo.resposta) {
                campo.resposta = [
                  ...campo.resposta.split(';'),
                  opcao.item,
                ].join(';');
              } else {
                campo.resposta = [opcao.item].join(';');
              }

              opcao.checked = !opcao.checked;
              return true;
            }
          });
        }
      });
    }
  });

  setSecoes([...secoes]);
};

// --------------------------Comments Controll----------------------------------------------//
export const changeComments = (
  comentario,
  idCampo,
  setSecoes,
  secoes,
  sectionId,
) => {
  secoes.map(secao => {
    if (secao.id === sectionId) {
      secao.campos.map(campo => {
        if (campo.id === idCampo) {
          campo.observacoes = comentario;
        }
      });
    }
  });
  setSecoes([...secoes]);
};

// --------------------------Images Controll Answers----------------------------------------------//
export const addImage = (
  idResposta,
  idCampo,
  item,
  setSecoes,
  secoes,
  sectionId,
) => {
  if (!item) return null;
  secoes.map(secao => {
    if (secao.id === sectionId) {
      secao.campos.map(campo => {
        if (campo.id === idCampo) {
          campo.opcoes.map(opcao => {
            if (opcao.id === idResposta) {
              opcao.arquivos = item;
            }
          });
        }
      });
    }
  });
  setSecoes([...secoes]);
};

export const editImage = (
  idResposta,
  idCampo,
  item,
  setSecoes,
  secoes,
  sectionId,
) => {
  if (!item) return null;
  secoes.map(secao => {
    if (secao.id === sectionId) {
      secao.campos.map(campo => {
        if (campo.id === idCampo) {
          campo.opcoes.map(opcao => {
            if (opcao.id === idResposta) {
              opcao.arquivos.map(arquivo => {
                if (arquivo?.id === item?.id) {
                  arquivo.legenda = item.legenda;
                }
              });
            }
          });
        }
      });
    }
  });
  setSecoes([...secoes]);
};

export const deleteImage = (
  idResposta,
  idCampo,
  item,
  setSecoes,
  secoes,
  sectionId,
) => {
  if (!item) return null;
  secoes.map(secao => {
    if (secao.id === sectionId) {
      secao.campos.map(campo => {
        if (campo.id === idCampo) {
          campo.opcoes.map(opcao => {
            if (opcao.id === idResposta) {
              const newArr =
                typeof opcao?.arquivos === 'object'
                  ? opcao?.arquivos?.filter(
                      arquivo => arquivo?.id !== item[0]?.id,
                    )
                  : [];
              opcao.arquivos = newArr;
            }
          });
        }
      });
    }
  });
  setSecoes([...secoes]);
};

// --------------------------Images Controll Questions (Field) ----------------------------------------------//
export const addImageQuestion = (
  idCampo,
  item,
  setSecoes,
  secoes,
  sectionId,
) => {
  if (!item) return null;
  secoes.map(secao => {
    if (secao.id === sectionId) {
      secao.campos.map(campo => {
        if (item === 'reset') {
          const hasId = campo.arquivos.filter(ar => ar.id);
          campo.arquivos = hasId;
        } else if (campo.id === idCampo) {
          campo.arquivos = campo?.arquivos
            ? [...campo.arquivos, ...item]
            : item;
        }
      });
    }
  });
  setSecoes([...secoes]);
};

export const editImageQuestion = (
  idCampo,
  item,
  setSecoes,
  secoes,
  sectionId,
) => {
  if (!item) return null;
  secoes.map(secao => {
    if (secao.id === sectionId) {
      secao.campos.map(campo => {
        if (campo.id === idCampo) {
          campo.arquivos.map(arquivo => {
            item.map(i => {
              if (arquivo?.id === item[i]?.id) {
                arquivo.legenda = item[i]?.legenda;
              }
            });
          });
        }
      });
    }
  });
  setSecoes([...secoes]);
};

export const deleteImageQuestion = (
  idCampo,
  item,
  setSecoes,
  secoes,
  sectionId,
) => {
  if (!item) return null;
  secoes.map(secao => {
    if (secao.id === sectionId) {
      secao.campos.map(campo => {
        if (campo.id === idCampo) {
          const idsItems = item.map(i => i.id);
          const newArchives = campo?.arquivos?.filter(
            arquivo => !idsItems.includes(arquivo?.id),
          );
          campo.arquivos = newArchives || [];
        }
      });
    }
  });
  setSecoes([...secoes]);
};

// --------------------------Text Controll----------------------------------------------//
export const changeText = (texto, idCampo, setSecoes, secoes, sectionId) => {
  secoes.map(secao => {
    if (secao.id === sectionId) {
      secao.campos.map(campo => {
        if (campo.id === idCampo) {
          campo.resposta = texto;
        }
      });
    }
  });
  setSecoes([...secoes]);
};

// --------------------------ID Controll----------------------------------------------//
export const changeId = (
  texto,
  idCampo,
  setSecoes,
  secoes,
  sectionId,
  resString,
) => {
  secoes.map(secao => {
    if (secao.id === sectionId) {
      secao.campos.map(campo => {
        if (campo.id === idCampo) {
          campo.id_resposta = texto;
          campo.resposta = resString;
        }
      });
    }
  });
  setSecoes([...secoes]);
};
