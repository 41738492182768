import { Logs } from 'components/Logs';
// import { useEffect, useState } from 'react';
// import * as services from '../services';

export const Historico = ({ logs }) => {
  // const [logs, setLogs] = useState([]);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const getLogs = async () => {
  //     const res = await services.requestLogs(evaluation.id);
  //     setLogs(res.data.logs);
  //     setLoading(false);
  //   };
  //   getLogs();
  // }, []);

  return <Logs logs={logs} loading={false} />;
};
