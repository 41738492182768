import { useTheme } from 'styled-components';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import * as S from './styled';

const Checkbox = ({ value, onChange, label, disabled, error, preview }) => {
  const theme = useTheme();
  return (
    <S.Label hasError={error} preview={preview} disabled={disabled}>
      <S.Box
        isCheck={value}
        hasError={error}
        preview={preview}
        disabled={disabled}
      >
        <input
          type="checkbox"
          onChange={!disabled ? onChange : undefined}
          checked={value}
          readOnly={disabled}
        />
        <CheckOutlinedIcon
          fontSize="20px"
          htmlColor={
            value
              ? theme.palette.system.white
              : theme.palette.system.transparent
          }
        />
      </S.Box>
      {label && <span>{label}</span>}
    </S.Label>
  );
};

export default Checkbox;
