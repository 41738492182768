import styled from 'styled-components';
import Card from '@mui/material/Card';

const StyledCard = styled(Card)`
  width: 100%;
  max-width: ${props => props.maxWidth || '100%'};
  height: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  overflow: hidden;
`;

const Header = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BoxLoading = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GraphContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: 'thin';

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme.palette.system.highlight};
  }
`;

export { StyledCard, Header, BoxLoading, GraphContainer };
