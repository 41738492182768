import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 5px 150px;
`;

export const ControllerArea = styled.div`
  ${({ theme }) => css`
    position: fixed;
    z-index: 998;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 65px;
    background-color: ${theme.palette.system.overlay};
    box-shadow: 0px -5px 12px -4px ${theme.palette.system.shadow};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;

    .statusArea {
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-left: 100px;
    }

    .buttonsArea {
      .backArea {
        margin-right: 20px;
      }
    }
  `}
`;

export const HeaderPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  .titlePage {
    font-size: 24px;
    color: ${({ theme }) => theme.palette.words.title.natural};
    font-weight: 900;
  }
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
