import {
  getDesviosTelemetria,
  getVeiculos,
  getCriticidade,
  getCondicoes,
  getCarregamentos,
} from 'constants/_SERVICES/provider';

// Pages
import MinhasTarefas from 'pages/Provider/MinhasTarefas';

export default {
  '/minhas-tarefas': {
    date: true,
    defaults: [],
    page: <MinhasTarefas />,
  },
};
